export default {
  10: {
    name: {
      th: 'กรุงเทพมหานคร',
      en: 'Bangkok',
    },
    amphoes: {
      1001: {
        name: {
          th: 'พระนคร',
          en: 'Phra Nakhon',
        },
        tambons: {
          100101: {
            name: {
              th: 'พระบรมมหาราชวัง',
              en: 'Phra Borom Maha Ratchawang',
            },
            coordinates: {
              lat: '13.751',
              lng: '100.492',
            },
            zipcode: 10200,
          },
          100102: {
            name: {
              th: 'วังบูรพาภิรมย์',
              en: 'Wang Burapha Phirom',
            },
            coordinates: {
              lat: '13.744',
              lng: '100.499',
            },
            zipcode: 10200,
          },
          100103: {
            name: {
              th: 'วัดราชบพิธ',
              en: 'Wat Ratchabophit',
            },
            coordinates: {
              lat: '13.750',
              lng: '100.499',
            },
            zipcode: 10200,
          },
          100104: {
            name: {
              th: 'สำราญราษฎร์',
              en: 'Samran Rat',
            },
            coordinates: {
              lat: '13.751',
              lng: '100.503',
            },
            zipcode: 10200,
          },
          100105: {
            name: {
              th: 'ศาลเจ้าพ่อเสือ',
              en: 'San Chaopho Suea',
            },
            coordinates: {
              lat: '13.754',
              lng: '100.497',
            },
            zipcode: 10200,
          },
          100106: {
            name: {
              th: 'เสาชิงช้า',
              en: 'Sao Chingcha',
            },
            coordinates: {
              lat: '13.753',
              lng: '100.500',
            },
            zipcode: 10200,
          },
          100107: {
            name: {
              th: 'บวรนิเวศ',
              en: 'Bowon Niwet',
            },
            coordinates: {
              lat: '13.757',
              lng: '100.501',
            },
            zipcode: 10200,
          },
          100108: {
            name: {
              th: 'ตลาดยอด',
              en: 'Talat Yot',
            },
            coordinates: {
              lat: '13.760',
              lng: '100.498',
            },
            zipcode: 10200,
          },
          100109: {
            name: {
              th: 'ชนะสงคราม',
              en: 'Chana Songkhram',
            },
            coordinates: {
              lat: '13.762',
              lng: '100.495',
            },
            zipcode: 10200,
          },
          100110: {
            name: {
              th: 'บ้านพานถม',
              en: 'Ban Phan Thom',
            },
            coordinates: {
              lat: '13.762',
              lng: '100.503',
            },
            zipcode: 10200,
          },
          100111: {
            name: {
              th: 'บางขุนพรหม',
              en: 'Bang Khun Phrom',
            },
            coordinates: {
              lat: '13.765',
              lng: '100.505',
            },
            zipcode: 10200,
          },
          100112: {
            name: {
              th: 'วัดสามพระยา',
              en: 'Wat Sam Phraya',
            },
            coordinates: {
              lat: '13.768',
              lng: '100.499',
            },
            zipcode: 10200,
          },
        },
      },
      1002: {
        name: {
          th: 'ดุสิต',
          en: 'Dusit',
        },
        tambons: {
          100201: {
            name: {
              th: 'ดุสิต',
              en: 'Dusit',
            },
            coordinates: {
              lat: '13.772',
              lng: '100.513',
            },
            zipcode: 10300,
          },
          100202: {
            name: {
              th: 'วชิรพยาบาล',
              en: 'Wachira Phayaban',
            },
            coordinates: {
              lat: '13.778',
              lng: '100.505',
            },
            zipcode: 10300,
          },
          100203: {
            name: {
              th: 'สวนจิตรลดา',
              en: 'Suan Chit Lada',
            },
            coordinates: {
              lat: '13.767',
              lng: '100.520',
            },
            zipcode: 10303,
          },
          100204: {
            name: {
              th: 'สี่แยกมหานาค',
              en: 'Si Yaek Maha Nak',
            },
            coordinates: {
              lat: '13.758',
              lng: '100.517',
            },
            zipcode: 10300,
          },
          100206: {
            name: {
              th: 'ถนนนครไชยศรี',
              en: null,
            },
            coordinates: {
              lat: '13.789',
              lng: '100.522',
            },
            zipcode: 10300,
          },
        },
      },
      1003: {
        name: {
          th: 'หนองจอก',
          en: 'Nong Chok',
        },
        tambons: {
          100301: {
            name: {
              th: 'กระทุ่มราย',
              en: null,
            },
            coordinates: {
              lat: '13.823',
              lng: '100.897',
            },
            zipcode: 10530,
          },
          100302: {
            name: {
              th: 'หนองจอก',
              en: null,
            },
            coordinates: {
              lat: '13.869',
              lng: '100.885',
            },
            zipcode: 10530,
          },
          100303: {
            name: {
              th: 'คลองสิบ',
              en: null,
            },
            coordinates: {
              lat: '13.914',
              lng: '100.820',
            },
            zipcode: 10530,
          },
          100304: {
            name: {
              th: 'คลองสิบสอง',
              en: null,
            },
            coordinates: {
              lat: '13.914',
              lng: '100.880',
            },
            zipcode: 10530,
          },
          100305: {
            name: {
              th: 'โคกแฝด',
              en: null,
            },
            coordinates: {
              lat: '13.840',
              lng: '100.836',
            },
            zipcode: 10530,
          },
          100306: {
            name: {
              th: 'คู้ฝั่งเหนือ',
              en: null,
            },
            coordinates: {
              lat: '13.872',
              lng: '100.810',
            },
            zipcode: 10530,
          },
          100307: {
            name: {
              th: 'ลำผักชี',
              en: null,
            },
            coordinates: {
              lat: '13.797',
              lng: '100.844',
            },
            zipcode: 10530,
          },
          100308: {
            name: {
              th: 'ลำต้อยติ่ง',
              en: null,
            },
            coordinates: {
              lat: '13.781',
              lng: '100.877',
            },
            zipcode: 10530,
          },
        },
      },
      1004: {
        name: {
          th: 'บางรัก',
          en: 'Bang Rak',
        },
        tambons: {
          100401: {
            name: {
              th: 'มหาพฤฒาราม',
              en: null,
            },
            coordinates: {
              lat: '13.734',
              lng: '100.520',
            },
            zipcode: 10500,
          },
          100402: {
            name: {
              th: 'สีลม',
              en: null,
            },
            coordinates: {
              lat: '13.730',
              lng: '100.525',
            },
            zipcode: 10500,
          },
          100403: {
            name: {
              th: 'สุริยวงศ์',
              en: null,
            },
            coordinates: {
              lat: '13.724',
              lng: '100.530',
            },
            zipcode: 10500,
          },
          100404: {
            name: {
              th: 'บางรัก',
              en: null,
            },
            coordinates: {
              lat: '13.727',
              lng: '100.527',
            },
            zipcode: 10500,
          },
          100405: {
            name: {
              th: 'สี่พระยา',
              en: null,
            },
            coordinates: {
              lat: '13.725',
              lng: '100.514',
            },
            zipcode: 10500,
          },
        },
      },
      1005: {
        name: {
          th: 'บางเขน',
          en: 'Bang Khen',
        },
        tambons: {
          100502: {
            name: {
              th: 'อนุสาวรีย์',
              en: null,
            },
            coordinates: {
              lat: '13.868',
              lng: '100.606',
            },
            zipcode: 10220,
          },
          100508: {
            name: {
              th: 'ท่าแร้ง',
              en: null,
            },
            coordinates: {
              lat: '13.866',
              lng: '100.650',
            },
            zipcode: 10220,
          },
        },
      },
      1006: {
        name: {
          th: 'บางกะปิ',
          en: 'Bang Kapi',
        },
        tambons: {
          100601: {
            name: {
              th: 'คลองจั่น',
              en: null,
            },
            coordinates: {
              lat: '13.786',
              lng: '100.635',
            },
            zipcode: 10240,
          },
          100608: {
            name: {
              th: 'หัวหมาก',
              en: null,
            },
            coordinates: {
              lat: '13.756',
              lng: '100.660',
            },
            zipcode: 10250,
          },
        },
      },
      1007: {
        name: {
          th: 'ปทุมวัน',
          en: 'Pathum Wan',
        },
        tambons: {
          100701: {
            name: {
              th: 'รองเมือง',
              en: ' Rong Muang',
            },
            coordinates: {
              lat: '13.744',
              lng: '100.520',
            },
            zipcode: 10330,
          },
          100702: {
            name: {
              th: 'วังใหม่',
              en: ' Wang Mai',
            },
            coordinates: {
              lat: '13.742',
              lng: '100.526',
            },
            zipcode: 10330,
          },
          100703: {
            name: {
              th: 'ปทุมวัน',
              en: null,
            },
            coordinates: {
              lat: '13.740',
              lng: '100.535',
            },
            zipcode: 10330,
          },
          100704: {
            name: {
              th: 'ลุมพินี',
              en: null,
            },
            coordinates: {
              lat: '13.736',
              lng: '100.546',
            },
            zipcode: 10330,
          },
        },
      },
      1008: {
        name: {
          th: 'ป้อมปราบศัตรูพ่าย',
          en: 'Pom Prap Sattru Phai',
        },
        tambons: {
          100801: {
            name: {
              th: 'ป้อมปราบ',
              en: null,
            },
            coordinates: {
              lat: '13.743',
              lng: '100.514',
            },
            zipcode: 10100,
          },
          100802: {
            name: {
              th: 'วัดเทพศิรินทร์',
              en: null,
            },
            coordinates: {
              lat: '13.749',
              lng: '100.512',
            },
            zipcode: 10100,
          },
          100803: {
            name: {
              th: 'คลองมหานาค',
              en: null,
            },
            coordinates: {
              lat: '13.753',
              lng: '100.513',
            },
            zipcode: 10100,
          },
          100804: {
            name: {
              th: 'บ้านบาตร',
              en: null,
            },
            coordinates: {
              lat: '13.752',
              lng: '100.507',
            },
            zipcode: 10100,
          },
          100805: {
            name: {
              th: 'วัดโสมนัส',
              en: null,
            },
            coordinates: {
              lat: '13.759',
              lng: '100.511',
            },
            zipcode: 10100,
          },
        },
      },
      1009: {
        name: {
          th: 'พระโขนง',
          en: 'Phra Khanong',
        },
        tambons: {
          100905: {
            name: {
              th: 'บางจาก',
              en: null,
            },
            coordinates: {
              lat: '13.692',
              lng: '100.626',
            },
            zipcode: 10160,
          },
        },
      },
      1010: {
        name: {
          th: 'มีนบุรี',
          en: 'Min Buri',
        },
        tambons: {
          101001: {
            name: {
              th: 'มีนบุรี',
              en: null,
            },
            coordinates: {
              lat: '13.804',
              lng: '100.723',
            },
            zipcode: 10510,
          },
          101002: {
            name: {
              th: 'แสนแสบ',
              en: null,
            },
            coordinates: {
              lat: '13.812',
              lng: '100.774',
            },
            zipcode: 10510,
          },
        },
      },
      1011: {
        name: {
          th: 'ลาดกระบัง',
          en: 'Lat Krabang',
        },
        tambons: {
          101101: {
            name: {
              th: 'ลาดกระบัง',
              en: null,
            },
            coordinates: {
              lat: '13.723',
              lng: '100.771',
            },
            zipcode: 10520,
          },
          101102: {
            name: {
              th: 'คลองสองต้นนุ่น',
              en: null,
            },
            coordinates: {
              lat: '13.753',
              lng: '100.723',
            },
            zipcode: 10520,
          },
          101103: {
            name: {
              th: 'คลองสามประเวศ',
              en: null,
            },
            coordinates: {
              lat: '13.753',
              lng: '100.754',
            },
            zipcode: 10520,
          },
          101104: {
            name: {
              th: 'ลำปลาทิว',
              en: null,
            },
            coordinates: {
              lat: '13.765',
              lng: '100.817',
            },
            zipcode: 10520,
          },
          101105: {
            name: {
              th: 'ทับยาว',
              en: null,
            },
            coordinates: {
              lat: '13.728',
              lng: '100.815',
            },
            zipcode: 10520,
          },
          101106: {
            name: {
              th: 'ขุมทอง',
              en: null,
            },
            coordinates: {
              lat: '13.736',
              lng: '100.855',
            },
            zipcode: 10520,
          },
        },
      },
      1012: {
        name: {
          th: 'ยานนาวา',
          en: 'Yan Nawa',
        },
        tambons: {
          101203: {
            name: {
              th: 'ช่องนนทรี',
              en: null,
            },
            coordinates: {
              lat: '13.682',
              lng: '100.533',
            },
            zipcode: 10120,
          },
          101204: {
            name: {
              th: 'บางโพงพาง',
              en: null,
            },
            coordinates: {
              lat: '13.697',
              lng: '100.544',
            },
            zipcode: 10120,
          },
        },
      },
      1013: {
        name: {
          th: 'สัมพันธวงศ์',
          en: 'Samphanthawong',
        },
        tambons: {
          101301: {
            name: {
              th: 'จักรวรรดิ',
              en: null,
            },
            coordinates: {
              lat: '13.741',
              lng: '100.504',
            },
            zipcode: null,
          },
          101302: {
            name: {
              th: 'สัมพันธวงศ์',
              en: null,
            },
            coordinates: {
              lat: '13.739',
              lng: '100.511',
            },
            zipcode: 10100,
          },
          101303: {
            name: {
              th: 'ตลาดน้อย',
              en: null,
            },
            coordinates: {
              lat: '13.734',
              lng: '100.513',
            },
            zipcode: 10100,
          },
        },
      },
      1014: {
        name: {
          th: 'พญาไท',
          en: 'Phaya Thai',
        },
        tambons: {
          101401: {
            name: {
              th: 'สามเสนใน',
              en: null,
            },
            coordinates: {
              lat: '13.782',
              lng: '100.545',
            },
            zipcode: null,
          },
        },
      },
      1015: {
        name: {
          th: 'ธนบุรี',
          en: 'Thon Buri',
        },
        tambons: {
          101501: {
            name: {
              th: 'วัดกัลยาณ์',
              en: null,
            },
            coordinates: {
              lat: '13.737',
              lng: '100.493',
            },
            zipcode: 10600,
          },
          101502: {
            name: {
              th: 'หิรัญรูจี',
              en: null,
            },
            coordinates: {
              lat: '13.731',
              lng: '100.490',
            },
            zipcode: 10600,
          },
          101503: {
            name: {
              th: 'บางยี่เรือ',
              en: ' Bang Yi Ruea',
            },
            coordinates: {
              lat: '13.720',
              lng: '100.482',
            },
            zipcode: 10600,
          },
          101504: {
            name: {
              th: 'บุคคโล',
              en: null,
            },
            coordinates: {
              lat: '13.707',
              lng: '100.486',
            },
            zipcode: 10600,
          },
          101505: {
            name: {
              th: 'ตลาดพลู',
              en: null,
            },
            coordinates: {
              lat: '13.715',
              lng: '100.473',
            },
            zipcode: 10600,
          },
        },
      },
      1016: {
        name: {
          th: 'บางกอกใหญ่',
          en: 'Bangkok Yai',
        },
        tambons: {
          101601: {
            name: {
              th: 'วัดอรุณ',
              en: null,
            },
            coordinates: {
              lat: '13.743',
              lng: '100.486',
            },
            zipcode: 10600,
          },
          101602: {
            name: {
              th: 'วัดท่าพระ',
              en: null,
            },
            coordinates: {
              lat: '13.733',
              lng: '100.475',
            },
            zipcode: 10600,
          },
        },
      },
      1017: {
        name: {
          th: 'ห้วยขวาง',
          en: 'Huai Khwang',
        },
        tambons: {
          101701: {
            name: {
              th: 'ห้วยขวาง',
              en: null,
            },
            coordinates: {
              lat: '13.769',
              lng: '100.577',
            },
            zipcode: 10310,
          },
          101702: {
            name: {
              th: 'บางกะปิ',
              en: null,
            },
            coordinates: {
              lat: '13.752',
              lng: '100.586',
            },
            zipcode: 10310,
          },
          101704: {
            name: {
              th: 'สามเสนนอก',
              en: null,
            },
            coordinates: {
              lat: '13.796',
              lng: '100.579',
            },
            zipcode: 10310,
          },
        },
      },
      1018: {
        name: {
          th: 'คลองสาน',
          en: 'Khlong San',
        },
        tambons: {
          101801: {
            name: {
              th: 'สมเด็จเจ้าพระยา',
              en: null,
            },
            coordinates: {
              lat: '13.731',
              lng: '100.497',
            },
            zipcode: 10600,
          },
          101802: {
            name: {
              th: 'คลองสาน',
              en: null,
            },
            coordinates: {
              lat: '13.735',
              lng: '100.504',
            },
            zipcode: null,
          },
          101803: {
            name: {
              th: 'บางลำภูล่าง',
              en: null,
            },
            coordinates: {
              lat: '13.715',
              lng: '100.501',
            },
            zipcode: 10600,
          },
          101804: {
            name: {
              th: 'คลองต้นไทร',
              en: null,
            },
            coordinates: {
              lat: '13.725',
              lng: '100.508',
            },
            zipcode: 10600,
          },
        },
      },
      1019: {
        name: {
          th: 'ตลิ่งชัน',
          en: 'Taling Chan',
        },
        tambons: {
          101901: {
            name: {
              th: 'คลองชักพระ',
              en: null,
            },
            coordinates: {
              lat: '13.760',
              lng: '100.456',
            },
            zipcode: 10170,
          },
          101902: {
            name: {
              th: 'ตลิ่งชัน',
              en: null,
            },
            coordinates: {
              lat: '13.789',
              lng: '100.459',
            },
            zipcode: 10170,
          },
          101903: {
            name: {
              th: 'ฉิมพลี',
              en: null,
            },
            coordinates: {
              lat: '13.786',
              lng: '100.432',
            },
            zipcode: 10170,
          },
          101904: {
            name: {
              th: 'บางพรม',
              en: null,
            },
            coordinates: {
              lat: '13.752',
              lng: '100.442',
            },
            zipcode: 10170,
          },
          101905: {
            name: {
              th: 'บางระมาด',
              en: null,
            },
            coordinates: {
              lat: '13.767',
              lng: '100.431',
            },
            zipcode: 10170,
          },
          101907: {
            name: {
              th: 'บางเชือกหนัง',
              en: null,
            },
            coordinates: {
              lat: '13.751',
              lng: '100.419',
            },
            zipcode: 10170,
          },
        },
      },
      1020: {
        name: {
          th: 'บางกอกน้อย',
          en: 'Bangkok Noi',
        },
        tambons: {
          102004: {
            name: {
              th: 'ศิริราช',
              en: ' Siriraj',
            },
            coordinates: {
              lat: '13.759',
              lng: '100.481',
            },
            zipcode: 10700,
          },
          102005: {
            name: {
              th: 'บ้านช่างหล่อ',
              en: null,
            },
            coordinates: {
              lat: '13.752',
              lng: '100.477',
            },
            zipcode: 10700,
          },
          102006: {
            name: {
              th: 'บางขุนนนท์',
              en: null,
            },
            coordinates: {
              lat: '13.774',
              lng: '100.466',
            },
            zipcode: 10700,
          },
          102007: {
            name: {
              th: 'บางขุนศรี',
              en: null,
            },
            coordinates: {
              lat: '13.760',
              lng: '100.463',
            },
            zipcode: 10700,
          },
          102009: {
            name: {
              th: 'อรุณอมรินทร์',
              en: null,
            },
            coordinates: {
              lat: '13.772',
              lng: '100.477',
            },
            zipcode: null,
          },
        },
      },
      1021: {
        name: {
          th: 'บางขุนเทียน',
          en: 'Bang Khun Thian',
        },
        tambons: {
          102105: {
            name: {
              th: 'ท่าข้าม',
              en: null,
            },
            coordinates: {
              lat: '13.555',
              lng: '100.434',
            },
            zipcode: 10150,
          },
          102107: {
            name: {
              th: 'แสมดำ',
              en: null,
            },
            coordinates: {
              lat: '13.605',
              lng: '100.395',
            },
            zipcode: 10150,
          },
        },
      },
      1022: {
        name: {
          th: 'ภาษีเจริญ',
          en: 'Phasi Charoen',
        },
        tambons: {
          102201: {
            name: {
              th: 'บางหว้า',
              en: null,
            },
            coordinates: {
              lat: '13.714',
              lng: '100.443',
            },
            zipcode: 10160,
          },
          102202: {
            name: {
              th: 'บางด้วน',
              en: null,
            },
            coordinates: {
              lat: '13.723',
              lng: '100.437',
            },
            zipcode: 10160,
          },
          102206: {
            name: {
              th: 'บางจาก',
              en: null,
            },
            coordinates: {
              lat: '13.727',
              lng: '100.450',
            },
            zipcode: null,
          },
          102207: {
            name: {
              th: 'บางแวก',
              en: null,
            },
            coordinates: {
              lat: '13.736',
              lng: '100.445',
            },
            zipcode: 10160,
          },
          102208: {
            name: {
              th: 'คลองขวาง',
              en: null,
            },
            coordinates: {
              lat: '13.738',
              lng: '100.423',
            },
            zipcode: 10160,
          },
          102209: {
            name: {
              th: 'ปากคลองภาษีเจริญ',
              en: null,
            },
            coordinates: {
              lat: '13.722',
              lng: '100.464',
            },
            zipcode: 10160,
          },
          102210: {
            name: {
              th: 'คูหาสวรรค์',
              en: null,
            },
            coordinates: {
              lat: '13.727',
              lng: '100.457',
            },
            zipcode: 10160,
          },
        },
      },
      1023: {
        name: {
          th: 'หนองแขม',
          en: 'Nong Khaem',
        },
        tambons: {
          102302: {
            name: {
              th: 'หนองแขม',
              en: null,
            },
            coordinates: {
              lat: '13.680',
              lng: '100.358',
            },
            zipcode: 10160,
          },
          102303: {
            name: {
              th: 'หนองค้างพลู',
              en: null,
            },
            coordinates: {
              lat: '13.714',
              lng: '100.351',
            },
            zipcode: 10160,
          },
        },
      },
      1024: {
        name: {
          th: 'ราษฎร์บูรณะ',
          en: 'Rat Burana',
        },
        tambons: {
          102401: {
            name: {
              th: 'ราษฎร์บูรณะ',
              en: null,
            },
            coordinates: {
              lat: '13.670',
              lng: '100.510',
            },
            zipcode: 10140,
          },
          102402: {
            name: {
              th: 'บางปะกอก',
              en: null,
            },
            coordinates: {
              lat: '13.675',
              lng: '100.491',
            },
            zipcode: 10140,
          },
        },
      },
      1025: {
        name: {
          th: 'บางพลัด',
          en: 'Bang Phlat',
        },
        tambons: {
          102501: {
            name: {
              th: 'บางพลัด',
              en: null,
            },
            coordinates: {
              lat: '13.791',
              lng: '100.487',
            },
            zipcode: null,
          },
          102502: {
            name: {
              th: 'บางอ้อ',
              en: null,
            },
            coordinates: {
              lat: '13.802',
              lng: '100.512',
            },
            zipcode: null,
          },
          102503: {
            name: {
              th: 'บางบำหรุ',
              en: null,
            },
            coordinates: {
              lat: '13.781',
              lng: '100.482',
            },
            zipcode: null,
          },
          102504: {
            name: {
              th: 'บางยี่ขัน',
              en: null,
            },
            coordinates: {
              lat: '13.774',
              lng: '100.492',
            },
            zipcode: null,
          },
        },
      },
      1026: {
        name: {
          th: 'ดินแดง',
          en: 'Din Daeng',
        },
        tambons: {
          102601: {
            name: {
              th: 'ดินแดง',
              en: null,
            },
            coordinates: {
              lat: '13.778',
              lng: '100.567',
            },
            zipcode: null,
          },
        },
      },
      1027: {
        name: {
          th: 'บึงกุ่ม',
          en: 'Bueng Kum',
        },
        tambons: {
          102701: {
            name: {
              th: 'คลองกุ่ม',
              en: null,
            },
            coordinates: {
              lat: '13.808',
              lng: '100.650',
            },
            zipcode: null,
          },
        },
      },
      1028: {
        name: {
          th: 'สาทร',
          en: 'Sathon',
        },
        tambons: {
          102801: {
            name: {
              th: 'ทุ่งวัดดอน',
              en: null,
            },
            coordinates: {
              lat: '13.710',
              lng: '100.532',
            },
            zipcode: null,
          },
          102802: {
            name: {
              th: 'ยานนาวา',
              en: null,
            },
            coordinates: {
              lat: '13.714',
              lng: '100.514',
            },
            zipcode: null,
          },
          102803: {
            name: {
              th: 'ทุ่งมหาเมฆ',
              en: null,
            },
            coordinates: {
              lat: '13.718',
              lng: '100.541',
            },
            zipcode: null,
          },
        },
      },
      1029: {
        name: {
          th: 'บางซื่อ',
          en: 'Bang Su',
        },
        tambons: {
          102901: {
            name: {
              th: 'บางซื่อ',
              en: null,
            },
            coordinates: {
              lat: '13.820',
              lng: '100.529',
            },
            zipcode: null,
          },
        },
      },
      1030: {
        name: {
          th: 'จตุจักร',
          en: 'Chatuchak',
        },
        tambons: {
          103001: {
            name: {
              th: 'ลาดยาว',
              en: null,
            },
            coordinates: {
              lat: '13.826',
              lng: '100.565',
            },
            zipcode: null,
          },
        },
      },
      1031: {
        name: {
          th: 'บางคอแหลม',
          en: 'Bang Kho Laem',
        },
        tambons: {
          103101: {
            name: {
              th: 'บางคอแหลม',
              en: null,
            },
            coordinates: {
              lat: '13.696',
              lng: '100.494',
            },
            zipcode: null,
          },
          103102: {
            name: {
              th: 'วัดพระยาไกร',
              en: null,
            },
            coordinates: {
              lat: '13.706',
              lng: '100.508',
            },
            zipcode: null,
          },
          103103: {
            name: {
              th: 'บางโคล่',
              en: null,
            },
            coordinates: {
              lat: '13.694',
              lng: '100.516',
            },
            zipcode: null,
          },
        },
      },
      1032: {
        name: {
          th: 'ประเวศ',
          en: 'Prawet',
        },
        tambons: {
          103201: {
            name: {
              th: 'ประเวศ',
              en: null,
            },
            coordinates: {
              lat: '13.719',
              lng: '100.664',
            },
            zipcode: null,
          },
          103202: {
            name: {
              th: 'หนองบอน',
              en: null,
            },
            coordinates: {
              lat: '13.687',
              lng: '100.656',
            },
            zipcode: null,
          },
          103203: {
            name: {
              th: 'ดอกไม้',
              en: null,
            },
            coordinates: {
              lat: '13.680',
              lng: '100.689',
            },
            zipcode: null,
          },
        },
      },
      1033: {
        name: {
          th: 'คลองเตย',
          en: 'Khlong Toei',
        },
        tambons: {
          103301: {
            name: {
              th: 'คลองเตย',
              en: null,
            },
            coordinates: {
              lat: '13.710',
              lng: '100.570',
            },
            zipcode: null,
          },
          103302: {
            name: {
              th: 'คลองตัน',
              en: null,
            },
            coordinates: {
              lat: '13.723',
              lng: '100.571',
            },
            zipcode: null,
          },
          103303: {
            name: {
              th: 'พระโขนง',
              en: null,
            },
            coordinates: {
              lat: '13.707',
              lng: '100.595',
            },
            zipcode: null,
          },
        },
      },
      1034: {
        name: {
          th: 'สวนหลวง',
          en: 'Suan Luang',
        },
        tambons: {
          103401: {
            name: {
              th: 'สวนหลวง',
              en: null,
            },
            coordinates: {
              lat: '13.726',
              lng: '100.628',
            },
            zipcode: null,
          },
        },
      },
      1035: {
        name: {
          th: 'จอมทอง',
          en: 'Chom Thong',
        },
        tambons: {
          103501: {
            name: {
              th: 'บางขุนเทียน',
              en: null,
            },
            coordinates: {
              lat: '13.694',
              lng: '100.450',
            },
            zipcode: null,
          },
          103502: {
            name: {
              th: 'บางค้อ',
              en: null,
            },
            coordinates: {
              lat: '13.702',
              lng: '100.476',
            },
            zipcode: null,
          },
          103503: {
            name: {
              th: 'บางมด',
              en: null,
            },
            coordinates: {
              lat: '13.672',
              lng: '100.468',
            },
            zipcode: null,
          },
          103504: {
            name: {
              th: 'จอมทอง',
              en: null,
            },
            coordinates: {
              lat: '13.693',
              lng: '100.468',
            },
            zipcode: null,
          },
        },
      },
      1036: {
        name: {
          th: 'ดอนเมือง',
          en: 'Don Mueang',
        },
        tambons: {
          103602: {
            name: {
              th: 'สีกัน',
              en: null,
            },
            coordinates: {
              lat: '13.925',
              lng: '100.593',
            },
            zipcode: null,
          },
          103603: {
            name: {
              th: 'สนามบิน',
              en: null,
            },
            coordinates: {
              lat: '13.925',
              lng: '100.593',
            },
            zipcode: null,
          },
          103604: {
            name: {
              th: 'ดอนเมือง',
              en: null,
            },
            coordinates: {
              lat: '13.925',
              lng: '100.593',
            },
            zipcode: null,
          },
        },
      },
      1037: {
        name: {
          th: 'ราชเทวี',
          en: 'Ratchathewi',
        },
        tambons: {
          103701: {
            name: {
              th: 'ทุ่งพญาไท',
              en: null,
            },
            coordinates: {
              lat: '13.763',
              lng: '100.530',
            },
            zipcode: null,
          },
          103702: {
            name: {
              th: 'ถนนพญาไท',
              en: ' Thanon Phaya Thai',
            },
            coordinates: {
              lat: '13.757',
              lng: '100.538',
            },
            zipcode: null,
          },
          103703: {
            name: {
              th: 'ถนนเพชรบุรี',
              en: null,
            },
            coordinates: {
              lat: '13.752',
              lng: '100.559',
            },
            zipcode: null,
          },
          103704: {
            name: {
              th: 'มักกะสัน',
              en: null,
            },
            coordinates: {
              lat: '13.752',
              lng: '100.528',
            },
            zipcode: null,
          },
        },
      },
      1038: {
        name: {
          th: 'ลาดพร้าว',
          en: 'Lat Phrao',
        },
        tambons: {
          103801: {
            name: {
              th: 'ลาดพร้าว',
              en: null,
            },
            coordinates: {
              lat: '13.811',
              lng: '100.612',
            },
            zipcode: null,
          },
          103802: {
            name: {
              th: 'จรเข้บัว',
              en: null,
            },
            coordinates: {
              lat: '13.840',
              lng: '100.600',
            },
            zipcode: null,
          },
        },
      },
      1039: {
        name: {
          th: 'วัฒนา',
          en: 'Watthana',
        },
        tambons: {
          103901: {
            name: {
              th: 'คลองเตยเหนือ',
              en: null,
            },
            coordinates: {
              lat: '13.743',
              lng: '100.560',
            },
            zipcode: null,
          },
          103902: {
            name: {
              th: 'คลองตันเหนือ',
              en: null,
            },
            coordinates: {
              lat: '13.736',
              lng: '100.576',
            },
            zipcode: null,
          },
          103903: {
            name: {
              th: 'พระโขนงเหนือ',
              en: null,
            },
            coordinates: {
              lat: '13.719',
              lng: '100.596',
            },
            zipcode: null,
          },
        },
      },
      1040: {
        name: {
          th: 'บางแค',
          en: 'Bang Khae',
        },
        tambons: {
          104001: {
            name: {
              th: 'บางแค',
              en: null,
            },
            coordinates: {
              lat: '13.698',
              lng: '100.409',
            },
            zipcode: null,
          },
          104002: {
            name: {
              th: 'บางแคเหนือ',
              en: null,
            },
            coordinates: {
              lat: '13.720',
              lng: '100.400',
            },
            zipcode: null,
          },
          104003: {
            name: {
              th: 'บางไผ่',
              en: null,
            },
            coordinates: {
              lat: '13.741',
              lng: '100.385',
            },
            zipcode: null,
          },
          104004: {
            name: {
              th: 'หลักสอง',
              en: null,
            },
            coordinates: {
              lat: '13.683',
              lng: '100.396',
            },
            zipcode: null,
          },
        },
      },
      1041: {
        name: {
          th: 'หลักสี่',
          en: 'Lak Si',
        },
        tambons: {
          104101: {
            name: {
              th: 'ทุ่งสองห้อง',
              en: null,
            },
            coordinates: {
              lat: '13.883',
              lng: '100.564',
            },
            zipcode: null,
          },
          104102: {
            name: {
              th: 'ตลาดบางเขน',
              en: null,
            },
            coordinates: {
              lat: '13.871',
              lng: '100.580',
            },
            zipcode: null,
          },
        },
      },
      1042: {
        name: {
          th: 'สายไหม',
          en: 'Sai Mai',
        },
        tambons: {
          104201: {
            name: {
              th: 'สายไหม',
              en: null,
            },
            coordinates: {
              lat: '13.921',
              lng: '100.654',
            },
            zipcode: null,
          },
          104202: {
            name: {
              th: 'ออเงิน',
              en: null,
            },
            coordinates: {
              lat: '13.896',
              lng: '100.672',
            },
            zipcode: null,
          },
          104203: {
            name: {
              th: 'คลองถนน',
              en: null,
            },
            coordinates: {
              lat: '13.898',
              lng: '100.630',
            },
            zipcode: null,
          },
        },
      },
      1043: {
        name: {
          th: 'คันนายาว',
          en: 'Khan Na Yao',
        },
        tambons: {
          104301: {
            name: {
              th: 'คันนายาว',
              en: null,
            },
            coordinates: {
              lat: '13.821',
              lng: '100.677',
            },
            zipcode: null,
          },
        },
      },
      1044: {
        name: {
          th: 'สะพานสูง',
          en: 'Saphan Sung',
        },
        tambons: {
          104401: {
            name: {
              th: 'สะพานสูง',
              en: null,
            },
            coordinates: {
              lat: '13.761',
              lng: '100.688',
            },
            zipcode: null,
          },
        },
      },
      1045: {
        name: {
          th: 'วังทองหลาง',
          en: 'Wang Thonglang',
        },
        tambons: {
          104501: {
            name: {
              th: 'วังทองหลาง',
              en: null,
            },
            coordinates: {
              lat: '13.779',
              lng: '100.609',
            },
            zipcode: null,
          },
        },
      },
      1046: {
        name: {
          th: 'คลองสามวา',
          en: 'Khlong Sam Wa',
        },
        tambons: {
          104601: {
            name: {
              th: 'สามวาตะวันตก',
              en: null,
            },
            coordinates: {
              lat: '13.889',
              lng: '100.708',
            },
            zipcode: null,
          },
          104602: {
            name: {
              th: 'สามวาตะวันออก',
              en: null,
            },
            coordinates: {
              lat: '13.896',
              lng: '100.760',
            },
            zipcode: null,
          },
          104603: {
            name: {
              th: 'บางชัน',
              en: null,
            },
            coordinates: {
              lat: '13.839',
              lng: '100.700',
            },
            zipcode: null,
          },
          104604: {
            name: {
              th: 'ทรายกองดิน',
              en: null,
            },
            coordinates: {
              lat: '13.854',
              lng: '100.745',
            },
            zipcode: null,
          },
          104605: {
            name: {
              th: 'ทรายกองดินใต้',
              en: null,
            },
            coordinates: {
              lat: '13.861',
              lng: '100.786',
            },
            zipcode: null,
          },
        },
      },
      1047: {
        name: {
          th: 'บางนา',
          en: 'Bang Na',
        },
        tambons: {
          104701: {
            name: {
              th: 'บางนา',
              en: null,
            },
            coordinates: {
              lat: '13.672',
              lng: '100.616',
            },
            zipcode: null,
          },
        },
      },
      1048: {
        name: {
          th: 'ทวีวัฒนา',
          en: 'Thawi Watthana',
        },
        tambons: {
          104801: {
            name: {
              th: 'ทวีวัฒนา',
              en: null,
            },
            coordinates: {
              lat: '13.758',
              lng: '100.348',
            },
            zipcode: null,
          },
          104802: {
            name: {
              th: 'ศาลาธรรมสพน์',
              en: null,
            },
            coordinates: {
              lat: '13.783',
              lng: '100.390',
            },
            zipcode: null,
          },
        },
      },
      1049: {
        name: {
          th: 'ทุ่งครุ',
          en: 'Thung Khru',
        },
        tambons: {
          104901: {
            name: {
              th: 'บางมด',
              en: null,
            },
            coordinates: {
              lat: '13.651',
              lng: '100.510',
            },
            zipcode: null,
          },
          104902: {
            name: {
              th: 'ทุ่งครุ',
              en: null,
            },
            coordinates: {
              lat: '13.614',
              lng: '100.497',
            },
            zipcode: null,
          },
        },
      },
      1050: {
        name: {
          th: 'บางบอน',
          en: 'Bang Bon',
        },
        tambons: {
          105001: {
            name: {
              th: 'บางบอน',
              en: null,
            },
            coordinates: {
              lat: '13.646',
              lng: '100.370',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  11: {
    name: {
      th: 'สมุทรปราการ',
      en: 'Samut Prakarn',
    },
    amphoes: {
      1101: {
        name: {
          th: 'เมืองสมุทรปราการ',
          en: 'Mueang Samut Prakan',
        },
        tambons: {
          110101: {
            name: {
              th: 'เทศบาลนครสมุทรปราการ',
              en: null,
            },
            coordinates: {
              lat: '13.593',
              lng: '100.597',
            },
            zipcode: 10270,
          },
          110102: {
            name: {
              th: 'สำโรงเหนือ',
              en: null,
            },
            coordinates: {
              lat: '13.649',
              lng: '100.617',
            },
            zipcode: 10270,
          },
          110103: {
            name: {
              th: 'บางเมือง',
              en: null,
            },
            coordinates: {
              lat: '13.601',
              lng: '100.622',
            },
            zipcode: 10270,
          },
          110104: {
            name: {
              th: 'ท้ายบ้าน',
              en: null,
            },
            coordinates: {
              lat: '13.556',
              lng: '100.599',
            },
            zipcode: 10280,
          },
          110105: {
            name: {
              th: 'ท้ายบ้านใหม่',
              en: null,
            },
            coordinates: {
              lat: '13.572',
              lng: '100.621',
            },
            zipcode: 10290,
          },
          110108: {
            name: {
              th: 'บางปูใหม่',
              en: null,
            },
            coordinates: {
              lat: '13.526',
              lng: '100.670',
            },
            zipcode: 10280,
          },
          110110: {
            name: {
              th: 'แพรกษา',
              en: null,
            },
            coordinates: {
              lat: '13.564',
              lng: '100.653',
            },
            zipcode: 10280,
          },
          110111: {
            name: {
              th: 'บางโปรง',
              en: null,
            },
            coordinates: {
              lat: '13.623',
              lng: '100.565',
            },
            zipcode: 10270,
          },
          110112: {
            name: {
              th: 'เทศบาลบางปู',
              en: 'Bang Pu',
            },
            coordinates: {
              lat: '13.510',
              lng: '100.751',
            },
            zipcode: 10280,
          },
          110113: {
            name: {
              th: 'บางด้วน',
              en: null,
            },
            coordinates: {
              lat: '13.622',
              lng: '100.581',
            },
            zipcode: 10270,
          },
          110114: {
            name: {
              th: 'เทศบาลบางเมือง',
              en: 'Bang Mueang Mai',
            },
            coordinates: {
              lat: '13.622',
              lng: '100.605',
            },
            zipcode: 10270,
          },
          110115: {
            name: {
              th: 'เทพารักษ์',
              en: null,
            },
            coordinates: {
              lat: '13.634',
              lng: '100.610',
            },
            zipcode: 10270,
          },
          110117: {
            name: {
              th: 'แพรกษาใหม่',
              en: null,
            },
            coordinates: {
              lat: '13.559',
              lng: '100.691',
            },
            zipcode: 10280,
          },
        },
      },
      1102: {
        name: {
          th: 'บางบ่อ',
          en: 'Bang Bo',
        },
        tambons: {
          110201: {
            name: {
              th: 'บางบ่อ',
              en: null,
            },
            coordinates: {
              lat: '13.611',
              lng: '100.862',
            },
            zipcode: 10560,
          },
          110202: {
            name: {
              th: 'บ้านระกาศ',
              en: null,
            },
            coordinates: {
              lat: '13.639',
              lng: '100.904',
            },
            zipcode: 10560,
          },
          110203: {
            name: {
              th: 'บางพลีน้อย',
              en: null,
            },
            coordinates: {
              lat: '13.574',
              lng: '100.900',
            },
            zipcode: 10560,
          },
          110204: {
            name: {
              th: 'บางเพรียง',
              en: null,
            },
            coordinates: {
              lat: '13.545',
              lng: '100.805',
            },
            zipcode: 10560,
          },
          110205: {
            name: {
              th: 'คลองด่าน',
              en: null,
            },
            coordinates: {
              lat: '13.511',
              lng: '100.835',
            },
            zipcode: 10550,
          },
          110206: {
            name: {
              th: 'คลองสวน',
              en: null,
            },
            coordinates: {
              lat: '13.659',
              lng: '100.925',
            },
            zipcode: 10560,
          },
          110207: {
            name: {
              th: 'เปร็ง',
              en: null,
            },
            coordinates: {
              lat: '13.672',
              lng: '100.881',
            },
            zipcode: 10560,
          },
          110208: {
            name: {
              th: 'คลองนิยมยาตรา',
              en: null,
            },
            coordinates: {
              lat: '13.624',
              lng: '100.935',
            },
            zipcode: 10560,
          },
        },
      },
      1103: {
        name: {
          th: 'บางพลี',
          en: 'Bang Phli',
        },
        tambons: {
          110301: {
            name: {
              th: 'บางพลีใหญ่',
              en: null,
            },
            coordinates: {
              lat: '13.618',
              lng: '100.694',
            },
            zipcode: 10540,
          },
          110302: {
            name: {
              th: 'บางแก้ว',
              en: null,
            },
            coordinates: {
              lat: '13.642',
              lng: '100.662',
            },
            zipcode: 10540,
          },
          110303: {
            name: {
              th: 'บางปลา',
              en: null,
            },
            coordinates: {
              lat: '13.561',
              lng: '100.739',
            },
            zipcode: 10540,
          },
          110304: {
            name: {
              th: 'บางโฉลง',
              en: null,
            },
            coordinates: {
              lat: '13.623',
              lng: '100.755',
            },
            zipcode: 10540,
          },
          110308: {
            name: {
              th: 'ราชาเทวะ',
              en: null,
            },
            coordinates: {
              lat: '13.681',
              lng: '100.733',
            },
            zipcode: 10540,
          },
          110309: {
            name: {
              th: 'หนองปรือ',
              en: null,
            },
            coordinates: {
              lat: '13.678',
              lng: '100.750',
            },
            zipcode: 10540,
          },
        },
      },
      1104: {
        name: {
          th: 'พระประแดง',
          en: 'Phra Pradaeng',
        },
        tambons: {
          110401: {
            name: {
              th: 'เทศบาลเมืองพระประแดง',
              en: 'Talat',
            },
            coordinates: {
              lat: '13.656',
              lng: '100.533',
            },
            zipcode: 10130,
          },
          110402: {
            name: {
              th: 'ตลาด',
              en: 'Bang Phueng',
            },
            coordinates: {
              lat: '13.671',
              lng: '100.520',
            },
            zipcode: 10130,
          },
          110403: {
            name: {
              th: 'บางจาก',
              en: 'Bang Chak',
            },
            coordinates: {
              lat: '13.616',
              lng: '100.536',
            },
            zipcode: 10130,
          },
          110404: {
            name: {
              th: 'บางครุ',
              en: 'Bang Khru',
            },
            coordinates: {
              lat: '13.631',
              lng: '100.526',
            },
            zipcode: 10130,
          },
          110405: {
            name: {
              th: 'บางหญ้าแพรก',
              en: 'Bang Ya Phraek',
            },
            coordinates: {
              lat: '13.645',
              lng: '100.546',
            },
            zipcode: 10130,
          },
          110406: {
            name: {
              th: 'บางหัวเสือ',
              en: 'Bang Hua Suea',
            },
            coordinates: {
              lat: '13.625',
              lng: '100.549',
            },
            zipcode: 10130,
          },
          110407: {
            name: {
              th: 'เทศบาลสำโรงใต้',
              en: 'Samrong Tai',
            },
            coordinates: {
              lat: '13.641',
              lng: '100.573',
            },
            zipcode: 10130,
          },
          110408: {
            name: {
              th: 'บางยอ',
              en: 'Bang Yo',
            },
            coordinates: {
              lat: '13.679',
              lng: '100.558',
            },
            zipcode: 10130,
          },
          110409: {
            name: {
              th: 'บางกะเจ้า',
              en: 'Bang Kachao',
            },
            coordinates: {
              lat: '13.697',
              lng: '100.561',
            },
            zipcode: 10130,
          },
          110410: {
            name: {
              th: 'บางน้ำผึ้ง',
              en: 'Bang Nam Phueng',
            },
            coordinates: {
              lat: '13.679',
              lng: '100.578',
            },
            zipcode: 10130,
          },
          110411: {
            name: {
              th: 'บางกระสอบ',
              en: 'Bang Krasop',
            },
            coordinates: {
              lat: '13.666',
              lng: '100.566',
            },
            zipcode: 10130,
          },
          110412: {
            name: {
              th: 'บางกอบัว',
              en: 'Bang Ko Bua',
            },
            coordinates: {
              lat: '13.693',
              lng: '100.577',
            },
            zipcode: 10130,
          },
          110413: {
            name: {
              th: 'ทรงคนอง',
              en: 'Song Khanong',
            },
            coordinates: {
              lat: '13.665',
              lng: '100.545',
            },
            zipcode: 10130,
          },
          110414: {
            name: {
              th: 'สำโรง',
              en: 'Samrong',
            },
            coordinates: {
              lat: '13.659',
              lng: '100.584',
            },
            zipcode: 10130,
          },
          110415: {
            name: {
              th: 'สำโรงกลาง',
              en: 'Samrong Klang',
            },
            coordinates: {
              lat: '13.653',
              lng: '100.564',
            },
            zipcode: 10130,
          },
        },
      },
      1105: {
        name: {
          th: 'พระสมุทรเจดีย์',
          en: 'Phra Samut Chedi',
        },
        tambons: {
          110501: {
            name: {
              th: 'นาเกลือ',
              en: 'Na Kluea',
            },
            coordinates: {
              lat: '13.530',
              lng: '100.494',
            },
            zipcode: null,
          },
          110502: {
            name: {
              th: 'บ้านคลองสวน',
              en: 'Ban Khlong Suan',
            },
            coordinates: {
              lat: '13.571',
              lng: '100.476',
            },
            zipcode: null,
          },
          110503: {
            name: {
              th: 'แหลมฟ้าผ่า',
              en: 'Laem Fa Pha',
            },
            coordinates: {
              lat: '13.539',
              lng: '100.559',
            },
            zipcode: null,
          },
          110504: {
            name: {
              th: 'ปากคลองบางปลากด',
              en: 'Pak Khlong Bang Pla Kot',
            },
            coordinates: {
              lat: '13.606',
              lng: '100.567',
            },
            zipcode: null,
          },
          110505: {
            name: {
              th: 'ในคลองบางปลากด',
              en: 'Nai Khlong Bang Pla Kot',
            },
            coordinates: {
              lat: '13.588',
              lng: '100.541',
            },
            zipcode: null,
          },
        },
      },
      1106: {
        name: {
          th: 'บางเสาธง',
          en: 'Bang Sao Thong',
        },
        tambons: {
          110601: {
            name: {
              th: 'บางเสาธง',
              en: 'Bang Sao Thong',
            },
            coordinates: {
              lat: '13.600',
              lng: '100.815',
            },
            zipcode: null,
          },
          110602: {
            name: {
              th: 'ศรีษะจรเข้น้อย',
              en: 'Sisa Chorakhe Noi',
            },
            coordinates: {
              lat: '13.689',
              lng: '100.799',
            },
            zipcode: 10570,
          },
          110603: {
            name: {
              th: 'ศรีษะจรเข้ใหญ่',
              en: 'Sisa Chorakhe Yai',
            },
            coordinates: {
              lat: '13.641',
              lng: '100.803',
            },
            zipcode: 10570,
          },
        },
      },
    },
  },
  12: {
    name: {
      th: 'นนทบุรี',
      en: 'Nonthaburi',
    },
    amphoes: {
      1201: {
        name: {
          th: 'เมืองนนทบุรี',
          en: 'Mueang Nonthaburi',
        },
        tambons: {
          120101: {
            name: {
              th: 'สวนใหญ่',
              en: 'Suan Yai',
            },
            coordinates: {
              lat: '13.842',
              lng: '100.494',
            },
            zipcode: 11000,
          },
          120102: {
            name: {
              th: 'ตลาดขวัญ',
              en: 'Talat Khwan',
            },
            coordinates: {
              lat: '13.850',
              lng: '100.509',
            },
            zipcode: 11000,
          },
          120103: {
            name: {
              th: 'บางเขน',
              en: 'Bang Khen',
            },
            coordinates: {
              lat: '13.835',
              lng: '100.516',
            },
            zipcode: 11000,
          },
          120104: {
            name: {
              th: 'บางกระสอ',
              en: 'Bang Kraso',
            },
            coordinates: {
              lat: '13.869',
              lng: '100.491',
            },
            zipcode: 11000,
          },
          120105: {
            name: {
              th: 'ท่าทราย',
              en: 'Tha Sai',
            },
            coordinates: {
              lat: '13.884',
              lng: '100.501',
            },
            zipcode: 11000,
          },
          120106: {
            name: {
              th: 'บางไผ่',
              en: 'Bang Phai',
            },
            coordinates: {
              lat: '13.823',
              lng: '100.492',
            },
            zipcode: 11000,
          },
          120107: {
            name: {
              th: 'บางศรีเมือง',
              en: 'Bang Si Mueang',
            },
            coordinates: {
              lat: '13.841',
              lng: '100.475',
            },
            zipcode: 11000,
          },
          120108: {
            name: {
              th: 'บางกร่าง',
              en: 'Bang Krang',
            },
            coordinates: {
              lat: '13.835',
              lng: '100.438',
            },
            zipcode: 11000,
          },
          120109: {
            name: {
              th: 'ไทรม้า',
              en: 'Sai Ma',
            },
            coordinates: {
              lat: '13.870',
              lng: '100.472',
            },
            zipcode: 11000,
          },
          120110: {
            name: {
              th: 'บางรักน้อย',
              en: 'Bang Rak Noi',
            },
            coordinates: {
              lat: '13.859',
              lng: '100.464',
            },
            zipcode: 11000,
          },
        },
      },
      1202: {
        name: {
          th: 'บางกรวย',
          en: 'Bang Kruai',
        },
        tambons: {
          120201: {
            name: {
              th: 'วัดชลอ',
              en: 'Wat Chalo',
            },
            coordinates: {
              lat: '13.802',
              lng: '100.476',
            },
            zipcode: 11130,
          },
          120202: {
            name: {
              th: 'บางกรวย',
              en: 'Bang Kruai',
            },
            coordinates: {
              lat: '13.809',
              lng: '100.499',
            },
            zipcode: 11130,
          },
          120203: {
            name: {
              th: 'บางสีทอง',
              en: 'Bang Si Thong',
            },
            coordinates: {
              lat: '13.818',
              lng: '100.479',
            },
            zipcode: 11130,
          },
          120204: {
            name: {
              th: 'บางขนุน',
              en: 'Bang Khanun',
            },
            coordinates: {
              lat: '13.811',
              lng: '100.453',
            },
            zipcode: 11130,
          },
          120205: {
            name: {
              th: 'บางขุนกอง',
              en: 'Bang Khun Kong',
            },
            coordinates: {
              lat: '13.821',
              lng: '100.444',
            },
            zipcode: 11130,
          },
          120206: {
            name: {
              th: 'บางคูเวียง',
              en: 'Bang Khu Wiang',
            },
            coordinates: {
              lat: '13.822',
              lng: '100.417',
            },
            zipcode: 11130,
          },
          120207: {
            name: {
              th: 'มหาสวัสดิ์',
              en: 'Mahasawat',
            },
            coordinates: {
              lat: '13.806',
              lng: '100.434',
            },
            zipcode: 11130,
          },
          120208: {
            name: {
              th: 'ปลายบาง',
              en: 'Plai Bang',
            },
            coordinates: {
              lat: '13.814',
              lng: '100.402',
            },
            zipcode: 11130,
          },
          120209: {
            name: {
              th: 'ศาลากลาง',
              en: 'Sala Klang',
            },
            coordinates: {
              lat: '13.810',
              lng: '100.358',
            },
            zipcode: 11130,
          },
        },
      },
      1203: {
        name: {
          th: 'บางใหญ่',
          en: 'Bang Yai',
        },
        tambons: {
          120301: {
            name: {
              th: 'บางม่วง',
              en: 'Bang Muang',
            },
            coordinates: {
              lat: '13.838',
              lng: '100.406',
            },
            zipcode: 11140,
          },
          120302: {
            name: {
              th: 'บางแม่นาง',
              en: 'Bang Mae Nang',
            },
            coordinates: {
              lat: '13.868',
              lng: '100.381',
            },
            zipcode: 11140,
          },
          120303: {
            name: {
              th: 'บางเลน',
              en: 'Bang Len',
            },
            coordinates: {
              lat: '13.854',
              lng: '100.434',
            },
            zipcode: 11140,
          },
          120304: {
            name: {
              th: 'เสาธงหิน',
              en: 'Sao Thong Hin',
            },
            coordinates: {
              lat: '13.875',
              lng: '100.404',
            },
            zipcode: 11140,
          },
          120305: {
            name: {
              th: 'บางใหญ่',
              en: 'Bang Yai',
            },
            coordinates: {
              lat: '13.837',
              lng: '100.375',
            },
            zipcode: 11140,
          },
          120306: {
            name: {
              th: 'บ้านใหม่',
              en: 'Ban Mai',
            },
            coordinates: {
              lat: '13.861',
              lng: '100.333',
            },
            zipcode: 11120,
          },
        },
      },
      1204: {
        name: {
          th: 'บางบัวทอง',
          en: 'Bang Bua Thong',
        },
        tambons: {
          120401: {
            name: {
              th: 'โสนลอย',
              en: 'Sano Loi',
            },
            coordinates: {
              lat: '13.914',
              lng: '100.422',
            },
            zipcode: 11110,
          },
          120402: {
            name: {
              th: 'บางบัวทอง',
              en: 'Bang Bua Thong',
            },
            coordinates: {
              lat: '13.944',
              lng: '100.390',
            },
            zipcode: 11110,
          },
          120403: {
            name: {
              th: 'บางรักใหญ่',
              en: 'Bang Rak Yai',
            },
            coordinates: {
              lat: '13.878',
              lng: '100.438',
            },
            zipcode: 11110,
          },
          120404: {
            name: {
              th: 'บางคูรัด',
              en: 'Bang Khu Rat',
            },
            coordinates: {
              lat: '13.907',
              lng: '100.351',
            },
            zipcode: 11110,
          },
          120405: {
            name: {
              th: 'ละหาร',
              en: 'Lahan',
            },
            coordinates: {
              lat: '13.964',
              lng: '100.401',
            },
            zipcode: 11110,
          },
          120406: {
            name: {
              th: 'ลำโพ',
              en: 'Lam Pho',
            },
            coordinates: {
              lat: '13.970',
              lng: '100.416',
            },
            zipcode: 11110,
          },
          120407: {
            name: {
              th: 'พิมลราช',
              en: 'Phimonrat',
            },
            coordinates: {
              lat: '13.934',
              lng: '100.366',
            },
            zipcode: 11110,
          },
          120408: {
            name: {
              th: 'บางรักพัฒนา',
              en: 'Bang Rak Phatthana',
            },
            coordinates: {
              lat: '13.894',
              lng: '100.410',
            },
            zipcode: 11110,
          },
        },
      },
      1205: {
        name: {
          th: 'ไทรน้อย',
          en: 'Sai Noi',
        },
        tambons: {
          120501: {
            name: {
              th: 'ไทรน้อย',
              en: 'Sai Noi',
            },
            coordinates: {
              lat: '13.976',
              lng: '100.344',
            },
            zipcode: 11150,
          },
          120502: {
            name: {
              th: 'ราษฎร์นิยม',
              en: 'Rat Niyom',
            },
            coordinates: {
              lat: '14.088',
              lng: '100.329',
            },
            zipcode: 11150,
          },
          120503: {
            name: {
              th: 'หนองเพรางาย',
              en: 'Nong Phrao Ngai',
            },
            coordinates: {
              lat: '13.906',
              lng: '100.322',
            },
            zipcode: 11150,
          },
          120504: {
            name: {
              th: 'ไทรใหญ่',
              en: 'Sai Yai',
            },
            coordinates: {
              lat: '14.085',
              lng: '100.292',
            },
            zipcode: 11150,
          },
          120505: {
            name: {
              th: 'ขุนศรี',
              en: 'Khun Si',
            },
            coordinates: {
              lat: '14.005',
              lng: '100.287',
            },
            zipcode: 11150,
          },
          120506: {
            name: {
              th: 'คลองขวาง',
              en: 'Khlong Khwang',
            },
            coordinates: {
              lat: '14.004',
              lng: '100.306',
            },
            zipcode: 11150,
          },
          120507: {
            name: {
              th: 'ทวีวัฒนา',
              en: 'Thawi Watthana',
            },
            coordinates: {
              lat: '13.942',
              lng: '100.312',
            },
            zipcode: 11150,
          },
        },
      },
      1206: {
        name: {
          th: 'ปากเกร็ด',
          en: 'Pak Kret',
        },
        tambons: {
          120601: {
            name: {
              th: 'ปากเกร็ด',
              en: 'Pak Kret',
            },
            coordinates: {
              lat: '13.912',
              lng: '100.506',
            },
            zipcode: 11120,
          },
          120602: {
            name: {
              th: 'บางตลาด',
              en: 'Bang Talat',
            },
            coordinates: {
              lat: '13.896',
              lng: '100.521',
            },
            zipcode: 11120,
          },
          120603: {
            name: {
              th: 'บ้านใหม่',
              en: 'Ban Mai',
            },
            coordinates: {
              lat: '13.930',
              lng: '100.546',
            },
            zipcode: null,
          },
          120604: {
            name: {
              th: 'บางพูด',
              en: 'Bang Phut',
            },
            coordinates: {
              lat: '13.927',
              lng: '100.516',
            },
            zipcode: 11120,
          },
          120605: {
            name: {
              th: 'บางตะไนย์',
              en: 'Bang Tanai',
            },
            coordinates: {
              lat: '13.931',
              lng: '100.492',
            },
            zipcode: 11120,
          },
          120606: {
            name: {
              th: 'คลองพระอุดม',
              en: 'Khlong Phra Udom',
            },
            coordinates: {
              lat: '13.930',
              lng: '100.479',
            },
            zipcode: 11120,
          },
          120607: {
            name: {
              th: 'ท่าอิฐ',
              en: 'Tha It',
            },
            coordinates: {
              lat: '13.894',
              lng: '100.480',
            },
            zipcode: 11120,
          },
          120608: {
            name: {
              th: 'เกาะเกร็ด',
              en: 'Ko Kret',
            },
            coordinates: {
              lat: '13.910',
              lng: '100.472',
            },
            zipcode: 11120,
          },
          120609: {
            name: {
              th: 'อ้อมเกร็ด',
              en: 'Om Kret',
            },
            coordinates: {
              lat: '13.909',
              lng: '100.453',
            },
            zipcode: 11120,
          },
          120610: {
            name: {
              th: 'คลองข่อย',
              en: 'Khlong Khoi',
            },
            coordinates: {
              lat: '13.959',
              lng: '100.450',
            },
            zipcode: 11120,
          },
          120611: {
            name: {
              th: 'บางพลับ',
              en: 'Bang Phlap',
            },
            coordinates: {
              lat: '13.929',
              lng: '100.458',
            },
            zipcode: 11120,
          },
          120612: {
            name: {
              th: 'คลองเกลือ',
              en: 'Khlong Kluea',
            },
            coordinates: {
              lat: '13.902',
              lng: '100.550',
            },
            zipcode: 11120,
          },
        },
      },
    },
  },
  13: {
    name: {
      th: 'ปทุมธานี',
      en: 'Pathum Thani',
    },
    amphoes: {
      1301: {
        name: {
          th: 'เมืองปทุมธานี',
          en: 'Mueang Pathum Thani',
        },
        tambons: {
          130101: {
            name: {
              th: 'บางปรอก',
              en: 'Bang Prok',
            },
            coordinates: {
              lat: '14.012',
              lng: '100.528',
            },
            zipcode: 12000,
          },
          130102: {
            name: {
              th: 'บ้านใหม่',
              en: 'Ban Mai',
            },
            coordinates: {
              lat: '13.963',
              lng: '100.553',
            },
            zipcode: 12000,
          },
          130103: {
            name: {
              th: 'บ้านกลาง',
              en: 'Ban Klang',
            },
            coordinates: {
              lat: '14.001',
              lng: '100.558',
            },
            zipcode: 12000,
          },
          130104: {
            name: {
              th: 'บ้านฉาง',
              en: 'Ban Chang',
            },
            coordinates: {
              lat: '14.029',
              lng: '100.507',
            },
            zipcode: 12000,
          },
          130105: {
            name: {
              th: 'บ้านกระแชง',
              en: 'Ban Krachaeng',
            },
            coordinates: {
              lat: '14.031',
              lng: '100.552',
            },
            zipcode: 12000,
          },
          130106: {
            name: {
              th: 'บางขะแยง',
              en: 'Bang Khayaeng',
            },
            coordinates: {
              lat: '13.967',
              lng: '100.523',
            },
            zipcode: 12000,
          },
          130107: {
            name: {
              th: 'บางคูวัด',
              en: 'Bang Khu Wat',
            },
            coordinates: {
              lat: '13.963',
              lng: '100.495',
            },
            zipcode: 12000,
          },
          130108: {
            name: {
              th: 'บางหลวง',
              en: 'Bang Luang',
            },
            coordinates: {
              lat: '14.007',
              lng: '100.509',
            },
            zipcode: 12000,
          },
          130109: {
            name: {
              th: 'บางเดื่อ',
              en: 'Bang Duea',
            },
            coordinates: {
              lat: '13.990',
              lng: '100.487',
            },
            zipcode: 12000,
          },
          130110: {
            name: {
              th: 'บางพูด',
              en: 'Bang Phut',
            },
            coordinates: {
              lat: '14.035',
              lng: '100.572',
            },
            zipcode: 12000,
          },
          130111: {
            name: {
              th: 'บางพูน',
              en: 'Bang Phun',
            },
            coordinates: {
              lat: '13.988',
              lng: '100.594',
            },
            zipcode: 12000,
          },
          130112: {
            name: {
              th: 'บางกะดี',
              en: 'Bang Kadi',
            },
            coordinates: {
              lat: '13.983',
              lng: '100.537',
            },
            zipcode: 12000,
          },
          130113: {
            name: {
              th: 'สวนพริกไทย',
              en: 'Suan Phrik Thai',
            },
            coordinates: {
              lat: '14.018',
              lng: '100.568',
            },
            zipcode: 12000,
          },
          130114: {
            name: {
              th: 'หลักหก',
              en: 'Lak Hok',
            },
            coordinates: {
              lat: '13.963',
              lng: '100.590',
            },
            zipcode: 12000,
          },
        },
      },
      1302: {
        name: {
          th: 'คลองหลวง',
          en: 'khlong Luang',
        },
        tambons: {
          130201: {
            name: {
              th: 'คลองหนึ่ง',
              en: 'Khlong Nueng',
            },
            coordinates: {
              lat: '14.066',
              lng: '100.607',
            },
            zipcode: 12120,
          },
          130202: {
            name: {
              th: 'คลองสอง',
              en: 'Khlong Song',
            },
            coordinates: {
              lat: '14.079',
              lng: '100.642',
            },
            zipcode: 12120,
          },
          130203: {
            name: {
              th: 'คลองสาม',
              en: 'Khlong Sam',
            },
            coordinates: {
              lat: '14.087',
              lng: '100.664',
            },
            zipcode: 12120,
          },
          130204: {
            name: {
              th: 'คลองสี่',
              en: 'Khlong Si',
            },
            coordinates: {
              lat: '14.097',
              lng: '100.687',
            },
            zipcode: 12120,
          },
          130205: {
            name: {
              th: 'คลองห้า',
              en: 'Khlong Ha',
            },
            coordinates: {
              lat: '14.106',
              lng: '100.710',
            },
            zipcode: 12120,
          },
          130206: {
            name: {
              th: 'คลองหก',
              en: 'Khlong Hok',
            },
            coordinates: {
              lat: '14.117',
              lng: '100.733',
            },
            zipcode: 12120,
          },
          130207: {
            name: {
              th: 'คลองเจ็ด',
              en: 'Khlong Chet',
            },
            coordinates: {
              lat: '14.125',
              lng: '100.750',
            },
            zipcode: 12120,
          },
        },
      },
      1303: {
        name: {
          th: 'ธัญบุรี',
          en: 'Thanyaburi',
        },
        tambons: {
          130301: {
            name: {
              th: 'ประชาธิปัตย์',
              en: 'Pracha Thipat',
            },
            coordinates: {
              lat: '13.987',
              lng: '100.632',
            },
            zipcode: 12130,
          },
          130302: {
            name: {
              th: 'บึงยี่โถ',
              en: 'Bueng Yitho',
            },
            coordinates: {
              lat: '13.999',
              lng: '100.687',
            },
            zipcode: 12130,
          },
          130303: {
            name: {
              th: 'รังสิต',
              en: 'Rangsit',
            },
            coordinates: {
              lat: '14.018',
              lng: '100.731',
            },
            zipcode: 12110,
          },
          130304: {
            name: {
              th: 'ลำผักกูด',
              en: 'Lam Phak Kut',
            },
            coordinates: {
              lat: '14.036',
              lng: '100.779',
            },
            zipcode: 12110,
          },
          130305: {
            name: {
              th: 'บึงสนั่น',
              en: 'Bueng Sanan',
            },
            coordinates: {
              lat: '14.052',
              lng: '100.824',
            },
            zipcode: 12110,
          },
          130306: {
            name: {
              th: 'บึงน้ำรักษ์',
              en: 'Bueng Nam Rak',
            },
            coordinates: {
              lat: '14.071',
              lng: '100.880',
            },
            zipcode: 12110,
          },
        },
      },
      1304: {
        name: {
          th: 'หนองเสือ',
          en: 'Nong Suea',
        },
        tambons: {
          130401: {
            name: {
              th: 'บึงบา',
              en: 'Bueng Ba',
            },
            coordinates: {
              lat: '14.112',
              lng: '100.824',
            },
            zipcode: 12170,
          },
          130402: {
            name: {
              th: 'บึงบอน',
              en: 'Bueng Bon',
            },
            coordinates: {
              lat: '14.087',
              lng: '100.778',
            },
            zipcode: 12170,
          },
          130403: {
            name: {
              th: 'บึงกาสาม',
              en: 'Bueng Ka Sam',
            },
            coordinates: {
              lat: '14.200',
              lng: '100.824',
            },
            zipcode: 12170,
          },
          130404: {
            name: {
              th: 'บึงชำอ้อ',
              en: 'Bueng Cham O',
            },
            coordinates: {
              lat: '14.172',
              lng: '100.778',
            },
            zipcode: 12170,
          },
          130405: {
            name: {
              th: 'หนองสามวัง',
              en: 'Nong Sam Wang',
            },
            coordinates: {
              lat: '14.130',
              lng: '100.881',
            },
            zipcode: 12170,
          },
          130406: {
            name: {
              th: 'ศาลาครุ',
              en: 'Sala Khru',
            },
            coordinates: {
              lat: '14.244',
              lng: '100.928',
            },
            zipcode: 12170,
          },
          130407: {
            name: {
              th: 'นพรัตน์',
              en: 'Noppharat',
            },
            coordinates: {
              lat: '14.223',
              lng: '100.866',
            },
            zipcode: 12170,
          },
        },
      },
      1305: {
        name: {
          th: 'ลาดหลุมแก้ว',
          en: 'Lat Lum Kaeo',
        },
        tambons: {
          130501: {
            name: {
              th: 'ระแหง',
              en: 'Rahaeng',
            },
            coordinates: {
              lat: '14.059',
              lng: '100.396',
            },
            zipcode: 12140,
          },
          130502: {
            name: {
              th: 'ลาดหลุมแก้ว',
              en: 'Lat Lum Kaeo',
            },
            coordinates: {
              lat: '14.006',
              lng: '100.410',
            },
            zipcode: 12140,
          },
          130503: {
            name: {
              th: 'คูบางหลวง',
              en: 'Khu Bang Luang',
            },
            coordinates: {
              lat: '14.047',
              lng: '100.467',
            },
            zipcode: 12140,
          },
          130504: {
            name: {
              th: 'คูขวาง',
              en: 'Khu Khwang',
            },
            coordinates: {
              lat: '14.068',
              lng: '100.437',
            },
            zipcode: 12140,
          },
          130505: {
            name: {
              th: 'คลองพระอุดม',
              en: 'Khlong Phra Udom',
            },
            coordinates: {
              lat: '13.990',
              lng: '100.449',
            },
            zipcode: 12140,
          },
          130506: {
            name: {
              th: 'บ่อเงิน',
              en: 'Bo Ngoen',
            },
            coordinates: {
              lat: '14.102',
              lng: '100.383',
            },
            zipcode: 12140,
          },
          130507: {
            name: {
              th: 'หน้าไม้',
              en: 'Na Mai',
            },
            coordinates: {
              lat: '14.034',
              lng: '100.359',
            },
            zipcode: 12140,
          },
        },
      },
      1306: {
        name: {
          th: 'ลำลูกกา',
          en: 'Lam Luk Ka',
        },
        tambons: {
          130601: {
            name: {
              th: 'คูคต',
              en: 'Khu Khot',
            },
            coordinates: {
              lat: '13.955',
              lng: '100.641',
            },
            zipcode: 12130,
          },
          130602: {
            name: {
              th: 'ลาดสวาย',
              en: 'Lat Sawai',
            },
            coordinates: {
              lat: '13.958',
              lng: '100.685',
            },
            zipcode: 12150,
          },
          130603: {
            name: {
              th: 'บึงคำพร้อย',
              en: 'Bueng Kham Phoi',
            },
            coordinates: {
              lat: '13.961',
              lng: '100.731',
            },
            zipcode: 12150,
          },
          130604: {
            name: {
              th: 'ลำลูกกา',
              en: 'Lam Luk Ka',
            },
            coordinates: {
              lat: '13.972',
              lng: '100.774',
            },
            zipcode: 12150,
          },
          130605: {
            name: {
              th: 'บึงทองหลาง',
              en: 'Bueng Thong Lang',
            },
            coordinates: {
              lat: '13.984',
              lng: '100.820',
            },
            zipcode: 12150,
          },
          130606: {
            name: {
              th: 'ลำไทร',
              en: 'Lam Sai',
            },
            coordinates: {
              lat: '13.973',
              lng: '100.861',
            },
            zipcode: 12150,
          },
          130607: {
            name: {
              th: 'บึงคอไห',
              en: 'Bueng Kho Hai',
            },
            coordinates: {
              lat: '14.029',
              lng: '100.862',
            },
            zipcode: 12150,
          },
          130608: {
            name: {
              th: 'พืชอุดม',
              en: 'Phuet Udom',
            },
            coordinates: {
              lat: '13.975',
              lng: '100.896',
            },
            zipcode: 12150,
          },
        },
      },
      1307: {
        name: {
          th: 'สามโคก',
          en: 'Sam Khok',
        },
        tambons: {
          130701: {
            name: {
              th: 'บางเตย',
              en: 'Bang Toei',
            },
            coordinates: {
              lat: '14.062',
              lng: '100.504',
            },
            zipcode: 12160,
          },
          130702: {
            name: {
              th: 'คลองควาย',
              en: 'Khlong Khwai',
            },
            coordinates: {
              lat: '14.097',
              lng: '100.478',
            },
            zipcode: 12160,
          },
          130703: {
            name: {
              th: 'สามโคก',
              en: 'Sam Khok',
            },
            coordinates: {
              lat: '14.052',
              lng: '100.529',
            },
            zipcode: 12160,
          },
          130704: {
            name: {
              th: 'กระแชง',
              en: 'Krachaeng',
            },
            coordinates: {
              lat: '14.043',
              lng: '100.544',
            },
            zipcode: 12160,
          },
          130705: {
            name: {
              th: 'บางโพธิ์เหนือ',
              en: 'Bang Pho Nuea',
            },
            coordinates: {
              lat: '14.042',
              lng: '100.515',
            },
            zipcode: 12160,
          },
          130706: {
            name: {
              th: 'เชียงรากใหญ่',
              en: 'Chiang Rak Yai',
            },
            coordinates: {
              lat: '14.057',
              lng: '100.574',
            },
            zipcode: 12160,
          },
          130707: {
            name: {
              th: 'บ้านปทุม',
              en: 'Ban Pathum',
            },
            coordinates: {
              lat: '14.067',
              lng: '100.546',
            },
            zipcode: 12160,
          },
          130708: {
            name: {
              th: 'บ้านงิ้ว',
              en: 'Ban Ngio',
            },
            coordinates: {
              lat: '14.084',
              lng: '100.539',
            },
            zipcode: 12160,
          },
          130709: {
            name: {
              th: 'เชียงรากน้อย',
              en: 'Chiang Rak Noi',
            },
            coordinates: {
              lat: '14.105',
              lng: '100.567',
            },
            zipcode: 12160,
          },
          130710: {
            name: {
              th: 'บางกระบือ',
              en: 'Bang Krabue',
            },
            coordinates: {
              lat: '14.103',
              lng: '100.538',
            },
            zipcode: 12160,
          },
          130711: {
            name: {
              th: 'ท้ายเกาะ',
              en: 'Thai Ko',
            },
            coordinates: {
              lat: '14.105',
              lng: '100.496',
            },
            zipcode: 12160,
          },
        },
      },
    },
  },
  14: {
    name: {
      th: 'พระนครศรีอยุธยา',
      en: 'Phra Nakhon Si Ayutthaya',
    },
    amphoes: {
      1401: {
        name: {
          th: 'พระนครศรีอยุธยา',
          en: 'Phra Nakhon Si Ayutthaya',
        },
        tambons: {
          140101: {
            name: {
              th: 'ประตูชัย',
              en: 'Pratu Chai',
            },
            coordinates: {
              lat: '14.349',
              lng: '100.551',
            },
            zipcode: 13000,
          },
          140102: {
            name: {
              th: 'กะมัง',
              en: 'Kamang',
            },
            coordinates: {
              lat: '14.341',
              lng: '100.581',
            },
            zipcode: 13000,
          },
          140103: {
            name: {
              th: 'หอรัตนไชย',
              en: 'Ho Rattana Chai',
            },
            coordinates: {
              lat: '14.354',
              lng: '100.578',
            },
            zipcode: 13000,
          },
          140104: {
            name: {
              th: 'หัวรอ',
              en: 'Hua Ro',
            },
            coordinates: {
              lat: '14.368',
              lng: '100.578',
            },
            zipcode: 13000,
          },
          140105: {
            name: {
              th: 'ท่าวาสุกรี',
              en: 'Tha Wasukri',
            },
            coordinates: {
              lat: '14.361',
              lng: '100.552',
            },
            zipcode: 13000,
          },
          140106: {
            name: {
              th: 'ไผ่ลิง',
              en: 'Phai Ling',
            },
            coordinates: {
              lat: '14.356',
              lng: '100.589',
            },
            zipcode: 13000,
          },
          140107: {
            name: {
              th: 'ปากกราน',
              en: 'Pak Kran',
            },
            coordinates: {
              lat: '14.310',
              lng: '100.532',
            },
            zipcode: 13000,
          },
          140108: {
            name: {
              th: 'ภูเขาทอง',
              en: 'Phu Khao Thong',
            },
            coordinates: {
              lat: '14.365',
              lng: '100.542',
            },
            zipcode: 13000,
          },
          140109: {
            name: {
              th: 'สำเภาล่ม',
              en: 'Samphao Lom',
            },
            coordinates: {
              lat: '14.340',
              lng: '100.570',
            },
            zipcode: 13000,
          },
          140110: {
            name: {
              th: 'สวนพริก',
              en: 'Suan Phrik',
            },
            coordinates: {
              lat: '14.391',
              lng: '100.558',
            },
            zipcode: 13000,
          },
          140111: {
            name: {
              th: 'คลองตะเคียน',
              en: 'Khlong Takhian',
            },
            coordinates: {
              lat: '14.331',
              lng: '100.554',
            },
            zipcode: 13000,
          },
          140112: {
            name: {
              th: 'วัดตูม',
              en: 'Wat Tum',
            },
            coordinates: {
              lat: '14.390',
              lng: '100.539',
            },
            zipcode: 13000,
          },
          140113: {
            name: {
              th: 'หันตรา',
              en: 'Han Tra',
            },
            coordinates: {
              lat: '14.371',
              lng: '100.600',
            },
            zipcode: 13000,
          },
          140114: {
            name: {
              th: 'ลุมพลี',
              en: 'Lumphli',
            },
            coordinates: {
              lat: '14.376',
              lng: '100.548',
            },
            zipcode: 13000,
          },
          140115: {
            name: {
              th: 'บ้านใหม่',
              en: 'Ban Mai',
            },
            coordinates: {
              lat: '14.390',
              lng: '100.521',
            },
            zipcode: 13150,
          },
          140116: {
            name: {
              th: 'บ้านเกาะ',
              en: 'Ban Ko',
            },
            coordinates: {
              lat: '14.389',
              lng: '100.582',
            },
            zipcode: 13000,
          },
          140117: {
            name: {
              th: 'คลองสวนพลู',
              en: 'Khlong Suan Phu',
            },
            coordinates: {
              lat: '14.334',
              lng: '100.595',
            },
            zipcode: 13000,
          },
          140118: {
            name: {
              th: 'คลองสระบัว',
              en: 'Khlong Sa Bua',
            },
            coordinates: {
              lat: '14.371',
              lng: '100.563',
            },
            zipcode: 13000,
          },
          140119: {
            name: {
              th: 'เกาะเรียน',
              en: 'Ko Rian',
            },
            coordinates: {
              lat: '14.318',
              lng: '100.579',
            },
            zipcode: 13000,
          },
          140120: {
            name: {
              th: 'บ้านป้อม',
              en: 'Ban Pom',
            },
            coordinates: {
              lat: '14.347',
              lng: '100.529',
            },
            zipcode: 13000,
          },
          140121: {
            name: {
              th: 'บ้านรุม',
              en: 'Ban Run',
            },
            coordinates: {
              lat: '14.312',
              lng: '100.559',
            },
            zipcode: 13000,
          },
        },
      },
      1402: {
        name: {
          th: 'ท่าเรือ',
          en: 'Tha Ruea',
        },
        tambons: {
          140201: {
            name: {
              th: 'ท่าเรือ',
              en: 'Tha Ruea',
            },
            coordinates: {
              lat: '14.566',
              lng: '100.719',
            },
            zipcode: 13130,
          },
          140202: {
            name: {
              th: 'จำปา',
              en: 'Champa',
            },
            coordinates: {
              lat: '14.550',
              lng: '100.741',
            },
            zipcode: null,
          },
          140203: {
            name: {
              th: 'ท่าหลวง',
              en: 'Tha Luang',
            },
            coordinates: {
              lat: '14.548',
              lng: '100.764',
            },
            zipcode: 18270,
          },
          140204: {
            name: {
              th: 'บ้านร่อม',
              en: 'Ban Rom',
            },
            coordinates: {
              lat: '14.567',
              lng: '100.698',
            },
            zipcode: 13130,
          },
          140205: {
            name: {
              th: 'ศาลาลอย',
              en: 'Sala Loi',
            },
            coordinates: {
              lat: '14.532',
              lng: '100.700',
            },
            zipcode: 13130,
          },
          140206: {
            name: {
              th: 'วังแดง',
              en: 'Wang Daeng',
            },
            coordinates: {
              lat: '14.541',
              lng: '100.670',
            },
            zipcode: 13130,
          },
          140207: {
            name: {
              th: 'โพธิ์เอน',
              en: 'Pho En',
            },
            coordinates: {
              lat: '14.519',
              lng: '100.676',
            },
            zipcode: 13130,
          },
          140208: {
            name: {
              th: 'ปากท่า',
              en: 'Pak Tha',
            },
            coordinates: {
              lat: '14.504',
              lng: '100.692',
            },
            zipcode: 13130,
          },
          140209: {
            name: {
              th: 'หนองขนาก',
              en: 'Nong Khanak',
            },
            coordinates: {
              lat: '14.512',
              lng: '100.732',
            },
            zipcode: 13130,
          },
          140210: {
            name: {
              th: 'ท่าเจ้าสนุก',
              en: 'Tha Chao Sanuk',
            },
            coordinates: {
              lat: '14.543',
              lng: '100.717',
            },
            zipcode: 13130,
          },
        },
      },
      1403: {
        name: {
          th: 'นครหลวง',
          en: 'Nakhon Luang',
        },
        tambons: {
          140301: {
            name: {
              th: 'นครหลวง',
              en: 'Nakhon Luang',
            },
            coordinates: {
              lat: '14.469',
              lng: '100.622',
            },
            zipcode: 13260,
          },
          140302: {
            name: {
              th: 'ท่าช้าง',
              en: 'Tha Chang',
            },
            coordinates: {
              lat: '14.511',
              lng: '100.652',
            },
            zipcode: 13260,
          },
          140303: {
            name: {
              th: 'บ่อโพง',
              en: 'Bo Phong',
            },
            coordinates: {
              lat: '14.406',
              lng: '100.603',
            },
            zipcode: 13260,
          },
          140304: {
            name: {
              th: 'บ้านชุ้ง',
              en: 'Ban Chung',
            },
            coordinates: {
              lat: '14.458',
              lng: '100.655',
            },
            zipcode: 13260,
          },
          140305: {
            name: {
              th: 'ปากจั่น',
              en: 'Pak Chan',
            },
            coordinates: {
              lat: '14.440',
              lng: '100.620',
            },
            zipcode: 13260,
          },
          140306: {
            name: {
              th: 'บางระกำ',
              en: 'Bang Rakam',
            },
            coordinates: {
              lat: '14.466',
              lng: '100.589',
            },
            zipcode: 13260,
          },
          140307: {
            name: {
              th: 'บางพระครู',
              en: 'Bang Phrakhru',
            },
            coordinates: {
              lat: '14.483',
              lng: '100.613',
            },
            zipcode: 13260,
          },
          140308: {
            name: {
              th: 'แม่ลา',
              en: 'Mae La',
            },
            coordinates: {
              lat: '14.503',
              lng: '100.619',
            },
            zipcode: 13260,
          },
          140309: {
            name: {
              th: 'หนองปลิง',
              en: 'Nong Pling',
            },
            coordinates: {
              lat: '14.423',
              lng: '100.640',
            },
            zipcode: 13260,
          },
          140310: {
            name: {
              th: 'คลองสะแก',
              en: 'Khlong Sakae',
            },
            coordinates: {
              lat: '14.424',
              lng: '100.604',
            },
            zipcode: 13260,
          },
          140311: {
            name: {
              th: 'สามไถ',
              en: 'Sam Thai',
            },
            coordinates: {
              lat: '14.489',
              lng: '100.675',
            },
            zipcode: 13260,
          },
          140312: {
            name: {
              th: 'พระนอน',
              en: 'Phra Non',
            },
            coordinates: {
              lat: '14.485',
              lng: '100.650',
            },
            zipcode: 13260,
          },
        },
      },
      1404: {
        name: {
          th: 'บางไทร',
          en: 'Bang Sai',
        },
        tambons: {
          140401: {
            name: {
              th: 'บางไทร',
              en: 'Bang Sai',
            },
            coordinates: {
              lat: '14.191',
              lng: '100.480',
            },
            zipcode: 13190,
          },
          140402: {
            name: {
              th: 'บางพลี',
              en: 'Bang Phli',
            },
            coordinates: {
              lat: '14.212',
              lng: '100.465',
            },
            zipcode: 13190,
          },
          140403: {
            name: {
              th: 'สนามชัย',
              en: 'Sanam Chai',
            },
            coordinates: {
              lat: '14.213',
              lng: '100.522',
            },
            zipcode: 13190,
          },
          140404: {
            name: {
              th: 'บ้านแป้ง',
              en: 'Ban Paeng',
            },
            coordinates: {
              lat: '14.232',
              lng: '100.507',
            },
            zipcode: 13190,
          },
          140405: {
            name: {
              th: 'หน้าไม้',
              en: 'Na Mai',
            },
            coordinates: {
              lat: '14.315',
              lng: '100.462',
            },
            zipcode: 13190,
          },
          140406: {
            name: {
              th: 'บางยี่โท',
              en: 'Bang Yitho',
            },
            coordinates: {
              lat: '14.299',
              lng: '100.445',
            },
            zipcode: 13190,
          },
          140407: {
            name: {
              th: 'แคออก',
              en: 'Khae Ok',
            },
            coordinates: {
              lat: '14.305',
              lng: '100.472',
            },
            zipcode: 13190,
          },
          140408: {
            name: {
              th: 'แคตก',
              en: 'Khae Tok',
            },
            coordinates: {
              lat: '14.287',
              lng: '100.480',
            },
            zipcode: 13190,
          },
          140409: {
            name: {
              th: 'ช่างเหล็ก',
              en: 'Chang Lek',
            },
            coordinates: {
              lat: '14.278',
              lng: '100.453',
            },
            zipcode: 13190,
          },
          140410: {
            name: {
              th: 'กระแชง',
              en: 'Krachaeng',
            },
            coordinates: {
              lat: '14.279',
              lng: '100.505',
            },
            zipcode: 13190,
          },
          140411: {
            name: {
              th: 'บ้างกลึง',
              en: 'Ban Klueng',
            },
            coordinates: {
              lat: '14.309',
              lng: '100.499',
            },
            zipcode: 13190,
          },
          140412: {
            name: {
              th: 'ช้างน้อย',
              en: 'Chang Noi',
            },
            coordinates: {
              lat: '14.252',
              lng: '100.505',
            },
            zipcode: 13190,
          },
          140413: {
            name: {
              th: 'ห่อหมก',
              en: 'Ho Mok',
            },
            coordinates: {
              lat: '14.259',
              lng: '100.474',
            },
            zipcode: 13190,
          },
          140414: {
            name: {
              th: 'ไผ่พระ',
              en: 'Phai Phra',
            },
            coordinates: {
              lat: '14.222',
              lng: '100.432',
            },
            zipcode: 13190,
          },
          140415: {
            name: {
              th: 'กกแก้วบูรพา',
              en: 'Kok Kaeo Burapha',
            },
            coordinates: {
              lat: '14.188',
              lng: '100.435',
            },
            zipcode: 13190,
          },
          140416: {
            name: {
              th: 'ไม้ตรา',
              en: 'Mai Tra',
            },
            coordinates: {
              lat: '14.164',
              lng: '100.473',
            },
            zipcode: 13190,
          },
          140417: {
            name: {
              th: 'บ้านม้า',
              en: 'Ban Ma',
            },
            coordinates: {
              lat: '14.141',
              lng: '100.490',
            },
            zipcode: 13190,
          },
          140418: {
            name: {
              th: 'บ้านเกาะ',
              en: 'Ban Ko',
            },
            coordinates: {
              lat: '14.236',
              lng: '100.460',
            },
            zipcode: null,
          },
          140419: {
            name: {
              th: 'ราชคราม',
              en: 'Ratchakhram',
            },
            coordinates: {
              lat: '14.186',
              lng: '100.519',
            },
            zipcode: 13290,
          },
          140420: {
            name: {
              th: 'ช้างใหญ่',
              en: 'Chang Yai',
            },
            coordinates: {
              lat: '14.161',
              lng: '100.522',
            },
            zipcode: 13290,
          },
          140422: {
            name: {
              th: 'เชียงรากน้อย',
              en: 'Chiang Rak Noi',
            },
            coordinates: {
              lat: '14.134',
              lng: '100.561',
            },
            zipcode: 13290,
          },
          140423: {
            name: {
              th: 'โคกช้าง',
              en: 'Khok Chang',
            },
            coordinates: {
              lat: '14.123',
              lng: '100.492',
            },
            zipcode: 13120,
          },
        },
      },
      1405: {
        name: {
          th: 'บางบาล',
          en: 'Bang Ban',
        },
        tambons: {
          140501: {
            name: {
              th: 'บางบาล',
              en: 'Bang Ban',
            },
            coordinates: {
              lat: '14.410',
              lng: '100.464',
            },
            zipcode: 13250,
          },
          140502: {
            name: {
              th: 'วัดยม',
              en: 'Wat Yom',
            },
            coordinates: {
              lat: '14.390',
              lng: '100.497',
            },
            zipcode: 13160,
          },
          140503: {
            name: {
              th: 'ไทรน้อย',
              en: 'Sai Noi',
            },
            coordinates: {
              lat: '14.407',
              lng: '100.486',
            },
            zipcode: 13250,
          },
          140504: {
            name: {
              th: 'สะพานไทย',
              en: 'Saphan Thai',
            },
            coordinates: {
              lat: '14.363',
              lng: '100.486',
            },
            zipcode: 13250,
          },
          140505: {
            name: {
              th: 'มหาพราหมณ์',
              en: 'Mahaphram',
            },
            coordinates: {
              lat: '14.364',
              lng: '100.505',
            },
            zipcode: 13250,
          },
          140506: {
            name: {
              th: 'กบเจา',
              en: 'Kop Chao',
            },
            coordinates: {
              lat: '14.347',
              lng: '100.485',
            },
            zipcode: 13250,
          },
          140507: {
            name: {
              th: 'บ้านคลัง',
              en: 'Ban Khlang',
            },
            coordinates: {
              lat: '14.347',
              lng: '100.457',
            },
            zipcode: 13250,
          },
          140508: {
            name: {
              th: 'พระขาว',
              en: 'Phra Khao',
            },
            coordinates: {
              lat: '14.328',
              lng: '100.482',
            },
            zipcode: 13250,
          },
          140509: {
            name: {
              th: 'น้ำเต้า',
              en: 'Namtao',
            },
            coordinates: {
              lat: '14.339',
              lng: '100.438',
            },
            zipcode: 13150,
          },
          140510: {
            name: {
              th: 'ทางช้าง',
              en: 'Thang Chang',
            },
            coordinates: {
              lat: '14.375',
              lng: '100.432',
            },
            zipcode: 13250,
          },
          140511: {
            name: {
              th: 'วัดตะกู',
              en: 'Wat Taku',
            },
            coordinates: {
              lat: '14.388',
              lng: '100.424',
            },
            zipcode: 13250,
          },
          140512: {
            name: {
              th: 'บางหลวง',
              en: 'Bang Luang',
            },
            coordinates: {
              lat: '14.403',
              lng: '100.433',
            },
            zipcode: 13250,
          },
          140513: {
            name: {
              th: 'บางหลวงโดด',
              en: 'Bang Luang Dot',
            },
            coordinates: {
              lat: '14.419',
              lng: '100.430',
            },
            zipcode: 13250,
          },
          140514: {
            name: {
              th: 'บางหัก',
              en: 'Bang Hak',
            },
            coordinates: {
              lat: '14.436',
              lng: '100.439',
            },
            zipcode: 13250,
          },
          140515: {
            name: {
              th: 'บางชะนี',
              en: 'Bang Chani',
            },
            coordinates: {
              lat: '14.429',
              lng: '100.461',
            },
            zipcode: 13250,
          },
          140516: {
            name: {
              th: 'บ้านกุ่ม',
              en: 'Ban Kum',
            },
            coordinates: {
              lat: '14.436',
              lng: '100.492',
            },
            zipcode: 13250,
          },
        },
      },
      1406: {
        name: {
          th: 'บางปะอิน',
          en: 'Bang Pa-in',
        },
        tambons: {
          140601: {
            name: {
              th: 'บ้านเลน',
              en: 'Ban Len',
            },
            coordinates: {
              lat: '14.241',
              lng: '100.598',
            },
            zipcode: 13160,
          },
          140602: {
            name: {
              th: 'เชียงรากน้อย',
              en: 'Chiang Rak Noi',
            },
            coordinates: {
              lat: '14.160',
              lng: '100.594',
            },
            zipcode: null,
          },
          140603: {
            name: {
              th: 'บ้านโพ',
              en: 'Ban Pho',
            },
            coordinates: {
              lat: '14.272',
              lng: '100.588',
            },
            zipcode: 13160,
          },
          140604: {
            name: {
              th: 'บ้านกรด',
              en: 'Ban Krot',
            },
            coordinates: {
              lat: '14.307',
              lng: '100.604',
            },
            zipcode: 13160,
          },
          140605: {
            name: {
              th: 'บางกระสั้น',
              en: 'Bang Krasan',
            },
            coordinates: {
              lat: '14.189',
              lng: '100.565',
            },
            zipcode: 13160,
          },
          140606: {
            name: {
              th: 'คลองจิก',
              en: 'Khlong Chik',
            },
            coordinates: {
              lat: '14.214',
              lng: '100.597',
            },
            zipcode: 13160,
          },
          140607: {
            name: {
              th: 'บ้านหว้า',
              en: 'Ban Wa',
            },
            coordinates: {
              lat: '14.258',
              lng: '100.616',
            },
            zipcode: 13160,
          },
          140608: {
            name: {
              th: 'วัดยม',
              en: 'Wat Yom',
            },
            coordinates: {
              lat: '14.260',
              lng: '100.568',
            },
            zipcode: null,
          },
          140609: {
            name: {
              th: 'บางประแดง',
              en: 'Bang Pradaeng',
            },
            coordinates: {
              lat: '14.288',
              lng: '100.544',
            },
            zipcode: 13160,
          },
          140610: {
            name: {
              th: 'สามเรือน',
              en: 'Sam Ruean',
            },
            coordinates: {
              lat: '14.292',
              lng: '100.647',
            },
            zipcode: 13160,
          },
          140611: {
            name: {
              th: 'เกาะเกิด',
              en: 'Ko Koet',
            },
            coordinates: {
              lat: '14.206',
              lng: '100.539',
            },
            zipcode: 13160,
          },
          140612: {
            name: {
              th: 'บ้านพลับ',
              en: 'Ban Phlap',
            },
            coordinates: {
              lat: '14.223',
              lng: '100.552',
            },
            zipcode: 13160,
          },
          140613: {
            name: {
              th: 'บ้านแป้ง',
              en: 'Ban Paeng',
            },
            coordinates: {
              lat: '14.239',
              lng: '100.557',
            },
            zipcode: null,
          },
          140614: {
            name: {
              th: 'คุ้งลาน',
              en: 'Khung Lan',
            },
            coordinates: {
              lat: '14.295',
              lng: '100.621',
            },
            zipcode: 13160,
          },
          140615: {
            name: {
              th: 'ตลิ่งชัน',
              en: 'Taling Chan',
            },
            coordinates: {
              lat: '14.250',
              lng: '100.642',
            },
            zipcode: 13160,
          },
          140616: {
            name: {
              th: 'บ้านสร้าง',
              en: 'Ban Sang',
            },
            coordinates: {
              lat: '14.289',
              lng: '100.668',
            },
            zipcode: 13170,
          },
          140618: {
            name: {
              th: 'ขนอนหลวง',
              en: 'Khanon Luang',
            },
            coordinates: {
              lat: '14.266',
              lng: '100.542',
            },
            zipcode: 13160,
          },
        },
      },
      1407: {
        name: {
          th: 'บางปะหัน',
          en: 'Bang Pahan',
        },
        tambons: {
          140701: {
            name: {
              th: 'บางปะหัน',
              en: 'Bang Pahan',
            },
            coordinates: {
              lat: '14.458',
              lng: '100.558',
            },
            zipcode: 13220,
          },
          140702: {
            name: {
              th: 'ขยาย',
              en: 'Khayai',
            },
            coordinates: {
              lat: '14.414',
              lng: '100.564',
            },
            zipcode: 13220,
          },
          140703: {
            name: {
              th: 'บางเดื่อ',
              en: 'Bang Duea',
            },
            coordinates: {
              lat: '14.436',
              lng: '100.576',
            },
            zipcode: 13220,
          },
          140704: {
            name: {
              th: 'เสาธง',
              en: 'Sao Thong',
            },
            coordinates: {
              lat: '14.496',
              lng: '100.549',
            },
            zipcode: 13220,
          },
          140705: {
            name: {
              th: 'ทางกลาง',
              en: 'Thang Klang',
            },
            coordinates: {
              lat: '14.513',
              lng: '100.544',
            },
            zipcode: 13220,
          },
          140706: {
            name: {
              th: 'บางเพลิง',
              en: 'Bang Phloeng',
            },
            coordinates: {
              lat: '14.500',
              lng: '100.577',
            },
            zipcode: 13220,
          },
          140707: {
            name: {
              th: 'หันสัง',
              en: 'Han Sang',
            },
            coordinates: {
              lat: '14.512',
              lng: '100.517',
            },
            zipcode: 13220,
          },
          140708: {
            name: {
              th: 'บางนางร้า',
              en: 'Bang Nang Ra',
            },
            coordinates: {
              lat: '14.474',
              lng: '100.531',
            },
            zipcode: 13220,
          },
          140709: {
            name: {
              th: 'ตานิม',
              en: 'Tanim',
            },
            coordinates: {
              lat: '14.491',
              lng: '100.532',
            },
            zipcode: 13220,
          },
          140710: {
            name: {
              th: 'ทับน้ำ',
              en: 'Thap Nam',
            },
            coordinates: {
              lat: '14.464',
              lng: '100.508',
            },
            zipcode: 13220,
          },
          140711: {
            name: {
              th: 'บ้านม้า',
              en: 'Ban Ma',
            },
            coordinates: {
              lat: '14.492',
              lng: '100.510',
            },
            zipcode: null,
          },
          140712: {
            name: {
              th: 'ขวัญเมือง',
              en: 'Khwan Mueang',
            },
            coordinates: {
              lat: '14.444',
              lng: '100.536',
            },
            zipcode: 13220,
          },
          140713: {
            name: {
              th: 'บ้านลี่',
              en: 'Ban Li',
            },
            coordinates: {
              lat: '14.449',
              lng: '100.521',
            },
            zipcode: 13220,
          },
          140714: {
            name: {
              th: 'โพธิ์สามต้น',
              en: 'Pho Sam Ton',
            },
            coordinates: {
              lat: '14.421',
              lng: '100.547',
            },
            zipcode: 13220,
          },
          140715: {
            name: {
              th: 'พุทเลา',
              en: 'Phut Lao',
            },
            coordinates: {
              lat: '14.419',
              lng: '100.525',
            },
            zipcode: 13220,
          },
          140716: {
            name: {
              th: 'ตาลเอน',
              en: 'Tan En',
            },
            coordinates: {
              lat: '14.524',
              lng: '100.563',
            },
            zipcode: 13220,
          },
          140717: {
            name: {
              th: 'บ้านขล้อ',
              en: 'Ban Khlo',
            },
            coordinates: {
              lat: '14.532',
              lng: '100.586',
            },
            zipcode: 13220,
          },
        },
      },
      1408: {
        name: {
          th: 'ผักไห่',
          en: 'Phak Hai',
        },
        tambons: {
          140801: {
            name: {
              th: 'ผักไห่',
              en: 'Phak Hai',
            },
            coordinates: {
              lat: '14.462',
              lng: '100.379',
            },
            zipcode: 13120,
          },
          140802: {
            name: {
              th: 'อมฤต',
              en: 'Ammarit',
            },
            coordinates: {
              lat: '14.472',
              lng: '100.361',
            },
            zipcode: 13120,
          },
          140803: {
            name: {
              th: 'บ้านแค',
              en: 'Ban Khae',
            },
            coordinates: {
              lat: '14.495',
              lng: '100.405',
            },
            zipcode: 13120,
          },
          140804: {
            name: {
              th: 'ลาดน้ำเค็ม',
              en: 'Lat Nam Khem',
            },
            coordinates: {
              lat: '14.482',
              lng: '100.393',
            },
            zipcode: 13120,
          },
          140805: {
            name: {
              th: 'ตาลาน',
              en: 'Ta Lan',
            },
            coordinates: {
              lat: '14.446',
              lng: '100.387',
            },
            zipcode: 13120,
          },
          140806: {
            name: {
              th: 'ท่าดินแดง',
              en: 'Tha Din Daeng',
            },
            coordinates: {
              lat: '14.416',
              lng: '100.388',
            },
            zipcode: 13120,
          },
          140807: {
            name: {
              th: 'ดอนลาน',
              en: 'Don Lan',
            },
            coordinates: {
              lat: '14.433',
              lng: '100.286',
            },
            zipcode: 13280,
          },
          140808: {
            name: {
              th: 'นาคู',
              en: 'Na Khu',
            },
            coordinates: {
              lat: '14.468',
              lng: '100.272',
            },
            zipcode: 13280,
          },
          140809: {
            name: {
              th: 'กุฎี',
              en: 'Ku Di',
            },
            coordinates: {
              lat: '14.439',
              lng: '100.407',
            },
            zipcode: 13120,
          },
          140810: {
            name: {
              th: 'ลำตะเคียน',
              en: 'Lam Takhian',
            },
            coordinates: {
              lat: '14.419',
              lng: '100.318',
            },
            zipcode: 13280,
          },
          140811: {
            name: {
              th: 'โคกช้าง',
              en: 'Khok Chang',
            },
            coordinates: {
              lat: '14.500',
              lng: '100.388',
            },
            zipcode: null,
          },
          140812: {
            name: {
              th: 'จักราช',
              en: 'Chakkarat',
            },
            coordinates: {
              lat: '14.447',
              lng: '100.340',
            },
            zipcode: 13280,
          },
          140813: {
            name: {
              th: 'หนองน้ำใหญ่',
              en: 'Nong Nam Yai',
            },
            coordinates: {
              lat: '14.474',
              lng: '100.311',
            },
            zipcode: 13280,
          },
          140814: {
            name: {
              th: 'ลาดชิด',
              en: 'Lat Chit',
            },
            coordinates: {
              lat: '14.422',
              lng: '100.358',
            },
            zipcode: 13120,
          },
          140815: {
            name: {
              th: 'หน้าโคก',
              en: 'Na Khok',
            },
            coordinates: {
              lat: '14.490',
              lng: '100.351',
            },
            zipcode: 13120,
          },
          140816: {
            name: {
              th: 'บ้านใหญ่',
              en: 'Ban Yai',
            },
            coordinates: {
              lat: '14.435',
              lng: '100.384',
            },
            zipcode: 13120,
          },
        },
      },
      1409: {
        name: {
          th: 'ภาชี',
          en: 'Phachi',
        },
        tambons: {
          140901: {
            name: {
              th: 'ภาชี',
              en: 'Phachi',
            },
            coordinates: {
              lat: '14.442',
              lng: '100.725',
            },
            zipcode: 13140,
          },
          140902: {
            name: {
              th: 'โคกม่วง',
              en: 'Khok Muang',
            },
            coordinates: {
              lat: '14.428',
              lng: '100.750',
            },
            zipcode: 13140,
          },
          140903: {
            name: {
              th: 'ระโสม',
              en: 'Rasom',
            },
            coordinates: {
              lat: '14.390',
              lng: '100.770',
            },
            zipcode: 13140,
          },
          140904: {
            name: {
              th: 'หนองน้ำใส',
              en: 'Nong Nam Sai',
            },
            coordinates: {
              lat: '14.457',
              lng: '100.751',
            },
            zipcode: 13140,
          },
          140905: {
            name: {
              th: 'ดอนหญ้านาง',
              en: 'Don Ya Nang',
            },
            coordinates: {
              lat: '14.478',
              lng: '100.739',
            },
            zipcode: 13140,
          },
          140906: {
            name: {
              th: 'ไผ่ล้อม',
              en: 'Phai Lom',
            },
            coordinates: {
              lat: '14.474',
              lng: '100.693',
            },
            zipcode: 13140,
          },
          140907: {
            name: {
              th: 'กระจิว',
              en: 'Krachio',
            },
            coordinates: {
              lat: '14.420',
              lng: '100.690',
            },
            zipcode: 13140,
          },
          140908: {
            name: {
              th: 'พระแก้ว',
              en: 'Phra Kaeo',
            },
            coordinates: {
              lat: '14.440',
              lng: '100.675',
            },
            zipcode: 13140,
          },
        },
      },
      1410: {
        name: {
          th: 'ลาดบัวหลวง',
          en: 'Lat Bua Luang',
        },
        tambons: {
          141001: {
            name: {
              th: 'ลาดบัวหลวง',
              en: 'Lat Bua Luang',
            },
            coordinates: {
              lat: '14.193',
              lng: '100.319',
            },
            zipcode: 13230,
          },
          141002: {
            name: {
              th: 'หลักชัย',
              en: 'Lak Chai',
            },
            coordinates: {
              lat: '14.195',
              lng: '100.277',
            },
            zipcode: 13230,
          },
          141003: {
            name: {
              th: 'สามเมือง',
              en: 'Sam Mueang',
            },
            coordinates: {
              lat: '14.153',
              lng: '100.297',
            },
            zipcode: 13230,
          },
          141004: {
            name: {
              th: 'พระยาบันลือ',
              en: 'Phraya Banlue',
            },
            coordinates: {
              lat: '14.161',
              lng: '100.395',
            },
            zipcode: 13230,
          },
          141005: {
            name: {
              th: 'สิงหนาท',
              en: 'Singhanat',
            },
            coordinates: {
              lat: '14.132',
              lng: '100.423',
            },
            zipcode: 13230,
          },
          141006: {
            name: {
              th: 'คู้สลอด',
              en: 'Khu Salot',
            },
            coordinates: {
              lat: '14.196',
              lng: '100.369',
            },
            zipcode: 13230,
          },
          141007: {
            name: {
              th: 'คลองพระยาบันลือ',
              en: 'Khlong Phraya Banlue',
            },
            coordinates: {
              lat: '14.134',
              lng: '100.368',
            },
            zipcode: 13230,
          },
        },
      },
      1411: {
        name: {
          th: 'วังน้อย',
          en: 'Wang Noi',
        },
        tambons: {
          141101: {
            name: {
              th: 'ลำตาเสา',
              en: 'Lam Ta Sao',
            },
            coordinates: {
              lat: '14.275',
              lng: '100.708',
            },
            zipcode: 13170,
          },
          141102: {
            name: {
              th: 'บ่อตาโล่',
              en: 'Bo Ta Lo',
            },
            coordinates: {
              lat: '14.241',
              lng: '100.674',
            },
            zipcode: 13170,
          },
          141103: {
            name: {
              th: 'วังน้อย',
              en: 'Wang Noi',
            },
            coordinates: {
              lat: '14.201',
              lng: '100.699',
            },
            zipcode: 13170,
          },
          141104: {
            name: {
              th: 'ลำไทร',
              en: 'Lam Sai',
            },
            coordinates: {
              lat: '14.200',
              lng: '100.641',
            },
            zipcode: 13170,
          },
          141105: {
            name: {
              th: 'สนับทึบ',
              en: 'Sanap Thuep',
            },
            coordinates: {
              lat: '14.298',
              lng: '100.813',
            },
            zipcode: 13170,
          },
          141106: {
            name: {
              th: 'พยอม',
              en: 'Phayom',
            },
            coordinates: {
              lat: '14.166',
              lng: '100.632',
            },
            zipcode: 13170,
          },
          141107: {
            name: {
              th: 'หันตะเภา',
              en: 'Han Taphao',
            },
            coordinates: {
              lat: '14.304',
              lng: '100.780',
            },
            zipcode: 13170,
          },
          141108: {
            name: {
              th: 'ข้าวงาม',
              en: 'Wang Chula',
            },
            coordinates: {
              lat: '14.229',
              lng: '100.758',
            },
            zipcode: 13170,
          },
          141109: {
            name: {
              th: 'วังจุฬา',
              en: 'Khao Ngam',
            },
            coordinates: {
              lat: '14.249',
              lng: '100.800',
            },
            zipcode: 13170,
          },
          141110: {
            name: {
              th: 'ชะแมบ',
              en: 'Chamaep',
            },
            coordinates: {
              lat: '14.257',
              lng: '100.748',
            },
            zipcode: 13170,
          },
        },
      },
      1412: {
        name: {
          th: 'เสนา',
          en: 'Sena',
        },
        tambons: {
          141201: {
            name: {
              th: 'เสนา',
              en: 'Sena',
            },
            coordinates: {
              lat: '14.328',
              lng: '100.404',
            },
            zipcode: 13110,
          },
          141202: {
            name: {
              th: 'บ้านแพน',
              en: 'Ban Phaen',
            },
            coordinates: {
              lat: '14.342',
              lng: '100.417',
            },
            zipcode: 13110,
          },
          141203: {
            name: {
              th: 'เจ้าเจ็ด',
              en: 'Chao Chet',
            },
            coordinates: {
              lat: '14.303',
              lng: '100.378',
            },
            zipcode: 13110,
          },
          141204: {
            name: {
              th: 'สามกอ',
              en: 'Sam Ko',
            },
            coordinates: {
              lat: '14.306',
              lng: '100.399',
            },
            zipcode: 13110,
          },
          141205: {
            name: {
              th: 'บางนมโค',
              en: 'Bang Nom Kho',
            },
            coordinates: {
              lat: '14.292',
              lng: '100.424',
            },
            zipcode: 13110,
          },
          141206: {
            name: {
              th: 'หัวเวียง',
              en: 'Hua Wiang',
            },
            coordinates: {
              lat: '14.376',
              lng: '100.402',
            },
            zipcode: 13110,
          },
          141207: {
            name: {
              th: 'มารวิชัย',
              en: 'Man Wichai',
            },
            coordinates: {
              lat: '14.240',
              lng: '100.371',
            },
            zipcode: 13110,
          },
          141208: {
            name: {
              th: 'บ้านโพธิ์',
              en: 'Ban Pho',
            },
            coordinates: {
              lat: '14.351',
              lng: '100.394',
            },
            zipcode: 13110,
          },
          141209: {
            name: {
              th: 'รางจรเข้',
              en: 'Rang Chorakhe',
            },
            coordinates: {
              lat: '14.351',
              lng: '100.366',
            },
            zipcode: 13110,
          },
          141210: {
            name: {
              th: 'บ้านกระทุ่ม',
              en: 'Ban Krathum',
            },
            coordinates: {
              lat: '14.390',
              lng: '100.383',
            },
            zipcode: 13110,
          },
          141211: {
            name: {
              th: 'บ้านแถว',
              en: 'Ban Thaeo',
            },
            coordinates: {
              lat: '14.301',
              lng: '100.348',
            },
            zipcode: 13110,
          },
          141212: {
            name: {
              th: 'ชายนา',
              en: 'Chai Na',
            },
            coordinates: {
              lat: '14.258',
              lng: '100.348',
            },
            zipcode: 13110,
          },
          141213: {
            name: {
              th: 'สามตุ่ม',
              en: 'Sam Tum',
            },
            coordinates: {
              lat: '14.240',
              lng: '100.406',
            },
            zipcode: 13110,
          },
          141214: {
            name: {
              th: 'ลาดงา',
              en: 'Lat Nga',
            },
            coordinates: {
              lat: '14.372',
              lng: '100.358',
            },
            zipcode: 13110,
          },
          141215: {
            name: {
              th: 'ดอนทอง',
              en: 'Don Thong',
            },
            coordinates: {
              lat: '14.240',
              lng: '100.306',
            },
            zipcode: 13110,
          },
          141216: {
            name: {
              th: 'บ้านหลวง',
              en: 'Ban Luang',
            },
            coordinates: {
              lat: '14.278',
              lng: '100.401',
            },
            zipcode: 13110,
          },
          141217: {
            name: {
              th: 'เจ้าเสด็จ',
              en: 'Chao Sadet',
            },
            coordinates: {
              lat: '14.331',
              lng: '100.363',
            },
            zipcode: 13110,
          },
        },
      },
      1413: {
        name: {
          th: 'บางซ้าย',
          en: 'Bang Sai',
        },
        tambons: {
          141301: {
            name: {
              th: 'บางซ้าย',
              en: 'Bang Sai',
            },
            coordinates: {
              lat: '14.314',
              lng: '100.286',
            },
            zipcode: 13270,
          },
          141302: {
            name: {
              th: 'แก้วฟ้า',
              en: 'Kaeo Fa',
            },
            coordinates: {
              lat: '14.313',
              lng: '100.326',
            },
            zipcode: 13270,
          },
          141303: {
            name: {
              th: 'เต่าเล่า',
              en: 'Tao Lao',
            },
            coordinates: {
              lat: '14.340',
              lng: '100.319',
            },
            zipcode: 13270,
          },
          141304: {
            name: {
              th: 'ปลายกลัด',
              en: 'Plai Klat',
            },
            coordinates: {
              lat: '14.382',
              lng: '100.315',
            },
            zipcode: 13270,
          },
          141305: {
            name: {
              th: 'เทพมงคล',
              en: 'Thep Mongkhon',
            },
            coordinates: {
              lat: '14.236',
              lng: '100.258',
            },
            zipcode: 13270,
          },
          141306: {
            name: {
              th: 'วังพัฒนา',
              en: 'Wang Phatthana',
            },
            coordinates: {
              lat: '14.272',
              lng: '100.296',
            },
            zipcode: 13270,
          },
        },
      },
      1414: {
        name: {
          th: 'อุทัย',
          en: 'Uthai',
        },
        tambons: {
          141401: {
            name: {
              th: 'คานหาม',
              en: 'Khan Ham',
            },
            coordinates: {
              lat: '14.335',
              lng: '100.641',
            },
            zipcode: 13210,
          },
          141402: {
            name: {
              th: 'บ้านช้าง',
              en: 'Ban Chang',
            },
            coordinates: {
              lat: '14.322',
              lng: '100.680',
            },
            zipcode: 13210,
          },
          141403: {
            name: {
              th: 'สามบัณฑิต',
              en: 'Sam Bandit',
            },
            coordinates: {
              lat: '14.349',
              lng: '100.742',
            },
            zipcode: 13210,
          },
          141404: {
            name: {
              th: 'บ้านหีบ',
              en: 'Ban Hip',
            },
            coordinates: {
              lat: '14.372',
              lng: '100.712',
            },
            zipcode: 13210,
          },
          141405: {
            name: {
              th: 'หนองไม้ซุง',
              en: 'Nong Mai Sung',
            },
            coordinates: {
              lat: '14.376',
              lng: '100.751',
            },
            zipcode: 13210,
          },
          141406: {
            name: {
              th: 'อุทัย',
              en: 'Uthai',
            },
            coordinates: {
              lat: '14.373',
              lng: '100.655',
            },
            zipcode: 13210,
          },
          141407: {
            name: {
              th: 'เสนา',
              en: 'Sena',
            },
            coordinates: {
              lat: '14.392',
              lng: '100.689',
            },
            zipcode: null,
          },
          141408: {
            name: {
              th: 'หนองน้ำส้ม',
              en: 'Nong Nam Som',
            },
            coordinates: {
              lat: '14.320',
              lng: '100.714',
            },
            zipcode: 13210,
          },
          141409: {
            name: {
              th: 'โพสาวหาญ',
              en: 'Pho Sao Han',
            },
            coordinates: {
              lat: '14.332',
              lng: '100.759',
            },
            zipcode: 13210,
          },
          141410: {
            name: {
              th: 'ธนู',
              en: 'Thanu',
            },
            coordinates: {
              lat: '14.355',
              lng: '100.622',
            },
            zipcode: 13210,
          },
          141411: {
            name: {
              th: 'ข้าวเม่า',
              en: 'Khao Mao',
            },
            coordinates: {
              lat: '14.379',
              lng: '100.624',
            },
            zipcode: 13210,
          },
        },
      },
      1415: {
        name: {
          th: 'มหาราช',
          en: 'Maha Rat',
        },
        tambons: {
          141501: {
            name: {
              th: 'หัวไผ่',
              en: 'Hua Phai',
            },
            coordinates: {
              lat: '14.532',
              lng: '100.525',
            },
            zipcode: 13150,
          },
          141502: {
            name: {
              th: 'กะทุ่ม',
              en: 'Kathum',
            },
            coordinates: {
              lat: '14.546',
              lng: '100.556',
            },
            zipcode: 13150,
          },
          141503: {
            name: {
              th: 'มหาราช',
              en: 'Maharat',
            },
            coordinates: {
              lat: '14.552',
              lng: '100.528',
            },
            zipcode: 13150,
          },
          141504: {
            name: {
              th: 'น้ำเต้า',
              en: 'Namtao',
            },
            coordinates: {
              lat: '14.566',
              lng: '100.542',
            },
            zipcode: null,
          },
          141505: {
            name: {
              th: 'บางนา',
              en: 'Bang Na',
            },
            coordinates: {
              lat: '14.576',
              lng: '100.581',
            },
            zipcode: 13150,
          },
          141506: {
            name: {
              th: 'โรงช้าง',
              en: 'Rong Chang',
            },
            coordinates: {
              lat: '14.602',
              lng: '100.563',
            },
            zipcode: 13150,
          },
          141507: {
            name: {
              th: 'เจ้าปลุก',
              en: 'Chao Pluk',
            },
            coordinates: {
              lat: '14.584',
              lng: '100.538',
            },
            zipcode: 13150,
          },
          141508: {
            name: {
              th: 'พิตเพียน',
              en: 'Phit Phian',
            },
            coordinates: {
              lat: '14.608',
              lng: '100.544',
            },
            zipcode: 13150,
          },
          141509: {
            name: {
              th: 'บ้านนา',
              en: 'Ban Na',
            },
            coordinates: {
              lat: '14.606',
              lng: '100.510',
            },
            zipcode: 13150,
          },
          141510: {
            name: {
              th: 'บ้านขวาง',
              en: 'Ban Khwang',
            },
            coordinates: {
              lat: '14.576',
              lng: '100.518',
            },
            zipcode: 13150,
          },
          141511: {
            name: {
              th: 'ท่าตอ',
              en: 'Tha To',
            },
            coordinates: {
              lat: '14.556',
              lng: '100.511',
            },
            zipcode: 13150,
          },
          141512: {
            name: {
              th: 'บ้านใหม่',
              en: 'Ban Mai',
            },
            coordinates: {
              lat: '14.531',
              lng: '100.500',
            },
            zipcode: null,
          },
        },
      },
      1416: {
        name: {
          th: 'บ้านแพรก',
          en: 'Ban Phraek',
        },
        tambons: {
          141601: {
            name: {
              th: 'บ้านแพรก',
              en: 'Ban Phraek',
            },
            coordinates: {
              lat: '14.640',
              lng: '100.585',
            },
            zipcode: 13240,
          },
          141602: {
            name: {
              th: 'บ้านใหม่',
              en: 'Ban Mai',
            },
            coordinates: {
              lat: '14.655',
              lng: '100.586',
            },
            zipcode: null,
          },
          141603: {
            name: {
              th: 'สำพะเนียง',
              en: 'Sam Phaniang',
            },
            coordinates: {
              lat: '14.638',
              lng: '100.567',
            },
            zipcode: 13240,
          },
          141604: {
            name: {
              th: 'คลองน้อย',
              en: 'Khlong Noi',
            },
            coordinates: {
              lat: '14.627',
              lng: '100.553',
            },
            zipcode: 13240,
          },
          141605: {
            name: {
              th: 'สองห้อง',
              en: 'Song Hong',
            },
            coordinates: {
              lat: '14.646',
              lng: '100.519',
            },
            zipcode: 13240,
          },
        },
      },
    },
  },
  15: {
    name: {
      th: 'อ่างทอง',
      en: 'Ang Thong',
    },
    amphoes: {
      1501: {
        name: {
          th: 'เมืองอ่างทอง',
          en: 'Mueang Ang Thong',
        },
        tambons: {
          150101: {
            name: {
              th: 'ตลาดหลวง',
              en: 'Talat Luang',
            },
            coordinates: {
              lat: '14.592',
              lng: '100.453',
            },
            zipcode: 14000,
          },
          150102: {
            name: {
              th: 'บางแก้ว',
              en: 'Bang Kaeo',
            },
            coordinates: {
              lat: '14.588',
              lng: '100.459',
            },
            zipcode: 14000,
          },
          150103: {
            name: {
              th: 'ศาลาแดง',
              en: 'Sala Daeng',
            },
            coordinates: {
              lat: '14.599',
              lng: '100.445',
            },
            zipcode: 14000,
          },
          150104: {
            name: {
              th: 'ป่างิ้ว',
              en: 'Pa Ngio',
            },
            coordinates: {
              lat: '14.612',
              lng: '100.413',
            },
            zipcode: 14000,
          },
          150105: {
            name: {
              th: 'บ้านแห',
              en: 'Ban Hae',
            },
            coordinates: {
              lat: '14.565',
              lng: '100.434',
            },
            zipcode: 14000,
          },
          150106: {
            name: {
              th: 'ตลาดกรวด',
              en: 'Talat Kruat',
            },
            coordinates: {
              lat: '14.619',
              lng: '100.478',
            },
            zipcode: 14000,
          },
          150107: {
            name: {
              th: 'มหาดไทย',
              en: 'Mahatthai',
            },
            coordinates: {
              lat: '14.568',
              lng: '100.400',
            },
            zipcode: 14000,
          },
          150108: {
            name: {
              th: 'บ้านอิฐ',
              en: 'Ban It',
            },
            coordinates: {
              lat: '14.583',
              lng: '100.476',
            },
            zipcode: 14000,
          },
          150109: {
            name: {
              th: 'หัวไผ่',
              en: 'Hua Phai',
            },
            coordinates: {
              lat: '14.553',
              lng: '100.474',
            },
            zipcode: 14000,
          },
          150110: {
            name: {
              th: 'จำปาหล่อ',
              en: 'Champa Lo',
            },
            coordinates: {
              lat: '14.539',
              lng: '100.433',
            },
            zipcode: 14000,
          },
          150111: {
            name: {
              th: 'โพสะ',
              en: 'Pho Sa',
            },
            coordinates: {
              lat: '14.542',
              lng: '100.457',
            },
            zipcode: 14000,
          },
          150112: {
            name: {
              th: 'บ้านรี',
              en: 'Ban Ri',
            },
            coordinates: {
              lat: '14.605',
              lng: '100.485',
            },
            zipcode: 14000,
          },
          150113: {
            name: {
              th: 'คลองวัว',
              en: 'Khlong Wua',
            },
            coordinates: {
              lat: '14.578',
              lng: '100.432',
            },
            zipcode: 14000,
          },
          150114: {
            name: {
              th: 'ย่านซื่อ',
              en: 'Yan Sue',
            },
            coordinates: {
              lat: '14.619',
              lng: '100.447',
            },
            zipcode: 14000,
          },
        },
      },
      1502: {
        name: {
          th: 'ไชโย',
          en: 'Chaiyo',
        },
        tambons: {
          150201: {
            name: {
              th: 'จรเข้ร้อง',
              en: 'Chorakhe Rong',
            },
            coordinates: {
              lat: '14.658',
              lng: '100.482',
            },
            zipcode: 14140,
          },
          150202: {
            name: {
              th: 'ไชยภูมิ',
              en: 'Chaiyaphum',
            },
            coordinates: {
              lat: '14.705',
              lng: '100.465',
            },
            zipcode: 14140,
          },
          150203: {
            name: {
              th: 'ชัยฤทธิ์',
              en: 'Chaiyarit',
            },
            coordinates: {
              lat: '14.637',
              lng: '100.472',
            },
            zipcode: 14140,
          },
          150204: {
            name: {
              th: 'เทวราช',
              en: 'Thewarat',
            },
            coordinates: {
              lat: '14.646',
              lng: '100.449',
            },
            zipcode: 14140,
          },
          150205: {
            name: {
              th: 'ราชสถิตย์',
              en: 'Ratsathit',
            },
            coordinates: {
              lat: '14.672',
              lng: '100.456',
            },
            zipcode: 14140,
          },
          150206: {
            name: {
              th: 'ไชโย',
              en: 'Chaiyo',
            },
            coordinates: {
              lat: '14.707',
              lng: '100.436',
            },
            zipcode: 14140,
          },
          150207: {
            name: {
              th: 'หลักฟ้า',
              en: 'Lak Fa',
            },
            coordinates: {
              lat: '14.684',
              lng: '100.462',
            },
            zipcode: 14140,
          },
          150208: {
            name: {
              th: 'ชะไว',
              en: 'Chawai',
            },
            coordinates: {
              lat: '14.687',
              lng: '100.494',
            },
            zipcode: 14140,
          },
          150209: {
            name: {
              th: 'ตรีณรงค์',
              en: 'Tri Narong',
            },
            coordinates: {
              lat: '14.676',
              lng: '100.498',
            },
            zipcode: 14140,
          },
        },
      },
      1503: {
        name: {
          th: 'ป่าโมก',
          en: 'Pa Mok',
        },
        tambons: {
          150301: {
            name: {
              th: 'บางปลากด',
              en: 'Bang Pla Kot',
            },
            coordinates: {
              lat: '14.509',
              lng: '100.461',
            },
            zipcode: 14130,
          },
          150302: {
            name: {
              th: 'ป่าโมก',
              en: 'Pa Mok',
            },
            coordinates: {
              lat: '14.501',
              lng: '100.437',
            },
            zipcode: 14130,
          },
          150303: {
            name: {
              th: 'สายทอง',
              en: 'Sai Thong',
            },
            coordinates: {
              lat: '14.513',
              lng: '100.484',
            },
            zipcode: 14130,
          },
          150304: {
            name: {
              th: 'โรงช้าง',
              en: 'Rong Chang',
            },
            coordinates: {
              lat: '14.488',
              lng: '100.472',
            },
            zipcode: 14130,
          },
          150305: {
            name: {
              th: 'บางเสด็จ',
              en: 'Bang Sadet',
            },
            coordinates: {
              lat: '14.461',
              lng: '100.473',
            },
            zipcode: 14130,
          },
          150306: {
            name: {
              th: 'นรสิงห์',
              en: 'Norasing',
            },
            coordinates: {
              lat: '14.518',
              lng: '100.431',
            },
            zipcode: 14130,
          },
          150307: {
            name: {
              th: 'เอกราช',
              en: 'Ekkarat',
            },
            coordinates: {
              lat: '14.477',
              lng: '100.426',
            },
            zipcode: 14130,
          },
          150308: {
            name: {
              th: 'โผงเผง',
              en: 'Phong Pheng',
            },
            coordinates: {
              lat: '14.455',
              lng: '100.442',
            },
            zipcode: 14130,
          },
        },
      },
      1504: {
        name: {
          th: 'โพธิ์ทอง',
          en: 'Pho Thong',
        },
        tambons: {
          150401: {
            name: {
              th: 'อ่างแก้ว',
              en: 'Ang Kaeo',
            },
            coordinates: {
              lat: '14.666',
              lng: '100.393',
            },
            zipcode: 14120,
          },
          150402: {
            name: {
              th: 'อินทประมูล',
              en: 'Inthapramun',
            },
            coordinates: {
              lat: '14.651',
              lng: '100.425',
            },
            zipcode: 14120,
          },
          150403: {
            name: {
              th: 'บางพลับ',
              en: 'Bang Phlap',
            },
            coordinates: {
              lat: '14.641',
              lng: '100.406',
            },
            zipcode: 14120,
          },
          150404: {
            name: {
              th: 'หนองแม่ไก่',
              en: 'Nong Mae Kai',
            },
            coordinates: {
              lat: '14.708',
              lng: '100.351',
            },
            zipcode: 14120,
          },
          150405: {
            name: {
              th: 'รำมะสัก',
              en: 'Ram Masak',
            },
            coordinates: {
              lat: '14.679',
              lng: '100.242',
            },
            zipcode: 14120,
          },
          150406: {
            name: {
              th: 'บางระกำ',
              en: 'Bang Rakam',
            },
            coordinates: {
              lat: '14.691',
              lng: '100.428',
            },
            zipcode: 14120,
          },
          150407: {
            name: {
              th: 'โพธิ์รังนก',
              en: 'Pho Rang Nok',
            },
            coordinates: {
              lat: '14.614',
              lng: '100.378',
            },
            zipcode: 14120,
          },
          150408: {
            name: {
              th: 'องครักษ์',
              en: 'Ongkharak',
            },
            coordinates: {
              lat: '14.731',
              lng: '100.398',
            },
            zipcode: 14120,
          },
          150409: {
            name: {
              th: 'โคกพุทรา',
              en: 'Khok Phutsa',
            },
            coordinates: {
              lat: '14.689',
              lng: '100.375',
            },
            zipcode: 14120,
          },
          150410: {
            name: {
              th: 'ยางซ้าย',
              en: 'Yang Chai',
            },
            coordinates: {
              lat: '14.658',
              lng: '100.297',
            },
            zipcode: 14120,
          },
          150411: {
            name: {
              th: 'บ่อแร่',
              en: 'Bo Rae',
            },
            coordinates: {
              lat: '14.637',
              lng: '100.372',
            },
            zipcode: 14120,
          },
          150412: {
            name: {
              th: 'ทางพระ',
              en: 'Thang Phra',
            },
            coordinates: {
              lat: '14.677',
              lng: '100.360',
            },
            zipcode: 14120,
          },
          150413: {
            name: {
              th: 'สามง่าม',
              en: 'Sam Ngam',
            },
            coordinates: {
              lat: '14.638',
              lng: '100.389',
            },
            zipcode: 14120,
          },
          150414: {
            name: {
              th: 'บางเจ้าฉ่า',
              en: 'Bang Chao Cha',
            },
            coordinates: {
              lat: '14.695',
              lng: '100.398',
            },
            zipcode: 14120,
          },
          150415: {
            name: {
              th: 'คำหยาด',
              en: 'Kham Yat',
            },
            coordinates: {
              lat: '14.676',
              lng: '100.323',
            },
            zipcode: 14120,
          },
        },
      },
      1505: {
        name: {
          th: 'แสวงหา',
          en: 'Sawaeng Ha',
        },
        tambons: {
          150501: {
            name: {
              th: 'แสวงหา',
              en: 'Sawaeng Ha',
            },
            coordinates: {
              lat: '14.763',
              lng: '100.310',
            },
            zipcode: 14150,
          },
          150502: {
            name: {
              th: 'ศรีพราน',
              en: 'Si Phran',
            },
            coordinates: {
              lat: '14.725',
              lng: '100.328',
            },
            zipcode: 14150,
          },
          150503: {
            name: {
              th: 'บ้านพราน',
              en: 'Ban Phran',
            },
            coordinates: {
              lat: '14.709',
              lng: '100.306',
            },
            zipcode: 14150,
          },
          150504: {
            name: {
              th: 'วังน้ำเย็น',
              en: 'Wang Nam Yen',
            },
            coordinates: {
              lat: '14.738',
              lng: '100.221',
            },
            zipcode: 14150,
          },
          150505: {
            name: {
              th: 'สีบัวทอง',
              en: 'Si Bua Thong',
            },
            coordinates: {
              lat: '14.769',
              lng: '100.248',
            },
            zipcode: 14150,
          },
          150506: {
            name: {
              th: 'ห้วยไผ่',
              en: 'Huai Phai',
            },
            coordinates: {
              lat: '14.789',
              lng: '100.324',
            },
            zipcode: 14150,
          },
          150507: {
            name: {
              th: 'จำลอง',
              en: 'Chamlong',
            },
            coordinates: {
              lat: '14.741',
              lng: '100.364',
            },
            zipcode: 14150,
          },
        },
      },
      1506: {
        name: {
          th: 'วิเศษชัยชาญ',
          en: 'Wiset Chai Chan',
        },
        tambons: {
          150601: {
            name: {
              th: 'ไผ่จำศีล',
              en: 'Phai Chamsin',
            },
            coordinates: {
              lat: '14.590',
              lng: '100.366',
            },
            zipcode: null,
          },
          150602: {
            name: {
              th: 'ศาลเจ้าโรงทอง',
              en: 'San Chao Rong Thong',
            },
            coordinates: {
              lat: '14.597',
              lng: '100.340',
            },
            zipcode: 14110,
          },
          150603: {
            name: {
              th: 'ไผ่ดำพัฒนา',
              en: 'Phai Dam Patthana',
            },
            coordinates: {
              lat: '14.529',
              lng: '100.392',
            },
            zipcode: 14110,
          },
          150604: {
            name: {
              th: 'สาวร้องไห้',
              en: 'Sao Rong Hai',
            },
            coordinates: {
              lat: '14.546',
              lng: '100.223',
            },
            zipcode: 14110,
          },
          150605: {
            name: {
              th: 'ท่าช้าง',
              en: 'Tha Chang',
            },
            coordinates: {
              lat: '14.562',
              lng: '100.333',
            },
            zipcode: 14110,
          },
          150606: {
            name: {
              th: 'ยี่ล้น',
              en: 'Yi Lon',
            },
            coordinates: {
              lat: '14.579',
              lng: '100.301',
            },
            zipcode: 14110,
          },
          150607: {
            name: {
              th: 'บางจัก',
              en: 'Bang Chak',
            },
            coordinates: {
              lat: '14.502',
              lng: '100.330',
            },
            zipcode: 14110,
          },
          150608: {
            name: {
              th: 'ห้วยคันแหลน',
              en: 'Huai Khan Laen',
            },
            coordinates: {
              lat: '14.555',
              lng: '100.294',
            },
            zipcode: 14110,
          },
          150609: {
            name: {
              th: 'คลองขนาก',
              en: 'Khlong Khanak',
            },
            coordinates: {
              lat: '14.530',
              lng: '100.337',
            },
            zipcode: 14110,
          },
          150610: {
            name: {
              th: 'ไผ่วง',
              en: 'Phai Wong',
            },
            coordinates: {
              lat: '14.505',
              lng: '100.251',
            },
            zipcode: 14110,
          },
          150611: {
            name: {
              th: 'สี่ร้อย',
              en: 'Si Roi',
            },
            coordinates: {
              lat: '14.550',
              lng: '100.370',
            },
            zipcode: 14110,
          },
          150612: {
            name: {
              th: 'ม่วงเตี้ย',
              en: 'Muang Tia',
            },
            coordinates: {
              lat: '14.636',
              lng: '100.323',
            },
            zipcode: 14110,
          },
          150613: {
            name: {
              th: 'หัวตะพาน',
              en: 'Hua Taphan',
            },
            coordinates: {
              lat: '14.583',
              lng: '100.387',
            },
            zipcode: 14110,
          },
          150614: {
            name: {
              th: 'หลักแก้ว',
              en: 'Lak Kaeo',
            },
            coordinates: {
              lat: '14.517',
              lng: '100.289',
            },
            zipcode: 14110,
          },
          150615: {
            name: {
              th: 'ตลาดใหม่',
              en: 'Talat Mai',
            },
            coordinates: {
              lat: '14.542',
              lng: '100.315',
            },
            zipcode: 14110,
          },
        },
      },
      1507: {
        name: {
          th: 'สามโก้',
          en: 'Samko',
        },
        tambons: {
          150701: {
            name: {
              th: 'สามโก้',
              en: 'Sam Ko',
            },
            coordinates: {
              lat: '14.606',
              lng: '100.252',
            },
            zipcode: 14160,
          },
          150702: {
            name: {
              th: 'ราษฎรพัฒนา',
              en: 'Ratsadon Phatthana',
            },
            coordinates: {
              lat: '14.605',
              lng: '100.293',
            },
            zipcode: 14160,
          },
          150703: {
            name: {
              th: 'อบทม',
              en: 'Optom',
            },
            coordinates: {
              lat: '14.581',
              lng: '100.246',
            },
            zipcode: 14160,
          },
          150704: {
            name: {
              th: 'โพธิ์ม่วงพันธ์',
              en: 'Pho Muang Phan',
            },
            coordinates: {
              lat: '14.561',
              lng: '100.254',
            },
            zipcode: 14160,
          },
          150705: {
            name: {
              th: 'มงคลธรรมนิมิต',
              en: 'Mongkhontham Nimit',
            },
            coordinates: {
              lat: '14.640',
              lng: '100.260',
            },
            zipcode: 14160,
          },
        },
      },
    },
  },
  16: {
    name: {
      th: 'ลพบุรี',
      en: 'Lop Buri',
    },
    amphoes: {
      1601: {
        name: {
          th: 'เมืองลพบุรี',
          en: 'Mueang Lop Buri',
        },
        tambons: {
          160101: {
            name: {
              th: 'ทะเลชุบศร',
              en: 'Thale Chup Son',
            },
            coordinates: {
              lat: '14.804',
              lng: '100.634',
            },
            zipcode: 15000,
          },
          160102: {
            name: {
              th: 'ท่าหิน',
              en: 'Tha Hin',
            },
            coordinates: {
              lat: '14.802',
              lng: '100.611',
            },
            zipcode: 15000,
          },
          160103: {
            name: {
              th: 'กกโก',
              en: 'Kok Ko',
            },
            coordinates: {
              lat: '14.767',
              lng: '100.684',
            },
            zipcode: 15000,
          },
          160104: {
            name: {
              th: 'โก่งธนู',
              en: 'Kong Thanu',
            },
            coordinates: {
              lat: '14.674',
              lng: '100.597',
            },
            zipcode: 13240,
          },
          160105: {
            name: {
              th: 'เขาพระงาม',
              en: 'Khao Phra Ngam',
            },
            coordinates: {
              lat: '14.901',
              lng: '100.670',
            },
            zipcode: 15160,
          },
          160106: {
            name: {
              th: 'เขาสามยอด',
              en: 'Khao Sam Yot',
            },
            coordinates: {
              lat: '14.830',
              lng: '100.671',
            },
            zipcode: 15000,
          },
          160107: {
            name: {
              th: 'โคกกระเทียม',
              en: 'Khok Kathiam',
            },
            coordinates: {
              lat: '14.890',
              lng: '100.580',
            },
            zipcode: 15160,
          },
          160108: {
            name: {
              th: 'โคกลำพาน',
              en: 'Khok Lamphan',
            },
            coordinates: {
              lat: '14.740',
              lng: '100.640',
            },
            zipcode: 15000,
          },
          160109: {
            name: {
              th: 'โคกตูม',
              en: 'Khok Tum',
            },
            coordinates: {
              lat: '14.866',
              lng: '100.817',
            },
            zipcode: 15210,
          },
          160110: {
            name: {
              th: 'งิ้วราย',
              en: 'Ngio Rai',
            },
            coordinates: {
              lat: '14.704',
              lng: '100.592',
            },
            zipcode: 15000,
          },
          160111: {
            name: {
              th: 'ดอนโพธิ์',
              en: 'Don Pho',
            },
            coordinates: {
              lat: '14.691',
              lng: '100.627',
            },
            zipcode: 15000,
          },
          160112: {
            name: {
              th: 'ตะลุง',
              en: 'Talung',
            },
            coordinates: {
              lat: '14.737',
              lng: '100.601',
            },
            zipcode: 15000,
          },
          160114: {
            name: {
              th: 'ท่าแค',
              en: 'Tha Khae',
            },
            coordinates: {
              lat: '14.865',
              lng: '100.619',
            },
            zipcode: 15000,
          },
          160115: {
            name: {
              th: 'ท่าศาลา',
              en: 'Tha Sala',
            },
            coordinates: {
              lat: '14.792',
              lng: '100.671',
            },
            zipcode: 15000,
          },
          160116: {
            name: {
              th: 'นิคมสร้างตนเอง',
              en: 'Nikhom Sang Ton-Eng',
            },
            coordinates: {
              lat: '14.824',
              lng: '100.732',
            },
            zipcode: 15000,
          },
          160117: {
            name: {
              th: 'บางขันหมาก',
              en: 'Bang Khan Mak',
            },
            coordinates: {
              lat: '14.820',
              lng: '100.583',
            },
            zipcode: 15000,
          },
          160118: {
            name: {
              th: 'บ้านข่อย',
              en: 'Ban Khoi',
            },
            coordinates: {
              lat: '14.683',
              lng: '100.529',
            },
            zipcode: 15000,
          },
          160119: {
            name: {
              th: 'ท้ายตลาด',
              en: 'Thai Talat',
            },
            coordinates: {
              lat: '14.725',
              lng: '100.563',
            },
            zipcode: 15000,
          },
          160120: {
            name: {
              th: 'ป่าตาล',
              en: 'Pa Tan',
            },
            coordinates: {
              lat: '14.778',
              lng: '100.640',
            },
            zipcode: 15000,
          },
          160121: {
            name: {
              th: 'พรหมมาสตร์',
              en: 'Phrommat',
            },
            coordinates: {
              lat: '14.820',
              lng: '100.603',
            },
            zipcode: 15000,
          },
          160122: {
            name: {
              th: 'โพธิ์เก้าต้น',
              en: 'Pho Kao Ton',
            },
            coordinates: {
              lat: '14.775',
              lng: '100.599',
            },
            zipcode: 15000,
          },
          160123: {
            name: {
              th: 'โพธิ์ตรุ',
              en: 'Pho Tru',
            },
            coordinates: {
              lat: '14.725',
              lng: '100.532',
            },
            zipcode: 15000,
          },
          160124: {
            name: {
              th: 'สี่คลอง',
              en: 'Si Khlong',
            },
            coordinates: {
              lat: '14.681',
              lng: '100.563',
            },
            zipcode: 15000,
          },
          160125: {
            name: {
              th: 'ถนนใหญ่',
              en: 'Thanon Yai',
            },
            coordinates: {
              lat: '14.831',
              lng: '100.629',
            },
            zipcode: 15000,
          },
        },
      },
      1602: {
        name: {
          th: 'พัฒนานิคม',
          en: 'Phatthana Nikhom',
        },
        tambons: {
          160201: {
            name: {
              th: 'พัฒนานิคม',
              en: 'Phatthana Nikhom',
            },
            coordinates: {
              lat: '14.896',
              lng: '100.966',
            },
            zipcode: 15140,
          },
          160202: {
            name: {
              th: 'ช่องสาริกา',
              en: 'Chong Sarika',
            },
            coordinates: {
              lat: '14.802',
              lng: '100.909',
            },
            zipcode: 15220,
          },
          160203: {
            name: {
              th: 'มะนาวหวาน',
              en: 'Manao Wan',
            },
            coordinates: {
              lat: '14.934',
              lng: '101.093',
            },
            zipcode: 15140,
          },
          160204: {
            name: {
              th: 'ดีลัง',
              en: 'Di Lang',
            },
            coordinates: {
              lat: '14.940',
              lng: '100.852',
            },
            zipcode: 15220,
          },
          160205: {
            name: {
              th: 'โคกสลุง',
              en: 'Khok Salung',
            },
            coordinates: {
              lat: '15.010',
              lng: '100.969',
            },
            zipcode: 15140,
          },
          160206: {
            name: {
              th: 'ชอนน้อย',
              en: 'Chon Noi',
            },
            coordinates: {
              lat: '14.795',
              lng: '100.985',
            },
            zipcode: 15140,
          },
          160207: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '14.851',
              lng: '101.054',
            },
            zipcode: 15140,
          },
          160208: {
            name: {
              th: 'ห้วยขุนราม',
              en: 'Huai Khun Ram',
            },
            coordinates: {
              lat: '14.951',
              lng: '101.205',
            },
            zipcode: 18220,
          },
          160209: {
            name: {
              th: 'น้ำสุด',
              en: 'Nam Sut',
            },
            coordinates: {
              lat: '14.952',
              lng: '101.146',
            },
            zipcode: 15140,
          },
        },
      },
      1603: {
        name: {
          th: 'โคกสำโรง',
          en: 'Khok Samrong',
        },
        tambons: {
          160301: {
            name: {
              th: 'โคกสำโรง',
              en: 'Khok Samrong',
            },
            coordinates: {
              lat: '15.071',
              lng: '100.713',
            },
            zipcode: 15120,
          },
          160302: {
            name: {
              th: 'เกาะแก้ว',
              en: 'Ko Kaeo',
            },
            coordinates: {
              lat: '15.136',
              lng: '100.736',
            },
            zipcode: 15120,
          },
          160303: {
            name: {
              th: 'ถลุงเหล็ก',
              en: 'Thalung Lek',
            },
            coordinates: {
              lat: '15.064',
              lng: '100.680',
            },
            zipcode: 15120,
          },
          160304: {
            name: {
              th: 'หลุมข้าว',
              en: 'Lum Khao',
            },
            coordinates: {
              lat: '15.024',
              lng: '100.642',
            },
            zipcode: 15120,
          },
          160305: {
            name: {
              th: 'ห้วยโป่ง',
              en: 'Huai Pong',
            },
            coordinates: {
              lat: '14.981',
              lng: '100.677',
            },
            zipcode: 15120,
          },
          160306: {
            name: {
              th: 'คลองเกตุ',
              en: 'Khlong Ket',
            },
            coordinates: {
              lat: '14.967',
              lng: '100.802',
            },
            zipcode: 15120,
          },
          160307: {
            name: {
              th: 'สะแกราบ',
              en: 'Sakae Rap',
            },
            coordinates: {
              lat: '15.120',
              lng: '100.797',
            },
            zipcode: 15120,
          },
          160308: {
            name: {
              th: 'เพนียด',
              en: 'Phaniat',
            },
            coordinates: {
              lat: '15.036',
              lng: '100.812',
            },
            zipcode: 15120,
          },
          160309: {
            name: {
              th: 'วังเพลิง',
              en: 'Wang Phloeng',
            },
            coordinates: {
              lat: '15.062',
              lng: '100.841',
            },
            zipcode: 15120,
          },
          160310: {
            name: {
              th: 'ดงมะรุม',
              en: 'Dong Marum',
            },
            coordinates: {
              lat: '15.127',
              lng: '100.877',
            },
            zipcode: 15120,
          },
          160318: {
            name: {
              th: 'วังขอนขว้าง',
              en: 'Wang Khon Khwang',
            },
            coordinates: {
              lat: '15.023',
              lng: '100.697',
            },
            zipcode: 15120,
          },
          160320: {
            name: {
              th: 'วังจั่น',
              en: 'Wang Chan',
            },
            coordinates: {
              lat: '15.102',
              lng: '100.673',
            },
            zipcode: 15120,
          },
          160322: {
            name: {
              th: 'หนองแขม',
              en: 'Nong Khaem',
            },
            coordinates: {
              lat: '15.143',
              lng: '100.685',
            },
            zipcode: 15120,
          },
        },
      },
      1604: {
        name: {
          th: 'ชัยบาดาล',
          en: 'Chai Badan',
        },
        tambons: {
          160401: {
            name: {
              th: 'ลำนารายณ์',
              en: 'Lam Narai',
            },
            coordinates: {
              lat: '15.214',
              lng: '101.146',
            },
            zipcode: 15130,
          },
          160402: {
            name: {
              th: 'ชัยนารายณ์',
              en: 'Chai Narai',
            },
            coordinates: {
              lat: '15.195',
              lng: '101.037',
            },
            zipcode: 15130,
          },
          160403: {
            name: {
              th: 'ศิลาทิพย์',
              en: 'Sila Thip',
            },
            coordinates: {
              lat: '15.314',
              lng: '101.087',
            },
            zipcode: 15130,
          },
          160404: {
            name: {
              th: 'ห้วยหิน',
              en: 'Huai Hin',
            },
            coordinates: {
              lat: '15.154',
              lng: '101.044',
            },
            zipcode: 15130,
          },
          160405: {
            name: {
              th: 'ม่วงค่อม',
              en: 'Muang Khom',
            },
            coordinates: {
              lat: '15.078',
              lng: '101.024',
            },
            zipcode: 15230,
          },
          160406: {
            name: {
              th: 'บัวชุม',
              en: 'Bua Chum',
            },
            coordinates: {
              lat: '15.176',
              lng: '101.238',
            },
            zipcode: 15130,
          },
          160407: {
            name: {
              th: 'ท่าดินดำ',
              en: 'Tha Din Dam',
            },
            coordinates: {
              lat: '15.150',
              lng: '101.134',
            },
            zipcode: 15130,
          },
          160408: {
            name: {
              th: 'มะกอกหวาน',
              en: 'Makok Wan',
            },
            coordinates: {
              lat: '15.059',
              lng: '101.077',
            },
            zipcode: 15230,
          },
          160409: {
            name: {
              th: 'ซับตะเคียน',
              en: 'Sap Takhian',
            },
            coordinates: {
              lat: '15.112',
              lng: '101.327',
            },
            zipcode: 15130,
          },
          160410: {
            name: {
              th: 'นาโสม',
              en: 'Na Som',
            },
            coordinates: {
              lat: '15.245',
              lng: '101.282',
            },
            zipcode: 15190,
          },
          160411: {
            name: {
              th: 'หนองยายโต๊ะ',
              en: 'Nong Yai To',
            },
            coordinates: {
              lat: '15.220',
              lng: '101.264',
            },
            zipcode: 15130,
          },
          160412: {
            name: {
              th: 'เกาะรัง',
              en: 'Ko Rang',
            },
            coordinates: {
              lat: '15.318',
              lng: '101.251',
            },
            zipcode: 15130,
          },
          160414: {
            name: {
              th: 'ท่ามะนาว',
              en: 'Tha Manao',
            },
            coordinates: {
              lat: '15.187',
              lng: '101.162',
            },
            zipcode: 15130,
          },
          160417: {
            name: {
              th: 'นิคมลำนารายณ์',
              en: 'Nikhom Lam Narai',
            },
            coordinates: {
              lat: '15.264',
              lng: '101.150',
            },
            zipcode: 15130,
          },
          160418: {
            name: {
              th: 'ชัยบาดาล',
              en: 'Chai Badan',
            },
            coordinates: {
              lat: '15.095',
              lng: '101.058',
            },
            zipcode: 15230,
          },
          160419: {
            name: {
              th: 'บ้านใหม่สามัคคี',
              en: 'Ban Mai Samakkhi',
            },
            coordinates: {
              lat: '15.258',
              lng: '101.022',
            },
            zipcode: 15130,
          },
          160422: {
            name: {
              th: 'เขาแหลม',
              en: 'Khao Laem',
            },
            coordinates: {
              lat: '15.126',
              lng: '100.964',
            },
            zipcode: 15130,
          },
        },
      },
      1605: {
        name: {
          th: 'ท่าวุ้ง',
          en: 'Tha Wung',
        },
        tambons: {
          160501: {
            name: {
              th: 'ท่าวุ้ง',
              en: 'Tha Wung',
            },
            coordinates: {
              lat: '14.820',
              lng: '100.496',
            },
            zipcode: 15150,
          },
          160502: {
            name: {
              th: 'บางคู้',
              en: 'Bang Khu',
            },
            coordinates: {
              lat: '14.804',
              lng: '100.529',
            },
            zipcode: 15150,
          },
          160503: {
            name: {
              th: 'โพตลาดแก้ว',
              en: 'Pho Talat Kaeo',
            },
            coordinates: {
              lat: '14.795',
              lng: '100.558',
            },
            zipcode: 15150,
          },
          160504: {
            name: {
              th: 'บางลี่',
              en: 'Bang Li',
            },
            coordinates: {
              lat: '14.849',
              lng: '100.526',
            },
            zipcode: 15150,
          },
          160505: {
            name: {
              th: 'บางงา',
              en: 'Bang Nga',
            },
            coordinates: {
              lat: '14.850',
              lng: '100.492',
            },
            zipcode: 15150,
          },
          160506: {
            name: {
              th: 'โคกสลุด',
              en: 'Khok Salut',
            },
            coordinates: {
              lat: '14.881',
              lng: '100.482',
            },
            zipcode: 15150,
          },
          160507: {
            name: {
              th: 'เขาสมอคอน',
              en: 'Khao Samo Khon',
            },
            coordinates: {
              lat: '14.906',
              lng: '100.447',
            },
            zipcode: 15180,
          },
          160508: {
            name: {
              th: 'หัวสำโรง',
              en: 'Hua Samrong',
            },
            coordinates: {
              lat: '14.776',
              lng: '100.494',
            },
            zipcode: 15150,
          },
          160509: {
            name: {
              th: 'ลาดสาลี',
              en: 'Lat Sali',
            },
            coordinates: {
              lat: '14.723',
              lng: '100.487',
            },
            zipcode: 15150,
          },
          160511: {
            name: {
              th: 'มุจลินท์',
              en: 'Mutchalin',
            },
            coordinates: {
              lat: '14.876',
              lng: '100.509',
            },
            zipcode: 15150,
          },
        },
      },
      1606: {
        name: {
          th: 'บ้านหมี่',
          en: 'Ban Mi',
        },
        tambons: {
          160601: {
            name: {
              th: 'ไผ่ใหญ่',
              en: 'Phai Yai',
            },
            coordinates: {
              lat: '15.089',
              lng: '100.459',
            },
            zipcode: 15110,
          },
          160602: {
            name: {
              th: 'บ้านทราย',
              en: 'Ban Sai',
            },
            coordinates: {
              lat: '15.034',
              lng: '100.582',
            },
            zipcode: 15110,
          },
          160603: {
            name: {
              th: 'บ้านกล้วย',
              en: 'Ban Kluai',
            },
            coordinates: {
              lat: '15.016',
              lng: '100.578',
            },
            zipcode: 15110,
          },
          160604: {
            name: {
              th: 'ดงพลับ',
              en: 'Dong Phlap',
            },
            coordinates: {
              lat: '15.059',
              lng: '100.630',
            },
            zipcode: 15110,
          },
          160605: {
            name: {
              th: 'บ้านชี',
              en: 'Ban Chi',
            },
            coordinates: {
              lat: '14.947',
              lng: '100.500',
            },
            zipcode: 15180,
          },
          160606: {
            name: {
              th: 'พุคา',
              en: 'Phu Kha',
            },
            coordinates: {
              lat: '14.953',
              lng: '100.598',
            },
            zipcode: 15110,
          },
          160607: {
            name: {
              th: 'หินปัก',
              en: 'Hin Pak',
            },
            coordinates: {
              lat: '15.048',
              lng: '100.592',
            },
            zipcode: 15110,
          },
          160608: {
            name: {
              th: 'บางพึ่ง',
              en: 'Bang Phueng',
            },
            coordinates: {
              lat: '14.994',
              lng: '100.496',
            },
            zipcode: 15110,
          },
          160609: {
            name: {
              th: 'หนองทรายขาว',
              en: 'Nong Sai Khao',
            },
            coordinates: {
              lat: '14.996',
              lng: '100.597',
            },
            zipcode: 15110,
          },
          160610: {
            name: {
              th: 'บางกะพี้',
              en: 'Bang Kaphi',
            },
            coordinates: {
              lat: '15.073',
              lng: '100.613',
            },
            zipcode: 15110,
          },
          160611: {
            name: {
              th: 'หนองเต่า',
              en: 'Nong Tao',
            },
            coordinates: {
              lat: '14.963',
              lng: '100.555',
            },
            zipcode: 15110,
          },
          160612: {
            name: {
              th: 'โพนทอง',
              en: 'Phon Thong',
            },
            coordinates: {
              lat: '15.040',
              lng: '100.559',
            },
            zipcode: 15110,
          },
          160613: {
            name: {
              th: 'บางขาม',
              en: 'Bang Kham',
            },
            coordinates: {
              lat: '14.953',
              lng: '100.457',
            },
            zipcode: 15180,
          },
          160614: {
            name: {
              th: 'ดอนดึง',
              en: 'Don Dueng',
            },
            coordinates: {
              lat: '15.146',
              lng: '100.612',
            },
            zipcode: 15110,
          },
          160615: {
            name: {
              th: 'ชอนม่วง',
              en: 'Chon Muang',
            },
            coordinates: {
              lat: '15.210',
              lng: '100.610',
            },
            zipcode: 15110,
          },
          160616: {
            name: {
              th: 'หนองกระเบียน',
              en: 'Nong Krabian',
            },
            coordinates: {
              lat: '15.148',
              lng: '100.534',
            },
            zipcode: 15110,
          },
          160617: {
            name: {
              th: 'สายห้วยแก้ว',
              en: 'Sai Huai Kaeo',
            },
            coordinates: {
              lat: '15.095',
              lng: '100.509',
            },
            zipcode: 15110,
          },
          160618: {
            name: {
              th: 'มหาสอน',
              en: 'Mahason',
            },
            coordinates: {
              lat: '15.021',
              lng: '100.484',
            },
            zipcode: 15110,
          },
          160621: {
            name: {
              th: 'หนองเมือง',
              en: 'Nong Mueang',
            },
            coordinates: {
              lat: '15.124',
              lng: '100.569',
            },
            zipcode: 15110,
          },
          160622: {
            name: {
              th: 'สนามแจง',
              en: 'Sanam Chaeng',
            },
            coordinates: {
              lat: '15.008',
              lng: '100.538',
            },
            zipcode: 15110,
          },
        },
      },
      1607: {
        name: {
          th: 'ท่าหลวง',
          en: 'Tha Luang',
        },
        tambons: {
          160701: {
            name: {
              th: 'ท่าหลวง',
              en: 'Tha Luang',
            },
            coordinates: {
              lat: '15.071',
              lng: '101.117',
            },
            zipcode: null,
          },
          160702: {
            name: {
              th: 'แก่งผักกูด',
              en: 'Kaeng Phak Kut',
            },
            coordinates: {
              lat: '15.014',
              lng: '101.085',
            },
            zipcode: null,
          },
          160703: {
            name: {
              th: 'ซับจำปา',
              en: 'Sap Champa',
            },
            coordinates: {
              lat: '15.026',
              lng: '101.228',
            },
            zipcode: null,
          },
          160704: {
            name: {
              th: 'หนองผักแว่น',
              en: 'Nong Phak Waen',
            },
            coordinates: {
              lat: '15.119',
              lng: '101.216',
            },
            zipcode: null,
          },
          160705: {
            name: {
              th: 'ทะเลวังวัด',
              en: 'Thale Wang Wat',
            },
            coordinates: {
              lat: '15.023',
              lng: '101.184',
            },
            zipcode: 15230,
          },
          160706: {
            name: {
              th: 'หัวลำ',
              en: 'Hua Lam',
            },
            coordinates: {
              lat: '15.029',
              lng: '101.292',
            },
            zipcode: 15230,
          },
        },
      },
      1608: {
        name: {
          th: 'สระโบสถ์',
          en: 'Sa Bot',
        },
        tambons: {
          160801: {
            name: {
              th: 'สระโบสถ์',
              en: 'Sa Bot',
            },
            coordinates: {
              lat: '15.224',
              lng: '100.846',
            },
            zipcode: null,
          },
          160802: {
            name: {
              th: 'มหาโพธิ',
              en: 'Mahapho',
            },
            coordinates: {
              lat: '15.289',
              lng: '100.861',
            },
            zipcode: 15240,
          },
          160803: {
            name: {
              th: 'ทุ่งท่าช้าง',
              en: 'Thung Tha Chang',
            },
            coordinates: {
              lat: '15.169',
              lng: '100.817',
            },
            zipcode: null,
          },
          160804: {
            name: {
              th: 'ห้วยใหญ่',
              en: 'Huai Yai',
            },
            coordinates: {
              lat: '15.169',
              lng: '100.793',
            },
            zipcode: 15240,
          },
          160805: {
            name: {
              th: 'นิยมชัย',
              en: 'Niyom Chai',
            },
            coordinates: {
              lat: '15.183',
              lng: '100.904',
            },
            zipcode: 15240,
          },
        },
      },
      1609: {
        name: {
          th: 'โคกเจริญ',
          en: 'Khok Charoen',
        },
        tambons: {
          160901: {
            name: {
              th: 'โคกเจริญ',
              en: 'Khok Charoen',
            },
            coordinates: {
              lat: '15.408',
              lng: '100.777',
            },
            zipcode: null,
          },
          160902: {
            name: {
              th: 'ยางราก',
              en: 'Yang Rak',
            },
            coordinates: {
              lat: '15.366',
              lng: '100.935',
            },
            zipcode: null,
          },
          160903: {
            name: {
              th: 'หนองมะค่า',
              en: 'Nong Makha',
            },
            coordinates: {
              lat: '15.515',
              lng: '100.839',
            },
            zipcode: null,
          },
          160904: {
            name: {
              th: 'วังทอง',
              en: 'Wang Thong',
            },
            coordinates: {
              lat: '15.469',
              lng: '100.811',
            },
            zipcode: null,
          },
          160905: {
            name: {
              th: 'โคกแสมสาร',
              en: 'Khok Samae San',
            },
            coordinates: {
              lat: '15.344',
              lng: '100.850',
            },
            zipcode: 15250,
          },
        },
      },
      1610: {
        name: {
          th: 'ลำสนธิ',
          en: 'Lam Sonthi',
        },
        tambons: {
          161001: {
            name: {
              th: 'ลำสนธิ',
              en: 'Lam Sonthi',
            },
            coordinates: {
              lat: '15.341',
              lng: '101.362',
            },
            zipcode: null,
          },
          161002: {
            name: {
              th: 'ซับสมบูรณ์',
              en: 'Sap Sombun',
            },
            coordinates: {
              lat: '15.193',
              lng: '101.354',
            },
            zipcode: null,
          },
          161003: {
            name: {
              th: 'หนองรี',
              en: 'Nong Ri',
            },
            coordinates: {
              lat: '15.282',
              lng: '101.319',
            },
            zipcode: null,
          },
          161004: {
            name: {
              th: 'กุดตาเพชร',
              en: 'Kut Ta Phet',
            },
            coordinates: {
              lat: '15.523',
              lng: '101.346',
            },
            zipcode: null,
          },
          161005: {
            name: {
              th: 'เขารวก',
              en: 'Khao Ruak',
            },
            coordinates: {
              lat: '15.374',
              lng: '101.330',
            },
            zipcode: null,
          },
          161006: {
            name: {
              th: 'เขาน้อย',
              en: 'Khao Noi',
            },
            coordinates: {
              lat: '15.112',
              lng: '101.387',
            },
            zipcode: 15130,
          },
        },
      },
      1611: {
        name: {
          th: 'หนองม่วง',
          en: 'Nong Muang',
        },
        tambons: {
          161101: {
            name: {
              th: 'หนองม่วง',
              en: 'Nong Muang',
            },
            coordinates: {
              lat: '15.230',
              lng: '100.664',
            },
            zipcode: null,
          },
          161102: {
            name: {
              th: 'บ่อทอง',
              en: 'Bo Thong',
            },
            coordinates: {
              lat: '15.293',
              lng: '100.652',
            },
            zipcode: null,
          },
          161103: {
            name: {
              th: 'ดงดินแดง',
              en: 'Dong Din Daeng',
            },
            coordinates: {
              lat: '15.385',
              lng: '100.679',
            },
            zipcode: null,
          },
          161104: {
            name: {
              th: 'ชอนสมบูรณ์',
              en: 'Chon Sombun',
            },
            coordinates: {
              lat: '15.288',
              lng: '100.763',
            },
            zipcode: null,
          },
          161105: {
            name: {
              th: 'ยางโทน',
              en: 'Yang Thon',
            },
            coordinates: {
              lat: '15.248',
              lng: '100.702',
            },
            zipcode: null,
          },
          161106: {
            name: {
              th: 'ชอนสารเดช',
              en: 'Chon Saradet',
            },
            coordinates: {
              lat: '15.195',
              lng: '100.709',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  17: {
    name: {
      th: 'สิงห์บุรี',
      en: 'Sing Buri',
    },
    amphoes: {
      1701: {
        name: {
          th: 'เมืองสิงห์บุรี',
          en: 'Mueang Sing Buri',
        },
        tambons: {
          170101: {
            name: {
              th: 'บางพุทรา',
              en: 'Bang Phutsa',
            },
            coordinates: {
              lat: '14.890',
              lng: '100.404',
            },
            zipcode: 16000,
          },
          170102: {
            name: {
              th: 'บางมัญ',
              en: 'Bang Man',
            },
            coordinates: {
              lat: '14.916',
              lng: '100.400',
            },
            zipcode: 16000,
          },
          170103: {
            name: {
              th: 'โพกรวม',
              en: 'Phok Ruam',
            },
            coordinates: {
              lat: '14.944',
              lng: '100.394',
            },
            zipcode: 16000,
          },
          170104: {
            name: {
              th: 'ม่วงหมู่',
              en: 'Muang Mu',
            },
            coordinates: {
              lat: '14.869',
              lng: '100.432',
            },
            zipcode: 16000,
          },
          170105: {
            name: {
              th: 'หัวไผ่',
              en: 'Hua Phai',
            },
            coordinates: {
              lat: '14.965',
              lng: '100.429',
            },
            zipcode: 16000,
          },
          170106: {
            name: {
              th: 'ต้นโพธิ์',
              en: 'Ton Pho',
            },
            coordinates: {
              lat: '14.869',
              lng: '100.398',
            },
            zipcode: 16000,
          },
          170107: {
            name: {
              th: 'จักรสีห์',
              en: 'Chaksi',
            },
            coordinates: {
              lat: '14.836',
              lng: '100.398',
            },
            zipcode: 16000,
          },
          170108: {
            name: {
              th: 'บางกระบือ',
              en: 'Bang Krabue',
            },
            coordinates: {
              lat: '14.907',
              lng: '100.363',
            },
            zipcode: 16000,
          },
        },
      },
      1702: {
        name: {
          th: 'บางระจัน',
          en: 'Bang Rachan',
        },
        tambons: {
          170201: {
            name: {
              th: 'สิงห์',
              en: 'Sing',
            },
            coordinates: {
              lat: '14.890',
              lng: '100.338',
            },
            zipcode: 16130,
          },
          170202: {
            name: {
              th: 'ไม้ดัด',
              en: 'Mai Dat',
            },
            coordinates: {
              lat: '14.858',
              lng: '100.345',
            },
            zipcode: 16130,
          },
          170203: {
            name: {
              th: 'เชิงกลัด',
              en: 'Choeng Klat',
            },
            coordinates: {
              lat: '14.910',
              lng: '100.269',
            },
            zipcode: 16130,
          },
          170204: {
            name: {
              th: 'โพชนไก่',
              en: 'Pho Chon Kai',
            },
            coordinates: {
              lat: '14.929',
              lng: '100.292',
            },
            zipcode: 16130,
          },
          170205: {
            name: {
              th: 'แม่ลา',
              en: 'Mae La',
            },
            coordinates: {
              lat: '14.941',
              lng: '100.319',
            },
            zipcode: 16130,
          },
          170206: {
            name: {
              th: 'บ้านจ่า',
              en: 'Ban Cha',
            },
            coordinates: {
              lat: '14.852',
              lng: '100.284',
            },
            zipcode: 16130,
          },
          170207: {
            name: {
              th: 'พักทัน',
              en: 'Phak Than',
            },
            coordinates: {
              lat: '14.924',
              lng: '100.220',
            },
            zipcode: 16130,
          },
          170208: {
            name: {
              th: 'สระแจง',
              en: 'Sa Chaeng',
            },
            coordinates: {
              lat: '14.873',
              lng: '100.235',
            },
            zipcode: 16130,
          },
        },
      },
      1703: {
        name: {
          th: 'ค่ายบางระจัน',
          en: 'Khai Bang Rachan',
        },
        tambons: {
          170301: {
            name: {
              th: 'โพทะเล',
              en: 'Pho Thale',
            },
            coordinates: {
              lat: '14.829',
              lng: '100.257',
            },
            zipcode: 16150,
          },
          170302: {
            name: {
              th: 'บางระจัน',
              en: 'Bang Rachan',
            },
            coordinates: {
              lat: '14.799',
              lng: '100.296',
            },
            zipcode: 16150,
          },
          170303: {
            name: {
              th: 'โพสังโฆ',
              en: 'Pho Sangkho',
            },
            coordinates: {
              lat: '14.833',
              lng: '100.349',
            },
            zipcode: 16150,
          },
          170304: {
            name: {
              th: 'ท่าข้าม',
              en: 'Tha Kham',
            },
            coordinates: {
              lat: '14.827',
              lng: '100.368',
            },
            zipcode: 16150,
          },
          170305: {
            name: {
              th: 'คอทราย',
              en: 'Kho Sai',
            },
            coordinates: {
              lat: '14.817',
              lng: '100.281',
            },
            zipcode: 16150,
          },
          170306: {
            name: {
              th: 'หนองกระทุ่ม',
              en: 'Nong Krathum',
            },
            coordinates: {
              lat: '14.820',
              lng: '100.225',
            },
            zipcode: 16150,
          },
        },
      },
      1704: {
        name: {
          th: 'พรหมบุรี',
          en: 'Phrom Buri',
        },
        tambons: {
          170401: {
            name: {
              th: 'พระงาม',
              en: 'Phra Ngam',
            },
            coordinates: {
              lat: '14.748',
              lng: '100.422',
            },
            zipcode: 16120,
          },
          170402: {
            name: {
              th: 'พรหมบุรี',
              en: 'Phrom Buri',
            },
            coordinates: {
              lat: '14.837',
              lng: '100.451',
            },
            zipcode: 16160,
          },
          170403: {
            name: {
              th: 'บางน้ำเชี่ยว',
              en: 'Bang Nam Chiao',
            },
            coordinates: {
              lat: '14.783',
              lng: '100.458',
            },
            zipcode: 16120,
          },
          170404: {
            name: {
              th: 'บ้านหม้อ',
              en: 'Ban Mo',
            },
            coordinates: {
              lat: '14.746',
              lng: '100.461',
            },
            zipcode: 16120,
          },
          170405: {
            name: {
              th: 'บ้านแป้ง',
              en: 'Ban Paeng',
            },
            coordinates: {
              lat: '14.811',
              lng: '100.458',
            },
            zipcode: 16120,
          },
          170406: {
            name: {
              th: 'หัวป่า',
              en: 'Hua Pa',
            },
            coordinates: {
              lat: '14.831',
              lng: '100.425',
            },
            zipcode: 16120,
          },
          170407: {
            name: {
              th: 'โรงช้าง',
              en: 'Rong Chang',
            },
            coordinates: {
              lat: '14.801',
              lng: '100.425',
            },
            zipcode: 16120,
          },
        },
      },
      1705: {
        name: {
          th: 'ท่าช้าง',
          en: 'Tha Chang',
        },
        tambons: {
          170501: {
            name: {
              th: 'ถอนสมอ',
              en: 'Thon Samo',
            },
            coordinates: {
              lat: '14.765',
              lng: '100.384',
            },
            zipcode: 16140,
          },
          170502: {
            name: {
              th: 'โพประจักษ์',
              en: 'Pho Prachak',
            },
            coordinates: {
              lat: '14.774',
              lng: '100.360',
            },
            zipcode: 16140,
          },
          170503: {
            name: {
              th: 'วิหารขาว',
              en: 'Wihan Khao',
            },
            coordinates: {
              lat: '14.801',
              lng: '100.409',
            },
            zipcode: 16140,
          },
          170504: {
            name: {
              th: 'พิกุลทอง',
              en: 'Phikun Thong',
            },
            coordinates: {
              lat: '14.772',
              lng: '100.410',
            },
            zipcode: 16140,
          },
        },
      },
      1706: {
        name: {
          th: 'อินทร์บุรี',
          en: 'In Buri',
        },
        tambons: {
          170601: {
            name: {
              th: 'อินทร์บุรี',
              en: 'In Buri',
            },
            coordinates: {
              lat: '15.009',
              lng: '100.329',
            },
            zipcode: 16110,
          },
          170602: {
            name: {
              th: 'ประศุก',
              en: 'Prasuk',
            },
            coordinates: {
              lat: '15.042',
              lng: '100.291',
            },
            zipcode: 16110,
          },
          170603: {
            name: {
              th: 'ทับยา',
              en: 'Thap Ya',
            },
            coordinates: {
              lat: '14.960',
              lng: '100.334',
            },
            zipcode: 16110,
          },
          170604: {
            name: {
              th: 'งิ้วราย',
              en: 'Ngio Rai',
            },
            coordinates: {
              lat: '14.988',
              lng: '100.389',
            },
            zipcode: 16110,
          },
          170605: {
            name: {
              th: 'ชีน้ำร้าย',
              en: 'Chi Nam Rai',
            },
            coordinates: {
              lat: '15.081',
              lng: '100.331',
            },
            zipcode: 16110,
          },
          170606: {
            name: {
              th: 'ท่างาม',
              en: 'Tha Ngam',
            },
            coordinates: {
              lat: '15.041',
              lng: '100.343',
            },
            zipcode: 16110,
          },
          170607: {
            name: {
              th: 'น้ำตาล',
              en: 'Namtan',
            },
            coordinates: {
              lat: '14.976',
              lng: '100.361',
            },
            zipcode: 16110,
          },
          170608: {
            name: {
              th: 'ทองเอน',
              en: 'Thong En',
            },
            coordinates: {
              lat: '15.046',
              lng: '100.385',
            },
            zipcode: 16110,
          },
          170609: {
            name: {
              th: 'ห้วยชัน',
              en: 'Huai Chan',
            },
            coordinates: {
              lat: '15.019',
              lng: '100.269',
            },
            zipcode: 16110,
          },
          170610: {
            name: {
              th: 'โพธิ์ชัย',
              en: 'Pho Chai',
            },
            coordinates: {
              lat: '15.027',
              lng: '100.439',
            },
            zipcode: 16110,
          },
        },
      },
    },
  },
  18: {
    name: {
      th: 'ชัยนาท',
      en: 'Chai Nat',
    },
    amphoes: {
      1801: {
        name: {
          th: 'เมืองชัยนาท',
          en: 'Mueang Chai Nat',
        },
        tambons: {
          180101: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '15.181',
              lng: '100.127',
            },
            zipcode: 17000,
          },
          180102: {
            name: {
              th: 'บ้านกล้วย',
              en: 'Ban Kluai',
            },
            coordinates: {
              lat: '15.182',
              lng: '100.153',
            },
            zipcode: 17000,
          },
          180103: {
            name: {
              th: 'ท่าชัย',
              en: 'Tha Chai',
            },
            coordinates: {
              lat: '15.163',
              lng: '100.113',
            },
            zipcode: 17000,
          },
          180104: {
            name: {
              th: 'ชัยนาท',
              en: 'Chai Nat',
            },
            coordinates: {
              lat: '15.129',
              lng: '100.151',
            },
            zipcode: 17000,
          },
          180105: {
            name: {
              th: 'เขาท่าพระ',
              en: 'Khao Tha Phra',
            },
            coordinates: {
              lat: '15.218',
              lng: '100.134',
            },
            zipcode: 17000,
          },
          180106: {
            name: {
              th: 'หาดท่าเสา',
              en: 'Hat Tha Sao',
            },
            coordinates: {
              lat: '15.200',
              lng: '100.079',
            },
            zipcode: 17120,
          },
          180107: {
            name: {
              th: 'ธรรมามูล',
              en: 'Thammamun',
            },
            coordinates: {
              lat: '15.264',
              lng: '100.133',
            },
            zipcode: 17000,
          },
          180108: {
            name: {
              th: 'เสือโฮก',
              en: 'Suea Hok',
            },
            coordinates: {
              lat: '15.238',
              lng: '100.224',
            },
            zipcode: 17000,
          },
          180109: {
            name: {
              th: 'นางลือ',
              en: 'Nang Lue',
            },
            coordinates: {
              lat: '15.122',
              lng: '100.082',
            },
            zipcode: 17000,
          },
        },
      },
      1802: {
        name: {
          th: 'มโนรมย์',
          en: 'Manorom',
        },
        tambons: {
          180201: {
            name: {
              th: 'คุ้งสำเภา',
              en: 'Khung Samphao',
            },
            coordinates: {
              lat: '15.291',
              lng: '100.091',
            },
            zipcode: 17110,
          },
          180202: {
            name: {
              th: 'วัดโคก',
              en: 'Wat Khok',
            },
            coordinates: {
              lat: '15.326',
              lng: '100.107',
            },
            zipcode: 17110,
          },
          180203: {
            name: {
              th: 'ศิลาดาน',
              en: 'Sila Dan',
            },
            coordinates: {
              lat: '15.354',
              lng: '100.122',
            },
            zipcode: 17110,
          },
          180204: {
            name: {
              th: 'ท่าฉนวน',
              en: 'Tha Chanuan',
            },
            coordinates: {
              lat: '15.379',
              lng: '100.138',
            },
            zipcode: 17110,
          },
          180205: {
            name: {
              th: 'หางน้ำสาคร',
              en: 'Hang Nam Sakhon',
            },
            coordinates: {
              lat: '15.292',
              lng: '100.146',
            },
            zipcode: 17170,
          },
          180206: {
            name: {
              th: 'ไร่พัฒนา',
              en: 'Rai Phatthana',
            },
            coordinates: {
              lat: '15.333',
              lng: '100.225',
            },
            zipcode: 17170,
          },
          180207: {
            name: {
              th: 'อู่ตะเภา',
              en: 'U Taphao',
            },
            coordinates: {
              lat: '15.274',
              lng: '100.219',
            },
            zipcode: 17170,
          },
        },
      },
      1803: {
        name: {
          th: 'วัดสิงห์',
          en: 'Wat Sing',
        },
        tambons: {
          180301: {
            name: {
              th: 'วัดสิงห์',
              en: 'Wat Sing',
            },
            coordinates: {
              lat: '15.263',
              lng: '100.041',
            },
            zipcode: 17120,
          },
          180302: {
            name: {
              th: 'มะขามเฒ่า',
              en: 'Makham Thao',
            },
            coordinates: {
              lat: '15.227',
              lng: '100.040',
            },
            zipcode: 17120,
          },
          180303: {
            name: {
              th: 'หนองน้อย',
              en: 'Nong Noi',
            },
            coordinates: {
              lat: '15.177',
              lng: '100.015',
            },
            zipcode: 17120,
          },
          180304: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '15.283',
              lng: '100.013',
            },
            zipcode: 17120,
          },
          180306: {
            name: {
              th: 'หนองขุ่น',
              en: 'Nong Khun',
            },
            coordinates: {
              lat: '15.235',
              lng: '99.970',
            },
            zipcode: 17120,
          },
          180307: {
            name: {
              th: 'บ่อแร่',
              en: 'Bo Rae',
            },
            coordinates: {
              lat: '15.283',
              lng: '99.958',
            },
            zipcode: 17120,
          },
          180311: {
            name: {
              th: 'วังหมัน',
              en: 'Wang Man',
            },
            coordinates: {
              lat: '15.171',
              lng: '99.916',
            },
            zipcode: 17120,
          },
        },
      },
      1804: {
        name: {
          th: 'สรรพยา',
          en: 'Sapphaya',
        },
        tambons: {
          180401: {
            name: {
              th: 'สรรพยา',
              en: 'Sapphaya',
            },
            coordinates: {
              lat: '15.128',
              lng: '100.233',
            },
            zipcode: 17150,
          },
          180402: {
            name: {
              th: 'ตลุก',
              en: 'Taluk',
            },
            coordinates: {
              lat: '15.195',
              lng: '100.198',
            },
            zipcode: 17150,
          },
          180403: {
            name: {
              th: 'เขาแก้ว',
              en: 'Khao Kaeo',
            },
            coordinates: {
              lat: '15.142',
              lng: '100.316',
            },
            zipcode: 17150,
          },
          180404: {
            name: {
              th: 'โพนางดำตก',
              en: 'Pho Nang Dam Tok',
            },
            coordinates: {
              lat: '15.084',
              lng: '100.272',
            },
            zipcode: 17150,
          },
          180405: {
            name: {
              th: 'โพนางดำออก',
              en: 'Pho Nang Dam Ok',
            },
            coordinates: {
              lat: '15.120',
              lng: '100.283',
            },
            zipcode: 17150,
          },
          180406: {
            name: {
              th: 'บางหลวง',
              en: 'Bang Luang',
            },
            coordinates: {
              lat: '15.143',
              lng: '100.197',
            },
            zipcode: 17150,
          },
          180407: {
            name: {
              th: 'หาดอาษา',
              en: 'Hat A Sa',
            },
            coordinates: {
              lat: '15.165',
              lng: '100.262',
            },
            zipcode: 17150,
          },
        },
      },
      1805: {
        name: {
          th: 'สรรคบุรี',
          en: 'Sankhaburi',
        },
        tambons: {
          180501: {
            name: {
              th: 'แพรกศรีราชา',
              en: 'Phraek Si Racha',
            },
            coordinates: {
              lat: '15.062',
              lng: '100.107',
            },
            zipcode: 17140,
          },
          180502: {
            name: {
              th: 'เที่ยงแท้',
              en: 'Thiang Thae',
            },
            coordinates: {
              lat: '15.089',
              lng: '100.161',
            },
            zipcode: 17140,
          },
          180503: {
            name: {
              th: 'ห้วยกรด',
              en: 'Huai Krot',
            },
            coordinates: {
              lat: '15.096',
              lng: '100.203',
            },
            zipcode: 17140,
          },
          180504: {
            name: {
              th: 'โพงาม',
              en: 'Pho Ngam',
            },
            coordinates: {
              lat: '14.995',
              lng: '100.249',
            },
            zipcode: 17140,
          },
          180505: {
            name: {
              th: 'บางขุด',
              en: 'Bang Khut',
            },
            coordinates: {
              lat: '14.991',
              lng: '100.194',
            },
            zipcode: 17140,
          },
          180506: {
            name: {
              th: 'ดงคอน',
              en: 'Dong Khon',
            },
            coordinates: {
              lat: '14.976',
              lng: '100.135',
            },
            zipcode: 17140,
          },
          180507: {
            name: {
              th: 'ดอนกำ',
              en: 'Don Kam',
            },
            coordinates: {
              lat: '14.966',
              lng: '100.236',
            },
            zipcode: 17140,
          },
          180508: {
            name: {
              th: 'ห้วยกรดพัฒนา',
              en: 'Huai Krot Phatthana',
            },
            coordinates: {
              lat: '15.074',
              lng: '100.228',
            },
            zipcode: 17140,
          },
        },
      },
      1806: {
        name: {
          th: 'หันคา',
          en: 'Hankha',
        },
        tambons: {
          180601: {
            name: {
              th: 'หันคา',
              en: 'Han Kha',
            },
            coordinates: {
              lat: '15.016',
              lng: '99.998',
            },
            zipcode: 17130,
          },
          180602: {
            name: {
              th: 'บ้านเชี่ยน',
              en: 'Ban Chian',
            },
            coordinates: {
              lat: '14.942',
              lng: '99.997',
            },
            zipcode: 17130,
          },
          180605: {
            name: {
              th: 'ไพรนกยูง',
              en: 'Phrai Nok Yung',
            },
            coordinates: {
              lat: '15.081',
              lng: '99.856',
            },
            zipcode: 17130,
          },
          180606: {
            name: {
              th: 'หนองแซง',
              en: 'Nong Saeng',
            },
            coordinates: {
              lat: '15.107',
              lng: '99.973',
            },
            zipcode: 17160,
          },
          180607: {
            name: {
              th: 'ห้วยงู',
              en: 'Huai Ngu',
            },
            coordinates: {
              lat: '15.072',
              lng: '100.067',
            },
            zipcode: 17160,
          },
          180608: {
            name: {
              th: 'วังไก่เถื่อน',
              en: 'Wang Kai Thuean',
            },
            coordinates: {
              lat: '15.004',
              lng: '100.056',
            },
            zipcode: 17130,
          },
          180609: {
            name: {
              th: 'เด่นใหญ่',
              en: 'Den Yai',
            },
            coordinates: {
              lat: '15.017',
              lng: '99.930',
            },
            zipcode: 17130,
          },
          180611: {
            name: {
              th: 'สามง่ามท่าโบสถ์',
              en: 'Sam Ngam Tha Bot',
            },
            coordinates: {
              lat: '15.065',
              lng: '100.026',
            },
            zipcode: 17160,
          },
        },
      },
      1807: {
        name: {
          th: 'หนองมะโมง',
          en: 'Nong Mamong',
        },
        tambons: {
          180701: {
            name: {
              th: 'หนองมะโมง',
              en: 'Nong Mamong',
            },
            coordinates: {
              lat: '15.216',
              lng: '99.772',
            },
            zipcode: null,
          },
          180702: {
            name: {
              th: 'วังตะเคียน',
              en: 'Wang Takhian',
            },
            coordinates: {
              lat: '15.278',
              lng: '99.802',
            },
            zipcode: null,
          },
          180703: {
            name: {
              th: 'สะพานหิน',
              en: 'Saphan Hin',
            },
            coordinates: {
              lat: '15.197',
              lng: '99.827',
            },
            zipcode: null,
          },
          180704: {
            name: {
              th: 'กุดจอก',
              en: 'Kut Chok',
            },
            coordinates: {
              lat: '15.241',
              lng: '99.894',
            },
            zipcode: null,
          },
        },
      },
      1808: {
        name: {
          th: 'เนินขาม',
          en: 'Noen Kham',
        },
        tambons: {
          180801: {
            name: {
              th: 'เนินขาม',
              en: 'Noen Kham',
            },
            coordinates: {
              lat: '14.957',
              lng: '99.901',
            },
            zipcode: null,
          },
          180802: {
            name: {
              th: 'กะบกเตี้ย',
              en: 'Kabok Tia',
            },
            coordinates: {
              lat: '15.036',
              lng: '99.816',
            },
            zipcode: null,
          },
          180803: {
            name: {
              th: 'สุขเดือนห้า',
              en: 'Suk Duean Ha',
            },
            coordinates: {
              lat: '14.968',
              lng: '99.828',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  19: {
    name: {
      th: 'สระบุรี',
      en: 'Saraburi',
    },
    amphoes: {
      1901: {
        name: {
          th: 'เมืองสระบุรี',
          en: 'Mueang Saraburi',
        },
        tambons: {
          190101: {
            name: {
              th: 'ปากเพรียว',
              en: 'Pak Phriao',
            },
            coordinates: {
              lat: '14.527',
              lng: '100.915',
            },
            zipcode: 18000,
          },
          190105: {
            name: {
              th: 'ดาวเรือง',
              en: 'Dao Rueang',
            },
            coordinates: {
              lat: '14.563',
              lng: '100.901',
            },
            zipcode: 18000,
          },
          190106: {
            name: {
              th: 'นาโฉง',
              en: 'Na Chong',
            },
            coordinates: {
              lat: '14.529',
              lng: '100.886',
            },
            zipcode: 18000,
          },
          190107: {
            name: {
              th: 'โคกสว่าง',
              en: 'Khok Sawang',
            },
            coordinates: {
              lat: '14.510',
              lng: '100.886',
            },
            zipcode: 18000,
          },
          190108: {
            name: {
              th: 'หนองโน',
              en: 'Nong No',
            },
            coordinates: {
              lat: '14.489',
              lng: '100.861',
            },
            zipcode: 18000,
          },
          190109: {
            name: {
              th: 'หนองยาว',
              en: 'Nong Yao',
            },
            coordinates: {
              lat: '14.472',
              lng: '100.890',
            },
            zipcode: 18000,
          },
          190110: {
            name: {
              th: 'ปากข้าวสาร',
              en: 'Pak Khao San',
            },
            coordinates: {
              lat: '14.490',
              lng: '100.913',
            },
            zipcode: 18000,
          },
          190111: {
            name: {
              th: 'หนองปลาไหล',
              en: 'Nong Pla Lai',
            },
            coordinates: {
              lat: '14.456',
              lng: '100.962',
            },
            zipcode: 18000,
          },
          190112: {
            name: {
              th: 'กุดนกเปล้า',
              en: 'Kut Nok Plao',
            },
            coordinates: {
              lat: '14.502',
              lng: '100.976',
            },
            zipcode: 18000,
          },
          190113: {
            name: {
              th: 'ตลิ่งชัน',
              en: 'Taling Chan',
            },
            coordinates: {
              lat: '14.544',
              lng: '100.956',
            },
            zipcode: 18000,
          },
          190114: {
            name: {
              th: 'ตะกุด',
              en: 'Takut',
            },
            coordinates: {
              lat: '14.553',
              lng: '100.933',
            },
            zipcode: 18000,
          },
        },
      },
      1902: {
        name: {
          th: 'แก่งคอย',
          en: 'Kaeng Khoi',
        },
        tambons: {
          190201: {
            name: {
              th: 'แก่งคอย',
              en: 'Kaeng Khoi',
            },
            coordinates: {
              lat: '14.586',
              lng: '101.002',
            },
            zipcode: 18110,
          },
          190202: {
            name: {
              th: 'ทับกวาง',
              en: 'Thap Kwang',
            },
            coordinates: {
              lat: '14.605',
              lng: '101.095',
            },
            zipcode: 18260,
          },
          190203: {
            name: {
              th: 'ตาลเดี่ยว',
              en: 'Tan Diao',
            },
            coordinates: {
              lat: '14.552',
              lng: '101.015',
            },
            zipcode: 18110,
          },
          190204: {
            name: {
              th: 'ห้วยแห้ง',
              en: 'Huai Haeng',
            },
            coordinates: {
              lat: '14.477',
              lng: '101.004',
            },
            zipcode: 18110,
          },
          190205: {
            name: {
              th: 'ท่าคล้อ',
              en: 'Tha Khlo',
            },
            coordinates: {
              lat: '14.712',
              lng: '100.993',
            },
            zipcode: 18110,
          },
          190206: {
            name: {
              th: 'หินซ้อน',
              en: 'Hin Son',
            },
            coordinates: {
              lat: '14.753',
              lng: '101.033',
            },
            zipcode: 18110,
          },
          190207: {
            name: {
              th: 'บ้านธาตุ',
              en: 'Ban That',
            },
            coordinates: {
              lat: '14.626',
              lng: '100.997',
            },
            zipcode: 18110,
          },
          190208: {
            name: {
              th: 'บ้านป่า',
              en: 'Ban Pa',
            },
            coordinates: {
              lat: '14.622',
              lng: '101.029',
            },
            zipcode: 18110,
          },
          190209: {
            name: {
              th: 'ท่าตูม',
              en: 'Tha Tum',
            },
            coordinates: {
              lat: '14.662',
              lng: '100.993',
            },
            zipcode: 18110,
          },
          190210: {
            name: {
              th: 'ชะอม',
              en: 'Cha-Om',
            },
            coordinates: {
              lat: '14.418',
              lng: '101.134',
            },
            zipcode: 18110,
          },
          190211: {
            name: {
              th: 'สองคอน',
              en: 'Song Khon',
            },
            coordinates: {
              lat: '14.636',
              lng: '100.968',
            },
            zipcode: 18110,
          },
          190212: {
            name: {
              th: 'เตาปูน',
              en: 'Tao Pun',
            },
            coordinates: {
              lat: '14.603',
              lng: '100.956',
            },
            zipcode: 18110,
          },
          190213: {
            name: {
              th: 'ชำผักแพว',
              en: 'Cham Phak Phaeo',
            },
            coordinates: {
              lat: '14.529',
              lng: '101.090',
            },
            zipcode: 18110,
          },
          190215: {
            name: {
              th: 'ท่ามะปราง',
              en: 'Tha Maprang',
            },
            coordinates: {
              lat: '14.483',
              lng: '101.103',
            },
            zipcode: 18110,
          },
        },
      },
      1903: {
        name: {
          th: 'หนองแค',
          en: 'Nong Khae',
        },
        tambons: {
          190301: {
            name: {
              th: 'หนองแค',
              en: 'Nong Khae',
            },
            coordinates: {
              lat: '14.337',
              lng: '100.869',
            },
            zipcode: 18140,
          },
          190302: {
            name: {
              th: 'กุ่มหัก',
              en: 'Kum Hak',
            },
            coordinates: {
              lat: '14.312',
              lng: '100.863',
            },
            zipcode: 18140,
          },
          190303: {
            name: {
              th: 'คชสิทธิ์',
              en: 'Khotchasit',
            },
            coordinates: {
              lat: '14.391',
              lng: '100.807',
            },
            zipcode: 18250,
          },
          190304: {
            name: {
              th: 'โคกตูม',
              en: 'Khok Tum',
            },
            coordinates: {
              lat: '14.421',
              lng: '100.784',
            },
            zipcode: 18250,
          },
          190305: {
            name: {
              th: 'โคกแย้',
              en: 'Khok Yae',
            },
            coordinates: {
              lat: '14.387',
              lng: '100.928',
            },
            zipcode: 18230,
          },
          190306: {
            name: {
              th: 'บัวลอย',
              en: 'Bua Loi',
            },
            coordinates: {
              lat: '14.404',
              lng: '100.851',
            },
            zipcode: 18230,
          },
          190307: {
            name: {
              th: 'ไผ่ต่ำ',
              en: 'Phai Tam',
            },
            coordinates: {
              lat: '14.326',
              lng: '100.834',
            },
            zipcode: 18140,
          },
          190308: {
            name: {
              th: 'โพนทอง',
              en: 'Phon Thong',
            },
            coordinates: {
              lat: '14.425',
              lng: '100.813',
            },
            zipcode: 18250,
          },
          190309: {
            name: {
              th: 'ห้วยขมิ้น',
              en: 'Huai Khamin',
            },
            coordinates: {
              lat: '14.381',
              lng: '100.871',
            },
            zipcode: 18230,
          },
          190310: {
            name: {
              th: 'ห้วยทราย',
              en: 'Huai Sai',
            },
            coordinates: {
              lat: '14.420',
              lng: '100.933',
            },
            zipcode: 18230,
          },
          190311: {
            name: {
              th: 'หนองไข่น้ำ',
              en: 'Nong Khai Nam',
            },
            coordinates: {
              lat: '14.360',
              lng: '100.878',
            },
            zipcode: 18140,
          },
          190312: {
            name: {
              th: 'หนองแขม',
              en: 'Nong Khaem',
            },
            coordinates: {
              lat: '14.339',
              lng: '100.797',
            },
            zipcode: 18140,
          },
          190313: {
            name: {
              th: 'หนองจิก',
              en: 'Nong Chik',
            },
            coordinates: {
              lat: '14.441',
              lng: '100.866',
            },
            zipcode: 18230,
          },
          190314: {
            name: {
              th: 'หนองจรเข้',
              en: 'Nong Chorakhe',
            },
            coordinates: {
              lat: '14.334',
              lng: '100.907',
            },
            zipcode: 18140,
          },
          190315: {
            name: {
              th: 'หนองนาก',
              en: 'Nong Nak',
            },
            coordinates: {
              lat: '14.444',
              lng: '100.891',
            },
            zipcode: 18230,
          },
          190316: {
            name: {
              th: 'หนองปลาหมอ',
              en: 'Nong Pla Mo',
            },
            coordinates: {
              lat: '14.369',
              lng: '100.845',
            },
            zipcode: 18140,
          },
          190317: {
            name: {
              th: 'หนองปลิง',
              en: 'Nong Pling',
            },
            coordinates: {
              lat: '14.370',
              lng: '100.829',
            },
            zipcode: 18140,
          },
          190318: {
            name: {
              th: 'หนองโรง',
              en: 'Nong Rong',
            },
            coordinates: {
              lat: '14.279',
              lng: '100.865',
            },
            zipcode: 18140,
          },
        },
      },
      1904: {
        name: {
          th: 'วิหารแดง',
          en: 'Wihan Daeng',
        },
        tambons: {
          190401: {
            name: {
              th: 'หนองหมู',
              en: 'Nong Mu',
            },
            coordinates: {
              lat: '14.288',
              lng: '100.932',
            },
            zipcode: 18150,
          },
          190402: {
            name: {
              th: 'บ้านลำ',
              en: 'Ban Lam',
            },
            coordinates: {
              lat: '14.391',
              lng: '100.979',
            },
            zipcode: 18150,
          },
          190403: {
            name: {
              th: 'คลองเรือ',
              en: 'Khlong Ruea',
            },
            coordinates: {
              lat: '14.366',
              lng: '101.032',
            },
            zipcode: 18150,
          },
          190404: {
            name: {
              th: 'วิหารแดง',
              en: 'Wihan Daeng',
            },
            coordinates: {
              lat: '14.313',
              lng: '100.990',
            },
            zipcode: 18150,
          },
          190405: {
            name: {
              th: 'หนองสรวง',
              en: 'Nong Suang',
            },
            coordinates: {
              lat: '14.340',
              lng: '100.983',
            },
            zipcode: 18150,
          },
          190406: {
            name: {
              th: 'เจริญธรรม',
              en: 'Charoen Tham',
            },
            coordinates: {
              lat: '14.387',
              lng: '101.006',
            },
            zipcode: 18150,
          },
        },
      },
      1905: {
        name: {
          th: 'หนองแซง',
          en: 'Nong Saeng',
        },
        tambons: {
          190501: {
            name: {
              th: 'หนองแซง',
              en: 'Nong Saeng',
            },
            coordinates: {
              lat: '14.510',
              lng: '100.759',
            },
            zipcode: 18170,
          },
          190502: {
            name: {
              th: 'หนองควายโซ',
              en: 'Nong Khwai So',
            },
            coordinates: {
              lat: '14.487',
              lng: '100.795',
            },
            zipcode: 18170,
          },
          190503: {
            name: {
              th: 'หนองหัวโพ',
              en: 'Nong Hua Pho',
            },
            coordinates: {
              lat: '14.483',
              lng: '100.817',
            },
            zipcode: 18170,
          },
          190504: {
            name: {
              th: 'หนองสีดา',
              en: 'Nong Sida',
            },
            coordinates: {
              lat: '14.515',
              lng: '100.841',
            },
            zipcode: 18170,
          },
          190505: {
            name: {
              th: 'หนองกบ',
              en: 'Nong Kop',
            },
            coordinates: {
              lat: '14.456',
              lng: '100.776',
            },
            zipcode: 18170,
          },
          190506: {
            name: {
              th: 'ไก่เส่า',
              en: 'Kai Sao',
            },
            coordinates: {
              lat: '14.516',
              lng: '100.793',
            },
            zipcode: 18170,
          },
          190507: {
            name: {
              th: 'โคกสะอาด',
              en: 'Khok Sa-At',
            },
            coordinates: {
              lat: '14.456',
              lng: '100.802',
            },
            zipcode: 18170,
          },
          190508: {
            name: {
              th: 'ม่วงหวาน',
              en: 'Muang Wan',
            },
            coordinates: {
              lat: '14.476',
              lng: '100.836',
            },
            zipcode: 18170,
          },
        },
      },
      1906: {
        name: {
          th: 'บ้านหมอ',
          en: 'Ban Mo',
        },
        tambons: {
          190601: {
            name: {
              th: 'บ้านหมอ',
              en: 'Ban Mo',
            },
            coordinates: {
              lat: '14.616',
              lng: '100.724',
            },
            zipcode: 18130,
          },
          190602: {
            name: {
              th: 'บางโขมด',
              en: 'Bang Khamot',
            },
            coordinates: {
              lat: '14.591',
              lng: '100.742',
            },
            zipcode: 18130,
          },
          190603: {
            name: {
              th: 'สร้างโศก',
              en: 'Sang Sok',
            },
            coordinates: {
              lat: '14.622',
              lng: '100.754',
            },
            zipcode: 18130,
          },
          190604: {
            name: {
              th: 'ตลาดน้อย',
              en: 'Talat Noi',
            },
            coordinates: {
              lat: '14.643',
              lng: '100.716',
            },
            zipcode: 18130,
          },
          190605: {
            name: {
              th: 'หรเทพ',
              en: 'Horathep',
            },
            coordinates: {
              lat: '14.628',
              lng: '100.670',
            },
            zipcode: 18130,
          },
          190606: {
            name: {
              th: 'โคกใหญ่',
              en: 'Khok Yai',
            },
            coordinates: {
              lat: '14.606',
              lng: '100.677',
            },
            zipcode: 18130,
          },
          190607: {
            name: {
              th: 'ไผ่ขวาง',
              en: 'Phai Khwang',
            },
            coordinates: {
              lat: '14.579',
              lng: '100.648',
            },
            zipcode: 18130,
          },
          190608: {
            name: {
              th: 'บ้านครัว',
              en: 'Ban Khrua',
            },
            coordinates: {
              lat: '14.584',
              lng: '100.767',
            },
            zipcode: 18270,
          },
          190609: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '14.618',
              lng: '100.774',
            },
            zipcode: 18130,
          },
        },
      },
      1907: {
        name: {
          th: 'ดอนพุด',
          en: 'Don Phut',
        },
        tambons: {
          190701: {
            name: {
              th: 'ดอนพุด',
              en: 'Don Phut',
            },
            coordinates: {
              lat: '14.596',
              lng: '100.621',
            },
            zipcode: null,
          },
          190702: {
            name: {
              th: 'ไผ่หลิ่ว',
              en: 'Phai Lio',
            },
            coordinates: {
              lat: '14.625',
              lng: '100.632',
            },
            zipcode: null,
          },
          190703: {
            name: {
              th: 'บ้านหลวง',
              en: 'Ban Luang',
            },
            coordinates: {
              lat: '14.571',
              lng: '100.620',
            },
            zipcode: null,
          },
          190704: {
            name: {
              th: 'ดงตะงาว',
              en: 'Dong Ta-Ngao',
            },
            coordinates: {
              lat: '14.624',
              lng: '100.599',
            },
            zipcode: null,
          },
        },
      },
      1908: {
        name: {
          th: 'หนองโดน',
          en: 'Nong Don',
        },
        tambons: {
          190801: {
            name: {
              th: 'หนองโดน',
              en: 'Nong Don',
            },
            coordinates: {
              lat: '14.688',
              lng: '100.693',
            },
            zipcode: 18190,
          },
          190802: {
            name: {
              th: 'บ้านกลับ',
              en: 'Ban Klap',
            },
            coordinates: {
              lat: '14.730',
              lng: '100.684',
            },
            zipcode: 18190,
          },
          190803: {
            name: {
              th: 'ดอนทอง',
              en: 'Don Thong',
            },
            coordinates: {
              lat: '14.664',
              lng: '100.654',
            },
            zipcode: 18190,
          },
          190804: {
            name: {
              th: 'บ้านโปร่ง',
              en: 'Ban Prong',
            },
            coordinates: {
              lat: '14.716',
              lng: '100.734',
            },
            zipcode: 18190,
          },
        },
      },
      1909: {
        name: {
          th: 'พระพุทธบาท',
          en: 'Phra Phutthabat',
        },
        tambons: {
          190901: {
            name: {
              th: 'พระพุทธบาท',
              en: 'Phraphutthabat',
            },
            coordinates: {
              lat: '14.732',
              lng: '100.782',
            },
            zipcode: 18120,
          },
          190902: {
            name: {
              th: 'ขุนโขลน',
              en: 'Khun Khlon',
            },
            coordinates: {
              lat: '14.713',
              lng: '100.831',
            },
            zipcode: 18120,
          },
          190903: {
            name: {
              th: 'ธารเกษม',
              en: 'Than Kasem',
            },
            coordinates: {
              lat: '14.763',
              lng: '100.812',
            },
            zipcode: 18120,
          },
          190904: {
            name: {
              th: 'นายาว',
              en: 'Na Yao',
            },
            coordinates: {
              lat: '14.768',
              lng: '100.763',
            },
            zipcode: 18120,
          },
          190905: {
            name: {
              th: 'พุคำจาน',
              en: 'Phu Kham Chan',
            },
            coordinates: {
              lat: '14.744',
              lng: '100.847',
            },
            zipcode: 18120,
          },
          190906: {
            name: {
              th: 'เขาวง',
              en: 'Khao Wong',
            },
            coordinates: {
              lat: '14.679',
              lng: '100.830',
            },
            zipcode: 18120,
          },
          190907: {
            name: {
              th: 'ห้วยป่าหวาย',
              en: 'Huai Pa Wai',
            },
            coordinates: {
              lat: '14.638',
              lng: '100.830',
            },
            zipcode: 18120,
          },
          190908: {
            name: {
              th: 'พุกร่าง',
              en: 'Phu Krang',
            },
            coordinates: {
              lat: '14.682',
              lng: '100.784',
            },
            zipcode: 18120,
          },
        },
      },
      1910: {
        name: {
          th: 'เสาไห้',
          en: 'Sao Hai',
        },
        tambons: {
          191001: {
            name: {
              th: 'เสาไห้',
              en: 'Sao Hai',
            },
            coordinates: {
              lat: '14.538',
              lng: '100.850',
            },
            zipcode: 18160,
          },
          191002: {
            name: {
              th: 'บ้านยาง',
              en: 'Ban Yang',
            },
            coordinates: {
              lat: '14.582',
              lng: '100.816',
            },
            zipcode: 18160,
          },
          191003: {
            name: {
              th: 'หัวปลวก',
              en: 'Hua Pluak',
            },
            coordinates: {
              lat: '14.615',
              lng: '100.844',
            },
            zipcode: 18160,
          },
          191004: {
            name: {
              th: 'งิ้วงาม',
              en: 'Ngio Ngam',
            },
            coordinates: {
              lat: '14.590',
              lng: '100.851',
            },
            zipcode: 18160,
          },
          191005: {
            name: {
              th: 'ศาลารีไทย',
              en: 'Sala Ri Thai',
            },
            coordinates: {
              lat: '14.565',
              lng: '100.848',
            },
            zipcode: 18160,
          },
          191006: {
            name: {
              th: 'ต้นตาล',
              en: 'Ton Tan',
            },
            coordinates: {
              lat: '14.574',
              lng: '100.885',
            },
            zipcode: 18160,
          },
          191007: {
            name: {
              th: 'ท่าช้าง',
              en: 'Tha Chang',
            },
            coordinates: {
              lat: '14.586',
              lng: '100.863',
            },
            zipcode: 18160,
          },
          191008: {
            name: {
              th: 'พระยาทด',
              en: 'Phraya Thot',
            },
            coordinates: {
              lat: '14.572',
              lng: '100.871',
            },
            zipcode: 18160,
          },
          191009: {
            name: {
              th: 'ม่วงงาม',
              en: 'Muang Ngam',
            },
            coordinates: {
              lat: '14.544',
              lng: '100.807',
            },
            zipcode: 18160,
          },
          191010: {
            name: {
              th: 'เริงราง',
              en: 'Roeng Rang',
            },
            coordinates: {
              lat: '14.558',
              lng: '100.786',
            },
            zipcode: 18160,
          },
          191011: {
            name: {
              th: 'เมืองเก่า',
              en: 'Mueang Kao',
            },
            coordinates: {
              lat: '14.540',
              lng: '100.825',
            },
            zipcode: 18160,
          },
          191012: {
            name: {
              th: 'สวนดอกไม้',
              en: 'Suan Dokmai',
            },
            coordinates: {
              lat: '14.540',
              lng: '100.868',
            },
            zipcode: 18160,
          },
        },
      },
      1911: {
        name: {
          th: 'มวกเหล็ก',
          en: 'Muak Lek',
        },
        tambons: {
          191101: {
            name: {
              th: 'มวกเหล็ก',
              en: 'Muak Lek',
            },
            coordinates: {
              lat: '14.705',
              lng: '101.151',
            },
            zipcode: 18180,
          },
          191102: {
            name: {
              th: 'มิตรภาพ',
              en: 'Mittraphap',
            },
            coordinates: {
              lat: '14.474',
              lng: '101.213',
            },
            zipcode: 18180,
          },
          191104: {
            name: {
              th: 'หนองย่างเสือ',
              en: 'Nong Yang Suea',
            },
            coordinates: {
              lat: '14.789',
              lng: '101.270',
            },
            zipcode: 18180,
          },
          191105: {
            name: {
              th: 'ลำสมพุง',
              en: 'Lam Somphung',
            },
            coordinates: {
              lat: '15.007',
              lng: '101.381',
            },
            zipcode: 30130,
          },
          191107: {
            name: {
              th: 'ลำพญากลาง',
              en: 'Lam Phaya Klang',
            },
            coordinates: {
              lat: '14.871',
              lng: '101.367',
            },
            zipcode: 30130,
          },
          191109: {
            name: {
              th: 'ซับสนุ่น',
              en: 'Sap Sanun',
            },
            coordinates: {
              lat: '14.932',
              lng: '101.309',
            },
            zipcode: 18220,
          },
        },
      },
      1912: {
        name: {
          th: 'วังม่วง',
          en: 'Wang Muang',
        },
        tambons: {
          191201: {
            name: {
              th: 'แสลงพัน',
              en: 'Salaeng Phan',
            },
            coordinates: {
              lat: '14.745',
              lng: '101.116',
            },
            zipcode: null,
          },
          191202: {
            name: {
              th: 'คำพราน',
              en: 'Kham Phran',
            },
            coordinates: {
              lat: '14.818',
              lng: '101.132',
            },
            zipcode: null,
          },
          191203: {
            name: {
              th: 'วังม่วง',
              en: 'Wang Muang',
            },
            coordinates: {
              lat: '14.861',
              lng: '101.151',
            },
            zipcode: null,
          },
        },
      },
      1913: {
        name: {
          th: 'เฉลิมพระเกียรติ',
          en: 'Chaloem Phra Kiet',
        },
        tambons: {
          191301: {
            name: {
              th: 'เขาดินพัฒนา',
              en: 'Khao Din Phatthana',
            },
            coordinates: {
              lat: '14.612',
              lng: '100.906',
            },
            zipcode: null,
          },
          191302: {
            name: {
              th: 'บ้านแก้ง',
              en: 'Ban Kaeng',
            },
            coordinates: {
              lat: '14.582',
              lng: '100.936',
            },
            zipcode: null,
          },
          191303: {
            name: {
              th: 'ผึ้งรวง',
              en: 'Phueng Ruang',
            },
            coordinates: {
              lat: '14.607',
              lng: '100.931',
            },
            zipcode: null,
          },
          191304: {
            name: {
              th: 'พุแค',
              en: 'Phu Khae',
            },
            coordinates: {
              lat: '14.656',
              lng: '100.905',
            },
            zipcode: null,
          },
          191305: {
            name: {
              th: 'ห้วยบง',
              en: 'Huai Bong',
            },
            coordinates: {
              lat: '14.626',
              lng: '100.877',
            },
            zipcode: null,
          },
          191306: {
            name: {
              th: 'หน้าพระลาน',
              en: 'Na Phralan',
            },
            coordinates: {
              lat: '14.713',
              lng: '100.904',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  20: {
    name: {
      th: 'ชลบุรี',
      en: 'Chon Buri',
    },
    amphoes: {
      2001: {
        name: {
          th: 'เมืองชลบุรี',
          en: 'Mueang Chon Buri',
        },
        tambons: {
          200101: {
            name: {
              th: 'บางปลาสร้อย',
              en: 'Bang Pla Soi',
            },
            coordinates: {
              lat: '13.364',
              lng: '100.987',
            },
            zipcode: 20000,
          },
          200102: {
            name: {
              th: 'มะขามหย่ง',
              en: 'Makham Yong',
            },
            coordinates: {
              lat: '13.372',
              lng: '100.985',
            },
            zipcode: 20000,
          },
          200103: {
            name: {
              th: 'บ้านโขด',
              en: 'Ban Khot',
            },
            coordinates: {
              lat: '13.376',
              lng: '100.990',
            },
            zipcode: 20000,
          },
          200104: {
            name: {
              th: 'แสนสุข',
              en: 'Saen Suk',
            },
            coordinates: {
              lat: '13.274',
              lng: '100.928',
            },
            zipcode: 20130,
          },
          200105: {
            name: {
              th: 'บ้านสวน',
              en: 'Ban Suan',
            },
            coordinates: {
              lat: '13.347',
              lng: '100.977',
            },
            zipcode: 20000,
          },
          200106: {
            name: {
              th: 'หนองรี',
              en: 'Nong Ri',
            },
            coordinates: {
              lat: '13.307',
              lng: '101.057',
            },
            zipcode: 20000,
          },
          200107: {
            name: {
              th: 'นาป่า',
              en: 'Na Pa',
            },
            coordinates: {
              lat: '13.385',
              lng: '101.030',
            },
            zipcode: 20000,
          },
          200108: {
            name: {
              th: 'หนองข้างคอก',
              en: 'Nong Khang Khok',
            },
            coordinates: {
              lat: '13.305',
              lng: '101.006',
            },
            zipcode: 20000,
          },
          200109: {
            name: {
              th: 'ดอนหัวฬอ',
              en: 'Don Hua Lo',
            },
            coordinates: {
              lat: '13.421',
              lng: '101.044',
            },
            zipcode: 20000,
          },
          200110: {
            name: {
              th: 'หนองไม้แดง',
              en: 'Nong Mai Daeng',
            },
            coordinates: {
              lat: '13.406',
              lng: '101.003',
            },
            zipcode: 20000,
          },
          200111: {
            name: {
              th: 'บางทราย',
              en: 'Bang Sai',
            },
            coordinates: {
              lat: '13.393',
              lng: '100.996',
            },
            zipcode: 20000,
          },
          200112: {
            name: {
              th: 'คลองตำหรุ',
              en: 'Khlong Tamru',
            },
            coordinates: {
              lat: '13.441',
              lng: '100.982',
            },
            zipcode: 20000,
          },
          200113: {
            name: {
              th: 'เหมือง',
              en: 'Mueang',
            },
            coordinates: {
              lat: '13.263',
              lng: '100.959',
            },
            zipcode: 20130,
          },
          200114: {
            name: {
              th: 'บ้านปึก',
              en: 'Ban Puek',
            },
            coordinates: {
              lat: '13.302',
              lng: '100.928',
            },
            zipcode: 20130,
          },
          200115: {
            name: {
              th: 'ห้วยกะปิ',
              en: 'Huai Kapi',
            },
            coordinates: {
              lat: '13.304',
              lng: '100.968',
            },
            zipcode: 20000,
          },
          200116: {
            name: {
              th: 'เสม็ด',
              en: 'Samet',
            },
            coordinates: {
              lat: '13.326',
              lng: '100.951',
            },
            zipcode: 20000,
          },
          200117: {
            name: {
              th: 'อ่างศิลา',
              en: 'Ang Sila',
            },
            coordinates: {
              lat: '13.325',
              lng: '100.929',
            },
            zipcode: 20000,
          },
          200118: {
            name: {
              th: 'สำนักบก',
              en: 'Samnak Bok',
            },
            coordinates: {
              lat: '13.368',
              lng: '101.061',
            },
            zipcode: 20000,
          },
        },
      },
      2002: {
        name: {
          th: 'บ้านบึง',
          en: 'Ban Bueng',
        },
        tambons: {
          200201: {
            name: {
              th: 'บ้านบึง',
              en: 'Ban Bueng',
            },
            coordinates: {
              lat: '13.297',
              lng: '101.107',
            },
            zipcode: 20170,
          },
          200202: {
            name: {
              th: 'คลองกิ่ว',
              en: 'Khlong Kio',
            },
            coordinates: {
              lat: '13.225',
              lng: '101.107',
            },
            zipcode: 20220,
          },
          200203: {
            name: {
              th: 'มาบไผ่',
              en: 'Map Phai',
            },
            coordinates: {
              lat: '13.359',
              lng: '101.093',
            },
            zipcode: 20170,
          },
          200204: {
            name: {
              th: 'หนองซ้ำซาก',
              en: 'Nong Samsak',
            },
            coordinates: {
              lat: '13.330',
              lng: '101.072',
            },
            zipcode: 20170,
          },
          200205: {
            name: {
              th: 'หนองบอนแดง',
              en: 'Nong Bon Daeng',
            },
            coordinates: {
              lat: '13.353',
              lng: '101.159',
            },
            zipcode: 20170,
          },
          200206: {
            name: {
              th: 'หนองชาก',
              en: 'Nong Chak',
            },
            coordinates: {
              lat: '13.298',
              lng: '101.172',
            },
            zipcode: 20170,
          },
          200207: {
            name: {
              th: 'หนองอิรุณ',
              en: 'Nong I Run',
            },
            coordinates: {
              lat: '13.280',
              lng: '101.247',
            },
            zipcode: 20220,
          },
          200208: {
            name: {
              th: 'หนองไผ่แก้ว',
              en: 'Nong Phai Kaeo',
            },
            coordinates: {
              lat: '13.213',
              lng: '101.246',
            },
            zipcode: 20220,
          },
        },
      },
      2003: {
        name: {
          th: 'หนองใหญ่',
          en: 'Nong Yai',
        },
        tambons: {
          200301: {
            name: {
              th: 'หนองใหญ่',
              en: 'Nong Yai',
            },
            coordinates: {
              lat: '13.153',
              lng: '101.375',
            },
            zipcode: null,
          },
          200302: {
            name: {
              th: 'คลองพลู',
              en: 'Khlong Phlu',
            },
            coordinates: {
              lat: '13.115',
              lng: '101.476',
            },
            zipcode: null,
          },
          200303: {
            name: {
              th: 'หนองเสือช้าง',
              en: 'Nong Suea Chang',
            },
            coordinates: {
              lat: '13.117',
              lng: '101.283',
            },
            zipcode: null,
          },
          200304: {
            name: {
              th: 'ห้างสูง',
              en: 'Hang Sung',
            },
            coordinates: {
              lat: '13.243',
              lng: '101.347',
            },
            zipcode: 20190,
          },
          200305: {
            name: {
              th: 'เขาซก',
              en: 'Khao Sok',
            },
            coordinates: {
              lat: '13.070',
              lng: '101.420',
            },
            zipcode: 20190,
          },
        },
      },
      2004: {
        name: {
          th: 'บางละมุง',
          en: 'Bang Lamung',
        },
        tambons: {
          200401: {
            name: {
              th: 'บางละมุง',
              en: null,
            },
            coordinates: {
              lat: '13.052',
              lng: '100.922',
            },
            zipcode: 20150,
          },
          200402: {
            name: {
              th: 'หนองปรือ',
              en: 'Nong Prue',
            },
            coordinates: {
              lat: '12.920',
              lng: '100.935',
            },
            zipcode: 20140,
          },
          200403: {
            name: {
              th: 'หนองปลาไหล',
              en: 'Nong Pla Lai',
            },
            coordinates: {
              lat: '12.982',
              lng: '100.945',
            },
            zipcode: 20150,
          },
          200404: {
            name: {
              th: 'โป่ง',
              en: 'Pong',
            },
            coordinates: {
              lat: '12.933',
              lng: '100.987',
            },
            zipcode: 20150,
          },
          200405: {
            name: {
              th: 'เขาไม้แก้ว',
              en: 'Khao Mai Kaeo',
            },
            coordinates: {
              lat: '12.951',
              lng: '101.057',
            },
            zipcode: 20150,
          },
          200406: {
            name: {
              th: 'ห้วยใหญ่',
              en: 'Huai Yai',
            },
            coordinates: {
              lat: '12.835',
              lng: '100.985',
            },
            zipcode: 20150,
          },
          200407: {
            name: {
              th: 'ตะเคียนเตี้ย',
              en: 'Takhian Tia',
            },
            coordinates: {
              lat: '13.002',
              lng: '101.015',
            },
            zipcode: 20150,
          },
          200408: {
            name: {
              th: 'นาเกลือ',
              en: 'Na Kluea',
            },
            coordinates: {
              lat: '12.955',
              lng: '100.909',
            },
            zipcode: 20150,
          },
          200409: {
            name: {
              th: 'เขตการปกคองพิเศษพัทยา',
              en: null,
            },
            coordinates: {
              lat: '12.910',
              lng: '100.884',
            },
            zipcode: null,
          },
        },
      },
      2005: {
        name: {
          th: 'พานทอง',
          en: 'Phan Thong',
        },
        tambons: {
          200501: {
            name: {
              th: 'พานทอง',
              en: 'Phan Thong',
            },
            coordinates: {
              lat: '13.461',
              lng: '101.084',
            },
            zipcode: 20160,
          },
          200502: {
            name: {
              th: 'หนองตำลึง',
              en: 'Nong Tamlueng',
            },
            coordinates: {
              lat: '13.408',
              lng: '101.078',
            },
            zipcode: 20160,
          },
          200503: {
            name: {
              th: 'มาบโป่ง',
              en: 'Map Pong',
            },
            coordinates: {
              lat: '13.431',
              lng: '101.121',
            },
            zipcode: 20160,
          },
          200504: {
            name: {
              th: 'หนองกะขะ',
              en: 'Nong Kakha',
            },
            coordinates: {
              lat: '13.431',
              lng: '101.095',
            },
            zipcode: 20160,
          },
          200505: {
            name: {
              th: 'หนองหงษ์',
              en: 'Nong Hong',
            },
            coordinates: {
              lat: '13.389',
              lng: '101.113',
            },
            zipcode: 20160,
          },
          200506: {
            name: {
              th: 'โคกขี้หนอน',
              en: 'Khok Khi Non',
            },
            coordinates: {
              lat: '13.552',
              lng: '101.114',
            },
            zipcode: 20160,
          },
          200507: {
            name: {
              th: 'บ้านเก่า',
              en: 'Ban Kao',
            },
            coordinates: {
              lat: '13.454',
              lng: '101.059',
            },
            zipcode: 20160,
          },
          200508: {
            name: {
              th: 'หน้าประดู่',
              en: 'Na Pradu',
            },
            coordinates: {
              lat: '13.497',
              lng: '101.099',
            },
            zipcode: 20160,
          },
          200509: {
            name: {
              th: 'บางนาง',
              en: 'Bang Nang',
            },
            coordinates: {
              lat: '13.487',
              lng: '101.067',
            },
            zipcode: 20160,
          },
          200510: {
            name: {
              th: 'เกาะลอย',
              en: 'Ko Loi',
            },
            coordinates: {
              lat: '13.519',
              lng: '101.074',
            },
            zipcode: 20160,
          },
          200511: {
            name: {
              th: 'บางหัก',
              en: 'Bang Hak',
            },
            coordinates: {
              lat: '13.544',
              lng: '101.075',
            },
            zipcode: 20160,
          },
        },
      },
      2006: {
        name: {
          th: 'พนัสนิคม',
          en: 'Phanat Nikhom',
        },
        tambons: {
          200601: {
            name: {
              th: 'พนัสนิคม',
              en: 'Phanat Nikhom',
            },
            coordinates: {
              lat: '13.450',
              lng: '101.180',
            },
            zipcode: 20140,
          },
          200602: {
            name: {
              th: 'หน้าพระธาตุ',
              en: 'Na Phrathat',
            },
            coordinates: {
              lat: '13.468',
              lng: '101.147',
            },
            zipcode: 20140,
          },
          200603: {
            name: {
              th: 'วัดหลวง',
              en: 'Wat Luang',
            },
            coordinates: {
              lat: '13.518',
              lng: '101.161',
            },
            zipcode: 20140,
          },
          200604: {
            name: {
              th: 'บ้านเชิด',
              en: 'Ban Soet',
            },
            coordinates: {
              lat: '13.444',
              lng: '101.144',
            },
            zipcode: 20140,
          },
          200605: {
            name: {
              th: 'นาเริก',
              en: 'Na Roek',
            },
            coordinates: {
              lat: '13.405',
              lng: '101.248',
            },
            zipcode: 20140,
          },
          200606: {
            name: {
              th: 'หมอนนาง',
              en: 'Mon Nang',
            },
            coordinates: {
              lat: '13.346',
              lng: '101.257',
            },
            zipcode: 20140,
          },
          200607: {
            name: {
              th: 'สระสี่เหลี่ยม',
              en: 'Sa Si Liam',
            },
            coordinates: {
              lat: '13.554',
              lng: '101.239',
            },
            zipcode: 20140,
          },
          200608: {
            name: {
              th: 'วัดโบสถ์',
              en: 'Wat Bot',
            },
            coordinates: {
              lat: '13.498',
              lng: '101.139',
            },
            zipcode: 20140,
          },
          200609: {
            name: {
              th: 'กุฎโง้ง',
              en: 'Kut Ngong',
            },
            coordinates: {
              lat: '13.444',
              lng: '101.165',
            },
            zipcode: 20140,
          },
          200610: {
            name: {
              th: 'หัวถนน',
              en: 'Hua Thanon',
            },
            coordinates: {
              lat: '13.531',
              lng: '101.265',
            },
            zipcode: 20140,
          },
          200611: {
            name: {
              th: 'ท่าข้าม',
              en: 'Tha Kham',
            },
            coordinates: {
              lat: '13.559',
              lng: '101.177',
            },
            zipcode: 20140,
          },
          200613: {
            name: {
              th: 'หนองปรือ',
              en: 'Nong Prue',
            },
            coordinates: {
              lat: '13.511',
              lng: '101.254',
            },
            zipcode: null,
          },
          200614: {
            name: {
              th: 'หนองขยาด',
              en: 'Nong Khayat',
            },
            coordinates: {
              lat: '13.402',
              lng: '101.151',
            },
            zipcode: 20140,
          },
          200615: {
            name: {
              th: 'ทุ่งขวาง',
              en: 'Thung Khwang',
            },
            coordinates: {
              lat: '13.400',
              lng: '101.188',
            },
            zipcode: 20140,
          },
          200616: {
            name: {
              th: 'หนองเหียง',
              en: 'Nong Hiang',
            },
            coordinates: {
              lat: '13.464',
              lng: '101.330',
            },
            zipcode: 20140,
          },
          200617: {
            name: {
              th: 'นาวังหิน',
              en: 'Na Wang Hin',
            },
            coordinates: {
              lat: '13.442',
              lng: '101.251',
            },
            zipcode: 20140,
          },
          200618: {
            name: {
              th: 'บ้านช้าง',
              en: 'Ban Chang',
            },
            coordinates: {
              lat: '13.435',
              lng: '101.213',
            },
            zipcode: 20140,
          },
          200620: {
            name: {
              th: 'โคกเพลาะ',
              en: 'Khok Phlo',
            },
            coordinates: {
              lat: '13.520',
              lng: '101.121',
            },
            zipcode: 20140,
          },
          200621: {
            name: {
              th: 'ไร่หลักทอง',
              en: 'Rai Lak Thong',
            },
            coordinates: {
              lat: '13.484',
              lng: '101.184',
            },
            zipcode: 20140,
          },
        },
      },
      2007: {
        name: {
          th: 'ศรีราชา',
          en: 'Si Racha',
        },
        tambons: {
          200701: {
            name: {
              th: 'ศรีราชา',
              en: 'Si Racha',
            },
            coordinates: {
              lat: '13.162',
              lng: '100.923',
            },
            zipcode: 20110,
          },
          200702: {
            name: {
              th: 'สุรศักดิ์',
              en: 'Surasak',
            },
            coordinates: {
              lat: '13.160',
              lng: '100.983',
            },
            zipcode: 20110,
          },
          200703: {
            name: {
              th: 'ทุ่งสุขลา',
              en: null,
            },
            coordinates: {
              lat: '13.097',
              lng: '100.912',
            },
            zipcode: 20230,
          },
          200704: {
            name: {
              th: 'บึง',
              en: 'Bueng',
            },
            coordinates: {
              lat: '13.073',
              lng: '101.006',
            },
            zipcode: 20230,
          },
          200705: {
            name: {
              th: 'หนองขาม',
              en: 'Nong Kham',
            },
            coordinates: {
              lat: '13.134',
              lng: '101.038',
            },
            zipcode: 20230,
          },
          200706: {
            name: {
              th: 'เขาคันทรง',
              en: 'Khao Khan Song',
            },
            coordinates: {
              lat: '13.129',
              lng: '101.123',
            },
            zipcode: 20110,
          },
          200707: {
            name: {
              th: 'บางพระ',
              en: 'Bang Phra',
            },
            coordinates: {
              lat: '13.223',
              lng: '101.007',
            },
            zipcode: 20110,
          },
          200708: {
            name: {
              th: 'บ่อวิน',
              en: 'Bo Win',
            },
            coordinates: {
              lat: '13.052',
              lng: '101.093',
            },
            zipcode: 20230,
          },
        },
      },
      2008: {
        name: {
          th: 'เกาะสีชัง',
          en: 'Ko Sichang',
        },
        tambons: {
          200801: {
            name: {
              th: 'ท่าเทววงษ์',
              en: 'Tha Thewawong',
            },
            coordinates: {
              lat: '13.151',
              lng: '100.809',
            },
            zipcode: null,
          },
        },
      },
      2009: {
        name: {
          th: 'สัตหีบ',
          en: 'Sattahip',
        },
        tambons: {
          200901: {
            name: {
              th: 'สัตหีบ',
              en: 'Sattahip',
            },
            coordinates: {
              lat: '12.688',
              lng: '100.889',
            },
            zipcode: 20180,
          },
          200902: {
            name: {
              th: 'นาจอมเทียน',
              en: 'Na Chom Thian',
            },
            coordinates: {
              lat: '12.775',
              lng: '100.940',
            },
            zipcode: 20250,
          },
          200903: {
            name: {
              th: 'พลูตาหลวง',
              en: 'Phlu Ta Luang',
            },
            coordinates: {
              lat: '12.701',
              lng: '100.967',
            },
            zipcode: 20180,
          },
          200904: {
            name: {
              th: 'บางเสร่',
              en: 'Bang Sare',
            },
            coordinates: {
              lat: '12.744',
              lng: '100.908',
            },
            zipcode: 20250,
          },
          200905: {
            name: {
              th: 'แสมสาร',
              en: 'Samae San',
            },
            coordinates: {
              lat: '12.623',
              lng: '100.954',
            },
            zipcode: 20180,
          },
        },
      },
      2010: {
        name: {
          th: 'บ่อทอง',
          en: 'Bo Thong',
        },
        tambons: {
          201001: {
            name: {
              th: 'บ่อทอง',
              en: 'Bo Thong',
            },
            coordinates: {
              lat: '13.193',
              lng: '101.504',
            },
            zipcode: null,
          },
          201002: {
            name: {
              th: 'วัดสุวรรณ',
              en: 'Wat Suwan',
            },
            coordinates: {
              lat: '13.332',
              lng: '101.419',
            },
            zipcode: null,
          },
          201003: {
            name: {
              th: 'บ่อกวางทอง',
              en: 'Bo Kwang Thong',
            },
            coordinates: {
              lat: '13.318',
              lng: '101.308',
            },
            zipcode: null,
          },
          201004: {
            name: {
              th: 'ธาตุทอง',
              en: 'That Thong',
            },
            coordinates: {
              lat: '13.259',
              lng: '101.389',
            },
            zipcode: 20270,
          },
          201005: {
            name: {
              th: 'เกษตรสุวรรณ',
              en: 'Kaset Suwan',
            },
            coordinates: {
              lat: '13.326',
              lng: '101.526',
            },
            zipcode: 20270,
          },
          201006: {
            name: {
              th: 'พลวงทอง',
              en: 'Phluang Thong',
            },
            coordinates: {
              lat: '13.217',
              lng: '101.626',
            },
            zipcode: 20270,
          },
        },
      },
      2011: {
        name: {
          th: 'เกาะจันทร์',
          en: 'Ko Chan',
        },
        tambons: {
          201101: {
            name: {
              th: 'เกาะจันทร์',
              en: 'Ko Chan',
            },
            coordinates: {
              lat: '13.403',
              lng: '101.421',
            },
            zipcode: null,
          },
          201102: {
            name: {
              th: 'ท่าบุญมี',
              en: 'Tha Bunmi',
            },
            coordinates: {
              lat: '13.389',
              lng: '101.288',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  21: {
    name: {
      th: 'ระยอง',
      en: 'Rayong',
    },
    amphoes: {
      2101: {
        name: {
          th: 'เมืองระยอง',
          en: 'Mueang Rayong',
        },
        tambons: {
          210101: {
            name: {
              th: 'ท่าประดู่',
              en: 'Tha Pradu',
            },
            coordinates: {
              lat: '12.672',
              lng: '101.266',
            },
            zipcode: 21000,
          },
          210102: {
            name: {
              th: 'เชิงเนิน',
              en: 'Choeng Noen',
            },
            coordinates: {
              lat: '12.677',
              lng: '101.318',
            },
            zipcode: 21000,
          },
          210103: {
            name: {
              th: 'ตะพง',
              en: 'Taphong',
            },
            coordinates: {
              lat: '12.653',
              lng: '101.364',
            },
            zipcode: 21000,
          },
          210105: {
            name: {
              th: 'เพ',
              en: 'Phe',
            },
            coordinates: {
              lat: '12.629',
              lng: '101.418',
            },
            zipcode: 21160,
          },
          210106: {
            name: {
              th: 'แกลง',
              en: 'Klaeng',
            },
            coordinates: {
              lat: '12.663',
              lng: '101.467',
            },
            zipcode: 21160,
          },
          210107: {
            name: {
              th: 'บ้านแลง',
              en: 'Ban Laeng',
            },
            coordinates: {
              lat: '12.707',
              lng: '101.377',
            },
            zipcode: 21000,
          },
          210108: {
            name: {
              th: 'นาตาขวัญ',
              en: 'Na Ta Khwan',
            },
            coordinates: {
              lat: '12.741',
              lng: '101.369',
            },
            zipcode: 21000,
          },
          210109: {
            name: {
              th: 'เนินพระ',
              en: 'Noen Phra',
            },
            coordinates: {
              lat: '12.681',
              lng: '101.223',
            },
            zipcode: 21150,
          },
          210110: {
            name: {
              th: 'กระเฉด',
              en: 'Kachet',
            },
            coordinates: {
              lat: '12.843',
              lng: '101.456',
            },
            zipcode: 21100,
          },
          210111: {
            name: {
              th: 'ทับมา',
              en: 'Thap Ma',
            },
            coordinates: {
              lat: '12.708',
              lng: '101.247',
            },
            zipcode: 21000,
          },
          210112: {
            name: {
              th: 'น้ำคอก',
              en: 'Nam Khok',
            },
            coordinates: {
              lat: '12.716',
              lng: '101.269',
            },
            zipcode: 21000,
          },
          210114: {
            name: {
              th: 'มาบตาพุด',
              en: 'Maptaphut',
            },
            coordinates: {
              lat: '12.701',
              lng: '101.188',
            },
            zipcode: 21150,
          },
          210115: {
            name: {
              th: 'สำนักทอง',
              en: 'Samnak Thong',
            },
            coordinates: {
              lat: '12.721',
              lng: '101.472',
            },
            zipcode: 21100,
          },
        },
      },
      2102: {
        name: {
          th: 'บ้านฉาง',
          en: 'Ban Chang',
        },
        tambons: {
          210201: {
            name: {
              th: 'สำนักท้อน',
              en: 'Samnak Thon',
            },
            coordinates: {
              lat: '12.804',
              lng: '101.066',
            },
            zipcode: null,
          },
          210202: {
            name: {
              th: 'พลา',
              en: 'Phla',
            },
            coordinates: {
              lat: '12.688',
              lng: '101.025',
            },
            zipcode: null,
          },
          210203: {
            name: {
              th: 'บ้านฉาง',
              en: 'Ban Chang',
            },
            coordinates: {
              lat: '12.732',
              lng: '101.076',
            },
            zipcode: 21130,
          },
        },
      },
      2103: {
        name: {
          th: 'แกลง',
          en: 'Klaeng',
        },
        tambons: {
          210301: {
            name: {
              th: 'ทางเกวียน',
              en: 'Thang Kwian',
            },
            coordinates: {
              lat: '12.807',
              lng: '101.625',
            },
            zipcode: 21110,
          },
          210302: {
            name: {
              th: 'วังหว้า',
              en: 'Wang Wa',
            },
            coordinates: {
              lat: '12.763',
              lng: '101.624',
            },
            zipcode: 21110,
          },
          210303: {
            name: {
              th: 'ชากโดน',
              en: 'Chak Don',
            },
            coordinates: {
              lat: '12.700',
              lng: '101.604',
            },
            zipcode: 21110,
          },
          210304: {
            name: {
              th: 'เนินฆ้อ',
              en: 'Noen Kho',
            },
            coordinates: {
              lat: '12.704',
              lng: '101.678',
            },
            zipcode: 21110,
          },
          210305: {
            name: {
              th: 'กร่ำ',
              en: 'Kram',
            },
            coordinates: {
              lat: '12.668',
              lng: '101.631',
            },
            zipcode: 21190,
          },
          210306: {
            name: {
              th: 'ชากพง',
              en: 'Chak Phong',
            },
            coordinates: {
              lat: '12.668',
              lng: '101.549',
            },
            zipcode: 21190,
          },
          210307: {
            name: {
              th: 'กระแสบน',
              en: 'Krasae Bon',
            },
            coordinates: {
              lat: '12.871',
              lng: '101.611',
            },
            zipcode: 21110,
          },
          210308: {
            name: {
              th: 'บ้านนา',
              en: 'Ban Na',
            },
            coordinates: {
              lat: '12.846',
              lng: '101.674',
            },
            zipcode: 21110,
          },
          210309: {
            name: {
              th: 'ทุ่งควายกิน',
              en: 'Thung Khwai Kin',
            },
            coordinates: {
              lat: '12.808',
              lng: '101.733',
            },
            zipcode: 21110,
          },
          210310: {
            name: {
              th: 'กองดิน',
              en: 'Kong Din',
            },
            coordinates: {
              lat: '12.815',
              lng: '101.792',
            },
            zipcode: 22160,
          },
          210311: {
            name: {
              th: 'คลองปูน',
              en: 'Khlong Pun',
            },
            coordinates: {
              lat: '12.765',
              lng: '101.750',
            },
            zipcode: 21170,
          },
          210312: {
            name: {
              th: 'พังราด',
              en: 'Phang Rat',
            },
            coordinates: {
              lat: '12.729',
              lng: '101.772',
            },
            zipcode: 21170,
          },
          210313: {
            name: {
              th: 'ปากน้ำกระแส',
              en: 'Pak Nam Krasae',
            },
            coordinates: {
              lat: '12.717',
              lng: '101.709',
            },
            zipcode: 21170,
          },
          210317: {
            name: {
              th: 'ห้วยยาง',
              en: 'Huai Yang',
            },
            coordinates: {
              lat: '12.812',
              lng: '101.533',
            },
            zipcode: 21110,
          },
          210318: {
            name: {
              th: 'สองสลึง',
              en: 'Song Salueng',
            },
            coordinates: {
              lat: '12.743',
              lng: '101.548',
            },
            zipcode: 21110,
          },
        },
      },
      2104: {
        name: {
          th: 'วังจันทร์',
          en: 'Wang Chan',
        },
        tambons: {
          210401: {
            name: {
              th: 'วังจันทร์',
              en: 'Wang Chan',
            },
            coordinates: {
              lat: '12.858',
              lng: '101.528',
            },
            zipcode: null,
          },
          210402: {
            name: {
              th: 'ชุมแสง',
              en: 'Chum Saeng',
            },
            coordinates: {
              lat: '12.956',
              lng: '101.526',
            },
            zipcode: null,
          },
          210403: {
            name: {
              th: 'ป่ายุบใน',
              en: 'Pa Yup Nai',
            },
            coordinates: {
              lat: '13.005',
              lng: '101.479',
            },
            zipcode: 21210,
          },
          210404: {
            name: {
              th: 'พลงตาเอี่ยม',
              en: 'Phong Ta Iam',
            },
            coordinates: {
              lat: '12.912',
              lng: '101.541',
            },
            zipcode: 21210,
          },
        },
      },
      2105: {
        name: {
          th: 'บ้านค่าย',
          en: 'Ban Khai',
        },
        tambons: {
          210501: {
            name: {
              th: 'บ้านค่าย',
              en: 'Ban Khai',
            },
            coordinates: {
              lat: '12.775',
              lng: '101.304',
            },
            zipcode: 21120,
          },
          210502: {
            name: {
              th: 'หนองละลอก',
              en: 'Nong Lalok',
            },
            coordinates: {
              lat: '12.821',
              lng: '101.256',
            },
            zipcode: 21120,
          },
          210503: {
            name: {
              th: 'หนองตะพาน',
              en: 'Nong Taphan',
            },
            coordinates: {
              lat: '12.752',
              lng: '101.258',
            },
            zipcode: 21120,
          },
          210504: {
            name: {
              th: 'ตาขัน',
              en: 'Ta Khan',
            },
            coordinates: {
              lat: '12.735',
              lng: '101.321',
            },
            zipcode: 21120,
          },
          210505: {
            name: {
              th: 'บางบุตร',
              en: 'Bang But',
            },
            coordinates: {
              lat: '12.848',
              lng: '101.391',
            },
            zipcode: 21120,
          },
          210506: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '12.906',
              lng: '101.373',
            },
            zipcode: 21120,
          },
          210507: {
            name: {
              th: 'ชากบก',
              en: 'Chak Bok',
            },
            coordinates: {
              lat: '12.783',
              lng: '101.364',
            },
            zipcode: 21120,
          },
        },
      },
      2106: {
        name: {
          th: 'ปลวกแดง',
          en: 'Pluak Daeng',
        },
        tambons: {
          210601: {
            name: {
              th: 'ปลวกแดง',
              en: 'Pluak Daeng',
            },
            coordinates: {
              lat: '12.978',
              lng: '101.226',
            },
            zipcode: null,
          },
          210602: {
            name: {
              th: 'ตาสิทธิ์',
              en: 'Ta Sit',
            },
            coordinates: {
              lat: '13.038',
              lng: '101.225',
            },
            zipcode: null,
          },
          210603: {
            name: {
              th: 'ละหาร',
              en: 'Lahan',
            },
            coordinates: {
              lat: '12.973',
              lng: '101.311',
            },
            zipcode: null,
          },
          210604: {
            name: {
              th: 'แม่น้ำคู้',
              en: 'Maenam Khu',
            },
            coordinates: {
              lat: '12.908',
              lng: '101.230',
            },
            zipcode: null,
          },
          210605: {
            name: {
              th: 'มาบยางพร',
              en: 'Map Yang Phon',
            },
            coordinates: {
              lat: '12.970',
              lng: '101.136',
            },
            zipcode: null,
          },
          210606: {
            name: {
              th: 'หนองไร่',
              en: 'Nong Rai',
            },
            coordinates: {
              lat: '13.039',
              lng: '101.338',
            },
            zipcode: null,
          },
        },
      },
      2107: {
        name: {
          th: 'เขาชะเมา',
          en: 'Khao Chamao',
        },
        tambons: {
          210701: {
            name: {
              th: 'น้ำเป็น',
              en: 'Nam Pen',
            },
            coordinates: {
              lat: '12.901',
              lng: '101.749',
            },
            zipcode: null,
          },
          210702: {
            name: {
              th: 'ห้วยทับมอญ',
              en: 'Huai Thap Mon',
            },
            coordinates: {
              lat: '13.027',
              lng: '101.686',
            },
            zipcode: null,
          },
          210703: {
            name: {
              th: 'ชำฆ้อ',
              en: 'Cham Noi',
            },
            coordinates: {
              lat: '12.933',
              lng: '101.641',
            },
            zipcode: null,
          },
          210704: {
            name: {
              th: 'เขาน้อย',
              en: 'Khao Noi',
            },
            coordinates: {
              lat: '13.062',
              lng: '101.617',
            },
            zipcode: null,
          },
        },
      },
      2108: {
        name: {
          th: 'นิคมพัฒนา',
          en: 'Nikhom Phatthana',
        },
        tambons: {
          210801: {
            name: {
              th: 'นิคมพัฒนา',
              en: 'Nikhom Phatthana',
            },
            coordinates: {
              lat: '12.856',
              lng: '101.177',
            },
            zipcode: null,
          },
          210802: {
            name: {
              th: 'มาบข่า',
              en: 'Map Kha',
            },
            coordinates: {
              lat: '12.782',
              lng: '101.193',
            },
            zipcode: null,
          },
          210803: {
            name: {
              th: 'พนานิคม',
              en: 'Phana Nikhom',
            },
            coordinates: {
              lat: '12.902',
              lng: '101.135',
            },
            zipcode: null,
          },
          210804: {
            name: {
              th: 'มะขามคู่',
              en: 'Makham Khu',
            },
            coordinates: {
              lat: '12.847',
              lng: '101.101',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  22: {
    name: {
      th: 'จันทบุรี',
      en: 'Chanthaburi',
    },
    amphoes: {
      2201: {
        name: {
          th: 'เมืองจันทบุรี',
          en: 'Mueang Chanthaburi',
        },
        tambons: {
          220101: {
            name: {
              th: 'ตลาด',
              en: 'Talat',
            },
            coordinates: {
              lat: '12.591',
              lng: '102.087',
            },
            zipcode: 22000,
          },
          220102: {
            name: {
              th: 'วัดใหม่',
              en: 'Wat Mai',
            },
            coordinates: {
              lat: '12.611',
              lng: '102.105',
            },
            zipcode: 22000,
          },
          220103: {
            name: {
              th: 'คลองนารายณ์',
              en: 'Khlong Narai',
            },
            coordinates: {
              lat: '12.574',
              lng: '102.166',
            },
            zipcode: 22000,
          },
          220104: {
            name: {
              th: 'เกาะขวาง',
              en: 'Ko Khwang',
            },
            coordinates: {
              lat: '12.562',
              lng: '102.090',
            },
            zipcode: 22000,
          },
          220105: {
            name: {
              th: 'คมบาง',
              en: 'Kom Bang',
            },
            coordinates: {
              lat: '12.542',
              lng: '102.169',
            },
            zipcode: 22000,
          },
          220106: {
            name: {
              th: 'ท่าช้าง',
              en: 'Tha Chang',
            },
            coordinates: {
              lat: '12.644',
              lng: '102.098',
            },
            zipcode: 22000,
          },
          220107: {
            name: {
              th: 'จันทนิมิต',
              en: 'Chanthanimit',
            },
            coordinates: {
              lat: '12.610',
              lng: '102.123',
            },
            zipcode: 22000,
          },
          220108: {
            name: {
              th: 'บางกะจะ',
              en: 'Bang Kacha',
            },
            coordinates: {
              lat: '12.563',
              lng: '102.056',
            },
            zipcode: 22000,
          },
          220109: {
            name: {
              th: 'แสลง',
              en: 'Salaeng',
            },
            coordinates: {
              lat: '12.694',
              lng: '102.107',
            },
            zipcode: 22000,
          },
          220110: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '12.534',
              lng: '102.101',
            },
            zipcode: 22000,
          },
          220111: {
            name: {
              th: 'พลับพลา',
              en: 'Phlapphla',
            },
            coordinates: {
              lat: '12.605',
              lng: '102.172',
            },
            zipcode: 22000,
          },
        },
      },
      2202: {
        name: {
          th: 'ขลุง',
          en: 'Khlung',
        },
        tambons: {
          220201: {
            name: {
              th: 'ขลุง',
              en: 'Khlung',
            },
            coordinates: {
              lat: '12.452',
              lng: '102.218',
            },
            zipcode: 22110,
          },
          220202: {
            name: {
              th: 'บ่อ',
              en: 'Bo',
            },
            coordinates: {
              lat: '12.412',
              lng: '102.279',
            },
            zipcode: 22110,
          },
          220203: {
            name: {
              th: 'เกวียนหัก',
              en: 'Kwian Hak',
            },
            coordinates: {
              lat: '12.468',
              lng: '102.200',
            },
            zipcode: 22110,
          },
          220204: {
            name: {
              th: 'ตะปอน',
              en: 'Tapon',
            },
            coordinates: {
              lat: '12.493',
              lng: '102.175',
            },
            zipcode: 22110,
          },
          220205: {
            name: {
              th: 'บางชัน',
              en: 'Bang Chan',
            },
            coordinates: {
              lat: '12.346',
              lng: '102.310',
            },
            zipcode: 22110,
          },
          220206: {
            name: {
              th: 'วันยาว',
              en: 'Wan Yao',
            },
            coordinates: {
              lat: '12.439',
              lng: '102.234',
            },
            zipcode: 22110,
          },
          220207: {
            name: {
              th: 'ซึ้ง',
              en: 'Sueng',
            },
            coordinates: {
              lat: '12.487',
              lng: '102.288',
            },
            zipcode: 22110,
          },
          220208: {
            name: {
              th: 'มาบไพ',
              en: 'Map Phai',
            },
            coordinates: {
              lat: '12.598',
              lng: '102.258',
            },
            zipcode: 22110,
          },
          220209: {
            name: {
              th: 'วังสรรพรส',
              en: 'Wang Sappharot',
            },
            coordinates: {
              lat: '12.560',
              lng: '102.290',
            },
            zipcode: 22110,
          },
          220210: {
            name: {
              th: 'ตรอกนอง',
              en: 'Trok Nong',
            },
            coordinates: {
              lat: '12.533',
              lng: '102.275',
            },
            zipcode: 22110,
          },
          220211: {
            name: {
              th: 'ตกพรม',
              en: 'Tok Phrom',
            },
            coordinates: {
              lat: '12.625',
              lng: '102.339',
            },
            zipcode: 22110,
          },
          220212: {
            name: {
              th: 'บ่อเวฬุ',
              en: 'Bo Weru',
            },
            coordinates: {
              lat: '12.723',
              lng: '102.367',
            },
            zipcode: 22150,
          },
        },
      },
      2203: {
        name: {
          th: 'ท่าใหม่',
          en: 'Tha Mai',
        },
        tambons: {
          220301: {
            name: {
              th: 'ท่าใหม่',
              en: 'Tha Mai',
            },
            coordinates: {
              lat: '12.628',
              lng: '101.985',
            },
            zipcode: 22120,
          },
          220302: {
            name: {
              th: 'ยายร้า',
              en: 'Yai Ra',
            },
            coordinates: {
              lat: '12.612',
              lng: '102.027',
            },
            zipcode: 22120,
          },
          220303: {
            name: {
              th: 'สีพยา',
              en: 'Si Phaya',
            },
            coordinates: {
              lat: '12.578',
              lng: '102.029',
            },
            zipcode: 22120,
          },
          220304: {
            name: {
              th: 'บ่อพุ',
              en: 'Bo Phu',
            },
            coordinates: {
              lat: '12.595',
              lng: '102.028',
            },
            zipcode: 22120,
          },
          220305: {
            name: {
              th: 'พลอยแหวน',
              en: 'Phloi Waen',
            },
            coordinates: {
              lat: '12.617',
              lng: '102.053',
            },
            zipcode: 22120,
          },
          220306: {
            name: {
              th: 'เขาวัว',
              en: 'Khao Wua',
            },
            coordinates: {
              lat: '12.637',
              lng: '102.035',
            },
            zipcode: 22120,
          },
          220307: {
            name: {
              th: 'เขาบายศรี',
              en: 'Khao Baisi',
            },
            coordinates: {
              lat: '12.740',
              lng: '102.061',
            },
            zipcode: 22120,
          },
          220308: {
            name: {
              th: 'สองพี่น้อง',
              en: 'Song Phi Nong',
            },
            coordinates: {
              lat: '12.672',
              lng: '101.973',
            },
            zipcode: 22120,
          },
          220309: {
            name: {
              th: 'ทุ่งเบญจา',
              en: 'Thung Bencha',
            },
            coordinates: {
              lat: '12.814',
              lng: '102.014',
            },
            zipcode: 22170,
          },
          220311: {
            name: {
              th: 'รำพัน',
              en: 'Ramphan',
            },
            coordinates: {
              lat: '12.669',
              lng: '101.914',
            },
            zipcode: 22170,
          },
          220312: {
            name: {
              th: 'โขมง',
              en: 'Khamong',
            },
            coordinates: {
              lat: '12.620',
              lng: '101.940',
            },
            zipcode: 22170,
          },
          220313: {
            name: {
              th: 'ตะกาดเง้า',
              en: 'Takat Ngao',
            },
            coordinates: {
              lat: '12.581',
              lng: '101.989',
            },
            zipcode: 22120,
          },
          220314: {
            name: {
              th: 'คลองขุด',
              en: 'Khlong Khut',
            },
            coordinates: {
              lat: '12.563',
              lng: '101.935',
            },
            zipcode: 22120,
          },
          220324: {
            name: {
              th: 'เขาแก้ว',
              en: 'Khao Kaeo',
            },
            coordinates: {
              lat: '12.867',
              lng: '101.939',
            },
            zipcode: 22170,
          },
        },
      },
      2204: {
        name: {
          th: 'โป่งน้ำร้อน',
          en: 'Pong Nam Ron',
        },
        tambons: {
          220401: {
            name: {
              th: 'ทับไทร',
              en: 'Thap Sai',
            },
            coordinates: {
              lat: '12.950',
              lng: '102.247',
            },
            zipcode: 22140,
          },
          220402: {
            name: {
              th: 'โป่งน้ำร้อน',
              en: 'Pong Nam Ron',
            },
            coordinates: {
              lat: '12.890',
              lng: '102.382',
            },
            zipcode: 22140,
          },
          220404: {
            name: {
              th: 'หนองตาคง',
              en: 'Nong Ta Khong',
            },
            coordinates: {
              lat: '13.067',
              lng: '102.381',
            },
            zipcode: 22140,
          },
          220409: {
            name: {
              th: 'เทพนิมิต',
              en: 'Thep Nimit',
            },
            coordinates: {
              lat: '13.044',
              lng: '102.453',
            },
            zipcode: 22140,
          },
          220410: {
            name: {
              th: 'คลองใหญ่',
              en: 'Khlong Yai',
            },
            coordinates: {
              lat: '12.873',
              lng: '102.476',
            },
            zipcode: 22140,
          },
        },
      },
      2205: {
        name: {
          th: 'มะขาม',
          en: 'Makham',
        },
        tambons: {
          220501: {
            name: {
              th: 'มะขาม',
              en: 'Makham',
            },
            coordinates: {
              lat: '12.677',
              lng: '102.187',
            },
            zipcode: 22150,
          },
          220502: {
            name: {
              th: 'ท่าหลวง',
              en: 'Tha Luang',
            },
            coordinates: {
              lat: '12.700',
              lng: '102.147',
            },
            zipcode: 22150,
          },
          220503: {
            name: {
              th: 'ปัถวี',
              en: 'Patthawi',
            },
            coordinates: {
              lat: '12.743',
              lng: '102.272',
            },
            zipcode: 22150,
          },
          220504: {
            name: {
              th: 'วังแซ้ม',
              en: 'Wang Saem',
            },
            coordinates: {
              lat: '12.756',
              lng: '102.156',
            },
            zipcode: 22150,
          },
          220506: {
            name: {
              th: 'ฉมัน',
              en: 'Cha Man',
            },
            coordinates: {
              lat: '12.817',
              lng: '102.224',
            },
            zipcode: 22150,
          },
          220508: {
            name: {
              th: 'อ่างคีรี',
              en: 'Ang Khiri',
            },
            coordinates: {
              lat: '12.644',
              lng: '102.226',
            },
            zipcode: 22150,
          },
        },
      },
      2206: {
        name: {
          th: 'แหลมสิงห์',
          en: 'Laem Sing',
        },
        tambons: {
          220601: {
            name: {
              th: 'ปากน้ำแหลมสิงห์',
              en: 'Pak Nam Laem Sing',
            },
            coordinates: {
              lat: '12.452',
              lng: '102.122',
            },
            zipcode: 22130,
          },
          220602: {
            name: {
              th: 'เกาะเปริด',
              en: 'Ko Proet',
            },
            coordinates: {
              lat: '12.415',
              lng: '102.144',
            },
            zipcode: 22130,
          },
          220603: {
            name: {
              th: 'หนองชิ่ม',
              en: 'Nong Chim',
            },
            coordinates: {
              lat: '12.417',
              lng: '102.178',
            },
            zipcode: 22130,
          },
          220604: {
            name: {
              th: 'พลิ้ว',
              en: 'Phlio',
            },
            coordinates: {
              lat: '12.516',
              lng: '102.161',
            },
            zipcode: 22190,
          },
          220605: {
            name: {
              th: 'คลองน้ำเค็ม',
              en: 'Khlong Nam Khem',
            },
            coordinates: {
              lat: '12.498',
              lng: '102.123',
            },
            zipcode: 22190,
          },
          220606: {
            name: {
              th: 'บางสระเก้า',
              en: 'Bang Sa Kao',
            },
            coordinates: {
              lat: '12.511',
              lng: '102.109',
            },
            zipcode: 22190,
          },
          220607: {
            name: {
              th: 'บางกะไชย',
              en: 'Bang Kachai',
            },
            coordinates: {
              lat: '12.530',
              lng: '102.024',
            },
            zipcode: 22120,
          },
        },
      },
      2207: {
        name: {
          th: 'สอยดาว',
          en: 'Soi Dao',
        },
        tambons: {
          220701: {
            name: {
              th: 'ปะตง',
              en: 'Patong',
            },
            coordinates: {
              lat: '13.170',
              lng: '102.194',
            },
            zipcode: null,
          },
          220702: {
            name: {
              th: 'ทุ่งขนาน',
              en: 'Thung Khanan',
            },
            coordinates: {
              lat: '13.232',
              lng: '102.319',
            },
            zipcode: null,
          },
          220703: {
            name: {
              th: 'ทับช้าง',
              en: 'Thap Chang',
            },
            coordinates: {
              lat: '13.265',
              lng: '102.161',
            },
            zipcode: null,
          },
          220704: {
            name: {
              th: 'ทรายขาว',
              en: 'Sai Khao',
            },
            coordinates: {
              lat: '13.086',
              lng: '102.197',
            },
            zipcode: null,
          },
          220705: {
            name: {
              th: 'สะตอน',
              en: 'Saton',
            },
            coordinates: {
              lat: '13.174',
              lng: '102.371',
            },
            zipcode: null,
          },
        },
      },
      2208: {
        name: {
          th: 'แก่งหางแมว',
          en: 'Kaeng Hang Maeo',
        },
        tambons: {
          220801: {
            name: {
              th: 'แก่งหางแมว',
              en: 'Kaeng Hang Maeo',
            },
            coordinates: {
              lat: '12.982',
              lng: '101.929',
            },
            zipcode: null,
          },
          220802: {
            name: {
              th: 'ขุนซ่อง',
              en: 'Khun Song',
            },
            coordinates: {
              lat: '13.169',
              lng: '101.961',
            },
            zipcode: null,
          },
          220803: {
            name: {
              th: 'สามพี่น้อง',
              en: 'Sam Phi Nong',
            },
            coordinates: {
              lat: '12.977',
              lng: '101.817',
            },
            zipcode: null,
          },
          220804: {
            name: {
              th: 'พวา',
              en: 'Phawa',
            },
            coordinates: {
              lat: '13.105',
              lng: '101.791',
            },
            zipcode: null,
          },
          220805: {
            name: {
              th: 'เขาวงกต',
              en: 'Khao Wongkot',
            },
            coordinates: {
              lat: '12.881',
              lng: '101.848',
            },
            zipcode: null,
          },
        },
      },
      2209: {
        name: {
          th: 'นายายอาม',
          en: 'Na Yai Am',
        },
        tambons: {
          220901: {
            name: {
              th: 'นายายอาม',
              en: 'Na Yai Am',
            },
            coordinates: {
              lat: '12.782',
              lng: '101.847',
            },
            zipcode: null,
          },
          220902: {
            name: {
              th: 'วังโตนด',
              en: 'Wang Tanot',
            },
            coordinates: {
              lat: '12.701',
              lng: '101.933',
            },
            zipcode: null,
          },
          220903: {
            name: {
              th: 'กระแจะ',
              en: 'Krachae',
            },
            coordinates: {
              lat: '12.689',
              lng: '101.861',
            },
            zipcode: null,
          },
          220904: {
            name: {
              th: 'สนามไชย',
              en: 'Sanam Chai',
            },
            coordinates: {
              lat: '12.625',
              lng: '101.874',
            },
            zipcode: null,
          },
          220905: {
            name: {
              th: 'ช้างข้าม',
              en: 'Chang Kham',
            },
            coordinates: {
              lat: '12.707',
              lng: '101.819',
            },
            zipcode: null,
          },
          220906: {
            name: {
              th: 'วังใหม่',
              en: 'Wang Mai',
            },
            coordinates: {
              lat: '12.764',
              lng: '101.904',
            },
            zipcode: 22170,
          },
        },
      },
      2210: {
        name: {
          th: 'เขาคิชฌกูฏ',
          en: 'Khao Khitchakut',
        },
        tambons: {
          221001: {
            name: {
              th: 'ซากไทย',
              en: 'Chak Thai',
            },
            coordinates: {
              lat: '12.765',
              lng: '102.101',
            },
            zipcode: 22210,
          },
          221002: {
            name: {
              th: 'พลวง',
              en: 'Phluang',
            },
            coordinates: {
              lat: '12.822',
              lng: '102.106',
            },
            zipcode: null,
          },
          221003: {
            name: {
              th: 'ตะเคียนทอง',
              en: 'Takhian Thong',
            },
            coordinates: {
              lat: '12.903',
              lng: '102.132',
            },
            zipcode: null,
          },
          221004: {
            name: {
              th: 'คลองพลู',
              en: 'Khlong Phlu',
            },
            coordinates: {
              lat: '12.931',
              lng: '102.014',
            },
            zipcode: null,
          },
          221005: {
            name: {
              th: 'จันทเขลม',
              en: 'Chanthakhlem',
            },
            coordinates: {
              lat: '13.071',
              lng: '102.090',
            },
            zipcode: 22210,
          },
        },
      },
    },
  },
  23: {
    name: {
      th: 'ตราด',
      en: 'Trat',
    },
    amphoes: {
      2301: {
        name: {
          th: 'เมืองตราด',
          en: 'Mueang Trat',
        },
        tambons: {
          230102: {
            name: {
              th: 'หนองเสม็ด',
              en: 'Nong Samet',
            },
            coordinates: {
              lat: '12.216',
              lng: '102.504',
            },
            zipcode: 23000,
          },
          230103: {
            name: {
              th: 'หนองโสน',
              en: 'Nong Sano',
            },
            coordinates: {
              lat: '12.184',
              lng: '102.488',
            },
            zipcode: 23000,
          },
          230104: {
            name: {
              th: 'หนองคันทรง',
              en: 'Nong Khan Song',
            },
            coordinates: {
              lat: '12.194',
              lng: '102.542',
            },
            zipcode: 23000,
          },
          230105: {
            name: {
              th: 'ห้วงน้ำขาว',
              en: 'Huang Nam Khao',
            },
            coordinates: {
              lat: '12.143',
              lng: '102.518',
            },
            zipcode: 23000,
          },
          230106: {
            name: {
              th: 'อ่าวใหญ่',
              en: 'Ao Yai',
            },
            coordinates: {
              lat: '12.078',
              lng: '102.562',
            },
            zipcode: 23000,
          },
          230107: {
            name: {
              th: 'วังกระแจะ',
              en: 'Wang Krachae',
            },
            coordinates: {
              lat: '12.272',
              lng: '102.479',
            },
            zipcode: 23000,
          },
          230108: {
            name: {
              th: 'ห้วยแร้ง',
              en: 'Huai Raeng',
            },
            coordinates: {
              lat: '12.387',
              lng: '102.560',
            },
            zipcode: 23000,
          },
          230109: {
            name: {
              th: 'เนินทราย',
              en: 'Noen Sai',
            },
            coordinates: {
              lat: '12.283',
              lng: '102.547',
            },
            zipcode: 23000,
          },
          230110: {
            name: {
              th: 'ท่าพริก',
              en: 'Tha Phrik',
            },
            coordinates: {
              lat: '12.243',
              lng: '102.589',
            },
            zipcode: 23000,
          },
          230111: {
            name: {
              th: 'ท่ากุ่ม',
              en: 'Tha Kum',
            },
            coordinates: {
              lat: '12.345',
              lng: '102.675',
            },
            zipcode: 23000,
          },
          230112: {
            name: {
              th: 'ตะกาง',
              en: 'Takang',
            },
            coordinates: {
              lat: '12.250',
              lng: '102.659',
            },
            zipcode: 23000,
          },
          230113: {
            name: {
              th: 'ชำราก',
              en: 'Cham Rak',
            },
            coordinates: {
              lat: '12.194',
              lng: '102.677',
            },
            zipcode: 23000,
          },
          230114: {
            name: {
              th: 'แหลมกลัด',
              en: 'Laem Klat',
            },
            coordinates: {
              lat: '12.117',
              lng: '102.704',
            },
            zipcode: 23000,
          },
        },
      },
      2302: {
        name: {
          th: 'คลองใหญ่',
          en: 'Khlong Yai',
        },
        tambons: {
          230201: {
            name: {
              th: 'คลองใหญ่',
              en: 'Khlong Yai',
            },
            coordinates: {
              lat: '11.774',
              lng: '102.889',
            },
            zipcode: 23120,
          },
          230202: {
            name: {
              th: 'ไม้รูด',
              en: 'Mai Rut',
            },
            coordinates: {
              lat: '11.910',
              lng: '102.805',
            },
            zipcode: 23110,
          },
          230203: {
            name: {
              th: 'หาดเล็ก',
              en: 'Hat Lek',
            },
            coordinates: {
              lat: '11.699',
              lng: '102.909',
            },
            zipcode: 23110,
          },
        },
      },
      2303: {
        name: {
          th: 'เขาสมิง',
          en: 'Khao Saming',
        },
        tambons: {
          230301: {
            name: {
              th: 'เขาสมิง',
              en: 'Khao Saming',
            },
            coordinates: {
              lat: '12.340',
              lng: '102.437',
            },
            zipcode: 23130,
          },
          230302: {
            name: {
              th: 'แสนตุ้ง',
              en: 'Saen Tung',
            },
            coordinates: {
              lat: '12.398',
              lng: '102.382',
            },
            zipcode: 23150,
          },
          230303: {
            name: {
              th: 'วังตะเคียน',
              en: 'Wang Takhian',
            },
            coordinates: {
              lat: '12.478',
              lng: '102.531',
            },
            zipcode: 23130,
          },
          230304: {
            name: {
              th: 'ท่าโสม',
              en: 'Tha Som',
            },
            coordinates: {
              lat: '12.296',
              lng: '102.346',
            },
            zipcode: 23150,
          },
          230305: {
            name: {
              th: 'สะตอ',
              en: 'Sato',
            },
            coordinates: {
              lat: '12.553',
              lng: '102.434',
            },
            zipcode: 23150,
          },
          230306: {
            name: {
              th: 'ประณีต',
              en: 'Pranit',
            },
            coordinates: {
              lat: '12.525',
              lng: '102.359',
            },
            zipcode: 23150,
          },
          230307: {
            name: {
              th: 'เทพนิมิต',
              en: 'Thep Nimit',
            },
            coordinates: {
              lat: '12.469',
              lng: '102.435',
            },
            zipcode: 23150,
          },
          230308: {
            name: {
              th: 'ทุ่งนนทรี',
              en: 'Thung Nonsi',
            },
            coordinates: {
              lat: '12.405',
              lng: '102.501',
            },
            zipcode: 23130,
          },
        },
      },
      2304: {
        name: {
          th: 'บ่อไร่',
          en: 'Bo Rai',
        },
        tambons: {
          230401: {
            name: {
              th: 'บ่อพลอย',
              en: 'Bo Phloi',
            },
            coordinates: {
              lat: '12.604',
              lng: '102.559',
            },
            zipcode: null,
          },
          230402: {
            name: {
              th: 'ช้างทูน',
              en: 'Chang Thun',
            },
            coordinates: {
              lat: '12.585',
              lng: '102.469',
            },
            zipcode: null,
          },
          230403: {
            name: {
              th: 'ด่านชุมพล',
              en: 'Dan Chumphon',
            },
            coordinates: {
              lat: '12.461',
              lng: '102.664',
            },
            zipcode: null,
          },
          230404: {
            name: {
              th: 'หนองบอน',
              en: 'Nong Bon',
            },
            coordinates: {
              lat: '12.683',
              lng: '102.447',
            },
            zipcode: null,
          },
          230405: {
            name: {
              th: 'นนทรีย์',
              en: 'Nonsi',
            },
            coordinates: {
              lat: '12.544',
              lng: '102.600',
            },
            zipcode: 23140,
          },
        },
      },
      2305: {
        name: {
          th: 'แหลมงอบ',
          en: 'Laem Ngop',
        },
        tambons: {
          230501: {
            name: {
              th: 'แหลมงอบ',
              en: 'Laem Ngop',
            },
            coordinates: {
              lat: '12.186',
              lng: '102.419',
            },
            zipcode: 23120,
          },
          230502: {
            name: {
              th: 'น้ำเชี่ยว',
              en: 'Nam Chiao',
            },
            coordinates: {
              lat: '12.209',
              lng: '102.436',
            },
            zipcode: 23120,
          },
          230503: {
            name: {
              th: 'บางปิด',
              en: 'Bang Pit',
            },
            coordinates: {
              lat: '12.241',
              lng: '102.301',
            },
            zipcode: 23120,
          },
          230507: {
            name: {
              th: 'คลองใหญ่',
              en: 'Khlong Yai',
            },
            coordinates: {
              lat: '12.226',
              lng: '102.362',
            },
            zipcode: null,
          },
        },
      },
      2306: {
        name: {
          th: 'เกาะกูด',
          en: 'Ko Kut',
        },
        tambons: {
          230601: {
            name: {
              th: 'เกาะหมาก',
              en: 'Ko Mak',
            },
            coordinates: {
              lat: '11.818',
              lng: '102.481',
            },
            zipcode: null,
          },
          230602: {
            name: {
              th: 'เกาะกูด',
              en: 'Ko Kut',
            },
            coordinates: {
              lat: '11.657',
              lng: '102.567',
            },
            zipcode: null,
          },
        },
      },
      2307: {
        name: {
          th: 'เกาะช้าง',
          en: 'Ko Chang',
        },
        tambons: {
          230701: {
            name: {
              th: 'เกาะช้าง',
              en: 'Ko Chang',
            },
            coordinates: {
              lat: '12.070',
              lng: '102.321',
            },
            zipcode: null,
          },
          230702: {
            name: {
              th: 'เกาะช้างใต้',
              en: 'Ko Chang Tai',
            },
            coordinates: {
              lat: '11.996',
              lng: '102.331',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  24: {
    name: {
      th: 'ฉะเชิงเทรา',
      en: 'Chachoengsao',
    },
    amphoes: {
      2401: {
        name: {
          th: 'เมืองฉะเชิงเทรา',
          en: 'Mueang Chachoengsao',
        },
        tambons: {
          240101: {
            name: {
              th: 'หน้าเมือง',
              en: 'Na Mueang',
            },
            coordinates: {
              lat: '13.686',
              lng: '101.066',
            },
            zipcode: 24000,
          },
          240102: {
            name: {
              th: 'ท่าไข่',
              en: 'Tha Khai',
            },
            coordinates: {
              lat: '13.736',
              lng: '101.060',
            },
            zipcode: 24000,
          },
          240103: {
            name: {
              th: 'บ้านใหม่',
              en: 'Ban Mai',
            },
            coordinates: {
              lat: '13.707',
              lng: '101.100',
            },
            zipcode: 24000,
          },
          240104: {
            name: {
              th: 'คลองนา',
              en: 'Khlong Na',
            },
            coordinates: {
              lat: '13.648',
              lng: '101.102',
            },
            zipcode: 24000,
          },
          240105: {
            name: {
              th: 'บางตีนเป็ด',
              en: 'Bang Tin Pet',
            },
            coordinates: {
              lat: '13.661',
              lng: '101.075',
            },
            zipcode: 24000,
          },
          240106: {
            name: {
              th: 'บางไผ่',
              en: 'Bang Phai',
            },
            coordinates: {
              lat: '13.692',
              lng: '101.102',
            },
            zipcode: 24000,
          },
          240107: {
            name: {
              th: 'คลองจุกกระเฌอ',
              en: 'Khlong Chuk Khachoe',
            },
            coordinates: {
              lat: '13.686',
              lng: '101.125',
            },
            zipcode: 24000,
          },
          240108: {
            name: {
              th: 'บางแก้ว',
              en: 'Bang Kaeo',
            },
            coordinates: {
              lat: '13.765',
              lng: '101.094',
            },
            zipcode: 24000,
          },
          240109: {
            name: {
              th: 'บางขวัญ',
              en: 'Bang Khwan',
            },
            coordinates: {
              lat: '13.780',
              lng: '101.054',
            },
            zipcode: 24000,
          },
          240110: {
            name: {
              th: 'คลองนครเนื่องเขต',
              en: 'Khlong Nakhon Nueang Khet',
            },
            coordinates: {
              lat: '13.766',
              lng: '101.008',
            },
            zipcode: 24000,
          },
          240111: {
            name: {
              th: 'วังตะเคียน',
              en: 'Wang Takhian',
            },
            coordinates: {
              lat: '13.719',
              lng: '101.034',
            },
            zipcode: 24000,
          },
          240112: {
            name: {
              th: 'โสธร',
              en: 'Sothon',
            },
            coordinates: {
              lat: '13.680',
              lng: '101.053',
            },
            zipcode: 24000,
          },
          240113: {
            name: {
              th: 'บางพระ',
              en: 'Bang Phra',
            },
            coordinates: {
              lat: '13.653',
              lng: '101.020',
            },
            zipcode: 24000,
          },
          240114: {
            name: {
              th: 'บางกระไห',
              en: 'Bang Kahai',
            },
            coordinates: {
              lat: '13.670',
              lng: '101.002',
            },
            zipcode: 24000,
          },
          240115: {
            name: {
              th: 'หนามแดง',
              en: 'Nam Daeng',
            },
            coordinates: {
              lat: '13.736',
              lng: '100.989',
            },
            zipcode: 24000,
          },
          240116: {
            name: {
              th: 'คลองเปรง',
              en: 'Khlong Preng',
            },
            coordinates: {
              lat: '13.707',
              lng: '100.933',
            },
            zipcode: 24000,
          },
          240117: {
            name: {
              th: 'คลองอุดมชลจร',
              en: 'Khlong Udom Chonlachon',
            },
            coordinates: {
              lat: '13.736',
              lng: '100.920',
            },
            zipcode: 24000,
          },
          240118: {
            name: {
              th: 'คลองหลวงแพ่ง',
              en: 'Khlong Luang Phaeng',
            },
            coordinates: {
              lat: '13.780',
              lng: '100.927',
            },
            zipcode: 24000,
          },
          240119: {
            name: {
              th: 'บางเตย',
              en: 'Bang Toei',
            },
            coordinates: {
              lat: '13.706',
              lng: '100.993',
            },
            zipcode: 24000,
          },
        },
      },
      2402: {
        name: {
          th: 'บางคล้า',
          en: 'Bang Khla',
        },
        tambons: {
          240201: {
            name: {
              th: 'บางคล้า',
              en: 'Bang Khla',
            },
            coordinates: {
              lat: '13.722',
              lng: '101.210',
            },
            zipcode: 24110,
          },
          240204: {
            name: {
              th: 'บางสวน',
              en: 'Bang Suan',
            },
            coordinates: {
              lat: '13.696',
              lng: '101.177',
            },
            zipcode: 24110,
          },
          240208: {
            name: {
              th: 'บางกระเจ็ด',
              en: 'Bang Krachet',
            },
            coordinates: {
              lat: '13.852',
              lng: '101.208',
            },
            zipcode: 24110,
          },
          240209: {
            name: {
              th: 'ปากน้ำ',
              en: 'Pak Nam',
            },
            coordinates: {
              lat: '13.753',
              lng: '101.212',
            },
            zipcode: 24110,
          },
          240210: {
            name: {
              th: 'ท่าทองหลาง',
              en: 'Tha Thong Lang',
            },
            coordinates: {
              lat: '13.694',
              lng: '101.229',
            },
            zipcode: 24110,
          },
          240211: {
            name: {
              th: 'สาวชะโงก',
              en: 'Sao Cha-Ngo',
            },
            coordinates: {
              lat: '13.683',
              lng: '101.152',
            },
            zipcode: 24110,
          },
          240212: {
            name: {
              th: 'เสม็ดเหนือ',
              en: 'Samet Nuea',
            },
            coordinates: {
              lat: '13.673',
              lng: '101.201',
            },
            zipcode: 24110,
          },
          240213: {
            name: {
              th: 'เสม็ดใต้',
              en: 'Samet Tai',
            },
            coordinates: {
              lat: '13.653',
              lng: '101.162',
            },
            zipcode: 24110,
          },
          240214: {
            name: {
              th: 'หัวไทร',
              en: 'Hua Sai',
            },
            coordinates: {
              lat: '13.799',
              lng: '101.221',
            },
            zipcode: 24110,
          },
        },
      },
      2403: {
        name: {
          th: 'บางน้ำเปรี้ยว',
          en: 'Bang Nam Priao',
        },
        tambons: {
          240301: {
            name: {
              th: 'บางน้ำเปรี้ยว',
              en: 'Bang Nam Priao',
            },
            coordinates: {
              lat: '13.829',
              lng: '101.002',
            },
            zipcode: 24150,
          },
          240302: {
            name: {
              th: 'บางขนาก',
              en: 'Bang Khanak',
            },
            coordinates: {
              lat: '13.858',
              lng: '101.115',
            },
            zipcode: 24150,
          },
          240303: {
            name: {
              th: 'สิงโตทอง',
              en: 'Singto Thong',
            },
            coordinates: {
              lat: '13.911',
              lng: '101.073',
            },
            zipcode: 24150,
          },
          240304: {
            name: {
              th: 'หมอนทอง',
              en: 'Mon Thong',
            },
            coordinates: {
              lat: '13.874',
              lng: '101.043',
            },
            zipcode: 24150,
          },
          240305: {
            name: {
              th: 'บึงน้ำรักษ์',
              en: 'Bueng Nam Rak',
            },
            coordinates: {
              lat: '13.908',
              lng: '100.926',
            },
            zipcode: 24170,
          },
          240306: {
            name: {
              th: 'ดอนเกาะกา',
              en: 'Don Ko Ka',
            },
            coordinates: {
              lat: '13.944',
              lng: '101.016',
            },
            zipcode: 24170,
          },
          240307: {
            name: {
              th: 'โยธะกา',
              en: 'Yothaka',
            },
            coordinates: {
              lat: '13.925',
              lng: '101.128',
            },
            zipcode: 24150,
          },
          240308: {
            name: {
              th: 'ดอนฉิมพลี',
              en: 'Don Chimphli',
            },
            coordinates: {
              lat: '13.908',
              lng: '100.964',
            },
            zipcode: 24170,
          },
          240309: {
            name: {
              th: 'ศาลาแดง',
              en: 'Sala Daeng',
            },
            coordinates: {
              lat: '13.838',
              lng: '100.928',
            },
            zipcode: 24000,
          },
          240310: {
            name: {
              th: 'โพรงอากาศ',
              en: 'Phrong Akat',
            },
            coordinates: {
              lat: '13.827',
              lng: '101.062',
            },
            zipcode: 24150,
          },
        },
      },
      2404: {
        name: {
          th: 'บางปะกง',
          en: 'Bang Pakong',
        },
        tambons: {
          240401: {
            name: {
              th: 'บางปะกง',
              en: 'Bang Pakong',
            },
            coordinates: {
              lat: '13.496',
              lng: '100.966',
            },
            zipcode: 24130,
          },
          240402: {
            name: {
              th: 'ท่าสะอ้าน',
              en: 'Tha Sa-An',
            },
            coordinates: {
              lat: '13.553',
              lng: '100.995',
            },
            zipcode: 24130,
          },
          240403: {
            name: {
              th: 'บางวัว',
              en: 'Bang Wua',
            },
            coordinates: {
              lat: '13.569',
              lng: '100.964',
            },
            zipcode: 24130,
          },
          240404: {
            name: {
              th: 'บางสมัคร',
              en: 'Bang Samak',
            },
            coordinates: {
              lat: '13.575',
              lng: '100.936',
            },
            zipcode: 24180,
          },
          240405: {
            name: {
              th: 'บางผึ้ง',
              en: 'Bang Phueng',
            },
            coordinates: {
              lat: '13.527',
              lng: '101.054',
            },
            zipcode: 24130,
          },
          240406: {
            name: {
              th: 'บางเกลือ',
              en: 'Bang Kluea',
            },
            coordinates: {
              lat: '13.526',
              lng: '100.919',
            },
            zipcode: 24180,
          },
          240407: {
            name: {
              th: 'สองคลอง',
              en: 'Song Khlong',
            },
            coordinates: {
              lat: '13.484',
              lng: '100.910',
            },
            zipcode: 24130,
          },
          240408: {
            name: {
              th: 'หนองจอก',
              en: 'Nong Chok',
            },
            coordinates: {
              lat: '13.603',
              lng: '100.989',
            },
            zipcode: 24130,
          },
          240409: {
            name: {
              th: 'พิมพา',
              en: 'Phimpha',
            },
            coordinates: {
              lat: '13.599',
              lng: '100.937',
            },
            zipcode: 24180,
          },
          240410: {
            name: {
              th: 'ท่าข้าม',
              en: 'Tha Kham',
            },
            coordinates: {
              lat: '13.479',
              lng: '100.994',
            },
            zipcode: 24130,
          },
          240411: {
            name: {
              th: 'หอมศีล',
              en: 'Hom Sin',
            },
            coordinates: {
              lat: '13.543',
              lng: '100.892',
            },
            zipcode: 24180,
          },
          240412: {
            name: {
              th: 'เขาดิน',
              en: 'Khao Din',
            },
            coordinates: {
              lat: '13.528',
              lng: '101.026',
            },
            zipcode: 24130,
          },
        },
      },
      2405: {
        name: {
          th: 'บ้านโพธิ์',
          en: 'Ban Pho',
        },
        tambons: {
          240501: {
            name: {
              th: 'บ้านโพธิ์',
              en: 'Ban Pho',
            },
            coordinates: {
              lat: '13.588',
              lng: '101.086',
            },
            zipcode: 24140,
          },
          240502: {
            name: {
              th: 'เกาะไร่',
              en: 'Ko Rai',
            },
            coordinates: {
              lat: '13.678',
              lng: '100.958',
            },
            zipcode: 24140,
          },
          240503: {
            name: {
              th: 'คลองขุด',
              en: 'Khlong Khut',
            },
            coordinates: {
              lat: '13.611',
              lng: '101.194',
            },
            zipcode: 24140,
          },
          240504: {
            name: {
              th: 'คลองบ้านโพธิ์',
              en: 'Khlong Ban Pho',
            },
            coordinates: {
              lat: '13.572',
              lng: '101.057',
            },
            zipcode: 24140,
          },
          240505: {
            name: {
              th: 'คลองประเวศ',
              en: 'Khlong Prawet',
            },
            coordinates: {
              lat: '13.630',
              lng: '101.024',
            },
            zipcode: 24140,
          },
          240506: {
            name: {
              th: 'ดอนทราย',
              en: 'Don Sai',
            },
            coordinates: {
              lat: '13.631',
              lng: '101.125',
            },
            zipcode: 24140,
          },
          240507: {
            name: {
              th: 'เทพราช',
              en: 'Theppharat',
            },
            coordinates: {
              lat: '13.641',
              lng: '100.982',
            },
            zipcode: 24140,
          },
          240508: {
            name: {
              th: 'ท่าพลับ',
              en: 'Tha Phlap',
            },
            coordinates: {
              lat: '13.616',
              lng: '101.075',
            },
            zipcode: 24140,
          },
          240509: {
            name: {
              th: 'หนองตีนนก',
              en: 'Nong Tin Nok',
            },
            coordinates: {
              lat: '13.585',
              lng: '101.107',
            },
            zipcode: 24140,
          },
          240510: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '13.617',
              lng: '101.101',
            },
            zipcode: 24140,
          },
          240511: {
            name: {
              th: 'บางซ่อน',
              en: 'Bang Son',
            },
            coordinates: {
              lat: '13.558',
              lng: '101.058',
            },
            zipcode: 24140,
          },
          240512: {
            name: {
              th: 'บางกรูด',
              en: 'Bang Krut',
            },
            coordinates: {
              lat: '13.633',
              lng: '101.078',
            },
            zipcode: 24140,
          },
          240513: {
            name: {
              th: 'แหลมประดู่',
              en: 'Laem Pradu',
            },
            coordinates: {
              lat: '13.597',
              lng: '101.209',
            },
            zipcode: 24140,
          },
          240514: {
            name: {
              th: 'ลาดขวาง',
              en: 'Lat Khwang',
            },
            coordinates: {
              lat: '13.605',
              lng: '101.023',
            },
            zipcode: 24140,
          },
          240515: {
            name: {
              th: 'สนามจันทร์',
              en: 'Sanam Chan',
            },
            coordinates: {
              lat: '13.594',
              lng: '101.066',
            },
            zipcode: 24140,
          },
          240516: {
            name: {
              th: 'แสนภูดาษ',
              en: 'Saen Phu Dat',
            },
            coordinates: {
              lat: '13.582',
              lng: '101.015',
            },
            zipcode: 24140,
          },
          240517: {
            name: {
              th: 'สิบเอ็ดศอก',
              en: 'Sip Et Sok',
            },
            coordinates: {
              lat: '13.605',
              lng: '101.148',
            },
            zipcode: 24140,
          },
        },
      },
      2406: {
        name: {
          th: 'พนมสารคาม',
          en: 'Phanom Sarakham',
        },
        tambons: {
          240601: {
            name: {
              th: 'เกาะขนุน',
              en: 'Ko Khanun',
            },
            coordinates: {
              lat: '13.695',
              lng: '101.399',
            },
            zipcode: 24120,
          },
          240602: {
            name: {
              th: 'บ้านซ่อง',
              en: 'Ban Song',
            },
            coordinates: {
              lat: '13.803',
              lng: '101.403',
            },
            zipcode: 24120,
          },
          240603: {
            name: {
              th: 'พนมสารคาม',
              en: 'Phanom Sarakham',
            },
            coordinates: {
              lat: '13.771',
              lng: '101.324',
            },
            zipcode: 24120,
          },
          240604: {
            name: {
              th: 'เมืองเก่า',
              en: 'Mueang Kao',
            },
            coordinates: {
              lat: '13.728',
              lng: '101.319',
            },
            zipcode: 24120,
          },
          240605: {
            name: {
              th: 'หนองยาว',
              en: 'Nong Yao',
            },
            coordinates: {
              lat: '13.816',
              lng: '101.351',
            },
            zipcode: 24120,
          },
          240606: {
            name: {
              th: 'ท่าถ่าน',
              en: 'Tha Than',
            },
            coordinates: {
              lat: '13.760',
              lng: '101.383',
            },
            zipcode: 24120,
          },
          240607: {
            name: {
              th: 'หนองแหน',
              en: 'Nong Nae',
            },
            coordinates: {
              lat: '13.661',
              lng: '101.335',
            },
            zipcode: 24120,
          },
          240608: {
            name: {
              th: 'เขาหินซ้อน',
              en: 'Khao Hin Son',
            },
            coordinates: {
              lat: '13.750',
              lng: '101.494',
            },
            zipcode: 24120,
          },
        },
      },
      2407: {
        name: {
          th: 'ราชสาส์น',
          en: 'Ratchasan',
        },
        tambons: {
          240701: {
            name: {
              th: 'บางคา',
              en: 'Bang Kha',
            },
            coordinates: {
              lat: '13.788',
              lng: '101.278',
            },
            zipcode: 24120,
          },
          240702: {
            name: {
              th: 'เมืองใหม่',
              en: 'Mueang Mai',
            },
            coordinates: {
              lat: '13.715',
              lng: '101.278',
            },
            zipcode: 24120,
          },
          240703: {
            name: {
              th: 'ดงน้อย',
              en: 'Dong Noi',
            },
            coordinates: {
              lat: '13.836',
              lng: '101.291',
            },
            zipcode: 24120,
          },
        },
      },
      2408: {
        name: {
          th: 'สนามชัย',
          en: 'Sanam Chai Khet',
        },
        tambons: {
          240801: {
            name: {
              th: 'คู้ยายหมี',
              en: 'Khu Yai Mi',
            },
            coordinates: {
              lat: '13.644',
              lng: '101.461',
            },
            zipcode: 24160,
          },
          240802: {
            name: {
              th: 'ท่ากระดาน',
              en: 'Tha Kradan',
            },
            coordinates: {
              lat: '13.606',
              lng: '101.710',
            },
            zipcode: 24160,
          },
          240803: {
            name: {
              th: 'ทุ่งพระยา',
              en: 'Thung Phraya',
            },
            coordinates: {
              lat: '13.687',
              lng: '101.745',
            },
            zipcode: 24160,
          },
          240805: {
            name: {
              th: 'ลาดกระทิง',
              en: 'Lat Krathing',
            },
            coordinates: {
              lat: '13.548',
              lng: '101.475',
            },
            zipcode: 24160,
          },
        },
      },
      2409: {
        name: {
          th: 'แปลงยาว',
          en: 'Pleang Yao',
        },
        tambons: {
          240901: {
            name: {
              th: 'แปลงยาว',
              en: 'Plaeng Yao',
            },
            coordinates: {
              lat: '13.599',
              lng: '101.293',
            },
            zipcode: 24190,
          },
          240902: {
            name: {
              th: 'วังเย็น',
              en: 'Wang Yen',
            },
            coordinates: {
              lat: '13.536',
              lng: '101.334',
            },
            zipcode: 24190,
          },
          240903: {
            name: {
              th: 'หัวสำโรง',
              en: 'Hua Samrong',
            },
            coordinates: {
              lat: '13.624',
              lng: '101.315',
            },
            zipcode: 24190,
          },
          240904: {
            name: {
              th: 'หนองไม้แก่น',
              en: 'Nong Mai Kaen',
            },
            coordinates: {
              lat: '13.504',
              lng: '101.404',
            },
            zipcode: 24190,
          },
        },
      },
      2410: {
        name: {
          th: 'ท่าตะเกียบ',
          en: 'Tha Takiap',
        },
        tambons: {
          241001: {
            name: {
              th: 'ท่าตะเกียบ',
              en: 'Tha Takiap',
            },
            coordinates: {
              lat: '13.508',
              lng: '101.639',
            },
            zipcode: null,
          },
          241002: {
            name: {
              th: 'คลองตะเกรา',
              en: 'Khlong Takrao',
            },
            coordinates: {
              lat: '13.299',
              lng: '101.739',
            },
            zipcode: null,
          },
        },
      },
      2411: {
        name: {
          th: 'คลองเขื่อน',
          en: 'Khlong Khuean',
        },
        tambons: {
          241101: {
            name: {
              th: 'ก้อนแก้ว',
              en: 'Kon Kaeo',
            },
            coordinates: {
              lat: '13.765',
              lng: '101.136',
            },
            zipcode: null,
          },
          241102: {
            name: {
              th: 'คลองเขื่อน',
              en: 'Khlong Khuean',
            },
            coordinates: {
              lat: '13.772',
              lng: '101.185',
            },
            zipcode: null,
          },
          241103: {
            name: {
              th: 'บางเล่า',
              en: 'Bang Lao',
            },
            coordinates: {
              lat: '13.721',
              lng: '101.150',
            },
            zipcode: null,
          },
          241104: {
            name: {
              th: 'บางโรง',
              en: 'Bang Rong',
            },
            coordinates: {
              lat: '13.824',
              lng: '101.137',
            },
            zipcode: null,
          },
          241105: {
            name: {
              th: 'บางตลาด',
              en: 'Bang Talat',
            },
            coordinates: {
              lat: '13.736',
              lng: '101.165',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  25: {
    name: {
      th: 'ปราจีนบุรี',
      en: 'Prachin Buri',
    },
    amphoes: {
      2501: {
        name: {
          th: 'เมืองปราจีนบุรี',
          en: 'Mueang Prachin Buri',
        },
        tambons: {
          250101: {
            name: {
              th: 'หน้าเมือง',
              en: 'Na Mueang',
            },
            coordinates: {
              lat: '14.057',
              lng: '101.374',
            },
            zipcode: 25000,
          },
          250102: {
            name: {
              th: 'รอบเมือง',
              en: 'Rop Mueang',
            },
            coordinates: {
              lat: '14.066',
              lng: '101.327',
            },
            zipcode: 25000,
          },
          250103: {
            name: {
              th: 'วัดโบสถ์',
              en: 'Wat Bot',
            },
            coordinates: {
              lat: '14.034',
              lng: '101.310',
            },
            zipcode: 25000,
          },
          250104: {
            name: {
              th: 'บางเดชะ',
              en: 'Bang Decha',
            },
            coordinates: {
              lat: '13.991',
              lng: '101.330',
            },
            zipcode: 25000,
          },
          250105: {
            name: {
              th: 'ท่างาม',
              en: 'Tha Ngam',
            },
            coordinates: {
              lat: '14.017',
              lng: '101.413',
            },
            zipcode: 25000,
          },
          250106: {
            name: {
              th: 'บางบริบูรณ์',
              en: 'Bang Boribun',
            },
            coordinates: {
              lat: '14.039',
              lng: '101.429',
            },
            zipcode: 25000,
          },
          250107: {
            name: {
              th: 'ดงพระราม',
              en: 'Dong Phraram',
            },
            coordinates: {
              lat: '14.072',
              lng: '101.394',
            },
            zipcode: 25000,
          },
          250108: {
            name: {
              th: 'บ้านพระ',
              en: 'Ban Phra',
            },
            coordinates: {
              lat: '14.116',
              lng: '101.361',
            },
            zipcode: 25230,
          },
          250109: {
            name: {
              th: 'โคกไม้ลาย',
              en: 'Khok Mai Lai',
            },
            coordinates: {
              lat: '14.140',
              lng: '101.298',
            },
            zipcode: 25230,
          },
          250111: {
            name: {
              th: 'ดงขี้เหล็ก',
              en: 'Dong Khi Lek',
            },
            coordinates: {
              lat: '14.132',
              lng: '101.439',
            },
            zipcode: 25000,
          },
          250112: {
            name: {
              th: 'เนินหอม',
              en: 'Noen Hom',
            },
            coordinates: {
              lat: '14.181',
              lng: '101.378',
            },
            zipcode: 25230,
          },
          250113: {
            name: {
              th: 'โนนห้อม',
              en: 'Non Hom',
            },
            coordinates: {
              lat: '14.094',
              lng: '101.463',
            },
            zipcode: 25000,
          },
        },
      },
      2502: {
        name: {
          th: 'กบินทร์บุรี',
          en: 'Kabin Buri',
        },
        tambons: {
          250201: {
            name: {
              th: 'กบินทร์',
              en: 'Kabin',
            },
            coordinates: {
              lat: '13.939',
              lng: '101.711',
            },
            zipcode: 25110,
          },
          250202: {
            name: {
              th: 'เมืองเก่า',
              en: 'Mueang Kao',
            },
            coordinates: {
              lat: '13.999',
              lng: '101.770',
            },
            zipcode: 25240,
          },
          250203: {
            name: {
              th: 'วังดาล',
              en: 'Wang Dan',
            },
            coordinates: {
              lat: '14.006',
              lng: '101.645',
            },
            zipcode: 25110,
          },
          250204: {
            name: {
              th: 'นนทรี',
              en: 'Nonsi',
            },
            coordinates: {
              lat: '14.056',
              lng: '101.668',
            },
            zipcode: 25110,
          },
          250205: {
            name: {
              th: 'ย่านรี',
              en: 'Yan Ri',
            },
            coordinates: {
              lat: '13.897',
              lng: '101.785',
            },
            zipcode: 25110,
          },
          250206: {
            name: {
              th: 'วังตะเคียน',
              en: 'Wang Takhian',
            },
            coordinates: {
              lat: '13.849',
              lng: '101.870',
            },
            zipcode: 25110,
          },
          250207: {
            name: {
              th: 'หาดนางแก้ว',
              en: 'Hat Nang Kaeo',
            },
            coordinates: {
              lat: '13.963',
              lng: '101.642',
            },
            zipcode: 25110,
          },
          250208: {
            name: {
              th: 'ลาดตะเคียน',
              en: 'Lat Takhian',
            },
            coordinates: {
              lat: '13.873',
              lng: '101.658',
            },
            zipcode: 25110,
          },
          250209: {
            name: {
              th: 'บ้านนา',
              en: 'Ban Na',
            },
            coordinates: {
              lat: '13.990',
              lng: '101.880',
            },
            zipcode: 25110,
          },
          250210: {
            name: {
              th: 'บ่อทอง',
              en: 'Bo Thong',
            },
            coordinates: {
              lat: '13.935',
              lng: '101.881',
            },
            zipcode: 25110,
          },
          250211: {
            name: {
              th: 'หนองกี่',
              en: 'Nong Ki',
            },
            coordinates: {
              lat: '14.068',
              lng: '101.841',
            },
            zipcode: 25110,
          },
          250212: {
            name: {
              th: 'นาแขม',
              en: 'Na Khaem',
            },
            coordinates: {
              lat: '14.052',
              lng: '101.723',
            },
            zipcode: 25110,
          },
          250213: {
            name: {
              th: 'เขาไม้แก้ว',
              en: 'Khao Mai Kaeo',
            },
            coordinates: {
              lat: '13.801',
              lng: '101.775',
            },
            zipcode: 25110,
          },
          250214: {
            name: {
              th: 'วังท่าช้าง',
              en: 'Wang Tha Chang',
            },
            coordinates: {
              lat: '13.694',
              lng: '101.885',
            },
            zipcode: 25110,
          },
        },
      },
      2503: {
        name: {
          th: 'นาดี',
          en: 'Na Di',
        },
        tambons: {
          250301: {
            name: {
              th: 'นาดี',
              en: 'Na Di',
            },
            coordinates: {
              lat: '14.189',
              lng: '101.792',
            },
            zipcode: null,
          },
          250302: {
            name: {
              th: 'สำพันตา',
              en: 'Sam Phan Ta',
            },
            coordinates: {
              lat: '14.081',
              lng: '101.766',
            },
            zipcode: 25220,
          },
          250303: {
            name: {
              th: 'สะพานหิน',
              en: 'Saphan Hin',
            },
            coordinates: {
              lat: '14.152',
              lng: '101.665',
            },
            zipcode: null,
          },
          250304: {
            name: {
              th: 'ทุ่งโพธิ์',
              en: 'Thung Pho',
            },
            coordinates: {
              lat: '14.141',
              lng: '101.889',
            },
            zipcode: null,
          },
          250305: {
            name: {
              th: 'แก่งดินสอ',
              en: 'Kaeng Dinso',
            },
            coordinates: {
              lat: '14.095',
              lng: '101.984',
            },
            zipcode: 25220,
          },
          250306: {
            name: {
              th: 'บุพราหมณ์',
              en: 'Bu Phram',
            },
            coordinates: {
              lat: '14.268',
              lng: '101.872',
            },
            zipcode: 25220,
          },
        },
      },
      2506: {
        name: {
          th: 'บ้านสร้าง',
          en: 'Ban Sang',
        },
        tambons: {
          250601: {
            name: {
              th: 'บ้านสร้าง',
              en: 'Ban Sang',
            },
            coordinates: {
              lat: '14.026',
              lng: '101.210',
            },
            zipcode: 25150,
          },
          250602: {
            name: {
              th: 'บางกระเบา',
              en: 'Bang Krabao',
            },
            coordinates: {
              lat: '13.984',
              lng: '101.202',
            },
            zipcode: 25150,
          },
          250603: {
            name: {
              th: 'บางเตย',
              en: 'Bang Toei',
            },
            coordinates: {
              lat: '13.946',
              lng: '101.223',
            },
            zipcode: 25150,
          },
          250604: {
            name: {
              th: 'บางยาง',
              en: 'Bang Yang',
            },
            coordinates: {
              lat: '13.940',
              lng: '101.175',
            },
            zipcode: 25150,
          },
          250605: {
            name: {
              th: 'บางแตน',
              en: 'Bang Taen',
            },
            coordinates: {
              lat: '13.890',
              lng: '101.177',
            },
            zipcode: 25150,
          },
          250606: {
            name: {
              th: 'บางพลวง',
              en: 'Bang Phluang',
            },
            coordinates: {
              lat: '13.986',
              lng: '101.282',
            },
            zipcode: 25150,
          },
          250607: {
            name: {
              th: 'บางปลาร้า',
              en: 'Bang Pla Ra',
            },
            coordinates: {
              lat: '13.926',
              lng: '101.313',
            },
            zipcode: 25150,
          },
          250608: {
            name: {
              th: 'บางขาม',
              en: 'Bang Kham',
            },
            coordinates: {
              lat: '13.893',
              lng: '101.274',
            },
            zipcode: 25150,
          },
          250609: {
            name: {
              th: 'กระทุ่มแพ้ว',
              en: 'Krathum Phaeo',
            },
            coordinates: {
              lat: '13.891',
              lng: '101.346',
            },
            zipcode: 25150,
          },
        },
      },
      2507: {
        name: {
          th: 'ประจันตคาม',
          en: 'Prachantakham',
        },
        tambons: {
          250701: {
            name: {
              th: 'ประจันตคาม',
              en: 'Prachantakham',
            },
            coordinates: {
              lat: '14.055',
              lng: '101.497',
            },
            zipcode: 25130,
          },
          250702: {
            name: {
              th: 'เกาะลอย',
              en: 'Ko Loi',
            },
            coordinates: {
              lat: '14.021',
              lng: '101.515',
            },
            zipcode: 25130,
          },
          250703: {
            name: {
              th: 'บ้านหอย',
              en: 'Ban Hoi',
            },
            coordinates: {
              lat: '13.996',
              lng: '101.567',
            },
            zipcode: 25130,
          },
          250704: {
            name: {
              th: 'หนองแสง',
              en: 'Nong Saeng',
            },
            coordinates: {
              lat: '14.052',
              lng: '101.563',
            },
            zipcode: 25130,
          },
          250705: {
            name: {
              th: 'ดงบัง',
              en: 'Dong Bang',
            },
            coordinates: {
              lat: '14.020',
              lng: '101.579',
            },
            zipcode: 25130,
          },
          250706: {
            name: {
              th: 'คำโตนด',
              en: 'Kham Tanot',
            },
            coordinates: {
              lat: '14.112',
              lng: '101.597',
            },
            zipcode: 25130,
          },
          250707: {
            name: {
              th: 'บุฝ้าย',
              en: 'Bu Fai',
            },
            coordinates: {
              lat: '14.298',
              lng: '101.601',
            },
            zipcode: 25130,
          },
          250708: {
            name: {
              th: 'หนองแก้ว',
              en: 'Nong Kaeo',
            },
            coordinates: {
              lat: '14.111',
              lng: '101.548',
            },
            zipcode: 25130,
          },
          250709: {
            name: {
              th: 'โพธิ์งาม',
              en: null,
            },
            coordinates: {
              lat: '14.214',
              lng: '101.466',
            },
            zipcode: 25130,
          },
        },
      },
      2508: {
        name: {
          th: 'ศรีมหาโพธิ',
          en: 'Si Maha Phot',
        },
        tambons: {
          250801: {
            name: {
              th: 'ศรีมหาโพธิ',
              en: 'Si Mahapho',
            },
            coordinates: {
              lat: '13.849',
              lng: '101.567',
            },
            zipcode: 25140,
          },
          250802: {
            name: {
              th: 'สัมพันธ์',
              en: 'Samphan',
            },
            coordinates: {
              lat: '13.997',
              lng: '101.480',
            },
            zipcode: 25140,
          },
          250803: {
            name: {
              th: 'บ้านทาม',
              en: 'Ban Tham',
            },
            coordinates: {
              lat: '13.976',
              lng: '101.552',
            },
            zipcode: 25140,
          },
          250804: {
            name: {
              th: 'ท่าตูม',
              en: 'Tha Tum',
            },
            coordinates: {
              lat: '13.932',
              lng: '101.571',
            },
            zipcode: 25140,
          },
          250805: {
            name: {
              th: 'บางกุ้ง',
              en: 'Bang Kung',
            },
            coordinates: {
              lat: '13.964',
              lng: '101.483',
            },
            zipcode: 25140,
          },
          250806: {
            name: {
              th: 'ดงกระทงยาม',
              en: 'Dong Krathong Yam',
            },
            coordinates: {
              lat: '13.958',
              lng: '101.426',
            },
            zipcode: 25140,
          },
          250807: {
            name: {
              th: 'หนองโพรง',
              en: 'Nong Phrong',
            },
            coordinates: {
              lat: '13.787',
              lng: '101.581',
            },
            zipcode: 25140,
          },
          250808: {
            name: {
              th: 'หัวหว้า',
              en: 'Hua Wa',
            },
            coordinates: {
              lat: '13.908',
              lng: '101.461',
            },
            zipcode: 25140,
          },
          250809: {
            name: {
              th: 'หาดยาง',
              en: 'Hat Yang',
            },
            coordinates: {
              lat: '13.988',
              lng: '101.432',
            },
            zipcode: 25140,
          },
          250810: {
            name: {
              th: 'กรอกสมบูรณ์',
              en: 'Krok Sombun',
            },
            coordinates: {
              lat: '13.767',
              lng: '101.660',
            },
            zipcode: 25140,
          },
        },
      },
      2509: {
        name: {
          th: 'ศรีมโหสถ',
          en: 'Si Mahosot',
        },
        tambons: {
          250901: {
            name: {
              th: 'โคกปีบ',
              en: 'Khok Pip',
            },
            coordinates: {
              lat: '13.880',
              lng: '101.412',
            },
            zipcode: null,
          },
          250902: {
            name: {
              th: 'โคกไทย',
              en: 'Khok Thai',
            },
            coordinates: {
              lat: '13.845',
              lng: '101.448',
            },
            zipcode: null,
          },
          250903: {
            name: {
              th: 'คู้ลำพัน',
              en: 'Khu Lamphan',
            },
            coordinates: {
              lat: '13.926',
              lng: '101.410',
            },
            zipcode: null,
          },
          250904: {
            name: {
              th: 'ไผ่ชะเลือด',
              en: 'Phai Chalueat',
            },
            coordinates: {
              lat: '13.919',
              lng: '101.372',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  26: {
    name: {
      th: 'นครนายก',
      en: 'Nakhon Nayok',
    },
    amphoes: {
      2601: {
        name: {
          th: 'เมืองนครนายก',
          en: 'Mueang Nakhon Nayok',
        },
        tambons: {
          260101: {
            name: {
              th: 'นครนายก',
              en: 'Nakhon Nayok',
            },
            coordinates: {
              lat: '14.198',
              lng: '101.233',
            },
            zipcode: 26000,
          },
          260102: {
            name: {
              th: 'ท่าช้าง',
              en: 'Tha Chang',
            },
            coordinates: {
              lat: '14.192',
              lng: '101.176',
            },
            zipcode: 26000,
          },
          260103: {
            name: {
              th: 'บ้านใหญ่',
              en: 'Ban Yai',
            },
            coordinates: {
              lat: '14.221',
              lng: '101.225',
            },
            zipcode: 26000,
          },
          260104: {
            name: {
              th: 'วังกระโจม',
              en: 'Wang Krachom',
            },
            coordinates: {
              lat: '14.176',
              lng: '101.212',
            },
            zipcode: 26000,
          },
          260105: {
            name: {
              th: 'ท่าทราย',
              en: 'Tha Sai',
            },
            coordinates: {
              lat: '14.174',
              lng: '101.111',
            },
            zipcode: 26000,
          },
          260106: {
            name: {
              th: 'ดอนยอ',
              en: 'Don Yo',
            },
            coordinates: {
              lat: '14.128',
              lng: '101.109',
            },
            zipcode: 26000,
          },
          260107: {
            name: {
              th: 'ศรีจุฬา',
              en: 'Si Chula',
            },
            coordinates: {
              lat: '14.072',
              lng: '101.132',
            },
            zipcode: 26000,
          },
          260108: {
            name: {
              th: 'ดงละคร',
              en: 'Dong Lakhon',
            },
            coordinates: {
              lat: '14.136',
              lng: '101.167',
            },
            zipcode: 26000,
          },
          260109: {
            name: {
              th: 'ศรีนาวา',
              en: 'Si Nawa',
            },
            coordinates: {
              lat: '14.209',
              lng: '101.267',
            },
            zipcode: 26000,
          },
          260110: {
            name: {
              th: 'สาริกา',
              en: 'Sarika',
            },
            coordinates: {
              lat: '14.359',
              lng: '101.261',
            },
            zipcode: 26000,
          },
          260111: {
            name: {
              th: 'หินตั้ง',
              en: 'Hin Tang',
            },
            coordinates: {
              lat: '14.408',
              lng: '101.352',
            },
            zipcode: 26000,
          },
          260112: {
            name: {
              th: 'เขาพระ',
              en: 'Khao Phra',
            },
            coordinates: {
              lat: '14.301',
              lng: '101.212',
            },
            zipcode: 26000,
          },
          260113: {
            name: {
              th: 'พรหมณี',
              en: 'Phrommani',
            },
            coordinates: {
              lat: '14.264',
              lng: '101.164',
            },
            zipcode: 26000,
          },
        },
      },
      2602: {
        name: {
          th: 'ปากพลี',
          en: 'Pak Phli',
        },
        tambons: {
          260201: {
            name: {
              th: 'เกาะหวาย',
              en: 'Ko Wai',
            },
            coordinates: {
              lat: '14.175',
              lng: '101.271',
            },
            zipcode: 26130,
          },
          260202: {
            name: {
              th: 'เกาะโพธิ์',
              en: 'Ko Pho',
            },
            coordinates: {
              lat: '14.156',
              lng: '101.231',
            },
            zipcode: 26130,
          },
          260203: {
            name: {
              th: 'ปากพลี',
              en: 'Pak Phli',
            },
            coordinates: {
              lat: '14.132',
              lng: '101.263',
            },
            zipcode: 26130,
          },
          260204: {
            name: {
              th: 'โคกกรวด',
              en: 'Khok Kruat',
            },
            coordinates: {
              lat: '14.180',
              lng: '101.306',
            },
            zipcode: 26130,
          },
          260205: {
            name: {
              th: 'ท่าเรือ',
              en: 'Tha Ruea',
            },
            coordinates: {
              lat: '14.085',
              lng: '101.235',
            },
            zipcode: 26130,
          },
          260206: {
            name: {
              th: 'หนองแสง',
              en: 'Nong Saeng',
            },
            coordinates: {
              lat: '14.211',
              lng: '101.307',
            },
            zipcode: 26130,
          },
          260207: {
            name: {
              th: 'นาหินลาด',
              en: 'Na Hin Lat',
            },
            coordinates: {
              lat: '14.317',
              lng: '101.457',
            },
            zipcode: 26130,
          },
        },
      },
      2603: {
        name: {
          th: 'บ้านนา',
          en: 'Ban Na',
        },
        tambons: {
          260301: {
            name: {
              th: 'บ้านนา',
              en: 'Ban Na',
            },
            coordinates: {
              lat: '14.262',
              lng: '101.059',
            },
            zipcode: 26110,
          },
          260302: {
            name: {
              th: 'บ้านพร้าว',
              en: 'Ban Phrao',
            },
            coordinates: {
              lat: '14.222',
              lng: '101.101',
            },
            zipcode: 26110,
          },
          260303: {
            name: {
              th: 'บ้านพริก',
              en: 'Ban Phrik',
            },
            coordinates: {
              lat: '14.252',
              lng: '100.976',
            },
            zipcode: 26110,
          },
          260304: {
            name: {
              th: 'อาษา',
              en: 'A Sa',
            },
            coordinates: {
              lat: '14.226',
              lng: '101.009',
            },
            zipcode: 26110,
          },
          260305: {
            name: {
              th: 'ทองหลาง',
              en: 'Thong Lang',
            },
            coordinates: {
              lat: '14.207',
              lng: '101.037',
            },
            zipcode: 26110,
          },
          260306: {
            name: {
              th: 'บางอ้อ',
              en: 'Bang O',
            },
            coordinates: {
              lat: '14.176',
              lng: '101.069',
            },
            zipcode: 26110,
          },
          260307: {
            name: {
              th: 'พิกุลออก',
              en: 'Phikun Ok',
            },
            coordinates: {
              lat: '14.244',
              lng: '101.027',
            },
            zipcode: 26110,
          },
          260308: {
            name: {
              th: 'ป่าขะ',
              en: 'Pa Kha',
            },
            coordinates: {
              lat: '14.297',
              lng: '101.065',
            },
            zipcode: 26110,
          },
          260309: {
            name: {
              th: 'เขาเพิ่ม',
              en: 'Khao Phoem',
            },
            coordinates: {
              lat: '14.362',
              lng: '101.096',
            },
            zipcode: 26110,
          },
          260310: {
            name: {
              th: 'ศรีกะอาง',
              en: 'Si Ka-Ang',
            },
            coordinates: {
              lat: '14.308',
              lng: '101.117',
            },
            zipcode: 26110,
          },
        },
      },
      2604: {
        name: {
          th: 'องครักษ์',
          en: 'Ongkharak',
        },
        tambons: {
          260401: {
            name: {
              th: 'พระอาจารย์',
              en: null,
            },
            coordinates: {
              lat: '13.986',
              lng: '101.020',
            },
            zipcode: 26120,
          },
          260402: {
            name: {
              th: 'บึงศาล',
              en: 'Bueng San',
            },
            coordinates: {
              lat: '14.052',
              lng: '100.938',
            },
            zipcode: 26120,
          },
          260403: {
            name: {
              th: 'ศรีษะกระบือ',
              en: 'Sisa Krabue',
            },
            coordinates: {
              lat: '14.043',
              lng: '100.986',
            },
            zipcode: 26120,
          },
          260404: {
            name: {
              th: 'โพธิ์แทน',
              en: 'Pho Thaen',
            },
            coordinates: {
              lat: '14.208',
              lng: '100.949',
            },
            zipcode: 26120,
          },
          260405: {
            name: {
              th: 'บางสมบูรณ์',
              en: 'Bang Sombun',
            },
            coordinates: {
              lat: '14.021',
              lng: '101.112',
            },
            zipcode: 26120,
          },
          260406: {
            name: {
              th: 'ทรายมูล',
              en: 'Sai Mun',
            },
            coordinates: {
              lat: '14.123',
              lng: '101.069',
            },
            zipcode: 26120,
          },
          260407: {
            name: {
              th: 'บางปลากด',
              en: 'Bang Pla Kot',
            },
            coordinates: {
              lat: '14.166',
              lng: '100.971',
            },
            zipcode: 26120,
          },
          260408: {
            name: {
              th: 'บางลูกเสือ',
              en: 'Bang Luk Suea',
            },
            coordinates: {
              lat: '14.054',
              lng: '101.066',
            },
            zipcode: 26120,
          },
          260409: {
            name: {
              th: 'องครักษ์',
              en: 'Ongkharak',
            },
            coordinates: {
              lat: '14.107',
              lng: '101.005',
            },
            zipcode: 26120,
          },
          260410: {
            name: {
              th: 'ชุมพล',
              en: 'Chum Phon',
            },
            coordinates: {
              lat: '13.990',
              lng: '100.937',
            },
            zipcode: 26120,
          },
          260411: {
            name: {
              th: 'คลองใหญ่',
              en: 'Khlong Yai',
            },
            coordinates: {
              lat: '14.127',
              lng: '100.980',
            },
            zipcode: 26120,
          },
        },
      },
    },
  },
  27: {
    name: {
      th: 'สระแก้ว',
      en: 'Sa kaeo',
    },
    amphoes: {
      2701: {
        name: {
          th: 'เมืองสระแก้ว',
          en: 'Mueang Sa Kaeo',
        },
        tambons: {
          270101: {
            name: {
              th: 'สระแก้ว',
              en: 'Sa Kaeo',
            },
            coordinates: {
              lat: '13.803',
              lng: '102.077',
            },
            zipcode: 27000,
          },
          270102: {
            name: {
              th: 'บ้านแก้ง',
              en: 'Ban Kaeng',
            },
            coordinates: {
              lat: '13.984',
              lng: '101.990',
            },
            zipcode: 27000,
          },
          270103: {
            name: {
              th: 'ศาลาลำดวน',
              en: 'Sala Lamduan',
            },
            coordinates: {
              lat: '13.838',
              lng: '101.964',
            },
            zipcode: 27000,
          },
          270104: {
            name: {
              th: 'โคกปี่ฆ้อง',
              en: 'Khok Pi Khong',
            },
            coordinates: {
              lat: '13.925',
              lng: '102.028',
            },
            zipcode: 27000,
          },
          270105: {
            name: {
              th: 'ท่าแยก',
              en: 'Tha Yaek',
            },
            coordinates: {
              lat: '14.064',
              lng: '102.266',
            },
            zipcode: 27000,
          },
          270106: {
            name: {
              th: 'ท่าเกษม',
              en: 'Tha Kasem',
            },
            coordinates: {
              lat: '13.766',
              lng: '102.164',
            },
            zipcode: 27000,
          },
          270108: {
            name: {
              th: 'สระขวัญ',
              en: 'Sa Khwan',
            },
            coordinates: {
              lat: '13.763',
              lng: '102.008',
            },
            zipcode: 27000,
          },
          270111: {
            name: {
              th: 'หนองบอน',
              en: 'Nong Bon',
            },
            coordinates: {
              lat: '13.850',
              lng: '102.087',
            },
            zipcode: 27000,
          },
        },
      },
      2702: {
        name: {
          th: 'คลองหาด',
          en: 'Khlong Hat',
        },
        tambons: {
          270201: {
            name: {
              th: 'คลองหาด',
              en: 'Khlong Hat',
            },
            coordinates: {
              lat: '13.429',
              lng: '102.329',
            },
            zipcode: 27260,
          },
          270202: {
            name: {
              th: 'ไทยอุดม',
              en: 'Thai Udom',
            },
            coordinates: {
              lat: '13.407',
              lng: '102.260',
            },
            zipcode: 27260,
          },
          270203: {
            name: {
              th: 'ซับมะกรูด',
              en: 'Sap Makrut',
            },
            coordinates: {
              lat: '13.519',
              lng: '102.228',
            },
            zipcode: 27260,
          },
          270204: {
            name: {
              th: 'ไทรเดี่ยว',
              en: 'Sai Diao',
            },
            coordinates: {
              lat: '13.593',
              lng: '102.212',
            },
            zipcode: 27260,
          },
          270205: {
            name: {
              th: 'คลองไก่เถื่อน',
              en: 'Khlong Kai Thuean',
            },
            coordinates: {
              lat: '13.346',
              lng: '102.301',
            },
            zipcode: 27260,
          },
          270206: {
            name: {
              th: 'เบญจขร',
              en: 'Benchakhon',
            },
            coordinates: {
              lat: '13.542',
              lng: '102.307',
            },
            zipcode: 27260,
          },
          270207: {
            name: {
              th: 'ไทรทอง',
              en: 'Sai Thong',
            },
            coordinates: {
              lat: '13.614',
              lng: '102.309',
            },
            zipcode: 27260,
          },
        },
      },
      2703: {
        name: {
          th: 'ตาพระยา',
          en: 'Ta Phraya',
        },
        tambons: {
          270301: {
            name: {
              th: 'ตาพระยา',
              en: 'Ta Phraya',
            },
            coordinates: {
              lat: '13.956',
              lng: '102.764',
            },
            zipcode: 27180,
          },
          270302: {
            name: {
              th: 'ทัพเสด็จ',
              en: 'Thap Sadet',
            },
            coordinates: {
              lat: '14.047',
              lng: '102.813',
            },
            zipcode: 27180,
          },
          270306: {
            name: {
              th: 'ทัพราช',
              en: 'Thappharat',
            },
            coordinates: {
              lat: '14.079',
              lng: '102.639',
            },
            zipcode: 27180,
          },
          270307: {
            name: {
              th: 'ทัพไทย',
              en: 'Thap Thai',
            },
            coordinates: {
              lat: '14.123',
              lng: '102.845',
            },
            zipcode: 27180,
          },
          270309: {
            name: {
              th: 'โคคลาน',
              en: 'Khot Lan',
            },
            coordinates: {
              lat: '13.968',
              lng: '102.650',
            },
            zipcode: 27180,
          },
        },
      },
      2704: {
        name: {
          th: 'วังน้ำเย็น',
          en: 'Wang Nam Yen',
        },
        tambons: {
          270401: {
            name: {
              th: 'วังน้ำเย็น',
              en: 'Wang Nam Yen',
            },
            coordinates: {
              lat: '13.517',
              lng: '102.166',
            },
            zipcode: 27210,
          },
          270403: {
            name: {
              th: 'ตาหลังใน',
              en: 'Ta Lang Nai',
            },
            coordinates: {
              lat: '13.471',
              lng: '102.130',
            },
            zipcode: 27210,
          },
          270405: {
            name: {
              th: 'คลองหินปูน',
              en: 'Khlong Hin Pun',
            },
            coordinates: {
              lat: '13.606',
              lng: '102.104',
            },
            zipcode: 27210,
          },
          270406: {
            name: {
              th: 'ทุ่งมหาเจริญ',
              en: 'Thung Mahacharoen',
            },
            coordinates: {
              lat: '13.482',
              lng: '102.009',
            },
            zipcode: 27210,
          },
        },
      },
      2705: {
        name: {
          th: 'วัฒนานคร',
          en: 'Watthana Nakhon',
        },
        tambons: {
          270501: {
            name: {
              th: 'วัฒนานคร',
              en: 'Watthana Nakhon',
            },
            coordinates: {
              lat: '13.741',
              lng: '102.325',
            },
            zipcode: 27160,
          },
          270502: {
            name: {
              th: 'ท่าเกวียน',
              en: 'Tha Kwian',
            },
            coordinates: {
              lat: '13.666',
              lng: '102.251',
            },
            zipcode: 27160,
          },
          270503: {
            name: {
              th: 'ผักขะ',
              en: 'Phak Kha',
            },
            coordinates: {
              lat: '13.710',
              lng: '102.382',
            },
            zipcode: 27160,
          },
          270504: {
            name: {
              th: 'โนนหมากเค็ง',
              en: 'Non Mak Kheng',
            },
            coordinates: {
              lat: '13.824',
              lng: '102.315',
            },
            zipcode: 27160,
          },
          270505: {
            name: {
              th: 'หนองน้ำใส',
              en: 'Nong Nam Sai',
            },
            coordinates: {
              lat: '13.871',
              lng: '102.341',
            },
            zipcode: 27160,
          },
          270506: {
            name: {
              th: 'ช่องกุ่ม',
              en: 'Chong Kum',
            },
            coordinates: {
              lat: '14.001',
              lng: '102.449',
            },
            zipcode: 27160,
          },
          270507: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '13.799',
              lng: '102.372',
            },
            zipcode: null,
          },
          270508: {
            name: {
              th: 'แซร์ออ',
              en: 'Sae-O',
            },
            coordinates: {
              lat: '13.974',
              lng: '102.518',
            },
            zipcode: 27160,
          },
          270509: {
            name: {
              th: 'หนองหมากฝ้าย',
              en: 'Nong Mak Fai',
            },
            coordinates: {
              lat: '14.024',
              lng: '102.358',
            },
            zipcode: 27160,
          },
          270510: {
            name: {
              th: 'หนองตะเคียนบอน',
              en: 'Nong Takhian Bon',
            },
            coordinates: {
              lat: '13.916',
              lng: '102.265',
            },
            zipcode: 27160,
          },
          270511: {
            name: {
              th: 'ห้วยโจด',
              en: 'Huai Chot',
            },
            coordinates: {
              lat: '13.771',
              lng: '102.281',
            },
            zipcode: 27160,
          },
        },
      },
      2706: {
        name: {
          th: 'อรัญประเทศ',
          en: 'Aranyaprathet',
        },
        tambons: {
          270601: {
            name: {
              th: 'อรัญประเทศ',
              en: 'Aranya Prathet',
            },
            coordinates: {
              lat: '13.680',
              lng: '102.517',
            },
            zipcode: 27120,
          },
          270602: {
            name: {
              th: 'เมืองไผ่',
              en: 'Mueang Phai',
            },
            coordinates: {
              lat: '13.651',
              lng: '102.427',
            },
            zipcode: 27120,
          },
          270603: {
            name: {
              th: 'หันทราย',
              en: 'Han Sai',
            },
            coordinates: {
              lat: '13.806',
              lng: '102.452',
            },
            zipcode: 27120,
          },
          270604: {
            name: {
              th: 'คลองน้ำใส',
              en: 'Khlong Nam Sai',
            },
            coordinates: {
              lat: '13.590',
              lng: '102.514',
            },
            zipcode: 27120,
          },
          270605: {
            name: {
              th: 'ท่าข้าม',
              en: 'Tha Kham',
            },
            coordinates: {
              lat: '13.639',
              lng: '102.531',
            },
            zipcode: 27120,
          },
          270606: {
            name: {
              th: 'ป่าไร่',
              en: 'Pa Rai',
            },
            coordinates: {
              lat: '13.745',
              lng: '102.585',
            },
            zipcode: 27120,
          },
          270607: {
            name: {
              th: 'ทับพริก',
              en: 'Thap Phrik',
            },
            coordinates: {
              lat: '13.506',
              lng: '102.336',
            },
            zipcode: 27120,
          },
          270608: {
            name: {
              th: 'บ้านใหม่หนองไทร',
              en: 'Ban Mai Nong Sai',
            },
            coordinates: {
              lat: '13.715',
              lng: '102.510',
            },
            zipcode: 27120,
          },
          270609: {
            name: {
              th: 'ผ่านศึก',
              en: 'Phan Suek',
            },
            coordinates: {
              lat: '13.592',
              lng: '102.432',
            },
            zipcode: 27120,
          },
          270610: {
            name: {
              th: 'หนองสังข์',
              en: 'Nong Sang',
            },
            coordinates: {
              lat: '13.804',
              lng: '102.539',
            },
            zipcode: 27120,
          },
          270611: {
            name: {
              th: 'คลองทับจันทร์',
              en: 'Khlong Thap Chan',
            },
            coordinates: {
              lat: '13.643',
              lng: '102.387',
            },
            zipcode: 27120,
          },
          270612: {
            name: {
              th: 'ฟากห้วย',
              en: 'Fak Huai',
            },
            coordinates: {
              lat: '13.646',
              lng: '102.480',
            },
            zipcode: 27120,
          },
          270613: {
            name: {
              th: 'บ้านด่าน',
              en: 'Ban Dan',
            },
            coordinates: {
              lat: '13.746',
              lng: '102.526',
            },
            zipcode: 27120,
          },
        },
      },
      2707: {
        name: {
          th: 'เขาฉกรรจ์',
          en: 'Khao Chakan',
        },
        tambons: {
          270701: {
            name: {
              th: 'เขาฉกรรจ์',
              en: 'Khao Chakan',
            },
            coordinates: {
              lat: '13.663',
              lng: '102.098',
            },
            zipcode: null,
          },
          270702: {
            name: {
              th: 'หนองหว้า',
              en: 'Nong Wa',
            },
            coordinates: {
              lat: '13.672',
              lng: '102.037',
            },
            zipcode: null,
          },
          270703: {
            name: {
              th: 'พระเพลิง',
              en: 'Phra Phloeng',
            },
            coordinates: {
              lat: '13.596',
              lng: '102.042',
            },
            zipcode: null,
          },
          270704: {
            name: {
              th: 'เขาสามสิบ',
              en: 'Khao Sam Sip',
            },
            coordinates: {
              lat: '13.708',
              lng: '102.118',
            },
            zipcode: null,
          },
        },
      },
      2708: {
        name: {
          th: 'โคกสูง',
          en: 'Khok Sung',
        },
        tambons: {
          270801: {
            name: {
              th: 'โคกสูง',
              en: 'Khok Sung',
            },
            coordinates: {
              lat: '13.831',
              lng: '102.719',
            },
            zipcode: null,
          },
          270802: {
            name: {
              th: 'หนองม่วง',
              en: 'Nong Muang',
            },
            coordinates: {
              lat: '13.874',
              lng: '102.582',
            },
            zipcode: null,
          },
          270803: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '13.866',
              lng: '102.674',
            },
            zipcode: null,
          },
          270804: {
            name: {
              th: 'โนนหมากมุ่น',
              en: 'Non Mak Mun',
            },
            coordinates: {
              lat: '13.774',
              lng: '102.664',
            },
            zipcode: null,
          },
        },
      },
      2709: {
        name: {
          th: 'วังสมบูรณ์',
          en: 'Wang Sombun',
        },
        tambons: {
          270901: {
            name: {
              th: 'วังสมบูรณ์',
              en: 'Wang Sombun',
            },
            coordinates: {
              lat: '13.344',
              lng: '102.207',
            },
            zipcode: null,
          },
          270902: {
            name: {
              th: 'วังใหม่',
              en: 'Wang Mai',
            },
            coordinates: {
              lat: '13.413',
              lng: '102.039',
            },
            zipcode: null,
          },
          270903: {
            name: {
              th: 'วังทอง',
              en: 'Wang Thong',
            },
            coordinates: {
              lat: '13.338',
              lng: '102.081',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  30: {
    name: {
      th: 'นครราชสีมา',
      en: 'Nakhon Ratchasima',
    },
    amphoes: {
      3001: {
        name: {
          th: 'เมืองนครราชสีมา',
          en: 'Mueang Nakhon Ratchasima',
        },
        tambons: {
          300102: {
            name: {
              th: 'โพธิ์กลาง',
              en: 'Pho Klang',
            },
            coordinates: {
              lat: '14.906',
              lng: '102.102',
            },
            zipcode: 30000,
          },
          300103: {
            name: {
              th: 'หนองจะบก',
              en: 'Nong Chabok',
            },
            coordinates: {
              lat: '14.916',
              lng: '102.064',
            },
            zipcode: 30000,
          },
          300104: {
            name: {
              th: 'โคกสูง',
              en: 'Khok Sung',
            },
            coordinates: {
              lat: '15.095',
              lng: '102.116',
            },
            zipcode: 30310,
          },
          300105: {
            name: {
              th: 'มะเริง',
              en: 'Maroeng',
            },
            coordinates: {
              lat: '14.980',
              lng: '102.164',
            },
            zipcode: 30000,
          },
          300106: {
            name: {
              th: 'หนองระเวียง',
              en: 'Nong Rawiang',
            },
            coordinates: {
              lat: '14.946',
              lng: '102.199',
            },
            zipcode: 30000,
          },
          300107: {
            name: {
              th: 'ปรุใหญ่',
              en: 'Pru Yai',
            },
            coordinates: {
              lat: '14.994',
              lng: '102.052',
            },
            zipcode: 30000,
          },
          300108: {
            name: {
              th: 'หมื่นไวย',
              en: 'Muen Wai',
            },
            coordinates: {
              lat: '15.011',
              lng: '102.108',
            },
            zipcode: 30000,
          },
          300109: {
            name: {
              th: 'พลกรัง',
              en: 'Phon Krang',
            },
            coordinates: {
              lat: '15.026',
              lng: '102.011',
            },
            zipcode: 30000,
          },
          300110: {
            name: {
              th: 'หนองไผ่ล้อม',
              en: 'Nong Phai Lom',
            },
            coordinates: {
              lat: '14.947',
              lng: '102.085',
            },
            zipcode: 30000,
          },
          300111: {
            name: {
              th: 'หัวทะเล',
              en: 'Hua Thale',
            },
            coordinates: {
              lat: '14.965',
              lng: '102.140',
            },
            zipcode: 30000,
          },
          300112: {
            name: {
              th: 'บ้านเกาะ',
              en: 'Ban Ko',
            },
            coordinates: {
              lat: '14.981',
              lng: '102.091',
            },
            zipcode: 30000,
          },
          300114: {
            name: {
              th: 'พุดซา',
              en: 'Phut Sa',
            },
            coordinates: {
              lat: '15.049',
              lng: '102.043',
            },
            zipcode: 30000,
          },
          300115: {
            name: {
              th: 'บ้านโพธิ์',
              en: 'Ban Pho',
            },
            coordinates: {
              lat: '15.038',
              lng: '102.191',
            },
            zipcode: 30310,
          },
          300116: {
            name: {
              th: 'จอหอ',
              en: 'Cho Ho',
            },
            coordinates: {
              lat: '15.055',
              lng: '102.148',
            },
            zipcode: 30310,
          },
          300117: {
            name: {
              th: 'โคกกรวด',
              en: 'Khok Kruat',
            },
            coordinates: {
              lat: '14.897',
              lng: '101.974',
            },
            zipcode: 30280,
          },
          300118: {
            name: {
              th: 'ไชยมงคล',
              en: 'Chai Mongkhon',
            },
            coordinates: {
              lat: '14.849',
              lng: '102.080',
            },
            zipcode: 30000,
          },
          300119: {
            name: {
              th: 'หนองบัวศาลา',
              en: 'Nong Bua Sala',
            },
            coordinates: {
              lat: '14.899',
              lng: '102.152',
            },
            zipcode: 30000,
          },
          300120: {
            name: {
              th: 'สุรนารี',
              en: 'Suranari',
            },
            coordinates: {
              lat: '14.915',
              lng: '102.037',
            },
            zipcode: 30000,
          },
          300121: {
            name: {
              th: 'สีมุม',
              en: 'Si Mum',
            },
            coordinates: {
              lat: '14.986',
              lng: '102.007',
            },
            zipcode: 30000,
          },
          300122: {
            name: {
              th: 'ตลาด',
              en: 'Talat',
            },
            coordinates: {
              lat: '15.018',
              lng: '102.154',
            },
            zipcode: 30310,
          },
          300123: {
            name: {
              th: 'พะเนา',
              en: 'Phanao',
            },
            coordinates: {
              lat: '14.986',
              lng: '102.189',
            },
            zipcode: 30000,
          },
          300124: {
            name: {
              th: 'หนองกระทุ่ม',
              en: 'Nong Krathum',
            },
            coordinates: {
              lat: '15.009',
              lng: '102.086',
            },
            zipcode: 30000,
          },
          300125: {
            name: {
              th: 'หนองไข่น้ำ',
              en: 'Nong Khai Nam',
            },
            coordinates: {
              lat: '15.105',
              lng: '102.174',
            },
            zipcode: 30310,
          },
        },
      },
      3002: {
        name: {
          th: 'ครบุรี',
          en: 'Khon Buri',
        },
        tambons: {
          300201: {
            name: {
              th: 'แชะ',
              en: 'Chae',
            },
            coordinates: {
              lat: '14.569',
              lng: '102.278',
            },
            zipcode: 30250,
          },
          300202: {
            name: {
              th: 'เฉลียง',
              en: 'Chaliang',
            },
            coordinates: {
              lat: '14.489',
              lng: '102.279',
            },
            zipcode: 30250,
          },
          300203: {
            name: {
              th: 'ครบุรี',
              en: 'Khon Buri',
            },
            coordinates: {
              lat: '14.599',
              lng: '102.130',
            },
            zipcode: 30250,
          },
          300204: {
            name: {
              th: 'โคกกระชาย',
              en: 'Khok Krachai',
            },
            coordinates: {
              lat: '14.301',
              lng: '102.196',
            },
            zipcode: 30250,
          },
          300205: {
            name: {
              th: 'จระเข้หิน',
              en: 'Chorakhe Hin',
            },
            coordinates: {
              lat: '14.367',
              lng: '102.100',
            },
            zipcode: 30250,
          },
          300206: {
            name: {
              th: 'มาบตะโกเอน',
              en: 'Map Tako En',
            },
            coordinates: {
              lat: '14.570',
              lng: '102.385',
            },
            zipcode: 30250,
          },
          300207: {
            name: {
              th: 'อรพิมพ์',
              en: 'Oraphim',
            },
            coordinates: {
              lat: '14.569',
              lng: '102.207',
            },
            zipcode: 30250,
          },
          300208: {
            name: {
              th: 'บ้านใหม่',
              en: 'Ban Mai',
            },
            coordinates: {
              lat: '14.449',
              lng: '102.207',
            },
            zipcode: null,
          },
          300209: {
            name: {
              th: 'ลำเพียก',
              en: 'Lamphiak',
            },
            coordinates: {
              lat: '14.255',
              lng: '102.302',
            },
            zipcode: 30250,
          },
          300210: {
            name: {
              th: 'ครบุรีใต้',
              en: 'Khon Buri Tai',
            },
            coordinates: {
              lat: '14.529',
              lng: '102.103',
            },
            zipcode: 30250,
          },
          300211: {
            name: {
              th: 'ตะแบกบาน',
              en: 'Tabaek Ban',
            },
            coordinates: {
              lat: '14.462',
              lng: '102.343',
            },
            zipcode: 30250,
          },
          300212: {
            name: {
              th: 'สระว่านพระยา',
              en: 'Sa Wan Phraya',
            },
            coordinates: {
              lat: '14.528',
              lng: '102.384',
            },
            zipcode: 30250,
          },
        },
      },
      3003: {
        name: {
          th: 'เสิงสาง',
          en: 'Soeng Sang',
        },
        tambons: {
          300301: {
            name: {
              th: 'เสิงสาง',
              en: 'Soeng Sang',
            },
            coordinates: {
              lat: '14.426',
              lng: '102.429',
            },
            zipcode: 30330,
          },
          300302: {
            name: {
              th: 'สระตะเคียน',
              en: 'Sa Takhian',
            },
            coordinates: {
              lat: '14.325',
              lng: '102.552',
            },
            zipcode: 30330,
          },
          300303: {
            name: {
              th: 'โนนสมบูรณ์',
              en: 'Non Sombun',
            },
            coordinates: {
              lat: '14.278',
              lng: '102.398',
            },
            zipcode: 30330,
          },
          300304: {
            name: {
              th: 'กุดโบสถ์',
              en: 'Kut Bot',
            },
            coordinates: {
              lat: '14.457',
              lng: '102.540',
            },
            zipcode: 30330,
          },
          300305: {
            name: {
              th: 'สุขไพบูลย์',
              en: 'Suk Phaibun',
            },
            coordinates: {
              lat: '14.526',
              lng: '102.448',
            },
            zipcode: 30330,
          },
          300306: {
            name: {
              th: 'บ้านราษฎร์',
              en: 'Ban Rat',
            },
            coordinates: {
              lat: '14.247',
              lng: '102.474',
            },
            zipcode: 30330,
          },
        },
      },
      3004: {
        name: {
          th: 'คง',
          en: 'Khong',
        },
        tambons: {
          300401: {
            name: {
              th: 'เมืองคง',
              en: 'Mueang Khong',
            },
            coordinates: {
              lat: '15.451',
              lng: '102.334',
            },
            zipcode: 30260,
          },
          300402: {
            name: {
              th: 'คูขาด',
              en: 'Khu Khat',
            },
            coordinates: {
              lat: '15.445',
              lng: '102.407',
            },
            zipcode: 30260,
          },
          300403: {
            name: {
              th: 'เทพาลัย',
              en: 'Thephalai',
            },
            coordinates: {
              lat: '15.368',
              lng: '102.466',
            },
            zipcode: 30260,
          },
          300404: {
            name: {
              th: 'ตาจั่น',
              en: 'Ta Chan',
            },
            coordinates: {
              lat: '15.314',
              lng: '102.368',
            },
            zipcode: 30260,
          },
          300405: {
            name: {
              th: 'บ้านปรางค์',
              en: 'Ban Prang',
            },
            coordinates: {
              lat: '15.474',
              lng: '102.080',
            },
            zipcode: 30260,
          },
          300406: {
            name: {
              th: 'หนองมะนาว',
              en: 'Nong Manao',
            },
            coordinates: {
              lat: '15.500',
              lng: '102.280',
            },
            zipcode: 30260,
          },
          300407: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '15.496',
              lng: '102.167',
            },
            zipcode: 30260,
          },
          300408: {
            name: {
              th: 'โนนเต็ง',
              en: 'Non Teng',
            },
            coordinates: {
              lat: '15.449',
              lng: '102.266',
            },
            zipcode: 30260,
          },
          300409: {
            name: {
              th: 'ดอนใหญ่',
              en: 'Don Yai',
            },
            coordinates: {
              lat: '15.397',
              lng: '102.357',
            },
            zipcode: 30260,
          },
          300410: {
            name: {
              th: 'ขามสมบูรณ์',
              en: 'Kham Sombun',
            },
            coordinates: {
              lat: '15.371',
              lng: '102.403',
            },
            zipcode: 30260,
          },
        },
      },
      3005: {
        name: {
          th: 'บ้านเหลื่อม',
          en: 'Ban Lueam',
        },
        tambons: {
          300501: {
            name: {
              th: 'บ้านเหลื่อม',
              en: 'Ban Lueam',
            },
            coordinates: {
              lat: '15.625',
              lng: '102.136',
            },
            zipcode: 30350,
          },
          300502: {
            name: {
              th: 'วังโพธิ์',
              en: 'Wang Pho',
            },
            coordinates: {
              lat: '15.571',
              lng: '102.103',
            },
            zipcode: 30350,
          },
          300503: {
            name: {
              th: 'โคกกระเบื้อง',
              en: 'Khok Krabueang',
            },
            coordinates: {
              lat: '15.571',
              lng: '102.187',
            },
            zipcode: 30350,
          },
          300504: {
            name: {
              th: 'ช่อระกา',
              en: 'Cho Raka',
            },
            coordinates: {
              lat: '15.540',
              lng: '102.057',
            },
            zipcode: 30350,
          },
        },
      },
      3006: {
        name: {
          th: 'จักราช',
          en: 'Chakkarat',
        },
        tambons: {
          300601: {
            name: {
              th: 'จักราช',
              en: 'Chakkarat',
            },
            coordinates: {
              lat: '15.027',
              lng: '102.432',
            },
            zipcode: 30230,
          },
          300603: {
            name: {
              th: 'ทองหลาง',
              en: 'Thong Lang',
            },
            coordinates: {
              lat: '15.052',
              lng: '102.353',
            },
            zipcode: 30230,
          },
          300604: {
            name: {
              th: 'สีสุก',
              en: 'Si Suk',
            },
            coordinates: {
              lat: '14.874',
              lng: '102.385',
            },
            zipcode: 30440,
          },
          300605: {
            name: {
              th: 'หนองขาม',
              en: 'Nong Kham',
            },
            coordinates: {
              lat: '14.931',
              lng: '102.506',
            },
            zipcode: 30230,
          },
          300607: {
            name: {
              th: 'หนองพลวง',
              en: 'Nong Phluang',
            },
            coordinates: {
              lat: '15.070',
              lng: '102.424',
            },
            zipcode: 30180,
          },
          300610: {
            name: {
              th: 'ศรีละกอ',
              en: 'Si Lako',
            },
            coordinates: {
              lat: '14.938',
              lng: '102.427',
            },
            zipcode: 30230,
          },
          300611: {
            name: {
              th: 'คลองเมือง',
              en: 'Khlong Mueang',
            },
            coordinates: {
              lat: '14.852',
              lng: '102.502',
            },
            zipcode: 30230,
          },
          300613: {
            name: {
              th: 'หินโคน',
              en: 'Hin Khon',
            },
            coordinates: {
              lat: '15.013',
              lng: '102.498',
            },
            zipcode: 30230,
          },
        },
      },
      3007: {
        name: {
          th: 'โชคชัย',
          en: 'Chok Chai',
        },
        tambons: {
          300701: {
            name: {
              th: 'กระโทก',
              en: 'Krathok',
            },
            coordinates: {
              lat: '14.714',
              lng: '102.189',
            },
            zipcode: 30190,
          },
          300702: {
            name: {
              th: 'พลับพลา',
              en: 'Phlapphla',
            },
            coordinates: {
              lat: '14.755',
              lng: '102.129',
            },
            zipcode: 30190,
          },
          300703: {
            name: {
              th: 'ท่าอ่าง',
              en: 'Tha Ang',
            },
            coordinates: {
              lat: '14.821',
              lng: '102.160',
            },
            zipcode: 30190,
          },
          300704: {
            name: {
              th: 'ทุ่งอรุณ',
              en: 'Thung Arun',
            },
            coordinates: {
              lat: '14.631',
              lng: '102.184',
            },
            zipcode: 30190,
          },
          300705: {
            name: {
              th: 'ท่าลาดขาว',
              en: 'Tha Lat Khao',
            },
            coordinates: {
              lat: '14.672',
              lng: '102.154',
            },
            zipcode: 30190,
          },
          300706: {
            name: {
              th: 'ท่าจะหลุง',
              en: 'Tha Chalung',
            },
            coordinates: {
              lat: '14.870',
              lng: '102.264',
            },
            zipcode: 30190,
          },
          300707: {
            name: {
              th: 'ท่าเยี่ยม',
              en: 'Tha Yiam',
            },
            coordinates: {
              lat: '14.716',
              lng: '102.237',
            },
            zipcode: 30190,
          },
          300708: {
            name: {
              th: 'โชคชัย',
              en: 'Chok Chai',
            },
            coordinates: {
              lat: '14.763',
              lng: '102.172',
            },
            zipcode: 30190,
          },
          300709: {
            name: {
              th: 'ละลมใหม่พัฒนา',
              en: 'Lalom Mai Phatthana',
            },
            coordinates: {
              lat: '14.811',
              lng: '102.243',
            },
            zipcode: 30190,
          },
          300710: {
            name: {
              th: 'ด่านเกวียน',
              en: 'Dan Kwian',
            },
            coordinates: {
              lat: '14.864',
              lng: '102.189',
            },
            zipcode: 30190,
          },
        },
      },
      3008: {
        name: {
          th: 'ด่านขุนทด',
          en: 'Dan Khun Thot',
        },
        tambons: {
          300801: {
            name: {
              th: 'กุดพิมาน',
              en: 'Kut Phiman',
            },
            coordinates: {
              lat: '15.303',
              lng: '101.759',
            },
            zipcode: 30210,
          },
          300802: {
            name: {
              th: 'ด่านขุนทด',
              en: 'Dan Khun Thot',
            },
            coordinates: {
              lat: '15.214',
              lng: '101.788',
            },
            zipcode: 30210,
          },
          300803: {
            name: {
              th: 'ด่านนอก',
              en: 'Dan Nok',
            },
            coordinates: {
              lat: '15.157',
              lng: '101.870',
            },
            zipcode: 30210,
          },
          300804: {
            name: {
              th: 'ด่านใน',
              en: 'Dan Nai',
            },
            coordinates: {
              lat: '15.119',
              lng: '101.855',
            },
            zipcode: 30210,
          },
          300805: {
            name: {
              th: 'ตะเคียน',
              en: 'Takhian',
            },
            coordinates: {
              lat: '15.124',
              lng: '101.724',
            },
            zipcode: 30210,
          },
          300806: {
            name: {
              th: 'บ้านเก่า',
              en: 'Ban Kao',
            },
            coordinates: {
              lat: '15.211',
              lng: '101.673',
            },
            zipcode: 30210,
          },
          300807: {
            name: {
              th: 'บ้านแปรง',
              en: 'Ban Praeng',
            },
            coordinates: {
              lat: '15.372',
              lng: '101.809',
            },
            zipcode: 36220,
          },
          300808: {
            name: {
              th: 'พันชนะ',
              en: 'Phan Chana',
            },
            coordinates: {
              lat: '15.270',
              lng: '101.689',
            },
            zipcode: 30210,
          },
          300809: {
            name: {
              th: 'สระจรเข้',
              en: 'Sa Chorakhe',
            },
            coordinates: {
              lat: '15.147',
              lng: '101.808',
            },
            zipcode: 30210,
          },
          300810: {
            name: {
              th: 'หนองกราด',
              en: 'Nong Krat',
            },
            coordinates: {
              lat: '15.348',
              lng: '101.667',
            },
            zipcode: 30210,
          },
          300811: {
            name: {
              th: 'หนองบัวตะเกียด',
              en: 'Nong Bua Takiat',
            },
            coordinates: {
              lat: '15.281',
              lng: '101.821',
            },
            zipcode: 30210,
          },
          300812: {
            name: {
              th: 'หนองบัวละคร',
              en: 'Nong Bua Lakhon',
            },
            coordinates: {
              lat: '15.243',
              lng: '101.863',
            },
            zipcode: 30210,
          },
          300813: {
            name: {
              th: 'หินดาด',
              en: 'Hin Dat',
            },
            coordinates: {
              lat: '15.149',
              lng: '101.618',
            },
            zipcode: 30240,
          },
          300815: {
            name: {
              th: 'ห้วยบง',
              en: 'Huai Bong',
            },
            coordinates: {
              lat: '15.161',
              lng: '101.486',
            },
            zipcode: 30210,
          },
          300817: {
            name: {
              th: 'โนนเมืองพัฒนา',
              en: 'Non Mueang Phatthana',
            },
            coordinates: {
              lat: '15.304',
              lng: '101.897',
            },
            zipcode: 30210,
          },
          300818: {
            name: {
              th: 'หนองไทร',
              en: 'Nong Sai',
            },
            coordinates: {
              lat: '15.360',
              lng: '101.855',
            },
            zipcode: 36220,
          },
        },
      },
      3009: {
        name: {
          th: 'โนนไทย',
          en: 'Non Thai',
        },
        tambons: {
          300901: {
            name: {
              th: 'โนนไทย',
              en: 'Non Thai',
            },
            coordinates: {
              lat: '15.213',
              lng: '102.061',
            },
            zipcode: 30220,
          },
          300902: {
            name: {
              th: 'ด่านจาก',
              en: 'Dan Chak',
            },
            coordinates: {
              lat: '15.162',
              lng: '102.095',
            },
            zipcode: 30220,
          },
          300903: {
            name: {
              th: 'กำปัง',
              en: 'Kampang',
            },
            coordinates: {
              lat: '15.118',
              lng: '102.060',
            },
            zipcode: 30220,
          },
          300904: {
            name: {
              th: 'สำโรง',
              en: 'Samrong',
            },
            coordinates: {
              lat: '15.128',
              lng: '102.018',
            },
            zipcode: 30220,
          },
          300905: {
            name: {
              th: 'ค้างพลู',
              en: 'Khang Phlu',
            },
            coordinates: {
              lat: '15.149',
              lng: '101.973',
            },
            zipcode: 30220,
          },
          300906: {
            name: {
              th: 'บ้านวัง',
              en: 'Ban Wang',
            },
            coordinates: {
              lat: '15.152',
              lng: '101.914',
            },
            zipcode: 30220,
          },
          300907: {
            name: {
              th: 'บัลลังก์',
              en: 'Banlang',
            },
            coordinates: {
              lat: '15.223',
              lng: '101.928',
            },
            zipcode: 30220,
          },
          300908: {
            name: {
              th: 'สายออ',
              en: 'Sai-O',
            },
            coordinates: {
              lat: '15.226',
              lng: '102.002',
            },
            zipcode: 30220,
          },
          300909: {
            name: {
              th: 'ถนนโพธิ์',
              en: 'Thanon Pho',
            },
            coordinates: {
              lat: '15.274',
              lng: '102.138',
            },
            zipcode: 30220,
          },
          300914: {
            name: {
              th: 'มะค่า',
              en: 'Makha',
            },
            coordinates: {
              lat: '15.265',
              lng: '102.098',
            },
            zipcode: 30160,
          },
        },
      },
      3010: {
        name: {
          th: 'โนนสูง',
          en: 'Non Sung',
        },
        tambons: {
          301001: {
            name: {
              th: 'โนนสูง',
              en: 'Non Sung',
            },
            coordinates: {
              lat: '15.179',
              lng: '102.256',
            },
            zipcode: 30160,
          },
          301002: {
            name: {
              th: 'ใหม่',
              en: 'Mai',
            },
            coordinates: {
              lat: '15.152',
              lng: '102.287',
            },
            zipcode: 30160,
          },
          301003: {
            name: {
              th: 'โตนด',
              en: 'Tanot',
            },
            coordinates: {
              lat: '15.097',
              lng: '102.293',
            },
            zipcode: 30160,
          },
          301004: {
            name: {
              th: 'บิง',
              en: 'Bing',
            },
            coordinates: {
              lat: '15.118',
              lng: '102.333',
            },
            zipcode: 30160,
          },
          301005: {
            name: {
              th: 'ดอนชมพู',
              en: 'Don Chomphu',
            },
            coordinates: {
              lat: '15.171',
              lng: '102.339',
            },
            zipcode: 30160,
          },
          301006: {
            name: {
              th: 'ธารปราสาท',
              en: 'Than Prasat',
            },
            coordinates: {
              lat: '15.263',
              lng: '102.392',
            },
            zipcode: 30420,
          },
          301007: {
            name: {
              th: 'หลุมข้าว',
              en: 'Lum Khao',
            },
            coordinates: {
              lat: '15.224',
              lng: '102.313',
            },
            zipcode: 30160,
          },
          301008: {
            name: {
              th: 'มะค่า',
              en: 'Makha',
            },
            coordinates: {
              lat: '15.315',
              lng: '102.321',
            },
            zipcode: null,
          },
          301009: {
            name: {
              th: 'พลสงคราม',
              en: 'Phon Songkhram',
            },
            coordinates: {
              lat: '15.299',
              lng: '102.273',
            },
            zipcode: 30160,
          },
          301010: {
            name: {
              th: 'จันอัด',
              en: 'Chan-At',
            },
            coordinates: {
              lat: '15.161',
              lng: '102.158',
            },
            zipcode: 30160,
          },
          301011: {
            name: {
              th: 'ขามเฒ่า',
              en: 'Kham Thao',
            },
            coordinates: {
              lat: '15.305',
              lng: '102.219',
            },
            zipcode: 30160,
          },
          301012: {
            name: {
              th: 'ด่านคล้า',
              en: 'Dan Khla',
            },
            coordinates: {
              lat: '15.152',
              lng: '102.212',
            },
            zipcode: 30160,
          },
          301013: {
            name: {
              th: 'ลำคอหงษ์',
              en: 'Lam Kho Hong',
            },
            coordinates: {
              lat: '15.252',
              lng: '102.210',
            },
            zipcode: 30160,
          },
          301014: {
            name: {
              th: 'เมืองปราสาท',
              en: 'Mueang Prasat',
            },
            coordinates: {
              lat: '15.201',
              lng: '102.194',
            },
            zipcode: 30160,
          },
          301015: {
            name: {
              th: 'ดอนหวาย',
              en: 'Don Wai',
            },
            coordinates: {
              lat: '15.116',
              lng: '102.287',
            },
            zipcode: 30160,
          },
          301016: {
            name: {
              th: 'ลำมูล',
              en: 'Lammun',
            },
            coordinates: {
              lat: '15.072',
              lng: '102.308',
            },
            zipcode: 30160,
          },
        },
      },
      3011: {
        name: {
          th: 'ขามสะแกแสง',
          en: 'Kham Sakaesaeng',
        },
        tambons: {
          301101: {
            name: {
              th: 'ขามสะแกแสง',
              en: 'Kham Sakae Saeng',
            },
            coordinates: {
              lat: '15.360',
              lng: '102.195',
            },
            zipcode: 30290,
          },
          301102: {
            name: {
              th: 'โนนเมือง',
              en: 'Non Mueang',
            },
            coordinates: {
              lat: '15.416',
              lng: '102.116',
            },
            zipcode: 30290,
          },
          301103: {
            name: {
              th: 'เมืองนาท',
              en: 'Mueang Nat',
            },
            coordinates: {
              lat: '15.386',
              lng: '102.248',
            },
            zipcode: 30290,
          },
          301104: {
            name: {
              th: 'ชีวึก',
              en: 'Chiwuek',
            },
            coordinates: {
              lat: '15.351',
              lng: '102.099',
            },
            zipcode: 30290,
          },
          301105: {
            name: {
              th: 'พะงาด',
              en: 'Phangat',
            },
            coordinates: {
              lat: '15.334',
              lng: '102.149',
            },
            zipcode: 30290,
          },
          301106: {
            name: {
              th: 'หนองหัวฟาน',
              en: 'Nong Hua Fan',
            },
            coordinates: {
              lat: '15.433',
              lng: '102.237',
            },
            zipcode: 30290,
          },
          301107: {
            name: {
              th: 'เมืองเกษตร',
              en: 'Mueang Kaset',
            },
            coordinates: {
              lat: '15.424',
              lng: '102.159',
            },
            zipcode: 30290,
          },
        },
      },
      3012: {
        name: {
          th: 'บัวใหญ่',
          en: 'Bua Yai',
        },
        tambons: {
          301201: {
            name: {
              th: 'บัวใหญ่',
              en: 'Bua Yai',
            },
            coordinates: {
              lat: '15.602',
              lng: '102.438',
            },
            zipcode: 30120,
          },
          301203: {
            name: {
              th: 'ห้วยยาง',
              en: 'Huai Yang',
            },
            coordinates: {
              lat: '15.626',
              lng: '102.363',
            },
            zipcode: 30120,
          },
          301204: {
            name: {
              th: 'เสมาใหญ่',
              en: 'Sema Yai',
            },
            coordinates: {
              lat: '15.516',
              lng: '102.429',
            },
            zipcode: 30120,
          },
          301206: {
            name: {
              th: 'ดอนตะหนิน',
              en: 'Don Tanin',
            },
            coordinates: {
              lat: '15.486',
              lng: '102.466',
            },
            zipcode: 30120,
          },
          301207: {
            name: {
              th: 'หนองบัวสะอาด',
              en: 'Nong Bua Sa-At',
            },
            coordinates: {
              lat: '15.593',
              lng: '102.300',
            },
            zipcode: 30120,
          },
          301208: {
            name: {
              th: 'โนนทองหลาง',
              en: 'Non Thong Lang',
            },
            coordinates: {
              lat: '15.522',
              lng: '102.374',
            },
            zipcode: 30120,
          },
          301214: {
            name: {
              th: 'กุดจอก',
              en: 'Kut Chok',
            },
            coordinates: {
              lat: '15.571',
              lng: '102.502',
            },
            zipcode: 30120,
          },
          301215: {
            name: {
              th: 'ด่านช้าง',
              en: 'Dan Chang',
            },
            coordinates: {
              lat: '15.623',
              lng: '102.407',
            },
            zipcode: 30120,
          },
          301220: {
            name: {
              th: 'ขุนทอง',
              en: 'Khun Thong',
            },
            coordinates: {
              lat: '15.683',
              lng: '102.364',
            },
            zipcode: 30120,
          },
          301224: {
            name: {
              th: 'หนองแจ้งใหญ่',
              en: 'Nong Chaeng Yai',
            },
            coordinates: {
              lat: '15.567',
              lng: '102.372',
            },
            zipcode: 30120,
          },
        },
      },
      3013: {
        name: {
          th: 'ประทาย',
          en: 'Prathai',
        },
        tambons: {
          301301: {
            name: {
              th: 'ประทาย',
              en: 'Prathai',
            },
            coordinates: {
              lat: '15.536',
              lng: '102.724',
            },
            zipcode: 30180,
          },
          301303: {
            name: {
              th: 'กระทุ่มราย',
              en: 'Krathum Rai',
            },
            coordinates: {
              lat: '15.530',
              lng: '102.659',
            },
            zipcode: 30180,
          },
          301304: {
            name: {
              th: 'วังไม้แดง',
              en: 'Wang Mai Daeng',
            },
            coordinates: {
              lat: '15.684',
              lng: '102.632',
            },
            zipcode: 30180,
          },
          301306: {
            name: {
              th: 'ตลาดไทร',
              en: 'Talat Sai',
            },
            coordinates: {
              lat: '15.528',
              lng: '102.780',
            },
            zipcode: 30180,
          },
          301307: {
            name: {
              th: 'หนองพลวง',
              en: 'Nong Phluang',
            },
            coordinates: {
              lat: '15.583',
              lng: '102.707',
            },
            zipcode: null,
          },
          301308: {
            name: {
              th: 'หนองค่าย',
              en: 'Nong Khai',
            },
            coordinates: {
              lat: '15.596',
              lng: '102.759',
            },
            zipcode: 30180,
          },
          301309: {
            name: {
              th: 'หันห้วยทราย',
              en: 'Han Huai Sai',
            },
            coordinates: {
              lat: '15.611',
              lng: '102.656',
            },
            zipcode: 30180,
          },
          301310: {
            name: {
              th: 'ดอนมัน',
              en: 'Don Man',
            },
            coordinates: {
              lat: '15.491',
              lng: '102.822',
            },
            zipcode: 30180,
          },
          301313: {
            name: {
              th: 'นางรำ',
              en: 'Nang Ram',
            },
            coordinates: {
              lat: '15.484',
              lng: '102.623',
            },
            zipcode: 30180,
          },
          301314: {
            name: {
              th: 'โนนเพ็ด',
              en: 'Non Phet',
            },
            coordinates: {
              lat: '15.677',
              lng: '102.684',
            },
            zipcode: 30180,
          },
          301315: {
            name: {
              th: 'ทุ่งสว่าง',
              en: 'Thung Sawang',
            },
            coordinates: {
              lat: '15.493',
              lng: '102.686',
            },
            zipcode: 30180,
          },
          301317: {
            name: {
              th: 'โคกกลาง',
              en: 'Khok Klang',
            },
            coordinates: {
              lat: '15.463',
              lng: '102.737',
            },
            zipcode: 30180,
          },
          301318: {
            name: {
              th: 'เมืองโดน',
              en: 'Mueang Don',
            },
            coordinates: {
              lat: '15.635',
              lng: '102.712',
            },
            zipcode: 30180,
          },
        },
      },
      3014: {
        name: {
          th: 'ปักธงชัย',
          en: 'Pak Thong Chai',
        },
        tambons: {
          301401: {
            name: {
              th: 'เมืองปัก',
              en: 'Mueang Pak',
            },
            coordinates: {
              lat: '14.719',
              lng: '102.055',
            },
            zipcode: 30150,
          },
          301402: {
            name: {
              th: 'ตะคุ',
              en: 'Takhu',
            },
            coordinates: {
              lat: '14.754',
              lng: '101.953',
            },
            zipcode: 30150,
          },
          301403: {
            name: {
              th: 'โคกไทย',
              en: 'Khok Thai',
            },
            coordinates: {
              lat: '14.741',
              lng: '102.090',
            },
            zipcode: 30150,
          },
          301404: {
            name: {
              th: 'สำโรง',
              en: 'Samrong',
            },
            coordinates: {
              lat: '14.650',
              lng: '102.063',
            },
            zipcode: null,
          },
          301405: {
            name: {
              th: 'ตะขบ',
              en: 'Takhop',
            },
            coordinates: {
              lat: '14.686',
              lng: '101.745',
            },
            zipcode: 30150,
          },
          301406: {
            name: {
              th: 'นกออก',
              en: 'Nok Ok',
            },
            coordinates: {
              lat: '14.686',
              lng: '102.036',
            },
            zipcode: 30150,
          },
          301407: {
            name: {
              th: 'ดอน',
              en: 'Don',
            },
            coordinates: {
              lat: '14.679',
              lng: '102.108',
            },
            zipcode: 30150,
          },
          301409: {
            name: {
              th: 'ตูม',
              en: 'Tum',
            },
            coordinates: {
              lat: '14.701',
              lng: '101.986',
            },
            zipcode: 30150,
          },
          301410: {
            name: {
              th: 'งิ้ว',
              en: 'Ngio',
            },
            coordinates: {
              lat: '14.666',
              lng: '101.997',
            },
            zipcode: 30240,
          },
          301411: {
            name: {
              th: 'สะแกราช',
              en: 'Sakae Rat',
            },
            coordinates: {
              lat: '14.604',
              lng: '102.048',
            },
            zipcode: 30150,
          },
          301412: {
            name: {
              th: 'ลำนางแก้ว',
              en: 'Lam Nang Kaeo',
            },
            coordinates: {
              lat: '14.563',
              lng: '101.870',
            },
            zipcode: 30150,
          },
          301416: {
            name: {
              th: 'ภูหลวง',
              en: 'Phu Luang',
            },
            coordinates: {
              lat: '14.602',
              lng: '101.984',
            },
            zipcode: 30150,
          },
          301417: {
            name: {
              th: 'ธงชัยเหนือ',
              en: 'Thong Chai Nuea',
            },
            coordinates: {
              lat: '14.793',
              lng: '102.034',
            },
            zipcode: 30150,
          },
          301418: {
            name: {
              th: 'สุขเกษม',
              en: 'Suk Kasem',
            },
            coordinates: {
              lat: '14.667',
              lng: '101.939',
            },
            zipcode: 30150,
          },
          301419: {
            name: {
              th: 'เกษมทรัพย์',
              en: 'Kasem Sap',
            },
            coordinates: {
              lat: '14.663',
              lng: '102.046',
            },
            zipcode: 30150,
          },
          301420: {
            name: {
              th: 'บ่อปลาทอง',
              en: 'Bo Pla Thong',
            },
            coordinates: {
              lat: '14.637',
              lng: '101.902',
            },
            zipcode: 30150,
          },
        },
      },
      3015: {
        name: {
          th: 'พิมาย',
          en: 'Phimai',
        },
        tambons: {
          301501: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '15.206',
              lng: '102.489',
            },
            zipcode: null,
          },
          301502: {
            name: {
              th: 'สัมฤทธิ์',
              en: 'Samrit',
            },
            coordinates: {
              lat: '15.216',
              lng: '102.424',
            },
            zipcode: 30110,
          },
          301503: {
            name: {
              th: 'โบสถ์',
              en: 'Bot',
            },
            coordinates: {
              lat: '15.215',
              lng: '102.628',
            },
            zipcode: 30110,
          },
          301504: {
            name: {
              th: 'กระเบื้องใหญ่',
              en: 'Krabueang Yai',
            },
            coordinates: {
              lat: '15.271',
              lng: '102.458',
            },
            zipcode: 30110,
          },
          301505: {
            name: {
              th: 'ท่าหลวง',
              en: 'Tha Luang',
            },
            coordinates: {
              lat: '15.282',
              lng: '102.547',
            },
            zipcode: 30110,
          },
          301506: {
            name: {
              th: 'รังกาใหญ่',
              en: 'Rang Ka Yai',
            },
            coordinates: {
              lat: '15.190',
              lng: '102.555',
            },
            zipcode: 30110,
          },
          301507: {
            name: {
              th: 'ชีวาน',
              en: 'Chiwan',
            },
            coordinates: {
              lat: '15.344',
              lng: '102.540',
            },
            zipcode: 30110,
          },
          301508: {
            name: {
              th: 'นิคมสร้างตนเอง',
              en: 'Nikhom Sang Ton-Eng',
            },
            coordinates: {
              lat: '15.128',
              lng: '102.490',
            },
            zipcode: 30110,
          },
          301509: {
            name: {
              th: 'กระชอน',
              en: 'Krachon',
            },
            coordinates: {
              lat: '15.443',
              lng: '102.670',
            },
            zipcode: 30110,
          },
          301510: {
            name: {
              th: 'ดงใหญ่',
              en: 'Dong Yai',
            },
            coordinates: {
              lat: '15.338',
              lng: '102.591',
            },
            zipcode: 30110,
          },
          301511: {
            name: {
              th: 'ธารละหลอด',
              en: 'Than Lalot',
            },
            coordinates: {
              lat: '15.162',
              lng: '102.403',
            },
            zipcode: 30110,
          },
          301512: {
            name: {
              th: 'หนองระเวียง',
              en: 'Nong Rawiang',
            },
            coordinates: {
              lat: '15.113',
              lng: '102.440',
            },
            zipcode: null,
          },
        },
      },
      3016: {
        name: {
          th: 'ห้วยแถลง',
          en: 'Huai Thalaeng',
        },
        tambons: {
          301601: {
            name: {
              th: 'ห้วยแถลง',
              en: 'Huai Thalaeng',
            },
            coordinates: {
              lat: '14.967',
              lng: '102.652',
            },
            zipcode: 30240,
          },
          301602: {
            name: {
              th: 'ทับสวาย',
              en: 'Thap Sawai',
            },
            coordinates: {
              lat: '15.021',
              lng: '102.634',
            },
            zipcode: 30240,
          },
          301603: {
            name: {
              th: 'เมืองพลับพลา',
              en: 'Mueang Phlapphla',
            },
            coordinates: {
              lat: '15.063',
              lng: '102.634',
            },
            zipcode: 30240,
          },
          301604: {
            name: {
              th: 'หลุ่งตะเคียน',
              en: 'Lung Takhian',
            },
            coordinates: {
              lat: '15.120',
              lng: '102.638',
            },
            zipcode: 30240,
          },
          301605: {
            name: {
              th: 'หินดาด',
              en: 'Hin Dat',
            },
            coordinates: {
              lat: '14.995',
              lng: '102.565',
            },
            zipcode: null,
          },
          301606: {
            name: {
              th: 'งิ้ว',
              en: 'Ngio',
            },
            coordinates: {
              lat: '15.077',
              lng: '102.689',
            },
            zipcode: null,
          },
          301607: {
            name: {
              th: 'กงรถ',
              en: 'Kong Rot',
            },
            coordinates: {
              lat: '15.047',
              lng: '102.752',
            },
            zipcode: 30240,
          },
          301608: {
            name: {
              th: 'หลุ่งประดู่',
              en: 'Lung Pradu',
            },
            coordinates: {
              lat: '15.060',
              lng: '102.582',
            },
            zipcode: 30240,
          },
          301609: {
            name: {
              th: 'ตะโก',
              en: 'Tako',
            },
            coordinates: {
              lat: '15.019',
              lng: '102.718',
            },
            zipcode: 30240,
          },
          301610: {
            name: {
              th: 'ห้วยแคน',
              en: 'Huai Khaen',
            },
            coordinates: {
              lat: '14.985',
              lng: '102.687',
            },
            zipcode: 30240,
          },
        },
      },
      3017: {
        name: {
          th: 'ชุมพวง',
          en: 'Chum Phuang',
        },
        tambons: {
          301701: {
            name: {
              th: 'ชุมพวง',
              en: 'Chum Phuang',
            },
            coordinates: {
              lat: '15.344',
              lng: '102.750',
            },
            zipcode: 30270,
          },
          301702: {
            name: {
              th: 'ประสุข',
              en: 'Prasuk',
            },
            coordinates: {
              lat: '15.342',
              lng: '102.685',
            },
            zipcode: 30270,
          },
          301703: {
            name: {
              th: 'ท่าลาด',
              en: 'Tha Lat',
            },
            coordinates: {
              lat: '15.243',
              lng: '102.720',
            },
            zipcode: 30270,
          },
          301704: {
            name: {
              th: 'สาหร่าย',
              en: 'Sarai',
            },
            coordinates: {
              lat: '15.184',
              lng: '102.735',
            },
            zipcode: 30270,
          },
          301705: {
            name: {
              th: 'ตลาดไทร',
              en: 'Talat Sai',
            },
            coordinates: {
              lat: '15.109',
              lng: '102.773',
            },
            zipcode: null,
          },
          301710: {
            name: {
              th: 'โนนรัง',
              en: 'Non Rang',
            },
            coordinates: {
              lat: '15.258',
              lng: '102.810',
            },
            zipcode: 30270,
          },
          301714: {
            name: {
              th: 'หนองหลัก',
              en: 'Nong Lak',
            },
            coordinates: {
              lat: '15.411',
              lng: '102.732',
            },
            zipcode: 30270,
          },
          301716: {
            name: {
              th: 'โนนตูม',
              en: 'Non Tum',
            },
            coordinates: {
              lat: '15.167',
              lng: '102.769',
            },
            zipcode: 30270,
          },
          301717: {
            name: {
              th: 'โนนยอ',
              en: 'Non Yo',
            },
            coordinates: {
              lat: '15.339',
              lng: '102.828',
            },
            zipcode: 30270,
          },
        },
      },
      3018: {
        name: {
          th: 'สูงเนิน',
          en: 'Sung Noen',
        },
        tambons: {
          301801: {
            name: {
              th: 'สูงเนิน',
              en: 'Sung Noen',
            },
            coordinates: {
              lat: '14.861',
              lng: '101.842',
            },
            zipcode: 30170,
          },
          301802: {
            name: {
              th: 'เสมา',
              en: 'Sema',
            },
            coordinates: {
              lat: '14.982',
              lng: '101.777',
            },
            zipcode: 30170,
          },
          301803: {
            name: {
              th: 'โคราช',
              en: 'Khorat',
            },
            coordinates: {
              lat: '14.909',
              lng: '101.851',
            },
            zipcode: 30170,
          },
          301804: {
            name: {
              th: 'บุ่งขี้เหล็ก',
              en: 'Bung Khi Lek',
            },
            coordinates: {
              lat: '14.952',
              lng: '101.839',
            },
            zipcode: 30170,
          },
          301805: {
            name: {
              th: 'โนนค่า',
              en: 'Non Kha',
            },
            coordinates: {
              lat: '15.034',
              lng: '101.840',
            },
            zipcode: 30170,
          },
          301806: {
            name: {
              th: 'โค้งยาง',
              en: 'Khong Yang',
            },
            coordinates: {
              lat: '14.922',
              lng: '101.903',
            },
            zipcode: 30170,
          },
          301807: {
            name: {
              th: 'มะเกลือเก่า',
              en: 'Makluea Kao',
            },
            coordinates: {
              lat: '14.779',
              lng: '101.805',
            },
            zipcode: 30170,
          },
          301808: {
            name: {
              th: 'มะเกลือใหม่',
              en: 'Makluea Mai',
            },
            coordinates: {
              lat: '14.798',
              lng: '101.728',
            },
            zipcode: 30170,
          },
          301809: {
            name: {
              th: 'นากลาง',
              en: 'Na Klang',
            },
            coordinates: {
              lat: '14.855',
              lng: '101.942',
            },
            zipcode: 30380,
          },
          301810: {
            name: {
              th: 'หนองตะไก้',
              en: 'Nong Takai',
            },
            coordinates: {
              lat: '14.790',
              lng: '101.895',
            },
            zipcode: 30410,
          },
        },
      },
      3019: {
        name: {
          th: 'ขามทะเลสอ',
          en: 'Kham Thale So',
        },
        tambons: {
          301901: {
            name: {
              th: 'ขามทะเลสอ',
              en: 'Kham Thale So',
            },
            coordinates: {
              lat: '14.974',
              lng: '101.959',
            },
            zipcode: 30280,
          },
          301902: {
            name: {
              th: 'โป่งแดง',
              en: 'Pong Daeng',
            },
            coordinates: {
              lat: '14.970',
              lng: '101.916',
            },
            zipcode: 30280,
          },
          301903: {
            name: {
              th: 'พันดุง',
              en: 'Phan Dung',
            },
            coordinates: {
              lat: '15.069',
              lng: '101.952',
            },
            zipcode: 30280,
          },
          301904: {
            name: {
              th: 'หนองสรวง',
              en: 'Nong Suang',
            },
            coordinates: {
              lat: '15.086',
              lng: '101.894',
            },
            zipcode: 30280,
          },
          301905: {
            name: {
              th: 'บึงอ้อ',
              en: 'Bueng O',
            },
            coordinates: {
              lat: '15.028',
              lng: '101.932',
            },
            zipcode: 30280,
          },
        },
      },
      3020: {
        name: {
          th: 'สีคิ้ว',
          en: 'Si Khio',
        },
        tambons: {
          302001: {
            name: {
              th: 'สีคิ้ว',
              en: 'Sikhio',
            },
            coordinates: {
              lat: '14.905',
              lng: '101.687',
            },
            zipcode: 30140,
          },
          302002: {
            name: {
              th: 'บ้านหัน',
              en: 'Ban Han',
            },
            coordinates: {
              lat: '14.985',
              lng: '101.750',
            },
            zipcode: 30140,
          },
          302003: {
            name: {
              th: 'กฤษณา',
              en: 'Kritsana',
            },
            coordinates: {
              lat: '15.046',
              lng: '101.556',
            },
            zipcode: 30140,
          },
          302004: {
            name: {
              th: 'ลาดบัวขาว',
              en: 'Lat Bua Khao',
            },
            coordinates: {
              lat: '14.823',
              lng: '101.629',
            },
            zipcode: 30340,
          },
          302005: {
            name: {
              th: 'หนองหญ้าขาว',
              en: 'Nong Ya Khao',
            },
            coordinates: {
              lat: '14.959',
              lng: '101.574',
            },
            zipcode: 30140,
          },
          302006: {
            name: {
              th: 'กุดน้อย',
              en: 'Kut Noi',
            },
            coordinates: {
              lat: '14.931',
              lng: '101.753',
            },
            zipcode: 30140,
          },
          302007: {
            name: {
              th: 'หนองน้ำใส',
              en: 'Nong Nam Sai',
            },
            coordinates: {
              lat: '14.932',
              lng: '101.485',
            },
            zipcode: 30140,
          },
          302008: {
            name: {
              th: 'วังโรงใหญ่',
              en: 'Wang Rong Yai',
            },
            coordinates: {
              lat: '15.045',
              lng: '101.659',
            },
            zipcode: 30140,
          },
          302009: {
            name: {
              th: 'มิตรภาพ',
              en: 'Mittraphap',
            },
            coordinates: {
              lat: '14.797',
              lng: '101.685',
            },
            zipcode: 30140,
          },
          302010: {
            name: {
              th: 'คลองไผ่',
              en: 'Khlong Phai',
            },
            coordinates: {
              lat: '14.883',
              lng: '101.515',
            },
            zipcode: 30340,
          },
          302011: {
            name: {
              th: 'ดอนเมือง',
              en: 'Don Mueang',
            },
            coordinates: {
              lat: '15.020',
              lng: '101.456',
            },
            zipcode: 30140,
          },
          302012: {
            name: {
              th: 'หนองบัวน้อย',
              en: 'Nong Bua Noi',
            },
            coordinates: {
              lat: '15.047',
              lng: '101.760',
            },
            zipcode: 30140,
          },
        },
      },
      3021: {
        name: {
          th: 'ปากช่อง',
          en: 'Pak Chong',
        },
        tambons: {
          302101: {
            name: {
              th: 'ปากช่อง',
              en: 'Pak Chong',
            },
            coordinates: {
              lat: '14.722',
              lng: '101.363',
            },
            zipcode: 30130,
          },
          302102: {
            name: {
              th: 'กลางดง',
              en: 'Klang Dong',
            },
            coordinates: {
              lat: '14.650',
              lng: '101.283',
            },
            zipcode: 30320,
          },
          302103: {
            name: {
              th: 'จันทึก',
              en: 'Chan Thuek',
            },
            coordinates: {
              lat: '14.806',
              lng: '101.456',
            },
            zipcode: 30130,
          },
          302104: {
            name: {
              th: 'วังกะทะ',
              en: 'Wang Katha',
            },
            coordinates: {
              lat: '14.536',
              lng: '101.640',
            },
            zipcode: 30130,
          },
          302105: {
            name: {
              th: 'หมูสี',
              en: 'Mu Si',
            },
            coordinates: {
              lat: '14.501',
              lng: '101.402',
            },
            zipcode: 30130,
          },
          302106: {
            name: {
              th: 'หนองสาหร่าย',
              en: 'Nong Sarai',
            },
            coordinates: {
              lat: '14.689',
              lng: '101.488',
            },
            zipcode: 30130,
          },
          302107: {
            name: {
              th: 'ขนงพระ',
              en: 'Khanong Phra',
            },
            coordinates: {
              lat: '14.594',
              lng: '101.530',
            },
            zipcode: 30130,
          },
          302108: {
            name: {
              th: 'โป่งตาลอง',
              en: 'Pong Ta Long',
            },
            coordinates: {
              lat: '14.472',
              lng: '101.605',
            },
            zipcode: 30130,
          },
          302109: {
            name: {
              th: 'คลองม่วง',
              en: 'Khlong Muang',
            },
            coordinates: {
              lat: '14.647',
              lng: '101.649',
            },
            zipcode: 30130,
          },
          302110: {
            name: {
              th: 'หนองน้ำแดง',
              en: 'Nong Nam Daeng',
            },
            coordinates: {
              lat: '14.613',
              lng: '101.398',
            },
            zipcode: 30130,
          },
          302111: {
            name: {
              th: 'วังไทร',
              en: 'Wang Sai',
            },
            coordinates: {
              lat: '14.706',
              lng: '101.573',
            },
            zipcode: 30130,
          },
          302112: {
            name: {
              th: 'พญาเย็น',
              en: 'Phaya Yen',
            },
            coordinates: {
              lat: '14.548',
              lng: '101.246',
            },
            zipcode: 30320,
          },
        },
      },
      3022: {
        name: {
          th: 'หนองบุญมาก',
          en: 'Nong Bunmak',
        },
        tambons: {
          302201: {
            name: {
              th: 'หนองบุนนาก',
              en: 'Nong Bunnak',
            },
            coordinates: {
              lat: '14.609',
              lng: '102.400',
            },
            zipcode: 30410,
          },
          302202: {
            name: {
              th: 'สารภี',
              en: 'Saraphi',
            },
            coordinates: {
              lat: '14.814',
              lng: '102.417',
            },
            zipcode: 30410,
          },
          302203: {
            name: {
              th: 'ไทยเจริญ',
              en: 'Thai Charoen',
            },
            coordinates: {
              lat: '14.743',
              lng: '102.450',
            },
            zipcode: 30410,
          },
          302204: {
            name: {
              th: 'หนองหัวแรต',
              en: 'Nong Hua Raet',
            },
            coordinates: {
              lat: '14.715',
              lng: '102.332',
            },
            zipcode: 30410,
          },
          302205: {
            name: {
              th: 'แหลมทอง',
              en: 'Laem Thong',
            },
            coordinates: {
              lat: '14.780',
              lng: '102.304',
            },
            zipcode: 30410,
          },
          302206: {
            name: {
              th: 'หนองตะไก้',
              en: 'Nong Takai',
            },
            coordinates: {
              lat: '14.654',
              lng: '102.397',
            },
            zipcode: null,
          },
          302207: {
            name: {
              th: 'ลุงเขว้า',
              en: 'Lung Khwao',
            },
            coordinates: {
              lat: '14.813',
              lng: '102.339',
            },
            zipcode: 30410,
          },
          302208: {
            name: {
              th: 'หนองไม้ไผ่',
              en: 'Nong Mai Phai',
            },
            coordinates: {
              lat: '14.641',
              lng: '102.320',
            },
            zipcode: 30410,
          },
          302209: {
            name: {
              th: 'บ้านใหม่',
              en: 'Ban Mai',
            },
            coordinates: {
              lat: '14.754',
              lng: '102.398',
            },
            zipcode: null,
          },
        },
      },
      3023: {
        name: {
          th: 'แก้งสนามนาง',
          en: 'Kaeng Sanam Nang',
        },
        tambons: {
          302301: {
            name: {
              th: 'แก้งสนามนาง',
              en: 'Kaeng Sanam Nang',
            },
            coordinates: {
              lat: '15.727',
              lng: '102.277',
            },
            zipcode: null,
          },
          302302: {
            name: {
              th: 'โนนสำราญ',
              en: 'Non Samran',
            },
            coordinates: {
              lat: '15.750',
              lng: '102.325',
            },
            zipcode: null,
          },
          302303: {
            name: {
              th: 'บึงพะไล',
              en: 'Bueng Phalai',
            },
            coordinates: {
              lat: '15.661',
              lng: '102.236',
            },
            zipcode: null,
          },
          302304: {
            name: {
              th: 'สีสุก',
              en: 'Si Suk',
            },
            coordinates: {
              lat: '15.640',
              lng: '102.175',
            },
            zipcode: null,
          },
          302305: {
            name: {
              th: 'บึงสำโรง',
              en: 'Bueng Samrong',
            },
            coordinates: {
              lat: '15.715',
              lng: '102.198',
            },
            zipcode: 30440,
          },
        },
      },
      3024: {
        name: {
          th: 'โนนแดง',
          en: 'Non Daeng',
        },
        tambons: {
          302402: {
            name: {
              th: 'โนนตาเถร',
              en: 'Non Ta Then',
            },
            coordinates: {
              lat: '15.435',
              lng: '102.488',
            },
            zipcode: null,
          },
          302403: {
            name: {
              th: 'สำพะเนียง',
              en: 'Sam Phaniang',
            },
            coordinates: {
              lat: '15.452',
              lng: '102.568',
            },
            zipcode: null,
          },
          302404: {
            name: {
              th: 'วังหิน',
              en: 'Wang Hin',
            },
            coordinates: {
              lat: '15.505',
              lng: '102.567',
            },
            zipcode: null,
          },
          302405: {
            name: {
              th: 'ดอนยาวใหญ่',
              en: 'Don Yao Yai',
            },
            coordinates: {
              lat: '15.410',
              lng: '102.536',
            },
            zipcode: null,
          },
        },
      },
      3025: {
        name: {
          th: 'วังน้ำเขียว',
          en: 'Wang Nam Khiao',
        },
        tambons: {
          302501: {
            name: {
              th: 'วังน้ำเขียว',
              en: 'Wang Nam Khiao',
            },
            coordinates: {
              lat: '14.399',
              lng: '101.817',
            },
            zipcode: null,
          },
          302502: {
            name: {
              th: 'วังหมี',
              en: 'Wang Mi',
            },
            coordinates: {
              lat: '14.474',
              lng: '101.769',
            },
            zipcode: null,
          },
          302503: {
            name: {
              th: 'ระเริง',
              en: 'Raroeng',
            },
            coordinates: {
              lat: '14.574',
              lng: '101.731',
            },
            zipcode: null,
          },
          302504: {
            name: {
              th: 'อุดมทรัพย์',
              en: 'Udom Sap',
            },
            coordinates: {
              lat: '14.490',
              lng: '101.969',
            },
            zipcode: null,
          },
          302505: {
            name: {
              th: 'ไทยสามัคคี',
              en: 'Thai Samakkhi',
            },
            coordinates: {
              lat: '14.353',
              lng: '101.966',
            },
            zipcode: 30370,
          },
        },
      },
      3026: {
        name: {
          th: 'เทพารักษ์',
          en: 'Thepharak',
        },
        tambons: {
          302601: {
            name: {
              th: 'สำนักตะคร้อ',
              en: 'Samnak Takhro',
            },
            coordinates: {
              lat: '15.297',
              lng: '101.564',
            },
            zipcode: null,
          },
          302602: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '15.256',
              lng: '101.436',
            },
            zipcode: null,
          },
          302603: {
            name: {
              th: 'บึงปรือ',
              en: 'Bueng Prue',
            },
            coordinates: {
              lat: '15.303',
              lng: '101.411',
            },
            zipcode: null,
          },
          302604: {
            name: {
              th: 'วังยายทอง',
              en: 'Wang Yai Thong',
            },
            coordinates: {
              lat: '15.349',
              lng: '101.518',
            },
            zipcode: 30210,
          },
        },
      },
      3027: {
        name: {
          th: 'เมืองยาง',
          en: 'Mueang Yang',
        },
        tambons: {
          302701: {
            name: {
              th: 'เมืองยาง',
              en: 'Mueang Yang',
            },
            coordinates: {
              lat: '15.418',
              lng: '102.886',
            },
            zipcode: null,
          },
          302702: {
            name: {
              th: 'กระเบื้องนอก',
              en: 'Krabueang Nok',
            },
            coordinates: {
              lat: '15.440',
              lng: '102.957',
            },
            zipcode: null,
          },
          302703: {
            name: {
              th: 'ละหานปลาค้าว',
              en: 'Lahan Pla Khao',
            },
            coordinates: {
              lat: '15.487',
              lng: '102.889',
            },
            zipcode: null,
          },
          302704: {
            name: {
              th: 'โนนอุดม',
              en: 'Non Udom',
            },
            coordinates: {
              lat: '15.426',
              lng: '102.812',
            },
            zipcode: null,
          },
        },
      },
      3028: {
        name: {
          th: 'พระทองคำ',
          en: 'Phra Thong Kham',
        },
        tambons: {
          302801: {
            name: {
              th: 'สระพระ',
              en: 'Sa Phra',
            },
            coordinates: {
              lat: '15.311',
              lng: '101.959',
            },
            zipcode: null,
          },
          302802: {
            name: {
              th: 'มาบกราด',
              en: 'Map Krat',
            },
            coordinates: {
              lat: '15.369',
              lng: '101.926',
            },
            zipcode: null,
          },
          302803: {
            name: {
              th: 'พังเทียม',
              en: 'Phang Thiam',
            },
            coordinates: {
              lat: '15.275',
              lng: '101.995',
            },
            zipcode: null,
          },
          302804: {
            name: {
              th: 'ทัพรั้ง',
              en: 'Thap Rang',
            },
            coordinates: {
              lat: '15.406',
              lng: '101.976',
            },
            zipcode: null,
          },
          302805: {
            name: {
              th: 'หนองหอย',
              en: 'Nong Hoi',
            },
            coordinates: {
              lat: '15.334',
              lng: '102.056',
            },
            zipcode: null,
          },
        },
      },
      3029: {
        name: {
          th: 'ลำทะเมนชัย',
          en: 'Lamtaman Chai',
        },
        tambons: {
          302901: {
            name: {
              th: 'ขุย',
              en: 'Khui',
            },
            coordinates: {
              lat: '15.353',
              lng: '102.866',
            },
            zipcode: null,
          },
          302902: {
            name: {
              th: 'บ้านยาง',
              en: 'Ban Yang',
            },
            coordinates: {
              lat: '15.250',
              lng: '102.944',
            },
            zipcode: null,
          },
          302903: {
            name: {
              th: 'ช่องแมว',
              en: 'Chong Maeo',
            },
            coordinates: {
              lat: '15.276',
              lng: '102.874',
            },
            zipcode: null,
          },
          302904: {
            name: {
              th: 'ไพล',
              en: 'Phrai',
            },
            coordinates: {
              lat: '15.360',
              lng: '102.971',
            },
            zipcode: null,
          },
        },
      },
      3030: {
        name: {
          th: 'บัวลาย',
          en: 'Bua Lai',
        },
        tambons: {
          303001: {
            name: {
              th: 'เมืองพะไล',
              en: 'Mueang Phalai',
            },
            coordinates: {
              lat: '15.649',
              lng: '102.532',
            },
            zipcode: null,
          },
          303002: {
            name: {
              th: 'โนนจาน',
              en: 'Non Chan',
            },
            coordinates: {
              lat: '15.699',
              lng: '102.472',
            },
            zipcode: null,
          },
          303003: {
            name: {
              th: 'บัวลาย',
              en: 'Bua Lai',
            },
            coordinates: {
              lat: '15.661',
              lng: '102.468',
            },
            zipcode: null,
          },
          303004: {
            name: {
              th: 'หนองหว้า',
              en: 'Nong Wa',
            },
            coordinates: {
              lat: '15.667',
              lng: '102.573',
            },
            zipcode: null,
          },
        },
      },
      3031: {
        name: {
          th: 'สีดา',
          en: 'Sida',
        },
        tambons: {
          303101: {
            name: {
              th: 'สีดา',
              en: 'Sida',
            },
            coordinates: {
              lat: '15.564',
              lng: '102.588',
            },
            zipcode: null,
          },
          303102: {
            name: {
              th: 'โพนทอง',
              en: 'Phon Thong',
            },
            coordinates: {
              lat: '15.540',
              lng: '102.590',
            },
            zipcode: null,
          },
          303103: {
            name: {
              th: 'โนนประดู่',
              en: 'Non Pradu',
            },
            coordinates: {
              lat: '15.521',
              lng: '102.479',
            },
            zipcode: null,
          },
          303104: {
            name: {
              th: 'สามเมือง',
              en: 'Sam Mueang',
            },
            coordinates: {
              lat: '15.591',
              lng: '102.537',
            },
            zipcode: null,
          },
          303105: {
            name: {
              th: 'หนองตาดใหญ่',
              en: 'Nong Tat Yai',
            },
            coordinates: {
              lat: '15.617',
              lng: '102.591',
            },
            zipcode: null,
          },
        },
      },
      3032: {
        name: {
          th: 'เฉลิมพระเกียรติ',
          en: 'Chaloem Phra Kiet',
        },
        tambons: {
          303201: {
            name: {
              th: 'ช้างทอง',
              en: 'Chang Thong',
            },
            coordinates: {
              lat: '15.029',
              lng: '102.310',
            },
            zipcode: null,
          },
          303202: {
            name: {
              th: 'ท่าช้าง',
              en: 'Tha Chang',
            },
            coordinates: {
              lat: '14.981',
              lng: '102.323',
            },
            zipcode: null,
          },
          303203: {
            name: {
              th: 'พระพุทธ',
              en: 'Phraphut',
            },
            coordinates: {
              lat: '14.975',
              lng: '102.232',
            },
            zipcode: null,
          },
          303204: {
            name: {
              th: 'หนองงูเหลือม',
              en: 'Nong Ngu Lueam',
            },
            coordinates: {
              lat: '15.053',
              lng: '102.234',
            },
            zipcode: null,
          },
          303205: {
            name: {
              th: 'หนองยาง',
              en: 'Nong Yang',
            },
            coordinates: {
              lat: '14.930',
              lng: '102.287',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  31: {
    name: {
      th: 'บุรีรัมย์',
      en: 'Buri Ram',
    },
    amphoes: {
      3101: {
        name: {
          th: 'เมืองบุรีรัมย์',
          en: 'Mueang Buri Ram',
        },
        tambons: {
          310101: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '14.999',
              lng: '103.104',
            },
            zipcode: 31000,
          },
          310102: {
            name: {
              th: 'อิสาณ',
              en: 'I San',
            },
            coordinates: {
              lat: '14.979',
              lng: '103.128',
            },
            zipcode: 31000,
          },
          310103: {
            name: {
              th: 'เสม็ด',
              en: 'Samet',
            },
            coordinates: {
              lat: '14.941',
              lng: '103.098',
            },
            zipcode: 31000,
          },
          310104: {
            name: {
              th: 'บ้านบัว',
              en: 'Ban Bua',
            },
            coordinates: {
              lat: '14.949',
              lng: '102.997',
            },
            zipcode: 31000,
          },
          310105: {
            name: {
              th: 'สะแกโพรง',
              en: 'Sakae Phrong',
            },
            coordinates: {
              lat: '14.853',
              lng: '102.942',
            },
            zipcode: 31000,
          },
          310106: {
            name: {
              th: 'สวายจึก',
              en: 'Sawai Chik',
            },
            coordinates: {
              lat: '14.896',
              lng: '103.146',
            },
            zipcode: 31000,
          },
          310108: {
            name: {
              th: 'บ้านยาง',
              en: 'Ban Yang',
            },
            coordinates: {
              lat: '15.018',
              lng: '103.156',
            },
            zipcode: 31130,
          },
          310112: {
            name: {
              th: 'พระครู',
              en: 'Phrakhru',
            },
            coordinates: {
              lat: '15.099',
              lng: '103.018',
            },
            zipcode: 31000,
          },
          310113: {
            name: {
              th: 'ถลุงเหล็ก',
              en: 'Thalung Lek',
            },
            coordinates: {
              lat: '15.116',
              lng: '103.078',
            },
            zipcode: 31000,
          },
          310114: {
            name: {
              th: 'หนองตาด',
              en: 'Nong Tat',
            },
            coordinates: {
              lat: '15.031',
              lng: '103.024',
            },
            zipcode: 31000,
          },
          310117: {
            name: {
              th: 'ลุมปุ๊ก',
              en: 'Lumpuk',
            },
            coordinates: {
              lat: '14.908',
              lng: '102.951',
            },
            zipcode: 31000,
          },
          310118: {
            name: {
              th: 'สองห้อง',
              en: 'Song Hong',
            },
            coordinates: {
              lat: '14.808',
              lng: '102.953',
            },
            zipcode: 31000,
          },
          310119: {
            name: {
              th: 'บัวทอง',
              en: 'Bua Thong',
            },
            coordinates: {
              lat: '15.058',
              lng: '103.136',
            },
            zipcode: 31000,
          },
          310120: {
            name: {
              th: 'ชุมเห็ด',
              en: 'Chum Het',
            },
            coordinates: {
              lat: '15.033',
              lng: '103.093',
            },
            zipcode: 31000,
          },
          310122: {
            name: {
              th: 'หลักเขต',
              en: 'Lak Khet',
            },
            coordinates: {
              lat: '14.824',
              lng: '103.159',
            },
            zipcode: 31000,
          },
          310125: {
            name: {
              th: 'สะแกซำ',
              en: 'Sakae Sam',
            },
            coordinates: {
              lat: '14.871',
              lng: '103.099',
            },
            zipcode: 31000,
          },
          310126: {
            name: {
              th: 'กลันทา',
              en: 'Kalantha',
            },
            coordinates: {
              lat: '15.095',
              lng: '103.109',
            },
            zipcode: 31000,
          },
          310127: {
            name: {
              th: 'กระสัง',
              en: 'Krasang',
            },
            coordinates: {
              lat: '14.974',
              lng: '103.047',
            },
            zipcode: 31150,
          },
          310128: {
            name: {
              th: 'เมืองฝาง',
              en: 'Mueang Fang',
            },
            coordinates: {
              lat: '14.782',
              lng: '102.982',
            },
            zipcode: 31000,
          },
        },
      },
      3102: {
        name: {
          th: 'คูเมือง',
          en: 'Khu Mueang',
        },
        tambons: {
          310201: {
            name: {
              th: 'คูเมือง',
              en: 'Khu Mueang',
            },
            coordinates: {
              lat: '15.240',
              lng: '103.000',
            },
            zipcode: 31190,
          },
          310202: {
            name: {
              th: 'ปะเคียบ',
              en: 'Pakhiap',
            },
            coordinates: {
              lat: '15.391',
              lng: '103.054',
            },
            zipcode: null,
          },
          310203: {
            name: {
              th: 'บ้านแพ',
              en: 'Ban Phae',
            },
            coordinates: {
              lat: '15.388',
              lng: '103.008',
            },
            zipcode: 31190,
          },
          310204: {
            name: {
              th: 'พรสำราญ',
              en: 'Phon Samran',
            },
            coordinates: {
              lat: '15.169',
              lng: '103.027',
            },
            zipcode: 31190,
          },
          310205: {
            name: {
              th: 'หินเหล็กไฟ',
              en: 'Hin Lek Fai',
            },
            coordinates: {
              lat: '15.157',
              lng: '103.064',
            },
            zipcode: 31190,
          },
          310206: {
            name: {
              th: 'ตูมใหญ่',
              en: 'Tum Yai',
            },
            coordinates: {
              lat: '15.165',
              lng: '103.137',
            },
            zipcode: 31190,
          },
          310207: {
            name: {
              th: 'หนองขมาร',
              en: 'Nong Khaman',
            },
            coordinates: {
              lat: '15.314',
              lng: '102.995',
            },
            zipcode: 31190,
          },
        },
      },
      3103: {
        name: {
          th: 'กระสัง',
          en: 'Krasang',
        },
        tambons: {
          310301: {
            name: {
              th: 'กระสัง',
              en: 'Krasang',
            },
            coordinates: {
              lat: '14.922',
              lng: '103.296',
            },
            zipcode: null,
          },
          310302: {
            name: {
              th: 'ลำดวน',
              en: 'Lamduan',
            },
            coordinates: {
              lat: '15.061',
              lng: '103.403',
            },
            zipcode: 31160,
          },
          310303: {
            name: {
              th: 'สองชั้น',
              en: 'Song Chan',
            },
            coordinates: {
              lat: '14.887',
              lng: '103.224',
            },
            zipcode: 31160,
          },
          310304: {
            name: {
              th: 'สูงเนิน',
              en: 'Sung Noen',
            },
            coordinates: {
              lat: '14.820',
              lng: '103.239',
            },
            zipcode: 31160,
          },
          310305: {
            name: {
              th: 'หนองเต็ง',
              en: 'Nong Teng',
            },
            coordinates: {
              lat: '14.905',
              lng: '103.374',
            },
            zipcode: 31160,
          },
          310306: {
            name: {
              th: 'เมืองไผ่',
              en: 'Mueang Phai',
            },
            coordinates: {
              lat: '14.994',
              lng: '103.292',
            },
            zipcode: 31210,
          },
          310307: {
            name: {
              th: 'ชุมแสง',
              en: 'Chum Saeng',
            },
            coordinates: {
              lat: '14.984',
              lng: '103.395',
            },
            zipcode: 31150,
          },
          310308: {
            name: {
              th: 'บ้านปรือ',
              en: 'Ban Prue',
            },
            coordinates: {
              lat: '14.848',
              lng: '103.299',
            },
            zipcode: 31160,
          },
          310309: {
            name: {
              th: 'ห้วยสำราญ',
              en: 'Huai Samran',
            },
            coordinates: {
              lat: '15.072',
              lng: '103.336',
            },
            zipcode: 31160,
          },
          310310: {
            name: {
              th: 'กันทรารมย์',
              en: 'Kanthararom',
            },
            coordinates: {
              lat: '14.966',
              lng: '103.340',
            },
            zipcode: 31160,
          },
          310311: {
            name: {
              th: 'ศรีภูมิ',
              en: 'Si Phum',
            },
            coordinates: {
              lat: '15.027',
              lng: '103.338',
            },
            zipcode: 31160,
          },
        },
      },
      3104: {
        name: {
          th: 'นางรอง',
          en: 'Nang Rong',
        },
        tambons: {
          310401: {
            name: {
              th: 'นางรอง',
              en: 'Nang Rong',
            },
            coordinates: {
              lat: '14.673',
              lng: '102.806',
            },
            zipcode: 31110,
          },
          310403: {
            name: {
              th: 'สะเดา',
              en: 'Sadao',
            },
            coordinates: {
              lat: '14.577',
              lng: '102.770',
            },
            zipcode: 31250,
          },
          310405: {
            name: {
              th: 'ชุมแสง',
              en: 'Chum Saeng',
            },
            coordinates: {
              lat: '14.561',
              lng: '102.666',
            },
            zipcode: null,
          },
          310406: {
            name: {
              th: 'หนองโบสถ์',
              en: 'Nong Bot',
            },
            coordinates: {
              lat: '14.612',
              lng: '102.691',
            },
            zipcode: 31110,
          },
          310408: {
            name: {
              th: 'หนองกง',
              en: 'Nong Kong',
            },
            coordinates: {
              lat: '14.674',
              lng: '102.896',
            },
            zipcode: 31110,
          },
          310413: {
            name: {
              th: 'ถนนหัก',
              en: 'Thanon Hak',
            },
            coordinates: {
              lat: '14.616',
              lng: '102.833',
            },
            zipcode: 31110,
          },
          310414: {
            name: {
              th: 'หนองไทร',
              en: 'Nong Sai',
            },
            coordinates: {
              lat: '14.499',
              lng: '102.757',
            },
            zipcode: 31110,
          },
          310415: {
            name: {
              th: 'ก้านเหลือง',
              en: 'Kan Lueang',
            },
            coordinates: {
              lat: '14.730',
              lng: '102.711',
            },
            zipcode: 31110,
          },
          310416: {
            name: {
              th: 'บ้านสิงห์',
              en: 'Ban Sing',
            },
            coordinates: {
              lat: '14.735',
              lng: '102.789',
            },
            zipcode: 31110,
          },
          310417: {
            name: {
              th: 'ลำไทรโยง',
              en: 'Lam Sai Yong',
            },
            coordinates: {
              lat: '14.665',
              lng: '102.735',
            },
            zipcode: 31110,
          },
          310418: {
            name: {
              th: 'ทรัพย์พระยา',
              en: 'Sap Phraya',
            },
            coordinates: {
              lat: '14.516',
              lng: '102.662',
            },
            zipcode: 31110,
          },
          310424: {
            name: {
              th: 'หนองยายพิมพ์',
              en: 'Nong Yai Phim',
            },
            coordinates: {
              lat: '14.690',
              lng: '102.863',
            },
            zipcode: 31110,
          },
          310425: {
            name: {
              th: 'หัวถนน',
              en: 'Hua Thanon',
            },
            coordinates: {
              lat: '14.661',
              lng: '102.648',
            },
            zipcode: 31110,
          },
          310426: {
            name: {
              th: 'ทุ่งแสงทอง',
              en: 'Thung Saeng Thong',
            },
            coordinates: {
              lat: '14.581',
              lng: '102.705',
            },
            zipcode: 31110,
          },
          310427: {
            name: {
              th: 'หนองโสน',
              en: 'Nong Sano',
            },
            coordinates: {
              lat: '14.737',
              lng: '102.934',
            },
            zipcode: 31110,
          },
        },
      },
      3105: {
        name: {
          th: 'หนองกี่',
          en: 'Nong Ki',
        },
        tambons: {
          310501: {
            name: {
              th: 'หนองกี่',
              en: 'Nong Ki',
            },
            coordinates: {
              lat: '14.669',
              lng: '102.571',
            },
            zipcode: null,
          },
          310502: {
            name: {
              th: 'เย้ยปราสาท',
              en: 'Yoei Prasat',
            },
            coordinates: {
              lat: '14.739',
              lng: '102.564',
            },
            zipcode: null,
          },
          310503: {
            name: {
              th: 'เมืองไผ่',
              en: 'Mueang Phai',
            },
            coordinates: {
              lat: '14.774',
              lng: '102.645',
            },
            zipcode: null,
          },
          310504: {
            name: {
              th: 'ดอนอะราง',
              en: 'Don Arang',
            },
            coordinates: {
              lat: '14.642',
              lng: '102.571',
            },
            zipcode: null,
          },
          310505: {
            name: {
              th: 'โคกสว่าง',
              en: 'Khok Sawang',
            },
            coordinates: {
              lat: '14.782',
              lng: '102.489',
            },
            zipcode: 31210,
          },
          310506: {
            name: {
              th: 'ทุ่งกระตาดพัฒนา',
              en: 'Thung Kratat Phatthana',
            },
            coordinates: {
              lat: '14.674',
              lng: '102.497',
            },
            zipcode: 31210,
          },
          310507: {
            name: {
              th: 'ทุ่งกระเต็น',
              en: 'Thung Kraten',
            },
            coordinates: {
              lat: '14.693',
              lng: '102.564',
            },
            zipcode: 31210,
          },
          310508: {
            name: {
              th: 'ท่าโพธิ์ชัย',
              en: 'Tha Pho Chai',
            },
            coordinates: {
              lat: '14.736',
              lng: '102.635',
            },
            zipcode: 31210,
          },
          310509: {
            name: {
              th: 'โคกสูง',
              en: 'Khok Sung',
            },
            coordinates: {
              lat: '14.806',
              lng: '102.562',
            },
            zipcode: 31210,
          },
          310510: {
            name: {
              th: 'บุกระสัง',
              en: 'Bu Krasang',
            },
            coordinates: {
              lat: '14.724',
              lng: '102.501',
            },
            zipcode: 31210,
          },
        },
      },
      3106: {
        name: {
          th: 'ละหานทราย',
          en: 'Lahan Sai',
        },
        tambons: {
          310601: {
            name: {
              th: 'ละหานทราย',
              en: 'Lahan Sai',
            },
            coordinates: {
              lat: '14.418',
              lng: '102.854',
            },
            zipcode: 31170,
          },
          310603: {
            name: {
              th: 'ตาจง',
              en: 'Ta Chong',
            },
            coordinates: {
              lat: '14.439',
              lng: '102.920',
            },
            zipcode: 31170,
          },
          310604: {
            name: {
              th: 'สำโรงใหม่',
              en: 'Samrong Mai',
            },
            coordinates: {
              lat: '14.284',
              lng: '102.850',
            },
            zipcode: 31170,
          },
          310607: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '14.268',
              lng: '102.935',
            },
            zipcode: 31170,
          },
          310610: {
            name: {
              th: 'หนองตระครอง',
              en: 'Nong Takhrong',
            },
            coordinates: {
              lat: '14.393',
              lng: '102.787',
            },
            zipcode: 31170,
          },
          310611: {
            name: {
              th: 'โคกว่าน',
              en: 'Khok Wan',
            },
            coordinates: {
              lat: '14.449',
              lng: '102.784',
            },
            zipcode: 31170,
          },
        },
      },
      3107: {
        name: {
          th: 'ประโคนชัย',
          en: 'Prakhon Chai',
        },
        tambons: {
          310701: {
            name: {
              th: 'ประโคนชัย',
              en: 'Prakhon Chai',
            },
            coordinates: {
              lat: '14.613',
              lng: '103.081',
            },
            zipcode: 31140,
          },
          310702: {
            name: {
              th: 'แสลงโทน',
              en: 'Salaeng Thon',
            },
            coordinates: {
              lat: '14.787',
              lng: '103.055',
            },
            zipcode: 31140,
          },
          310703: {
            name: {
              th: 'บ้านไทร',
              en: 'Ban Sai',
            },
            coordinates: {
              lat: '14.705',
              lng: '103.065',
            },
            zipcode: 31140,
          },
          310705: {
            name: {
              th: 'ละเวี้ย',
              en: 'Lawia',
            },
            coordinates: {
              lat: '14.599',
              lng: '103.191',
            },
            zipcode: 31140,
          },
          310706: {
            name: {
              th: 'จรเข้มาก',
              en: 'Chorakhe Mak',
            },
            coordinates: {
              lat: '14.500',
              lng: '102.989',
            },
            zipcode: 31140,
          },
          310707: {
            name: {
              th: 'ปังกู',
              en: 'Pang Ku',
            },
            coordinates: {
              lat: '14.537',
              lng: '103.067',
            },
            zipcode: 31140,
          },
          310708: {
            name: {
              th: 'โคกย่าง',
              en: 'Khok Yang',
            },
            coordinates: {
              lat: '14.562',
              lng: '103.005',
            },
            zipcode: 31140,
          },
          310710: {
            name: {
              th: 'โคกม้า',
              en: 'Khok Ma',
            },
            coordinates: {
              lat: '14.641',
              lng: '103.035',
            },
            zipcode: 31140,
          },
          310713: {
            name: {
              th: 'ไพศาล',
              en: 'Phaisan',
            },
            coordinates: {
              lat: '14.662',
              lng: '103.208',
            },
            zipcode: 31140,
          },
          310714: {
            name: {
              th: 'ตะโกตาพิ',
              en: 'Tako Taphi',
            },
            coordinates: {
              lat: '14.638',
              lng: '102.981',
            },
            zipcode: 31140,
          },
          310715: {
            name: {
              th: 'เขาคอก',
              en: 'Khao Khok',
            },
            coordinates: {
              lat: '14.440',
              lng: '103.004',
            },
            zipcode: 31140,
          },
          310716: {
            name: {
              th: 'หนองบอน',
              en: 'Nong Bon',
            },
            coordinates: {
              lat: '14.542',
              lng: '103.143',
            },
            zipcode: 31140,
          },
          310718: {
            name: {
              th: 'โคกมะขาม',
              en: 'Khok Makham',
            },
            coordinates: {
              lat: '14.602',
              lng: '103.129',
            },
            zipcode: 31140,
          },
          310719: {
            name: {
              th: 'โคกตูม',
              en: 'Khok Tum',
            },
            coordinates: {
              lat: '14.702',
              lng: '102.989',
            },
            zipcode: 31140,
          },
          310720: {
            name: {
              th: 'ประทัดบุ',
              en: 'Prathat Bu',
            },
            coordinates: {
              lat: '14.569',
              lng: '102.962',
            },
            zipcode: 31140,
          },
          310721: {
            name: {
              th: 'สี่เหลี่ยม',
              en: 'Si Liam',
            },
            coordinates: {
              lat: '14.750',
              lng: '103.003',
            },
            zipcode: 31140,
          },
        },
      },
      3108: {
        name: {
          th: 'บ้านกรวด',
          en: 'Ban Kruat',
        },
        tambons: {
          310801: {
            name: {
              th: 'บ้านกรวด',
              en: 'Ban Kruat',
            },
            coordinates: {
              lat: '14.429',
              lng: '103.077',
            },
            zipcode: 31180,
          },
          310802: {
            name: {
              th: 'โนนเจริญ',
              en: 'Non Charoen',
            },
            coordinates: {
              lat: '14.464',
              lng: '103.189',
            },
            zipcode: 31180,
          },
          310803: {
            name: {
              th: 'หนองไม้งาม',
              en: 'Nong Mai Ngam',
            },
            coordinates: {
              lat: '14.344',
              lng: '102.997',
            },
            zipcode: 31180,
          },
          310804: {
            name: {
              th: 'ปราสาท',
              en: 'Prasat',
            },
            coordinates: {
              lat: '14.362',
              lng: '103.109',
            },
            zipcode: null,
          },
          310805: {
            name: {
              th: 'สายตะกู',
              en: 'Sai Taku',
            },
            coordinates: {
              lat: '14.397',
              lng: '103.211',
            },
            zipcode: 31180,
          },
          310806: {
            name: {
              th: 'หินลาด',
              en: 'Hin Lat',
            },
            coordinates: {
              lat: '14.473',
              lng: '103.143',
            },
            zipcode: 31180,
          },
          310807: {
            name: {
              th: 'บึงเจริญ',
              en: 'Bueng Charoen',
            },
            coordinates: {
              lat: '14.337',
              lng: '103.037',
            },
            zipcode: 31180,
          },
          310808: {
            name: {
              th: 'จันทบเพชร',
              en: 'Chanthabophet',
            },
            coordinates: {
              lat: '14.384',
              lng: '103.164',
            },
            zipcode: 31180,
          },
          310809: {
            name: {
              th: 'เขาดินเหนือ',
              en: 'Khao Din Nuea',
            },
            coordinates: {
              lat: '14.524',
              lng: '103.199',
            },
            zipcode: 31180,
          },
        },
      },
      3109: {
        name: {
          th: 'พุทไธสง',
          en: 'Phutthaisong',
        },
        tambons: {
          310901: {
            name: {
              th: 'พุทไธสง',
              en: 'Phutthaisong',
            },
            coordinates: {
              lat: '15.582',
              lng: '103.014',
            },
            zipcode: 31120,
          },
          310902: {
            name: {
              th: 'มะเฟือง',
              en: 'Mafueang',
            },
            coordinates: {
              lat: '15.497',
              lng: '103.032',
            },
            zipcode: 31120,
          },
          310903: {
            name: {
              th: 'บ้านจาน',
              en: 'Ban Chan',
            },
            coordinates: {
              lat: '15.507',
              lng: '102.983',
            },
            zipcode: 31120,
          },
          310906: {
            name: {
              th: 'บ้านเป้า',
              en: 'Ban Pao',
            },
            coordinates: {
              lat: '15.627',
              lng: '102.892',
            },
            zipcode: 31120,
          },
          310907: {
            name: {
              th: 'บ้านแวง',
              en: 'Ban Waeng',
            },
            coordinates: {
              lat: '15.580',
              lng: '102.959',
            },
            zipcode: 31120,
          },
          310909: {
            name: {
              th: 'บ้านยาง',
              en: 'Ban Yang',
            },
            coordinates: {
              lat: '15.463',
              lng: '103.097',
            },
            zipcode: null,
          },
          310910: {
            name: {
              th: 'หายโศก',
              en: 'Hai Sok',
            },
            coordinates: {
              lat: '15.526',
              lng: '102.937',
            },
            zipcode: 31120,
          },
        },
      },
      3110: {
        name: {
          th: 'ลำปลายมาศ',
          en: 'Lam Plai Mat',
        },
        tambons: {
          311001: {
            name: {
              th: 'ลำปลายมาศ',
              en: 'Lam Plai Mat',
            },
            coordinates: {
              lat: '15.019',
              lng: '102.834',
            },
            zipcode: 31130,
          },
          311002: {
            name: {
              th: 'หนองคู',
              en: 'Nong Khu',
            },
            coordinates: {
              lat: '14.999',
              lng: '102.803',
            },
            zipcode: 31130,
          },
          311003: {
            name: {
              th: 'แสลงพัน',
              en: 'Salaeng Phan',
            },
            coordinates: {
              lat: '14.990',
              lng: '102.957',
            },
            zipcode: 31130,
          },
          311004: {
            name: {
              th: 'ทะเมนชัย',
              en: 'Thamenchai',
            },
            coordinates: {
              lat: '15.051',
              lng: '102.945',
            },
            zipcode: 31130,
          },
          311005: {
            name: {
              th: 'ตลาดโพธิ์',
              en: 'Talat Pho',
            },
            coordinates: {
              lat: '15.083',
              lng: '102.893',
            },
            zipcode: 31130,
          },
          311006: {
            name: {
              th: 'หนองกระทิง',
              en: 'Nong Kathing',
            },
            coordinates: {
              lat: '14.974',
              lng: '102.760',
            },
            zipcode: 31130,
          },
          311007: {
            name: {
              th: 'โคกกลาง',
              en: 'Khok Klang',
            },
            coordinates: {
              lat: '14.949',
              lng: '102.862',
            },
            zipcode: 31130,
          },
          311008: {
            name: {
              th: 'โคกสะอาด',
              en: 'Khok Sa-At',
            },
            coordinates: {
              lat: '15.154',
              lng: '102.902',
            },
            zipcode: 31130,
          },
          311009: {
            name: {
              th: 'เมืองแฝก',
              en: 'Mueang Faek',
            },
            coordinates: {
              lat: '15.154',
              lng: '102.973',
            },
            zipcode: 31130,
          },
          311010: {
            name: {
              th: 'บ้านยาง',
              en: 'Ban Yang',
            },
            coordinates: {
              lat: '14.873',
              lng: '102.886',
            },
            zipcode: null,
          },
          311011: {
            name: {
              th: 'ผไทรินทร์',
              en: 'Phathai Rin',
            },
            coordinates: {
              lat: '14.917',
              lng: '102.777',
            },
            zipcode: 31130,
          },
          311012: {
            name: {
              th: 'โคกล่าม',
              en: 'Khok Lam',
            },
            coordinates: {
              lat: '15.114',
              lng: '102.839',
            },
            zipcode: 31130,
          },
          311013: {
            name: {
              th: 'หินโคน',
              en: 'Hin Khon',
            },
            coordinates: {
              lat: '15.057',
              lng: '102.820',
            },
            zipcode: 31130,
          },
          311014: {
            name: {
              th: 'หนองบัวโคก',
              en: 'Nong Bua Khok',
            },
            coordinates: {
              lat: '15.006',
              lng: '102.889',
            },
            zipcode: 31130,
          },
          311015: {
            name: {
              th: 'บุโพธิ์',
              en: 'Bu Pho',
            },
            coordinates: {
              lat: '15.109',
              lng: '102.938',
            },
            zipcode: 31130,
          },
          311016: {
            name: {
              th: 'หนองโดน',
              en: 'Nong Don',
            },
            coordinates: {
              lat: '14.882',
              lng: '102.824',
            },
            zipcode: 31130,
          },
        },
      },
      3111: {
        name: {
          th: 'สตึก',
          en: 'Satuek',
        },
        tambons: {
          311101: {
            name: {
              th: 'สตึก',
              en: 'Satuek',
            },
            coordinates: {
              lat: '15.263',
              lng: '103.301',
            },
            zipcode: 31150,
          },
          311102: {
            name: {
              th: 'นิคม',
              en: 'Nikhom',
            },
            coordinates: {
              lat: '15.274',
              lng: '103.225',
            },
            zipcode: 31150,
          },
          311103: {
            name: {
              th: 'ทุ่งวัง',
              en: 'Thung Wang',
            },
            coordinates: {
              lat: '15.259',
              lng: '103.386',
            },
            zipcode: 31150,
          },
          311104: {
            name: {
              th: 'เมืองแก',
              en: 'Mueang Kae',
            },
            coordinates: {
              lat: '15.190',
              lng: '103.308',
            },
            zipcode: 31150,
          },
          311105: {
            name: {
              th: 'หนองใหญ่',
              en: 'Nong Yai',
            },
            coordinates: {
              lat: '15.131',
              lng: '103.291',
            },
            zipcode: 31150,
          },
          311106: {
            name: {
              th: 'ร่อนทอง',
              en: 'Ron Thong',
            },
            coordinates: {
              lat: '15.200',
              lng: '103.244',
            },
            zipcode: 31150,
          },
          311109: {
            name: {
              th: 'ดอนมนต์',
              en: 'Don Mon',
            },
            coordinates: {
              lat: '15.209',
              lng: '103.183',
            },
            zipcode: 31150,
          },
          311110: {
            name: {
              th: 'ชุมแสง',
              en: 'Chum Saeng',
            },
            coordinates: {
              lat: '15.137',
              lng: '103.377',
            },
            zipcode: null,
          },
          311111: {
            name: {
              th: 'ท่าม่วง',
              en: 'Tha Muang',
            },
            coordinates: {
              lat: '15.300',
              lng: '103.442',
            },
            zipcode: 31150,
          },
          311112: {
            name: {
              th: 'สะแก',
              en: 'Sakae',
            },
            coordinates: {
              lat: '15.300',
              lng: '103.354',
            },
            zipcode: 31150,
          },
          311114: {
            name: {
              th: 'สนามชัย',
              en: 'Sanam Chai',
            },
            coordinates: {
              lat: '15.207',
              lng: '103.356',
            },
            zipcode: 31150,
          },
          311115: {
            name: {
              th: 'กระสัง',
              en: 'Krasang',
            },
            coordinates: {
              lat: '15.195',
              lng: '103.405',
            },
            zipcode: null,
          },
        },
      },
      3112: {
        name: {
          th: 'ปะคำ',
          en: 'Pakham',
        },
        tambons: {
          311201: {
            name: {
              th: 'ปะคำ',
              en: 'Pakham',
            },
            coordinates: {
              lat: '14.454',
              lng: '102.721',
            },
            zipcode: 31220,
          },
          311202: {
            name: {
              th: 'ไทยเจริญ',
              en: 'Thai Charoen',
            },
            coordinates: {
              lat: '14.461',
              lng: '102.683',
            },
            zipcode: null,
          },
          311203: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '14.432',
              lng: '102.657',
            },
            zipcode: 31220,
          },
          311204: {
            name: {
              th: 'โคกมะม่วง',
              en: 'Khok Mamuang',
            },
            coordinates: {
              lat: '14.402',
              lng: '102.612',
            },
            zipcode: 31220,
          },
          311205: {
            name: {
              th: 'หูทำนบ',
              en: 'Hu Thamnop',
            },
            coordinates: {
              lat: '14.364',
              lng: '102.696',
            },
            zipcode: 31220,
          },
        },
      },
      3113: {
        name: {
          th: 'นาโพธิ์',
          en: 'Na Pho',
        },
        tambons: {
          311301: {
            name: {
              th: 'นาโพธิ์',
              en: 'Na Pho',
            },
            coordinates: {
              lat: '15.635',
              lng: '102.944',
            },
            zipcode: 31230,
          },
          311302: {
            name: {
              th: 'บ้านคู',
              en: 'Ban Khu',
            },
            coordinates: {
              lat: '15.712',
              lng: '102.933',
            },
            zipcode: 31230,
          },
          311303: {
            name: {
              th: 'บ้านดู่',
              en: 'Ban Du',
            },
            coordinates: {
              lat: '15.713',
              lng: '102.977',
            },
            zipcode: 31230,
          },
          311304: {
            name: {
              th: 'ดอนกอก',
              en: 'Don Kok',
            },
            coordinates: {
              lat: '15.763',
              lng: '102.895',
            },
            zipcode: 31230,
          },
          311305: {
            name: {
              th: 'ศรีสว่าง',
              en: 'Si Sawang',
            },
            coordinates: {
              lat: '15.673',
              lng: '102.934',
            },
            zipcode: 31230,
          },
        },
      },
      3114: {
        name: {
          th: 'หนองหงส์',
          en: 'Nong Hong',
        },
        tambons: {
          311401: {
            name: {
              th: 'สระแก้ว',
              en: 'Sa Kaeo',
            },
            coordinates: {
              lat: '14.828',
              lng: '102.681',
            },
            zipcode: null,
          },
          311402: {
            name: {
              th: 'ห้วยหิน',
              en: 'Huai Hin',
            },
            coordinates: {
              lat: '14.871',
              lng: '102.594',
            },
            zipcode: null,
          },
          311403: {
            name: {
              th: 'ไทยสามัคคี',
              en: 'Thai Samakkhi',
            },
            coordinates: {
              lat: '14.885',
              lng: '102.763',
            },
            zipcode: null,
          },
          311404: {
            name: {
              th: 'หนองชัยศรี',
              en: 'Nong Chai Si',
            },
            coordinates: {
              lat: '14.823',
              lng: '102.645',
            },
            zipcode: 31240,
          },
          311405: {
            name: {
              th: 'เสาเดียว',
              en: 'Sao Diao',
            },
            coordinates: {
              lat: '14.911',
              lng: '102.609',
            },
            zipcode: 31240,
          },
          311406: {
            name: {
              th: 'เมืองฝ้าย',
              en: 'Mueang Fai',
            },
            coordinates: {
              lat: '14.843',
              lng: '102.741',
            },
            zipcode: 31240,
          },
          311407: {
            name: {
              th: 'สระทอง',
              en: 'Sa Thong',
            },
            coordinates: {
              lat: '14.889',
              lng: '102.670',
            },
            zipcode: 31240,
          },
        },
      },
      3115: {
        name: {
          th: 'พลับพลาชัย',
          en: 'Phlapphla Chai',
        },
        tambons: {
          311501: {
            name: {
              th: 'จันดุม',
              en: 'Chan Dum',
            },
            coordinates: {
              lat: '14.678',
              lng: '103.118',
            },
            zipcode: null,
          },
          311502: {
            name: {
              th: 'โคกขมิ้น',
              en: 'Khok Khamin',
            },
            coordinates: {
              lat: '14.769',
              lng: '103.193',
            },
            zipcode: null,
          },
          311503: {
            name: {
              th: 'ป่าชัน',
              en: 'Pa Chan',
            },
            coordinates: {
              lat: '14.717',
              lng: '103.241',
            },
            zipcode: null,
          },
          311504: {
            name: {
              th: 'สะเดา',
              en: 'Sadao',
            },
            coordinates: {
              lat: '14.722',
              lng: '103.168',
            },
            zipcode: null,
          },
          311505: {
            name: {
              th: 'สำโรง',
              en: 'Samrong',
            },
            coordinates: {
              lat: '14.776',
              lng: '103.111',
            },
            zipcode: null,
          },
        },
      },
      3116: {
        name: {
          th: 'ห้วยราช',
          en: 'Huai Rat',
        },
        tambons: {
          311601: {
            name: {
              th: 'ห้วยราช',
              en: 'Huai Rat',
            },
            coordinates: {
              lat: '14.964',
              lng: '103.183',
            },
            zipcode: null,
          },
          311602: {
            name: {
              th: 'สามแวง',
              en: 'Sam Waeng',
            },
            coordinates: {
              lat: '15.009',
              lng: '103.192',
            },
            zipcode: null,
          },
          311603: {
            name: {
              th: 'ตาเสา',
              en: 'Ta Sao',
            },
            coordinates: {
              lat: '15.088',
              lng: '103.285',
            },
            zipcode: null,
          },
          311604: {
            name: {
              th: 'บ้านตะโก',
              en: 'Ban Tako',
            },
            coordinates: {
              lat: '14.969',
              lng: '103.233',
            },
            zipcode: null,
          },
          311605: {
            name: {
              th: 'สนวน',
              en: 'Sanuan',
            },
            coordinates: {
              lat: '14.933',
              lng: '103.182',
            },
            zipcode: null,
          },
          311606: {
            name: {
              th: 'โคกเหล็ก',
              en: 'Khok Lek',
            },
            coordinates: {
              lat: '15.023',
              lng: '103.230',
            },
            zipcode: 31000,
          },
          311607: {
            name: {
              th: 'เมืองโพธิ์',
              en: 'Mueang Pho',
            },
            coordinates: {
              lat: '15.042',
              lng: '103.268',
            },
            zipcode: 31000,
          },
          311608: {
            name: {
              th: 'ห้วยราชา',
              en: 'Huai Racha',
            },
            coordinates: {
              lat: '14.982',
              lng: '103.203',
            },
            zipcode: 31000,
          },
        },
      },
      3117: {
        name: {
          th: 'โนนสุวรรณ',
          en: 'Non Din Daeng',
        },
        tambons: {
          311701: {
            name: {
              th: 'โนนสุวรรณ',
              en: 'Non Suwan',
            },
            coordinates: {
              lat: '14.538',
              lng: '102.604',
            },
            zipcode: null,
          },
          311702: {
            name: {
              th: 'ทุ่งจังหัน',
              en: 'Thung Changhan',
            },
            coordinates: {
              lat: '14.566',
              lng: '102.558',
            },
            zipcode: null,
          },
          311703: {
            name: {
              th: 'โกรกแก้ว',
              en: 'Krok Kaeo',
            },
            coordinates: {
              lat: '14.621',
              lng: '102.637',
            },
            zipcode: null,
          },
          311704: {
            name: {
              th: 'ดงอีจาน',
              en: 'Dong I Chan',
            },
            coordinates: {
              lat: '14.546',
              lng: '102.517',
            },
            zipcode: null,
          },
        },
      },
      3118: {
        name: {
          th: 'ชำนิ',
          en: 'Chamni',
        },
        tambons: {
          311801: {
            name: {
              th: 'ชำนิ',
              en: 'Chamni',
            },
            coordinates: {
              lat: '14.785',
              lng: '102.810',
            },
            zipcode: null,
          },
          311802: {
            name: {
              th: 'หนองปล่อง',
              en: 'Nong Plong',
            },
            coordinates: {
              lat: '14.743',
              lng: '102.844',
            },
            zipcode: null,
          },
          311803: {
            name: {
              th: 'เมืองยาง',
              en: 'Mueang Yang',
            },
            coordinates: {
              lat: '14.794',
              lng: '102.906',
            },
            zipcode: null,
          },
          311804: {
            name: {
              th: 'ช่อผกา',
              en: 'Cho Phaka',
            },
            coordinates: {
              lat: '14.809',
              lng: '102.787',
            },
            zipcode: null,
          },
          311805: {
            name: {
              th: 'ละลวด',
              en: 'Laluat',
            },
            coordinates: {
              lat: '14.780',
              lng: '102.745',
            },
            zipcode: null,
          },
          311806: {
            name: {
              th: 'โคกสนวน',
              en: 'Khok Sanuan',
            },
            coordinates: {
              lat: '14.755',
              lng: '102.888',
            },
            zipcode: 31110,
          },
        },
      },
      3119: {
        name: {
          th: 'บ้านใหม่ไชยพจน์',
          en: 'Ban Mai Chaiyaphot',
        },
        tambons: {
          311901: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '15.553',
              lng: '102.907',
            },
            zipcode: null,
          },
          311902: {
            name: {
              th: 'ทองหลาง',
              en: 'Thong Lang',
            },
            coordinates: {
              lat: '15.608',
              lng: '102.805',
            },
            zipcode: null,
          },
          311903: {
            name: {
              th: 'แดงใหญ่',
              en: 'Daeng Yai',
            },
            coordinates: {
              lat: '15.605',
              lng: '102.857',
            },
            zipcode: 31120,
          },
          311904: {
            name: {
              th: 'กู่สวนแตง',
              en: 'Ku Suan Taeng',
            },
            coordinates: {
              lat: '15.534',
              lng: '102.860',
            },
            zipcode: null,
          },
          311905: {
            name: {
              th: 'หนองเยือง',
              en: 'Nong Yueang',
            },
            coordinates: {
              lat: '15.555',
              lng: '102.811',
            },
            zipcode: null,
          },
        },
      },
      3120: {
        name: {
          th: 'โนนดินแดง',
          en: 'Non Din Daeng',
        },
        tambons: {
          312001: {
            name: {
              th: 'โนนดินแดง',
              en: 'Non Din Daeng',
            },
            coordinates: {
              lat: '14.316',
              lng: '102.721',
            },
            zipcode: null,
          },
          312002: {
            name: {
              th: 'ส้มป่อย',
              en: 'Som Poi',
            },
            coordinates: {
              lat: '14.346',
              lng: '102.764',
            },
            zipcode: null,
          },
          312003: {
            name: {
              th: 'ลำนางรอง',
              en: 'Lam Nang Rong',
            },
            coordinates: {
              lat: '14.213',
              lng: '102.698',
            },
            zipcode: null,
          },
        },
      },
      3121: {
        name: {
          th: 'บ้านด่าน',
          en: 'Ban Dan',
        },
        tambons: {
          312101: {
            name: {
              th: 'บ้านด่าน',
              en: 'Ban Dan',
            },
            coordinates: {
              lat: '15.112',
              lng: '103.162',
            },
            zipcode: null,
          },
          312102: {
            name: {
              th: 'ปราสาท',
              en: 'Prasat',
            },
            coordinates: {
              lat: '15.081',
              lng: '103.217',
            },
            zipcode: null,
          },
          312103: {
            name: {
              th: 'วังเหนือ',
              en: 'Wang Nuea',
            },
            coordinates: {
              lat: '15.138',
              lng: '103.219',
            },
            zipcode: null,
          },
          312104: {
            name: {
              th: 'โนนขวาง',
              en: 'Non Khwang',
            },
            coordinates: {
              lat: '15.191',
              lng: '103.157',
            },
            zipcode: null,
          },
        },
      },
      3122: {
        name: {
          th: 'แคนดง',
          en: 'Khaen Dong',
        },
        tambons: {
          312201: {
            name: {
              th: 'แคนดง',
              en: 'Khaen Dong',
            },
            coordinates: {
              lat: '15.317',
              lng: '103.123',
            },
            zipcode: null,
          },
          312202: {
            name: {
              th: 'ดงพลอง',
              en: 'Dong Phlong',
            },
            coordinates: {
              lat: '15.321',
              lng: '103.172',
            },
            zipcode: null,
          },
          312203: {
            name: {
              th: 'สระบัว',
              en: 'Sa Bua',
            },
            coordinates: {
              lat: '15.320',
              lng: '103.077',
            },
            zipcode: null,
          },
          312204: {
            name: {
              th: 'หัวฝาย',
              en: 'Hua Fai',
            },
            coordinates: {
              lat: '15.267',
              lng: '103.180',
            },
            zipcode: null,
          },
        },
      },
      3123: {
        name: {
          th: 'เฉลิมพระเกียรติ',
          en: 'Chaloem Phra Kiet',
        },
        tambons: {
          312301: {
            name: {
              th: 'เจริญสุข',
              en: 'Charoen Suk',
            },
            coordinates: {
              lat: '14.574',
              lng: '102.865',
            },
            zipcode: null,
          },
          312302: {
            name: {
              th: 'ตาเป๊ก',
              en: 'Ta Pek',
            },
            coordinates: {
              lat: '14.568',
              lng: '102.924',
            },
            zipcode: null,
          },
          312303: {
            name: {
              th: 'อีสานเขต',
              en: 'Isan Khet',
            },
            coordinates: {
              lat: '14.641',
              lng: '102.931',
            },
            zipcode: null,
          },
          312304: {
            name: {
              th: 'ถาวร',
              en: 'Thawon',
            },
            coordinates: {
              lat: '14.518',
              lng: '102.870',
            },
            zipcode: null,
          },
          312305: {
            name: {
              th: 'ยายแย้มวัฒนา',
              en: 'Yai Yaem Watthana',
            },
            coordinates: {
              lat: '14.505',
              lng: '102.918',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  32: {
    name: {
      th: 'สุรินทร์',
      en: 'Surin',
    },
    amphoes: {
      3201: {
        name: {
          th: 'เมืองสุรินทร์',
          en: 'Mueang Surin',
        },
        tambons: {
          320101: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '14.884',
              lng: '103.490',
            },
            zipcode: 32000,
          },
          320102: {
            name: {
              th: 'ตั้งใจ',
              en: 'Tang Chai',
            },
            coordinates: {
              lat: '15.074',
              lng: '103.550',
            },
            zipcode: 32000,
          },
          320103: {
            name: {
              th: 'เพี้ยราม',
              en: 'Phia Ram',
            },
            coordinates: {
              lat: '15.067',
              lng: '103.471',
            },
            zipcode: 32000,
          },
          320104: {
            name: {
              th: 'นาดี',
              en: 'Na Di',
            },
            coordinates: {
              lat: '15.024',
              lng: '103.528',
            },
            zipcode: 32000,
          },
          320105: {
            name: {
              th: 'ท่าสว่าง',
              en: 'Tha Sawang',
            },
            coordinates: {
              lat: '14.955',
              lng: '103.452',
            },
            zipcode: 32000,
          },
          320106: {
            name: {
              th: 'สลักได',
              en: 'Salak Dai',
            },
            coordinates: {
              lat: '14.855',
              lng: '103.562',
            },
            zipcode: 32000,
          },
          320107: {
            name: {
              th: 'ตาอ็อง',
              en: 'Ta-Ong',
            },
            coordinates: {
              lat: '14.748',
              lng: '103.617',
            },
            zipcode: 32000,
          },
          320109: {
            name: {
              th: 'สำโรง',
              en: 'Samrong',
            },
            coordinates: {
              lat: '14.802',
              lng: '103.627',
            },
            zipcode: 32000,
          },
          320110: {
            name: {
              th: 'แกใหญ่',
              en: 'Kae Yai',
            },
            coordinates: {
              lat: '14.951',
              lng: '103.513',
            },
            zipcode: 32000,
          },
          320111: {
            name: {
              th: 'นอกเมือง',
              en: 'Nok Mueang',
            },
            coordinates: {
              lat: '14.858',
              lng: '103.491',
            },
            zipcode: 32000,
          },
          320112: {
            name: {
              th: 'คอโค',
              en: 'Kho Kho',
            },
            coordinates: {
              lat: '14.903',
              lng: '103.425',
            },
            zipcode: 32000,
          },
          320113: {
            name: {
              th: 'สวาย',
              en: 'Sawat',
            },
            coordinates: {
              lat: '14.793',
              lng: '103.337',
            },
            zipcode: 32000,
          },
          320114: {
            name: {
              th: 'เฉนียง',
              en: 'Chaniang',
            },
            coordinates: {
              lat: '14.796',
              lng: '103.477',
            },
            zipcode: 32000,
          },
          320116: {
            name: {
              th: 'เทนมีย์',
              en: 'Then Mi',
            },
            coordinates: {
              lat: '14.774',
              lng: '103.542',
            },
            zipcode: 32000,
          },
          320118: {
            name: {
              th: 'นาบัว',
              en: 'Na Bua',
            },
            coordinates: {
              lat: '14.782',
              lng: '103.412',
            },
            zipcode: 32000,
          },
          320119: {
            name: {
              th: 'เมืองที',
              en: 'Mueang Thi',
            },
            coordinates: {
              lat: '14.910',
              lng: '103.652',
            },
            zipcode: 32000,
          },
          320120: {
            name: {
              th: 'ราม',
              en: 'Ram',
            },
            coordinates: {
              lat: '14.848',
              lng: '103.650',
            },
            zipcode: 32000,
          },
          320121: {
            name: {
              th: 'บุฤาษี',
              en: 'Bu Ruesi',
            },
            coordinates: {
              lat: '14.904',
              lng: '103.597',
            },
            zipcode: 32000,
          },
          320122: {
            name: {
              th: 'ตระแสง',
              en: 'Trasaeng',
            },
            coordinates: {
              lat: '14.855',
              lng: '103.403',
            },
            zipcode: 32000,
          },
          320125: {
            name: {
              th: 'แสลงพันธ์',
              en: 'Salaeng Phan',
            },
            coordinates: {
              lat: '14.918',
              lng: '103.545',
            },
            zipcode: 32000,
          },
          320126: {
            name: {
              th: 'กาเกาะ',
              en: 'Ka Ko',
            },
            coordinates: {
              lat: '15.114',
              lng: '103.473',
            },
            zipcode: 32000,
          },
        },
      },
      3202: {
        name: {
          th: 'ชุมพลบุรี',
          en: 'Chumphon Buri',
        },
        tambons: {
          320201: {
            name: {
              th: 'ชุมพลบุรี',
              en: 'Chumphon Buri',
            },
            coordinates: {
              lat: '15.377',
              lng: '103.411',
            },
            zipcode: 32190,
          },
          320202: {
            name: {
              th: 'นาหนองไผ่',
              en: 'Na Nong Phai',
            },
            coordinates: {
              lat: '15.376',
              lng: '103.487',
            },
            zipcode: 32190,
          },
          320203: {
            name: {
              th: 'ไพรขลา',
              en: 'Phrai Khla',
            },
            coordinates: {
              lat: '15.395',
              lng: '103.556',
            },
            zipcode: 32190,
          },
          320204: {
            name: {
              th: 'ศรีณรงค์',
              en: 'Si Narong',
            },
            coordinates: {
              lat: '15.373',
              lng: '103.338',
            },
            zipcode: 32190,
          },
          320205: {
            name: {
              th: 'ยะวึก',
              en: 'Yawuek',
            },
            coordinates: {
              lat: '15.359',
              lng: '103.230',
            },
            zipcode: 32190,
          },
          320206: {
            name: {
              th: 'เมืองบัว',
              en: 'Mueang Bua',
            },
            coordinates: {
              lat: '15.429',
              lng: '103.205',
            },
            zipcode: 32190,
          },
          320207: {
            name: {
              th: 'สระขุด',
              en: 'Sa Khut',
            },
            coordinates: {
              lat: '15.440',
              lng: '103.150',
            },
            zipcode: 32190,
          },
          320208: {
            name: {
              th: 'กระเบื้อง',
              en: 'Krabueang',
            },
            coordinates: {
              lat: '15.359',
              lng: '103.291',
            },
            zipcode: 32190,
          },
          320209: {
            name: {
              th: 'หนองเรือ',
              en: null,
            },
            coordinates: {
              lat: '15.312',
              lng: '103.557',
            },
            zipcode: 32190,
          },
        },
      },
      3203: {
        name: {
          th: 'ท่าตูม',
          en: 'Tha Tum',
        },
        tambons: {
          320301: {
            name: {
              th: 'ท่าตูม',
              en: 'Tha Tum',
            },
            coordinates: {
              lat: '15.295',
              lng: '103.639',
            },
            zipcode: 32120,
          },
          320302: {
            name: {
              th: 'กระโพ',
              en: 'Krapho',
            },
            coordinates: {
              lat: '15.245',
              lng: '103.492',
            },
            zipcode: 32120,
          },
          320303: {
            name: {
              th: 'พรมเทพ',
              en: 'Phrom Thep',
            },
            coordinates: {
              lat: '15.351',
              lng: '103.622',
            },
            zipcode: 32120,
          },
          320304: {
            name: {
              th: 'โพนครก',
              en: 'Phon Khrok',
            },
            coordinates: {
              lat: '15.401',
              lng: '103.725',
            },
            zipcode: 32120,
          },
          320305: {
            name: {
              th: 'เมืองแก',
              en: 'Mueang Kae',
            },
            coordinates: {
              lat: '15.221',
              lng: '103.670',
            },
            zipcode: 32120,
          },
          320306: {
            name: {
              th: 'บะ',
              en: 'Ba',
            },
            coordinates: {
              lat: '15.242',
              lng: '103.569',
            },
            zipcode: 32120,
          },
          320307: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '15.327',
              lng: '103.742',
            },
            zipcode: 32110,
          },
          320308: {
            name: {
              th: 'บัวโคก',
              en: 'Bua Khok',
            },
            coordinates: {
              lat: '15.228',
              lng: '103.710',
            },
            zipcode: 32120,
          },
          320309: {
            name: {
              th: 'หนองเมธี',
              en: 'Nong Methi',
            },
            coordinates: {
              lat: '15.232',
              lng: '103.615',
            },
            zipcode: 32120,
          },
          320310: {
            name: {
              th: 'ทุ่งกุลา',
              en: 'Thung Kula',
            },
            coordinates: {
              lat: '15.429',
              lng: '103.620',
            },
            zipcode: 32120,
          },
        },
      },
      3204: {
        name: {
          th: 'จอมพระ',
          en: 'Chom Phra',
        },
        tambons: {
          320401: {
            name: {
              th: 'จอมพระ',
              en: 'Chom Phra',
            },
            coordinates: {
              lat: '15.107',
              lng: '103.599',
            },
            zipcode: 32180,
          },
          320402: {
            name: {
              th: 'เมืองลีง',
              en: 'Mueang Lueng',
            },
            coordinates: {
              lat: '15.170',
              lng: '103.465',
            },
            zipcode: 32180,
          },
          320403: {
            name: {
              th: 'กระหาด',
              en: 'Krahat',
            },
            coordinates: {
              lat: '15.130',
              lng: '103.529',
            },
            zipcode: 32180,
          },
          320404: {
            name: {
              th: 'บุแกรง',
              en: 'Bu Kraeng',
            },
            coordinates: {
              lat: '15.079',
              lng: '103.683',
            },
            zipcode: 32180,
          },
          320405: {
            name: {
              th: 'หนองสนิท',
              en: 'Nong Sanit',
            },
            coordinates: {
              lat: '15.160',
              lng: '103.655',
            },
            zipcode: 32180,
          },
          320406: {
            name: {
              th: 'บ้านผือ',
              en: 'Ban Phue',
            },
            coordinates: {
              lat: '15.123',
              lng: '103.682',
            },
            zipcode: 32180,
          },
          320407: {
            name: {
              th: 'ลุ่มระวี',
              en: 'Lum Rawi',
            },
            coordinates: {
              lat: '15.162',
              lng: '103.602',
            },
            zipcode: 32180,
          },
          320408: {
            name: {
              th: 'ชุมแสง',
              en: 'Chum Saeng',
            },
            coordinates: {
              lat: '15.178',
              lng: '103.530',
            },
            zipcode: 32180,
          },
          320409: {
            name: {
              th: 'เป็นสุข',
              en: 'Pen Suk',
            },
            coordinates: {
              lat: '15.136',
              lng: '103.565',
            },
            zipcode: 32180,
          },
        },
      },
      3205: {
        name: {
          th: 'ปราสาท',
          en: 'Prasat',
        },
        tambons: {
          320501: {
            name: {
              th: 'กังแอน',
              en: 'Kang Aen',
            },
            coordinates: {
              lat: '14.656',
              lng: '103.406',
            },
            zipcode: 32140,
          },
          320502: {
            name: {
              th: 'ทมอ',
              en: 'Thamo',
            },
            coordinates: {
              lat: '14.722',
              lng: '103.486',
            },
            zipcode: 32140,
          },
          320503: {
            name: {
              th: 'ไพล',
              en: 'Phrai',
            },
            coordinates: {
              lat: '14.688',
              lng: '103.377',
            },
            zipcode: 32140,
          },
          320504: {
            name: {
              th: 'ปรือ',
              en: 'Prue',
            },
            coordinates: {
              lat: '14.645',
              lng: '103.288',
            },
            zipcode: 32140,
          },
          320505: {
            name: {
              th: 'ทุ่งมน',
              en: 'Thung Mon',
            },
            coordinates: {
              lat: '14.720',
              lng: '103.282',
            },
            zipcode: 32140,
          },
          320506: {
            name: {
              th: 'ตาเบา',
              en: 'Ta Bao',
            },
            coordinates: {
              lat: '14.615',
              lng: '103.493',
            },
            zipcode: 32140,
          },
          320507: {
            name: {
              th: 'หนองใหญ่',
              en: 'Nong Yai',
            },
            coordinates: {
              lat: '14.552',
              lng: '103.462',
            },
            zipcode: 32140,
          },
          320508: {
            name: {
              th: 'โคกยาง',
              en: 'Khok Yang',
            },
            coordinates: {
              lat: '14.673',
              lng: '103.529',
            },
            zipcode: 32140,
          },
          320509: {
            name: {
              th: 'โคกสะอาด',
              en: 'Khok Sa-At',
            },
            coordinates: {
              lat: '14.541',
              lng: '103.373',
            },
            zipcode: 32140,
          },
          320510: {
            name: {
              th: 'บ้านไทร',
              en: 'Ban Sai',
            },
            coordinates: {
              lat: '14.622',
              lng: '103.602',
            },
            zipcode: 32140,
          },
          320511: {
            name: {
              th: 'โชคนาสาม',
              en: 'Chok Na Sam',
            },
            coordinates: {
              lat: '14.544',
              lng: '103.280',
            },
            zipcode: 32140,
          },
          320512: {
            name: {
              th: 'เชื้อเพลิง',
              en: 'Chue Phloeng',
            },
            coordinates: {
              lat: '14.713',
              lng: '103.418',
            },
            zipcode: 32140,
          },
          320513: {
            name: {
              th: 'ปราสาททนง',
              en: 'Prasat Thanong',
            },
            coordinates: {
              lat: '14.652',
              lng: '103.356',
            },
            zipcode: 32140,
          },
          320514: {
            name: {
              th: 'ตานี',
              en: 'Tani',
            },
            coordinates: {
              lat: '14.617',
              lng: '103.258',
            },
            zipcode: 32140,
          },
          320515: {
            name: {
              th: 'บ้านพลวง',
              en: 'Ban Phluang',
            },
            coordinates: {
              lat: '14.602',
              lng: '103.408',
            },
            zipcode: 32140,
          },
          320516: {
            name: {
              th: 'กันตรวจระมวล',
              en: 'Kanta Wacharamuan',
            },
            coordinates: {
              lat: '14.592',
              lng: '103.557',
            },
            zipcode: 32140,
          },
          320517: {
            name: {
              th: 'สมุด',
              en: 'Samut',
            },
            coordinates: {
              lat: '14.686',
              lng: '103.339',
            },
            zipcode: 32140,
          },
          320518: {
            name: {
              th: 'ประทัดบุ',
              en: 'Prathat Bu',
            },
            coordinates: {
              lat: '14.727',
              lng: '103.341',
            },
            zipcode: 32140,
          },
        },
      },
      3206: {
        name: {
          th: 'กาบเชิง',
          en: 'Kap Choeng',
        },
        tambons: {
          320601: {
            name: {
              th: 'กาบเชิง',
              en: 'Kap Choeng',
            },
            coordinates: {
              lat: '14.488',
              lng: '103.585',
            },
            zipcode: null,
          },
          320604: {
            name: {
              th: 'คูตัน',
              en: 'Khu Tan',
            },
            coordinates: {
              lat: '14.533',
              lng: '103.667',
            },
            zipcode: null,
          },
          320605: {
            name: {
              th: 'ด่าน',
              en: 'Dan',
            },
            coordinates: {
              lat: '14.465',
              lng: '103.709',
            },
            zipcode: null,
          },
          320606: {
            name: {
              th: 'แนงมุด',
              en: 'Naeng Mut',
            },
            coordinates: {
              lat: '14.397',
              lng: '103.459',
            },
            zipcode: 32210,
          },
          320607: {
            name: {
              th: 'โคกตะเคียน',
              en: 'Khok Takhian',
            },
            coordinates: {
              lat: '14.480',
              lng: '103.505',
            },
            zipcode: 32210,
          },
          320610: {
            name: {
              th: 'ตะเคียน',
              en: 'Takhian',
            },
            coordinates: {
              lat: '14.464',
              lng: '103.632',
            },
            zipcode: 32210,
          },
        },
      },
      3207: {
        name: {
          th: 'รัตนบุรี',
          en: 'Rattanaburi',
        },
        tambons: {
          320701: {
            name: {
              th: 'รัตนบุรี',
              en: 'Rattana Buri',
            },
            coordinates: {
              lat: '15.311',
              lng: '103.843',
            },
            zipcode: 32130,
          },
          320702: {
            name: {
              th: 'ธาตุ',
              en: 'That',
            },
            coordinates: {
              lat: '15.317',
              lng: '103.940',
            },
            zipcode: 32130,
          },
          320703: {
            name: {
              th: 'แก',
              en: 'Kae',
            },
            coordinates: {
              lat: '15.355',
              lng: '103.808',
            },
            zipcode: 32130,
          },
          320704: {
            name: {
              th: 'ดอนแรด',
              en: 'Don Raet',
            },
            coordinates: {
              lat: '15.369',
              lng: '104.014',
            },
            zipcode: 32130,
          },
          320705: {
            name: {
              th: 'หนองบัวทอง',
              en: 'Nong Bua Thong',
            },
            coordinates: {
              lat: '15.392',
              lng: '103.933',
            },
            zipcode: 32130,
          },
          320706: {
            name: {
              th: 'หนองบัวบาน',
              en: 'Nong Bua Ban',
            },
            coordinates: {
              lat: '15.239',
              lng: '103.826',
            },
            zipcode: 32130,
          },
          320709: {
            name: {
              th: 'ไผ่',
              en: 'Phai',
            },
            coordinates: {
              lat: '15.324',
              lng: '103.895',
            },
            zipcode: 32130,
          },
          320711: {
            name: {
              th: 'เบิด',
              en: 'Boet',
            },
            coordinates: {
              lat: '15.268',
              lng: '103.921',
            },
            zipcode: 32130,
          },
          320713: {
            name: {
              th: 'น้ำเขียว',
              en: 'Nam Khiao',
            },
            coordinates: {
              lat: '15.316',
              lng: '103.786',
            },
            zipcode: 32130,
          },
          320714: {
            name: {
              th: 'กุดขาคีม',
              en: 'Kut Kha Khim',
            },
            coordinates: {
              lat: '15.407',
              lng: '103.846',
            },
            zipcode: 32130,
          },
          320715: {
            name: {
              th: 'ยางสว่าง',
              en: 'Yang Sawang',
            },
            coordinates: {
              lat: '15.306',
              lng: '103.987',
            },
            zipcode: 32130,
          },
          320716: {
            name: {
              th: 'ทับใหญ่',
              en: 'Thap Yai',
            },
            coordinates: {
              lat: '15.410',
              lng: '103.890',
            },
            zipcode: 32130,
          },
        },
      },
      3208: {
        name: {
          th: 'สนม',
          en: 'Sanom',
        },
        tambons: {
          320801: {
            name: {
              th: 'สนม',
              en: 'Sanom',
            },
            coordinates: {
              lat: '15.203',
              lng: '103.761',
            },
            zipcode: null,
          },
          320802: {
            name: {
              th: 'โพนโก',
              en: 'Phon Ko',
            },
            coordinates: {
              lat: '15.251',
              lng: '103.766',
            },
            zipcode: null,
          },
          320803: {
            name: {
              th: 'หนองระฆัง',
              en: 'Nong Rakhang',
            },
            coordinates: {
              lat: '15.152',
              lng: '103.755',
            },
            zipcode: null,
          },
          320804: {
            name: {
              th: 'นานวน',
              en: 'Na Nuan',
            },
            coordinates: {
              lat: '15.139',
              lng: '103.793',
            },
            zipcode: null,
          },
          320805: {
            name: {
              th: 'แคน',
              en: 'Khaen',
            },
            coordinates: {
              lat: '15.170',
              lng: '103.828',
            },
            zipcode: null,
          },
          320806: {
            name: {
              th: 'หัวงัว',
              en: 'Hua Ngua',
            },
            coordinates: {
              lat: '15.124',
              lng: '103.838',
            },
            zipcode: 32160,
          },
          320807: {
            name: {
              th: 'หนองอียอ',
              en: 'Nong I Yo',
            },
            coordinates: {
              lat: '15.157',
              lng: '103.714',
            },
            zipcode: 32160,
          },
        },
      },
      3209: {
        name: {
          th: 'ศีขรภูมิ',
          en: 'Sikhoraphum',
        },
        tambons: {
          320901: {
            name: {
              th: 'ระแงง',
              en: 'Ra-Ngaeng',
            },
            coordinates: {
              lat: '14.898',
              lng: '103.824',
            },
            zipcode: 32110,
          },
          320902: {
            name: {
              th: 'ตรึม',
              en: 'Truem',
            },
            coordinates: {
              lat: '15.035',
              lng: '103.845',
            },
            zipcode: 32110,
          },
          320903: {
            name: {
              th: 'จารพัต',
              en: 'Charaphat',
            },
            coordinates: {
              lat: '14.867',
              lng: '103.735',
            },
            zipcode: 32110,
          },
          320904: {
            name: {
              th: 'ยาง',
              en: 'Yang',
            },
            coordinates: {
              lat: '14.955',
              lng: '103.856',
            },
            zipcode: 32110,
          },
          320905: {
            name: {
              th: 'แตล',
              en: 'Tlae',
            },
            coordinates: {
              lat: '15.029',
              lng: '103.710',
            },
            zipcode: 32110,
          },
          320906: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '15.083',
              lng: '103.760',
            },
            zipcode: null,
          },
          320907: {
            name: {
              th: 'คาละแมะ',
              en: 'Khalamae',
            },
            coordinates: {
              lat: '15.081',
              lng: '103.825',
            },
            zipcode: 32110,
          },
          320908: {
            name: {
              th: 'หนองเหล็ก',
              en: 'Nong Lek',
            },
            coordinates: {
              lat: '14.812',
              lng: '103.722',
            },
            zipcode: 32110,
          },
          320909: {
            name: {
              th: 'หนองขวาว',
              en: 'Nong Khwao',
            },
            coordinates: {
              lat: '14.979',
              lng: '103.794',
            },
            zipcode: 32110,
          },
          320910: {
            name: {
              th: 'ช่างปี่',
              en: 'Chang Pi',
            },
            coordinates: {
              lat: '14.946',
              lng: '103.705',
            },
            zipcode: 32110,
          },
          320911: {
            name: {
              th: 'กุดหวาย',
              en: 'Kut Wai',
            },
            coordinates: {
              lat: '14.920',
              lng: '103.868',
            },
            zipcode: 32110,
          },
          320912: {
            name: {
              th: 'ขวาวใหญ่',
              en: 'Khwao Yai',
            },
            coordinates: {
              lat: '15.018',
              lng: '103.770',
            },
            zipcode: 32110,
          },
          320913: {
            name: {
              th: 'นารุ่ง',
              en: 'Na Rung',
            },
            coordinates: {
              lat: '14.984',
              lng: '103.829',
            },
            zipcode: 32110,
          },
          320914: {
            name: {
              th: 'ตรมไพร',
              en: 'Trom Phrai',
            },
            coordinates: {
              lat: '14.845',
              lng: '103.785',
            },
            zipcode: 32110,
          },
          320915: {
            name: {
              th: 'ผักไหม',
              en: 'Phak Mai',
            },
            coordinates: {
              lat: '14.848',
              lng: '103.830',
            },
            zipcode: 32110,
          },
        },
      },
      3210: {
        name: {
          th: 'สังขะ',
          en: 'Sangkha',
        },
        tambons: {
          321001: {
            name: {
              th: 'สังขะ',
              en: 'Sangkha',
            },
            coordinates: {
              lat: '14.663',
              lng: '103.839',
            },
            zipcode: 32150,
          },
          321002: {
            name: {
              th: 'ขอนแตก',
              en: 'Khon Taek',
            },
            coordinates: {
              lat: '14.717',
              lng: '103.932',
            },
            zipcode: 32150,
          },
          321006: {
            name: {
              th: 'ดม',
              en: 'Dom',
            },
            coordinates: {
              lat: '14.575',
              lng: '103.856',
            },
            zipcode: 32150,
          },
          321007: {
            name: {
              th: 'พระแก้ว',
              en: 'Phra Kaeo',
            },
            coordinates: {
              lat: '14.653',
              lng: '103.954',
            },
            zipcode: 32150,
          },
          321008: {
            name: {
              th: 'บ้านจารย์',
              en: 'Ban Chan',
            },
            coordinates: {
              lat: '14.561',
              lng: '103.749',
            },
            zipcode: 32150,
          },
          321009: {
            name: {
              th: 'กระเทียม',
              en: 'Krathiam',
            },
            coordinates: {
              lat: '14.604',
              lng: '103.673',
            },
            zipcode: 32150,
          },
          321010: {
            name: {
              th: 'สะกาด',
              en: 'Sakat',
            },
            coordinates: {
              lat: '14.612',
              lng: '103.753',
            },
            zipcode: 32150,
          },
          321011: {
            name: {
              th: 'ตาตุม',
              en: 'Tatum',
            },
            coordinates: {
              lat: '14.449',
              lng: '103.779',
            },
            zipcode: 32150,
          },
          321012: {
            name: {
              th: 'ทับทัน',
              en: 'Thapthan',
            },
            coordinates: {
              lat: '14.676',
              lng: '103.786',
            },
            zipcode: 32150,
          },
          321013: {
            name: {
              th: 'ตาคง',
              en: 'Ta Khong',
            },
            coordinates: {
              lat: '14.660',
              lng: '104.034',
            },
            zipcode: 32150,
          },
          321015: {
            name: {
              th: 'บ้านชบ',
              en: 'Ban Chop',
            },
            coordinates: {
              lat: '14.618',
              lng: '103.849',
            },
            zipcode: 32150,
          },
          321017: {
            name: {
              th: 'เทพรักษา',
              en: 'Thep Raksa',
            },
            coordinates: {
              lat: '14.481',
              lng: '103.868',
            },
            zipcode: 32150,
          },
        },
      },
      3211: {
        name: {
          th: 'ลำดวน',
          en: 'Lamduan',
        },
        tambons: {
          321101: {
            name: {
              th: 'ลำดวน',
              en: 'Lamduan',
            },
            coordinates: {
              lat: '14.702',
              lng: '103.642',
            },
            zipcode: 32220,
          },
          321102: {
            name: {
              th: 'โชคเหนือ',
              en: 'Chok Nuea',
            },
            coordinates: {
              lat: '14.738',
              lng: '103.721',
            },
            zipcode: 32220,
          },
          321103: {
            name: {
              th: 'อู่โลก',
              en: 'U Lok',
            },
            coordinates: {
              lat: '14.684',
              lng: '103.691',
            },
            zipcode: 32220,
          },
          321104: {
            name: {
              th: 'ตรำดม',
              en: 'Tramdom',
            },
            coordinates: {
              lat: '14.763',
              lng: '103.680',
            },
            zipcode: 32220,
          },
          321105: {
            name: {
              th: 'ตระเปียงเตีย',
              en: 'Trapiang Tia',
            },
            coordinates: {
              lat: '14.751',
              lng: '103.770',
            },
            zipcode: 32220,
          },
        },
      },
      3212: {
        name: {
          th: 'สำโรงทาบ',
          en: 'Samrong Thap',
        },
        tambons: {
          321201: {
            name: {
              th: 'สำโรงทาบ',
              en: 'Samrong Thap',
            },
            coordinates: {
              lat: '14.989',
              lng: '103.926',
            },
            zipcode: 32170,
          },
          321202: {
            name: {
              th: 'หนองไผ่ล้อม',
              en: 'Nong Phai Lom',
            },
            coordinates: {
              lat: '15.020',
              lng: '103.905',
            },
            zipcode: 32170,
          },
          321203: {
            name: {
              th: 'กระออม',
              en: 'Kra-Om',
            },
            coordinates: {
              lat: '15.130',
              lng: '103.968',
            },
            zipcode: 32170,
          },
          321204: {
            name: {
              th: 'หนองฮะ',
              en: 'Nong Ha',
            },
            coordinates: {
              lat: '15.097',
              lng: '103.911',
            },
            zipcode: 32170,
          },
          321205: {
            name: {
              th: 'ศรีสุข',
              en: 'Si Suk',
            },
            coordinates: {
              lat: '15.070',
              lng: '103.949',
            },
            zipcode: null,
          },
          321206: {
            name: {
              th: 'เกาะแก้ว',
              en: 'Ko Kaeo',
            },
            coordinates: {
              lat: '14.958',
              lng: '103.952',
            },
            zipcode: 32170,
          },
          321207: {
            name: {
              th: 'หมื่นศรี',
              en: 'Muen Si',
            },
            coordinates: {
              lat: '15.003',
              lng: '103.968',
            },
            zipcode: 32170,
          },
          321208: {
            name: {
              th: 'เสม็จ',
              en: 'Samet',
            },
            coordinates: {
              lat: '15.092',
              lng: '103.875',
            },
            zipcode: 32170,
          },
          321209: {
            name: {
              th: 'สะโน',
              en: 'Sano',
            },
            coordinates: {
              lat: '15.066',
              lng: '103.999',
            },
            zipcode: 32170,
          },
          321210: {
            name: {
              th: 'ประดู่',
              en: 'Pradu',
            },
            coordinates: {
              lat: '15.039',
              lng: '103.952',
            },
            zipcode: 32170,
          },
        },
      },
      3213: {
        name: {
          th: 'บัวเชด',
          en: 'Buachet',
        },
        tambons: {
          321301: {
            name: {
              th: 'บัวเชด',
              en: 'Bua Chet',
            },
            coordinates: {
              lat: '14.527',
              lng: '103.942',
            },
            zipcode: 32230,
          },
          321302: {
            name: {
              th: 'สะเดา',
              en: 'Sadao',
            },
            coordinates: {
              lat: '14.532',
              lng: '104.014',
            },
            zipcode: 32230,
          },
          321303: {
            name: {
              th: 'จรัส',
              en: 'Charat',
            },
            coordinates: {
              lat: '14.413',
              lng: '103.976',
            },
            zipcode: 32230,
          },
          321304: {
            name: {
              th: 'ตาวัง',
              en: 'Ta Wang',
            },
            coordinates: {
              lat: '14.609',
              lng: '104.016',
            },
            zipcode: 32230,
          },
          321305: {
            name: {
              th: 'อาโพน',
              en: 'A Phon',
            },
            coordinates: {
              lat: '14.479',
              lng: '103.921',
            },
            zipcode: 32230,
          },
          321306: {
            name: {
              th: 'สำเภาลูน',
              en: 'Samphao Lun',
            },
            coordinates: {
              lat: '14.570',
              lng: '103.953',
            },
            zipcode: 32230,
          },
        },
      },
      3214: {
        name: {
          th: 'พนมดงรัก',
          en: 'Phanom Dong Rak',
        },
        tambons: {
          321401: {
            name: {
              th: 'บักได',
              en: 'Bak Dai',
            },
            coordinates: {
              lat: '14.416',
              lng: '103.350',
            },
            zipcode: null,
          },
          321402: {
            name: {
              th: 'โคกกลาง',
              en: 'Khok Klang',
            },
            coordinates: {
              lat: '14.490',
              lng: '103.249',
            },
            zipcode: null,
          },
          321403: {
            name: {
              th: 'จีกแดก',
              en: 'Chik Daek',
            },
            coordinates: {
              lat: '14.497',
              lng: '103.354',
            },
            zipcode: null,
          },
          321404: {
            name: {
              th: 'ตาเมียง',
              en: 'Ta Miang',
            },
            coordinates: {
              lat: '14.386',
              lng: '103.260',
            },
            zipcode: null,
          },
        },
      },
      3215: {
        name: {
          th: 'ศรีณรงค์',
          en: 'Si Narong',
        },
        tambons: {
          321501: {
            name: {
              th: 'ณรงค์',
              en: 'Narong',
            },
            coordinates: {
              lat: '14.754',
              lng: '103.882',
            },
            zipcode: null,
          },
          321502: {
            name: {
              th: 'แจนแวน',
              en: 'Chaen Waen',
            },
            coordinates: {
              lat: '14.797',
              lng: '103.922',
            },
            zipcode: null,
          },
          321503: {
            name: {
              th: 'ตรวจ',
              en: 'Truat',
            },
            coordinates: {
              lat: '14.802',
              lng: '103.846',
            },
            zipcode: null,
          },
          321504: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '14.757',
              lng: '103.977',
            },
            zipcode: null,
          },
          321505: {
            name: {
              th: 'ศรีสุข',
              en: 'Si Suk',
            },
            coordinates: {
              lat: '14.738',
              lng: '103.818',
            },
            zipcode: null,
          },
        },
      },
      3216: {
        name: {
          th: 'เขวาสินรินทร์',
          en: 'Khwao Sinarin',
        },
        tambons: {
          321601: {
            name: {
              th: 'เขวาสินรินทร์',
              en: 'Khewa Sinarin',
            },
            coordinates: {
              lat: '15.009',
              lng: '103.582',
            },
            zipcode: null,
          },
          321602: {
            name: {
              th: 'บึง',
              en: 'Bueng',
            },
            coordinates: {
              lat: '15.060',
              lng: '103.610',
            },
            zipcode: null,
          },
          321603: {
            name: {
              th: 'ตากูก',
              en: 'Ta Kuk',
            },
            coordinates: {
              lat: '15.026',
              lng: '103.644',
            },
            zipcode: null,
          },
          321604: {
            name: {
              th: 'ปราสาททอง',
              en: 'Prasat Thong',
            },
            coordinates: {
              lat: '14.976',
              lng: '103.648',
            },
            zipcode: null,
          },
          321605: {
            name: {
              th: 'บ้านแร่',
              en: 'Ban Rae',
            },
            coordinates: {
              lat: '14.966',
              lng: '103.576',
            },
            zipcode: null,
          },
        },
      },
      3217: {
        name: {
          th: 'โนนนารายณ์',
          en: 'Non Narai',
        },
        tambons: {
          321701: {
            name: {
              th: 'หนองหลวง',
              en: 'Nong Luang',
            },
            coordinates: {
              lat: '15.228',
              lng: '103.927',
            },
            zipcode: null,
          },
          321702: {
            name: {
              th: 'คำผง',
              en: 'Kham Phong',
            },
            coordinates: {
              lat: '15.186',
              lng: '103.942',
            },
            zipcode: null,
          },
          321703: {
            name: {
              th: 'โนน',
              en: 'Non',
            },
            coordinates: {
              lat: '15.173',
              lng: '103.911',
            },
            zipcode: null,
          },
          321704: {
            name: {
              th: 'ระเวียง',
              en: 'Rawiang',
            },
            coordinates: {
              lat: '15.251',
              lng: '103.885',
            },
            zipcode: null,
          },
          321705: {
            name: {
              th: 'หนองเทพ',
              en: 'Nong Thep',
            },
            coordinates: {
              lat: '15.140',
              lng: '103.880',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  33: {
    name: {
      th: 'ศรีสะเกษ',
      en: 'Si Sa Ket',
    },
    amphoes: {
      3301: {
        name: {
          th: 'เมืองศรีสะเกษ',
          en: 'Mueang Si Sa Ket',
        },
        tambons: {
          330101: {
            name: {
              th: 'เมืองเหนือ',
              en: 'Mueang Nuea',
            },
            coordinates: {
              lat: '15.103',
              lng: '104.336',
            },
            zipcode: 33000,
          },
          330103: {
            name: {
              th: 'คูซอด',
              en: 'Khu Sot',
            },
            coordinates: {
              lat: '15.213',
              lng: '104.278',
            },
            zipcode: 33000,
          },
          330104: {
            name: {
              th: 'ซำ',
              en: 'Sam',
            },
            coordinates: {
              lat: '15.040',
              lng: '104.332',
            },
            zipcode: 33000,
          },
          330105: {
            name: {
              th: 'จาน',
              en: 'Chan',
            },
            coordinates: {
              lat: '14.989',
              lng: '104.398',
            },
            zipcode: 33000,
          },
          330106: {
            name: {
              th: 'ตะดอบ',
              en: 'Tadop',
            },
            coordinates: {
              lat: '15.037',
              lng: '104.434',
            },
            zipcode: 33000,
          },
          330107: {
            name: {
              th: 'หนองครก',
              en: 'Nong Khrok',
            },
            coordinates: {
              lat: '15.078',
              lng: '104.295',
            },
            zipcode: 33000,
          },
          330111: {
            name: {
              th: 'โพนข่า',
              en: 'Phon Kha',
            },
            coordinates: {
              lat: '15.055',
              lng: '104.385',
            },
            zipcode: 33000,
          },
          330112: {
            name: {
              th: 'โพนค้อ',
              en: 'Phon Kho',
            },
            coordinates: {
              lat: '15.015',
              lng: '104.369',
            },
            zipcode: 33000,
          },
          330116: {
            name: {
              th: 'หญ้าปล้อง',
              en: 'Ya Plong',
            },
            coordinates: {
              lat: '15.135',
              lng: '104.293',
            },
            zipcode: 33000,
          },
          330118: {
            name: {
              th: 'ทุ่ม',
              en: 'Thum',
            },
            coordinates: {
              lat: '14.980',
              lng: '104.336',
            },
            zipcode: 33000,
          },
          330119: {
            name: {
              th: 'หนองไฮ',
              en: 'Nong Hai',
            },
            coordinates: {
              lat: '15.007',
              lng: '104.427',
            },
            zipcode: 33000,
          },
          330121: {
            name: {
              th: 'หนองแก้ว',
              en: 'Nong Kaeo',
            },
            coordinates: {
              lat: '15.096',
              lng: '104.433',
            },
            zipcode: 33000,
          },
          330122: {
            name: {
              th: 'น้ำคำ',
              en: 'Nam Kham',
            },
            coordinates: {
              lat: '15.167',
              lng: '104.340',
            },
            zipcode: 33000,
          },
          330123: {
            name: {
              th: 'โพธิ์',
              en: 'Pho',
            },
            coordinates: {
              lat: '15.124',
              lng: '104.394',
            },
            zipcode: 33250,
          },
          330124: {
            name: {
              th: 'หมากเขียบ',
              en: 'Mak Khiap',
            },
            coordinates: {
              lat: '15.051',
              lng: '104.258',
            },
            zipcode: 33000,
          },
          330127: {
            name: {
              th: 'หนองไผ่',
              en: 'Nong Phai',
            },
            coordinates: {
              lat: '15.143',
              lng: '104.271',
            },
            zipcode: 33000,
          },
        },
      },
      3302: {
        name: {
          th: 'ยางชุมน้อย',
          en: 'Yang Chum Noi',
        },
        tambons: {
          330201: {
            name: {
              th: 'ยางชุมน้อย',
              en: 'Yang Chum Noi',
            },
            coordinates: {
              lat: '15.260',
              lng: '104.401',
            },
            zipcode: 33190,
          },
          330202: {
            name: {
              th: 'ลิ้นฟ้า',
              en: 'Lin Fa',
            },
            coordinates: {
              lat: '15.180',
              lng: '104.422',
            },
            zipcode: 33190,
          },
          330203: {
            name: {
              th: 'คอนกาม',
              en: 'Khon Kam',
            },
            coordinates: {
              lat: '15.223',
              lng: '104.360',
            },
            zipcode: null,
          },
          330204: {
            name: {
              th: 'โนนคูณ',
              en: 'Non Khun',
            },
            coordinates: {
              lat: '15.302',
              lng: '104.354',
            },
            zipcode: 33190,
          },
          330205: {
            name: {
              th: 'กุดเมืองฮาม',
              en: 'Kut Mueang Ham',
            },
            coordinates: {
              lat: '15.235',
              lng: '104.319',
            },
            zipcode: 33190,
          },
          330206: {
            name: {
              th: 'บึงบอน',
              en: 'Bueng Bon',
            },
            coordinates: {
              lat: '15.251',
              lng: '104.444',
            },
            zipcode: 33190,
          },
          330207: {
            name: {
              th: 'ยางชุมใหญ่',
              en: 'Yang Chum Yai',
            },
            coordinates: {
              lat: '15.212',
              lng: '104.413',
            },
            zipcode: 33190,
          },
        },
      },
      3303: {
        name: {
          th: 'กันทรารมย์',
          en: 'Kanthararom',
        },
        tambons: {
          330301: {
            name: {
              th: 'ดูน',
              en: 'Dun',
            },
            coordinates: {
              lat: '15.113',
              lng: '104.578',
            },
            zipcode: 33130,
          },
          330302: {
            name: {
              th: 'โนนสัง',
              en: 'Non Sang',
            },
            coordinates: {
              lat: '15.100',
              lng: '104.642',
            },
            zipcode: 33130,
          },
          330303: {
            name: {
              th: 'หนองหัวช้าง',
              en: 'Nong Hua Chang',
            },
            coordinates: {
              lat: '15.049',
              lng: '104.643',
            },
            zipcode: 33130,
          },
          330304: {
            name: {
              th: 'ยาง',
              en: 'Yang',
            },
            coordinates: {
              lat: '15.072',
              lng: '104.526',
            },
            zipcode: 33130,
          },
          330305: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '15.194',
              lng: '104.658',
            },
            zipcode: 33130,
          },
          330306: {
            name: {
              th: 'หนองแก้ว',
              en: 'Nong Kaeo',
            },
            coordinates: {
              lat: '15.143',
              lng: '104.702',
            },
            zipcode: null,
          },
          330307: {
            name: {
              th: 'ทาม',
              en: 'Tham',
            },
            coordinates: {
              lat: '15.232',
              lng: '104.616',
            },
            zipcode: 33130,
          },
          330308: {
            name: {
              th: 'ละทาย',
              en: 'Lathai',
            },
            coordinates: {
              lat: '15.182',
              lng: '104.583',
            },
            zipcode: 33130,
          },
          330309: {
            name: {
              th: 'เมืองน้อย',
              en: 'Mueang Noi',
            },
            coordinates: {
              lat: '15.203',
              lng: '104.547',
            },
            zipcode: 33130,
          },
          330310: {
            name: {
              th: 'อีปาด',
              en: 'I Pat',
            },
            coordinates: {
              lat: '15.223',
              lng: '104.500',
            },
            zipcode: 33130,
          },
          330311: {
            name: {
              th: 'บัวน้อย',
              en: 'Bua Noi',
            },
            coordinates: {
              lat: '15.173',
              lng: '104.478',
            },
            zipcode: 33130,
          },
          330312: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '15.120',
              lng: '104.525',
            },
            zipcode: 33130,
          },
          330313: {
            name: {
              th: 'ดู่',
              en: 'Du',
            },
            coordinates: {
              lat: '15.080',
              lng: '104.476',
            },
            zipcode: 33160,
          },
          330314: {
            name: {
              th: 'ผักแพว',
              en: 'Phak Phaeo',
            },
            coordinates: {
              lat: '15.034',
              lng: '104.537',
            },
            zipcode: 33130,
          },
          330315: {
            name: {
              th: 'จาน',
              en: 'Chan',
            },
            coordinates: {
              lat: '14.995',
              lng: '104.494',
            },
            zipcode: null,
          },
          330320: {
            name: {
              th: 'คำเนียม',
              en: 'Kham Niam',
            },
            coordinates: {
              lat: '15.066',
              lng: '104.583',
            },
            zipcode: 33130,
          },
        },
      },
      3304: {
        name: {
          th: 'กันทรลักษ์',
          en: 'Kantharalak',
        },
        tambons: {
          330401: {
            name: {
              th: 'บึงมะลู',
              en: 'Bueng Malu',
            },
            coordinates: {
              lat: '14.535',
              lng: '104.714',
            },
            zipcode: 33110,
          },
          330402: {
            name: {
              th: 'กุดเสลา',
              en: 'Kut Salao',
            },
            coordinates: {
              lat: '14.679',
              lng: '104.833',
            },
            zipcode: 33110,
          },
          330403: {
            name: {
              th: 'เมือง',
              en: 'Mueang',
            },
            coordinates: {
              lat: '14.582',
              lng: '104.670',
            },
            zipcode: 33110,
          },
          330405: {
            name: {
              th: 'สังเม็ก',
              en: 'Sang Mek',
            },
            coordinates: {
              lat: '14.643',
              lng: '104.729',
            },
            zipcode: 33110,
          },
          330406: {
            name: {
              th: 'น้ำอ้อม',
              en: 'Nam Om',
            },
            coordinates: {
              lat: '14.660',
              lng: '104.657',
            },
            zipcode: 33110,
          },
          330407: {
            name: {
              th: 'ละลาย',
              en: 'Lalai',
            },
            coordinates: {
              lat: '14.444',
              lng: '104.547',
            },
            zipcode: 33110,
          },
          330408: {
            name: {
              th: 'รุง',
              en: 'Rung',
            },
            coordinates: {
              lat: '14.480',
              lng: '104.630',
            },
            zipcode: 33110,
          },
          330409: {
            name: {
              th: 'ตระกาจ',
              en: 'Trakat',
            },
            coordinates: {
              lat: '14.712',
              lng: '104.596',
            },
            zipcode: 33110,
          },
          330411: {
            name: {
              th: 'จานใหญ่',
              en: 'Chan Yai',
            },
            coordinates: {
              lat: '14.724',
              lng: '104.655',
            },
            zipcode: 33110,
          },
          330412: {
            name: {
              th: 'ภูเงิน',
              en: 'Phu Ngoen',
            },
            coordinates: {
              lat: '14.745',
              lng: '104.571',
            },
            zipcode: 33110,
          },
          330413: {
            name: {
              th: 'ชำ',
              en: 'Cham',
            },
            coordinates: {
              lat: '14.543',
              lng: '104.575',
            },
            zipcode: 33110,
          },
          330414: {
            name: {
              th: 'กระแชง',
              en: 'Krachaeng',
            },
            coordinates: {
              lat: '14.650',
              lng: '104.566',
            },
            zipcode: 33110,
          },
          330415: {
            name: {
              th: 'โนนสำราญ',
              en: 'Non Samran',
            },
            coordinates: {
              lat: '14.534',
              lng: '104.800',
            },
            zipcode: 33110,
          },
          330416: {
            name: {
              th: 'หนองหญ้าลาด',
              en: 'Nong Ya Lat',
            },
            coordinates: {
              lat: '14.654',
              lng: '104.621',
            },
            zipcode: 33110,
          },
          330419: {
            name: {
              th: 'เสาธงชัย',
              en: 'Sao Thong Chai',
            },
            coordinates: {
              lat: '14.447',
              lng: '104.685',
            },
            zipcode: 33110,
          },
          330420: {
            name: {
              th: 'ขนุน',
              en: 'Khanun',
            },
            coordinates: {
              lat: '14.602',
              lng: '104.798',
            },
            zipcode: 33110,
          },
          330421: {
            name: {
              th: 'สวนกล้วย',
              en: 'Suan Kluai',
            },
            coordinates: {
              lat: '14.682',
              lng: '104.777',
            },
            zipcode: 33110,
          },
          330423: {
            name: {
              th: 'เวียงเหนือ',
              en: 'Wiang Nuea',
            },
            coordinates: {
              lat: '14.637',
              lng: '104.686',
            },
            zipcode: 33110,
          },
          330424: {
            name: {
              th: 'ทุ่งใหญ่',
              en: 'Thung Yai',
            },
            coordinates: {
              lat: '14.590',
              lng: '104.591',
            },
            zipcode: 33110,
          },
          330425: {
            name: {
              th: 'ภูผาหมอก',
              en: 'Phu Pha Mok',
            },
            coordinates: {
              lat: '14.466',
              lng: '104.809',
            },
            zipcode: 33110,
          },
        },
      },
      3305: {
        name: {
          th: 'ขุขันธ์',
          en: 'Khukhan',
        },
        tambons: {
          330501: {
            name: {
              th: 'กันทรารมย์',
              en: 'Kanthararom',
            },
            coordinates: {
              lat: '14.717',
              lng: '104.070',
            },
            zipcode: 33140,
          },
          330502: {
            name: {
              th: 'จะกง',
              en: 'Cha Kong',
            },
            coordinates: {
              lat: '14.821',
              lng: '104.253',
            },
            zipcode: 33140,
          },
          330503: {
            name: {
              th: 'ใจดี',
              en: 'Chai Di',
            },
            coordinates: {
              lat: '14.753',
              lng: '104.150',
            },
            zipcode: 33140,
          },
          330504: {
            name: {
              th: 'ดองกำเม็ด',
              en: 'Dong Kammet',
            },
            coordinates: {
              lat: '14.775',
              lng: '104.186',
            },
            zipcode: 33140,
          },
          330505: {
            name: {
              th: 'โสน',
              en: 'Sano',
            },
            coordinates: {
              lat: '14.657',
              lng: '104.104',
            },
            zipcode: 33140,
          },
          330506: {
            name: {
              th: 'ปรือใหญ่',
              en: 'Prue Yai',
            },
            coordinates: {
              lat: '14.601',
              lng: '104.246',
            },
            zipcode: 33140,
          },
          330507: {
            name: {
              th: 'สะเดาใหญ่',
              en: 'Sadao Yai',
            },
            coordinates: {
              lat: '14.712',
              lng: '104.268',
            },
            zipcode: 33140,
          },
          330508: {
            name: {
              th: 'ตาอุด',
              en: 'Ta Ut',
            },
            coordinates: {
              lat: '14.669',
              lng: '104.271',
            },
            zipcode: 33140,
          },
          330509: {
            name: {
              th: 'ห้วยเหนือ',
              en: 'Huai Nuea',
            },
            coordinates: {
              lat: '14.720',
              lng: '104.205',
            },
            zipcode: 33140,
          },
          330510: {
            name: {
              th: 'ห้วยใต้',
              en: 'Huai Tai',
            },
            coordinates: {
              lat: '14.662',
              lng: '104.179',
            },
            zipcode: 33140,
          },
          330511: {
            name: {
              th: 'หัวเสือ',
              en: 'Hua Suea',
            },
            coordinates: {
              lat: '14.834',
              lng: '104.186',
            },
            zipcode: 33140,
          },
          330513: {
            name: {
              th: 'ตะเคียน',
              en: 'Takhian',
            },
            coordinates: {
              lat: '14.773',
              lng: '104.248',
            },
            zipcode: 33140,
          },
          330515: {
            name: {
              th: 'นิคมพัฒนา',
              en: 'Nikhom Phatthana',
            },
            coordinates: {
              lat: '14.644',
              lng: '104.234',
            },
            zipcode: 33140,
          },
          330517: {
            name: {
              th: 'โคกเพชร',
              en: 'Khok Phet',
            },
            coordinates: {
              lat: '14.804',
              lng: '104.149',
            },
            zipcode: 33140,
          },
          330518: {
            name: {
              th: 'ปราสาท',
              en: 'Prasat',
            },
            coordinates: {
              lat: '14.727',
              lng: '104.016',
            },
            zipcode: 33210,
          },
          330521: {
            name: {
              th: 'สำโรงตาเจ็น',
              en: 'Samrong Ta Chen',
            },
            coordinates: {
              lat: '14.872',
              lng: '104.170',
            },
            zipcode: 33140,
          },
          330522: {
            name: {
              th: 'ห้วยสำราญ',
              en: 'Huai Samran',
            },
            coordinates: {
              lat: '14.715',
              lng: '104.115',
            },
            zipcode: 33140,
          },
          330524: {
            name: {
              th: 'กฤษณา',
              en: 'Kritsana',
            },
            coordinates: {
              lat: '14.874',
              lng: '104.255',
            },
            zipcode: 33140,
          },
          330525: {
            name: {
              th: 'ลมศักดิ์',
              en: 'Lom Sak',
            },
            coordinates: {
              lat: '14.762',
              lng: '104.283',
            },
            zipcode: 33140,
          },
          330526: {
            name: {
              th: 'หนองฉลอง',
              en: 'Nong Chalong',
            },
            coordinates: {
              lat: '14.684',
              lng: '104.227',
            },
            zipcode: 33140,
          },
          330527: {
            name: {
              th: 'ศรีตระกูล',
              en: 'Si Trakun',
            },
            coordinates: {
              lat: '14.639',
              lng: '104.280',
            },
            zipcode: 33140,
          },
          330528: {
            name: {
              th: 'ศรีสะอาด',
              en: 'Si Sa-At',
            },
            coordinates: {
              lat: '14.754',
              lng: '104.102',
            },
            zipcode: 33140,
          },
        },
      },
      3306: {
        name: {
          th: 'ไพรบึง',
          en: 'Phrai Bueng',
        },
        tambons: {
          330601: {
            name: {
              th: 'ไพรบึง',
              en: 'Phrai Bueng',
            },
            coordinates: {
              lat: '14.744',
              lng: '104.373',
            },
            zipcode: 33180,
          },
          330602: {
            name: {
              th: 'ดินแดง',
              en: 'Din Daeng',
            },
            coordinates: {
              lat: '14.801',
              lng: '104.308',
            },
            zipcode: 33180,
          },
          330603: {
            name: {
              th: 'ปราสาทเยอ',
              en: 'Prasat Yoe',
            },
            coordinates: {
              lat: '14.811',
              lng: '104.364',
            },
            zipcode: 33180,
          },
          330604: {
            name: {
              th: 'สำโรงพลัน',
              en: 'Samrong Phlan',
            },
            coordinates: {
              lat: '14.700',
              lng: '104.342',
            },
            zipcode: 33180,
          },
          330605: {
            name: {
              th: 'สุขสวัสดิ์',
              en: 'Suk Sawat',
            },
            coordinates: {
              lat: '14.856',
              lng: '104.359',
            },
            zipcode: 33180,
          },
          330606: {
            name: {
              th: 'โนนปูน',
              en: 'Non Pun',
            },
            coordinates: {
              lat: '14.857',
              lng: '104.303',
            },
            zipcode: 33180,
          },
        },
      },
      3307: {
        name: {
          th: 'ปรางค์กู่',
          en: 'Prang Ku',
        },
        tambons: {
          330701: {
            name: {
              th: 'พิมาย',
              en: 'Phimai',
            },
            coordinates: {
              lat: '14.835',
              lng: '104.032',
            },
            zipcode: 33170,
          },
          330702: {
            name: {
              th: 'กู่',
              en: 'Ku',
            },
            coordinates: {
              lat: '14.853',
              lng: '103.957',
            },
            zipcode: 33170,
          },
          330703: {
            name: {
              th: 'หนองเชียงทูน',
              en: 'Nong Chiang Thun',
            },
            coordinates: {
              lat: '14.875',
              lng: '104.101',
            },
            zipcode: 33170,
          },
          330704: {
            name: {
              th: 'ตูม',
              en: 'Tum',
            },
            coordinates: {
              lat: '14.903',
              lng: '104.009',
            },
            zipcode: null,
          },
          330705: {
            name: {
              th: 'สมอ',
              en: 'Samo',
            },
            coordinates: {
              lat: '14.820',
              lng: '104.094',
            },
            zipcode: 33170,
          },
          330706: {
            name: {
              th: 'โพธิ์ศรี',
              en: 'Pho Si',
            },
            coordinates: {
              lat: '14.780',
              lng: '104.082',
            },
            zipcode: 33170,
          },
          330707: {
            name: {
              th: 'สำโรงปราสาท',
              en: 'Samrong Prasat',
            },
            coordinates: {
              lat: '14.917',
              lng: '104.081',
            },
            zipcode: 33170,
          },
          330708: {
            name: {
              th: 'ดู่',
              en: 'Du',
            },
            coordinates: {
              lat: '14.782',
              lng: '104.027',
            },
            zipcode: null,
          },
          330709: {
            name: {
              th: 'สวาย',
              en: 'Sawat',
            },
            coordinates: {
              lat: '14.913',
              lng: '103.962',
            },
            zipcode: 33170,
          },
          330710: {
            name: {
              th: 'พิมายเหนือ',
              en: 'Phimai Nuea',
            },
            coordinates: {
              lat: '14.881',
              lng: '104.042',
            },
            zipcode: 33170,
          },
        },
      },
      3308: {
        name: {
          th: 'ขุนหาญ',
          en: 'Khun Han',
        },
        tambons: {
          330801: {
            name: {
              th: 'สิ',
              en: 'Si',
            },
            coordinates: {
              lat: '14.625',
              lng: '104.436',
            },
            zipcode: 33150,
          },
          330802: {
            name: {
              th: 'บักดอง',
              en: 'Bak Dong',
            },
            coordinates: {
              lat: '14.476',
              lng: '104.444',
            },
            zipcode: 33150,
          },
          330803: {
            name: {
              th: 'พราน',
              en: 'Phran',
            },
            coordinates: {
              lat: '14.592',
              lng: '104.501',
            },
            zipcode: 33150,
          },
          330804: {
            name: {
              th: 'โพธิ์วงศ์',
              en: 'Pho Wong',
            },
            coordinates: {
              lat: '14.666',
              lng: '104.458',
            },
            zipcode: 33150,
          },
          330805: {
            name: {
              th: 'ไพร',
              en: 'Phrai',
            },
            coordinates: {
              lat: '14.706',
              lng: '104.454',
            },
            zipcode: 33150,
          },
          330806: {
            name: {
              th: 'กระหวัน',
              en: 'Krawan',
            },
            coordinates: {
              lat: '14.673',
              lng: '104.414',
            },
            zipcode: 33150,
          },
          330807: {
            name: {
              th: 'ขุนหาญ',
              en: 'Khun Han',
            },
            coordinates: {
              lat: '14.635',
              lng: '104.371',
            },
            zipcode: 33150,
          },
          330808: {
            name: {
              th: 'โนนสูง',
              en: 'Non Sung',
            },
            coordinates: {
              lat: '14.581',
              lng: '104.416',
            },
            zipcode: 33150,
          },
          330809: {
            name: {
              th: 'กันทรอม',
              en: 'Kan Throm',
            },
            coordinates: {
              lat: '14.575',
              lng: '104.353',
            },
            zipcode: 33150,
          },
          330810: {
            name: {
              th: 'ภูฝ้าย',
              en: 'Phu Fai',
            },
            coordinates: {
              lat: '14.683',
              lng: '104.510',
            },
            zipcode: 33150,
          },
          330811: {
            name: {
              th: 'โพธิ์กระสังข์',
              en: 'Pho Krasang',
            },
            coordinates: {
              lat: '14.628',
              lng: '104.327',
            },
            zipcode: 33150,
          },
          330812: {
            name: {
              th: 'ห้วยจันทร์',
              en: 'Huai Chan',
            },
            coordinates: {
              lat: '14.465',
              lng: '104.353',
            },
            zipcode: 33150,
          },
        },
      },
      3309: {
        name: {
          th: 'ราษีไศล',
          en: 'Rasi Salai',
        },
        tambons: {
          330901: {
            name: {
              th: 'เมืองคง',
              en: 'Mueang Khong',
            },
            coordinates: {
              lat: '15.356',
              lng: '104.159',
            },
            zipcode: 33160,
          },
          330902: {
            name: {
              th: 'เมืองแคน',
              en: 'Mueang Khaen',
            },
            coordinates: {
              lat: '15.351',
              lng: '104.214',
            },
            zipcode: 33160,
          },
          330903: {
            name: {
              th: 'หนองแค',
              en: 'Nong Khae',
            },
            coordinates: {
              lat: '15.380',
              lng: '104.106',
            },
            zipcode: 33160,
          },
          330906: {
            name: {
              th: 'จิกสังข์ทอง',
              en: 'Chik Sang Thong',
            },
            coordinates: {
              lat: '15.435',
              lng: '104.125',
            },
            zipcode: 33160,
          },
          330907: {
            name: {
              th: 'ด่าน',
              en: 'Dan',
            },
            coordinates: {
              lat: '15.421',
              lng: '104.026',
            },
            zipcode: 33160,
          },
          330908: {
            name: {
              th: 'ดู่',
              en: 'Du',
            },
            coordinates: {
              lat: '15.416',
              lng: '104.162',
            },
            zipcode: null,
          },
          330909: {
            name: {
              th: 'หนองอึ่ง',
              en: 'Nong Ueng',
            },
            coordinates: {
              lat: '15.278',
              lng: '104.176',
            },
            zipcode: 33160,
          },
          330910: {
            name: {
              th: 'บัวหุ่ง',
              en: 'Bua Hung',
            },
            coordinates: {
              lat: '15.321',
              lng: '104.105',
            },
            zipcode: 33160,
          },
          330911: {
            name: {
              th: 'ไผ่',
              en: 'Phai',
            },
            coordinates: {
              lat: '15.332',
              lng: '104.257',
            },
            zipcode: 33160,
          },
          330912: {
            name: {
              th: 'ส้มป่อย',
              en: 'Som Poi',
            },
            coordinates: {
              lat: '15.283',
              lng: '104.232',
            },
            zipcode: 33160,
          },
          330913: {
            name: {
              th: 'หนองหมี',
              en: 'Nong Mi',
            },
            coordinates: {
              lat: '15.271',
              lng: '104.293',
            },
            zipcode: 33160,
          },
          330914: {
            name: {
              th: 'หว้านคำ',
              en: 'Wan Kham',
            },
            coordinates: {
              lat: '15.407',
              lng: '104.200',
            },
            zipcode: 33160,
          },
          330915: {
            name: {
              th: 'สร้างปี่',
              en: 'Sang Pi',
            },
            coordinates: {
              lat: '15.317',
              lng: '104.305',
            },
            zipcode: 33160,
          },
        },
      },
      3310: {
        name: {
          th: 'อุทุมพรพิสัย',
          en: 'Uthumphon Phisai',
        },
        tambons: {
          331001: {
            name: {
              th: 'กำแพง',
              en: 'Kamphaeng',
            },
            coordinates: {
              lat: '15.100',
              lng: '104.150',
            },
            zipcode: 33120,
          },
          331002: {
            name: {
              th: 'อี่หล่ำ',
              en: 'I Lam',
            },
            coordinates: {
              lat: '15.198',
              lng: '104.188',
            },
            zipcode: 33120,
          },
          331003: {
            name: {
              th: 'ก้านเหลือง',
              en: 'Kan Lueang',
            },
            coordinates: {
              lat: '15.052',
              lng: '104.143',
            },
            zipcode: 33120,
          },
          331004: {
            name: {
              th: 'ทุ่งไชย',
              en: 'Thung Chai',
            },
            coordinates: {
              lat: '15.016',
              lng: '104.109',
            },
            zipcode: 33120,
          },
          331005: {
            name: {
              th: 'สำโรง',
              en: 'Samrong',
            },
            coordinates: {
              lat: '15.146',
              lng: '104.148',
            },
            zipcode: 33120,
          },
          331006: {
            name: {
              th: 'แขม',
              en: 'Khaem',
            },
            coordinates: {
              lat: '15.198',
              lng: '104.140',
            },
            zipcode: 33120,
          },
          331007: {
            name: {
              th: 'หนองไฮ',
              en: 'Nong Hai',
            },
            coordinates: {
              lat: '15.145',
              lng: '104.093',
            },
            zipcode: null,
          },
          331008: {
            name: {
              th: 'ขะยูง',
              en: 'Khayung',
            },
            coordinates: {
              lat: '15.126',
              lng: '104.238',
            },
            zipcode: 33120,
          },
          331010: {
            name: {
              th: 'ตาเกษ',
              en: 'Ta Kat',
            },
            coordinates: {
              lat: '15.143',
              lng: '104.210',
            },
            zipcode: 33120,
          },
          331011: {
            name: {
              th: 'หัวช้าง',
              en: 'Hua Chang',
            },
            coordinates: {
              lat: '15.251',
              lng: '104.132',
            },
            zipcode: 33120,
          },
          331012: {
            name: {
              th: 'รังแร้ง',
              en: 'Rang Raeng',
            },
            coordinates: {
              lat: '15.223',
              lng: '104.228',
            },
            zipcode: 33120,
          },
          331014: {
            name: {
              th: 'แต้',
              en: 'Tae',
            },
            coordinates: {
              lat: '15.120',
              lng: '104.178',
            },
            zipcode: 33120,
          },
          331015: {
            name: {
              th: 'แข้',
              en: 'Khae',
            },
            coordinates: {
              lat: '15.067',
              lng: '104.176',
            },
            zipcode: 33120,
          },
          331016: {
            name: {
              th: 'โพธิ์ชัย',
              en: 'Pho Chai',
            },
            coordinates: {
              lat: '15.173',
              lng: '104.227',
            },
            zipcode: 33120,
          },
          331017: {
            name: {
              th: 'ปะอาว',
              en: 'Pa-Ao',
            },
            coordinates: {
              lat: '15.101',
              lng: '104.214',
            },
            zipcode: 33120,
          },
          331018: {
            name: {
              th: 'หนองห้าง',
              en: 'Nong Hang',
            },
            coordinates: {
              lat: '15.098',
              lng: '104.088',
            },
            zipcode: 33120,
          },
          331022: {
            name: {
              th: 'สระกำแพงใหญ่',
              en: 'Sa Kamphaeng Yai',
            },
            coordinates: {
              lat: '15.093',
              lng: '104.128',
            },
            zipcode: 33120,
          },
          331024: {
            name: {
              th: 'โคกหล่าม',
              en: 'Khok Lam',
            },
            coordinates: {
              lat: '15.220',
              lng: '104.171',
            },
            zipcode: 33120,
          },
          331025: {
            name: {
              th: 'โคกจาน',
              en: 'Khok Chan',
            },
            coordinates: {
              lat: '14.966',
              lng: '104.104',
            },
            zipcode: 33120,
          },
        },
      },
      3311: {
        name: {
          th: 'บึงบูรพ์',
          en: 'Bueng Bun',
        },
        tambons: {
          331101: {
            name: {
              th: 'เป๊าะ',
              en: 'Po',
            },
            coordinates: {
              lat: '15.297',
              lng: '104.060',
            },
            zipcode: null,
          },
          331102: {
            name: {
              th: 'บึงบูรพ์',
              en: 'Bueng Bun',
            },
            coordinates: {
              lat: '15.328',
              lng: '104.067',
            },
            zipcode: 33220,
          },
        },
      },
      3312: {
        name: {
          th: 'ห้วยทับทัน',
          en: 'Huai Thap Than',
        },
        tambons: {
          331201: {
            name: {
              th: 'ห้วยทับทัน',
              en: 'Huai Thapthan',
            },
            coordinates: {
              lat: '15.065',
              lng: '104.044',
            },
            zipcode: null,
          },
          331202: {
            name: {
              th: 'เมืองหลวง',
              en: 'Mueang Luang',
            },
            coordinates: {
              lat: '15.021',
              lng: '104.047',
            },
            zipcode: 33210,
          },
          331203: {
            name: {
              th: 'กล้วยกว้าง',
              en: 'Kluai Kwang',
            },
            coordinates: {
              lat: '14.966',
              lng: '104.069',
            },
            zipcode: null,
          },
          331204: {
            name: {
              th: 'ผักไหม',
              en: 'Phak Mai',
            },
            coordinates: {
              lat: '14.947',
              lng: '104.013',
            },
            zipcode: null,
          },
          331205: {
            name: {
              th: 'จานแสนไชย',
              en: 'Chan Saen Chai',
            },
            coordinates: {
              lat: '14.981',
              lng: '104.019',
            },
            zipcode: 33210,
          },
          331206: {
            name: {
              th: 'ปราสาท',
              en: 'Prasat',
            },
            coordinates: {
              lat: '15.110',
              lng: '104.027',
            },
            zipcode: null,
          },
        },
      },
      3313: {
        name: {
          th: 'โนนคูณ',
          en: 'Non Khun',
        },
        tambons: {
          331301: {
            name: {
              th: 'โนนค้อ',
              en: 'Non Kho',
            },
            coordinates: {
              lat: '14.899',
              lng: '104.710',
            },
            zipcode: null,
          },
          331302: {
            name: {
              th: 'บก',
              en: 'Bok',
            },
            coordinates: {
              lat: '14.976',
              lng: '104.640',
            },
            zipcode: null,
          },
          331303: {
            name: {
              th: 'โพธิ์',
              en: 'Pho',
            },
            coordinates: {
              lat: '14.923',
              lng: '104.614',
            },
            zipcode: null,
          },
          331304: {
            name: {
              th: 'หนองกุง',
              en: 'Nong Kung',
            },
            coordinates: {
              lat: '14.861',
              lng: '104.774',
            },
            zipcode: null,
          },
          331305: {
            name: {
              th: 'เหล่ากวาง',
              en: 'Lao Kwang',
            },
            coordinates: {
              lat: '14.880',
              lng: '104.638',
            },
            zipcode: 33250,
          },
        },
      },
      3314: {
        name: {
          th: 'ศรีรัตนะ',
          en: 'Si Rattana',
        },
        tambons: {
          331401: {
            name: {
              th: 'ศรีแก้ว',
              en: 'Si Kaeo',
            },
            coordinates: {
              lat: '14.766',
              lng: '104.476',
            },
            zipcode: null,
          },
          331402: {
            name: {
              th: 'พิงพวย',
              en: 'Phing Phuai',
            },
            coordinates: {
              lat: '14.744',
              lng: '104.453',
            },
            zipcode: null,
          },
          331403: {
            name: {
              th: 'สระเยาว์',
              en: 'Sa Yao',
            },
            coordinates: {
              lat: '14.785',
              lng: '104.515',
            },
            zipcode: null,
          },
          331404: {
            name: {
              th: 'ตูม',
              en: 'Tum',
            },
            coordinates: {
              lat: '14.866',
              lng: '104.488',
            },
            zipcode: null,
          },
          331405: {
            name: {
              th: 'เสืองข้าว',
              en: 'Sueang Khao',
            },
            coordinates: {
              lat: '14.813',
              lng: '104.555',
            },
            zipcode: 33240,
          },
          331406: {
            name: {
              th: 'ศรีโนนงาม',
              en: 'Si Non Ngam',
            },
            coordinates: {
              lat: '14.806',
              lng: '104.419',
            },
            zipcode: 33240,
          },
          331407: {
            name: {
              th: 'สะพุง',
              en: 'Saphung',
            },
            coordinates: {
              lat: '14.828',
              lng: '104.474',
            },
            zipcode: 33240,
          },
        },
      },
      3315: {
        name: {
          th: 'น้ำเกลี้ยง',
          en: 'Nam Kliang',
        },
        tambons: {
          331501: {
            name: {
              th: 'น้ำเกลี้ยง',
              en: 'Nam Kliang',
            },
            coordinates: {
              lat: '14.917',
              lng: '104.550',
            },
            zipcode: null,
          },
          331502: {
            name: {
              th: 'ละเอาะ',
              en: 'La-O',
            },
            coordinates: {
              lat: '14.930',
              lng: '104.451',
            },
            zipcode: null,
          },
          331503: {
            name: {
              th: 'ตองปิด',
              en: 'Tong Pit',
            },
            coordinates: {
              lat: '14.964',
              lng: '104.479',
            },
            zipcode: null,
          },
          331504: {
            name: {
              th: 'เขิน',
              en: 'Khoen',
            },
            coordinates: {
              lat: '14.852',
              lng: '104.580',
            },
            zipcode: null,
          },
          331505: {
            name: {
              th: 'รุ่งระวี',
              en: 'Rung Rawi',
            },
            coordinates: {
              lat: '14.876',
              lng: '104.525',
            },
            zipcode: 33130,
          },
          331506: {
            name: {
              th: 'คูบ',
              en: 'Khup',
            },
            coordinates: {
              lat: '14.971',
              lng: '104.569',
            },
            zipcode: 33130,
          },
        },
      },
      3316: {
        name: {
          th: 'วังหิน',
          en: 'Wang Hin',
        },
        tambons: {
          331601: {
            name: {
              th: 'บุสูง',
              en: 'Bu Sung',
            },
            coordinates: {
              lat: '14.957',
              lng: '104.209',
            },
            zipcode: null,
          },
          331602: {
            name: {
              th: 'ธาตุ',
              en: 'That',
            },
            coordinates: {
              lat: '14.988',
              lng: '104.186',
            },
            zipcode: null,
          },
          331603: {
            name: {
              th: 'ดวนใหญ่',
              en: 'Duan Yai',
            },
            coordinates: {
              lat: '14.945',
              lng: '104.271',
            },
            zipcode: null,
          },
          331604: {
            name: {
              th: 'บ่อแก้ว',
              en: 'Bo Kaeo',
            },
            coordinates: {
              lat: '14.992',
              lng: '104.270',
            },
            zipcode: null,
          },
          331605: {
            name: {
              th: 'ศรีสำราญ',
              en: 'Si Samran',
            },
            coordinates: {
              lat: '14.909',
              lng: '104.155',
            },
            zipcode: null,
          },
          331606: {
            name: {
              th: 'ทุ่งสว่าง',
              en: 'Thung Sawang',
            },
            coordinates: {
              lat: '15.018',
              lng: '104.224',
            },
            zipcode: 33270,
          },
          331607: {
            name: {
              th: 'วังหิน',
              en: 'Wang Hin',
            },
            coordinates: {
              lat: '14.920',
              lng: '104.240',
            },
            zipcode: 33270,
          },
          331608: {
            name: {
              th: 'โพนยาง',
              en: 'Phon Yang',
            },
            coordinates: {
              lat: '14.926',
              lng: '104.204',
            },
            zipcode: 33270,
          },
        },
      },
      3317: {
        name: {
          th: 'ภูสิงห์',
          en: 'Phu Sing',
        },
        tambons: {
          331701: {
            name: {
              th: 'โคกตาล',
              en: 'Khok Tan',
            },
            coordinates: {
              lat: '14.549',
              lng: '104.203',
            },
            zipcode: null,
          },
          331702: {
            name: {
              th: 'ห้วยตามอญ',
              en: 'Huai Ta Mon',
            },
            coordinates: {
              lat: '14.525',
              lng: '104.077',
            },
            zipcode: null,
          },
          331703: {
            name: {
              th: 'ห้วยตึ๊กชู',
              en: 'Huai Tuek Chu',
            },
            coordinates: {
              lat: '14.566',
              lng: '104.120',
            },
            zipcode: 33140,
          },
          331704: {
            name: {
              th: 'ละลม',
              en: 'Lalom',
            },
            coordinates: {
              lat: '14.588',
              lng: '104.079',
            },
            zipcode: null,
          },
          331705: {
            name: {
              th: 'ตะเคียนราม',
              en: 'Takhian Ram',
            },
            coordinates: {
              lat: '14.606',
              lng: '104.169',
            },
            zipcode: null,
          },
          331706: {
            name: {
              th: 'ดงรัก',
              en: 'Dong Rak',
            },
            coordinates: {
              lat: '14.440',
              lng: '104.189',
            },
            zipcode: null,
          },
          331707: {
            name: {
              th: 'ไพรพัฒนา',
              en: 'Phrai Phatthana',
            },
            coordinates: {
              lat: '14.426',
              lng: '104.085',
            },
            zipcode: 33140,
          },
        },
      },
      3318: {
        name: {
          th: 'เมืองจันทร์',
          en: 'Mueang Chan',
        },
        tambons: {
          331801: {
            name: {
              th: 'เมืองจันทร์',
              en: 'Mueang Chan',
            },
            coordinates: {
              lat: '15.155',
              lng: '104.011',
            },
            zipcode: null,
          },
          331802: {
            name: {
              th: 'ตาโกน',
              en: 'Ta Kon',
            },
            coordinates: {
              lat: '15.205',
              lng: '103.996',
            },
            zipcode: null,
          },
          331803: {
            name: {
              th: 'หนองใหญ่',
              en: 'Nong Yai',
            },
            coordinates: {
              lat: '15.198',
              lng: '104.035',
            },
            zipcode: null,
          },
        },
      },
      3319: {
        name: {
          th: 'เบญจลักษ์',
          en: 'Benchalak',
        },
        tambons: {
          331901: {
            name: {
              th: 'เสียว',
              en: 'Siao',
            },
            coordinates: {
              lat: '14.799',
              lng: '104.668',
            },
            zipcode: null,
          },
          331902: {
            name: {
              th: 'หนองหว้า',
              en: 'Nong Wa',
            },
            coordinates: {
              lat: '14.754',
              lng: '104.722',
            },
            zipcode: null,
          },
          331903: {
            name: {
              th: 'หนองงูเหลือม',
              en: 'Nong Ngu Lueam',
            },
            coordinates: {
              lat: '14.836',
              lng: '104.653',
            },
            zipcode: null,
          },
          331904: {
            name: {
              th: 'หนองฮาง',
              en: 'Nong Hang',
            },
            coordinates: {
              lat: '14.778',
              lng: '104.594',
            },
            zipcode: null,
          },
          331905: {
            name: {
              th: 'ท่าคล้อ',
              en: 'Tha Khlo',
            },
            coordinates: {
              lat: '14.761',
              lng: '104.783',
            },
            zipcode: null,
          },
        },
      },
      3320: {
        name: {
          th: 'พยุห์',
          en: 'Phayu',
        },
        tambons: {
          332001: {
            name: {
              th: 'พยุห์',
              en: 'Phayu',
            },
            coordinates: {
              lat: '14.935',
              lng: '104.387',
            },
            zipcode: null,
          },
          332002: {
            name: {
              th: 'พรหมสวัสดิ์',
              en: 'Phrom Sawat',
            },
            coordinates: {
              lat: '14.852',
              lng: '104.427',
            },
            zipcode: null,
          },
          332003: {
            name: {
              th: 'ตำแย',
              en: 'Tamyae',
            },
            coordinates: {
              lat: '14.918',
              lng: '104.422',
            },
            zipcode: null,
          },
          332004: {
            name: {
              th: 'โนนเพ็ก',
              en: 'Non Phek',
            },
            coordinates: {
              lat: '14.933',
              lng: '104.327',
            },
            zipcode: null,
          },
          332005: {
            name: {
              th: 'หนองค้า',
              en: 'Nong Kha',
            },
            coordinates: {
              lat: '14.898',
              lng: '104.334',
            },
            zipcode: null,
          },
        },
      },
      3321: {
        name: {
          th: 'โพธิ์ศรีสุวรรณ',
          en: 'Pho Si Suwan',
        },
        tambons: {
          332101: {
            name: {
              th: 'โดด',
              en: 'Dot',
            },
            coordinates: {
              lat: '15.204',
              lng: '104.078',
            },
            zipcode: null,
          },
          332102: {
            name: {
              th: 'เสียว',
              en: 'Siao',
            },
            coordinates: {
              lat: '15.251',
              lng: '104.043',
            },
            zipcode: null,
          },
          332103: {
            name: {
              th: 'หนองม้า',
              en: 'Nong Ma',
            },
            coordinates: {
              lat: '15.254',
              lng: '104.083',
            },
            zipcode: null,
          },
          332104: {
            name: {
              th: 'ผือใหญ่',
              en: 'Phue Yai',
            },
            coordinates: {
              lat: '15.233',
              lng: '104.111',
            },
            zipcode: null,
          },
          332105: {
            name: {
              th: 'อีเซ',
              en: 'I Se',
            },
            coordinates: {
              lat: '15.269',
              lng: '104.009',
            },
            zipcode: null,
          },
        },
      },
      3322: {
        name: {
          th: 'ศิลาลาด',
          en: 'Sila Lat',
        },
        tambons: {
          332201: {
            name: {
              th: 'กุง',
              en: 'Kung',
            },
            coordinates: {
              lat: '15.474',
              lng: '104.084',
            },
            zipcode: null,
          },
          332202: {
            name: {
              th: 'คลีกลิ้ง',
              en: 'Khli Kling',
            },
            coordinates: {
              lat: '15.529',
              lng: '104.109',
            },
            zipcode: null,
          },
          332203: {
            name: {
              th: 'หนองบัวดง',
              en: 'Nong Bua Dong',
            },
            coordinates: {
              lat: '15.458',
              lng: '104.046',
            },
            zipcode: null,
          },
          332204: {
            name: {
              th: 'โจดม่วง',
              en: 'Chot Muang',
            },
            coordinates: {
              lat: '15.473',
              lng: '104.139',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  34: {
    name: {
      th: 'อุบลราชธานี',
      en: 'Ubon Ratchathani',
    },
    amphoes: {
      3401: {
        name: {
          th: 'เมืองอุบลราชธานี',
          en: 'Mueang Ubon Ratchathani',
        },
        tambons: {
          340101: {
            name: {
              th: 'ในเมือง',
              en: null,
            },
            coordinates: {
              lat: '15.232',
              lng: '104.859',
            },
            zipcode: 34000,
          },
          340104: {
            name: {
              th: 'หัวเรือ',
              en: 'Hua Ruea',
            },
            coordinates: {
              lat: '15.367',
              lng: '104.845',
            },
            zipcode: 34000,
          },
          340105: {
            name: {
              th: 'หนองขอน',
              en: 'Nong Khon',
            },
            coordinates: {
              lat: '15.320',
              lng: '104.766',
            },
            zipcode: 34000,
          },
          340107: {
            name: {
              th: 'ปทุม',
              en: 'Pathum',
            },
            coordinates: {
              lat: '15.244',
              lng: '104.894',
            },
            zipcode: 34000,
          },
          340108: {
            name: {
              th: 'ขามใหญ่',
              en: 'Kham Yai',
            },
            coordinates: {
              lat: '15.302',
              lng: '104.836',
            },
            zipcode: 34000,
          },
          340109: {
            name: {
              th: 'แจระแม',
              en: 'Chaeramae',
            },
            coordinates: {
              lat: '15.251',
              lng: '104.802',
            },
            zipcode: 34000,
          },
          340111: {
            name: {
              th: 'หนองบ่อ',
              en: 'Nong Bo',
            },
            coordinates: {
              lat: '15.239',
              lng: '104.727',
            },
            zipcode: 34000,
          },
          340112: {
            name: {
              th: 'ไร่น้อย',
              en: 'Rai Noi',
            },
            coordinates: {
              lat: '15.314',
              lng: '104.905',
            },
            zipcode: 34000,
          },
          340113: {
            name: {
              th: 'กระโสบ',
              en: 'Krasop',
            },
            coordinates: {
              lat: '15.309',
              lng: '104.970',
            },
            zipcode: 34000,
          },
          340116: {
            name: {
              th: 'กุดลาด',
              en: 'Kut Lat',
            },
            coordinates: {
              lat: '15.291',
              lng: '105.000',
            },
            zipcode: 34000,
          },
          340119: {
            name: {
              th: 'ขี้เหล็ก',
              en: 'Khi Lek',
            },
            coordinates: {
              lat: '15.351',
              lng: '104.815',
            },
            zipcode: 34260,
          },
          340120: {
            name: {
              th: 'ปะอาว',
              en: 'Pa-Ao',
            },
            coordinates: {
              lat: '15.365',
              lng: '104.716',
            },
            zipcode: 34000,
          },
        },
      },
      3402: {
        name: {
          th: 'ศรีเมืองใหม่',
          en: 'Si Mueang Mai',
        },
        tambons: {
          340201: {
            name: {
              th: 'นาคำ',
              en: 'Na Kham',
            },
            coordinates: {
              lat: '15.507',
              lng: '105.327',
            },
            zipcode: 34250,
          },
          340202: {
            name: {
              th: 'แก้งกอก',
              en: 'Kaeng Kok',
            },
            coordinates: {
              lat: '15.478',
              lng: '105.237',
            },
            zipcode: 34250,
          },
          340203: {
            name: {
              th: 'เอือดใหญ่',
              en: 'Ueat Yai',
            },
            coordinates: {
              lat: '15.520',
              lng: '105.219',
            },
            zipcode: 34250,
          },
          340204: {
            name: {
              th: 'วาริน',
              en: 'Warin',
            },
            coordinates: {
              lat: '15.415',
              lng: '105.260',
            },
            zipcode: 34250,
          },
          340205: {
            name: {
              th: 'ลาดควาย',
              en: 'Lat Khwai',
            },
            coordinates: {
              lat: '15.564',
              lng: '105.329',
            },
            zipcode: 34250,
          },
          340206: {
            name: {
              th: 'สงยาง',
              en: 'Song Yang',
            },
            coordinates: {
              lat: '15.623',
              lng: '105.286',
            },
            zipcode: 34250,
          },
          340207: {
            name: {
              th: 'ตะบ่าย',
              en: 'Tabai',
            },
            coordinates: {
              lat: '15.560',
              lng: '105.227',
            },
            zipcode: 34250,
          },
          340208: {
            name: {
              th: 'คำไหล',
              en: 'Kham Lai',
            },
            coordinates: {
              lat: '15.409',
              lng: '105.346',
            },
            zipcode: 34250,
          },
          340209: {
            name: {
              th: 'หนามแท่ง',
              en: 'Nam Thaeng',
            },
            coordinates: {
              lat: '15.695',
              lng: '105.565',
            },
            zipcode: 34250,
          },
          340210: {
            name: {
              th: 'นาเลิน',
              en: 'Na Loen',
            },
            coordinates: {
              lat: '15.607',
              lng: '105.383',
            },
            zipcode: 34250,
          },
          340211: {
            name: {
              th: 'ดอนใหญ่',
              en: 'Don Yai',
            },
            coordinates: {
              lat: '15.447',
              lng: '105.316',
            },
            zipcode: 34250,
          },
        },
      },
      3403: {
        name: {
          th: 'โขงเจียม',
          en: 'Khong Chiam',
        },
        tambons: {
          340301: {
            name: {
              th: 'โขงเจียม',
              en: 'Khong Chiam',
            },
            coordinates: {
              lat: '15.307',
              lng: '105.530',
            },
            zipcode: 34220,
          },
          340302: {
            name: {
              th: 'ห้วยยาง',
              en: 'Huai Yang',
            },
            coordinates: {
              lat: '15.467',
              lng: '105.455',
            },
            zipcode: 34220,
          },
          340303: {
            name: {
              th: 'นาโพธิ์กลาง',
              en: 'Na Pho Klang',
            },
            coordinates: {
              lat: '15.553',
              lng: '105.557',
            },
            zipcode: 34220,
          },
          340304: {
            name: {
              th: 'หนองแสงใหญ่',
              en: 'Nong Saeng Yai',
            },
            coordinates: {
              lat: '15.339',
              lng: '105.389',
            },
            zipcode: 34220,
          },
          340305: {
            name: {
              th: 'ห้วยไผ่',
              en: 'Huai Phai',
            },
            coordinates: {
              lat: '15.420',
              lng: '105.551',
            },
            zipcode: 34220,
          },
        },
      },
      3404: {
        name: {
          th: 'เขื่องใน',
          en: 'Khueang Nai',
        },
        tambons: {
          340401: {
            name: {
              th: 'เขื่องใน',
              en: 'Khueang Nai',
            },
            coordinates: {
              lat: '15.371',
              lng: '104.556',
            },
            zipcode: 34150,
          },
          340402: {
            name: {
              th: 'สร้างถ่อ',
              en: 'Sang Tho',
            },
            coordinates: {
              lat: '15.367',
              lng: '104.503',
            },
            zipcode: 34150,
          },
          340403: {
            name: {
              th: 'ค้อทอง',
              en: 'Kho Thong',
            },
            coordinates: {
              lat: '15.399',
              lng: '104.429',
            },
            zipcode: 34150,
          },
          340404: {
            name: {
              th: 'ก่อเอ้',
              en: 'Ko-E',
            },
            coordinates: {
              lat: '15.392',
              lng: '104.623',
            },
            zipcode: 34150,
          },
          340405: {
            name: {
              th: 'หัวดอน',
              en: 'Hua Don',
            },
            coordinates: {
              lat: '15.307',
              lng: '104.690',
            },
            zipcode: 34150,
          },
          340406: {
            name: {
              th: 'ชีทวน',
              en: 'Chi Tuan',
            },
            coordinates: {
              lat: '15.269',
              lng: '104.653',
            },
            zipcode: 34150,
          },
          340407: {
            name: {
              th: 'ท่าไห',
              en: 'Tha Hai',
            },
            coordinates: {
              lat: '15.319',
              lng: '104.602',
            },
            zipcode: 34150,
          },
          340408: {
            name: {
              th: 'นาคำใหญ่',
              en: 'Na Kham Yai',
            },
            coordinates: {
              lat: '15.283',
              lng: '104.566',
            },
            zipcode: 34150,
          },
          340409: {
            name: {
              th: 'แดงหม้อ',
              en: 'Daeng Mo',
            },
            coordinates: {
              lat: '15.290',
              lng: '104.513',
            },
            zipcode: 34150,
          },
          340410: {
            name: {
              th: 'ธาตุน้อย',
              en: 'That Noi',
            },
            coordinates: {
              lat: '15.281',
              lng: '104.480',
            },
            zipcode: 34150,
          },
          340411: {
            name: {
              th: 'บ้านไทย',
              en: 'Ban Thai',
            },
            coordinates: {
              lat: '15.454',
              lng: '104.424',
            },
            zipcode: 34320,
          },
          340412: {
            name: {
              th: 'บ้านกอก',
              en: 'Ban Kok',
            },
            coordinates: {
              lat: '15.489',
              lng: '104.464',
            },
            zipcode: 34320,
          },
          340413: {
            name: {
              th: 'กลางใหญ่',
              en: 'Klang Yai',
            },
            coordinates: {
              lat: '15.515',
              lng: '104.407',
            },
            zipcode: 34320,
          },
          340414: {
            name: {
              th: 'โนนรัง',
              en: 'Non Rang',
            },
            coordinates: {
              lat: '15.521',
              lng: '104.500',
            },
            zipcode: 34320,
          },
          340415: {
            name: {
              th: 'ยางขี้นก',
              en: 'Yang Khi Nok',
            },
            coordinates: {
              lat: '15.446',
              lng: '104.508',
            },
            zipcode: 34150,
          },
          340416: {
            name: {
              th: 'ศรีสุข',
              en: 'Si Suk',
            },
            coordinates: {
              lat: '15.477',
              lng: '104.515',
            },
            zipcode: 34150,
          },
          340417: {
            name: {
              th: 'สหธาตุ',
              en: 'Sahathat',
            },
            coordinates: {
              lat: '15.338',
              lng: '104.459',
            },
            zipcode: 34150,
          },
          340418: {
            name: {
              th: 'หนองเหล่า',
              en: 'Nong Lao',
            },
            coordinates: {
              lat: '15.453',
              lng: '104.592',
            },
            zipcode: 34150,
          },
        },
      },
      3405: {
        name: {
          th: 'เขมราฐ',
          en: 'Khemarat',
        },
        tambons: {
          340501: {
            name: {
              th: 'เขมราฐ',
              en: 'Khemarat',
            },
            coordinates: {
              lat: '16.049',
              lng: '105.136',
            },
            zipcode: 34170,
          },
          340503: {
            name: {
              th: 'ขามป้อม',
              en: 'Kham Pom',
            },
            coordinates: {
              lat: '15.977',
              lng: '105.179',
            },
            zipcode: 34360,
          },
          340504: {
            name: {
              th: 'เจียด',
              en: 'Chiat',
            },
            coordinates: {
              lat: '15.872',
              lng: '105.222',
            },
            zipcode: 34170,
          },
          340507: {
            name: {
              th: 'หนองผือ',
              en: 'Nong Phue',
            },
            coordinates: {
              lat: '15.950',
              lng: '105.058',
            },
            zipcode: 34170,
          },
          340508: {
            name: {
              th: 'นาแวง',
              en: 'Na Waeng',
            },
            coordinates: {
              lat: '16.011',
              lng: '105.329',
            },
            zipcode: 34170,
          },
          340510: {
            name: {
              th: 'แก้งเหนือ',
              en: 'Kaeng Nuea',
            },
            coordinates: {
              lat: '15.932',
              lng: '105.128',
            },
            zipcode: 34170,
          },
          340511: {
            name: {
              th: 'หนองนกทา',
              en: 'Nong Nok Tha',
            },
            coordinates: {
              lat: '16.019',
              lng: '105.096',
            },
            zipcode: 34170,
          },
          340512: {
            name: {
              th: 'หนองสิม',
              en: 'Nong Sim',
            },
            coordinates: {
              lat: '16.025',
              lng: '105.037',
            },
            zipcode: 34170,
          },
          340513: {
            name: {
              th: 'หัวนา',
              en: 'Hua Na',
            },
            coordinates: {
              lat: '15.867',
              lng: '105.155',
            },
            zipcode: 34170,
          },
        },
      },
      3407: {
        name: {
          th: 'เดชอุดม',
          en: 'Det Udom',
        },
        tambons: {
          340701: {
            name: {
              th: 'เมืองเดช',
              en: 'Mueang Det',
            },
            coordinates: {
              lat: '14.920',
              lng: '105.055',
            },
            zipcode: 34160,
          },
          340702: {
            name: {
              th: 'นาส่วง',
              en: 'Na Suang',
            },
            coordinates: {
              lat: '15.012',
              lng: '104.983',
            },
            zipcode: 34160,
          },
          340704: {
            name: {
              th: 'นาเจริญ',
              en: 'Na Charoen',
            },
            coordinates: {
              lat: '14.902',
              lng: '104.955',
            },
            zipcode: 34160,
          },
          340706: {
            name: {
              th: 'ทุ่งเทิง',
              en: 'Thung Thoeng',
            },
            coordinates: {
              lat: '14.819',
              lng: '104.846',
            },
            zipcode: 34160,
          },
          340708: {
            name: {
              th: 'สมสะอาด',
              en: 'Som Sa-At',
            },
            coordinates: {
              lat: '14.824',
              lng: '104.987',
            },
            zipcode: 34160,
          },
          340709: {
            name: {
              th: 'กุดประทาย',
              en: 'Kut Prathai',
            },
            coordinates: {
              lat: '14.966',
              lng: '105.196',
            },
            zipcode: 34160,
          },
          340710: {
            name: {
              th: 'ตบหู',
              en: 'Top Hu',
            },
            coordinates: {
              lat: '14.702',
              lng: '105.090',
            },
            zipcode: 34160,
          },
          340711: {
            name: {
              th: 'กลาง',
              en: 'Klang',
            },
            coordinates: {
              lat: '14.797',
              lng: '105.141',
            },
            zipcode: 34160,
          },
          340712: {
            name: {
              th: 'แก้ง',
              en: 'Kaeng',
            },
            coordinates: {
              lat: '14.695',
              lng: '105.014',
            },
            zipcode: 34160,
          },
          340713: {
            name: {
              th: 'ท่าโพธิ์ศรี',
              en: 'Tha Pho Si',
            },
            coordinates: {
              lat: '14.840',
              lng: '105.049',
            },
            zipcode: 34160,
          },
          340715: {
            name: {
              th: 'บัวงาม',
              en: 'Bua Ngam',
            },
            coordinates: {
              lat: '14.801',
              lng: '105.218',
            },
            zipcode: 34230,
          },
          340716: {
            name: {
              th: 'คำครั่ง',
              en: 'Kham Khrang',
            },
            coordinates: {
              lat: '14.889',
              lng: '105.205',
            },
            zipcode: 34160,
          },
          340717: {
            name: {
              th: 'นากระแซง',
              en: 'Na Krasaeng',
            },
            coordinates: {
              lat: '14.870',
              lng: '104.908',
            },
            zipcode: 34160,
          },
          340720: {
            name: {
              th: 'โพนงาม',
              en: 'Phon Ngam',
            },
            coordinates: {
              lat: '14.904',
              lng: '105.115',
            },
            zipcode: 34230,
          },
          340721: {
            name: {
              th: 'ป่าโมง',
              en: 'Pa Mong',
            },
            coordinates: {
              lat: '14.961',
              lng: '104.976',
            },
            zipcode: 34160,
          },
          340723: {
            name: {
              th: 'โนนสมบูรณ์',
              en: 'Non Sombun',
            },
            coordinates: {
              lat: '14.760',
              lng: '105.030',
            },
            zipcode: 34280,
          },
        },
      },
      3408: {
        name: {
          th: 'นาจะหลวย',
          en: 'Na Chaluai',
        },
        tambons: {
          340801: {
            name: {
              th: 'นาจะหลวย',
              en: 'Na Chaluai',
            },
            coordinates: {
              lat: '14.487',
              lng: '105.261',
            },
            zipcode: 34280,
          },
          340802: {
            name: {
              th: 'โนนสมบูรณ์',
              en: 'Non Sombun',
            },
            coordinates: {
              lat: '14.688',
              lng: '105.201',
            },
            zipcode: null,
          },
          340803: {
            name: {
              th: 'พรสวรรค์',
              en: 'Phon Sawan',
            },
            coordinates: {
              lat: '14.627',
              lng: '105.180',
            },
            zipcode: 34280,
          },
          340804: {
            name: {
              th: 'บ้านตูม',
              en: 'Ban Tum',
            },
            coordinates: {
              lat: '14.608',
              lng: '105.241',
            },
            zipcode: 34280,
          },
          340805: {
            name: {
              th: 'โสกแสง',
              en: 'Sok Saeng',
            },
            coordinates: {
              lat: '14.661',
              lng: '105.258',
            },
            zipcode: 34280,
          },
          340806: {
            name: {
              th: 'โนนสวรรค์',
              en: 'Non Sawan',
            },
            coordinates: {
              lat: '14.659',
              lng: '105.141',
            },
            zipcode: 34280,
          },
        },
      },
      3409: {
        name: {
          th: 'น้ำยืน',
          en: 'Nam Yuen',
        },
        tambons: {
          340901: {
            name: {
              th: 'โซง',
              en: 'Song',
            },
            coordinates: {
              lat: '14.460',
              lng: '104.947',
            },
            zipcode: 34260,
          },
          340903: {
            name: {
              th: 'ยาง',
              en: 'Yang',
            },
            coordinates: {
              lat: '14.634',
              lng: '105.011',
            },
            zipcode: 34260,
          },
          340904: {
            name: {
              th: 'โดมประดิษฐ์',
              en: 'Dom Pradit',
            },
            coordinates: {
              lat: '14.337',
              lng: '105.059',
            },
            zipcode: 34260,
          },
          340906: {
            name: {
              th: 'บุเปือย',
              en: 'Bu Pueai',
            },
            coordinates: {
              lat: '14.538',
              lng: '105.056',
            },
            zipcode: 34260,
          },
          340907: {
            name: {
              th: 'สีวิเชียร',
              en: 'Si Wichian',
            },
            coordinates: {
              lat: '14.483',
              lng: '105.040',
            },
            zipcode: 34260,
          },
          340909: {
            name: {
              th: 'ยางใหญ่',
              en: 'Yang Yai',
            },
            coordinates: {
              lat: '14.575',
              lng: '105.094',
            },
            zipcode: 34260,
          },
          340911: {
            name: {
              th: 'เก่าขาม',
              en: 'Kao Kham',
            },
            coordinates: {
              lat: '14.577',
              lng: '105.020',
            },
            zipcode: 34260,
          },
        },
      },
      3410: {
        name: {
          th: 'บุณฑริก',
          en: 'Boontharik',
        },
        tambons: {
          341001: {
            name: {
              th: 'โพนงาม',
              en: 'Phon Ngam',
            },
            coordinates: {
              lat: '14.759',
              lng: '105.464',
            },
            zipcode: null,
          },
          341002: {
            name: {
              th: 'ห้วยข่า',
              en: 'Huai Kha',
            },
            coordinates: {
              lat: '14.567',
              lng: '105.424',
            },
            zipcode: 34230,
          },
          341003: {
            name: {
              th: 'คอแลน',
              en: 'Kho Laen',
            },
            coordinates: {
              lat: '14.867',
              lng: '105.461',
            },
            zipcode: 34230,
          },
          341004: {
            name: {
              th: 'นาโพธิ์',
              en: 'Na Pho',
            },
            coordinates: {
              lat: '14.891',
              lng: '105.294',
            },
            zipcode: 34110,
          },
          341005: {
            name: {
              th: 'หนองสะโน',
              en: 'Nong Sano',
            },
            coordinates: {
              lat: '14.771',
              lng: '105.288',
            },
            zipcode: 34230,
          },
          341006: {
            name: {
              th: 'โนนค้อ',
              en: 'Non Kho',
            },
            coordinates: {
              lat: '14.672',
              lng: '105.307',
            },
            zipcode: 34230,
          },
          341007: {
            name: {
              th: 'บัวงาม',
              en: 'Bua Ngam',
            },
            coordinates: {
              lat: '14.741',
              lng: '105.371',
            },
            zipcode: null,
          },
          341008: {
            name: {
              th: 'บ้านแมด',
              en: 'Ban Maet',
            },
            coordinates: {
              lat: '14.906',
              lng: '105.348',
            },
            zipcode: 34230,
          },
        },
      },
      3411: {
        name: {
          th: 'ตระการพืชผล',
          en: 'Trakan Phuet Phon',
        },
        tambons: {
          341101: {
            name: {
              th: 'ขุหลุ',
              en: 'Khulu',
            },
            coordinates: {
              lat: '15.601',
              lng: '105.021',
            },
            zipcode: 34130,
          },
          341102: {
            name: {
              th: 'กระเดียน',
              en: 'Kradian',
            },
            coordinates: {
              lat: '15.618',
              lng: '105.089',
            },
            zipcode: 34130,
          },
          341103: {
            name: {
              th: 'เกษม',
              en: 'Kasem',
            },
            coordinates: {
              lat: '15.691',
              lng: '105.049',
            },
            zipcode: 34130,
          },
          341104: {
            name: {
              th: 'กุศกร',
              en: 'Kusakon',
            },
            coordinates: {
              lat: '15.570',
              lng: '105.102',
            },
            zipcode: 34130,
          },
          341105: {
            name: {
              th: 'ขามเปี้ย',
              en: 'Kham Pia',
            },
            coordinates: {
              lat: '15.542',
              lng: '104.934',
            },
            zipcode: 34130,
          },
          341106: {
            name: {
              th: 'คอนสาย',
              en: 'Khon Sai',
            },
            coordinates: {
              lat: '15.736',
              lng: '105.128',
            },
            zipcode: 34130,
          },
          341107: {
            name: {
              th: 'โคกจาน',
              en: 'Khok Chan',
            },
            coordinates: {
              lat: '15.507',
              lng: '105.100',
            },
            zipcode: 34130,
          },
          341108: {
            name: {
              th: 'นาพิน',
              en: 'Na Phin',
            },
            coordinates: {
              lat: '15.562',
              lng: '105.034',
            },
            zipcode: 34130,
          },
          341109: {
            name: {
              th: 'นาสะไม',
              en: 'Na Samai',
            },
            coordinates: {
              lat: '15.687',
              lng: '104.913',
            },
            zipcode: 34130,
          },
          341110: {
            name: {
              th: 'โนนกุง',
              en: 'Non Kung',
            },
            coordinates: {
              lat: '15.659',
              lng: '105.168',
            },
            zipcode: 34130,
          },
          341111: {
            name: {
              th: 'ตระการ',
              en: 'Trakan',
            },
            coordinates: {
              lat: '15.466',
              lng: '105.050',
            },
            zipcode: 34130,
          },
          341112: {
            name: {
              th: 'ตากแดด',
              en: 'Tak Daet',
            },
            coordinates: {
              lat: '15.589',
              lng: '105.183',
            },
            zipcode: 34130,
          },
          341113: {
            name: {
              th: 'ไหล่ทุ่ง',
              en: 'Lai Thung',
            },
            coordinates: {
              lat: '15.600',
              lng: '104.968',
            },
            zipcode: 34130,
          },
          341114: {
            name: {
              th: 'เป้า',
              en: 'Pao',
            },
            coordinates: {
              lat: '15.690',
              lng: '104.978',
            },
            zipcode: 34130,
          },
          341115: {
            name: {
              th: 'เซเป็ด',
              en: 'Se Pet',
            },
            coordinates: {
              lat: '15.547',
              lng: '104.988',
            },
            zipcode: 34130,
          },
          341116: {
            name: {
              th: 'สะพือ',
              en: 'Saphue',
            },
            coordinates: {
              lat: '15.510',
              lng: '105.029',
            },
            zipcode: 34130,
          },
          341117: {
            name: {
              th: 'หนองเต่า',
              en: 'Nong Tao',
            },
            coordinates: {
              lat: '15.725',
              lng: '104.933',
            },
            zipcode: 34130,
          },
          341118: {
            name: {
              th: 'ถ้ำแข้',
              en: 'Tham Khae',
            },
            coordinates: {
              lat: '15.672',
              lng: '105.134',
            },
            zipcode: 34130,
          },
          341119: {
            name: {
              th: 'ท่าหลวง',
              en: 'Tha Luang',
            },
            coordinates: {
              lat: '15.673',
              lng: '105.212',
            },
            zipcode: 34130,
          },
          341120: {
            name: {
              th: 'ห้วยฝ้ายพัฒนา',
              en: 'Huai Fai Phatthana',
            },
            coordinates: {
              lat: '15.522',
              lng: '105.168',
            },
            zipcode: 34130,
          },
          341121: {
            name: {
              th: 'กุดยาลวน',
              en: 'Kut Ya Luan',
            },
            coordinates: {
              lat: '15.731',
              lng: '105.029',
            },
            zipcode: 34130,
          },
          341122: {
            name: {
              th: 'บ้านแดง',
              en: 'Ban Daeng',
            },
            coordinates: {
              lat: '15.658',
              lng: '105.081',
            },
            zipcode: 34130,
          },
          341123: {
            name: {
              th: 'คำเจริญ',
              en: 'Kham Charoen',
            },
            coordinates: {
              lat: '15.642',
              lng: '105.032',
            },
            zipcode: 34130,
          },
        },
      },
      3412: {
        name: {
          th: 'กุดข้าวปุ้น',
          en: 'Kut Khaopun',
        },
        tambons: {
          341201: {
            name: {
              th: 'ข้าวปุ้น',
              en: 'Khao Pun',
            },
            coordinates: {
              lat: '15.792',
              lng: '105.006',
            },
            zipcode: 34270,
          },
          341202: {
            name: {
              th: 'โนนสวาง',
              en: 'Non Sawang',
            },
            coordinates: {
              lat: '15.867',
              lng: '105.026',
            },
            zipcode: 34270,
          },
          341203: {
            name: {
              th: 'แก่งเค็ง',
              en: 'Kaeng Kheng',
            },
            coordinates: {
              lat: '15.776',
              lng: '105.101',
            },
            zipcode: 34270,
          },
          341204: {
            name: {
              th: 'กาบิน',
              en: 'Kabin',
            },
            coordinates: {
              lat: '15.757',
              lng: '104.964',
            },
            zipcode: 34270,
          },
          341205: {
            name: {
              th: 'หนองทันน้ำ',
              en: 'Nong Than Nam',
            },
            coordinates: {
              lat: '15.828',
              lng: '105.099',
            },
            zipcode: 34270,
          },
        },
      },
      3414: {
        name: {
          th: 'ม่วงสามสิบ',
          en: 'Muang Sam Sip',
        },
        tambons: {
          341401: {
            name: {
              th: 'ม่วงสามสิบ',
              en: 'Muang Sam Sip',
            },
            coordinates: {
              lat: '15.515',
              lng: '104.730',
            },
            zipcode: 34140,
          },
          341402: {
            name: {
              th: 'เหล่าบก',
              en: 'Lao Bok',
            },
            coordinates: {
              lat: '15.657',
              lng: '104.667',
            },
            zipcode: 34140,
          },
          341403: {
            name: {
              th: 'ดุมใหญ่',
              en: 'Dum Yai',
            },
            coordinates: {
              lat: '15.615',
              lng: '104.739',
            },
            zipcode: 34140,
          },
          341404: {
            name: {
              th: 'หนองช้างใหญ่',
              en: 'Nong Chang Yai',
            },
            coordinates: {
              lat: '15.564',
              lng: '104.827',
            },
            zipcode: 34140,
          },
          341405: {
            name: {
              th: 'หนองเมือง',
              en: 'Nong Mueang',
            },
            coordinates: {
              lat: '15.571',
              lng: '104.735',
            },
            zipcode: 34140,
          },
          341406: {
            name: {
              th: 'เตย',
              en: 'Toei',
            },
            coordinates: {
              lat: '15.500',
              lng: '104.828',
            },
            zipcode: 34140,
          },
          341407: {
            name: {
              th: 'ยางสักกระโพหลุ่ม',
              en: 'Yang Sak Krapho Lum',
            },
            coordinates: {
              lat: '15.449',
              lng: '104.811',
            },
            zipcode: 34140,
          },
          341408: {
            name: {
              th: 'หนองไข่นก',
              en: 'Nong Khai Nok',
            },
            coordinates: {
              lat: '15.397',
              lng: '104.765',
            },
            zipcode: 34140,
          },
          341409: {
            name: {
              th: 'หนองเหล่า',
              en: 'Nong Lao',
            },
            coordinates: {
              lat: '15.443',
              lng: '104.679',
            },
            zipcode: null,
          },
          341410: {
            name: {
              th: 'หนองฮาง',
              en: 'Nong Hang',
            },
            coordinates: {
              lat: '15.501',
              lng: '104.661',
            },
            zipcode: 34140,
          },
          341411: {
            name: {
              th: 'ยางโยภาพ',
              en: 'Yang Yo Phap',
            },
            coordinates: {
              lat: '15.593',
              lng: '104.667',
            },
            zipcode: 34140,
          },
          341412: {
            name: {
              th: 'ไผ่ใหญ่',
              en: 'Phai Yai',
            },
            coordinates: {
              lat: '15.598',
              lng: '104.617',
            },
            zipcode: 34140,
          },
          341413: {
            name: {
              th: 'นาเลิง',
              en: 'Na Loeng',
            },
            coordinates: {
              lat: '15.541',
              lng: '104.586',
            },
            zipcode: 34140,
          },
          341414: {
            name: {
              th: 'โพนแพง',
              en: 'Phon Phaeng',
            },
            coordinates: {
              lat: '15.444',
              lng: '104.743',
            },
            zipcode: 34140,
          },
        },
      },
      3415: {
        name: {
          th: 'วารินชำราบ',
          en: 'Warin Chamrap',
        },
        tambons: {
          341501: {
            name: {
              th: 'วารินชำราบ',
              en: 'Warin Chamrap',
            },
            coordinates: {
              lat: '15.200',
              lng: '104.853',
            },
            zipcode: 34190,
          },
          341502: {
            name: {
              th: 'ธาตุ',
              en: 'That',
            },
            coordinates: {
              lat: '15.139',
              lng: '104.887',
            },
            zipcode: 34190,
          },
          341504: {
            name: {
              th: 'ท่าลาด',
              en: 'Tha Lat',
            },
            coordinates: {
              lat: '15.105',
              lng: '104.776',
            },
            zipcode: 34310,
          },
          341505: {
            name: {
              th: 'โนนโหนน',
              en: 'Non Non',
            },
            coordinates: {
              lat: '15.101',
              lng: '104.818',
            },
            zipcode: 34190,
          },
          341507: {
            name: {
              th: 'คูเมือง',
              en: 'Khu Mueang',
            },
            coordinates: {
              lat: '15.080',
              lng: '104.865',
            },
            zipcode: 34190,
          },
          341508: {
            name: {
              th: 'สระสมิง',
              en: 'Sa Saming',
            },
            coordinates: {
              lat: '14.999',
              lng: '104.903',
            },
            zipcode: 34190,
          },
          341510: {
            name: {
              th: 'คำน้ำแซบ',
              en: 'Kham Nam Sap',
            },
            coordinates: {
              lat: '15.198',
              lng: '104.815',
            },
            zipcode: 34190,
          },
          341511: {
            name: {
              th: 'บุ่งหวาย',
              en: 'Bung Wai',
            },
            coordinates: {
              lat: '15.158',
              lng: '104.753',
            },
            zipcode: 34310,
          },
          341515: {
            name: {
              th: 'คำขวาง',
              en: 'Kham Khwang',
            },
            coordinates: {
              lat: '15.165',
              lng: '104.924',
            },
            zipcode: 34190,
          },
          341516: {
            name: {
              th: 'โพธิ์ใหญ่',
              en: 'Pho Yai',
            },
            coordinates: {
              lat: '15.122',
              lng: '104.963',
            },
            zipcode: 34190,
          },
          341518: {
            name: {
              th: 'แสนสุข',
              en: 'Saen Suk',
            },
            coordinates: {
              lat: '15.173',
              lng: '104.872',
            },
            zipcode: 34190,
          },
          341520: {
            name: {
              th: 'หนองกินเพล',
              en: 'Nong Kin Phlen',
            },
            coordinates: {
              lat: '15.204',
              lng: '104.782',
            },
            zipcode: 34190,
          },
          341521: {
            name: {
              th: 'โนนผึ้ง',
              en: 'Non Phueng',
            },
            coordinates: {
              lat: '15.162',
              lng: '104.823',
            },
            zipcode: 34190,
          },
          341522: {
            name: {
              th: 'เมืองศรีไค',
              en: 'Mueang Si Khai',
            },
            coordinates: {
              lat: '15.087',
              lng: '104.904',
            },
            zipcode: 34190,
          },
          341524: {
            name: {
              th: 'ห้วยขะยุง',
              en: 'Huai Khayung',
            },
            coordinates: {
              lat: '15.091',
              lng: '104.708',
            },
            zipcode: 34310,
          },
          341526: {
            name: {
              th: 'บุ่งไหม',
              en: 'Bung Mai',
            },
            coordinates: {
              lat: '15.197',
              lng: '104.961',
            },
            zipcode: 34190,
          },
        },
      },
      3419: {
        name: {
          th: 'พิบูลมังสาหาร',
          en: 'Phibun Mangsahan',
        },
        tambons: {
          341901: {
            name: {
              th: 'พิบูล',
              en: 'Phibun',
            },
            coordinates: {
              lat: '15.248',
              lng: '105.226',
            },
            zipcode: 34110,
          },
          341902: {
            name: {
              th: 'กุดชมภู',
              en: 'Kut Chomphu',
            },
            coordinates: {
              lat: '15.202',
              lng: '105.249',
            },
            zipcode: 34110,
          },
          341904: {
            name: {
              th: 'ดอนจิก',
              en: 'Don Chik',
            },
            coordinates: {
              lat: '15.132',
              lng: '105.313',
            },
            zipcode: 34110,
          },
          341905: {
            name: {
              th: 'ทรายมูล',
              en: 'Sai Mun',
            },
            coordinates: {
              lat: '15.304',
              lng: '105.344',
            },
            zipcode: 34110,
          },
          341906: {
            name: {
              th: 'นาโพธิ์',
              en: 'Na Pho',
            },
            coordinates: {
              lat: '15.069',
              lng: '105.128',
            },
            zipcode: null,
          },
          341907: {
            name: {
              th: 'โนนกลาง',
              en: 'Non Klang',
            },
            coordinates: {
              lat: '15.066',
              lng: '105.344',
            },
            zipcode: 34360,
          },
          341909: {
            name: {
              th: 'โพธิ์ไทร',
              en: 'Pho Sai',
            },
            coordinates: {
              lat: '15.216',
              lng: '105.158',
            },
            zipcode: 34340,
          },
          341910: {
            name: {
              th: 'โพธิ์ศรี',
              en: 'Pho Si',
            },
            coordinates: {
              lat: '15.293',
              lng: '105.237',
            },
            zipcode: 34110,
          },
          341911: {
            name: {
              th: 'ระเว',
              en: 'Rawe',
            },
            coordinates: {
              lat: '15.340',
              lng: '105.297',
            },
            zipcode: 34110,
          },
          341912: {
            name: {
              th: 'ไร่ใต้',
              en: 'Rai Tai',
            },
            coordinates: {
              lat: '15.136',
              lng: '105.122',
            },
            zipcode: 34110,
          },
          341913: {
            name: {
              th: 'หนองบัวฮี',
              en: 'Nong Bua Hi',
            },
            coordinates: {
              lat: '15.065',
              lng: '105.190',
            },
            zipcode: 34110,
          },
          341914: {
            name: {
              th: 'อ่างศิลา',
              en: 'Ang Sila',
            },
            coordinates: {
              lat: '15.031',
              lng: '105.288',
            },
            zipcode: 34110,
          },
          341918: {
            name: {
              th: 'โนนกาหลง',
              en: 'Non Kalong',
            },
            coordinates: {
              lat: '14.993',
              lng: '105.336',
            },
            zipcode: 34110,
          },
          341919: {
            name: {
              th: 'บ้านแขม',
              en: 'Ban Khaem',
            },
            coordinates: {
              lat: '15.022',
              lng: '105.250',
            },
            zipcode: 34110,
          },
        },
      },
      3420: {
        name: {
          th: 'ตาลสุม',
          en: 'Tan Sum',
        },
        tambons: {
          342001: {
            name: {
              th: 'ตาลสุม',
              en: 'Tan Sum',
            },
            coordinates: {
              lat: '15.326',
              lng: '105.156',
            },
            zipcode: 34330,
          },
          342002: {
            name: {
              th: 'สำโรง',
              en: 'Samrong',
            },
            coordinates: {
              lat: '15.380',
              lng: '105.233',
            },
            zipcode: null,
          },
          342003: {
            name: {
              th: 'จิกเทิง',
              en: 'Chik Thoeng',
            },
            coordinates: {
              lat: '15.352',
              lng: '105.086',
            },
            zipcode: 34330,
          },
          342004: {
            name: {
              th: 'หนองกุง',
              en: 'Nong Kung',
            },
            coordinates: {
              lat: '15.444',
              lng: '105.185',
            },
            zipcode: 34330,
          },
          342005: {
            name: {
              th: 'นาคาย',
              en: 'Na Khai',
            },
            coordinates: {
              lat: '15.417',
              lng: '105.108',
            },
            zipcode: 34330,
          },
          342006: {
            name: {
              th: 'คำหว้า',
              en: 'Kham Wa',
            },
            coordinates: {
              lat: '15.385',
              lng: '105.166',
            },
            zipcode: 34330,
          },
        },
      },
      3421: {
        name: {
          th: 'โพธิ์ไทร',
          en: 'Pho Sai',
        },
        tambons: {
          342101: {
            name: {
              th: 'โพธิ์ไทร',
              en: 'Pho Sai',
            },
            coordinates: {
              lat: '15.816',
              lng: '105.261',
            },
            zipcode: null,
          },
          342102: {
            name: {
              th: 'ม่วงใหญ่',
              en: 'Muang Yai',
            },
            coordinates: {
              lat: '15.860',
              lng: '105.273',
            },
            zipcode: 34340,
          },
          342103: {
            name: {
              th: 'สำโรง',
              en: 'Samrong',
            },
            coordinates: {
              lat: '15.728',
              lng: '105.494',
            },
            zipcode: null,
          },
          342104: {
            name: {
              th: 'สองคอน',
              en: 'Song Khon',
            },
            coordinates: {
              lat: '15.834',
              lng: '105.345',
            },
            zipcode: 34340,
          },
          342105: {
            name: {
              th: 'สารภี',
              en: 'Saraphi',
            },
            coordinates: {
              lat: '15.756',
              lng: '105.254',
            },
            zipcode: 34340,
          },
          342106: {
            name: {
              th: 'เหล่างาม',
              en: 'Lao Ngam',
            },
            coordinates: {
              lat: '15.760',
              lng: '105.374',
            },
            zipcode: 34340,
          },
        },
      },
      3422: {
        name: {
          th: 'สำโรง',
          en: 'Samrong',
        },
        tambons: {
          342201: {
            name: {
              th: 'สำโรง',
              en: 'Samrong',
            },
            coordinates: {
              lat: '15.010',
              lng: '104.793',
            },
            zipcode: null,
          },
          342202: {
            name: {
              th: 'โคกก่อง',
              en: 'Khok Kong',
            },
            coordinates: {
              lat: '15.033',
              lng: '104.839',
            },
            zipcode: null,
          },
          342203: {
            name: {
              th: 'หนองไฮ',
              en: 'Nong Hai',
            },
            coordinates: {
              lat: '14.960',
              lng: '104.760',
            },
            zipcode: null,
          },
          342204: {
            name: {
              th: 'ค้อน้อย',
              en: 'Kho Noi',
            },
            coordinates: {
              lat: '14.922',
              lng: '104.812',
            },
            zipcode: null,
          },
          342205: {
            name: {
              th: 'โนนกาเล็น',
              en: 'Non Kalen',
            },
            coordinates: {
              lat: '15.020',
              lng: '104.720',
            },
            zipcode: null,
          },
          342206: {
            name: {
              th: 'โคกสว่าง',
              en: 'Khok Sawang',
            },
            coordinates: {
              lat: '14.936',
              lng: '104.865',
            },
            zipcode: null,
          },
          342207: {
            name: {
              th: 'โนนกลาง',
              en: 'Non Klang',
            },
            coordinates: {
              lat: '14.959',
              lng: '104.713',
            },
            zipcode: null,
          },
          342208: {
            name: {
              th: 'บอน',
              en: 'Bon',
            },
            coordinates: {
              lat: '15.055',
              lng: '104.765',
            },
            zipcode: 34360,
          },
          342209: {
            name: {
              th: 'ขามป้อม',
              en: 'Kham Pom',
            },
            coordinates: {
              lat: '14.981',
              lng: '104.821',
            },
            zipcode: null,
          },
        },
      },
      3424: {
        name: {
          th: 'ดอนมดแดง',
          en: 'Don Mot Daeng',
        },
        tambons: {
          342401: {
            name: {
              th: 'ดอนมดแดง',
              en: 'Don Mot Daeng',
            },
            coordinates: {
              lat: '15.315',
              lng: '105.065',
            },
            zipcode: null,
          },
          342402: {
            name: {
              th: 'เหล่าแดง',
              en: 'Lao Daeng',
            },
            coordinates: {
              lat: '15.375',
              lng: '105.028',
            },
            zipcode: null,
          },
          342403: {
            name: {
              th: 'ท่าเมือง',
              en: 'Tha Mueang',
            },
            coordinates: {
              lat: '15.473',
              lng: '104.968',
            },
            zipcode: null,
          },
          342404: {
            name: {
              th: 'คำไฮใหญ่',
              en: null,
            },
            coordinates: {
              lat: '15.372',
              lng: '104.968',
            },
            zipcode: null,
          },
        },
      },
      3425: {
        name: {
          th: 'สิรินธร',
          en: 'Sirindhorn',
        },
        tambons: {
          342501: {
            name: {
              th: 'คันไร่',
              en: 'Khan Rai',
            },
            coordinates: {
              lat: '15.225',
              lng: '105.330',
            },
            zipcode: null,
          },
          342502: {
            name: {
              th: 'ช่องเม็ก',
              en: 'Chong Mek',
            },
            coordinates: {
              lat: '15.126',
              lng: '105.414',
            },
            zipcode: null,
          },
          342503: {
            name: {
              th: 'โนนก่อ',
              en: 'Non Ko',
            },
            coordinates: {
              lat: '14.993',
              lng: '105.425',
            },
            zipcode: null,
          },
          342504: {
            name: {
              th: 'นิคมลำโดมน้อย',
              en: 'Nikhom Lam Dom Noi',
            },
            coordinates: {
              lat: '15.210',
              lng: '105.412',
            },
            zipcode: 34350,
          },
          342505: {
            name: {
              th: 'ฝางคำ',
              en: 'Fang Kham',
            },
            coordinates: {
              lat: '15.134',
              lng: '105.364',
            },
            zipcode: null,
          },
          342506: {
            name: {
              th: 'คำเขื่อนแก้ว',
              en: 'Kham Khuean Kaeo',
            },
            coordinates: {
              lat: '15.240',
              lng: '105.507',
            },
            zipcode: null,
          },
        },
      },
      3426: {
        name: {
          th: 'ทุ่งศรีอุดม',
          en: 'Thung Si Udom',
        },
        tambons: {
          342602: {
            name: {
              th: 'หนองอ้ม',
              en: 'Nong Om',
            },
            coordinates: {
              lat: '14.804',
              lng: '104.922',
            },
            zipcode: null,
          },
          342603: {
            name: {
              th: 'นาเกษม',
              en: 'Na Kasem',
            },
            coordinates: {
              lat: '14.723',
              lng: '104.931',
            },
            zipcode: null,
          },
          342604: {
            name: {
              th: 'กุดเรือ',
              en: 'Kut Ruea',
            },
            coordinates: {
              lat: '14.678',
              lng: '104.955',
            },
            zipcode: null,
          },
          342605: {
            name: {
              th: 'โคกชำแระ',
              en: 'Khok Chamrae',
            },
            coordinates: {
              lat: '14.752',
              lng: '104.908',
            },
            zipcode: null,
          },
          342606: {
            name: {
              th: 'นาห่อม',
              en: 'Na Hom',
            },
            coordinates: {
              lat: '14.760',
              lng: '104.855',
            },
            zipcode: 34160,
          },
        },
      },
      3429: {
        name: {
          th: 'นาเยีย',
          en: 'Na Yia',
        },
        tambons: {
          342901: {
            name: {
              th: 'นาเยีย',
              en: 'Na Yia',
            },
            coordinates: {
              lat: '15.006',
              lng: '105.143',
            },
            zipcode: null,
          },
          342902: {
            name: {
              th: 'นาดี',
              en: 'Na Di',
            },
            coordinates: {
              lat: '15.116',
              lng: '105.018',
            },
            zipcode: null,
          },
          342903: {
            name: {
              th: 'นาเรือง',
              en: 'Na Rueang',
            },
            coordinates: {
              lat: '14.987',
              lng: '105.068',
            },
            zipcode: null,
          },
        },
      },
      3430: {
        name: {
          th: 'นาตาล',
          en: 'Na Tan',
        },
        tambons: {
          343001: {
            name: {
              th: 'นาตาล',
              en: 'Na Tan',
            },
            coordinates: {
              lat: '15.893',
              lng: '105.310',
            },
            zipcode: null,
          },
          343002: {
            name: {
              th: 'พะลาน',
              en: 'Phalan',
            },
            coordinates: {
              lat: '15.960',
              lng: '105.332',
            },
            zipcode: null,
          },
          343003: {
            name: {
              th: 'กองโพน',
              en: 'Kong Phon',
            },
            coordinates: {
              lat: '15.965',
              lng: '105.270',
            },
            zipcode: null,
          },
          343004: {
            name: {
              th: 'พังเคน',
              en: 'Phang Khen',
            },
            coordinates: {
              lat: '15.921',
              lng: '105.212',
            },
            zipcode: null,
          },
        },
      },
      3431: {
        name: {
          th: 'เหล่าเสือโก้ก',
          en: 'Lao Suea Kok',
        },
        tambons: {
          343101: {
            name: {
              th: 'เหล่าเสือโก้ก',
              en: 'Lao Suea Kok',
            },
            coordinates: {
              lat: '15.432',
              lng: '104.918',
            },
            zipcode: null,
          },
          343102: {
            name: {
              th: 'โพนเมือง',
              en: 'Phon Mueang',
            },
            coordinates: {
              lat: '15.492',
              lng: '104.891',
            },
            zipcode: null,
          },
          343103: {
            name: {
              th: 'แพงใหญ่',
              en: 'Phaeng Yai',
            },
            coordinates: {
              lat: '15.395',
              lng: '104.881',
            },
            zipcode: null,
          },
          343104: {
            name: {
              th: 'หนองบก',
              en: 'Nong Bok',
            },
            coordinates: {
              lat: '15.423',
              lng: '104.988',
            },
            zipcode: null,
          },
        },
      },
      3432: {
        name: {
          th: 'สว่างวีระวงศ์',
          en: 'Sawang Wirawong',
        },
        tambons: {
          343201: {
            name: {
              th: 'แก่งโดม',
              en: 'Kaeng Dom',
            },
            coordinates: {
              lat: '15.204',
              lng: '105.087',
            },
            zipcode: null,
          },
          343202: {
            name: {
              th: 'ท่าช้าง',
              en: 'Tha Chang',
            },
            coordinates: {
              lat: '15.229',
              lng: '104.990',
            },
            zipcode: null,
          },
          343203: {
            name: {
              th: 'บุ่งมะแลง',
              en: 'Bung Malaeng',
            },
            coordinates: {
              lat: '15.238',
              lng: '105.045',
            },
            zipcode: null,
          },
          343204: {
            name: {
              th: 'สว่าง',
              en: 'Sawang',
            },
            coordinates: {
              lat: '15.274',
              lng: '105.118',
            },
            zipcode: null,
          },
        },
      },
      3433: {
        name: {
          th: 'น้ำขุ่น',
          en: 'Nam Khun',
        },
        tambons: {
          343301: {
            name: {
              th: 'ตาเกา',
              en: 'Ta Kao',
            },
            coordinates: {
              lat: '14.568',
              lng: '104.919',
            },
            zipcode: null,
          },
          343302: {
            name: {
              th: 'ไพบูลย์',
              en: 'Phaibun',
            },
            coordinates: {
              lat: '14.603',
              lng: '104.854',
            },
            zipcode: null,
          },
          343303: {
            name: {
              th: 'ขี้เหล็ก',
              en: 'Khi Lek',
            },
            coordinates: {
              lat: '14.624',
              lng: '104.899',
            },
            zipcode: null,
          },
          343304: {
            name: {
              th: 'โคกสะอาด',
              en: 'Khok Sa-At',
            },
            coordinates: {
              lat: '14.443',
              lng: '104.848',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  35: {
    name: {
      th: 'ยโสธร',
      en: 'Yasothon',
    },
    amphoes: {
      3501: {
        name: {
          th: 'เมืองยโสธร',
          en: 'Mueang Yasothon',
        },
        tambons: {
          350101: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '15.799',
              lng: '104.148',
            },
            zipcode: 35000,
          },
          350102: {
            name: {
              th: 'น้ำคำใหญ่',
              en: 'Nam Kham Yai',
            },
            coordinates: {
              lat: '15.828',
              lng: '104.167',
            },
            zipcode: 35000,
          },
          350103: {
            name: {
              th: 'ตาดทอง',
              en: 'Tat Thong',
            },
            coordinates: {
              lat: '15.777',
              lng: '104.205',
            },
            zipcode: 35000,
          },
          350104: {
            name: {
              th: 'สำราญ',
              en: 'Samran',
            },
            coordinates: {
              lat: '15.838',
              lng: '104.113',
            },
            zipcode: 35000,
          },
          350105: {
            name: {
              th: 'ค้อเหนือ',
              en: 'Kho Nuea',
            },
            coordinates: {
              lat: '15.835',
              lng: '104.044',
            },
            zipcode: 35000,
          },
          350106: {
            name: {
              th: 'ดู่ทุ่ง',
              en: 'Du Thung',
            },
            coordinates: {
              lat: '15.899',
              lng: '104.041',
            },
            zipcode: 35000,
          },
          350107: {
            name: {
              th: 'เดิด',
              en: 'Doet',
            },
            coordinates: {
              lat: '15.945',
              lng: '104.062',
            },
            zipcode: 35000,
          },
          350108: {
            name: {
              th: 'ขั้นไดใหญ่',
              en: 'Khan Dai Yai',
            },
            coordinates: {
              lat: '15.915',
              lng: '104.120',
            },
            zipcode: 35000,
          },
          350109: {
            name: {
              th: 'ทุ่งแต้',
              en: 'Thung Tae',
            },
            coordinates: {
              lat: '15.913',
              lng: '104.179',
            },
            zipcode: 35000,
          },
          350110: {
            name: {
              th: 'สิงห์',
              en: 'Sing',
            },
            coordinates: {
              lat: '15.823',
              lng: '104.241',
            },
            zipcode: 35000,
          },
          350111: {
            name: {
              th: 'นาสะไมย์',
              en: 'Na Samai',
            },
            coordinates: {
              lat: '15.895',
              lng: '104.246',
            },
            zipcode: 35000,
          },
          350112: {
            name: {
              th: 'เขื่องคำ',
              en: 'Khueang Kham',
            },
            coordinates: {
              lat: '15.740',
              lng: '104.160',
            },
            zipcode: 35000,
          },
          350113: {
            name: {
              th: 'หนองหิน',
              en: 'Nong Hin',
            },
            coordinates: {
              lat: '15.859',
              lng: '104.282',
            },
            zipcode: 35000,
          },
          350114: {
            name: {
              th: 'หนองคู',
              en: 'Nong Khu',
            },
            coordinates: {
              lat: '15.780',
              lng: '104.252',
            },
            zipcode: 35000,
          },
          350115: {
            name: {
              th: 'ขุมเงิน',
              en: 'Khum Ngoen',
            },
            coordinates: {
              lat: '15.713',
              lng: '104.194',
            },
            zipcode: 35000,
          },
          350116: {
            name: {
              th: 'ทุ่งนางโอก',
              en: 'Thung Nang Ok',
            },
            coordinates: {
              lat: '15.886',
              lng: '104.147',
            },
            zipcode: 35000,
          },
          350117: {
            name: {
              th: 'หนองเรือ',
              en: 'Nong Ruea',
            },
            coordinates: {
              lat: '15.861',
              lng: '104.207',
            },
            zipcode: 35000,
          },
          350118: {
            name: {
              th: 'หนองเป็ด',
              en: 'Nong Pet',
            },
            coordinates: {
              lat: '15.877',
              lng: '104.335',
            },
            zipcode: 35000,
          },
        },
      },
      3502: {
        name: {
          th: 'ทรายมูล',
          en: 'Sai Mun',
        },
        tambons: {
          350201: {
            name: {
              th: 'ทรายมูล',
              en: 'Sai Mun',
            },
            coordinates: {
              lat: '15.956',
              lng: '104.224',
            },
            zipcode: null,
          },
          350202: {
            name: {
              th: 'ดู่ลาด',
              en: 'Du Lat',
            },
            coordinates: {
              lat: '16.012',
              lng: '104.208',
            },
            zipcode: 35170,
          },
          350203: {
            name: {
              th: 'ดงมะไฟ',
              en: 'Dong Mafai',
            },
            coordinates: {
              lat: '15.981',
              lng: '104.135',
            },
            zipcode: null,
          },
          350204: {
            name: {
              th: 'นาเวียง',
              en: 'Na Wiang',
            },
            coordinates: {
              lat: '16.070',
              lng: '104.128',
            },
            zipcode: null,
          },
          350205: {
            name: {
              th: 'ไผ่',
              en: 'Phai',
            },
            coordinates: {
              lat: '15.927',
              lng: '104.293',
            },
            zipcode: 35170,
          },
        },
      },
      3503: {
        name: {
          th: 'กุดชุม',
          en: 'Kut Chum',
        },
        tambons: {
          350301: {
            name: {
              th: 'กุดชุม',
              en: 'Kut Chum',
            },
            coordinates: {
              lat: '16.069',
              lng: '104.349',
            },
            zipcode: 35140,
          },
          350302: {
            name: {
              th: 'โนนเปือย',
              en: 'Non Pueai',
            },
            coordinates: {
              lat: '16.011',
              lng: '104.387',
            },
            zipcode: 35140,
          },
          350303: {
            name: {
              th: 'กำแมด',
              en: 'Kammaet',
            },
            coordinates: {
              lat: '15.943',
              lng: '104.379',
            },
            zipcode: 35140,
          },
          350304: {
            name: {
              th: 'นาโส่',
              en: 'Na So',
            },
            coordinates: {
              lat: '15.972',
              lng: '104.317',
            },
            zipcode: 35140,
          },
          350305: {
            name: {
              th: 'ห้วยแก้ง',
              en: 'Huai Kaeng',
            },
            coordinates: {
              lat: '16.007',
              lng: '104.291',
            },
            zipcode: 35140,
          },
          350306: {
            name: {
              th: 'หนองหมี',
              en: 'Nong Mi',
            },
            coordinates: {
              lat: '16.051',
              lng: '104.296',
            },
            zipcode: 35140,
          },
          350307: {
            name: {
              th: 'โพนงาม',
              en: 'Phon Ngam',
            },
            coordinates: {
              lat: '16.045',
              lng: '104.166',
            },
            zipcode: 35140,
          },
          350308: {
            name: {
              th: 'คำน้ำสร้าง',
              en: 'Kham Nam Sang',
            },
            coordinates: {
              lat: '16.099',
              lng: '104.258',
            },
            zipcode: 35140,
          },
          350309: {
            name: {
              th: 'หนองแหน',
              en: 'Nong Nae',
            },
            coordinates: {
              lat: '16.127',
              lng: '104.217',
            },
            zipcode: 35140,
          },
        },
      },
      3504: {
        name: {
          th: 'คำเขื่อนแก้ว',
          en: 'Kham Khuean Kaeo',
        },
        tambons: {
          350401: {
            name: {
              th: 'ลุมพุก',
              en: 'Lumphuk',
            },
            coordinates: {
              lat: '15.643',
              lng: '104.323',
            },
            zipcode: 35110,
          },
          350402: {
            name: {
              th: 'ย่อ',
              en: 'Yo',
            },
            coordinates: {
              lat: '15.693',
              lng: '104.222',
            },
            zipcode: 35110,
          },
          350403: {
            name: {
              th: 'สงเปือย',
              en: 'Song Pueai',
            },
            coordinates: {
              lat: '15.647',
              lng: '104.221',
            },
            zipcode: 35110,
          },
          350404: {
            name: {
              th: 'โพนทัน',
              en: 'Phon Than',
            },
            coordinates: {
              lat: '15.665',
              lng: '104.282',
            },
            zipcode: 35110,
          },
          350405: {
            name: {
              th: 'ทุ่งมน',
              en: 'Thung Mon',
            },
            coordinates: {
              lat: '15.747',
              lng: '104.286',
            },
            zipcode: 35110,
          },
          350406: {
            name: {
              th: 'นาคำ',
              en: 'Na Kham',
            },
            coordinates: {
              lat: '15.633',
              lng: '104.412',
            },
            zipcode: 35180,
          },
          350407: {
            name: {
              th: 'ดงแคนใหญ่',
              en: 'Dong Khaen Yai',
            },
            coordinates: {
              lat: '15.572',
              lng: '104.395',
            },
            zipcode: 35180,
          },
          350408: {
            name: {
              th: 'กู่จาน',
              en: 'Ku Chan',
            },
            coordinates: {
              lat: '15.682',
              lng: '104.399',
            },
            zipcode: 35110,
          },
          350409: {
            name: {
              th: 'นาแก',
              en: 'Na Kae',
            },
            coordinates: {
              lat: '15.597',
              lng: '104.426',
            },
            zipcode: 35180,
          },
          350410: {
            name: {
              th: 'กุดกุง',
              en: 'Kut Kung',
            },
            coordinates: {
              lat: '15.615',
              lng: '104.246',
            },
            zipcode: 35110,
          },
          350411: {
            name: {
              th: 'เหล่าไฮ',
              en: 'Lao Hai',
            },
            coordinates: {
              lat: '15.724',
              lng: '104.382',
            },
            zipcode: 35110,
          },
          350412: {
            name: {
              th: 'แคนน้อย',
              en: 'Khaen Noi',
            },
            coordinates: {
              lat: '15.594',
              lng: '104.347',
            },
            zipcode: 35180,
          },
          350413: {
            name: {
              th: 'ดงเจริญ',
              en: 'Dong Charoen',
            },
            coordinates: {
              lat: '15.698',
              lng: '104.292',
            },
            zipcode: 35110,
          },
        },
      },
      3505: {
        name: {
          th: 'ป่าติ้ว',
          en: 'Pa Tio',
        },
        tambons: {
          350501: {
            name: {
              th: 'โพธิ์ไทร',
              en: 'Pho Sai',
            },
            coordinates: {
              lat: '15.832',
              lng: '104.398',
            },
            zipcode: 35150,
          },
          350502: {
            name: {
              th: 'กระจาย',
              en: 'Krachai',
            },
            coordinates: {
              lat: '15.801',
              lng: '104.299',
            },
            zipcode: 35150,
          },
          350503: {
            name: {
              th: 'โคกนาโก',
              en: 'Khok Na Ko',
            },
            coordinates: {
              lat: '15.925',
              lng: '104.430',
            },
            zipcode: 35150,
          },
          350504: {
            name: {
              th: 'เชียงเพ็ง',
              en: 'Chiang Pheng',
            },
            coordinates: {
              lat: '15.772',
              lng: '104.409',
            },
            zipcode: 35150,
          },
          350505: {
            name: {
              th: 'ศรีฐาน',
              en: 'Si Than',
            },
            coordinates: {
              lat: '15.777',
              lng: '104.343',
            },
            zipcode: 35150,
          },
        },
      },
      3506: {
        name: {
          th: 'มหาชนะชัย',
          en: 'Maha Chana Chai',
        },
        tambons: {
          350601: {
            name: {
              th: 'ฟ้าหยาด',
              en: 'Fa Yat',
            },
            coordinates: {
              lat: '15.514',
              lng: '104.256',
            },
            zipcode: 35130,
          },
          350602: {
            name: {
              th: 'หัวเมือง',
              en: 'Hua Mueang',
            },
            coordinates: {
              lat: '15.566',
              lng: '104.207',
            },
            zipcode: 35130,
          },
          350603: {
            name: {
              th: 'คูเมือง',
              en: 'Khu Mueang',
            },
            coordinates: {
              lat: '15.502',
              lng: '104.184',
            },
            zipcode: 35130,
          },
          350604: {
            name: {
              th: 'ผือฮี',
              en: 'Phue Hi',
            },
            coordinates: {
              lat: '15.450',
              lng: '104.270',
            },
            zipcode: 35130,
          },
          350605: {
            name: {
              th: 'บากเรือ',
              en: 'Bak Ruea',
            },
            coordinates: {
              lat: '15.564',
              lng: '104.278',
            },
            zipcode: 35130,
          },
          350606: {
            name: {
              th: 'ม่วง',
              en: 'Muang',
            },
            coordinates: {
              lat: '15.473',
              lng: '104.324',
            },
            zipcode: 35130,
          },
          350607: {
            name: {
              th: 'โนนทราย',
              en: 'Non Sai',
            },
            coordinates: {
              lat: '15.552',
              lng: '104.329',
            },
            zipcode: 35130,
          },
          350608: {
            name: {
              th: 'บึงแก',
              en: 'Bueng Kae',
            },
            coordinates: {
              lat: '15.511',
              lng: '104.358',
            },
            zipcode: 35130,
          },
          350609: {
            name: {
              th: 'พระเสาร์',
              en: 'Phra Sao',
            },
            coordinates: {
              lat: '15.566',
              lng: '104.153',
            },
            zipcode: 35130,
          },
          350610: {
            name: {
              th: 'สงยาง',
              en: 'Song Yang',
            },
            coordinates: {
              lat: '15.446',
              lng: '104.225',
            },
            zipcode: 35130,
          },
        },
      },
      3507: {
        name: {
          th: 'ค้อวัง',
          en: 'Kho Wang',
        },
        tambons: {
          350701: {
            name: {
              th: 'ฟ้าห่วน',
              en: 'Fa Huan',
            },
            coordinates: {
              lat: '15.339',
              lng: '104.405',
            },
            zipcode: null,
          },
          350702: {
            name: {
              th: 'กุดน้ำใส',
              en: 'Kut Nam Sai',
            },
            coordinates: {
              lat: '15.415',
              lng: '104.353',
            },
            zipcode: null,
          },
          350703: {
            name: {
              th: 'น้ำอ้อม',
              en: 'Nam Om',
            },
            coordinates: {
              lat: '15.397',
              lng: '104.280',
            },
            zipcode: null,
          },
          350704: {
            name: {
              th: 'ค้อวัง',
              en: 'Kho Wang',
            },
            coordinates: {
              lat: '15.363',
              lng: '104.310',
            },
            zipcode: null,
          },
        },
      },
      3508: {
        name: {
          th: 'เลิงนกทา',
          en: 'Loeng Nok Tha',
        },
        tambons: {
          350802: {
            name: {
              th: 'บุ่งค้า',
              en: 'Bung Kha',
            },
            coordinates: {
              lat: '16.176',
              lng: '104.669',
            },
            zipcode: 35120,
          },
          350803: {
            name: {
              th: 'สวาท',
              en: 'Sawat',
            },
            coordinates: {
              lat: '16.153',
              lng: '104.538',
            },
            zipcode: 35120,
          },
          350805: {
            name: {
              th: 'ห้องแซง',
              en: 'Hong Saeng',
            },
            coordinates: {
              lat: '16.269',
              lng: '104.373',
            },
            zipcode: 35120,
          },
          350806: {
            name: {
              th: 'สามัคคี',
              en: 'Samakkhi',
            },
            coordinates: {
              lat: '16.232',
              lng: '104.453',
            },
            zipcode: 35120,
          },
          350807: {
            name: {
              th: 'กุดเชียงหมี',
              en: 'Kut Chiang Mi',
            },
            coordinates: {
              lat: '16.313',
              lng: '104.446',
            },
            zipcode: 35120,
          },
          350810: {
            name: {
              th: 'สามแยก',
              en: 'Sam Yaek',
            },
            coordinates: {
              lat: '16.217',
              lng: '104.562',
            },
            zipcode: 35120,
          },
          350811: {
            name: {
              th: 'กุดแห่',
              en: 'Kut Hae',
            },
            coordinates: {
              lat: '16.275',
              lng: '104.520',
            },
            zipcode: 35120,
          },
          350812: {
            name: {
              th: 'โคกสำราญ',
              en: 'Khok Samran',
            },
            coordinates: {
              lat: '16.149',
              lng: '104.592',
            },
            zipcode: 35120,
          },
          350813: {
            name: {
              th: 'สร้างมิ่ง',
              en: 'Sang Ming',
            },
            coordinates: {
              lat: '16.095',
              lng: '104.506',
            },
            zipcode: 35120,
          },
          350814: {
            name: {
              th: 'ศรีแก้ว',
              en: 'Si Kaeo',
            },
            coordinates: {
              lat: '16.194',
              lng: '104.332',
            },
            zipcode: 35120,
          },
        },
      },
      3509: {
        name: {
          th: 'ไทยเจริญ',
          en: 'Thai Charoen',
        },
        tambons: {
          350901: {
            name: {
              th: 'ไทยเจริญ',
              en: 'Thai Charoen',
            },
            coordinates: {
              lat: '16.060',
              lng: '104.432',
            },
            zipcode: null,
          },
          350902: {
            name: {
              th: 'น้ำคำ',
              en: 'Nam Kham',
            },
            coordinates: {
              lat: '16.015',
              lng: '104.486',
            },
            zipcode: null,
          },
          350903: {
            name: {
              th: 'ส้มผ่อ',
              en: 'Som Pho',
            },
            coordinates: {
              lat: '16.162',
              lng: '104.432',
            },
            zipcode: null,
          },
          350904: {
            name: {
              th: 'คำเตย',
              en: 'Kham Toei',
            },
            coordinates: {
              lat: '16.100',
              lng: '104.388',
            },
            zipcode: null,
          },
          350905: {
            name: {
              th: 'คำไผ่',
              en: 'Kham Phai',
            },
            coordinates: {
              lat: '16.061',
              lng: '104.521',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  36: {
    name: {
      th: 'ชัยภูมิ',
      en: 'Chaiyaphum',
    },
    amphoes: {
      3601: {
        name: {
          th: 'เมืองชัยภูมิ',
          en: 'Mueang Chaiyaphum',
        },
        tambons: {
          360101: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '15.804',
              lng: '102.037',
            },
            zipcode: 36000,
          },
          360102: {
            name: {
              th: 'รอบเมือง',
              en: 'Rop Mueang',
            },
            coordinates: {
              lat: '15.846',
              lng: '102.027',
            },
            zipcode: 36000,
          },
          360103: {
            name: {
              th: 'โพนทอง',
              en: 'Phon Thong',
            },
            coordinates: {
              lat: '15.863',
              lng: '102.114',
            },
            zipcode: 36000,
          },
          360104: {
            name: {
              th: 'นาฝาย',
              en: 'Na Fai',
            },
            coordinates: {
              lat: '15.919',
              lng: '101.982',
            },
            zipcode: 36000,
          },
          360105: {
            name: {
              th: 'บ้านค่าย',
              en: 'Ban Khai',
            },
            coordinates: {
              lat: '15.684',
              lng: '102.028',
            },
            zipcode: 36240,
          },
          360106: {
            name: {
              th: 'กุดตุ้ม',
              en: 'Kut Tum',
            },
            coordinates: {
              lat: '15.771',
              lng: '102.181',
            },
            zipcode: 36000,
          },
          360107: {
            name: {
              th: 'ชีลอง',
              en: 'Chilong',
            },
            coordinates: {
              lat: '15.730',
              lng: '101.999',
            },
            zipcode: 36000,
          },
          360108: {
            name: {
              th: 'บ้านเล่า',
              en: 'Ban Lao',
            },
            coordinates: {
              lat: '15.872',
              lng: '102.061',
            },
            zipcode: 36000,
          },
          360109: {
            name: {
              th: 'นาเสียว',
              en: 'Na Siao',
            },
            coordinates: {
              lat: '15.941',
              lng: '102.110',
            },
            zipcode: 36000,
          },
          360110: {
            name: {
              th: 'หนองนาแซง',
              en: 'Nong Na Saeng',
            },
            coordinates: {
              lat: '15.742',
              lng: '102.022',
            },
            zipcode: 36000,
          },
          360111: {
            name: {
              th: 'ลาดใหญ่',
              en: 'Lat Yai',
            },
            coordinates: {
              lat: '15.809',
              lng: '102.189',
            },
            zipcode: 36000,
          },
          360112: {
            name: {
              th: 'หนองไผ่',
              en: 'Nong Phai',
            },
            coordinates: {
              lat: '15.707',
              lng: '102.052',
            },
            zipcode: 36150,
          },
          360113: {
            name: {
              th: 'ท่าหินโงม',
              en: 'Tha Hin Ngom',
            },
            coordinates: {
              lat: '16.021',
              lng: '101.993',
            },
            zipcode: 36000,
          },
          360114: {
            name: {
              th: 'ห้วยต้อน',
              en: 'Huai Ton',
            },
            coordinates: {
              lat: '15.930',
              lng: '101.919',
            },
            zipcode: 36000,
          },
          360115: {
            name: {
              th: 'ห้วยบง',
              en: 'Huai Bong',
            },
            coordinates: {
              lat: '15.843',
              lng: '102.167',
            },
            zipcode: 36000,
          },
          360116: {
            name: {
              th: 'โนนสำราญ',
              en: 'Non Samran',
            },
            coordinates: {
              lat: '15.661',
              lng: '102.093',
            },
            zipcode: 36240,
          },
          360117: {
            name: {
              th: 'โคกสูง',
              en: 'Khok Sung',
            },
            coordinates: {
              lat: '15.827',
              lng: '101.958',
            },
            zipcode: 36000,
          },
          360118: {
            name: {
              th: 'บุ่งคล้า',
              en: 'Bung Khla',
            },
            coordinates: {
              lat: '15.759',
              lng: '102.056',
            },
            zipcode: 36000,
          },
          360119: {
            name: {
              th: 'ซับสีทอง',
              en: 'Sap Si Thong',
            },
            coordinates: {
              lat: '16.137',
              lng: '102.017',
            },
            zipcode: 36000,
          },
        },
      },
      3602: {
        name: {
          th: 'บ้านเขว้า',
          en: 'Ban Khwao',
        },
        tambons: {
          360201: {
            name: {
              th: 'บ้านเขว้า',
              en: 'Ban Khwao',
            },
            coordinates: {
              lat: '15.753',
              lng: '101.911',
            },
            zipcode: 36170,
          },
          360202: {
            name: {
              th: 'ตลาดแร้ง',
              en: 'Talat Raeng',
            },
            coordinates: {
              lat: '15.778',
              lng: '101.861',
            },
            zipcode: 36170,
          },
          360203: {
            name: {
              th: 'ลุ่มลำชี',
              en: 'Lum Lam Chi',
            },
            coordinates: {
              lat: '15.697',
              lng: '101.932',
            },
            zipcode: 36170,
          },
          360204: {
            name: {
              th: 'ชีบน',
              en: 'Chi Bon',
            },
            coordinates: {
              lat: '15.866',
              lng: '101.759',
            },
            zipcode: 36170,
          },
          360205: {
            name: {
              th: 'ภูแลนคา',
              en: 'Phu Laen Kha',
            },
            coordinates: {
              lat: '15.876',
              lng: '101.840',
            },
            zipcode: 36170,
          },
          360206: {
            name: {
              th: 'โนนแดง',
              en: 'Non Daeng',
            },
            coordinates: {
              lat: '15.804',
              lng: '101.930',
            },
            zipcode: 36170,
          },
        },
      },
      3603: {
        name: {
          th: 'คอนสวรรค์',
          en: 'Khon Sawan',
        },
        tambons: {
          360301: {
            name: {
              th: 'คอนสวรรค์',
              en: 'Khon Sawan',
            },
            coordinates: {
              lat: '15.930',
              lng: '102.327',
            },
            zipcode: 36140,
          },
          360302: {
            name: {
              th: 'ยางหวาย',
              en: 'Yang Wai',
            },
            coordinates: {
              lat: '15.887',
              lng: '102.310',
            },
            zipcode: 36140,
          },
          360303: {
            name: {
              th: 'ช่องสามหมอ',
              en: 'Chong Sam Mo',
            },
            coordinates: {
              lat: '16.018',
              lng: '102.292',
            },
            zipcode: 36150,
          },
          360304: {
            name: {
              th: 'โนนสะอาด',
              en: 'Non Sa-At',
            },
            coordinates: {
              lat: '15.816',
              lng: '102.275',
            },
            zipcode: 36140,
          },
          360305: {
            name: {
              th: 'ห้วยไร่',
              en: 'Huai Rai',
            },
            coordinates: {
              lat: '16.021',
              lng: '102.247',
            },
            zipcode: 36140,
          },
          360306: {
            name: {
              th: 'บ้านโสก',
              en: 'Ban Sok',
            },
            coordinates: {
              lat: '15.892',
              lng: '102.263',
            },
            zipcode: 36140,
          },
          360307: {
            name: {
              th: 'โคกมั่งงอย',
              en: 'Khok Mang Ngoi',
            },
            coordinates: {
              lat: '15.955',
              lng: '102.259',
            },
            zipcode: 36140,
          },
          360308: {
            name: {
              th: 'หนองขาม',
              en: 'Nong Kham',
            },
            coordinates: {
              lat: '15.853',
              lng: '102.234',
            },
            zipcode: 36150,
          },
          360309: {
            name: {
              th: 'ศรีสำราญ',
              en: 'Si Samran',
            },
            coordinates: {
              lat: '15.993',
              lng: '102.339',
            },
            zipcode: 36140,
          },
        },
      },
      3604: {
        name: {
          th: 'เกษตรสมบูรณ์',
          en: 'Kaset Sombun',
        },
        tambons: {
          360401: {
            name: {
              th: 'บ้านยาง',
              en: 'Ban Yang',
            },
            coordinates: {
              lat: '16.286',
              lng: '101.869',
            },
            zipcode: 36120,
          },
          360402: {
            name: {
              th: 'บ้านหัน',
              en: 'Ban Han',
            },
            coordinates: {
              lat: '16.307',
              lng: '102.027',
            },
            zipcode: 36120,
          },
          360403: {
            name: {
              th: 'บ้านเดื่อ',
              en: 'Ban Duea',
            },
            coordinates: {
              lat: '16.089',
              lng: '101.908',
            },
            zipcode: 36120,
          },
          360404: {
            name: {
              th: 'บ้านเป้า',
              en: 'Ban Pao',
            },
            coordinates: {
              lat: '16.372',
              lng: '101.951',
            },
            zipcode: 36120,
          },
          360405: {
            name: {
              th: 'กุดเลาะ',
              en: 'Kut Lo',
            },
            coordinates: {
              lat: '16.309',
              lng: '101.943',
            },
            zipcode: 36120,
          },
          360406: {
            name: {
              th: 'โนนกอก',
              en: 'Non Kok',
            },
            coordinates: {
              lat: '16.171',
              lng: '101.949',
            },
            zipcode: 36120,
          },
          360407: {
            name: {
              th: 'สระโพนทอง',
              en: 'Sa Phon Thong',
            },
            coordinates: {
              lat: '16.246',
              lng: '101.984',
            },
            zipcode: 36120,
          },
          360408: {
            name: {
              th: 'หนองข่า',
              en: 'Nong Kha',
            },
            coordinates: {
              lat: '16.169',
              lng: '101.869',
            },
            zipcode: 36120,
          },
          360409: {
            name: {
              th: 'หนองโพนงาม',
              en: 'Nong Phon Ngam',
            },
            coordinates: {
              lat: '16.434',
              lng: '101.877',
            },
            zipcode: 36120,
          },
          360410: {
            name: {
              th: 'บ้านบัว',
              en: 'Ban Bua',
            },
            coordinates: {
              lat: '16.242',
              lng: '101.874',
            },
            zipcode: 36120,
          },
          360412: {
            name: {
              th: 'โนนทอง',
              en: 'Non Thong',
            },
            coordinates: {
              lat: '16.379',
              lng: '101.797',
            },
            zipcode: 36120,
          },
        },
      },
      3605: {
        name: {
          th: 'หนองบัวแดง',
          en: 'Nong Bua Daeng',
        },
        tambons: {
          360501: {
            name: {
              th: 'หนองบัวแดง',
              en: 'Nong Bua Daeng',
            },
            coordinates: {
              lat: '16.351',
              lng: '101.721',
            },
            zipcode: 36210,
          },
          360502: {
            name: {
              th: 'กุดชุมแสง',
              en: 'Kut Chum Saeng',
            },
            coordinates: {
              lat: '16.012',
              lng: '101.834',
            },
            zipcode: 36210,
          },
          360503: {
            name: {
              th: 'ถ้ำวัวแดง',
              en: 'Tham Wua Daeng',
            },
            coordinates: {
              lat: '16.161',
              lng: '101.440',
            },
            zipcode: 36210,
          },
          360504: {
            name: {
              th: 'นางแดด',
              en: 'Nang Daet',
            },
            coordinates: {
              lat: '16.280',
              lng: '101.518',
            },
            zipcode: 36210,
          },
          360507: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '16.177',
              lng: '101.722',
            },
            zipcode: 36210,
          },
          360508: {
            name: {
              th: 'คูเมือง',
              en: 'Khu Mueang',
            },
            coordinates: {
              lat: '16.012',
              lng: '101.723',
            },
            zipcode: 36210,
          },
          360509: {
            name: {
              th: 'ท่าใหญ่',
              en: 'Tha Yai',
            },
            coordinates: {
              lat: '16.028',
              lng: '101.624',
            },
            zipcode: 36210,
          },
          360511: {
            name: {
              th: 'วังชมภู',
              en: 'Wang Chomphu',
            },
            coordinates: {
              lat: '16.119',
              lng: '101.578',
            },
            zipcode: 36210,
          },
        },
      },
      3606: {
        name: {
          th: 'จัตุรัส',
          en: 'Chatturat',
        },
        tambons: {
          360601: {
            name: {
              th: 'บ้านกอก',
              en: 'Ban Kok',
            },
            coordinates: {
              lat: '15.467',
              lng: '101.902',
            },
            zipcode: 36130,
          },
          360602: {
            name: {
              th: 'หนองบัวบาน',
              en: 'Nong Bua Ban',
            },
            coordinates: {
              lat: '15.652',
              lng: '101.802',
            },
            zipcode: 36130,
          },
          360603: {
            name: {
              th: 'บ้านขาม',
              en: 'Ban Kham',
            },
            coordinates: {
              lat: '15.474',
              lng: '101.805',
            },
            zipcode: 36130,
          },
          360605: {
            name: {
              th: 'กุดน้ำใส',
              en: 'Kut Nam Sai',
            },
            coordinates: {
              lat: '15.596',
              lng: '101.794',
            },
            zipcode: 36130,
          },
          360606: {
            name: {
              th: 'หนองโดน',
              en: 'Nong Don',
            },
            coordinates: {
              lat: '15.567',
              lng: '101.736',
            },
            zipcode: 36130,
          },
          360607: {
            name: {
              th: 'ละหาน',
              en: 'Lahan',
            },
            coordinates: {
              lat: '15.629',
              lng: '101.938',
            },
            zipcode: 36130,
          },
          360610: {
            name: {
              th: 'หนองบัวใหญ่',
              en: 'Nong Bua Yai',
            },
            coordinates: {
              lat: '15.561',
              lng: '101.888',
            },
            zipcode: 36130,
          },
          360611: {
            name: {
              th: 'หนองบัวโคก',
              en: 'Nong Bua Khok',
            },
            coordinates: {
              lat: '15.448',
              lng: '101.854',
            },
            zipcode: 36220,
          },
          360613: {
            name: {
              th: 'ส้มป่อย',
              en: 'Som Poi',
            },
            coordinates: {
              lat: '15.711',
              lng: '101.806',
            },
            zipcode: 36130,
          },
        },
      },
      3607: {
        name: {
          th: 'บำเหน็จณรงค์',
          en: 'Bamnet Narong',
        },
        tambons: {
          360701: {
            name: {
              th: 'บ้านชวน',
              en: 'Ban Chuan',
            },
            coordinates: {
              lat: '15.518',
              lng: '101.653',
            },
            zipcode: 36160,
          },
          360702: {
            name: {
              th: 'บ้านเพชร',
              en: 'Ban Phet',
            },
            coordinates: {
              lat: '15.434',
              lng: '101.697',
            },
            zipcode: 36110,
          },
          360703: {
            name: {
              th: 'บ้านตาล',
              en: 'Ban Tan',
            },
            coordinates: {
              lat: '15.484',
              lng: '101.756',
            },
            zipcode: 36220,
          },
          360704: {
            name: {
              th: 'หัวทะเล',
              en: 'Hua Thale',
            },
            coordinates: {
              lat: '15.424',
              lng: '101.757',
            },
            zipcode: 36220,
          },
          360705: {
            name: {
              th: 'โคกเริงรมย์',
              en: 'Khok Roeng Rom',
            },
            coordinates: {
              lat: '15.391',
              lng: '101.576',
            },
            zipcode: 36160,
          },
          360706: {
            name: {
              th: 'เกาะมะนาว',
              en: 'Ko Manao',
            },
            coordinates: {
              lat: '15.504',
              lng: '101.610',
            },
            zipcode: 36160,
          },
          360707: {
            name: {
              th: 'โคกเพชรพัฒนา',
              en: 'Khok Phet Phatthana',
            },
            coordinates: {
              lat: '15.471',
              lng: '101.578',
            },
            zipcode: 36160,
          },
        },
      },
      3608: {
        name: {
          th: 'หนองบัวระเหว',
          en: 'Nong Bua Rawe',
        },
        tambons: {
          360801: {
            name: {
              th: 'หนองบัวระเหว',
              en: 'Nong Bua Rawe',
            },
            coordinates: {
              lat: '15.757',
              lng: '101.786',
            },
            zipcode: 36250,
          },
          360802: {
            name: {
              th: 'วังตะเฆ่',
              en: 'Wang Takhe',
            },
            coordinates: {
              lat: '15.830',
              lng: '101.583',
            },
            zipcode: 36250,
          },
          360803: {
            name: {
              th: 'ห้วยแย้',
              en: 'Huai Yae',
            },
            coordinates: {
              lat: '15.921',
              lng: '101.640',
            },
            zipcode: 36250,
          },
          360804: {
            name: {
              th: 'โคกสะอาด',
              en: 'Khok Sa-At',
            },
            coordinates: {
              lat: '15.778',
              lng: '101.699',
            },
            zipcode: 36250,
          },
          360805: {
            name: {
              th: 'โสกปลาดุก',
              en: 'Sok Pla Duk',
            },
            coordinates: {
              lat: '15.718',
              lng: '101.695',
            },
            zipcode: 36250,
          },
        },
      },
      3609: {
        name: {
          th: 'เทพสถิต',
          en: 'Thep Sathit',
        },
        tambons: {
          360901: {
            name: {
              th: 'วะตะแบก',
              en: 'Wa Tabaek',
            },
            coordinates: {
              lat: '15.426',
              lng: '101.436',
            },
            zipcode: null,
          },
          360902: {
            name: {
              th: 'ห้วยยายจิ๋ว',
              en: 'Huai Yai Chio',
            },
            coordinates: {
              lat: '15.432',
              lng: '101.547',
            },
            zipcode: null,
          },
          360903: {
            name: {
              th: 'นายางกลัก',
              en: 'Na Yang Klak',
            },
            coordinates: {
              lat: '15.676',
              lng: '101.517',
            },
            zipcode: null,
          },
          360904: {
            name: {
              th: 'บ้านไร่',
              en: 'Ban Rai',
            },
            coordinates: {
              lat: '15.595',
              lng: '101.456',
            },
            zipcode: null,
          },
          360905: {
            name: {
              th: 'โป่งนก',
              en: 'Pong Nok',
            },
            coordinates: {
              lat: '15.808',
              lng: '101.469',
            },
            zipcode: 36230,
          },
        },
      },
      3610: {
        name: {
          th: 'ภูเขียว',
          en: 'Phu Khiao',
        },
        tambons: {
          361001: {
            name: {
              th: 'ผักปัง',
              en: 'Phak Pang',
            },
            coordinates: {
              lat: '16.357',
              lng: '102.136',
            },
            zipcode: 36110,
          },
          361002: {
            name: {
              th: 'กวางโจน',
              en: 'Kwang Chon',
            },
            coordinates: {
              lat: '16.282',
              lng: '102.232',
            },
            zipcode: 36110,
          },
          361003: {
            name: {
              th: 'หนองคอนไทย',
              en: 'Nong Khon Thai',
            },
            coordinates: {
              lat: '16.461',
              lng: '102.181',
            },
            zipcode: 36110,
          },
          361004: {
            name: {
              th: 'บ้านแก้ง',
              en: 'Ban Kaeng',
            },
            coordinates: {
              lat: '16.420',
              lng: '102.049',
            },
            zipcode: 36150,
          },
          361005: {
            name: {
              th: 'กุดยม',
              en: 'Kut Yom',
            },
            coordinates: {
              lat: '16.417',
              lng: '102.202',
            },
            zipcode: 36110,
          },
          361006: {
            name: {
              th: 'บ้านเพชร',
              en: 'Ban Phet',
            },
            coordinates: {
              lat: '16.344',
              lng: '102.256',
            },
            zipcode: null,
          },
          361007: {
            name: {
              th: 'โคกสะอาด',
              en: 'Khok Sa-At',
            },
            coordinates: {
              lat: '16.475',
              lng: '102.125',
            },
            zipcode: null,
          },
          361008: {
            name: {
              th: 'หนองตูม',
              en: 'Nong Tum',
            },
            coordinates: {
              lat: '16.325',
              lng: '102.092',
            },
            zipcode: 36110,
          },
          361009: {
            name: {
              th: 'โอโล',
              en: 'O Lo',
            },
            coordinates: {
              lat: '16.346',
              lng: '102.184',
            },
            zipcode: 36110,
          },
          361010: {
            name: {
              th: 'ธาตุทอง',
              en: 'That Thong',
            },
            coordinates: {
              lat: '16.235',
              lng: '102.160',
            },
            zipcode: 36110,
          },
          361011: {
            name: {
              th: 'บ้านดอน',
              en: 'Ban Don',
            },
            coordinates: {
              lat: '16.305',
              lng: '102.304',
            },
            zipcode: 36110,
          },
        },
      },
      3611: {
        name: {
          th: 'บ้านแท่น',
          en: 'Ban Thaen',
        },
        tambons: {
          361101: {
            name: {
              th: 'บ้านแท่น',
              en: 'Ban Thaen',
            },
            coordinates: {
              lat: '16.405',
              lng: '102.349',
            },
            zipcode: 36190,
          },
          361102: {
            name: {
              th: 'สามสวน',
              en: 'Sam Suan',
            },
            coordinates: {
              lat: '16.419',
              lng: '102.270',
            },
            zipcode: 36190,
          },
          361103: {
            name: {
              th: 'สระพัง',
              en: 'Sa Phang',
            },
            coordinates: {
              lat: '16.401',
              lng: '102.418',
            },
            zipcode: 36190,
          },
          361104: {
            name: {
              th: 'บ้านเต่า',
              en: 'Ban Tao',
            },
            coordinates: {
              lat: '16.350',
              lng: '102.387',
            },
            zipcode: 36190,
          },
          361105: {
            name: {
              th: 'หนองคู',
              en: 'Nong Khu',
            },
            coordinates: {
              lat: '16.293',
              lng: '102.398',
            },
            zipcode: 36190,
          },
        },
      },
      3612: {
        name: {
          th: 'แก้งคร้อ',
          en: 'Kaeng Khro',
        },
        tambons: {
          361201: {
            name: {
              th: 'ช่องสามหมอ',
              en: 'Chong Sam Mo',
            },
            coordinates: {
              lat: '16.107',
              lng: '102.236',
            },
            zipcode: null,
          },
          361202: {
            name: {
              th: 'หนองขาม',
              en: 'Nong Kham',
            },
            coordinates: {
              lat: '16.164',
              lng: '102.194',
            },
            zipcode: null,
          },
          361203: {
            name: {
              th: 'นาหนองทุ่ม',
              en: 'Na Nong Thum',
            },
            coordinates: {
              lat: '16.032',
              lng: '102.164',
            },
            zipcode: 36150,
          },
          361204: {
            name: {
              th: 'บ้านแก้ง',
              en: 'Ban Kaeng',
            },
            coordinates: {
              lat: '16.172',
              lng: '102.325',
            },
            zipcode: null,
          },
          361205: {
            name: {
              th: 'หนองสังข์',
              en: 'Nong Sang',
            },
            coordinates: {
              lat: '16.242',
              lng: '102.382',
            },
            zipcode: 36150,
          },
          361206: {
            name: {
              th: 'หลุบคา',
              en: 'Lup Kha',
            },
            coordinates: {
              lat: '16.202',
              lng: '102.259',
            },
            zipcode: 36150,
          },
          361207: {
            name: {
              th: 'โคกกุง',
              en: 'Khok Kung',
            },
            coordinates: {
              lat: '16.111',
              lng: '102.154',
            },
            zipcode: 36150,
          },
          361208: {
            name: {
              th: 'เก่าย่าดี',
              en: 'Kao Ya Di',
            },
            coordinates: {
              lat: '16.056',
              lng: '102.074',
            },
            zipcode: 36150,
          },
          361209: {
            name: {
              th: 'ท่ามะไฟหวาน',
              en: 'Tha Mafai Wan',
            },
            coordinates: {
              lat: '16.155',
              lng: '102.097',
            },
            zipcode: 36150,
          },
          361210: {
            name: {
              th: 'หนองไผ่',
              en: 'Nong Phai',
            },
            coordinates: {
              lat: '16.130',
              lng: '102.273',
            },
            zipcode: null,
          },
        },
      },
      3613: {
        name: {
          th: 'คอนสาร',
          en: 'Khon San',
        },
        tambons: {
          361301: {
            name: {
              th: 'คอนสาร',
              en: 'Khon San',
            },
            coordinates: {
              lat: '16.610',
              lng: '101.921',
            },
            zipcode: 36180,
          },
          361302: {
            name: {
              th: 'ทุ่งพระ',
              en: 'Thung Phra',
            },
            coordinates: {
              lat: '16.649',
              lng: '101.642',
            },
            zipcode: 36180,
          },
          361303: {
            name: {
              th: 'โนนคูณ',
              en: 'Non Khun',
            },
            coordinates: {
              lat: '16.485',
              lng: '101.981',
            },
            zipcode: 36180,
          },
          361304: {
            name: {
              th: 'ห้วยยาง',
              en: 'Huai Yang',
            },
            coordinates: {
              lat: '16.536',
              lng: '101.896',
            },
            zipcode: 36180,
          },
          361305: {
            name: {
              th: 'ทุ่งลุยลาย',
              en: 'Thung Lui Lai',
            },
            coordinates: {
              lat: '16.522',
              lng: '101.634',
            },
            zipcode: 36180,
          },
          361306: {
            name: {
              th: 'ดงบัง',
              en: 'Dong Bang',
            },
            coordinates: {
              lat: '16.587',
              lng: '101.955',
            },
            zipcode: 36180,
          },
          361307: {
            name: {
              th: 'ทุ่งนาเลา',
              en: 'Thung Na Lao',
            },
            coordinates: {
              lat: '16.590',
              lng: '101.864',
            },
            zipcode: 36180,
          },
          361308: {
            name: {
              th: 'ดงกลาง',
              en: 'Dong Klang',
            },
            coordinates: {
              lat: '16.533',
              lng: '101.983',
            },
            zipcode: 36180,
          },
        },
      },
      3614: {
        name: {
          th: 'ภักดีชุมพล',
          en: 'Phakdi Chumphon',
        },
        tambons: {
          361401: {
            name: {
              th: 'บ้านเจียง',
              en: 'Ban Chiang',
            },
            coordinates: {
              lat: '16.131',
              lng: '101.375',
            },
            zipcode: null,
          },
          361402: {
            name: {
              th: 'เจาทอง',
              en: 'Chao Thong',
            },
            coordinates: {
              lat: '15.888',
              lng: '101.397',
            },
            zipcode: null,
          },
          361403: {
            name: {
              th: 'วังทอง',
              en: 'Wang Thong',
            },
            coordinates: {
              lat: '15.793',
              lng: '101.372',
            },
            zipcode: null,
          },
          361404: {
            name: {
              th: 'แหลมทอง',
              en: 'Laem Thong',
            },
            coordinates: {
              lat: '16.014',
              lng: '101.379',
            },
            zipcode: 36260,
          },
        },
      },
      3615: {
        name: {
          th: 'เนินสง่า',
          en: 'Noen Sa-nga',
        },
        tambons: {
          361501: {
            name: {
              th: 'หนองฉิม',
              en: 'Nong Chim',
            },
            coordinates: {
              lat: '15.559',
              lng: '101.968',
            },
            zipcode: null,
          },
          361502: {
            name: {
              th: 'ตาเนิน',
              en: 'Ta Noen',
            },
            coordinates: {
              lat: '15.527',
              lng: '102.016',
            },
            zipcode: null,
          },
          361503: {
            name: {
              th: 'กะฮาด',
              en: 'Kahat',
            },
            coordinates: {
              lat: '15.632',
              lng: '102.026',
            },
            zipcode: null,
          },
          361504: {
            name: {
              th: 'รังงาม',
              en: 'Rang Ngam',
            },
            coordinates: {
              lat: '15.487',
              lng: '101.964',
            },
            zipcode: null,
          },
        },
      },
      3616: {
        name: {
          th: 'ซับใหญ่',
          en: 'Sap Yai',
        },
        tambons: {
          361601: {
            name: {
              th: 'ซับใหญ่',
              en: 'Sap Yai',
            },
            coordinates: {
              lat: '15.602',
              lng: '101.573',
            },
            zipcode: null,
          },
          361602: {
            name: {
              th: 'ท่ากูบ',
              en: 'Tha Kup',
            },
            coordinates: {
              lat: '15.623',
              lng: '101.708',
            },
            zipcode: null,
          },
          361603: {
            name: {
              th: 'ตะโกทอง',
              en: 'Tako Thong',
            },
            coordinates: {
              lat: '15.565',
              lng: '101.670',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  37: {
    name: {
      th: 'อำนาจเจริญ',
      en: 'Amnat Charoen',
    },
    amphoes: {
      3701: {
        name: {
          th: 'เมืองอำนาจเจริญ',
          en: 'Mueang Amnat Charoen',
        },
        tambons: {
          370101: {
            name: {
              th: 'บุ่ง',
              en: 'Bung',
            },
            coordinates: {
              lat: '15.872',
              lng: '104.635',
            },
            zipcode: 37000,
          },
          370102: {
            name: {
              th: 'ไก่คำ',
              en: 'Kai Kham',
            },
            coordinates: {
              lat: '15.804',
              lng: '104.640',
            },
            zipcode: 37000,
          },
          370103: {
            name: {
              th: 'นาจิก',
              en: 'Na Chik',
            },
            coordinates: {
              lat: '15.787',
              lng: '104.564',
            },
            zipcode: 37000,
          },
          370104: {
            name: {
              th: 'ปลาค้าว',
              en: 'Pla Khao',
            },
            coordinates: {
              lat: '15.785',
              lng: '104.790',
            },
            zipcode: 37000,
          },
          370105: {
            name: {
              th: 'เหล่าพรวน',
              en: 'Lao Phruan',
            },
            coordinates: {
              lat: '15.867',
              lng: '104.794',
            },
            zipcode: 37000,
          },
          370106: {
            name: {
              th: 'สร้างนกทา',
              en: 'Sang Nok Tha',
            },
            coordinates: {
              lat: '15.841',
              lng: '104.750',
            },
            zipcode: 37000,
          },
          370107: {
            name: {
              th: 'คึมใหญ่',
              en: 'Khuem Yai',
            },
            coordinates: {
              lat: '15.948',
              lng: '104.751',
            },
            zipcode: 37000,
          },
          370108: {
            name: {
              th: 'นาผือ',
              en: 'Na Phue',
            },
            coordinates: {
              lat: '15.982',
              lng: '104.626',
            },
            zipcode: 37000,
          },
          370109: {
            name: {
              th: 'น้ำปลีก',
              en: 'Nam Plik',
            },
            coordinates: {
              lat: '15.811',
              lng: '104.460',
            },
            zipcode: 37000,
          },
          370110: {
            name: {
              th: 'นาวัง',
              en: 'Na Wang',
            },
            coordinates: {
              lat: '15.997',
              lng: '104.579',
            },
            zipcode: 37000,
          },
          370111: {
            name: {
              th: 'นาหมอม้า',
              en: 'Na Mo Ma',
            },
            coordinates: {
              lat: '15.885',
              lng: '104.478',
            },
            zipcode: 37000,
          },
          370112: {
            name: {
              th: 'โนนโพธิ์',
              en: 'Non Pho',
            },
            coordinates: {
              lat: '15.873',
              lng: '104.558',
            },
            zipcode: 37000,
          },
          370113: {
            name: {
              th: 'โนนหนามแท่ง',
              en: 'Non Nam Thaeng',
            },
            coordinates: {
              lat: '15.929',
              lng: '104.631',
            },
            zipcode: 37000,
          },
          370114: {
            name: {
              th: 'ห้วยไร่',
              en: 'Huai Rai',
            },
            coordinates: {
              lat: '15.851',
              lng: '104.689',
            },
            zipcode: 37000,
          },
          370115: {
            name: {
              th: 'หนองมะแซว',
              en: 'Nong Masaeo',
            },
            coordinates: {
              lat: '15.774',
              lng: '104.746',
            },
            zipcode: 37000,
          },
          370116: {
            name: {
              th: 'กุดปลาดุก',
              en: 'Kut Pla Duk',
            },
            coordinates: {
              lat: '15.945',
              lng: '104.525',
            },
            zipcode: 37000,
          },
          370117: {
            name: {
              th: 'ดอนเมย',
              en: 'Don Moei',
            },
            coordinates: {
              lat: '15.823',
              lng: '104.562',
            },
            zipcode: 37000,
          },
          370118: {
            name: {
              th: 'นายม',
              en: 'Na Yom',
            },
            coordinates: {
              lat: '15.878',
              lng: '104.513',
            },
            zipcode: 37000,
          },
          370119: {
            name: {
              th: 'นาแต้',
              en: 'Na Tae',
            },
            coordinates: {
              lat: '15.954',
              lng: '104.692',
            },
            zipcode: 37000,
          },
        },
      },
      3702: {
        name: {
          th: 'ชานุมาน',
          en: 'Chanuman',
        },
        tambons: {
          370201: {
            name: {
              th: 'ชานุมาน',
              en: 'Chanuman',
            },
            coordinates: {
              lat: '16.232',
              lng: '104.944',
            },
            zipcode: 37210,
          },
          370202: {
            name: {
              th: 'โคกสาร',
              en: 'Khok San',
            },
            coordinates: {
              lat: '16.156',
              lng: '104.996',
            },
            zipcode: 37210,
          },
          370203: {
            name: {
              th: 'คำเขื่อนแก้ว',
              en: 'Kham Khuean Kaeo',
            },
            coordinates: {
              lat: '16.150',
              lng: '104.922',
            },
            zipcode: 37210,
          },
          370204: {
            name: {
              th: 'โคกก่ง',
              en: 'Khok Kong',
            },
            coordinates: {
              lat: '16.066',
              lng: '104.970',
            },
            zipcode: 37210,
          },
          370205: {
            name: {
              th: 'ป่าก่อ',
              en: 'Pa Ko',
            },
            coordinates: {
              lat: '16.046',
              lng: '104.860',
            },
            zipcode: 37210,
          },
        },
      },
      3703: {
        name: {
          th: 'ปทุมราชวงศา',
          en: 'Pathum Ratchawongsa',
        },
        tambons: {
          370301: {
            name: {
              th: 'หนองข่า',
              en: 'Nong Kha',
            },
            coordinates: {
              lat: '15.973',
              lng: '104.892',
            },
            zipcode: null,
          },
          370302: {
            name: {
              th: 'คำโพน',
              en: 'Kham Phon',
            },
            coordinates: {
              lat: '15.968',
              lng: '104.967',
            },
            zipcode: 37110,
          },
          370303: {
            name: {
              th: 'นาหว้า',
              en: 'Na Wa',
            },
            coordinates: {
              lat: '15.897',
              lng: '104.918',
            },
            zipcode: 37110,
          },
          370304: {
            name: {
              th: 'ลือ',
              en: 'Lue',
            },
            coordinates: {
              lat: '15.796',
              lng: '104.868',
            },
            zipcode: 37110,
          },
          370305: {
            name: {
              th: 'ห้วย',
              en: 'Huai',
            },
            coordinates: {
              lat: '15.830',
              lng: '104.948',
            },
            zipcode: 37110,
          },
          370306: {
            name: {
              th: 'โนนงาม',
              en: 'Non Ngam',
            },
            coordinates: {
              lat: '15.862',
              lng: '104.828',
            },
            zipcode: 37110,
          },
          370307: {
            name: {
              th: 'นาป่าแซง',
              en: 'Na Pa Saeng',
            },
            coordinates: {
              lat: '15.872',
              lng: '104.876',
            },
            zipcode: 37110,
          },
        },
      },
      3704: {
        name: {
          th: 'พนา',
          en: 'Phana',
        },
        tambons: {
          370401: {
            name: {
              th: 'พนา',
              en: 'Phana',
            },
            coordinates: {
              lat: '15.678',
              lng: '104.882',
            },
            zipcode: 37180,
          },
          370402: {
            name: {
              th: 'จานลาน',
              en: 'Chan Lan',
            },
            coordinates: {
              lat: '15.619',
              lng: '104.901',
            },
            zipcode: 37180,
          },
          370403: {
            name: {
              th: 'ไม้กลอน',
              en: 'Mai Klon',
            },
            coordinates: {
              lat: '15.731',
              lng: '104.818',
            },
            zipcode: 37180,
          },
          370404: {
            name: {
              th: 'พระเหลา',
              en: 'Phra Lao',
            },
            coordinates: {
              lat: '15.737',
              lng: '104.882',
            },
            zipcode: 37180,
          },
        },
      },
      3705: {
        name: {
          th: 'เสนางคนิคม',
          en: 'Senangkhanikhom',
        },
        tambons: {
          370501: {
            name: {
              th: 'เสนางคนิคม',
              en: 'Senangkha Nikhom',
            },
            coordinates: {
              lat: '16.056',
              lng: '104.685',
            },
            zipcode: null,
          },
          370502: {
            name: {
              th: 'โพนทอง',
              en: 'Phon Thong',
            },
            coordinates: {
              lat: '16.069',
              lng: '104.764',
            },
            zipcode: null,
          },
          370503: {
            name: {
              th: 'ไร่สีสุก',
              en: 'Rai Si Suk',
            },
            coordinates: {
              lat: '16.092',
              lng: '104.639',
            },
            zipcode: null,
          },
          370504: {
            name: {
              th: 'นาเวียง',
              en: 'Na Wiang',
            },
            coordinates: {
              lat: '16.029',
              lng: '104.592',
            },
            zipcode: null,
          },
          370505: {
            name: {
              th: 'หนองไฮ',
              en: 'Nong Hai',
            },
            coordinates: {
              lat: '16.022',
              lng: '104.787',
            },
            zipcode: null,
          },
          370506: {
            name: {
              th: 'หนองสามสี',
              en: 'Nong Sam Si',
            },
            coordinates: {
              lat: '16.050',
              lng: '104.616',
            },
            zipcode: 37290,
          },
        },
      },
      3706: {
        name: {
          th: 'หัวตะพาน',
          en: 'Hua Taphan',
        },
        tambons: {
          370601: {
            name: {
              th: 'หัวตะพาน',
              en: 'Hua Taphan',
            },
            coordinates: {
              lat: '15.718',
              lng: '104.457',
            },
            zipcode: 37240,
          },
          370602: {
            name: {
              th: 'คำพระ',
              en: 'Kham Phra',
            },
            coordinates: {
              lat: '15.761',
              lng: '104.518',
            },
            zipcode: 37240,
          },
          370603: {
            name: {
              th: 'เค็งใหญ่',
              en: 'Kheng Yai',
            },
            coordinates: {
              lat: '15.738',
              lng: '104.564',
            },
            zipcode: 37240,
          },
          370604: {
            name: {
              th: 'หนองแก้ว',
              en: 'Nong Kaeo',
            },
            coordinates: {
              lat: '15.696',
              lng: '104.531',
            },
            zipcode: 37240,
          },
          370605: {
            name: {
              th: 'โพนเมืองน้อย',
              en: 'Phon Mueang Noi',
            },
            coordinates: {
              lat: '15.650',
              lng: '104.580',
            },
            zipcode: 37240,
          },
          370606: {
            name: {
              th: 'สร้างถ่อน้อย',
              en: 'Sang Tho Noi',
            },
            coordinates: {
              lat: '15.641',
              lng: '104.490',
            },
            zipcode: 37240,
          },
          370607: {
            name: {
              th: 'จิกดู่',
              en: 'Chik Du',
            },
            coordinates: {
              lat: '15.583',
              lng: '104.545',
            },
            zipcode: 37240,
          },
          370608: {
            name: {
              th: 'รัตนวารี',
              en: 'Rattana Wari',
            },
            coordinates: {
              lat: '15.723',
              lng: '104.513',
            },
            zipcode: 37240,
          },
        },
      },
      3707: {
        name: {
          th: 'ลืออำนาจ',
          en: 'Lue Amnat',
        },
        tambons: {
          370701: {
            name: {
              th: 'อำนาจ',
              en: 'Amnat',
            },
            coordinates: {
              lat: '15.710',
              lng: '104.668',
            },
            zipcode: null,
          },
          370702: {
            name: {
              th: 'ดงมะยาง',
              en: 'Dong Mayang',
            },
            coordinates: {
              lat: '15.716',
              lng: '104.617',
            },
            zipcode: null,
          },
          370703: {
            name: {
              th: 'เปือย',
              en: 'Pueai',
            },
            coordinates: {
              lat: '15.687',
              lng: '104.697',
            },
            zipcode: null,
          },
          370704: {
            name: {
              th: 'ดงบัง',
              en: 'Dong Bang',
            },
            coordinates: {
              lat: '15.716',
              lng: '104.765',
            },
            zipcode: null,
          },
          370705: {
            name: {
              th: 'ไร่ขี',
              en: 'Rai Khi',
            },
            coordinates: {
              lat: '15.663',
              lng: '104.780',
            },
            zipcode: null,
          },
          370706: {
            name: {
              th: 'แมด',
              en: 'Maet',
            },
            coordinates: {
              lat: '15.761',
              lng: '104.689',
            },
            zipcode: 37120,
          },
          370707: {
            name: {
              th: 'โคกกลาง',
              en: 'Khok Klang',
            },
            coordinates: {
              lat: '15.751',
              lng: '104.637',
            },
            zipcode: 37120,
          },
        },
      },
    },
  },
  38: {
    name: {
      th: 'บึงกาฬ',
      en: 'Bueng Kan',
    },
    amphoes: {
      3801: {
        name: {
          th: 'บึงกาฬ',
          en: 'Bueng Kan',
        },
        tambons: {
          380101: {
            name: {
              th: 'บึงกาฬ',
              en: null,
            },
            coordinates: {
              lat: '18.331',
              lng: '103.679',
            },
            zipcode: null,
          },
          380102: {
            name: {
              th: 'โนนสมบูรณ์',
              en: null,
            },
            coordinates: {
              lat: '18.284',
              lng: '103.638',
            },
            zipcode: null,
          },
          380103: {
            name: {
              th: 'หนองเข็ง',
              en: null,
            },
            coordinates: {
              lat: '18.356',
              lng: '103.468',
            },
            zipcode: null,
          },
          380104: {
            name: {
              th: 'หอคำ',
              en: null,
            },
            coordinates: {
              lat: '18.406',
              lng: '103.380',
            },
            zipcode: null,
          },
          380105: {
            name: {
              th: 'หนองเลิง',
              en: null,
            },
            coordinates: {
              lat: '18.328',
              lng: '103.393',
            },
            zipcode: null,
          },
          380106: {
            name: {
              th: 'โคกก่อง',
              en: null,
            },
            coordinates: {
              lat: '18.298',
              lng: '103.772',
            },
            zipcode: null,
          },
          380107: {
            name: {
              th: 'นาสวรรค์',
              en: null,
            },
            coordinates: {
              lat: '18.265',
              lng: '103.581',
            },
            zipcode: null,
          },
          380108: {
            name: {
              th: 'ไคสี',
              en: null,
            },
            coordinates: {
              lat: '18.403',
              lng: '103.508',
            },
            zipcode: null,
          },
          380109: {
            name: {
              th: 'ชัยพร',
              en: null,
            },
            coordinates: {
              lat: '18.240',
              lng: '103.839',
            },
            zipcode: null,
          },
          380110: {
            name: {
              th: 'วิศิษฐ์',
              en: null,
            },
            coordinates: {
              lat: '18.359',
              lng: '103.592',
            },
            zipcode: null,
          },
          380111: {
            name: {
              th: 'คำนาดี',
              en: null,
            },
            coordinates: {
              lat: '18.219',
              lng: '103.593',
            },
            zipcode: null,
          },
          380112: {
            name: {
              th: 'โป่งเปือย',
              en: null,
            },
            coordinates: {
              lat: '18.332',
              lng: '103.531',
            },
            zipcode: null,
          },
        },
      },
      3802: {
        name: {
          th: 'พรเจริญ',
          en: 'Phon Charoen',
        },
        tambons: {
          380201: {
            name: {
              th: 'ศรีชมภู',
              en: null,
            },
            coordinates: {
              lat: '18.044',
              lng: '103.679',
            },
            zipcode: null,
          },
          380202: {
            name: {
              th: 'ดอนหญ้านาง',
              en: null,
            },
            coordinates: {
              lat: '18.046',
              lng: '103.616',
            },
            zipcode: null,
          },
          380203: {
            name: {
              th: 'พรเจริญ',
              en: null,
            },
            coordinates: {
              lat: '18.031',
              lng: '103.711',
            },
            zipcode: null,
          },
          380204: {
            name: {
              th: 'หนองหัวช้าง',
              en: null,
            },
            coordinates: {
              lat: '18.125',
              lng: '103.583',
            },
            zipcode: null,
          },
          380205: {
            name: {
              th: 'วังชมภู',
              en: null,
            },
            coordinates: {
              lat: '17.983',
              lng: '103.683',
            },
            zipcode: null,
          },
          380206: {
            name: {
              th: 'ป่าแฝก',
              en: null,
            },
            coordinates: {
              lat: '18.094',
              lng: '103.698',
            },
            zipcode: null,
          },
          380207: {
            name: {
              th: 'ศรีสำราญ',
              en: null,
            },
            coordinates: {
              lat: '18.121',
              lng: '103.646',
            },
            zipcode: null,
          },
        },
      },
      3803: {
        name: {
          th: 'โซ่พิสัย',
          en: 'So Phisai',
        },
        tambons: {
          380301: {
            name: {
              th: 'โซ่',
              en: null,
            },
            coordinates: {
              lat: '18.039',
              lng: '103.391',
            },
            zipcode: null,
          },
          380302: {
            name: {
              th: 'หนองพันทา',
              en: null,
            },
            coordinates: {
              lat: '18.129',
              lng: '103.384',
            },
            zipcode: null,
          },
          380303: {
            name: {
              th: 'ศรีชมภู',
              en: null,
            },
            coordinates: {
              lat: '18.271',
              lng: '103.464',
            },
            zipcode: null,
          },
          380304: {
            name: {
              th: 'คำแก้ว',
              en: null,
            },
            coordinates: {
              lat: '18.093',
              lng: '103.489',
            },
            zipcode: null,
          },
          380305: {
            name: {
              th: 'บัวตูม',
              en: null,
            },
            coordinates: {
              lat: '18.072',
              lng: '103.364',
            },
            zipcode: null,
          },
          380306: {
            name: {
              th: 'ถ้ำเจริญ',
              en: null,
            },
            coordinates: {
              lat: '18.208',
              lng: '103.497',
            },
            zipcode: null,
          },
          380307: {
            name: {
              th: 'เหล่าทอง',
              en: null,
            },
            coordinates: {
              lat: '18.144',
              lng: '103.442',
            },
            zipcode: null,
          },
        },
      },
      3804: {
        name: {
          th: 'เซกา',
          en: 'Seka',
        },
        tambons: {
          380401: {
            name: {
              th: 'เซกา',
              en: null,
            },
            coordinates: {
              lat: '17.964',
              lng: '103.945',
            },
            zipcode: null,
          },
          380402: {
            name: {
              th: 'ซาง',
              en: null,
            },
            coordinates: {
              lat: '17.839',
              lng: '103.958',
            },
            zipcode: null,
          },
          380403: {
            name: {
              th: 'ท่ากกแดง',
              en: null,
            },
            coordinates: {
              lat: '17.911',
              lng: '103.816',
            },
            zipcode: null,
          },
          380404: {
            name: {
              th: 'บ้านต้อง',
              en: null,
            },
            coordinates: {
              lat: '18.099',
              lng: '103.946',
            },
            zipcode: null,
          },
          380405: {
            name: {
              th: 'ป่งไฮ',
              en: null,
            },
            coordinates: {
              lat: '17.976',
              lng: '103.873',
            },
            zipcode: null,
          },
          380406: {
            name: {
              th: 'น้ำจั้น',
              en: null,
            },
            coordinates: {
              lat: '18.063',
              lng: '103.894',
            },
            zipcode: null,
          },
          380407: {
            name: {
              th: 'ท่าสะอาด',
              en: null,
            },
            coordinates: {
              lat: '17.975',
              lng: '103.744',
            },
            zipcode: null,
          },
          380408: {
            name: {
              th: 'หนองทุ่ม',
              en: null,
            },
            coordinates: {
              lat: '17.914',
              lng: '103.891',
            },
            zipcode: null,
          },
          380409: {
            name: {
              th: 'โสกก่าม',
              en: null,
            },
            coordinates: {
              lat: '18.075',
              lng: '104.015',
            },
            zipcode: null,
          },
        },
      },
      3805: {
        name: {
          th: 'ปากคาด',
          en: 'Pak Khat',
        },
        tambons: {
          380501: {
            name: {
              th: 'ปากคาด',
              en: null,
            },
            coordinates: {
              lat: '18.343',
              lng: '103.316',
            },
            zipcode: null,
          },
          380502: {
            name: {
              th: 'หนองยอง',
              en: null,
            },
            coordinates: {
              lat: '18.208',
              lng: '103.351',
            },
            zipcode: null,
          },
          380503: {
            name: {
              th: 'นากั้ง',
              en: null,
            },
            coordinates: {
              lat: '18.382',
              lng: '103.285',
            },
            zipcode: null,
          },
          380504: {
            name: {
              th: 'โนนศิลา',
              en: null,
            },
            coordinates: {
              lat: '18.292',
              lng: '103.346',
            },
            zipcode: null,
          },
          380505: {
            name: {
              th: 'สมสนุก',
              en: null,
            },
            coordinates: {
              lat: '18.222',
              lng: '103.398',
            },
            zipcode: null,
          },
          380506: {
            name: {
              th: 'นาดง',
              en: null,
            },
            coordinates: {
              lat: '18.256',
              lng: '103.304',
            },
            zipcode: null,
          },
        },
      },
      3806: {
        name: {
          th: 'บึงโขงหลง',
          en: 'Bueng Khong Long',
        },
        tambons: {
          380601: {
            name: {
              th: 'บึงโขงหลง',
              en: null,
            },
            coordinates: {
              lat: '18.002',
              lng: '104.045',
            },
            zipcode: null,
          },
          380602: {
            name: {
              th: 'โพธิ์หมากแข้ง',
              en: null,
            },
            coordinates: {
              lat: '17.941',
              lng: '104.075',
            },
            zipcode: null,
          },
          380603: {
            name: {
              th: 'ดงบัง',
              en: null,
            },
            coordinates: {
              lat: '18.033',
              lng: '104.133',
            },
            zipcode: null,
          },
          380604: {
            name: {
              th: 'ท่าดอกคำ',
              en: null,
            },
            coordinates: {
              lat: '18.123',
              lng: '104.058',
            },
            zipcode: null,
          },
        },
      },
      3807: {
        name: {
          th: 'ศรีวิไล',
          en: 'Si Wilai',
        },
        tambons: {
          380701: {
            name: {
              th: 'ศรีวิไล',
              en: null,
            },
            coordinates: {
              lat: '18.225',
              lng: '103.732',
            },
            zipcode: null,
          },
          380702: {
            name: {
              th: 'ชุมภูพร',
              en: null,
            },
            coordinates: {
              lat: '18.146',
              lng: '103.714',
            },
            zipcode: null,
          },
          380703: {
            name: {
              th: 'นาแสง',
              en: null,
            },
            coordinates: {
              lat: '18.082',
              lng: '103.843',
            },
            zipcode: null,
          },
          380704: {
            name: {
              th: 'นาสะแบง',
              en: null,
            },
            coordinates: {
              lat: '18.140',
              lng: '103.814',
            },
            zipcode: null,
          },
          380705: {
            name: {
              th: 'นาสิงห์',
              en: null,
            },
            coordinates: {
              lat: '18.212',
              lng: '103.789',
            },
            zipcode: null,
          },
        },
      },
      3808: {
        name: {
          th: 'บุ่งคล้า',
          en: 'Bung Khla',
        },
        tambons: {
          380801: {
            name: {
              th: 'บุ่งคล้า',
              en: null,
            },
            coordinates: {
              lat: '18.252',
              lng: '103.962',
            },
            zipcode: null,
          },
          380802: {
            name: {
              th: 'หนองเดิ่น',
              en: null,
            },
            coordinates: {
              lat: '18.294',
              lng: '103.925',
            },
            zipcode: null,
          },
          380803: {
            name: {
              th: 'โคกกว้าง',
              en: null,
            },
            coordinates: {
              lat: '18.218',
              lng: '104.010',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  39: {
    name: {
      th: 'หนองบัวลำภู',
      en: 'Nong Bua Lam Phu',
    },
    amphoes: {
      3901: {
        name: {
          th: 'เมืองหนองบัวลำภู',
          en: 'Mueang Nong Bua Lam Phu',
        },
        tambons: {
          390101: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '17.168',
              lng: '102.444',
            },
            zipcode: 39000,
          },
          390102: {
            name: {
              th: 'หนองภัยศูนย์',
              en: 'Nong Phai Sun',
            },
            coordinates: {
              lat: '17.263',
              lng: '102.395',
            },
            zipcode: 39000,
          },
          390103: {
            name: {
              th: 'โพธิ์ชัย',
              en: 'Pho Chai',
            },
            coordinates: {
              lat: '17.211',
              lng: '102.381',
            },
            zipcode: 39000,
          },
          390104: {
            name: {
              th: 'หนองสวรรค์',
              en: 'Nong Sawan',
            },
            coordinates: {
              lat: '17.200',
              lng: '102.317',
            },
            zipcode: 39000,
          },
          390105: {
            name: {
              th: 'หัวนา',
              en: 'Hua Na',
            },
            coordinates: {
              lat: '17.032',
              lng: '102.390',
            },
            zipcode: 39000,
          },
          390106: {
            name: {
              th: 'บ้านขาม',
              en: 'Ban Kham',
            },
            coordinates: {
              lat: '17.111',
              lng: '102.477',
            },
            zipcode: 39000,
          },
          390107: {
            name: {
              th: 'นามะเฟือง',
              en: 'Na Mafueang',
            },
            coordinates: {
              lat: '17.046',
              lng: '102.506',
            },
            zipcode: 39000,
          },
          390108: {
            name: {
              th: 'บ้านพร้าว',
              en: 'Ban Phrao',
            },
            coordinates: {
              lat: '17.102',
              lng: '102.392',
            },
            zipcode: 39000,
          },
          390109: {
            name: {
              th: 'โนนขมิ้น',
              en: 'Non Khamin',
            },
            coordinates: {
              lat: '17.164',
              lng: '102.283',
            },
            zipcode: 39000,
          },
          390110: {
            name: {
              th: 'ลำภู',
              en: 'Lamphu',
            },
            coordinates: {
              lat: '17.243',
              lng: '102.440',
            },
            zipcode: 39000,
          },
          390111: {
            name: {
              th: 'กุดจิก',
              en: 'Kut Chik',
            },
            coordinates: {
              lat: '17.333',
              lng: '102.372',
            },
            zipcode: 39000,
          },
          390112: {
            name: {
              th: 'โนนทัน',
              en: 'Non Than',
            },
            coordinates: {
              lat: '17.245',
              lng: '102.503',
            },
            zipcode: 39000,
          },
          390113: {
            name: {
              th: 'นาคำไฮ',
              en: 'Na Kham Hai',
            },
            coordinates: {
              lat: '17.280',
              lng: '102.304',
            },
            zipcode: 39000,
          },
          390114: {
            name: {
              th: 'ป่าไม้งาม',
              en: 'Pa Mai Ngam',
            },
            coordinates: {
              lat: '16.974',
              lng: '102.366',
            },
            zipcode: 39000,
          },
          390115: {
            name: {
              th: 'หนองหว้า',
              en: 'Nong Wa',
            },
            coordinates: {
              lat: '17.147',
              lng: '102.350',
            },
            zipcode: 39000,
          },
        },
      },
      3902: {
        name: {
          th: 'นากลาง',
          en: 'Na Klang',
        },
        tambons: {
          390201: {
            name: {
              th: 'นากลาง',
              en: 'Na Klang',
            },
            coordinates: {
              lat: '17.312',
              lng: '102.214',
            },
            zipcode: 39170,
          },
          390202: {
            name: {
              th: 'ด่านช้าง',
              en: 'Dan Chang',
            },
            coordinates: {
              lat: '17.315',
              lng: '102.140',
            },
            zipcode: 39170,
          },
          390205: {
            name: {
              th: 'กุดดินจี่',
              en: 'Kut Din Chi',
            },
            coordinates: {
              lat: '17.349',
              lng: '102.273',
            },
            zipcode: 39350,
          },
          390206: {
            name: {
              th: 'ฝั่งแดง',
              en: 'Fang Daeng',
            },
            coordinates: {
              lat: '17.221',
              lng: '102.223',
            },
            zipcode: 39170,
          },
          390207: {
            name: {
              th: 'เก่ากลอย',
              en: 'Kao Kloi',
            },
            coordinates: {
              lat: '17.397',
              lng: '102.339',
            },
            zipcode: 39350,
          },
          390209: {
            name: {
              th: 'โนนเมือง',
              en: 'Non Mueang',
            },
            coordinates: {
              lat: '17.181',
              lng: '102.154',
            },
            zipcode: 39140,
          },
          390210: {
            name: {
              th: 'อุทัยสวรรค์',
              en: 'Uthai Sawan',
            },
            coordinates: {
              lat: '17.235',
              lng: '102.143',
            },
            zipcode: 39170,
          },
          390211: {
            name: {
              th: 'ดงสวรรค์',
              en: 'Dong Sawan',
            },
            coordinates: {
              lat: '17.443',
              lng: '102.193',
            },
            zipcode: 39350,
          },
          390213: {
            name: {
              th: 'กุดแห่',
              en: 'Kut Hae',
            },
            coordinates: {
              lat: '17.391',
              lng: '102.149',
            },
            zipcode: 39170,
          },
        },
      },
      3903: {
        name: {
          th: 'โนนสัง',
          en: 'Non Sang',
        },
        tambons: {
          390301: {
            name: {
              th: 'โนนสัง',
              en: 'Non Sang',
            },
            coordinates: {
              lat: '16.872',
              lng: '102.560',
            },
            zipcode: 39140,
          },
          390302: {
            name: {
              th: 'บ้านถิ่น',
              en: 'Ban Thin',
            },
            coordinates: {
              lat: '16.987',
              lng: '102.527',
            },
            zipcode: 39140,
          },
          390303: {
            name: {
              th: 'หนองเรือ',
              en: 'Nong Ruea',
            },
            coordinates: {
              lat: '16.844',
              lng: '102.504',
            },
            zipcode: 39140,
          },
          390304: {
            name: {
              th: 'กุดดู่',
              en: 'Kut Du',
            },
            coordinates: {
              lat: '16.971',
              lng: '102.650',
            },
            zipcode: 39140,
          },
          390305: {
            name: {
              th: 'บ้านค้อ',
              en: 'Ban Kho',
            },
            coordinates: {
              lat: '16.855',
              lng: '102.601',
            },
            zipcode: 39140,
          },
          390306: {
            name: {
              th: 'โนนเมือง',
              en: 'Non Mueang',
            },
            coordinates: {
              lat: '16.840',
              lng: '102.437',
            },
            zipcode: null,
          },
          390307: {
            name: {
              th: 'โคกใหญ่',
              en: 'Khok Yai',
            },
            coordinates: {
              lat: '16.809',
              lng: '102.569',
            },
            zipcode: 39140,
          },
          390308: {
            name: {
              th: 'โคกม่วง',
              en: 'Khok Muang',
            },
            coordinates: {
              lat: '16.892',
              lng: '102.400',
            },
            zipcode: 39140,
          },
          390309: {
            name: {
              th: 'นิคมพัฒนา',
              en: 'Nikhom Phatthana',
            },
            coordinates: {
              lat: '16.930',
              lng: '102.524',
            },
            zipcode: 39140,
          },
          390310: {
            name: {
              th: 'ปางกู่',
              en: 'Pang Ku',
            },
            coordinates: {
              lat: '17.003',
              lng: '102.593',
            },
            zipcode: 39140,
          },
        },
      },
      3904: {
        name: {
          th: 'ศรีบุญเรือง',
          en: 'Si Bun Rueang',
        },
        tambons: {
          390401: {
            name: {
              th: 'เมืองใหม่',
              en: 'Mueang Mai',
            },
            coordinates: {
              lat: '16.992',
              lng: '102.291',
            },
            zipcode: 39180,
          },
          390402: {
            name: {
              th: 'ศรีบุญเรือง',
              en: 'Si Bunrueang',
            },
            coordinates: {
              lat: '16.922',
              lng: '102.302',
            },
            zipcode: 39180,
          },
          390403: {
            name: {
              th: 'หนองบัวใต้',
              en: 'Nong Bua Tai',
            },
            coordinates: {
              lat: '16.920',
              lng: '102.353',
            },
            zipcode: 39180,
          },
          390404: {
            name: {
              th: 'กุดสะเทียน',
              en: 'Kut Sathian',
            },
            coordinates: {
              lat: '17.056',
              lng: '102.238',
            },
            zipcode: 39180,
          },
          390405: {
            name: {
              th: 'นากอก',
              en: 'Na Kok',
            },
            coordinates: {
              lat: '16.904',
              lng: '102.201',
            },
            zipcode: 39180,
          },
          390406: {
            name: {
              th: 'โนนสะอาด',
              en: 'Non Sa-At',
            },
            coordinates: {
              lat: '16.977',
              lng: '102.176',
            },
            zipcode: 39180,
          },
          390407: {
            name: {
              th: 'ยางหล่อ',
              en: 'Yang Lo',
            },
            coordinates: {
              lat: '17.062',
              lng: '102.159',
            },
            zipcode: 39180,
          },
          390408: {
            name: {
              th: 'โนนม่วง',
              en: 'Non Muang',
            },
            coordinates: {
              lat: '17.128',
              lng: '102.181',
            },
            zipcode: 39180,
          },
          390409: {
            name: {
              th: 'หนองกุงแก้ว',
              en: 'Nong Kung Kaeo',
            },
            coordinates: {
              lat: '17.149',
              lng: '102.073',
            },
            zipcode: 39180,
          },
          390410: {
            name: {
              th: 'หนองแก',
              en: 'Nong Kae',
            },
            coordinates: {
              lat: '17.083',
              lng: '102.294',
            },
            zipcode: 39180,
          },
          390411: {
            name: {
              th: 'ทรายทอง',
              en: 'Sai Thong',
            },
            coordinates: {
              lat: '16.856',
              lng: '102.349',
            },
            zipcode: 39180,
          },
          390412: {
            name: {
              th: 'หันนางาม',
              en: 'Han Na Ngam',
            },
            coordinates: {
              lat: '16.985',
              lng: '102.246',
            },
            zipcode: 39180,
          },
        },
      },
      3905: {
        name: {
          th: 'สุวรรณคูหา',
          en: 'Suwannakhuha',
        },
        tambons: {
          390501: {
            name: {
              th: 'นาสี',
              en: 'Na Si',
            },
            coordinates: {
              lat: '17.631',
              lng: '102.313',
            },
            zipcode: null,
          },
          390502: {
            name: {
              th: 'บ้านโคก',
              en: 'Ban Khok',
            },
            coordinates: {
              lat: '17.528',
              lng: '102.199',
            },
            zipcode: null,
          },
          390503: {
            name: {
              th: 'นาดี',
              en: 'Na Di',
            },
            coordinates: {
              lat: '17.473',
              lng: '102.269',
            },
            zipcode: null,
          },
          390504: {
            name: {
              th: 'นาด่าน',
              en: 'Na Dan',
            },
            coordinates: {
              lat: '17.470',
              lng: '102.347',
            },
            zipcode: 39270,
          },
          390505: {
            name: {
              th: 'ดงมะไฟ',
              en: 'Dong Mafai',
            },
            coordinates: {
              lat: '17.635',
              lng: '102.245',
            },
            zipcode: 39270,
          },
          390506: {
            name: {
              th: 'สุวรรณคูหา',
              en: 'Suwanna Khuha',
            },
            coordinates: {
              lat: '17.551',
              lng: '102.277',
            },
            zipcode: 39270,
          },
          390507: {
            name: {
              th: 'บุญทัน',
              en: 'Bunthan',
            },
            coordinates: {
              lat: '17.582',
              lng: '102.140',
            },
            zipcode: 39270,
          },
          390508: {
            name: {
              th: 'กุดผึ้ง',
              en: 'Kut Phueng',
            },
            coordinates: {
              lat: '17.549',
              lng: '102.315',
            },
            zipcode: 39270,
          },
        },
      },
      3906: {
        name: {
          th: 'นาวัง',
          en: 'Na Wang',
        },
        tambons: {
          390601: {
            name: {
              th: 'นาเหล่า',
              en: 'Na Lao',
            },
            coordinates: {
              lat: '17.294',
              lng: '102.073',
            },
            zipcode: null,
          },
          390602: {
            name: {
              th: 'นาแก',
              en: 'Na Kae',
            },
            coordinates: {
              lat: '17.396',
              lng: '102.078',
            },
            zipcode: null,
          },
          390603: {
            name: {
              th: 'วังทอง',
              en: 'Wang Thong',
            },
            coordinates: {
              lat: '17.320',
              lng: '102.030',
            },
            zipcode: null,
          },
          390604: {
            name: {
              th: 'วังปลาป้อม',
              en: 'Wang Pla Pom',
            },
            coordinates: {
              lat: '17.426',
              lng: '102.024',
            },
            zipcode: null,
          },
          390605: {
            name: {
              th: 'เทพคีรี',
              en: 'Thep Khiri',
            },
            coordinates: {
              lat: '17.308',
              lng: '102.117',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  40: {
    name: {
      th: 'ขอนแก่น',
      en: 'Khon Kaen',
    },
    amphoes: {
      4001: {
        name: {
          th: 'เมืองขอนแก่น',
          en: 'Mueang Khon Kaen',
        },
        tambons: {
          400101: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '16.438',
              lng: '102.837',
            },
            zipcode: 40000,
          },
          400102: {
            name: {
              th: 'สำราญ',
              en: 'Samran',
            },
            coordinates: {
              lat: '16.537',
              lng: '102.837',
            },
            zipcode: 40000,
          },
          400103: {
            name: {
              th: 'โคกสี',
              en: 'Khok Si',
            },
            coordinates: {
              lat: '16.481',
              lng: '102.985',
            },
            zipcode: 40000,
          },
          400104: {
            name: {
              th: 'ท่าพระ',
              en: 'Tha Phra',
            },
            coordinates: {
              lat: '16.314',
              lng: '102.813',
            },
            zipcode: 40260,
          },
          400105: {
            name: {
              th: 'บ้านทุ่ม',
              en: 'Ban Thum',
            },
            coordinates: {
              lat: '16.459',
              lng: '102.701',
            },
            zipcode: 40000,
          },
          400106: {
            name: {
              th: 'เมืองเก่า',
              en: 'Mueang Kao',
            },
            coordinates: {
              lat: '16.385',
              lng: '102.809',
            },
            zipcode: 40000,
          },
          400107: {
            name: {
              th: 'พระลับ',
              en: 'Phra Lap',
            },
            coordinates: {
              lat: '16.401',
              lng: '102.885',
            },
            zipcode: 40000,
          },
          400108: {
            name: {
              th: 'สาวะถี',
              en: 'Sawathi',
            },
            coordinates: {
              lat: '16.538',
              lng: '102.684',
            },
            zipcode: 40000,
          },
          400109: {
            name: {
              th: 'บ้านหว้า',
              en: 'Ban Wa',
            },
            coordinates: {
              lat: '16.384',
              lng: '102.713',
            },
            zipcode: 40000,
          },
          400110: {
            name: {
              th: 'บ้านค้อ',
              en: 'Ban Kho',
            },
            coordinates: {
              lat: '16.569',
              lng: '102.753',
            },
            zipcode: 40000,
          },
          400111: {
            name: {
              th: 'แดงใหญ่',
              en: 'Daeng Yai',
            },
            coordinates: {
              lat: '16.485',
              lng: '102.752',
            },
            zipcode: 40000,
          },
          400112: {
            name: {
              th: 'ดอนช้าง',
              en: 'Don Chang',
            },
            coordinates: {
              lat: '16.363',
              lng: '102.759',
            },
            zipcode: 40000,
          },
          400113: {
            name: {
              th: 'ดอนหัน',
              en: 'Don Han',
            },
            coordinates: {
              lat: '16.334',
              lng: '102.869',
            },
            zipcode: 40260,
          },
          400114: {
            name: {
              th: 'ศิลา',
              en: 'Sila',
            },
            coordinates: {
              lat: '16.490',
              lng: '102.857',
            },
            zipcode: 40000,
          },
          400115: {
            name: {
              th: 'บ้านเป็ด',
              en: 'Ban Pet',
            },
            coordinates: {
              lat: '16.435',
              lng: '102.781',
            },
            zipcode: 40000,
          },
          400116: {
            name: {
              th: 'หนองตูม',
              en: 'Nong Tum',
            },
            coordinates: {
              lat: '16.516',
              lng: '102.931',
            },
            zipcode: 40000,
          },
          400117: {
            name: {
              th: 'บึงเนียม',
              en: 'Bueng Niam',
            },
            coordinates: {
              lat: '16.430',
              lng: '102.922',
            },
            zipcode: 40000,
          },
          400118: {
            name: {
              th: 'โนนท่อน',
              en: 'Non Thon',
            },
            coordinates: {
              lat: '16.595',
              lng: '102.837',
            },
            zipcode: 40000,
          },
        },
      },
      4002: {
        name: {
          th: 'บ้านฝาง',
          en: 'Ban Fang',
        },
        tambons: {
          400201: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '16.497',
              lng: '102.596',
            },
            zipcode: null,
          },
          400202: {
            name: {
              th: 'ป่าหวายนั่ง',
              en: 'Pa Wai Nang',
            },
            coordinates: {
              lat: '16.609',
              lng: '102.650',
            },
            zipcode: 40270,
          },
          400203: {
            name: {
              th: 'โนนฆ้อง',
              en: 'Non Khong',
            },
            coordinates: {
              lat: '16.416',
              lng: '102.589',
            },
            zipcode: 40270,
          },
          400204: {
            name: {
              th: 'บ้านเหล่า',
              en: 'Ban Lao',
            },
            coordinates: {
              lat: '16.399',
              lng: '102.650',
            },
            zipcode: 40270,
          },
          400205: {
            name: {
              th: 'ป่ามะนาว',
              en: 'Pa Manao',
            },
            coordinates: {
              lat: '16.367',
              lng: '102.573',
            },
            zipcode: 40270,
          },
          400206: {
            name: {
              th: 'บ้านฝาง',
              en: 'Ban Fang',
            },
            coordinates: {
              lat: '16.443',
              lng: '102.654',
            },
            zipcode: 40270,
          },
          400207: {
            name: {
              th: 'โคกงาม',
              en: 'Khok Ngam',
            },
            coordinates: {
              lat: '16.582',
              lng: '102.600',
            },
            zipcode: 40270,
          },
        },
      },
      4003: {
        name: {
          th: 'พระยืน',
          en: 'Phra Yuen',
        },
        tambons: {
          400301: {
            name: {
              th: 'พระยืน',
              en: 'Phra Yuen',
            },
            coordinates: {
              lat: '16.323',
              lng: '102.612',
            },
            zipcode: 40320,
          },
          400302: {
            name: {
              th: 'พระบุ',
              en: 'Phra Bu',
            },
            coordinates: {
              lat: '16.265',
              lng: '102.697',
            },
            zipcode: 40320,
          },
          400303: {
            name: {
              th: 'บ้านโต้น',
              en: 'Ban Ton',
            },
            coordinates: {
              lat: '16.274',
              lng: '102.751',
            },
            zipcode: null,
          },
          400304: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '16.318',
              lng: '102.738',
            },
            zipcode: 40320,
          },
          400305: {
            name: {
              th: 'ขามป้อม',
              en: 'Kham Pom',
            },
            coordinates: {
              lat: '16.301',
              lng: '102.657',
            },
            zipcode: 40340,
          },
        },
      },
      4004: {
        name: {
          th: 'หนองเรือ',
          en: 'Nong Ruea',
        },
        tambons: {
          400401: {
            name: {
              th: 'หนองเรือ',
              en: 'Nong Ruea',
            },
            coordinates: {
              lat: '16.501',
              lng: '102.464',
            },
            zipcode: 40210,
          },
          400402: {
            name: {
              th: 'บ้านเม็ง',
              en: 'Ban Meng',
            },
            coordinates: {
              lat: '16.435',
              lng: '102.460',
            },
            zipcode: 40210,
          },
          400403: {
            name: {
              th: 'บ้านกง',
              en: 'Ban Kong',
            },
            coordinates: {
              lat: '16.500',
              lng: '102.553',
            },
            zipcode: 40240,
          },
          400404: {
            name: {
              th: 'ยางคำ',
              en: 'Yang Kham',
            },
            coordinates: {
              lat: '16.398',
              lng: '102.515',
            },
            zipcode: 40240,
          },
          400405: {
            name: {
              th: 'จระเข้',
              en: 'Chorakhe',
            },
            coordinates: {
              lat: '16.458',
              lng: '102.536',
            },
            zipcode: 40240,
          },
          400406: {
            name: {
              th: 'โนนทอง',
              en: 'Non Thong',
            },
            coordinates: {
              lat: '16.552',
              lng: '102.384',
            },
            zipcode: 40330,
          },
          400407: {
            name: {
              th: 'กุดกว้าง',
              en: 'Kut Kwang',
            },
            coordinates: {
              lat: '16.475',
              lng: '102.320',
            },
            zipcode: 40210,
          },
          400408: {
            name: {
              th: 'โนนทัน',
              en: 'Non Than',
            },
            coordinates: {
              lat: '16.507',
              lng: '102.377',
            },
            zipcode: 40210,
          },
          400409: {
            name: {
              th: 'โนนสะอาด',
              en: 'Non Sa-At',
            },
            coordinates: {
              lat: '16.538',
              lng: '102.312',
            },
            zipcode: 40330,
          },
          400410: {
            name: {
              th: 'บ้านผือ',
              en: 'Ban Phue',
            },
            coordinates: {
              lat: '16.540',
              lng: '102.556',
            },
            zipcode: 40240,
          },
        },
      },
      4005: {
        name: {
          th: 'ชุมแพ',
          en: 'Chum Phae',
        },
        tambons: {
          400501: {
            name: {
              th: 'ชุมแพ',
              en: 'Chum Phae',
            },
            coordinates: {
              lat: '16.526',
              lng: '102.074',
            },
            zipcode: 40130,
          },
          400502: {
            name: {
              th: 'โนนหัน',
              en: 'Non Han',
            },
            coordinates: {
              lat: '16.603',
              lng: '102.019',
            },
            zipcode: 40290,
          },
          400503: {
            name: {
              th: 'นาหนองทุ่ม',
              en: 'Na Nong Thum',
            },
            coordinates: {
              lat: '16.767',
              lng: '101.980',
            },
            zipcode: 40290,
          },
          400504: {
            name: {
              th: 'โนนอุดม',
              en: 'Non Udom',
            },
            coordinates: {
              lat: '16.505',
              lng: '102.231',
            },
            zipcode: 40130,
          },
          400505: {
            name: {
              th: 'ขัวเรียง',
              en: 'Khua Riang',
            },
            coordinates: {
              lat: '16.561',
              lng: '102.214',
            },
            zipcode: 40130,
          },
          400506: {
            name: {
              th: 'หนองไผ่',
              en: 'Nong Phai',
            },
            coordinates: {
              lat: '16.565',
              lng: '102.147',
            },
            zipcode: 40130,
          },
          400507: {
            name: {
              th: 'ไชยสอ',
              en: 'Chai So',
            },
            coordinates: {
              lat: '16.514',
              lng: '102.140',
            },
            zipcode: 40130,
          },
          400508: {
            name: {
              th: 'วังหินลาด',
              en: 'Wang Hin Lat',
            },
            coordinates: {
              lat: '16.625',
              lng: '102.093',
            },
            zipcode: 40130,
          },
          400509: {
            name: {
              th: 'นาเพียง',
              en: 'Na Phiang',
            },
            coordinates: {
              lat: '16.490',
              lng: '102.270',
            },
            zipcode: 40130,
          },
          400510: {
            name: {
              th: 'หนองเขียด',
              en: 'Nong Khiat',
            },
            coordinates: {
              lat: '16.658',
              lng: '102.014',
            },
            zipcode: 40290,
          },
          400511: {
            name: {
              th: 'หนองเสาเล้า',
              en: 'Nong Sao Lao',
            },
            coordinates: {
              lat: '16.586',
              lng: '102.298',
            },
            zipcode: 40130,
          },
          400512: {
            name: {
              th: 'โนนสะอาด',
              en: 'Non Sa-At',
            },
            coordinates: {
              lat: '16.576',
              lng: '101.999',
            },
            zipcode: null,
          },
        },
      },
      4006: {
        name: {
          th: 'สีชมพู',
          en: 'Si Chomphu',
        },
        tambons: {
          400601: {
            name: {
              th: 'สีชมพู',
              en: 'Si Chomphu',
            },
            coordinates: {
              lat: '16.841',
              lng: '102.156',
            },
            zipcode: 40220,
          },
          400602: {
            name: {
              th: 'ศรีสุข',
              en: 'Si Suk',
            },
            coordinates: {
              lat: '16.751',
              lng: '102.206',
            },
            zipcode: 40220,
          },
          400603: {
            name: {
              th: 'นาจาน',
              en: 'Na Chan',
            },
            coordinates: {
              lat: '16.657',
              lng: '102.147',
            },
            zipcode: 40220,
          },
          400604: {
            name: {
              th: 'วังเพิ่ม',
              en: 'Wang Phoem',
            },
            coordinates: {
              lat: '16.820',
              lng: '102.204',
            },
            zipcode: 40220,
          },
          400605: {
            name: {
              th: 'ซำยาง',
              en: 'Sam Yang',
            },
            coordinates: {
              lat: '16.688',
              lng: '102.091',
            },
            zipcode: 40220,
          },
          400606: {
            name: {
              th: 'หนองแดง',
              en: 'Nong Daeng',
            },
            coordinates: {
              lat: '16.711',
              lng: '102.113',
            },
            zipcode: 40220,
          },
          400607: {
            name: {
              th: 'ดงลาน',
              en: 'Dong Lan',
            },
            coordinates: {
              lat: '16.805',
              lng: '102.075',
            },
            zipcode: 40220,
          },
          400608: {
            name: {
              th: 'บริบูรณ์',
              en: 'Boribun',
            },
            coordinates: {
              lat: '16.855',
              lng: '102.092',
            },
            zipcode: 40220,
          },
          400609: {
            name: {
              th: 'บ้านใหม่',
              en: 'Ban Mai',
            },
            coordinates: {
              lat: '16.761',
              lng: '102.068',
            },
            zipcode: 40220,
          },
          400610: {
            name: {
              th: 'ภูห่าน',
              en: 'Phu Han',
            },
            coordinates: {
              lat: '16.720',
              lng: '102.046',
            },
            zipcode: 40220,
          },
        },
      },
      4007: {
        name: {
          th: 'น้ำพอง',
          en: 'Nam Phong',
        },
        tambons: {
          400701: {
            name: {
              th: 'น้ำพอง',
              en: 'Nam Phong',
            },
            coordinates: {
              lat: '16.760',
              lng: '102.825',
            },
            zipcode: 40140,
          },
          400702: {
            name: {
              th: 'วังชัย',
              en: 'Wang Chai',
            },
            coordinates: {
              lat: '16.728',
              lng: '102.862',
            },
            zipcode: 40140,
          },
          400703: {
            name: {
              th: 'หนองกุง',
              en: 'Nong Kung',
            },
            coordinates: {
              lat: '16.730',
              lng: '102.897',
            },
            zipcode: 40140,
          },
          400704: {
            name: {
              th: 'บัวใหญ่',
              en: 'Bua Yai',
            },
            coordinates: {
              lat: '16.632',
              lng: '102.951',
            },
            zipcode: 40140,
          },
          400705: {
            name: {
              th: 'สะอาด',
              en: 'Sa-At',
            },
            coordinates: {
              lat: '16.788',
              lng: '102.768',
            },
            zipcode: 40310,
          },
          400706: {
            name: {
              th: 'ม่วงหวาน',
              en: 'Muang Wan',
            },
            coordinates: {
              lat: '16.646',
              lng: '102.779',
            },
            zipcode: 40310,
          },
          400707: {
            name: {
              th: 'บ้านขาม',
              en: 'Ban Kham',
            },
            coordinates: {
              lat: '16.568',
              lng: '102.952',
            },
            zipcode: 40140,
          },
          400708: {
            name: {
              th: 'บัวเงิน',
              en: 'Bua Ngoen',
            },
            coordinates: {
              lat: '16.793',
              lng: '102.995',
            },
            zipcode: 40140,
          },
          400709: {
            name: {
              th: 'ทรายมูล',
              en: 'Sai Mun',
            },
            coordinates: {
              lat: '16.669',
              lng: '102.913',
            },
            zipcode: 40140,
          },
          400710: {
            name: {
              th: 'ท่ากระเสริม',
              en: 'Tha Krasoem',
            },
            coordinates: {
              lat: '16.613',
              lng: '102.878',
            },
            zipcode: 40140,
          },
          400711: {
            name: {
              th: 'พังทุย',
              en: 'Phang Thui',
            },
            coordinates: {
              lat: '16.774',
              lng: '102.928',
            },
            zipcode: 40140,
          },
          400712: {
            name: {
              th: 'กุดน้ำใส',
              en: 'Kut Nam Sai',
            },
            coordinates: {
              lat: '16.697',
              lng: '102.789',
            },
            zipcode: 40310,
          },
        },
      },
      4008: {
        name: {
          th: 'อุบลรัตน์',
          en: 'Ubolratana',
        },
        tambons: {
          400801: {
            name: {
              th: 'โคกสูง',
              en: 'Khok Sung',
            },
            coordinates: {
              lat: '16.679',
              lng: '102.676',
            },
            zipcode: 40250,
          },
          400802: {
            name: {
              th: 'บ้านดง',
              en: 'Ban Dong',
            },
            coordinates: {
              lat: '16.801',
              lng: '102.645',
            },
            zipcode: 40250,
          },
          400803: {
            name: {
              th: 'เขื่อนอุบลรัตน์',
              en: 'Khuean Ubonrat',
            },
            coordinates: {
              lat: '16.715',
              lng: '102.636',
            },
            zipcode: 40250,
          },
          400804: {
            name: {
              th: 'นาคำ',
              en: 'Na Kham',
            },
            coordinates: {
              lat: '16.855',
              lng: '102.680',
            },
            zipcode: 40250,
          },
          400805: {
            name: {
              th: 'ศรีสุขสำราญ',
              en: 'Si Suk Samran',
            },
            coordinates: {
              lat: '16.920',
              lng: '102.703',
            },
            zipcode: 40250,
          },
          400806: {
            name: {
              th: 'ทุ่งโป่ง',
              en: 'Thung Pong',
            },
            coordinates: {
              lat: '16.731',
              lng: '102.676',
            },
            zipcode: 40250,
          },
        },
      },
      4009: {
        name: {
          th: 'กระนวน',
          en: 'Kranuan',
        },
        tambons: {
          400901: {
            name: {
              th: 'หนองโก',
              en: 'Nong Ko',
            },
            coordinates: {
              lat: '16.727',
              lng: '103.110',
            },
            zipcode: 40170,
          },
          400902: {
            name: {
              th: 'หนองกุงใหญ่',
              en: 'Nong Kung Yai',
            },
            coordinates: {
              lat: '16.646',
              lng: '103.086',
            },
            zipcode: 40170,
          },
          400905: {
            name: {
              th: 'ห้วยโจด',
              en: 'Huai Chot',
            },
            coordinates: {
              lat: '16.667',
              lng: '103.022',
            },
            zipcode: 40170,
          },
          400906: {
            name: {
              th: 'ห้วยยาง',
              en: 'Huai Yang',
            },
            coordinates: {
              lat: '16.870',
              lng: '103.085',
            },
            zipcode: 40170,
          },
          400907: {
            name: {
              th: 'บ้านฝาง',
              en: 'Ban Fang',
            },
            coordinates: {
              lat: '16.769',
              lng: '103.050',
            },
            zipcode: null,
          },
          400909: {
            name: {
              th: 'ดูนสาด',
              en: 'Dun Sat',
            },
            coordinates: {
              lat: '16.810',
              lng: '103.149',
            },
            zipcode: 40170,
          },
          400910: {
            name: {
              th: 'หนองโน',
              en: 'Nong No',
            },
            coordinates: {
              lat: '16.722',
              lng: '103.030',
            },
            zipcode: 40170,
          },
          400911: {
            name: {
              th: 'น้ำอ้อม',
              en: 'Nam Om',
            },
            coordinates: {
              lat: '16.671',
              lng: '103.127',
            },
            zipcode: 40170,
          },
          400912: {
            name: {
              th: 'หัวนาคำ',
              en: 'Hua Na Kham',
            },
            coordinates: {
              lat: '16.813',
              lng: '103.080',
            },
            zipcode: 40170,
          },
        },
      },
      4010: {
        name: {
          th: 'บ้านไผ่',
          en: 'Ban Phai',
        },
        tambons: {
          401001: {
            name: {
              th: 'บ้านไผ่',
              en: 'Ban Phai',
            },
            coordinates: {
              lat: '16.119',
              lng: '102.713',
            },
            zipcode: 40110,
          },
          401002: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '16.106',
              lng: '102.767',
            },
            zipcode: null,
          },
          401005: {
            name: {
              th: 'เมืองเพีย',
              en: 'Mueang Phia',
            },
            coordinates: {
              lat: '16.102',
              lng: '102.684',
            },
            zipcode: 40110,
          },
          401009: {
            name: {
              th: 'บ้านลาน',
              en: 'Ban Lan',
            },
            coordinates: {
              lat: '15.992',
              lng: '102.838',
            },
            zipcode: 40110,
          },
          401010: {
            name: {
              th: 'แคนเหนือ',
              en: 'Khaen Nuea',
            },
            coordinates: {
              lat: '15.990',
              lng: '102.771',
            },
            zipcode: 40110,
          },
          401011: {
            name: {
              th: 'ภูเหล็ก',
              en: 'Phu Lek',
            },
            coordinates: {
              lat: '16.115',
              lng: '102.828',
            },
            zipcode: 40110,
          },
          401013: {
            name: {
              th: 'ป่าปอ',
              en: 'Pa Po',
            },
            coordinates: {
              lat: '15.919',
              lng: '102.777',
            },
            zipcode: 40110,
          },
          401014: {
            name: {
              th: 'หินตั้ง',
              en: 'Hin Tang',
            },
            coordinates: {
              lat: '16.045',
              lng: '102.831',
            },
            zipcode: 40110,
          },
          401016: {
            name: {
              th: 'หนองน้ำใส',
              en: 'Nong Nam Sai',
            },
            coordinates: {
              lat: '16.042',
              lng: '102.760',
            },
            zipcode: 40110,
          },
          401017: {
            name: {
              th: 'หัวหนอง',
              en: 'Hua Nong',
            },
            coordinates: {
              lat: '16.040',
              lng: '102.697',
            },
            zipcode: 40110,
          },
        },
      },
      4011: {
        name: {
          th: 'เปือยน้อย',
          en: 'Pueai Noi',
        },
        tambons: {
          401101: {
            name: {
              th: 'เปือยน้อย',
              en: 'Pueai Noi',
            },
            coordinates: {
              lat: '15.879',
              lng: '102.904',
            },
            zipcode: null,
          },
          401102: {
            name: {
              th: 'วังม่วง',
              en: 'Wang Muang',
            },
            coordinates: {
              lat: '15.884',
              lng: '102.829',
            },
            zipcode: null,
          },
          401103: {
            name: {
              th: 'ขามป้อม',
              en: 'Kham Pom',
            },
            coordinates: {
              lat: '15.938',
              lng: '102.874',
            },
            zipcode: null,
          },
          401104: {
            name: {
              th: 'สระแก้ว',
              en: 'Sa Kaeo',
            },
            coordinates: {
              lat: '15.850',
              lng: '102.895',
            },
            zipcode: null,
          },
        },
      },
      4012: {
        name: {
          th: 'พล',
          en: 'Phon',
        },
        tambons: {
          401201: {
            name: {
              th: 'เมืองพล',
              en: 'Mueang Phon',
            },
            coordinates: {
              lat: '15.793',
              lng: '102.640',
            },
            zipcode: 40120,
          },
          401203: {
            name: {
              th: 'โจดหนองแก',
              en: 'Chot Nong Kae',
            },
            coordinates: {
              lat: '15.856',
              lng: '102.650',
            },
            zipcode: 40120,
          },
          401204: {
            name: {
              th: 'เก่างิ้ว',
              en: 'Kao Ngio',
            },
            coordinates: {
              lat: '15.844',
              lng: '102.598',
            },
            zipcode: 40120,
          },
          401205: {
            name: {
              th: 'หนองมะเขือ',
              en: 'Nong Makhuea',
            },
            coordinates: {
              lat: '15.753',
              lng: '102.546',
            },
            zipcode: 40120,
          },
          401206: {
            name: {
              th: 'หนองแวงโสกพระ',
              en: 'Nong Waeng Sok Phra',
            },
            coordinates: {
              lat: '15.746',
              lng: '102.644',
            },
            zipcode: 40120,
          },
          401207: {
            name: {
              th: 'เพ็กใหญ่',
              en: 'Phek Yai',
            },
            coordinates: {
              lat: '15.841',
              lng: '102.538',
            },
            zipcode: 40120,
          },
          401208: {
            name: {
              th: 'โคกสง่า',
              en: 'Khok Sa-Nga',
            },
            coordinates: {
              lat: '15.754',
              lng: '102.488',
            },
            zipcode: 40120,
          },
          401209: {
            name: {
              th: 'หนองแวงนางเบ้า',
              en: 'Nong Waeng Nang Bao',
            },
            coordinates: {
              lat: '15.887',
              lng: '102.611',
            },
            zipcode: 40120,
          },
          401210: {
            name: {
              th: 'ลอมคอม',
              en: 'Lom Khom',
            },
            coordinates: {
              lat: '15.794',
              lng: '102.525',
            },
            zipcode: 40120,
          },
          401211: {
            name: {
              th: 'โนนข่า',
              en: 'Non Kha',
            },
            coordinates: {
              lat: '15.764',
              lng: '102.591',
            },
            zipcode: 40120,
          },
          401212: {
            name: {
              th: 'โสกนกเต็น',
              en: 'Sok Nok Ten',
            },
            coordinates: {
              lat: '15.816',
              lng: '102.660',
            },
            zipcode: 40120,
          },
          401213: {
            name: {
              th: 'หัวทุ่ง',
              en: 'Hua Thung',
            },
            coordinates: {
              lat: '15.879',
              lng: '102.556',
            },
            zipcode: 40120,
          },
        },
      },
      4013: {
        name: {
          th: 'แวงใหญ่',
          en: 'Waeng Yai',
        },
        tambons: {
          401301: {
            name: {
              th: 'คอนฉิม',
              en: 'Khon Chim',
            },
            coordinates: {
              lat: '15.947',
              lng: '102.486',
            },
            zipcode: null,
          },
          401302: {
            name: {
              th: 'ใหม่นาเพียง',
              en: 'Mai Na Phiang',
            },
            coordinates: {
              lat: '15.906',
              lng: '102.471',
            },
            zipcode: null,
          },
          401303: {
            name: {
              th: 'โนนทอง',
              en: 'Non Thong',
            },
            coordinates: {
              lat: '15.904',
              lng: '102.401',
            },
            zipcode: null,
          },
          401304: {
            name: {
              th: 'แวงใหญ่',
              en: 'Waeng Yai',
            },
            coordinates: {
              lat: '15.954',
              lng: '102.533',
            },
            zipcode: null,
          },
          401305: {
            name: {
              th: 'โนนสะอาด',
              en: 'Non Sa-At',
            },
            coordinates: {
              lat: '15.954',
              lng: '102.421',
            },
            zipcode: null,
          },
        },
      },
      4014: {
        name: {
          th: 'แวงน้อย',
          en: 'Waeng Noi',
        },
        tambons: {
          401401: {
            name: {
              th: 'แวงน้อย',
              en: 'Waeng Noi',
            },
            coordinates: {
              lat: '15.813',
              lng: '102.423',
            },
            zipcode: null,
          },
          401402: {
            name: {
              th: 'ก้านเหลือง',
              en: 'Kan Lueang',
            },
            coordinates: {
              lat: '15.844',
              lng: '102.464',
            },
            zipcode: null,
          },
          401403: {
            name: {
              th: 'ท่านางแนว',
              en: 'Tha Nang Naeo',
            },
            coordinates: {
              lat: '15.866',
              lng: '102.372',
            },
            zipcode: 40230,
          },
          401404: {
            name: {
              th: 'ละหานนา',
              en: 'Lahan Na',
            },
            coordinates: {
              lat: '15.813',
              lng: '102.367',
            },
            zipcode: 40230,
          },
          401405: {
            name: {
              th: 'ท่าวัด',
              en: 'Tha Wat',
            },
            coordinates: {
              lat: '15.735',
              lng: '102.397',
            },
            zipcode: null,
          },
          401406: {
            name: {
              th: 'ทางขวาง',
              en: 'Thang Khwang',
            },
            coordinates: {
              lat: '15.758',
              lng: '102.429',
            },
            zipcode: null,
          },
        },
      },
      4015: {
        name: {
          th: 'หนองสองห้อง',
          en: 'Nong Song Hong',
        },
        tambons: {
          401501: {
            name: {
              th: 'หนองสองห้อง',
              en: 'Nong Song Hong',
            },
            coordinates: {
              lat: '15.707',
              lng: '102.771',
            },
            zipcode: 40190,
          },
          401502: {
            name: {
              th: 'คึมชาด',
              en: 'Khuem Chat',
            },
            coordinates: {
              lat: '15.818',
              lng: '102.802',
            },
            zipcode: 40190,
          },
          401503: {
            name: {
              th: 'โนนธาตุ',
              en: 'Non That',
            },
            coordinates: {
              lat: '15.705',
              lng: '102.720',
            },
            zipcode: 40190,
          },
          401504: {
            name: {
              th: 'ตะกั่วป่า',
              en: 'Takua Pa',
            },
            coordinates: {
              lat: '15.714',
              lng: '102.824',
            },
            zipcode: 40190,
          },
          401505: {
            name: {
              th: 'สำโรง',
              en: 'Samrong',
            },
            coordinates: {
              lat: '15.833',
              lng: '102.744',
            },
            zipcode: 40190,
          },
          401506: {
            name: {
              th: 'หนองเม็ก',
              en: 'Nong Mek',
            },
            coordinates: {
              lat: '15.787',
              lng: '102.724',
            },
            zipcode: 40190,
          },
          401507: {
            name: {
              th: 'ดอนดู่',
              en: 'Don Du',
            },
            coordinates: {
              lat: '15.809',
              lng: '102.874',
            },
            zipcode: 40190,
          },
          401508: {
            name: {
              th: 'ดงเค็ง',
              en: 'Dong Kheng',
            },
            coordinates: {
              lat: '15.670',
              lng: '102.812',
            },
            zipcode: 40190,
          },
          401509: {
            name: {
              th: 'หันโจด',
              en: 'Han Chot',
            },
            coordinates: {
              lat: '15.758',
              lng: '102.695',
            },
            zipcode: 40190,
          },
          401510: {
            name: {
              th: 'ดอนดั่ง',
              en: 'Don Dang',
            },
            coordinates: {
              lat: '15.770',
              lng: '102.788',
            },
            zipcode: 40190,
          },
          401511: {
            name: {
              th: 'วังหิน',
              en: 'Wang Hin',
            },
            coordinates: {
              lat: '15.873',
              lng: '102.742',
            },
            zipcode: 40190,
          },
          401512: {
            name: {
              th: 'หนองไผ่ล้อม',
              en: 'Nong Phai Lom',
            },
            coordinates: {
              lat: '15.752',
              lng: '102.841',
            },
            zipcode: 40190,
          },
        },
      },
      4016: {
        name: {
          th: 'ภูเวียง',
          en: 'Phu Wiang',
        },
        tambons: {
          401601: {
            name: {
              th: 'บ้านเรือ',
              en: 'Ban Ruea',
            },
            coordinates: {
              lat: '16.676',
              lng: '102.395',
            },
            zipcode: 40150,
          },
          401604: {
            name: {
              th: 'หว้าทอง',
              en: 'Wa Thong',
            },
            coordinates: {
              lat: '16.761',
              lng: '102.469',
            },
            zipcode: 40150,
          },
          401605: {
            name: {
              th: 'กุดขอนแก่น',
              en: 'Kut Khon Kaen',
            },
            coordinates: {
              lat: '16.580',
              lng: '102.502',
            },
            zipcode: 40150,
          },
          401606: {
            name: {
              th: 'นาชุมแสง',
              en: 'Na Chum Saeng',
            },
            coordinates: {
              lat: '16.602',
              lng: '102.331',
            },
            zipcode: 40150,
          },
          401607: {
            name: {
              th: 'นาหว้า',
              en: 'Na Wa',
            },
            coordinates: {
              lat: '16.721',
              lng: '102.572',
            },
            zipcode: 40150,
          },
          401610: {
            name: {
              th: 'หนองกุงธนสาร',
              en: 'Nong Kung Thanasan',
            },
            coordinates: {
              lat: '16.650',
              lng: '102.437',
            },
            zipcode: 40150,
          },
          401612: {
            name: {
              th: 'หนองกุงเซิน',
              en: 'Nong Kung Soen',
            },
            coordinates: {
              lat: '16.633',
              lng: '102.518',
            },
            zipcode: 40150,
          },
          401613: {
            name: {
              th: 'สงเปือย',
              en: 'Song Pueai',
            },
            coordinates: {
              lat: '16.605',
              lng: '102.394',
            },
            zipcode: 40150,
          },
          401614: {
            name: {
              th: 'ทุ่งชมพู',
              en: 'Thung Chomphu',
            },
            coordinates: {
              lat: '16.762',
              lng: '102.524',
            },
            zipcode: 40150,
          },
          401616: {
            name: {
              th: 'ดินดำ',
              en: 'Din Dam',
            },
            coordinates: {
              lat: '16.722',
              lng: '102.398',
            },
            zipcode: 40150,
          },
          401617: {
            name: {
              th: 'ภูเวียง',
              en: 'Phu Wiang',
            },
            coordinates: {
              lat: '16.679',
              lng: '102.364',
            },
            zipcode: 40150,
          },
        },
      },
      4017: {
        name: {
          th: 'มัญจาคีรี',
          en: 'Mancha Khiri',
        },
        tambons: {
          401701: {
            name: {
              th: 'กุดเค้า',
              en: 'Kut Khao',
            },
            coordinates: {
              lat: '16.114',
              lng: '102.492',
            },
            zipcode: 40160,
          },
          401702: {
            name: {
              th: 'สวนหม่อน',
              en: 'Suan Mon',
            },
            coordinates: {
              lat: '16.165',
              lng: '102.561',
            },
            zipcode: 40160,
          },
          401703: {
            name: {
              th: 'หนองแปน',
              en: 'Nong Paen',
            },
            coordinates: {
              lat: '16.178',
              lng: '102.629',
            },
            zipcode: 40160,
          },
          401704: {
            name: {
              th: 'โพนเพ็ก',
              en: 'Phon Phek',
            },
            coordinates: {
              lat: '16.237',
              lng: '102.534',
            },
            zipcode: 40160,
          },
          401705: {
            name: {
              th: 'คำแคน',
              en: 'Kham Khaen',
            },
            coordinates: {
              lat: '16.323',
              lng: '102.476',
            },
            zipcode: 40160,
          },
          401706: {
            name: {
              th: 'นาข่า',
              en: 'Nakha',
            },
            coordinates: {
              lat: '16.183',
              lng: '102.500',
            },
            zipcode: 40160,
          },
          401707: {
            name: {
              th: 'นางาม',
              en: 'Na Ngam',
            },
            coordinates: {
              lat: '16.196',
              lng: '102.419',
            },
            zipcode: 40160,
          },
          401710: {
            name: {
              th: 'ท่าศาลา',
              en: 'Tha Sala',
            },
            coordinates: {
              lat: '16.269',
              lng: '102.615',
            },
            zipcode: 40160,
          },
        },
      },
      4018: {
        name: {
          th: 'ชนบท',
          en: 'Chonnabot',
        },
        tambons: {
          401801: {
            name: {
              th: 'ชนบท',
              en: 'Chonnabot',
            },
            coordinates: {
              lat: '16.067',
              lng: '102.632',
            },
            zipcode: 40180,
          },
          401802: {
            name: {
              th: 'กุดเพียขอม',
              en: 'Kut Phia Khom',
            },
            coordinates: {
              lat: '16.032',
              lng: '102.613',
            },
            zipcode: 40180,
          },
          401803: {
            name: {
              th: 'วังแสง',
              en: 'Wang Saeng',
            },
            coordinates: {
              lat: '16.013',
              lng: '102.482',
            },
            zipcode: 40180,
          },
          401804: {
            name: {
              th: 'ห้วยแก',
              en: 'Huai Kae',
            },
            coordinates: {
              lat: '15.993',
              lng: '102.531',
            },
            zipcode: 40180,
          },
          401805: {
            name: {
              th: 'บ้านแท่น',
              en: 'Ban Thaen',
            },
            coordinates: {
              lat: '15.966',
              lng: '102.603',
            },
            zipcode: 40180,
          },
          401806: {
            name: {
              th: 'ศรีบุญเรือง',
              en: 'Si Bunrueang',
            },
            coordinates: {
              lat: '16.115',
              lng: '102.623',
            },
            zipcode: 40180,
          },
          401807: {
            name: {
              th: 'โนนพะยอม',
              en: 'Non Phayom',
            },
            coordinates: {
              lat: '16.054',
              lng: '102.547',
            },
            zipcode: 40180,
          },
          401808: {
            name: {
              th: 'ปอแดง',
              en: 'Po Daeng',
            },
            coordinates: {
              lat: '15.950',
              lng: '102.579',
            },
            zipcode: 40180,
          },
        },
      },
      4019: {
        name: {
          th: 'เขาสวนกวาง',
          en: 'Khao Suan Kwang',
        },
        tambons: {
          401901: {
            name: {
              th: 'เขาสวนกวาง',
              en: 'Khao Suan Kwang',
            },
            coordinates: {
              lat: '16.861',
              lng: '102.840',
            },
            zipcode: 40280,
          },
          401902: {
            name: {
              th: 'ดงเมืองแอม',
              en: 'Dong Mueang Am',
            },
            coordinates: {
              lat: '16.862',
              lng: '102.767',
            },
            zipcode: 40280,
          },
          401903: {
            name: {
              th: 'นางิ้ว',
              en: 'Na Ngio',
            },
            coordinates: {
              lat: '16.932',
              lng: '102.784',
            },
            zipcode: 40280,
          },
          401904: {
            name: {
              th: 'โนนสมบูรณ์',
              en: 'Non Sombun',
            },
            coordinates: {
              lat: '17.025',
              lng: '102.709',
            },
            zipcode: null,
          },
          401905: {
            name: {
              th: 'คำม่วง',
              en: 'Kham Muang',
            },
            coordinates: {
              lat: '16.819',
              lng: '102.867',
            },
            zipcode: 40280,
          },
        },
      },
      4020: {
        name: {
          th: 'ภูผาม่าน',
          en: 'Phu Phaman',
        },
        tambons: {
          402001: {
            name: {
              th: 'โนนคอม',
              en: 'Non Khom',
            },
            coordinates: {
              lat: '16.648',
              lng: '101.935',
            },
            zipcode: 40350,
          },
          402002: {
            name: {
              th: 'นาฝาย',
              en: 'Na Fai',
            },
            coordinates: {
              lat: '16.680',
              lng: '101.871',
            },
            zipcode: 40350,
          },
          402003: {
            name: {
              th: 'ภูผาม่าน',
              en: 'Phu Pha Man',
            },
            coordinates: {
              lat: '16.677',
              lng: '101.910',
            },
            zipcode: 40350,
          },
          402004: {
            name: {
              th: 'วังสวาบ',
              en: 'Wang Sawap',
            },
            coordinates: {
              lat: '16.714',
              lng: '101.817',
            },
            zipcode: 40350,
          },
          402005: {
            name: {
              th: 'ห้วยม่วง',
              en: 'Huai Muang',
            },
            coordinates: {
              lat: '16.765',
              lng: '101.909',
            },
            zipcode: 40350,
          },
        },
      },
      4021: {
        name: {
          th: 'ซำสูง',
          en: 'Sam Sung',
        },
        tambons: {
          402101: {
            name: {
              th: 'กระนวน',
              en: 'Kranuan',
            },
            coordinates: {
              lat: '16.534',
              lng: '103.069',
            },
            zipcode: null,
          },
          402102: {
            name: {
              th: 'คำแมด',
              en: 'Kham Maet',
            },
            coordinates: {
              lat: '16.616',
              lng: '103.122',
            },
            zipcode: null,
          },
          402103: {
            name: {
              th: 'บ้านโนน',
              en: 'Ban Non',
            },
            coordinates: {
              lat: '16.560',
              lng: '103.027',
            },
            zipcode: null,
          },
          402104: {
            name: {
              th: 'คูคำ',
              en: 'Khu Kham',
            },
            coordinates: {
              lat: '16.508',
              lng: '103.014',
            },
            zipcode: null,
          },
          402105: {
            name: {
              th: 'ห้วยเตย',
              en: 'Huai Toei',
            },
            coordinates: {
              lat: '16.582',
              lng: '103.089',
            },
            zipcode: null,
          },
        },
      },
      4022: {
        name: {
          th: 'โคกโพธิ์ไชย',
          en: 'Khok Pho Chai',
        },
        tambons: {
          402201: {
            name: {
              th: 'บ้านโคก',
              en: 'Ban Khok',
            },
            coordinates: {
              lat: '16.112',
              lng: '102.378',
            },
            zipcode: null,
          },
          402202: {
            name: {
              th: 'โพธิ์ไชย',
              en: 'Pho Chai',
            },
            coordinates: {
              lat: '16.027',
              lng: '102.399',
            },
            zipcode: null,
          },
          402203: {
            name: {
              th: 'ซับสมบูรณ์',
              en: 'Sap Sombun',
            },
            coordinates: {
              lat: '16.074',
              lng: '102.329',
            },
            zipcode: null,
          },
          402204: {
            name: {
              th: 'นาแพง',
              en: 'Na Phaeng',
            },
            coordinates: {
              lat: '16.078',
              lng: '102.472',
            },
            zipcode: null,
          },
        },
      },
      4023: {
        name: {
          th: 'หนองนาคำ',
          en: 'Nong Na Kham',
        },
        tambons: {
          402301: {
            name: {
              th: 'กุดธาตุ',
              en: 'Kut That',
            },
            coordinates: {
              lat: '16.834',
              lng: '102.308',
            },
            zipcode: null,
          },
          402302: {
            name: {
              th: 'บ้านโคก',
              en: 'Ban Khok',
            },
            coordinates: {
              lat: '16.791',
              lng: '102.335',
            },
            zipcode: null,
          },
          402303: {
            name: {
              th: 'ขนวน',
              en: 'Khanuan',
            },
            coordinates: {
              lat: '16.774',
              lng: '102.387',
            },
            zipcode: null,
          },
        },
      },
      4024: {
        name: {
          th: 'บ้านแฮด',
          en: 'Ban Haet',
        },
        tambons: {
          402401: {
            name: {
              th: 'บ้านแฮด',
              en: 'Ban Haet',
            },
            coordinates: {
              lat: '16.211',
              lng: '102.778',
            },
            zipcode: null,
          },
          402402: {
            name: {
              th: 'โคกสำราญ',
              en: 'Khok Samran',
            },
            coordinates: {
              lat: '16.190',
              lng: '102.710',
            },
            zipcode: null,
          },
          402403: {
            name: {
              th: 'โนนสมบูรณ์',
              en: 'Non Sombun',
            },
            coordinates: {
              lat: '16.259',
              lng: '102.788',
            },
            zipcode: null,
          },
          402404: {
            name: {
              th: 'หนองแซง',
              en: 'Nong Saeng',
            },
            coordinates: {
              lat: '16.175',
              lng: '102.809',
            },
            zipcode: null,
          },
        },
      },
      4025: {
        name: {
          th: 'โนนศิลา',
          en: 'Non Sila',
        },
        tambons: {
          402501: {
            name: {
              th: 'โนนศิลา',
              en: 'Non Sila',
            },
            coordinates: {
              lat: '15.940',
              lng: '102.664',
            },
            zipcode: null,
          },
          402502: {
            name: {
              th: 'หนองปลาหมอ',
              en: 'Nong Pla Mo',
            },
            coordinates: {
              lat: '15.989',
              lng: '102.635',
            },
            zipcode: null,
          },
          402503: {
            name: {
              th: 'บ้านหัน',
              en: 'Ban Han',
            },
            coordinates: {
              lat: '15.940',
              lng: '102.720',
            },
            zipcode: null,
          },
          402504: {
            name: {
              th: 'เปือยใหญ่',
              en: 'Pueai Yai',
            },
            coordinates: {
              lat: '16.027',
              lng: '102.651',
            },
            zipcode: null,
          },
          402505: {
            name: {
              th: 'โนนแดง',
              en: 'Non Daeng',
            },
            coordinates: {
              lat: '16.005',
              lng: '102.687',
            },
            zipcode: null,
          },
        },
      },
      4026: {
        name: {
          th: 'เวียงเก่า',
          en: 'Wiang Kao',
        },
        tambons: {
          402602: {
            name: {
              th: 'ในเมือง',
              en: null,
            },
            coordinates: {
              lat: '16.742',
              lng: '102.259',
            },
            zipcode: null,
          },
          402608: {
            name: {
              th: 'เขาน้อย',
              en: null,
            },
            coordinates: {
              lat: '16.723',
              lng: '102.330',
            },
            zipcode: null,
          },
          402615: {
            name: {
              th: 'เมืองเก่าพัฒนา',
              en: null,
            },
            coordinates: {
              lat: '16.625',
              lng: '102.206',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  41: {
    name: {
      th: 'อุดรธานี',
      en: 'Udon Thani',
    },
    amphoes: {
      4101: {
        name: {
          th: 'เมืองอุดรธานี',
          en: 'Mueang Udon Thani',
        },
        tambons: {
          410101: {
            name: {
              th: 'หมากแข้ง',
              en: 'Mak Khaeng',
            },
            coordinates: {
              lat: '17.411',
              lng: '102.792',
            },
            zipcode: 41000,
          },
          410102: {
            name: {
              th: 'นิคมสงเคราะห์',
              en: 'Nikhom Songkhro',
            },
            coordinates: {
              lat: '17.365',
              lng: '102.688',
            },
            zipcode: 41000,
          },
          410103: {
            name: {
              th: 'บ้านขาว',
              en: 'Ban Khao',
            },
            coordinates: {
              lat: '17.545',
              lng: '102.775',
            },
            zipcode: 41000,
          },
          410104: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '17.406',
              lng: '102.822',
            },
            zipcode: 41000,
          },
          410105: {
            name: {
              th: 'บ้านตาด',
              en: 'Ban Tat',
            },
            coordinates: {
              lat: '17.292',
              lng: '102.762',
            },
            zipcode: 41000,
          },
          410106: {
            name: {
              th: 'โนนสูง',
              en: 'Non Sung',
            },
            coordinates: {
              lat: '17.287',
              lng: '102.846',
            },
            zipcode: 41330,
          },
          410107: {
            name: {
              th: 'หมูม่น',
              en: 'Mu Mon',
            },
            coordinates: {
              lat: '17.469',
              lng: '102.771',
            },
            zipcode: 41000,
          },
          410108: {
            name: {
              th: 'เชียงยืน',
              en: 'Chiang Yuen',
            },
            coordinates: {
              lat: '17.431',
              lng: '102.651',
            },
            zipcode: 41000,
          },
          410109: {
            name: {
              th: 'หนองนาคำ',
              en: 'Nong Na Kham',
            },
            coordinates: {
              lat: '17.367',
              lng: '102.896',
            },
            zipcode: 41000,
          },
          410110: {
            name: {
              th: 'กุดสระ',
              en: 'Kut Sa',
            },
            coordinates: {
              lat: '17.491',
              lng: '102.839',
            },
            zipcode: 41000,
          },
          410111: {
            name: {
              th: 'นาข่า',
              en: null,
            },
            coordinates: {
              lat: '17.540',
              lng: '102.849',
            },
            zipcode: 41000,
          },
          410112: {
            name: {
              th: 'บ้านเลื่อม',
              en: 'Ban Lueam',
            },
            coordinates: {
              lat: '17.421',
              lng: '102.764',
            },
            zipcode: 41000,
          },
          410113: {
            name: {
              th: 'เชียงพิณ',
              en: 'Chiang Phin',
            },
            coordinates: {
              lat: '17.417',
              lng: '102.699',
            },
            zipcode: 41000,
          },
          410114: {
            name: {
              th: 'สามพร้าว',
              en: 'Sam Phrao',
            },
            coordinates: {
              lat: '17.456',
              lng: '102.886',
            },
            zipcode: 41000,
          },
          410115: {
            name: {
              th: 'หนองไฮ',
              en: 'Nong Hai',
            },
            coordinates: {
              lat: '17.271',
              lng: '102.697',
            },
            zipcode: 41000,
          },
          410117: {
            name: {
              th: 'บ้านจั่น',
              en: 'Ban Chan',
            },
            coordinates: {
              lat: '17.343',
              lng: '102.779',
            },
            zipcode: 41000,
          },
          410118: {
            name: {
              th: 'หนองขอนกว้าง',
              en: 'Nong Khon Kwang',
            },
            coordinates: {
              lat: '17.376',
              lng: '102.816',
            },
            zipcode: 41000,
          },
          410119: {
            name: {
              th: 'โคกสะอาด',
              en: 'Khok Sa-At',
            },
            coordinates: {
              lat: '17.356',
              lng: '102.638',
            },
            zipcode: 41000,
          },
          410120: {
            name: {
              th: 'นากว้าง',
              en: 'Na Kwang',
            },
            coordinates: {
              lat: '17.533',
              lng: '102.701',
            },
            zipcode: 41000,
          },
          410121: {
            name: {
              th: 'หนองไผ่',
              en: 'Nong Phai',
            },
            coordinates: {
              lat: '17.256',
              lng: '102.898',
            },
            zipcode: 41330,
          },
        },
      },
      4102: {
        name: {
          th: 'กุดจับ',
          en: 'Kut Chap',
        },
        tambons: {
          410201: {
            name: {
              th: 'กุดจับ',
              en: 'Kut Chap',
            },
            coordinates: {
              lat: '17.382',
              lng: '102.515',
            },
            zipcode: null,
          },
          410202: {
            name: {
              th: 'ปะโค',
              en: 'Pakho',
            },
            coordinates: {
              lat: '17.480',
              lng: '102.596',
            },
            zipcode: null,
          },
          410203: {
            name: {
              th: 'ขอนยูง',
              en: 'Khon Yung',
            },
            coordinates: {
              lat: '17.385',
              lng: '102.460',
            },
            zipcode: null,
          },
          410204: {
            name: {
              th: 'เชียงเพ็ง',
              en: 'Chiang Pheng',
            },
            coordinates: {
              lat: '17.490',
              lng: '102.652',
            },
            zipcode: null,
          },
          410205: {
            name: {
              th: 'สร้างก่อ',
              en: 'Sang Ko',
            },
            coordinates: {
              lat: '17.479',
              lng: '102.506',
            },
            zipcode: 41250,
          },
          410206: {
            name: {
              th: 'เมืองเพีย',
              en: 'Mueang Phia',
            },
            coordinates: {
              lat: '17.411',
              lng: '102.574',
            },
            zipcode: 41250,
          },
          410207: {
            name: {
              th: 'ตาลเลียน',
              en: 'Tan Lian',
            },
            coordinates: {
              lat: '17.440',
              lng: '102.429',
            },
            zipcode: 41250,
          },
        },
      },
      4103: {
        name: {
          th: 'หนองวัวซอ',
          en: 'Nong Wua So',
        },
        tambons: {
          410301: {
            name: {
              th: 'หมากหญ้า',
              en: 'Mak Ya',
            },
            coordinates: {
              lat: '17.238',
              lng: '102.628',
            },
            zipcode: 41360,
          },
          410302: {
            name: {
              th: 'หนองอ้อ',
              en: 'Nong O',
            },
            coordinates: {
              lat: '17.187',
              lng: '102.541',
            },
            zipcode: 41220,
          },
          410303: {
            name: {
              th: 'อูบมุง',
              en: 'Up Mung',
            },
            coordinates: {
              lat: '17.087',
              lng: '102.564',
            },
            zipcode: 41220,
          },
          410304: {
            name: {
              th: 'กุดหมากไฟ',
              en: 'Kut Mak Fai',
            },
            coordinates: {
              lat: '17.057',
              lng: '102.645',
            },
            zipcode: 41220,
          },
          410305: {
            name: {
              th: 'น้ำพ่น',
              en: 'Nam Phon',
            },
            coordinates: {
              lat: '17.309',
              lng: '102.473',
            },
            zipcode: 41360,
          },
          410306: {
            name: {
              th: 'หนองบัวบาน',
              en: 'Nong Bua Ban',
            },
            coordinates: {
              lat: '17.288',
              lng: '102.645',
            },
            zipcode: 41360,
          },
          410307: {
            name: {
              th: 'โนนหวาย',
              en: 'Non Wai',
            },
            coordinates: {
              lat: '17.142',
              lng: '102.601',
            },
            zipcode: 41220,
          },
          410308: {
            name: {
              th: 'หนองวัวซอ',
              en: 'Nong Wua So',
            },
            coordinates: {
              lat: '17.288',
              lng: '102.576',
            },
            zipcode: 41360,
          },
        },
      },
      4104: {
        name: {
          th: 'กุมภวาปี',
          en: 'Kumphawapi',
        },
        tambons: {
          410401: {
            name: {
              th: 'ตูมใต้',
              en: 'Tum Tai',
            },
            coordinates: {
              lat: '17.084',
              lng: '102.963',
            },
            zipcode: 41110,
          },
          410402: {
            name: {
              th: 'พันดอน',
              en: 'Phan Don',
            },
            coordinates: {
              lat: '17.137',
              lng: '102.938',
            },
            zipcode: 41370,
          },
          410403: {
            name: {
              th: 'เวียงคำ',
              en: 'Wiang Kham',
            },
            coordinates: {
              lat: '17.097',
              lng: '103.116',
            },
            zipcode: 41110,
          },
          410404: {
            name: {
              th: 'แชแล',
              en: 'Chae Lae',
            },
            coordinates: {
              lat: '17.153',
              lng: '103.075',
            },
            zipcode: 41110,
          },
          410406: {
            name: {
              th: 'เชียงแหว',
              en: 'Chiang Wae',
            },
            coordinates: {
              lat: '17.198',
              lng: '103.026',
            },
            zipcode: 41110,
          },
          410407: {
            name: {
              th: 'ห้วยเกิ้ง',
              en: 'Huai Koeng',
            },
            coordinates: {
              lat: '17.044',
              lng: '102.934',
            },
            zipcode: 41110,
          },
          410409: {
            name: {
              th: 'เสอเพลอ',
              en: 'Soe Phloe',
            },
            coordinates: {
              lat: '17.202',
              lng: '102.874',
            },
            zipcode: 41370,
          },
          410410: {
            name: {
              th: 'สีออ',
              en: 'Si-O',
            },
            coordinates: {
              lat: '16.995',
              lng: '103.038',
            },
            zipcode: 41110,
          },
          410411: {
            name: {
              th: 'ปะโค',
              en: 'Pakho',
            },
            coordinates: {
              lat: '17.056',
              lng: '102.896',
            },
            zipcode: null,
          },
          410413: {
            name: {
              th: 'ผาสุก',
              en: 'Pha Suk',
            },
            coordinates: {
              lat: '17.169',
              lng: '102.940',
            },
            zipcode: 41280,
          },
          410414: {
            name: {
              th: 'ท่าลี่',
              en: 'Tha Li',
            },
            coordinates: {
              lat: '16.933',
              lng: '103.064',
            },
            zipcode: 41110,
          },
          410415: {
            name: {
              th: 'กุมภวาปี',
              en: 'Kumphawapi',
            },
            coordinates: {
              lat: '17.112',
              lng: '103.009',
            },
            zipcode: 41110,
          },
          410416: {
            name: {
              th: 'หนองหว้า',
              en: 'Nong Wa',
            },
            coordinates: {
              lat: '17.033',
              lng: '102.981',
            },
            zipcode: null,
          },
        },
      },
      4105: {
        name: {
          th: 'โนนสะอาด',
          en: 'Non Sa-at',
        },
        tambons: {
          410501: {
            name: {
              th: 'โนนสะอาด',
              en: 'Non Sa-At',
            },
            coordinates: {
              lat: '16.977',
              lng: '102.902',
            },
            zipcode: null,
          },
          410502: {
            name: {
              th: 'บุ่งแก้ว',
              en: 'Bung Kaeo',
            },
            coordinates: {
              lat: '16.969',
              lng: '102.981',
            },
            zipcode: null,
          },
          410503: {
            name: {
              th: 'โพธิ์ศรีสำราญ',
              en: 'Pho Si Samran',
            },
            coordinates: {
              lat: '16.983',
              lng: '102.839',
            },
            zipcode: null,
          },
          410504: {
            name: {
              th: 'ทมนางาม',
              en: 'Thom Na Ngam',
            },
            coordinates: {
              lat: '16.888',
              lng: '102.956',
            },
            zipcode: 41240,
          },
          410505: {
            name: {
              th: 'หนองกุงศรี',
              en: 'Nong Kung Si',
            },
            coordinates: {
              lat: '17.048',
              lng: '102.772',
            },
            zipcode: 41240,
          },
          410506: {
            name: {
              th: 'โคกกลาง',
              en: 'Khok Klang',
            },
            coordinates: {
              lat: '16.929',
              lng: '102.911',
            },
            zipcode: 41240,
          },
        },
      },
      4106: {
        name: {
          th: 'หนองหาน',
          en: 'Nong Saeng',
        },
        tambons: {
          410601: {
            name: {
              th: 'หนองหาน',
              en: 'Nong Han',
            },
            coordinates: {
              lat: '17.381',
              lng: '103.095',
            },
            zipcode: 41130,
          },
          410602: {
            name: {
              th: 'หนองเม็ก',
              en: 'Nong Mek',
            },
            coordinates: {
              lat: '17.355',
              lng: '103.169',
            },
            zipcode: 41130,
          },
          410605: {
            name: {
              th: 'พังงู',
              en: 'Phang Ngu',
            },
            coordinates: {
              lat: '17.257',
              lng: '103.117',
            },
            zipcode: 41130,
          },
          410606: {
            name: {
              th: 'สะแบง',
              en: 'Sabaeng',
            },
            coordinates: {
              lat: '17.453',
              lng: '103.116',
            },
            zipcode: 41130,
          },
          410607: {
            name: {
              th: 'สร้อยพร้าว',
              en: 'Soi Phrao',
            },
            coordinates: {
              lat: '17.430',
              lng: '103.056',
            },
            zipcode: 41130,
          },
          410609: {
            name: {
              th: 'บ้านเชียง',
              en: 'Ban Chiang',
            },
            coordinates: {
              lat: '17.398',
              lng: '103.228',
            },
            zipcode: 41320,
          },
          410610: {
            name: {
              th: 'บ้านยา',
              en: 'Ban Ya',
            },
            coordinates: {
              lat: '17.395',
              lng: '103.273',
            },
            zipcode: 41320,
          },
          410611: {
            name: {
              th: 'โพนงาม',
              en: 'Phon Ngam',
            },
            coordinates: {
              lat: '17.360',
              lng: '102.968',
            },
            zipcode: 41130,
          },
          410612: {
            name: {
              th: 'ผักตบ',
              en: 'Phak Top',
            },
            coordinates: {
              lat: '17.339',
              lng: '103.021',
            },
            zipcode: 41130,
          },
          410614: {
            name: {
              th: 'หนองไผ่',
              en: 'Nong Phai',
            },
            coordinates: {
              lat: '17.327',
              lng: '103.111',
            },
            zipcode: null,
          },
          410617: {
            name: {
              th: 'ดอนหายโศก',
              en: 'Don Hai Sok',
            },
            coordinates: {
              lat: '17.422',
              lng: '102.993',
            },
            zipcode: 41130,
          },
          410618: {
            name: {
              th: 'หนองสระปลา',
              en: 'Nong Sa Pla',
            },
            coordinates: {
              lat: '17.348',
              lng: '103.254',
            },
            zipcode: 41320,
          },
        },
      },
      4107: {
        name: {
          th: 'ทุ่งฝน',
          en: 'Thung Fon',
        },
        tambons: {
          410701: {
            name: {
              th: 'ทุ่งฝน',
              en: 'Thung Fon',
            },
            coordinates: {
              lat: '17.478',
              lng: '103.263',
            },
            zipcode: null,
          },
          410702: {
            name: {
              th: 'ทุ่งใหญ่',
              en: 'Thung Yai',
            },
            coordinates: {
              lat: '17.475',
              lng: '103.174',
            },
            zipcode: null,
          },
          410703: {
            name: {
              th: 'นาชุมแสง',
              en: 'Na Chum Saeng',
            },
            coordinates: {
              lat: '17.561',
              lng: '103.193',
            },
            zipcode: 41310,
          },
          410704: {
            name: {
              th: 'นาทม',
              en: 'Na Thom',
            },
            coordinates: {
              lat: '17.542',
              lng: '103.159',
            },
            zipcode: 41310,
          },
        },
      },
      4108: {
        name: {
          th: 'ไชยวาน',
          en: 'Chai Wan',
        },
        tambons: {
          410801: {
            name: {
              th: 'ไชยวาน',
              en: 'Chaiwan',
            },
            coordinates: {
              lat: '17.303',
              lng: '103.205',
            },
            zipcode: null,
          },
          410802: {
            name: {
              th: 'หนองหลัก',
              en: 'Nong Lak',
            },
            coordinates: {
              lat: '17.237',
              lng: '103.204',
            },
            zipcode: null,
          },
          410803: {
            name: {
              th: 'คำเลาะ',
              en: 'Kham Lo',
            },
            coordinates: {
              lat: '17.212',
              lng: '103.345',
            },
            zipcode: 41290,
          },
          410804: {
            name: {
              th: 'โพนสูง',
              en: 'Phon Sung',
            },
            coordinates: {
              lat: '17.173',
              lng: '103.240',
            },
            zipcode: null,
          },
        },
      },
      4109: {
        name: {
          th: 'ศรีธาตุ',
          en: 'Si That',
        },
        tambons: {
          410901: {
            name: {
              th: 'ศรีธาตุ',
              en: 'Si That',
            },
            coordinates: {
              lat: '16.987',
              lng: '103.211',
            },
            zipcode: 41230,
          },
          410902: {
            name: {
              th: 'จำปี',
              en: 'Champi',
            },
            coordinates: {
              lat: '16.998',
              lng: '103.171',
            },
            zipcode: 41230,
          },
          410903: {
            name: {
              th: 'บ้านโปร่ง',
              en: 'Ban Prong',
            },
            coordinates: {
              lat: '16.991',
              lng: '103.089',
            },
            zipcode: 41230,
          },
          410904: {
            name: {
              th: 'หัวนาคำ',
              en: 'Hua Na Kham',
            },
            coordinates: {
              lat: '17.083',
              lng: '103.284',
            },
            zipcode: 41230,
          },
          410905: {
            name: {
              th: 'หนองนกเขียน',
              en: 'Nong Nok Khian',
            },
            coordinates: {
              lat: '17.012',
              lng: '103.291',
            },
            zipcode: 41230,
          },
          410906: {
            name: {
              th: 'นายูง',
              en: 'Na Yung',
            },
            coordinates: {
              lat: '16.952',
              lng: '103.308',
            },
            zipcode: 41230,
          },
          410907: {
            name: {
              th: 'ตาดทอง',
              en: 'Tat Thong',
            },
            coordinates: {
              lat: '17.067',
              lng: '103.155',
            },
            zipcode: 41230,
          },
        },
      },
      4110: {
        name: {
          th: 'วังสามหมอ',
          en: 'Wang Sam Mo',
        },
        tambons: {
          411001: {
            name: {
              th: 'หนองกุงทับม้า',
              en: 'Nong Kung Thap Ma',
            },
            coordinates: {
              lat: '17.019',
              lng: '103.472',
            },
            zipcode: 41280,
          },
          411002: {
            name: {
              th: 'หนองหญ้าไซ',
              en: 'Nong Ya Sai',
            },
            coordinates: {
              lat: '16.952',
              lng: '103.368',
            },
            zipcode: 41280,
          },
          411003: {
            name: {
              th: 'บะยาว',
              en: 'Bayao',
            },
            coordinates: {
              lat: '17.084',
              lng: '103.405',
            },
            zipcode: 41280,
          },
          411004: {
            name: {
              th: 'ผาสุก',
              en: 'Pha Suk',
            },
            coordinates: {
              lat: '17.134',
              lng: '103.537',
            },
            zipcode: null,
          },
          411005: {
            name: {
              th: 'คำโคกสูง',
              en: 'Kham Khok Sung',
            },
            coordinates: {
              lat: '16.882',
              lng: '103.426',
            },
            zipcode: 41280,
          },
          411006: {
            name: {
              th: 'วังสามหมอ',
              en: 'Wang Sam Mo',
            },
            coordinates: {
              lat: '16.941',
              lng: '103.456',
            },
            zipcode: 41280,
          },
        },
      },
      4111: {
        name: {
          th: 'บ้านดุง',
          en: 'Ban Dung',
        },
        tambons: {
          411101: {
            name: {
              th: 'ศรีสุทโธ',
              en: 'Si Suttho',
            },
            coordinates: {
              lat: '17.684',
              lng: '103.259',
            },
            zipcode: 41190,
          },
          411102: {
            name: {
              th: 'บ้านดุง',
              en: 'Ban Dung',
            },
            coordinates: {
              lat: '17.741',
              lng: '103.250',
            },
            zipcode: 41190,
          },
          411103: {
            name: {
              th: 'ดงเย็น',
              en: 'Dong Yen',
            },
            coordinates: {
              lat: '17.637',
              lng: '103.379',
            },
            zipcode: 41190,
          },
          411104: {
            name: {
              th: 'โพนสูง',
              en: 'Phon Sung',
            },
            coordinates: {
              lat: '17.672',
              lng: '103.296',
            },
            zipcode: null,
          },
          411105: {
            name: {
              th: 'อ้อมกอ',
              en: 'Om Ko',
            },
            coordinates: {
              lat: '17.594',
              lng: '103.305',
            },
            zipcode: 41190,
          },
          411106: {
            name: {
              th: 'บ้านจันทน์',
              en: 'Ban Chan',
            },
            coordinates: {
              lat: '17.796',
              lng: '103.296',
            },
            zipcode: null,
          },
          411107: {
            name: {
              th: 'บ้านชัย',
              en: 'Ban Chai',
            },
            coordinates: {
              lat: '17.623',
              lng: '103.190',
            },
            zipcode: 41190,
          },
          411108: {
            name: {
              th: 'นาไหม',
              en: 'Na Mai',
            },
            coordinates: {
              lat: '17.768',
              lng: '103.170',
            },
            zipcode: 41190,
          },
          411109: {
            name: {
              th: 'ถ่อนนาลับ',
              en: 'Thon Na Lap',
            },
            coordinates: {
              lat: '17.844',
              lng: '103.199',
            },
            zipcode: 41190,
          },
          411110: {
            name: {
              th: 'วังทอง',
              en: 'Wang Thong',
            },
            coordinates: {
              lat: '17.759',
              lng: '103.320',
            },
            zipcode: 41190,
          },
          411111: {
            name: {
              th: 'บ้านม่วง',
              en: 'Ban Muang',
            },
            coordinates: {
              lat: '17.713',
              lng: '103.362',
            },
            zipcode: 41190,
          },
          411112: {
            name: {
              th: 'บ้านตาด',
              en: 'Ban Tat',
            },
            coordinates: {
              lat: '17.573',
              lng: '103.248',
            },
            zipcode: null,
          },
          411113: {
            name: {
              th: 'นาคำ',
              en: 'Na Kham',
            },
            coordinates: {
              lat: '17.691',
              lng: '103.170',
            },
            zipcode: 41190,
          },
        },
      },
      4117: {
        name: {
          th: 'บ้านผือ',
          en: 'Ban Phue',
        },
        tambons: {
          411701: {
            name: {
              th: 'บ้านผือ',
              en: 'Ban Phue',
            },
            coordinates: {
              lat: '17.697',
              lng: '102.448',
            },
            zipcode: 41160,
          },
          411702: {
            name: {
              th: 'หายโศก',
              en: 'Hai Sok',
            },
            coordinates: {
              lat: '17.692',
              lng: '102.531',
            },
            zipcode: 41160,
          },
          411703: {
            name: {
              th: 'เขือน้ำ',
              en: 'Khuea Nam',
            },
            coordinates: {
              lat: '17.565',
              lng: '102.646',
            },
            zipcode: 41160,
          },
          411704: {
            name: {
              th: 'คำบง',
              en: 'Kham Bong',
            },
            coordinates: {
              lat: '17.540',
              lng: '102.556',
            },
            zipcode: 41160,
          },
          411705: {
            name: {
              th: 'โนนทอง',
              en: 'Non Thong',
            },
            coordinates: {
              lat: '17.560',
              lng: '102.424',
            },
            zipcode: 41160,
          },
          411706: {
            name: {
              th: 'ข้าวสาร',
              en: 'Khao San',
            },
            coordinates: {
              lat: '17.634',
              lng: '102.439',
            },
            zipcode: 41160,
          },
          411707: {
            name: {
              th: 'จำปาโมง',
              en: 'Champa Mok',
            },
            coordinates: {
              lat: '17.613',
              lng: '102.376',
            },
            zipcode: 41160,
          },
          411708: {
            name: {
              th: 'กลางใหญ่',
              en: 'Klang Yai',
            },
            coordinates: {
              lat: '17.785',
              lng: '102.375',
            },
            zipcode: 41160,
          },
          411709: {
            name: {
              th: 'เมืองพาน',
              en: 'Mueang Phan',
            },
            coordinates: {
              lat: '17.684',
              lng: '102.365',
            },
            zipcode: 41160,
          },
          411710: {
            name: {
              th: 'คำด้วง',
              en: 'Kham Duang',
            },
            coordinates: {
              lat: '17.872',
              lng: '102.346',
            },
            zipcode: 41160,
          },
          411711: {
            name: {
              th: 'หนองหัวคู',
              en: 'Nong Hua Khu',
            },
            coordinates: {
              lat: '17.574',
              lng: '102.560',
            },
            zipcode: 41160,
          },
          411712: {
            name: {
              th: 'บ้านค้อ',
              en: 'Ban Kho',
            },
            coordinates: {
              lat: '17.750',
              lng: '102.446',
            },
            zipcode: null,
          },
          411713: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '17.509',
              lng: '102.391',
            },
            zipcode: 41160,
          },
        },
      },
      4118: {
        name: {
          th: 'น้ำโสม',
          en: 'Nam Som',
        },
        tambons: {
          411801: {
            name: {
              th: 'นางัว',
              en: 'Na Ngua',
            },
            coordinates: {
              lat: '17.741',
              lng: '102.215',
            },
            zipcode: 41210,
          },
          411802: {
            name: {
              th: 'น้ำโสม',
              en: 'Nam Som',
            },
            coordinates: {
              lat: '17.715',
              lng: '102.092',
            },
            zipcode: 41210,
          },
          411805: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '17.810',
              lng: '102.300',
            },
            zipcode: null,
          },
          411806: {
            name: {
              th: 'บ้านหยวก',
              en: 'Ban Yuak',
            },
            coordinates: {
              lat: '17.697',
              lng: '102.187',
            },
            zipcode: 41210,
          },
          411807: {
            name: {
              th: 'โสมเยี่ยม',
              en: 'Som Yiam',
            },
            coordinates: {
              lat: '17.823',
              lng: '102.207',
            },
            zipcode: 41210,
          },
          411810: {
            name: {
              th: 'ศรีสำราญ',
              en: 'Si Samran',
            },
            coordinates: {
              lat: '17.732',
              lng: '102.162',
            },
            zipcode: 41210,
          },
          411812: {
            name: {
              th: 'สามัคคี',
              en: 'Samakkhi',
            },
            coordinates: {
              lat: '17.730',
              lng: '102.288',
            },
            zipcode: 41210,
          },
        },
      },
      4119: {
        name: {
          th: 'เพ็ญ',
          en: 'Phen',
        },
        tambons: {
          411901: {
            name: {
              th: 'เพ็ญ',
              en: 'Phen',
            },
            coordinates: {
              lat: '17.673',
              lng: '102.936',
            },
            zipcode: 41150,
          },
          411902: {
            name: {
              th: 'บ้านธาตุ',
              en: 'Ban That',
            },
            coordinates: {
              lat: '17.695',
              lng: '102.850',
            },
            zipcode: 41150,
          },
          411903: {
            name: {
              th: 'นาพู่',
              en: 'Na Phu',
            },
            coordinates: {
              lat: '17.600',
              lng: '102.764',
            },
            zipcode: 41150,
          },
          411904: {
            name: {
              th: 'เชียงหวาง',
              en: 'Chiang Wang',
            },
            coordinates: {
              lat: '17.617',
              lng: '102.849',
            },
            zipcode: 41150,
          },
          411905: {
            name: {
              th: 'สุมเส้า',
              en: 'Sum Sao',
            },
            coordinates: {
              lat: '17.603',
              lng: '102.984',
            },
            zipcode: 41150,
          },
          411906: {
            name: {
              th: 'นาบัว',
              en: 'Na Bua',
            },
            coordinates: {
              lat: '17.551',
              lng: '102.976',
            },
            zipcode: 41150,
          },
          411907: {
            name: {
              th: 'บ้านเหล่า',
              en: 'Ban Lao',
            },
            coordinates: {
              lat: '17.685',
              lng: '103.040',
            },
            zipcode: 41150,
          },
          411908: {
            name: {
              th: 'จอมศรี',
              en: 'Chom Si',
            },
            coordinates: {
              lat: '17.845',
              lng: '102.899',
            },
            zipcode: 41150,
          },
          411909: {
            name: {
              th: 'เตาไห',
              en: 'Tao Hai',
            },
            coordinates: {
              lat: '17.634',
              lng: '103.066',
            },
            zipcode: 41150,
          },
          411910: {
            name: {
              th: 'โคกกลาง',
              en: 'Khok Klang',
            },
            coordinates: {
              lat: '17.729',
              lng: '102.979',
            },
            zipcode: null,
          },
          411911: {
            name: {
              th: 'สร้างแป้น',
              en: 'Sang Paen',
            },
            coordinates: {
              lat: '17.513',
              lng: '102.944',
            },
            zipcode: 41150,
          },
        },
      },
      4120: {
        name: {
          th: 'สร้างคอม',
          en: 'Sang Khom',
        },
        tambons: {
          412001: {
            name: {
              th: 'สร้างคอม',
              en: 'Sang Khom',
            },
            coordinates: {
              lat: '17.814',
              lng: '103.090',
            },
            zipcode: null,
          },
          412002: {
            name: {
              th: 'เชียงดา',
              en: 'Chiang Da',
            },
            coordinates: {
              lat: '17.859',
              lng: '103.027',
            },
            zipcode: null,
          },
          412003: {
            name: {
              th: 'บ้านยวด',
              en: 'Ban Yuat',
            },
            coordinates: {
              lat: '17.734',
              lng: '103.040',
            },
            zipcode: null,
          },
          412004: {
            name: {
              th: 'บ้านโคก',
              en: 'Ban Khok',
            },
            coordinates: {
              lat: '17.786',
              lng: '103.016',
            },
            zipcode: null,
          },
          412005: {
            name: {
              th: 'นาสะอาด',
              en: 'Na Sa-At',
            },
            coordinates: {
              lat: '17.843',
              lng: '103.058',
            },
            zipcode: 41260,
          },
          412006: {
            name: {
              th: 'บ้านหินโงม',
              en: 'Ban Hin Ngom',
            },
            coordinates: {
              lat: '17.738',
              lng: '103.099',
            },
            zipcode: 41260,
          },
        },
      },
      4121: {
        name: {
          th: 'หนองแสง',
          en: 'Nong Saeng',
        },
        tambons: {
          412101: {
            name: {
              th: 'หนองแสง',
              en: 'Nong Saeng',
            },
            coordinates: {
              lat: '17.172',
              lng: '102.842',
            },
            zipcode: null,
          },
          412102: {
            name: {
              th: 'แสงสว่าง',
              en: 'Saeng Sawang',
            },
            coordinates: {
              lat: '17.121',
              lng: '102.804',
            },
            zipcode: null,
          },
          412103: {
            name: {
              th: 'นาดี',
              en: 'Na Di',
            },
            coordinates: {
              lat: '17.081',
              lng: '102.844',
            },
            zipcode: null,
          },
          412104: {
            name: {
              th: 'ทับกุง',
              en: 'Thap Kung',
            },
            coordinates: {
              lat: '17.182',
              lng: '102.737',
            },
            zipcode: 41340,
          },
        },
      },
      4122: {
        name: {
          th: 'นายูง',
          en: 'Na Yung',
        },
        tambons: {
          412201: {
            name: {
              th: 'นายูง',
              en: 'Na Yung',
            },
            coordinates: {
              lat: '17.916',
              lng: '102.213',
            },
            zipcode: null,
          },
          412202: {
            name: {
              th: 'บ้านก้อง',
              en: 'Ban Kong',
            },
            coordinates: {
              lat: '17.821',
              lng: '102.079',
            },
            zipcode: null,
          },
          412203: {
            name: {
              th: 'นาแค',
              en: 'Na Khae',
            },
            coordinates: {
              lat: '17.963',
              lng: '102.085',
            },
            zipcode: null,
          },
          412204: {
            name: {
              th: 'โนนทอง',
              en: 'Non Thong',
            },
            coordinates: {
              lat: '17.989',
              lng: '102.198',
            },
            zipcode: null,
          },
        },
      },
      4123: {
        name: {
          th: 'พิบูลย์รักษ์',
          en: 'Phibun Rak',
        },
        tambons: {
          412301: {
            name: {
              th: 'บ้านแดง',
              en: 'Ban Daeng',
            },
            coordinates: {
              lat: '17.539',
              lng: '103.072',
            },
            zipcode: null,
          },
          412302: {
            name: {
              th: 'นาทราย',
              en: 'Na Sai',
            },
            coordinates: {
              lat: '17.563',
              lng: '103.123',
            },
            zipcode: null,
          },
          412303: {
            name: {
              th: 'ดอนกลอย',
              en: 'Don Kloi',
            },
            coordinates: {
              lat: '17.480',
              lng: '103.023',
            },
            zipcode: null,
          },
        },
      },
      4124: {
        name: {
          th: 'กู่แก้ว',
          en: 'Ku Kaeo',
        },
        tambons: {
          412401: {
            name: {
              th: 'บ้านจีต',
              en: 'Ban Chiet',
            },
            coordinates: {
              lat: '17.135',
              lng: '103.197',
            },
            zipcode: null,
          },
          412402: {
            name: {
              th: 'โนนทองอินทร์',
              en: 'Non Thong In',
            },
            coordinates: {
              lat: '17.197',
              lng: '103.177',
            },
            zipcode: null,
          },
          412403: {
            name: {
              th: 'ค้อใหญ่',
              en: 'Kho Yai',
            },
            coordinates: {
              lat: '17.205',
              lng: '103.141',
            },
            zipcode: null,
          },
          412404: {
            name: {
              th: 'คอนสาย',
              en: 'Khon Sai',
            },
            coordinates: {
              lat: '17.158',
              lng: '103.134',
            },
            zipcode: null,
          },
        },
      },
      4125: {
        name: {
          th: 'ประจักษ์ศิลปาคม',
          en: 'Prachaksinlapakhom',
        },
        tambons: {
          412501: {
            name: {
              th: 'นาม่วง',
              en: 'Na Muang',
            },
            coordinates: {
              lat: '17.256',
              lng: '102.976',
            },
            zipcode: null,
          },
          412502: {
            name: {
              th: 'ห้วยสามพาด',
              en: 'Huai Sam Phat',
            },
            coordinates: {
              lat: '17.237',
              lng: '102.934',
            },
            zipcode: null,
          },
          412503: {
            name: {
              th: 'อุ่มจาน',
              en: 'Um Chan',
            },
            coordinates: {
              lat: '17.234',
              lng: '103.062',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  42: {
    name: {
      th: 'เลย',
      en: 'Loei',
    },
    amphoes: {
      4201: {
        name: {
          th: 'เมืองเลย',
          en: 'Mueang Loei',
        },
        tambons: {
          420101: {
            name: {
              th: 'กุดป่อง',
              en: 'Kut Pong',
            },
            coordinates: {
              lat: '17.493',
              lng: '101.732',
            },
            zipcode: 42000,
          },
          420102: {
            name: {
              th: 'เมือง',
              en: 'Mueang',
            },
            coordinates: {
              lat: '17.523',
              lng: '101.705',
            },
            zipcode: 42000,
          },
          420103: {
            name: {
              th: 'นาอ้อ',
              en: 'Na O',
            },
            coordinates: {
              lat: '17.582',
              lng: '101.732',
            },
            zipcode: 42100,
          },
          420104: {
            name: {
              th: 'กกดู่',
              en: 'Kok Du',
            },
            coordinates: {
              lat: '17.604',
              lng: '101.635',
            },
            zipcode: 42000,
          },
          420105: {
            name: {
              th: 'น้ำหมาน',
              en: 'Nam Man',
            },
            coordinates: {
              lat: '17.525',
              lng: '101.646',
            },
            zipcode: 42000,
          },
          420106: {
            name: {
              th: 'เสี้ยว',
              en: 'Siao',
            },
            coordinates: {
              lat: '17.458',
              lng: '101.636',
            },
            zipcode: 42000,
          },
          420107: {
            name: {
              th: 'นาอาน',
              en: 'Na An',
            },
            coordinates: {
              lat: '17.460',
              lng: '101.741',
            },
            zipcode: 42000,
          },
          420108: {
            name: {
              th: 'นาโป่ง',
              en: 'Na Pong',
            },
            coordinates: {
              lat: '17.408',
              lng: '101.688',
            },
            zipcode: 42000,
          },
          420109: {
            name: {
              th: 'นาดินดำ',
              en: 'Na Din Dam',
            },
            coordinates: {
              lat: '17.455',
              lng: '101.842',
            },
            zipcode: 42000,
          },
          420110: {
            name: {
              th: 'น้ำสวย',
              en: 'Nam Suai',
            },
            coordinates: {
              lat: '17.613',
              lng: '101.852',
            },
            zipcode: 42000,
          },
          420111: {
            name: {
              th: 'ชัยพฤกษ์',
              en: 'Chaiyaphruek',
            },
            coordinates: {
              lat: '17.523',
              lng: '101.777',
            },
            zipcode: 42000,
          },
          420112: {
            name: {
              th: 'นาแขม',
              en: 'Na Khaem',
            },
            coordinates: {
              lat: '17.687',
              lng: '101.660',
            },
            zipcode: 42000,
          },
          420113: {
            name: {
              th: 'ศรีสองรัก',
              en: 'Si Song Rak',
            },
            coordinates: {
              lat: '17.642',
              lng: '101.769',
            },
            zipcode: 42100,
          },
          420114: {
            name: {
              th: 'กกทอง',
              en: 'Kok Thong',
            },
            coordinates: {
              lat: '17.519',
              lng: '101.562',
            },
            zipcode: 42000,
          },
        },
      },
      4202: {
        name: {
          th: 'นาด้วง',
          en: 'Na Duang',
        },
        tambons: {
          420201: {
            name: {
              th: 'นาด้วง',
              en: 'Na Duang',
            },
            coordinates: {
              lat: '17.500',
              lng: '102.043',
            },
            zipcode: 42210,
          },
          420202: {
            name: {
              th: 'นาดอกคำ',
              en: 'Na Dok Kham',
            },
            coordinates: {
              lat: '17.591',
              lng: '101.990',
            },
            zipcode: 42210,
          },
          420203: {
            name: {
              th: 'ท่าสะอาด',
              en: 'Tha Sa-At',
            },
            coordinates: {
              lat: '17.497',
              lng: '101.917',
            },
            zipcode: 42210,
          },
          420204: {
            name: {
              th: 'ท่าสวรรค์',
              en: 'Tha Sawan',
            },
            coordinates: {
              lat: '17.432',
              lng: '101.940',
            },
            zipcode: 42210,
          },
        },
      },
      4203: {
        name: {
          th: 'เชียงคาน',
          en: 'Chiang Khan',
        },
        tambons: {
          420301: {
            name: {
              th: 'เชียงคาน',
              en: 'Chiang Khan',
            },
            coordinates: {
              lat: '17.881',
              lng: '101.665',
            },
            zipcode: 42110,
          },
          420302: {
            name: {
              th: 'ธาตุ',
              en: 'That',
            },
            coordinates: {
              lat: '17.738',
              lng: '101.847',
            },
            zipcode: 42110,
          },
          420303: {
            name: {
              th: 'นาซ่าว',
              en: 'Na Sao',
            },
            coordinates: {
              lat: '17.814',
              lng: '101.663',
            },
            zipcode: 42110,
          },
          420304: {
            name: {
              th: 'เขาแก้ว',
              en: 'Khao Kaeo',
            },
            coordinates: {
              lat: '17.820',
              lng: '101.781',
            },
            zipcode: 42110,
          },
          420305: {
            name: {
              th: 'ปากตม',
              en: 'Pak Tom',
            },
            coordinates: {
              lat: '17.768',
              lng: '101.554',
            },
            zipcode: 42110,
          },
          420306: {
            name: {
              th: 'บุฮม',
              en: 'Bu Hom',
            },
            coordinates: {
              lat: '17.958',
              lng: '101.780',
            },
            zipcode: 42110,
          },
          420307: {
            name: {
              th: 'จอมศรี',
              en: 'Chom Si',
            },
            coordinates: {
              lat: '17.763',
              lng: '101.735',
            },
            zipcode: 42110,
          },
          420308: {
            name: {
              th: 'หาดทรายขาว',
              en: 'Hat Sai Khao',
            },
            coordinates: {
              lat: '17.743',
              lng: '101.658',
            },
            zipcode: 42110,
          },
        },
      },
      4204: {
        name: {
          th: 'ปากชม',
          en: 'Pak Chom',
        },
        tambons: {
          420401: {
            name: {
              th: 'ปากชม',
              en: 'Pak Chom',
            },
            coordinates: {
              lat: '17.960',
              lng: '101.902',
            },
            zipcode: 42150,
          },
          420402: {
            name: {
              th: 'เชียงกลม',
              en: 'Chiang Klom',
            },
            coordinates: {
              lat: '17.851',
              lng: '101.962',
            },
            zipcode: 42150,
          },
          420403: {
            name: {
              th: 'หาดคัมภีร์',
              en: 'Hat Khamphi',
            },
            coordinates: {
              lat: '18.111',
              lng: '102.010',
            },
            zipcode: 42150,
          },
          420404: {
            name: {
              th: 'ห้วยบ่อซืน',
              en: 'Huai Bo Suen',
            },
            coordinates: {
              lat: '17.730',
              lng: '101.986',
            },
            zipcode: 42150,
          },
          420405: {
            name: {
              th: 'ห้วยพิชัย',
              en: 'Huai Phichai',
            },
            coordinates: {
              lat: '18.017',
              lng: '101.971',
            },
            zipcode: 42150,
          },
          420406: {
            name: {
              th: 'ชมเจริญ',
              en: 'Chom Charoen',
            },
            coordinates: {
              lat: '17.700',
              lng: '101.922',
            },
            zipcode: 42150,
          },
        },
      },
      4205: {
        name: {
          th: 'ด่านซ้าย',
          en: 'Dan Sai',
        },
        tambons: {
          420501: {
            name: {
              th: 'ด่านซ้าย',
              en: 'Dan Sai',
            },
            coordinates: {
              lat: '17.267',
              lng: '101.155',
            },
            zipcode: 42120,
          },
          420502: {
            name: {
              th: 'ปากหมัน',
              en: 'Pak Man',
            },
            coordinates: {
              lat: '17.483',
              lng: '101.223',
            },
            zipcode: 42120,
          },
          420503: {
            name: {
              th: 'นาดี',
              en: 'Na Di',
            },
            coordinates: {
              lat: '17.420',
              lng: '101.154',
            },
            zipcode: 42120,
          },
          420504: {
            name: {
              th: 'โคกงาม',
              en: 'Khok Ngam',
            },
            coordinates: {
              lat: '17.385',
              lng: '101.233',
            },
            zipcode: 42120,
          },
          420505: {
            name: {
              th: 'โพนสูง',
              en: 'Phon Sung',
            },
            coordinates: {
              lat: '17.277',
              lng: '101.282',
            },
            zipcode: 42120,
          },
          420506: {
            name: {
              th: 'อิปุ่ม',
              en: 'I Pum',
            },
            coordinates: {
              lat: '17.187',
              lng: '101.254',
            },
            zipcode: 42120,
          },
          420507: {
            name: {
              th: 'กกสะทอน',
              en: 'Kok Sathon',
            },
            coordinates: {
              lat: '17.041',
              lng: '101.090',
            },
            zipcode: 42120,
          },
          420508: {
            name: {
              th: 'โป่ง',
              en: 'Pong',
            },
            coordinates: {
              lat: '17.156',
              lng: '101.198',
            },
            zipcode: 42120,
          },
          420509: {
            name: {
              th: 'วังยาว',
              en: 'Wang Yao',
            },
            coordinates: {
              lat: '17.210',
              lng: '101.328',
            },
            zipcode: 42120,
          },
          420510: {
            name: {
              th: 'นาหอ',
              en: 'Na Ho',
            },
            coordinates: {
              lat: '17.331',
              lng: '101.142',
            },
            zipcode: 42120,
          },
        },
      },
      4206: {
        name: {
          th: 'นาแห้ว',
          en: 'Na Haeo',
        },
        tambons: {
          420601: {
            name: {
              th: 'นาแห้ว',
              en: 'Na Haeo',
            },
            coordinates: {
              lat: '17.484',
              lng: '101.066',
            },
            zipcode: null,
          },
          420602: {
            name: {
              th: 'แสงภา',
              en: 'Saeng Pha',
            },
            coordinates: {
              lat: '17.507',
              lng: '100.965',
            },
            zipcode: null,
          },
          420603: {
            name: {
              th: 'นาพึง',
              en: 'Na Phueng',
            },
            coordinates: {
              lat: '17.416',
              lng: '101.027',
            },
            zipcode: 42170,
          },
          420604: {
            name: {
              th: 'นามาลา',
              en: 'Na Mala',
            },
            coordinates: {
              lat: '17.336',
              lng: '101.071',
            },
            zipcode: 42170,
          },
          420605: {
            name: {
              th: 'เหล่ากอหก',
              en: 'Lao Ko Hok',
            },
            coordinates: {
              lat: '17.485',
              lng: '100.864',
            },
            zipcode: 42170,
          },
        },
      },
      4207: {
        name: {
          th: 'ภูเรือ',
          en: 'Phu Ruea',
        },
        tambons: {
          420701: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '17.454',
              lng: '101.371',
            },
            zipcode: null,
          },
          420702: {
            name: {
              th: 'ท่าศาลา',
              en: 'Tha Sala',
            },
            coordinates: {
              lat: '17.391',
              lng: '101.463',
            },
            zipcode: null,
          },
          420703: {
            name: {
              th: 'ร่องจิก',
              en: 'Rong Chik',
            },
            coordinates: {
              lat: '17.346',
              lng: '101.333',
            },
            zipcode: null,
          },
          420704: {
            name: {
              th: 'ปลาบ่า',
              en: 'Pla Ba',
            },
            coordinates: {
              lat: '17.318',
              lng: '101.439',
            },
            zipcode: null,
          },
          420705: {
            name: {
              th: 'ลาดค่าง',
              en: 'Lat Khang',
            },
            coordinates: {
              lat: '17.497',
              lng: '101.285',
            },
            zipcode: 42160,
          },
          420706: {
            name: {
              th: 'สานตม',
              en: 'Santom',
            },
            coordinates: {
              lat: '17.460',
              lng: '101.482',
            },
            zipcode: 42160,
          },
        },
      },
      4208: {
        name: {
          th: 'ท่าลี่',
          en: 'Tha Li',
        },
        tambons: {
          420801: {
            name: {
              th: 'ท่าลี่',
              en: 'Tha Li',
            },
            coordinates: {
              lat: '17.573',
              lng: '101.412',
            },
            zipcode: 42140,
          },
          420802: {
            name: {
              th: 'หนองผือ',
              en: 'Nong Phue',
            },
            coordinates: {
              lat: '17.678',
              lng: '101.448',
            },
            zipcode: 42140,
          },
          420803: {
            name: {
              th: 'อาฮี',
              en: 'A Hi',
            },
            coordinates: {
              lat: '17.636',
              lng: '101.347',
            },
            zipcode: 42140,
          },
          420804: {
            name: {
              th: 'น้ำแคม',
              en: 'Nam Khaem',
            },
            coordinates: {
              lat: '17.676',
              lng: '101.527',
            },
            zipcode: 42140,
          },
          420805: {
            name: {
              th: 'โคกใหญ่',
              en: 'Khok Yai',
            },
            coordinates: {
              lat: '17.556',
              lng: '101.491',
            },
            zipcode: 42140,
          },
          420806: {
            name: {
              th: 'น้ำทูน',
              en: 'Nam Thun',
            },
            coordinates: {
              lat: '17.592',
              lng: '101.288',
            },
            zipcode: 42140,
          },
        },
      },
      4209: {
        name: {
          th: 'วังสะพุง',
          en: 'Wang Saphung',
        },
        tambons: {
          420901: {
            name: {
              th: 'วังสะพุง',
              en: 'Wang Saphung',
            },
            coordinates: {
              lat: '17.296',
              lng: '101.746',
            },
            zipcode: 42130,
          },
          420902: {
            name: {
              th: 'ทรายขาว',
              en: 'Sai Khao',
            },
            coordinates: {
              lat: '17.213',
              lng: '101.535',
            },
            zipcode: 42130,
          },
          420903: {
            name: {
              th: 'หนองหญ้าปล้อง',
              en: 'Nong Ya Plong',
            },
            coordinates: {
              lat: '17.234',
              lng: '101.926',
            },
            zipcode: 42130,
          },
          420904: {
            name: {
              th: 'หนองงิ้ว',
              en: 'Nong Ngio',
            },
            coordinates: {
              lat: '17.374',
              lng: '101.571',
            },
            zipcode: 42130,
          },
          420905: {
            name: {
              th: 'ปากปวน',
              en: 'Pak Puan',
            },
            coordinates: {
              lat: '17.357',
              lng: '101.739',
            },
            zipcode: 42130,
          },
          420906: {
            name: {
              th: 'ผาน้อย',
              en: 'Pha Noi',
            },
            coordinates: {
              lat: '17.351',
              lng: '101.831',
            },
            zipcode: 42130,
          },
          420910: {
            name: {
              th: 'ผาบิ้ง',
              en: 'Pha Bing',
            },
            coordinates: {
              lat: '17.193',
              lng: '101.773',
            },
            zipcode: 42130,
          },
          420911: {
            name: {
              th: 'เขาหลวง',
              en: 'Khao Luang',
            },
            coordinates: {
              lat: '17.336',
              lng: '101.674',
            },
            zipcode: 42130,
          },
          420912: {
            name: {
              th: 'โคกขมิ้น',
              en: 'Khok Khamin',
            },
            coordinates: {
              lat: '17.171',
              lng: '101.938',
            },
            zipcode: 42130,
          },
          420913: {
            name: {
              th: 'ศรีสงคราม',
              en: 'Si Songkhram',
            },
            coordinates: {
              lat: '17.254',
              lng: '101.799',
            },
            zipcode: 42130,
          },
        },
      },
      4210: {
        name: {
          th: 'ภูกระดึง',
          en: 'Phu Kradueng',
        },
        tambons: {
          421001: {
            name: {
              th: 'ศรีฐาน',
              en: 'Si Than',
            },
            coordinates: {
              lat: '16.880',
              lng: '101.771',
            },
            zipcode: 42180,
          },
          421005: {
            name: {
              th: 'ผานกเค้า',
              en: 'Pha Nok Khao',
            },
            coordinates: {
              lat: '16.896',
              lng: '101.986',
            },
            zipcode: 42180,
          },
          421007: {
            name: {
              th: 'ภูกระดึง',
              en: 'Phu Kradueng',
            },
            coordinates: {
              lat: '16.971',
              lng: '101.850',
            },
            zipcode: 42180,
          },
          421010: {
            name: {
              th: 'ห้วยส้ม',
              en: 'Huai Som',
            },
            coordinates: {
              lat: '16.937',
              lng: '101.939',
            },
            zipcode: 42180,
          },
        },
      },
      4211: {
        name: {
          th: 'ภูหลวง',
          en: 'Phu Luang',
        },
        tambons: {
          421101: {
            name: {
              th: 'ภูหอ',
              en: 'Phu Ho',
            },
            coordinates: {
              lat: '17.135',
              lng: '101.629',
            },
            zipcode: null,
          },
          421102: {
            name: {
              th: 'หนองคัน',
              en: 'Nong Khan',
            },
            coordinates: {
              lat: '17.141',
              lng: '101.701',
            },
            zipcode: null,
          },
          421104: {
            name: {
              th: 'ห้วยสีเสียด',
              en: 'Huai Si Siat',
            },
            coordinates: {
              lat: '17.138',
              lng: '101.740',
            },
            zipcode: 42230,
          },
          421105: {
            name: {
              th: 'เลยวังไสย์',
              en: 'Loei Wang Sai',
            },
            coordinates: {
              lat: '17.086',
              lng: '101.549',
            },
            zipcode: 42230,
          },
          421106: {
            name: {
              th: 'แก่งศรีภูมิ',
              en: 'Kaeng Si Phum',
            },
            coordinates: {
              lat: '17.049',
              lng: '101.678',
            },
            zipcode: 42230,
          },
        },
      },
      4212: {
        name: {
          th: 'ผาขาว',
          en: 'Pha  Khao',
        },
        tambons: {
          421201: {
            name: {
              th: 'ผาขาว',
              en: 'Pha Khao',
            },
            coordinates: {
              lat: '17.019',
              lng: '101.960',
            },
            zipcode: null,
          },
          421202: {
            name: {
              th: 'ท่าช้างคล้อง',
              en: 'Tha Chang Khlong',
            },
            coordinates: {
              lat: '17.019',
              lng: '102.090',
            },
            zipcode: null,
          },
          421203: {
            name: {
              th: 'โนนปอแดง',
              en: 'Non Po Daeng',
            },
            coordinates: {
              lat: '16.983',
              lng: '101.985',
            },
            zipcode: null,
          },
          421204: {
            name: {
              th: 'โนนป่าซาง',
              en: 'Non Pa Sang',
            },
            coordinates: {
              lat: '17.102',
              lng: '102.052',
            },
            zipcode: null,
          },
          421205: {
            name: {
              th: 'บ้านเพิ่ม',
              en: 'Ban Phoem',
            },
            coordinates: {
              lat: '17.100',
              lng: '101.938',
            },
            zipcode: 42240,
          },
        },
      },
      4213: {
        name: {
          th: 'เอราวัณ',
          en: 'Erawan',
        },
        tambons: {
          421301: {
            name: {
              th: 'เอราวัณ',
              en: 'Erawan',
            },
            coordinates: {
              lat: '17.373',
              lng: '101.892',
            },
            zipcode: null,
          },
          421302: {
            name: {
              th: 'ผาอินทร์แปลง',
              en: 'Pha In Plaeng',
            },
            coordinates: {
              lat: '17.303',
              lng: '101.962',
            },
            zipcode: null,
          },
          421303: {
            name: {
              th: 'ผาสามยอด',
              en: 'Pha Sam Yot',
            },
            coordinates: {
              lat: '17.228',
              lng: '102.066',
            },
            zipcode: null,
          },
          421304: {
            name: {
              th: 'ทรัพย์ไพวัลย์',
              en: 'Sap Phaiwan',
            },
            coordinates: {
              lat: '17.193',
              lng: '101.994',
            },
            zipcode: null,
          },
        },
      },
      4214: {
        name: {
          th: 'หนองหิน',
          en: 'Nong Hin',
        },
        tambons: {
          421401: {
            name: {
              th: 'หนองหิน',
              en: 'Nong Hin',
            },
            coordinates: {
              lat: '17.112',
              lng: '101.860',
            },
            zipcode: null,
          },
          421402: {
            name: {
              th: 'ตาดข่า',
              en: 'Tat Kha',
            },
            coordinates: {
              lat: '17.033',
              lng: '101.879',
            },
            zipcode: null,
          },
          421403: {
            name: {
              th: 'ปวนพุ',
              en: 'Puan Phu',
            },
            coordinates: {
              lat: '17.031',
              lng: '101.741',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  43: {
    name: {
      th: 'หนองคาย',
      en: 'Nong Khai',
    },
    amphoes: {
      4301: {
        name: {
          th: 'เมืองหนองคาย',
          en: 'Mueang Nong Khai',
        },
        tambons: {
          430101: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '17.890',
              lng: '102.761',
            },
            zipcode: 43000,
          },
          430102: {
            name: {
              th: 'มีชัย',
              en: 'Mi Chai',
            },
            coordinates: {
              lat: '17.868',
              lng: '102.729',
            },
            zipcode: 43000,
          },
          430103: {
            name: {
              th: 'โพธิ์ชัย',
              en: 'Pho Chai',
            },
            coordinates: {
              lat: '17.847',
              lng: '102.773',
            },
            zipcode: 43000,
          },
          430104: {
            name: {
              th: 'กวนวัน',
              en: 'Kuan Wan',
            },
            coordinates: {
              lat: '17.858',
              lng: '102.700',
            },
            zipcode: 43000,
          },
          430105: {
            name: {
              th: 'เวียงคุก',
              en: 'Wiang Khuk',
            },
            coordinates: {
              lat: '17.783',
              lng: '102.673',
            },
            zipcode: 43000,
          },
          430106: {
            name: {
              th: 'วัดธาตุ',
              en: 'Wat That',
            },
            coordinates: {
              lat: '17.851',
              lng: '102.826',
            },
            zipcode: 43000,
          },
          430107: {
            name: {
              th: 'หาดคำ',
              en: 'Hat Kham',
            },
            coordinates: {
              lat: '17.900',
              lng: '102.825',
            },
            zipcode: 43000,
          },
          430108: {
            name: {
              th: 'หินโงม',
              en: 'Hin Ngom',
            },
            coordinates: {
              lat: '17.940',
              lng: '102.849',
            },
            zipcode: 43000,
          },
          430109: {
            name: {
              th: 'บ้านเดื่อ',
              en: 'Ban Duea',
            },
            coordinates: {
              lat: '17.985',
              lng: '102.954',
            },
            zipcode: 43000,
          },
          430110: {
            name: {
              th: 'ค่ายบกหวาน',
              en: 'Khai Bok Wan',
            },
            coordinates: {
              lat: '17.781',
              lng: '102.783',
            },
            zipcode: 43100,
          },
          430111: {
            name: {
              th: 'สองห้อง',
              en: 'Phon Sawang',
            },
            coordinates: {
              lat: '17.715',
              lng: '102.811',
            },
            zipcode: 43100,
          },
          430113: {
            name: {
              th: 'พระธาตุบังพวน',
              en: 'Phrathat Bang Phuan',
            },
            coordinates: {
              lat: '17.736',
              lng: '102.693',
            },
            zipcode: 43100,
          },
          430116: {
            name: {
              th: 'หนองกอมเกาะ',
              en: 'Nong Kom Ko',
            },
            coordinates: {
              lat: '17.830',
              lng: '102.742',
            },
            zipcode: 43000,
          },
          430117: {
            name: {
              th: 'ปะโค',
              en: 'Pakho',
            },
            coordinates: {
              lat: '17.801',
              lng: '102.716',
            },
            zipcode: 43000,
          },
          430118: {
            name: {
              th: 'เมืองหมี',
              en: 'Mueang Mi',
            },
            coordinates: {
              lat: '17.833',
              lng: '102.702',
            },
            zipcode: 43000,
          },
          430119: {
            name: {
              th: 'สีกาย',
              en: 'Si Kai',
            },
            coordinates: {
              lat: '17.958',
              lng: '102.884',
            },
            zipcode: 43000,
          },
        },
      },
      4302: {
        name: {
          th: 'ท่าบ่อ',
          en: 'Tha Bo',
        },
        tambons: {
          430201: {
            name: {
              th: 'ท่าบ่อ',
              en: 'Tha Bo',
            },
            coordinates: {
              lat: '17.827',
              lng: '102.568',
            },
            zipcode: 43110,
          },
          430202: {
            name: {
              th: 'น้ำโมง',
              en: 'Nam Mong',
            },
            coordinates: {
              lat: '17.840',
              lng: '102.524',
            },
            zipcode: 43110,
          },
          430203: {
            name: {
              th: 'กองนาง',
              en: 'Kong Nang',
            },
            coordinates: {
              lat: '17.889',
              lng: '102.592',
            },
            zipcode: 43110,
          },
          430204: {
            name: {
              th: 'โคกคอน',
              en: 'Khok Khon',
            },
            coordinates: {
              lat: '17.781',
              lng: '102.496',
            },
            zipcode: 43110,
          },
          430205: {
            name: {
              th: 'บ้านเดื่อ',
              en: 'Ban Duea',
            },
            coordinates: {
              lat: '17.775',
              lng: '102.579',
            },
            zipcode: null,
          },
          430206: {
            name: {
              th: 'บ้านถ่อน',
              en: 'Ban Thon',
            },
            coordinates: {
              lat: '17.776',
              lng: '102.632',
            },
            zipcode: 43110,
          },
          430207: {
            name: {
              th: 'บ้านว่าน',
              en: 'Ban Wan',
            },
            coordinates: {
              lat: '17.743',
              lng: '102.558',
            },
            zipcode: 43110,
          },
          430208: {
            name: {
              th: 'นาข่า',
              en: 'Nakha',
            },
            coordinates: {
              lat: '17.784',
              lng: '102.458',
            },
            zipcode: 43110,
          },
          430209: {
            name: {
              th: 'โพนสา',
              en: 'Phon Sa',
            },
            coordinates: {
              lat: '17.819',
              lng: '102.610',
            },
            zipcode: 43110,
          },
          430210: {
            name: {
              th: 'หนองนาง',
              en: 'Nong Nang',
            },
            coordinates: {
              lat: '17.713',
              lng: '102.621',
            },
            zipcode: 43110,
          },
        },
      },
      4305: {
        name: {
          th: 'โพนพิสัย',
          en: 'Phon Phisai',
        },
        tambons: {
          430501: {
            name: {
              th: 'จุมพล',
              en: 'Chum Phon',
            },
            coordinates: {
              lat: '18.027',
              lng: '103.146',
            },
            zipcode: 43120,
          },
          430502: {
            name: {
              th: 'วัดหลวง',
              en: 'Wat Luang',
            },
            coordinates: {
              lat: '17.949',
              lng: '103.013',
            },
            zipcode: 43120,
          },
          430503: {
            name: {
              th: 'กุดบง',
              en: 'Kut Bong',
            },
            coordinates: {
              lat: '18.105',
              lng: '103.118',
            },
            zipcode: 43120,
          },
          430504: {
            name: {
              th: 'ชุมช้าง',
              en: 'Chum Chang',
            },
            coordinates: {
              lat: '17.974',
              lng: '103.132',
            },
            zipcode: 43120,
          },
          430506: {
            name: {
              th: 'ทุ่งหลวง',
              en: 'Thung Luang',
            },
            coordinates: {
              lat: '17.896',
              lng: '102.976',
            },
            zipcode: 43120,
          },
          430507: {
            name: {
              th: 'เหล่าต่างคำ',
              en: 'Lao Tang Kham',
            },
            coordinates: {
              lat: '17.917',
              lng: '102.911',
            },
            zipcode: 43120,
          },
          430508: {
            name: {
              th: 'นาหนัง',
              en: 'Na Nang',
            },
            coordinates: {
              lat: '17.888',
              lng: '103.136',
            },
            zipcode: 43120,
          },
          430509: {
            name: {
              th: 'เซิม',
              en: 'Some',
            },
            coordinates: {
              lat: '17.926',
              lng: '103.277',
            },
            zipcode: 43120,
          },
          430513: {
            name: {
              th: 'บ้านโพธิ์',
              en: 'Ban Pho',
            },
            coordinates: {
              lat: '17.860',
              lng: '103.291',
            },
            zipcode: 43120,
          },
          430521: {
            name: {
              th: 'บ้านผือ',
              en: 'Ban Phue',
            },
            coordinates: {
              lat: '17.930',
              lng: '103.213',
            },
            zipcode: 43120,
          },
          430522: {
            name: {
              th: 'สร้างนางขาว',
              en: 'Sang Nang Khao',
            },
            coordinates: {
              lat: '17.899',
              lng: '103.010',
            },
            zipcode: 43120,
          },
        },
      },
      4307: {
        name: {
          th: 'ศรีเชียงใหม่',
          en: 'Si Chiang Mai',
        },
        tambons: {
          430701: {
            name: {
              th: 'พานพร้าว',
              en: 'Phan Phrao',
            },
            coordinates: {
              lat: '17.942',
              lng: '102.594',
            },
            zipcode: 43130,
          },
          430703: {
            name: {
              th: 'บ้านหม้อ',
              en: 'Ban Mo',
            },
            coordinates: {
              lat: '17.957',
              lng: '102.502',
            },
            zipcode: 43130,
          },
          430704: {
            name: {
              th: 'พระพุทธบาท',
              en: 'Phraphutthabat',
            },
            coordinates: {
              lat: '17.965',
              lng: '102.424',
            },
            zipcode: 43130,
          },
          430705: {
            name: {
              th: 'หนองปลาปาก',
              en: 'Nong Pla Pak',
            },
            coordinates: {
              lat: '17.913',
              lng: '102.514',
            },
            zipcode: 43130,
          },
        },
      },
      4308: {
        name: {
          th: 'สังคม',
          en: 'Sangkhom',
        },
        tambons: {
          430801: {
            name: {
              th: 'แก้งไก่',
              en: 'Kaeng Kai',
            },
            coordinates: {
              lat: '18.037',
              lng: '102.235',
            },
            zipcode: 43160,
          },
          430802: {
            name: {
              th: 'ผาตั้ง',
              en: 'Pha Tang',
            },
            coordinates: {
              lat: '17.996',
              lng: '102.328',
            },
            zipcode: 43160,
          },
          430803: {
            name: {
              th: 'บ้านม่วง',
              en: 'Ban Muang',
            },
            coordinates: {
              lat: '18.181',
              lng: '102.128',
            },
            zipcode: 43160,
          },
          430804: {
            name: {
              th: 'นางิ้ว',
              en: 'Na Ngio',
            },
            coordinates: {
              lat: '18.092',
              lng: '102.125',
            },
            zipcode: 43160,
          },
          430805: {
            name: {
              th: 'สังคม',
              en: 'Sangkhom',
            },
            coordinates: {
              lat: '18.091',
              lng: '102.216',
            },
            zipcode: 43160,
          },
        },
      },
      4314: {
        name: {
          th: 'สระใคร',
          en: 'Sakhrai',
        },
        tambons: {
          431401: {
            name: {
              th: 'สระใคร',
              en: 'Sa Khrai',
            },
            coordinates: {
              lat: '17.703',
              lng: '102.750',
            },
            zipcode: null,
          },
          431402: {
            name: {
              th: 'คอกช้าง',
              en: 'Khok Chang',
            },
            coordinates: {
              lat: '17.638',
              lng: '102.736',
            },
            zipcode: null,
          },
          431403: {
            name: {
              th: 'บ้านฝาง',
              en: 'Ban Fang',
            },
            coordinates: {
              lat: '17.651',
              lng: '102.665',
            },
            zipcode: null,
          },
        },
      },
      4315: {
        name: {
          th: 'เฝ้าไร่',
          en: 'Fao Rai',
        },
        tambons: {
          431501: {
            name: {
              th: 'เฝ้าไร่',
              en: 'Fao Rai',
            },
            coordinates: {
              lat: '17.969',
              lng: '103.270',
            },
            zipcode: null,
          },
          431502: {
            name: {
              th: 'นาดี',
              en: 'Na Di',
            },
            coordinates: {
              lat: '17.999',
              lng: '103.366',
            },
            zipcode: null,
          },
          431503: {
            name: {
              th: 'หนองหลวง',
              en: 'Nong Luang',
            },
            coordinates: {
              lat: '18.021',
              lng: '103.240',
            },
            zipcode: null,
          },
          431504: {
            name: {
              th: 'วังหลวง',
              en: 'Wang Luang',
            },
            coordinates: {
              lat: '18.086',
              lng: '103.256',
            },
            zipcode: null,
          },
          431505: {
            name: {
              th: 'อุดมพร',
              en: 'Udom Phon',
            },
            coordinates: {
              lat: '17.891',
              lng: '103.357',
            },
            zipcode: null,
          },
        },
      },
      4316: {
        name: {
          th: 'รัตนวาปี',
          en: 'Rattanawapi',
        },
        tambons: {
          431601: {
            name: {
              th: 'รัตนวาปี',
              en: 'Rattana Wapi',
            },
            coordinates: {
              lat: '18.223',
              lng: '103.183',
            },
            zipcode: null,
          },
          431602: {
            name: {
              th: 'นาทับไฮ',
              en: 'Na Thap Hai',
            },
            coordinates: {
              lat: '18.177',
              lng: '103.306',
            },
            zipcode: null,
          },
          431603: {
            name: {
              th: 'บ้านต้อน',
              en: 'Ban Ton',
            },
            coordinates: {
              lat: '18.258',
              lng: '103.236',
            },
            zipcode: null,
          },
          431604: {
            name: {
              th: 'พระบาทนาสิงห์',
              en: 'Phrabat Na Sing',
            },
            coordinates: {
              lat: '18.163',
              lng: '103.209',
            },
            zipcode: null,
          },
          431605: {
            name: {
              th: 'โพนแพง',
              en: 'Phon Phaeng',
            },
            coordinates: {
              lat: '18.252',
              lng: '103.202',
            },
            zipcode: null,
          },
        },
      },
      4317: {
        name: {
          th: 'โพธิ์ตาก',
          en: 'Pho Tak',
        },
        tambons: {
          431701: {
            name: {
              th: 'โพธิ์ตาก',
              en: 'Pho Tak',
            },
            coordinates: {
              lat: '17.846',
              lng: '102.422',
            },
            zipcode: null,
          },
          431702: {
            name: {
              th: 'โพนทอง',
              en: 'Phon Thong',
            },
            coordinates: {
              lat: '17.881',
              lng: '102.454',
            },
            zipcode: null,
          },
          431703: {
            name: {
              th: 'ด่านศรีสุข',
              en: 'Dan Si Suk',
            },
            coordinates: {
              lat: '17.925',
              lng: '102.398',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  44: {
    name: {
      th: 'มหาสารคาม',
      en: 'Maha Sarakham',
    },
    amphoes: {
      4401: {
        name: {
          th: 'เมืองมหาสารคาม',
          en: 'Mueang Maha Sarakham',
        },
        tambons: {
          440101: {
            name: {
              th: 'ตลาด',
              en: 'Talat',
            },
            coordinates: {
              lat: '16.175',
              lng: '103.319',
            },
            zipcode: 44000,
          },
          440102: {
            name: {
              th: 'เขวา',
              en: 'Khewa',
            },
            coordinates: {
              lat: '16.150',
              lng: '103.389',
            },
            zipcode: 44000,
          },
          440103: {
            name: {
              th: 'ท่าตูม',
              en: 'Tha Tum',
            },
            coordinates: {
              lat: '16.170',
              lng: '103.460',
            },
            zipcode: 44000,
          },
          440104: {
            name: {
              th: 'แวงน่าง',
              en: 'Waeng Nang',
            },
            coordinates: {
              lat: '16.128',
              lng: '103.313',
            },
            zipcode: 44000,
          },
          440105: {
            name: {
              th: 'โคกก่อ',
              en: 'Khok Ko',
            },
            coordinates: {
              lat: '16.026',
              lng: '103.239',
            },
            zipcode: 44000,
          },
          440106: {
            name: {
              th: 'ดอนหว่าน',
              en: 'Don Wan',
            },
            coordinates: {
              lat: '15.997',
              lng: '103.318',
            },
            zipcode: 44000,
          },
          440107: {
            name: {
              th: 'เกิ้ง',
              en: 'Koeng',
            },
            coordinates: {
              lat: '16.210',
              lng: '103.330',
            },
            zipcode: 44000,
          },
          440108: {
            name: {
              th: 'แก่งเลิงจาน',
              en: 'Kaeng Loeng Chan',
            },
            coordinates: {
              lat: '16.139',
              lng: '103.226',
            },
            zipcode: 44000,
          },
          440109: {
            name: {
              th: 'ท่าสองคอน',
              en: null,
            },
            coordinates: {
              lat: '16.163',
              lng: '103.150',
            },
            zipcode: 44000,
          },
          440110: {
            name: {
              th: 'ลาดพัฒนา',
              en: 'Lat Phatthana',
            },
            coordinates: {
              lat: '16.207',
              lng: '103.397',
            },
            zipcode: 44000,
          },
          440111: {
            name: {
              th: 'หนองปลิง',
              en: 'Nong Pling',
            },
            coordinates: {
              lat: '16.070',
              lng: '103.313',
            },
            zipcode: 44000,
          },
          440112: {
            name: {
              th: 'ห้วยแอ่ง',
              en: 'Huai Aeng',
            },
            coordinates: {
              lat: '16.117',
              lng: '103.432',
            },
            zipcode: 44000,
          },
          440113: {
            name: {
              th: 'หนองโน',
              en: 'Nong No',
            },
            coordinates: {
              lat: '16.098',
              lng: '103.252',
            },
            zipcode: 44000,
          },
          440114: {
            name: {
              th: 'บัวค้อ',
              en: 'Bua Kho',
            },
            coordinates: {
              lat: '15.986',
              lng: '103.278',
            },
            zipcode: 44000,
          },
        },
      },
      4402: {
        name: {
          th: 'แกดำ',
          en: 'Kae Dam',
        },
        tambons: {
          440201: {
            name: {
              th: 'แกดำ',
              en: 'Kae Dam',
            },
            coordinates: {
              lat: '16.026',
              lng: '103.401',
            },
            zipcode: 44190,
          },
          440202: {
            name: {
              th: 'วังแสง',
              en: 'Wang Saeng',
            },
            coordinates: {
              lat: '16.047',
              lng: '103.358',
            },
            zipcode: 44190,
          },
          440203: {
            name: {
              th: 'มิตรภาพ',
              en: 'Mittraphap',
            },
            coordinates: {
              lat: '16.090',
              lng: '103.387',
            },
            zipcode: 44190,
          },
          440204: {
            name: {
              th: 'หนองกุง',
              en: 'Nong Kung',
            },
            coordinates: {
              lat: '16.072',
              lng: '103.437',
            },
            zipcode: 44190,
          },
          440205: {
            name: {
              th: 'โนนภิบาล',
              en: 'Non Phiban',
            },
            coordinates: {
              lat: '16.022',
              lng: '103.442',
            },
            zipcode: 44190,
          },
        },
      },
      4403: {
        name: {
          th: 'โกสุมพิสัย',
          en: 'Kosum Phisai',
        },
        tambons: {
          440301: {
            name: {
              th: 'หัวขวาง',
              en: 'Hua Khwang',
            },
            coordinates: {
              lat: '16.249',
              lng: '103.065',
            },
            zipcode: 44140,
          },
          440302: {
            name: {
              th: 'ยางน้อย',
              en: 'Yang Noi',
            },
            coordinates: {
              lat: '16.301',
              lng: '103.098',
            },
            zipcode: 44140,
          },
          440303: {
            name: {
              th: 'วังยาว',
              en: 'Wang Yao',
            },
            coordinates: {
              lat: '16.223',
              lng: '102.928',
            },
            zipcode: 44140,
          },
          440304: {
            name: {
              th: 'เขวาไร่',
              en: 'Khewa Rai',
            },
            coordinates: {
              lat: '16.249',
              lng: '102.870',
            },
            zipcode: 44140,
          },
          440305: {
            name: {
              th: 'แพง',
              en: 'Phaeng',
            },
            coordinates: {
              lat: '16.260',
              lng: '102.967',
            },
            zipcode: 44140,
          },
          440306: {
            name: {
              th: 'แก้งแก',
              en: 'Kaeng Kae',
            },
            coordinates: {
              lat: '16.197',
              lng: '103.103',
            },
            zipcode: 44140,
          },
          440307: {
            name: {
              th: 'หนองเหล็ก',
              en: 'Nong Lek',
            },
            coordinates: {
              lat: '16.134',
              lng: '102.948',
            },
            zipcode: 44140,
          },
          440308: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '16.338',
              lng: '102.995',
            },
            zipcode: 44140,
          },
          440309: {
            name: {
              th: 'เหล่า',
              en: 'Lao',
            },
            coordinates: {
              lat: '16.180',
              lng: '103.068',
            },
            zipcode: 44140,
          },
          440310: {
            name: {
              th: 'เขื่อน',
              en: 'Khuean',
            },
            coordinates: {
              lat: '16.344',
              lng: '103.068',
            },
            zipcode: 44140,
          },
          440311: {
            name: {
              th: 'หนองบอน',
              en: 'Nong Bon',
            },
            coordinates: {
              lat: '16.274',
              lng: '103.018',
            },
            zipcode: 44140,
          },
          440312: {
            name: {
              th: 'โพนงาม',
              en: 'Phon Ngam',
            },
            coordinates: {
              lat: '16.323',
              lng: '102.959',
            },
            zipcode: 44140,
          },
          440313: {
            name: {
              th: 'ยางท่าแจ้ง',
              en: 'Yang Tha Chaeng',
            },
            coordinates: {
              lat: '16.315',
              lng: '103.036',
            },
            zipcode: 44140,
          },
          440314: {
            name: {
              th: 'แห่ใต้',
              en: 'Hae Tai',
            },
            coordinates: {
              lat: '16.281',
              lng: '103.139',
            },
            zipcode: 44140,
          },
          440315: {
            name: {
              th: 'หนองกุงสวรรค์',
              en: 'Nong Kung Sawan',
            },
            coordinates: {
              lat: '16.194',
              lng: '102.975',
            },
            zipcode: 44140,
          },
          440316: {
            name: {
              th: 'เลิงใต้',
              en: 'Loeng Tai',
            },
            coordinates: {
              lat: '16.216',
              lng: '103.142',
            },
            zipcode: 44140,
          },
          440317: {
            name: {
              th: 'ดอนกลาง',
              en: 'Don Klang',
            },
            coordinates: {
              lat: '16.165',
              lng: '102.891',
            },
            zipcode: 44140,
          },
        },
      },
      4404: {
        name: {
          th: 'กันทรวิชัย',
          en: 'Kantharawichai',
        },
        tambons: {
          440401: {
            name: {
              th: 'โคกพระ',
              en: 'Khok Phra',
            },
            coordinates: {
              lat: '16.337',
              lng: '103.316',
            },
            zipcode: 44150,
          },
          440402: {
            name: {
              th: 'คันธารราษฎร์',
              en: 'Khan Than Rat',
            },
            coordinates: {
              lat: '16.299',
              lng: '103.283',
            },
            zipcode: 44150,
          },
          440403: {
            name: {
              th: 'มะค่า',
              en: 'Makha',
            },
            coordinates: {
              lat: '16.254',
              lng: '103.351',
            },
            zipcode: 44150,
          },
          440404: {
            name: {
              th: 'ท่าขอนยาง',
              en: 'Tha Khon Yang',
            },
            coordinates: {
              lat: '16.256',
              lng: '103.275',
            },
            zipcode: 44150,
          },
          440405: {
            name: {
              th: 'นาสีนวน',
              en: 'Na Si Nuan',
            },
            coordinates: {
              lat: '16.317',
              lng: '103.202',
            },
            zipcode: 44150,
          },
          440406: {
            name: {
              th: 'ขามเรียง',
              en: 'Kham Riang',
            },
            coordinates: {
              lat: '16.247',
              lng: '103.236',
            },
            zipcode: 44150,
          },
          440407: {
            name: {
              th: 'เขวาใหญ่',
              en: 'Khewa Yai',
            },
            coordinates: {
              lat: '16.228',
              lng: '103.184',
            },
            zipcode: 44150,
          },
          440408: {
            name: {
              th: 'ศรีสุข',
              en: 'Si Suk',
            },
            coordinates: {
              lat: '16.348',
              lng: '103.263',
            },
            zipcode: 44150,
          },
          440409: {
            name: {
              th: 'กุดใส้จ่อ',
              en: 'Kut Sai Cho',
            },
            coordinates: {
              lat: '16.283',
              lng: '103.380',
            },
            zipcode: 44150,
          },
          440410: {
            name: {
              th: 'ขามเฒ่าพัฒนา',
              en: 'Kham Thao Phatthana',
            },
            coordinates: {
              lat: '16.277',
              lng: '103.321',
            },
            zipcode: 44150,
          },
        },
      },
      4405: {
        name: {
          th: 'เชียงยืน',
          en: 'Chiang Yuen',
        },
        tambons: {
          440501: {
            name: {
              th: 'เชียงยืน',
              en: 'Chiang Yuen',
            },
            coordinates: {
              lat: '16.431',
              lng: '103.093',
            },
            zipcode: 44160,
          },
          440503: {
            name: {
              th: 'หนองซอน',
              en: 'Nong Son',
            },
            coordinates: {
              lat: '16.334',
              lng: '103.156',
            },
            zipcode: 44160,
          },
          440505: {
            name: {
              th: 'ดอนเงิน',
              en: 'Don Ngoen',
            },
            coordinates: {
              lat: '16.424',
              lng: '103.151',
            },
            zipcode: 44160,
          },
          440506: {
            name: {
              th: 'กู่ทอง',
              en: 'Ku Thong',
            },
            coordinates: {
              lat: '16.426',
              lng: '103.003',
            },
            zipcode: 44160,
          },
          440507: {
            name: {
              th: 'นาทอง',
              en: 'Na Thong',
            },
            coordinates: {
              lat: '16.389',
              lng: '103.059',
            },
            zipcode: 44160,
          },
          440508: {
            name: {
              th: 'เสือเฒ่า',
              en: 'Suea Thao',
            },
            coordinates: {
              lat: '16.470',
              lng: '103.053',
            },
            zipcode: 44160,
          },
          440511: {
            name: {
              th: 'โพนทอง',
              en: 'Phon Thong',
            },
            coordinates: {
              lat: '16.372',
              lng: '103.144',
            },
            zipcode: 44160,
          },
          440512: {
            name: {
              th: 'เหล่าบัวบาน',
              en: 'Lao Bua Ban',
            },
            coordinates: {
              lat: '16.367',
              lng: '103.012',
            },
            zipcode: 44160,
          },
        },
      },
      4406: {
        name: {
          th: 'บรบือ',
          en: 'Borabue',
        },
        tambons: {
          440601: {
            name: {
              th: 'บรบือ',
              en: 'Borabue',
            },
            coordinates: {
              lat: '16.061',
              lng: '103.143',
            },
            zipcode: 44130,
          },
          440602: {
            name: {
              th: 'บ่อใหญ่',
              en: 'Bo Yai',
            },
            coordinates: {
              lat: '16.115',
              lng: '103.176',
            },
            zipcode: 44130,
          },
          440604: {
            name: {
              th: 'วังไชย',
              en: 'Wang Chai',
            },
            coordinates: {
              lat: '15.986',
              lng: '103.064',
            },
            zipcode: 44130,
          },
          440605: {
            name: {
              th: 'หนองม่วง',
              en: 'Nong Muang',
            },
            coordinates: {
              lat: '15.961',
              lng: '103.212',
            },
            zipcode: 44130,
          },
          440606: {
            name: {
              th: 'กำพี้',
              en: 'Kamphi',
            },
            coordinates: {
              lat: '15.959',
              lng: '103.144',
            },
            zipcode: 44130,
          },
          440607: {
            name: {
              th: 'โนนราษี',
              en: 'Non Rasi',
            },
            coordinates: {
              lat: '15.888',
              lng: '103.142',
            },
            zipcode: 44130,
          },
          440608: {
            name: {
              th: 'โนนแดง',
              en: 'Non Daeng',
            },
            coordinates: {
              lat: '15.903',
              lng: '103.080',
            },
            zipcode: 44130,
          },
          440610: {
            name: {
              th: 'หนองจิก',
              en: 'Nong Chik',
            },
            coordinates: {
              lat: '16.024',
              lng: '103.170',
            },
            zipcode: 44130,
          },
          440611: {
            name: {
              th: 'บัวมาศ',
              en: null,
            },
            coordinates: {
              lat: '15.897',
              lng: '103.202',
            },
            zipcode: 44130,
          },
          440613: {
            name: {
              th: 'หนองคูขาด',
              en: 'Nong Khu Khat',
            },
            coordinates: {
              lat: '15.938',
              lng: '102.989',
            },
            zipcode: 44130,
          },
          440615: {
            name: {
              th: 'วังใหม่',
              en: 'Wang Mai',
            },
            coordinates: {
              lat: '16.031',
              lng: '103.039',
            },
            zipcode: 44130,
          },
          440616: {
            name: {
              th: 'ยาง',
              en: 'Yang',
            },
            coordinates: {
              lat: '15.919',
              lng: '103.236',
            },
            zipcode: 44130,
          },
          440618: {
            name: {
              th: 'หนองสิม',
              en: 'Nong Sim',
            },
            coordinates: {
              lat: '16.012',
              lng: '103.114',
            },
            zipcode: 44130,
          },
          440619: {
            name: {
              th: 'หนองโก',
              en: 'Nong Ko',
            },
            coordinates: {
              lat: '16.068',
              lng: '103.201',
            },
            zipcode: 44130,
          },
          440620: {
            name: {
              th: 'ดอนงัว',
              en: 'Don Ngua',
            },
            coordinates: {
              lat: '15.877',
              lng: '103.159',
            },
            zipcode: 44130,
          },
        },
      },
      4407: {
        name: {
          th: 'นาเชือก',
          en: 'Na Chueak',
        },
        tambons: {
          440701: {
            name: {
              th: 'นาเชือก',
              en: 'Na Chueak',
            },
            coordinates: {
              lat: '15.773',
              lng: '103.047',
            },
            zipcode: 44170,
          },
          440702: {
            name: {
              th: 'สำโรง',
              en: 'Samrong',
            },
            coordinates: {
              lat: '15.870',
              lng: '103.007',
            },
            zipcode: 44170,
          },
          440703: {
            name: {
              th: 'หนองแดง',
              en: 'Nong Daeng',
            },
            coordinates: {
              lat: '15.889',
              lng: '102.948',
            },
            zipcode: 44170,
          },
          440704: {
            name: {
              th: 'เขวาไร่',
              en: 'Khewa Rai',
            },
            coordinates: {
              lat: '15.841',
              lng: '103.065',
            },
            zipcode: null,
          },
          440705: {
            name: {
              th: 'หนองโพธิ์',
              en: 'Nong Pho',
            },
            coordinates: {
              lat: '15.755',
              lng: '103.130',
            },
            zipcode: 44170,
          },
          440706: {
            name: {
              th: 'ปอพาน',
              en: 'Po Phan',
            },
            coordinates: {
              lat: '15.816',
              lng: '102.954',
            },
            zipcode: 44170,
          },
          440707: {
            name: {
              th: 'หนองเม็ก',
              en: 'Nong Mek',
            },
            coordinates: {
              lat: '15.786',
              lng: '102.998',
            },
            zipcode: 44170,
          },
          440708: {
            name: {
              th: 'หนองเรือ',
              en: 'Nong Ruea',
            },
            coordinates: {
              lat: '15.828',
              lng: '103.123',
            },
            zipcode: 44170,
          },
          440709: {
            name: {
              th: 'หนองกุง',
              en: 'Nong Kung',
            },
            coordinates: {
              lat: '15.722',
              lng: '103.038',
            },
            zipcode: null,
          },
          440710: {
            name: {
              th: 'สันป่าตอง',
              en: 'San Patong',
            },
            coordinates: {
              lat: '15.735',
              lng: '103.076',
            },
            zipcode: 44170,
          },
        },
      },
      4408: {
        name: {
          th: 'พยัคฆภูมิพิสัย',
          en: 'Phayakkhaphum Phisai',
        },
        tambons: {
          440801: {
            name: {
              th: 'ปะหลาน',
              en: 'Palan',
            },
            coordinates: {
              lat: '15.503',
              lng: '103.163',
            },
            zipcode: 44110,
          },
          440802: {
            name: {
              th: 'ก้ามปู',
              en: 'Kam Pu',
            },
            coordinates: {
              lat: '15.558',
              lng: '103.222',
            },
            zipcode: 44110,
          },
          440803: {
            name: {
              th: 'เวียงสะอาด',
              en: 'Wiang Sa-At',
            },
            coordinates: {
              lat: '15.461',
              lng: '103.262',
            },
            zipcode: 44110,
          },
          440804: {
            name: {
              th: 'เม็กดำ',
              en: 'Mek Dam',
            },
            coordinates: {
              lat: '15.573',
              lng: '103.072',
            },
            zipcode: 44110,
          },
          440805: {
            name: {
              th: 'นาสีนวล',
              en: 'Na Si Nuan',
            },
            coordinates: {
              lat: '15.635',
              lng: '103.223',
            },
            zipcode: 44110,
          },
          440809: {
            name: {
              th: 'ราษฎร์เจริญ',
              en: 'Rat Charoen',
            },
            coordinates: {
              lat: '15.456',
              lng: '103.321',
            },
            zipcode: 44110,
          },
          440810: {
            name: {
              th: 'หนองบัวแก้ว',
              en: 'Nong Bua Kaeo',
            },
            coordinates: {
              lat: '15.543',
              lng: '103.309',
            },
            zipcode: 44110,
          },
          440812: {
            name: {
              th: 'เมืองเตา',
              en: 'Mueang Tao',
            },
            coordinates: {
              lat: '15.479',
              lng: '103.378',
            },
            zipcode: 44110,
          },
          440815: {
            name: {
              th: 'ลานสะแก',
              en: 'Lan Sakae',
            },
            coordinates: {
              lat: '15.568',
              lng: '103.159',
            },
            zipcode: 44110,
          },
          440816: {
            name: {
              th: 'เวียงชัย',
              en: 'Wiang Chai',
            },
            coordinates: {
              lat: '15.517',
              lng: '103.266',
            },
            zipcode: 44110,
          },
          440818: {
            name: {
              th: 'ราษฎร์พัฒนา',
              en: 'Rat Phatthana',
            },
            coordinates: {
              lat: '15.506',
              lng: '103.310',
            },
            zipcode: 44110,
          },
          440819: {
            name: {
              th: 'เมืองเสือ',
              en: 'Mueang Suea',
            },
            coordinates: {
              lat: '15.484',
              lng: '103.206',
            },
            zipcode: 44110,
          },
          440820: {
            name: {
              th: 'ภารแอ่น',
              en: 'Phara-Aen',
            },
            coordinates: {
              lat: '15.593',
              lng: '103.258',
            },
            zipcode: 44110,
          },
          440828: {
            name: {
              th: 'หนองบัว',
              en: null,
            },
            coordinates: {
              lat: '15.591',
              lng: '103.106',
            },
            zipcode: null,
          },
        },
      },
      4409: {
        name: {
          th: 'วาปีปทุม',
          en: 'Wapi Pathum',
        },
        tambons: {
          440901: {
            name: {
              th: 'หนองแสง',
              en: 'Nong Saeng',
            },
            coordinates: {
              lat: '15.829',
              lng: '103.360',
            },
            zipcode: 44120,
          },
          440902: {
            name: {
              th: 'ขามป้อม',
              en: 'Kham Pom',
            },
            coordinates: {
              lat: '15.921',
              lng: '103.377',
            },
            zipcode: 44120,
          },
          440903: {
            name: {
              th: 'เสือโก้ก',
              en: 'Suea Kok',
            },
            coordinates: {
              lat: '15.948',
              lng: '103.442',
            },
            zipcode: 44120,
          },
          440904: {
            name: {
              th: 'ดงใหญ่',
              en: 'Dong Yai',
            },
            coordinates: {
              lat: '15.898',
              lng: '103.308',
            },
            zipcode: 44120,
          },
          440905: {
            name: {
              th: 'โพธิ์ชัย',
              en: 'Pho Chai',
            },
            coordinates: {
              lat: '15.729',
              lng: '103.415',
            },
            zipcode: 44120,
          },
          440906: {
            name: {
              th: 'หัวเรือ',
              en: 'Hua Ruea',
            },
            coordinates: {
              lat: '15.762',
              lng: '103.349',
            },
            zipcode: 44120,
          },
          440907: {
            name: {
              th: 'แคน',
              en: 'Khaen',
            },
            coordinates: {
              lat: '15.875',
              lng: '103.244',
            },
            zipcode: 44120,
          },
          440908: {
            name: {
              th: 'งัวบา',
              en: 'Ngua Ba',
            },
            coordinates: {
              lat: '15.955',
              lng: '103.347',
            },
            zipcode: 44120,
          },
          440909: {
            name: {
              th: 'นาข่า',
              en: 'Nakha',
            },
            coordinates: {
              lat: '15.836',
              lng: '103.193',
            },
            zipcode: 44120,
          },
          440910: {
            name: {
              th: 'บ้านหวาย',
              en: 'Ban Wai',
            },
            coordinates: {
              lat: '15.812',
              lng: '103.294',
            },
            zipcode: 44120,
          },
          440911: {
            name: {
              th: 'หนองไฮ',
              en: 'Nong Hai',
            },
            coordinates: {
              lat: '15.885',
              lng: '103.432',
            },
            zipcode: 44120,
          },
          440912: {
            name: {
              th: 'ประชาพัฒนา',
              en: 'Pracha Phatthana',
            },
            coordinates: {
              lat: '15.816',
              lng: '103.419',
            },
            zipcode: 44120,
          },
          440913: {
            name: {
              th: 'หนองทุ่ม',
              en: 'Nong Thum',
            },
            coordinates: {
              lat: '15.875',
              lng: '103.399',
            },
            zipcode: 44120,
          },
          440914: {
            name: {
              th: 'หนองแสน',
              en: 'Nong Saen',
            },
            coordinates: {
              lat: '15.983',
              lng: '103.444',
            },
            zipcode: 44120,
          },
          440915: {
            name: {
              th: 'โคกสีทองหลาง',
              en: 'Khok Si Thong Lang',
            },
            coordinates: {
              lat: '15.852',
              lng: '103.312',
            },
            zipcode: 44120,
          },
        },
      },
      4410: {
        name: {
          th: 'นาดูน',
          en: 'Na Dun',
        },
        tambons: {
          441001: {
            name: {
              th: 'นาดูน',
              en: 'Na Dun',
            },
            coordinates: {
              lat: '15.738',
              lng: '103.205',
            },
            zipcode: null,
          },
          441002: {
            name: {
              th: 'หนองไผ่',
              en: 'Nong Phai',
            },
            coordinates: {
              lat: '15.726',
              lng: '103.318',
            },
            zipcode: null,
          },
          441003: {
            name: {
              th: 'หนองคู',
              en: 'Nong Khu',
            },
            coordinates: {
              lat: '15.782',
              lng: '103.226',
            },
            zipcode: null,
          },
          441004: {
            name: {
              th: 'ดงบัง',
              en: 'Dong Bang',
            },
            coordinates: {
              lat: '15.663',
              lng: '103.257',
            },
            zipcode: 44180,
          },
          441005: {
            name: {
              th: 'ดงดวน',
              en: 'Dong Duan',
            },
            coordinates: {
              lat: '15.731',
              lng: '103.162',
            },
            zipcode: 44180,
          },
          441006: {
            name: {
              th: 'หัวดง',
              en: 'Hua Dong',
            },
            coordinates: {
              lat: '15.691',
              lng: '103.194',
            },
            zipcode: 44180,
          },
          441007: {
            name: {
              th: 'ดงยาง',
              en: 'Dong Yang',
            },
            coordinates: {
              lat: '15.757',
              lng: '103.296',
            },
            zipcode: 44180,
          },
          441008: {
            name: {
              th: 'กู่สันตรัตน์',
              en: 'Ku Santarat',
            },
            coordinates: {
              lat: '15.719',
              lng: '103.277',
            },
            zipcode: 44180,
          },
          441009: {
            name: {
              th: 'พระธาตุ',
              en: 'Phrathat',
            },
            coordinates: {
              lat: '15.697',
              lng: '103.258',
            },
            zipcode: 44180,
          },
        },
      },
      4411: {
        name: {
          th: 'ยางสีสุราช',
          en: 'Yang Sisurat',
        },
        tambons: {
          441101: {
            name: {
              th: 'ยางสีสุราช',
              en: 'Yang Sisurat',
            },
            coordinates: {
              lat: '15.677',
              lng: '103.093',
            },
            zipcode: null,
          },
          441102: {
            name: {
              th: 'นาภู',
              en: 'Na Phu',
            },
            coordinates: {
              lat: '15.682',
              lng: '103.032',
            },
            zipcode: null,
          },
          441103: {
            name: {
              th: 'แวงดง',
              en: 'Waeng Dong',
            },
            coordinates: {
              lat: '15.638',
              lng: '103.035',
            },
            zipcode: null,
          },
          441104: {
            name: {
              th: 'บ้านกู่',
              en: 'Ban Ku',
            },
            coordinates: {
              lat: '15.686',
              lng: '103.136',
            },
            zipcode: null,
          },
          441105: {
            name: {
              th: 'ดงเมือง',
              en: 'Dong Mueang',
            },
            coordinates: {
              lat: '15.649',
              lng: '103.146',
            },
            zipcode: null,
          },
          441106: {
            name: {
              th: 'ขามเรียน',
              en: 'Kham Rian',
            },
            coordinates: {
              lat: '15.627',
              lng: '103.113',
            },
            zipcode: null,
          },
          441107: {
            name: {
              th: 'หนองบัวสันตุ',
              en: 'Nong Bua Santu',
            },
            coordinates: {
              lat: '15.637',
              lng: '103.177',
            },
            zipcode: 44210,
          },
        },
      },
      4412: {
        name: {
          th: 'กุดรัง',
          en: 'Kut Rang',
        },
        tambons: {
          441201: {
            name: {
              th: 'กุดรัง',
              en: 'Kut Rang',
            },
            coordinates: {
              lat: '16.072',
              lng: '103.000',
            },
            zipcode: null,
          },
          441202: {
            name: {
              th: 'นาโพธิ์',
              en: 'Na Pho',
            },
            coordinates: {
              lat: '16.077',
              lng: '102.907',
            },
            zipcode: null,
          },
          441203: {
            name: {
              th: 'เลิงแฝก',
              en: 'Loeng Faek',
            },
            coordinates: {
              lat: '15.968',
              lng: '102.960',
            },
            zipcode: null,
          },
          441204: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '16.006',
              lng: '102.935',
            },
            zipcode: null,
          },
          441205: {
            name: {
              th: 'ห้วยเตย',
              en: 'Huai Toei',
            },
            coordinates: {
              lat: '16.123',
              lng: '103.049',
            },
            zipcode: null,
          },
        },
      },
      4413: {
        name: {
          th: 'ชื่นชม',
          en: 'Chuen Chom',
        },
        tambons: {
          441301: {
            name: {
              th: 'ชื่นชม',
              en: 'Chuen Chom',
            },
            coordinates: {
              lat: '16.551',
              lng: '103.144',
            },
            zipcode: null,
          },
          441302: {
            name: {
              th: 'กุดปลาดุก',
              en: 'Kut Pla Duk',
            },
            coordinates: {
              lat: '16.588',
              lng: '103.138',
            },
            zipcode: null,
          },
          441303: {
            name: {
              th: 'เหล่าดอกไม้',
              en: 'Lao Dokmai',
            },
            coordinates: {
              lat: '16.492',
              lng: '103.145',
            },
            zipcode: null,
          },
          441304: {
            name: {
              th: 'หนองกุง',
              en: 'Nong Kung',
            },
            coordinates: {
              lat: '16.475',
              lng: '103.124',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  45: {
    name: {
      th: 'ร้อยเอ็ด',
      en: 'Roi Et',
    },
    amphoes: {
      4501: {
        name: {
          th: 'เมืองร้อยเอ็ด',
          en: 'Mueang Roi Et',
        },
        tambons: {
          450101: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '16.051',
              lng: '103.653',
            },
            zipcode: 45000,
          },
          450102: {
            name: {
              th: 'รอบเมือง',
              en: 'Rop Mueang',
            },
            coordinates: {
              lat: '16.006',
              lng: '103.632',
            },
            zipcode: 45210,
          },
          450103: {
            name: {
              th: 'เหนือเมือง',
              en: 'Nuea Mueang',
            },
            coordinates: {
              lat: '16.079',
              lng: '103.652',
            },
            zipcode: 45000,
          },
          450104: {
            name: {
              th: 'ขอนแก่น',
              en: 'Khon Kaen',
            },
            coordinates: {
              lat: '16.025',
              lng: '103.591',
            },
            zipcode: 45000,
          },
          450105: {
            name: {
              th: 'นาโพธิ์',
              en: 'Na Pho',
            },
            coordinates: {
              lat: '15.961',
              lng: '103.569',
            },
            zipcode: 45000,
          },
          450106: {
            name: {
              th: 'สะอาดสมบูรณ์',
              en: 'Sa-At Sombun',
            },
            coordinates: {
              lat: '15.932',
              lng: '103.626',
            },
            zipcode: 45000,
          },
          450108: {
            name: {
              th: 'สีแก้ว',
              en: 'Si Kaeo',
            },
            coordinates: {
              lat: '16.126',
              lng: '103.527',
            },
            zipcode: 45000,
          },
          450109: {
            name: {
              th: 'ปอภาร',
              en: 'Po Phan',
            },
            coordinates: {
              lat: '16.081',
              lng: '103.523',
            },
            zipcode: null,
          },
          450110: {
            name: {
              th: 'โนนรัง',
              en: 'Non Rang',
            },
            coordinates: {
              lat: '16.002',
              lng: '103.664',
            },
            zipcode: 45000,
          },
          450117: {
            name: {
              th: 'หนองแก้ว',
              en: 'Nong Kaeo',
            },
            coordinates: {
              lat: '15.939',
              lng: '103.660',
            },
            zipcode: 45000,
          },
          450118: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '16.103',
              lng: '103.603',
            },
            zipcode: 45150,
          },
          450120: {
            name: {
              th: 'ดงลาน',
              en: 'Dong Lan',
            },
            coordinates: {
              lat: '16.064',
              lng: '103.578',
            },
            zipcode: 45000,
          },
          450123: {
            name: {
              th: 'แคนใหญ่',
              en: 'Khaen Yai',
            },
            coordinates: {
              lat: '15.897',
              lng: '103.672',
            },
            zipcode: 45000,
          },
          450124: {
            name: {
              th: 'โนนตาล',
              en: 'Non Tan',
            },
            coordinates: {
              lat: '15.969',
              lng: '103.666',
            },
            zipcode: 45000,
          },
          450125: {
            name: {
              th: 'เมืองทอง',
              en: 'Mueang Thong',
            },
            coordinates: {
              lat: '15.932',
              lng: '103.591',
            },
            zipcode: 45000,
          },
        },
      },
      4502: {
        name: {
          th: 'เกษตรวิสัย',
          en: 'Kaset Wisai',
        },
        tambons: {
          450201: {
            name: {
              th: 'เกษตรวิสัย',
              en: 'Kaset Wisai',
            },
            coordinates: {
              lat: '15.662',
              lng: '103.609',
            },
            zipcode: 45150,
          },
          450202: {
            name: {
              th: 'เมืองบัว',
              en: 'Mueang Bua',
            },
            coordinates: {
              lat: '15.593',
              lng: '103.595',
            },
            zipcode: 45150,
          },
          450203: {
            name: {
              th: 'เหล่าหลวง',
              en: 'Lao Luang',
            },
            coordinates: {
              lat: '15.720',
              lng: '103.552',
            },
            zipcode: 45150,
          },
          450204: {
            name: {
              th: 'สิงห์โคก',
              en: 'Sing Khok',
            },
            coordinates: {
              lat: '15.704',
              lng: '103.668',
            },
            zipcode: 45150,
          },
          450205: {
            name: {
              th: 'ดงครั่งใหญ่',
              en: 'Dong Khrang Yai',
            },
            coordinates: {
              lat: '15.487',
              lng: '103.534',
            },
            zipcode: 45150,
          },
          450206: {
            name: {
              th: 'บ้านฝาง',
              en: 'Ban Fang',
            },
            coordinates: {
              lat: '15.703',
              lng: '103.452',
            },
            zipcode: 45150,
          },
          450207: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '15.741',
              lng: '103.631',
            },
            zipcode: null,
          },
          450208: {
            name: {
              th: 'กำแพง',
              en: 'Kamphaeng',
            },
            coordinates: {
              lat: '15.560',
              lng: '103.496',
            },
            zipcode: 45150,
          },
          450209: {
            name: {
              th: 'กู่กาสิงห์',
              en: 'Ku Ka Sing',
            },
            coordinates: {
              lat: '15.591',
              lng: '103.655',
            },
            zipcode: 45150,
          },
          450210: {
            name: {
              th: 'น้ำอ้อม',
              en: 'Nam Om',
            },
            coordinates: {
              lat: '15.639',
              lng: '103.511',
            },
            zipcode: 45150,
          },
          450211: {
            name: {
              th: 'โนนสว่าง',
              en: 'Non Sawang',
            },
            coordinates: {
              lat: '15.704',
              lng: '103.506',
            },
            zipcode: 45150,
          },
          450212: {
            name: {
              th: 'ทุ่งทอง',
              en: 'Thung Thong',
            },
            coordinates: {
              lat: '15.487',
              lng: '103.451',
            },
            zipcode: 45150,
          },
          450213: {
            name: {
              th: 'ดงครั่งน้อย',
              en: 'Dong Khrang Noi',
            },
            coordinates: {
              lat: '15.511',
              lng: '103.617',
            },
            zipcode: 45150,
          },
        },
      },
      4503: {
        name: {
          th: 'ปทุมรัตต์',
          en: 'Pathum Rat',
        },
        tambons: {
          450301: {
            name: {
              th: 'บัวแดง',
              en: 'Bua Daeng',
            },
            coordinates: {
              lat: '15.650',
              lng: '103.359',
            },
            zipcode: 45190,
          },
          450302: {
            name: {
              th: 'ดอกล้ำ',
              en: 'Dok Lam',
            },
            coordinates: {
              lat: '15.639',
              lng: '103.309',
            },
            zipcode: 45190,
          },
          450303: {
            name: {
              th: 'หนองแคน',
              en: 'Nong Khaen',
            },
            coordinates: {
              lat: '15.688',
              lng: '103.374',
            },
            zipcode: 45190,
          },
          450304: {
            name: {
              th: 'โพนสูง',
              en: 'Phon Sung',
            },
            coordinates: {
              lat: '15.655',
              lng: '103.444',
            },
            zipcode: 45190,
          },
          450305: {
            name: {
              th: 'โนนสวรรค์',
              en: 'Non Sawan',
            },
            coordinates: {
              lat: '15.584',
              lng: '103.435',
            },
            zipcode: 45190,
          },
          450306: {
            name: {
              th: 'สระบัว',
              en: 'Sa Bua',
            },
            coordinates: {
              lat: '15.568',
              lng: '103.371',
            },
            zipcode: 45190,
          },
          450307: {
            name: {
              th: 'โนนสง่า',
              en: 'Non Sa-Nga',
            },
            coordinates: {
              lat: '15.608',
              lng: '103.349',
            },
            zipcode: 45190,
          },
          450308: {
            name: {
              th: 'ขี้เหล็ก',
              en: 'Khi Lek',
            },
            coordinates: {
              lat: '15.635',
              lng: '103.471',
            },
            zipcode: 45160,
          },
        },
      },
      4504: {
        name: {
          th: 'จตุรพักตรพิมาน',
          en: 'Chaturaphak Phiman',
        },
        tambons: {
          450401: {
            name: {
              th: 'หัวช้าง',
              en: 'Hua Chang',
            },
            coordinates: {
              lat: '15.845',
              lng: '103.569',
            },
            zipcode: 45130,
          },
          450402: {
            name: {
              th: 'หนองผือ',
              en: 'Nong Phue',
            },
            coordinates: {
              lat: '15.836',
              lng: '103.514',
            },
            zipcode: 45220,
          },
          450403: {
            name: {
              th: 'เมืองหงส์',
              en: 'Mueang Hong',
            },
            coordinates: {
              lat: '15.874',
              lng: '103.480',
            },
            zipcode: 45180,
          },
          450404: {
            name: {
              th: 'โคกล่าม',
              en: 'Khok Lam',
            },
            coordinates: {
              lat: '15.908',
              lng: '103.535',
            },
            zipcode: 45180,
          },
          450405: {
            name: {
              th: 'น้ำใส',
              en: 'Nam Sai',
            },
            coordinates: {
              lat: '15.901',
              lng: '103.599',
            },
            zipcode: 45180,
          },
          450406: {
            name: {
              th: 'ดงแดง',
              en: 'Dong Daeng',
            },
            coordinates: {
              lat: '15.854',
              lng: '103.669',
            },
            zipcode: 45180,
          },
          450407: {
            name: {
              th: 'ดงกลาง',
              en: 'Dong Klang',
            },
            coordinates: {
              lat: '15.803',
              lng: '103.604',
            },
            zipcode: 45180,
          },
          450408: {
            name: {
              th: 'ป่าสังข์',
              en: 'Pa Sang',
            },
            coordinates: {
              lat: '15.805',
              lng: '103.665',
            },
            zipcode: 45180,
          },
          450409: {
            name: {
              th: 'อีง่อง',
              en: 'I Ngong',
            },
            coordinates: {
              lat: '15.776',
              lng: '103.515',
            },
            zipcode: 45180,
          },
          450410: {
            name: {
              th: 'ลิ้นฟ้า',
              en: 'Lin Fa',
            },
            coordinates: {
              lat: '15.756',
              lng: '103.434',
            },
            zipcode: 45180,
          },
          450411: {
            name: {
              th: 'ดู่น้อย',
              en: 'Du Noi',
            },
            coordinates: {
              lat: '15.784',
              lng: '103.467',
            },
            zipcode: 45180,
          },
          450412: {
            name: {
              th: 'ศรีโคตร',
              en: 'Si Khot',
            },
            coordinates: {
              lat: '15.773',
              lng: '103.566',
            },
            zipcode: 45180,
          },
        },
      },
      4505: {
        name: {
          th: 'ธวัชบุรี',
          en: 'Thawat Buri',
        },
        tambons: {
          450501: {
            name: {
              th: 'นิเวศน์',
              en: 'Niwet',
            },
            coordinates: {
              lat: '16.042',
              lng: '103.737',
            },
            zipcode: 45170,
          },
          450502: {
            name: {
              th: 'ธงธานี',
              en: 'Thong Thani',
            },
            coordinates: {
              lat: '16.103',
              lng: '103.853',
            },
            zipcode: 45170,
          },
          450503: {
            name: {
              th: 'หนองไผ่',
              en: 'Nong Phai',
            },
            coordinates: {
              lat: '15.996',
              lng: '103.722',
            },
            zipcode: 45170,
          },
          450504: {
            name: {
              th: 'ธวัชบุรี',
              en: 'Thawat Buri',
            },
            coordinates: {
              lat: '16.116',
              lng: '103.811',
            },
            zipcode: 45170,
          },
          450506: {
            name: {
              th: 'อุ่มเม้า',
              en: 'Um Mao',
            },
            coordinates: {
              lat: '16.025',
              lng: '103.773',
            },
            zipcode: 45170,
          },
          450507: {
            name: {
              th: 'มะอึ',
              en: 'Ma-Ue',
            },
            coordinates: {
              lat: '16.081',
              lng: '103.731',
            },
            zipcode: 45170,
          },
          450510: {
            name: {
              th: 'เขวาทุ่ง',
              en: 'Khewa Thung',
            },
            coordinates: {
              lat: '15.945',
              lng: '103.760',
            },
            zipcode: 45170,
          },
          450515: {
            name: {
              th: 'ไพศาล',
              en: 'Phaisan',
            },
            coordinates: {
              lat: '16.026',
              lng: '103.817',
            },
            zipcode: 45170,
          },
          450517: {
            name: {
              th: 'เมืองน้อย',
              en: 'Mueang Noi',
            },
            coordinates: {
              lat: '15.985',
              lng: '103.764',
            },
            zipcode: 45170,
          },
          450520: {
            name: {
              th: 'บึงนคร',
              en: 'Bueng Nakhon',
            },
            coordinates: {
              lat: '16.072',
              lng: '103.852',
            },
            zipcode: 45170,
          },
          450522: {
            name: {
              th: 'ราชธานี',
              en: 'Ratchathani',
            },
            coordinates: {
              lat: '15.883',
              lng: '103.728',
            },
            zipcode: 45170,
          },
          450524: {
            name: {
              th: 'หนองพอก',
              en: 'Nong Phok',
            },
            coordinates: {
              lat: '16.099',
              lng: '103.767',
            },
            zipcode: 45210,
          },
        },
      },
      4506: {
        name: {
          th: 'พนมไพร',
          en: 'Phanom Phrai',
        },
        tambons: {
          450601: {
            name: {
              th: 'พนมไพร',
              en: 'Phanom Phrai',
            },
            coordinates: {
              lat: '15.688',
              lng: '104.118',
            },
            zipcode: 45140,
          },
          450602: {
            name: {
              th: 'แสนสุข',
              en: 'Saen Suk',
            },
            coordinates: {
              lat: '15.733',
              lng: '104.076',
            },
            zipcode: 45140,
          },
          450603: {
            name: {
              th: 'กุดน้ำใส',
              en: 'Kut Nam Sai',
            },
            coordinates: {
              lat: '15.762',
              lng: '104.065',
            },
            zipcode: 45140,
          },
          450604: {
            name: {
              th: 'หนองทัพไทย',
              en: 'Nong Thap Thai',
            },
            coordinates: {
              lat: '15.689',
              lng: '103.984',
            },
            zipcode: 45140,
          },
          450605: {
            name: {
              th: 'โพธิ์ใหญ่',
              en: 'Pho Yai',
            },
            coordinates: {
              lat: '15.757',
              lng: '104.013',
            },
            zipcode: 45140,
          },
          450606: {
            name: {
              th: 'วารีสวัสดิ์',
              en: 'Wari Sawat',
            },
            coordinates: {
              lat: '15.684',
              lng: '104.044',
            },
            zipcode: 45140,
          },
          450607: {
            name: {
              th: 'โคกสว่าง',
              en: 'Khok Sawang',
            },
            coordinates: {
              lat: '15.637',
              lng: '104.074',
            },
            zipcode: 45210,
          },
          450611: {
            name: {
              th: 'โพธิ์ชัย',
              en: 'Pho Chai',
            },
            coordinates: {
              lat: '15.591',
              lng: '104.080',
            },
            zipcode: 45140,
          },
          450612: {
            name: {
              th: 'นานวล',
              en: 'Na Nuan',
            },
            coordinates: {
              lat: '15.639',
              lng: '104.124',
            },
            zipcode: 45140,
          },
          450613: {
            name: {
              th: 'คำไฮ',
              en: 'Kham Hai',
            },
            coordinates: {
              lat: '15.639',
              lng: '104.159',
            },
            zipcode: 45140,
          },
          450614: {
            name: {
              th: 'สระแก้ว',
              en: 'Sa Kaeo',
            },
            coordinates: {
              lat: '15.704',
              lng: '104.080',
            },
            zipcode: 45140,
          },
          450615: {
            name: {
              th: 'ค้อใหญ่',
              en: 'Kho Yai',
            },
            coordinates: {
              lat: '15.600',
              lng: '104.139',
            },
            zipcode: 45140,
          },
          450617: {
            name: {
              th: 'ชานุวรรณ',
              en: 'Chanuwan',
            },
            coordinates: {
              lat: '15.803',
              lng: '104.088',
            },
            zipcode: 45140,
          },
        },
      },
      4507: {
        name: {
          th: 'โพนทอง',
          en: 'Phon Thong',
        },
        tambons: {
          450701: {
            name: {
              th: 'แวง',
              en: 'Waeng',
            },
            coordinates: {
              lat: '16.329',
              lng: '103.984',
            },
            zipcode: 45110,
          },
          450702: {
            name: {
              th: 'โคกกกม่วง',
              en: 'Khok Kok Muang',
            },
            coordinates: {
              lat: '16.181',
              lng: '104.028',
            },
            zipcode: 45110,
          },
          450703: {
            name: {
              th: 'นาอุดม',
              en: 'Na Udom',
            },
            coordinates: {
              lat: '16.318',
              lng: '103.856',
            },
            zipcode: 45110,
          },
          450704: {
            name: {
              th: 'สว่าง',
              en: 'Sawang',
            },
            coordinates: {
              lat: '16.218',
              lng: '104.005',
            },
            zipcode: 45110,
          },
          450705: {
            name: {
              th: 'หนองใหญ่',
              en: 'Nong Yai',
            },
            coordinates: {
              lat: '16.401',
              lng: '103.998',
            },
            zipcode: null,
          },
          450706: {
            name: {
              th: 'โพธิ์ทอง',
              en: 'Pho Thong',
            },
            coordinates: {
              lat: '16.322',
              lng: '103.920',
            },
            zipcode: null,
          },
          450707: {
            name: {
              th: 'โนนชัยศรี',
              en: 'Non Chai Si',
            },
            coordinates: {
              lat: '16.213',
              lng: '103.956',
            },
            zipcode: 45110,
          },
          450708: {
            name: {
              th: 'โพธิ์ศรีสว่าง',
              en: 'Pho Si Sawang',
            },
            coordinates: {
              lat: '16.242',
              lng: '103.888',
            },
            zipcode: 45110,
          },
          450709: {
            name: {
              th: 'อุ่มเม่า',
              en: 'Um Mao',
            },
            coordinates: {
              lat: '16.277',
              lng: '103.862',
            },
            zipcode: 45110,
          },
          450710: {
            name: {
              th: 'คำนาดี',
              en: null,
            },
            coordinates: {
              lat: '16.396',
              lng: '103.915',
            },
            zipcode: 45110,
          },
          450711: {
            name: {
              th: 'พรมสวรรค์',
              en: 'Phrom Sawan',
            },
            coordinates: {
              lat: '16.199',
              lng: '104.084',
            },
            zipcode: 45110,
          },
          450712: {
            name: {
              th: 'สระนกแก้ว',
              en: 'Sa Nok Kaeo',
            },
            coordinates: {
              lat: '16.271',
              lng: '103.986',
            },
            zipcode: 45110,
          },
          450713: {
            name: {
              th: 'วังสามัคคี',
              en: 'Wang Samakkhi',
            },
            coordinates: {
              lat: '16.267',
              lng: '104.041',
            },
            zipcode: 45110,
          },
          450714: {
            name: {
              th: 'โคกสูง',
              en: 'Khok Sung',
            },
            coordinates: {
              lat: '16.206',
              lng: '103.873',
            },
            zipcode: 45110,
          },
        },
      },
      4508: {
        name: {
          th: 'โพธิ์ชัย',
          en: 'Pho Chai',
        },
        tambons: {
          450801: {
            name: {
              th: 'ขามเปี้ย',
              en: 'Kham Pia',
            },
            coordinates: {
              lat: '16.334',
              lng: '103.748',
            },
            zipcode: 45230,
          },
          450802: {
            name: {
              th: 'เชียงใหม่',
              en: 'Chiang Mai',
            },
            coordinates: {
              lat: '16.242',
              lng: '103.804',
            },
            zipcode: null,
          },
          450803: {
            name: {
              th: 'บัวคำ',
              en: 'Bua Kham',
            },
            coordinates: {
              lat: '16.281',
              lng: '103.812',
            },
            zipcode: 45230,
          },
          450804: {
            name: {
              th: 'อัคคะคำ',
              en: 'Ak Khakham',
            },
            coordinates: {
              lat: '16.385',
              lng: '103.746',
            },
            zipcode: null,
          },
          450805: {
            name: {
              th: 'สะอาด',
              en: 'Sa-At',
            },
            coordinates: {
              lat: '16.209',
              lng: '103.767',
            },
            zipcode: 45230,
          },
          450806: {
            name: {
              th: 'คำพอุง',
              en: 'Kham Pha-Ung',
            },
            coordinates: {
              lat: '16.423',
              lng: '103.840',
            },
            zipcode: 45230,
          },
          450807: {
            name: {
              th: 'หนองตาไก้',
              en: 'Nong Ta Kai',
            },
            coordinates: {
              lat: '16.256',
              lng: '103.760',
            },
            zipcode: 45230,
          },
          450808: {
            name: {
              th: 'ดอนโอง',
              en: 'Don Ong',
            },
            coordinates: {
              lat: '16.206',
              lng: '103.826',
            },
            zipcode: 45230,
          },
          450809: {
            name: {
              th: 'โพธิ์ศรี',
              en: 'Pho Si',
            },
            coordinates: {
              lat: '16.344',
              lng: '103.830',
            },
            zipcode: 45230,
          },
        },
      },
      4509: {
        name: {
          th: 'หนองพอก',
          en: 'Nong Phok',
        },
        tambons: {
          450901: {
            name: {
              th: 'หนองพอก',
              en: 'Nong Phok',
            },
            coordinates: {
              lat: '16.318',
              lng: '104.159',
            },
            zipcode: null,
          },
          450902: {
            name: {
              th: 'บึงงาม',
              en: 'Bueng Ngam',
            },
            coordinates: {
              lat: '16.388',
              lng: '104.300',
            },
            zipcode: null,
          },
          450903: {
            name: {
              th: 'ภูเขาทอง',
              en: 'Phu Khao Thong',
            },
            coordinates: {
              lat: '16.398',
              lng: '104.228',
            },
            zipcode: 45210,
          },
          450904: {
            name: {
              th: 'กกโพธิ์',
              en: 'Kok Pho',
            },
            coordinates: {
              lat: '16.296',
              lng: '104.113',
            },
            zipcode: 45210,
          },
          450905: {
            name: {
              th: 'โคกสว่าง',
              en: 'Khok Sawang',
            },
            coordinates: {
              lat: '16.268',
              lng: '104.275',
            },
            zipcode: null,
          },
          450906: {
            name: {
              th: 'หนองขุ่นใหญ่',
              en: 'Nong Khun Yai',
            },
            coordinates: {
              lat: '16.234',
              lng: '104.146',
            },
            zipcode: 45210,
          },
          450907: {
            name: {
              th: 'รอบเมือง',
              en: 'Rop Mueang',
            },
            coordinates: {
              lat: '16.283',
              lng: '104.207',
            },
            zipcode: null,
          },
          450908: {
            name: {
              th: 'ผาน้ำย้อย',
              en: 'Pha Nam Yoi',
            },
            coordinates: {
              lat: '16.331',
              lng: '104.289',
            },
            zipcode: 45210,
          },
          450909: {
            name: {
              th: 'ท่าสีดา',
              en: 'Tha Si Da',
            },
            coordinates: {
              lat: '16.200',
              lng: '104.215',
            },
            zipcode: 45210,
          },
        },
      },
      4510: {
        name: {
          th: 'เสลภูมิ',
          en: 'Selaphum',
        },
        tambons: {
          451001: {
            name: {
              th: 'กลาง',
              en: 'Klang',
            },
            coordinates: {
              lat: '16.024',
              lng: '103.921',
            },
            zipcode: 45120,
          },
          451002: {
            name: {
              th: 'นางาม',
              en: 'Na Ngam',
            },
            coordinates: {
              lat: '15.911',
              lng: '103.940',
            },
            zipcode: 45120,
          },
          451003: {
            name: {
              th: 'เมืองไพร',
              en: 'Mueang Phrai',
            },
            coordinates: {
              lat: '16.001',
              lng: '103.993',
            },
            zipcode: 45120,
          },
          451004: {
            name: {
              th: 'นาแซง',
              en: 'Na Saeng',
            },
            coordinates: {
              lat: '16.144',
              lng: '103.968',
            },
            zipcode: 45120,
          },
          451005: {
            name: {
              th: 'นาเมือง',
              en: 'Na Mueang',
            },
            coordinates: {
              lat: '16.074',
              lng: '103.940',
            },
            zipcode: 45120,
          },
          451006: {
            name: {
              th: 'วังหลวง',
              en: 'Wang Luang',
            },
            coordinates: {
              lat: '16.090',
              lng: '103.995',
            },
            zipcode: 45120,
          },
          451007: {
            name: {
              th: 'ท่าม่วง',
              en: 'Tha Muang',
            },
            coordinates: {
              lat: '16.124',
              lng: '103.893',
            },
            zipcode: 45120,
          },
          451008: {
            name: {
              th: 'ขวาว',
              en: 'Khwao',
            },
            coordinates: {
              lat: '15.946',
              lng: '103.990',
            },
            zipcode: 45120,
          },
          451009: {
            name: {
              th: 'โพธิ์ทอง',
              en: 'Pho Thong',
            },
            coordinates: {
              lat: '16.136',
              lng: '104.138',
            },
            zipcode: null,
          },
          451010: {
            name: {
              th: 'ภูเงิน',
              en: 'Phu Ngoen',
            },
            coordinates: {
              lat: '16.040',
              lng: '104.078',
            },
            zipcode: 45120,
          },
          451011: {
            name: {
              th: 'เกาะแก้ว',
              en: 'Ko Kaeo',
            },
            coordinates: {
              lat: '16.161',
              lng: '103.868',
            },
            zipcode: 45120,
          },
          451012: {
            name: {
              th: 'นาเลิง',
              en: 'Na Loeng',
            },
            coordinates: {
              lat: '15.933',
              lng: '103.915',
            },
            zipcode: 45120,
          },
          451013: {
            name: {
              th: 'เหล่าน้อย',
              en: 'Lao Noi',
            },
            coordinates: {
              lat: '16.095',
              lng: '104.060',
            },
            zipcode: 45120,
          },
          451014: {
            name: {
              th: 'ศรีวิสัย',
              en: 'Si Wilai',
            },
            coordinates: {
              lat: '16.132',
              lng: '104.079',
            },
            zipcode: 45120,
          },
          451015: {
            name: {
              th: 'หนองหลวง',
              en: 'Nong Luang',
            },
            coordinates: {
              lat: '16.148',
              lng: '103.923',
            },
            zipcode: 45120,
          },
          451016: {
            name: {
              th: 'พรสวรรค์',
              en: 'Phon Sawan',
            },
            coordinates: {
              lat: '16.184',
              lng: '104.148',
            },
            zipcode: 45120,
          },
          451017: {
            name: {
              th: 'ขวัญเมือง',
              en: 'Khwan Mueang',
            },
            coordinates: {
              lat: '16.028',
              lng: '103.958',
            },
            zipcode: 45120,
          },
          451018: {
            name: {
              th: 'บึงเกลือ',
              en: 'Bueng Kluea',
            },
            coordinates: {
              lat: '16.020',
              lng: '104.021',
            },
            zipcode: 45120,
          },
        },
      },
      4511: {
        name: {
          th: 'สุวรรณภูมิ',
          en: 'Suwannaphum',
        },
        tambons: {
          451101: {
            name: {
              th: 'สระคู',
              en: 'Sa Khu',
            },
            coordinates: {
              lat: '15.552',
              lng: '103.766',
            },
            zipcode: 45130,
          },
          451102: {
            name: {
              th: 'ดอกไม้',
              en: 'Dokmai',
            },
            coordinates: {
              lat: '15.654',
              lng: '103.774',
            },
            zipcode: 45130,
          },
          451103: {
            name: {
              th: 'นาใหญ่',
              en: 'Na Yai',
            },
            coordinates: {
              lat: '15.704',
              lng: '103.750',
            },
            zipcode: 45130,
          },
          451104: {
            name: {
              th: 'หินกอง',
              en: 'Hin Kong',
            },
            coordinates: {
              lat: '15.599',
              lng: '103.730',
            },
            zipcode: 45130,
          },
          451105: {
            name: {
              th: 'เมืองทุ่ง',
              en: 'Mueang Thung',
            },
            coordinates: {
              lat: '15.627',
              lng: '103.850',
            },
            zipcode: 45130,
          },
          451106: {
            name: {
              th: 'หัวโทน',
              en: 'Hua Thon',
            },
            coordinates: {
              lat: '15.736',
              lng: '103.877',
            },
            zipcode: 45130,
          },
          451107: {
            name: {
              th: 'บ่อพันขัน',
              en: 'Bo Phan Khan',
            },
            coordinates: {
              lat: '15.604',
              lng: '103.898',
            },
            zipcode: 45130,
          },
          451108: {
            name: {
              th: 'ทุ่งหลวง',
              en: 'Thung Luang',
            },
            coordinates: {
              lat: '15.482',
              lng: '103.713',
            },
            zipcode: 45130,
          },
          451109: {
            name: {
              th: 'หัวช้าง',
              en: 'Hua Chang',
            },
            coordinates: {
              lat: '15.645',
              lng: '103.915',
            },
            zipcode: null,
          },
          451110: {
            name: {
              th: 'น้ำคำ',
              en: 'Nam Kham',
            },
            coordinates: {
              lat: '15.734',
              lng: '103.824',
            },
            zipcode: 45130,
          },
          451111: {
            name: {
              th: 'ห้วยหินลาด',
              en: 'Huai Hin Lat',
            },
            coordinates: {
              lat: '15.685',
              lng: '103.879',
            },
            zipcode: 45130,
          },
          451112: {
            name: {
              th: 'ช้างเผือก',
              en: 'Chang Phueak',
            },
            coordinates: {
              lat: '15.681',
              lng: '103.938',
            },
            zipcode: 45130,
          },
          451113: {
            name: {
              th: 'ทุ่งกุลา',
              en: 'Thung Kula',
            },
            coordinates: {
              lat: '15.482',
              lng: '103.805',
            },
            zipcode: 45130,
          },
          451114: {
            name: {
              th: 'ทุ่งศรีเมือง',
              en: 'Thung Si Mueang',
            },
            coordinates: {
              lat: '15.575',
              lng: '103.877',
            },
            zipcode: 45130,
          },
          451115: {
            name: {
              th: 'จำปาขัน',
              en: 'Champa Khan',
            },
            coordinates: {
              lat: '15.591',
              lng: '103.928',
            },
            zipcode: 45130,
          },
        },
      },
      4512: {
        name: {
          th: 'เมืองสรวง',
          en: 'Mueang Suang',
        },
        tambons: {
          451201: {
            name: {
              th: 'หนองผือ',
              en: 'Nong Phue',
            },
            coordinates: {
              lat: '15.817',
              lng: '103.714',
            },
            zipcode: null,
          },
          451202: {
            name: {
              th: 'หนองหิน',
              en: 'Nong Hin',
            },
            coordinates: {
              lat: '15.786',
              lng: '103.797',
            },
            zipcode: 45220,
          },
          451203: {
            name: {
              th: 'คูเมือง',
              en: 'Khu Mueang',
            },
            coordinates: {
              lat: '15.749',
              lng: '103.722',
            },
            zipcode: 45220,
          },
          451204: {
            name: {
              th: 'กกกุง',
              en: 'Kok Kung',
            },
            coordinates: {
              lat: '15.770',
              lng: '103.758',
            },
            zipcode: 45220,
          },
          451205: {
            name: {
              th: 'เมืองสรวง',
              en: 'Mueang Suang',
            },
            coordinates: {
              lat: '15.817',
              lng: '103.755',
            },
            zipcode: 45220,
          },
        },
      },
      4513: {
        name: {
          th: 'โพนทราย',
          en: 'Phon Sai',
        },
        tambons: {
          451301: {
            name: {
              th: 'โพนทราย',
              en: 'Phon Sai',
            },
            coordinates: {
              lat: '15.497',
              lng: '104.005',
            },
            zipcode: 45240,
          },
          451302: {
            name: {
              th: 'สามขา',
              en: 'Sam Kha',
            },
            coordinates: {
              lat: '15.474',
              lng: '103.944',
            },
            zipcode: 45240,
          },
          451303: {
            name: {
              th: 'ศรีสว่าง',
              en: 'Si Sawang',
            },
            coordinates: {
              lat: '15.533',
              lng: '103.868',
            },
            zipcode: 45240,
          },
          451304: {
            name: {
              th: 'ยางคำ',
              en: 'Yang Kham',
            },
            coordinates: {
              lat: '15.443',
              lng: '103.971',
            },
            zipcode: 45240,
          },
          451305: {
            name: {
              th: 'ท่าหาดยาว',
              en: 'Tha Hat Yao',
            },
            coordinates: {
              lat: '15.493',
              lng: '103.910',
            },
            zipcode: 45240,
          },
        },
      },
      4514: {
        name: {
          th: 'อาจสามารถ',
          en: 'At Samat',
        },
        tambons: {
          451401: {
            name: {
              th: 'อาจสามารถ',
              en: 'At Samat',
            },
            coordinates: {
              lat: '15.864',
              lng: '103.850',
            },
            zipcode: 45160,
          },
          451402: {
            name: {
              th: 'โพนเมือง',
              en: 'Phon Mueang',
            },
            coordinates: {
              lat: '15.913',
              lng: '103.881',
            },
            zipcode: 45160,
          },
          451403: {
            name: {
              th: 'บ้านแจ้ง',
              en: 'Ban Chaeng',
            },
            coordinates: {
              lat: '15.865',
              lng: '103.925',
            },
            zipcode: 45160,
          },
          451404: {
            name: {
              th: 'หน่อม',
              en: 'Nom',
            },
            coordinates: {
              lat: '15.808',
              lng: '103.955',
            },
            zipcode: 45160,
          },
          451405: {
            name: {
              th: 'หนองหมื่นถ่าน',
              en: 'Nong Muen Than',
            },
            coordinates: {
              lat: '15.755',
              lng: '103.937',
            },
            zipcode: 45160,
          },
          451406: {
            name: {
              th: 'หนองขาม',
              en: 'Nong Kham',
            },
            coordinates: {
              lat: '15.800',
              lng: '103.867',
            },
            zipcode: 45160,
          },
          451407: {
            name: {
              th: 'โหรา',
              en: 'Hora',
            },
            coordinates: {
              lat: '15.854',
              lng: '103.802',
            },
            zipcode: 45160,
          },
          451408: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '15.861',
              lng: '103.765',
            },
            zipcode: 45160,
          },
          451409: {
            name: {
              th: 'ขี้เหล็ก',
              en: 'Khi Lek',
            },
            coordinates: {
              lat: '15.903',
              lng: '103.791',
            },
            zipcode: null,
          },
        },
      },
      4515: {
        name: {
          th: 'เมยวดี',
          en: 'Moei Wadi',
        },
        tambons: {
          451501: {
            name: {
              th: 'เมยวดี',
              en: 'Moei Wadi',
            },
            coordinates: {
              lat: '16.395',
              lng: '104.167',
            },
            zipcode: null,
          },
          451502: {
            name: {
              th: 'ชุมพร',
              en: 'Chumphon',
            },
            coordinates: {
              lat: '16.342',
              lng: '104.067',
            },
            zipcode: null,
          },
          451503: {
            name: {
              th: 'บุ่งเลิศ',
              en: 'Bung Loet',
            },
            coordinates: {
              lat: '16.416',
              lng: '104.123',
            },
            zipcode: 45250,
          },
          451504: {
            name: {
              th: 'ชมสะอาด',
              en: 'Chom Sa-At',
            },
            coordinates: {
              lat: '16.393',
              lng: '104.098',
            },
            zipcode: 45250,
          },
        },
      },
      4516: {
        name: {
          th: 'ศรีสมเด็จ',
          en: 'Si Somdet',
        },
        tambons: {
          451601: {
            name: {
              th: 'โพธิ์ทอง',
              en: 'Pho Thong',
            },
            coordinates: {
              lat: '15.973',
              lng: '103.535',
            },
            zipcode: null,
          },
          451602: {
            name: {
              th: 'ศรีสมเด็จ',
              en: 'Si Somdet',
            },
            coordinates: {
              lat: '15.980',
              lng: '103.474',
            },
            zipcode: null,
          },
          451603: {
            name: {
              th: 'เมืองเปลือย',
              en: 'Mueang Plueai',
            },
            coordinates: {
              lat: '16.011',
              lng: '103.551',
            },
            zipcode: null,
          },
          451604: {
            name: {
              th: 'หนองใหญ่',
              en: 'Nong Yai',
            },
            coordinates: {
              lat: '16.044',
              lng: '103.541',
            },
            zipcode: null,
          },
          451605: {
            name: {
              th: 'สวนจิก',
              en: 'Suan Chik',
            },
            coordinates: {
              lat: '16.034',
              lng: '103.492',
            },
            zipcode: null,
          },
          451606: {
            name: {
              th: 'โพธิ์สัย',
              en: 'Pho Sai',
            },
            coordinates: {
              lat: '16.096',
              lng: '103.475',
            },
            zipcode: 45280,
          },
          451607: {
            name: {
              th: 'หนองแวงควง',
              en: 'Nong Waeng Khuang',
            },
            coordinates: {
              lat: '15.950',
              lng: '103.506',
            },
            zipcode: 45000,
          },
          451608: {
            name: {
              th: 'บ้านบาก',
              en: 'Ban Bak',
            },
            coordinates: {
              lat: '15.987',
              lng: '103.518',
            },
            zipcode: 45000,
          },
        },
      },
      4517: {
        name: {
          th: 'จังหาร',
          en: 'Changhan',
        },
        tambons: {
          451701: {
            name: {
              th: 'ดินดำ',
              en: 'Din Dam',
            },
            coordinates: {
              lat: '16.183',
              lng: '103.679',
            },
            zipcode: null,
          },
          451702: {
            name: {
              th: 'ปาฝา',
              en: 'Pa Fa',
            },
            coordinates: {
              lat: '16.140',
              lng: '103.637',
            },
            zipcode: null,
          },
          451703: {
            name: {
              th: 'ม่วงลาด',
              en: 'Muang Lat',
            },
            coordinates: {
              lat: '16.204',
              lng: '103.543',
            },
            zipcode: null,
          },
          451704: {
            name: {
              th: 'จังหาร',
              en: 'Changhan',
            },
            coordinates: {
              lat: '16.140',
              lng: '103.594',
            },
            zipcode: null,
          },
          451705: {
            name: {
              th: 'ดงสิงห์',
              en: 'Dong Sing',
            },
            coordinates: {
              lat: '16.175',
              lng: '103.580',
            },
            zipcode: null,
          },
          451706: {
            name: {
              th: 'ยางใหญ่',
              en: 'Yang Yai',
            },
            coordinates: {
              lat: '16.132',
              lng: '103.679',
            },
            zipcode: 45000,
          },
          451707: {
            name: {
              th: 'ผักแว่น',
              en: 'Phak Waen',
            },
            coordinates: {
              lat: '16.174',
              lng: '103.535',
            },
            zipcode: 45000,
          },
          451708: {
            name: {
              th: 'แสนชาติ',
              en: 'Saen Chat',
            },
            coordinates: {
              lat: '16.176',
              lng: '103.639',
            },
            zipcode: 45000,
          },
        },
      },
      4518: {
        name: {
          th: 'เชียงขวัญ',
          en: 'Chiang Khwan',
        },
        tambons: {
          451801: {
            name: {
              th: 'เชียงขวัญ',
              en: null,
            },
            coordinates: {
              lat: '16.191',
              lng: '103.724',
            },
            zipcode: null,
          },
          451802: {
            name: {
              th: 'พลับพลา',
              en: 'Phlapphla',
            },
            coordinates: {
              lat: '16.135',
              lng: '103.782',
            },
            zipcode: null,
          },
          451803: {
            name: {
              th: 'พระธาตุ',
              en: 'Phrathat',
            },
            coordinates: {
              lat: '16.160',
              lng: '103.769',
            },
            zipcode: null,
          },
          451804: {
            name: {
              th: 'พระเจ้า',
              en: 'Phrachao',
            },
            coordinates: {
              lat: '16.128',
              lng: '103.741',
            },
            zipcode: null,
          },
          451805: {
            name: {
              th: 'หมูม้น',
              en: null,
            },
            coordinates: {
              lat: '16.101',
              lng: '103.705',
            },
            zipcode: null,
          },
          451806: {
            name: {
              th: 'บ้านเขือง',
              en: null,
            },
            coordinates: {
              lat: '16.150',
              lng: '103.710',
            },
            zipcode: null,
          },
        },
      },
      4519: {
        name: {
          th: 'หนองฮี',
          en: 'Nong Hi',
        },
        tambons: {
          451901: {
            name: {
              th: 'หนองฮี',
              en: 'Nong Hi',
            },
            coordinates: {
              lat: '15.621',
              lng: '104.000',
            },
            zipcode: null,
          },
          451902: {
            name: {
              th: 'สาวแห',
              en: 'Sao Hae',
            },
            coordinates: {
              lat: '15.550',
              lng: '104.062',
            },
            zipcode: null,
          },
          451903: {
            name: {
              th: 'ดูกอึ่ง',
              en: 'Duk Ueng',
            },
            coordinates: {
              lat: '15.559',
              lng: '104.016',
            },
            zipcode: null,
          },
          451904: {
            name: {
              th: 'เด่นราษฎร์',
              en: 'Den Rat',
            },
            coordinates: {
              lat: '15.582',
              lng: '103.965',
            },
            zipcode: null,
          },
        },
      },
      4520: {
        name: {
          th: 'ทุ่งเขาหลวง',
          en: 'Thung Khao Luang',
        },
        tambons: {
          452001: {
            name: {
              th: 'ทุ่งเขาหลวง',
              en: null,
            },
            coordinates: {
              lat: '16.032',
              lng: '103.853',
            },
            zipcode: null,
          },
          452002: {
            name: {
              th: 'เทอดไทย',
              en: 'Thoet Thai',
            },
            coordinates: {
              lat: '15.963',
              lng: '103.866',
            },
            zipcode: null,
          },
          452003: {
            name: {
              th: 'บึงงาม',
              en: 'Bueng Ngam',
            },
            coordinates: {
              lat: '15.986',
              lng: '103.894',
            },
            zipcode: null,
          },
          452004: {
            name: {
              th: 'มะบ้า',
              en: null,
            },
            coordinates: {
              lat: '16.039',
              lng: '103.889',
            },
            zipcode: null,
          },
          452005: {
            name: {
              th: 'เหล่า',
              en: 'Lao',
            },
            coordinates: {
              lat: '15.965',
              lng: '103.827',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  46: {
    name: {
      th: 'กาฬสินธุ์',
      en: 'Kalasin',
    },
    amphoes: {
      4601: {
        name: {
          th: 'เมืองกาฬสินธุ์',
          en: 'Mueang Kalasin',
        },
        tambons: {
          460101: {
            name: {
              th: 'กาฬสินธุ์',
              en: 'Kalasin',
            },
            coordinates: {
              lat: '16.437',
              lng: '103.522',
            },
            zipcode: 46000,
          },
          460102: {
            name: {
              th: 'เหนือ',
              en: 'Nuea',
            },
            coordinates: {
              lat: '16.442',
              lng: '103.574',
            },
            zipcode: 46000,
          },
          460103: {
            name: {
              th: 'หลุบ',
              en: 'Lup',
            },
            coordinates: {
              lat: '16.393',
              lng: '103.512',
            },
            zipcode: 46000,
          },
          460104: {
            name: {
              th: 'ไผ่',
              en: 'Phai',
            },
            coordinates: {
              lat: '16.522',
              lng: '103.575',
            },
            zipcode: 46000,
          },
          460105: {
            name: {
              th: 'ลำปาว',
              en: 'Lampao',
            },
            coordinates: {
              lat: '16.591',
              lng: '103.496',
            },
            zipcode: 46000,
          },
          460106: {
            name: {
              th: 'ลำพาน',
              en: 'Lamphan',
            },
            coordinates: {
              lat: '16.457',
              lng: '103.484',
            },
            zipcode: 46000,
          },
          460107: {
            name: {
              th: 'เชียงเครือ',
              en: 'Chiang Khruea',
            },
            coordinates: {
              lat: '16.434',
              lng: '103.612',
            },
            zipcode: 46000,
          },
          460108: {
            name: {
              th: 'บึงวิชัย',
              en: 'Bueng Wichai',
            },
            coordinates: {
              lat: '16.500',
              lng: '103.499',
            },
            zipcode: 46000,
          },
          460109: {
            name: {
              th: 'ห้วยโพธิ์',
              en: 'Huai Pho',
            },
            coordinates: {
              lat: '16.392',
              lng: '103.578',
            },
            zipcode: 46000,
          },
          460111: {
            name: {
              th: 'ภูปอ',
              en: 'Phu Po',
            },
            coordinates: {
              lat: '16.590',
              lng: '103.615',
            },
            zipcode: 46000,
          },
          460113: {
            name: {
              th: 'ภูดิน',
              en: 'Phu Din',
            },
            coordinates: {
              lat: '16.636',
              lng: '103.528',
            },
            zipcode: 46000,
          },
          460115: {
            name: {
              th: 'หนองกุง',
              en: 'Nong Kung',
            },
            coordinates: {
              lat: '16.534',
              lng: '103.526',
            },
            zipcode: 46000,
          },
          460116: {
            name: {
              th: 'กลางหมื่น',
              en: 'Klang Muen',
            },
            coordinates: {
              lat: '16.518',
              lng: '103.641',
            },
            zipcode: 46000,
          },
          460117: {
            name: {
              th: 'ขมิ้น',
              en: 'Khamin',
            },
            coordinates: {
              lat: '16.607',
              lng: '103.569',
            },
            zipcode: 46000,
          },
          460119: {
            name: {
              th: 'โพนทอง',
              en: 'Phon Thong',
            },
            coordinates: {
              lat: '16.478',
              lng: '103.548',
            },
            zipcode: 46000,
          },
          460120: {
            name: {
              th: 'นาจารย์',
              en: 'Na Chan',
            },
            coordinates: {
              lat: '16.561',
              lng: '103.643',
            },
            zipcode: 46000,
          },
          460121: {
            name: {
              th: 'ลำคลอง',
              en: 'Lam Khlong',
            },
            coordinates: {
              lat: '16.587',
              lng: '103.450',
            },
            zipcode: 46000,
          },
        },
      },
      4602: {
        name: {
          th: 'นามน',
          en: 'Na Mon',
        },
        tambons: {
          460201: {
            name: {
              th: 'นามน',
              en: 'Na Mon',
            },
            coordinates: {
              lat: '16.561',
              lng: '103.776',
            },
            zipcode: null,
          },
          460202: {
            name: {
              th: 'ยอดแกง',
              en: 'Yot Kaeng',
            },
            coordinates: {
              lat: '16.622',
              lng: '103.735',
            },
            zipcode: null,
          },
          460203: {
            name: {
              th: 'สงเปลือย',
              en: 'Song Plueai',
            },
            coordinates: {
              lat: '16.624',
              lng: '103.793',
            },
            zipcode: 46230,
          },
          460204: {
            name: {
              th: 'หลักเหลี่ยม',
              en: 'Lak Liam',
            },
            coordinates: {
              lat: '16.548',
              lng: '103.820',
            },
            zipcode: 46230,
          },
          460205: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '16.537',
              lng: '103.855',
            },
            zipcode: 46220,
          },
        },
      },
      4603: {
        name: {
          th: 'กมลาไสย',
          en: 'Kamalasai',
        },
        tambons: {
          460301: {
            name: {
              th: 'กมลาไสย',
              en: 'Kamalasai',
            },
            coordinates: {
              lat: '16.310',
              lng: '103.551',
            },
            zipcode: 46130,
          },
          460302: {
            name: {
              th: 'หลักเมือง',
              en: 'Lak Mueang',
            },
            coordinates: {
              lat: '16.332',
              lng: '103.595',
            },
            zipcode: 46130,
          },
          460303: {
            name: {
              th: 'โพนงาม',
              en: 'Phon Ngam',
            },
            coordinates: {
              lat: '16.316',
              lng: '103.658',
            },
            zipcode: 46130,
          },
          460304: {
            name: {
              th: 'ดงลิง',
              en: 'Dong Ling',
            },
            coordinates: {
              lat: '16.250',
              lng: '103.661',
            },
            zipcode: 46130,
          },
          460305: {
            name: {
              th: 'ธัญญา',
              en: 'Thanya',
            },
            coordinates: {
              lat: '16.259',
              lng: '103.567',
            },
            zipcode: 46130,
          },
          460308: {
            name: {
              th: 'หนองแปน',
              en: 'Nong Paen',
            },
            coordinates: {
              lat: '16.289',
              lng: '103.511',
            },
            zipcode: 46130,
          },
          460310: {
            name: {
              th: 'เจ้าท่า',
              en: 'Chao Tha',
            },
            coordinates: {
              lat: '16.225',
              lng: '103.591',
            },
            zipcode: 46130,
          },
          460311: {
            name: {
              th: 'โคกสมบูรณ์',
              en: 'Khok Sombun',
            },
            coordinates: {
              lat: '16.360',
              lng: '103.667',
            },
            zipcode: 46130,
          },
        },
      },
      4604: {
        name: {
          th: 'ร่องคำ',
          en: 'Rong Kham',
        },
        tambons: {
          460401: {
            name: {
              th: 'ร่องคำ',
              en: 'Rong Kham',
            },
            coordinates: {
              lat: '16.266',
              lng: '103.737',
            },
            zipcode: 46210,
          },
          460402: {
            name: {
              th: 'สามัคคี',
              en: 'Samakkhi',
            },
            coordinates: {
              lat: '16.318',
              lng: '103.713',
            },
            zipcode: 46210,
          },
          460403: {
            name: {
              th: 'เหล่าอ้อย',
              en: 'Lao Oi',
            },
            coordinates: {
              lat: '16.254',
              lng: '103.708',
            },
            zipcode: 46210,
          },
        },
      },
      4605: {
        name: {
          th: 'กุฉินารายณ์',
          en: 'Kuchinarai',
        },
        tambons: {
          460501: {
            name: {
              th: 'บัวขาว',
              en: 'Bua Khao',
            },
            coordinates: {
              lat: '16.521',
              lng: '104.055',
            },
            zipcode: 46110,
          },
          460502: {
            name: {
              th: 'แจนแลน',
              en: 'Chaen Laen',
            },
            coordinates: {
              lat: '16.554',
              lng: '103.998',
            },
            zipcode: 46110,
          },
          460503: {
            name: {
              th: 'เหล่าใหญ่',
              en: 'Lao Yai',
            },
            coordinates: {
              lat: '16.622',
              lng: '103.993',
            },
            zipcode: 46110,
          },
          460504: {
            name: {
              th: 'จุมจัง',
              en: 'Chum Chang',
            },
            coordinates: {
              lat: '16.458',
              lng: '104.107',
            },
            zipcode: 46110,
          },
          460505: {
            name: {
              th: 'เหล่าไฮงาม',
              en: 'Lao Hai Ngam',
            },
            coordinates: {
              lat: '16.471',
              lng: '104.171',
            },
            zipcode: 46110,
          },
          460506: {
            name: {
              th: 'กุดหว้า',
              en: 'Kut Wa',
            },
            coordinates: {
              lat: '16.537',
              lng: '104.167',
            },
            zipcode: 46110,
          },
          460507: {
            name: {
              th: 'สามขา',
              en: 'Sam Kha',
            },
            coordinates: {
              lat: '16.464',
              lng: '103.975',
            },
            zipcode: 46110,
          },
          460508: {
            name: {
              th: 'นาขาม',
              en: 'Nakham',
            },
            coordinates: {
              lat: '16.508',
              lng: '103.905',
            },
            zipcode: 46110,
          },
          460509: {
            name: {
              th: 'หนองห้าง',
              en: 'Nong Hang',
            },
            coordinates: {
              lat: '16.572',
              lng: '104.124',
            },
            zipcode: 46110,
          },
          460510: {
            name: {
              th: 'นาโก',
              en: 'Nako',
            },
            coordinates: {
              lat: '16.606',
              lng: '104.055',
            },
            zipcode: 46110,
          },
          460511: {
            name: {
              th: 'สมสะอาด',
              en: 'Som Sa-At',
            },
            coordinates: {
              lat: '16.451',
              lng: '104.048',
            },
            zipcode: 46110,
          },
          460512: {
            name: {
              th: 'กุดค้าว',
              en: 'Kut Khao',
            },
            coordinates: {
              lat: '16.506',
              lng: '104.001',
            },
            zipcode: 46110,
          },
        },
      },
      4606: {
        name: {
          th: 'เขาวง',
          en: 'Khao Wong',
        },
        tambons: {
          460601: {
            name: {
              th: 'คุ้มเก่า',
              en: 'Khum Kao',
            },
            coordinates: {
              lat: '16.679',
              lng: '104.132',
            },
            zipcode: 46160,
          },
          460602: {
            name: {
              th: 'สงเปลือย',
              en: 'Song Plueai',
            },
            coordinates: {
              lat: '16.626',
              lng: '104.119',
            },
            zipcode: null,
          },
          460603: {
            name: {
              th: 'หนองผือ',
              en: 'Nong Phue',
            },
            coordinates: {
              lat: '16.751',
              lng: '104.094',
            },
            zipcode: 46160,
          },
          460606: {
            name: {
              th: 'กุดสิมคุ้มใหม่',
              en: 'Kut Sinkhum Mai',
            },
            coordinates: {
              lat: '16.676',
              lng: '104.049',
            },
            zipcode: 46160,
          },
          460608: {
            name: {
              th: 'สระพังทอง',
              en: 'Sa Phang Thong',
            },
            coordinates: {
              lat: '16.719',
              lng: '104.055',
            },
            zipcode: 46160,
          },
          460611: {
            name: {
              th: 'กุดปลาค้าว',
              en: 'Kut Pla Khao',
            },
            coordinates: {
              lat: '16.715',
              lng: '104.115',
            },
            zipcode: 46160,
          },
        },
      },
      4607: {
        name: {
          th: 'ยางตลาด',
          en: 'Yang Talat',
        },
        tambons: {
          460701: {
            name: {
              th: 'ยางตลาด',
              en: 'Yang Talat',
            },
            coordinates: {
              lat: '16.393',
              lng: '103.352',
            },
            zipcode: 46120,
          },
          460702: {
            name: {
              th: 'หัวงัว',
              en: 'Hua Ngua',
            },
            coordinates: {
              lat: '16.337',
              lng: '103.446',
            },
            zipcode: 46120,
          },
          460703: {
            name: {
              th: 'อุ่มเม่า',
              en: 'Um Mao',
            },
            coordinates: {
              lat: '16.398',
              lng: '103.464',
            },
            zipcode: 46120,
          },
          460704: {
            name: {
              th: 'บัวบาน',
              en: 'Bua Ban',
            },
            coordinates: {
              lat: '16.479',
              lng: '103.416',
            },
            zipcode: 46120,
          },
          460705: {
            name: {
              th: 'เว่อ',
              en: 'Woe',
            },
            coordinates: {
              lat: '16.543',
              lng: '103.351',
            },
            zipcode: 46120,
          },
          460706: {
            name: {
              th: 'อิตื้อ',
              en: 'I Tue',
            },
            coordinates: {
              lat: '16.464',
              lng: '103.262',
            },
            zipcode: 46120,
          },
          460707: {
            name: {
              th: 'หัวนาคำ',
              en: 'Hua Na Kham',
            },
            coordinates: {
              lat: '16.392',
              lng: '103.227',
            },
            zipcode: 46120,
          },
          460708: {
            name: {
              th: 'หนองอีเฒ่า',
              en: 'Nong I Thao',
            },
            coordinates: {
              lat: '16.339',
              lng: '103.371',
            },
            zipcode: 46120,
          },
          460709: {
            name: {
              th: 'ดอนสมบูรณ์',
              en: 'Don Sombun',
            },
            coordinates: {
              lat: '16.437',
              lng: '103.406',
            },
            zipcode: 46120,
          },
          460710: {
            name: {
              th: 'นาเชือก',
              en: 'Na Chueak',
            },
            coordinates: {
              lat: '16.557',
              lng: '103.415',
            },
            zipcode: 46120,
          },
          460711: {
            name: {
              th: 'คลองขาม',
              en: 'Khlong Kham',
            },
            coordinates: {
              lat: '16.456',
              lng: '103.338',
            },
            zipcode: 46120,
          },
          460712: {
            name: {
              th: 'เขาพระนอน',
              en: 'Khao Phra Non',
            },
            coordinates: {
              lat: '16.529',
              lng: '103.380',
            },
            zipcode: 46120,
          },
          460713: {
            name: {
              th: 'นาดี',
              en: 'Na Di',
            },
            coordinates: {
              lat: '16.361',
              lng: '103.474',
            },
            zipcode: 46120,
          },
          460714: {
            name: {
              th: 'โนนสูง',
              en: 'Non Sung',
            },
            coordinates: {
              lat: '16.464',
              lng: '103.207',
            },
            zipcode: 46120,
          },
          460715: {
            name: {
              th: 'หนองตอกแป้น',
              en: 'Nong Tok Paen',
            },
            coordinates: {
              lat: '16.319',
              lng: '103.406',
            },
            zipcode: 46120,
          },
        },
      },
      4608: {
        name: {
          th: 'ห้วยเม็ก',
          en: 'Huai Mek',
        },
        tambons: {
          460801: {
            name: {
              th: 'ห้วยเม็ก',
              en: 'Huai Mek',
            },
            coordinates: {
              lat: '16.596',
              lng: '103.216',
            },
            zipcode: 46170,
          },
          460802: {
            name: {
              th: 'คำใหญ่',
              en: 'Kham Yai',
            },
            coordinates: {
              lat: '16.664',
              lng: '103.177',
            },
            zipcode: 46170,
          },
          460803: {
            name: {
              th: 'กุดโดน',
              en: 'Kut Don',
            },
            coordinates: {
              lat: '16.544',
              lng: '103.268',
            },
            zipcode: 46170,
          },
          460804: {
            name: {
              th: 'บึงนาเรียง',
              en: 'Bueng Na Riang',
            },
            coordinates: {
              lat: '16.600',
              lng: '103.288',
            },
            zipcode: 46170,
          },
          460805: {
            name: {
              th: 'หัวหิน',
              en: 'Hua Hin',
            },
            coordinates: {
              lat: '16.602',
              lng: '103.358',
            },
            zipcode: 46170,
          },
          460806: {
            name: {
              th: 'พิมูล',
              en: 'Phimun',
            },
            coordinates: {
              lat: '16.703',
              lng: '103.181',
            },
            zipcode: 46170,
          },
          460807: {
            name: {
              th: 'คำเหมือดแก้ว',
              en: 'Kham Mueat Kaeo',
            },
            coordinates: {
              lat: '16.523',
              lng: '103.199',
            },
            zipcode: 46170,
          },
          460808: {
            name: {
              th: 'โนนสะอาด',
              en: 'Non Sa-At',
            },
            coordinates: {
              lat: '16.538',
              lng: '103.314',
            },
            zipcode: 46170,
          },
          460809: {
            name: {
              th: 'ทรายทอง',
              en: 'Sai Thong',
            },
            coordinates: {
              lat: '16.740',
              lng: '103.175',
            },
            zipcode: 46170,
          },
        },
      },
      4609: {
        name: {
          th: 'สหัสขันธ์',
          en: 'Sahatsakhan',
        },
        tambons: {
          460902: {
            name: {
              th: 'สหัสขันธ์',
              en: 'Sahatsakhan',
            },
            coordinates: {
              lat: '16.728',
              lng: '103.550',
            },
            zipcode: 46140,
          },
          460903: {
            name: {
              th: 'นามะเขือ',
              en: 'Na Makhuea',
            },
            coordinates: {
              lat: '16.678',
              lng: '103.638',
            },
            zipcode: 46140,
          },
          460904: {
            name: {
              th: 'โนนศิลา',
              en: 'Non Sila',
            },
            coordinates: {
              lat: '16.800',
              lng: '103.605',
            },
            zipcode: null,
          },
          460905: {
            name: {
              th: 'นิคม',
              en: 'Nikhom',
            },
            coordinates: {
              lat: '16.677',
              lng: '103.492',
            },
            zipcode: 46140,
          },
          460906: {
            name: {
              th: 'โนนแหลมทอง',
              en: 'Non Laem Thong',
            },
            coordinates: {
              lat: '16.751',
              lng: '103.619',
            },
            zipcode: 46140,
          },
          460907: {
            name: {
              th: 'โนนบุรี',
              en: 'Non Buri',
            },
            coordinates: {
              lat: '16.706',
              lng: '103.491',
            },
            zipcode: 46140,
          },
          460908: {
            name: {
              th: 'โนนน้ำเกลี้ยง',
              en: 'Non Nam Kliang',
            },
            coordinates: {
              lat: '16.683',
              lng: '103.538',
            },
            zipcode: 46140,
          },
        },
      },
      4610: {
        name: {
          th: 'คำม่วง',
          en: 'Kham Muang',
        },
        tambons: {
          461001: {
            name: {
              th: 'ทุ่งคลอง',
              en: 'Thung Khlong',
            },
            coordinates: {
              lat: '16.938',
              lng: '103.679',
            },
            zipcode: null,
          },
          461002: {
            name: {
              th: 'โพน',
              en: 'Phon',
            },
            coordinates: {
              lat: '16.841',
              lng: '103.637',
            },
            zipcode: null,
          },
          461005: {
            name: {
              th: 'ดินจี่',
              en: 'Din Chi',
            },
            coordinates: {
              lat: '16.962',
              lng: '103.719',
            },
            zipcode: 46180,
          },
          461006: {
            name: {
              th: 'นาบอน',
              en: 'Na Bon',
            },
            coordinates: {
              lat: '16.881',
              lng: '103.673',
            },
            zipcode: 46180,
          },
          461007: {
            name: {
              th: 'นาทัน',
              en: 'Na Than',
            },
            coordinates: {
              lat: '17.024',
              lng: '103.613',
            },
            zipcode: 46180,
          },
          461009: {
            name: {
              th: 'เนินยาง',
              en: 'Noen Yang',
            },
            coordinates: {
              lat: '16.803',
              lng: '103.655',
            },
            zipcode: 46180,
          },
        },
      },
      4611: {
        name: {
          th: 'ท่าคันโท',
          en: 'Tha Khantho',
        },
        tambons: {
          461101: {
            name: {
              th: 'ท่าคันโท',
              en: 'Tha Khantho',
            },
            coordinates: {
              lat: '16.869',
              lng: '103.237',
            },
            zipcode: 46190,
          },
          461102: {
            name: {
              th: 'กุงเก่า',
              en: 'Kung Kao',
            },
            coordinates: {
              lat: '16.870',
              lng: '103.188',
            },
            zipcode: 46190,
          },
          461103: {
            name: {
              th: 'ยางอู้ม',
              en: 'Yang Um',
            },
            coordinates: {
              lat: '16.902',
              lng: '103.286',
            },
            zipcode: 46190,
          },
          461104: {
            name: {
              th: 'กุดจิก',
              en: 'Kut Chik',
            },
            coordinates: {
              lat: '16.843',
              lng: '103.115',
            },
            zipcode: 46190,
          },
          461105: {
            name: {
              th: 'นาตาล',
              en: 'Na Tan',
            },
            coordinates: {
              lat: '16.847',
              lng: '103.280',
            },
            zipcode: 46190,
          },
          461106: {
            name: {
              th: 'ดงสมบูรณ์',
              en: 'Dong Sombun',
            },
            coordinates: {
              lat: '16.869',
              lng: '103.366',
            },
            zipcode: 46190,
          },
        },
      },
      4612: {
        name: {
          th: 'หนองกุงศรี',
          en: 'Nong Kung Si',
        },
        tambons: {
          461201: {
            name: {
              th: 'หนองกุงศรี',
              en: 'Nong Kung Si',
            },
            coordinates: {
              lat: '16.649',
              lng: '103.274',
            },
            zipcode: 46220,
          },
          461202: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '16.728',
              lng: '103.376',
            },
            zipcode: null,
          },
          461203: {
            name: {
              th: 'โคกเครือ',
              en: 'Khok Khruea',
            },
            coordinates: {
              lat: '16.781',
              lng: '103.238',
            },
            zipcode: null,
          },
          461204: {
            name: {
              th: 'หนองสรวง',
              en: 'Nong Suang',
            },
            coordinates: {
              lat: '16.770',
              lng: '103.422',
            },
            zipcode: 46220,
          },
          461205: {
            name: {
              th: 'เสาเล้า',
              en: 'Sao Lao',
            },
            coordinates: {
              lat: '16.651',
              lng: '103.392',
            },
            zipcode: 46220,
          },
          461206: {
            name: {
              th: 'หนองใหญ่',
              en: 'Nong Yai',
            },
            coordinates: {
              lat: '16.701',
              lng: '103.223',
            },
            zipcode: 46220,
          },
          461207: {
            name: {
              th: 'ดงมูล',
              en: 'Dong Mun',
            },
            coordinates: {
              lat: '16.779',
              lng: '103.313',
            },
            zipcode: 46220,
          },
          461208: {
            name: {
              th: 'ลำหนองแสน',
              en: 'Lam Nong Saen',
            },
            coordinates: {
              lat: '16.678',
              lng: '103.307',
            },
            zipcode: 46220,
          },
          461209: {
            name: {
              th: 'หนองหิน',
              en: 'Nong Hin',
            },
            coordinates: {
              lat: '16.823',
              lng: '103.397',
            },
            zipcode: 46220,
          },
        },
      },
      4613: {
        name: {
          th: 'สมเด็จ',
          en: 'Somdet',
        },
        tambons: {
          461301: {
            name: {
              th: 'สมเด็จ',
              en: 'Somdet',
            },
            coordinates: {
              lat: '16.705',
              lng: '103.754',
            },
            zipcode: 46150,
          },
          461302: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '16.660',
              lng: '103.690',
            },
            zipcode: 46150,
          },
          461303: {
            name: {
              th: 'แซงบาดาล',
              en: 'Saeng Badan',
            },
            coordinates: {
              lat: '16.819',
              lng: '103.700',
            },
            zipcode: 46150,
          },
          461304: {
            name: {
              th: 'มหาไชย',
              en: 'Mahachai',
            },
            coordinates: {
              lat: '16.823',
              lng: '103.783',
            },
            zipcode: 46150,
          },
          461305: {
            name: {
              th: 'หมูม่น',
              en: 'Mu Mon',
            },
            coordinates: {
              lat: '16.759',
              lng: '103.679',
            },
            zipcode: 46150,
          },
          461306: {
            name: {
              th: 'ผาเสวย',
              en: 'Pha Sawoei',
            },
            coordinates: {
              lat: '16.797',
              lng: '103.863',
            },
            zipcode: 46150,
          },
          461307: {
            name: {
              th: 'ศรีสมเด็จ',
              en: 'Si Somdet',
            },
            coordinates: {
              lat: '16.738',
              lng: '103.706',
            },
            zipcode: 46150,
          },
          461308: {
            name: {
              th: 'ลำห้วยหลัว',
              en: 'Lam Huai Lua',
            },
            coordinates: {
              lat: '16.732',
              lng: '103.815',
            },
            zipcode: 46150,
          },
        },
      },
      4614: {
        name: {
          th: 'ห้วยผึ้ง',
          en: 'Huai Phueng',
        },
        tambons: {
          461401: {
            name: {
              th: 'คำบง',
              en: 'Kham Bong',
            },
            coordinates: {
              lat: '16.758',
              lng: '103.912',
            },
            zipcode: 46240,
          },
          461402: {
            name: {
              th: 'ไค้นุ่น',
              en: 'Khai Nun',
            },
            coordinates: {
              lat: '16.612',
              lng: '103.933',
            },
            zipcode: 46240,
          },
          461403: {
            name: {
              th: 'นิคมห้วยผึ้ง',
              en: 'Nikhom Huai Phueng',
            },
            coordinates: {
              lat: '16.675',
              lng: '103.909',
            },
            zipcode: 46240,
          },
          461404: {
            name: {
              th: 'หนองอีบุตร',
              en: 'Nong I But',
            },
            coordinates: {
              lat: '16.597',
              lng: '103.873',
            },
            zipcode: 46240,
          },
        },
      },
      4615: {
        name: {
          th: 'สามชัย',
          en: 'Sam Chai',
        },
        tambons: {
          461501: {
            name: {
              th: 'สำราญ',
              en: 'Samran',
            },
            coordinates: {
              lat: '16.954',
              lng: '103.522',
            },
            zipcode: null,
          },
          461502: {
            name: {
              th: 'สำราญใต้',
              en: 'Samran Tai',
            },
            coordinates: {
              lat: '16.814',
              lng: '103.480',
            },
            zipcode: null,
          },
          461503: {
            name: {
              th: 'คำสร้างเที่ยง',
              en: 'Kham Sang Thiang',
            },
            coordinates: {
              lat: '16.814',
              lng: '103.546',
            },
            zipcode: null,
          },
          461504: {
            name: {
              th: 'หนองช้าง',
              en: 'Nong Chang',
            },
            coordinates: {
              lat: '16.913',
              lng: '103.571',
            },
            zipcode: null,
          },
        },
      },
      4616: {
        name: {
          th: 'นาคู',
          en: 'Na Khu',
        },
        tambons: {
          461601: {
            name: {
              th: 'นาคู',
              en: 'Na Khu',
            },
            coordinates: {
              lat: '16.808',
              lng: '104.070',
            },
            zipcode: null,
          },
          461602: {
            name: {
              th: 'สายนาวัง',
              en: 'Sai Na Wang',
            },
            coordinates: {
              lat: '16.761',
              lng: '104.063',
            },
            zipcode: null,
          },
          461603: {
            name: {
              th: 'โนนนาจาง',
              en: 'Non Na Chan',
            },
            coordinates: {
              lat: '16.733',
              lng: '103.989',
            },
            zipcode: null,
          },
          461604: {
            name: {
              th: 'บ่อแก้ว',
              en: 'Bo Kaeo',
            },
            coordinates: {
              lat: '16.791',
              lng: '103.994',
            },
            zipcode: null,
          },
          461605: {
            name: {
              th: 'ภูแล่นช้าง',
              en: 'Phu Laen Chang',
            },
            coordinates: {
              lat: '16.688',
              lng: '103.970',
            },
            zipcode: null,
          },
        },
      },
      4617: {
        name: {
          th: 'ดอนจาน',
          en: 'Don Chan',
        },
        tambons: {
          461701: {
            name: {
              th: 'ดอนจาน',
              en: 'Don Chan',
            },
            coordinates: {
              lat: '16.425',
              lng: '103.696',
            },
            zipcode: null,
          },
          461702: {
            name: {
              th: 'สะอาดไชยศรี',
              en: 'Sa-At Chai Si',
            },
            coordinates: {
              lat: '16.471',
              lng: '103.773',
            },
            zipcode: null,
          },
          461703: {
            name: {
              th: 'พยุง',
              en: 'Dong Phayung',
            },
            coordinates: {
              lat: '16.531',
              lng: '103.708',
            },
            zipcode: null,
          },
          461704: {
            name: {
              th: 'ม่วงนา',
              en: 'Muang Na',
            },
            coordinates: {
              lat: '16.475',
              lng: '103.639',
            },
            zipcode: null,
          },
          461705: {
            name: {
              th: 'นาจำปา',
              en: 'Na Champa',
            },
            coordinates: {
              lat: '16.480',
              lng: '103.696',
            },
            zipcode: null,
          },
        },
      },
      4618: {
        name: {
          th: 'ฆ้องชัย',
          en: 'Khong Chai',
        },
        tambons: {
          461801: {
            name: {
              th: 'ฆ้องชัยพัฒนา',
              en: 'Khong Chai Phatthana',
            },
            coordinates: {
              lat: '16.257',
              lng: '103.463',
            },
            zipcode: null,
          },
          461802: {
            name: {
              th: 'เหล่ากลาง',
              en: 'Lao Klang',
            },
            coordinates: {
              lat: '16.245',
              lng: '103.515',
            },
            zipcode: 46130,
          },
          461803: {
            name: {
              th: 'โนนศิลา',
              en: 'Khok Sa-At',
            },
            coordinates: {
              lat: '16.285',
              lng: '103.435',
            },
            zipcode: null,
          },
          461804: {
            name: {
              th: 'ลำชี',
              en: 'Non Sila Loeng',
            },
            coordinates: {
              lat: '16.321',
              lng: '103.493',
            },
            zipcode: 46130,
          },
          461805: {
            name: {
              th: 'โคกสะอาด',
              en: 'Lam Chi',
            },
            coordinates: {
              lat: '16.215',
              lng: '103.468',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  47: {
    name: {
      th: 'สกลนคร',
      en: 'Sakon Nakhon',
    },
    amphoes: {
      4701: {
        name: {
          th: 'เมืองสกลนคร',
          en: 'Mueang Sakon Nakhon',
        },
        tambons: {
          470101: {
            name: {
              th: 'ธาตุเชิงชุม',
              en: 'That Choengchum',
            },
            coordinates: {
              lat: '17.163',
              lng: '104.162',
            },
            zipcode: 47000,
          },
          470102: {
            name: {
              th: 'ขมิ้น',
              en: 'Khamin',
            },
            coordinates: {
              lat: '17.260',
              lng: '104.014',
            },
            zipcode: 47220,
          },
          470103: {
            name: {
              th: 'งิ้วด่อน',
              en: 'Ngio Don',
            },
            coordinates: {
              lat: '17.114',
              lng: '104.165',
            },
            zipcode: 47000,
          },
          470104: {
            name: {
              th: 'โนนหอม',
              en: 'Non Hom',
            },
            coordinates: {
              lat: '17.053',
              lng: '104.196',
            },
            zipcode: 47000,
          },
          470106: {
            name: {
              th: 'เชียงเครือ',
              en: 'Chiang Khruea',
            },
            coordinates: {
              lat: '17.279',
              lng: '104.123',
            },
            zipcode: 47000,
          },
          470107: {
            name: {
              th: 'ท่าแร่',
              en: 'Tha Rae',
            },
            coordinates: {
              lat: '17.269',
              lng: '104.207',
            },
            zipcode: 47230,
          },
          470109: {
            name: {
              th: 'ม่วงลาย',
              en: 'Muang Lai',
            },
            coordinates: {
              lat: '17.125',
              lng: '104.260',
            },
            zipcode: 47000,
          },
          470111: {
            name: {
              th: 'ดงชน',
              en: 'Dong Chon',
            },
            coordinates: {
              lat: '17.096',
              lng: '104.240',
            },
            zipcode: 47000,
          },
          470112: {
            name: {
              th: 'ห้วยยาง',
              en: 'Huai Yang',
            },
            coordinates: {
              lat: '17.095',
              lng: '104.033',
            },
            zipcode: 47000,
          },
          470113: {
            name: {
              th: 'พังขว้าง',
              en: 'Phang Khwang',
            },
            coordinates: {
              lat: '17.177',
              lng: '104.017',
            },
            zipcode: 47000,
          },
          470115: {
            name: {
              th: 'ดงมะไฟ',
              en: 'Dong Mafai',
            },
            coordinates: {
              lat: '17.059',
              lng: '104.105',
            },
            zipcode: 47000,
          },
          470116: {
            name: {
              th: 'ธาตุนาเวง',
              en: 'That Naweng',
            },
            coordinates: {
              lat: '17.147',
              lng: '104.095',
            },
            zipcode: 47000,
          },
          470117: {
            name: {
              th: 'เหล่าปอแดง',
              en: 'Lao Po Daeng',
            },
            coordinates: {
              lat: '17.137',
              lng: '104.212',
            },
            zipcode: 47000,
          },
          470118: {
            name: {
              th: 'หนองลาด',
              en: 'Nong Lat',
            },
            coordinates: {
              lat: '17.337',
              lng: '104.072',
            },
            zipcode: 47150,
          },
          470120: {
            name: {
              th: 'ฮางโฮง',
              en: 'Hang Hong',
            },
            coordinates: {
              lat: '17.220',
              lng: '104.122',
            },
            zipcode: 47000,
          },
          470121: {
            name: {
              th: 'โคกก่อง',
              en: 'Khok Kong',
            },
            coordinates: {
              lat: '17.133',
              lng: '104.294',
            },
            zipcode: 47000,
          },
        },
      },
      4702: {
        name: {
          th: 'กุสุมาลย์',
          en: 'Kusuman',
        },
        tambons: {
          470201: {
            name: {
              th: 'กุสุมาลย์',
              en: 'Kusuman',
            },
            coordinates: {
              lat: '17.302',
              lng: '104.386',
            },
            zipcode: 47210,
          },
          470202: {
            name: {
              th: 'นาโพธิ์',
              en: 'Na Pho',
            },
            coordinates: {
              lat: '17.352',
              lng: '104.287',
            },
            zipcode: 47210,
          },
          470203: {
            name: {
              th: 'นาเพียง',
              en: 'Na Phiang',
            },
            coordinates: {
              lat: '17.367',
              lng: '104.216',
            },
            zipcode: 47230,
          },
          470204: {
            name: {
              th: 'โพธิไพศาล',
              en: 'Pho Phisan',
            },
            coordinates: {
              lat: '17.379',
              lng: '104.378',
            },
            zipcode: null,
          },
          470205: {
            name: {
              th: 'อุ่มจาน',
              en: 'Um Chan',
            },
            coordinates: {
              lat: '17.350',
              lng: '104.137',
            },
            zipcode: 47230,
          },
        },
      },
      4703: {
        name: {
          th: 'กุดบาก',
          en: 'Kut Bak',
        },
        tambons: {
          470301: {
            name: {
              th: 'กุดบาก',
              en: 'Kut Bak',
            },
            coordinates: {
              lat: '17.051',
              lng: '103.762',
            },
            zipcode: 47180,
          },
          470303: {
            name: {
              th: 'นาม่อง',
              en: 'Na Mong',
            },
            coordinates: {
              lat: '17.093',
              lng: '103.906',
            },
            zipcode: 47180,
          },
          470305: {
            name: {
              th: 'กุดไห',
              en: 'Kut Hai',
            },
            coordinates: {
              lat: '17.100',
              lng: '103.696',
            },
            zipcode: 47180,
          },
        },
      },
      4704: {
        name: {
          th: 'พรรณานิคม',
          en: 'Phanna Nikhom',
        },
        tambons: {
          470401: {
            name: {
              th: 'พรรณา',
              en: 'Phanna',
            },
            coordinates: {
              lat: '17.356',
              lng: '103.856',
            },
            zipcode: 47130,
          },
          470402: {
            name: {
              th: 'วังยาง',
              en: 'Wang Yang',
            },
            coordinates: {
              lat: '17.341',
              lng: '103.911',
            },
            zipcode: 47130,
          },
          470403: {
            name: {
              th: 'พอกน้อย',
              en: 'Phok Noi',
            },
            coordinates: {
              lat: '17.315',
              lng: '103.952',
            },
            zipcode: 47220,
          },
          470404: {
            name: {
              th: 'นาหัวบ่อ',
              en: 'Na Hua Bo',
            },
            coordinates: {
              lat: '17.226',
              lng: '103.917',
            },
            zipcode: 47220,
          },
          470405: {
            name: {
              th: 'ไร่',
              en: 'Rai',
            },
            coordinates: {
              lat: '17.274',
              lng: '103.846',
            },
            zipcode: 47130,
          },
          470406: {
            name: {
              th: 'ช้างมิ่ง',
              en: 'Chang Ming',
            },
            coordinates: {
              lat: '17.342',
              lng: '103.796',
            },
            zipcode: 47130,
          },
          470407: {
            name: {
              th: 'นาใน',
              en: 'Na Nai',
            },
            coordinates: {
              lat: '17.207',
              lng: '103.821',
            },
            zipcode: 47130,
          },
          470408: {
            name: {
              th: 'สว่าง',
              en: 'Sawang',
            },
            coordinates: {
              lat: '17.367',
              lng: '103.981',
            },
            zipcode: 47130,
          },
          470409: {
            name: {
              th: 'บะฮี',
              en: 'Bahi',
            },
            coordinates: {
              lat: '17.426',
              lng: '103.901',
            },
            zipcode: 47130,
          },
          470410: {
            name: {
              th: 'เชิงชุม',
              en: 'Choeng Chum',
            },
            coordinates: {
              lat: '17.435',
              lng: '104.007',
            },
            zipcode: 47130,
          },
        },
      },
      4705: {
        name: {
          th: 'พังโคน',
          en: 'Phanh khon',
        },
        tambons: {
          470501: {
            name: {
              th: 'พังโคน',
              en: 'Phang Khon',
            },
            coordinates: {
              lat: '17.390',
              lng: '103.704',
            },
            zipcode: 47160,
          },
          470502: {
            name: {
              th: 'ม่วงไข่',
              en: 'Muang Khai',
            },
            coordinates: {
              lat: '17.405',
              lng: '103.665',
            },
            zipcode: 47160,
          },
          470503: {
            name: {
              th: 'แร่',
              en: 'Rae',
            },
            coordinates: {
              lat: '17.297',
              lng: '103.767',
            },
            zipcode: 47160,
          },
          470504: {
            name: {
              th: 'ไฮหย่อง',
              en: 'Hai Yong',
            },
            coordinates: {
              lat: '17.406',
              lng: '103.765',
            },
            zipcode: 47160,
          },
          470505: {
            name: {
              th: 'ต้นผึ้ง',
              en: 'Ton Phueng',
            },
            coordinates: {
              lat: '17.432',
              lng: '103.827',
            },
            zipcode: 47160,
          },
        },
      },
      4706: {
        name: {
          th: 'วาริชภูมิ',
          en: 'Waritchaphum',
        },
        tambons: {
          470601: {
            name: {
              th: 'วาริชภูมิ',
              en: 'Waritchaphum',
            },
            coordinates: {
              lat: '17.232',
              lng: '103.642',
            },
            zipcode: 47150,
          },
          470602: {
            name: {
              th: 'ปลาโหล',
              en: 'Pla Lo',
            },
            coordinates: {
              lat: '17.327',
              lng: '103.671',
            },
            zipcode: 47150,
          },
          470603: {
            name: {
              th: 'หนองลาด',
              en: 'Nong Lat',
            },
            coordinates: {
              lat: '17.319',
              lng: '103.543',
            },
            zipcode: null,
          },
          470604: {
            name: {
              th: 'คำบ่อ',
              en: 'Kham Bo',
            },
            coordinates: {
              lat: '17.225',
              lng: '103.551',
            },
            zipcode: 47150,
          },
          470605: {
            name: {
              th: 'ค้อเขียว',
              en: 'Kho Khiao',
            },
            coordinates: {
              lat: '17.276',
              lng: '103.527',
            },
            zipcode: 47150,
          },
        },
      },
      4707: {
        name: {
          th: 'นิคมน้ำอูน',
          en: 'Nikhom Nam un',
        },
        tambons: {
          470701: {
            name: {
              th: 'นิคมน้ำอูน',
              en: 'Nikhom Nam Un',
            },
            coordinates: {
              lat: '17.142',
              lng: '103.645',
            },
            zipcode: 47270,
          },
          470702: {
            name: {
              th: 'หนองปลิง',
              en: 'Nong Pling',
            },
            coordinates: {
              lat: '17.180',
              lng: '103.749',
            },
            zipcode: 47270,
          },
          470703: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '17.148',
              lng: '103.827',
            },
            zipcode: 47270,
          },
          470704: {
            name: {
              th: 'สุวรรณคาม',
              en: 'Suwanakham',
            },
            coordinates: {
              lat: '17.155',
              lng: '103.712',
            },
            zipcode: 47270,
          },
        },
      },
      4708: {
        name: {
          th: 'วานรนิวาส',
          en: 'Wanon Niwat',
        },
        tambons: {
          470801: {
            name: {
              th: 'วานรนิวาส',
              en: 'Wanon Niwat',
            },
            coordinates: {
              lat: '17.645',
              lng: '103.752',
            },
            zipcode: 47120,
          },
          470802: {
            name: {
              th: 'เดื่อศรีคันไชย',
              en: 'Duea Si Khan Chai',
            },
            coordinates: {
              lat: '17.473',
              lng: '103.730',
            },
            zipcode: 47120,
          },
          470803: {
            name: {
              th: 'ขัวก่าย',
              en: 'Khua Kai',
            },
            coordinates: {
              lat: '17.579',
              lng: '103.814',
            },
            zipcode: 47120,
          },
          470804: {
            name: {
              th: 'หนองสนม',
              en: 'Nong Sanom',
            },
            coordinates: {
              lat: '17.526',
              lng: '103.860',
            },
            zipcode: 47120,
          },
          470805: {
            name: {
              th: 'คูสะคาม',
              en: 'Khu Sakham',
            },
            coordinates: {
              lat: '17.727',
              lng: '103.610',
            },
            zipcode: 47120,
          },
          470806: {
            name: {
              th: 'ธาตุ',
              en: 'That',
            },
            coordinates: {
              lat: '17.641',
              lng: '103.651',
            },
            zipcode: 47120,
          },
          470807: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '17.767',
              lng: '103.726',
            },
            zipcode: 47120,
          },
          470808: {
            name: {
              th: 'ศรีวิชัย',
              en: 'Si Wichai',
            },
            coordinates: {
              lat: '17.536',
              lng: '103.680',
            },
            zipcode: 47120,
          },
          470809: {
            name: {
              th: 'นาซอ',
              en: 'Na So',
            },
            coordinates: {
              lat: '17.674',
              lng: '103.848',
            },
            zipcode: 47120,
          },
          470810: {
            name: {
              th: 'อินทร์แปลง',
              en: 'In Plaeng',
            },
            coordinates: {
              lat: '17.787',
              lng: '103.593',
            },
            zipcode: 47120,
          },
          470811: {
            name: {
              th: 'นาคำ',
              en: 'Na Kham',
            },
            coordinates: {
              lat: '17.692',
              lng: '103.792',
            },
            zipcode: 47120,
          },
          470812: {
            name: {
              th: 'คอนสวรรค์',
              en: 'Khon Sawan',
            },
            coordinates: {
              lat: '17.593',
              lng: '103.748',
            },
            zipcode: 47120,
          },
          470813: {
            name: {
              th: 'กุดเรือคำ',
              en: 'Kut Ruea Kham',
            },
            coordinates: {
              lat: '17.760',
              lng: '103.646',
            },
            zipcode: 47120,
          },
          470814: {
            name: {
              th: 'หนองแวงใต้',
              en: 'Nong Waeng Tai',
            },
            coordinates: {
              lat: '17.724',
              lng: '103.760',
            },
            zipcode: 47120,
          },
        },
      },
      4709: {
        name: {
          th: 'คำตากล้า',
          en: 'Kham Ta Kla',
        },
        tambons: {
          470901: {
            name: {
              th: 'คำตากล้า',
              en: 'Kham Ta Kla',
            },
            coordinates: {
              lat: '17.834',
              lng: '103.754',
            },
            zipcode: 47250,
          },
          470902: {
            name: {
              th: 'หนองบัวสิม',
              en: 'Nong Bua Sim',
            },
            coordinates: {
              lat: '17.841',
              lng: '103.859',
            },
            zipcode: 47250,
          },
          470903: {
            name: {
              th: 'นาแต้',
              en: 'Na Tae',
            },
            coordinates: {
              lat: '17.866',
              lng: '103.699',
            },
            zipcode: 47250,
          },
          470904: {
            name: {
              th: 'แพด',
              en: 'Phaet',
            },
            coordinates: {
              lat: '17.775',
              lng: '103.842',
            },
            zipcode: 47250,
          },
        },
      },
      4710: {
        name: {
          th: 'บ้านม่วง',
          en: 'Ban Muang',
        },
        tambons: {
          471001: {
            name: {
              th: 'ม่วง',
              en: 'Muang',
            },
            coordinates: {
              lat: '17.858',
              lng: '103.561',
            },
            zipcode: 47140,
          },
          471002: {
            name: {
              th: 'มาย',
              en: 'Mai',
            },
            coordinates: {
              lat: '17.855',
              lng: '103.480',
            },
            zipcode: 47140,
          },
          471003: {
            name: {
              th: 'ดงหม้อทอง',
              en: 'Dong Mo Thong',
            },
            coordinates: {
              lat: '17.995',
              lng: '103.528',
            },
            zipcode: 47140,
          },
          471004: {
            name: {
              th: 'ดงเหนือ',
              en: 'Dong Nuea',
            },
            coordinates: {
              lat: '17.985',
              lng: '103.584',
            },
            zipcode: 47140,
          },
          471005: {
            name: {
              th: 'ดงหม้อทองใต้',
              en: 'Dong Mo Thong Tai',
            },
            coordinates: {
              lat: '17.932',
              lng: '103.441',
            },
            zipcode: 47140,
          },
          471006: {
            name: {
              th: 'ห้วยหลัว',
              en: 'Huai Lua',
            },
            coordinates: {
              lat: '17.795',
              lng: '103.416',
            },
            zipcode: 47140,
          },
          471007: {
            name: {
              th: 'โนนสะอาด',
              en: 'Non Sa-At',
            },
            coordinates: {
              lat: '17.929',
              lng: '103.653',
            },
            zipcode: 47140,
          },
          471008: {
            name: {
              th: 'หนองกวั่ง',
              en: 'Nong Kwang',
            },
            coordinates: {
              lat: '17.796',
              lng: '103.487',
            },
            zipcode: 47140,
          },
          471009: {
            name: {
              th: 'บ่อแก้ว',
              en: 'Bo Kaeo',
            },
            coordinates: {
              lat: '17.863',
              lng: '103.626',
            },
            zipcode: 47140,
          },
        },
      },
      4711: {
        name: {
          th: 'อากาศอำนวย',
          en: 'Akat Amnuai',
        },
        tambons: {
          471101: {
            name: {
              th: 'อากาศ',
              en: 'Akat',
            },
            coordinates: {
              lat: '17.572',
              lng: '104.013',
            },
            zipcode: 47170,
          },
          471102: {
            name: {
              th: 'โพนแพง',
              en: 'Phon Phaeng',
            },
            coordinates: {
              lat: '17.538',
              lng: '103.956',
            },
            zipcode: 47170,
          },
          471103: {
            name: {
              th: 'วาใหญ่',
              en: 'Wa Yai',
            },
            coordinates: {
              lat: '17.630',
              lng: '103.926',
            },
            zipcode: 47170,
          },
          471104: {
            name: {
              th: 'โพนงาม',
              en: 'Phon Ngam',
            },
            coordinates: {
              lat: '17.757',
              lng: '104.006',
            },
            zipcode: 47170,
          },
          471105: {
            name: {
              th: 'ท่าก้อน',
              en: 'Tha Kon',
            },
            coordinates: {
              lat: '17.759',
              lng: '103.961',
            },
            zipcode: 47170,
          },
          471106: {
            name: {
              th: 'นาฮี',
              en: 'Na Hi',
            },
            coordinates: {
              lat: '17.718',
              lng: '103.920',
            },
            zipcode: 47170,
          },
          471107: {
            name: {
              th: 'บะหว้า',
              en: 'Bawa',
            },
            coordinates: {
              lat: '17.495',
              lng: '103.987',
            },
            zipcode: 47170,
          },
          471108: {
            name: {
              th: 'สามัคคีพัฒนา',
              en: 'Samakkhi Phatthana',
            },
            coordinates: {
              lat: '17.669',
              lng: '104.019',
            },
            zipcode: 47170,
          },
        },
      },
      4712: {
        name: {
          th: 'สว่างแดนดิน',
          en: 'Sawang Daen Din',
        },
        tambons: {
          471201: {
            name: {
              th: 'สว่างแดนดิน',
              en: 'Sawang Daen Din',
            },
            coordinates: {
              lat: '17.448',
              lng: '103.462',
            },
            zipcode: 47110,
          },
          471203: {
            name: {
              th: 'คำสะอาด',
              en: 'Kham Sa-At',
            },
            coordinates: {
              lat: '17.511',
              lng: '103.351',
            },
            zipcode: 47110,
          },
          471204: {
            name: {
              th: 'บ้านต้าย',
              en: 'Ban Tai',
            },
            coordinates: {
              lat: '17.441',
              lng: '103.385',
            },
            zipcode: 47110,
          },
          471206: {
            name: {
              th: 'บงเหนือ',
              en: 'Bong Nuea',
            },
            coordinates: {
              lat: '17.319',
              lng: '103.301',
            },
            zipcode: 47110,
          },
          471207: {
            name: {
              th: 'โพนสูง',
              en: 'Phon Sung',
            },
            coordinates: {
              lat: '17.538',
              lng: '103.400',
            },
            zipcode: 47110,
          },
          471208: {
            name: {
              th: 'โคกสี',
              en: 'Khok Si',
            },
            coordinates: {
              lat: '17.605',
              lng: '103.446',
            },
            zipcode: 47110,
          },
          471210: {
            name: {
              th: 'หนองหลวง',
              en: 'Nong Luang',
            },
            coordinates: {
              lat: '17.377',
              lng: '103.383',
            },
            zipcode: 47110,
          },
          471211: {
            name: {
              th: 'บงใต้',
              en: 'Bong Tai',
            },
            coordinates: {
              lat: '17.422',
              lng: '103.327',
            },
            zipcode: 47110,
          },
          471212: {
            name: {
              th: 'ค้อใต้',
              en: 'Kho Tai',
            },
            coordinates: {
              lat: '17.368',
              lng: '103.330',
            },
            zipcode: 47110,
          },
          471213: {
            name: {
              th: 'พันนา',
              en: 'Phan Na',
            },
            coordinates: {
              lat: '17.416',
              lng: '103.569',
            },
            zipcode: 47240,
          },
          471214: {
            name: {
              th: 'แวง',
              en: 'Waeng',
            },
            coordinates: {
              lat: '17.456',
              lng: '103.630',
            },
            zipcode: 47240,
          },
          471215: {
            name: {
              th: 'ทรายมูล',
              en: 'Sai Mun',
            },
            coordinates: {
              lat: '17.470',
              lng: '103.526',
            },
            zipcode: 47110,
          },
          471216: {
            name: {
              th: 'ตาลโกน',
              en: 'Tan Kon',
            },
            coordinates: {
              lat: '17.345',
              lng: '103.564',
            },
            zipcode: 47240,
          },
          471217: {
            name: {
              th: 'ตาลเนิ้ง',
              en: 'Tan Noeng',
            },
            coordinates: {
              lat: '17.346',
              lng: '103.611',
            },
            zipcode: 47240,
          },
          471220: {
            name: {
              th: 'ธาตุทอง',
              en: 'That Thong',
            },
            coordinates: {
              lat: '17.494',
              lng: '103.579',
            },
            zipcode: 47240,
          },
          471221: {
            name: {
              th: 'บ้านถ่อน',
              en: 'Ban Thon',
            },
            coordinates: {
              lat: '17.532',
              lng: '103.452',
            },
            zipcode: 47110,
          },
        },
      },
      4713: {
        name: {
          th: 'ส่องดาว',
          en: 'Song Dao',
        },
        tambons: {
          471301: {
            name: {
              th: 'ส่องดาว',
              en: 'Song Dao',
            },
            coordinates: {
              lat: '17.350',
              lng: '103.449',
            },
            zipcode: 47190,
          },
          471302: {
            name: {
              th: 'ท่าศิลา',
              en: 'Tha Sila',
            },
            coordinates: {
              lat: '17.284',
              lng: '103.387',
            },
            zipcode: 47190,
          },
          471303: {
            name: {
              th: 'วัฒนา',
              en: 'Watthana',
            },
            coordinates: {
              lat: '17.362',
              lng: '103.540',
            },
            zipcode: 47190,
          },
          471304: {
            name: {
              th: 'ปทุมวาปี',
              en: 'Pathum Wapi',
            },
            coordinates: {
              lat: '17.298',
              lng: '103.482',
            },
            zipcode: 47190,
          },
        },
      },
      4714: {
        name: {
          th: 'เต่างอย',
          en: 'Tao Ngoi',
        },
        tambons: {
          471401: {
            name: {
              th: 'เต่างอย',
              en: 'Tao Ngoi',
            },
            coordinates: {
              lat: '16.944',
              lng: '104.242',
            },
            zipcode: null,
          },
          471402: {
            name: {
              th: 'บึงทวาย',
              en: 'Bueng Thawai',
            },
            coordinates: {
              lat: '17.034',
              lng: '104.157',
            },
            zipcode: 47260,
          },
          471403: {
            name: {
              th: 'นาตาล',
              en: 'Na Tan',
            },
            coordinates: {
              lat: '16.973',
              lng: '104.116',
            },
            zipcode: 47260,
          },
          471404: {
            name: {
              th: 'จันทร์เพ็ญ',
              en: 'Chan Phen',
            },
            coordinates: {
              lat: '16.925',
              lng: '104.155',
            },
            zipcode: 47260,
          },
        },
      },
      4715: {
        name: {
          th: 'โคกศรีสุพรรณ',
          en: 'Khok Si Suphan',
        },
        tambons: {
          471501: {
            name: {
              th: 'ตองโขบ',
              en: 'Tong Khop',
            },
            coordinates: {
              lat: '17.010',
              lng: '104.257',
            },
            zipcode: null,
          },
          471502: {
            name: {
              th: 'เหล่าโพนค้อ',
              en: 'Lao Phon Kho',
            },
            coordinates: {
              lat: '17.000',
              lng: '104.331',
            },
            zipcode: null,
          },
          471503: {
            name: {
              th: 'ด่านม่วงคำ',
              en: 'Dan Muang Kham',
            },
            coordinates: {
              lat: '17.096',
              lng: '104.320',
            },
            zipcode: 47280,
          },
          471504: {
            name: {
              th: 'แมดนาท่ม',
              en: 'Maet Na Thom',
            },
            coordinates: {
              lat: '17.045',
              lng: '104.347',
            },
            zipcode: 47280,
          },
        },
      },
      4716: {
        name: {
          th: 'เจริญศิลป์',
          en: 'Charoen Sin',
        },
        tambons: {
          471601: {
            name: {
              th: 'บ้านเหล่า',
              en: 'Ban Lao',
            },
            coordinates: {
              lat: '17.699',
              lng: '103.524',
            },
            zipcode: null,
          },
          471602: {
            name: {
              th: 'เจริญศิลป์',
              en: 'Charoen Sin',
            },
            coordinates: {
              lat: '17.568',
              lng: '103.561',
            },
            zipcode: null,
          },
          471603: {
            name: {
              th: 'ทุ่งแก',
              en: 'Thung Kae',
            },
            coordinates: {
              lat: '17.602',
              lng: '103.528',
            },
            zipcode: null,
          },
          471604: {
            name: {
              th: 'โคกศิลา',
              en: 'Khok Sila',
            },
            coordinates: {
              lat: '17.536',
              lng: '103.605',
            },
            zipcode: null,
          },
          471605: {
            name: {
              th: 'หนองแปน',
              en: 'Nong Paen',
            },
            coordinates: {
              lat: '17.706',
              lng: '103.443',
            },
            zipcode: null,
          },
        },
      },
      4717: {
        name: {
          th: 'โพนนาแก้ว',
          en: 'Phon Na Kaeo',
        },
        tambons: {
          471701: {
            name: {
              th: 'บ้านโพน',
              en: 'Ban Phon',
            },
            coordinates: {
              lat: '17.219',
              lng: '104.375',
            },
            zipcode: null,
          },
          471702: {
            name: {
              th: 'นาแก้ว',
              en: 'Na Kaeo',
            },
            coordinates: {
              lat: '17.268',
              lng: '104.239',
            },
            zipcode: null,
          },
          471703: {
            name: {
              th: 'นาตงวัฒนา',
              en: 'Na Tong Watthana',
            },
            coordinates: {
              lat: '17.242',
              lng: '104.341',
            },
            zipcode: null,
          },
          471704: {
            name: {
              th: 'บ้านแป้น',
              en: 'Ban Paen',
            },
            coordinates: {
              lat: '17.196',
              lng: '104.234',
            },
            zipcode: null,
          },
          471705: {
            name: {
              th: 'เชียงสือ',
              en: 'Chiang Sue',
            },
            coordinates: {
              lat: '17.137',
              lng: '104.361',
            },
            zipcode: 47230,
          },
        },
      },
      4718: {
        name: {
          th: 'ภูพาน',
          en: 'Phu Phan',
        },
        tambons: {
          471801: {
            name: {
              th: 'สร้างค้อ',
              en: 'Sang Kho',
            },
            coordinates: {
              lat: '16.883',
              lng: '103.918',
            },
            zipcode: null,
          },
          471802: {
            name: {
              th: 'หลุบเลา',
              en: 'Luplao',
            },
            coordinates: {
              lat: '16.881',
              lng: '104.022',
            },
            zipcode: null,
          },
          471803: {
            name: {
              th: 'โคกภู',
              en: 'Khok Phu',
            },
            coordinates: {
              lat: '16.972',
              lng: '103.859',
            },
            zipcode: null,
          },
          471804: {
            name: {
              th: 'กกปลาซิว',
              en: 'Kok Pla Sio',
            },
            coordinates: {
              lat: '16.969',
              lng: '104.042',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  48: {
    name: {
      th: 'นครพนม',
      en: 'Nakhon Phanom',
    },
    amphoes: {
      4801: {
        name: {
          th: 'เมืองนครพนม',
          en: 'Mueang Nakhon Phanom',
        },
        tambons: {
          480101: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '17.409',
              lng: '104.781',
            },
            zipcode: 48000,
          },
          480103: {
            name: {
              th: 'นาทราย',
              en: 'Na Sai',
            },
            coordinates: {
              lat: '17.401',
              lng: '104.650',
            },
            zipcode: 48000,
          },
          480104: {
            name: {
              th: 'นาราชควาย',
              en: 'Na Ratchakhwai',
            },
            coordinates: {
              lat: '17.416',
              lng: '104.707',
            },
            zipcode: 48000,
          },
          480105: {
            name: {
              th: 'กุรุคุ',
              en: 'Kurukhu',
            },
            coordinates: {
              lat: '17.338',
              lng: '104.545',
            },
            zipcode: 48000,
          },
          480106: {
            name: {
              th: 'บ้านผึ้ง',
              en: 'Ban Phueng',
            },
            coordinates: {
              lat: '17.336',
              lng: '104.631',
            },
            zipcode: 48000,
          },
          480107: {
            name: {
              th: 'อาจสามารถ',
              en: 'At Samat',
            },
            coordinates: {
              lat: '17.461',
              lng: '104.725',
            },
            zipcode: 48000,
          },
          480108: {
            name: {
              th: 'ขามเฒ่า',
              en: 'Kham Thao',
            },
            coordinates: {
              lat: '17.263',
              lng: '104.764',
            },
            zipcode: 48000,
          },
          480109: {
            name: {
              th: 'บ้านกลาง',
              en: 'Ban Klang',
            },
            coordinates: {
              lat: '17.157',
              lng: '104.784',
            },
            zipcode: 48000,
          },
          480110: {
            name: {
              th: 'ท่าค้อ',
              en: 'Tha Kho',
            },
            coordinates: {
              lat: '17.316',
              lng: '104.746',
            },
            zipcode: 48000,
          },
          480111: {
            name: {
              th: 'คำเตย',
              en: 'Kham Toei',
            },
            coordinates: {
              lat: '17.210',
              lng: '104.709',
            },
            zipcode: 48000,
          },
          480112: {
            name: {
              th: 'หนองญาติ',
              en: 'Nong Yat',
            },
            coordinates: {
              lat: '17.342',
              lng: '104.732',
            },
            zipcode: 48000,
          },
          480113: {
            name: {
              th: 'ดงขวาง',
              en: 'Dong Khwang',
            },
            coordinates: {
              lat: '17.207',
              lng: '104.782',
            },
            zipcode: 48000,
          },
          480114: {
            name: {
              th: 'วังตามัว',
              en: 'Wang Ta Mua',
            },
            coordinates: {
              lat: '17.312',
              lng: '104.492',
            },
            zipcode: 48000,
          },
          480115: {
            name: {
              th: 'โพธิ์ตาก',
              en: 'Pho Tak',
            },
            coordinates: {
              lat: '17.344',
              lng: '104.690',
            },
            zipcode: 48000,
          },
        },
      },
      4802: {
        name: {
          th: 'ปลาปาก',
          en: 'Pla Pak',
        },
        tambons: {
          480201: {
            name: {
              th: 'ปลาปาก',
              en: 'Pla Pak',
            },
            coordinates: {
              lat: '17.197',
              lng: '104.515',
            },
            zipcode: 48160,
          },
          480202: {
            name: {
              th: 'หนองฮี',
              en: 'Nong Hi',
            },
            coordinates: {
              lat: '17.128',
              lng: '104.587',
            },
            zipcode: 48160,
          },
          480203: {
            name: {
              th: 'กุตาไก้',
              en: 'Kut Takai',
            },
            coordinates: {
              lat: '17.217',
              lng: '104.608',
            },
            zipcode: 48160,
          },
          480204: {
            name: {
              th: 'โคกสว่าง',
              en: 'Khok Sawang',
            },
            coordinates: {
              lat: '17.145',
              lng: '104.465',
            },
            zipcode: 48160,
          },
          480205: {
            name: {
              th: 'โคกสูง',
              en: 'Khok Sung',
            },
            coordinates: {
              lat: '17.178',
              lng: '104.413',
            },
            zipcode: 48160,
          },
          480206: {
            name: {
              th: 'มหาชัย',
              en: 'Mahachai',
            },
            coordinates: {
              lat: '17.247',
              lng: '104.492',
            },
            zipcode: 48160,
          },
          480207: {
            name: {
              th: 'นามะเขือ',
              en: 'Na Makhuea',
            },
            coordinates: {
              lat: '17.273',
              lng: '104.654',
            },
            zipcode: 48160,
          },
          480208: {
            name: {
              th: 'หนองเทาใหญ่',
              en: 'Nong Thao Yai',
            },
            coordinates: {
              lat: '17.121',
              lng: '104.523',
            },
            zipcode: 48160,
          },
        },
      },
      4803: {
        name: {
          th: 'ท่าอุเทน',
          en: 'Tha Uthen',
        },
        tambons: {
          480301: {
            name: {
              th: 'ท่าอุเทน',
              en: 'Tha Uthen',
            },
            coordinates: {
              lat: '17.576',
              lng: '104.591',
            },
            zipcode: 48120,
          },
          480302: {
            name: {
              th: 'โนนตาล',
              en: 'Non Tan',
            },
            coordinates: {
              lat: '17.534',
              lng: '104.598',
            },
            zipcode: 48120,
          },
          480303: {
            name: {
              th: 'ท่าจำปา',
              en: 'Tha Champa',
            },
            coordinates: {
              lat: '17.592',
              lng: '104.494',
            },
            zipcode: 48120,
          },
          480304: {
            name: {
              th: 'ไชยบุรี',
              en: 'Chai Buri',
            },
            coordinates: {
              lat: '17.636',
              lng: '104.433',
            },
            zipcode: 48120,
          },
          480305: {
            name: {
              th: 'พนอม',
              en: 'Phanom',
            },
            coordinates: {
              lat: '17.697',
              lng: '104.393',
            },
            zipcode: 48120,
          },
          480306: {
            name: {
              th: 'พะทาย',
              en: 'Phathai',
            },
            coordinates: {
              lat: '17.756',
              lng: '104.316',
            },
            zipcode: 48120,
          },
          480311: {
            name: {
              th: 'เวินพระบาท',
              en: 'Woen Phrabat',
            },
            coordinates: {
              lat: '17.509',
              lng: '104.676',
            },
            zipcode: 48120,
          },
          480312: {
            name: {
              th: 'รามราช',
              en: 'Rammarat',
            },
            coordinates: {
              lat: '17.468',
              lng: '104.635',
            },
            zipcode: 48120,
          },
          480314: {
            name: {
              th: 'หนองเทา',
              en: 'Nong Thao',
            },
            coordinates: {
              lat: '17.783',
              lng: '104.352',
            },
            zipcode: 48120,
          },
        },
      },
      4804: {
        name: {
          th: 'บ้านแพง',
          en: 'Ban Phaeng',
        },
        tambons: {
          480401: {
            name: {
              th: 'บ้านแพง',
              en: 'Ban Phaeng',
            },
            coordinates: {
              lat: '17.967',
              lng: '104.215',
            },
            zipcode: 48140,
          },
          480402: {
            name: {
              th: 'ไผ่ล้อม',
              en: 'Phai Lom',
            },
            coordinates: {
              lat: '17.989',
              lng: '104.154',
            },
            zipcode: 48140,
          },
          480403: {
            name: {
              th: 'โพนทอง',
              en: 'Phon Thong',
            },
            coordinates: {
              lat: '17.876',
              lng: '104.217',
            },
            zipcode: 48170,
          },
          480404: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '17.785',
              lng: '104.233',
            },
            zipcode: 48140,
          },
          480408: {
            name: {
              th: 'นางัว',
              en: 'Na Ngua',
            },
            coordinates: {
              lat: '17.943',
              lng: '104.182',
            },
            zipcode: 48180,
          },
        },
      },
      4805: {
        name: {
          th: 'ธาตุพนม',
          en: 'That Phanom',
        },
        tambons: {
          480501: {
            name: {
              th: 'ธาตุพนม',
              en: 'That Phanom',
            },
            coordinates: {
              lat: '16.939',
              lng: '104.720',
            },
            zipcode: 48110,
          },
          480502: {
            name: {
              th: 'ฝั่งแดง',
              en: 'Fang Daeng',
            },
            coordinates: {
              lat: '16.914',
              lng: '104.675',
            },
            zipcode: 48110,
          },
          480503: {
            name: {
              th: 'โพนแพง',
              en: 'Phon Phaeng',
            },
            coordinates: {
              lat: '17.148',
              lng: '104.695',
            },
            zipcode: 48110,
          },
          480504: {
            name: {
              th: 'พระกลางทุ่ง',
              en: 'Phra Klang Thung',
            },
            coordinates: {
              lat: '16.986',
              lng: '104.699',
            },
            zipcode: 48110,
          },
          480505: {
            name: {
              th: 'นาถ่อน',
              en: 'Na Thon',
            },
            coordinates: {
              lat: '17.137',
              lng: '104.744',
            },
            zipcode: 48110,
          },
          480506: {
            name: {
              th: 'แสนพัน',
              en: 'Saen Phan',
            },
            coordinates: {
              lat: '17.066',
              lng: '104.749',
            },
            zipcode: 48110,
          },
          480507: {
            name: {
              th: 'ดอนนางหงส์',
              en: 'Don Nang Hong',
            },
            coordinates: {
              lat: '17.097',
              lng: '104.743',
            },
            zipcode: 48110,
          },
          480508: {
            name: {
              th: 'น้ำก่ำ',
              en: 'Nam Kam',
            },
            coordinates: {
              lat: '16.872',
              lng: '104.723',
            },
            zipcode: 48110,
          },
          480509: {
            name: {
              th: 'อุ่มเหม้า',
              en: 'Um Mao',
            },
            coordinates: {
              lat: '16.839',
              lng: '104.670',
            },
            zipcode: 48110,
          },
          480510: {
            name: {
              th: 'นาหนาด',
              en: 'Na Nat',
            },
            coordinates: {
              lat: '16.915',
              lng: '104.654',
            },
            zipcode: 48110,
          },
          480511: {
            name: {
              th: 'กุดฉิม',
              en: 'Kut Chim',
            },
            coordinates: {
              lat: '17.059',
              lng: '104.726',
            },
            zipcode: 48110,
          },
          480512: {
            name: {
              th: 'ธาตุพนมเหนือ',
              en: 'That Phanom Nuea',
            },
            coordinates: {
              lat: '16.975',
              lng: '104.667',
            },
            zipcode: 48110,
          },
        },
      },
      4806: {
        name: {
          th: 'เรณูนคร',
          en: 'Renu Nakhon',
        },
        tambons: {
          480601: {
            name: {
              th: 'เรณู',
              en: 'Renu',
            },
            coordinates: {
              lat: '17.081',
              lng: '104.644',
            },
            zipcode: 48170,
          },
          480602: {
            name: {
              th: 'โพนทอง',
              en: 'Phon Thong',
            },
            coordinates: {
              lat: '17.068',
              lng: '104.697',
            },
            zipcode: null,
          },
          480603: {
            name: {
              th: 'ท่าลาด',
              en: 'Tha Lat',
            },
            coordinates: {
              lat: '17.007',
              lng: '104.589',
            },
            zipcode: 48170,
          },
          480604: {
            name: {
              th: 'นางาม',
              en: 'Na Ngam',
            },
            coordinates: {
              lat: '17.106',
              lng: '104.672',
            },
            zipcode: 48170,
          },
          480605: {
            name: {
              th: 'โคกหินแฮ่',
              en: 'Khok Hin Hae',
            },
            coordinates: {
              lat: '17.046',
              lng: '104.628',
            },
            zipcode: 48170,
          },
          480607: {
            name: {
              th: 'หนองย่างชิ้น',
              en: 'Nong Yang Chin',
            },
            coordinates: {
              lat: '17.019',
              lng: '104.698',
            },
            zipcode: 48170,
          },
          480608: {
            name: {
              th: 'เรณูใต้',
              en: 'Renu Tai',
            },
            coordinates: {
              lat: '17.019',
              lng: '104.660',
            },
            zipcode: 48170,
          },
          480609: {
            name: {
              th: 'นาขาม',
              en: 'Nakham',
            },
            coordinates: {
              lat: '16.984',
              lng: '104.630',
            },
            zipcode: 48170,
          },
        },
      },
      4807: {
        name: {
          th: 'นาแก',
          en: 'Na Kae',
        },
        tambons: {
          480701: {
            name: {
              th: 'นาแก',
              en: 'Na Kae',
            },
            coordinates: {
              lat: '16.897',
              lng: '104.495',
            },
            zipcode: 48130,
          },
          480702: {
            name: {
              th: 'พระซอง',
              en: 'Phra Song',
            },
            coordinates: {
              lat: '17.021',
              lng: '104.551',
            },
            zipcode: 48130,
          },
          480703: {
            name: {
              th: 'หนองสังข์',
              en: 'Nong Sang',
            },
            coordinates: {
              lat: '16.989',
              lng: '104.468',
            },
            zipcode: 48130,
          },
          480704: {
            name: {
              th: 'นาคู่',
              en: 'Na Khu',
            },
            coordinates: {
              lat: '16.978',
              lng: '104.539',
            },
            zipcode: 48130,
          },
          480705: {
            name: {
              th: 'พิมาน',
              en: 'Phiman',
            },
            coordinates: {
              lat: '16.909',
              lng: '104.540',
            },
            zipcode: 48130,
          },
          480706: {
            name: {
              th: 'พุ่มแก',
              en: 'Phum Kae',
            },
            coordinates: {
              lat: '16.911',
              lng: '104.621',
            },
            zipcode: 48130,
          },
          480707: {
            name: {
              th: 'ก้านเหลือง',
              en: 'Kan Lueang',
            },
            coordinates: {
              lat: '16.897',
              lng: '104.383',
            },
            zipcode: 48130,
          },
          480708: {
            name: {
              th: 'หนองบ่อ',
              en: 'Nong Bo',
            },
            coordinates: {
              lat: '16.932',
              lng: '104.322',
            },
            zipcode: 48130,
          },
          480709: {
            name: {
              th: 'นาเลียง',
              en: 'Na Liang',
            },
            coordinates: {
              lat: '17.063',
              lng: '104.551',
            },
            zipcode: 48130,
          },
          480712: {
            name: {
              th: 'บ้านแก้ง',
              en: 'Ban Kaeng',
            },
            coordinates: {
              lat: '16.918',
              lng: '104.446',
            },
            zipcode: 48130,
          },
          480713: {
            name: {
              th: 'คำพี้',
              en: 'Kham Phi',
            },
            coordinates: {
              lat: '16.937',
              lng: '104.369',
            },
            zipcode: 48130,
          },
          480715: {
            name: {
              th: 'สีชมพู',
              en: 'Si Chomphu',
            },
            coordinates: {
              lat: '16.918',
              lng: '104.575',
            },
            zipcode: 48130,
          },
        },
      },
      4808: {
        name: {
          th: 'ศรีสงคราม',
          en: 'Si Songkhram',
        },
        tambons: {
          480801: {
            name: {
              th: 'ศรีสงคราม',
              en: 'Si Songkhram',
            },
            coordinates: {
              lat: '17.641',
              lng: '104.289',
            },
            zipcode: 48150,
          },
          480802: {
            name: {
              th: 'นาเดื่อ',
              en: 'Na Duea',
            },
            coordinates: {
              lat: '17.555',
              lng: '104.333',
            },
            zipcode: 48150,
          },
          480803: {
            name: {
              th: 'บ้านเอื้อง',
              en: 'Ban Ueang',
            },
            coordinates: {
              lat: '17.548',
              lng: '104.232',
            },
            zipcode: 48150,
          },
          480804: {
            name: {
              th: 'สามผง',
              en: 'Sam Phong',
            },
            coordinates: {
              lat: '17.730',
              lng: '104.164',
            },
            zipcode: 48150,
          },
          480805: {
            name: {
              th: 'ท่าบ่อสงคราม',
              en: 'Tha Bo Songkhram',
            },
            coordinates: {
              lat: '17.633',
              lng: '104.196',
            },
            zipcode: 48150,
          },
          480806: {
            name: {
              th: 'บ้านข่า',
              en: 'Ban Kha',
            },
            coordinates: {
              lat: '17.666',
              lng: '104.121',
            },
            zipcode: 48150,
          },
          480807: {
            name: {
              th: 'นาคำ',
              en: 'Na Kham',
            },
            coordinates: {
              lat: '17.619',
              lng: '104.354',
            },
            zipcode: 48150,
          },
          480808: {
            name: {
              th: 'โพนสว่าง',
              en: 'Phon Sawang',
            },
            coordinates: {
              lat: '17.542',
              lng: '104.167',
            },
            zipcode: 48150,
          },
          480809: {
            name: {
              th: 'หาดแพง',
              en: 'Hat Phaeng',
            },
            coordinates: {
              lat: '17.712',
              lng: '104.300',
            },
            zipcode: 48150,
          },
        },
      },
      4809: {
        name: {
          th: 'นาหว้า',
          en: 'Na Wa',
        },
        tambons: {
          480901: {
            name: {
              th: 'นาหว้า',
              en: 'Na Wa',
            },
            coordinates: {
              lat: '17.471',
              lng: '104.060',
            },
            zipcode: 48180,
          },
          480902: {
            name: {
              th: 'นางัว',
              en: 'Na Ngua',
            },
            coordinates: {
              lat: '17.424',
              lng: '104.071',
            },
            zipcode: null,
          },
          480903: {
            name: {
              th: 'บ้านเสียว',
              en: 'Ban Siao',
            },
            coordinates: {
              lat: '17.554',
              lng: '104.082',
            },
            zipcode: 48180,
          },
          480904: {
            name: {
              th: 'นาคูณใหญ่',
              en: 'Na Khun Yai',
            },
            coordinates: {
              lat: '17.507',
              lng: '104.145',
            },
            zipcode: 48180,
          },
          480905: {
            name: {
              th: 'เหล่าพัฒนา',
              en: 'Lao Phathana',
            },
            coordinates: {
              lat: '17.617',
              lng: '104.070',
            },
            zipcode: 48180,
          },
          480906: {
            name: {
              th: 'ท่าเรือ',
              en: 'Tha Ruea',
            },
            coordinates: {
              lat: '17.503',
              lng: '104.052',
            },
            zipcode: 48180,
          },
        },
      },
      4810: {
        name: {
          th: 'โพนสวรรค์',
          en: 'Phon Sawan',
        },
        tambons: {
          481001: {
            name: {
              th: 'โพนสวรรค์',
              en: 'Phon Sawan',
            },
            coordinates: {
              lat: '17.444',
              lng: '104.504',
            },
            zipcode: null,
          },
          481002: {
            name: {
              th: 'นาหัวบ่อ',
              en: 'Na Hua Bo',
            },
            coordinates: {
              lat: '17.482',
              lng: '104.368',
            },
            zipcode: null,
          },
          481003: {
            name: {
              th: 'นาขมิ้น',
              en: 'Na Khamin',
            },
            coordinates: {
              lat: '17.523',
              lng: '104.513',
            },
            zipcode: null,
          },
          481005: {
            name: {
              th: 'บ้านค้อ',
              en: 'Ban Kho',
            },
            coordinates: {
              lat: '17.473',
              lng: '104.264',
            },
            zipcode: null,
          },
          481006: {
            name: {
              th: 'โพนจาน',
              en: 'Phon Chan',
            },
            coordinates: {
              lat: '17.409',
              lng: '104.424',
            },
            zipcode: 48190,
          },
        },
      },
      4811: {
        name: {
          th: 'นาทม',
          en: 'Na Thom',
        },
        tambons: {
          481101: {
            name: {
              th: 'นาทม',
              en: 'Na Thom',
            },
            coordinates: {
              lat: '17.845',
              lng: '104.037',
            },
            zipcode: null,
          },
          481102: {
            name: {
              th: 'หนองซน',
              en: 'Nong Son',
            },
            coordinates: {
              lat: '17.866',
              lng: '104.147',
            },
            zipcode: null,
          },
          481103: {
            name: {
              th: 'ดอนเตย',
              en: 'Don Toei',
            },
            coordinates: {
              lat: '17.798',
              lng: '104.108',
            },
            zipcode: null,
          },
        },
      },
      4812: {
        name: {
          th: 'วังยาง',
          en: 'Wang Yang',
        },
        tambons: {
          481201: {
            name: {
              th: 'วังยาง',
              en: 'Wang Yang',
            },
            coordinates: {
              lat: '17.063',
              lng: '104.442',
            },
            zipcode: null,
          },
          481202: {
            name: {
              th: 'ยอดชาด',
              en: 'Khok Si',
            },
            coordinates: {
              lat: '17.065',
              lng: '104.482',
            },
            zipcode: null,
          },
          481203: {
            name: {
              th: 'โคกสี',
              en: 'Yot Chat',
            },
            coordinates: {
              lat: '17.081',
              lng: '104.387',
            },
            zipcode: null,
          },
          481204: {
            name: {
              th: 'หนองโพธิ์',
              en: 'Nong Pho',
            },
            coordinates: {
              lat: '17.091',
              lng: '104.491',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  49: {
    name: {
      th: 'มุกดาหาร',
      en: 'Mukdahan',
    },
    amphoes: {
      4901: {
        name: {
          th: 'เมืองมุกดาหาร',
          en: 'Mueang Mukdahan',
        },
        tambons: {
          490101: {
            name: {
              th: 'มุกดาหาร',
              en: 'Mukdahan',
            },
            coordinates: {
              lat: '16.558',
              lng: '104.646',
            },
            zipcode: 49000,
          },
          490102: {
            name: {
              th: 'ศรีบุญเรือง',
              en: 'Si Bunrueang',
            },
            coordinates: {
              lat: '16.530',
              lng: '104.724',
            },
            zipcode: 49000,
          },
          490103: {
            name: {
              th: 'บ้านโคก',
              en: 'Ban Khok',
            },
            coordinates: {
              lat: '16.640',
              lng: '104.565',
            },
            zipcode: 49000,
          },
          490104: {
            name: {
              th: 'บางทรายใหญ่',
              en: 'Bang Sai Yai',
            },
            coordinates: {
              lat: '16.619',
              lng: '104.693',
            },
            zipcode: 49000,
          },
          490105: {
            name: {
              th: 'โพนทราย',
              en: 'Phon Sai',
            },
            coordinates: {
              lat: '16.541',
              lng: '104.584',
            },
            zipcode: 49000,
          },
          490106: {
            name: {
              th: 'ผึ่งแดด',
              en: 'Phueng Daet',
            },
            coordinates: {
              lat: '16.631',
              lng: '104.476',
            },
            zipcode: 49000,
          },
          490107: {
            name: {
              th: 'นาโสก',
              en: 'Na Sok',
            },
            coordinates: {
              lat: '16.487',
              lng: '104.522',
            },
            zipcode: 49000,
          },
          490108: {
            name: {
              th: 'นาสีนวน',
              en: 'Na Si Nuan',
            },
            coordinates: {
              lat: '16.455',
              lng: '104.785',
            },
            zipcode: 49000,
          },
          490109: {
            name: {
              th: 'คำป่าหลาย',
              en: 'Kham Pa Lai',
            },
            coordinates: {
              lat: '16.711',
              lng: '104.621',
            },
            zipcode: 49000,
          },
          490110: {
            name: {
              th: 'คำอาฮวน',
              en: 'Kham A Huan',
            },
            coordinates: {
              lat: '16.494',
              lng: '104.667',
            },
            zipcode: 49000,
          },
          490111: {
            name: {
              th: 'ดงเย็น',
              en: 'Dong Yen',
            },
            coordinates: {
              lat: '16.371',
              lng: '104.697',
            },
            zipcode: 49000,
          },
          490112: {
            name: {
              th: 'ดงมอน',
              en: 'Dong Mon',
            },
            coordinates: {
              lat: '16.687',
              lng: '104.500',
            },
            zipcode: 49000,
          },
          490113: {
            name: {
              th: 'กุดแข้',
              en: 'Kut Khae',
            },
            coordinates: {
              lat: '16.574',
              lng: '104.565',
            },
            zipcode: 49000,
          },
        },
      },
      4902: {
        name: {
          th: 'นิคมคำสร้อย',
          en: 'Nikhom Kham Soi',
        },
        tambons: {
          490201: {
            name: {
              th: 'นิคมคำสร้อย',
              en: 'Nikhom Kham Soi',
            },
            coordinates: {
              lat: '16.306',
              lng: '104.582',
            },
            zipcode: 49130,
          },
          490202: {
            name: {
              th: 'นากอก',
              en: 'Na Kok',
            },
            coordinates: {
              lat: '16.346',
              lng: '104.614',
            },
            zipcode: null,
          },
          490203: {
            name: {
              th: 'หนองแวง',
              en: 'Nong Waeng',
            },
            coordinates: {
              lat: '16.412',
              lng: '104.605',
            },
            zipcode: null,
          },
          490204: {
            name: {
              th: 'กกแดง',
              en: 'Kok Daeng',
            },
            coordinates: {
              lat: '16.410',
              lng: '104.505',
            },
            zipcode: null,
          },
          490205: {
            name: {
              th: 'นาอุดม',
              en: 'Na Udom',
            },
            coordinates: {
              lat: '16.274',
              lng: '104.615',
            },
            zipcode: 49130,
          },
          490206: {
            name: {
              th: 'โชคชัย',
              en: 'Chok Chai',
            },
            coordinates: {
              lat: '16.358',
              lng: '104.499',
            },
            zipcode: 49130,
          },
          490207: {
            name: {
              th: 'ร่มเกล้า',
              en: 'Romklao',
            },
            coordinates: {
              lat: '16.381',
              lng: '104.437',
            },
            zipcode: 49130,
          },
        },
      },
      4903: {
        name: {
          th: 'ดอนตาล',
          en: 'Don Tan',
        },
        tambons: {
          490301: {
            name: {
              th: 'ดอนตาล',
              en: 'Don Tan',
            },
            coordinates: {
              lat: '16.299',
              lng: '104.905',
            },
            zipcode: 49120,
          },
          490302: {
            name: {
              th: 'โพธิ์ไทร',
              en: 'Pho Sai',
            },
            coordinates: {
              lat: '16.376',
              lng: '104.862',
            },
            zipcode: 49120,
          },
          490303: {
            name: {
              th: 'ป่าไร่',
              en: 'Pa Rai',
            },
            coordinates: {
              lat: '16.254',
              lng: '104.701',
            },
            zipcode: 49120,
          },
          490304: {
            name: {
              th: 'เหล่าหมี',
              en: 'Lao Mi',
            },
            coordinates: {
              lat: '16.346',
              lng: '104.781',
            },
            zipcode: 49120,
          },
          490305: {
            name: {
              th: 'บ้านบาก',
              en: 'Ban Bak',
            },
            coordinates: {
              lat: '16.232',
              lng: '104.852',
            },
            zipcode: 49120,
          },
          490306: {
            name: {
              th: 'นาสะเม็ง',
              en: 'Na Sameng',
            },
            coordinates: {
              lat: '16.287',
              lng: '104.837',
            },
            zipcode: 49120,
          },
          490307: {
            name: {
              th: 'บ้านแก้ง',
              en: 'Ban Kaeng',
            },
            coordinates: {
              lat: '16.410',
              lng: '104.814',
            },
            zipcode: 49120,
          },
        },
      },
      4904: {
        name: {
          th: 'ดงหลวง',
          en: 'Don Tan',
        },
        tambons: {
          490401: {
            name: {
              th: 'ดงหลวง',
              en: 'Dong Luang',
            },
            coordinates: {
              lat: '16.818',
              lng: '104.523',
            },
            zipcode: 49140,
          },
          490402: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '16.811',
              lng: '104.568',
            },
            zipcode: 49140,
          },
          490403: {
            name: {
              th: 'กกตูม',
              en: 'Kok Tum',
            },
            coordinates: {
              lat: '16.792',
              lng: '104.196',
            },
            zipcode: 49140,
          },
          490404: {
            name: {
              th: 'หนองแคน',
              en: 'Nong Khaen',
            },
            coordinates: {
              lat: '16.760',
              lng: '104.492',
            },
            zipcode: 49140,
          },
          490405: {
            name: {
              th: 'ชะโนดน้อย',
              en: 'Chanot Noi',
            },
            coordinates: {
              lat: '16.805',
              lng: '104.618',
            },
            zipcode: 49140,
          },
          490406: {
            name: {
              th: 'พังแดง',
              en: 'Phang Daeng',
            },
            coordinates: {
              lat: '16.797',
              lng: '104.373',
            },
            zipcode: 49140,
          },
        },
      },
      4905: {
        name: {
          th: 'คำชะอี',
          en: 'Khamcha-i',
        },
        tambons: {
          490501: {
            name: {
              th: 'บ้านช่ง',
              en: null,
            },
            coordinates: {
              lat: '16.569',
              lng: '104.451',
            },
            zipcode: 49160,
          },
          490504: {
            name: {
              th: 'คำชะอี',
              en: 'Kham Cha-I',
            },
            coordinates: {
              lat: '16.589',
              lng: '104.279',
            },
            zipcode: 49110,
          },
          490505: {
            name: {
              th: 'หนองเอี่ยน',
              en: 'Nong Ian',
            },
            coordinates: {
              lat: '16.616',
              lng: '104.453',
            },
            zipcode: 49110,
          },
          490506: {
            name: {
              th: 'บ้านค้อ',
              en: 'Ban Kho',
            },
            coordinates: {
              lat: '16.701',
              lng: '104.352',
            },
            zipcode: 49110,
          },
          490507: {
            name: {
              th: 'บ้านเหล่า',
              en: 'Ban Lao',
            },
            coordinates: {
              lat: '16.686',
              lng: '104.282',
            },
            zipcode: 49110,
          },
          490508: {
            name: {
              th: 'โพนงาม',
              en: 'Phon Ngam',
            },
            coordinates: {
              lat: '16.675',
              lng: '104.435',
            },
            zipcode: 49110,
          },
          490511: {
            name: {
              th: 'เหล่าสร้างถ่อ',
              en: 'Lao Sang Tho',
            },
            coordinates: {
              lat: '16.561',
              lng: '104.492',
            },
            zipcode: 49110,
          },
          490512: {
            name: {
              th: 'คำบก',
              en: 'Kham Bok',
            },
            coordinates: {
              lat: '16.499',
              lng: '104.411',
            },
            zipcode: 49110,
          },
          490514: {
            name: {
              th: 'น้ำเที่ยง',
              en: 'Nam Thiang',
            },
            coordinates: {
              lat: '16.569',
              lng: '104.398',
            },
            zipcode: 49110,
          },
        },
      },
      4906: {
        name: {
          th: 'ว่านใหญ่',
          en: 'Wan Yai',
        },
        tambons: {
          490601: {
            name: {
              th: 'หว้านใหญ่',
              en: 'Wan Yai',
            },
            coordinates: {
              lat: '16.716',
              lng: '104.728',
            },
            zipcode: null,
          },
          490602: {
            name: {
              th: 'ป่งขาม',
              en: 'Pong Kham',
            },
            coordinates: {
              lat: '16.763',
              lng: '104.729',
            },
            zipcode: 49150,
          },
          490603: {
            name: {
              th: 'บางทรายน้อย',
              en: 'Bang Sai Noi',
            },
            coordinates: {
              lat: '16.667',
              lng: '104.707',
            },
            zipcode: null,
          },
          490604: {
            name: {
              th: 'ชะโนด',
              en: 'Chanot',
            },
            coordinates: {
              lat: '16.680',
              lng: '104.733',
            },
            zipcode: 49150,
          },
          490605: {
            name: {
              th: 'ดงหมู',
              en: 'Dong Mu',
            },
            coordinates: {
              lat: '16.807',
              lng: '104.708',
            },
            zipcode: 49150,
          },
        },
      },
      4907: {
        name: {
          th: 'หนองสูง',
          en: 'Nong Sung',
        },
        tambons: {
          490701: {
            name: {
              th: 'หนองสูง',
              en: 'Nong Sung',
            },
            coordinates: {
              lat: '16.459',
              lng: '104.361',
            },
            zipcode: null,
          },
          490702: {
            name: {
              th: 'โนนยาง',
              en: 'Non Yang',
            },
            coordinates: {
              lat: '16.490',
              lng: '104.278',
            },
            zipcode: null,
          },
          490703: {
            name: {
              th: 'ภูวง',
              en: 'Phu Wong',
            },
            coordinates: {
              lat: '16.455',
              lng: '104.447',
            },
            zipcode: null,
          },
          490704: {
            name: {
              th: 'บ้านเป้า',
              en: 'Ban Pao',
            },
            coordinates: {
              lat: '16.410',
              lng: '104.337',
            },
            zipcode: null,
          },
          490705: {
            name: {
              th: 'หนองสูงใต้',
              en: 'Nong Sung Tai',
            },
            coordinates: {
              lat: '16.388',
              lng: '104.382',
            },
            zipcode: null,
          },
          490706: {
            name: {
              th: 'หนองสูงเหนือ',
              en: 'Nong Sung Nuea',
            },
            coordinates: {
              lat: '16.491',
              lng: '104.360',
            },
            zipcode: 49160,
          },
        },
      },
    },
  },
  50: {
    name: {
      th: 'เชียงใหม่',
      en: 'Chiang Mai',
    },
    amphoes: {
      5001: {
        name: {
          th: 'เมืองเชียงใหม่',
          en: 'Mueang Chiang Mai',
        },
        tambons: {
          500101: {
            name: {
              th: 'ศรีภูมิ',
              en: 'Si Phum',
            },
            coordinates: {
              lat: '18.796',
              lng: '98.985',
            },
            zipcode: 50200,
          },
          500102: {
            name: {
              th: 'พระสิงห์',
              en: 'Phra Sing',
            },
            coordinates: {
              lat: '18.785',
              lng: '98.985',
            },
            zipcode: 50200,
          },
          500103: {
            name: {
              th: 'หายยา',
              en: 'Hai Ya',
            },
            coordinates: {
              lat: '18.777',
              lng: '98.984',
            },
            zipcode: 50100,
          },
          500104: {
            name: {
              th: 'ช้างม่อย',
              en: 'Chang Moi',
            },
            coordinates: {
              lat: '18.796',
              lng: '98.996',
            },
            zipcode: 50300,
          },
          500105: {
            name: {
              th: 'ช้างคลาน',
              en: 'Chang Khlan',
            },
            coordinates: {
              lat: '18.774',
              lng: '98.996',
            },
            zipcode: 50100,
          },
          500106: {
            name: {
              th: 'วัดเกต',
              en: 'Wat Ket',
            },
            coordinates: {
              lat: '18.785',
              lng: '99.011',
            },
            zipcode: 50000,
          },
          500107: {
            name: {
              th: 'ช้างเผือก',
              en: 'Chang Phueak',
            },
            coordinates: {
              lat: '18.821',
              lng: '98.969',
            },
            zipcode: 50300,
          },
          500109: {
            name: {
              th: 'สุเทพ',
              en: null,
            },
            coordinates: {
              lat: '18.768',
              lng: '98.921',
            },
            zipcode: 50100,
          },
          500110: {
            name: {
              th: 'ป่าแดด',
              en: 'Pa Daet',
            },
            coordinates: {
              lat: '18.742',
              lng: '98.978',
            },
            zipcode: 50100,
          },
          500111: {
            name: {
              th: 'หนองหอย',
              en: 'Nong Hoi',
            },
            coordinates: {
              lat: '18.758',
              lng: '99.015',
            },
            zipcode: 50000,
          },
          500112: {
            name: {
              th: 'ท่าศาลา',
              en: 'Tha Sala',
            },
            coordinates: {
              lat: '18.770',
              lng: '99.034',
            },
            zipcode: 50000,
          },
          500113: {
            name: {
              th: 'หนองป่าครั่ง',
              en: 'Nong Pa Khrang',
            },
            coordinates: {
              lat: '18.788',
              lng: '99.034',
            },
            zipcode: 50000,
          },
          500114: {
            name: {
              th: 'ฟ้าฮ่าม',
              en: 'Fa Ham',
            },
            coordinates: {
              lat: '18.816',
              lng: '99.008',
            },
            zipcode: 50000,
          },
          500115: {
            name: {
              th: 'ป่าตัน',
              en: 'Pa Tan',
            },
            coordinates: {
              lat: '18.811',
              lng: '98.999',
            },
            zipcode: 50300,
          },
          500116: {
            name: {
              th: 'สันผีเสื้อ',
              en: 'San Phisuea',
            },
            coordinates: {
              lat: '18.841',
              lng: '98.998',
            },
            zipcode: 50300,
          },
        },
      },
      5002: {
        name: {
          th: 'จอมทอง',
          en: 'Chom Thong',
        },
        tambons: {
          500203: {
            name: {
              th: 'บ้านหลวง',
              en: 'Ban Luang',
            },
            coordinates: {
              lat: '18.502',
              lng: '98.600',
            },
            zipcode: 50280,
          },
          500204: {
            name: {
              th: 'ข่วงเปา',
              en: 'Khuang Pao',
            },
            coordinates: {
              lat: '18.472',
              lng: '98.726',
            },
            zipcode: 50160,
          },
          500205: {
            name: {
              th: 'สบเตี๊ยะ',
              en: 'Sop Tia',
            },
            coordinates: {
              lat: '18.352',
              lng: '98.668',
            },
            zipcode: 50160,
          },
          500206: {
            name: {
              th: 'บ้านแปะ',
              en: 'Ban Pae',
            },
            coordinates: {
              lat: '18.256',
              lng: '98.565',
            },
            zipcode: 50240,
          },
          500207: {
            name: {
              th: 'ดอยแก้ว',
              en: 'Doi Kaeo',
            },
            coordinates: {
              lat: '18.390',
              lng: '98.576',
            },
            zipcode: 50160,
          },
          500209: {
            name: {
              th: 'แม่สอย',
              en: 'Mae Soi',
            },
            coordinates: {
              lat: '18.222',
              lng: '98.721',
            },
            zipcode: 50240,
          },
        },
      },
      5003: {
        name: {
          th: 'แม่แจ่ม',
          en: 'Mae Chaem',
        },
        tambons: {
          500301: {
            name: {
              th: 'ช่างเคิ่ง',
              en: 'Chang Khoeng',
            },
            coordinates: {
              lat: '18.552',
              lng: '98.401',
            },
            zipcode: 50270,
          },
          500302: {
            name: {
              th: 'ท่าผา',
              en: 'Tha Pha',
            },
            coordinates: {
              lat: '18.485',
              lng: '98.416',
            },
            zipcode: 50270,
          },
          500303: {
            name: {
              th: 'บ้านทับ',
              en: 'Ban Thap',
            },
            coordinates: {
              lat: '18.342',
              lng: '98.234',
            },
            zipcode: 50270,
          },
          500304: {
            name: {
              th: 'แม่ศึก',
              en: 'Mae Suek',
            },
            coordinates: {
              lat: '18.738',
              lng: '98.224',
            },
            zipcode: 50270,
          },
          500305: {
            name: {
              th: 'แม่นาจร',
              en: 'Mae Na Chon',
            },
            coordinates: {
              lat: '18.802',
              lng: '98.373',
            },
            zipcode: 50270,
          },
          500307: {
            name: {
              th: 'ปางหินฝน',
              en: 'Pang Hin Fon',
            },
            coordinates: {
              lat: '18.451',
              lng: '98.136',
            },
            zipcode: 50270,
          },
          500308: {
            name: {
              th: 'กองแขก',
              en: 'Kong Khaek',
            },
            coordinates: {
              lat: '18.358',
              lng: '98.376',
            },
            zipcode: 50270,
          },
        },
      },
      5004: {
        name: {
          th: 'เชียงดาว',
          en: 'Chiang Dao',
        },
        tambons: {
          500401: {
            name: {
              th: 'เชียงดาว',
              en: 'Chiang Dao',
            },
            coordinates: {
              lat: '19.389',
              lng: '98.947',
            },
            zipcode: 50170,
          },
          500402: {
            name: {
              th: 'เมืองนะ',
              en: 'Mueang Na',
            },
            coordinates: {
              lat: '19.312',
              lng: '98.939',
            },
            zipcode: 50170,
          },
          500403: {
            name: {
              th: 'เมืองงาย',
              en: 'Mueang Ngai',
            },
            coordinates: {
              lat: '19.477',
              lng: '98.833',
            },
            zipcode: 50170,
          },
          500404: {
            name: {
              th: 'แม่นะ',
              en: 'Mae Na',
            },
            coordinates: {
              lat: '19.687',
              lng: '98.900',
            },
            zipcode: 50170,
          },
          500405: {
            name: {
              th: 'เมืองคอง',
              en: 'Mueang Khong',
            },
            coordinates: {
              lat: '19.388',
              lng: '98.753',
            },
            zipcode: 50170,
          },
          500406: {
            name: {
              th: 'ปิงโค้ง',
              en: 'Ping Khong',
            },
            coordinates: {
              lat: '19.507',
              lng: '99.066',
            },
            zipcode: 50170,
          },
          500407: {
            name: {
              th: 'ทุ่งข้าวพวง',
              en: 'Thung Khao Phuang',
            },
            coordinates: {
              lat: '19.552',
              lng: '98.906',
            },
            zipcode: 50170,
          },
        },
      },
      5005: {
        name: {
          th: 'ดอยสะเก็ด',
          en: 'Doi Saket',
        },
        tambons: {
          500501: {
            name: {
              th: 'เชิงดอย',
              en: 'Choeng Doi',
            },
            coordinates: {
              lat: '18.899',
              lng: '99.170',
            },
            zipcode: 50220,
          },
          500502: {
            name: {
              th: 'สันปูเลย',
              en: 'San Pu Loei',
            },
            coordinates: {
              lat: '18.808',
              lng: '99.072',
            },
            zipcode: 50220,
          },
          500503: {
            name: {
              th: 'ลวงเหนือ',
              en: 'Luang Nuea',
            },
            coordinates: {
              lat: '18.965',
              lng: '99.141',
            },
            zipcode: 50220,
          },
          500504: {
            name: {
              th: 'ป่าป้อง',
              en: 'Pa Pong',
            },
            coordinates: {
              lat: '18.850',
              lng: '99.160',
            },
            zipcode: 50220,
          },
          500505: {
            name: {
              th: 'สง่าบ้าน',
              en: 'Sa-Nga Ban',
            },
            coordinates: {
              lat: '18.832',
              lng: '99.120',
            },
            zipcode: 50220,
          },
          500506: {
            name: {
              th: 'ป่าลาน',
              en: 'Pa Lan',
            },
            coordinates: {
              lat: '18.836',
              lng: '99.106',
            },
            zipcode: 50220,
          },
          500507: {
            name: {
              th: 'ตลาดขวัญ',
              en: 'Talat Khwan',
            },
            coordinates: {
              lat: '18.841',
              lng: '99.088',
            },
            zipcode: 50220,
          },
          500508: {
            name: {
              th: 'สำราญราษฎร์',
              en: 'Samran Rat',
            },
            coordinates: {
              lat: '18.807',
              lng: '99.095',
            },
            zipcode: 50220,
          },
          500509: {
            name: {
              th: 'แม่คือ',
              en: 'Mae Khue',
            },
            coordinates: {
              lat: '18.782',
              lng: '99.101',
            },
            zipcode: 50220,
          },
          500510: {
            name: {
              th: 'ตลาดใหญ่',
              en: 'Talat Yai',
            },
            coordinates: {
              lat: '18.810',
              lng: '99.127',
            },
            zipcode: 50220,
          },
          500511: {
            name: {
              th: 'แม่ฮ้อยเงิน',
              en: 'Mae Hoi Ngoen',
            },
            coordinates: {
              lat: '18.805',
              lng: '99.149',
            },
            zipcode: 50220,
          },
          500512: {
            name: {
              th: 'แม่โป่ง',
              en: 'Mae Pong',
            },
            coordinates: {
              lat: '18.847',
              lng: '99.196',
            },
            zipcode: 50220,
          },
          500513: {
            name: {
              th: 'ป่าเมี่ยง',
              en: 'Pa Miang',
            },
            coordinates: {
              lat: '18.935',
              lng: '99.243',
            },
            zipcode: 50220,
          },
          500514: {
            name: {
              th: 'เทพเสด็จ',
              en: 'Thep Sadet',
            },
            coordinates: {
              lat: '18.956',
              lng: '99.326',
            },
            zipcode: 50220,
          },
        },
      },
      5006: {
        name: {
          th: 'แม่แตง',
          en: 'Mae Taeng',
        },
        tambons: {
          500601: {
            name: {
              th: 'สันมหาพน',
              en: 'San Mahaphon',
            },
            coordinates: {
              lat: '19.118',
              lng: '98.946',
            },
            zipcode: 50150,
          },
          500602: {
            name: {
              th: 'แม่แตง',
              en: 'Mae Taeng',
            },
            coordinates: {
              lat: '19.155',
              lng: '98.910',
            },
            zipcode: 50150,
          },
          500603: {
            name: {
              th: 'ขี้เหล็ก',
              en: 'Khi Lek',
            },
            coordinates: {
              lat: '19.071',
              lng: '98.916',
            },
            zipcode: 50150,
          },
          500604: {
            name: {
              th: 'ช่อแล',
              en: 'Cho Lae',
            },
            coordinates: {
              lat: '19.144',
              lng: '99.024',
            },
            zipcode: 50150,
          },
          500605: {
            name: {
              th: 'แม่หอพระ',
              en: 'Mae Ho Phra',
            },
            coordinates: {
              lat: '19.117',
              lng: '99.072',
            },
            zipcode: 50150,
          },
          500606: {
            name: {
              th: 'สบเปิง',
              en: 'Sop Poeng',
            },
            coordinates: {
              lat: '19.104',
              lng: '98.810',
            },
            zipcode: 50150,
          },
          500607: {
            name: {
              th: 'บ้านเป้า',
              en: 'Ban Pao',
            },
            coordinates: {
              lat: '19.218',
              lng: '99.047',
            },
            zipcode: 50150,
          },
          500608: {
            name: {
              th: 'สันป่ายาง',
              en: 'San Pa Yang',
            },
            coordinates: {
              lat: '19.049',
              lng: '98.830',
            },
            zipcode: 50330,
          },
          500609: {
            name: {
              th: 'ป่าแป๋',
              en: 'Pa Pae',
            },
            coordinates: {
              lat: '19.179',
              lng: '98.671',
            },
            zipcode: 50150,
          },
          500610: {
            name: {
              th: 'เมืองก๋าย',
              en: 'Mueang Kai',
            },
            coordinates: {
              lat: '19.186',
              lng: '98.790',
            },
            zipcode: 50150,
          },
          500611: {
            name: {
              th: 'บ้านช้าง',
              en: 'Ban Chang',
            },
            coordinates: {
              lat: '19.147',
              lng: '98.861',
            },
            zipcode: 50150,
          },
          500612: {
            name: {
              th: 'กื้ดช้าง',
              en: 'Kuet Chang',
            },
            coordinates: {
              lat: '19.269',
              lng: '98.768',
            },
            zipcode: 50150,
          },
          500613: {
            name: {
              th: 'อินทขิล',
              en: 'Inthakhin',
            },
            coordinates: {
              lat: '19.200',
              lng: '98.953',
            },
            zipcode: 50150,
          },
        },
      },
      5007: {
        name: {
          th: 'แม่ริม',
          en: 'Mae Rim',
        },
        tambons: {
          500701: {
            name: {
              th: 'ริมใต้',
              en: 'Rim Tai',
            },
            coordinates: {
              lat: '18.914',
              lng: '98.964',
            },
            zipcode: 50180,
          },
          500702: {
            name: {
              th: 'ริมเหนือ',
              en: 'Rim Nuea',
            },
            coordinates: {
              lat: '18.929',
              lng: '98.948',
            },
            zipcode: 50180,
          },
          500703: {
            name: {
              th: 'สันโป่ง',
              en: 'San Pong',
            },
            coordinates: {
              lat: '18.954',
              lng: '98.954',
            },
            zipcode: 50180,
          },
          500704: {
            name: {
              th: 'ขี้เหล็ก',
              en: 'Khi Lek',
            },
            coordinates: {
              lat: '19.011',
              lng: '98.942',
            },
            zipcode: null,
          },
          500705: {
            name: {
              th: 'สะลวง',
              en: 'Saluang',
            },
            coordinates: {
              lat: '19.005',
              lng: '98.837',
            },
            zipcode: 50330,
          },
          500706: {
            name: {
              th: 'ห้วยทราย',
              en: 'Huai Sai',
            },
            coordinates: {
              lat: '18.967',
              lng: '98.915',
            },
            zipcode: 50180,
          },
          500707: {
            name: {
              th: 'แม่แรม',
              en: 'Mae Raem',
            },
            coordinates: {
              lat: '18.911',
              lng: '98.893',
            },
            zipcode: 50180,
          },
          500708: {
            name: {
              th: 'โป่งแยง',
              en: 'Pong Yaeng',
            },
            coordinates: {
              lat: '18.885',
              lng: '98.820',
            },
            zipcode: 50180,
          },
          500709: {
            name: {
              th: 'แม่สา',
              en: 'Mae Sa',
            },
            coordinates: {
              lat: '18.894',
              lng: '98.945',
            },
            zipcode: 50180,
          },
          500710: {
            name: {
              th: 'ดอนแก้ว',
              en: 'Don Kaeo',
            },
            coordinates: {
              lat: '18.860',
              lng: '98.939',
            },
            zipcode: 50180,
          },
          500711: {
            name: {
              th: 'เหมืองแก้ว',
              en: 'Mueang Kaeo',
            },
            coordinates: {
              lat: '18.898',
              lng: '98.983',
            },
            zipcode: 50180,
          },
        },
      },
      5008: {
        name: {
          th: 'สะเมิง',
          en: 'Samoeng',
        },
        tambons: {
          500801: {
            name: {
              th: 'สะเมิงใต้',
              en: 'Samoeng Tai',
            },
            coordinates: {
              lat: '18.823',
              lng: '98.714',
            },
            zipcode: 50250,
          },
          500802: {
            name: {
              th: 'สะเมิงเหนือ',
              en: 'Samoeng Nuea',
            },
            coordinates: {
              lat: '18.977',
              lng: '98.739',
            },
            zipcode: 50250,
          },
          500803: {
            name: {
              th: 'แม่สาบ',
              en: 'Mae Sap',
            },
            coordinates: {
              lat: '18.945',
              lng: '98.641',
            },
            zipcode: 50250,
          },
          500804: {
            name: {
              th: 'บ่อแก้ว',
              en: 'Bo Kaeo',
            },
            coordinates: {
              lat: '18.791',
              lng: '98.604',
            },
            zipcode: 50250,
          },
          500805: {
            name: {
              th: 'ยั้งเมิน',
              en: 'Yung Moen',
            },
            coordinates: {
              lat: '19.001',
              lng: '98.464',
            },
            zipcode: 50250,
          },
        },
      },
      5009: {
        name: {
          th: 'ฝาง',
          en: 'Fang',
        },
        tambons: {
          500901: {
            name: {
              th: 'เวียง',
              en: 'Wiang',
            },
            coordinates: {
              lat: '19.944',
              lng: '99.214',
            },
            zipcode: 50190,
          },
          500903: {
            name: {
              th: 'ม่อนปิ่น',
              en: 'Mon Pin',
            },
            coordinates: {
              lat: '19.955',
              lng: '99.112',
            },
            zipcode: 50110,
          },
          500904: {
            name: {
              th: 'แม่งอน',
              en: 'Mae Ngon',
            },
            coordinates: {
              lat: '19.793',
              lng: '99.124',
            },
            zipcode: 50320,
          },
          500906: {
            name: {
              th: 'สันทราย',
              en: 'San Sai',
            },
            coordinates: {
              lat: '19.879',
              lng: '99.209',
            },
            zipcode: 50140,
          },
          500910: {
            name: {
              th: 'แม่คะ',
              en: 'Mae Kha',
            },
            coordinates: {
              lat: '19.821',
              lng: '99.213',
            },
            zipcode: 50110,
          },
          500912: {
            name: {
              th: 'โป่งน้ำร้อน',
              en: 'Pong Nam Ron',
            },
            coordinates: {
              lat: '20.061',
              lng: '99.121',
            },
            zipcode: 50110,
          },
        },
      },
      5010: {
        name: {
          th: 'แม่อาย',
          en: 'Mae Ai',
        },
        tambons: {
          501001: {
            name: {
              th: 'แม่อาย',
              en: 'Mae Ai',
            },
            coordinates: {
              lat: '20.065',
              lng: '99.262',
            },
            zipcode: null,
          },
          501002: {
            name: {
              th: 'แม่สาว',
              en: 'Mae Sao',
            },
            coordinates: {
              lat: '20.060',
              lng: '99.168',
            },
            zipcode: null,
          },
          501003: {
            name: {
              th: 'สันต้นหมื้อ',
              en: 'San Ton Mue',
            },
            coordinates: {
              lat: '19.948',
              lng: '99.280',
            },
            zipcode: 50280,
          },
          501004: {
            name: {
              th: 'แม่นาวาง',
              en: 'Mae Na Wang',
            },
            coordinates: {
              lat: '19.980',
              lng: '99.382',
            },
            zipcode: null,
          },
          501005: {
            name: {
              th: 'ท่าตอน',
              en: 'Tha Ton',
            },
            coordinates: {
              lat: '20.066',
              lng: '99.387',
            },
            zipcode: 50280,
          },
          501006: {
            name: {
              th: 'บ้านหลวง',
              en: 'Ban Luang',
            },
            coordinates: {
              lat: '19.871',
              lng: '99.343',
            },
            zipcode: null,
          },
        },
      },
      5011: {
        name: {
          th: 'พร้าว',
          en: 'Phrao',
        },
        tambons: {
          501101: {
            name: {
              th: 'เวียง',
              en: 'Wiang',
            },
            coordinates: {
              lat: '19.378',
              lng: '99.203',
            },
            zipcode: null,
          },
          501102: {
            name: {
              th: 'ทุ่งหลวง',
              en: 'Thung Luang',
            },
            coordinates: {
              lat: '19.359',
              lng: '99.202',
            },
            zipcode: 50190,
          },
          501103: {
            name: {
              th: 'ป่าตุ้ม',
              en: 'Pa Tum',
            },
            coordinates: {
              lat: '19.370',
              lng: '99.266',
            },
            zipcode: 50190,
          },
          501104: {
            name: {
              th: 'ป่าไหน่',
              en: 'Pa Nai',
            },
            coordinates: {
              lat: '19.440',
              lng: '99.261',
            },
            zipcode: 50190,
          },
          501105: {
            name: {
              th: 'สันทราย',
              en: 'San Sai',
            },
            coordinates: {
              lat: '19.504',
              lng: '99.204',
            },
            zipcode: null,
          },
          501106: {
            name: {
              th: 'บ้านโป่ง',
              en: 'Ban Pong',
            },
            coordinates: {
              lat: '19.392',
              lng: '99.121',
            },
            zipcode: 50190,
          },
          501107: {
            name: {
              th: 'น้ำแพร่',
              en: 'Nam Phrae',
            },
            coordinates: {
              lat: '19.330',
              lng: '99.109',
            },
            zipcode: 50230,
          },
          501108: {
            name: {
              th: 'เขื่อนผาก',
              en: 'Khuean Phak',
            },
            coordinates: {
              lat: '19.308',
              lng: '99.164',
            },
            zipcode: 50190,
          },
          501109: {
            name: {
              th: 'แม่แวน',
              en: 'Mae Waen',
            },
            coordinates: {
              lat: '19.303',
              lng: '99.261',
            },
            zipcode: 50190,
          },
          501110: {
            name: {
              th: 'แม่ปั๋ง',
              en: 'Mae Pang',
            },
            coordinates: {
              lat: '19.213',
              lng: '99.212',
            },
            zipcode: 50190,
          },
          501111: {
            name: {
              th: 'โหล่งขอด',
              en: 'Long Khot',
            },
            coordinates: {
              lat: '19.074',
              lng: '99.225',
            },
            zipcode: 50190,
          },
        },
      },
      5012: {
        name: {
          th: 'สันป่าตอง',
          en: 'San Pa Tong',
        },
        tambons: {
          501201: {
            name: {
              th: 'ยุหว่า',
              en: 'Yu Wa',
            },
            coordinates: {
              lat: '18.620',
              lng: '98.875',
            },
            zipcode: 50120,
          },
          501202: {
            name: {
              th: 'สันกลาง',
              en: 'San Klang',
            },
            coordinates: {
              lat: '18.665',
              lng: '98.871',
            },
            zipcode: 50120,
          },
          501203: {
            name: {
              th: 'ท่าวังพร้าว',
              en: 'Tha Wang Phrao',
            },
            coordinates: {
              lat: '18.531',
              lng: '98.864',
            },
            zipcode: 50120,
          },
          501204: {
            name: {
              th: 'มะขามหลวง',
              en: 'Makham Luang',
            },
            coordinates: {
              lat: '18.601',
              lng: '98.907',
            },
            zipcode: 50120,
          },
          501205: {
            name: {
              th: 'แม่ก๊า',
              en: 'Mae Ka',
            },
            coordinates: {
              lat: '18.574',
              lng: '98.939',
            },
            zipcode: 50120,
          },
          501206: {
            name: {
              th: 'บ้านแม',
              en: 'Ban Mae',
            },
            coordinates: {
              lat: '18.626',
              lng: '98.854',
            },
            zipcode: 50120,
          },
          501207: {
            name: {
              th: 'บ้านกลาง',
              en: 'Ban Klang',
            },
            coordinates: {
              lat: '18.557',
              lng: '98.887',
            },
            zipcode: 50120,
          },
          501208: {
            name: {
              th: 'ทุ่งสะโตก',
              en: 'Thung Satok',
            },
            coordinates: {
              lat: '18.586',
              lng: '98.849',
            },
            zipcode: 50120,
          },
          501210: {
            name: {
              th: 'ทุ่งต้อม',
              en: 'ทุ่งต้อม',
            },
            coordinates: {
              lat: '18.613',
              lng: '98.920',
            },
            zipcode: 50120,
          },
          501214: {
            name: {
              th: 'น้ำบ่อหลวง',
              en: 'Nam Bo Luang',
            },
            coordinates: {
              lat: '18.674',
              lng: '98.841',
            },
            zipcode: 50120,
          },
          501215: {
            name: {
              th: 'มะขุนหวาน',
              en: 'Makhun Wan',
            },
            coordinates: {
              lat: '18.574',
              lng: '98.907',
            },
            zipcode: 50120,
          },
        },
      },
      5013: {
        name: {
          th: 'สันกำแพง',
          en: 'San Kamphaeng',
        },
        tambons: {
          501301: {
            name: {
              th: 'สันกำแพง',
              en: 'San Kamphaeng',
            },
            coordinates: {
              lat: '18.756',
              lng: '99.133',
            },
            zipcode: 50130,
          },
          501302: {
            name: {
              th: 'ทรายมูล',
              en: 'Sai Mun',
            },
            coordinates: {
              lat: '18.735',
              lng: '99.136',
            },
            zipcode: 50130,
          },
          501303: {
            name: {
              th: 'ร้องวัวแดง',
              en: 'Rong Wua Daeng',
            },
            coordinates: {
              lat: '18.746',
              lng: '99.206',
            },
            zipcode: 50130,
          },
          501304: {
            name: {
              th: 'บวกค้าง',
              en: 'Buak Khang',
            },
            coordinates: {
              lat: '18.704',
              lng: '99.107',
            },
            zipcode: 50130,
          },
          501305: {
            name: {
              th: 'แช่ช้าง',
              en: 'Chae Chang',
            },
            coordinates: {
              lat: '18.722',
              lng: '99.154',
            },
            zipcode: 50130,
          },
          501306: {
            name: {
              th: 'ออนใต้',
              en: 'On Tai',
            },
            coordinates: {
              lat: '18.700',
              lng: '99.229',
            },
            zipcode: 50130,
          },
          501310: {
            name: {
              th: 'แม่ปูคา',
              en: 'Mae Pu Kha',
            },
            coordinates: {
              lat: '18.785',
              lng: '99.126',
            },
            zipcode: 50130,
          },
          501311: {
            name: {
              th: 'ห้วยทราย',
              en: 'Huai Sai',
            },
            coordinates: {
              lat: '18.781',
              lng: '99.189',
            },
            zipcode: null,
          },
          501312: {
            name: {
              th: 'ต้นเปา',
              en: 'Ton Pao',
            },
            coordinates: {
              lat: '18.760',
              lng: '99.082',
            },
            zipcode: 50130,
          },
          501313: {
            name: {
              th: 'สันกลาง',
              en: 'San Klang',
            },
            coordinates: {
              lat: '18.775',
              lng: '99.056',
            },
            zipcode: null,
          },
        },
      },
      5014: {
        name: {
          th: 'สันทราย',
          en: 'San Sai',
        },
        tambons: {
          501401: {
            name: {
              th: 'สันทรายหลวง',
              en: 'San Sai Luang',
            },
            coordinates: {
              lat: '18.857',
              lng: '99.046',
            },
            zipcode: 50210,
          },
          501402: {
            name: {
              th: 'สันทรายน้อย',
              en: 'San Sai Noi',
            },
            coordinates: {
              lat: '18.819',
              lng: '99.024',
            },
            zipcode: 50210,
          },
          501403: {
            name: {
              th: 'สันพระเนตร',
              en: 'San Phranet',
            },
            coordinates: {
              lat: '18.808',
              lng: '99.038',
            },
            zipcode: 50210,
          },
          501404: {
            name: {
              th: 'สันนาเม็ง',
              en: 'San Na Meng',
            },
            coordinates: {
              lat: '18.842',
              lng: '99.062',
            },
            zipcode: 50210,
          },
          501405: {
            name: {
              th: 'สันป่าเปา',
              en: 'San Pa Pao',
            },
            coordinates: {
              lat: '18.861',
              lng: '99.084',
            },
            zipcode: 50210,
          },
          501406: {
            name: {
              th: 'หนองแหย่ง',
              en: 'Nong Yaeng',
            },
            coordinates: {
              lat: '18.911',
              lng: '99.101',
            },
            zipcode: 50210,
          },
          501407: {
            name: {
              th: 'หนองจ๊อม',
              en: 'Nong Chom',
            },
            coordinates: {
              lat: '18.852',
              lng: '99.023',
            },
            zipcode: 50210,
          },
          501408: {
            name: {
              th: 'หนองหาร',
              en: 'Nong Han',
            },
            coordinates: {
              lat: '18.930',
              lng: '99.014',
            },
            zipcode: 50290,
          },
          501410: {
            name: {
              th: 'แม่แฝกใหม่',
              en: 'Mae Faek Mai',
            },
            coordinates: {
              lat: '19.026',
              lng: '99.007',
            },
            zipcode: 50290,
          },
          501411: {
            name: {
              th: 'เมืองเล็น',
              en: 'Mueang Len',
            },
            coordinates: {
              lat: '18.896',
              lng: '99.080',
            },
            zipcode: 50210,
          },
          501412: {
            name: {
              th: 'ป่าไผ่',
              en: 'Pa Phai',
            },
            coordinates: {
              lat: '18.907',
              lng: '99.051',
            },
            zipcode: 50210,
          },
        },
      },
      5015: {
        name: {
          th: 'หางดง',
          en: 'Hang Dong',
        },
        tambons: {
          501501: {
            name: {
              th: 'หางดง',
              en: 'Hang Dong',
            },
            coordinates: {
              lat: '18.692',
              lng: '98.915',
            },
            zipcode: 50230,
          },
          501502: {
            name: {
              th: 'หนองแก๋ว',
              en: 'Nong Kaeo',
            },
            coordinates: {
              lat: '18.674',
              lng: '98.933',
            },
            zipcode: 50230,
          },
          501503: {
            name: {
              th: 'หารแก้ว',
              en: 'Han Kaeo',
            },
            coordinates: {
              lat: '18.650',
              lng: '98.922',
            },
            zipcode: 50230,
          },
          501504: {
            name: {
              th: 'หนองตอง',
              en: 'Nong Tong',
            },
            coordinates: {
              lat: '18.613',
              lng: '98.945',
            },
            zipcode: 50340,
          },
          501505: {
            name: {
              th: 'ขุนคง',
              en: 'Khun Khong',
            },
            coordinates: {
              lat: '18.673',
              lng: '98.956',
            },
            zipcode: 50230,
          },
          501506: {
            name: {
              th: 'สบแม่ข่า',
              en: 'Sop Mae Kha',
            },
            coordinates: {
              lat: '18.689',
              lng: '98.980',
            },
            zipcode: 50230,
          },
          501507: {
            name: {
              th: 'บ้านแหวน',
              en: 'Ban Waen',
            },
            coordinates: {
              lat: '18.701',
              lng: '98.944',
            },
            zipcode: 50230,
          },
          501508: {
            name: {
              th: 'สันผักหวาน',
              en: 'San Phak Wan',
            },
            coordinates: {
              lat: '18.719',
              lng: '98.970',
            },
            zipcode: 50230,
          },
          501509: {
            name: {
              th: 'หนองควาย',
              en: 'Nong Khwai',
            },
            coordinates: {
              lat: '18.731',
              lng: '98.923',
            },
            zipcode: 50230,
          },
          501510: {
            name: {
              th: 'บ้านปง',
              en: 'Ban Pong',
            },
            coordinates: {
              lat: '18.769',
              lng: '98.868',
            },
            zipcode: 50230,
          },
          501511: {
            name: {
              th: 'น้ำแพร่',
              en: 'Nam Phrae',
            },
            coordinates: {
              lat: '18.709',
              lng: '98.881',
            },
            zipcode: null,
          },
        },
      },
      5016: {
        name: {
          th: 'ฮอด',
          en: 'Hot',
        },
        tambons: {
          501601: {
            name: {
              th: 'หางดง',
              en: 'Hang Dong',
            },
            coordinates: {
              lat: '18.160',
              lng: '98.483',
            },
            zipcode: null,
          },
          501602: {
            name: {
              th: 'ฮอด',
              en: 'Hot',
            },
            coordinates: {
              lat: '18.103',
              lng: '98.583',
            },
            zipcode: 50240,
          },
          501603: {
            name: {
              th: 'บ้านตาล',
              en: 'Ban Tan',
            },
            coordinates: {
              lat: '18.088',
              lng: '98.709',
            },
            zipcode: 50240,
          },
          501604: {
            name: {
              th: 'บ่อหลวง',
              en: 'Bo Luang',
            },
            coordinates: {
              lat: '18.158',
              lng: '98.379',
            },
            zipcode: 50240,
          },
          501605: {
            name: {
              th: 'บ่อสลี',
              en: 'Bo Sali',
            },
            coordinates: {
              lat: '18.155',
              lng: '98.244',
            },
            zipcode: 50240,
          },
          501606: {
            name: {
              th: 'นาคอเรือ',
              en: 'นาคอเรือ',
            },
            coordinates: {
              lat: '18.009',
              lng: '98.520',
            },
            zipcode: 50240,
          },
        },
      },
      5017: {
        name: {
          th: 'ดอยเต่า',
          en: 'Doi Tao',
        },
        tambons: {
          501701: {
            name: {
              th: 'ดอยเต่า',
              en: 'Doi Tao',
            },
            coordinates: {
              lat: '17.897',
              lng: '98.731',
            },
            zipcode: 50260,
          },
          501702: {
            name: {
              th: 'ท่าเดื่อ',
              en: 'Tha Duea',
            },
            coordinates: {
              lat: '17.959',
              lng: '98.676',
            },
            zipcode: 50260,
          },
          501703: {
            name: {
              th: 'มืดกา',
              en: 'Muet Ka',
            },
            coordinates: {
              lat: '17.873',
              lng: '98.593',
            },
            zipcode: 50260,
          },
          501704: {
            name: {
              th: 'บ้านแอ่น',
              en: 'Ban Aen',
            },
            coordinates: {
              lat: '18.053',
              lng: '98.626',
            },
            zipcode: 50260,
          },
          501705: {
            name: {
              th: 'บงตัน',
              en: 'Bong Tan',
            },
            coordinates: {
              lat: '18.017',
              lng: '98.661',
            },
            zipcode: 50260,
          },
          501706: {
            name: {
              th: 'โปงทุ่ง',
              en: 'Pong Thung',
            },
            coordinates: {
              lat: '17.836',
              lng: '98.736',
            },
            zipcode: 50260,
          },
        },
      },
      5018: {
        name: {
          th: 'อมก๋อย',
          en: 'Omkoi',
        },
        tambons: {
          501801: {
            name: {
              th: 'อมก๋อย',
              en: 'Omkoi',
            },
            coordinates: {
              lat: '17.915',
              lng: '98.335',
            },
            zipcode: 50310,
          },
          501802: {
            name: {
              th: 'ยางเปียง',
              en: 'Yang Piang',
            },
            coordinates: {
              lat: '17.682',
              lng: '98.392',
            },
            zipcode: 50310,
          },
          501803: {
            name: {
              th: 'แม่ตื่น',
              en: 'Mae Tuen',
            },
            coordinates: {
              lat: '17.398',
              lng: '98.395',
            },
            zipcode: 50310,
          },
          501804: {
            name: {
              th: 'ม่อนจอง',
              en: 'Mon Chong',
            },
            coordinates: {
              lat: '17.430',
              lng: '98.500',
            },
            zipcode: 50310,
          },
          501805: {
            name: {
              th: 'สบโขง',
              en: 'Sop Khong',
            },
            coordinates: {
              lat: '17.654',
              lng: '98.217',
            },
            zipcode: 50310,
          },
          501806: {
            name: {
              th: 'นาเกียน',
              en: 'Na Kian',
            },
            coordinates: {
              lat: '17.864',
              lng: '98.156',
            },
            zipcode: 50310,
          },
        },
      },
      5019: {
        name: {
          th: 'สารภี',
          en: 'Saraphi',
        },
        tambons: {
          501901: {
            name: {
              th: 'ยางเนิ้ง',
              en: 'Yang Noeng',
            },
            coordinates: {
              lat: '18.707',
              lng: '99.036',
            },
            zipcode: 50140,
          },
          501902: {
            name: {
              th: 'สารภี',
              en: 'Saraphi',
            },
            coordinates: {
              lat: '18.681',
              lng: '99.047',
            },
            zipcode: 50140,
          },
          501903: {
            name: {
              th: 'ชมภู',
              en: 'Chomphu',
            },
            coordinates: {
              lat: '18.694',
              lng: '99.071',
            },
            zipcode: 50140,
          },
          501904: {
            name: {
              th: 'ไชยสถาน',
              en: 'Chaiya Sathan',
            },
            coordinates: {
              lat: '18.745',
              lng: '99.043',
            },
            zipcode: 50140,
          },
          501905: {
            name: {
              th: 'ขัวมุง',
              en: 'Khua Mung',
            },
            coordinates: {
              lat: '18.678',
              lng: '98.991',
            },
            zipcode: 50140,
          },
          501906: {
            name: {
              th: 'หนองแฝก',
              en: 'Nong Faek',
            },
            coordinates: {
              lat: '18.691',
              lng: '99.019',
            },
            zipcode: 50140,
          },
          501907: {
            name: {
              th: 'หนองผึ้ง',
              en: 'Nong Phueng',
            },
            coordinates: {
              lat: '18.735',
              lng: '99.021',
            },
            zipcode: 50140,
          },
          501908: {
            name: {
              th: 'ท่ากว้าง',
              en: 'Tha Kwang',
            },
            coordinates: {
              lat: '18.659',
              lng: '98.998',
            },
            zipcode: 50140,
          },
          501909: {
            name: {
              th: 'ดอนแก้ว',
              en: 'Don Kaeo',
            },
            coordinates: {
              lat: '18.697',
              lng: '98.997',
            },
            zipcode: null,
          },
          501910: {
            name: {
              th: 'ท่าวังตาล',
              en: 'Tha Wang Tan',
            },
            coordinates: {
              lat: '18.718',
              lng: '99.007',
            },
            zipcode: 50140,
          },
          501911: {
            name: {
              th: 'สันทราย',
              en: 'San Sai',
            },
            coordinates: {
              lat: '18.640',
              lng: '98.972',
            },
            zipcode: null,
          },
          501912: {
            name: {
              th: 'ป่าบง',
              en: 'Pa Bong',
            },
            coordinates: {
              lat: '18.736',
              lng: '99.059',
            },
            zipcode: 50140,
          },
        },
      },
      5020: {
        name: {
          th: 'เวียงแหง',
          en: 'Wiang Haeng',
        },
        tambons: {
          502001: {
            name: {
              th: 'เมืองแหง',
              en: 'Mueang Haeng',
            },
            coordinates: {
              lat: '19.515',
              lng: '98.639',
            },
            zipcode: 50350,
          },
          502002: {
            name: {
              th: 'เปียงหลวง',
              en: 'Piang Luang',
            },
            coordinates: {
              lat: '19.692',
              lng: '98.693',
            },
            zipcode: 50350,
          },
          502003: {
            name: {
              th: 'แสนไห',
              en: 'Saen Hai',
            },
            coordinates: {
              lat: '19.615',
              lng: '98.632',
            },
            zipcode: 50350,
          },
        },
      },
      5021: {
        name: {
          th: 'ไชยปราการ',
          en: 'Chai Prakan',
        },
        tambons: {
          502101: {
            name: {
              th: 'ปงตำ',
              en: 'Pong Tam',
            },
            coordinates: {
              lat: '19.748',
              lng: '99.145',
            },
            zipcode: null,
          },
          502102: {
            name: {
              th: 'ศรีดงเย็น',
              en: 'Si Dong Yen',
            },
            coordinates: {
              lat: '19.646',
              lng: '99.178',
            },
            zipcode: null,
          },
          502103: {
            name: {
              th: 'แม่ทะลบ',
              en: 'Mae Thalop',
            },
            coordinates: {
              lat: '19.730',
              lng: '99.247',
            },
            zipcode: null,
          },
          502104: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '19.736',
              lng: '99.073',
            },
            zipcode: null,
          },
        },
      },
      5022: {
        name: {
          th: 'แม่วาง',
          en: 'Mae Wang',
        },
        tambons: {
          502201: {
            name: {
              th: 'บ้านกาด',
              en: 'Ban Kat',
            },
            coordinates: {
              lat: '18.637',
              lng: '98.752',
            },
            zipcode: null,
          },
          502202: {
            name: {
              th: 'ทุ่งปี้',
              en: 'Thung Pi',
            },
            coordinates: {
              lat: '18.587',
              lng: '98.741',
            },
            zipcode: null,
          },
          502203: {
            name: {
              th: 'ทุ่งรวงทอง',
              en: 'Thung Ruang Thong',
            },
            coordinates: {
              lat: '18.554',
              lng: '98.849',
            },
            zipcode: null,
          },
          502204: {
            name: {
              th: 'แม่วิน',
              en: 'Mae Win',
            },
            coordinates: {
              lat: '18.683',
              lng: '98.674',
            },
            zipcode: null,
          },
          502205: {
            name: {
              th: 'ดอนเปา',
              en: 'Don Pao',
            },
            coordinates: {
              lat: '18.679',
              lng: '98.783',
            },
            zipcode: 50360,
          },
        },
      },
      5023: {
        name: {
          th: 'แม่ออน',
          en: 'Mae On',
        },
        tambons: {
          502301: {
            name: {
              th: 'ออนเหนือ',
              en: 'On Nuea',
            },
            coordinates: {
              lat: '18.793',
              lng: '99.308',
            },
            zipcode: null,
          },
          502302: {
            name: {
              th: 'ออนกลาง',
              en: 'On Klang',
            },
            coordinates: {
              lat: '18.758',
              lng: '99.263',
            },
            zipcode: null,
          },
          502303: {
            name: {
              th: 'บ้านสหกรณ์',
              en: 'Ban Sahakon',
            },
            coordinates: {
              lat: '18.825',
              lng: '99.242',
            },
            zipcode: null,
          },
          502304: {
            name: {
              th: 'ห้วยแก้ว',
              en: 'Huai Kaeo',
            },
            coordinates: {
              lat: '18.884',
              lng: '99.327',
            },
            zipcode: null,
          },
          502305: {
            name: {
              th: 'แม่ทา',
              en: 'Mae Tha',
            },
            coordinates: {
              lat: '18.591',
              lng: '99.289',
            },
            zipcode: null,
          },
          502306: {
            name: {
              th: 'ทาเหนือ',
              en: 'Tha Nuea',
            },
            coordinates: {
              lat: '18.702',
              lng: '99.308',
            },
            zipcode: null,
          },
        },
      },
      5024: {
        name: {
          th: 'ดอยหล่อ',
          en: 'Doi Lo',
        },
        tambons: {
          502401: {
            name: {
              th: 'ดอยหล่อ',
              en: 'Doi Lo',
            },
            coordinates: {
              lat: '18.488',
              lng: '98.798',
            },
            zipcode: null,
          },
          502402: {
            name: {
              th: 'สองแคว',
              en: 'Song Khwae',
            },
            coordinates: {
              lat: '18.504',
              lng: '98.841',
            },
            zipcode: null,
          },
          502403: {
            name: {
              th: 'ยางคราม',
              en: 'Yang Khram',
            },
            coordinates: {
              lat: '18.560',
              lng: '98.782',
            },
            zipcode: null,
          },
          502404: {
            name: {
              th: 'สันติสุข',
              en: 'Santi Suk',
            },
            coordinates: {
              lat: '18.581',
              lng: '98.682',
            },
            zipcode: null,
          },
        },
      },
      5025: {
        name: {
          th: 'กัลยาณิวัฒนา',
          en: 'Kanlayaniwatthana',
        },
        tambons: {
          502501: {
            name: {
              th: 'บ้านจันทร์',
              en: 'บ้านจันทร์',
            },
            coordinates: {
              lat: '19.110',
              lng: '98.281',
            },
            zipcode: null,
          },
          502502: {
            name: {
              th: 'แม่แดด',
              en: 'แม่แดด',
            },
            coordinates: {
              lat: '18.936',
              lng: '98.380',
            },
            zipcode: null,
          },
          502503: {
            name: {
              th: 'แจ่มหลวง',
              en: 'แจ่มหลวง',
            },
            coordinates: {
              lat: '19.011',
              lng: '98.218',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  51: {
    name: {
      th: 'ลำพูน',
      en: 'Lamphun',
    },
    amphoes: {
      5101: {
        name: {
          th: 'เมืองลำพูน',
          en: 'Mueang Lamphun',
        },
        tambons: {
          510101: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '18.573',
              lng: '99.002',
            },
            zipcode: 51000,
          },
          510102: {
            name: {
              th: 'เหมืองง่า',
              en: 'Mueang Nga',
            },
            coordinates: {
              lat: '18.613',
              lng: '99.032',
            },
            zipcode: 51000,
          },
          510103: {
            name: {
              th: 'อุโมงค์',
              en: 'Umong',
            },
            coordinates: {
              lat: '18.648',
              lng: '99.042',
            },
            zipcode: 51150,
          },
          510104: {
            name: {
              th: 'หนองช้างคืน',
              en: 'Nong Chang Khuen',
            },
            coordinates: {
              lat: '18.656',
              lng: '99.013',
            },
            zipcode: 51150,
          },
          510105: {
            name: {
              th: 'ประตูป่า',
              en: 'Pratu Pa',
            },
            coordinates: {
              lat: '18.623',
              lng: '98.986',
            },
            zipcode: 51000,
          },
          510106: {
            name: {
              th: 'ริมปิง',
              en: 'Rim Ping',
            },
            coordinates: {
              lat: '18.598',
              lng: '98.984',
            },
            zipcode: 51000,
          },
          510107: {
            name: {
              th: 'ต้นธง',
              en: 'Ton Thong',
            },
            coordinates: {
              lat: '18.572',
              lng: '98.985',
            },
            zipcode: 51000,
          },
          510108: {
            name: {
              th: 'บ้านแป้น',
              en: 'Ban Paen',
            },
            coordinates: {
              lat: '18.523',
              lng: '98.951',
            },
            zipcode: 51000,
          },
          510109: {
            name: {
              th: 'เหมืองจี้',
              en: 'Mueang Chi',
            },
            coordinates: {
              lat: '18.468',
              lng: '98.971',
            },
            zipcode: 51000,
          },
          510110: {
            name: {
              th: 'ป่าสัก',
              en: 'Pa Sak',
            },
            coordinates: {
              lat: '18.519',
              lng: '99.030',
            },
            zipcode: 51000,
          },
          510111: {
            name: {
              th: 'เวียงยอง',
              en: 'Wiang Yong',
            },
            coordinates: {
              lat: '18.558',
              lng: '99.009',
            },
            zipcode: 51000,
          },
          510112: {
            name: {
              th: 'บ้านกลาง',
              en: 'Ban Klang',
            },
            coordinates: {
              lat: '18.575',
              lng: '99.063',
            },
            zipcode: 51000,
          },
          510113: {
            name: {
              th: 'มะเขือแจ้',
              en: 'Makhuea Chae',
            },
            coordinates: {
              lat: '18.592',
              lng: '99.128',
            },
            zipcode: 51000,
          },
          510116: {
            name: {
              th: 'ศรีบัวบาน',
              en: 'Si Bua Ban',
            },
            coordinates: {
              lat: '18.517',
              lng: '99.109',
            },
            zipcode: 51000,
          },
          510117: {
            name: {
              th: 'หนองหนาม',
              en: 'Nong Nam',
            },
            coordinates: {
              lat: '18.502',
              lng: '98.989',
            },
            zipcode: 51000,
          },
        },
      },
      5102: {
        name: {
          th: 'แม่ทา',
          en: 'Mae Tha',
        },
        tambons: {
          510201: {
            name: {
              th: 'ทาปลาดุก',
              en: 'Tha Pla Duk',
            },
            coordinates: {
              lat: '18.532',
              lng: '99.245',
            },
            zipcode: 51140,
          },
          510202: {
            name: {
              th: 'ทาสบเส้า',
              en: 'Tha Sopsao',
            },
            coordinates: {
              lat: '18.417',
              lng: '99.135',
            },
            zipcode: 51140,
          },
          510203: {
            name: {
              th: 'ทากาศ',
              en: 'Tha Kat',
            },
            coordinates: {
              lat: '18.342',
              lng: '99.052',
            },
            zipcode: 51170,
          },
          510204: {
            name: {
              th: 'ทาขุมเงิน',
              en: 'Tha Khum Ngoen',
            },
            coordinates: {
              lat: '18.363',
              lng: '98.962',
            },
            zipcode: 51170,
          },
          510205: {
            name: {
              th: 'ทาทุ่งหลวง',
              en: 'Tha Thung Luang',
            },
            coordinates: {
              lat: '18.438',
              lng: '99.030',
            },
            zipcode: 51170,
          },
          510206: {
            name: {
              th: 'ทาแม่ลอบ',
              en: 'Tha Mae Lop',
            },
            coordinates: {
              lat: '18.241',
              lng: '98.970',
            },
            zipcode: 51170,
          },
        },
      },
      5103: {
        name: {
          th: 'บ้านโฮ่ง',
          en: 'Ban Hong',
        },
        tambons: {
          510301: {
            name: {
              th: 'บ้านโฮ่ง',
              en: 'Ban Hong',
            },
            coordinates: {
              lat: '18.308',
              lng: '98.815',
            },
            zipcode: 51130,
          },
          510302: {
            name: {
              th: 'ป่าพลู',
              en: 'Pa Phlu',
            },
            coordinates: {
              lat: '18.211',
              lng: '98.867',
            },
            zipcode: 51130,
          },
          510303: {
            name: {
              th: 'เหล่ายาว',
              en: 'Lao Yao',
            },
            coordinates: {
              lat: '18.360',
              lng: '98.794',
            },
            zipcode: 51130,
          },
          510304: {
            name: {
              th: 'ศรีเตี้ย',
              en: 'Si Tia',
            },
            coordinates: {
              lat: '18.383',
              lng: '98.750',
            },
            zipcode: 51130,
          },
          510305: {
            name: {
              th: 'หนองปลาสะวาย',
              en: 'Nong Pla Sawai',
            },
            coordinates: {
              lat: '18.314',
              lng: '98.697',
            },
            zipcode: 51130,
          },
        },
      },
      5104: {
        name: {
          th: 'ลี้',
          en: 'Li',
        },
        tambons: {
          510401: {
            name: {
              th: 'ลี้',
              en: 'Li',
            },
            coordinates: {
              lat: '17.790',
              lng: '98.986',
            },
            zipcode: 51110,
          },
          510402: {
            name: {
              th: 'แม่ตืน',
              en: 'Mae Tuen',
            },
            coordinates: {
              lat: '17.956',
              lng: '98.956',
            },
            zipcode: 51110,
          },
          510403: {
            name: {
              th: 'นาทราย',
              en: 'Na Sai',
            },
            coordinates: {
              lat: '17.687',
              lng: '98.970',
            },
            zipcode: 51110,
          },
          510404: {
            name: {
              th: 'ดงดำ',
              en: 'Dong Dam',
            },
            coordinates: {
              lat: '17.742',
              lng: '99.062',
            },
            zipcode: 51110,
          },
          510405: {
            name: {
              th: 'ก้อ',
              en: 'Ko',
            },
            coordinates: {
              lat: '17.604',
              lng: '98.783',
            },
            zipcode: 51110,
          },
          510406: {
            name: {
              th: 'แม่ลาน',
              en: 'Mae Lan',
            },
            coordinates: {
              lat: '17.761',
              lng: '98.809',
            },
            zipcode: 51110,
          },
          510408: {
            name: {
              th: 'ป่าไผ่',
              en: 'Pa Phai',
            },
            coordinates: {
              lat: '17.855',
              lng: '98.936',
            },
            zipcode: 51110,
          },
          510409: {
            name: {
              th: 'ศรีวิชัย',
              en: 'Si Wichai',
            },
            coordinates: {
              lat: '18.079',
              lng: '98.898',
            },
            zipcode: 51110,
          },
        },
      },
      5105: {
        name: {
          th: 'ทุ่งหัวช้าง',
          en: 'Thung Hua Chang',
        },
        tambons: {
          510501: {
            name: {
              th: 'ทุ่งหัวช้าง',
              en: 'Thung Hua Chang',
            },
            coordinates: {
              lat: '17.983',
              lng: '99.048',
            },
            zipcode: null,
          },
          510502: {
            name: {
              th: 'บ้านปวง',
              en: 'Ban Puang',
            },
            coordinates: {
              lat: '17.870',
              lng: '99.089',
            },
            zipcode: null,
          },
          510503: {
            name: {
              th: 'ตะเคียนปม',
              en: 'Takhian Pom',
            },
            coordinates: {
              lat: '18.106',
              lng: '99.025',
            },
            zipcode: 51160,
          },
        },
      },
      5106: {
        name: {
          th: 'ป่าซาง',
          en: 'Pa Sang',
        },
        tambons: {
          510601: {
            name: {
              th: 'ปากบ่อง',
              en: 'Pak Bong',
            },
            coordinates: {
              lat: '18.537',
              lng: '98.933',
            },
            zipcode: 51120,
          },
          510602: {
            name: {
              th: 'ป่าซาง',
              en: 'Pa Sang',
            },
            coordinates: {
              lat: '18.512',
              lng: '98.937',
            },
            zipcode: 51120,
          },
          510603: {
            name: {
              th: 'แม่แรง',
              en: 'Mae Raeng',
            },
            coordinates: {
              lat: '18.498',
              lng: '98.910',
            },
            zipcode: 51120,
          },
          510604: {
            name: {
              th: 'ม่วงน้อย',
              en: 'Muang Noi',
            },
            coordinates: {
              lat: '18.479',
              lng: '98.941',
            },
            zipcode: 51120,
          },
          510605: {
            name: {
              th: 'บ้านเรือน',
              en: 'Ban Ruean',
            },
            coordinates: {
              lat: '18.509',
              lng: '98.871',
            },
            zipcode: 51120,
          },
          510606: {
            name: {
              th: 'มะกอก',
              en: 'Makok',
            },
            coordinates: {
              lat: '18.449',
              lng: '98.923',
            },
            zipcode: 51120,
          },
          510607: {
            name: {
              th: 'ท่าตุ้ม',
              en: 'Tha Tum',
            },
            coordinates: {
              lat: '18.478',
              lng: '98.873',
            },
            zipcode: 51120,
          },
          510608: {
            name: {
              th: 'น้ำดิบ',
              en: 'Nam Dip',
            },
            coordinates: {
              lat: '18.424',
              lng: '98.833',
            },
            zipcode: 51120,
          },
          510611: {
            name: {
              th: 'นครเจดีย์',
              en: 'Nakhon Chedi',
            },
            coordinates: {
              lat: '18.371',
              lng: '98.898',
            },
            zipcode: 51120,
          },
        },
      },
      5107: {
        name: {
          th: 'บ้านธิ',
          en: 'Ban Thi',
        },
        tambons: {
          510701: {
            name: {
              th: 'บ้านธิ',
              en: 'Ban Thi',
            },
            coordinates: {
              lat: '18.614',
              lng: '99.190',
            },
            zipcode: null,
          },
          510702: {
            name: {
              th: 'ห้วยยาบ',
              en: 'Huai Yap',
            },
            coordinates: {
              lat: '18.679',
              lng: '99.163',
            },
            zipcode: null,
          },
        },
      },
      5108: {
        name: {
          th: 'เวียงหนองล่อง',
          en: 'Wiang Nong Long',
        },
        tambons: {
          510801: {
            name: {
              th: 'หนองล่อง',
              en: 'Nong Long',
            },
            coordinates: {
              lat: '18.404',
              lng: '98.732',
            },
            zipcode: null,
          },
          510802: {
            name: {
              th: 'หนองยวง',
              en: 'Nong Yuang',
            },
            coordinates: {
              lat: '18.415',
              lng: '98.780',
            },
            zipcode: null,
          },
          510803: {
            name: {
              th: 'วังผาง',
              en: 'Wang Phang',
            },
            coordinates: {
              lat: '18.422',
              lng: '98.744',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  52: {
    name: {
      th: 'ลำปาง',
      en: 'Lampang',
    },
    amphoes: {
      5201: {
        name: {
          th: 'เมืองลำปาง',
          en: 'Mueang Lampang',
        },
        tambons: {
          520101: {
            name: {
              th: 'เวียงเหนือ สวนดอก สบตุ๋ย อยู่นอก1 ตำบล คือหัวเ*',
              en: null,
            },
            coordinates: {
              lat: '18.290',
              lng: '99.492',
            },
            zipcode: 52000,
          },
          520105: {
            name: {
              th: 'พระบาท',
              en: 'Phrabat',
            },
            coordinates: {
              lat: '18.255',
              lng: '99.518',
            },
            zipcode: 52000,
          },
          520106: {
            name: {
              th: 'ชมพู',
              en: 'Chomphu',
            },
            coordinates: {
              lat: '18.254',
              lng: '99.479',
            },
            zipcode: 52100,
          },
          520107: {
            name: {
              th: 'กล้วยแพะ',
              en: 'Kluai Phae',
            },
            coordinates: {
              lat: '18.211',
              lng: '99.502',
            },
            zipcode: 52000,
          },
          520108: {
            name: {
              th: 'ปงแสนทอง',
              en: 'Pong Saen Thong',
            },
            coordinates: {
              lat: '18.268',
              lng: '99.432',
            },
            zipcode: 52100,
          },
          520109: {
            name: {
              th: 'บ้านแลง',
              en: 'Ban Laeng',
            },
            coordinates: {
              lat: '18.526',
              lng: '99.691',
            },
            zipcode: 52000,
          },
          520110: {
            name: {
              th: 'บ้านเสด็จ',
              en: 'Ban Sadet',
            },
            coordinates: {
              lat: '18.392',
              lng: '99.645',
            },
            zipcode: 52000,
          },
          520111: {
            name: {
              th: 'พิชัย',
              en: 'Phichai',
            },
            coordinates: {
              lat: '18.316',
              lng: '99.571',
            },
            zipcode: 52000,
          },
          520112: {
            name: {
              th: 'ทุ่งฝาย',
              en: 'Thung Fai',
            },
            coordinates: {
              lat: '18.374',
              lng: '99.550',
            },
            zipcode: 52000,
          },
          520113: {
            name: {
              th: 'บ้านเอื้อม',
              en: 'Ban Ueam',
            },
            coordinates: {
              lat: '18.478',
              lng: '99.357',
            },
            zipcode: 52100,
          },
          520114: {
            name: {
              th: 'บ้านเป้า',
              en: 'Ban Pao',
            },
            coordinates: {
              lat: '18.357',
              lng: '99.455',
            },
            zipcode: 52100,
          },
          520115: {
            name: {
              th: 'บ้านค่า',
              en: 'Ban Kha',
            },
            coordinates: {
              lat: '18.520',
              lng: '99.414',
            },
            zipcode: 52100,
          },
          520116: {
            name: {
              th: 'บ่อแฮ้ว',
              en: 'Bo Haeo',
            },
            coordinates: {
              lat: '18.310',
              lng: '99.433',
            },
            zipcode: 52100,
          },
          520117: {
            name: {
              th: 'ต้นธงชัย',
              en: 'Ton Thong Chai',
            },
            coordinates: {
              lat: '18.390',
              lng: '99.485',
            },
            zipcode: 52000,
          },
          520118: {
            name: {
              th: 'นิคมพัฒนา',
              en: 'Nikhom Phatthana',
            },
            coordinates: {
              lat: '18.445',
              lng: '99.545',
            },
            zipcode: 52000,
          },
          520119: {
            name: {
              th: 'บุญนาคพัฒนา',
              en: 'Bunnak Phatthana',
            },
            coordinates: {
              lat: '18.460',
              lng: '99.598',
            },
            zipcode: 52000,
          },
        },
      },
      5202: {
        name: {
          th: 'แม่เมาะ',
          en: 'Mae Mo',
        },
        tambons: {
          520201: {
            name: {
              th: 'บ้านดง',
              en: 'Ban Dong',
            },
            coordinates: {
              lat: '18.374',
              lng: '99.701',
            },
            zipcode: null,
          },
          520202: {
            name: {
              th: 'นาสัก',
              en: 'Na Sak',
            },
            coordinates: {
              lat: '18.343',
              lng: '99.836',
            },
            zipcode: null,
          },
          520203: {
            name: {
              th: 'จางเหนือ',
              en: 'Chang Nuea',
            },
            coordinates: {
              lat: '18.456',
              lng: '99.941',
            },
            zipcode: 52220,
          },
          520204: {
            name: {
              th: 'แม่เมาะ',
              en: 'Mae Mo',
            },
            coordinates: {
              lat: '18.297',
              lng: '99.668',
            },
            zipcode: 52220,
          },
          520205: {
            name: {
              th: 'สบป้าน',
              en: 'Sop Pat',
            },
            coordinates: {
              lat: '18.250',
              lng: '99.807',
            },
            zipcode: 52220,
          },
        },
      },
      5203: {
        name: {
          th: 'เกาะคา',
          en: 'Ko kha',
        },
        tambons: {
          520301: {
            name: {
              th: 'ลำปางหลวง',
              en: 'Lampang Luang',
            },
            coordinates: {
              lat: '18.238',
              lng: '99.387',
            },
            zipcode: 52130,
          },
          520302: {
            name: {
              th: 'นาแก้ว',
              en: 'Na Kaeo',
            },
            coordinates: {
              lat: '18.031',
              lng: '99.301',
            },
            zipcode: 52130,
          },
          520303: {
            name: {
              th: 'ไหล่หิน',
              en: 'Lai Hin',
            },
            coordinates: {
              lat: '18.186',
              lng: '99.255',
            },
            zipcode: 52130,
          },
          520304: {
            name: {
              th: 'วังพร้าว',
              en: 'Wang Phrao',
            },
            coordinates: {
              lat: '18.147',
              lng: '99.407',
            },
            zipcode: 52130,
          },
          520305: {
            name: {
              th: 'ศาลา',
              en: 'Sala',
            },
            coordinates: {
              lat: '18.196',
              lng: '99.413',
            },
            zipcode: 52130,
          },
          520306: {
            name: {
              th: 'เกาะคา',
              en: 'Ko Kha',
            },
            coordinates: {
              lat: '18.202',
              lng: '99.372',
            },
            zipcode: 52130,
          },
          520307: {
            name: {
              th: 'นาแส่ง',
              en: 'Na Saeng',
            },
            coordinates: {
              lat: '18.074',
              lng: '99.381',
            },
            zipcode: 52130,
          },
          520308: {
            name: {
              th: 'ท่าผา',
              en: 'Tha Pha',
            },
            coordinates: {
              lat: '18.173',
              lng: '99.365',
            },
            zipcode: 52130,
          },
          520309: {
            name: {
              th: 'ใหม่พัฒนา',
              en: 'Mai Phatthana',
            },
            coordinates: {
              lat: '18.254',
              lng: '99.318',
            },
            zipcode: 52130,
          },
        },
      },
      5204: {
        name: {
          th: 'เสริมงาม',
          en: 'Soem Ngam',
        },
        tambons: {
          520401: {
            name: {
              th: 'ทุ่งงาม',
              en: 'Thung Ngam',
            },
            coordinates: {
              lat: '18.071',
              lng: '99.244',
            },
            zipcode: 52210,
          },
          520402: {
            name: {
              th: 'เสริมขวา',
              en: 'Soem Khwa',
            },
            coordinates: {
              lat: '18.138',
              lng: '99.196',
            },
            zipcode: 52210,
          },
          520403: {
            name: {
              th: 'เสริมซ้าย',
              en: 'Soem Sai',
            },
            coordinates: {
              lat: '17.975',
              lng: '99.165',
            },
            zipcode: 52210,
          },
          520404: {
            name: {
              th: 'เสริมกลาง',
              en: 'Soem Klang',
            },
            coordinates: {
              lat: '18.132',
              lng: '99.087',
            },
            zipcode: 52210,
          },
        },
      },
      5205: {
        name: {
          th: 'งาว',
          en: 'Ngao',
        },
        tambons: {
          520501: {
            name: {
              th: 'หลวงเหนือ',
              en: 'Luang Nuea',
            },
            coordinates: {
              lat: '18.752',
              lng: '99.996',
            },
            zipcode: 52110,
          },
          520502: {
            name: {
              th: 'หลวงใต้',
              en: 'Luang Tai',
            },
            coordinates: {
              lat: '18.743',
              lng: '99.958',
            },
            zipcode: 52110,
          },
          520503: {
            name: {
              th: 'บ้านโป่ง',
              en: 'Ban Pong',
            },
            coordinates: {
              lat: '18.726',
              lng: '99.886',
            },
            zipcode: 52110,
          },
          520504: {
            name: {
              th: 'บ้านร้อง',
              en: 'Ban Rong',
            },
            coordinates: {
              lat: '18.972',
              lng: '99.824',
            },
            zipcode: 52110,
          },
          520505: {
            name: {
              th: 'ปงเตา',
              en: 'Pong Tao',
            },
            coordinates: {
              lat: '18.839',
              lng: '99.927',
            },
            zipcode: 52110,
          },
          520506: {
            name: {
              th: 'นาแก',
              en: 'Na Kae',
            },
            coordinates: {
              lat: '18.817',
              lng: '99.885',
            },
            zipcode: 52110,
          },
          520507: {
            name: {
              th: 'บ้านอ้อน',
              en: 'Ban On',
            },
            coordinates: {
              lat: '18.788',
              lng: '99.852',
            },
            zipcode: 52110,
          },
          520508: {
            name: {
              th: 'บ้านแหง',
              en: 'Ban Haeng',
            },
            coordinates: {
              lat: '18.782',
              lng: '100.054',
            },
            zipcode: 52110,
          },
          520509: {
            name: {
              th: 'บ้านหวด',
              en: 'Ban Huat',
            },
            coordinates: {
              lat: '18.609',
              lng: '99.888',
            },
            zipcode: 52110,
          },
          520510: {
            name: {
              th: 'แม่ตีบ',
              en: 'Mae Tip',
            },
            coordinates: {
              lat: '18.634',
              lng: '100.038',
            },
            zipcode: 52110,
          },
        },
      },
      5206: {
        name: {
          th: 'แจ้ห่ม',
          en: 'Chae Hom',
        },
        tambons: {
          520601: {
            name: {
              th: 'แจ้ห่ม',
              en: 'Chae Hom',
            },
            coordinates: {
              lat: '18.700',
              lng: '99.616',
            },
            zipcode: 52120,
          },
          520602: {
            name: {
              th: 'บ้านสา',
              en: 'Ban Sa',
            },
            coordinates: {
              lat: '18.592',
              lng: '99.561',
            },
            zipcode: 52120,
          },
          520603: {
            name: {
              th: 'ปงดอน',
              en: 'Pong Don',
            },
            coordinates: {
              lat: '18.783',
              lng: '99.695',
            },
            zipcode: 52120,
          },
          520604: {
            name: {
              th: 'แม่สุก',
              en: 'Mae Suk',
            },
            coordinates: {
              lat: '18.836',
              lng: '99.578',
            },
            zipcode: 52120,
          },
          520605: {
            name: {
              th: 'เมืองมาย',
              en: 'Mueang Mai',
            },
            coordinates: {
              lat: '18.645',
              lng: '99.725',
            },
            zipcode: 52120,
          },
          520606: {
            name: {
              th: 'ทุ่งผึ้ง',
              en: 'Thung Phueng',
            },
            coordinates: {
              lat: '18.914',
              lng: '99.703',
            },
            zipcode: 52120,
          },
          520607: {
            name: {
              th: 'วิเชตนคร',
              en: 'Wichet Nakhon',
            },
            coordinates: {
              lat: '18.726',
              lng: '99.557',
            },
            zipcode: 52120,
          },
        },
      },
      5207: {
        name: {
          th: 'วังเหนือ',
          en: 'Wang Nuea',
        },
        tambons: {
          520701: {
            name: {
              th: 'ทุ่งฮั้ว',
              en: 'Thung Hua',
            },
            coordinates: {
              lat: '19.229',
              lng: '99.631',
            },
            zipcode: 52140,
          },
          520702: {
            name: {
              th: 'วังเหนือ',
              en: 'Wang Nuea',
            },
            coordinates: {
              lat: '19.160',
              lng: '99.585',
            },
            zipcode: 52140,
          },
          520703: {
            name: {
              th: 'วังใต้',
              en: 'Wang Tai',
            },
            coordinates: {
              lat: '19.084',
              lng: '99.577',
            },
            zipcode: 52140,
          },
          520704: {
            name: {
              th: 'ร่องเคาะ',
              en: 'Rong Kho',
            },
            coordinates: {
              lat: '18.992',
              lng: '99.601',
            },
            zipcode: 52140,
          },
          520705: {
            name: {
              th: 'วังทอง',
              en: 'Wang Thong',
            },
            coordinates: {
              lat: '19.070',
              lng: '99.713',
            },
            zipcode: 52140,
          },
          520706: {
            name: {
              th: 'วังซ้าย',
              en: 'Wang Sai',
            },
            coordinates: {
              lat: '19.157',
              lng: '99.645',
            },
            zipcode: 52140,
          },
          520707: {
            name: {
              th: 'วังแก้ว',
              en: 'Wang Kaeo',
            },
            coordinates: {
              lat: '19.329',
              lng: '99.636',
            },
            zipcode: 52140,
          },
          520708: {
            name: {
              th: 'วังทรายคำ',
              en: 'Wang Sai Thong',
            },
            coordinates: {
              lat: '19.080',
              lng: '99.649',
            },
            zipcode: 52140,
          },
        },
      },
      5208: {
        name: {
          th: 'เถิน',
          en: 'Thoen',
        },
        tambons: {
          520801: {
            name: {
              th: 'ล้อมแรด',
              en: 'Lom Raet',
            },
            coordinates: {
              lat: '17.634',
              lng: '99.212',
            },
            zipcode: 52160,
          },
          520802: {
            name: {
              th: 'แม่วะ',
              en: 'Mae Wa',
            },
            coordinates: {
              lat: '17.468',
              lng: '99.216',
            },
            zipcode: 52230,
          },
          520803: {
            name: {
              th: 'แม่ปะ',
              en: 'Mae Pa',
            },
            coordinates: {
              lat: '17.684',
              lng: '99.299',
            },
            zipcode: 52160,
          },
          520804: {
            name: {
              th: 'แม่มอก',
              en: 'Mae Mok',
            },
            coordinates: {
              lat: '17.609',
              lng: '99.381',
            },
            zipcode: 52160,
          },
          520805: {
            name: {
              th: 'เวียงมอก',
              en: 'Wiang Mok',
            },
            coordinates: {
              lat: '17.396',
              lng: '99.353',
            },
            zipcode: 52160,
          },
          520806: {
            name: {
              th: 'นาโป่ง',
              en: 'Na Pong',
            },
            coordinates: {
              lat: '17.651',
              lng: '99.116',
            },
            zipcode: 52160,
          },
          520807: {
            name: {
              th: 'แม่ถอด',
              en: 'Mae Thot',
            },
            coordinates: {
              lat: '17.766',
              lng: '99.201',
            },
            zipcode: 52160,
          },
          520808: {
            name: {
              th: 'เถินบุรี',
              en: 'Thoen Buri',
            },
            coordinates: {
              lat: '17.558',
              lng: '99.207',
            },
            zipcode: 52160,
          },
        },
      },
      5209: {
        name: {
          th: 'แม่พริก',
          en: 'Mae Phrik',
        },
        tambons: {
          520901: {
            name: {
              th: 'แม่พริก',
              en: 'Mae Phrik',
            },
            coordinates: {
              lat: '17.513',
              lng: '99.009',
            },
            zipcode: 52180,
          },
          520902: {
            name: {
              th: 'ผาปัง',
              en: 'Pha Pang',
            },
            coordinates: {
              lat: '17.564',
              lng: '99.069',
            },
            zipcode: 52180,
          },
          520903: {
            name: {
              th: 'แม่ปุ',
              en: 'Mae Pu',
            },
            coordinates: {
              lat: '17.539',
              lng: '99.152',
            },
            zipcode: 52180,
          },
          520904: {
            name: {
              th: 'พระบาทวังตวง',
              en: 'Phrabat Wang Tuang',
            },
            coordinates: {
              lat: '17.412',
              lng: '99.162',
            },
            zipcode: 52230,
          },
        },
      },
      5210: {
        name: {
          th: 'แม่ทะ',
          en: 'Mae Tha',
        },
        tambons: {
          521001: {
            name: {
              th: 'แม่ทะ',
              en: 'Mae Tha',
            },
            coordinates: {
              lat: '18.162',
              lng: '99.563',
            },
            zipcode: 52150,
          },
          521002: {
            name: {
              th: 'นาครัว',
              en: 'Na Khrua',
            },
            coordinates: {
              lat: '18.109',
              lng: '99.545',
            },
            zipcode: 52150,
          },
          521003: {
            name: {
              th: 'ป่าตัน',
              en: 'Pa Tan',
            },
            coordinates: {
              lat: '18.122',
              lng: '99.477',
            },
            zipcode: 52150,
          },
          521004: {
            name: {
              th: 'บ้านกิ่ว',
              en: 'Ban Kio',
            },
            coordinates: {
              lat: '18.089',
              lng: '99.491',
            },
            zipcode: 52150,
          },
          521005: {
            name: {
              th: 'บ้านบอม',
              en: 'Ban Bom',
            },
            coordinates: {
              lat: '18.054',
              lng: '99.489',
            },
            zipcode: 52150,
          },
          521006: {
            name: {
              th: 'น้ำโจ้',
              en: 'Nam Cho',
            },
            coordinates: {
              lat: '18.170',
              lng: '99.460',
            },
            zipcode: 52150,
          },
          521007: {
            name: {
              th: 'ดอนไฟ',
              en: 'Don Fai',
            },
            coordinates: {
              lat: '18.104',
              lng: '99.671',
            },
            zipcode: 52150,
          },
          521008: {
            name: {
              th: 'หัวเสือ',
              en: 'Hua Suea',
            },
            coordinates: {
              lat: '18.173',
              lng: '99.696',
            },
            zipcode: 52150,
          },
          521010: {
            name: {
              th: 'วังเงิน',
              en: 'Wang Ngoen',
            },
            coordinates: {
              lat: '18.090',
              lng: '99.614',
            },
            zipcode: 52150,
          },
          521011: {
            name: {
              th: 'สันดอนแก้ว',
              en: 'San Don Kaeo',
            },
            coordinates: {
              lat: '17.984',
              lng: '99.484',
            },
            zipcode: 52150,
          },
        },
      },
      5211: {
        name: {
          th: 'สบปราบ',
          en: 'Sop Prap',
        },
        tambons: {
          521101: {
            name: {
              th: 'สบปราบ',
              en: 'Sop Prap',
            },
            coordinates: {
              lat: '17.835',
              lng: '99.344',
            },
            zipcode: 52170,
          },
          521102: {
            name: {
              th: 'สมัย',
              en: 'Samai',
            },
            coordinates: {
              lat: '17.900',
              lng: '99.421',
            },
            zipcode: 52170,
          },
          521103: {
            name: {
              th: 'แม่กัวะ',
              en: 'Mae Kua',
            },
            coordinates: {
              lat: '17.956',
              lng: '99.353',
            },
            zipcode: 52170,
          },
          521104: {
            name: {
              th: 'นายาง',
              en: 'Na Yang',
            },
            coordinates: {
              lat: '17.904',
              lng: '99.266',
            },
            zipcode: 52170,
          },
        },
      },
      5212: {
        name: {
          th: 'ห้างฉัตร',
          en: 'Hang Chat',
        },
        tambons: {
          521201: {
            name: {
              th: 'ห้างฉัตร',
              en: 'Hang Chat',
            },
            coordinates: {
              lat: '18.337',
              lng: '99.353',
            },
            zipcode: 52190,
          },
          521202: {
            name: {
              th: 'หนองหล่ม',
              en: 'Nong Lom',
            },
            coordinates: {
              lat: '18.349',
              lng: '99.387',
            },
            zipcode: 52190,
          },
          521203: {
            name: {
              th: 'เมืองยาว',
              en: 'Mueang Yao',
            },
            coordinates: {
              lat: '18.269',
              lng: '99.200',
            },
            zipcode: 52190,
          },
          521204: {
            name: {
              th: 'ปงยางคก',
              en: 'Pong Yang Khok',
            },
            coordinates: {
              lat: '18.284',
              lng: '99.365',
            },
            zipcode: 52190,
          },
          521205: {
            name: {
              th: 'เวียงตาล',
              en: 'Wiang Tan',
            },
            coordinates: {
              lat: '18.408',
              lng: '99.277',
            },
            zipcode: 52190,
          },
          521206: {
            name: {
              th: 'แม่สัน',
              en: 'Mae San',
            },
            coordinates: {
              lat: '18.291',
              lng: '99.273',
            },
            zipcode: 52190,
          },
          521207: {
            name: {
              th: 'วอแก้ว',
              en: 'Wo Kaeo',
            },
            coordinates: {
              lat: '18.421',
              lng: '99.339',
            },
            zipcode: 52190,
          },
        },
      },
      5213: {
        name: {
          th: 'เมืองปาน',
          en: 'Mueang Pan',
        },
        tambons: {
          521301: {
            name: {
              th: 'เมืองปาน',
              en: 'Mueang Pan',
            },
            coordinates: {
              lat: '18.797',
              lng: '99.446',
            },
            zipcode: null,
          },
          521302: {
            name: {
              th: 'บ้านขอ',
              en: 'Ban Kho',
            },
            coordinates: {
              lat: '18.685',
              lng: '99.481',
            },
            zipcode: null,
          },
          521303: {
            name: {
              th: 'ทุ่งกว๋าว',
              en: 'Thung Khwao',
            },
            coordinates: {
              lat: '18.570',
              lng: '99.470',
            },
            zipcode: null,
          },
          521304: {
            name: {
              th: 'แจ้ซ้อน',
              en: 'Chae Son',
            },
            coordinates: {
              lat: '18.838',
              lng: '99.501',
            },
            zipcode: null,
          },
          521305: {
            name: {
              th: 'หัวเมือง',
              en: 'Hua Mueang',
            },
            coordinates: {
              lat: '18.946',
              lng: '99.531',
            },
            zipcode: 52240,
          },
        },
      },
    },
  },
  53: {
    name: {
      th: 'อุตรดิตถ์',
      en: 'Uttaradit',
    },
    amphoes: {
      5301: {
        name: {
          th: 'เมืองอุตรดิตถ์',
          en: 'Mueang Uttaradit',
        },
        tambons: {
          530101: {
            name: {
              th: 'ท่าอิฐ',
              en: 'Tha It',
            },
            coordinates: {
              lat: '17.613',
              lng: '100.079',
            },
            zipcode: 53000,
          },
          530102: {
            name: {
              th: 'ท่าเสา',
              en: 'Tha Sao',
            },
            coordinates: {
              lat: '17.652',
              lng: '100.086',
            },
            zipcode: 53000,
          },
          530103: {
            name: {
              th: 'บ้านเกาะ',
              en: 'Ban Ko',
            },
            coordinates: {
              lat: '17.579',
              lng: '100.117',
            },
            zipcode: 53000,
          },
          530104: {
            name: {
              th: 'ป่าเซ่า',
              en: 'Pa Sao',
            },
            coordinates: {
              lat: '17.600',
              lng: '100.160',
            },
            zipcode: 53000,
          },
          530105: {
            name: {
              th: 'คุ้งตะเภา',
              en: 'Khung Taphao',
            },
            coordinates: {
              lat: '17.641',
              lng: '100.166',
            },
            zipcode: 53000,
          },
          530106: {
            name: {
              th: 'วังกะพี้',
              en: 'Wang Kaphi',
            },
            coordinates: {
              lat: '17.545',
              lng: '100.079',
            },
            zipcode: 53170,
          },
          530107: {
            name: {
              th: 'หาดกรวด',
              en: 'Hat Kruat',
            },
            coordinates: {
              lat: '17.562',
              lng: '100.199',
            },
            zipcode: 53000,
          },
          530108: {
            name: {
              th: 'น้ำริด',
              en: 'Nam Rit',
            },
            coordinates: {
              lat: '17.684',
              lng: '100.132',
            },
            zipcode: 53000,
          },
          530109: {
            name: {
              th: 'งิ้วงาม',
              en: 'Ngio Ngam',
            },
            coordinates: {
              lat: '17.702',
              lng: '100.174',
            },
            zipcode: 53000,
          },
          530110: {
            name: {
              th: 'บ้านด่านนาขาม',
              en: 'Ban Dan Na Kham',
            },
            coordinates: {
              lat: '17.784',
              lng: '100.161',
            },
            zipcode: 53000,
          },
          530111: {
            name: {
              th: 'บ้านด่าน',
              en: 'Ban Dan',
            },
            coordinates: {
              lat: '17.663',
              lng: '100.280',
            },
            zipcode: 53000,
          },
          530112: {
            name: {
              th: 'ผาจุก',
              en: 'Pha Chuk',
            },
            coordinates: {
              lat: '17.639',
              lng: '100.243',
            },
            zipcode: 53000,
          },
          530113: {
            name: {
              th: 'วังดิน',
              en: 'Wang Din',
            },
            coordinates: {
              lat: '17.737',
              lng: '100.266',
            },
            zipcode: 53000,
          },
          530114: {
            name: {
              th: 'แสนตอ',
              en: 'Saen To',
            },
            coordinates: {
              lat: '17.651',
              lng: '100.365',
            },
            zipcode: 53000,
          },
          530115: {
            name: {
              th: 'หาดงิ้ว',
              en: 'Hat Ngio',
            },
            coordinates: {
              lat: '17.681',
              lng: '100.337',
            },
            zipcode: 53000,
          },
          530116: {
            name: {
              th: 'ขุนฝาง',
              en: 'Khun Fang',
            },
            coordinates: {
              lat: '17.760',
              lng: '100.223',
            },
            zipcode: 53000,
          },
          530117: {
            name: {
              th: 'ถ้ำฉลอง',
              en: 'Tham Chalong',
            },
            coordinates: {
              lat: '17.606',
              lng: '100.360',
            },
            zipcode: 53000,
          },
        },
      },
      5302: {
        name: {
          th: 'ตรอน',
          en: 'Tron',
        },
        tambons: {
          530201: {
            name: {
              th: 'วังแดง',
              en: 'Wang Daeng',
            },
            coordinates: {
              lat: '17.488',
              lng: '100.113',
            },
            zipcode: 53140,
          },
          530202: {
            name: {
              th: 'บ้านแก่ง',
              en: 'Ban Kaeng',
            },
            coordinates: {
              lat: '17.441',
              lng: '100.158',
            },
            zipcode: 53140,
          },
          530203: {
            name: {
              th: 'หาดสองแคว',
              en: 'Hat Song Khwae',
            },
            coordinates: {
              lat: '17.426',
              lng: '100.115',
            },
            zipcode: 53140,
          },
          530204: {
            name: {
              th: 'น้ำอ่าง',
              en: 'Nam Ang',
            },
            coordinates: {
              lat: '17.456',
              lng: '100.215',
            },
            zipcode: 53140,
          },
          530205: {
            name: {
              th: 'ข่อยสูง',
              en: 'Khoi Sung',
            },
            coordinates: {
              lat: '17.502',
              lng: '99.976',
            },
            zipcode: 53140,
          },
        },
      },
      5303: {
        name: {
          th: 'ท่าปลา',
          en: 'Tha Pla',
        },
        tambons: {
          530301: {
            name: {
              th: 'ท่าปลา',
              en: 'Tha Pla',
            },
            coordinates: {
              lat: '17.845',
              lng: '100.448',
            },
            zipcode: 53150,
          },
          530302: {
            name: {
              th: 'หาดล้า',
              en: 'Hat La',
            },
            coordinates: {
              lat: '17.760',
              lng: '100.333',
            },
            zipcode: 53190,
          },
          530303: {
            name: {
              th: 'ผาเลือด',
              en: 'Pha Lueat',
            },
            coordinates: {
              lat: '17.749',
              lng: '100.491',
            },
            zipcode: 53190,
          },
          530304: {
            name: {
              th: 'จริม',
              en: 'Charim',
            },
            coordinates: {
              lat: '17.834',
              lng: '100.353',
            },
            zipcode: 53150,
          },
          530305: {
            name: {
              th: 'น้ำหมัน',
              en: 'Nam Man',
            },
            coordinates: {
              lat: '17.862',
              lng: '100.250',
            },
            zipcode: 53150,
          },
          530306: {
            name: {
              th: 'ท่าแฝก',
              en: 'Tha Faek',
            },
            coordinates: {
              lat: '17.920',
              lng: '100.615',
            },
            zipcode: 53110,
          },
          530307: {
            name: {
              th: 'นางพญา',
              en: 'Nang Phaya',
            },
            coordinates: {
              lat: '17.970',
              lng: '100.441',
            },
            zipcode: 53150,
          },
          530308: {
            name: {
              th: 'ร่วมจิต',
              en: 'Ruam Chit',
            },
            coordinates: {
              lat: '17.718',
              lng: '100.329',
            },
            zipcode: 53190,
          },
        },
      },
      5304: {
        name: {
          th: 'น้ำปาด',
          en: 'Nam Pat',
        },
        tambons: {
          530401: {
            name: {
              th: 'แสนตอ',
              en: 'Saen To',
            },
            coordinates: {
              lat: '17.720',
              lng: '100.646',
            },
            zipcode: null,
          },
          530402: {
            name: {
              th: 'บ้านฝาย',
              en: 'Ban Fai',
            },
            coordinates: {
              lat: '17.740',
              lng: '100.758',
            },
            zipcode: 53110,
          },
          530403: {
            name: {
              th: 'เด่นเหล็ก',
              en: 'Den Lek',
            },
            coordinates: {
              lat: '17.820',
              lng: '100.788',
            },
            zipcode: 53110,
          },
          530404: {
            name: {
              th: 'น้ำไคร้',
              en: 'Nam Khrai',
            },
            coordinates: {
              lat: '17.608',
              lng: '100.542',
            },
            zipcode: 53110,
          },
          530405: {
            name: {
              th: 'น้ำไฝ',
              en: 'Nam Phai',
            },
            coordinates: {
              lat: '17.602',
              lng: '100.705',
            },
            zipcode: 53110,
          },
          530406: {
            name: {
              th: 'ห้วยมุ่น',
              en: 'Huai Mun',
            },
            coordinates: {
              lat: '17.794',
              lng: '100.935',
            },
            zipcode: 53110,
          },
        },
      },
      5305: {
        name: {
          th: 'ฟากท่า',
          en: 'Fak Tha',
        },
        tambons: {
          530501: {
            name: {
              th: 'ฟากท่า',
              en: 'Fak Tha',
            },
            coordinates: {
              lat: '18.068',
              lng: '100.937',
            },
            zipcode: 53160,
          },
          530502: {
            name: {
              th: 'สองคอน',
              en: 'Song Khon',
            },
            coordinates: {
              lat: '17.955',
              lng: '100.906',
            },
            zipcode: 53160,
          },
          530503: {
            name: {
              th: 'บ้านเสี้ยว',
              en: 'Ban Siao',
            },
            coordinates: {
              lat: '17.914',
              lng: '100.848',
            },
            zipcode: 53160,
          },
          530504: {
            name: {
              th: 'สองห้อง',
              en: 'Song Hong',
            },
            coordinates: {
              lat: '17.930',
              lng: '100.757',
            },
            zipcode: 53160,
          },
        },
      },
      5306: {
        name: {
          th: 'บ้านโคก',
          en: 'Ban Khok',
        },
        tambons: {
          530601: {
            name: {
              th: 'ม่วงเจ็ดต้น',
              en: 'Muang Chet Ton',
            },
            coordinates: {
              lat: '18.115',
              lng: '101.062',
            },
            zipcode: 53180,
          },
          530602: {
            name: {
              th: 'บ้านโคก',
              en: 'Ban Khok',
            },
            coordinates: {
              lat: '18.008',
              lng: '101.084',
            },
            zipcode: 53180,
          },
          530603: {
            name: {
              th: 'นาขุม',
              en: 'Na Khum',
            },
            coordinates: {
              lat: '17.951',
              lng: '100.998',
            },
            zipcode: 53180,
          },
          530604: {
            name: {
              th: 'บ่อเบี้ย',
              en: 'Bo Bia',
            },
            coordinates: {
              lat: '18.253',
              lng: '101.044',
            },
            zipcode: 53180,
          },
        },
      },
      5307: {
        name: {
          th: 'พิชัย',
          en: 'Phichai',
        },
        tambons: {
          530701: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '17.301',
              lng: '100.107',
            },
            zipcode: 53120,
          },
          530702: {
            name: {
              th: 'บ้านดารา',
              en: 'Ban Dara',
            },
            coordinates: {
              lat: '17.370',
              lng: '100.130',
            },
            zipcode: 53220,
          },
          530703: {
            name: {
              th: 'ไร่อ้อย',
              en: 'Rai Oi',
            },
            coordinates: {
              lat: '17.358',
              lng: '100.027',
            },
            zipcode: 53120,
          },
          530704: {
            name: {
              th: 'ท่าสัก',
              en: 'Tha Sak',
            },
            coordinates: {
              lat: '17.379',
              lng: '100.178',
            },
            zipcode: 53220,
          },
          530705: {
            name: {
              th: 'คอรุม',
              en: 'Kho Rum',
            },
            coordinates: {
              lat: '17.292',
              lng: '100.031',
            },
            zipcode: 53120,
          },
          530706: {
            name: {
              th: 'บ้านหม้อ',
              en: 'Ban Mo',
            },
            coordinates: {
              lat: '17.256',
              lng: '100.123',
            },
            zipcode: 53120,
          },
          530707: {
            name: {
              th: 'ท่ามะเฟือง',
              en: 'Tha Mafueang',
            },
            coordinates: {
              lat: '17.238',
              lng: '100.021',
            },
            zipcode: 53120,
          },
          530708: {
            name: {
              th: 'บ้านโคน',
              en: 'Ban Khon',
            },
            coordinates: {
              lat: '17.208',
              lng: '100.138',
            },
            zipcode: 53120,
          },
          530709: {
            name: {
              th: 'พญาแมน',
              en: 'Phaya Maen',
            },
            coordinates: {
              lat: '17.187',
              lng: '100.016',
            },
            zipcode: 53120,
          },
          530710: {
            name: {
              th: 'นาอิน',
              en: 'Na In',
            },
            coordinates: {
              lat: '17.245',
              lng: '100.193',
            },
            zipcode: 53120,
          },
          530711: {
            name: {
              th: 'นายาง',
              en: 'Na Yang',
            },
            coordinates: {
              lat: '17.318',
              lng: '100.210',
            },
            zipcode: 53120,
          },
        },
      },
      5308: {
        name: {
          th: 'ลับแล',
          en: 'Laplae',
        },
        tambons: {
          530801: {
            name: {
              th: 'ศรีพนมมาศ',
              en: 'Si Phanom Mat',
            },
            coordinates: {
              lat: '17.652',
              lng: '100.039',
            },
            zipcode: 53130,
          },
          530802: {
            name: {
              th: 'แม่พูล',
              en: 'Mae Phun',
            },
            coordinates: {
              lat: '17.746',
              lng: '99.968',
            },
            zipcode: 53130,
          },
          530803: {
            name: {
              th: 'นานกกก',
              en: 'Na Nok Kok',
            },
            coordinates: {
              lat: '17.754',
              lng: '100.072',
            },
            zipcode: 53130,
          },
          530804: {
            name: {
              th: 'ฝายหลวง',
              en: 'Fai Luang',
            },
            coordinates: {
              lat: '17.651',
              lng: '99.997',
            },
            zipcode: 53130,
          },
          530805: {
            name: {
              th: 'ชัยจุมพล',
              en: 'Chai Chumphon',
            },
            coordinates: {
              lat: '17.631',
              lng: '100.037',
            },
            zipcode: 53130,
          },
          530806: {
            name: {
              th: 'ไผ่ล้อม',
              en: 'Phai Lom',
            },
            coordinates: {
              lat: '17.529',
              lng: '100.035',
            },
            zipcode: 53210,
          },
          530807: {
            name: {
              th: 'ทุ่งยั้ง',
              en: 'Thung Yang',
            },
            coordinates: {
              lat: '17.580',
              lng: '100.015',
            },
            zipcode: 53210,
          },
          530808: {
            name: {
              th: 'ด่านแม่คำมัน',
              en: 'Dan Mae Kham Man',
            },
            coordinates: {
              lat: '17.547',
              lng: '99.963',
            },
            zipcode: 53210,
          },
        },
      },
      5309: {
        name: {
          th: 'ทองแสนขัน',
          en: 'Thong Saen Khan',
        },
        tambons: {
          530901: {
            name: {
              th: 'ผักขวง',
              en: 'Phak Khuang',
            },
            coordinates: {
              lat: '17.535',
              lng: '100.415',
            },
            zipcode: null,
          },
          530902: {
            name: {
              th: 'บ่อทอง',
              en: 'Bo Thong',
            },
            coordinates: {
              lat: '17.444',
              lng: '100.356',
            },
            zipcode: null,
          },
          530903: {
            name: {
              th: 'ป่าคาย',
              en: 'Pa Khai',
            },
            coordinates: {
              lat: '17.495',
              lng: '100.267',
            },
            zipcode: null,
          },
          530904: {
            name: {
              th: 'น้ำพี้',
              en: 'Namphi',
            },
            coordinates: {
              lat: '17.581',
              lng: '100.299',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  54: {
    name: {
      th: 'แพร่',
      en: 'Phrae',
    },
    amphoes: {
      5401: {
        name: {
          th: 'เมืองแพร่',
          en: 'Mueang Phrae',
        },
        tambons: {
          540101: {
            name: {
              th: 'ในเวียง',
              en: 'Nai Wiang',
            },
            coordinates: {
              lat: '18.140',
              lng: '100.142',
            },
            zipcode: 54000,
          },
          540102: {
            name: {
              th: 'นาจักร',
              en: 'Na Chak',
            },
            coordinates: {
              lat: '18.113',
              lng: '100.164',
            },
            zipcode: 54000,
          },
          540103: {
            name: {
              th: 'นาชำ',
              en: 'Nam Cham',
            },
            coordinates: {
              lat: '18.186',
              lng: '100.255',
            },
            zipcode: 54000,
          },
          540104: {
            name: {
              th: 'ป่าแดง',
              en: 'Pa Daeng',
            },
            coordinates: {
              lat: '18.063',
              lng: '100.316',
            },
            zipcode: 54000,
          },
          540105: {
            name: {
              th: 'ทุ่งโฮ้ง',
              en: 'Thung Hong',
            },
            coordinates: {
              lat: '18.188',
              lng: '100.196',
            },
            zipcode: 54000,
          },
          540106: {
            name: {
              th: 'เหมืองหม้อ',
              en: 'Mueang Mo',
            },
            coordinates: {
              lat: '18.163',
              lng: '100.210',
            },
            zipcode: 54000,
          },
          540107: {
            name: {
              th: 'วังธง',
              en: 'Wang Thong',
            },
            coordinates: {
              lat: '18.209',
              lng: '100.110',
            },
            zipcode: 54000,
          },
          540108: {
            name: {
              th: 'แม่หล่าย',
              en: 'Mae Lai',
            },
            coordinates: {
              lat: '18.215',
              lng: '100.204',
            },
            zipcode: 54000,
          },
          540109: {
            name: {
              th: 'ห้วยม้า',
              en: 'Huai Ma',
            },
            coordinates: {
              lat: '18.228',
              lng: '100.237',
            },
            zipcode: 54000,
          },
          540110: {
            name: {
              th: 'ป่าแมต',
              en: 'Pa Maet',
            },
            coordinates: {
              lat: '18.158',
              lng: '100.092',
            },
            zipcode: 54000,
          },
          540111: {
            name: {
              th: 'บ้านถิ่น',
              en: 'Ban Thin',
            },
            coordinates: {
              lat: '18.154',
              lng: '100.224',
            },
            zipcode: 54000,
          },
          540112: {
            name: {
              th: 'สวนเขื่อน',
              en: 'Suan Khuean',
            },
            coordinates: {
              lat: '18.128',
              lng: '100.306',
            },
            zipcode: 54000,
          },
          540113: {
            name: {
              th: 'วังหงษ์',
              en: 'Wang Hong',
            },
            coordinates: {
              lat: '18.246',
              lng: '100.139',
            },
            zipcode: null,
          },
          540114: {
            name: {
              th: 'แม่คำมี',
              en: 'Mae Kham Mi',
            },
            coordinates: {
              lat: '18.247',
              lng: '100.217',
            },
            zipcode: 54170,
          },
          540115: {
            name: {
              th: 'ทุ่งกวาว',
              en: 'Thung Kwao',
            },
            coordinates: {
              lat: '18.165',
              lng: '100.154',
            },
            zipcode: 54000,
          },
          540116: {
            name: {
              th: 'ท่าข้าม',
              en: 'Tha Kham',
            },
            coordinates: {
              lat: '18.229',
              lng: '100.126',
            },
            zipcode: 54000,
          },
          540117: {
            name: {
              th: 'แม่ยม',
              en: 'Mae Yom',
            },
            coordinates: {
              lat: '18.187',
              lng: '100.157',
            },
            zipcode: 54000,
          },
          540118: {
            name: {
              th: 'ช่อแฮ',
              en: 'Cho Hae',
            },
            coordinates: {
              lat: '17.954',
              lng: '100.323',
            },
            zipcode: 54000,
          },
          540119: {
            name: {
              th: 'ร่องฟอง',
              en: 'Rong Fong',
            },
            coordinates: {
              lat: '18.167',
              lng: '100.193',
            },
            zipcode: 54000,
          },
          540120: {
            name: {
              th: 'กาญจนา',
              en: 'Kanchana',
            },
            coordinates: {
              lat: '18.123',
              lng: '100.184',
            },
            zipcode: 54000,
          },
        },
      },
      5402: {
        name: {
          th: 'ร้องกวาง',
          en: 'Mueang Phrae',
        },
        tambons: {
          540201: {
            name: {
              th: 'ร้องกวาง',
              en: 'Rong Kwang',
            },
            coordinates: {
              lat: '18.351',
              lng: '100.336',
            },
            zipcode: 54140,
          },
          540204: {
            name: {
              th: 'ร้องเข็ม',
              en: 'Si Narong',
            },
            coordinates: {
              lat: '18.293',
              lng: '100.313',
            },
            zipcode: 54140,
          },
          540205: {
            name: {
              th: 'น้ำเลา',
              en: 'Nam Lao',
            },
            coordinates: {
              lat: '18.254',
              lng: '100.309',
            },
            zipcode: 54140,
          },
          540206: {
            name: {
              th: 'บ้านเวียง',
              en: 'Ban Wiang',
            },
            coordinates: {
              lat: '18.208',
              lng: '100.381',
            },
            zipcode: 54140,
          },
          540207: {
            name: {
              th: 'ทุ่งศรี',
              en: 'Rong Khem',
            },
            coordinates: {
              lat: '18.316',
              lng: '100.340',
            },
            zipcode: 54140,
          },
          540208: {
            name: {
              th: 'แม่ยางตาล',
              en: 'Mae Yang Tan',
            },
            coordinates: {
              lat: '18.304',
              lng: '100.257',
            },
            zipcode: 54140,
          },
          540209: {
            name: {
              th: 'แม่ยางฮ่อ',
              en: 'Mae Yang Ho',
            },
            coordinates: {
              lat: '18.350',
              lng: '100.260',
            },
            zipcode: 54140,
          },
          540210: {
            name: {
              th: 'ไผ่โทน',
              en: 'Phai Thon',
            },
            coordinates: {
              lat: '18.320',
              lng: '100.464',
            },
            zipcode: 54140,
          },
          540213: {
            name: {
              th: 'ห้วยโรง',
              en: 'Huai Rong',
            },
            coordinates: {
              lat: '18.432',
              lng: '100.513',
            },
            zipcode: 54140,
          },
          540214: {
            name: {
              th: 'แม่ทราย',
              en: 'Mae Sai',
            },
            coordinates: {
              lat: '18.411',
              lng: '100.314',
            },
            zipcode: 54140,
          },
          540215: {
            name: {
              th: 'แม่ยางร้อง',
              en: 'Mae Yang Rong',
            },
            coordinates: {
              lat: '18.396',
              lng: '100.248',
            },
            zipcode: 54140,
          },
        },
      },
      5403: {
        name: {
          th: 'ลอง',
          en: 'Long',
        },
        tambons: {
          540301: {
            name: {
              th: 'ห้วยอ้อ',
              en: 'Huai O',
            },
            coordinates: {
              lat: '18.131',
              lng: '99.825',
            },
            zipcode: 54150,
          },
          540302: {
            name: {
              th: 'บ้านปิน',
              en: 'Ban Pin',
            },
            coordinates: {
              lat: '18.065',
              lng: '99.937',
            },
            zipcode: 54150,
          },
          540303: {
            name: {
              th: 'ต้าผามอก',
              en: 'Ta Pha Mok',
            },
            coordinates: {
              lat: '18.151',
              lng: '99.983',
            },
            zipcode: 54150,
          },
          540304: {
            name: {
              th: 'เวียงต้า',
              en: 'Wiang Ta',
            },
            coordinates: {
              lat: '18.303',
              lng: '99.999',
            },
            zipcode: 54150,
          },
          540305: {
            name: {
              th: 'ปากกาง',
              en: 'Pak Kang',
            },
            coordinates: {
              lat: '18.042',
              lng: '99.811',
            },
            zipcode: 54150,
          },
          540306: {
            name: {
              th: 'หัวทุ่ง',
              en: 'Hua Thung',
            },
            coordinates: {
              lat: '18.093',
              lng: '99.776',
            },
            zipcode: 54150,
          },
          540307: {
            name: {
              th: 'ทุ่งแล้ง',
              en: 'Thung Laeng',
            },
            coordinates: {
              lat: '17.959',
              lng: '99.752',
            },
            zipcode: 54150,
          },
          540308: {
            name: {
              th: 'บ่อเหล็กลอง',
              en: 'Bo Lek Long',
            },
            coordinates: {
              lat: '18.013',
              lng: '99.719',
            },
            zipcode: 54150,
          },
          540309: {
            name: {
              th: 'แม่ปาน',
              en: 'Mae Pan',
            },
            coordinates: {
              lat: '17.986',
              lng: '99.871',
            },
            zipcode: 54150,
          },
        },
      },
      5404: {
        name: {
          th: 'สูงเม่น',
          en: 'Sung Men',
        },
        tambons: {
          540401: {
            name: {
              th: 'สูงเม่น',
              en: 'Sung Men',
            },
            coordinates: {
              lat: '18.059',
              lng: '100.087',
            },
            zipcode: 54130,
          },
          540402: {
            name: {
              th: 'น้ำชำ',
              en: 'Nam Cham',
            },
            coordinates: {
              lat: '18.034',
              lng: '100.091',
            },
            zipcode: null,
          },
          540403: {
            name: {
              th: 'หัวฝาย',
              en: 'Hua Fai',
            },
            coordinates: {
              lat: '17.961',
              lng: '100.188',
            },
            zipcode: 54130,
          },
          540404: {
            name: {
              th: 'ดอนมูล',
              en: 'Don Mun',
            },
            coordinates: {
              lat: '18.067',
              lng: '100.126',
            },
            zipcode: 54130,
          },
          540405: {
            name: {
              th: 'บ้านเหล่า',
              en: 'Ban Lao',
            },
            coordinates: {
              lat: '18.015',
              lng: '100.206',
            },
            zipcode: 54130,
          },
          540406: {
            name: {
              th: 'บ้านกวาง',
              en: 'Ban Kwang',
            },
            coordinates: {
              lat: '18.033',
              lng: '100.222',
            },
            zipcode: 54130,
          },
          540407: {
            name: {
              th: 'บ้านปง',
              en: 'Ban Pong',
            },
            coordinates: {
              lat: '18.060',
              lng: '100.026',
            },
            zipcode: 54130,
          },
          540408: {
            name: {
              th: 'บ้านกาศ',
              en: 'Ban Kat',
            },
            coordinates: {
              lat: '18.090',
              lng: '100.168',
            },
            zipcode: 54130,
          },
          540409: {
            name: {
              th: 'ร่องกาศ',
              en: 'Rong Kat',
            },
            coordinates: {
              lat: '18.096',
              lng: '100.126',
            },
            zipcode: 54130,
          },
          540410: {
            name: {
              th: 'สบสาย',
              en: 'Sop Sai',
            },
            coordinates: {
              lat: '18.102',
              lng: '100.023',
            },
            zipcode: 54130,
          },
          540411: {
            name: {
              th: 'เวียงทอง',
              en: 'Wiang Thong',
            },
            coordinates: {
              lat: '18.124',
              lng: '100.046',
            },
            zipcode: 54000,
          },
          540412: {
            name: {
              th: 'พระหลวง',
              en: 'Phra Luang',
            },
            coordinates: {
              lat: '18.069',
              lng: '100.111',
            },
            zipcode: 54130,
          },
        },
      },
      5405: {
        name: {
          th: 'เด่นชัย',
          en: 'Den Chai',
        },
        tambons: {
          540501: {
            name: {
              th: 'เด่นชัย',
              en: 'Den Chai',
            },
            coordinates: {
              lat: '17.973',
              lng: '100.040',
            },
            zipcode: 54110,
          },
          540502: {
            name: {
              th: 'แม่จั๊วะ',
              en: 'Mae Chua',
            },
            coordinates: {
              lat: '17.975',
              lng: '100.104',
            },
            zipcode: 54110,
          },
          540503: {
            name: {
              th: 'ไทรย้อย',
              en: 'Sai Yoi',
            },
            coordinates: {
              lat: '17.892',
              lng: '99.956',
            },
            zipcode: 54110,
          },
          540504: {
            name: {
              th: 'ห้วยไร่',
              en: 'Huai Rai',
            },
            coordinates: {
              lat: '17.898',
              lng: '100.090',
            },
            zipcode: 54110,
          },
          540505: {
            name: {
              th: 'ปงป่าหวาย',
              en: 'Pong Pa Wai',
            },
            coordinates: {
              lat: '18.024',
              lng: '99.991',
            },
            zipcode: 54110,
          },
        },
      },
      5406: {
        name: {
          th: 'สอง',
          en: 'Song',
        },
        tambons: {
          540601: {
            name: {
              th: 'บ้านหนุน',
              en: 'Ban Nun',
            },
            coordinates: {
              lat: '18.437',
              lng: '100.234',
            },
            zipcode: 54120,
          },
          540602: {
            name: {
              th: 'บ้านกลาง',
              en: 'Ban Klang',
            },
            coordinates: {
              lat: '18.485',
              lng: '100.183',
            },
            zipcode: 54120,
          },
          540603: {
            name: {
              th: 'ห้วยหม้าย',
              en: 'Huai Mai',
            },
            coordinates: {
              lat: '18.418',
              lng: '100.089',
            },
            zipcode: 54120,
          },
          540604: {
            name: {
              th: 'เตาปูน',
              en: 'Tao Pun',
            },
            coordinates: {
              lat: '18.531',
              lng: '100.271',
            },
            zipcode: 54120,
          },
          540605: {
            name: {
              th: 'หัวเมือง',
              en: 'Hua Mueang',
            },
            coordinates: {
              lat: '18.333',
              lng: '100.181',
            },
            zipcode: 54120,
          },
          540606: {
            name: {
              th: 'สะเอียบ',
              en: 'Sa-Iap',
            },
            coordinates: {
              lat: '18.709',
              lng: '100.247',
            },
            zipcode: 54120,
          },
          540607: {
            name: {
              th: 'แดนชุมพล',
              en: 'Daen Chumphon',
            },
            coordinates: {
              lat: '18.365',
              lng: '100.198',
            },
            zipcode: 54120,
          },
          540608: {
            name: {
              th: 'ทุ่งน้าว',
              en: 'Thung Nao',
            },
            coordinates: {
              lat: '18.402',
              lng: '100.200',
            },
            zipcode: 54120,
          },
        },
      },
      5407: {
        name: {
          th: 'วังชิ้น',
          en: 'Wang Chin',
        },
        tambons: {
          540701: {
            name: {
              th: 'วังชิ้น',
              en: 'Wang Chin',
            },
            coordinates: {
              lat: '17.861',
              lng: '99.647',
            },
            zipcode: 54160,
          },
          540702: {
            name: {
              th: 'สรอย',
              en: 'Soi',
            },
            coordinates: {
              lat: '17.759',
              lng: '99.425',
            },
            zipcode: 54160,
          },
          540703: {
            name: {
              th: 'แม่ป้าก',
              en: 'Mae Pak',
            },
            coordinates: {
              lat: '18.005',
              lng: '99.638',
            },
            zipcode: 54160,
          },
          540704: {
            name: {
              th: 'นาพูน',
              en: 'Na Phun',
            },
            coordinates: {
              lat: '17.850',
              lng: '99.790',
            },
            zipcode: 54160,
          },
          540705: {
            name: {
              th: 'แม่พุง',
              en: 'Mae Phung',
            },
            coordinates: {
              lat: '17.866',
              lng: '99.539',
            },
            zipcode: 54160,
          },
          540706: {
            name: {
              th: 'ป่าสัก',
              en: 'Pa Sak',
            },
            coordinates: {
              lat: '17.826',
              lng: '99.472',
            },
            zipcode: 54160,
          },
          540707: {
            name: {
              th: 'แม่เกิ๋ง',
              en: 'Mae Koeng',
            },
            coordinates: {
              lat: '17.945',
              lng: '99.556',
            },
            zipcode: 54160,
          },
        },
      },
      5408: {
        name: {
          th: 'หนองม่วงไข่',
          en: 'Nong Muang Khai',
        },
        tambons: {
          540801: {
            name: {
              th: 'แม่คำมี',
              en: 'Mae Kham Mi',
            },
            coordinates: {
              lat: '18.271',
              lng: '100.218',
            },
            zipcode: null,
          },
          540802: {
            name: {
              th: 'หนองม่วงไข่',
              en: 'Nong Muang Khai',
            },
            coordinates: {
              lat: '18.295',
              lng: '100.208',
            },
            zipcode: null,
          },
          540803: {
            name: {
              th: 'น้ำรัด',
              en: 'Nam Rat',
            },
            coordinates: {
              lat: '18.268',
              lng: '100.138',
            },
            zipcode: null,
          },
          540804: {
            name: {
              th: 'วังหลวง',
              en: 'Wang Luang',
            },
            coordinates: {
              lat: '18.294',
              lng: '100.115',
            },
            zipcode: null,
          },
          540805: {
            name: {
              th: 'ตำหนักธรรม',
              en: 'Tamnak Tham',
            },
            coordinates: {
              lat: '18.271',
              lng: '100.266',
            },
            zipcode: 54170,
          },
          540806: {
            name: {
              th: 'ทุ่งแค้ว',
              en: 'Thung Khaeo',
            },
            coordinates: {
              lat: '18.320',
              lng: '100.120',
            },
            zipcode: 54170,
          },
        },
      },
    },
  },
  55: {
    name: {
      th: 'น่าน',
      en: 'Nan',
    },
    amphoes: {
      5501: {
        name: {
          th: 'เมืองน่าน',
          en: 'Mueang Nan',
        },
        tambons: {
          550101: {
            name: {
              th: 'ในเวียง',
              en: 'Nai Wiang',
            },
            coordinates: {
              lat: '18.793',
              lng: '100.786',
            },
            zipcode: 55000,
          },
          550102: {
            name: {
              th: 'บ่อ',
              en: 'Bo',
            },
            coordinates: {
              lat: '18.970',
              lng: '100.721',
            },
            zipcode: 55000,
          },
          550103: {
            name: {
              th: 'ผาสิงห์',
              en: 'Pha Sing',
            },
            coordinates: {
              lat: '18.850',
              lng: '100.749',
            },
            zipcode: 55000,
          },
          550104: {
            name: {
              th: 'ไชยสถาน',
              en: 'Chaiya Sathan',
            },
            coordinates: {
              lat: '18.783',
              lng: '100.731',
            },
            zipcode: 55000,
          },
          550105: {
            name: {
              th: 'ถืมตอง',
              en: 'Thuem Thong',
            },
            coordinates: {
              lat: '18.797',
              lng: '100.712',
            },
            zipcode: 55000,
          },
          550106: {
            name: {
              th: 'เรือง',
              en: 'Rueang',
            },
            coordinates: {
              lat: '18.783',
              lng: '100.615',
            },
            zipcode: 55000,
          },
          550107: {
            name: {
              th: 'นาชาว',
              en: 'Na Sao',
            },
            coordinates: {
              lat: '18.708',
              lng: '100.682',
            },
            zipcode: 55000,
          },
          550108: {
            name: {
              th: 'ดู่ใต้',
              en: 'Du Tai',
            },
            coordinates: {
              lat: '18.724',
              lng: '100.739',
            },
            zipcode: 55000,
          },
          550109: {
            name: {
              th: 'กองควาย',
              en: 'Kong Khwai',
            },
            coordinates: {
              lat: '18.686',
              lng: '100.732',
            },
            zipcode: 55000,
          },
          550116: {
            name: {
              th: 'สวก',
              en: 'Suak',
            },
            coordinates: {
              lat: '18.742',
              lng: '100.624',
            },
            zipcode: 55000,
          },
          550117: {
            name: {
              th: 'สะเนียน',
              en: 'Sanian',
            },
            coordinates: {
              lat: '18.912',
              lng: '100.592',
            },
            zipcode: 55000,
          },
        },
      },
      5502: {
        name: {
          th: 'แม่จริม',
          en: 'Mae Charim',
        },
        tambons: {
          550202: {
            name: {
              th: 'หนองแดง',
              en: 'Nong Daeng',
            },
            coordinates: {
              lat: '18.736',
              lng: '101.025',
            },
            zipcode: 55170,
          },
          550203: {
            name: {
              th: 'หมอเมือง',
              en: 'Mo Mueang',
            },
            coordinates: {
              lat: '18.687',
              lng: '100.975',
            },
            zipcode: null,
          },
          550204: {
            name: {
              th: 'น้ำพาง',
              en: 'Nam Phang',
            },
            coordinates: {
              lat: '18.611',
              lng: '101.057',
            },
            zipcode: 55170,
          },
          550205: {
            name: {
              th: 'น้ำปาย',
              en: 'Nam Pai',
            },
            coordinates: {
              lat: '18.641',
              lng: '100.961',
            },
            zipcode: 55170,
          },
          550206: {
            name: {
              th: 'แม่จริม',
              en: 'Mae Charim',
            },
            coordinates: {
              lat: '18.824',
              lng: '101.045',
            },
            zipcode: 55170,
          },
        },
      },
      5503: {
        name: {
          th: 'บ้านหลวง',
          en: 'Ban Luang',
        },
        tambons: {
          550301: {
            name: {
              th: 'บ้านฟ้า',
              en: 'Ban Fa',
            },
            coordinates: {
              lat: '18.782',
              lng: '100.429',
            },
            zipcode: null,
          },
          550302: {
            name: {
              th: 'ป่าคาหลวง',
              en: 'Pa Kha Luang',
            },
            coordinates: {
              lat: '18.858',
              lng: '100.405',
            },
            zipcode: null,
          },
          550303: {
            name: {
              th: 'สวด',
              en: 'Suat',
            },
            coordinates: {
              lat: '18.849',
              lng: '100.484',
            },
            zipcode: 55190,
          },
          550304: {
            name: {
              th: 'บ้านพี้',
              en: 'Ban Phi',
            },
            coordinates: {
              lat: '18.907',
              lng: '100.425',
            },
            zipcode: 55190,
          },
        },
      },
      5504: {
        name: {
          th: 'นาน้อย',
          en: 'Na Noi',
        },
        tambons: {
          550401: {
            name: {
              th: 'นาน้อย',
              en: 'Na Noi',
            },
            coordinates: {
              lat: '18.313',
              lng: '100.681',
            },
            zipcode: 55150,
          },
          550402: {
            name: {
              th: 'เชียงของ',
              en: 'Chiang Khong',
            },
            coordinates: {
              lat: '18.273',
              lng: '100.884',
            },
            zipcode: 55150,
          },
          550403: {
            name: {
              th: 'ศรีษะเกษ',
              en: 'Sisa Ket',
            },
            coordinates: {
              lat: '18.369',
              lng: '100.771',
            },
            zipcode: 55150,
          },
          550404: {
            name: {
              th: 'สถาน',
              en: 'Sathan',
            },
            coordinates: {
              lat: '18.236',
              lng: '100.745',
            },
            zipcode: 55120,
          },
          550405: {
            name: {
              th: 'สันทะ',
              en: 'Santha',
            },
            coordinates: {
              lat: '18.276',
              lng: '100.567',
            },
            zipcode: 55150,
          },
          550406: {
            name: {
              th: 'บัวใหญ่',
              en: 'Bua Yai',
            },
            coordinates: {
              lat: '18.350',
              lng: '100.570',
            },
            zipcode: 55150,
          },
          550407: {
            name: {
              th: 'น้ำตก',
              en: 'Namtok',
            },
            coordinates: {
              lat: '18.394',
              lng: '100.629',
            },
            zipcode: 55150,
          },
        },
      },
      5505: {
        name: {
          th: 'ปัว',
          en: 'Pua',
        },
        tambons: {
          550501: {
            name: {
              th: 'ปัว',
              en: 'Pua',
            },
            coordinates: {
              lat: '19.168',
              lng: '100.903',
            },
            zipcode: 55120,
          },
          550502: {
            name: {
              th: 'แงง',
              en: 'Ngaeng',
            },
            coordinates: {
              lat: '19.197',
              lng: '100.849',
            },
            zipcode: 55120,
          },
          550503: {
            name: {
              th: 'สถาน',
              en: 'Sathan',
            },
            coordinates: {
              lat: '19.224',
              lng: '100.951',
            },
            zipcode: null,
          },
          550504: {
            name: {
              th: 'ศิลาแลง',
              en: 'Sila Laeng',
            },
            coordinates: {
              lat: '19.148',
              lng: '100.967',
            },
            zipcode: 55120,
          },
          550505: {
            name: {
              th: 'ศิลาเพชร',
              en: 'Sila Phet',
            },
            coordinates: {
              lat: '19.097',
              lng: '100.957',
            },
            zipcode: 55120,
          },
          550506: {
            name: {
              th: 'อวน',
              en: 'Ouan',
            },
            coordinates: {
              lat: '19.029',
              lng: '100.998',
            },
            zipcode: 55120,
          },
          550509: {
            name: {
              th: 'ไชยวัฒนา',
              en: 'Chai Watthana',
            },
            coordinates: {
              lat: '19.222',
              lng: '100.895',
            },
            zipcode: 55120,
          },
          550510: {
            name: {
              th: 'เจดียชัย',
              en: 'Chedi Chai',
            },
            coordinates: {
              lat: '19.162',
              lng: '100.838',
            },
            zipcode: 55120,
          },
          550511: {
            name: {
              th: 'ภูคา',
              en: 'Phu Kha',
            },
            coordinates: {
              lat: '19.254',
              lng: '101.091',
            },
            zipcode: 55120,
          },
          550512: {
            name: {
              th: 'สกาด',
              en: 'Sakat',
            },
            coordinates: {
              lat: '19.270',
              lng: '101.031',
            },
            zipcode: 55120,
          },
          550513: {
            name: {
              th: 'ป่ากลาง',
              en: 'Pa Klang',
            },
            coordinates: {
              lat: '19.130',
              lng: '100.900',
            },
            zipcode: 55120,
          },
          550514: {
            name: {
              th: 'วรนคร',
              en: 'Wara Nakhon',
            },
            coordinates: {
              lat: '19.176',
              lng: '100.947',
            },
            zipcode: 55120,
          },
        },
      },
      5506: {
        name: {
          th: 'ท่าวังผา',
          en: 'Tha Wang Pha',
        },
        tambons: {
          550601: {
            name: {
              th: 'ริม',
              en: 'Rim',
            },
            coordinates: {
              lat: '19.119',
              lng: '100.798',
            },
            zipcode: 55140,
          },
          550602: {
            name: {
              th: 'ป่าคา',
              en: 'Pa Kha',
            },
            coordinates: {
              lat: '19.107',
              lng: '100.665',
            },
            zipcode: 55140,
          },
          550603: {
            name: {
              th: 'ผาตอ',
              en: 'Pha To',
            },
            coordinates: {
              lat: '19.214',
              lng: '100.774',
            },
            zipcode: 55140,
          },
          550604: {
            name: {
              th: 'ยม',
              en: 'Yom',
            },
            coordinates: {
              lat: '19.061',
              lng: '100.926',
            },
            zipcode: 55140,
          },
          550605: {
            name: {
              th: 'ตาลชุม',
              en: 'Tan Chum',
            },
            coordinates: {
              lat: '19.022',
              lng: '100.829',
            },
            zipcode: 55110,
          },
          550606: {
            name: {
              th: 'ศรีภูมิ',
              en: 'Si Phum',
            },
            coordinates: {
              lat: '19.069',
              lng: '100.726',
            },
            zipcode: 55140,
          },
          550607: {
            name: {
              th: 'จอมพระ',
              en: 'Chom Phra',
            },
            coordinates: {
              lat: '19.103',
              lng: '100.865',
            },
            zipcode: 55140,
          },
          550608: {
            name: {
              th: 'แสนทอง',
              en: 'Saen Thong',
            },
            coordinates: {
              lat: '19.140',
              lng: '100.751',
            },
            zipcode: 55140,
          },
          550609: {
            name: {
              th: 'ท่าวังผา',
              en: 'Tha Wang Pha',
            },
            coordinates: {
              lat: '19.128',
              lng: '100.831',
            },
            zipcode: 55140,
          },
          550610: {
            name: {
              th: 'ผาทอง',
              en: 'Pha Thong',
            },
            coordinates: {
              lat: '19.235',
              lng: '100.684',
            },
            zipcode: 55140,
          },
        },
      },
      5507: {
        name: {
          th: 'เวียงสา',
          en: 'Wiang Sa',
        },
        tambons: {
          550701: {
            name: {
              th: 'กลางเวียง',
              en: 'Klang Wiang',
            },
            coordinates: {
              lat: '18.577',
              lng: '100.740',
            },
            zipcode: 55110,
          },
          550702: {
            name: {
              th: 'ขึ่ง',
              en: 'Khueng',
            },
            coordinates: {
              lat: '18.530',
              lng: '100.834',
            },
            zipcode: 55110,
          },
          550703: {
            name: {
              th: 'ไหล่น่าน',
              en: 'Lai Nan',
            },
            coordinates: {
              lat: '18.590',
              lng: '100.930',
            },
            zipcode: 55110,
          },
          550704: {
            name: {
              th: 'ตาลชุม',
              en: 'Tan Chum',
            },
            coordinates: {
              lat: '18.637',
              lng: '100.783',
            },
            zipcode: null,
          },
          550705: {
            name: {
              th: 'นาเหลือง',
              en: 'Na Lueang',
            },
            coordinates: {
              lat: '18.679',
              lng: '100.837',
            },
            zipcode: 55110,
          },
          550706: {
            name: {
              th: 'ส้าน',
              en: 'San',
            },
            coordinates: {
              lat: '18.464',
              lng: '100.729',
            },
            zipcode: 55110,
          },
          550707: {
            name: {
              th: 'น้ำมวบ',
              en: 'Nam Muap',
            },
            coordinates: {
              lat: '18.444',
              lng: '100.940',
            },
            zipcode: 55110,
          },
          550708: {
            name: {
              th: 'น้ำปั้ว',
              en: 'Nam Pua',
            },
            coordinates: {
              lat: '18.639',
              lng: '100.725',
            },
            zipcode: 55110,
          },
          550709: {
            name: {
              th: 'ยาบหัวนา',
              en: 'Yap Hua Na',
            },
            coordinates: {
              lat: '18.611',
              lng: '100.471',
            },
            zipcode: 55110,
          },
          550710: {
            name: {
              th: 'ปงสนุก',
              en: 'Pong Sanuk',
            },
            coordinates: {
              lat: '18.600',
              lng: '100.648',
            },
            zipcode: 55110,
          },
          550711: {
            name: {
              th: 'อ่ายนาไลย',
              en: 'I Na Lai',
            },
            coordinates: {
              lat: '18.514',
              lng: '100.541',
            },
            zipcode: 55110,
          },
          550712: {
            name: {
              th: 'ส้านนาหนองใหม่',
              en: 'Sanna Nong Mai',
            },
            coordinates: {
              lat: '18.531',
              lng: '100.996',
            },
            zipcode: 55110,
          },
          550713: {
            name: {
              th: 'แม่ขะนิง',
              en: 'Mae Khaning',
            },
            coordinates: {
              lat: '18.718',
              lng: '100.552',
            },
            zipcode: 55110,
          },
          550714: {
            name: {
              th: 'แม่สาคร',
              en: 'Mae Sakhon',
            },
            coordinates: {
              lat: '18.474',
              lng: '100.637',
            },
            zipcode: 55110,
          },
          550715: {
            name: {
              th: 'จอมจันทร์',
              en: 'Chom Chan',
            },
            coordinates: {
              lat: '18.637',
              lng: '100.896',
            },
            zipcode: 55110,
          },
          550716: {
            name: {
              th: 'แม่สา',
              en: 'Mae Sa',
            },
            coordinates: {
              lat: '18.564',
              lng: '100.660',
            },
            zipcode: 55110,
          },
          550717: {
            name: {
              th: 'ทุ่งศรีทอง',
              en: 'Thung Si Thong',
            },
            coordinates: {
              lat: '18.661',
              lng: '100.694',
            },
            zipcode: 55110,
          },
        },
      },
      5508: {
        name: {
          th: 'ทุ่งช้าง',
          en: 'Thung Chang',
        },
        tambons: {
          550801: {
            name: {
              th: 'ปอน',
              en: 'Pon',
            },
            coordinates: {
              lat: '19.535',
              lng: '100.975',
            },
            zipcode: 55130,
          },
          550802: {
            name: {
              th: 'งอบ',
              en: 'Ngop',
            },
            coordinates: {
              lat: '19.514',
              lng: '100.849',
            },
            zipcode: 55130,
          },
          550803: {
            name: {
              th: 'และ',
              en: 'Lae',
            },
            coordinates: {
              lat: '19.447',
              lng: '100.811',
            },
            zipcode: 55130,
          },
          550804: {
            name: {
              th: 'ทุ่งช้าง',
              en: 'Thung Chang',
            },
            coordinates: {
              lat: '19.390',
              lng: '100.834',
            },
            zipcode: 55130,
          },
        },
      },
      5509: {
        name: {
          th: 'เชียงกลาง',
          en: 'Chiang Klang',
        },
        tambons: {
          550901: {
            name: {
              th: 'เชียงกลาง',
              en: 'Chiang Klang',
            },
            coordinates: {
              lat: '19.320',
              lng: '100.970',
            },
            zipcode: null,
          },
          550902: {
            name: {
              th: 'เปือ',
              en: 'Puea',
            },
            coordinates: {
              lat: '19.324',
              lng: '100.843',
            },
            zipcode: null,
          },
          550903: {
            name: {
              th: 'เชียงคาน',
              en: 'Chiang Khan',
            },
            coordinates: {
              lat: '19.246',
              lng: '100.855',
            },
            zipcode: 55160,
          },
          550904: {
            name: {
              th: 'พระธาตุ',
              en: 'Phrathat',
            },
            coordinates: {
              lat: '19.338',
              lng: '100.925',
            },
            zipcode: 55160,
          },
          550908: {
            name: {
              th: 'พญาแก้ว',
              en: 'Phaya Kaeo',
            },
            coordinates: {
              lat: '19.270',
              lng: '100.909',
            },
            zipcode: 55160,
          },
          550909: {
            name: {
              th: 'พระพุทธบาท',
              en: 'Phraphutthabat',
            },
            coordinates: {
              lat: '19.258',
              lng: '100.814',
            },
            zipcode: 55160,
          },
        },
      },
      5510: {
        name: {
          th: 'นาหมื่น',
          en: 'Na Muen',
        },
        tambons: {
          551001: {
            name: {
              th: 'นาทะนุง',
              en: 'Na Thanung',
            },
            coordinates: {
              lat: '18.117',
              lng: '100.615',
            },
            zipcode: 55180,
          },
          551002: {
            name: {
              th: 'บ่อแก้ว',
              en: 'Bo Kaeo',
            },
            coordinates: {
              lat: '18.139',
              lng: '100.832',
            },
            zipcode: 55180,
          },
          551003: {
            name: {
              th: 'เมืองลี่',
              en: 'Mueang Li',
            },
            coordinates: {
              lat: '18.226',
              lng: '100.505',
            },
            zipcode: 55180,
          },
          551004: {
            name: {
              th: 'ปิงหลวง',
              en: 'Ping Luang',
            },
            coordinates: {
              lat: '18.115',
              lng: '100.486',
            },
            zipcode: 55180,
          },
        },
      },
      5511: {
        name: {
          th: 'สันติสุข',
          en: 'Santi Suk',
        },
        tambons: {
          551101: {
            name: {
              th: 'ดู่พงษ์',
              en: 'Du Phong',
            },
            coordinates: {
              lat: '18.898',
              lng: '100.902',
            },
            zipcode: null,
          },
          551102: {
            name: {
              th: 'ป่าแลวหลวง',
              en: 'Pa Laeo Luang',
            },
            coordinates: {
              lat: '18.957',
              lng: '100.950',
            },
            zipcode: 55210,
          },
          551103: {
            name: {
              th: 'พงษ์',
              en: 'Phong',
            },
            coordinates: {
              lat: '18.899',
              lng: '100.983',
            },
            zipcode: null,
          },
        },
      },
      5512: {
        name: {
          th: 'บ่อเกลือ',
          en: 'Bo Kluea',
        },
        tambons: {
          551201: {
            name: {
              th: 'บ่อเกลือเหนือ',
              en: 'Bo Kluea Nuea',
            },
            coordinates: {
              lat: '19.297',
              lng: '101.176',
            },
            zipcode: null,
          },
          551202: {
            name: {
              th: 'บ่อเกลือใต้',
              en: 'Bo Kluea Tai',
            },
            coordinates: {
              lat: '19.127',
              lng: '101.130',
            },
            zipcode: null,
          },
          551204: {
            name: {
              th: 'ภูฟ้า',
              en: 'Phu Fa',
            },
            coordinates: {
              lat: '18.973',
              lng: '101.201',
            },
            zipcode: 55220,
          },
          551205: {
            name: {
              th: 'ดงพญา',
              en: 'Dong Phaya',
            },
            coordinates: {
              lat: '19.220',
              lng: '101.198',
            },
            zipcode: 55220,
          },
        },
      },
      5513: {
        name: {
          th: 'สองแคว',
          en: 'Song Khwae',
        },
        tambons: {
          551301: {
            name: {
              th: 'นาไร่หลวง',
              en: 'Na Rai Luang',
            },
            coordinates: {
              lat: '19.339',
              lng: '100.704',
            },
            zipcode: null,
          },
          551302: {
            name: {
              th: 'ชนแดน',
              en: 'Chon Daen',
            },
            coordinates: {
              lat: '19.462',
              lng: '100.691',
            },
            zipcode: 55160,
          },
          551303: {
            name: {
              th: 'ยอด',
              en: 'Yot',
            },
            coordinates: {
              lat: '19.384',
              lng: '100.567',
            },
            zipcode: null,
          },
        },
      },
      5514: {
        name: {
          th: 'ภูเพียง',
          en: 'Phu Phiang',
        },
        tambons: {
          551401: {
            name: {
              th: 'ม่วงตึ๊ด',
              en: 'Muang Tuet',
            },
            coordinates: {
              lat: '18.756',
              lng: '100.816',
            },
            zipcode: null,
          },
          551402: {
            name: {
              th: 'นาปัง',
              en: 'Na Pang',
            },
            coordinates: {
              lat: '18.696',
              lng: '100.801',
            },
            zipcode: null,
          },
          551403: {
            name: {
              th: 'น้ำแก่น',
              en: 'Nam Kaen',
            },
            coordinates: {
              lat: '18.698',
              lng: '100.880',
            },
            zipcode: null,
          },
          551404: {
            name: {
              th: 'น้ำเกี๋ยน',
              en: 'Nam Kian',
            },
            coordinates: {
              lat: '18.737',
              lng: '100.841',
            },
            zipcode: null,
          },
          551405: {
            name: {
              th: 'เมืองจัง',
              en: 'Mueang Chang',
            },
            coordinates: {
              lat: '18.878',
              lng: '100.830',
            },
            zipcode: null,
          },
          551406: {
            name: {
              th: 'ท่าน้าว',
              en: 'Tha Nao',
            },
            coordinates: {
              lat: '18.731',
              lng: '100.791',
            },
            zipcode: null,
          },
          551407: {
            name: {
              th: 'ฝายแก้ว',
              en: 'Fai Kaeo',
            },
            coordinates: {
              lat: '18.803',
              lng: '100.933',
            },
            zipcode: null,
          },
        },
      },
      5515: {
        name: {
          th: 'เฉลิมพระเกียรติ',
          en: 'Chaloem Phra Kiet',
        },
        tambons: {
          551501: {
            name: {
              th: 'ห้วยโก๋น',
              en: 'Huai Kon',
            },
            coordinates: {
              lat: '19.559',
              lng: '101.046',
            },
            zipcode: null,
          },
          551502: {
            name: {
              th: 'ขุนน่าน',
              en: 'Khun Nan',
            },
            coordinates: {
              lat: '19.450',
              lng: '101.128',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  56: {
    name: {
      th: 'พะเยา',
      en: 'Phayao',
    },
    amphoes: {
      5601: {
        name: {
          th: 'เมืองพะเยา',
          en: 'Mueang Phayao',
        },
        tambons: {
          560101: {
            name: {
              th: 'เวียง',
              en: 'Wiang',
            },
            coordinates: {
              lat: '19.172',
              lng: '99.894',
            },
            zipcode: 56110,
          },
          560102: {
            name: {
              th: 'แม่ต๋ำ',
              en: 'Mae Tam',
            },
            coordinates: {
              lat: '19.142',
              lng: '99.912',
            },
            zipcode: 56000,
          },
          560104: {
            name: {
              th: 'แม่นาเรือ',
              en: 'Mae Na Ruea',
            },
            coordinates: {
              lat: '19.088',
              lng: '99.797',
            },
            zipcode: 56000,
          },
          560105: {
            name: {
              th: 'บ้านตุ่น',
              en: 'Ban Tun',
            },
            coordinates: {
              lat: '19.141',
              lng: '99.823',
            },
            zipcode: 56000,
          },
          560106: {
            name: {
              th: 'บ้านต๊ำ',
              en: 'Ban Tam',
            },
            coordinates: {
              lat: '19.216',
              lng: '99.756',
            },
            zipcode: 56000,
          },
          560107: {
            name: {
              th: 'บ้านต๋อม',
              en: 'Ban Tom',
            },
            coordinates: {
              lat: '19.198',
              lng: '99.800',
            },
            zipcode: 56000,
          },
          560108: {
            name: {
              th: 'แม่ปีม',
              en: 'Mae Puem',
            },
            coordinates: {
              lat: '19.337',
              lng: '99.902',
            },
            zipcode: 56000,
          },
          560110: {
            name: {
              th: 'แม่กา',
              en: 'Mae Ka',
            },
            coordinates: {
              lat: '19.049',
              lng: '99.917',
            },
            zipcode: 56000,
          },
          560111: {
            name: {
              th: 'บ้านใหม่',
              en: 'Ban Mai',
            },
            coordinates: {
              lat: '19.295',
              lng: '99.733',
            },
            zipcode: 56000,
          },
          560112: {
            name: {
              th: 'จำป่าหวาย',
              en: 'Champa Wai',
            },
            coordinates: {
              lat: '19.105',
              lng: '99.945',
            },
            zipcode: 56000,
          },
          560113: {
            name: {
              th: 'ท่าวังทอง',
              en: 'Tha Wang Thong',
            },
            coordinates: {
              lat: '19.202',
              lng: '99.927',
            },
            zipcode: 56000,
          },
          560114: {
            name: {
              th: 'แม่ใส',
              en: 'Mae Sai',
            },
            coordinates: {
              lat: '19.127',
              lng: '99.886',
            },
            zipcode: 56000,
          },
          560115: {
            name: {
              th: 'บ้านสาง',
              en: 'Ban Sang',
            },
            coordinates: {
              lat: '19.156',
              lng: '99.811',
            },
            zipcode: 56000,
          },
          560116: {
            name: {
              th: 'ท่าจำปี',
              en: 'Tha Champi',
            },
            coordinates: {
              lat: '19.245',
              lng: '99.815',
            },
            zipcode: 56000,
          },
          560118: {
            name: {
              th: 'สันป่าม่วง',
              en: 'San Pa Muang',
            },
            coordinates: {
              lat: '19.166',
              lng: '99.759',
            },
            zipcode: 56000,
          },
        },
      },
      5602: {
        name: {
          th: 'จุน',
          en: 'Mueang Phayao',
        },
        tambons: {
          560201: {
            name: {
              th: 'ห้วยข้าวก่ำ',
              en: 'Huai Khao Kam',
            },
            coordinates: {
              lat: '19.352',
              lng: '100.120',
            },
            zipcode: 56150,
          },
          560202: {
            name: {
              th: 'จุน',
              en: 'Chun',
            },
            coordinates: {
              lat: '19.282',
              lng: '100.152',
            },
            zipcode: 56150,
          },
          560203: {
            name: {
              th: 'ลอ',
              en: 'Lo',
            },
            coordinates: {
              lat: '19.414',
              lng: '100.073',
            },
            zipcode: 56150,
          },
          560204: {
            name: {
              th: 'หงส์หิน',
              en: 'Hong Hin',
            },
            coordinates: {
              lat: '19.507',
              lng: '100.103',
            },
            zipcode: 56150,
          },
          560205: {
            name: {
              th: 'ทุ่งรวงทอง',
              en: 'Thung Ruang Thong',
            },
            coordinates: {
              lat: '19.436',
              lng: '100.166',
            },
            zipcode: 56150,
          },
          560206: {
            name: {
              th: 'ห้วยยางขาม',
              en: 'Huai Yang Kham',
            },
            coordinates: {
              lat: '19.378',
              lng: '100.163',
            },
            zipcode: 56150,
          },
          560207: {
            name: {
              th: 'พระธาตุขิงแกง',
              en: 'Phrathat Khing Kaeng',
            },
            coordinates: {
              lat: '19.273',
              lng: '100.186',
            },
            zipcode: 56150,
          },
        },
      },
      5603: {
        name: {
          th: 'เชียงคำ',
          en: 'Mueang Phayao',
        },
        tambons: {
          560301: {
            name: {
              th: 'หย่วน',
              en: 'Yuan',
            },
            coordinates: {
              lat: '19.536',
              lng: '100.293',
            },
            zipcode: 56110,
          },
          560306: {
            name: {
              th: 'น้ำแวน',
              en: 'Nam Waen',
            },
            coordinates: {
              lat: '19.477',
              lng: '100.244',
            },
            zipcode: 56110,
          },
          560307: {
            name: {
              th: 'เวียง',
              en: 'Wiang',
            },
            coordinates: {
              lat: '19.495',
              lng: '100.327',
            },
            zipcode: null,
          },
          560308: {
            name: {
              th: 'ฝายกวาง',
              en: 'Fai Kwang',
            },
            coordinates: {
              lat: '19.394',
              lng: '100.244',
            },
            zipcode: 56110,
          },
          560309: {
            name: {
              th: 'เจดีย์คำ',
              en: 'Chedi Kham',
            },
            coordinates: {
              lat: '19.537',
              lng: '100.337',
            },
            zipcode: 56110,
          },
          560310: {
            name: {
              th: 'ร่มเย็น',
              en: 'Rom Yen',
            },
            coordinates: {
              lat: '19.517',
              lng: '100.406',
            },
            zipcode: 56110,
          },
          560311: {
            name: {
              th: 'เชียงบาน',
              en: 'Chiang Ban',
            },
            coordinates: {
              lat: '19.586',
              lng: '100.234',
            },
            zipcode: 56110,
          },
          560312: {
            name: {
              th: 'แม่ลาว',
              en: 'Mae Lao',
            },
            coordinates: {
              lat: '19.432',
              lng: '100.451',
            },
            zipcode: 56110,
          },
          560313: {
            name: {
              th: 'อ่างทอง',
              en: 'Ang Thong',
            },
            coordinates: {
              lat: '19.552',
              lng: '100.201',
            },
            zipcode: 56110,
          },
          560314: {
            name: {
              th: 'ทุ่งผาสุข',
              en: 'ทุ่งผาสุข',
            },
            coordinates: {
              lat: '19.473',
              lng: '100.291',
            },
            zipcode: 56110,
          },
        },
      },
      5604: {
        name: {
          th: 'เชียงม่วน',
          en: 'Mueang Phayao',
        },
        tambons: {
          560401: {
            name: {
              th: 'เชียงม่วน',
              en: 'Chiang Muan',
            },
            coordinates: {
              lat: '18.888',
              lng: '100.334',
            },
            zipcode: 56160,
          },
          560402: {
            name: {
              th: 'บ้านมาง',
              en: 'Ban Mang',
            },
            coordinates: {
              lat: '18.869',
              lng: '100.220',
            },
            zipcode: 56160,
          },
          560403: {
            name: {
              th: 'สระ',
              en: 'Sa',
            },
            coordinates: {
              lat: '18.992',
              lng: '100.404',
            },
            zipcode: 56160,
          },
        },
      },
      5605: {
        name: {
          th: 'ดอกคำใต้',
          en: 'Mueang Phayao',
        },
        tambons: {
          560501: {
            name: {
              th: 'ดอกคำใต้',
              en: 'Dok Khamtai',
            },
            coordinates: {
              lat: '19.152',
              lng: '99.955',
            },
            zipcode: 56120,
          },
          560502: {
            name: {
              th: 'ดอนศรีชุม',
              en: 'Don Si Chum',
            },
            coordinates: {
              lat: '19.157',
              lng: '100.026',
            },
            zipcode: 56120,
          },
          560503: {
            name: {
              th: 'บ้านถ้ำ',
              en: 'Ban Tham',
            },
            coordinates: {
              lat: '19.105',
              lng: '100.074',
            },
            zipcode: 56120,
          },
          560504: {
            name: {
              th: 'บ้านปิน',
              en: 'Ban Pin',
            },
            coordinates: {
              lat: '18.988',
              lng: '100.034',
            },
            zipcode: 56120,
          },
          560505: {
            name: {
              th: 'ห้วยลาน',
              en: 'Huai Lan',
            },
            coordinates: {
              lat: '19.370',
              lng: '100.040',
            },
            zipcode: 56120,
          },
          560506: {
            name: {
              th: 'สันโค้ง',
              en: 'San Khong',
            },
            coordinates: {
              lat: '19.195',
              lng: '100.034',
            },
            zipcode: 56120,
          },
          560507: {
            name: {
              th: 'ป่าซาง',
              en: 'Pa Sang',
            },
            coordinates: {
              lat: '19.284',
              lng: '100.084',
            },
            zipcode: 56120,
          },
          560508: {
            name: {
              th: 'หนองหล่ม',
              en: 'Nong Lom',
            },
            coordinates: {
              lat: '18.925',
              lng: '100.096',
            },
            zipcode: 56120,
          },
          560509: {
            name: {
              th: 'ดงสุวรรณ',
              en: 'Dong Suwan',
            },
            coordinates: {
              lat: '19.242',
              lng: '100.053',
            },
            zipcode: 56120,
          },
          560510: {
            name: {
              th: 'บุญเกิด',
              en: 'Bunkoet',
            },
            coordinates: {
              lat: '19.134',
              lng: '99.998',
            },
            zipcode: 56120,
          },
          560511: {
            name: {
              th: 'สว่างอารมณ์',
              en: 'Sawang Arom',
            },
            coordinates: {
              lat: '19.194',
              lng: '99.982',
            },
            zipcode: 56120,
          },
          560512: {
            name: {
              th: 'คือเวียง',
              en: 'Khue Wiang',
            },
            coordinates: {
              lat: '19.085',
              lng: '100.014',
            },
            zipcode: 56120,
          },
        },
      },
      5606: {
        name: {
          th: 'ปง',
          en: 'Mueang Phayao',
        },
        tambons: {
          560601: {
            name: {
              th: 'ปง',
              en: 'Pong',
            },
            coordinates: {
              lat: '19.102',
              lng: '100.205',
            },
            zipcode: 56140,
          },
          560602: {
            name: {
              th: 'ควร',
              en: 'Khuan',
            },
            coordinates: {
              lat: '19.182',
              lng: '100.374',
            },
            zipcode: 56140,
          },
          560603: {
            name: {
              th: 'ออย',
              en: 'Oi',
            },
            coordinates: {
              lat: '19.211',
              lng: '100.329',
            },
            zipcode: 56140,
          },
          560604: {
            name: {
              th: 'งิม',
              en: 'Ngim',
            },
            coordinates: {
              lat: '19.284',
              lng: '100.403',
            },
            zipcode: 56140,
          },
          560605: {
            name: {
              th: 'ผาช้างน้อย',
              en: 'Pha Chang Noi',
            },
            coordinates: {
              lat: '19.282',
              lng: '100.502',
            },
            zipcode: 56140,
          },
          560606: {
            name: {
              th: 'นาปรัง',
              en: 'Na Prang',
            },
            coordinates: {
              lat: '19.219',
              lng: '100.242',
            },
            zipcode: 56140,
          },
          560607: {
            name: {
              th: 'ขุนควร',
              en: 'Khun Khuan',
            },
            coordinates: {
              lat: '19.111',
              lng: '100.457',
            },
            zipcode: 56140,
          },
        },
      },
      5607: {
        name: {
          th: 'แม่ใจ',
          en: 'Mueang Phayao',
        },
        tambons: {
          560701: {
            name: {
              th: 'แม่ใจ',
              en: 'Mae Chai',
            },
            coordinates: {
              lat: '19.330',
              lng: '99.846',
            },
            zipcode: 56130,
          },
          560702: {
            name: {
              th: 'ศรีถ้อย',
              en: 'Si Thoi',
            },
            coordinates: {
              lat: '19.357',
              lng: '99.725',
            },
            zipcode: 56130,
          },
          560703: {
            name: {
              th: 'แม่สุก',
              en: 'Mae Suk',
            },
            coordinates: {
              lat: '19.313',
              lng: '99.796',
            },
            zipcode: 56130,
          },
          560704: {
            name: {
              th: 'ป่าแฝก',
              en: 'Pa Faek',
            },
            coordinates: {
              lat: '19.426',
              lng: '99.763',
            },
            zipcode: 56130,
          },
          560705: {
            name: {
              th: 'บ้านเหล่า',
              en: 'Ban Lao',
            },
            coordinates: {
              lat: '19.399',
              lng: '99.859',
            },
            zipcode: 56130,
          },
          560706: {
            name: {
              th: 'เจริญราษฎร์',
              en: 'Charoen Rat',
            },
            coordinates: {
              lat: '19.396',
              lng: '99.756',
            },
            zipcode: 56130,
          },
        },
      },
      5608: {
        name: {
          th: 'ภูซาง',
          en: 'Mueang Phayao',
        },
        tambons: {
          560801: {
            name: {
              th: 'ภูซาง',
              en: 'Phu Sang',
            },
            coordinates: {
              lat: '19.671',
              lng: '100.378',
            },
            zipcode: null,
          },
          560802: {
            name: {
              th: 'ป่าสัก',
              en: 'Pa Sak',
            },
            coordinates: {
              lat: '19.622',
              lng: '100.370',
            },
            zipcode: null,
          },
          560803: {
            name: {
              th: 'ทุ่งกล้วย',
              en: 'Thung Kluai',
            },
            coordinates: {
              lat: '19.606',
              lng: '100.417',
            },
            zipcode: null,
          },
          560804: {
            name: {
              th: 'เชียงแรง',
              en: 'Chiang Raeng',
            },
            coordinates: {
              lat: '19.616',
              lng: '100.289',
            },
            zipcode: null,
          },
          560805: {
            name: {
              th: 'สบบง',
              en: 'Sop Bong',
            },
            coordinates: {
              lat: '19.570',
              lng: '100.317',
            },
            zipcode: null,
          },
        },
      },
      5609: {
        name: {
          th: 'ภูกามยาว',
          en: 'Mueang Phayao',
        },
        tambons: {
          560901: {
            name: {
              th: 'ห้วยแก้ว',
              en: 'Huai Kaeo',
            },
            coordinates: {
              lat: '19.311',
              lng: '99.999',
            },
            zipcode: null,
          },
          560902: {
            name: {
              th: 'ดงเจน',
              en: 'Dong Chen',
            },
            coordinates: {
              lat: '19.240',
              lng: '99.952',
            },
            zipcode: null,
          },
          560903: {
            name: {
              th: 'แม่อิง',
              en: 'Mae Ing',
            },
            coordinates: {
              lat: '19.241',
              lng: '99.989',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  57: {
    name: {
      th: 'เชียงราย',
      en: 'Chiang Rai',
    },
    amphoes: {
      5701: {
        name: {
          th: 'เมืองเชียงราย',
          en: 'Mueang Chiang Rai',
        },
        tambons: {
          570101: {
            name: {
              th: 'เวียง',
              en: 'Wiang',
            },
            coordinates: {
              lat: '19.907',
              lng: '99.832',
            },
            zipcode: 57170,
          },
          570102: {
            name: {
              th: 'รอบเวียง',
              en: 'Rop Wiang',
            },
            coordinates: {
              lat: '19.898',
              lng: '99.803',
            },
            zipcode: 57000,
          },
          570103: {
            name: {
              th: 'บ้านดู่',
              en: 'Ban Du',
            },
            coordinates: {
              lat: '19.971',
              lng: '99.847',
            },
            zipcode: 57100,
          },
          570104: {
            name: {
              th: 'นางแล',
              en: 'Nang Lae',
            },
            coordinates: {
              lat: '20.048',
              lng: '99.876',
            },
            zipcode: 57100,
          },
          570105: {
            name: {
              th: 'แม่ข้าวต้ม',
              en: 'Mae Khao Tom',
            },
            coordinates: {
              lat: '20.021',
              lng: '99.922',
            },
            zipcode: 57100,
          },
          570106: {
            name: {
              th: 'แม่ยาว',
              en: 'Mae Yao',
            },
            coordinates: {
              lat: '19.991',
              lng: '99.729',
            },
            zipcode: 57100,
          },
          570107: {
            name: {
              th: 'สันทราย',
              en: 'San Sai',
            },
            coordinates: {
              lat: '19.855',
              lng: '99.805',
            },
            zipcode: 57000,
          },
          570111: {
            name: {
              th: 'แม่กรณ์',
              en: 'Mae Kon',
            },
            coordinates: {
              lat: '19.862',
              lng: '99.698',
            },
            zipcode: 57000,
          },
          570112: {
            name: {
              th: 'ห้วยชมภู',
              en: 'Huai Chomphu',
            },
            coordinates: {
              lat: '20.015',
              lng: '99.506',
            },
            zipcode: 57000,
          },
          570113: {
            name: {
              th: 'ห้วยสัก',
              en: 'Huai Sak',
            },
            coordinates: {
              lat: '19.756',
              lng: '99.872',
            },
            zipcode: 57000,
          },
          570114: {
            name: {
              th: 'ริมกก',
              en: 'Rim Kok',
            },
            coordinates: {
              lat: '19.937',
              lng: '99.887',
            },
            zipcode: 57100,
          },
          570115: {
            name: {
              th: 'ดอยลาน',
              en: 'Doi Lan',
            },
            coordinates: {
              lat: '19.682',
              lng: '99.945',
            },
            zipcode: 57000,
          },
          570116: {
            name: {
              th: 'ป่าอ้อดอนชัย',
              en: 'Pa O Don Chai',
            },
            coordinates: {
              lat: '19.768',
              lng: '99.819',
            },
            zipcode: 57000,
          },
          570118: {
            name: {
              th: 'ท่าสาย',
              en: 'Tha Sai',
            },
            coordinates: {
              lat: '19.837',
              lng: '99.851',
            },
            zipcode: 57000,
          },
          570120: {
            name: {
              th: 'ดอยฮาง',
              en: 'Doi Hang',
            },
            coordinates: {
              lat: '19.931',
              lng: '99.713',
            },
            zipcode: 57000,
          },
        },
      },
      5702: {
        name: {
          th: 'เวียงชัย',
          en: 'Wiang Chai',
        },
        tambons: {
          570202: {
            name: {
              th: 'เวียงชัย',
              en: 'Wiang Chai',
            },
            coordinates: {
              lat: '19.866',
              lng: '99.912',
            },
            zipcode: null,
          },
          570203: {
            name: {
              th: 'ผางาม',
              en: 'Pha Ngam',
            },
            coordinates: {
              lat: '19.882',
              lng: '100.046',
            },
            zipcode: null,
          },
          570204: {
            name: {
              th: 'เวียงเหนือ',
              en: 'Wiang Nuea',
            },
            coordinates: {
              lat: '19.932',
              lng: '99.940',
            },
            zipcode: null,
          },
          570206: {
            name: {
              th: 'ดอนศิลา',
              en: 'Don Sila',
            },
            coordinates: {
              lat: '19.805',
              lng: '100.005',
            },
            zipcode: 57210,
          },
          570208: {
            name: {
              th: 'เมืองชุม',
              en: 'Mueang Chum',
            },
            coordinates: {
              lat: '19.904',
              lng: '99.974',
            },
            zipcode: 57210,
          },
        },
      },
      5703: {
        name: {
          th: 'เชียงของ',
          en: 'Chiang Khong',
        },
        tambons: {
          570301: {
            name: {
              th: 'เวียง',
              en: 'Wiang',
            },
            coordinates: {
              lat: '20.266',
              lng: '100.369',
            },
            zipcode: null,
          },
          570302: {
            name: {
              th: 'สถาน',
              en: 'Sathan',
            },
            coordinates: {
              lat: '20.202',
              lng: '100.364',
            },
            zipcode: 57140,
          },
          570303: {
            name: {
              th: 'ครึ่ง',
              en: 'Khrueng',
            },
            coordinates: {
              lat: '20.058',
              lng: '100.402',
            },
            zipcode: 57140,
          },
          570304: {
            name: {
              th: 'บุญเรือง',
              en: 'Bunrueang',
            },
            coordinates: {
              lat: '19.989',
              lng: '100.347',
            },
            zipcode: 57140,
          },
          570305: {
            name: {
              th: 'ห้วยซ้อ',
              en: 'Huai So',
            },
            coordinates: {
              lat: '20.061',
              lng: '100.284',
            },
            zipcode: 57140,
          },
          570308: {
            name: {
              th: 'ศรีดอนชัย',
              en: 'ศรีดอนชัย',
            },
            coordinates: {
              lat: '20.150',
              lng: '100.324',
            },
            zipcode: 57140,
          },
          570310: {
            name: {
              th: 'ริมโขง',
              en: 'ริมโขง',
            },
            coordinates: {
              lat: '20.361',
              lng: '100.313',
            },
            zipcode: 57140,
          },
        },
      },
      5704: {
        name: {
          th: 'เทิง',
          en: 'Thoeng',
        },
        tambons: {
          570401: {
            name: {
              th: 'เวียง',
              en: 'Wiang',
            },
            coordinates: {
              lat: '19.692',
              lng: '100.213',
            },
            zipcode: null,
          },
          570402: {
            name: {
              th: 'งิ้ว',
              en: 'Ngio',
            },
            coordinates: {
              lat: '19.697',
              lng: '100.096',
            },
            zipcode: 57160,
          },
          570403: {
            name: {
              th: 'ปล้อง',
              en: 'Plong',
            },
            coordinates: {
              lat: '19.658',
              lng: '100.062',
            },
            zipcode: 57230,
          },
          570404: {
            name: {
              th: 'แม่ลอย',
              en: null,
            },
            coordinates: {
              lat: '19.573',
              lng: '100.063',
            },
            zipcode: 57230,
          },
          570405: {
            name: {
              th: 'เชียงเคี่ยน',
              en: 'Chiang Khian',
            },
            coordinates: {
              lat: '19.625',
              lng: '99.990',
            },
            zipcode: 57230,
          },
          570409: {
            name: {
              th: 'ตับเต่า',
              en: 'Tap Tao',
            },
            coordinates: {
              lat: '19.787',
              lng: '100.355',
            },
            zipcode: 57160,
          },
          570410: {
            name: {
              th: 'หงาว',
              en: 'Ngao',
            },
            coordinates: {
              lat: '19.678',
              lng: '100.283',
            },
            zipcode: 57160,
          },
          570411: {
            name: {
              th: 'สันทรายงาม',
              en: 'San Sai Ngam',
            },
            coordinates: {
              lat: '19.717',
              lng: '100.180',
            },
            zipcode: 57160,
          },
          570412: {
            name: {
              th: 'ศรีดอนไชย',
              en: 'Si Don Chai',
            },
            coordinates: {
              lat: '19.609',
              lng: '100.092',
            },
            zipcode: 57230,
          },
          570413: {
            name: {
              th: 'หนองแรด',
              en: 'Nong Raet',
            },
            coordinates: {
              lat: '19.607',
              lng: '100.143',
            },
            zipcode: 57230,
          },
        },
      },
      5705: {
        name: {
          th: 'พาน',
          en: 'Phan',
        },
        tambons: {
          570501: {
            name: {
              th: 'สันมะเค็ด',
              en: 'San Makhet',
            },
            coordinates: {
              lat: '19.609',
              lng: '99.864',
            },
            zipcode: 57120,
          },
          570502: {
            name: {
              th: 'แม่อ้อ',
              en: 'Mae O',
            },
            coordinates: {
              lat: '19.672',
              lng: '99.821',
            },
            zipcode: 57120,
          },
          570503: {
            name: {
              th: 'ธารทอง',
              en: 'Than Thong',
            },
            coordinates: {
              lat: '19.710',
              lng: '99.723',
            },
            zipcode: 57250,
          },
          570504: {
            name: {
              th: 'สันติสุข',
              en: 'Santi Suk',
            },
            coordinates: {
              lat: '19.575',
              lng: '99.765',
            },
            zipcode: 57120,
          },
          570505: {
            name: {
              th: 'ดอยงาม',
              en: 'Doi Ngam',
            },
            coordinates: {
              lat: '19.548',
              lng: '99.808',
            },
            zipcode: 57120,
          },
          570506: {
            name: {
              th: 'หัวง้ม',
              en: 'Hua Ngom',
            },
            coordinates: {
              lat: '19.538',
              lng: '99.771',
            },
            zipcode: 57120,
          },
          570507: {
            name: {
              th: 'เจริญเมือง',
              en: 'Charoen Mueang',
            },
            coordinates: {
              lat: '19.616',
              lng: '99.746',
            },
            zipcode: 57120,
          },
          570508: {
            name: {
              th: 'ป่าหุ่ง',
              en: 'Pa Hung',
            },
            coordinates: {
              lat: '19.495',
              lng: '99.648',
            },
            zipcode: 57120,
          },
          570509: {
            name: {
              th: 'ม่วงคำ',
              en: 'Muang Kham',
            },
            coordinates: {
              lat: '19.490',
              lng: '99.711',
            },
            zipcode: 57120,
          },
          570510: {
            name: {
              th: 'ทรายขาว',
              en: 'Sai Khao',
            },
            coordinates: {
              lat: '19.663',
              lng: '99.733',
            },
            zipcode: 57120,
          },
          570511: {
            name: {
              th: 'สันกลาง',
              en: 'San Klang',
            },
            coordinates: {
              lat: '19.607',
              lng: '99.684',
            },
            zipcode: 57120,
          },
          570512: {
            name: {
              th: 'แม่เย็น',
              en: 'Mae Yen',
            },
            coordinates: {
              lat: '19.454',
              lng: '99.732',
            },
            zipcode: 57280,
          },
          570513: {
            name: {
              th: 'เมืองพาน',
              en: 'Mueang Phan',
            },
            coordinates: {
              lat: '19.554',
              lng: '99.746',
            },
            zipcode: 57120,
          },
          570514: {
            name: {
              th: 'ทานตะวัน',
              en: 'Than Tawan',
            },
            coordinates: {
              lat: '19.470',
              lng: '99.832',
            },
            zipcode: 57280,
          },
          570515: {
            name: {
              th: 'เวียงห้าว',
              en: 'Wiang Hao',
            },
            coordinates: {
              lat: '19.524',
              lng: '99.880',
            },
            zipcode: 57120,
          },
        },
      },
      5706: {
        name: {
          th: 'ป่าแดด',
          en: 'Pa Daet',
        },
        tambons: {
          570601: {
            name: {
              th: 'ป่าแดด',
              en: 'Pa Daet',
            },
            coordinates: {
              lat: '19.507',
              lng: '100.015',
            },
            zipcode: null,
          },
          570602: {
            name: {
              th: 'ป่าแงะ',
              en: 'Pa Ngae',
            },
            coordinates: {
              lat: '19.579',
              lng: '99.933',
            },
            zipcode: null,
          },
          570603: {
            name: {
              th: 'สันมะค่า',
              en: 'San Makha',
            },
            coordinates: {
              lat: '19.466',
              lng: '100.048',
            },
            zipcode: null,
          },
          570605: {
            name: {
              th: 'โรงช้าง',
              en: 'Rong Chang',
            },
            coordinates: {
              lat: '19.494',
              lng: '99.947',
            },
            zipcode: 57190,
          },
          570606: {
            name: {
              th: 'ศรีโพธิ์เงิน',
              en: 'Si Pho Ngoen',
            },
            coordinates: {
              lat: '19.435',
              lng: '99.934',
            },
            zipcode: 57190,
          },
        },
      },
      5707: {
        name: {
          th: 'แม่จัน',
          en: 'Mae Chan',
        },
        tambons: {
          570701: {
            name: {
              th: 'แม่จัน',
              en: 'Mae Chan',
            },
            coordinates: {
              lat: '20.112',
              lng: '99.813',
            },
            zipcode: 57110,
          },
          570702: {
            name: {
              th: 'จันจว้า',
              en: 'Chan Chawa',
            },
            coordinates: {
              lat: '20.239',
              lng: '99.982',
            },
            zipcode: 57270,
          },
          570703: {
            name: {
              th: 'แม่คำ',
              en: 'Mae Kham',
            },
            coordinates: {
              lat: '20.251',
              lng: '99.902',
            },
            zipcode: 57240,
          },
          570704: {
            name: {
              th: 'ป่าซาง',
              en: 'Pa Sang',
            },
            coordinates: {
              lat: '20.181',
              lng: '99.846',
            },
            zipcode: null,
          },
          570705: {
            name: {
              th: 'สันทราย',
              en: 'San Sai',
            },
            coordinates: {
              lat: '20.171',
              lng: '99.891',
            },
            zipcode: null,
          },
          570706: {
            name: {
              th: 'ท่าข้าวเปลือก',
              en: 'Tha Khao Plueak',
            },
            coordinates: {
              lat: '20.163',
              lng: '100.018',
            },
            zipcode: 57110,
          },
          570708: {
            name: {
              th: 'ป่าตึง',
              en: 'Pa Tueng',
            },
            coordinates: {
              lat: '20.134',
              lng: '99.770',
            },
            zipcode: 57110,
          },
          570710: {
            name: {
              th: 'แม่ไร่',
              en: 'Mae Rai',
            },
            coordinates: {
              lat: '20.256',
              lng: '99.864',
            },
            zipcode: 57240,
          },
          570711: {
            name: {
              th: 'ศรีค้ำ',
              en: 'Si Kham',
            },
            coordinates: {
              lat: '20.221',
              lng: '99.797',
            },
            zipcode: 57110,
          },
          570712: {
            name: {
              th: 'จันจว้าใต้',
              en: 'Chan Chawa Tai',
            },
            coordinates: {
              lat: '20.239',
              lng: '99.936',
            },
            zipcode: 57270,
          },
          570713: {
            name: {
              th: 'จอมสวรรค์',
              en: 'Chom Sawan',
            },
            coordinates: {
              lat: '20.170',
              lng: '99.950',
            },
            zipcode: 57110,
          },
        },
      },
      5708: {
        name: {
          th: 'เชียงแสน',
          en: 'Chiang Saen',
        },
        tambons: {
          570801: {
            name: {
              th: 'เวียง',
              en: 'Wiang',
            },
            coordinates: {
              lat: '20.305',
              lng: '100.081',
            },
            zipcode: null,
          },
          570802: {
            name: {
              th: 'ป่าสัก',
              en: 'Pa Sak',
            },
            coordinates: {
              lat: '20.280',
              lng: '100.003',
            },
            zipcode: 57150,
          },
          570803: {
            name: {
              th: 'บ้านแซว',
              en: 'Ban Saeo',
            },
            coordinates: {
              lat: '20.212',
              lng: '100.265',
            },
            zipcode: 57150,
          },
          570804: {
            name: {
              th: 'ศรีดอนมูล',
              en: 'Si Don Mun',
            },
            coordinates: {
              lat: '20.336',
              lng: '100.002',
            },
            zipcode: 57150,
          },
          570805: {
            name: {
              th: 'แม่เงิน',
              en: 'Mae Ngoen',
            },
            coordinates: {
              lat: '20.298',
              lng: '100.262',
            },
            zipcode: 57150,
          },
          570806: {
            name: {
              th: 'โยนก',
              en: 'Yonok',
            },
            coordinates: {
              lat: '20.238',
              lng: '100.062',
            },
            zipcode: 57150,
          },
        },
      },
      5709: {
        name: {
          th: 'แม่สาย',
          en: 'Mae Sai',
        },
        tambons: {
          570901: {
            name: {
              th: 'แม่สาย',
              en: 'Mae Sai',
            },
            coordinates: {
              lat: '20.431',
              lng: '99.913',
            },
            zipcode: 57130,
          },
          570902: {
            name: {
              th: 'ห้วยไคร้',
              en: 'Huai Khrai',
            },
            coordinates: {
              lat: '20.286',
              lng: '99.870',
            },
            zipcode: 57220,
          },
          570903: {
            name: {
              th: 'เกาะช้าง',
              en: 'Ko Chang',
            },
            coordinates: {
              lat: '20.398',
              lng: '100.010',
            },
            zipcode: 57130,
          },
          570904: {
            name: {
              th: 'โป่งผา',
              en: 'Pong Pha',
            },
            coordinates: {
              lat: '20.370',
              lng: '99.894',
            },
            zipcode: 57130,
          },
          570905: {
            name: {
              th: 'ศรีเมืองชุม',
              en: 'Si Mueang Chum',
            },
            coordinates: {
              lat: '20.378',
              lng: '99.957',
            },
            zipcode: 57130,
          },
          570906: {
            name: {
              th: 'เวียงพางคำ',
              en: 'Wiang Phang Kham',
            },
            coordinates: {
              lat: '20.409',
              lng: '99.900',
            },
            zipcode: 57130,
          },
          570908: {
            name: {
              th: 'บ้านด้าย',
              en: 'Ban Dai',
            },
            coordinates: {
              lat: '20.310',
              lng: '99.929',
            },
            zipcode: 57220,
          },
          570909: {
            name: {
              th: 'โป่งงาม',
              en: 'Pong Ngam',
            },
            coordinates: {
              lat: '20.325',
              lng: '99.869',
            },
            zipcode: 57130,
          },
        },
      },
      5710: {
        name: {
          th: 'แม่สรวย',
          en: 'Mae Suai',
        },
        tambons: {
          571001: {
            name: {
              th: 'แม่สรวย',
              en: 'Mae Saruai',
            },
            coordinates: {
              lat: '19.676',
              lng: '99.560',
            },
            zipcode: 57180,
          },
          571002: {
            name: {
              th: 'ป่าแดด',
              en: 'Pa Daet',
            },
            coordinates: {
              lat: '19.715',
              lng: '99.427',
            },
            zipcode: null,
          },
          571003: {
            name: {
              th: 'แม่พริก',
              en: 'Mae Phrik',
            },
            coordinates: {
              lat: '19.630',
              lng: '99.524',
            },
            zipcode: 57180,
          },
          571004: {
            name: {
              th: 'ศรีถ้อย',
              en: 'Si Thoi',
            },
            coordinates: {
              lat: '19.677',
              lng: '99.317',
            },
            zipcode: 57180,
          },
          571005: {
            name: {
              th: 'ท่าก๊อ',
              en: 'Tha Ko',
            },
            coordinates: {
              lat: '19.504',
              lng: '99.528',
            },
            zipcode: 57180,
          },
          571006: {
            name: {
              th: 'วาวี',
              en: 'Wawi',
            },
            coordinates: {
              lat: '19.848',
              lng: '99.497',
            },
            zipcode: 57180,
          },
          571007: {
            name: {
              th: 'เจดีย์หลวง',
              en: 'Chedi Luang',
            },
            coordinates: {
              lat: '19.569',
              lng: '99.546',
            },
            zipcode: 57180,
          },
        },
      },
      5711: {
        name: {
          th: 'เวียงป่าเป้า',
          en: 'Wiang Pa Pao',
        },
        tambons: {
          571101: {
            name: {
              th: 'สันสลี',
              en: 'San Sali',
            },
            coordinates: {
              lat: '19.419',
              lng: '99.514',
            },
            zipcode: 57170,
          },
          571102: {
            name: {
              th: 'เวียง',
              en: 'Wiang',
            },
            coordinates: {
              lat: '19.358',
              lng: '99.422',
            },
            zipcode: null,
          },
          571103: {
            name: {
              th: 'บ้านโป่ง',
              en: 'Ban Pong',
            },
            coordinates: {
              lat: '19.302',
              lng: '99.459',
            },
            zipcode: 57170,
          },
          571104: {
            name: {
              th: 'ป่างิ้ว',
              en: 'Pa Ngio',
            },
            coordinates: {
              lat: '19.252',
              lng: '99.441',
            },
            zipcode: 57170,
          },
          571105: {
            name: {
              th: 'เวียงกาหลง',
              en: 'Wiang Kalong',
            },
            coordinates: {
              lat: '19.241',
              lng: '99.525',
            },
            zipcode: 57260,
          },
          571106: {
            name: {
              th: 'แม่เจดีย์',
              en: 'Mae Chedi',
            },
            coordinates: {
              lat: '19.164',
              lng: '99.367',
            },
            zipcode: 57260,
          },
          571107: {
            name: {
              th: 'แม่เจดีย์ใหม่',
              en: 'Mae Chedi Mai',
            },
            coordinates: {
              lat: '19.098',
              lng: '99.451',
            },
            zipcode: 57260,
          },
        },
      },
      5712: {
        name: {
          th: 'พญาเม็งราย',
          en: 'Phaya Mengrai',
        },
        tambons: {
          571201: {
            name: {
              th: 'แม่เปา',
              en: 'Mae Pao',
            },
            coordinates: {
              lat: '19.902',
              lng: '100.118',
            },
            zipcode: null,
          },
          571202: {
            name: {
              th: 'แม่ต๋ำ',
              en: 'Mae Tam',
            },
            coordinates: {
              lat: '19.926',
              lng: '100.239',
            },
            zipcode: 57290,
          },
          571203: {
            name: {
              th: 'ไม้ยา',
              en: 'Mai Ya',
            },
            coordinates: {
              lat: '19.769',
              lng: '100.107',
            },
            zipcode: 57290,
          },
          571204: {
            name: {
              th: 'เม็งราย',
              en: 'Mengrai',
            },
            coordinates: {
              lat: '19.861',
              lng: '100.182',
            },
            zipcode: 57290,
          },
          571205: {
            name: {
              th: 'ตาดควัน',
              en: 'Tat Khwan',
            },
            coordinates: {
              lat: '19.991',
              lng: '100.184',
            },
            zipcode: 57290,
          },
        },
      },
      5713: {
        name: {
          th: 'เวียงแก่น',
          en: 'Wiang Kaen',
        },
        tambons: {
          571301: {
            name: {
              th: 'ม่วงยาย',
              en: 'Muang Yai',
            },
            coordinates: {
              lat: '20.108',
              lng: '100.539',
            },
            zipcode: null,
          },
          571302: {
            name: {
              th: 'ปอ',
              en: 'Po',
            },
            coordinates: {
              lat: '19.940',
              lng: '100.449',
            },
            zipcode: null,
          },
          571303: {
            name: {
              th: 'หล่ายงาว',
              en: 'Lai Ngao',
            },
            coordinates: {
              lat: '20.117',
              lng: '100.480',
            },
            zipcode: null,
          },
          571304: {
            name: {
              th: 'ท่าข้าม',
              en: 'Tha Kham',
            },
            coordinates: {
              lat: '20.042',
              lng: '100.520',
            },
            zipcode: 57310,
          },
        },
      },
      5714: {
        name: {
          th: 'ขุนตาล',
          en: 'Khun Tan',
        },
        tambons: {
          571401: {
            name: {
              th: 'ต้า',
              en: 'Ta',
            },
            coordinates: {
              lat: '19.804',
              lng: '100.233',
            },
            zipcode: null,
          },
          571402: {
            name: {
              th: 'ป่าตาล',
              en: 'Pa Tan',
            },
            coordinates: {
              lat: '19.847',
              lng: '100.272',
            },
            zipcode: null,
          },
          571403: {
            name: {
              th: 'ยางฮอม',
              en: 'Yang Hom',
            },
            coordinates: {
              lat: '19.924',
              lng: '100.306',
            },
            zipcode: null,
          },
        },
      },
      5715: {
        name: {
          th: 'แม่ฟ้าหลวง',
          en: 'Mae Fa Luang',
        },
        tambons: {
          571501: {
            name: {
              th: 'เทอดไทย',
              en: 'Thoet Thai',
            },
            coordinates: {
              lat: '20.306',
              lng: '99.603',
            },
            zipcode: 57240,
          },
          571502: {
            name: {
              th: 'แม่สลองใน',
              en: 'Mae Salong Nai',
            },
            coordinates: {
              lat: '20.194',
              lng: '99.706',
            },
            zipcode: 57110,
          },
          571503: {
            name: {
              th: 'แม่สลองนอก',
              en: 'Mae Salong Nok',
            },
            coordinates: {
              lat: '20.141',
              lng: '99.654',
            },
            zipcode: null,
          },
          571504: {
            name: {
              th: 'แม่ฟ้าหลวง',
              en: 'Mae Fa Luang',
            },
            coordinates: {
              lat: '20.288',
              lng: '99.783',
            },
            zipcode: 57240,
          },
        },
      },
      5716: {
        name: {
          th: 'แม่ลาว',
          en: 'Mae Lao',
        },
        tambons: {
          571601: {
            name: {
              th: 'ดงมะดะ',
              en: 'Dong Mada',
            },
            coordinates: {
              lat: '19.734',
              lng: '99.670',
            },
            zipcode: null,
          },
          571602: {
            name: {
              th: 'จอมหมอกแก้ว',
              en: 'Chom Mok Kaeo',
            },
            coordinates: {
              lat: '19.757',
              lng: '99.747',
            },
            zipcode: null,
          },
          571603: {
            name: {
              th: 'บัวสลี',
              en: 'Bua Sali',
            },
            coordinates: {
              lat: '19.804',
              lng: '99.768',
            },
            zipcode: null,
          },
          571604: {
            name: {
              th: 'ป่าก่อดำ',
              en: 'Pa Ko Dam',
            },
            coordinates: {
              lat: '19.794',
              lng: '99.715',
            },
            zipcode: null,
          },
          571605: {
            name: {
              th: 'โป่งแพร่',
              en: 'Pong Phrae',
            },
            coordinates: {
              lat: '19.812',
              lng: '99.653',
            },
            zipcode: null,
          },
        },
      },
      5717: {
        name: {
          th: 'เวียงเชียงรุ้ง',
          en: 'Wiang Chiang Rung',
        },
        tambons: {
          571701: {
            name: {
              th: 'ทุ่งก่อ',
              en: 'Thung Ko',
            },
            coordinates: {
              lat: '19.984',
              lng: '100.049',
            },
            zipcode: null,
          },
          571702: {
            name: {
              th: 'ดงมหาวัน',
              en: 'Dong Mahawan',
            },
            coordinates: {
              lat: '20.041',
              lng: '100.006',
            },
            zipcode: null,
          },
          571703: {
            name: {
              th: 'ป่าซาง',
              en: 'Pa Sang',
            },
            coordinates: {
              lat: '20.048',
              lng: '100.120',
            },
            zipcode: null,
          },
        },
      },
      5718: {
        name: {
          th: 'ดอยหลวง',
          en: 'Doi Luang',
        },
        tambons: {
          571801: {
            name: {
              th: 'ปงน้อย',
              en: 'Pong Noi',
            },
            coordinates: {
              lat: '20.104',
              lng: '100.068',
            },
            zipcode: null,
          },
          571802: {
            name: {
              th: 'โชคชัย',
              en: 'Chok Chai',
            },
            coordinates: {
              lat: '20.134',
              lng: '100.193',
            },
            zipcode: null,
          },
          571803: {
            name: {
              th: 'หนองป่าก่อ',
              en: 'Nong Pa Ko',
            },
            coordinates: {
              lat: '20.181',
              lng: '100.124',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  58: {
    name: {
      th: 'แม่ฮ่องสอน',
      en: 'Mae Hong Son',
    },
    amphoes: {
      5801: {
        name: {
          th: 'เมืองแม่ฮ่องสอน',
          en: 'Mueang Mae Hong Son',
        },
        tambons: {
          580101: {
            name: {
              th: 'จองคำ',
              en: 'Chong Kham',
            },
            coordinates: {
              lat: '19.302',
              lng: '97.970',
            },
            zipcode: 58000,
          },
          580102: {
            name: {
              th: 'ห้วยโป่ง',
              en: 'Huai Pong',
            },
            coordinates: {
              lat: '19.038',
              lng: '98.006',
            },
            zipcode: 58000,
          },
          580103: {
            name: {
              th: 'ผาบ่อง',
              en: 'Pha Bong',
            },
            coordinates: {
              lat: '19.215',
              lng: '97.910',
            },
            zipcode: 58000,
          },
          580104: {
            name: {
              th: 'ปางหมู',
              en: 'Pang Mu',
            },
            coordinates: {
              lat: '19.344',
              lng: '97.915',
            },
            zipcode: 58000,
          },
          580105: {
            name: {
              th: 'หมอกจำแป่',
              en: 'Mok Champae',
            },
            coordinates: {
              lat: '19.497',
              lng: '97.995',
            },
            zipcode: 58000,
          },
          580109: {
            name: {
              th: 'ห้วยปูลิง',
              en: 'Huai Pu Ling',
            },
            coordinates: {
              lat: '19.188',
              lng: '98.112',
            },
            zipcode: 58000,
          },
        },
      },
      5802: {
        name: {
          th: 'ขุนยวม',
          en: 'Khun Yuam',
        },
        tambons: {
          580201: {
            name: {
              th: 'ขุนยวม',
              en: 'Khun Yuam',
            },
            coordinates: {
              lat: '18.992',
              lng: '97.801',
            },
            zipcode: 58140,
          },
          580202: {
            name: {
              th: 'แม่เงา',
              en: 'Mae Ngao',
            },
            coordinates: {
              lat: '18.836',
              lng: '97.796',
            },
            zipcode: 58140,
          },
          580203: {
            name: {
              th: 'เมืองปอน',
              en: 'Mueang Pon',
            },
            coordinates: {
              lat: '18.702',
              lng: '97.945',
            },
            zipcode: 58140,
          },
          580204: {
            name: {
              th: 'แม่ยวมน้อย',
              en: 'Mae Yuam Noi',
            },
            coordinates: {
              lat: '18.742',
              lng: '98.051',
            },
            zipcode: 58140,
          },
          580205: {
            name: {
              th: 'แม่กิ๊',
              en: 'Mae Ki',
            },
            coordinates: {
              lat: '18.673',
              lng: '97.827',
            },
            zipcode: 58140,
          },
          580206: {
            name: {
              th: 'แม่อูคอ',
              en: 'Mae U Kho',
            },
            coordinates: {
              lat: '18.884',
              lng: '98.046',
            },
            zipcode: 58140,
          },
        },
      },
      5803: {
        name: {
          th: 'ปาย',
          en: 'Pai',
        },
        tambons: {
          580301: {
            name: {
              th: 'เวียงใต้',
              en: 'Wiang Tai',
            },
            coordinates: {
              lat: '19.362',
              lng: '98.419',
            },
            zipcode: 58130,
          },
          580302: {
            name: {
              th: 'เวียงเหนือ',
              en: 'Wiang Nuea',
            },
            coordinates: {
              lat: '19.512',
              lng: '98.497',
            },
            zipcode: 58130,
          },
          580303: {
            name: {
              th: 'แม่นาเติง',
              en: 'Mae Na Toeng',
            },
            coordinates: {
              lat: '19.456',
              lng: '98.363',
            },
            zipcode: 58130,
          },
          580304: {
            name: {
              th: 'แม่ฮี้',
              en: 'Mae Hi',
            },
            coordinates: {
              lat: '19.300',
              lng: '98.525',
            },
            zipcode: 58130,
          },
          580305: {
            name: {
              th: 'ทุ่งยาว',
              en: 'Thung Yao',
            },
            coordinates: {
              lat: '19.336',
              lng: '98.233',
            },
            zipcode: 58130,
          },
          580306: {
            name: {
              th: 'เมืองแปง',
              en: 'Mueang Paeng',
            },
            coordinates: {
              lat: '19.216',
              lng: '98.313',
            },
            zipcode: 58130,
          },
          580307: {
            name: {
              th: 'โป่งสา',
              en: 'Pong Sa',
            },
            coordinates: {
              lat: '19.120',
              lng: '98.581',
            },
            zipcode: 58130,
          },
        },
      },
      5804: {
        name: {
          th: 'แม่สะเรียง',
          en: 'Mae Sariang',
        },
        tambons: {
          580401: {
            name: {
              th: 'บ้านกาศ',
              en: 'Ban Kat',
            },
            coordinates: {
              lat: '18.259',
              lng: '97.880',
            },
            zipcode: 58110,
          },
          580402: {
            name: {
              th: 'แม่สะเรียง',
              en: 'Mae Sariang',
            },
            coordinates: {
              lat: '18.148',
              lng: '97.972',
            },
            zipcode: 58110,
          },
          580403: {
            name: {
              th: 'แม่คง',
              en: 'Mae Khong',
            },
            coordinates: {
              lat: '18.237',
              lng: '97.638',
            },
            zipcode: 58110,
          },
          580404: {
            name: {
              th: 'แม่เหาะ',
              en: 'Mae Ho',
            },
            coordinates: {
              lat: '18.120',
              lng: '98.074',
            },
            zipcode: 58110,
          },
          580405: {
            name: {
              th: 'แม่ยวม',
              en: 'Mae Yuam',
            },
            coordinates: {
              lat: '18.063',
              lng: '97.808',
            },
            zipcode: 58110,
          },
          580406: {
            name: {
              th: 'เสาหิน',
              en: 'Sao Hin',
            },
            coordinates: {
              lat: '18.432',
              lng: '97.719',
            },
            zipcode: 58110,
          },
          580408: {
            name: {
              th: 'ป่าแป๋',
              en: 'Pa Pae',
            },
            coordinates: {
              lat: '18.268',
              lng: '98.072',
            },
            zipcode: 58110,
          },
        },
      },
      5805: {
        name: {
          th: 'แม่ลาน้อย',
          en: 'Mae La Noi',
        },
        tambons: {
          580501: {
            name: {
              th: 'แม่ลาน้อย',
              en: 'Mae La Noi',
            },
            coordinates: {
              lat: '18.435',
              lng: '97.852',
            },
            zipcode: 58120,
          },
          580502: {
            name: {
              th: 'แม่ลาหลวง',
              en: 'Mae La Luang',
            },
            coordinates: {
              lat: '18.548',
              lng: '97.872',
            },
            zipcode: 58120,
          },
          580503: {
            name: {
              th: 'ท่าผาปุ้ม',
              en: 'Tha Pha Pum',
            },
            coordinates: {
              lat: '18.315',
              lng: '97.991',
            },
            zipcode: 58120,
          },
          580504: {
            name: {
              th: 'แม่โถ',
              en: 'Mae Tho',
            },
            coordinates: {
              lat: '18.609',
              lng: '98.032',
            },
            zipcode: 58120,
          },
          580505: {
            name: {
              th: 'ห้วยห้อม',
              en: 'Huai Hom',
            },
            coordinates: {
              lat: '18.370',
              lng: '98.102',
            },
            zipcode: 58120,
          },
          580506: {
            name: {
              th: 'แม่นาจาง',
              en: 'Mae Na Chang',
            },
            coordinates: {
              lat: '18.529',
              lng: '98.093',
            },
            zipcode: 58120,
          },
          580507: {
            name: {
              th: 'สันติคีรี',
              en: 'Santi Khiri',
            },
            coordinates: {
              lat: '18.508',
              lng: '97.992',
            },
            zipcode: 58120,
          },
          580508: {
            name: {
              th: 'ขุนแม่ลาน้อย',
              en: 'Khun Mae La Noi',
            },
            coordinates: {
              lat: '18.586',
              lng: '98.161',
            },
            zipcode: 58120,
          },
        },
      },
      5806: {
        name: {
          th: 'สบเมย',
          en: 'Sop Moei',
        },
        tambons: {
          580601: {
            name: {
              th: 'สบเมย',
              en: 'Sop Moei',
            },
            coordinates: {
              lat: '17.898',
              lng: '97.872',
            },
            zipcode: null,
          },
          580602: {
            name: {
              th: 'แม่คะตวน',
              en: 'Mae Khatuan',
            },
            coordinates: {
              lat: '18.003',
              lng: '97.981',
            },
            zipcode: null,
          },
          580603: {
            name: {
              th: 'กองก๋อย',
              en: 'Kong Koi',
            },
            coordinates: {
              lat: '18.015',
              lng: '98.160',
            },
            zipcode: 58110,
          },
          580604: {
            name: {
              th: 'แม่สวด',
              en: 'Mae Suat',
            },
            coordinates: {
              lat: '17.796',
              lng: '98.049',
            },
            zipcode: 58110,
          },
          580605: {
            name: {
              th: 'ป่าโปง',
              en: 'Pa Pong',
            },
            coordinates: {
              lat: '18.113',
              lng: '98.166',
            },
            zipcode: 58110,
          },
          580606: {
            name: {
              th: 'แม่สามแลบ',
              en: 'Mae Sam Laep',
            },
            coordinates: {
              lat: '17.899',
              lng: '97.759',
            },
            zipcode: 58110,
          },
        },
      },
      5807: {
        name: {
          th: 'ปางมะผ้า',
          en: 'Pang Mapha',
        },
        tambons: {
          580701: {
            name: {
              th: 'สบป่อง',
              en: 'Sop Pong',
            },
            coordinates: {
              lat: '19.453',
              lng: '98.169',
            },
            zipcode: null,
          },
          580702: {
            name: {
              th: 'ปางมะผ้า',
              en: 'Pang Mapha',
            },
            coordinates: {
              lat: '19.615',
              lng: '98.204',
            },
            zipcode: null,
          },
          580703: {
            name: {
              th: 'ถ้ำลอด',
              en: 'Tham Lot',
            },
            coordinates: {
              lat: '19.582',
              lng: '98.309',
            },
            zipcode: 58150,
          },
          580704: {
            name: {
              th: 'นาปู่ป้อม',
              en: 'Na Pu Pom',
            },
            coordinates: {
              lat: '19.659',
              lng: '98.114',
            },
            zipcode: 58150,
          },
        },
      },
    },
  },
  60: {
    name: {
      th: 'นครสวรรค์',
      en: 'Nakhon Sawan',
    },
    amphoes: {
      6001: {
        name: {
          th: 'เมืองนครสวรรค์',
          en: 'Mueang Nakhon Sawan',
        },
        tambons: {
          600101: {
            name: {
              th: 'ปากน้ำโพ',
              en: 'Pak Nam Pho',
            },
            coordinates: {
              lat: '15.703',
              lng: '100.128',
            },
            zipcode: 60000,
          },
          600102: {
            name: {
              th: 'กลางแดด',
              en: 'Klang Daet',
            },
            coordinates: {
              lat: '15.663',
              lng: '100.118',
            },
            zipcode: 60000,
          },
          600103: {
            name: {
              th: 'เกรียงไกร',
              en: 'Kriang Krai',
            },
            coordinates: {
              lat: '15.719',
              lng: '100.218',
            },
            zipcode: 60000,
          },
          600104: {
            name: {
              th: 'แควใหญ่',
              en: 'Khwae Yai',
            },
            coordinates: {
              lat: '15.712',
              lng: '100.174',
            },
            zipcode: 60000,
          },
          600105: {
            name: {
              th: 'ตะเคียนเลื่อน',
              en: 'Takhian Luean',
            },
            coordinates: {
              lat: '15.630',
              lng: '100.086',
            },
            zipcode: 60000,
          },
          600106: {
            name: {
              th: 'นครสวรรค์ตก',
              en: 'Nakhon Sawan Tok',
            },
            coordinates: {
              lat: '15.681',
              lng: '100.073',
            },
            zipcode: 60000,
          },
          600107: {
            name: {
              th: 'นครสวรรค์ออก',
              en: 'Nakhon Sawan Ok',
            },
            coordinates: {
              lat: '15.633',
              lng: '100.132',
            },
            zipcode: 60000,
          },
          600108: {
            name: {
              th: 'บางพระหลวง',
              en: 'Bang Phra Luang',
            },
            coordinates: {
              lat: '15.771',
              lng: '100.188',
            },
            zipcode: 60000,
          },
          600109: {
            name: {
              th: 'บางม่วง',
              en: 'Bang Muang',
            },
            coordinates: {
              lat: '15.755',
              lng: '100.110',
            },
            zipcode: 60000,
          },
          600110: {
            name: {
              th: 'บ้านมะเกลือ',
              en: 'Ban Makluea',
            },
            coordinates: {
              lat: '15.801',
              lng: '100.143',
            },
            zipcode: 60000,
          },
          600111: {
            name: {
              th: 'บ้านแก่ง',
              en: 'Ban Kaeng',
            },
            coordinates: {
              lat: '15.788',
              lng: '100.057',
            },
            zipcode: 60000,
          },
          600112: {
            name: {
              th: 'พระนอน',
              en: 'Phra Non',
            },
            coordinates: {
              lat: '15.663',
              lng: '100.216',
            },
            zipcode: 60000,
          },
          600113: {
            name: {
              th: 'วัดไทรย์',
              en: 'Wat Sai',
            },
            coordinates: {
              lat: '15.735',
              lng: '100.080',
            },
            zipcode: null,
          },
          600114: {
            name: {
              th: 'หนองกรด',
              en: 'Nong Krot',
            },
            coordinates: {
              lat: '15.724',
              lng: '99.997',
            },
            zipcode: 60240,
          },
          600115: {
            name: {
              th: 'หนองกระโดน',
              en: 'Nong Kradon',
            },
            coordinates: {
              lat: '15.804',
              lng: '99.965',
            },
            zipcode: 60240,
          },
          600116: {
            name: {
              th: 'หนองปลิง',
              en: 'Nong Pling',
            },
            coordinates: {
              lat: '15.645',
              lng: '100.169',
            },
            zipcode: 60000,
          },
          600117: {
            name: {
              th: 'บึงเสนาท',
              en: 'Bueng Senat',
            },
            coordinates: {
              lat: '15.734',
              lng: '100.140',
            },
            zipcode: 60000,
          },
        },
      },
      6002: {
        name: {
          th: 'โกรกพระ',
          en: 'Krok Phra',
        },
        tambons: {
          600201: {
            name: {
              th: 'โกรกพระ',
              en: 'Krok Phra',
            },
            coordinates: {
              lat: '15.559',
              lng: '100.056',
            },
            zipcode: 60170,
          },
          600202: {
            name: {
              th: 'ยางตาล',
              en: 'Yang Tan',
            },
            coordinates: {
              lat: '15.585',
              lng: '100.114',
            },
            zipcode: 60170,
          },
          600203: {
            name: {
              th: 'บางมะฝ่อ',
              en: 'Bang Mafo',
            },
            coordinates: {
              lat: '15.594',
              lng: '100.081',
            },
            zipcode: 60170,
          },
          600204: {
            name: {
              th: 'บางประมุง',
              en: 'Bang Pramung',
            },
            coordinates: {
              lat: '15.662',
              lng: '99.997',
            },
            zipcode: 60170,
          },
          600205: {
            name: {
              th: 'นากลาง',
              en: 'Na Klang',
            },
            coordinates: {
              lat: '15.620',
              lng: '99.981',
            },
            zipcode: 60170,
          },
          600206: {
            name: {
              th: 'ศาลาแดง',
              en: 'Sala Daeng',
            },
            coordinates: {
              lat: '15.581',
              lng: '99.996',
            },
            zipcode: 60170,
          },
          600207: {
            name: {
              th: 'เนินกว้าว',
              en: 'Noen Kwao',
            },
            coordinates: {
              lat: '15.540',
              lng: '100.015',
            },
            zipcode: 60170,
          },
          600208: {
            name: {
              th: 'เนินศาลา',
              en: 'Noen Sala',
            },
            coordinates: {
              lat: '15.540',
              lng: '99.960',
            },
            zipcode: 60170,
          },
          600209: {
            name: {
              th: 'หาดสูง',
              en: 'Hat Sung',
            },
            coordinates: {
              lat: '15.503',
              lng: '99.989',
            },
            zipcode: 60170,
          },
        },
      },
      6003: {
        name: {
          th: 'ชุมแสง',
          en: 'Chum Saeng',
        },
        tambons: {
          600301: {
            name: {
              th: 'ชุมแสง',
              en: 'Chum Saeng',
            },
            coordinates: {
              lat: '15.896',
              lng: '100.315',
            },
            zipcode: 60120,
          },
          600302: {
            name: {
              th: 'ทับกฤช',
              en: 'Thap Krit',
            },
            coordinates: {
              lat: '15.770',
              lng: '100.233',
            },
            zipcode: 60250,
          },
          600303: {
            name: {
              th: 'พิกุล',
              en: 'Phikun',
            },
            coordinates: {
              lat: '15.889',
              lng: '100.346',
            },
            zipcode: 60120,
          },
          600304: {
            name: {
              th: 'เกยไชย',
              en: 'Koei Chai',
            },
            coordinates: {
              lat: '15.869',
              lng: '100.286',
            },
            zipcode: 60120,
          },
          600305: {
            name: {
              th: 'ท่าไม้',
              en: 'Tha Mai',
            },
            coordinates: {
              lat: '15.915',
              lng: '100.253',
            },
            zipcode: 60120,
          },
          600306: {
            name: {
              th: 'บางเคียน',
              en: 'Bang Khian',
            },
            coordinates: {
              lat: '15.806',
              lng: '100.176',
            },
            zipcode: 60120,
          },
          600307: {
            name: {
              th: 'หนองกระเจา',
              en: 'Nong Krachao',
            },
            coordinates: {
              lat: '15.901',
              lng: '100.395',
            },
            zipcode: 60120,
          },
          600308: {
            name: {
              th: 'พันลาน',
              en: 'Phan Lan',
            },
            coordinates: {
              lat: '15.833',
              lng: '100.280',
            },
            zipcode: 60250,
          },
          600309: {
            name: {
              th: 'โคกหม้อ',
              en: 'Khok Mo',
            },
            coordinates: {
              lat: '15.830',
              lng: '100.235',
            },
            zipcode: 60120,
          },
          600310: {
            name: {
              th: 'ไผ่สิงห์',
              en: 'Phai Sing',
            },
            coordinates: {
              lat: '15.829',
              lng: '100.360',
            },
            zipcode: 60120,
          },
          600311: {
            name: {
              th: 'ฆะมัง',
              en: 'Kha Mang',
            },
            coordinates: {
              lat: '15.948',
              lng: '100.312',
            },
            zipcode: 60120,
          },
          600312: {
            name: {
              th: 'ทับกฤชใต้',
              en: 'Thap Krit Tai',
            },
            coordinates: {
              lat: '15.757',
              lng: '100.286',
            },
            zipcode: 60250,
          },
        },
      },
      6004: {
        name: {
          th: 'หนองบัว',
          en: 'Nong Bua',
        },
        tambons: {
          600401: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '15.850',
              lng: '100.654',
            },
            zipcode: 60110,
          },
          600402: {
            name: {
              th: 'หนองกลับ',
              en: 'Nong Klap',
            },
            coordinates: {
              lat: '15.924',
              lng: '100.614',
            },
            zipcode: 60110,
          },
          600403: {
            name: {
              th: 'ธารทหาร',
              en: 'Than Thahan',
            },
            coordinates: {
              lat: '15.787',
              lng: '100.549',
            },
            zipcode: 60110,
          },
          600404: {
            name: {
              th: 'ห้วยร่วม',
              en: 'Huai Ruam',
            },
            coordinates: {
              lat: '15.908',
              lng: '100.494',
            },
            zipcode: 60110,
          },
          600405: {
            name: {
              th: 'ห้วยถั่วใต้',
              en: 'Huai Thua Tai',
            },
            coordinates: {
              lat: '15.837',
              lng: '100.464',
            },
            zipcode: 60110,
          },
          600406: {
            name: {
              th: 'ห้วยถั่วเหนือ',
              en: 'Huai Thua Nuea',
            },
            coordinates: {
              lat: '15.855',
              lng: '100.504',
            },
            zipcode: 60110,
          },
          600407: {
            name: {
              th: 'ห้วยใหญ่',
              en: 'Huai Yai',
            },
            coordinates: {
              lat: '15.864',
              lng: '100.429',
            },
            zipcode: 60110,
          },
          600408: {
            name: {
              th: 'ทุ่งทอง',
              en: 'Thung Thong',
            },
            coordinates: {
              lat: '15.937',
              lng: '100.713',
            },
            zipcode: 60110,
          },
          600409: {
            name: {
              th: 'วังบ่อ',
              en: 'Wang Bo',
            },
            coordinates: {
              lat: '15.783',
              lng: '100.649',
            },
            zipcode: 60110,
          },
        },
      },
      6005: {
        name: {
          th: 'บรรพตพิสัย',
          en: 'Banphot Phisai',
        },
        tambons: {
          600501: {
            name: {
              th: 'ท่างิ้ว',
              en: 'Tha Ngio',
            },
            coordinates: {
              lat: '15.918',
              lng: '99.976',
            },
            zipcode: 60180,
          },
          600502: {
            name: {
              th: 'บางตาหงาย',
              en: 'Bang Ta Ngai',
            },
            coordinates: {
              lat: '15.943',
              lng: '100.022',
            },
            zipcode: 60180,
          },
          600503: {
            name: {
              th: 'หูกวาง',
              en: 'Hu Kwang',
            },
            coordinates: {
              lat: '15.879',
              lng: '100.014',
            },
            zipcode: 60180,
          },
          600504: {
            name: {
              th: 'อ่างทอง',
              en: 'Ang Thong',
            },
            coordinates: {
              lat: '15.871',
              lng: '99.942',
            },
            zipcode: 60180,
          },
          600505: {
            name: {
              th: 'บ้านแดน',
              en: 'Ban Daen',
            },
            coordinates: {
              lat: '15.937',
              lng: '99.890',
            },
            zipcode: 60180,
          },
          600506: {
            name: {
              th: 'บางแก้ว',
              en: 'Bang Kaeo',
            },
            coordinates: {
              lat: '15.982',
              lng: '99.872',
            },
            zipcode: 60180,
          },
          600507: {
            name: {
              th: 'ตาขีด',
              en: 'Ta Khit',
            },
            coordinates: {
              lat: '16.017',
              lng: '99.917',
            },
            zipcode: 60180,
          },
          600508: {
            name: {
              th: 'ตาสัง',
              en: 'Ta Sang',
            },
            coordinates: {
              lat: '15.993',
              lng: '99.967',
            },
            zipcode: 60180,
          },
          600509: {
            name: {
              th: 'ด่านช้าง',
              en: 'Dan Chang',
            },
            coordinates: {
              lat: '16.080',
              lng: '99.999',
            },
            zipcode: 60180,
          },
          600510: {
            name: {
              th: 'หนองกรด',
              en: 'Nong Krot',
            },
            coordinates: {
              lat: '15.997',
              lng: '100.095',
            },
            zipcode: null,
          },
          600511: {
            name: {
              th: 'หนองตางู',
              en: 'Nong Ta Ngu',
            },
            coordinates: {
              lat: '16.130',
              lng: '100.054',
            },
            zipcode: 60180,
          },
          600512: {
            name: {
              th: 'บึงปลาทู',
              en: 'Bueng Pla Thu',
            },
            coordinates: {
              lat: '16.073',
              lng: '100.065',
            },
            zipcode: 60180,
          },
          600513: {
            name: {
              th: 'เจริญผล',
              en: 'Charoen Phon',
            },
            coordinates: {
              lat: '15.956',
              lng: '99.988',
            },
            zipcode: 60180,
          },
        },
      },
      6006: {
        name: {
          th: 'เก้าเลี้ยว',
          en: 'Kao Liao',
        },
        tambons: {
          600601: {
            name: {
              th: 'มหาโพธิ',
              en: 'Mahapho',
            },
            coordinates: {
              lat: '15.828',
              lng: '100.104',
            },
            zipcode: 60230,
          },
          600602: {
            name: {
              th: 'เก้าเลี้ยว',
              en: 'Kao Liao',
            },
            coordinates: {
              lat: '15.849',
              lng: '100.085',
            },
            zipcode: 60230,
          },
          600603: {
            name: {
              th: 'หนองเต่า',
              en: 'Nong Tao',
            },
            coordinates: {
              lat: '15.884',
              lng: '100.146',
            },
            zipcode: 60230,
          },
          600604: {
            name: {
              th: 'เขาดิน',
              en: 'Khao Din',
            },
            coordinates: {
              lat: '15.839',
              lng: '100.039',
            },
            zipcode: 60230,
          },
          600605: {
            name: {
              th: 'หัวดง',
              en: 'Hua Dong',
            },
            coordinates: {
              lat: '15.908',
              lng: '100.072',
            },
            zipcode: 60230,
          },
        },
      },
      6007: {
        name: {
          th: 'ตาคลี',
          en: 'Takhli',
        },
        tambons: {
          600701: {
            name: {
              th: 'ตาคลี',
              en: 'Ta Khli',
            },
            coordinates: {
              lat: '15.271',
              lng: '100.339',
            },
            zipcode: 60140,
          },
          600702: {
            name: {
              th: 'ช่องแค',
              en: 'Chong Khae',
            },
            coordinates: {
              lat: '15.197',
              lng: '100.422',
            },
            zipcode: 60210,
          },
          600703: {
            name: {
              th: 'จันเสน',
              en: 'Chan Sen',
            },
            coordinates: {
              lat: '15.103',
              lng: '100.439',
            },
            zipcode: 60260,
          },
          600704: {
            name: {
              th: 'ห้วยหอม',
              en: 'Huai Hom',
            },
            coordinates: {
              lat: '15.225',
              lng: '100.483',
            },
            zipcode: 60210,
          },
          600705: {
            name: {
              th: 'หัวหวาย',
              en: 'Hua Wai',
            },
            coordinates: {
              lat: '15.384',
              lng: '100.383',
            },
            zipcode: 60140,
          },
          600706: {
            name: {
              th: 'หนองโพ',
              en: 'Nong Pho',
            },
            coordinates: {
              lat: '15.403',
              lng: '100.278',
            },
            zipcode: 60140,
          },
          600707: {
            name: {
              th: 'หนองหม้อ',
              en: 'Nong Mo',
            },
            coordinates: {
              lat: '15.194',
              lng: '100.325',
            },
            zipcode: 60140,
          },
          600708: {
            name: {
              th: 'สร้อยทอง',
              en: 'Soi Thong',
            },
            coordinates: {
              lat: '15.120',
              lng: '100.387',
            },
            zipcode: 60210,
          },
          600709: {
            name: {
              th: 'ลาดทิพรส',
              en: 'Lat Thippharot',
            },
            coordinates: {
              lat: '15.235',
              lng: '100.549',
            },
            zipcode: 60260,
          },
          600710: {
            name: {
              th: 'พรหมนิมิต',
              en: 'Phrom Nimit',
            },
            coordinates: {
              lat: '15.175',
              lng: '100.393',
            },
            zipcode: 60210,
          },
        },
      },
      6008: {
        name: {
          th: 'ท่าตะโก',
          en: 'Tha Tako',
        },
        tambons: {
          600801: {
            name: {
              th: 'ท่าตะโก',
              en: 'Tha Tako',
            },
            coordinates: {
              lat: '15.631',
              lng: '100.480',
            },
            zipcode: 60160,
          },
          600802: {
            name: {
              th: 'พนมรอก',
              en: 'Phanom Rok',
            },
            coordinates: {
              lat: '15.704',
              lng: '100.442',
            },
            zipcode: 60160,
          },
          600803: {
            name: {
              th: 'หัวถนน',
              en: 'Hua Thanon',
            },
            coordinates: {
              lat: '15.583',
              lng: '100.432',
            },
            zipcode: 60160,
          },
          600804: {
            name: {
              th: 'สายลำโพง',
              en: 'Sai Lamphong',
            },
            coordinates: {
              lat: '15.768',
              lng: '100.487',
            },
            zipcode: 60160,
          },
          600805: {
            name: {
              th: 'วังมหากร',
              en: 'Wang Mahakon',
            },
            coordinates: {
              lat: '15.649',
              lng: '100.355',
            },
            zipcode: 60160,
          },
          600806: {
            name: {
              th: 'ดอนคา',
              en: 'Don Kha',
            },
            coordinates: {
              lat: '15.685',
              lng: '100.538',
            },
            zipcode: 60160,
          },
          600807: {
            name: {
              th: 'ทำนบ',
              en: 'Tham Nop',
            },
            coordinates: {
              lat: '15.603',
              lng: '100.518',
            },
            zipcode: 60160,
          },
          600808: {
            name: {
              th: 'วังใหญ่',
              en: 'Wang Yai',
            },
            coordinates: {
              lat: '15.781',
              lng: '100.413',
            },
            zipcode: 60160,
          },
          600809: {
            name: {
              th: 'พนมเศษ',
              en: 'Phanom Set',
            },
            coordinates: {
              lat: '15.731',
              lng: '100.340',
            },
            zipcode: 60160,
          },
          600810: {
            name: {
              th: 'หนองหลวง',
              en: 'Nong Luang',
            },
            coordinates: {
              lat: '15.486',
              lng: '100.533',
            },
            zipcode: 60160,
          },
        },
      },
      6009: {
        name: {
          th: 'ไพศาลี',
          en: 'Phaisali',
        },
        tambons: {
          600901: {
            name: {
              th: 'โคกเดื่อ',
              en: 'Khok Duea',
            },
            coordinates: {
              lat: '15.622',
              lng: '100.604',
            },
            zipcode: 60220,
          },
          600902: {
            name: {
              th: 'สำโรงชัย',
              en: 'Samrong Chai',
            },
            coordinates: {
              lat: '15.524',
              lng: '100.633',
            },
            zipcode: 60220,
          },
          600903: {
            name: {
              th: 'วังน้ำลัด',
              en: 'Wang Nam Lat',
            },
            coordinates: {
              lat: '15.709',
              lng: '100.666',
            },
            zipcode: 60220,
          },
          600904: {
            name: {
              th: 'ตะคร้อ',
              en: 'Takhro',
            },
            coordinates: {
              lat: '15.476',
              lng: '100.710',
            },
            zipcode: 60220,
          },
          600905: {
            name: {
              th: 'โพธิ์ประสาท',
              en: 'Pho Prasat',
            },
            coordinates: {
              lat: '15.440',
              lng: '100.587',
            },
            zipcode: 60220,
          },
          600906: {
            name: {
              th: 'วังข่อย',
              en: 'Wang Khoi',
            },
            coordinates: {
              lat: '15.598',
              lng: '100.761',
            },
            zipcode: 60220,
          },
          600907: {
            name: {
              th: 'นาขอม',
              en: 'Na Khom',
            },
            coordinates: {
              lat: '15.721',
              lng: '100.784',
            },
            zipcode: 60220,
          },
          600908: {
            name: {
              th: 'ไพศาลี',
              en: 'Phaisali',
            },
            coordinates: {
              lat: '15.637',
              lng: '100.700',
            },
            zipcode: 60220,
          },
        },
      },
      6010: {
        name: {
          th: 'พยุหะคีรี',
          en: 'Phayuha Hkiri',
        },
        tambons: {
          601001: {
            name: {
              th: 'พยุหะ',
              en: 'Phayuha',
            },
            coordinates: {
              lat: '15.455',
              lng: '100.145',
            },
            zipcode: 60130,
          },
          601002: {
            name: {
              th: 'เนินมะกอก',
              en: 'Noen Makok',
            },
            coordinates: {
              lat: '15.449',
              lng: '100.200',
            },
            zipcode: 60130,
          },
          601003: {
            name: {
              th: 'นิคมเขาบ่อแก้ว',
              en: 'Nikhom Khao Bo Kaeo',
            },
            coordinates: {
              lat: '15.471',
              lng: '100.245',
            },
            zipcode: 60130,
          },
          601004: {
            name: {
              th: 'ม่วงหัก',
              en: 'Muang Hak',
            },
            coordinates: {
              lat: '15.389',
              lng: '100.200',
            },
            zipcode: 60130,
          },
          601005: {
            name: {
              th: 'ยางขาว',
              en: 'Yang Khao',
            },
            coordinates: {
              lat: '15.514',
              lng: '100.069',
            },
            zipcode: 60130,
          },
          601006: {
            name: {
              th: 'ย่านมัทรี',
              en: 'Yan Matsi',
            },
            coordinates: {
              lat: '15.524',
              lng: '100.106',
            },
            zipcode: 60130,
          },
          601007: {
            name: {
              th: 'เขาทอง',
              en: 'Khao Thong',
            },
            coordinates: {
              lat: '15.567',
              lng: '100.161',
            },
            zipcode: 60130,
          },
          601008: {
            name: {
              th: 'ท่าน้ำอ้อย',
              en: 'Tha Nam Oi',
            },
            coordinates: {
              lat: '15.423',
              lng: '100.149',
            },
            zipcode: 60130,
          },
          601009: {
            name: {
              th: 'น้ำทรง',
              en: 'Nam Song',
            },
            coordinates: {
              lat: '15.460',
              lng: '100.074',
            },
            zipcode: 60130,
          },
          601010: {
            name: {
              th: 'เขากะลา',
              en: 'Khao Kala',
            },
            coordinates: {
              lat: '15.542',
              lng: '100.316',
            },
            zipcode: 60130,
          },
          601011: {
            name: {
              th: 'สระทะเล',
              en: 'Sa Thale',
            },
            coordinates: {
              lat: '15.510',
              lng: '100.156',
            },
            zipcode: 60130,
          },
        },
      },
      6011: {
        name: {
          th: 'ลาดยาว',
          en: 'Lat Yao',
        },
        tambons: {
          601101: {
            name: {
              th: 'ลาดยาว',
              en: 'Lat Yao',
            },
            coordinates: {
              lat: '15.756',
              lng: '99.759',
            },
            zipcode: 60150,
          },
          601102: {
            name: {
              th: 'ห้วยน้ำหอม',
              en: 'Huai Nam Hom',
            },
            coordinates: {
              lat: '15.729',
              lng: '99.696',
            },
            zipcode: 60150,
          },
          601103: {
            name: {
              th: 'วังม้า',
              en: 'Wang Ma',
            },
            coordinates: {
              lat: '15.686',
              lng: '99.871',
            },
            zipcode: 60150,
          },
          601104: {
            name: {
              th: 'วังเมือง',
              en: 'Wang Mueang',
            },
            coordinates: {
              lat: '15.640',
              lng: '99.846',
            },
            zipcode: 60150,
          },
          601105: {
            name: {
              th: 'สร้อยละคร',
              en: 'Soi Lakhon',
            },
            coordinates: {
              lat: '15.726',
              lng: '99.824',
            },
            zipcode: 60150,
          },
          601106: {
            name: {
              th: 'มาบแก',
              en: 'Map Kae',
            },
            coordinates: {
              lat: '15.677',
              lng: '99.781',
            },
            zipcode: 60150,
          },
          601107: {
            name: {
              th: 'หนองยาว',
              en: 'Nong Yao',
            },
            coordinates: {
              lat: '15.737',
              lng: '99.887',
            },
            zipcode: 60150,
          },
          601108: {
            name: {
              th: 'หนองนมวัว',
              en: 'Nong Nom Wua',
            },
            coordinates: {
              lat: '15.777',
              lng: '99.866',
            },
            zipcode: 60150,
          },
          601109: {
            name: {
              th: 'บ้านไร่',
              en: 'Ban Rai',
            },
            coordinates: {
              lat: '15.873',
              lng: '99.791',
            },
            zipcode: 60150,
          },
          601110: {
            name: {
              th: 'เนินขี้เหล็ก',
              en: 'Noen Khi Lek',
            },
            coordinates: {
              lat: '15.839',
              lng: '99.858',
            },
            zipcode: 60150,
          },
          601116: {
            name: {
              th: 'ศาลเจ้าไก่ต่อ',
              en: 'San Chao Kai To',
            },
            coordinates: {
              lat: '15.788',
              lng: '99.669',
            },
            zipcode: 60150,
          },
          601117: {
            name: {
              th: 'สระแก้ว',
              en: 'Sa Kaeo',
            },
            coordinates: {
              lat: '15.784',
              lng: '99.803',
            },
            zipcode: 60150,
          },
        },
      },
      6012: {
        name: {
          th: 'ตากฟ้า',
          en: 'Tak Fa',
        },
        tambons: {
          601201: {
            name: {
              th: 'ตากฟ้า',
              en: 'Tak Fa',
            },
            coordinates: {
              lat: '15.313',
              lng: '100.488',
            },
            zipcode: 60190,
          },
          601202: {
            name: {
              th: 'ลำพยนต์',
              en: 'Lam Phayon',
            },
            coordinates: {
              lat: '15.320',
              lng: '100.573',
            },
            zipcode: 60190,
          },
          601203: {
            name: {
              th: 'สุขสำราญ',
              en: 'Suk Samran',
            },
            coordinates: {
              lat: '15.353',
              lng: '100.523',
            },
            zipcode: 60190,
          },
          601204: {
            name: {
              th: 'หนองพิกุล',
              en: 'Nong Phikun',
            },
            coordinates: {
              lat: '15.331',
              lng: '100.428',
            },
            zipcode: 60190,
          },
          601205: {
            name: {
              th: 'พุนกยูง',
              en: 'Phu Nok Yung',
            },
            coordinates: {
              lat: '15.402',
              lng: '100.467',
            },
            zipcode: 60190,
          },
          601206: {
            name: {
              th: 'อุดมธัญญา',
              en: 'Udom Thanya',
            },
            coordinates: {
              lat: '15.479',
              lng: '100.407',
            },
            zipcode: 60190,
          },
          601207: {
            name: {
              th: 'เขาชายธง',
              en: 'Khao Chai Thong',
            },
            coordinates: {
              lat: '15.278',
              lng: '100.448',
            },
            zipcode: 60190,
          },
        },
      },
      6013: {
        name: {
          th: 'แม่วงก์',
          en: 'Mae Wong',
        },
        tambons: {
          601301: {
            name: {
              th: 'แม่วงก์',
              en: 'Mae Wong',
            },
            coordinates: {
              lat: '15.781',
              lng: '99.570',
            },
            zipcode: 60150,
          },
          601303: {
            name: {
              th: 'แม่เลย์',
              en: 'Mae Le',
            },
            coordinates: {
              lat: '15.842',
              lng: '99.298',
            },
            zipcode: 60150,
          },
          601304: {
            name: {
              th: 'วังซ่าน',
              en: 'Wang San',
            },
            coordinates: {
              lat: '15.859',
              lng: '99.644',
            },
            zipcode: 60150,
          },
          601305: {
            name: {
              th: 'เขาชนกัน',
              en: 'Khao Chon Kan',
            },
            coordinates: {
              lat: '15.883',
              lng: '99.516',
            },
            zipcode: 60150,
          },
        },
      },
      6014: {
        name: {
          th: 'แม่เปิน',
          en: 'Mae Pern',
        },
        tambons: {
          601401: {
            name: {
              th: 'แม่เปิน',
              en: 'Mae Poen',
            },
            coordinates: {
              lat: '15.738',
              lng: '99.294',
            },
            zipcode: null,
          },
        },
      },
      6015: {
        name: {
          th: 'ชุมตาบง',
          en: 'Chum Ta Bong',
        },
        tambons: {
          601501: {
            name: {
              th: 'ชุมตาบง',
              en: 'Chum Ta Bong',
            },
            coordinates: {
              lat: '15.627',
              lng: '99.572',
            },
            zipcode: null,
          },
          601502: {
            name: {
              th: 'ปางสวรรค์',
              en: 'Pang Sawan',
            },
            coordinates: {
              lat: '15.688',
              lng: '99.560',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  61: {
    name: {
      th: 'อุทัยธานี',
      en: 'Uthai Thani',
    },
    amphoes: {
      6101: {
        name: {
          th: 'เมืองอุทัยธานี',
          en: 'Mueang Uthai Thani',
        },
        tambons: {
          610101: {
            name: {
              th: 'อุทัยใหม่',
              en: 'Uthai Mai',
            },
            coordinates: {
              lat: '15.375',
              lng: '100.031',
            },
            zipcode: 61000,
          },
          610102: {
            name: {
              th: 'น้ำซึม',
              en: 'Nam Suem',
            },
            coordinates: {
              lat: '15.353',
              lng: '100.033',
            },
            zipcode: 61000,
          },
          610103: {
            name: {
              th: 'สะแกกรัง',
              en: 'Sakae Krang',
            },
            coordinates: {
              lat: '15.354',
              lng: '100.068',
            },
            zipcode: 61000,
          },
          610104: {
            name: {
              th: 'ดอนขวาง',
              en: 'Don Khwang',
            },
            coordinates: {
              lat: '15.415',
              lng: '100.032',
            },
            zipcode: 61000,
          },
          610105: {
            name: {
              th: 'หาดทนง',
              en: 'Hat Thanong',
            },
            coordinates: {
              lat: '15.382',
              lng: '100.096',
            },
            zipcode: 61000,
          },
          610106: {
            name: {
              th: 'เกาะเทโพ',
              en: 'Ko Thepho',
            },
            coordinates: {
              lat: '15.350',
              lng: '100.088',
            },
            zipcode: 61000,
          },
          610107: {
            name: {
              th: 'ท่าซุง',
              en: 'Tha Sung',
            },
            coordinates: {
              lat: '15.310',
              lng: '100.058',
            },
            zipcode: 61000,
          },
          610108: {
            name: {
              th: 'หนองแก',
              en: 'Nong Kae',
            },
            coordinates: {
              lat: '15.417',
              lng: '99.974',
            },
            zipcode: 61000,
          },
          610109: {
            name: {
              th: 'โนนเหล็ก',
              en: 'Non Lek',
            },
            coordinates: {
              lat: '15.462',
              lng: '99.985',
            },
            zipcode: 61000,
          },
          610110: {
            name: {
              th: 'หนองเต่า',
              en: 'Nong Tao',
            },
            coordinates: {
              lat: '15.455',
              lng: '99.963',
            },
            zipcode: 61000,
          },
          610111: {
            name: {
              th: 'หนองไผ่แบน',
              en: 'Nong Phai Baen',
            },
            coordinates: {
              lat: '15.444',
              lng: '100.018',
            },
            zipcode: 61000,
          },
          610112: {
            name: {
              th: 'หนองพังค่า',
              en: 'Nong Phang Kha',
            },
            coordinates: {
              lat: '15.398',
              lng: '99.959',
            },
            zipcode: 61000,
          },
          610113: {
            name: {
              th: 'ทุ่งใหญ่',
              en: 'Thung Yai',
            },
            coordinates: {
              lat: '15.483',
              lng: '99.974',
            },
            zipcode: 61000,
          },
          610114: {
            name: {
              th: 'เนินแจง',
              en: 'Noen Chaeng',
            },
            coordinates: {
              lat: '15.460',
              lng: '100.031',
            },
            zipcode: 61000,
          },
        },
      },
      6102: {
        name: {
          th: 'ทัพทัน',
          en: 'Thap Than',
        },
        tambons: {
          610201: {
            name: {
              th: 'ทัพทัน',
              en: 'Thapthan',
            },
            coordinates: {
              lat: '15.467',
              lng: '99.888',
            },
            zipcode: 61120,
          },
          610202: {
            name: {
              th: 'ทุ่งนาไทย',
              en: 'Thung Na Thai',
            },
            coordinates: {
              lat: '15.450',
              lng: '99.926',
            },
            zipcode: 61120,
          },
          610203: {
            name: {
              th: 'เขาขี้ฝอย',
              en: 'Khao Khi Foi',
            },
            coordinates: {
              lat: '15.469',
              lng: '99.939',
            },
            zipcode: 61120,
          },
          610204: {
            name: {
              th: 'หนองหญ้าปล้อง',
              en: 'Nong Ya Plong',
            },
            coordinates: {
              lat: '15.495',
              lng: '99.909',
            },
            zipcode: 61120,
          },
          610205: {
            name: {
              th: 'โคกหม้อ',
              en: 'Khok Mo',
            },
            coordinates: {
              lat: '15.550',
              lng: '99.915',
            },
            zipcode: 61120,
          },
          610206: {
            name: {
              th: 'หนองยายดา',
              en: 'Nong Yai Da',
            },
            coordinates: {
              lat: '15.519',
              lng: '99.870',
            },
            zipcode: 61120,
          },
          610207: {
            name: {
              th: 'หนองกลางดง',
              en: 'Nong Klang Dong',
            },
            coordinates: {
              lat: '15.465',
              lng: '99.844',
            },
            zipcode: 61120,
          },
          610208: {
            name: {
              th: 'หนองกระทุ่ม',
              en: 'Nong Krathum',
            },
            coordinates: {
              lat: '15.508',
              lng: '99.710',
            },
            zipcode: 61120,
          },
          610209: {
            name: {
              th: 'หนองสระ',
              en: 'Nong Sa',
            },
            coordinates: {
              lat: '15.445',
              lng: '99.820',
            },
            zipcode: 61120,
          },
          610210: {
            name: {
              th: 'ตลุกดู่',
              en: 'Taluk Duk',
            },
            coordinates: {
              lat: '15.452',
              lng: '99.740',
            },
            zipcode: 61120,
          },
        },
      },
      6103: {
        name: {
          th: 'สว่างอารมณ์',
          en: 'Sawang Arom',
        },
        tambons: {
          610301: {
            name: {
              th: 'สว่างอารมณ์',
              en: 'Sawang Arom',
            },
            coordinates: {
              lat: '15.548',
              lng: '99.844',
            },
            zipcode: 61150,
          },
          610302: {
            name: {
              th: 'หนองหลวง',
              en: 'Nong Luang',
            },
            coordinates: {
              lat: '15.622',
              lng: '99.900',
            },
            zipcode: 61150,
          },
          610303: {
            name: {
              th: 'พลวงสองนาง',
              en: 'Phluang Song Nang',
            },
            coordinates: {
              lat: '15.550',
              lng: '99.779',
            },
            zipcode: 61150,
          },
          610304: {
            name: {
              th: 'ไผ่เขียว',
              en: 'Phai Khiao',
            },
            coordinates: {
              lat: '15.623',
              lng: '99.762',
            },
            zipcode: 61150,
          },
          610305: {
            name: {
              th: 'บ่อยาง',
              en: 'Bo Yang',
            },
            coordinates: {
              lat: '15.592',
              lng: '99.715',
            },
            zipcode: 61150,
          },
        },
      },
      6104: {
        name: {
          th: 'หนองฉาง',
          en: 'Nong Chang',
        },
        tambons: {
          610401: {
            name: {
              th: 'หนองฉาง',
              en: 'Nong Chang',
            },
            coordinates: {
              lat: '15.397',
              lng: '99.854',
            },
            zipcode: 61110,
          },
          610402: {
            name: {
              th: 'หนองยาง',
              en: 'Nong Yang',
            },
            coordinates: {
              lat: '15.343',
              lng: '99.815',
            },
            zipcode: 61110,
          },
          610403: {
            name: {
              th: 'หนองนางนวล',
              en: 'Nong Nang Nuan',
            },
            coordinates: {
              lat: '15.340',
              lng: '99.864',
            },
            zipcode: 61110,
          },
          610404: {
            name: {
              th: 'หนองสรวง',
              en: 'Nong Suang',
            },
            coordinates: {
              lat: '15.372',
              lng: '99.810',
            },
            zipcode: 61110,
          },
          610405: {
            name: {
              th: 'บ้านเก่า',
              en: 'Ban Kao',
            },
            coordinates: {
              lat: '15.427',
              lng: '99.874',
            },
            zipcode: 61110,
          },
          610406: {
            name: {
              th: 'อุทัยเก่า',
              en: 'Uthai Kao',
            },
            coordinates: {
              lat: '15.408',
              lng: '99.800',
            },
            zipcode: 61110,
          },
          610407: {
            name: {
              th: 'ทุ่งโพ',
              en: 'Thung Pho',
            },
            coordinates: {
              lat: '15.344',
              lng: '99.712',
            },
            zipcode: 61110,
          },
          610408: {
            name: {
              th: 'ทุ่งพง',
              en: 'Thung Phong',
            },
            coordinates: {
              lat: '15.425',
              lng: '99.843',
            },
            zipcode: 61110,
          },
          610409: {
            name: {
              th: 'เขาบางแกรก',
              en: 'Khao Bang Kraek',
            },
            coordinates: {
              lat: '15.328',
              lng: '99.657',
            },
            zipcode: 61170,
          },
          610410: {
            name: {
              th: 'เขากวางทอง',
              en: 'Khao Kwang Thong',
            },
            coordinates: {
              lat: '15.394',
              lng: '99.694',
            },
            zipcode: 61110,
          },
        },
      },
      6105: {
        name: {
          th: 'หนองขาหย่าง',
          en: 'Nong Khayang',
        },
        tambons: {
          610501: {
            name: {
              th: 'หนองขาหย่าง',
              en: 'Nong Kha Yang',
            },
            coordinates: {
              lat: '15.349',
              lng: '99.950',
            },
            zipcode: 61130,
          },
          610502: {
            name: {
              th: 'หนองไผ่',
              en: 'Nong Phai',
            },
            coordinates: {
              lat: '15.341',
              lng: '99.902',
            },
            zipcode: 61130,
          },
          610503: {
            name: {
              th: 'ดอนกลอย',
              en: 'Don Kloi',
            },
            coordinates: {
              lat: '15.389',
              lng: '99.903',
            },
            zipcode: 61130,
          },
          610504: {
            name: {
              th: 'ห้วยรอบ',
              en: 'Huai Rop',
            },
            coordinates: {
              lat: '15.371',
              lng: '99.900',
            },
            zipcode: 61130,
          },
          610505: {
            name: {
              th: 'ทุ่งพึ่ง',
              en: 'Thung Phueng',
            },
            coordinates: {
              lat: '15.422',
              lng: '99.934',
            },
            zipcode: 61130,
          },
          610506: {
            name: {
              th: 'ท่าโพ',
              en: 'Tha Pho',
            },
            coordinates: {
              lat: '15.369',
              lng: '99.984',
            },
            zipcode: 61130,
          },
          610507: {
            name: {
              th: 'หมกแถว',
              en: 'Mok Thaeo',
            },
            coordinates: {
              lat: '15.338',
              lng: '99.991',
            },
            zipcode: 61130,
          },
          610509: {
            name: {
              th: 'ดงขวาง',
              en: 'Dong Khwang',
            },
            coordinates: {
              lat: '15.315',
              lng: '99.999',
            },
            zipcode: 61130,
          },
        },
      },
      6106: {
        name: {
          th: 'บ้านไร่',
          en: 'Ban Rai',
        },
        tambons: {
          610601: {
            name: {
              th: 'บ้านไร่',
              en: 'Ban Rai',
            },
            coordinates: {
              lat: '15.050',
              lng: '99.464',
            },
            zipcode: 61140,
          },
          610602: {
            name: {
              th: 'ทัพหลวง',
              en: 'Thap Luang',
            },
            coordinates: {
              lat: '15.054',
              lng: '99.603',
            },
            zipcode: 61140,
          },
          610603: {
            name: {
              th: 'ห้วยแห้ง',
              en: 'Huai Haeng',
            },
            coordinates: {
              lat: '15.175',
              lng: '99.556',
            },
            zipcode: 61140,
          },
          610604: {
            name: {
              th: 'คอกควาย',
              en: 'Khok Khwai',
            },
            coordinates: {
              lat: '15.226',
              lng: '99.412',
            },
            zipcode: 61140,
          },
          610605: {
            name: {
              th: 'วังหิน',
              en: 'Wang Hin',
            },
            coordinates: {
              lat: '15.270',
              lng: '99.700',
            },
            zipcode: 61180,
          },
          610606: {
            name: {
              th: 'เมืองการุ้ง',
              en: 'Mueang Karung',
            },
            coordinates: {
              lat: '15.177',
              lng: '99.724',
            },
            zipcode: 61180,
          },
          610607: {
            name: {
              th: 'แก่นมะกรูด',
              en: 'Kaen Makrut',
            },
            coordinates: {
              lat: '15.292',
              lng: '99.168',
            },
            zipcode: 61140,
          },
          610609: {
            name: {
              th: 'หนองจอก',
              en: 'Nong Chok',
            },
            coordinates: {
              lat: '15.047',
              lng: '99.685',
            },
            zipcode: 61180,
          },
          610610: {
            name: {
              th: 'หูช้าง',
              en: 'Hu Chang',
            },
            coordinates: {
              lat: '15.142',
              lng: '99.681',
            },
            zipcode: 61180,
          },
          610611: {
            name: {
              th: 'บ้านบึง',
              en: 'Ban Bueng',
            },
            coordinates: {
              lat: '15.028',
              lng: '99.553',
            },
            zipcode: 61140,
          },
          610612: {
            name: {
              th: 'บ้านใหม่คลองเคียน',
              en: 'Ban Mai Khlong Khian',
            },
            coordinates: {
              lat: '15.238',
              lng: '99.674',
            },
            zipcode: 61180,
          },
          610613: {
            name: {
              th: 'หนองบ่มกล้วย',
              en: 'Nong Bom Kluai',
            },
            coordinates: {
              lat: '15.021',
              lng: '99.730',
            },
            zipcode: 61180,
          },
          610614: {
            name: {
              th: 'เจ้าวัด',
              en: 'Chao Wat',
            },
            coordinates: {
              lat: '15.132',
              lng: '99.431',
            },
            zipcode: 61150,
          },
        },
      },
      6107: {
        name: {
          th: 'ลานสัก',
          en: 'Lan Sak',
        },
        tambons: {
          610701: {
            name: {
              th: 'ลานสัก',
              en: 'Lan Sak',
            },
            coordinates: {
              lat: '15.486',
              lng: '99.544',
            },
            zipcode: null,
          },
          610702: {
            name: {
              th: 'ประดู่ยืน',
              en: 'Pradu Yuen',
            },
            coordinates: {
              lat: '15.452',
              lng: '99.643',
            },
            zipcode: null,
          },
          610703: {
            name: {
              th: 'ป่าอ้อ',
              en: 'Pa O',
            },
            coordinates: {
              lat: '15.408',
              lng: '99.519',
            },
            zipcode: null,
          },
          610704: {
            name: {
              th: 'ระบำ',
              en: 'Rabam',
            },
            coordinates: {
              lat: '15.538',
              lng: '99.336',
            },
            zipcode: 61160,
          },
          610705: {
            name: {
              th: 'น้ำรอบ',
              en: 'Nam Rop',
            },
            coordinates: {
              lat: '15.534',
              lng: '99.605',
            },
            zipcode: 61160,
          },
          610706: {
            name: {
              th: 'ทุ่งนางาม',
              en: 'Thung Na Ngam',
            },
            coordinates: {
              lat: '15.385',
              lng: '99.610',
            },
            zipcode: 61160,
          },
        },
      },
      6108: {
        name: {
          th: 'ห้วยคต',
          en: 'Huai Khot',
        },
        tambons: {
          610801: {
            name: {
              th: 'สุขฤทัย',
              en: 'Suk Ruethai',
            },
            coordinates: {
              lat: '15.274',
              lng: '99.646',
            },
            zipcode: null,
          },
          610802: {
            name: {
              th: 'ทองหลาง',
              en: 'Thong Lang',
            },
            coordinates: {
              lat: '15.316',
              lng: '99.481',
            },
            zipcode: 61170,
          },
          610803: {
            name: {
              th: 'ห้วยคต',
              en: 'Huai Khot',
            },
            coordinates: {
              lat: '15.272',
              lng: '99.568',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  62: {
    name: {
      th: 'กำแพงเพชร',
      en: 'Kamphaeng Phet',
    },
    amphoes: {
      6201: {
        name: {
          th: 'เมืองกำแพงเพชร',
          en: 'Mueang Kamphaeng Phet',
        },
        tambons: {
          620101: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '16.473',
              lng: '99.528',
            },
            zipcode: 62000,
          },
          620102: {
            name: {
              th: 'ไตรตรึงษ์',
              en: 'Trai Trueng',
            },
            coordinates: {
              lat: '16.328',
              lng: '99.553',
            },
            zipcode: 62160,
          },
          620103: {
            name: {
              th: 'อ่างทอง',
              en: 'Ang Thong',
            },
            coordinates: {
              lat: '16.267',
              lng: '99.465',
            },
            zipcode: 62000,
          },
          620104: {
            name: {
              th: 'นาบ่อคำ',
              en: 'Na Bo Kham',
            },
            coordinates: {
              lat: '16.458',
              lng: '99.321',
            },
            zipcode: 62000,
          },
          620105: {
            name: {
              th: 'นครชุม',
              en: 'Nakhon Chum',
            },
            coordinates: {
              lat: '16.490',
              lng: '99.457',
            },
            zipcode: 62000,
          },
          620106: {
            name: {
              th: 'ทรงธรรม',
              en: 'Songtham',
            },
            coordinates: {
              lat: '16.530',
              lng: '99.454',
            },
            zipcode: 62000,
          },
          620107: {
            name: {
              th: 'ลานดอกไม้',
              en: 'Lan Dokmai',
            },
            coordinates: {
              lat: '16.634',
              lng: '99.440',
            },
            zipcode: 62000,
          },
          620110: {
            name: {
              th: 'หนองปลิง',
              en: 'Nong Pling',
            },
            coordinates: {
              lat: '16.566',
              lng: '99.521',
            },
            zipcode: 62000,
          },
          620111: {
            name: {
              th: 'คณฑี',
              en: 'Kon Ti',
            },
            coordinates: {
              lat: '16.367',
              lng: '99.695',
            },
            zipcode: 62000,
          },
          620112: {
            name: {
              th: 'นิคมทุ่งโพธิ์ทะเล',
              en: 'Nikhom Thung Pho Thale',
            },
            coordinates: {
              lat: '16.485',
              lng: '99.703',
            },
            zipcode: 62000,
          },
          620113: {
            name: {
              th: 'เทพนคร',
              en: 'Thep Nakhon',
            },
            coordinates: {
              lat: '16.427',
              lng: '99.611',
            },
            zipcode: 62000,
          },
          620114: {
            name: {
              th: 'วังทอง',
              en: 'Wang Thong',
            },
            coordinates: {
              lat: '16.270',
              lng: '99.376',
            },
            zipcode: 62000,
          },
          620115: {
            name: {
              th: 'ท่าขุนราม',
              en: 'Tha Khun Ram',
            },
            coordinates: {
              lat: '16.451',
              lng: '99.452',
            },
            zipcode: 62000,
          },
          620117: {
            name: {
              th: 'คลองแม่ลาย',
              en: 'Khlong Mae Lai',
            },
            coordinates: {
              lat: '16.375',
              lng: '99.410',
            },
            zipcode: 62000,
          },
          620118: {
            name: {
              th: 'ธำมรงค์',
              en: 'Thammarong',
            },
            coordinates: {
              lat: '16.330',
              lng: '99.628',
            },
            zipcode: 62160,
          },
          620119: {
            name: {
              th: 'สระแก้ว',
              en: 'Sa Kaeo',
            },
            coordinates: {
              lat: '16.521',
              lng: '99.590',
            },
            zipcode: 62000,
          },
        },
      },
      6202: {
        name: {
          th: 'ไทรงาม',
          en: 'Sai Ngam',
        },
        tambons: {
          620201: {
            name: {
              th: 'ไทรงาม',
              en: 'Sai Ngam',
            },
            coordinates: {
              lat: '16.487',
              lng: '99.897',
            },
            zipcode: null,
          },
          620202: {
            name: {
              th: 'หนองคล้า',
              en: 'Nong Khla',
            },
            coordinates: {
              lat: '16.452',
              lng: '99.827',
            },
            zipcode: null,
          },
          620203: {
            name: {
              th: 'หนองทอง',
              en: 'Nong Thong',
            },
            coordinates: {
              lat: '16.417',
              lng: '99.756',
            },
            zipcode: 62150,
          },
          620204: {
            name: {
              th: 'หนองไม้กอง',
              en: 'Nong Mai Kong',
            },
            coordinates: {
              lat: '16.464',
              lng: '99.973',
            },
            zipcode: 62150,
          },
          620205: {
            name: {
              th: 'มหาชัย',
              en: 'Mahachai',
            },
            coordinates: {
              lat: '16.507',
              lng: '99.798',
            },
            zipcode: 62150,
          },
          620206: {
            name: {
              th: 'พานทอง',
              en: 'Phan Thong',
            },
            coordinates: {
              lat: '16.384',
              lng: '99.833',
            },
            zipcode: 62150,
          },
          620207: {
            name: {
              th: 'หนองแม่แตง',
              en: 'Nong Mae Taeng',
            },
            coordinates: {
              lat: '16.399',
              lng: '99.909',
            },
            zipcode: 62150,
          },
        },
      },
      6203: {
        name: {
          th: 'คลองลาน',
          en: 'Khlong Lan',
        },
        tambons: {
          620301: {
            name: {
              th: 'คลองน้ำไหล',
              en: 'Khlong Nam Lai',
            },
            coordinates: {
              lat: '16.157',
              lng: '99.144',
            },
            zipcode: 62180,
          },
          620302: {
            name: {
              th: 'โป่งน้ำร้อน',
              en: 'Pong Nam Ron',
            },
            coordinates: {
              lat: '16.389',
              lng: '99.258',
            },
            zipcode: null,
          },
          620303: {
            name: {
              th: 'คลองลานพัฒนา',
              en: 'Khlong Lan Phatthana',
            },
            coordinates: {
              lat: '16.096',
              lng: '99.252',
            },
            zipcode: 62180,
          },
          620304: {
            name: {
              th: 'สักงาม',
              en: 'Sak Ngam',
            },
            coordinates: {
              lat: '16.324',
              lng: '99.348',
            },
            zipcode: 62180,
          },
        },
      },
      6204: {
        name: {
          th: 'ขาณุวรลักษบุรี',
          en: 'Khanu Woralaksaburi',
        },
        tambons: {
          620403: {
            name: {
              th: 'ยางสูง',
              en: 'Yang Sung',
            },
            coordinates: {
              lat: '16.124',
              lng: '99.857',
            },
            zipcode: 62130,
          },
          620404: {
            name: {
              th: 'ป่าพุทรา',
              en: 'Pa Phutsa',
            },
            coordinates: {
              lat: '16.094',
              lng: '99.918',
            },
            zipcode: 62130,
          },
          620405: {
            name: {
              th: 'แสนตอ',
              en: 'Saen To',
            },
            coordinates: {
              lat: '16.051',
              lng: '99.831',
            },
            zipcode: 62130,
          },
          620406: {
            name: {
              th: 'สลกบาตร',
              en: 'Salok Bat',
            },
            coordinates: {
              lat: '16.000',
              lng: '99.801',
            },
            zipcode: 62140,
          },
          620407: {
            name: {
              th: 'บ่อถ้ำ',
              en: 'Bo Tham',
            },
            coordinates: {
              lat: '15.912',
              lng: '99.738',
            },
            zipcode: 62140,
          },
          620408: {
            name: {
              th: 'ดอนแตง',
              en: 'Don Taeng',
            },
            coordinates: {
              lat: '16.053',
              lng: '99.752',
            },
            zipcode: 62140,
          },
          620409: {
            name: {
              th: 'วังชะพลู',
              en: 'Wang Chaphlu',
            },
            coordinates: {
              lat: '16.011',
              lng: '99.639',
            },
            zipcode: 62140,
          },
          620410: {
            name: {
              th: 'โค้งไผ่',
              en: 'Khong Phai',
            },
            coordinates: {
              lat: '16.108',
              lng: '99.657',
            },
            zipcode: 62140,
          },
          620411: {
            name: {
              th: 'ปางมะค่า',
              en: 'Pang Makha',
            },
            coordinates: {
              lat: '15.967',
              lng: '99.461',
            },
            zipcode: 62140,
          },
          620412: {
            name: {
              th: 'วังหามแห',
              en: 'Wang Ham Hae',
            },
            coordinates: {
              lat: '16.066',
              lng: '99.603',
            },
            zipcode: 62140,
          },
          620413: {
            name: {
              th: 'เกาะตาล',
              en: 'Ko Tan',
            },
            coordinates: {
              lat: '16.103',
              lng: '99.788',
            },
            zipcode: 62130,
          },
        },
      },
      6205: {
        name: {
          th: 'คลองขลุง',
          en: 'Khlong Khlung',
        },
        tambons: {
          620501: {
            name: {
              th: 'คลองขลุง',
              en: 'Khlong Khlung',
            },
            coordinates: {
              lat: '16.176',
              lng: '99.698',
            },
            zipcode: 62120,
          },
          620502: {
            name: {
              th: 'ท่ามะเขือ',
              en: 'Tha Makhuea',
            },
            coordinates: {
              lat: '16.261',
              lng: '99.752',
            },
            zipcode: 62120,
          },
          620504: {
            name: {
              th: 'ท่าพุทรา',
              en: 'Tha Phutsa',
            },
            coordinates: {
              lat: '16.269',
              lng: '99.679',
            },
            zipcode: 62120,
          },
          620505: {
            name: {
              th: 'แม่ลาด',
              en: 'Mae Lat',
            },
            coordinates: {
              lat: '16.153',
              lng: '99.785',
            },
            zipcode: 62120,
          },
          620506: {
            name: {
              th: 'วังยาง',
              en: 'Wang Yang',
            },
            coordinates: {
              lat: '16.204',
              lng: '99.774',
            },
            zipcode: 62120,
          },
          620507: {
            name: {
              th: 'วังแขม',
              en: 'Wang Khaem',
            },
            coordinates: {
              lat: '16.208',
              lng: '99.837',
            },
            zipcode: 62120,
          },
          620508: {
            name: {
              th: 'หัวถนน',
              en: 'Hua Thanon',
            },
            coordinates: {
              lat: '16.267',
              lng: '99.631',
            },
            zipcode: 62120,
          },
          620509: {
            name: {
              th: 'วังไทร',
              en: 'Wang Sai',
            },
            coordinates: {
              lat: '16.200',
              lng: '99.631',
            },
            zipcode: 62120,
          },
          620513: {
            name: {
              th: 'วังบัว',
              en: 'Wang Bua',
            },
            coordinates: {
              lat: '16.308',
              lng: '99.729',
            },
            zipcode: 62120,
          },
          620516: {
            name: {
              th: 'คลองสมบูรณ์',
              en: 'Khlong Sombun',
            },
            coordinates: {
              lat: '16.250',
              lng: '99.543',
            },
            zipcode: 62120,
          },
        },
      },
      6206: {
        name: {
          th: 'พรานกระต่าย',
          en: 'Phran Kratai',
        },
        tambons: {
          620601: {
            name: {
              th: 'พรานกระต่าย',
              en: 'Phran Kratai',
            },
            coordinates: {
              lat: '16.657',
              lng: '99.538',
            },
            zipcode: 62110,
          },
          620602: {
            name: {
              th: 'หนองหัววัว',
              en: 'Nong Hua Wua',
            },
            coordinates: {
              lat: '16.748',
              lng: '99.579',
            },
            zipcode: 62110,
          },
          620603: {
            name: {
              th: 'ท่าไม้',
              en: 'Tha Mai',
            },
            coordinates: {
              lat: '16.679',
              lng: '99.434',
            },
            zipcode: 62110,
          },
          620604: {
            name: {
              th: 'วังควง',
              en: 'Wang Khuang',
            },
            coordinates: {
              lat: '16.779',
              lng: '99.398',
            },
            zipcode: 62110,
          },
          620605: {
            name: {
              th: 'วังตะแบก',
              en: 'Wang Tabaek',
            },
            coordinates: {
              lat: '16.712',
              lng: '99.666',
            },
            zipcode: 62110,
          },
          620606: {
            name: {
              th: 'เขาคีริส',
              en: 'Khao Khirit',
            },
            coordinates: {
              lat: '16.588',
              lng: '99.683',
            },
            zipcode: 62110,
          },
          620607: {
            name: {
              th: 'คุยบ้านโอง',
              en: 'Khui Ban Ong',
            },
            coordinates: {
              lat: '16.659',
              lng: '99.667',
            },
            zipcode: 62110,
          },
          620608: {
            name: {
              th: 'คลองพิไกร',
              en: 'Khlong Phikrai',
            },
            coordinates: {
              lat: '16.666',
              lng: '99.742',
            },
            zipcode: 62110,
          },
          620609: {
            name: {
              th: 'ถ้ำกระต่ายทอง',
              en: 'Tham Kratai Thong',
            },
            coordinates: {
              lat: '16.729',
              lng: '99.541',
            },
            zipcode: 62110,
          },
          620610: {
            name: {
              th: 'ห้วยยั้ง',
              en: 'Huai Yang',
            },
            coordinates: {
              lat: '16.824',
              lng: '99.559',
            },
            zipcode: 62110,
          },
        },
      },
      6207: {
        name: {
          th: 'ลานกระบือ',
          en: 'Lan Krabue',
        },
        tambons: {
          620701: {
            name: {
              th: 'ลานกระบือ',
              en: 'Lan Krabue',
            },
            coordinates: {
              lat: '16.625',
              lng: '99.861',
            },
            zipcode: 62170,
          },
          620702: {
            name: {
              th: 'ช่องลม',
              en: 'Chong Lom',
            },
            coordinates: {
              lat: '16.568',
              lng: '99.913',
            },
            zipcode: 62170,
          },
          620703: {
            name: {
              th: 'หนองหลวง',
              en: 'Nong Luang',
            },
            coordinates: {
              lat: '16.640',
              lng: '99.780',
            },
            zipcode: 62170,
          },
          620704: {
            name: {
              th: 'โนนพลวง',
              en: 'Non Phluang',
            },
            coordinates: {
              lat: '16.560',
              lng: '99.857',
            },
            zipcode: 62170,
          },
          620705: {
            name: {
              th: 'ประชาสุขสันต์',
              en: 'Pracha Suksan',
            },
            coordinates: {
              lat: '16.521',
              lng: '99.974',
            },
            zipcode: 62170,
          },
          620706: {
            name: {
              th: 'บึงทับแรต',
              en: 'Bueng Thap Raet',
            },
            coordinates: {
              lat: '16.689',
              lng: '99.855',
            },
            zipcode: 62170,
          },
          620707: {
            name: {
              th: 'จันทิมา',
              en: 'Chanthima',
            },
            coordinates: {
              lat: '16.570',
              lng: '99.791',
            },
            zipcode: 62170,
          },
        },
      },
      6208: {
        name: {
          th: 'ทรายทองวัฒนา',
          en: 'Sai Thong Watthana',
        },
        tambons: {
          620801: {
            name: {
              th: 'ทุ่งทราย',
              en: 'Thung Sai',
            },
            coordinates: {
              lat: '16.305',
              lng: '99.862',
            },
            zipcode: null,
          },
          620802: {
            name: {
              th: 'ทุ่งทอง',
              en: 'Thung Thong',
            },
            coordinates: {
              lat: '16.341',
              lng: '99.899',
            },
            zipcode: null,
          },
          620803: {
            name: {
              th: 'ถาวรวัฒนา',
              en: 'Thawon Watthana',
            },
            coordinates: {
              lat: '16.287',
              lng: '99.915',
            },
            zipcode: 62190,
          },
        },
      },
      6209: {
        name: {
          th: 'ปางศิลาทอง',
          en: 'Pang Sila Thong',
        },
        tambons: {
          620901: {
            name: {
              th: 'โพธิ์ทอง',
              en: 'Pho Thong',
            },
            coordinates: {
              lat: '16.110',
              lng: '99.527',
            },
            zipcode: null,
          },
          620902: {
            name: {
              th: 'หินดาต',
              en: 'Hin Dat',
            },
            coordinates: {
              lat: '16.052',
              lng: '99.426',
            },
            zipcode: null,
          },
          620903: {
            name: {
              th: 'ปางตาไว',
              en: 'Pang Ta Wai',
            },
            coordinates: {
              lat: '16.017',
              lng: '99.164',
            },
            zipcode: null,
          },
        },
      },
      6210: {
        name: {
          th: 'บึงสามัคคี',
          en: 'Bueng Samakkhi',
        },
        tambons: {
          621001: {
            name: {
              th: 'บึงสามัคคี',
              en: 'Bueng Samakkhi',
            },
            coordinates: {
              lat: '16.285',
              lng: '99.985',
            },
            zipcode: null,
          },
          621002: {
            name: {
              th: 'วังชะโอน',
              en: 'Wang Cha-On',
            },
            coordinates: {
              lat: '16.233',
              lng: '99.926',
            },
            zipcode: null,
          },
          621003: {
            name: {
              th: 'ระหาน',
              en: 'Rahan',
            },
            coordinates: {
              lat: '16.158',
              lng: '99.941',
            },
            zipcode: null,
          },
          621004: {
            name: {
              th: 'เทพนิมิต',
              en: 'Thep Nimit',
            },
            coordinates: {
              lat: '16.182',
              lng: '99.914',
            },
            zipcode: 62210,
          },
        },
      },
      6211: {
        name: {
          th: 'โกสัมพีนคร',
          en: 'Kosamphi Nakhon',
        },
        tambons: {
          621101: {
            name: {
              th: 'โกสัมพี',
              en: 'Kosamphi',
            },
            coordinates: {
              lat: '16.501',
              lng: '99.215',
            },
            zipcode: null,
          },
          621102: {
            name: {
              th: 'เพชรชมภู',
              en: 'Phet Chomphu',
            },
            coordinates: {
              lat: '16.580',
              lng: '99.361',
            },
            zipcode: null,
          },
          621103: {
            name: {
              th: 'ลานดอกไม้ตก',
              en: 'Lan Dokmai Tok',
            },
            coordinates: {
              lat: '16.581',
              lng: '99.429',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  63: {
    name: {
      th: 'ตาก',
      en: 'Tak',
    },
    amphoes: {
      6301: {
        name: {
          th: 'เมืองตาก',
          en: 'Mueang Tak',
        },
        tambons: {
          630101: {
            name: {
              th: 'ระแหง',
              en: null,
            },
            coordinates: {
              lat: '16.862',
              lng: '99.129',
            },
            zipcode: 63000,
          },
          630105: {
            name: {
              th: 'หนองบัวเหนือ',
              en: 'Nong Bua Nuea',
            },
            coordinates: {
              lat: '16.923',
              lng: '99.049',
            },
            zipcode: 63000,
          },
          630106: {
            name: {
              th: 'ไม้งาม',
              en: 'Mai Ngam',
            },
            coordinates: {
              lat: '16.972',
              lng: '99.186',
            },
            zipcode: 63000,
          },
          630107: {
            name: {
              th: 'โป่งแดง',
              en: 'Pong Daeng',
            },
            coordinates: {
              lat: '17.059',
              lng: '99.291',
            },
            zipcode: 63000,
          },
          630108: {
            name: {
              th: 'น้ำรึม',
              en: 'Nam Ruem',
            },
            coordinates: {
              lat: '16.897',
              lng: '99.168',
            },
            zipcode: 63000,
          },
          630109: {
            name: {
              th: 'วังหิน',
              en: 'Wang Hin',
            },
            coordinates: {
              lat: '16.786',
              lng: '99.231',
            },
            zipcode: 63000,
          },
          630111: {
            name: {
              th: 'แม่ท้อ',
              en: 'Mae Tho',
            },
            coordinates: {
              lat: '16.789',
              lng: '98.967',
            },
            zipcode: 63000,
          },
          630112: {
            name: {
              th: 'ป่ามะม่วง',
              en: 'Pa Mamuang',
            },
            coordinates: {
              lat: '16.866',
              lng: '99.075',
            },
            zipcode: 63000,
          },
          630113: {
            name: {
              th: 'หนองบัวใต้',
              en: 'Nong Bua Tai',
            },
            coordinates: {
              lat: '16.795',
              lng: '99.103',
            },
            zipcode: 63000,
          },
          630114: {
            name: {
              th: 'วังประจบ',
              en: 'Wang Prachop',
            },
            coordinates: {
              lat: '16.888',
              lng: '99.373',
            },
            zipcode: 63000,
          },
          630115: {
            name: {
              th: 'ตลุกกลางทุ่ง',
              en: 'Taluk Klang Thung',
            },
            coordinates: {
              lat: '16.861',
              lng: '99.249',
            },
            zipcode: 63000,
          },
        },
      },
      6302: {
        name: {
          th: 'บ้านตาก',
          en: 'Ban Tak',
        },
        tambons: {
          630201: {
            name: {
              th: 'ตากออก',
              en: 'Tak Ok',
            },
            coordinates: {
              lat: '17.066',
              lng: '99.107',
            },
            zipcode: 63120,
          },
          630202: {
            name: {
              th: 'สมอโคน',
              en: 'Samo Khon',
            },
            coordinates: {
              lat: '17.046',
              lng: '99.183',
            },
            zipcode: 63120,
          },
          630203: {
            name: {
              th: 'แม่สลิด',
              en: 'Mae Salit',
            },
            coordinates: {
              lat: '17.295',
              lng: '99.255',
            },
            zipcode: 63120,
          },
          630204: {
            name: {
              th: 'ตากตก',
              en: 'Tak Tok',
            },
            coordinates: {
              lat: '17.001',
              lng: '99.068',
            },
            zipcode: 63120,
          },
          630205: {
            name: {
              th: 'เกาะตะเภา',
              en: 'Ko Taphao',
            },
            coordinates: {
              lat: '17.110',
              lng: '99.011',
            },
            zipcode: 63120,
          },
          630206: {
            name: {
              th: 'ทุ่งกระเซาะ',
              en: 'Thung Kracho',
            },
            coordinates: {
              lat: '16.973',
              lng: '98.967',
            },
            zipcode: 63120,
          },
          630207: {
            name: {
              th: 'ท้องฟ้า',
              en: 'Thong Fa',
            },
            coordinates: {
              lat: '17.086',
              lng: '98.918',
            },
            zipcode: 63120,
          },
        },
      },
      6303: {
        name: {
          th: 'สามเงา',
          en: 'Sam Ngao',
        },
        tambons: {
          630301: {
            name: {
              th: 'สามเงา',
              en: 'Sam Ngao',
            },
            coordinates: {
              lat: '17.250',
              lng: '99.034',
            },
            zipcode: 63130,
          },
          630302: {
            name: {
              th: 'วังหมัน',
              en: 'Wang Man',
            },
            coordinates: {
              lat: '17.201',
              lng: '99.117',
            },
            zipcode: 63130,
          },
          630303: {
            name: {
              th: 'ยกกระบัตร',
              en: 'Yok Krabat',
            },
            coordinates: {
              lat: '17.335',
              lng: '99.034',
            },
            zipcode: 63130,
          },
          630304: {
            name: {
              th: 'ย่านรี',
              en: 'Yan Ri',
            },
            coordinates: {
              lat: '17.188',
              lng: '99.022',
            },
            zipcode: 63130,
          },
          630305: {
            name: {
              th: 'บ้านนา',
              en: 'Ban Na',
            },
            coordinates: {
              lat: '17.333',
              lng: '98.864',
            },
            zipcode: 63130,
          },
          630306: {
            name: {
              th: 'วังจันทร์',
              en: 'Wang Chan',
            },
            coordinates: {
              lat: '17.275',
              lng: '99.147',
            },
            zipcode: 63130,
          },
        },
      },
      6304: {
        name: {
          th: 'แม่ระมาด',
          en: 'Mae Ramat',
        },
        tambons: {
          630401: {
            name: {
              th: 'แม่ระมาด',
              en: 'Mae Ramat',
            },
            coordinates: {
              lat: '16.971',
              lng: '98.525',
            },
            zipcode: 63140,
          },
          630402: {
            name: {
              th: 'แม่จะเรา',
              en: 'Mae Charao',
            },
            coordinates: {
              lat: '16.947',
              lng: '98.591',
            },
            zipcode: 63140,
          },
          630403: {
            name: {
              th: 'ขะเนจื้อ',
              en: 'Khane Chue',
            },
            coordinates: {
              lat: '17.055',
              lng: '98.541',
            },
            zipcode: 63140,
          },
          630404: {
            name: {
              th: 'แม่ตื่น',
              en: 'Mae Tuen',
            },
            coordinates: {
              lat: '17.194',
              lng: '98.566',
            },
            zipcode: 63140,
          },
          630405: {
            name: {
              th: 'สามหมื่น',
              en: 'Sam Muen',
            },
            coordinates: {
              lat: '17.092',
              lng: '98.698',
            },
            zipcode: 63140,
          },
          630406: {
            name: {
              th: 'พระธาตุ',
              en: 'Phrathat',
            },
            coordinates: {
              lat: '17.004',
              lng: '98.668',
            },
            zipcode: 63140,
          },
        },
      },
      6305: {
        name: {
          th: 'ท่าสองยาง',
          en: 'Tha Song Yang',
        },
        tambons: {
          630501: {
            name: {
              th: 'ท่าสองยาง',
              en: 'Tha Song Yang',
            },
            coordinates: {
              lat: '17.724',
              lng: '97.817',
            },
            zipcode: 63150,
          },
          630502: {
            name: {
              th: 'แม่ต้าน',
              en: 'Mae Tan',
            },
            coordinates: {
              lat: '17.268',
              lng: '98.275',
            },
            zipcode: 63150,
          },
          630503: {
            name: {
              th: 'แม่สอง',
              en: 'Mae Song',
            },
            coordinates: {
              lat: '17.494',
              lng: '98.151',
            },
            zipcode: 63150,
          },
          630504: {
            name: {
              th: 'แม่หละ',
              en: 'Mae La',
            },
            coordinates: {
              lat: '17.170',
              lng: '98.359',
            },
            zipcode: 63150,
          },
          630505: {
            name: {
              th: 'แม่วะหลวง',
              en: 'Mae Wa Luang',
            },
            coordinates: {
              lat: '17.763',
              lng: '97.923',
            },
            zipcode: 63150,
          },
          630506: {
            name: {
              th: 'แม่อุสุ',
              en: 'Mae Usu',
            },
            coordinates: {
              lat: '17.361',
              lng: '98.214',
            },
            zipcode: 63150,
          },
        },
      },
      6306: {
        name: {
          th: 'แม่สอด',
          en: 'Mae Sot',
        },
        tambons: {
          630601: {
            name: {
              th: 'แม่สอด',
              en: 'Mae Sot',
            },
            coordinates: {
              lat: '16.716',
              lng: '98.573',
            },
            zipcode: 63110,
          },
          630602: {
            name: {
              th: 'แม่กุ',
              en: 'Mae Ku',
            },
            coordinates: {
              lat: '16.620',
              lng: '98.651',
            },
            zipcode: 63110,
          },
          630603: {
            name: {
              th: 'พะวอ',
              en: 'Phawo',
            },
            coordinates: {
              lat: '16.671',
              lng: '98.784',
            },
            zipcode: 63110,
          },
          630604: {
            name: {
              th: 'แม่ตาว',
              en: 'Mae Tao',
            },
            coordinates: {
              lat: '16.681',
              lng: '98.577',
            },
            zipcode: 63110,
          },
          630605: {
            name: {
              th: 'แม่กาษา',
              en: 'Mae Kasa',
            },
            coordinates: {
              lat: '16.897',
              lng: '98.654',
            },
            zipcode: 63110,
          },
          630606: {
            name: {
              th: 'ท่าสายลวด',
              en: 'Tha Sai Luat',
            },
            coordinates: {
              lat: '16.696',
              lng: '98.533',
            },
            zipcode: 63110,
          },
          630607: {
            name: {
              th: 'แม่ปะ',
              en: 'Mae Pa',
            },
            coordinates: {
              lat: '16.757',
              lng: '98.586',
            },
            zipcode: 63110,
          },
          630608: {
            name: {
              th: 'มหาวัน',
              en: 'Mahawan',
            },
            coordinates: {
              lat: '16.565',
              lng: '98.676',
            },
            zipcode: 63110,
          },
          630609: {
            name: {
              th: 'ด่านแม่ละเมา',
              en: 'Dan Mae Lamao',
            },
            coordinates: {
              lat: '16.748',
              lng: '98.862',
            },
            zipcode: 63110,
          },
          630610: {
            name: {
              th: 'พระธาตุผาแดง',
              en: 'Phrathat Pha Daeng',
            },
            coordinates: {
              lat: '16.692',
              lng: '98.639',
            },
            zipcode: 63110,
          },
        },
      },
      6307: {
        name: {
          th: 'พบพระ',
          en: 'Phop Phra',
        },
        tambons: {
          630701: {
            name: {
              th: 'พบพระ',
              en: 'Phop Phra',
            },
            coordinates: {
              lat: '16.402',
              lng: '98.697',
            },
            zipcode: 63160,
          },
          630702: {
            name: {
              th: 'ช่องแคบ',
              en: 'Chong Khaep',
            },
            coordinates: {
              lat: '16.512',
              lng: '98.749',
            },
            zipcode: 63160,
          },
          630703: {
            name: {
              th: 'คีรีราษฎร์',
              en: 'Khiri Rat',
            },
            coordinates: {
              lat: '16.537',
              lng: '98.845',
            },
            zipcode: 63160,
          },
          630704: {
            name: {
              th: 'วาเล่ย์',
              en: 'Wale',
            },
            coordinates: {
              lat: '16.368',
              lng: '98.768',
            },
            zipcode: 63160,
          },
          630705: {
            name: {
              th: 'รวมไทยพัฒนา',
              en: 'Ruam Thai Phatthana',
            },
            coordinates: {
              lat: '16.440',
              lng: '98.786',
            },
            zipcode: 63160,
          },
        },
      },
      6308: {
        name: {
          th: 'อุ้มผาง',
          en: 'Um Phang',
        },
        tambons: {
          630801: {
            name: {
              th: 'อุ้มผาง',
              en: 'Um Phang',
            },
            coordinates: {
              lat: '16.019',
              lng: '98.955',
            },
            zipcode: 63170,
          },
          630802: {
            name: {
              th: 'หนองหลวง',
              en: 'Nong Luang',
            },
            coordinates: {
              lat: '16.033',
              lng: '98.739',
            },
            zipcode: null,
          },
          630803: {
            name: {
              th: 'โมโกร',
              en: 'Mo Kro',
            },
            coordinates: {
              lat: '16.220',
              lng: '98.964',
            },
            zipcode: 63170,
          },
          630804: {
            name: {
              th: 'แม่จัน',
              en: 'Mae Chan',
            },
            coordinates: {
              lat: '15.727',
              lng: '98.716',
            },
            zipcode: 63170,
          },
          630805: {
            name: {
              th: 'แม่ละมุ้ง',
              en: 'Mae Lamung',
            },
            coordinates: {
              lat: '15.641',
              lng: '98.966',
            },
            zipcode: 63170,
          },
          630806: {
            name: {
              th: 'แม่กลอง',
              en: 'Mae Klong',
            },
            coordinates: {
              lat: '16.066',
              lng: '98.834',
            },
            zipcode: 63170,
          },
        },
      },
      6309: {
        name: {
          th: 'วังเจ้า',
          en: 'Wang Chao',
        },
        tambons: {
          630901: {
            name: {
              th: 'เชียงทอง',
              en: 'Chiang Thong',
            },
            coordinates: {
              lat: '16.571',
              lng: '99.122',
            },
            zipcode: null,
          },
          630902: {
            name: {
              th: 'นาโบสถ์',
              en: 'Na Bot',
            },
            coordinates: {
              lat: '16.705',
              lng: '99.156',
            },
            zipcode: null,
          },
          630903: {
            name: {
              th: 'ประดาง',
              en: 'Pradang',
            },
            coordinates: {
              lat: '16.748',
              lng: '99.184',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  64: {
    name: {
      th: 'สุโขทัย',
      en: 'Sukhothai',
    },
    amphoes: {
      6401: {
        name: {
          th: 'เมืองสุโขทัย',
          en: 'Mueang Sukhothai',
        },
        tambons: {
          640101: {
            name: {
              th: 'ธานี',
              en: 'Thani',
            },
            coordinates: {
              lat: '17.012',
              lng: '99.822',
            },
            zipcode: 64000,
          },
          640102: {
            name: {
              th: 'บ้านสวน',
              en: 'Ban Suan',
            },
            coordinates: {
              lat: '17.045',
              lng: '99.903',
            },
            zipcode: 64220,
          },
          640103: {
            name: {
              th: 'เมืองเก่า',
              en: 'Mueang Kao',
            },
            coordinates: {
              lat: '17.021',
              lng: '99.682',
            },
            zipcode: 64210,
          },
          640104: {
            name: {
              th: 'ปากแคว',
              en: null,
            },
            coordinates: {
              lat: '17.043',
              lng: '99.824',
            },
            zipcode: 64000,
          },
          640105: {
            name: {
              th: 'ยางซ้าย',
              en: 'Yang Sai',
            },
            coordinates: {
              lat: '16.974',
              lng: '99.821',
            },
            zipcode: 64000,
          },
          640106: {
            name: {
              th: 'บ้านกล้วย',
              en: 'Ban Kluai',
            },
            coordinates: {
              lat: '17.029',
              lng: '99.776',
            },
            zipcode: 64000,
          },
          640107: {
            name: {
              th: 'บ้านหลุม',
              en: 'Ban Lum',
            },
            coordinates: {
              lat: '16.983',
              lng: '99.870',
            },
            zipcode: 64000,
          },
          640108: {
            name: {
              th: 'ตาลเตี้ย',
              en: 'Tan Tia',
            },
            coordinates: {
              lat: '17.063',
              lng: '99.870',
            },
            zipcode: 64220,
          },
          640109: {
            name: {
              th: 'ปากพระ',
              en: 'Pak Phra',
            },
            coordinates: {
              lat: '16.915',
              lng: '99.836',
            },
            zipcode: 64000,
          },
          640110: {
            name: {
              th: 'วังทองแดง',
              en: 'Wang Thong Daeng',
            },
            coordinates: {
              lat: '17.107',
              lng: '99.687',
            },
            zipcode: 64210,
          },
        },
      },
      6402: {
        name: {
          th: 'บ้านด่านลานหอย',
          en: 'Ban Dan Lan Hoi',
        },
        tambons: {
          640201: {
            name: {
              th: 'ลานหอย',
              en: 'Lan Hoi',
            },
            coordinates: {
              lat: '16.887',
              lng: '99.609',
            },
            zipcode: 64140,
          },
          640202: {
            name: {
              th: 'บ้านด่าน',
              en: 'Ban Dan',
            },
            coordinates: {
              lat: '17.048',
              lng: '99.549',
            },
            zipcode: 64140,
          },
          640203: {
            name: {
              th: 'วังตะคร้อ',
              en: 'Wang Takhro',
            },
            coordinates: {
              lat: '16.948',
              lng: '99.500',
            },
            zipcode: 64140,
          },
          640204: {
            name: {
              th: 'วังน้ำขาว',
              en: 'Wang Nam Khao',
            },
            coordinates: {
              lat: '17.096',
              lng: '99.608',
            },
            zipcode: 64140,
          },
          640205: {
            name: {
              th: 'ตลิ่งชัน',
              en: 'Taling Chan',
            },
            coordinates: {
              lat: '17.156',
              lng: '99.414',
            },
            zipcode: 64140,
          },
          640206: {
            name: {
              th: 'หนองหญ้าปล้อง',
              en: 'Nong Ya Plong',
            },
            coordinates: {
              lat: '16.995',
              lng: '99.481',
            },
            zipcode: 64140,
          },
          640207: {
            name: {
              th: 'วังลึก',
              en: 'Wang Luek',
            },
            coordinates: {
              lat: '17.040',
              lng: '99.461',
            },
            zipcode: 64120,
          },
        },
      },
      6403: {
        name: {
          th: 'คีรีมาศ',
          en: 'Khiri Mat',
        },
        tambons: {
          640301: {
            name: {
              th: 'โตนด',
              en: 'Tanot',
            },
            coordinates: {
              lat: '16.839',
              lng: '99.824',
            },
            zipcode: 64160,
          },
          640302: {
            name: {
              th: 'ทุ่งหลวง',
              en: 'Thung Luang',
            },
            coordinates: {
              lat: '16.877',
              lng: '99.774',
            },
            zipcode: 64160,
          },
          640303: {
            name: {
              th: 'บ้านป้อม',
              en: 'Ban Pom',
            },
            coordinates: {
              lat: '16.931',
              lng: '99.776',
            },
            zipcode: 64160,
          },
          640304: {
            name: {
              th: 'สามพวง',
              en: 'Sam Phuang',
            },
            coordinates: {
              lat: '16.790',
              lng: '99.785',
            },
            zipcode: 64160,
          },
          640305: {
            name: {
              th: 'ศรีคีรีมาศ',
              en: 'Si Khiri Mat',
            },
            coordinates: {
              lat: '16.827',
              lng: '99.706',
            },
            zipcode: 64160,
          },
          640306: {
            name: {
              th: 'หนองจิก',
              en: 'Nong Chik',
            },
            coordinates: {
              lat: '16.764',
              lng: '99.749',
            },
            zipcode: 64160,
          },
          640307: {
            name: {
              th: 'นาเชิงคีรี',
              en: 'Na Choeng Khiri',
            },
            coordinates: {
              lat: '16.929',
              lng: '99.688',
            },
            zipcode: 64160,
          },
          640308: {
            name: {
              th: 'หนองกระดิ่ง',
              en: 'Nong Krading',
            },
            coordinates: {
              lat: '16.798',
              lng: '99.851',
            },
            zipcode: 64160,
          },
          640309: {
            name: {
              th: 'บ้านน้ำพุ',
              en: 'Ban Namphu',
            },
            coordinates: {
              lat: '16.815',
              lng: '99.632',
            },
            zipcode: 64160,
          },
          640310: {
            name: {
              th: 'ทุ่งยางเมือง',
              en: 'Thung Yang Mueang',
            },
            coordinates: {
              lat: '16.748',
              lng: '99.834',
            },
            zipcode: 64160,
          },
        },
      },
      6404: {
        name: {
          th: 'กงไกรลาศ',
          en: 'Kong Krailat',
        },
        tambons: {
          640401: {
            name: {
              th: 'กง',
              en: 'Kong',
            },
            coordinates: {
              lat: '16.893',
              lng: '99.984',
            },
            zipcode: 64170,
          },
          640402: {
            name: {
              th: 'บ้านกร่าง',
              en: 'Ban Krang',
            },
            coordinates: {
              lat: '16.947',
              lng: '99.939',
            },
            zipcode: 64170,
          },
          640403: {
            name: {
              th: 'ไกรนอก',
              en: 'Krai Nok',
            },
            coordinates: {
              lat: '16.907',
              lng: '100.083',
            },
            zipcode: 64170,
          },
          640404: {
            name: {
              th: 'ไกรกลาง',
              en: 'Krai Klang',
            },
            coordinates: {
              lat: '16.996',
              lng: '99.997',
            },
            zipcode: 64170,
          },
          640405: {
            name: {
              th: 'ไกรใน',
              en: 'Krai Nai',
            },
            coordinates: {
              lat: '17.022',
              lng: '99.947',
            },
            zipcode: 64170,
          },
          640406: {
            name: {
              th: 'ดงเดือย',
              en: 'Dong Dueai',
            },
            coordinates: {
              lat: '16.920',
              lng: '100.011',
            },
            zipcode: 64170,
          },
          640407: {
            name: {
              th: 'ป่าแฝก',
              en: 'Pa Faek',
            },
            coordinates: {
              lat: '16.969',
              lng: '99.927',
            },
            zipcode: 64170,
          },
          640408: {
            name: {
              th: 'กกแรต',
              en: 'Kok Raet',
            },
            coordinates: {
              lat: '17.005',
              lng: '100.027',
            },
            zipcode: 64170,
          },
          640409: {
            name: {
              th: 'ท่าฉนวน',
              en: 'Tha Chanuan',
            },
            coordinates: {
              lat: '16.891',
              lng: '99.878',
            },
            zipcode: 64170,
          },
          640410: {
            name: {
              th: 'หนองตูม',
              en: 'Nong Tum',
            },
            coordinates: {
              lat: '16.880',
              lng: '99.949',
            },
            zipcode: 64170,
          },
          640411: {
            name: {
              th: 'บ้านใหม่สุขเกษม',
              en: 'Ban Mai Suk Kasem',
            },
            coordinates: {
              lat: '16.954',
              lng: '100.078',
            },
            zipcode: 64170,
          },
        },
      },
      6405: {
        name: {
          th: 'ศรีสัชนาลัย',
          en: 'Si Satchanalai',
        },
        tambons: {
          640501: {
            name: {
              th: 'หาดเสี้ยว',
              en: 'Hat Siao',
            },
            coordinates: {
              lat: '17.520',
              lng: '99.784',
            },
            zipcode: 64130,
          },
          640502: {
            name: {
              th: 'ป่างิ้ว',
              en: 'Pa Ngio',
            },
            coordinates: {
              lat: '17.550',
              lng: '99.825',
            },
            zipcode: 64130,
          },
          640503: {
            name: {
              th: 'แม่สำ',
              en: 'Mae Sam',
            },
            coordinates: {
              lat: '17.608',
              lng: '99.713',
            },
            zipcode: 64130,
          },
          640504: {
            name: {
              th: 'แม่สิน',
              en: 'Mae Sin',
            },
            coordinates: {
              lat: '17.736',
              lng: '99.585',
            },
            zipcode: 64130,
          },
          640505: {
            name: {
              th: 'บ้านตึก',
              en: 'Ban Tuek',
            },
            coordinates: {
              lat: '17.649',
              lng: '99.881',
            },
            zipcode: 64130,
          },
          640506: {
            name: {
              th: 'หนองอ้อ',
              en: 'Nong O',
            },
            coordinates: {
              lat: '17.484',
              lng: '99.751',
            },
            zipcode: 64130,
          },
          640507: {
            name: {
              th: 'ท่าชัย',
              en: 'Tha Chai',
            },
            coordinates: {
              lat: '17.444',
              lng: '99.846',
            },
            zipcode: 64190,
          },
          640508: {
            name: {
              th: 'ศรีสัชนาลัย',
              en: 'Si Satchanalai',
            },
            coordinates: {
              lat: '17.409',
              lng: '99.778',
            },
            zipcode: 64190,
          },
          640509: {
            name: {
              th: 'ดงคู่',
              en: 'Dong Khu',
            },
            coordinates: {
              lat: '17.547',
              lng: '99.899',
            },
            zipcode: 64130,
          },
          640510: {
            name: {
              th: 'บ้านแก่ง',
              en: 'Ban Kaeng',
            },
            coordinates: {
              lat: '17.449',
              lng: '99.643',
            },
            zipcode: 64130,
          },
          640511: {
            name: {
              th: 'สารจิตร',
              en: 'Sarachit',
            },
            coordinates: {
              lat: '17.420',
              lng: '99.728',
            },
            zipcode: 64130,
          },
        },
      },
      6406: {
        name: {
          th: 'ศรีสำโรง',
          en: 'Si Samrong',
        },
        tambons: {
          640601: {
            name: {
              th: 'คลองตาล',
              en: 'Khlong Tan',
            },
            coordinates: {
              lat: '17.158',
              lng: '99.858',
            },
            zipcode: 64120,
          },
          640602: {
            name: {
              th: 'วังลึก',
              en: 'Wang Luek',
            },
            coordinates: {
              lat: '17.147',
              lng: '99.883',
            },
            zipcode: null,
          },
          640603: {
            name: {
              th: 'สามเรือน',
              en: 'Sam Ruean',
            },
            coordinates: {
              lat: '17.183',
              lng: '99.872',
            },
            zipcode: 64120,
          },
          640604: {
            name: {
              th: 'บ้านนา',
              en: 'Ban Na',
            },
            coordinates: {
              lat: '17.171',
              lng: '99.835',
            },
            zipcode: 64120,
          },
          640605: {
            name: {
              th: 'วังทอง',
              en: 'Wang Thong',
            },
            coordinates: {
              lat: '17.139',
              lng: '99.811',
            },
            zipcode: 64120,
          },
          640606: {
            name: {
              th: 'นาขุนไกร',
              en: 'Na Khun Krai',
            },
            coordinates: {
              lat: '17.196',
              lng: '99.582',
            },
            zipcode: 64120,
          },
          640607: {
            name: {
              th: 'เกาะตาเลี้ยง',
              en: 'Ko Ta Liang',
            },
            coordinates: {
              lat: '17.111',
              lng: '99.908',
            },
            zipcode: 64120,
          },
          640608: {
            name: {
              th: 'วัดเกาะ',
              en: 'Wat Ko',
            },
            coordinates: {
              lat: '17.188',
              lng: '99.827',
            },
            zipcode: 64120,
          },
          640609: {
            name: {
              th: 'บ้านไร่',
              en: 'Ban Rai',
            },
            coordinates: {
              lat: '17.164',
              lng: '99.764',
            },
            zipcode: 64120,
          },
          640610: {
            name: {
              th: 'ทับผึ้ง',
              en: 'Thap Phueng',
            },
            coordinates: {
              lat: '17.084',
              lng: '99.830',
            },
            zipcode: 64120,
          },
          640611: {
            name: {
              th: 'บ้านซ่าน',
              en: 'Ban San',
            },
            coordinates: {
              lat: '17.203',
              lng: '99.770',
            },
            zipcode: 64120,
          },
          640612: {
            name: {
              th: 'วังใหญ่',
              en: 'Wang Yai',
            },
            coordinates: {
              lat: '17.118',
              lng: '99.765',
            },
            zipcode: 64120,
          },
          640613: {
            name: {
              th: 'ราวต้นจันทร์',
              en: 'Rao Ton Chan',
            },
            coordinates: {
              lat: '17.174',
              lng: '99.723',
            },
            zipcode: 64120,
          },
        },
      },
      6407: {
        name: {
          th: 'สวรรคโลก',
          en: 'Sawankhalok',
        },
        tambons: {
          640701: {
            name: {
              th: 'เมืองสวรรคโลก',
              en: 'Mueang Sawankhalok',
            },
            coordinates: {
              lat: '17.315',
              lng: '99.834',
            },
            zipcode: 64110,
          },
          640702: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '17.326',
              lng: '99.878',
            },
            zipcode: 64110,
          },
          640703: {
            name: {
              th: 'คลองกระจง',
              en: 'Khlong Krachong',
            },
            coordinates: {
              lat: '17.239',
              lng: '99.828',
            },
            zipcode: 64110,
          },
          640704: {
            name: {
              th: 'วังพิณพาทย์',
              en: 'Wang Phin Phat',
            },
            coordinates: {
              lat: '17.335',
              lng: '99.815',
            },
            zipcode: 64110,
          },
          640705: {
            name: {
              th: 'วังไม้ขอน',
              en: 'Wang Mai Khon',
            },
            coordinates: {
              lat: '17.310',
              lng: '99.807',
            },
            zipcode: 64110,
          },
          640706: {
            name: {
              th: 'ย่านยาว',
              en: 'Yan Yao',
            },
            coordinates: {
              lat: '17.273',
              lng: '99.816',
            },
            zipcode: 64110,
          },
          640707: {
            name: {
              th: 'นาทุ่ง',
              en: 'Na Thung',
            },
            coordinates: {
              lat: '17.311',
              lng: '99.740',
            },
            zipcode: 64110,
          },
          640708: {
            name: {
              th: 'คลองยาง',
              en: 'Khlong Yang',
            },
            coordinates: {
              lat: '17.414',
              lng: '99.915',
            },
            zipcode: 64110,
          },
          640709: {
            name: {
              th: 'เมืองบางยม',
              en: 'Mueang Bang Yom',
            },
            coordinates: {
              lat: '17.214',
              lng: '99.876',
            },
            zipcode: 64110,
          },
          640710: {
            name: {
              th: 'ท่าทอง',
              en: 'Tha Thong',
            },
            coordinates: {
              lat: '17.212',
              lng: '99.850',
            },
            zipcode: 64110,
          },
          640711: {
            name: {
              th: 'ปากน้ำ',
              en: 'Pak Nam',
            },
            coordinates: {
              lat: '17.233',
              lng: '99.925',
            },
            zipcode: 64110,
          },
          640712: {
            name: {
              th: 'ป่ากุมเกาะ',
              en: 'Pa Kum Ko',
            },
            coordinates: {
              lat: '17.365',
              lng: '99.794',
            },
            zipcode: 64110,
          },
          640713: {
            name: {
              th: 'เมืองบางขลัง',
              en: 'Mueang Bang Khlang',
            },
            coordinates: {
              lat: '17.242',
              lng: '99.710',
            },
            zipcode: 64110,
          },
          640714: {
            name: {
              th: 'หนองกลับ',
              en: 'Nong Klap',
            },
            coordinates: {
              lat: '17.245',
              lng: '99.782',
            },
            zipcode: 64110,
          },
        },
      },
      6408: {
        name: {
          th: 'ศรีนคร',
          en: 'Si Nakhon',
        },
        tambons: {
          640801: {
            name: {
              th: 'ศรีนคร',
              en: 'Si Nakhon',
            },
            coordinates: {
              lat: '17.373',
              lng: '99.980',
            },
            zipcode: null,
          },
          640802: {
            name: {
              th: 'นครเดิฐ',
              en: 'Nakhon Doet',
            },
            coordinates: {
              lat: '17.471',
              lng: '99.955',
            },
            zipcode: null,
          },
          640803: {
            name: {
              th: 'น้ำขุม',
              en: 'Nam Khum',
            },
            coordinates: {
              lat: '17.412',
              lng: '99.982',
            },
            zipcode: null,
          },
          640804: {
            name: {
              th: 'คลองมะพลับ',
              en: 'Khlong Maphlap',
            },
            coordinates: {
              lat: '17.319',
              lng: '99.962',
            },
            zipcode: null,
          },
          640805: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '17.285',
              lng: '99.958',
            },
            zipcode: 64180,
          },
        },
      },
      6409: {
        name: {
          th: 'ทุ่งเสลี่ยม',
          en: 'Thung Saliam',
        },
        tambons: {
          640901: {
            name: {
              th: 'บ้านใหม่ไชยมงคล',
              en: 'Ban Mai Chai Mongkhon',
            },
            coordinates: {
              lat: '17.251',
              lng: '99.652',
            },
            zipcode: 64230,
          },
          640902: {
            name: {
              th: 'ไทยชนะศึก',
              en: 'Thai Chana Suek',
            },
            coordinates: {
              lat: '17.376',
              lng: '99.589',
            },
            zipcode: 64150,
          },
          640903: {
            name: {
              th: 'ทุ่งเสลี่ยม',
              en: 'Thung Saliam',
            },
            coordinates: {
              lat: '17.301',
              lng: '99.548',
            },
            zipcode: 64150,
          },
          640904: {
            name: {
              th: 'กลางดง',
              en: 'Klang Dong',
            },
            coordinates: {
              lat: '17.394',
              lng: '99.491',
            },
            zipcode: 64150,
          },
          640905: {
            name: {
              th: 'เขาแก้วศรีสมบูรณ์',
              en: 'Khao Kaeo Si Sombun',
            },
            coordinates: {
              lat: '17.320',
              lng: '99.643',
            },
            zipcode: 64230,
          },
        },
      },
    },
  },
  65: {
    name: {
      th: 'พิษณุโลก',
      en: 'Phitsanulok',
    },
    amphoes: {
      6501: {
        name: {
          th: 'เมืองพิษณุโลก',
          en: 'Mueang Phitsanulok',
        },
        tambons: {
          650101: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '16.815',
              lng: '100.260',
            },
            zipcode: 65000,
          },
          650102: {
            name: {
              th: 'วังน้ำคู้',
              en: 'Wang Nam Khu',
            },
            coordinates: {
              lat: '16.652',
              lng: '100.269',
            },
            zipcode: 65230,
          },
          650103: {
            name: {
              th: 'วัดจันทร์',
              en: 'Wat Chan',
            },
            coordinates: {
              lat: '16.812',
              lng: '100.225',
            },
            zipcode: 65000,
          },
          650104: {
            name: {
              th: 'วัดพริก',
              en: 'Wat Phrik',
            },
            coordinates: {
              lat: '16.715',
              lng: '100.243',
            },
            zipcode: 65230,
          },
          650105: {
            name: {
              th: 'ท่าทอง',
              en: 'Tha Thong',
            },
            coordinates: {
              lat: '16.798',
              lng: '100.197',
            },
            zipcode: 65000,
          },
          650106: {
            name: {
              th: 'ท่าโพธิ์',
              en: 'Tha Pho',
            },
            coordinates: {
              lat: '16.758',
              lng: '100.195',
            },
            zipcode: 65000,
          },
          650107: {
            name: {
              th: 'สมอแข',
              en: 'Samo Khae',
            },
            coordinates: {
              lat: '16.823',
              lng: '100.325',
            },
            zipcode: 65000,
          },
          650108: {
            name: {
              th: 'ดอนทอง',
              en: 'Don Thong',
            },
            coordinates: {
              lat: '16.884',
              lng: '100.392',
            },
            zipcode: 65000,
          },
          650109: {
            name: {
              th: 'บ้านป่า',
              en: 'Ban Pa',
            },
            coordinates: {
              lat: '16.926',
              lng: '100.368',
            },
            zipcode: 65000,
          },
          650110: {
            name: {
              th: 'ปากโทก',
              en: 'Pak Thok',
            },
            coordinates: {
              lat: '16.905',
              lng: '100.259',
            },
            zipcode: 65000,
          },
          650111: {
            name: {
              th: 'หัวรอ',
              en: 'Hua Ro',
            },
            coordinates: {
              lat: '16.875',
              lng: '100.274',
            },
            zipcode: 65000,
          },
          650112: {
            name: {
              th: 'จอมทอง',
              en: 'Chom Thong',
            },
            coordinates: {
              lat: '16.902',
              lng: '100.215',
            },
            zipcode: 65000,
          },
          650113: {
            name: {
              th: 'บ้านกร่าง',
              en: 'Ban Krang',
            },
            coordinates: {
              lat: '16.855',
              lng: '100.184',
            },
            zipcode: 65000,
          },
          650114: {
            name: {
              th: 'บ้านคลอง',
              en: 'Ban Khlong',
            },
            coordinates: {
              lat: '16.830',
              lng: '100.239',
            },
            zipcode: 65000,
          },
          650115: {
            name: {
              th: 'พลายชุมพล',
              en: 'Phlai Chumphon',
            },
            coordinates: {
              lat: '16.854',
              lng: '100.226',
            },
            zipcode: 65000,
          },
          650116: {
            name: {
              th: 'มะขามสูง',
              en: 'Makham Sung',
            },
            coordinates: {
              lat: '16.929',
              lng: '100.256',
            },
            zipcode: 65000,
          },
          650117: {
            name: {
              th: 'อรัญญิก',
              en: 'Aranyik',
            },
            coordinates: {
              lat: '16.800',
              lng: '100.288',
            },
            zipcode: 65000,
          },
          650118: {
            name: {
              th: 'บึงพระ',
              en: 'Bueng Phra',
            },
            coordinates: {
              lat: '16.749',
              lng: '100.273',
            },
            zipcode: 65000,
          },
          650119: {
            name: {
              th: 'ไผ่ขอดอน',
              en: 'Phai Kho Don',
            },
            coordinates: {
              lat: '16.911',
              lng: '100.158',
            },
            zipcode: 65000,
          },
          650120: {
            name: {
              th: 'งิ้วงาม',
              en: 'Ngio Ngam',
            },
            coordinates: {
              lat: '16.671',
              lng: '100.237',
            },
            zipcode: 65230,
          },
        },
      },
      6502: {
        name: {
          th: 'นครไทย',
          en: 'Nakhon Thai',
        },
        tambons: {
          650201: {
            name: {
              th: 'นครไทย',
              en: 'Nakhon Thai',
            },
            coordinates: {
              lat: '17.126',
              lng: '100.839',
            },
            zipcode: 65120,
          },
          650202: {
            name: {
              th: 'หนองกะท้าว',
              en: 'Nong Kathao',
            },
            coordinates: {
              lat: '17.096',
              lng: '100.674',
            },
            zipcode: 65120,
          },
          650203: {
            name: {
              th: 'บ้านแยง',
              en: 'Ban Yaeng',
            },
            coordinates: {
              lat: '16.942',
              lng: '100.710',
            },
            zipcode: 65120,
          },
          650204: {
            name: {
              th: 'เนินเพิ่ม',
              en: 'Noen Phoem',
            },
            coordinates: {
              lat: '17.033',
              lng: '100.946',
            },
            zipcode: 65120,
          },
          650205: {
            name: {
              th: 'นาบัว',
              en: 'Na Bua',
            },
            coordinates: {
              lat: '17.203',
              lng: '100.851',
            },
            zipcode: 65120,
          },
          650206: {
            name: {
              th: 'นครชุม',
              en: 'Nakhon Chum',
            },
            coordinates: {
              lat: '17.260',
              lng: '100.806',
            },
            zipcode: 65120,
          },
          650207: {
            name: {
              th: 'น้ำกุ่ม',
              en: 'Nam Kum',
            },
            coordinates: {
              lat: '17.398',
              lng: '100.866',
            },
            zipcode: 65120,
          },
          650208: {
            name: {
              th: 'ยางโกลน',
              en: 'Yang Klon',
            },
            coordinates: {
              lat: '17.207',
              lng: '100.950',
            },
            zipcode: 65120,
          },
          650209: {
            name: {
              th: 'บ่อโพธิ์',
              en: 'Bo Pho',
            },
            coordinates: {
              lat: '17.161',
              lng: '101.017',
            },
            zipcode: 65120,
          },
          650210: {
            name: {
              th: 'บ้านพร้าว',
              en: 'Ban Phrao',
            },
            coordinates: {
              lat: '17.146',
              lng: '100.760',
            },
            zipcode: 65120,
          },
          650211: {
            name: {
              th: 'ห้วยเฮี้ย',
              en: 'Huai Hia',
            },
            coordinates: {
              lat: '16.899',
              lng: '100.923',
            },
            zipcode: 65120,
          },
        },
      },
      6503: {
        name: {
          th: 'ชาติตระการ',
          en: 'Chat Trakan',
        },
        tambons: {
          650301: {
            name: {
              th: 'ป่าแดง',
              en: 'Pa Daeng',
            },
            coordinates: {
              lat: '17.285',
              lng: '100.561',
            },
            zipcode: 65170,
          },
          650302: {
            name: {
              th: 'ชาติตระการ',
              en: 'Chat Trakan',
            },
            coordinates: {
              lat: '17.320',
              lng: '100.704',
            },
            zipcode: 65170,
          },
          650303: {
            name: {
              th: 'สวนเมี่ยง',
              en: 'Suan Miang',
            },
            coordinates: {
              lat: '17.194',
              lng: '100.529',
            },
            zipcode: 65170,
          },
          650304: {
            name: {
              th: 'บ้านดง',
              en: 'Ban Dong',
            },
            coordinates: {
              lat: '17.361',
              lng: '100.505',
            },
            zipcode: 65170,
          },
          650305: {
            name: {
              th: 'บ่อภาค',
              en: 'Bo Phak',
            },
            coordinates: {
              lat: '17.540',
              lng: '100.817',
            },
            zipcode: 65170,
          },
          650306: {
            name: {
              th: 'ท่าสะแก',
              en: 'Tha Sakae',
            },
            coordinates: {
              lat: '17.241',
              lng: '100.711',
            },
            zipcode: 65170,
          },
        },
      },
      6504: {
        name: {
          th: 'บางระกำ',
          en: 'Bang Rakam',
        },
        tambons: {
          650401: {
            name: {
              th: 'บางระกำ',
              en: 'Bang Rakam',
            },
            coordinates: {
              lat: '16.738',
              lng: '100.145',
            },
            zipcode: 65140,
          },
          650402: {
            name: {
              th: 'ปลักแรด',
              en: 'Plak Raet',
            },
            coordinates: {
              lat: '16.693',
              lng: '100.094',
            },
            zipcode: 65140,
          },
          650403: {
            name: {
              th: 'พันเสา',
              en: 'Phan Sao',
            },
            coordinates: {
              lat: '16.622',
              lng: '100.064',
            },
            zipcode: 65140,
          },
          650404: {
            name: {
              th: 'วังอิทก',
              en: 'Wang I Thok',
            },
            coordinates: {
              lat: '16.665',
              lng: '100.164',
            },
            zipcode: 65140,
          },
          650405: {
            name: {
              th: 'บึงกอก',
              en: 'Bueng Kok',
            },
            coordinates: {
              lat: '16.700',
              lng: '100.011',
            },
            zipcode: 65140,
          },
          650406: {
            name: {
              th: 'หนองกุลา',
              en: 'Nong Kula',
            },
            coordinates: {
              lat: '16.636',
              lng: '99.960',
            },
            zipcode: 65140,
          },
          650407: {
            name: {
              th: 'ชุมแสงสงคราม',
              en: 'Chum Saeng Songkhram',
            },
            coordinates: {
              lat: '16.760',
              lng: '99.977',
            },
            zipcode: 65240,
          },
          650408: {
            name: {
              th: 'นิคมพัฒนา',
              en: 'Nikhom Phatthana',
            },
            coordinates: {
              lat: '16.741',
              lng: '99.885',
            },
            zipcode: 65140,
          },
          650409: {
            name: {
              th: 'บ่อทอง',
              en: 'Bo Thong',
            },
            coordinates: {
              lat: '16.631',
              lng: '100.134',
            },
            zipcode: 65140,
          },
          650410: {
            name: {
              th: 'ท่านางงาม',
              en: 'Tha Nang Ngam',
            },
            coordinates: {
              lat: '16.810',
              lng: '100.114',
            },
            zipcode: 65140,
          },
          650411: {
            name: {
              th: 'คุยม่วง',
              en: 'Khui Muang',
            },
            coordinates: {
              lat: '16.820',
              lng: '99.962',
            },
            zipcode: 65240,
          },
        },
      },
      6505: {
        name: {
          th: 'บางกระทุ่ม',
          en: 'Bang Krathum',
        },
        tambons: {
          650501: {
            name: {
              th: 'บางกระทุ่ม',
              en: 'Bang Krathum',
            },
            coordinates: {
              lat: '16.591',
              lng: '100.297',
            },
            zipcode: 65110,
          },
          650502: {
            name: {
              th: 'บ้านไร่',
              en: 'Ban Rai',
            },
            coordinates: {
              lat: '16.620',
              lng: '100.253',
            },
            zipcode: 65110,
          },
          650503: {
            name: {
              th: 'โคกสลุด',
              en: 'Khok Salut',
            },
            coordinates: {
              lat: '16.577',
              lng: '100.245',
            },
            zipcode: 65110,
          },
          650504: {
            name: {
              th: 'สนามคลี',
              en: 'Sanam Khli',
            },
            coordinates: {
              lat: '16.547',
              lng: '100.256',
            },
            zipcode: 65110,
          },
          650505: {
            name: {
              th: 'ท่าตาล',
              en: 'Tha Tan',
            },
            coordinates: {
              lat: '16.658',
              lng: '100.331',
            },
            zipcode: 65110,
          },
          650506: {
            name: {
              th: 'ไผ่ล้อม',
              en: 'Phai Lom',
            },
            coordinates: {
              lat: '16.560',
              lng: '100.347',
            },
            zipcode: 65110,
          },
          650507: {
            name: {
              th: 'นครป่าหมาก',
              en: 'Nakhon Pa Mak',
            },
            coordinates: {
              lat: '16.609',
              lng: '100.351',
            },
            zipcode: 65110,
          },
          650508: {
            name: {
              th: 'เนินกุ่ม',
              en: 'Noen Kum',
            },
            coordinates: {
              lat: '16.541',
              lng: '100.458',
            },
            zipcode: 65210,
          },
          650509: {
            name: {
              th: 'วัดตายม',
              en: 'Wat Ta Yom',
            },
            coordinates: {
              lat: '16.595',
              lng: '100.444',
            },
            zipcode: 65210,
          },
        },
      },
      6506: {
        name: {
          th: 'พรหมพิราม',
          en: 'Phrom Phiram',
        },
        tambons: {
          650601: {
            name: {
              th: 'พรหมพิราม',
              en: 'Phrom Phiram',
            },
            coordinates: {
              lat: '17.004',
              lng: '100.135',
            },
            zipcode: 65150,
          },
          650602: {
            name: {
              th: 'ท่าช้าง',
              en: 'Tha Chang',
            },
            coordinates: {
              lat: '16.976',
              lng: '100.168',
            },
            zipcode: 65150,
          },
          650603: {
            name: {
              th: 'วงฆ้อง',
              en: 'Wong Khong',
            },
            coordinates: {
              lat: '17.113',
              lng: '100.107',
            },
            zipcode: 65180,
          },
          650604: {
            name: {
              th: 'มะตูม',
              en: 'Matum',
            },
            coordinates: {
              lat: '16.936',
              lng: '100.205',
            },
            zipcode: 65150,
          },
          650605: {
            name: {
              th: 'หอกลอง',
              en: 'Ho Klong',
            },
            coordinates: {
              lat: '16.985',
              lng: '100.251',
            },
            zipcode: 65150,
          },
          650606: {
            name: {
              th: 'ศรีภิรมย์',
              en: 'Si Phirom',
            },
            coordinates: {
              lat: '17.094',
              lng: '100.029',
            },
            zipcode: 65180,
          },
          650607: {
            name: {
              th: 'ตลุกเทียม',
              en: 'Taluk Thiam',
            },
            coordinates: {
              lat: '17.149',
              lng: '100.039',
            },
            zipcode: 65180,
          },
          650608: {
            name: {
              th: 'วังวน',
              en: 'Wang Won',
            },
            coordinates: {
              lat: '17.050',
              lng: '100.029',
            },
            zipcode: 65150,
          },
          650609: {
            name: {
              th: 'หนองแขม',
              en: 'Nong Khaem',
            },
            coordinates: {
              lat: '17.046',
              lng: '100.131',
            },
            zipcode: 65150,
          },
          650610: {
            name: {
              th: 'มะต้อง',
              en: 'Matong',
            },
            coordinates: {
              lat: '17.086',
              lng: '100.166',
            },
            zipcode: 65180,
          },
          650611: {
            name: {
              th: 'ทับยายเชียง',
              en: 'Thap Yai Chiang',
            },
            coordinates: {
              lat: '17.095',
              lng: '100.294',
            },
            zipcode: 65150,
          },
          650612: {
            name: {
              th: 'ดงประคำ',
              en: 'Dong Prakham',
            },
            coordinates: {
              lat: '17.189',
              lng: '100.260',
            },
            zipcode: 65180,
          },
        },
      },
      6507: {
        name: {
          th: 'วัดโบสถ์',
          en: 'Wat Bot',
        },
        tambons: {
          650701: {
            name: {
              th: 'วัดโบสถ์',
              en: 'Wat Bot',
            },
            coordinates: {
              lat: '17.021',
              lng: '100.306',
            },
            zipcode: 65160,
          },
          650702: {
            name: {
              th: 'ท่างาม',
              en: 'Tha Ngam',
            },
            coordinates: {
              lat: '16.970',
              lng: '100.377',
            },
            zipcode: 65160,
          },
          650703: {
            name: {
              th: 'ท้อแท้',
              en: 'Tho Thae',
            },
            coordinates: {
              lat: '16.968',
              lng: '100.296',
            },
            zipcode: 65160,
          },
          650704: {
            name: {
              th: 'บ้านยาง',
              en: 'Ban Yang',
            },
            coordinates: {
              lat: '17.089',
              lng: '100.413',
            },
            zipcode: 65160,
          },
          650705: {
            name: {
              th: 'หินลาด',
              en: 'Hin Lat',
            },
            coordinates: {
              lat: '17.168',
              lng: '100.333',
            },
            zipcode: 65160,
          },
          650706: {
            name: {
              th: 'คันโช้ง',
              en: 'Khan Chong',
            },
            coordinates: {
              lat: '17.307',
              lng: '100.298',
            },
            zipcode: 65160,
          },
        },
      },
      6508: {
        name: {
          th: 'วังทอง',
          en: 'Wang Thong',
        },
        tambons: {
          650801: {
            name: {
              th: 'วังทอง',
              en: 'Wang Thong',
            },
            coordinates: {
              lat: '16.822',
              lng: '100.403',
            },
            zipcode: 65130,
          },
          650802: {
            name: {
              th: 'พันชาลี',
              en: 'Phan Chali',
            },
            coordinates: {
              lat: '16.608',
              lng: '100.523',
            },
            zipcode: 65130,
          },
          650803: {
            name: {
              th: 'แม่ระกา',
              en: 'Mae Raka',
            },
            coordinates: {
              lat: '16.707',
              lng: '100.362',
            },
            zipcode: 65130,
          },
          650804: {
            name: {
              th: 'บ้านกลาง',
              en: 'Ban Klang',
            },
            coordinates: {
              lat: '17.017',
              lng: '100.549',
            },
            zipcode: 65220,
          },
          650805: {
            name: {
              th: 'วังพิกุล',
              en: 'Wang Phikun',
            },
            coordinates: {
              lat: '16.775',
              lng: '100.358',
            },
            zipcode: 65130,
          },
          650806: {
            name: {
              th: 'แก่งโสภา',
              en: 'Kaeng Sopha',
            },
            coordinates: {
              lat: '16.907',
              lng: '100.625',
            },
            zipcode: 65220,
          },
          650807: {
            name: {
              th: 'ท่าหมื่นราม',
              en: 'Tha Muen Ram',
            },
            coordinates: {
              lat: '16.691',
              lng: '100.514',
            },
            zipcode: 65130,
          },
          650808: {
            name: {
              th: 'วังนกแอ่น',
              en: 'Wang Nok Aen',
            },
            coordinates: {
              lat: '16.819',
              lng: '100.582',
            },
            zipcode: 65130,
          },
          650809: {
            name: {
              th: 'หนองพระ',
              en: 'Nong Phra',
            },
            coordinates: {
              lat: '16.681',
              lng: '100.441',
            },
            zipcode: 65130,
          },
          650810: {
            name: {
              th: 'ชัยนาม',
              en: 'Chai Nam',
            },
            coordinates: {
              lat: '16.839',
              lng: '100.467',
            },
            zipcode: 65130,
          },
          650811: {
            name: {
              th: 'ดินทอง',
              en: 'Din Thong',
            },
            coordinates: {
              lat: '16.767',
              lng: '100.445',
            },
            zipcode: 65130,
          },
        },
      },
      6509: {
        name: {
          th: 'เนินมะปราง',
          en: 'Noen Maprang',
        },
        tambons: {
          650901: {
            name: {
              th: 'ชมพู',
              en: 'Chomphu',
            },
            coordinates: {
              lat: '16.717',
              lng: '100.641',
            },
            zipcode: null,
          },
          650902: {
            name: {
              th: 'บ้านมุง',
              en: 'Ban Mung',
            },
            coordinates: {
              lat: '16.543',
              lng: '100.752',
            },
            zipcode: null,
          },
          650903: {
            name: {
              th: 'ไทรย้อย',
              en: 'Sai Yoi',
            },
            coordinates: {
              lat: '16.452',
              lng: '100.634',
            },
            zipcode: null,
          },
          650904: {
            name: {
              th: 'วังโพรง',
              en: 'Wang Phrong',
            },
            coordinates: {
              lat: '16.383',
              lng: '100.664',
            },
            zipcode: null,
          },
          650905: {
            name: {
              th: 'บ้านน้อยซุ้มขี้เหล็ก',
              en: 'Ban Noi Sum Khi Lek',
            },
            coordinates: {
              lat: '16.542',
              lng: '100.596',
            },
            zipcode: null,
          },
          650906: {
            name: {
              th: 'เนินมะปราง',
              en: 'Noen Maprang',
            },
            coordinates: {
              lat: '16.595',
              lng: '100.626',
            },
            zipcode: 65190,
          },
          650907: {
            name: {
              th: 'วังยาง',
              en: 'Wang Yang',
            },
            coordinates: {
              lat: '16.457',
              lng: '100.741',
            },
            zipcode: 65190,
          },
        },
      },
    },
  },
  66: {
    name: {
      th: 'พิจิตร',
      en: 'Phichit',
    },
    amphoes: {
      6601: {
        name: {
          th: 'เมืองพิจิตร',
          en: 'Mueang Phichit',
        },
        tambons: {
          660101: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '16.443',
              lng: '100.343',
            },
            zipcode: 66000,
          },
          660102: {
            name: {
              th: 'ไผ่ขวาง',
              en: null,
            },
            coordinates: {
              lat: '16.504',
              lng: '100.303',
            },
            zipcode: 66000,
          },
          660103: {
            name: {
              th: 'ย่านยาว',
              en: null,
            },
            coordinates: {
              lat: '16.507',
              lng: '100.268',
            },
            zipcode: 66000,
          },
          660104: {
            name: {
              th: 'ท่าฬอ',
              en: null,
            },
            coordinates: {
              lat: '16.510',
              lng: '100.352',
            },
            zipcode: 66000,
          },
          660105: {
            name: {
              th: 'ปากทาง',
              en: null,
            },
            coordinates: {
              lat: '16.453',
              lng: '100.366',
            },
            zipcode: 66000,
          },
          660106: {
            name: {
              th: 'คลองคะเชนทร์',
              en: null,
            },
            coordinates: {
              lat: '16.458',
              lng: '100.293',
            },
            zipcode: 66000,
          },
          660107: {
            name: {
              th: 'โรงช้าง',
              en: null,
            },
            coordinates: {
              lat: '16.422',
              lng: '100.259',
            },
            zipcode: 66000,
          },
          660108: {
            name: {
              th: 'เมืองเก่า',
              en: null,
            },
            coordinates: {
              lat: '16.393',
              lng: '100.303',
            },
            zipcode: 66000,
          },
          660109: {
            name: {
              th: 'ท่าหลวง',
              en: null,
            },
            coordinates: {
              lat: '16.419',
              lng: '100.363',
            },
            zipcode: 66000,
          },
          660110: {
            name: {
              th: 'บ้านบุ่ง',
              en: null,
            },
            coordinates: {
              lat: '16.394',
              lng: '100.427',
            },
            zipcode: 66000,
          },
          660111: {
            name: {
              th: 'ฆะมัง',
              en: null,
            },
            coordinates: {
              lat: '16.369',
              lng: '100.373',
            },
            zipcode: 66000,
          },
          660112: {
            name: {
              th: 'ดงป่าคำ',
              en: null,
            },
            coordinates: {
              lat: '16.295',
              lng: '100.367',
            },
            zipcode: 66170,
          },
          660113: {
            name: {
              th: 'หัวดง',
              en: null,
            },
            coordinates: {
              lat: '16.332',
              lng: '100.441',
            },
            zipcode: 66170,
          },
          660115: {
            name: {
              th: 'ป่ามะคาบ',
              en: null,
            },
            coordinates: {
              lat: '16.501',
              lng: '100.392',
            },
            zipcode: 66000,
          },
          660119: {
            name: {
              th: 'สายคำโห้',
              en: null,
            },
            coordinates: {
              lat: '16.407',
              lng: '100.486',
            },
            zipcode: 66000,
          },
          660120: {
            name: {
              th: 'ดงกลาง',
              en: null,
            },
            coordinates: {
              lat: '16.335',
              lng: '100.363',
            },
            zipcode: 66170,
          },
        },
      },
      6602: {
        name: {
          th: 'วังทรายพูน',
          en: 'Wang Sai Phun',
        },
        tambons: {
          660201: {
            name: {
              th: 'วังทรายพูน',
              en: 'Wang Sai Phun',
            },
            coordinates: {
              lat: '16.383',
              lng: '100.605',
            },
            zipcode: null,
          },
          660202: {
            name: {
              th: 'หนองปลาไหล',
              en: 'Nong Pla Lai',
            },
            coordinates: {
              lat: '16.443',
              lng: '100.535',
            },
            zipcode: null,
          },
          660203: {
            name: {
              th: 'หนองพระ',
              en: 'Nong Phra',
            },
            coordinates: {
              lat: '16.342',
              lng: '100.566',
            },
            zipcode: null,
          },
          660204: {
            name: {
              th: 'หนองปล้อง',
              en: 'Nong Plong',
            },
            coordinates: {
              lat: '16.339',
              lng: '100.504',
            },
            zipcode: null,
          },
        },
      },
      6603: {
        name: {
          th: 'โพธิ์ประทับช้าง',
          en: 'Pho Prathap Chang',
        },
        tambons: {
          660301: {
            name: {
              th: 'โพธิ์ประทับช้าง',
              en: 'Pho Prathap Chang',
            },
            coordinates: {
              lat: '16.323',
              lng: '100.300',
            },
            zipcode: null,
          },
          660302: {
            name: {
              th: 'ไผ่ท่าโพ',
              en: 'Phai Tha Pho',
            },
            coordinates: {
              lat: '16.278',
              lng: '100.235',
            },
            zipcode: null,
          },
          660303: {
            name: {
              th: 'วังจิก',
              en: 'Wang Chik',
            },
            coordinates: {
              lat: '16.351',
              lng: '100.264',
            },
            zipcode: null,
          },
          660304: {
            name: {
              th: 'ไผ่รอบ',
              en: 'Phai Rop',
            },
            coordinates: {
              lat: '16.354',
              lng: '100.152',
            },
            zipcode: null,
          },
          660305: {
            name: {
              th: 'ดงเสือเหลือง',
              en: 'Dong Suea Lueang',
            },
            coordinates: {
              lat: '16.270',
              lng: '100.182',
            },
            zipcode: 66190,
          },
          660306: {
            name: {
              th: 'เนินสว่าง',
              en: 'Noen Sawang',
            },
            coordinates: {
              lat: '16.318',
              lng: '100.138',
            },
            zipcode: 66190,
          },
          660307: {
            name: {
              th: 'ทุ่งใหญ่',
              en: 'Thung Yai',
            },
            coordinates: {
              lat: '16.292',
              lng: '100.071',
            },
            zipcode: 66190,
          },
        },
      },
      6604: {
        name: {
          th: 'ตะพานหิน',
          en: 'Taphan Hin',
        },
        tambons: {
          660401: {
            name: {
              th: 'ตะพานหิน',
              en: 'Taphan Hin',
            },
            coordinates: {
              lat: '16.222',
              lng: '100.427',
            },
            zipcode: 66110,
          },
          660402: {
            name: {
              th: 'งิ้วราย',
              en: 'Ngio Rai',
            },
            coordinates: {
              lat: '16.267',
              lng: '100.427',
            },
            zipcode: 66110,
          },
          660403: {
            name: {
              th: 'ห้วยเกตุ',
              en: 'Huai Ket',
            },
            coordinates: {
              lat: '16.238',
              lng: '100.399',
            },
            zipcode: 66110,
          },
          660404: {
            name: {
              th: 'ไทรโรงโขน',
              en: 'Sai Rong Khon',
            },
            coordinates: {
              lat: '16.168',
              lng: '100.421',
            },
            zipcode: 66110,
          },
          660405: {
            name: {
              th: 'หนองพยอม',
              en: 'Nong Phayom',
            },
            coordinates: {
              lat: '16.235',
              lng: '100.460',
            },
            zipcode: 66110,
          },
          660406: {
            name: {
              th: 'ทุ่งโพธิ์',
              en: 'Thung Pho',
            },
            coordinates: {
              lat: '16.129',
              lng: '100.501',
            },
            zipcode: 66150,
          },
          660407: {
            name: {
              th: 'ดงตะขบ',
              en: 'Dong Takhop',
            },
            coordinates: {
              lat: '16.144',
              lng: '100.467',
            },
            zipcode: 66110,
          },
          660408: {
            name: {
              th: 'คลองคูณ',
              en: 'Khlong Khun',
            },
            coordinates: {
              lat: '16.161',
              lng: '100.361',
            },
            zipcode: 66110,
          },
          660409: {
            name: {
              th: 'วังสำโรง',
              en: 'Wang Samrong',
            },
            coordinates: {
              lat: '16.252',
              lng: '100.308',
            },
            zipcode: 66120,
          },
          660410: {
            name: {
              th: 'วังหว้า',
              en: 'Wang Wa',
            },
            coordinates: {
              lat: '16.207',
              lng: '100.354',
            },
            zipcode: 66110,
          },
          660411: {
            name: {
              th: 'วังหลุม',
              en: 'Wang Lum',
            },
            coordinates: {
              lat: '16.190',
              lng: '100.484',
            },
            zipcode: 66150,
          },
          660412: {
            name: {
              th: 'ทับหมัน',
              en: 'Thap Man',
            },
            coordinates: {
              lat: '16.207',
              lng: '100.277',
            },
            zipcode: 66110,
          },
          660413: {
            name: {
              th: 'ไผ่หลวง',
              en: 'Phai Luang',
            },
            coordinates: {
              lat: '16.193',
              lng: '100.428',
            },
            zipcode: 66110,
          },
        },
      },
      6605: {
        name: {
          th: 'บางมูลนาก',
          en: 'Bang Mun Nak',
        },
        tambons: {
          660502: {
            name: {
              th: 'บางไผ่',
              en: 'Bang Phai',
            },
            coordinates: {
              lat: '16.123',
              lng: '100.403',
            },
            zipcode: 66120,
          },
          660503: {
            name: {
              th: 'หอไกร',
              en: 'Ho Krai',
            },
            coordinates: {
              lat: '16.068',
              lng: '100.381',
            },
            zipcode: 66120,
          },
          660504: {
            name: {
              th: 'เนินมะกอก',
              en: 'Noen Makok',
            },
            coordinates: {
              lat: '15.991',
              lng: '100.362',
            },
            zipcode: 66120,
          },
          660505: {
            name: {
              th: 'วังสำโรง',
              en: 'Wang Samrong',
            },
            coordinates: {
              lat: '15.968',
              lng: '100.433',
            },
            zipcode: null,
          },
          660506: {
            name: {
              th: 'ภูมิ',
              en: 'Phum',
            },
            coordinates: {
              lat: '16.037',
              lng: '100.445',
            },
            zipcode: 66120,
          },
          660507: {
            name: {
              th: 'วังกรด',
              en: 'Wang Krot',
            },
            coordinates: {
              lat: '15.997',
              lng: '100.465',
            },
            zipcode: 66120,
          },
          660508: {
            name: {
              th: 'ห้วยเขน',
              en: 'Huai Khen',
            },
            coordinates: {
              lat: '16.033',
              lng: '100.479',
            },
            zipcode: 66120,
          },
          660509: {
            name: {
              th: 'วังตะกู',
              en: 'Wang Taku',
            },
            coordinates: {
              lat: '16.049',
              lng: '100.514',
            },
            zipcode: 66210,
          },
          660514: {
            name: {
              th: 'ลำประดา',
              en: 'Lam Prada',
            },
            coordinates: {
              lat: '16.082',
              lng: '100.464',
            },
            zipcode: 66120,
          },
        },
      },
      6606: {
        name: {
          th: 'โพทะเล',
          en: 'Pho Thale',
        },
        tambons: {
          660601: {
            name: {
              th: 'โพทะเล',
              en: 'Pho Thale',
            },
            coordinates: {
              lat: '16.103',
              lng: '100.236',
            },
            zipcode: 66130,
          },
          660602: {
            name: {
              th: 'ท้ายน้ำ',
              en: 'Thai Nam',
            },
            coordinates: {
              lat: '16.147',
              lng: '100.207',
            },
            zipcode: 66130,
          },
          660603: {
            name: {
              th: 'ทะนง',
              en: 'Tha Nong',
            },
            coordinates: {
              lat: '16.078',
              lng: '100.180',
            },
            zipcode: 66130,
          },
          660604: {
            name: {
              th: 'ท่าบัว',
              en: 'Tha Bua',
            },
            coordinates: {
              lat: '16.059',
              lng: '100.326',
            },
            zipcode: 66130,
          },
          660605: {
            name: {
              th: 'ทุ่งน้อย',
              en: 'Thung Noi',
            },
            coordinates: {
              lat: '16.117',
              lng: '100.315',
            },
            zipcode: 66130,
          },
          660606: {
            name: {
              th: 'ท่าขมิ้น',
              en: 'Tha Khamin',
            },
            coordinates: {
              lat: '16.025',
              lng: '100.200',
            },
            zipcode: 66130,
          },
          660607: {
            name: {
              th: 'ท่าเสา',
              en: 'Tha Sao',
            },
            coordinates: {
              lat: '15.966',
              lng: '100.212',
            },
            zipcode: 66130,
          },
          660608: {
            name: {
              th: 'บางคลาน',
              en: 'Bang Khlan',
            },
            coordinates: {
              lat: '16.001',
              lng: '100.286',
            },
            zipcode: 66130,
          },
          660611: {
            name: {
              th: 'ท่านั่ง',
              en: 'Tha Nang',
            },
            coordinates: {
              lat: '15.963',
              lng: '100.276',
            },
            zipcode: 66130,
          },
          660612: {
            name: {
              th: 'บ้านน้อย',
              en: 'Ban Noi',
            },
            coordinates: {
              lat: '16.043',
              lng: '100.288',
            },
            zipcode: 66130,
          },
          660613: {
            name: {
              th: 'วัดขวาง',
              en: 'Wat Khwang',
            },
            coordinates: {
              lat: '16.160',
              lng: '100.298',
            },
            zipcode: 66130,
          },
        },
      },
      6607: {
        name: {
          th: 'สามง่าม',
          en: 'Sam Ngam',
        },
        tambons: {
          660701: {
            name: {
              th: 'สามง่าม',
              en: 'Sam Ngam',
            },
            coordinates: {
              lat: '16.498',
              lng: '100.213',
            },
            zipcode: 66140,
          },
          660702: {
            name: {
              th: 'กำแพงดิน',
              en: 'Kamphaeng Din',
            },
            coordinates: {
              lat: '16.585',
              lng: '100.219',
            },
            zipcode: 66220,
          },
          660703: {
            name: {
              th: 'รังนก',
              en: 'Rang Nok',
            },
            coordinates: {
              lat: '16.432',
              lng: '100.212',
            },
            zipcode: 66140,
          },
          660706: {
            name: {
              th: 'เนินปอ',
              en: 'Noen Po',
            },
            coordinates: {
              lat: '16.408',
              lng: '100.136',
            },
            zipcode: 66140,
          },
          660707: {
            name: {
              th: 'หนองโสน',
              en: 'Nong Sano',
            },
            coordinates: {
              lat: '16.389',
              lng: '100.044',
            },
            zipcode: 66140,
          },
        },
      },
      6608: {
        name: {
          th: 'ทับคล้อ',
          en: 'Tap Khlo',
        },
        tambons: {
          660801: {
            name: {
              th: 'ทับคล้อ',
              en: 'Thap Khlo',
            },
            coordinates: {
              lat: '16.189',
              lng: '100.568',
            },
            zipcode: null,
          },
          660802: {
            name: {
              th: 'เขาทราย',
              en: 'Khao Sai',
            },
            coordinates: {
              lat: '16.193',
              lng: '100.637',
            },
            zipcode: null,
          },
          660803: {
            name: {
              th: 'เขาเจ็ดลูก',
              en: 'Khao Chet Luk',
            },
            coordinates: {
              lat: '16.274',
              lng: '100.569',
            },
            zipcode: null,
          },
          660804: {
            name: {
              th: 'ท้ายทุ่ง',
              en: 'Thai Thung',
            },
            coordinates: {
              lat: '16.086',
              lng: '100.618',
            },
            zipcode: null,
          },
        },
      },
      6609: {
        name: {
          th: 'สากเหล็ก',
          en: 'Sak Lek',
        },
        tambons: {
          660901: {
            name: {
              th: 'สากเหล็ก',
              en: 'Sak Lek',
            },
            coordinates: {
              lat: '16.486',
              lng: '100.461',
            },
            zipcode: null,
          },
          660902: {
            name: {
              th: 'ท่าเยี่ยม',
              en: 'Tha Yiam',
            },
            coordinates: {
              lat: '16.522',
              lng: '100.556',
            },
            zipcode: null,
          },
          660903: {
            name: {
              th: 'คลองทราย',
              en: 'Khlong Sai',
            },
            coordinates: {
              lat: '16.552',
              lng: '100.559',
            },
            zipcode: null,
          },
          660904: {
            name: {
              th: 'หนองหญ้าไทร',
              en: 'Nong Ya Sai',
            },
            coordinates: {
              lat: '16.544',
              lng: '100.527',
            },
            zipcode: 66160,
          },
          660905: {
            name: {
              th: 'วังทับไทร',
              en: 'Wang Thap Sai',
            },
            coordinates: {
              lat: '16.489',
              lng: '100.555',
            },
            zipcode: 66160,
          },
        },
      },
      6610: {
        name: {
          th: 'บึงนาราง',
          en: 'Bueng Na Rang',
        },
        tambons: {
          661001: {
            name: {
              th: 'ห้วยแก้ว',
              en: 'Huai Kaeo',
            },
            coordinates: {
              lat: '16.162',
              lng: '100.111',
            },
            zipcode: null,
          },
          661002: {
            name: {
              th: 'โพธิ์ไทรงาม',
              en: 'Pho Sai Ngam',
            },
            coordinates: {
              lat: '16.121',
              lng: '100.147',
            },
            zipcode: null,
          },
          661003: {
            name: {
              th: 'แหลมรัง',
              en: 'Laem Rang',
            },
            coordinates: {
              lat: '16.225',
              lng: '100.073',
            },
            zipcode: null,
          },
          661004: {
            name: {
              th: 'บางลาย',
              en: 'Bang Lai',
            },
            coordinates: {
              lat: '16.208',
              lng: '100.231',
            },
            zipcode: null,
          },
          661005: {
            name: {
              th: 'บึงนาราง',
              en: 'Bueng Na Rang',
            },
            coordinates: {
              lat: '16.205',
              lng: '100.163',
            },
            zipcode: null,
          },
        },
      },
      6611: {
        name: {
          th: 'ดงเจริญ',
          en: 'Dong Charoen',
        },
        tambons: {
          661101: {
            name: {
              th: 'วังงิ้วใต้',
              en: 'Wang Ngio Tai',
            },
            coordinates: {
              lat: '16.028',
              lng: '100.648',
            },
            zipcode: null,
          },
          661102: {
            name: {
              th: 'วังงิ้ว',
              en: 'Wang Ngio',
            },
            coordinates: {
              lat: '16.000',
              lng: '100.741',
            },
            zipcode: null,
          },
          661103: {
            name: {
              th: 'ห้วยร่วม',
              en: 'Huai Ruam',
            },
            coordinates: {
              lat: '15.972',
              lng: '100.536',
            },
            zipcode: null,
          },
          661104: {
            name: {
              th: 'ห้วยพุก',
              en: 'Huai Phuk',
            },
            coordinates: {
              lat: '16.004',
              lng: '100.609',
            },
            zipcode: null,
          },
          661105: {
            name: {
              th: 'สำนักขุนเณร',
              en: 'Samnak Khun Nen',
            },
            coordinates: {
              lat: '16.042',
              lng: '100.568',
            },
            zipcode: null,
          },
        },
      },
      6612: {
        name: {
          th: 'วชิรบารมี',
          en: 'Wachirabarami',
        },
        tambons: {
          661201: {
            name: {
              th: 'บ้านนา',
              en: 'Ban Na',
            },
            coordinates: {
              lat: '16.495',
              lng: '100.128',
            },
            zipcode: null,
          },
          661202: {
            name: {
              th: 'บึงบัว',
              en: 'Bueng Bua',
            },
            coordinates: {
              lat: '16.484',
              lng: '100.053',
            },
            zipcode: null,
          },
          661203: {
            name: {
              th: 'วังโมกข์',
              en: 'Wang Mok',
            },
            coordinates: {
              lat: '16.563',
              lng: '100.091',
            },
            zipcode: null,
          },
          661204: {
            name: {
              th: 'หนองหลุม',
              en: 'Nong Lum',
            },
            coordinates: {
              lat: '16.597',
              lng: '100.114',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  67: {
    name: {
      th: 'เพชรบูรณ์',
      en: 'Phetchabun',
    },
    amphoes: {
      6701: {
        name: {
          th: 'เมืองเพชรบูรณ์',
          en: 'Mueang Phetchabun',
        },
        tambons: {
          670101: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '16.420',
              lng: '101.156',
            },
            zipcode: 67000,
          },
          670102: {
            name: {
              th: 'ตะเบาะ',
              en: 'Tabo',
            },
            coordinates: {
              lat: '16.322',
              lng: '101.299',
            },
            zipcode: 67000,
          },
          670103: {
            name: {
              th: 'บ้านโตก',
              en: 'Ban Tok',
            },
            coordinates: {
              lat: '16.347',
              lng: '101.056',
            },
            zipcode: 67000,
          },
          670104: {
            name: {
              th: 'สะเดียง',
              en: 'Sadiang',
            },
            coordinates: {
              lat: '16.396',
              lng: '101.164',
            },
            zipcode: 67000,
          },
          670105: {
            name: {
              th: 'ป่าเลา',
              en: 'Pa Lao',
            },
            coordinates: {
              lat: '16.449',
              lng: '100.972',
            },
            zipcode: 67000,
          },
          670106: {
            name: {
              th: 'นางั่ว',
              en: 'Na Ngua',
            },
            coordinates: {
              lat: '16.507',
              lng: '101.146',
            },
            zipcode: 67000,
          },
          670107: {
            name: {
              th: 'ท่าพล',
              en: 'Tha Phon',
            },
            coordinates: {
              lat: '16.590',
              lng: '101.134',
            },
            zipcode: 67250,
          },
          670108: {
            name: {
              th: 'ดงมูลเหล็ก',
              en: 'Dong Mun Lek',
            },
            coordinates: {
              lat: '16.478',
              lng: '101.203',
            },
            zipcode: 67000,
          },
          670109: {
            name: {
              th: 'บ้านโคก',
              en: 'Ban Khok',
            },
            coordinates: {
              lat: '16.471',
              lng: '101.250',
            },
            zipcode: 67000,
          },
          670110: {
            name: {
              th: 'ชอนไพร',
              en: 'Chon Phrai',
            },
            coordinates: {
              lat: '16.341',
              lng: '101.153',
            },
            zipcode: 67000,
          },
          670111: {
            name: {
              th: 'นาป่า',
              en: 'Na Pa',
            },
            coordinates: {
              lat: '16.390',
              lng: '101.218',
            },
            zipcode: 67000,
          },
          670112: {
            name: {
              th: 'นายม',
              en: 'Na Yom',
            },
            coordinates: {
              lat: '16.229',
              lng: '101.230',
            },
            zipcode: 67210,
          },
          670113: {
            name: {
              th: 'วังชมภู',
              en: 'Wang Chomphu',
            },
            coordinates: {
              lat: '16.266',
              lng: '101.027',
            },
            zipcode: 67210,
          },
          670114: {
            name: {
              th: 'น้ำร้อน',
              en: 'Nam Ron',
            },
            coordinates: {
              lat: '16.322',
              lng: '101.187',
            },
            zipcode: 67000,
          },
          670115: {
            name: {
              th: 'ห้วยสะแก',
              en: 'Huai Sakae',
            },
            coordinates: {
              lat: '16.191',
              lng: '101.067',
            },
            zipcode: 67210,
          },
          670116: {
            name: {
              th: 'ห้วยใหญ่',
              en: 'Huai Yai',
            },
            coordinates: {
              lat: '16.505',
              lng: '101.383',
            },
            zipcode: 67000,
          },
          670117: {
            name: {
              th: 'ระวิง',
              en: 'Rawing',
            },
            coordinates: {
              lat: '16.146',
              lng: '101.138',
            },
            zipcode: 67210,
          },
        },
      },
      6702: {
        name: {
          th: 'ชนแดน',
          en: 'Chon Daen',
        },
        tambons: {
          670201: {
            name: {
              th: 'ชนแดน',
              en: 'Chon Daen',
            },
            coordinates: {
              lat: '16.158',
              lng: '100.895',
            },
            zipcode: 67150,
          },
          670202: {
            name: {
              th: 'ดงขุย',
              en: 'Dong Khu',
            },
            coordinates: {
              lat: '16.131',
              lng: '100.677',
            },
            zipcode: 67190,
          },
          670203: {
            name: {
              th: 'ท่าข้าม',
              en: 'Tha Kham',
            },
            coordinates: {
              lat: '16.204',
              lng: '100.792',
            },
            zipcode: 67150,
          },
          670204: {
            name: {
              th: 'พุทธบาท',
              en: 'Phutthabat',
            },
            coordinates: {
              lat: '16.271',
              lng: '100.892',
            },
            zipcode: 67150,
          },
          670205: {
            name: {
              th: 'ลาดแค',
              en: 'Lat Khae',
            },
            coordinates: {
              lat: '15.983',
              lng: '100.839',
            },
            zipcode: 67150,
          },
          670206: {
            name: {
              th: 'บ้านกล้วย',
              en: 'Ban Kluai',
            },
            coordinates: {
              lat: '16.050',
              lng: '100.744',
            },
            zipcode: 67190,
          },
          670208: {
            name: {
              th: 'ซับพุทรา',
              en: 'Sap Phutsa',
            },
            coordinates: {
              lat: '16.035',
              lng: '100.928',
            },
            zipcode: 67150,
          },
          670209: {
            name: {
              th: 'ตะกุดไร',
              en: 'Takut Lai',
            },
            coordinates: {
              lat: '16.180',
              lng: '100.726',
            },
            zipcode: 67190,
          },
          670210: {
            name: {
              th: 'ศาลาลาย',
              en: 'ศาลาลาย',
            },
            coordinates: {
              lat: '16.092',
              lng: '100.832',
            },
            zipcode: 67150,
          },
        },
      },
      6703: {
        name: {
          th: 'หล่มสัก',
          en: 'Lom Sak',
        },
        tambons: {
          670302: {
            name: {
              th: 'วัดป่า',
              en: 'Wat Pa',
            },
            coordinates: {
              lat: '16.795',
              lng: '101.234',
            },
            zipcode: 67110,
          },
          670303: {
            name: {
              th: 'ตาลเดี่ยว',
              en: 'Tan Diao',
            },
            coordinates: {
              lat: '16.785',
              lng: '101.248',
            },
            zipcode: 67110,
          },
          670304: {
            name: {
              th: 'ฝายนาแซง',
              en: 'Fai Na Saeng',
            },
            coordinates: {
              lat: '16.811',
              lng: '101.206',
            },
            zipcode: 67110,
          },
          670305: {
            name: {
              th: 'หนองสว่าง',
              en: 'Nong Sawang',
            },
            coordinates: {
              lat: '16.839',
              lng: '101.239',
            },
            zipcode: 67110,
          },
          670306: {
            name: {
              th: 'น้ำเฮี้ย',
              en: 'Nam Hia',
            },
            coordinates: {
              lat: '16.830',
              lng: '101.206',
            },
            zipcode: 67110,
          },
          670307: {
            name: {
              th: 'สักหลง',
              en: 'Sak Long',
            },
            coordinates: {
              lat: '16.844',
              lng: '101.275',
            },
            zipcode: 67110,
          },
          670308: {
            name: {
              th: 'ท่าอิบุญ',
              en: 'Tha I Bun',
            },
            coordinates: {
              lat: '16.889',
              lng: '101.346',
            },
            zipcode: 67110,
          },
          670309: {
            name: {
              th: 'บ้านโสก',
              en: 'Ban Sok',
            },
            coordinates: {
              lat: '16.803',
              lng: '101.284',
            },
            zipcode: 67110,
          },
          670310: {
            name: {
              th: 'บ้านติ้ว',
              en: 'Ban Tio',
            },
            coordinates: {
              lat: '16.782',
              lng: '101.357',
            },
            zipcode: 67110,
          },
          670311: {
            name: {
              th: 'ห้วยไร่',
              en: 'Huai Rai',
            },
            coordinates: {
              lat: '16.825',
              lng: '101.319',
            },
            zipcode: 67110,
          },
          670312: {
            name: {
              th: 'น้ำก้อ',
              en: 'Nam Ko',
            },
            coordinates: {
              lat: '16.794',
              lng: '101.158',
            },
            zipcode: 67110,
          },
          670313: {
            name: {
              th: 'ปากช่อง',
              en: 'Pak Chong',
            },
            coordinates: {
              lat: '16.708',
              lng: '101.304',
            },
            zipcode: 67110,
          },
          670314: {
            name: {
              th: 'น้ำชุน',
              en: 'Nam Chun',
            },
            coordinates: {
              lat: '16.739',
              lng: '101.143',
            },
            zipcode: 67110,
          },
          670315: {
            name: {
              th: 'หนองไขว่',
              en: 'Nong Khwai',
            },
            coordinates: {
              lat: '16.757',
              lng: '101.220',
            },
            zipcode: 67110,
          },
          670316: {
            name: {
              th: 'ลานบ่า',
              en: 'Lan Ba',
            },
            coordinates: {
              lat: '16.680',
              lng: '101.208',
            },
            zipcode: 67110,
          },
          670317: {
            name: {
              th: 'บุ่งคล้า',
              en: 'Bung Khla',
            },
            coordinates: {
              lat: '16.629',
              lng: '101.178',
            },
            zipcode: 67110,
          },
          670318: {
            name: {
              th: 'บุ่งน้ำเต้า',
              en: 'Bung Namtao',
            },
            coordinates: {
              lat: '16.677',
              lng: '101.146',
            },
            zipcode: 67110,
          },
          670319: {
            name: {
              th: 'บ้านกลาง',
              en: 'Ban Klang',
            },
            coordinates: {
              lat: '16.642',
              lng: '101.298',
            },
            zipcode: 67110,
          },
          670320: {
            name: {
              th: 'ช้างตะลูด',
              en: 'Chang Talut',
            },
            coordinates: {
              lat: '16.581',
              lng: '101.324',
            },
            zipcode: 67110,
          },
          670321: {
            name: {
              th: 'บ้านไร่',
              en: 'Ban Rai',
            },
            coordinates: {
              lat: '16.608',
              lng: '101.212',
            },
            zipcode: 67110,
          },
          670322: {
            name: {
              th: 'ปากดุก',
              en: 'Pak Duk',
            },
            coordinates: {
              lat: '16.720',
              lng: '101.249',
            },
            zipcode: 67110,
          },
          670323: {
            name: {
              th: 'บ้านหวาย',
              en: 'Ban Wai',
            },
            coordinates: {
              lat: '16.759',
              lng: '101.293',
            },
            zipcode: 67110,
          },
        },
      },
      6704: {
        name: {
          th: 'หล่มเก่า',
          en: 'Lom Kao',
        },
        tambons: {
          670401: {
            name: {
              th: 'หล่มเก่า',
              en: 'Lom Kao',
            },
            coordinates: {
              lat: '16.893',
              lng: '101.242',
            },
            zipcode: 67120,
          },
          670402: {
            name: {
              th: 'นาซำ',
              en: 'Na Sam',
            },
            coordinates: {
              lat: '17.026',
              lng: '101.217',
            },
            zipcode: 67120,
          },
          670403: {
            name: {
              th: 'หินฮาว',
              en: 'Hin Hao',
            },
            coordinates: {
              lat: '16.951',
              lng: '101.215',
            },
            zipcode: 67120,
          },
          670404: {
            name: {
              th: 'บ้านเนิน',
              en: 'Ban Noen',
            },
            coordinates: {
              lat: '16.857',
              lng: '101.095',
            },
            zipcode: 67120,
          },
          670405: {
            name: {
              th: 'ศิลา',
              en: 'Sila',
            },
            coordinates: {
              lat: '17.108',
              lng: '101.407',
            },
            zipcode: 67120,
          },
          670406: {
            name: {
              th: 'นาแซง',
              en: 'Na Saeng',
            },
            coordinates: {
              lat: '16.857',
              lng: '101.229',
            },
            zipcode: 67120,
          },
          670407: {
            name: {
              th: 'วังบาล',
              en: 'Wang Ban',
            },
            coordinates: {
              lat: '16.929',
              lng: '101.156',
            },
            zipcode: 67120,
          },
          670408: {
            name: {
              th: 'นาเกาะ',
              en: 'Na Ko',
            },
            coordinates: {
              lat: '16.832',
              lng: '101.176',
            },
            zipcode: 67120,
          },
          670409: {
            name: {
              th: 'ตาดกลอย',
              en: 'Tat Kloi',
            },
            coordinates: {
              lat: '16.998',
              lng: '101.402',
            },
            zipcode: 67120,
          },
        },
      },
      6705: {
        name: {
          th: 'วิเชียรบุรี',
          en: 'Wichian Buri',
        },
        tambons: {
          670501: {
            name: {
              th: 'ท่าโรง',
              en: 'Tha Rong',
            },
            coordinates: {
              lat: '15.660',
              lng: '101.141',
            },
            zipcode: 67130,
          },
          670502: {
            name: {
              th: 'สระประดู่',
              en: 'Sa Pradu',
            },
            coordinates: {
              lat: '15.628',
              lng: '101.061',
            },
            zipcode: 67130,
          },
          670503: {
            name: {
              th: 'สามแยก',
              en: 'Sam Yaek',
            },
            coordinates: {
              lat: '15.709',
              lng: '101.029',
            },
            zipcode: 67130,
          },
          670504: {
            name: {
              th: 'โคกปรง',
              en: 'Khok Prong',
            },
            coordinates: {
              lat: '15.814',
              lng: '101.227',
            },
            zipcode: 67130,
          },
          670505: {
            name: {
              th: 'น้ำร้อน',
              en: 'Nam Ron',
            },
            coordinates: {
              lat: '15.687',
              lng: '101.306',
            },
            zipcode: null,
          },
          670506: {
            name: {
              th: 'บ่อรัง',
              en: 'Bo Rang',
            },
            coordinates: {
              lat: '15.608',
              lng: '101.217',
            },
            zipcode: 67130,
          },
          670507: {
            name: {
              th: 'พุเตย',
              en: 'Phu Toei',
            },
            coordinates: {
              lat: '15.586',
              lng: '101.018',
            },
            zipcode: 67180,
          },
          670508: {
            name: {
              th: 'พุขาม',
              en: 'Phu Kham',
            },
            coordinates: {
              lat: '15.529',
              lng: '101.044',
            },
            zipcode: 67180,
          },
          670509: {
            name: {
              th: 'ภูน้ำหยด',
              en: null,
            },
            coordinates: {
              lat: '15.530',
              lng: '100.920',
            },
            zipcode: 67180,
          },
          670510: {
            name: {
              th: 'ซับสมบูรณ์',
              en: 'Sap Sombun',
            },
            coordinates: {
              lat: '15.669',
              lng: '100.983',
            },
            zipcode: 67180,
          },
          670511: {
            name: {
              th: 'บึงกระจับ',
              en: 'Bueng Krachap',
            },
            coordinates: {
              lat: '15.744',
              lng: '101.095',
            },
            zipcode: 67130,
          },
          670512: {
            name: {
              th: 'วังใหญ่',
              en: 'Wang Yai',
            },
            coordinates: {
              lat: '15.607',
              lng: '100.949',
            },
            zipcode: 67180,
          },
          670513: {
            name: {
              th: 'ยางสาว',
              en: 'Yang Sao',
            },
            coordinates: {
              lat: '15.755',
              lng: '101.216',
            },
            zipcode: 67130,
          },
          670514: {
            name: {
              th: 'ซับน้อย',
              en: 'Sap Noi',
            },
            coordinates: {
              lat: '15.657',
              lng: '100.858',
            },
            zipcode: 67180,
          },
        },
      },
      6706: {
        name: {
          th: 'ศรีเทพ',
          en: 'Si Thep',
        },
        tambons: {
          670601: {
            name: {
              th: 'ศรีเทพ',
              en: 'Si Thep',
            },
            coordinates: {
              lat: '15.439',
              lng: '101.140',
            },
            zipcode: null,
          },
          670602: {
            name: {
              th: 'สระกรวด',
              en: 'Sa Kruat',
            },
            coordinates: {
              lat: '15.420',
              lng: '101.070',
            },
            zipcode: null,
          },
          670603: {
            name: {
              th: 'คลองกระจัง',
              en: 'Khlong Krachang',
            },
            coordinates: {
              lat: '15.370',
              lng: '101.164',
            },
            zipcode: null,
          },
          670604: {
            name: {
              th: 'นาสนุ่น',
              en: 'Na Sanun',
            },
            coordinates: {
              lat: '15.527',
              lng: '101.136',
            },
            zipcode: null,
          },
          670605: {
            name: {
              th: 'โคกสะอาด',
              en: 'Khok Sa-At',
            },
            coordinates: {
              lat: '15.477',
              lng: '100.971',
            },
            zipcode: 67170,
          },
          670606: {
            name: {
              th: 'หนองย่างทอย',
              en: 'Nong Yang Thoi',
            },
            coordinates: {
              lat: '15.402',
              lng: '101.252',
            },
            zipcode: 67170,
          },
          670607: {
            name: {
              th: 'ประดู่งาม',
              en: 'Pradu Ngam',
            },
            coordinates: {
              lat: '15.436',
              lng: '100.971',
            },
            zipcode: 67170,
          },
        },
      },
      6707: {
        name: {
          th: 'หนองไผ่',
          en: 'Nong Phai',
        },
        tambons: {
          670701: {
            name: {
              th: 'กองทูล',
              en: 'Kong Thun',
            },
            coordinates: {
              lat: '15.956',
              lng: '101.103',
            },
            zipcode: 67140,
          },
          670702: {
            name: {
              th: 'นาเฉลียง',
              en: 'Na Chaliang',
            },
            coordinates: {
              lat: '16.075',
              lng: '101.084',
            },
            zipcode: 67220,
          },
          670703: {
            name: {
              th: 'บ้านโภชน์',
              en: 'Ban Phot',
            },
            coordinates: {
              lat: '15.933',
              lng: '100.928',
            },
            zipcode: 67140,
          },
          670704: {
            name: {
              th: 'ท่าแดง',
              en: 'Tha Daeng',
            },
            coordinates: {
              lat: '15.957',
              lng: '101.183',
            },
            zipcode: 67140,
          },
          670705: {
            name: {
              th: 'เพชรละคร',
              en: 'Phet Lakhon',
            },
            coordinates: {
              lat: '15.897',
              lng: '101.154',
            },
            zipcode: 67140,
          },
          670706: {
            name: {
              th: 'บ่อไทย',
              en: 'Bo Thai',
            },
            coordinates: {
              lat: '16.129',
              lng: '101.276',
            },
            zipcode: 67140,
          },
          670707: {
            name: {
              th: 'ห้วยโป่ง',
              en: 'Huai Pong',
            },
            coordinates: {
              lat: '16.141',
              lng: '101.034',
            },
            zipcode: 67220,
          },
          670708: {
            name: {
              th: 'วังท่าดี',
              en: 'Wang Tha Di',
            },
            coordinates: {
              lat: '15.995',
              lng: '101.223',
            },
            zipcode: 67140,
          },
          670709: {
            name: {
              th: 'บัววัฒนา',
              en: 'Bua Watthana',
            },
            coordinates: {
              lat: '15.953',
              lng: '101.034',
            },
            zipcode: 67140,
          },
          670710: {
            name: {
              th: 'หนองไผ่',
              en: 'Nong Phai',
            },
            coordinates: {
              lat: '16.004',
              lng: '101.037',
            },
            zipcode: 67140,
          },
          670711: {
            name: {
              th: 'วังโบสถ์',
              en: 'Wang Bot',
            },
            coordinates: {
              lat: '16.057',
              lng: '101.147',
            },
            zipcode: 67140,
          },
          670712: {
            name: {
              th: 'ยางงาม',
              en: 'Yang Ngam',
            },
            coordinates: {
              lat: '16.101',
              lng: '100.951',
            },
            zipcode: 67220,
          },
          670713: {
            name: {
              th: 'ท่าด้วง',
              en: 'Tha Duang',
            },
            coordinates: {
              lat: '16.001',
              lng: '101.301',
            },
            zipcode: 67140,
          },
        },
      },
      6708: {
        name: {
          th: 'บึงสามพัน',
          en: 'Bueng Sam Phan',
        },
        tambons: {
          670801: {
            name: {
              th: 'ซับสมอทอด',
              en: 'Sap Samo Thot',
            },
            coordinates: {
              lat: '15.846',
              lng: '100.939',
            },
            zipcode: 67160,
          },
          670802: {
            name: {
              th: 'ซับไม้แดง',
              en: 'Sap Mai Daeng',
            },
            coordinates: {
              lat: '15.788',
              lng: '100.922',
            },
            zipcode: 67160,
          },
          670803: {
            name: {
              th: 'หนองแจง',
              en: 'Nong Chaeng',
            },
            coordinates: {
              lat: '15.877',
              lng: '100.985',
            },
            zipcode: 67160,
          },
          670804: {
            name: {
              th: 'กันจุ',
              en: 'Kan Chu',
            },
            coordinates: {
              lat: '15.817',
              lng: '101.075',
            },
            zipcode: 67160,
          },
          670805: {
            name: {
              th: 'วังพิกุล',
              en: 'Wang Phikun',
            },
            coordinates: {
              lat: '15.747',
              lng: '100.861',
            },
            zipcode: 67230,
          },
          670806: {
            name: {
              th: 'พญาวัง',
              en: 'Phaya Wang',
            },
            coordinates: {
              lat: '15.880',
              lng: '100.847',
            },
            zipcode: 67160,
          },
          670807: {
            name: {
              th: 'ศรีมงคล',
              en: 'Si Mongkhon',
            },
            coordinates: {
              lat: '15.820',
              lng: '100.835',
            },
            zipcode: 67160,
          },
          670808: {
            name: {
              th: 'สระแก้ว',
              en: 'Sa Kaeo',
            },
            coordinates: {
              lat: '15.894',
              lng: '101.324',
            },
            zipcode: 67160,
          },
          670809: {
            name: {
              th: 'บึงสามพัน',
              en: 'Bueng Sam Phan',
            },
            coordinates: {
              lat: '15.791',
              lng: '101.022',
            },
            zipcode: 67160,
          },
        },
      },
      6709: {
        name: {
          th: 'น้ำหนาว',
          en: 'Nam Nao',
        },
        tambons: {
          670901: {
            name: {
              th: 'น้ำหนาว',
              en: 'Nam Nao',
            },
            coordinates: {
              lat: '16.764',
              lng: '101.681',
            },
            zipcode: null,
          },
          670902: {
            name: {
              th: 'หลักด่าน',
              en: 'Lak Dan',
            },
            coordinates: {
              lat: '16.937',
              lng: '101.477',
            },
            zipcode: 67260,
          },
          670903: {
            name: {
              th: 'วังกวาง',
              en: 'Wang Kwang',
            },
            coordinates: {
              lat: '16.936',
              lng: '101.595',
            },
            zipcode: 67260,
          },
          670904: {
            name: {
              th: 'โคกมน',
              en: 'Khok Mon',
            },
            coordinates: {
              lat: '16.709',
              lng: '101.710',
            },
            zipcode: 67260,
          },
        },
      },
      6710: {
        name: {
          th: 'วังโป่ง',
          en: 'Wang Pong',
        },
        tambons: {
          671001: {
            name: {
              th: 'วังโป่ง',
              en: 'Wang Pong',
            },
            coordinates: {
              lat: '16.347',
              lng: '100.809',
            },
            zipcode: null,
          },
          671002: {
            name: {
              th: 'ท้ายดง',
              en: 'Thai Dong',
            },
            coordinates: {
              lat: '16.297',
              lng: '100.678',
            },
            zipcode: null,
          },
          671003: {
            name: {
              th: 'ซับเปิบ',
              en: 'Sap Poep',
            },
            coordinates: {
              lat: '16.362',
              lng: '100.918',
            },
            zipcode: 67240,
          },
          671004: {
            name: {
              th: 'วังหิน',
              en: 'Wang Hin',
            },
            coordinates: {
              lat: '16.387',
              lng: '100.754',
            },
            zipcode: 67240,
          },
          671005: {
            name: {
              th: 'วังศาล',
              en: 'Wang San',
            },
            coordinates: {
              lat: '16.283',
              lng: '100.758',
            },
            zipcode: 67240,
          },
        },
      },
      6711: {
        name: {
          th: 'เขาค้อ',
          en: 'Khao kho',
        },
        tambons: {
          671101: {
            name: {
              th: 'ทุ่งสมอ',
              en: 'Thung Samo',
            },
            coordinates: {
              lat: '16.717',
              lng: '100.962',
            },
            zipcode: 67270,
          },
          671102: {
            name: {
              th: 'แคมป์สน',
              en: 'Khaem Son',
            },
            coordinates: {
              lat: '16.780',
              lng: '101.055',
            },
            zipcode: null,
          },
          671103: {
            name: {
              th: 'เขาค้อ',
              en: 'Khao Kho',
            },
            coordinates: {
              lat: '16.631',
              lng: '100.999',
            },
            zipcode: 67270,
          },
          671104: {
            name: {
              th: 'ริมสีม่วง',
              en: 'Rim Si Muang',
            },
            coordinates: {
              lat: '16.520',
              lng: '101.042',
            },
            zipcode: 67270,
          },
          671105: {
            name: {
              th: 'สะเดาะพง',
              en: 'Sadao Phong',
            },
            coordinates: {
              lat: '16.545',
              lng: '100.968',
            },
            zipcode: 67270,
          },
          671106: {
            name: {
              th: 'หนองแม่นา',
              en: 'Nong Mae Na',
            },
            coordinates: {
              lat: '16.578',
              lng: '100.898',
            },
            zipcode: 67270,
          },
          671107: {
            name: {
              th: 'เข็กน้อย',
              en: 'Khek Noi',
            },
            coordinates: {
              lat: '16.832',
              lng: '101.015',
            },
            zipcode: 67280,
          },
        },
      },
    },
  },
  70: {
    name: {
      th: 'ราชบุรี',
      en: 'Ratchaburi',
    },
    amphoes: {
      7001: {
        name: {
          th: 'เมืองราชบุรี',
          en: 'Mueang Ratchaburi',
        },
        tambons: {
          700101: {
            name: {
              th: 'หน้าเมือง',
              en: 'Na Mueang',
            },
            coordinates: {
              lat: '13.535',
              lng: '99.820',
            },
            zipcode: 70000,
          },
          700102: {
            name: {
              th: 'เจดีย์หัก',
              en: 'Chedi Hak',
            },
            coordinates: {
              lat: '13.539',
              lng: '99.781',
            },
            zipcode: 70000,
          },
          700103: {
            name: {
              th: 'ดอนตะโก',
              en: 'Don Tako',
            },
            coordinates: {
              lat: '13.509',
              lng: '99.792',
            },
            zipcode: 70000,
          },
          700104: {
            name: {
              th: 'หนองกลางนา',
              en: 'Nong Klang Na',
            },
            coordinates: {
              lat: '13.593',
              lng: '99.796',
            },
            zipcode: 70000,
          },
          700105: {
            name: {
              th: 'ห้วยไผ่',
              en: 'Huai Phai',
            },
            coordinates: {
              lat: '13.510',
              lng: '99.745',
            },
            zipcode: 70000,
          },
          700106: {
            name: {
              th: 'คุ้งน้ำวน',
              en: 'Khung Nam Won',
            },
            coordinates: {
              lat: '13.508',
              lng: '99.898',
            },
            zipcode: 70000,
          },
          700107: {
            name: {
              th: 'คุ้งกระถิน',
              en: 'Khung Krathin',
            },
            coordinates: {
              lat: '13.504',
              lng: '99.876',
            },
            zipcode: 70000,
          },
          700108: {
            name: {
              th: 'อ่างทอง',
              en: 'Ang Thong',
            },
            coordinates: {
              lat: '13.465',
              lng: '99.787',
            },
            zipcode: 70000,
          },
          700109: {
            name: {
              th: 'โคกหม้อ',
              en: 'Khok Mo',
            },
            coordinates: {
              lat: '13.571',
              lng: '99.834',
            },
            zipcode: 70000,
          },
          700110: {
            name: {
              th: 'สามเรือน',
              en: 'Sam Ruean',
            },
            coordinates: {
              lat: '13.603',
              lng: '99.860',
            },
            zipcode: 70000,
          },
          700111: {
            name: {
              th: 'พิกุลทอง',
              en: 'Phikun Thong',
            },
            coordinates: {
              lat: '13.575',
              lng: '99.866',
            },
            zipcode: 70000,
          },
          700112: {
            name: {
              th: 'น้ำพุ',
              en: 'Namphu',
            },
            coordinates: {
              lat: '13.547',
              lng: '99.631',
            },
            zipcode: 70000,
          },
          700113: {
            name: {
              th: 'ดอนแร่',
              en: 'Don Rae',
            },
            coordinates: {
              lat: '13.471',
              lng: '99.758',
            },
            zipcode: 70000,
          },
          700114: {
            name: {
              th: 'หินกอง',
              en: 'Hin Kong',
            },
            coordinates: {
              lat: '13.565',
              lng: '99.705',
            },
            zipcode: 70000,
          },
          700115: {
            name: {
              th: 'เขาแร้ง',
              en: 'Khao Raeng',
            },
            coordinates: {
              lat: '13.622',
              lng: '99.773',
            },
            zipcode: 70000,
          },
          700116: {
            name: {
              th: 'เกาะพลับพลา',
              en: 'Ko Phlapphla',
            },
            coordinates: {
              lat: '13.585',
              lng: '99.750',
            },
            zipcode: 70000,
          },
          700117: {
            name: {
              th: 'หลุมดิน',
              en: 'Lum Din',
            },
            coordinates: {
              lat: '13.571',
              lng: '99.808',
            },
            zipcode: 70000,
          },
          700118: {
            name: {
              th: 'บางป่า',
              en: 'Bang Pa',
            },
            coordinates: {
              lat: '13.553',
              lng: '99.886',
            },
            zipcode: 70000,
          },
          700119: {
            name: {
              th: 'พงสวาย',
              en: 'Phong Sawai',
            },
            coordinates: {
              lat: '13.545',
              lng: '99.848',
            },
            zipcode: 70000,
          },
          700120: {
            name: {
              th: 'คูบัว',
              en: 'Khu Bua',
            },
            coordinates: {
              lat: '13.480',
              lng: '99.822',
            },
            zipcode: 70000,
          },
          700121: {
            name: {
              th: 'ท่าราบ',
              en: 'Tha Rap',
            },
            coordinates: {
              lat: '13.602',
              lng: '99.819',
            },
            zipcode: 70000,
          },
          700122: {
            name: {
              th: 'บ้านไร่',
              en: 'Ban Rai',
            },
            coordinates: {
              lat: '13.511',
              lng: '99.845',
            },
            zipcode: 70000,
          },
        },
      },
      7002: {
        name: {
          th: 'จอมบึง',
          en: 'Chom Bueng',
        },
        tambons: {
          700201: {
            name: {
              th: 'จอมบึง',
              en: 'Chom Bueng',
            },
            coordinates: {
              lat: '13.622',
              lng: '99.606',
            },
            zipcode: 70150,
          },
          700202: {
            name: {
              th: 'ปากช่อง',
              en: 'Pak Chong',
            },
            coordinates: {
              lat: '13.667',
              lng: '99.649',
            },
            zipcode: 70150,
          },
          700203: {
            name: {
              th: 'เบิกไพร',
              en: 'Boek Phrai',
            },
            coordinates: {
              lat: '13.685',
              lng: '99.521',
            },
            zipcode: 70110,
          },
          700204: {
            name: {
              th: 'ด่านทับตะโก',
              en: 'Dan Thap Tako',
            },
            coordinates: {
              lat: '13.663',
              lng: '99.426',
            },
            zipcode: 70150,
          },
          700205: {
            name: {
              th: 'แก้มอ้น',
              en: 'Kaem On',
            },
            coordinates: {
              lat: '13.740',
              lng: '99.428',
            },
            zipcode: 70150,
          },
          700206: {
            name: {
              th: 'รางบัว',
              en: 'Rang Bua',
            },
            coordinates: {
              lat: '13.533',
              lng: '99.533',
            },
            zipcode: 70150,
          },
        },
      },
      7003: {
        name: {
          th: 'สวนผึ้ง',
          en: 'Suan Phueng',
        },
        tambons: {
          700301: {
            name: {
              th: 'สวนผึ้ง',
              en: 'Suan Phueng',
            },
            coordinates: {
              lat: '13.612',
              lng: '99.249',
            },
            zipcode: null,
          },
          700302: {
            name: {
              th: 'ป่าหวาย',
              en: 'Pa Wai',
            },
            coordinates: {
              lat: '13.578',
              lng: '99.423',
            },
            zipcode: null,
          },
          700304: {
            name: {
              th: 'ท่าเคย',
              en: 'Tha Khoei',
            },
            coordinates: {
              lat: '13.522',
              lng: '99.420',
            },
            zipcode: 70180,
          },
          700307: {
            name: {
              th: 'ตะนาวศรี',
              en: 'Tanao Si',
            },
            coordinates: {
              lat: '13.418',
              lng: '99.273',
            },
            zipcode: 70180,
          },
        },
      },
      7004: {
        name: {
          th: 'ดำเนินสะดวก',
          en: 'Damnoen Saduak',
        },
        tambons: {
          700401: {
            name: {
              th: 'ดำเนินสะดวก',
              en: 'Damnoen Saduak',
            },
            coordinates: {
              lat: '13.517',
              lng: '99.949',
            },
            zipcode: 70130,
          },
          700402: {
            name: {
              th: 'ประสาทสิทธิ์',
              en: 'Prasatsit',
            },
            coordinates: {
              lat: '13.555',
              lng: '100.036',
            },
            zipcode: 70210,
          },
          700403: {
            name: {
              th: 'ศรีสุราษฎร์',
              en: 'Si Surat',
            },
            coordinates: {
              lat: '13.533',
              lng: '99.984',
            },
            zipcode: 70130,
          },
          700404: {
            name: {
              th: 'ตาหลวง',
              en: 'Ta Luang',
            },
            coordinates: {
              lat: '13.504',
              lng: '99.953',
            },
            zipcode: 70130,
          },
          700405: {
            name: {
              th: 'ดอนกรวย',
              en: 'Don Kruai',
            },
            coordinates: {
              lat: '13.580',
              lng: '99.971',
            },
            zipcode: 70130,
          },
          700406: {
            name: {
              th: 'ดอนคลัง',
              en: 'Don Khlang',
            },
            coordinates: {
              lat: '13.619',
              lng: '99.974',
            },
            zipcode: 70130,
          },
          700407: {
            name: {
              th: 'บัวงาม',
              en: 'Bua Ngam',
            },
            coordinates: {
              lat: '13.608',
              lng: '100.016',
            },
            zipcode: 70210,
          },
          700408: {
            name: {
              th: 'บ้านไร่',
              en: 'Ban Rai',
            },
            coordinates: {
              lat: '13.606',
              lng: '99.909',
            },
            zipcode: null,
          },
          700409: {
            name: {
              th: 'แพงพวย',
              en: 'Phaeng Phuai',
            },
            coordinates: {
              lat: '13.571',
              lng: '99.925',
            },
            zipcode: 70130,
          },
          700410: {
            name: {
              th: 'สี่หมื่น',
              en: 'Si Muen',
            },
            coordinates: {
              lat: '13.522',
              lng: '99.929',
            },
            zipcode: 70130,
          },
          700411: {
            name: {
              th: 'ท่านัด',
              en: 'Tha Nat',
            },
            coordinates: {
              lat: '13.544',
              lng: '99.960',
            },
            zipcode: 70130,
          },
          700412: {
            name: {
              th: 'ขุนพิทักษ์',
              en: 'Khun Phithak',
            },
            coordinates: {
              lat: '13.509',
              lng: '99.982',
            },
            zipcode: 70130,
          },
          700413: {
            name: {
              th: 'ดอนไผ่',
              en: 'Don Phi',
            },
            coordinates: {
              lat: '13.551',
              lng: '100.009',
            },
            zipcode: 70130,
          },
        },
      },
      7005: {
        name: {
          th: 'บ้านโป่ง',
          en: 'Ban Pong',
        },
        tambons: {
          700501: {
            name: {
              th: 'บ้านโป่ง',
              en: 'Ban Pong',
            },
            coordinates: {
              lat: '13.816',
              lng: '99.873',
            },
            zipcode: 70110,
          },
          700502: {
            name: {
              th: 'ท่าผา',
              en: 'Tha Pha',
            },
            coordinates: {
              lat: '13.865',
              lng: '99.865',
            },
            zipcode: 70110,
          },
          700503: {
            name: {
              th: 'กรับใหญ่',
              en: 'Krap Yai',
            },
            coordinates: {
              lat: '13.908',
              lng: '99.868',
            },
            zipcode: 70190,
          },
          700504: {
            name: {
              th: 'ปากแรต',
              en: 'Pak Raet',
            },
            coordinates: {
              lat: '13.833',
              lng: '99.890',
            },
            zipcode: 70110,
          },
          700505: {
            name: {
              th: 'หนองกบ',
              en: 'Nong Kop',
            },
            coordinates: {
              lat: '13.821',
              lng: '99.934',
            },
            zipcode: 70110,
          },
          700506: {
            name: {
              th: 'หนองอ้อ',
              en: 'Nong O',
            },
            coordinates: {
              lat: '13.793',
              lng: '99.906',
            },
            zipcode: 70110,
          },
          700507: {
            name: {
              th: 'ดอนกระเบื้อง',
              en: 'Don Krabueang',
            },
            coordinates: {
              lat: '13.769',
              lng: '99.891',
            },
            zipcode: 70120,
          },
          700508: {
            name: {
              th: 'สวนกล้วย',
              en: 'Suan Kluai',
            },
            coordinates: {
              lat: '13.798',
              lng: '99.882',
            },
            zipcode: 70110,
          },
          700509: {
            name: {
              th: 'นครชุมน์',
              en: 'Nakhon Chum',
            },
            coordinates: {
              lat: '13.773',
              lng: '99.863',
            },
            zipcode: 70110,
          },
          700510: {
            name: {
              th: 'บ้านม่วง',
              en: 'Ban Muang',
            },
            coordinates: {
              lat: '13.771',
              lng: '99.815',
            },
            zipcode: 70110,
          },
          700511: {
            name: {
              th: 'คุ้งพยอม',
              en: 'Khung Phayom',
            },
            coordinates: {
              lat: '13.797',
              lng: '99.830',
            },
            zipcode: 70110,
          },
          700512: {
            name: {
              th: 'หนองปลาหมอ',
              en: 'Nong Pla Mo',
            },
            coordinates: {
              lat: '13.794',
              lng: '99.776',
            },
            zipcode: 70110,
          },
          700513: {
            name: {
              th: 'เขาขลุง',
              en: 'Khao Khlung',
            },
            coordinates: {
              lat: '13.798',
              lng: '99.728',
            },
            zipcode: 70110,
          },
          700514: {
            name: {
              th: 'เบิกไพร',
              en: 'Boek Phrai',
            },
            coordinates: {
              lat: '13.819',
              lng: '99.851',
            },
            zipcode: null,
          },
          700515: {
            name: {
              th: 'ลาดบัวขาว',
              en: 'Lat Bua Khao',
            },
            coordinates: {
              lat: '13.841',
              lng: '99.831',
            },
            zipcode: 70110,
          },
        },
      },
      7006: {
        name: {
          th: 'บางแพ',
          en: 'Bang Phae',
        },
        tambons: {
          700601: {
            name: {
              th: 'บางแพ',
              en: 'Bang Phae',
            },
            coordinates: {
              lat: '13.691',
              lng: '99.951',
            },
            zipcode: 70160,
          },
          700602: {
            name: {
              th: 'วังเย็น',
              en: 'Wang Yen',
            },
            coordinates: {
              lat: '13.705',
              lng: '99.909',
            },
            zipcode: 70160,
          },
          700603: {
            name: {
              th: 'หัวโพ',
              en: 'Hua Pho',
            },
            coordinates: {
              lat: '13.654',
              lng: '99.978',
            },
            zipcode: 70160,
          },
          700604: {
            name: {
              th: 'วัดแก้ว',
              en: 'Wat Kaeo',
            },
            coordinates: {
              lat: '13.657',
              lng: '99.918',
            },
            zipcode: 70160,
          },
          700605: {
            name: {
              th: 'ดอนใหญ่',
              en: 'Don Yai',
            },
            coordinates: {
              lat: '13.709',
              lng: '100.002',
            },
            zipcode: 70160,
          },
          700606: {
            name: {
              th: 'ดอนคา',
              en: 'Don Kha',
            },
            coordinates: {
              lat: '13.668',
              lng: '100.006',
            },
            zipcode: 70160,
          },
          700607: {
            name: {
              th: 'โพหัก',
              en: 'Pho Hak',
            },
            coordinates: {
              lat: '13.640',
              lng: '100.043',
            },
            zipcode: 70160,
          },
        },
      },
      7007: {
        name: {
          th: 'โพธาราม',
          en: 'Photharam',
        },
        tambons: {
          700701: {
            name: {
              th: 'โพธาราม',
              en: 'Photharam',
            },
            coordinates: {
              lat: '13.695',
              lng: '99.853',
            },
            zipcode: 70120,
          },
          700702: {
            name: {
              th: 'ดอนกระเบื้อง',
              en: 'Don Krabueang',
            },
            coordinates: {
              lat: '13.752',
              lng: '99.899',
            },
            zipcode: null,
          },
          700703: {
            name: {
              th: 'หนองโพ',
              en: 'Nong Pho',
            },
            coordinates: {
              lat: '13.741',
              lng: '99.924',
            },
            zipcode: 70120,
          },
          700704: {
            name: {
              th: 'บ้านเลือก',
              en: 'Ban Lueak',
            },
            coordinates: {
              lat: '13.725',
              lng: '99.886',
            },
            zipcode: 70120,
          },
          700705: {
            name: {
              th: 'คลองตาคต',
              en: 'Khlong Ta Kot',
            },
            coordinates: {
              lat: '13.672',
              lng: '99.854',
            },
            zipcode: 70120,
          },
          700706: {
            name: {
              th: 'บ้านฆ้อง',
              en: 'Ban Khong',
            },
            coordinates: {
              lat: '13.681',
              lng: '99.888',
            },
            zipcode: 70120,
          },
          700707: {
            name: {
              th: 'บ้านสิงห์',
              en: 'Ban Sing',
            },
            coordinates: {
              lat: '13.636',
              lng: '99.895',
            },
            zipcode: 70120,
          },
          700708: {
            name: {
              th: 'ดอนทราย',
              en: 'Don Sai',
            },
            coordinates: {
              lat: '13.632',
              lng: '99.858',
            },
            zipcode: 70120,
          },
          700709: {
            name: {
              th: 'เจ็ดเสมียน',
              en: 'Chet Samian',
            },
            coordinates: {
              lat: '13.638',
              lng: '99.828',
            },
            zipcode: 70120,
          },
          700710: {
            name: {
              th: 'คลองข่อย',
              en: 'Khlong Khoi',
            },
            coordinates: {
              lat: '13.675',
              lng: '99.827',
            },
            zipcode: 70120,
          },
          700711: {
            name: {
              th: 'ชำแระ',
              en: 'Chamrae',
            },
            coordinates: {
              lat: '13.738',
              lng: '99.809',
            },
            zipcode: 70120,
          },
          700712: {
            name: {
              th: 'สร้อยฟ้า',
              en: 'Soi Fa',
            },
            coordinates: {
              lat: '13.721',
              lng: '99.835',
            },
            zipcode: 70120,
          },
          700713: {
            name: {
              th: 'ท่าชุมพล',
              en: 'Tha Chumphon',
            },
            coordinates: {
              lat: '13.691',
              lng: '99.802',
            },
            zipcode: 70120,
          },
          700714: {
            name: {
              th: 'บางโตนด',
              en: 'Bang Tanot',
            },
            coordinates: {
              lat: '13.653',
              lng: '99.796',
            },
            zipcode: 70120,
          },
          700715: {
            name: {
              th: 'เตาปูน',
              en: 'Tao Pun',
            },
            coordinates: {
              lat: '13.749',
              lng: '99.742',
            },
            zipcode: 70120,
          },
          700716: {
            name: {
              th: 'นางแก้ว',
              en: 'Nang Kaeo',
            },
            coordinates: {
              lat: '13.697',
              lng: '99.768',
            },
            zipcode: 70120,
          },
          700717: {
            name: {
              th: 'ธรรมเสน',
              en: 'Thammasen',
            },
            coordinates: {
              lat: '13.669',
              lng: '99.726',
            },
            zipcode: 70120,
          },
          700718: {
            name: {
              th: 'เขาชะงุ้ม',
              en: 'Khao Cha-Ngum',
            },
            coordinates: {
              lat: '13.726',
              lng: '99.700',
            },
            zipcode: 70120,
          },
          700719: {
            name: {
              th: 'หนองกวาง',
              en: 'Nong Kwang',
            },
            coordinates: {
              lat: '13.747',
              lng: '99.597',
            },
            zipcode: 70120,
          },
        },
      },
      7008: {
        name: {
          th: 'ปากท่อ',
          en: 'Pak Tho',
        },
        tambons: {
          700801: {
            name: {
              th: 'ทุ่งหลวง',
              en: 'Thung Luang',
            },
            coordinates: {
              lat: '13.411',
              lng: '99.683',
            },
            zipcode: 70140,
          },
          700802: {
            name: {
              th: 'วังมะนาว',
              en: 'Wang Manao',
            },
            coordinates: {
              lat: '13.341',
              lng: '99.802',
            },
            zipcode: 70140,
          },
          700803: {
            name: {
              th: 'ดอนทราย',
              en: 'Don Sai',
            },
            coordinates: {
              lat: '13.368',
              lng: '99.776',
            },
            zipcode: null,
          },
          700804: {
            name: {
              th: 'หนองกระทุ่ม',
              en: 'Nong Krathum',
            },
            coordinates: {
              lat: '13.404',
              lng: '99.779',
            },
            zipcode: 70140,
          },
          700805: {
            name: {
              th: 'ปากท่อ',
              en: 'Pak Tho',
            },
            coordinates: {
              lat: '13.382',
              lng: '99.841',
            },
            zipcode: 70140,
          },
          700806: {
            name: {
              th: 'ป่าไก่',
              en: 'Pa Kai',
            },
            coordinates: {
              lat: '13.412',
              lng: '99.827',
            },
            zipcode: 70140,
          },
          700807: {
            name: {
              th: 'วัดยางงาม',
              en: 'Wat Yang Ngam',
            },
            coordinates: {
              lat: '13.399',
              lng: '99.864',
            },
            zipcode: 70140,
          },
          700808: {
            name: {
              th: 'อ่างหิน',
              en: 'Ang Hin',
            },
            coordinates: {
              lat: '13.460',
              lng: '99.626',
            },
            zipcode: 70140,
          },
          700809: {
            name: {
              th: 'บ่อกระดาน',
              en: 'Bo Kradan',
            },
            coordinates: {
              lat: '13.438',
              lng: '99.801',
            },
            zipcode: 70140,
          },
          700810: {
            name: {
              th: 'ยางหัก',
              en: 'Yang Hak',
            },
            coordinates: {
              lat: '13.283',
              lng: '99.596',
            },
            zipcode: 70140,
          },
          700811: {
            name: {
              th: 'วันดาว',
              en: 'Wan Dao',
            },
            coordinates: {
              lat: '13.354',
              lng: '99.856',
            },
            zipcode: 70140,
          },
          700812: {
            name: {
              th: 'ห้วยยางโทน',
              en: 'Huai Yang Thon',
            },
            coordinates: {
              lat: '13.345',
              lng: '99.693',
            },
            zipcode: 70140,
          },
        },
      },
      7009: {
        name: {
          th: 'วัดเพลง',
          en: 'Wat Phleng',
        },
        tambons: {
          700901: {
            name: {
              th: 'เกาะศาลพระ',
              en: 'Ko San Phra',
            },
            coordinates: {
              lat: '13.473',
              lng: '99.860',
            },
            zipcode: 70170,
          },
          700902: {
            name: {
              th: 'จอมประทัด',
              en: 'Chom Prathat',
            },
            coordinates: {
              lat: '13.424',
              lng: '99.863',
            },
            zipcode: 70170,
          },
          700903: {
            name: {
              th: 'วัดเพลง',
              en: 'Wat Phleng',
            },
            coordinates: {
              lat: '13.449',
              lng: '99.872',
            },
            zipcode: 70170,
          },
        },
      },
      7010: {
        name: {
          th: 'บ้านคา',
          en: 'Ban Kha',
        },
        tambons: {
          701001: {
            name: {
              th: 'บ้านคา',
              en: 'Ban Kha',
            },
            coordinates: {
              lat: '13.418',
              lng: '99.386',
            },
            zipcode: null,
          },
          701002: {
            name: {
              th: 'บ้านบึง',
              en: 'Ban Bueng',
            },
            coordinates: {
              lat: '13.283',
              lng: '99.444',
            },
            zipcode: null,
          },
          701003: {
            name: {
              th: 'หนองพันจันทร์',
              en: 'Nong Phan Chan',
            },
            coordinates: {
              lat: '13.448',
              lng: '99.462',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  71: {
    name: {
      th: 'กาญจนบุรี',
      en: 'Kanchanaburi',
    },
    amphoes: {
      7101: {
        name: {
          th: 'เมืองกาญจนบุรี',
          en: 'Mueang Kanchanaburi',
        },
        tambons: {
          710103: {
            name: {
              th: 'ปากแพรก',
              en: 'Pak Phraek',
            },
            coordinates: {
              lat: '14.039',
              lng: '99.551',
            },
            zipcode: 71000,
          },
          710104: {
            name: {
              th: 'ท่ามะขาม',
              en: 'Tha Makham',
            },
            coordinates: {
              lat: '14.035',
              lng: '99.503',
            },
            zipcode: 71000,
          },
          710105: {
            name: {
              th: 'แก่งเสี้ยน',
              en: 'Kaeng Sian',
            },
            coordinates: {
              lat: '14.103',
              lng: '99.501',
            },
            zipcode: 71000,
          },
          710106: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '14.050',
              lng: '99.417',
            },
            zipcode: 71190,
          },
          710107: {
            name: {
              th: 'ลาดหญ้า',
              en: 'Lat Ya',
            },
            coordinates: {
              lat: '14.138',
              lng: '99.419',
            },
            zipcode: 71190,
          },
          710108: {
            name: {
              th: 'วังด้ง',
              en: 'Wang Dong',
            },
            coordinates: {
              lat: '14.151',
              lng: '99.306',
            },
            zipcode: 71190,
          },
          710109: {
            name: {
              th: 'ช่องสะเดา',
              en: 'Chong Sadao',
            },
            coordinates: {
              lat: '14.228',
              lng: '99.187',
            },
            zipcode: 71190,
          },
          710110: {
            name: {
              th: 'หนองหญ้า',
              en: 'Nong Ya',
            },
            coordinates: {
              lat: '13.981',
              lng: '99.451',
            },
            zipcode: 71000,
          },
          710111: {
            name: {
              th: 'เกาะสำโรง',
              en: 'Ko Samrong',
            },
            coordinates: {
              lat: '13.951',
              lng: '99.520',
            },
            zipcode: 71000,
          },
          710113: {
            name: {
              th: 'บ้านเก่า',
              en: 'Ban Kao',
            },
            coordinates: {
              lat: '13.981',
              lng: '99.305',
            },
            zipcode: 71000,
          },
          710116: {
            name: {
              th: 'วังเย็น',
              en: 'Wang Yen',
            },
            coordinates: {
              lat: '13.954',
              lng: '99.400',
            },
            zipcode: 71000,
          },
        },
      },
      7102: {
        name: {
          th: 'ไทรโยค',
          en: 'Sai Yok',
        },
        tambons: {
          710201: {
            name: {
              th: 'ลุ่มสุ่ม',
              en: 'Lum Sum',
            },
            coordinates: {
              lat: '14.104',
              lng: '99.133',
            },
            zipcode: 71150,
          },
          710202: {
            name: {
              th: 'ท่าเสา',
              en: 'Tha Sao',
            },
            coordinates: {
              lat: '14.303',
              lng: '99.037',
            },
            zipcode: 71150,
          },
          710203: {
            name: {
              th: 'สิงห์',
              en: 'Sing',
            },
            coordinates: {
              lat: '14.035',
              lng: '99.287',
            },
            zipcode: 71150,
          },
          710204: {
            name: {
              th: 'ไทรโยค',
              en: 'Sai Yok',
            },
            coordinates: {
              lat: '14.421',
              lng: '98.741',
            },
            zipcode: 71150,
          },
          710205: {
            name: {
              th: 'วังกระแจะ',
              en: 'Wang Krachae',
            },
            coordinates: {
              lat: '14.260',
              lng: '98.911',
            },
            zipcode: 71150,
          },
          710206: {
            name: {
              th: 'ศรีมงคล',
              en: 'Si Mongkhon',
            },
            coordinates: {
              lat: '13.992',
              lng: '99.139',
            },
            zipcode: 71150,
          },
          710207: {
            name: {
              th: 'บ้องตี้',
              en: 'Bong Ti',
            },
            coordinates: {
              lat: '14.134',
              lng: '98.916',
            },
            zipcode: 71150,
          },
        },
      },
      7103: {
        name: {
          th: 'บ่อพลอย',
          en: 'Bo Phloi',
        },
        tambons: {
          710301: {
            name: {
              th: 'บ่อพลอย',
              en: 'Bo Phloi',
            },
            coordinates: {
              lat: '14.305',
              lng: '99.466',
            },
            zipcode: 71160,
          },
          710302: {
            name: {
              th: 'หนองกุ่ม',
              en: 'Nong Kum',
            },
            coordinates: {
              lat: '14.205',
              lng: '99.462',
            },
            zipcode: 71160,
          },
          710303: {
            name: {
              th: 'หนองรี',
              en: 'Nong Ri',
            },
            coordinates: {
              lat: '14.540',
              lng: '99.356',
            },
            zipcode: 71220,
          },
          710305: {
            name: {
              th: 'หลุมรัง',
              en: 'Lum Rang',
            },
            coordinates: {
              lat: '14.485',
              lng: '99.481',
            },
            zipcode: 71160,
          },
          710308: {
            name: {
              th: 'ช่องด่าน',
              en: 'Chong Dan',
            },
            coordinates: {
              lat: '14.412',
              lng: '99.437',
            },
            zipcode: 71160,
          },
        },
      },
      7104: {
        name: {
          th: 'ศรีสวัสดิ์',
          en: 'Si Sawat',
        },
        tambons: {
          710401: {
            name: {
              th: 'นาสวน',
              en: 'Na Suan',
            },
            coordinates: {
              lat: '14.895',
              lng: '99.029',
            },
            zipcode: 71250,
          },
          710402: {
            name: {
              th: 'ด่านแม่แฉลบ',
              en: 'Dan Mae Chalaep',
            },
            coordinates: {
              lat: '14.691',
              lng: '98.980',
            },
            zipcode: 71250,
          },
          710403: {
            name: {
              th: 'หนองเป็ด',
              en: 'Nong Pet',
            },
            coordinates: {
              lat: '14.464',
              lng: '99.232',
            },
            zipcode: 71250,
          },
          710404: {
            name: {
              th: 'ท่ากระดาน',
              en: 'Tha Kradan',
            },
            coordinates: {
              lat: '14.366',
              lng: '99.174',
            },
            zipcode: 71250,
          },
          710405: {
            name: {
              th: 'เขาโจด',
              en: 'Khao Chot',
            },
            coordinates: {
              lat: '14.885',
              lng: '99.183',
            },
            zipcode: 71220,
          },
          710406: {
            name: {
              th: 'แม่กระบุง',
              en: 'Mae Krabung',
            },
            coordinates: {
              lat: '14.527',
              lng: '98.996',
            },
            zipcode: 71250,
          },
        },
      },
      7105: {
        name: {
          th: 'ท่ามะกา',
          en: 'Tha Maka',
        },
        tambons: {
          710501: {
            name: {
              th: 'พงตึก',
              en: 'Phong Tuek',
            },
            coordinates: {
              lat: '13.869',
              lng: '99.776',
            },
            zipcode: 71120,
          },
          710502: {
            name: {
              th: 'ยางม่วง',
              en: 'Yang Muang',
            },
            coordinates: {
              lat: '13.921',
              lng: '99.834',
            },
            zipcode: 71120,
          },
          710503: {
            name: {
              th: 'ดอนชะเอม',
              en: 'Don Cha-Em',
            },
            coordinates: {
              lat: '13.971',
              lng: '99.807',
            },
            zipcode: 71130,
          },
          710504: {
            name: {
              th: 'ท่าไม้',
              en: 'Tha Mai',
            },
            coordinates: {
              lat: '13.906',
              lng: '99.801',
            },
            zipcode: 71120,
          },
          710505: {
            name: {
              th: 'ตะคร้ำเอน',
              en: 'Takhram En',
            },
            coordinates: {
              lat: '13.992',
              lng: '99.744',
            },
            zipcode: 71130,
          },
          710506: {
            name: {
              th: 'ท่ามะกา',
              en: 'Tha Maka',
            },
            coordinates: {
              lat: '13.930',
              lng: '99.779',
            },
            zipcode: 71120,
          },
          710507: {
            name: {
              th: 'ท่าเรือ (เทศบาลเมืองพระแท่น)',
              en: 'Tha Ruea',
            },
            coordinates: {
              lat: '13.954',
              lng: '99.752',
            },
            zipcode: 71130,
          },
          710508: {
            name: {
              th: 'โคกตะบอง',
              en: 'Khok Tabong',
            },
            coordinates: {
              lat: '13.839',
              lng: '99.746',
            },
            zipcode: 71120,
          },
          710509: {
            name: {
              th: 'ดอนขมิ้น',
              en: 'Don Khamin',
            },
            coordinates: {
              lat: '13.884',
              lng: '99.826',
            },
            zipcode: 71120,
          },
          710510: {
            name: {
              th: 'อุโลกสี่หมื่น',
              en: 'Ulok Si Muen',
            },
            coordinates: {
              lat: '14.060',
              lng: '99.773',
            },
            zipcode: 71130,
          },
          710511: {
            name: {
              th: 'เขาสามสิบหาบ',
              en: 'Khao Sam Sip Hap',
            },
            coordinates: {
              lat: '13.865',
              lng: '99.719',
            },
            zipcode: 71120,
          },
          710512: {
            name: {
              th: 'พระแท่น',
              en: 'Phra Thaen',
            },
            coordinates: {
              lat: '14.018',
              lng: '99.792',
            },
            zipcode: 71130,
          },
          710513: {
            name: {
              th: 'หวายเหนียว',
              en: 'Wai Niao',
            },
            coordinates: {
              lat: '13.902',
              lng: '99.759',
            },
            zipcode: 71120,
          },
          710514: {
            name: {
              th: 'แสนตอ',
              en: 'Saen To',
            },
            coordinates: {
              lat: '13.922',
              lng: '99.732',
            },
            zipcode: 71130,
          },
          710515: {
            name: {
              th: 'สนามแย้',
              en: 'Sanam Yae',
            },
            coordinates: {
              lat: '13.954',
              lng: '99.851',
            },
            zipcode: 70190,
          },
          710516: {
            name: {
              th: 'ท่าเสา',
              en: 'Tha Sao',
            },
            coordinates: {
              lat: '13.843',
              lng: '99.805',
            },
            zipcode: null,
          },
          710517: {
            name: {
              th: 'หนองลาน',
              en: 'Nong Lan',
            },
            coordinates: {
              lat: '14.037',
              lng: '99.827',
            },
            zipcode: 71130,
          },
        },
      },
      7106: {
        name: {
          th: 'ท่าม่วง',
          en: 'Tha Muang',
        },
        tambons: {
          710601: {
            name: {
              th: 'ท่าม่วง',
              en: 'Tha Muang',
            },
            coordinates: {
              lat: '13.974',
              lng: '99.625',
            },
            zipcode: 71110,
          },
          710602: {
            name: {
              th: 'วังขนาย',
              en: 'Wang Khanai',
            },
            coordinates: {
              lat: '13.960',
              lng: '99.663',
            },
            zipcode: 71110,
          },
          710603: {
            name: {
              th: 'วังศาลา',
              en: 'Wang Sala',
            },
            coordinates: {
              lat: '13.971',
              lng: '99.697',
            },
            zipcode: 71110,
          },
          710604: {
            name: {
              th: 'ท่าล้อ',
              en: 'Tha Lo',
            },
            coordinates: {
              lat: '13.987',
              lng: '99.589',
            },
            zipcode: 71000,
          },
          710605: {
            name: {
              th: 'หนองขาว',
              en: 'Nong Khao',
            },
            coordinates: {
              lat: '14.055',
              lng: '99.613',
            },
            zipcode: 71110,
          },
          710606: {
            name: {
              th: 'ทุ่งทอง',
              en: 'Thung Thong',
            },
            coordinates: {
              lat: '14.008',
              lng: '99.666',
            },
            zipcode: 71110,
          },
          710607: {
            name: {
              th: 'เขาน้อย',
              en: 'Khao Noi',
            },
            coordinates: {
              lat: '13.919',
              lng: '99.588',
            },
            zipcode: 71110,
          },
          710608: {
            name: {
              th: 'ม่วงชุม',
              en: 'Muang Chum',
            },
            coordinates: {
              lat: '13.937',
              lng: '99.617',
            },
            zipcode: 71110,
          },
          710609: {
            name: {
              th: 'บ้านใหม่',
              en: 'Ban Mai',
            },
            coordinates: {
              lat: '13.868',
              lng: '99.604',
            },
            zipcode: 71110,
          },
          710610: {
            name: {
              th: 'พังตรุ',
              en: 'Phang Tru',
            },
            coordinates: {
              lat: '13.863',
              lng: '99.645',
            },
            zipcode: 71140,
          },
          710611: {
            name: {
              th: 'ท่าตะคร้อ',
              en: 'Tha Takhro',
            },
            coordinates: {
              lat: '13.917',
              lng: '99.699',
            },
            zipcode: 71130,
          },
          710612: {
            name: {
              th: 'รางสาลี่',
              en: 'Rang Sali',
            },
            coordinates: {
              lat: '13.866',
              lng: '99.543',
            },
            zipcode: 71110,
          },
          710613: {
            name: {
              th: 'หนองตากยา',
              en: 'Nong Tak Ya',
            },
            coordinates: {
              lat: '13.795',
              lng: '99.546',
            },
            zipcode: 71110,
          },
        },
      },
      7107: {
        name: {
          th: 'ทองผาภูมิ',
          en: 'Thong Pha Phum',
        },
        tambons: {
          710701: {
            name: {
              th: 'ท่าขนุน',
              en: 'Tha Khanun',
            },
            coordinates: {
              lat: '14.762',
              lng: '98.641',
            },
            zipcode: 71180,
          },
          710702: {
            name: {
              th: 'ปิล๊อก',
              en: 'Pi Lok',
            },
            coordinates: {
              lat: '14.779',
              lng: '98.403',
            },
            zipcode: 71180,
          },
          710703: {
            name: {
              th: 'หินดาด',
              en: 'Hin Dat',
            },
            coordinates: {
              lat: '14.608',
              lng: '98.723',
            },
            zipcode: 71180,
          },
          710704: {
            name: {
              th: 'ลิ่นถิ่น',
              en: 'Lin Thin',
            },
            coordinates: {
              lat: '14.584',
              lng: '98.833',
            },
            zipcode: 71180,
          },
          710705: {
            name: {
              th: 'ชะแล',
              en: 'Chalae',
            },
            coordinates: {
              lat: '14.996',
              lng: '98.833',
            },
            zipcode: 71180,
          },
          710706: {
            name: {
              th: 'ห้วยเขย่ง',
              en: 'Huai Khayeng',
            },
            coordinates: {
              lat: '14.635',
              lng: '98.583',
            },
            zipcode: 71180,
          },
          710707: {
            name: {
              th: 'สหกรณ์นิคม',
              en: 'Sahakon Nikhom',
            },
            coordinates: {
              lat: '14.735',
              lng: '98.763',
            },
            zipcode: 71180,
          },
        },
      },
      7108: {
        name: {
          th: 'สังขละบุรี',
          en: 'Sangkhla Buri',
        },
        tambons: {
          710801: {
            name: {
              th: 'หนองลู',
              en: 'Nong Lu',
            },
            coordinates: {
              lat: '15.112',
              lng: '98.341',
            },
            zipcode: 71240,
          },
          710802: {
            name: {
              th: 'ปรังเผล',
              en: 'Prang Phle',
            },
            coordinates: {
              lat: '14.999',
              lng: '98.545',
            },
            zipcode: 71240,
          },
          710803: {
            name: {
              th: 'ไล่โว่',
              en: 'Lai Wo',
            },
            coordinates: {
              lat: '15.345',
              lng: '98.664',
            },
            zipcode: 71240,
          },
        },
      },
      7109: {
        name: {
          th: 'พนมทวน',
          en: 'Phanom Thuan',
        },
        tambons: {
          710901: {
            name: {
              th: 'พนมทวน',
              en: 'Phanom Thuan',
            },
            coordinates: {
              lat: '14.129',
              lng: '99.705',
            },
            zipcode: 71140,
          },
          710902: {
            name: {
              th: 'หนองโรง',
              en: 'Nong Rong',
            },
            coordinates: {
              lat: '14.162',
              lng: '99.622',
            },
            zipcode: 71140,
          },
          710903: {
            name: {
              th: 'ทุ่งสมอ',
              en: 'Thung Samo',
            },
            coordinates: {
              lat: '14.076',
              lng: '99.667',
            },
            zipcode: 71140,
          },
          710904: {
            name: {
              th: 'ดอนเจดีย์',
              en: 'Don Chedi',
            },
            coordinates: {
              lat: '14.036',
              lng: '99.674',
            },
            zipcode: 71140,
          },
          710905: {
            name: {
              th: 'พังตรุ',
              en: 'Phang Tru',
            },
            coordinates: {
              lat: '14.134',
              lng: '99.746',
            },
            zipcode: null,
          },
          710906: {
            name: {
              th: 'รางหวาย',
              en: 'Rang Wai',
            },
            coordinates: {
              lat: '14.232',
              lng: '99.767',
            },
            zipcode: 71170,
          },
          710911: {
            name: {
              th: 'หนองสาหร่าย',
              en: 'Nong Sarai',
            },
            coordinates: {
              lat: '14.067',
              lng: '99.728',
            },
            zipcode: 71140,
          },
          710912: {
            name: {
              th: 'ดอนตาเพชร',
              en: 'Don Ta Phet',
            },
            coordinates: {
              lat: '14.192',
              lng: '99.678',
            },
            zipcode: 71140,
          },
        },
      },
      7110: {
        name: {
          th: 'เลาขวัญ',
          en: 'Lao Khwan',
        },
        tambons: {
          711001: {
            name: {
              th: 'เลาขวัญ',
              en: 'Lao Khwan',
            },
            coordinates: {
              lat: '14.580',
              lng: '99.753',
            },
            zipcode: 71210,
          },
          711002: {
            name: {
              th: 'หนองโสน',
              en: 'Nong Sano',
            },
            coordinates: {
              lat: '14.648',
              lng: '99.758',
            },
            zipcode: 71210,
          },
          711003: {
            name: {
              th: 'หนองประดู่',
              en: 'Nong Pradu',
            },
            coordinates: {
              lat: '14.456',
              lng: '99.738',
            },
            zipcode: 71210,
          },
          711004: {
            name: {
              th: 'หนองปลิง',
              en: 'Nong Pling',
            },
            coordinates: {
              lat: '14.715',
              lng: '99.743',
            },
            zipcode: 71210,
          },
          711005: {
            name: {
              th: 'หนองนกแก้ว',
              en: 'Nong Nok Kaeo',
            },
            coordinates: {
              lat: '14.516',
              lng: '99.655',
            },
            zipcode: 71210,
          },
          711006: {
            name: {
              th: 'ทุ่งกระบ่ำ',
              en: 'Thung Krabam',
            },
            coordinates: {
              lat: '14.616',
              lng: '99.586',
            },
            zipcode: 71210,
          },
          711007: {
            name: {
              th: 'หนองฝ้าย',
              en: 'Nong Fai',
            },
            coordinates: {
              lat: '14.682',
              lng: '99.642',
            },
            zipcode: 71210,
          },
        },
      },
      7111: {
        name: {
          th: 'ด่านมะขามเตี้ย',
          en: 'Dan Makham Tia',
        },
        tambons: {
          711101: {
            name: {
              th: 'ด่านมะขามเตี้ย',
              en: 'Dan Makham Tia',
            },
            coordinates: {
              lat: '13.836',
              lng: '99.388',
            },
            zipcode: null,
          },
          711102: {
            name: {
              th: 'กลอนโด',
              en: 'Klon Do',
            },
            coordinates: {
              lat: '13.880',
              lng: '99.440',
            },
            zipcode: null,
          },
          711103: {
            name: {
              th: 'จรเข้เผือก',
              en: 'Chorakhe Phueak',
            },
            coordinates: {
              lat: '13.811',
              lng: '99.210',
            },
            zipcode: null,
          },
          711104: {
            name: {
              th: 'หนองไผ่',
              en: 'Nong Phai',
            },
            coordinates: {
              lat: '13.808',
              lng: '99.440',
            },
            zipcode: 71260,
          },
        },
      },
      7112: {
        name: {
          th: 'หนองปรือ',
          en: 'Nong Prue',
        },
        tambons: {
          711201: {
            name: {
              th: 'หนองปรือ',
              en: 'Nong Prue',
            },
            coordinates: {
              lat: '14.630',
              lng: '99.454',
            },
            zipcode: null,
          },
          711202: {
            name: {
              th: 'หนองปลาไหล',
              en: 'Nong Pla Lai',
            },
            coordinates: {
              lat: '14.626',
              lng: '99.527',
            },
            zipcode: null,
          },
          711203: {
            name: {
              th: 'สมเด็จเจริญ',
              en: 'Somdet Charoen',
            },
            coordinates: {
              lat: '14.746',
              lng: '99.338',
            },
            zipcode: null,
          },
        },
      },
      7113: {
        name: {
          th: 'ห้วยกระเจา',
          en: 'Huai Krachao',
        },
        tambons: {
          711301: {
            name: {
              th: 'ห้วยกระเจา',
              en: 'Huai Krachao',
            },
            coordinates: {
              lat: '14.312',
              lng: '99.618',
            },
            zipcode: null,
          },
          711302: {
            name: {
              th: 'วังไผ่',
              en: 'Wang Phai',
            },
            coordinates: {
              lat: '14.434',
              lng: '99.597',
            },
            zipcode: null,
          },
          711303: {
            name: {
              th: 'ดอนแสลบ',
              en: 'Don Salaep',
            },
            coordinates: {
              lat: '14.292',
              lng: '99.701',
            },
            zipcode: null,
          },
          711304: {
            name: {
              th: 'สระลงเรือ',
              en: 'Sa Long Ruea',
            },
            coordinates: {
              lat: '14.357',
              lng: '99.764',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  72: {
    name: {
      th: 'สุพรรณบุรี',
      en: 'Suphan Buri',
    },
    amphoes: {
      7201: {
        name: {
          th: 'เมืองสุพรรณบุรี',
          en: 'Mueang Saphan Buri',
        },
        tambons: {
          720101: {
            name: {
              th: 'ท่าพี่เลี้ยง',
              en: 'Tha Phi Liang',
            },
            coordinates: {
              lat: '14.471',
              lng: '100.116',
            },
            zipcode: 72000,
          },
          720102: {
            name: {
              th: 'รั้วใหญ่',
              en: 'Rua Yai',
            },
            coordinates: {
              lat: '14.451',
              lng: '100.113',
            },
            zipcode: 72000,
          },
          720103: {
            name: {
              th: 'ทับตีเหล็ก',
              en: 'Thap Ti Lek',
            },
            coordinates: {
              lat: '14.434',
              lng: '100.108',
            },
            zipcode: 72000,
          },
          720104: {
            name: {
              th: 'ท่าระหัด',
              en: 'Tha Rahat',
            },
            coordinates: {
              lat: '14.445',
              lng: '100.164',
            },
            zipcode: 72000,
          },
          720105: {
            name: {
              th: 'ไผ่ขวาง',
              en: 'Phai Khwang',
            },
            coordinates: {
              lat: '14.470',
              lng: '100.165',
            },
            zipcode: 72000,
          },
          720106: {
            name: {
              th: 'โคกโคเฒ่า',
              en: 'Khok Kho Thao',
            },
            coordinates: {
              lat: '14.464',
              lng: '100.233',
            },
            zipcode: 72000,
          },
          720107: {
            name: {
              th: 'ดอนตาล',
              en: 'Don Tan',
            },
            coordinates: {
              lat: '14.490',
              lng: '100.208',
            },
            zipcode: 72000,
          },
          720108: {
            name: {
              th: 'ดอนมะสังข์',
              en: 'Don Masang',
            },
            coordinates: {
              lat: '14.524',
              lng: '100.181',
            },
            zipcode: 72000,
          },
          720109: {
            name: {
              th: 'พิหารแดง',
              en: 'Phihan Daeng',
            },
            coordinates: {
              lat: '14.506',
              lng: '100.107',
            },
            zipcode: 72000,
          },
          720110: {
            name: {
              th: 'ดอนกำยาน',
              en: 'Don Kamyan',
            },
            coordinates: {
              lat: '14.454',
              lng: '100.064',
            },
            zipcode: 72000,
          },
          720111: {
            name: {
              th: 'ดอนโพธิ์ทอง',
              en: 'Don Pho Thong',
            },
            coordinates: {
              lat: '14.426',
              lng: '100.032',
            },
            zipcode: 72000,
          },
          720112: {
            name: {
              th: 'บ้านโพธิ์',
              en: 'Ban Pho',
            },
            coordinates: {
              lat: '14.524',
              lng: '100.071',
            },
            zipcode: 72000,
          },
          720113: {
            name: {
              th: 'สระแก้ว',
              en: 'Sa Kaeo',
            },
            coordinates: {
              lat: '14.502',
              lng: '100.014',
            },
            zipcode: 72230,
          },
          720114: {
            name: {
              th: 'ตลิ่งชัน',
              en: 'Taling Chan',
            },
            coordinates: {
              lat: '14.559',
              lng: '100.029',
            },
            zipcode: 72230,
          },
          720115: {
            name: {
              th: 'บางกุ้ง',
              en: 'Bang Kung',
            },
            coordinates: {
              lat: '14.457',
              lng: '100.003',
            },
            zipcode: 72210,
          },
          720116: {
            name: {
              th: 'ศาลาขาว',
              en: 'Sala Khao',
            },
            coordinates: {
              lat: '14.433',
              lng: '99.973',
            },
            zipcode: 72210,
          },
          720117: {
            name: {
              th: 'สวนแตง',
              en: 'Suan Taeng',
            },
            coordinates: {
              lat: '14.436',
              lng: '100.018',
            },
            zipcode: 72210,
          },
          720118: {
            name: {
              th: 'สนามชัย',
              en: 'Sanam Chai',
            },
            coordinates: {
              lat: '14.492',
              lng: '100.169',
            },
            zipcode: 72000,
          },
          720119: {
            name: {
              th: 'โพธิ์พระยา',
              en: 'Pho Phraya',
            },
            coordinates: {
              lat: '14.531',
              lng: '100.116',
            },
            zipcode: 72000,
          },
          720120: {
            name: {
              th: 'สนามคลี',
              en: 'Sanam Khli',
            },
            coordinates: {
              lat: '14.539',
              lng: '99.982',
            },
            zipcode: 72230,
          },
        },
      },
      7202: {
        name: {
          th: 'เดิมบางนางบวช',
          en: 'Doem Bang Nang Buat',
        },
        tambons: {
          720201: {
            name: {
              th: 'เขาพระ',
              en: 'Khao Phra',
            },
            coordinates: {
              lat: '14.853',
              lng: '100.095',
            },
            zipcode: 72120,
          },
          720202: {
            name: {
              th: 'เดิมบาง',
              en: 'Doem Bang',
            },
            coordinates: {
              lat: '14.893',
              lng: '100.109',
            },
            zipcode: 72120,
          },
          720203: {
            name: {
              th: 'นางบวช',
              en: 'Nang Buat',
            },
            coordinates: {
              lat: '14.811',
              lng: '100.120',
            },
            zipcode: 72120,
          },
          720204: {
            name: {
              th: 'เขาดิน',
              en: 'Khao Din',
            },
            coordinates: {
              lat: '14.803',
              lng: '100.189',
            },
            zipcode: 72120,
          },
          720205: {
            name: {
              th: 'ปากน้ำ',
              en: 'Pak Nam',
            },
            coordinates: {
              lat: '14.931',
              lng: '100.087',
            },
            zipcode: 72120,
          },
          720206: {
            name: {
              th: 'ทุ่งคลี',
              en: 'Thung Khli',
            },
            coordinates: {
              lat: '14.863',
              lng: '100.189',
            },
            zipcode: 72120,
          },
          720207: {
            name: {
              th: 'โคกช้าง',
              en: 'Khok Chang',
            },
            coordinates: {
              lat: '14.910',
              lng: '100.169',
            },
            zipcode: 72120,
          },
          720208: {
            name: {
              th: 'หัวเขา',
              en: 'Hua Khao',
            },
            coordinates: {
              lat: '14.869',
              lng: '100.038',
            },
            zipcode: 72120,
          },
          720209: {
            name: {
              th: 'หัวนา',
              en: 'Hua Na',
            },
            coordinates: {
              lat: '14.883',
              lng: '99.970',
            },
            zipcode: 72120,
          },
          720210: {
            name: {
              th: 'บ่อกรุ',
              en: 'Bo Kru',
            },
            coordinates: {
              lat: '14.893',
              lng: '99.905',
            },
            zipcode: 72120,
          },
          720211: {
            name: {
              th: 'วังศรีราช',
              en: 'Wang Si Rat',
            },
            coordinates: {
              lat: '14.850',
              lng: '99.988',
            },
            zipcode: 72120,
          },
          720213: {
            name: {
              th: 'ยางนอน',
              en: 'Yang Non',
            },
            coordinates: {
              lat: '14.857',
              lng: '100.132',
            },
            zipcode: 72120,
          },
          720214: {
            name: {
              th: 'หนองกระทุ่ม',
              en: 'Nong Krathum',
            },
            coordinates: {
              lat: '14.907',
              lng: '99.804',
            },
            zipcode: 72120,
          },
        },
      },
      7203: {
        name: {
          th: 'ด่านช้าง',
          en: 'Dan Chang',
        },
        tambons: {
          720301: {
            name: {
              th: 'หนองมะค่าโมง',
              en: 'Nong Makha Mong',
            },
            coordinates: {
              lat: '14.882',
              lng: '99.737',
            },
            zipcode: null,
          },
          720302: {
            name: {
              th: 'ด่านช้าง',
              en: 'Dan Chang',
            },
            coordinates: {
              lat: '14.744',
              lng: '99.527',
            },
            zipcode: null,
          },
          720303: {
            name: {
              th: 'ห้วยขมิ้น',
              en: 'Huai Khamin',
            },
            coordinates: {
              lat: '14.922',
              lng: '99.534',
            },
            zipcode: null,
          },
          720304: {
            name: {
              th: 'องค์พระ',
              en: 'Ong Phra',
            },
            coordinates: {
              lat: '14.800',
              lng: '99.386',
            },
            zipcode: null,
          },
          720305: {
            name: {
              th: 'วังคัน',
              en: 'Wang Khan',
            },
            coordinates: {
              lat: '14.937',
              lng: '99.666',
            },
            zipcode: 72180,
          },
          720306: {
            name: {
              th: 'นิคมกระเสียว',
              en: 'Nikhom Krasiao',
            },
            coordinates: {
              lat: '14.844',
              lng: '99.548',
            },
            zipcode: 72180,
          },
          720307: {
            name: {
              th: 'วังยาว',
              en: 'Wang Yao',
            },
            coordinates: {
              lat: '14.954',
              lng: '99.381',
            },
            zipcode: 72180,
          },
        },
      },
      7204: {
        name: {
          th: 'บางปลาม้า',
          en: 'Bang Pla Ma',
        },
        tambons: {
          720401: {
            name: {
              th: 'โคกคราม',
              en: 'Khok Khram',
            },
            coordinates: {
              lat: '14.414',
              lng: '100.151',
            },
            zipcode: 72150,
          },
          720402: {
            name: {
              th: 'บางปลาม้า',
              en: 'Bang Pla Ma',
            },
            coordinates: {
              lat: '14.402',
              lng: '100.102',
            },
            zipcode: 72150,
          },
          720403: {
            name: {
              th: 'ตะค่า',
              en: 'Takha',
            },
            coordinates: {
              lat: '14.348',
              lng: '100.171',
            },
            zipcode: 72150,
          },
          720404: {
            name: {
              th: 'บางใหญ่',
              en: 'Bang Yai',
            },
            coordinates: {
              lat: '14.300',
              lng: '100.102',
            },
            zipcode: 72150,
          },
          720405: {
            name: {
              th: 'กฤษณา',
              en: 'Kritsana',
            },
            coordinates: {
              lat: '14.290',
              lng: '100.178',
            },
            zipcode: 72150,
          },
          720406: {
            name: {
              th: 'สาลี',
              en: 'Sali',
            },
            coordinates: {
              lat: '14.286',
              lng: '100.221',
            },
            zipcode: 72150,
          },
          720407: {
            name: {
              th: 'ไผ่กองดิน',
              en: 'Phai Kong Din',
            },
            coordinates: {
              lat: '14.340',
              lng: '100.264',
            },
            zipcode: 72150,
          },
          720408: {
            name: {
              th: 'องครักษ์',
              en: 'Ongkharak',
            },
            coordinates: {
              lat: '14.374',
              lng: '100.236',
            },
            zipcode: 72150,
          },
          720409: {
            name: {
              th: 'จรเข้ใหญ่',
              en: 'Chorakhe Yai',
            },
            coordinates: {
              lat: '14.421',
              lng: '100.236',
            },
            zipcode: 72150,
          },
          720410: {
            name: {
              th: 'บ้านแหลม',
              en: 'Ban Laem',
            },
            coordinates: {
              lat: '14.346',
              lng: '100.140',
            },
            zipcode: 72150,
          },
          720411: {
            name: {
              th: 'มะขามล้ม',
              en: 'Makham Lom',
            },
            coordinates: {
              lat: '14.370',
              lng: '100.043',
            },
            zipcode: 72150,
          },
          720412: {
            name: {
              th: 'วังน้ำเย็น',
              en: 'Wang Nam Yen',
            },
            coordinates: {
              lat: '14.377',
              lng: '100.015',
            },
            zipcode: 72150,
          },
          720413: {
            name: {
              th: 'วัดโบสถ์',
              en: 'Wat Bot',
            },
            coordinates: {
              lat: '14.330',
              lng: '100.037',
            },
            zipcode: 72150,
          },
          720414: {
            name: {
              th: 'วัดดาว',
              en: 'Wat Dao',
            },
            coordinates: {
              lat: '14.350',
              lng: '100.106',
            },
            zipcode: 72150,
          },
        },
      },
      7205: {
        name: {
          th: 'ศรีประจันต์',
          en: 'Si Prachan',
        },
        tambons: {
          720501: {
            name: {
              th: 'ศรีประจันต์',
              en: 'Si Prachan',
            },
            coordinates: {
              lat: '14.609',
              lng: '100.179',
            },
            zipcode: 72140,
          },
          720502: {
            name: {
              th: 'บ้านกร่าง',
              en: 'Ban Krang',
            },
            coordinates: {
              lat: '14.638',
              lng: '100.104',
            },
            zipcode: 72140,
          },
          720503: {
            name: {
              th: 'มดแดง',
              en: 'Mot Daeng',
            },
            coordinates: {
              lat: '14.570',
              lng: '100.119',
            },
            zipcode: 72140,
          },
          720504: {
            name: {
              th: 'บางงาม',
              en: 'Bang Ngam',
            },
            coordinates: {
              lat: '14.601',
              lng: '100.097',
            },
            zipcode: 72140,
          },
          720505: {
            name: {
              th: 'ดอนปรู',
              en: 'Don Pru',
            },
            coordinates: {
              lat: '14.722',
              lng: '100.190',
            },
            zipcode: 72140,
          },
          720506: {
            name: {
              th: 'ปลายนา',
              en: 'Plai Na',
            },
            coordinates: {
              lat: '14.660',
              lng: '100.200',
            },
            zipcode: 72140,
          },
          720507: {
            name: {
              th: 'วังหว้า',
              en: 'Wang Wa',
            },
            coordinates: {
              lat: '14.661',
              lng: '100.095',
            },
            zipcode: 72140,
          },
          720508: {
            name: {
              th: 'วังน้ำซับ',
              en: 'Wang Nam Sap',
            },
            coordinates: {
              lat: '14.650',
              lng: '100.154',
            },
            zipcode: 72140,
          },
          720509: {
            name: {
              th: 'วังยาง',
              en: 'Wang Yang',
            },
            coordinates: {
              lat: '14.559',
              lng: '100.167',
            },
            zipcode: 72140,
          },
        },
      },
      7206: {
        name: {
          th: 'ดอนเจดีย์',
          en: 'Don Chedi',
        },
        tambons: {
          720601: {
            name: {
              th: 'ดอนเจดีย์',
              en: 'Don Chedi',
            },
            coordinates: {
              lat: '14.633',
              lng: '99.959',
            },
            zipcode: 72170,
          },
          720602: {
            name: {
              th: 'หนองสาหร่าย',
              en: 'Nong Sarai',
            },
            coordinates: {
              lat: '14.671',
              lng: '100.011',
            },
            zipcode: 72170,
          },
          720603: {
            name: {
              th: 'ไร่รถ',
              en: 'Rai Rot',
            },
            coordinates: {
              lat: '14.603',
              lng: '99.955',
            },
            zipcode: 72170,
          },
          720604: {
            name: {
              th: 'สระกระโจม',
              en: 'Sa Krachom',
            },
            coordinates: {
              lat: '14.643',
              lng: '99.865',
            },
            zipcode: 72250,
          },
          720605: {
            name: {
              th: 'ทะเลบก',
              en: 'Thale Bok',
            },
            coordinates: {
              lat: '14.708',
              lng: '99.838',
            },
            zipcode: 72250,
          },
        },
      },
      7207: {
        name: {
          th: 'สองพี่น้อง',
          en: 'Song Phi Nong',
        },
        tambons: {
          720701: {
            name: {
              th: 'สองพี่น้อง',
              en: 'Song Phi Nong',
            },
            coordinates: {
              lat: '14.225',
              lng: '100.023',
            },
            zipcode: 72110,
          },
          720702: {
            name: {
              th: 'บางเลน',
              en: 'Bang Len',
            },
            coordinates: {
              lat: '14.169',
              lng: '100.085',
            },
            zipcode: 72110,
          },
          720703: {
            name: {
              th: 'บางตาเถร',
              en: 'Bang Ta Then',
            },
            coordinates: {
              lat: '14.199',
              lng: '100.174',
            },
            zipcode: 72110,
          },
          720704: {
            name: {
              th: 'บางตะเคียน',
              en: 'Bang Takhian',
            },
            coordinates: {
              lat: '14.213',
              lng: '100.103',
            },
            zipcode: 72110,
          },
          720705: {
            name: {
              th: 'บ้านกุ่ม',
              en: 'Ban Kum',
            },
            coordinates: {
              lat: '14.260',
              lng: '100.114',
            },
            zipcode: 72110,
          },
          720706: {
            name: {
              th: 'หัวโพธิ์',
              en: 'Hua Pho',
            },
            coordinates: {
              lat: '14.302',
              lng: '99.981',
            },
            zipcode: 72110,
          },
          720707: {
            name: {
              th: 'บางพลับ',
              en: 'Bang Phlap',
            },
            coordinates: {
              lat: '14.252',
              lng: '100.046',
            },
            zipcode: 72110,
          },
          720708: {
            name: {
              th: 'เนินพระปรางค์',
              en: 'Noen Phraprang',
            },
            coordinates: {
              lat: '14.194',
              lng: '100.048',
            },
            zipcode: 72110,
          },
          720709: {
            name: {
              th: 'บ้านช้าง',
              en: 'Ban Chang',
            },
            coordinates: {
              lat: '14.234',
              lng: '100.206',
            },
            zipcode: 72110,
          },
          720710: {
            name: {
              th: 'ต้นตาล',
              en: 'Ton Tan',
            },
            coordinates: {
              lat: '14.220',
              lng: '100.061',
            },
            zipcode: 72110,
          },
          720711: {
            name: {
              th: 'ศรีสำราญ',
              en: 'Si Samran',
            },
            coordinates: {
              lat: '14.217',
              lng: '99.948',
            },
            zipcode: 72110,
          },
          720712: {
            name: {
              th: 'ทุ่งคอก',
              en: 'Thung Khok',
            },
            coordinates: {
              lat: '14.166',
              lng: '99.959',
            },
            zipcode: 72110,
          },
          720713: {
            name: {
              th: 'หนองบ่อ',
              en: 'Nong Bo',
            },
            coordinates: {
              lat: '14.204',
              lng: '99.860',
            },
            zipcode: 72190,
          },
          720714: {
            name: {
              th: 'บ่อสุพรรณ',
              en: 'Bo Suphan',
            },
            coordinates: {
              lat: '14.130',
              lng: '99.849',
            },
            zipcode: 72190,
          },
          720715: {
            name: {
              th: 'ดอนมะนาว',
              en: 'Don Manao',
            },
            coordinates: {
              lat: '14.166',
              lng: '100.011',
            },
            zipcode: 72110,
          },
        },
      },
      7208: {
        name: {
          th: 'สามชุก',
          en: 'Sam Chuk',
        },
        tambons: {
          720801: {
            name: {
              th: 'ย่านยาว',
              en: 'Yan Yao',
            },
            coordinates: {
              lat: '14.710',
              lng: '100.112',
            },
            zipcode: 72130,
          },
          720802: {
            name: {
              th: 'วังลึก',
              en: 'Wang Luek',
            },
            coordinates: {
              lat: '14.758',
              lng: '100.158',
            },
            zipcode: 72130,
          },
          720803: {
            name: {
              th: 'สามชุก',
              en: 'Sam Chuk',
            },
            coordinates: {
              lat: '14.763',
              lng: '100.095',
            },
            zipcode: 72130,
          },
          720804: {
            name: {
              th: 'หนองผักนาก',
              en: 'Nong Phak Nak',
            },
            coordinates: {
              lat: '14.757',
              lng: '100.032',
            },
            zipcode: 72130,
          },
          720805: {
            name: {
              th: 'บ้านสระ',
              en: 'Ban Sa',
            },
            coordinates: {
              lat: '14.708',
              lng: '100.041',
            },
            zipcode: 72130,
          },
          720806: {
            name: {
              th: 'หนองสะเดา',
              en: 'Nong Sadao',
            },
            coordinates: {
              lat: '14.803',
              lng: '99.991',
            },
            zipcode: 72130,
          },
          720807: {
            name: {
              th: 'กระเสียว',
              en: 'Krasiao',
            },
            coordinates: {
              lat: '14.801',
              lng: '100.056',
            },
            zipcode: 72130,
          },
        },
      },
      7209: {
        name: {
          th: 'อู่ทอง',
          en: 'U Thong',
        },
        tambons: {
          720901: {
            name: {
              th: 'อู่ทอง',
              en: 'U Thong',
            },
            coordinates: {
              lat: '14.377',
              lng: '99.880',
            },
            zipcode: 72160,
          },
          720902: {
            name: {
              th: 'สระยายโสม',
              en: 'Sa Yai Som',
            },
            coordinates: {
              lat: '14.273',
              lng: '99.874',
            },
            zipcode: 72220,
          },
          720903: {
            name: {
              th: 'จรเข้สามพัน',
              en: 'Chorakhe Sam Phan',
            },
            coordinates: {
              lat: '14.317',
              lng: '99.850',
            },
            zipcode: 71170,
          },
          720904: {
            name: {
              th: 'บ้านดอน',
              en: 'Ban Don',
            },
            coordinates: {
              lat: '14.303',
              lng: '99.923',
            },
            zipcode: 72160,
          },
          720905: {
            name: {
              th: 'ยุ้งทะลาย',
              en: 'Yung Thalai',
            },
            coordinates: {
              lat: '14.322',
              lng: '99.898',
            },
            zipcode: 72160,
          },
          720906: {
            name: {
              th: 'ดอนมะเกลือ',
              en: 'Don Makluea',
            },
            coordinates: {
              lat: '14.263',
              lng: '99.950',
            },
            zipcode: 72220,
          },
          720907: {
            name: {
              th: 'หนองโอ่ง',
              en: 'Nong Ong',
            },
            coordinates: {
              lat: '14.418',
              lng: '99.886',
            },
            zipcode: 72160,
          },
          720908: {
            name: {
              th: 'ดอนคา',
              en: 'Don Kha',
            },
            coordinates: {
              lat: '14.452',
              lng: '99.854',
            },
            zipcode: 72160,
          },
          720909: {
            name: {
              th: 'พลับพลาไชย',
              en: 'Phlapphla Chai',
            },
            coordinates: {
              lat: '14.517',
              lng: '99.887',
            },
            zipcode: 72160,
          },
          720910: {
            name: {
              th: 'บ้านโข้ง',
              en: 'Ban Khong',
            },
            coordinates: {
              lat: '14.578',
              lng: '99.864',
            },
            zipcode: 72160,
          },
          720911: {
            name: {
              th: 'เจดีย์',
              en: 'Chedi',
            },
            coordinates: {
              lat: '14.353',
              lng: '99.966',
            },
            zipcode: 72160,
          },
          720912: {
            name: {
              th: 'สระพังลาน',
              en: 'Sa Phang Lan',
            },
            coordinates: {
              lat: '14.253',
              lng: '99.906',
            },
            zipcode: 72220,
          },
          720913: {
            name: {
              th: 'กระจัน',
              en: 'Krachan',
            },
            coordinates: {
              lat: '14.367',
              lng: '99.928',
            },
            zipcode: 72160,
          },
        },
      },
      7210: {
        name: {
          th: 'หนองหญ้าไซ',
          en: 'Nong Ya Sai',
        },
        tambons: {
          721001: {
            name: {
              th: 'หนองหญ้าไซ',
              en: 'Nong Ya Sai',
            },
            coordinates: {
              lat: '14.776',
              lng: '99.885',
            },
            zipcode: null,
          },
          721002: {
            name: {
              th: 'หนองราชวัตร',
              en: 'Nong Ratchawat',
            },
            coordinates: {
              lat: '14.720',
              lng: '99.931',
            },
            zipcode: null,
          },
          721003: {
            name: {
              th: 'หนองโพธิ์',
              en: 'Nong Pho',
            },
            coordinates: {
              lat: '14.831',
              lng: '99.919',
            },
            zipcode: null,
          },
          721004: {
            name: {
              th: 'แจงงาม',
              en: 'Chaeng Ngam',
            },
            coordinates: {
              lat: '14.820',
              lng: '99.804',
            },
            zipcode: null,
          },
          721005: {
            name: {
              th: 'หนองขาม',
              en: 'Nong Kham',
            },
            coordinates: {
              lat: '14.796',
              lng: '99.693',
            },
            zipcode: 72240,
          },
          721006: {
            name: {
              th: 'ทัพหลวง',
              en: 'Thap Luang',
            },
            coordinates: {
              lat: '14.749',
              lng: '99.806',
            },
            zipcode: 72240,
          },
        },
      },
    },
  },
  73: {
    name: {
      th: 'นครปฐม',
      en: 'Nakhon Pathom',
    },
    amphoes: {
      7301: {
        name: {
          th: 'เมืองนครปฐม',
          en: 'mueang Nakhon Pathom',
        },
        tambons: {
          730101: {
            name: {
              th: 'พระปฐมเจดีย์',
              en: 'Phra Pathom Chedi',
            },
            coordinates: {
              lat: '13.819',
              lng: '100.050',
            },
            zipcode: 73000,
          },
          730102: {
            name: {
              th: 'บางแขม',
              en: 'Bang Khaem',
            },
            coordinates: {
              lat: '13.754',
              lng: '100.034',
            },
            zipcode: 73000,
          },
          730103: {
            name: {
              th: 'พระประโทน',
              en: 'Phra Prathon',
            },
            coordinates: {
              lat: '13.811',
              lng: '100.088',
            },
            zipcode: 73000,
          },
          730104: {
            name: {
              th: 'ธรรมศาลา',
              en: 'Thammasala',
            },
            coordinates: {
              lat: '13.807',
              lng: '100.105',
            },
            zipcode: 73000,
          },
          730105: {
            name: {
              th: 'ตาก้อง',
              en: 'Ta Kong',
            },
            coordinates: {
              lat: '13.892',
              lng: '100.054',
            },
            zipcode: 73000,
          },
          730106: {
            name: {
              th: 'มาบแค',
              en: 'Map Khae',
            },
            coordinates: {
              lat: '13.864',
              lng: '100.073',
            },
            zipcode: 73000,
          },
          730107: {
            name: {
              th: 'สนามจันทร์',
              en: 'Sanam Chan',
            },
            coordinates: {
              lat: '13.804',
              lng: '100.041',
            },
            zipcode: 73000,
          },
          730108: {
            name: {
              th: 'ดอนยายหอม',
              en: 'Don Yai Hom',
            },
            coordinates: {
              lat: '13.725',
              lng: '100.070',
            },
            zipcode: 73000,
          },
          730109: {
            name: {
              th: 'ถนนขาด',
              en: 'Thanon Khat',
            },
            coordinates: {
              lat: '13.782',
              lng: '100.077',
            },
            zipcode: 73000,
          },
          730110: {
            name: {
              th: 'บ่อพลับ',
              en: 'Bo Phlap',
            },
            coordinates: {
              lat: '13.837',
              lng: '100.065',
            },
            zipcode: 73000,
          },
          730111: {
            name: {
              th: 'นครปฐม',
              en: 'Nakhon Pathom',
            },
            coordinates: {
              lat: '13.848',
              lng: '100.044',
            },
            zipcode: 73000,
          },
          730112: {
            name: {
              th: 'วังตะกู',
              en: 'Wang Taku',
            },
            coordinates: {
              lat: '13.857',
              lng: '100.014',
            },
            zipcode: 73000,
          },
          730113: {
            name: {
              th: 'หนองปากโลง',
              en: 'Nong Pak Long',
            },
            coordinates: {
              lat: '13.866',
              lng: '99.989',
            },
            zipcode: 73000,
          },
          730114: {
            name: {
              th: 'สามควายเผือก',
              en: 'Sam Khwai Phueak',
            },
            coordinates: {
              lat: '13.841',
              lng: '100.113',
            },
            zipcode: 73000,
          },
          730115: {
            name: {
              th: 'ทุ่งน้อย',
              en: 'Thung Noi',
            },
            coordinates: {
              lat: '13.867',
              lng: '100.108',
            },
            zipcode: 73000,
          },
          730116: {
            name: {
              th: 'หนองดินแดง',
              en: 'Nong Din Daeng',
            },
            coordinates: {
              lat: '13.786',
              lng: '99.991',
            },
            zipcode: 73000,
          },
          730117: {
            name: {
              th: 'วังเย็น',
              en: 'Wang Yen',
            },
            coordinates: {
              lat: '13.766',
              lng: '100.008',
            },
            zipcode: 73000,
          },
          730118: {
            name: {
              th: 'โพรงมะเดื่อ',
              en: 'Phrong Maduea',
            },
            coordinates: {
              lat: '13.840',
              lng: '99.978',
            },
            zipcode: 73000,
          },
          730119: {
            name: {
              th: 'ลำพยา',
              en: 'Lam Phaya',
            },
            coordinates: {
              lat: '13.811',
              lng: '100.019',
            },
            zipcode: 73000,
          },
          730120: {
            name: {
              th: 'สระกระเทียม',
              en: 'Sa Kathiam',
            },
            coordinates: {
              lat: '13.750',
              lng: '99.975',
            },
            zipcode: 73000,
          },
          730121: {
            name: {
              th: 'สวนป่าน',
              en: 'Suan Pan',
            },
            coordinates: {
              lat: '13.757',
              lng: '99.947',
            },
            zipcode: 73000,
          },
          730122: {
            name: {
              th: 'ห้วยจรเข้',
              en: 'Huai Chorakhe',
            },
            coordinates: {
              lat: '13.804',
              lng: '100.067',
            },
            zipcode: 73000,
          },
          730123: {
            name: {
              th: 'ทัพหลวง',
              en: 'Thap Luang',
            },
            coordinates: {
              lat: '13.878',
              lng: '100.021',
            },
            zipcode: 73000,
          },
          730124: {
            name: {
              th: 'หนองงูเหลือม',
              en: 'Nong Ngu Lueam',
            },
            coordinates: {
              lat: '13.914',
              lng: '99.952',
            },
            zipcode: 73000,
          },
          730125: {
            name: {
              th: 'บ้านยาง',
              en: 'Ban Yang',
            },
            coordinates: {
              lat: '13.868',
              lng: '99.929',
            },
            zipcode: 73000,
          },
        },
      },
      7302: {
        name: {
          th: 'กำแพงแสน',
          en: 'Kamphaeng Saen',
        },
        tambons: {
          730201: {
            name: {
              th: 'ทุ่งกระพังโหม',
              en: 'Thung Kraphang Hom',
            },
            coordinates: {
              lat: '13.991',
              lng: '99.983',
            },
            zipcode: 73140,
          },
          730202: {
            name: {
              th: 'กระตีบ',
              en: 'Kratip',
            },
            coordinates: {
              lat: '14.111',
              lng: '99.964',
            },
            zipcode: 73180,
          },
          730203: {
            name: {
              th: 'ทุ่งลูกนก',
              en: 'Thung Luk Nok',
            },
            coordinates: {
              lat: '14.055',
              lng: '99.905',
            },
            zipcode: 73140,
          },
          730204: {
            name: {
              th: 'ห้วยขวาง',
              en: 'Huai Khwang',
            },
            coordinates: {
              lat: '13.923',
              lng: '100.015',
            },
            zipcode: 73140,
          },
          730205: {
            name: {
              th: 'ทุ่งขวาง',
              en: 'Thung Khwang',
            },
            coordinates: {
              lat: '13.965',
              lng: '99.963',
            },
            zipcode: 73140,
          },
          730206: {
            name: {
              th: 'สระสี่มุม',
              en: 'Sa Si Mum',
            },
            coordinates: {
              lat: '14.065',
              lng: '100.003',
            },
            zipcode: 73140,
          },
          730207: {
            name: {
              th: 'ทุ่งบัว',
              en: 'Thung Bua',
            },
            coordinates: {
              lat: '14.041',
              lng: '99.935',
            },
            zipcode: 73140,
          },
          730208: {
            name: {
              th: 'ดอนข่อย',
              en: 'Don Khoi',
            },
            coordinates: {
              lat: '14.022',
              lng: '100.035',
            },
            zipcode: 73140,
          },
          730209: {
            name: {
              th: 'สระพัฒนา',
              en: 'Sa Phatthana',
            },
            coordinates: {
              lat: '14.096',
              lng: '100.043',
            },
            zipcode: 73180,
          },
          730210: {
            name: {
              th: 'ห้วยหมอนทอง',
              en: 'Huai Mon Thong',
            },
            coordinates: {
              lat: '13.969',
              lng: '99.921',
            },
            zipcode: 73140,
          },
          730211: {
            name: {
              th: 'ห้วยม่วง',
              en: 'Huai Muang',
            },
            coordinates: {
              lat: '14.130',
              lng: '100.025',
            },
            zipcode: 73180,
          },
          730212: {
            name: {
              th: 'กำแพงแสน',
              en: 'Kamphaeng Saen',
            },
            coordinates: {
              lat: '14.024',
              lng: '99.981',
            },
            zipcode: 73140,
          },
          730213: {
            name: {
              th: 'รางพิกุล',
              en: 'Rang Phikun',
            },
            coordinates: {
              lat: '14.003',
              lng: '99.917',
            },
            zipcode: 73140,
          },
          730214: {
            name: {
              th: 'หนองกระทุ่ม',
              en: 'Nong Krathum',
            },
            coordinates: {
              lat: '14.004',
              lng: '99.865',
            },
            zipcode: 73140,
          },
          730215: {
            name: {
              th: 'วังน้ำเขียว',
              en: 'Wang Nam Khiao',
            },
            coordinates: {
              lat: '13.990',
              lng: '100.027',
            },
            zipcode: 73140,
          },
        },
      },
      7303: {
        name: {
          th: 'นครชัยศรี',
          en: 'Nakhon Chai Si',
        },
        tambons: {
          730301: {
            name: {
              th: 'นครชัยศรี',
              en: 'Nakhon Chai Si',
            },
            coordinates: {
              lat: '13.787',
              lng: '100.192',
            },
            zipcode: 73120,
          },
          730302: {
            name: {
              th: 'บางกระเบา',
              en: 'Bang Krabao',
            },
            coordinates: {
              lat: '13.793',
              lng: '100.183',
            },
            zipcode: 73120,
          },
          730303: {
            name: {
              th: 'วัดแค',
              en: 'Wat Khae',
            },
            coordinates: {
              lat: '13.814',
              lng: '100.175',
            },
            zipcode: 73120,
          },
          730304: {
            name: {
              th: 'ท่าตำหนัก',
              en: 'Tha Tamnak',
            },
            coordinates: {
              lat: '13.766',
              lng: '100.180',
            },
            zipcode: 73120,
          },
          730305: {
            name: {
              th: 'บางแก้ว',
              en: 'Bang Kaeo',
            },
            coordinates: {
              lat: '13.775',
              lng: '100.168',
            },
            zipcode: 73120,
          },
          730306: {
            name: {
              th: 'ท่ากระชับ',
              en: 'Tha Krachap',
            },
            coordinates: {
              lat: '13.760',
              lng: '100.155',
            },
            zipcode: 73120,
          },
          730307: {
            name: {
              th: 'ขุนแก้ว',
              en: 'Khun Kaeo',
            },
            coordinates: {
              lat: '13.774',
              lng: '100.209',
            },
            zipcode: 73120,
          },
          730308: {
            name: {
              th: 'ท่าพระยา',
              en: 'Tha Phraya',
            },
            coordinates: {
              lat: '13.785',
              lng: '100.144',
            },
            zipcode: 73120,
          },
          730309: {
            name: {
              th: 'พะเนียด',
              en: 'Phaniat',
            },
            coordinates: {
              lat: '13.795',
              lng: '100.123',
            },
            zipcode: 73120,
          },
          730310: {
            name: {
              th: 'บางระกำ',
              en: 'Bang Rakam',
            },
            coordinates: {
              lat: '13.756',
              lng: '100.134',
            },
            zipcode: 73130,
          },
          730311: {
            name: {
              th: 'โคกพระเจดีย์',
              en: 'Khok Phra Chedi',
            },
            coordinates: {
              lat: '13.726',
              lng: '100.126',
            },
            zipcode: 73120,
          },
          730312: {
            name: {
              th: 'ศีรษะทอง',
              en: 'Sisa Thong',
            },
            coordinates: {
              lat: '13.817',
              lng: '100.143',
            },
            zipcode: 73120,
          },
          730313: {
            name: {
              th: 'แหลมบัว',
              en: 'Laem Bua',
            },
            coordinates: {
              lat: '13.857',
              lng: '100.147',
            },
            zipcode: 73120,
          },
          730314: {
            name: {
              th: 'ศรีมหาโพธิ์',
              en: 'Si Mahapho',
            },
            coordinates: {
              lat: '13.889',
              lng: '100.155',
            },
            zipcode: 73120,
          },
          730315: {
            name: {
              th: 'สัมปทวน',
              en: 'Sampathuan',
            },
            coordinates: {
              lat: '13.830',
              lng: '100.195',
            },
            zipcode: 73120,
          },
          730316: {
            name: {
              th: 'วัดสำโรง',
              en: 'Wat Samrong',
            },
            coordinates: {
              lat: '13.827',
              lng: '100.218',
            },
            zipcode: 73120,
          },
          730317: {
            name: {
              th: 'ดอนแฝก',
              en: 'Don Faek',
            },
            coordinates: {
              lat: '13.859',
              lng: '100.244',
            },
            zipcode: 73120,
          },
          730318: {
            name: {
              th: 'ห้วยพลู',
              en: 'Huai Phlu',
            },
            coordinates: {
              lat: '13.878',
              lng: '100.233',
            },
            zipcode: 73120,
          },
          730319: {
            name: {
              th: 'วัดละมุด',
              en: 'Wat Lamut',
            },
            coordinates: {
              lat: '13.886',
              lng: '100.182',
            },
            zipcode: 73120,
          },
          730320: {
            name: {
              th: 'บางพระ',
              en: 'Bang Phra',
            },
            coordinates: {
              lat: '13.891',
              lng: '100.206',
            },
            zipcode: 73120,
          },
          730321: {
            name: {
              th: 'บางแก้วฟ้า',
              en: 'Bang Kaeo Fa',
            },
            coordinates: {
              lat: '13.896',
              lng: '100.247',
            },
            zipcode: 73120,
          },
          730322: {
            name: {
              th: 'ลานตากฟ้า',
              en: 'Lan Tak Fa',
            },
            coordinates: {
              lat: '13.828',
              lng: '100.233',
            },
            zipcode: 73120,
          },
          730323: {
            name: {
              th: 'งิ้วราย',
              en: 'Ngio Rai',
            },
            coordinates: {
              lat: '13.807',
              lng: '100.212',
            },
            zipcode: 73120,
          },
          730324: {
            name: {
              th: 'ไทยาวาส',
              en: 'Thaiyawat',
            },
            coordinates: {
              lat: '13.793',
              lng: '100.215',
            },
            zipcode: 73120,
          },
        },
      },
      7304: {
        name: {
          th: 'ดอนตูม',
          en: 'Don Tum',
        },
        tambons: {
          730401: {
            name: {
              th: 'สามง่าม',
              en: 'Sam Ngam',
            },
            coordinates: {
              lat: '13.978',
              lng: '100.083',
            },
            zipcode: 73150,
          },
          730402: {
            name: {
              th: 'ห้วยพระ',
              en: 'Huai Phra',
            },
            coordinates: {
              lat: '13.930',
              lng: '100.084',
            },
            zipcode: 73150,
          },
          730403: {
            name: {
              th: 'ลำเหย',
              en: 'Lam Hoei',
            },
            coordinates: {
              lat: '13.957',
              lng: '100.044',
            },
            zipcode: 73150,
          },
          730404: {
            name: {
              th: 'ดอนพุทรา',
              en: 'Don Phutsa',
            },
            coordinates: {
              lat: '13.936',
              lng: '100.124',
            },
            zipcode: 73150,
          },
          730405: {
            name: {
              th: 'บ้านหลวง',
              en: 'Ban Luang',
            },
            coordinates: {
              lat: '13.911',
              lng: '100.150',
            },
            zipcode: 73150,
          },
          730406: {
            name: {
              th: 'ดอนรวก',
              en: 'Don Ruak',
            },
            coordinates: {
              lat: '13.890',
              lng: '100.117',
            },
            zipcode: 73150,
          },
          730407: {
            name: {
              th: 'ห้วยด้วน',
              en: 'Huai Duan',
            },
            coordinates: {
              lat: '13.902',
              lng: '100.100',
            },
            zipcode: 73150,
          },
          730408: {
            name: {
              th: 'ลำลูกบัว',
              en: 'Lam Luk Bua',
            },
            coordinates: {
              lat: '14.024',
              lng: '100.074',
            },
            zipcode: 73150,
          },
        },
      },
      7305: {
        name: {
          th: 'บางเลน',
          en: 'Bang Len',
        },
        tambons: {
          730501: {
            name: {
              th: 'บางเลน',
              en: 'Bang Len',
            },
            coordinates: {
              lat: '14.014',
              lng: '100.151',
            },
            zipcode: 73130,
          },
          730502: {
            name: {
              th: 'บางปลา',
              en: 'Bang Pla',
            },
            coordinates: {
              lat: '13.970',
              lng: '100.158',
            },
            zipcode: 73130,
          },
          730503: {
            name: {
              th: 'บางหลวง',
              en: 'Bang Luang',
            },
            coordinates: {
              lat: '14.108',
              lng: '100.093',
            },
            zipcode: 73190,
          },
          730504: {
            name: {
              th: 'บางภาษี',
              en: 'Bang Phasi',
            },
            coordinates: {
              lat: '14.038',
              lng: '100.243',
            },
            zipcode: 73130,
          },
          730505: {
            name: {
              th: 'บางระกำ',
              en: 'Bang Rakam',
            },
            coordinates: {
              lat: '13.914',
              lng: '100.254',
            },
            zipcode: null,
          },
          730506: {
            name: {
              th: 'บางไทรป่า',
              en: 'Bang Sai Pa',
            },
            coordinates: {
              lat: '14.047',
              lng: '100.150',
            },
            zipcode: 73130,
          },
          730507: {
            name: {
              th: 'หินมูล',
              en: 'Hin Mun',
            },
            coordinates: {
              lat: '14.104',
              lng: '100.147',
            },
            zipcode: 73190,
          },
          730508: {
            name: {
              th: 'ไทรงาม',
              en: 'Sai Ngam',
            },
            coordinates: {
              lat: '14.093',
              lng: '100.177',
            },
            zipcode: 73130,
          },
          730509: {
            name: {
              th: 'ดอนตูม',
              en: 'Don Tum',
            },
            coordinates: {
              lat: '13.999',
              lng: '100.123',
            },
            zipcode: 73130,
          },
          730510: {
            name: {
              th: 'นิลเพชร',
              en: 'Nin Phet',
            },
            coordinates: {
              lat: '14.121',
              lng: '100.235',
            },
            zipcode: 73130,
          },
          730511: {
            name: {
              th: 'บัวปากท่า',
              en: 'Bua Pak Tha',
            },
            coordinates: {
              lat: '14.159',
              lng: '100.228',
            },
            zipcode: 73130,
          },
          730512: {
            name: {
              th: 'คลองนกกระทุง',
              en: 'Khlong Nok Krathung',
            },
            coordinates: {
              lat: '13.991',
              lng: '100.222',
            },
            zipcode: 73130,
          },
          730513: {
            name: {
              th: 'นราภิรมย์',
              en: 'Nara Phirom',
            },
            coordinates: {
              lat: '13.953',
              lng: '100.262',
            },
            zipcode: 73130,
          },
          730514: {
            name: {
              th: 'ลำพญา',
              en: 'Lam Phaya',
            },
            coordinates: {
              lat: '13.954',
              lng: '100.199',
            },
            zipcode: 73130,
          },
          730515: {
            name: {
              th: 'ไผ่หูช้าง',
              en: 'Phai Hu Chang',
            },
            coordinates: {
              lat: '14.064',
              lng: '100.116',
            },
            zipcode: 73130,
          },
        },
      },
      7306: {
        name: {
          th: 'สามพราน',
          en: 'Sam Phran',
        },
        tambons: {
          730601: {
            name: {
              th: 'ท่าข้าม',
              en: 'Tha Kham',
            },
            coordinates: {
              lat: '13.714',
              lng: '100.247',
            },
            zipcode: 73110,
          },
          730602: {
            name: {
              th: 'ทรงคนอง',
              en: 'Song Khanong',
            },
            coordinates: {
              lat: '13.780',
              lng: '100.262',
            },
            zipcode: 73210,
          },
          730603: {
            name: {
              th: 'หอมเกร็ด',
              en: 'Hom Kret',
            },
            coordinates: {
              lat: '13.779',
              lng: '100.240',
            },
            zipcode: 73110,
          },
          730604: {
            name: {
              th: 'บางกระทึก',
              en: 'Bang Krathuek',
            },
            coordinates: {
              lat: '13.772',
              lng: '100.305',
            },
            zipcode: 73210,
          },
          730605: {
            name: {
              th: 'บางเตย',
              en: 'Bang Toei',
            },
            coordinates: {
              lat: '13.787',
              lng: '100.289',
            },
            zipcode: 73210,
          },
          730606: {
            name: {
              th: 'สามพราน',
              en: 'Sam Phran',
            },
            coordinates: {
              lat: '13.716',
              lng: '100.219',
            },
            zipcode: 73110,
          },
          730607: {
            name: {
              th: 'บางช้าง',
              en: 'Bang Chang',
            },
            coordinates: {
              lat: '13.702',
              lng: '100.200',
            },
            zipcode: 73110,
          },
          730608: {
            name: {
              th: 'ไร่ขิง',
              en: 'Rai Khing',
            },
            coordinates: {
              lat: '13.737',
              lng: '100.283',
            },
            zipcode: 73210,
          },
          730609: {
            name: {
              th: 'ท่าตลาด',
              en: 'Tha Talat',
            },
            coordinates: {
              lat: '13.751',
              lng: '100.234',
            },
            zipcode: 73110,
          },
          730610: {
            name: {
              th: 'กระทุ่มล้ม',
              en: 'Krathum Lom',
            },
            coordinates: {
              lat: '13.737',
              lng: '100.322',
            },
            zipcode: 73220,
          },
          730611: {
            name: {
              th: 'คลองใหม่',
              en: 'Khlong Mai',
            },
            coordinates: {
              lat: '13.734',
              lng: '100.174',
            },
            zipcode: 73110,
          },
          730612: {
            name: {
              th: 'ตลาดจินดา',
              en: 'Talat Chinda',
            },
            coordinates: {
              lat: '13.698',
              lng: '100.150',
            },
            zipcode: 73110,
          },
          730613: {
            name: {
              th: 'คลองจินดา',
              en: 'Khlong Chinda',
            },
            coordinates: {
              lat: '13.677',
              lng: '100.092',
            },
            zipcode: 73110,
          },
          730614: {
            name: {
              th: 'ยายชา',
              en: 'Yai Cha',
            },
            coordinates: {
              lat: '13.733',
              lng: '100.235',
            },
            zipcode: 73110,
          },
          730615: {
            name: {
              th: 'บ้านใหม่',
              en: 'Ban Mai',
            },
            coordinates: {
              lat: '13.690',
              lng: '100.242',
            },
            zipcode: 73110,
          },
          730616: {
            name: {
              th: 'อ้อมใหญ่',
              en: 'Om Yai',
            },
            coordinates: {
              lat: '13.700',
              lng: '100.268',
            },
            zipcode: 73160,
          },
        },
      },
      7307: {
        name: {
          th: 'พุทธมณฑล',
          en: 'Phutthamonthon',
        },
        tambons: {
          730701: {
            name: {
              th: 'ศาลายา',
              en: 'Sala Ya',
            },
            coordinates: {
              lat: '13.813',
              lng: '100.305',
            },
            zipcode: null,
          },
          730702: {
            name: {
              th: 'คลองโยง',
              en: 'Khlong Yong',
            },
            coordinates: {
              lat: '13.862',
              lng: '100.281',
            },
            zipcode: null,
          },
          730703: {
            name: {
              th: 'มหาสวัสดิ์',
              en: 'Mahasawat',
            },
            coordinates: {
              lat: '13.815',
              lng: '100.262',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  74: {
    name: {
      th: 'สมุทรสาคร',
      en: 'Samut Sakhon',
    },
    amphoes: {
      7401: {
        name: {
          th: 'เมืองสมุทรสาคร',
          en: 'Mueang Samut Sakhon',
        },
        tambons: {
          740101: {
            name: {
              th: 'มหาชัย',
              en: 'Mahachai',
            },
            coordinates: {
              lat: '13.552',
              lng: '100.280',
            },
            zipcode: 74000,
          },
          740102: {
            name: {
              th: 'ท่าฉลอม',
              en: 'Tha Chalom',
            },
            coordinates: {
              lat: '13.538',
              lng: '100.269',
            },
            zipcode: 74000,
          },
          740103: {
            name: {
              th: 'โกรกกราก',
              en: 'Krok Krak',
            },
            coordinates: {
              lat: '13.533',
              lng: '100.276',
            },
            zipcode: 74000,
          },
          740104: {
            name: {
              th: 'บ้านบ่อ',
              en: 'Ban Bo',
            },
            coordinates: {
              lat: '13.487',
              lng: '100.192',
            },
            zipcode: 74000,
          },
          740105: {
            name: {
              th: 'บางโทรัด',
              en: 'Bang Thorat',
            },
            coordinates: {
              lat: '13.492',
              lng: '100.158',
            },
            zipcode: 74000,
          },
          740106: {
            name: {
              th: 'กาหลง',
              en: 'Kalong',
            },
            coordinates: {
              lat: '13.483',
              lng: '100.130',
            },
            zipcode: 74000,
          },
          740107: {
            name: {
              th: 'นาโคก',
              en: 'Na Khok',
            },
            coordinates: {
              lat: '13.464',
              lng: '100.096',
            },
            zipcode: 74000,
          },
          740108: {
            name: {
              th: 'ท่าจีน',
              en: 'Tha Chin',
            },
            coordinates: {
              lat: '13.539',
              lng: '100.252',
            },
            zipcode: 74000,
          },
          740109: {
            name: {
              th: 'นาดี',
              en: 'Na Di',
            },
            coordinates: {
              lat: '13.595',
              lng: '100.295',
            },
            zipcode: 74000,
          },
          740110: {
            name: {
              th: 'ท่าทราย',
              en: 'Tha Sai',
            },
            coordinates: {
              lat: '13.571',
              lng: '100.266',
            },
            zipcode: 74000,
          },
          740111: {
            name: {
              th: 'คอกกระบือ',
              en: 'Khok Krabue',
            },
            coordinates: {
              lat: '13.599',
              lng: '100.333',
            },
            zipcode: 74000,
          },
          740112: {
            name: {
              th: 'บางน้ำจืด',
              en: 'Bang Nam Chuet',
            },
            coordinates: {
              lat: '13.614',
              lng: '100.363',
            },
            zipcode: 74000,
          },
          740113: {
            name: {
              th: 'พันท้ายนรสิงห์',
              en: 'Phanthai Norasing',
            },
            coordinates: {
              lat: '13.539',
              lng: '100.377',
            },
            zipcode: 74000,
          },
          740114: {
            name: {
              th: 'โคกขาม',
              en: 'Khok Kham',
            },
            coordinates: {
              lat: '13.531',
              lng: '100.326',
            },
            zipcode: 74000,
          },
          740115: {
            name: {
              th: 'บ้านเกาะ',
              en: 'Ban Ko',
            },
            coordinates: {
              lat: '13.591',
              lng: '100.247',
            },
            zipcode: 74000,
          },
          740116: {
            name: {
              th: 'บางกระเจ้า',
              en: 'Bang Krachao',
            },
            coordinates: {
              lat: '13.512',
              lng: '100.215',
            },
            zipcode: 74000,
          },
          740117: {
            name: {
              th: 'บางหญ้าแพรก',
              en: 'Bang Ya Phraek',
            },
            coordinates: {
              lat: '13.501',
              lng: '100.250',
            },
            zipcode: 74000,
          },
          740118: {
            name: {
              th: 'ชัยมงคล',
              en: 'Chai Mongkhon',
            },
            coordinates: {
              lat: '13.540',
              lng: '100.185',
            },
            zipcode: 74000,
          },
        },
      },
      7402: {
        name: {
          th: 'กระทุ่มแบน',
          en: 'Krathum Baen',
        },
        tambons: {
          740201: {
            name: {
              th: 'ตลาดกระทุ่มแบน',
              en: 'Talat Krathum Baen',
            },
            coordinates: {
              lat: '13.654',
              lng: '100.250',
            },
            zipcode: 74110,
          },
          740202: {
            name: {
              th: 'อ้อมน้อย',
              en: 'Om Noi',
            },
            coordinates: {
              lat: '13.702',
              lng: '100.308',
            },
            zipcode: 74130,
          },
          740203: {
            name: {
              th: 'ท่าไม้',
              en: 'Tha Mai',
            },
            coordinates: {
              lat: '13.671',
              lng: '100.261',
            },
            zipcode: 74110,
          },
          740204: {
            name: {
              th: 'สวนหลวง',
              en: 'Suan Luang',
            },
            coordinates: {
              lat: '13.671',
              lng: '100.323',
            },
            zipcode: 74110,
          },
          740205: {
            name: {
              th: 'บางยาง',
              en: 'Bang Yang',
            },
            coordinates: {
              lat: '13.652',
              lng: '100.226',
            },
            zipcode: 74110,
          },
          740206: {
            name: {
              th: 'คลองมะเดื่อ',
              en: 'Khlong Maduea',
            },
            coordinates: {
              lat: '13.637',
              lng: '100.292',
            },
            zipcode: 74110,
          },
          740207: {
            name: {
              th: 'หนองนกไข่',
              en: 'Nong Nok Khai',
            },
            coordinates: {
              lat: '13.668',
              lng: '100.192',
            },
            zipcode: 74110,
          },
          740208: {
            name: {
              th: 'ดอนไก่ดี',
              en: 'Don Kai Di',
            },
            coordinates: {
              lat: '13.633',
              lng: '100.266',
            },
            zipcode: 74110,
          },
          740209: {
            name: {
              th: 'แคราย',
              en: 'Khae Rai',
            },
            coordinates: {
              lat: '13.643',
              lng: '100.324',
            },
            zipcode: 74110,
          },
          740210: {
            name: {
              th: 'ท่าเสา',
              en: 'Tha Sao',
            },
            coordinates: {
              lat: '13.623',
              lng: '100.240',
            },
            zipcode: 74110,
          },
        },
      },
      7403: {
        name: {
          th: 'บ้านแพ้ว',
          en: 'Ban Phaeo',
        },
        tambons: {
          740301: {
            name: {
              th: 'บ้านแพ้ว',
              en: 'Ban Phaeo',
            },
            coordinates: {
              lat: '13.618',
              lng: '100.104',
            },
            zipcode: 74120,
          },
          740302: {
            name: {
              th: 'หลักสาม',
              en: 'Lak Sam',
            },
            coordinates: {
              lat: '13.571',
              lng: '100.139',
            },
            zipcode: 74120,
          },
          740303: {
            name: {
              th: 'ยกกระบัตร',
              en: 'Yok Krabat',
            },
            coordinates: {
              lat: '13.559',
              lng: '100.082',
            },
            zipcode: 74120,
          },
          740304: {
            name: {
              th: 'โรงเข้',
              en: 'Rong Khe',
            },
            coordinates: {
              lat: '13.521',
              lng: '100.069',
            },
            zipcode: 74120,
          },
          740305: {
            name: {
              th: 'หนองสองห้อง',
              en: 'Nong Song Hong',
            },
            coordinates: {
              lat: '13.581',
              lng: '100.065',
            },
            zipcode: 74120,
          },
          740306: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '13.598',
              lng: '100.075',
            },
            zipcode: 74120,
          },
          740307: {
            name: {
              th: 'หลักสอง',
              en: 'Lak Song',
            },
            coordinates: {
              lat: '13.614',
              lng: '100.134',
            },
            zipcode: 74120,
          },
          740308: {
            name: {
              th: 'เจ็ดริ้ว',
              en: 'Chet Rio',
            },
            coordinates: {
              lat: '13.639',
              lng: '100.141',
            },
            zipcode: 74120,
          },
          740309: {
            name: {
              th: 'คลองตัน',
              en: 'Khlong Tan',
            },
            coordinates: {
              lat: '13.641',
              lng: '100.171',
            },
            zipcode: 74120,
          },
          740310: {
            name: {
              th: 'อำแพง',
              en: 'Amphaeng',
            },
            coordinates: {
              lat: '13.600',
              lng: '100.189',
            },
            zipcode: 74120,
          },
          740311: {
            name: {
              th: 'สวนส้ม',
              en: 'Suan Som',
            },
            coordinates: {
              lat: '13.624',
              lng: '100.186',
            },
            zipcode: 74120,
          },
          740312: {
            name: {
              th: 'เกษตรพัฒนา',
              en: 'Kaset Phatthana',
            },
            coordinates: {
              lat: '13.666',
              lng: '100.153',
            },
            zipcode: 74120,
          },
        },
      },
    },
  },
  75: {
    name: {
      th: 'สมุทรสงคราม',
      en: 'Samut Songkhram',
    },
    amphoes: {
      7501: {
        name: {
          th: 'เมืองสมุทรสงคราม',
          en: 'Mueang Samut Songkhram',
        },
        tambons: {
          750101: {
            name: {
              th: 'แม่กลอง',
              en: 'Mae Klong',
            },
            coordinates: {
              lat: '13.400',
              lng: '99.994',
            },
            zipcode: 75000,
          },
          750102: {
            name: {
              th: 'บางขันแตก',
              en: 'Bang Khan Taek',
            },
            coordinates: {
              lat: '13.384',
              lng: '99.955',
            },
            zipcode: 75000,
          },
          750103: {
            name: {
              th: 'ลาดใหญ่',
              en: 'Lat Yai',
            },
            coordinates: {
              lat: '13.451',
              lng: '100.037',
            },
            zipcode: 75000,
          },
          750104: {
            name: {
              th: 'บ้านปรก',
              en: 'Ban Prok',
            },
            coordinates: {
              lat: '13.430',
              lng: '99.992',
            },
            zipcode: 75000,
          },
          750105: {
            name: {
              th: 'บางแก้ว',
              en: 'Bang Kaeo',
            },
            coordinates: {
              lat: '13.431',
              lng: '100.060',
            },
            zipcode: 75000,
          },
          750106: {
            name: {
              th: 'ท้ายหาด',
              en: 'Thai Hat',
            },
            coordinates: {
              lat: '13.399',
              lng: '99.976',
            },
            zipcode: 75000,
          },
          750107: {
            name: {
              th: 'แหลมใหญ่',
              en: 'Laem Yai',
            },
            coordinates: {
              lat: '13.354',
              lng: '99.990',
            },
            zipcode: 75000,
          },
          750108: {
            name: {
              th: 'คลองเขิน',
              en: 'Khlong Khoen',
            },
            coordinates: {
              lat: '13.445',
              lng: '99.988',
            },
            zipcode: 75000,
          },
          750109: {
            name: {
              th: 'คลองโคน',
              en: 'Khlong Khon',
            },
            coordinates: {
              lat: '13.329',
              lng: '99.950',
            },
            zipcode: 75000,
          },
          750110: {
            name: {
              th: 'นางตะเคียน',
              en: 'Nang Takhian',
            },
            coordinates: {
              lat: '13.480',
              lng: '100.027',
            },
            zipcode: 75000,
          },
          750111: {
            name: {
              th: 'บางจะเกร็ง',
              en: 'Bang Chakreng',
            },
            coordinates: {
              lat: '13.376',
              lng: '100.010',
            },
            zipcode: 75000,
          },
        },
      },
      7502: {
        name: {
          th: 'บางคนที',
          en: 'Bang Khonthi',
        },
        tambons: {
          750201: {
            name: {
              th: 'กระดังงา',
              en: 'Kradang Nga',
            },
            coordinates: {
              lat: '13.472',
              lng: '99.951',
            },
            zipcode: 75120,
          },
          750202: {
            name: {
              th: 'บางสะแก',
              en: 'Bang Sakae',
            },
            coordinates: {
              lat: '13.450',
              lng: '99.928',
            },
            zipcode: 75120,
          },
          750203: {
            name: {
              th: 'บางยี่รงค์',
              en: 'Bang Yi Rong',
            },
            coordinates: {
              lat: '13.481',
              lng: '99.912',
            },
            zipcode: 75120,
          },
          750204: {
            name: {
              th: 'โรงหีบ',
              en: 'Rong Hip',
            },
            coordinates: {
              lat: '13.470',
              lng: '99.931',
            },
            zipcode: 75120,
          },
          750205: {
            name: {
              th: 'บางคนที',
              en: 'Bang Khonthi',
            },
            coordinates: {
              lat: '13.493',
              lng: '99.945',
            },
            zipcode: 75120,
          },
          750206: {
            name: {
              th: 'ดอนมะโนรา',
              en: 'Don Manora',
            },
            coordinates: {
              lat: '13.499',
              lng: '100.018',
            },
            zipcode: 75120,
          },
          750207: {
            name: {
              th: 'บางพรม',
              en: 'Bang Phrom',
            },
            coordinates: {
              lat: '13.452',
              lng: '99.955',
            },
            zipcode: 75120,
          },
          750208: {
            name: {
              th: 'บางกุ้ง',
              en: 'Bang Kung',
            },
            coordinates: {
              lat: '13.459',
              lng: '99.931',
            },
            zipcode: 75120,
          },
          750209: {
            name: {
              th: 'จอมปลวก',
              en: 'Chom Pluak',
            },
            coordinates: {
              lat: '13.483',
              lng: '99.976',
            },
            zipcode: 75120,
          },
          750210: {
            name: {
              th: 'บางนกแขวก',
              en: 'Bang Nok Khwaek',
            },
            coordinates: {
              lat: '13.504',
              lng: '99.923',
            },
            zipcode: 75120,
          },
          750211: {
            name: {
              th: 'ยายแพง',
              en: 'Yai Phaeng',
            },
            coordinates: {
              lat: '13.487',
              lng: '99.958',
            },
            zipcode: 75120,
          },
          750212: {
            name: {
              th: 'บางกระบือ',
              en: 'Bang Krabue',
            },
            coordinates: {
              lat: '13.463',
              lng: '99.968',
            },
            zipcode: 75120,
          },
          750213: {
            name: {
              th: 'บ้านปราโมทย์',
              en: 'Ban Pramot',
            },
            coordinates: {
              lat: '13.460',
              lng: '99.898',
            },
            zipcode: 75120,
          },
        },
      },
      7503: {
        name: {
          th: 'อัมพวา',
          en: 'Amphawa',
        },
        tambons: {
          750301: {
            name: {
              th: 'อัมพวา',
              en: 'Amphawa',
            },
            coordinates: {
              lat: '13.427',
              lng: '99.954',
            },
            zipcode: 75110,
          },
          750302: {
            name: {
              th: 'สวนหลวง',
              en: 'Suan Luang',
            },
            coordinates: {
              lat: '13.414',
              lng: '99.953',
            },
            zipcode: 75110,
          },
          750303: {
            name: {
              th: 'ท่าคา',
              en: 'Tha Kha',
            },
            coordinates: {
              lat: '13.466',
              lng: '99.993',
            },
            zipcode: 75110,
          },
          750304: {
            name: {
              th: 'วัดประดู่',
              en: 'Wat Pradu',
            },
            coordinates: {
              lat: '13.380',
              lng: '99.875',
            },
            zipcode: 75110,
          },
          750305: {
            name: {
              th: 'เหมืองใหม่',
              en: 'Mueang Mai',
            },
            coordinates: {
              lat: '13.433',
              lng: '99.910',
            },
            zipcode: 75110,
          },
          750306: {
            name: {
              th: 'บางช้าง',
              en: 'Bang Chang',
            },
            coordinates: {
              lat: '13.437',
              lng: '99.953',
            },
            zipcode: 75110,
          },
          750307: {
            name: {
              th: 'แควอ้อม',
              en: 'Khwae Om',
            },
            coordinates: {
              lat: '13.436',
              lng: '99.937',
            },
            zipcode: 75110,
          },
          750308: {
            name: {
              th: 'ปลายโพงพาง',
              en: 'Plai Phongphang',
            },
            coordinates: {
              lat: '13.385',
              lng: '99.923',
            },
            zipcode: 75110,
          },
          750309: {
            name: {
              th: 'บางแค',
              en: 'Bang Khae',
            },
            coordinates: {
              lat: '13.407',
              lng: '99.923',
            },
            zipcode: 75110,
          },
          750310: {
            name: {
              th: 'แพรกหนามแดง',
              en: 'Phraek Nam Daeng',
            },
            coordinates: {
              lat: '13.343',
              lng: '99.882',
            },
            zipcode: 75110,
          },
          750311: {
            name: {
              th: 'ยี่สาร',
              en: 'Yi San',
            },
            coordinates: {
              lat: '13.297',
              lng: '99.895',
            },
            zipcode: 75110,
          },
          750312: {
            name: {
              th: 'บางนางลี่',
              en: 'Bang Nang Li',
            },
            coordinates: {
              lat: '13.414',
              lng: '99.917',
            },
            zipcode: 75110,
          },
        },
      },
    },
  },
  76: {
    name: {
      th: 'เพชรบุรี',
      en: 'Phetchaburi',
    },
    amphoes: {
      7601: {
        name: {
          th: 'เมืองเพชรบุรี',
          en: 'Mueang Phetchaburi',
        },
        tambons: {
          760101: {
            name: {
              th: 'ท่าราบ',
              en: 'Tha Rap',
            },
            coordinates: {
              lat: '13.097',
              lng: '99.949',
            },
            zipcode: 76000,
          },
          760102: {
            name: {
              th: 'คลองกระแซง',
              en: 'Khlong Krachaeng',
            },
            coordinates: {
              lat: '13.112',
              lng: '99.943',
            },
            zipcode: 76000,
          },
          760103: {
            name: {
              th: 'บางจาน',
              en: 'Bang Chan',
            },
            coordinates: {
              lat: '13.130',
              lng: '100.015',
            },
            zipcode: 76000,
          },
          760104: {
            name: {
              th: 'นาพันสาม',
              en: 'Na Phan Sam',
            },
            coordinates: {
              lat: '13.054',
              lng: '100.030',
            },
            zipcode: 76000,
          },
          760105: {
            name: {
              th: 'ธงชัย',
              en: 'Thong Chai',
            },
            coordinates: {
              lat: '13.151',
              lng: '99.918',
            },
            zipcode: 76000,
          },
          760106: {
            name: {
              th: 'บ้านกุ่ม',
              en: 'Ban Kum',
            },
            coordinates: {
              lat: '13.156',
              lng: '99.941',
            },
            zipcode: 76000,
          },
          760107: {
            name: {
              th: 'หนองโสน',
              en: 'Nong Sano',
            },
            coordinates: {
              lat: '13.134',
              lng: '99.973',
            },
            zipcode: 76000,
          },
          760108: {
            name: {
              th: 'ไร่ส้ม',
              en: 'Rai Som',
            },
            coordinates: {
              lat: '13.112',
              lng: '99.916',
            },
            zipcode: 76000,
          },
          760109: {
            name: {
              th: 'เวียงคอย',
              en: 'Wiang Khoi',
            },
            coordinates: {
              lat: '13.121',
              lng: '99.900',
            },
            zipcode: 76000,
          },
          760110: {
            name: {
              th: 'บางจาก',
              en: 'Bang Chak',
            },
            coordinates: {
              lat: '13.158',
              lng: '99.891',
            },
            zipcode: 76000,
          },
          760111: {
            name: {
              th: 'บ้านหม้อ',
              en: 'Ban Mo',
            },
            coordinates: {
              lat: '13.092',
              lng: '99.932',
            },
            zipcode: 76000,
          },
          760112: {
            name: {
              th: 'ต้นมะม่วง',
              en: 'Ton Mamuang',
            },
            coordinates: {
              lat: '13.079',
              lng: '99.958',
            },
            zipcode: 76000,
          },
          760113: {
            name: {
              th: 'ช่องสะแก',
              en: 'Chong Sakae',
            },
            coordinates: {
              lat: '13.105',
              lng: '99.997',
            },
            zipcode: 76000,
          },
          760114: {
            name: {
              th: 'นาวุ้ง',
              en: 'Na Wung',
            },
            coordinates: {
              lat: '13.088',
              lng: '99.977',
            },
            zipcode: 76000,
          },
          760115: {
            name: {
              th: 'สำมะโรง',
              en: 'Sam Marong',
            },
            coordinates: {
              lat: '13.070',
              lng: '99.992',
            },
            zipcode: 76000,
          },
          760116: {
            name: {
              th: 'โพพระ',
              en: 'Pho Phra',
            },
            coordinates: {
              lat: '13.082',
              lng: '100.039',
            },
            zipcode: 76000,
          },
          760117: {
            name: {
              th: 'หาดเจ้าสำราญ',
              en: 'Hat Chao Samran',
            },
            coordinates: {
              lat: '13.007',
              lng: '100.044',
            },
            zipcode: 76100,
          },
          760118: {
            name: {
              th: 'หัวสะพาน',
              en: 'Hua Saphan',
            },
            coordinates: {
              lat: '13.117',
              lng: '99.866',
            },
            zipcode: 76000,
          },
          760119: {
            name: {
              th: 'ต้นมะพร้าว',
              en: 'Ton Maphrao',
            },
            coordinates: {
              lat: '13.146',
              lng: '99.860',
            },
            zipcode: 76000,
          },
          760120: {
            name: {
              th: 'วังตะโก',
              en: 'Wang Tako',
            },
            coordinates: {
              lat: '13.134',
              lng: '99.893',
            },
            zipcode: 76000,
          },
          760121: {
            name: {
              th: 'โพไร่หวาน',
              en: 'Pho Rai Wan',
            },
            coordinates: {
              lat: '13.053',
              lng: '99.974',
            },
            zipcode: 76000,
          },
          760122: {
            name: {
              th: 'ดอนยาง',
              en: 'Don Yang',
            },
            coordinates: {
              lat: '13.015',
              lng: '99.976',
            },
            zipcode: 76000,
          },
          760123: {
            name: {
              th: 'หนองขนาน',
              en: 'Nong Khanan',
            },
            coordinates: {
              lat: '12.995',
              lng: '100.013',
            },
            zipcode: 76000,
          },
          760124: {
            name: {
              th: 'หนองพลับ',
              en: 'Nong Phlap',
            },
            coordinates: {
              lat: '13.048',
              lng: '100.000',
            },
            zipcode: 76000,
          },
        },
      },
      7602: {
        name: {
          th: 'เขาย้อย',
          en: 'Khao Yoi',
        },
        tambons: {
          760201: {
            name: {
              th: 'เขาย้อย',
              en: 'Khao Yoi',
            },
            coordinates: {
              lat: '13.233',
              lng: '99.819',
            },
            zipcode: 76140,
          },
          760202: {
            name: {
              th: 'สระพัง',
              en: 'Sa Phang',
            },
            coordinates: {
              lat: '13.257',
              lng: '99.811',
            },
            zipcode: 76140,
          },
          760203: {
            name: {
              th: 'บางเค็ม',
              en: 'Bang Khem',
            },
            coordinates: {
              lat: '13.284',
              lng: '99.838',
            },
            zipcode: 76140,
          },
          760204: {
            name: {
              th: 'ทับคาง',
              en: 'Thap Khang',
            },
            coordinates: {
              lat: '13.204',
              lng: '99.833',
            },
            zipcode: 76140,
          },
          760205: {
            name: {
              th: 'หนองปลาไหล',
              en: 'Nong Pla Lai',
            },
            coordinates: {
              lat: '13.194',
              lng: '99.877',
            },
            zipcode: 76140,
          },
          760206: {
            name: {
              th: 'หนองปรง',
              en: 'Nong Prong',
            },
            coordinates: {
              lat: '13.168',
              lng: '99.801',
            },
            zipcode: 76140,
          },
          760207: {
            name: {
              th: 'หนองชุมพล',
              en: 'Nong Chumphon',
            },
            coordinates: {
              lat: '13.295',
              lng: '99.797',
            },
            zipcode: 76140,
          },
          760208: {
            name: {
              th: 'ห้วยโรง',
              en: 'Huai Rong',
            },
            coordinates: {
              lat: '13.316',
              lng: '99.841',
            },
            zipcode: 76140,
          },
          760209: {
            name: {
              th: 'ห้วยท่าช้าง',
              en: 'Huai Tha Chang',
            },
            coordinates: {
              lat: '13.136',
              lng: '99.800',
            },
            zipcode: 76140,
          },
          760210: {
            name: {
              th: 'หนองชุมพลเหนือ',
              en: 'Nong Chumphon Nuea',
            },
            coordinates: {
              lat: '13.301',
              lng: '99.724',
            },
            zipcode: 76140,
          },
        },
      },
      7603: {
        name: {
          th: 'หนองหญ้าปล้อง',
          en: 'Nong Ya Plong',
        },
        tambons: {
          760301: {
            name: {
              th: 'หนองหญ้าปล้อง',
              en: 'Nong Ya Plong',
            },
            coordinates: {
              lat: '13.201',
              lng: '99.709',
            },
            zipcode: null,
          },
          760302: {
            name: {
              th: 'ยางน้ำกลัดเหนือ',
              en: 'Yang Nam Klat Nuea',
            },
            coordinates: {
              lat: '13.158',
              lng: '99.390',
            },
            zipcode: 76160,
          },
          760303: {
            name: {
              th: 'ยางน้ำกลัดใต้',
              en: 'Yang Nam Klat Tai',
            },
            coordinates: {
              lat: '13.070',
              lng: '99.505',
            },
            zipcode: 76160,
          },
          760304: {
            name: {
              th: 'ท่าตะคร้อ',
              en: 'Tha Takhro',
            },
            coordinates: {
              lat: '13.099',
              lng: '99.749',
            },
            zipcode: 76160,
          },
        },
      },
      7604: {
        name: {
          th: 'ชะอำ',
          en: 'Cha-am',
        },
        tambons: {
          760401: {
            name: {
              th: 'ชะอำ',
              en: 'Cha-Am',
            },
            coordinates: {
              lat: '12.719',
              lng: '99.939',
            },
            zipcode: 76120,
          },
          760402: {
            name: {
              th: 'บางเก่า',
              en: 'Bang Kao',
            },
            coordinates: {
              lat: '12.856',
              lng: '99.985',
            },
            zipcode: 76120,
          },
          760403: {
            name: {
              th: 'นายาง',
              en: 'Na Yang',
            },
            coordinates: {
              lat: '12.887',
              lng: '99.945',
            },
            zipcode: 76120,
          },
          760404: {
            name: {
              th: 'เขาใหญ่',
              en: 'Khao Yai',
            },
            coordinates: {
              lat: '12.793',
              lng: '99.894',
            },
            zipcode: 76120,
          },
          760405: {
            name: {
              th: 'หนองศาลา',
              en: 'Nong Sala',
            },
            coordinates: {
              lat: '12.891',
              lng: '99.971',
            },
            zipcode: 76120,
          },
          760406: {
            name: {
              th: 'ห้วยทรายเหนือ',
              en: 'Huai Sai Nuea',
            },
            coordinates: {
              lat: '12.715',
              lng: '99.832',
            },
            zipcode: 76120,
          },
          760407: {
            name: {
              th: 'ไร่ใหม่พัฒนา',
              en: 'Rai Mai Phatthana',
            },
            coordinates: {
              lat: '12.655',
              lng: '99.806',
            },
            zipcode: 76120,
          },
          760408: {
            name: {
              th: 'สามพระยา',
              en: 'Sam Phraya',
            },
            coordinates: {
              lat: '12.684',
              lng: '99.882',
            },
            zipcode: 76120,
          },
          760409: {
            name: {
              th: 'ดอนขุนห้วย',
              en: 'Don Khun Huai',
            },
            coordinates: {
              lat: '12.864',
              lng: '99.892',
            },
            zipcode: 76120,
          },
        },
      },
      7605: {
        name: {
          th: 'ท่ายาง',
          en: 'Tha Yang',
        },
        tambons: {
          760501: {
            name: {
              th: 'ท่ายาง',
              en: 'Tha Yang',
            },
            coordinates: {
              lat: '12.954',
              lng: '99.904',
            },
            zipcode: 76130,
          },
          760502: {
            name: {
              th: 'ท่าคอย',
              en: 'Tha Khoi',
            },
            coordinates: {
              lat: '12.922',
              lng: '99.872',
            },
            zipcode: 76130,
          },
          760503: {
            name: {
              th: 'ยางหย่อง',
              en: 'Yang Yong',
            },
            coordinates: {
              lat: '12.990',
              lng: '99.883',
            },
            zipcode: 76130,
          },
          760504: {
            name: {
              th: 'หนองจอก',
              en: 'Nong Chok',
            },
            coordinates: {
              lat: '12.947',
              lng: '99.989',
            },
            zipcode: 76130,
          },
          760505: {
            name: {
              th: 'มาบปลาเค้า',
              en: 'Map Pla Khao',
            },
            coordinates: {
              lat: '12.981',
              lng: '99.929',
            },
            zipcode: 76130,
          },
          760506: {
            name: {
              th: 'ท่าไม้รวก',
              en: 'Tha Mai Ruak',
            },
            coordinates: {
              lat: '12.847',
              lng: '99.814',
            },
            zipcode: 76130,
          },
          760507: {
            name: {
              th: 'วังไคร้',
              en: 'Wang Khrai',
            },
            coordinates: {
              lat: '12.919',
              lng: '99.820',
            },
            zipcode: 76130,
          },
          760511: {
            name: {
              th: 'กลัดหลวง',
              en: 'Klat Luang',
            },
            coordinates: {
              lat: '12.801',
              lng: '99.732',
            },
            zipcode: 76130,
          },
          760512: {
            name: {
              th: 'ปึกเตียน',
              en: 'Puek Tian',
            },
            coordinates: {
              lat: '12.943',
              lng: '100.014',
            },
            zipcode: 76130,
          },
          760513: {
            name: {
              th: 'เขากระปุก',
              en: 'Khao Krapuk',
            },
            coordinates: {
              lat: '12.693',
              lng: '99.728',
            },
            zipcode: 76130,
          },
          760514: {
            name: {
              th: 'ท่าแลง',
              en: 'Tha Laeng',
            },
            coordinates: {
              lat: '12.961',
              lng: '99.832',
            },
            zipcode: 76130,
          },
          760515: {
            name: {
              th: 'บ้านในดง',
              en: 'Ban Nai Dong',
            },
            coordinates: {
              lat: '12.949',
              lng: '99.941',
            },
            zipcode: 76130,
          },
        },
      },
      7606: {
        name: {
          th: 'บ้านลาด',
          en: 'Ban Lat',
        },
        tambons: {
          760601: {
            name: {
              th: 'บ้านลาด',
              en: 'Ban Lat',
            },
            coordinates: {
              lat: '13.063',
              lng: '99.928',
            },
            zipcode: 76150,
          },
          760602: {
            name: {
              th: 'บ้านหาด',
              en: 'Ban Hat',
            },
            coordinates: {
              lat: '13.076',
              lng: '99.918',
            },
            zipcode: 76150,
          },
          760603: {
            name: {
              th: 'บ้านทาน',
              en: 'Ban Than',
            },
            coordinates: {
              lat: '13.081',
              lng: '99.842',
            },
            zipcode: 76150,
          },
          760604: {
            name: {
              th: 'ตำหรุ',
              en: 'Tamru',
            },
            coordinates: {
              lat: '13.027',
              lng: '99.893',
            },
            zipcode: 76150,
          },
          760605: {
            name: {
              th: 'สมอพลือ',
              en: 'Samo Phlue',
            },
            coordinates: {
              lat: '13.060',
              lng: '99.952',
            },
            zipcode: 76150,
          },
          760606: {
            name: {
              th: 'ไร่มะขาม',
              en: 'Rai Makham',
            },
            coordinates: {
              lat: '13.017',
              lng: '99.939',
            },
            zipcode: 76150,
          },
          760607: {
            name: {
              th: 'ท่าเสน',
              en: 'Tha Sen',
            },
            coordinates: {
              lat: '13.046',
              lng: '99.948',
            },
            zipcode: 76150,
          },
          760608: {
            name: {
              th: 'หนองกระเจ็ด',
              en: 'Nong Krachet',
            },
            coordinates: {
              lat: '13.001',
              lng: '99.953',
            },
            zipcode: 76150,
          },
          760609: {
            name: {
              th: 'หนองกะปุ',
              en: 'Nong Kapu',
            },
            coordinates: {
              lat: '13.065',
              lng: '99.786',
            },
            zipcode: 76150,
          },
          760610: {
            name: {
              th: 'ลาดโพธิ์',
              en: 'Lat Pho',
            },
            coordinates: {
              lat: '13.102',
              lng: '99.895',
            },
            zipcode: 76150,
          },
          760612: {
            name: {
              th: 'ไร่โคก',
              en: 'Rai Khok',
            },
            coordinates: {
              lat: '13.112',
              lng: '99.821',
            },
            zipcode: 76150,
          },
          760613: {
            name: {
              th: 'โรงเข้',
              en: 'Rong Khe',
            },
            coordinates: {
              lat: '13.081',
              lng: '99.891',
            },
            zipcode: 76150,
          },
          760614: {
            name: {
              th: 'ไร่สะท้อน',
              en: 'Rai Sathon',
            },
            coordinates: {
              lat: '13.030',
              lng: '99.866',
            },
            zipcode: 76150,
          },
          760615: {
            name: {
              th: 'ห้วยข้อง',
              en: 'Huai Khong',
            },
            coordinates: {
              lat: '13.035',
              lng: '99.829',
            },
            zipcode: 76150,
          },
          760616: {
            name: {
              th: 'ท่าช้าง',
              en: 'Tha Chang',
            },
            coordinates: {
              lat: '13.057',
              lng: '99.898',
            },
            zipcode: 76150,
          },
          760617: {
            name: {
              th: 'ถ้ำรงค์',
              en: 'Tham Rong',
            },
            coordinates: {
              lat: '13.022',
              lng: '99.911',
            },
            zipcode: 76150,
          },
          760618: {
            name: {
              th: 'ห้วยลึก',
              en: 'Huai Luek',
            },
            coordinates: {
              lat: '13.008',
              lng: '99.793',
            },
            zipcode: 76150,
          },
        },
      },
      7607: {
        name: {
          th: 'บ้านแหลม',
          en: 'Ban Laem',
        },
        tambons: {
          760701: {
            name: {
              th: 'บ้านแหลม',
              en: 'Ban Laem',
            },
            coordinates: {
              lat: '13.206',
              lng: '99.980',
            },
            zipcode: 76110,
          },
          760702: {
            name: {
              th: 'บางขุนไทร',
              en: 'Bang Khun Sai',
            },
            coordinates: {
              lat: '13.161',
              lng: '100.018',
            },
            zipcode: 76110,
          },
          760703: {
            name: {
              th: 'ปากทะเล',
              en: 'Pak Thale',
            },
            coordinates: {
              lat: '13.144',
              lng: '100.040',
            },
            zipcode: 76110,
          },
          760704: {
            name: {
              th: 'บางแก้ว',
              en: 'Bang Kaeo',
            },
            coordinates: {
              lat: '13.111',
              lng: '100.045',
            },
            zipcode: 76110,
          },
          760705: {
            name: {
              th: 'แหลมผักเบี้ย',
              en: 'Laem Phak Bia',
            },
            coordinates: {
              lat: '13.044',
              lng: '100.076',
            },
            zipcode: 76100,
          },
          760706: {
            name: {
              th: 'บางตะบูน',
              en: 'Bang Tabun',
            },
            coordinates: {
              lat: '13.259',
              lng: '99.912',
            },
            zipcode: 76110,
          },
          760707: {
            name: {
              th: 'บางตะบูนออก',
              en: 'Bang Tabun Ok',
            },
            coordinates: {
              lat: '13.247',
              lng: '99.950',
            },
            zipcode: 76110,
          },
          760708: {
            name: {
              th: 'บางครก',
              en: 'Bang Khrok',
            },
            coordinates: {
              lat: '13.204',
              lng: '99.933',
            },
            zipcode: 76110,
          },
          760709: {
            name: {
              th: 'ท่าแร้ง',
              en: 'Tha Raeng',
            },
            coordinates: {
              lat: '13.159',
              lng: '99.960',
            },
            zipcode: 76110,
          },
          760710: {
            name: {
              th: 'ท่าแร้งออก',
              en: 'Tha Raeng Ok',
            },
            coordinates: {
              lat: '13.155',
              lng: '99.976',
            },
            zipcode: 76110,
          },
        },
      },
      7608: {
        name: {
          th: 'แก่งกระจาน',
          en: 'Kaeng Krachan',
        },
        tambons: {
          760801: {
            name: {
              th: 'แก่งกระจาน',
              en: 'Kaeng Krachan',
            },
            coordinates: {
              lat: '12.979',
              lng: '99.609',
            },
            zipcode: null,
          },
          760802: {
            name: {
              th: 'สองพี่น้อง',
              en: 'Song Phi Nong',
            },
            coordinates: {
              lat: '12.853',
              lng: '99.614',
            },
            zipcode: null,
          },
          760803: {
            name: {
              th: 'วังจันทร์',
              en: 'Wang Chan',
            },
            coordinates: {
              lat: '12.942',
              lng: '99.735',
            },
            zipcode: null,
          },
          760804: {
            name: {
              th: 'ป่าเด็ง',
              en: 'Pa Deng',
            },
            coordinates: {
              lat: '12.651',
              lng: '99.480',
            },
            zipcode: 76170,
          },
          760805: {
            name: {
              th: 'พุสวรรค์',
              en: 'Phu Sawan',
            },
            coordinates: {
              lat: '12.997',
              lng: '99.743',
            },
            zipcode: 76170,
          },
          760806: {
            name: {
              th: 'ห้วยแม่เพรียง',
              en: 'Huai Mae Phriang',
            },
            coordinates: {
              lat: '12.913',
              lng: '99.284',
            },
            zipcode: 76170,
          },
        },
      },
    },
  },
  77: {
    name: {
      th: 'ประจวบคีรีขันธ์',
      en: 'Prachuap Khiri Khan',
    },
    amphoes: {
      7701: {
        name: {
          th: 'เมืองประจวบคีรีขันธ์',
          en: 'Mueang Prachuap Khiri Khan',
        },
        tambons: {
          770101: {
            name: {
              th: 'ประจวบคีรีขันธ์',
              en: 'Prachuap Khiri Khan',
            },
            coordinates: {
              lat: '11.805',
              lng: '99.784',
            },
            zipcode: 77000,
          },
          770102: {
            name: {
              th: 'เกาะหลัก',
              en: 'Ko Lak',
            },
            coordinates: {
              lat: '11.808',
              lng: '99.729',
            },
            zipcode: 77000,
          },
          770103: {
            name: {
              th: 'คลองวาฬ',
              en: 'Khlong Wan',
            },
            coordinates: {
              lat: '11.756',
              lng: '99.725',
            },
            zipcode: 77000,
          },
          770104: {
            name: {
              th: 'ห้วยทราย',
              en: 'Huai Sai',
            },
            coordinates: {
              lat: '11.709',
              lng: '99.672',
            },
            zipcode: 77000,
          },
          770105: {
            name: {
              th: 'อ่าวน้อย',
              en: 'Ao Noi',
            },
            coordinates: {
              lat: '11.904',
              lng: '99.701',
            },
            zipcode: 77210,
          },
          770106: {
            name: {
              th: 'บ่อนอก',
              en: 'Bo Nok',
            },
            coordinates: {
              lat: '11.996',
              lng: '99.771',
            },
            zipcode: 77210,
          },
        },
      },
      7702: {
        name: {
          th: 'กุยบุรี',
          en: 'Kui Buri',
        },
        tambons: {
          770201: {
            name: {
              th: 'กุยบุรี',
              en: 'Kui Buri',
            },
            coordinates: {
              lat: '12.052',
              lng: '99.763',
            },
            zipcode: 77150,
          },
          770202: {
            name: {
              th: 'กุยเหนือ',
              en: 'Kui Nuea',
            },
            coordinates: {
              lat: '12.086',
              lng: '99.908',
            },
            zipcode: 77150,
          },
          770203: {
            name: {
              th: 'เขาแดง',
              en: 'Khao Daeng',
            },
            coordinates: {
              lat: '12.159',
              lng: '99.980',
            },
            zipcode: 77150,
          },
          770204: {
            name: {
              th: 'ดอนยายหนู',
              en: 'Don Yai Nu',
            },
            coordinates: {
              lat: '12.136',
              lng: '99.929',
            },
            zipcode: 77150,
          },
          770206: {
            name: {
              th: 'สามกระทาย',
              en: 'Sam Krathai',
            },
            coordinates: {
              lat: '12.148',
              lng: '99.843',
            },
            zipcode: 77150,
          },
          770207: {
            name: {
              th: 'หาดขาม',
              en: 'Hat Kham',
            },
            coordinates: {
              lat: '12.108',
              lng: '99.659',
            },
            zipcode: 77150,
          },
        },
      },
      7703: {
        name: {
          th: 'ทับสะแก',
          en: 'Thap Sakae',
        },
        tambons: {
          770301: {
            name: {
              th: 'ทับสะแก',
              en: 'Thap Sakae',
            },
            coordinates: {
              lat: '11.516',
              lng: '99.627',
            },
            zipcode: 77130,
          },
          770302: {
            name: {
              th: 'อ่างทอง',
              en: 'Ang Thong',
            },
            coordinates: {
              lat: '11.454',
              lng: '99.508',
            },
            zipcode: 77130,
          },
          770303: {
            name: {
              th: 'นาหูกวาง',
              en: 'Na Hu Kwang',
            },
            coordinates: {
              lat: '11.537',
              lng: '99.499',
            },
            zipcode: 77130,
          },
          770304: {
            name: {
              th: 'เขาล้าน',
              en: 'Khao Lan',
            },
            coordinates: {
              lat: '11.606',
              lng: '99.501',
            },
            zipcode: 77130,
          },
          770305: {
            name: {
              th: 'ห้วยยาง',
              en: 'Huai Yang',
            },
            coordinates: {
              lat: '11.637',
              lng: '99.641',
            },
            zipcode: 77130,
          },
          770306: {
            name: {
              th: 'แสงอรุณ',
              en: 'Saeng Arun',
            },
            coordinates: {
              lat: '11.573',
              lng: '99.628',
            },
            zipcode: 77130,
          },
        },
      },
      7704: {
        name: {
          th: 'บางสะพาน',
          en: 'Bang Saphan',
        },
        tambons: {
          770401: {
            name: {
              th: 'กำเนิดนพคุณ',
              en: 'Kamnoet Nopphakhun',
            },
            coordinates: {
              lat: '11.248',
              lng: '99.516',
            },
            zipcode: 77140,
          },
          770402: {
            name: {
              th: 'พงศ์ประศาสน์',
              en: 'Phong Prasat',
            },
            coordinates: {
              lat: '11.188',
              lng: '99.466',
            },
            zipcode: 77140,
          },
          770403: {
            name: {
              th: 'ร่อนทอง',
              en: 'Ron Thong',
            },
            coordinates: {
              lat: '11.299',
              lng: '99.393',
            },
            zipcode: 77230,
          },
          770404: {
            name: {
              th: 'ธงชัย',
              en: 'Thong Chai',
            },
            coordinates: {
              lat: '11.318',
              lng: '99.525',
            },
            zipcode: 77190,
          },
          770405: {
            name: {
              th: 'ชัยเกษม',
              en: 'Chai Kasem',
            },
            coordinates: {
              lat: '11.375',
              lng: '99.473',
            },
            zipcode: 77190,
          },
          770406: {
            name: {
              th: 'ทองมงคล',
              en: 'Thong Mongkhon',
            },
            coordinates: {
              lat: '11.204',
              lng: '99.369',
            },
            zipcode: 77230,
          },
          770407: {
            name: {
              th: 'แม่รำพึง',
              en: 'Mae Ramphueng',
            },
            coordinates: {
              lat: '11.227',
              lng: '99.544',
            },
            zipcode: 77140,
          },
        },
      },
      7705: {
        name: {
          th: 'บางสะพานน้อย',
          en: 'Bang Saphan Noi',
        },
        tambons: {
          770501: {
            name: {
              th: 'ปากแพรก',
              en: 'Pak Phraek',
            },
            coordinates: {
              lat: '11.063',
              lng: '99.436',
            },
            zipcode: 77170,
          },
          770502: {
            name: {
              th: 'บางสะพาน',
              en: 'Bang Saphan',
            },
            coordinates: {
              lat: '11.107',
              lng: '99.443',
            },
            zipcode: 77170,
          },
          770503: {
            name: {
              th: 'ทรายทอง',
              en: 'Sai Thong',
            },
            coordinates: {
              lat: '11.011',
              lng: '99.424',
            },
            zipcode: 77170,
          },
          770504: {
            name: {
              th: 'ช้างแรก',
              en: 'Chang Raek',
            },
            coordinates: {
              lat: '11.103',
              lng: '99.306',
            },
            zipcode: 77170,
          },
          770505: {
            name: {
              th: 'ไชยราช',
              en: 'Chaiyarat',
            },
            coordinates: {
              lat: '11.012',
              lng: '99.256',
            },
            zipcode: 77170,
          },
        },
      },
      7706: {
        name: {
          th: 'ปราณบุรี',
          en: 'Pran Buri',
        },
        tambons: {
          770601: {
            name: {
              th: 'ปราณบุรี',
              en: 'Pran Buri',
            },
            coordinates: {
              lat: '12.354',
              lng: '99.932',
            },
            zipcode: 77160,
          },
          770602: {
            name: {
              th: 'เขาน้อย',
              en: 'Khao Noi',
            },
            coordinates: {
              lat: '12.405',
              lng: '99.898',
            },
            zipcode: 77160,
          },
          770604: {
            name: {
              th: 'ปากน้ำปราณ',
              en: 'Pak Nam Pran',
            },
            coordinates: {
              lat: '12.381',
              lng: '99.981',
            },
            zipcode: 77220,
          },
          770607: {
            name: {
              th: 'หนองตาแต้ม',
              en: 'Nong Ta Taem',
            },
            coordinates: {
              lat: '12.380',
              lng: '99.814',
            },
            zipcode: 77160,
          },
          770608: {
            name: {
              th: 'วังก์พง',
              en: 'Wang Phong',
            },
            coordinates: {
              lat: '12.447',
              lng: '99.911',
            },
            zipcode: 77160,
          },
          770609: {
            name: {
              th: 'เขาจ้าว',
              en: 'Khao Chao',
            },
            coordinates: {
              lat: '12.396',
              lng: '99.520',
            },
            zipcode: 77160,
          },
        },
      },
      7707: {
        name: {
          th: 'หัวหิน',
          en: 'Hua Hin',
        },
        tambons: {
          770701: {
            name: {
              th: 'หัวหิน',
              en: 'Hua Hin',
            },
            coordinates: {
              lat: '12.592',
              lng: '99.940',
            },
            zipcode: 77110,
          },
          770702: {
            name: {
              th: 'หนองแก',
              en: 'Nong Kae',
            },
            coordinates: {
              lat: '12.502',
              lng: '99.958',
            },
            zipcode: 77110,
          },
          770703: {
            name: {
              th: 'หินเหล็กไฟ',
              en: 'Hin Lek Fai',
            },
            coordinates: {
              lat: '12.592',
              lng: '99.862',
            },
            zipcode: 77110,
          },
          770704: {
            name: {
              th: 'หนองพลับ',
              en: 'Nong Phlap',
            },
            coordinates: {
              lat: '12.530',
              lng: '99.704',
            },
            zipcode: 77110,
          },
          770705: {
            name: {
              th: 'ทับใต้',
              en: 'Thap Tai',
            },
            coordinates: {
              lat: '12.516',
              lng: '99.863',
            },
            zipcode: 77110,
          },
          770706: {
            name: {
              th: 'ห้วยสัตว์ใหญ่',
              en: 'Huai Sat Yai',
            },
            coordinates: {
              lat: '12.512',
              lng: '99.526',
            },
            zipcode: 77110,
          },
          770707: {
            name: {
              th: 'บึงนคร',
              en: 'Bueng Nakhon',
            },
            coordinates: {
              lat: '12.403',
              lng: '99.655',
            },
            zipcode: 77110,
          },
        },
      },
      7708: {
        name: {
          th: 'สามร้อยยอด',
          en: 'Sam Roi Yot',
        },
        tambons: {
          770801: {
            name: {
              th: 'สามร้อยยอด',
              en: 'Sam Roi Yot',
            },
            coordinates: {
              lat: '12.266',
              lng: '99.946',
            },
            zipcode: null,
          },
          770802: {
            name: {
              th: 'ศิลาลอย',
              en: 'Sila Loi',
            },
            coordinates: {
              lat: '12.309',
              lng: '99.560',
            },
            zipcode: null,
          },
          770803: {
            name: {
              th: 'ไร่เก่า',
              en: 'Rai Kao',
            },
            coordinates: {
              lat: '12.252',
              lng: '99.706',
            },
            zipcode: null,
          },
          770804: {
            name: {
              th: 'ศาลาลัย',
              en: 'Sala Lai',
            },
            coordinates: {
              lat: '12.271',
              lng: '99.593',
            },
            zipcode: 77180,
          },
          770805: {
            name: {
              th: 'ไร่ใหม่',
              en: 'Rai Mai',
            },
            coordinates: {
              lat: '12.214',
              lng: '99.746',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  80: {
    name: {
      th: 'นครศรีธรรมราช',
      en: 'Nakhon Si Thammarat',
    },
    amphoes: {
      8001: {
        name: {
          th: 'เมืองนครศรีธรรมราช',
          en: 'Mueang Nakhon Si Thammarat',
        },
        tambons: {
          800101: {
            name: {
              th: 'ในเมือง',
              en: 'Nai Mueang',
            },
            coordinates: {
              lat: '8.408',
              lng: '99.972',
            },
            zipcode: 80000,
          },
          800102: {
            name: {
              th: 'ท่าวัง',
              en: 'Tha Wang',
            },
            coordinates: {
              lat: '8.449',
              lng: '99.961',
            },
            zipcode: 80000,
          },
          800103: {
            name: {
              th: 'คลัง',
              en: 'Khlang',
            },
            coordinates: {
              lat: '8.434',
              lng: '99.966',
            },
            zipcode: 80000,
          },
          800106: {
            name: {
              th: 'ท่าไร่',
              en: 'Tha Rai',
            },
            coordinates: {
              lat: '8.430',
              lng: '100.041',
            },
            zipcode: 80000,
          },
          800107: {
            name: {
              th: 'ปากนคร',
              en: 'Pak Nakhon',
            },
            coordinates: {
              lat: '8.421',
              lng: '99.996',
            },
            zipcode: 80000,
          },
          800108: {
            name: {
              th: 'นาทราย',
              en: null,
            },
            coordinates: {
              lat: '8.479',
              lng: '99.932',
            },
            zipcode: 80280,
          },
          800112: {
            name: {
              th: 'กำแพงเซา',
              en: 'Kamphaeng Sao',
            },
            coordinates: {
              lat: '8.401',
              lng: '99.868',
            },
            zipcode: 80280,
          },
          800113: {
            name: {
              th: 'ไชยมนตรี',
              en: 'Chai Montri',
            },
            coordinates: {
              lat: '8.401',
              lng: '99.914',
            },
            zipcode: 80000,
          },
          800114: {
            name: {
              th: 'มะม่วงสองต้น',
              en: 'Mamuang Song Ton',
            },
            coordinates: {
              lat: '8.397',
              lng: '99.952',
            },
            zipcode: 80000,
          },
          800115: {
            name: {
              th: 'นาเคียน',
              en: 'Na Khian',
            },
            coordinates: {
              lat: '8.452',
              lng: '99.919',
            },
            zipcode: 80000,
          },
          800116: {
            name: {
              th: 'ท่างิ้ว',
              en: 'Tha Ngio',
            },
            coordinates: {
              lat: '8.452',
              lng: '99.835',
            },
            zipcode: 80280,
          },
          800118: {
            name: {
              th: 'โพธิ์เสด็จ',
              en: 'Pho Sadet',
            },
            coordinates: {
              lat: '8.428',
              lng: '99.920',
            },
            zipcode: 80000,
          },
          800119: {
            name: {
              th: 'บางจาก',
              en: 'Bang Chak',
            },
            coordinates: {
              lat: '8.376',
              lng: '100.061',
            },
            zipcode: 80330,
          },
          800120: {
            name: {
              th: 'ปากพูน',
              en: 'Pak Phun',
            },
            coordinates: {
              lat: '8.529',
              lng: '99.997',
            },
            zipcode: 80000,
          },
          800121: {
            name: {
              th: 'ท่าซัก',
              en: 'Tha Sak',
            },
            coordinates: {
              lat: '8.510',
              lng: '100.032',
            },
            zipcode: 80000,
          },
          800122: {
            name: {
              th: 'ท่าเรือ',
              en: 'Tha Ruea',
            },
            coordinates: {
              lat: '8.321',
              lng: '100.013',
            },
            zipcode: 80000,
          },
        },
      },
      8002: {
        name: {
          th: 'พรหมคีรี',
          en: 'Phrom Khiri',
        },
        tambons: {
          800201: {
            name: {
              th: 'พรหมโลก',
              en: 'Phrom Lok',
            },
            coordinates: {
              lat: '8.500',
              lng: '99.793',
            },
            zipcode: null,
          },
          800202: {
            name: {
              th: 'บ้านเกาะ',
              en: 'Ban Ko',
            },
            coordinates: {
              lat: '8.539',
              lng: '99.814',
            },
            zipcode: null,
          },
          800203: {
            name: {
              th: 'อินคีรี',
              en: 'In Khiri',
            },
            coordinates: {
              lat: '8.540',
              lng: '99.874',
            },
            zipcode: null,
          },
          800204: {
            name: {
              th: 'ทอนหงส์',
              en: 'Thon Hong',
            },
            coordinates: {
              lat: '8.580',
              lng: '99.776',
            },
            zipcode: 80320,
          },
          800205: {
            name: {
              th: 'นาเรียง',
              en: 'Na Riang',
            },
            coordinates: {
              lat: '8.504',
              lng: '99.866',
            },
            zipcode: 80320,
          },
        },
      },
      8003: {
        name: {
          th: 'ลานสกา',
          en: 'Lan Saka',
        },
        tambons: {
          800301: {
            name: {
              th: 'เขาแก้ว',
              en: 'Khao Kaeo',
            },
            coordinates: {
              lat: '8.374',
              lng: '99.742',
            },
            zipcode: 80230,
          },
          800302: {
            name: {
              th: 'ลานสกา',
              en: 'Lan Saka',
            },
            coordinates: {
              lat: '8.305',
              lng: '99.771',
            },
            zipcode: 80230,
          },
          800303: {
            name: {
              th: 'ท่าดี',
              en: 'Tha Di',
            },
            coordinates: {
              lat: '8.443',
              lng: '99.804',
            },
            zipcode: 80230,
          },
          800304: {
            name: {
              th: 'กำโลน',
              en: 'Kamlon',
            },
            coordinates: {
              lat: '8.442',
              lng: '99.757',
            },
            zipcode: 80230,
          },
          800305: {
            name: {
              th: 'ขุนทะเล',
              en: 'Khun Thale',
            },
            coordinates: {
              lat: '8.344',
              lng: '99.848',
            },
            zipcode: 80230,
          },
        },
      },
      8004: {
        name: {
          th: 'ฉวาง',
          en: 'Chawang',
        },
        tambons: {
          800401: {
            name: {
              th: 'ฉวาง',
              en: 'Chawang',
            },
            coordinates: {
              lat: '8.397',
              lng: '99.501',
            },
            zipcode: 80150,
          },
          800403: {
            name: {
              th: 'ละอาย',
              en: 'La-Ai',
            },
            coordinates: {
              lat: '8.472',
              lng: '99.632',
            },
            zipcode: 80250,
          },
          800404: {
            name: {
              th: 'นาแว',
              en: 'Na Wae',
            },
            coordinates: {
              lat: '8.483',
              lng: '99.523',
            },
            zipcode: 80260,
          },
          800405: {
            name: {
              th: 'ไม้เรียง',
              en: 'Mai Riang',
            },
            coordinates: {
              lat: '8.457',
              lng: '99.477',
            },
            zipcode: 80260,
          },
          800406: {
            name: {
              th: 'กะเปียด',
              en: 'Kapiat',
            },
            coordinates: {
              lat: '8.519',
              lng: '99.460',
            },
            zipcode: 80260,
          },
          800407: {
            name: {
              th: 'นากะชะ',
              en: 'Na Kacha',
            },
            coordinates: {
              lat: '8.415',
              lng: '99.476',
            },
            zipcode: 80150,
          },
          800409: {
            name: {
              th: 'ห้วยปริก',
              en: 'Huai Prik',
            },
            coordinates: {
              lat: '8.569',
              lng: '99.440',
            },
            zipcode: 80260,
          },
          800410: {
            name: {
              th: 'ไสหร้า',
              en: 'Sai Ra',
            },
            coordinates: {
              lat: '8.437',
              lng: '99.547',
            },
            zipcode: 80150,
          },
          800415: {
            name: {
              th: 'นาเขลียง',
              en: 'Na Khliang',
            },
            coordinates: {
              lat: '8.533',
              lng: '99.500',
            },
            zipcode: 80260,
          },
          800416: {
            name: {
              th: 'จันดี',
              en: 'Chan Di',
            },
            coordinates: {
              lat: '8.391',
              lng: '99.554',
            },
            zipcode: 80250,
          },
        },
      },
      8005: {
        name: {
          th: 'พิปูน',
          en: 'Phipun',
        },
        tambons: {
          800501: {
            name: {
              th: 'พิปูน',
              en: 'Phipun',
            },
            coordinates: {
              lat: '8.555',
              lng: '99.653',
            },
            zipcode: 80270,
          },
          800502: {
            name: {
              th: 'กะทูน',
              en: 'Kathun',
            },
            coordinates: {
              lat: '8.638',
              lng: '99.530',
            },
            zipcode: 80270,
          },
          800503: {
            name: {
              th: 'เขาพระ',
              en: 'Khao Phra',
            },
            coordinates: {
              lat: '8.634',
              lng: '99.615',
            },
            zipcode: 80270,
          },
          800504: {
            name: {
              th: 'ยางค้อม',
              en: 'Yang Khom',
            },
            coordinates: {
              lat: '8.520',
              lng: '99.701',
            },
            zipcode: 80270,
          },
          800505: {
            name: {
              th: 'ควนกลาง',
              en: 'Khuan Klang',
            },
            coordinates: {
              lat: '8.534',
              lng: '99.553',
            },
            zipcode: 80270,
          },
        },
      },
      8006: {
        name: {
          th: 'เชียรใหญ่',
          en: 'Chian Yai',
        },
        tambons: {
          800601: {
            name: {
              th: 'เชียรใหญ่',
              en: 'Chian Yai',
            },
            coordinates: {
              lat: '8.202',
              lng: '100.116',
            },
            zipcode: 80190,
          },
          800603: {
            name: {
              th: 'ท่าขนาน',
              en: 'Tha Khanan',
            },
            coordinates: {
              lat: '8.140',
              lng: '100.165',
            },
            zipcode: 80190,
          },
          800604: {
            name: {
              th: 'บ้านกลาง',
              en: 'Ban Klang',
            },
            coordinates: {
              lat: '8.156',
              lng: '100.197',
            },
            zipcode: 80190,
          },
          800605: {
            name: {
              th: 'บ้านเนิน',
              en: 'Ban Noen',
            },
            coordinates: {
              lat: '8.166',
              lng: '100.170',
            },
            zipcode: 80190,
          },
          800606: {
            name: {
              th: 'ไสหมาก',
              en: 'Sai Mak',
            },
            coordinates: {
              lat: '8.203',
              lng: '100.163',
            },
            zipcode: 80190,
          },
          800607: {
            name: {
              th: 'ท้องลำเจียก',
              en: 'Thong Lamchiak',
            },
            coordinates: {
              lat: '8.147',
              lng: '100.117',
            },
            zipcode: 80190,
          },
          800610: {
            name: {
              th: 'เสือหึง',
              en: 'Suea Hueng',
            },
            coordinates: {
              lat: '8.189',
              lng: '100.242',
            },
            zipcode: 80190,
          },
          800611: {
            name: {
              th: 'การะเกด',
              en: 'Karaket',
            },
            coordinates: {
              lat: '8.073',
              lng: '100.154',
            },
            zipcode: 80190,
          },
          800612: {
            name: {
              th: 'เขาพระบาท',
              en: 'Khao Phrabat',
            },
            coordinates: {
              lat: '8.084',
              lng: '100.191',
            },
            zipcode: 80190,
          },
          800613: {
            name: {
              th: 'แม่เจ้าอยู่หัว',
              en: 'Mae Chao Yu Hua',
            },
            coordinates: {
              lat: '8.075',
              lng: '100.094',
            },
            zipcode: 80190,
          },
        },
      },
      8007: {
        name: {
          th: 'ชะอวด',
          en: 'Cha-uat',
        },
        tambons: {
          800701: {
            name: {
              th: 'ชะอวด',
              en: 'Cha-Uat',
            },
            coordinates: {
              lat: '7.996',
              lng: '100.020',
            },
            zipcode: 80180,
          },
          800702: {
            name: {
              th: 'ท่าเสม็ด',
              en: 'Tha Samet',
            },
            coordinates: {
              lat: '7.949',
              lng: '100.017',
            },
            zipcode: 80180,
          },
          800703: {
            name: {
              th: 'ท่าประจะ',
              en: 'Tha Pracha',
            },
            coordinates: {
              lat: '7.973',
              lng: '99.960',
            },
            zipcode: 80180,
          },
          800704: {
            name: {
              th: 'เคร็ง',
              en: 'Khreng',
            },
            coordinates: {
              lat: '7.952',
              lng: '100.103',
            },
            zipcode: 80180,
          },
          800705: {
            name: {
              th: 'วังอ่าง',
              en: 'Wang Ang',
            },
            coordinates: {
              lat: '7.927',
              lng: '99.886',
            },
            zipcode: 80180,
          },
          800706: {
            name: {
              th: 'บ้านตูล',
              en: 'Ban Tun',
            },
            coordinates: {
              lat: '8.070',
              lng: '100.000',
            },
            zipcode: 80180,
          },
          800707: {
            name: {
              th: 'ขอนหาด',
              en: 'Khon Hat',
            },
            coordinates: {
              lat: '7.872',
              lng: '100.059',
            },
            zipcode: 80180,
          },
          800708: {
            name: {
              th: 'เกาะขันธ์',
              en: 'Ko Khan',
            },
            coordinates: {
              lat: '7.910',
              lng: '99.935',
            },
            zipcode: 80180,
          },
          800709: {
            name: {
              th: 'ควนหนองหงษ์',
              en: 'Khuan Nong Hong',
            },
            coordinates: {
              lat: '8.009',
              lng: '99.885',
            },
            zipcode: 80180,
          },
          800710: {
            name: {
              th: 'เขาพระทอง',
              en: 'Khao Phra Thong',
            },
            coordinates: {
              lat: '7.974',
              lng: '99.905',
            },
            zipcode: 80180,
          },
          800711: {
            name: {
              th: 'นางหลง',
              en: 'Nang Long',
            },
            coordinates: {
              lat: '7.901',
              lng: '99.988',
            },
            zipcode: 80180,
          },
        },
      },
      8008: {
        name: {
          th: 'ท่าศาลา',
          en: 'Tha Sala',
        },
        tambons: {
          800801: {
            name: {
              th: 'ท่าศาลา',
              en: 'Tha Sala',
            },
            coordinates: {
              lat: '8.669',
              lng: '99.926',
            },
            zipcode: 80160,
          },
          800802: {
            name: {
              th: 'กลาย',
              en: 'Klai',
            },
            coordinates: {
              lat: '8.812',
              lng: '99.902',
            },
            zipcode: 80160,
          },
          800803: {
            name: {
              th: 'ท่าขึ้น',
              en: 'Tha Khuen',
            },
            coordinates: {
              lat: '8.714',
              lng: '99.887',
            },
            zipcode: 80160,
          },
          800804: {
            name: {
              th: 'หัวตะพาน',
              en: 'Hua Taphan',
            },
            coordinates: {
              lat: '8.628',
              lng: '99.889',
            },
            zipcode: 80160,
          },
          800806: {
            name: {
              th: 'สระแก้ว',
              en: 'Sa Kaeo',
            },
            coordinates: {
              lat: '8.752',
              lng: '99.867',
            },
            zipcode: 80160,
          },
          800807: {
            name: {
              th: 'โมคลาน',
              en: 'Mo Khlan',
            },
            coordinates: {
              lat: '8.589',
              lng: '99.885',
            },
            zipcode: 80160,
          },
          800809: {
            name: {
              th: 'ไทยบุรี',
              en: 'Thai Buri',
            },
            coordinates: {
              lat: '8.665',
              lng: '99.879',
            },
            zipcode: 80160,
          },
          800810: {
            name: {
              th: 'ดอนตะโก',
              en: 'Don Tako',
            },
            coordinates: {
              lat: '8.560',
              lng: '99.903',
            },
            zipcode: 80160,
          },
          800811: {
            name: {
              th: 'ตลิ่งชัน',
              en: 'Taling Chan',
            },
            coordinates: {
              lat: '8.783',
              lng: '99.821',
            },
            zipcode: 80160,
          },
          800813: {
            name: {
              th: 'โพธิ์ทอง',
              en: 'Pho Thong',
            },
            coordinates: {
              lat: '8.613',
              lng: '99.923',
            },
            zipcode: 80160,
          },
        },
      },
      8009: {
        name: {
          th: 'ทุ่งสง',
          en: 'Thung Song',
        },
        tambons: {
          800901: {
            name: {
              th: 'เทศบาลเมืองทุ่งสง-ปากแพรก',
              en: 'Pak Phraek',
            },
            coordinates: {
              lat: '8.168',
              lng: '99.689',
            },
            zipcode: 80140,
          },
          800902: {
            name: {
              th: 'ชะมาย',
              en: 'Chamai',
            },
            coordinates: {
              lat: '8.148',
              lng: '99.673',
            },
            zipcode: 80110,
          },
          800903: {
            name: {
              th: 'หนองหงส์',
              en: 'Nong Hong',
            },
            coordinates: {
              lat: '8.195',
              lng: '99.614',
            },
            zipcode: 80110,
          },
          800904: {
            name: {
              th: 'ควนกรด',
              en: 'Khuan Krot',
            },
            coordinates: {
              lat: '8.149',
              lng: '99.632',
            },
            zipcode: 80110,
          },
          800905: {
            name: {
              th: 'นาไม้ไผ่',
              en: 'Na Mai Phai',
            },
            coordinates: {
              lat: '8.119',
              lng: '99.590',
            },
            zipcode: 80110,
          },
          800906: {
            name: {
              th: 'นาหลวงเสน',
              en: 'Na Luang Sen',
            },
            coordinates: {
              lat: '8.240',
              lng: '99.705',
            },
            zipcode: 80110,
          },
          800907: {
            name: {
              th: 'เขาโร',
              en: 'Khao Ro',
            },
            coordinates: {
              lat: '8.047',
              lng: '99.594',
            },
            zipcode: 80110,
          },
          800908: {
            name: {
              th: 'กะปาง',
              en: 'Kapang',
            },
            coordinates: {
              lat: '8.029',
              lng: '99.683',
            },
            zipcode: 80310,
          },
          800909: {
            name: {
              th: 'ที่วัง',
              en: 'Thi Wang',
            },
            coordinates: {
              lat: '8.099',
              lng: '99.670',
            },
            zipcode: 80110,
          },
          800910: {
            name: {
              th: 'น้ำตก',
              en: 'Namtok',
            },
            coordinates: {
              lat: '7.971',
              lng: '99.780',
            },
            zipcode: 80110,
          },
          800911: {
            name: {
              th: 'ถ้ำใหญ่',
              en: 'Tham Yai',
            },
            coordinates: {
              lat: '8.164',
              lng: '99.742',
            },
            zipcode: 80110,
          },
          800912: {
            name: {
              th: 'นาโพธิ์',
              en: 'Na Pho',
            },
            coordinates: {
              lat: '8.209',
              lng: '99.574',
            },
            zipcode: 80110,
          },
          800913: {
            name: {
              th: 'เขาขาว',
              en: 'Khao Khao',
            },
            coordinates: {
              lat: '8.173',
              lng: '99.500',
            },
            zipcode: 80110,
          },
        },
      },
      8010: {
        name: {
          th: 'นาบอน',
          en: 'Na Bon',
        },
        tambons: {
          801001: {
            name: {
              th: 'นาบอน',
              en: 'Na Bon',
            },
            coordinates: {
              lat: '8.243',
              lng: '99.600',
            },
            zipcode: null,
          },
          801002: {
            name: {
              th: 'ทุ่งสง',
              en: 'Thung Song',
            },
            coordinates: {
              lat: '8.255',
              lng: '99.487',
            },
            zipcode: null,
          },
          801003: {
            name: {
              th: 'แก้วแสน',
              en: 'Kaeo Saen',
            },
            coordinates: {
              lat: '8.282',
              lng: '99.528',
            },
            zipcode: null,
          },
        },
      },
      8011: {
        name: {
          th: 'ทุ่งใหญ่',
          en: 'Thung Yai',
        },
        tambons: {
          801101: {
            name: {
              th: 'ท่ายาง',
              en: 'Tha Yang',
            },
            coordinates: {
              lat: '8.305',
              lng: '99.346',
            },
            zipcode: 80240,
          },
          801102: {
            name: {
              th: 'ทุ่งสัง',
              en: 'Thung Sang',
            },
            coordinates: {
              lat: '8.372',
              lng: '99.342',
            },
            zipcode: 80240,
          },
          801103: {
            name: {
              th: 'ทุ่งใหญ่',
              en: 'Thung Yai',
            },
            coordinates: {
              lat: '8.366',
              lng: '99.429',
            },
            zipcode: 80240,
          },
          801104: {
            name: {
              th: 'กุแหระ',
              en: 'Kurae',
            },
            coordinates: {
              lat: '8.194',
              lng: '99.337',
            },
            zipcode: 80240,
          },
          801105: {
            name: {
              th: 'ปริก',
              en: 'Prik',
            },
            coordinates: {
              lat: '8.280',
              lng: '99.432',
            },
            zipcode: 80240,
          },
          801106: {
            name: {
              th: 'บางรูป',
              en: 'Bang Rup',
            },
            coordinates: {
              lat: '8.400',
              lng: '99.279',
            },
            zipcode: 80240,
          },
          801107: {
            name: {
              th: 'กรุงหยัน',
              en: 'Krung Yan',
            },
            coordinates: {
              lat: '8.187',
              lng: '99.403',
            },
            zipcode: 80240,
          },
        },
      },
      8012: {
        name: {
          th: 'ปากพนัง',
          en: 'Pak Phanang',
        },
        tambons: {
          801202: {
            name: {
              th: 'คลองน้อย',
              en: 'Khlong Noi',
            },
            coordinates: {
              lat: '8.358',
              lng: '100.108',
            },
            zipcode: 80330,
          },
          801203: {
            name: {
              th: 'ป่าระกำ',
              en: 'Pa Rakam',
            },
            coordinates: {
              lat: '8.244',
              lng: '100.120',
            },
            zipcode: 80370,
          },
          801204: {
            name: {
              th: 'ชะเมา',
              en: 'Chamao',
            },
            coordinates: {
              lat: '8.275',
              lng: '100.054',
            },
            zipcode: 80330,
          },
          801205: {
            name: {
              th: 'คลองกระบือ',
              en: 'Khlong Krabue',
            },
            coordinates: {
              lat: '8.317',
              lng: '100.143',
            },
            zipcode: 80370,
          },
          801206: {
            name: {
              th: 'เกาะทวด',
              en: 'Ko Thuat',
            },
            coordinates: {
              lat: '8.283',
              lng: '100.092',
            },
            zipcode: 80330,
          },
          801207: {
            name: {
              th: 'บ้านใหม่',
              en: 'Ban Mai',
            },
            coordinates: {
              lat: '8.277',
              lng: '100.154',
            },
            zipcode: 80370,
          },
          801208: {
            name: {
              th: 'หูล่อง',
              en: 'Hu Long',
            },
            coordinates: {
              lat: '8.314',
              lng: '100.184',
            },
            zipcode: 80370,
          },
          801209: {
            name: {
              th: 'แหลมตะลุมพุก',
              en: 'Laem Talumphuk',
            },
            coordinates: {
              lat: '8.458',
              lng: '100.196',
            },
            zipcode: 80140,
          },
          801210: {
            name: {
              th: 'ปากพนังฝั่งตะวันตก',
              en: 'Pak Phanang Fang Tawan Tok',
            },
            coordinates: {
              lat: '8.360',
              lng: '100.165',
            },
            zipcode: 80370,
          },
          801211: {
            name: {
              th: 'บางศาลา',
              en: 'Bang Sala',
            },
            coordinates: {
              lat: '8.246',
              lng: '100.185',
            },
            zipcode: 80370,
          },
          801212: {
            name: {
              th: 'บางพระ',
              en: 'Bang Phra',
            },
            coordinates: {
              lat: '8.326',
              lng: '100.230',
            },
            zipcode: 80140,
          },
          801213: {
            name: {
              th: 'บางตะพง',
              en: 'Bang Taphong',
            },
            coordinates: {
              lat: '8.232',
              lng: '100.153',
            },
            zipcode: 80370,
          },
          801214: {
            name: {
              th: 'ปากพนังฝั่งตะวันออก',
              en: 'Pak Phanang Fang Tawan Ok',
            },
            coordinates: {
              lat: '8.397',
              lng: '100.200',
            },
            zipcode: 80140,
          },
          801215: {
            name: {
              th: 'บ้านเพิง',
              en: 'Ban Phoeng',
            },
            coordinates: {
              lat: '8.294',
              lng: '100.227',
            },
            zipcode: 80140,
          },
          801216: {
            name: {
              th: 'ท่าพญา',
              en: 'Tha Phaya',
            },
            coordinates: {
              lat: '8.267',
              lng: '100.258',
            },
            zipcode: 80140,
          },
          801217: {
            name: {
              th: 'ปากแพรก',
              en: 'Pak Phraek',
            },
            coordinates: {
              lat: '8.244',
              lng: '100.212',
            },
            zipcode: null,
          },
          801218: {
            name: {
              th: 'ขนาบนาก',
              en: 'Khanap Nak',
            },
            coordinates: {
              lat: '8.220',
              lng: '100.247',
            },
            zipcode: 80140,
          },
        },
      },
      8013: {
        name: {
          th: 'ร่อนพิบูลย์',
          en: 'Ron Phibun',
        },
        tambons: {
          801301: {
            name: {
              th: 'ร่อนพิบูลย์',
              en: 'Ron Phibun',
            },
            coordinates: {
              lat: '8.189',
              lng: '99.832',
            },
            zipcode: 80130,
          },
          801302: {
            name: {
              th: 'หินตก',
              en: 'Hin Tok',
            },
            coordinates: {
              lat: '8.237',
              lng: '99.880',
            },
            zipcode: 80350,
          },
          801303: {
            name: {
              th: 'เสาธง',
              en: 'Sao Thong',
            },
            coordinates: {
              lat: '8.252',
              lng: '99.924',
            },
            zipcode: 80350,
          },
          801304: {
            name: {
              th: 'ควนเกย',
              en: 'Khuan Koei',
            },
            coordinates: {
              lat: '8.140',
              lng: '99.900',
            },
            zipcode: 80130,
          },
          801305: {
            name: {
              th: 'ควนพัง',
              en: 'Khuan Phang',
            },
            coordinates: {
              lat: '8.159',
              lng: '99.977',
            },
            zipcode: 80130,
          },
          801306: {
            name: {
              th: 'ควนชุม',
              en: 'Khuan Chum',
            },
            coordinates: {
              lat: '8.183',
              lng: '99.919',
            },
            zipcode: 80130,
          },
        },
      },
      8014: {
        name: {
          th: 'สิชล',
          en: 'Sichon',
        },
        tambons: {
          801401: {
            name: {
              th: 'สิชล',
              en: 'Sichon',
            },
            coordinates: {
              lat: '9.008',
              lng: '99.902',
            },
            zipcode: 80120,
          },
          801402: {
            name: {
              th: 'ทุ่งปรัง',
              en: 'Thung Prang',
            },
            coordinates: {
              lat: '8.958',
              lng: '99.876',
            },
            zipcode: 80120,
          },
          801403: {
            name: {
              th: 'ฉลอง',
              en: 'Chalong',
            },
            coordinates: {
              lat: '8.890',
              lng: '99.773',
            },
            zipcode: 80120,
          },
          801404: {
            name: {
              th: 'เสาเภา',
              en: 'Sao Phao',
            },
            coordinates: {
              lat: '8.890',
              lng: '99.904',
            },
            zipcode: 80340,
          },
          801405: {
            name: {
              th: 'เปลี่ยน',
              en: 'Plian',
            },
            coordinates: {
              lat: '8.833',
              lng: '99.870',
            },
            zipcode: 80120,
          },
          801406: {
            name: {
              th: 'สีขีด',
              en: 'Si Khit',
            },
            coordinates: {
              lat: '9.023',
              lng: '99.786',
            },
            zipcode: 80120,
          },
          801407: {
            name: {
              th: 'เทพราช',
              en: 'Theppharat',
            },
            coordinates: {
              lat: '8.841',
              lng: '99.735',
            },
            zipcode: 80340,
          },
          801408: {
            name: {
              th: 'เขาน้อย',
              en: 'Khao Noi',
            },
            coordinates: {
              lat: '8.945',
              lng: '99.787',
            },
            zipcode: 80120,
          },
          801409: {
            name: {
              th: 'ทุ่งใส',
              en: 'Thung Sai',
            },
            coordinates: {
              lat: '9.060',
              lng: '99.890',
            },
            zipcode: 80120,
          },
        },
      },
      8015: {
        name: {
          th: 'ขนอม',
          en: 'Khanom',
        },
        tambons: {
          801501: {
            name: {
              th: 'ขนอม',
              en: 'Khanom',
            },
            coordinates: {
              lat: '9.166',
              lng: '99.838',
            },
            zipcode: 80210,
          },
          801502: {
            name: {
              th: 'ควนทอง',
              en: 'Khuan Thong',
            },
            coordinates: {
              lat: '9.181',
              lng: '99.782',
            },
            zipcode: 80210,
          },
          801503: {
            name: {
              th: 'ท้องเนียน',
              en: 'Thong Nian',
            },
            coordinates: {
              lat: '9.280',
              lng: '99.814',
            },
            zipcode: 80210,
          },
        },
      },
      8016: {
        name: {
          th: 'หัวไทร',
          en: 'Hua Sai',
        },
        tambons: {
          801601: {
            name: {
              th: 'หัวไทร',
              en: 'Hua Sai',
            },
            coordinates: {
              lat: '8.037',
              lng: '100.286',
            },
            zipcode: 80170,
          },
          801602: {
            name: {
              th: 'หน้าสตน',
              en: 'Na Saton',
            },
            coordinates: {
              lat: '7.969',
              lng: '100.322',
            },
            zipcode: 80170,
          },
          801603: {
            name: {
              th: 'ทรายขาว',
              en: 'Sai Khao',
            },
            coordinates: {
              lat: '8.040',
              lng: '100.226',
            },
            zipcode: 80170,
          },
          801604: {
            name: {
              th: 'แหลม',
              en: 'Laem',
            },
            coordinates: {
              lat: '7.970',
              lng: '100.192',
            },
            zipcode: 80170,
          },
          801605: {
            name: {
              th: 'เขาพังไกร',
              en: 'Khao Phang Krai',
            },
            coordinates: {
              lat: '7.976',
              lng: '100.272',
            },
            zipcode: 80170,
          },
          801606: {
            name: {
              th: 'บ้านราม',
              en: 'Ban Ram',
            },
            coordinates: {
              lat: '8.081',
              lng: '100.257',
            },
            zipcode: 80170,
          },
          801607: {
            name: {
              th: 'บางนบ',
              en: 'Bang Nop',
            },
            coordinates: {
              lat: '8.104',
              lng: '100.261',
            },
            zipcode: 80170,
          },
          801608: {
            name: {
              th: 'ท่าซอม',
              en: 'Tha Som',
            },
            coordinates: {
              lat: '8.146',
              lng: '100.246',
            },
            zipcode: 80170,
          },
          801609: {
            name: {
              th: 'ควนชะลิก',
              en: 'Khuan Chalik',
            },
            coordinates: {
              lat: '7.907',
              lng: '100.206',
            },
            zipcode: 80170,
          },
          801610: {
            name: {
              th: 'รามแก้ว',
              en: 'Ram Kaeo',
            },
            coordinates: {
              lat: '7.932',
              lng: '100.276',
            },
            zipcode: 80170,
          },
          801611: {
            name: {
              th: 'เกาะเพชร',
              en: 'Ko Phet',
            },
            coordinates: {
              lat: '8.112',
              lng: '100.291',
            },
            zipcode: 80170,
          },
        },
      },
      8017: {
        name: {
          th: 'บางขัน',
          en: 'Bang Khan',
        },
        tambons: {
          801701: {
            name: {
              th: 'บางขัน',
              en: 'Bang Khan',
            },
            coordinates: {
              lat: '8.069',
              lng: '99.422',
            },
            zipcode: null,
          },
          801702: {
            name: {
              th: 'บ้านลำนาว',
              en: 'Ban Lamnao',
            },
            coordinates: {
              lat: '7.968',
              lng: '99.417',
            },
            zipcode: null,
          },
          801703: {
            name: {
              th: 'วังหิน',
              en: 'Wang Hin',
            },
            coordinates: {
              lat: '7.984',
              lng: '99.554',
            },
            zipcode: null,
          },
          801704: {
            name: {
              th: 'บ้านนิคม',
              en: 'Ban Nikhom',
            },
            coordinates: {
              lat: '7.933',
              lng: '99.507',
            },
            zipcode: 80360,
          },
        },
      },
      8018: {
        name: {
          th: 'ถ้ำพรรณรา',
          en: 'Tham Phannara',
        },
        tambons: {
          801801: {
            name: {
              th: 'ถ้ำพรรณรา',
              en: 'Tham Phannara',
            },
            coordinates: {
              lat: '8.414',
              lng: '99.373',
            },
            zipcode: null,
          },
          801802: {
            name: {
              th: 'คลองเส',
              en: 'Khlong Se',
            },
            coordinates: {
              lat: '8.491',
              lng: '99.419',
            },
            zipcode: null,
          },
          801803: {
            name: {
              th: 'ดุสิต',
              en: 'Dusit',
            },
            coordinates: {
              lat: '8.466',
              lng: '99.347',
            },
            zipcode: null,
          },
        },
      },
      8019: {
        name: {
          th: 'จุฬาภรณ์',
          en: 'Chulabhorn',
        },
        tambons: {
          801901: {
            name: {
              th: 'บ้านควนมุด',
              en: 'Ban Khuan Mut',
            },
            coordinates: {
              lat: '8.028',
              lng: '99.924',
            },
            zipcode: null,
          },
          801902: {
            name: {
              th: 'บ้านชะอวด',
              en: 'Ban Cha-Uat',
            },
            coordinates: {
              lat: '8.058',
              lng: '99.933',
            },
            zipcode: null,
          },
          801903: {
            name: {
              th: 'ควนหนองคว้า',
              en: 'Khuan Nong Kwa',
            },
            coordinates: {
              lat: '8.103',
              lng: '99.935',
            },
            zipcode: null,
          },
          801904: {
            name: {
              th: 'ทุ่งโพธิ์',
              en: 'Thung Pho',
            },
            coordinates: {
              lat: '8.109',
              lng: '99.829',
            },
            zipcode: null,
          },
          801905: {
            name: {
              th: 'นาหมอบุญ',
              en: 'Na Mo Bun',
            },
            coordinates: {
              lat: '8.030',
              lng: '99.813',
            },
            zipcode: null,
          },
          801906: {
            name: {
              th: 'สาม',
              en: 'Sam Tambon',
            },
            coordinates: {
              lat: '8.060',
              lng: '99.806',
            },
            zipcode: null,
          },
        },
      },
      8020: {
        name: {
          th: 'พระพรหม',
          en: 'Phra Phrom',
        },
        tambons: {
          802001: {
            name: {
              th: 'นาพรุ',
              en: 'Na Phru',
            },
            coordinates: {
              lat: '8.331',
              lng: '99.907',
            },
            zipcode: null,
          },
          802002: {
            name: {
              th: 'นาสาร',
              en: 'Na San',
            },
            coordinates: {
              lat: '8.370',
              lng: '99.920',
            },
            zipcode: null,
          },
          802003: {
            name: {
              th: 'ท้ายสำเภา',
              en: 'Thai Samphao',
            },
            coordinates: {
              lat: '8.302',
              lng: '99.909',
            },
            zipcode: null,
          },
          802004: {
            name: {
              th: 'ช้างซ้าย',
              en: 'Chang Sai',
            },
            coordinates: {
              lat: '8.311',
              lng: '99.966',
            },
            zipcode: null,
          },
        },
      },
      8021: {
        name: {
          th: 'นบพิตำ',
          en: 'Nopphitam',
        },
        tambons: {
          802101: {
            name: {
              th: 'นบพิตำ',
              en: 'Nopphitam',
            },
            coordinates: {
              lat: '8.744',
              lng: '99.772',
            },
            zipcode: null,
          },
          802102: {
            name: {
              th: 'กรุงชิง',
              en: 'Krung Ching',
            },
            coordinates: {
              lat: '8.804',
              lng: '99.635',
            },
            zipcode: null,
          },
          802103: {
            name: {
              th: 'กะหรอ',
              en: 'Karo',
            },
            coordinates: {
              lat: '8.666',
              lng: '99.827',
            },
            zipcode: null,
          },
          802104: {
            name: {
              th: 'นาเหรง',
              en: 'Na Reng',
            },
            coordinates: {
              lat: '8.660',
              lng: '99.745',
            },
            zipcode: null,
          },
        },
      },
      8022: {
        name: {
          th: 'ช้างกลาง',
          en: 'Chang Klang',
        },
        tambons: {
          802201: {
            name: {
              th: 'ช้างกลาง',
              en: 'Chang Klang',
            },
            coordinates: {
              lat: '8.336',
              lng: '99.638',
            },
            zipcode: null,
          },
          802202: {
            name: {
              th: 'หลักช้าง',
              en: 'Lak Chang',
            },
            coordinates: {
              lat: '8.354',
              lng: '99.535',
            },
            zipcode: null,
          },
          802203: {
            name: {
              th: 'สวนขัน',
              en: 'Suan Khan',
            },
            coordinates: {
              lat: '8.416',
              lng: '99.643',
            },
            zipcode: null,
          },
        },
      },
      8023: {
        name: {
          th: 'เฉลิมพระเกียรติ',
          en: 'Chaloem Phra Kiet',
        },
        tambons: {
          802301: {
            name: {
              th: 'เชียรเขา',
              en: 'Chian Khao',
            },
            coordinates: {
              lat: '8.184',
              lng: '100.078',
            },
            zipcode: null,
          },
          802302: {
            name: {
              th: 'ดอนตรอ',
              en: 'Don Tro',
            },
            coordinates: {
              lat: '8.213',
              lng: '100.039',
            },
            zipcode: null,
          },
          802303: {
            name: {
              th: 'สวนหลวง',
              en: 'Suan Luang',
            },
            coordinates: {
              lat: '8.139',
              lng: '100.037',
            },
            zipcode: null,
          },
          802304: {
            name: {
              th: 'ทางพูน',
              en: 'Thang Phun',
            },
            coordinates: {
              lat: '8.239',
              lng: '99.981',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  81: {
    name: {
      th: 'กระบี่',
      en: 'Krabi',
    },
    amphoes: {
      8101: {
        name: {
          th: 'เมืองกระบี่',
          en: 'Mueang Krabi',
        },
        tambons: {
          810101: {
            name: {
              th: 'ปากน้ำ',
              en: null,
            },
            coordinates: {
              lat: '8.090',
              lng: '98.905',
            },
            zipcode: 81000,
          },
          810103: {
            name: {
              th: 'กระบี่น้อย',
              en: 'Krabi Noi',
            },
            coordinates: {
              lat: '8.176',
              lng: '98.959',
            },
            zipcode: 81000,
          },
          810105: {
            name: {
              th: 'เขาคราม',
              en: 'Khao Khram',
            },
            coordinates: {
              lat: '8.241',
              lng: '98.825',
            },
            zipcode: 81000,
          },
          810106: {
            name: {
              th: 'เขาทอง',
              en: 'Khao Thong',
            },
            coordinates: {
              lat: '8.157',
              lng: '98.760',
            },
            zipcode: 81000,
          },
          810111: {
            name: {
              th: 'ทับปริก',
              en: 'Thap Prik',
            },
            coordinates: {
              lat: '8.203',
              lng: '98.886',
            },
            zipcode: 81000,
          },
          810115: {
            name: {
              th: 'ไสไทย',
              en: 'Sai Thai',
            },
            coordinates: {
              lat: '8.064',
              lng: '98.872',
            },
            zipcode: 81000,
          },
          810116: {
            name: {
              th: 'อ่าวนาง',
              en: 'Ao Nang',
            },
            coordinates: {
              lat: '8.052',
              lng: '98.832',
            },
            zipcode: 81000,
          },
          810117: {
            name: {
              th: 'หนองทะเล',
              en: 'Nong Thale',
            },
            coordinates: {
              lat: '8.088',
              lng: '98.785',
            },
            zipcode: 81180,
          },
          810118: {
            name: {
              th: 'คลองประสงค์',
              en: 'Khlong Prasong',
            },
            coordinates: {
              lat: '8.002',
              lng: '98.913',
            },
            zipcode: 81000,
          },
        },
      },
      8102: {
        name: {
          th: 'เขาพนม',
          en: 'Khao Phanom',
        },
        tambons: {
          810201: {
            name: {
              th: 'เขาพนม',
              en: 'Khao Phanom',
            },
            coordinates: {
              lat: '8.227',
              lng: '99.045',
            },
            zipcode: 81140,
          },
          810202: {
            name: {
              th: 'เขาดิน',
              en: 'Khao Din',
            },
            coordinates: {
              lat: '8.342',
              lng: '99.167',
            },
            zipcode: 81140,
          },
          810203: {
            name: {
              th: 'สินปุน',
              en: 'Sin Pun',
            },
            coordinates: {
              lat: '8.277',
              lng: '99.295',
            },
            zipcode: 80240,
          },
          810204: {
            name: {
              th: 'พรุเตียว',
              en: 'Phru Tiao',
            },
            coordinates: {
              lat: '8.258',
              lng: '99.128',
            },
            zipcode: 81140,
          },
          810205: {
            name: {
              th: 'หน้าเขา',
              en: 'Na Khao',
            },
            coordinates: {
              lat: '8.343',
              lng: '98.966',
            },
            zipcode: 81140,
          },
          810206: {
            name: {
              th: 'โคกหาร',
              en: 'Khok Han',
            },
            coordinates: {
              lat: '8.188',
              lng: '99.252',
            },
            zipcode: 80240,
          },
        },
      },
      8103: {
        name: {
          th: 'เกาะลันตา',
          en: 'Ko Lanta',
        },
        tambons: {
          810301: {
            name: {
              th: 'เกาะลันตาใหญ่',
              en: 'Ko Lanta Yai',
            },
            coordinates: {
              lat: '7.534',
              lng: '99.073',
            },
            zipcode: 81150,
          },
          810302: {
            name: {
              th: 'เกาะลันตาน้อย',
              en: 'Ko Lanta Noi',
            },
            coordinates: {
              lat: '7.640',
              lng: '99.084',
            },
            zipcode: 81150,
          },
          810303: {
            name: {
              th: 'เกาะกลาง',
              en: 'Ko Klang',
            },
            coordinates: {
              lat: '7.751',
              lng: '99.063',
            },
            zipcode: 81120,
          },
          810304: {
            name: {
              th: 'คลองยาง',
              en: 'Khlong Yang',
            },
            coordinates: {
              lat: '7.790',
              lng: '99.110',
            },
            zipcode: 81120,
          },
          810305: {
            name: {
              th: 'ศาลาด่าน',
              en: 'Sala Dan',
            },
            coordinates: {
              lat: '7.603',
              lng: '99.050',
            },
            zipcode: 81150,
          },
        },
      },
      8104: {
        name: {
          th: 'คลองท่อม',
          en: 'Khlong Thom',
        },
        tambons: {
          810401: {
            name: {
              th: 'คลองท่อมใต้',
              en: 'Khlong Thom Tai',
            },
            coordinates: {
              lat: '7.933',
              lng: '99.172',
            },
            zipcode: 81120,
          },
          810402: {
            name: {
              th: 'คลองท่อมเหนือ',
              en: 'Khlong Thom Nuea',
            },
            coordinates: {
              lat: '7.930',
              lng: '99.263',
            },
            zipcode: 81120,
          },
          810403: {
            name: {
              th: 'คลองพน',
              en: 'Khlong Phon',
            },
            coordinates: {
              lat: '7.758',
              lng: '99.172',
            },
            zipcode: 81170,
          },
          810404: {
            name: {
              th: 'ทรายขาว',
              en: 'Sai Khao',
            },
            coordinates: {
              lat: '7.711',
              lng: '99.241',
            },
            zipcode: 81170,
          },
          810405: {
            name: {
              th: 'ห้วยน้ำขาว',
              en: 'Huai Nam Khao',
            },
            coordinates: {
              lat: '7.866',
              lng: '99.126',
            },
            zipcode: 81120,
          },
          810406: {
            name: {
              th: 'พรุดินนา',
              en: 'Phru Din Na',
            },
            coordinates: {
              lat: '8.060',
              lng: '99.219',
            },
            zipcode: 81120,
          },
          810407: {
            name: {
              th: 'เพหลา',
              en: 'Phela',
            },
            coordinates: {
              lat: '8.075',
              lng: '99.139',
            },
            zipcode: 81120,
          },
        },
      },
      8105: {
        name: {
          th: 'อ่าวลึก',
          en: 'Ao Luek',
        },
        tambons: {
          810501: {
            name: {
              th: 'อ่าวลึกใต้',
              en: 'Ao Luek Tai',
            },
            coordinates: {
              lat: '8.386',
              lng: '98.707',
            },
            zipcode: 81110,
          },
          810502: {
            name: {
              th: 'แหลมสัก',
              en: 'Laem Sak',
            },
            coordinates: {
              lat: '8.304',
              lng: '98.650',
            },
            zipcode: 81110,
          },
          810503: {
            name: {
              th: 'นาเหนือ',
              en: 'Na Nuea',
            },
            coordinates: {
              lat: '8.529',
              lng: '98.736',
            },
            zipcode: 81110,
          },
          810504: {
            name: {
              th: 'คลองหิน',
              en: 'Khlong Hin',
            },
            coordinates: {
              lat: '8.324',
              lng: '98.837',
            },
            zipcode: 81110,
          },
          810505: {
            name: {
              th: 'อ่าวลึกน้อย',
              en: 'Ao Luek Noi',
            },
            coordinates: {
              lat: '8.243',
              lng: '98.687',
            },
            zipcode: 81110,
          },
          810506: {
            name: {
              th: 'อ่าวลึกเหนือ',
              en: 'Ao Luek Nuea',
            },
            coordinates: {
              lat: '8.399',
              lng: '98.825',
            },
            zipcode: 81110,
          },
          810507: {
            name: {
              th: 'เขาใหญ่',
              en: 'Khao Yai',
            },
            coordinates: {
              lat: '8.465',
              lng: '98.719',
            },
            zipcode: 81110,
          },
          810508: {
            name: {
              th: 'คลองยา',
              en: 'Khlong Ya',
            },
            coordinates: {
              lat: '8.379',
              lng: '98.883',
            },
            zipcode: 81110,
          },
          810509: {
            name: {
              th: 'บ้านกลาง',
              en: 'Ban Klang',
            },
            coordinates: {
              lat: '8.352',
              lng: '98.792',
            },
            zipcode: 81110,
          },
        },
      },
      8106: {
        name: {
          th: 'ปลายพระยา',
          en: 'Plai Phraya',
        },
        tambons: {
          810601: {
            name: {
              th: 'ปลายพระยา',
              en: 'Plai Phraya',
            },
            coordinates: {
              lat: '8.564',
              lng: '98.879',
            },
            zipcode: null,
          },
          810602: {
            name: {
              th: 'เขาเขน',
              en: 'Khao Khen',
            },
            coordinates: {
              lat: '8.560',
              lng: '98.798',
            },
            zipcode: null,
          },
          810603: {
            name: {
              th: 'เขาต่อ',
              en: 'Khao To',
            },
            coordinates: {
              lat: '8.612',
              lng: '98.739',
            },
            zipcode: null,
          },
          810604: {
            name: {
              th: 'คีรีวง',
              en: 'Khiri Wong',
            },
            coordinates: {
              lat: '8.445',
              lng: '98.841',
            },
            zipcode: 81160,
          },
        },
      },
      8107: {
        name: {
          th: 'ลำทับ',
          en: 'Lam Thap',
        },
        tambons: {
          810701: {
            name: {
              th: 'ลำทับ',
              en: 'Lam Thap',
            },
            coordinates: {
              lat: '7.960',
              lng: '99.348',
            },
            zipcode: null,
          },
          810702: {
            name: {
              th: 'ดินอุดม',
              en: 'Din Udom',
            },
            coordinates: {
              lat: '8.100',
              lng: '99.358',
            },
            zipcode: 81190,
          },
          810703: {
            name: {
              th: 'ทุ่งไทรทอง',
              en: 'Thung Sai Thong',
            },
            coordinates: {
              lat: '8.004',
              lng: '99.311',
            },
            zipcode: 81190,
          },
          810704: {
            name: {
              th: 'ดินแดง',
              en: 'Din Daeng',
            },
            coordinates: {
              lat: '8.011',
              lng: '99.373',
            },
            zipcode: 81190,
          },
        },
      },
      8108: {
        name: {
          th: 'เหนือคลอง',
          en: 'Nuea Khlong',
        },
        tambons: {
          810801: {
            name: {
              th: 'เหนือคลอง',
              en: 'Nuea Khlong',
            },
            coordinates: {
              lat: '8.086',
              lng: '98.990',
            },
            zipcode: null,
          },
          810802: {
            name: {
              th: 'เกาะศรีบอยา',
              en: 'Ko Si Boya',
            },
            coordinates: {
              lat: '7.836',
              lng: '98.963',
            },
            zipcode: null,
          },
          810803: {
            name: {
              th: 'คลองขนาน',
              en: 'Khlong Khanan',
            },
            coordinates: {
              lat: '7.960',
              lng: '99.030',
            },
            zipcode: null,
          },
          810804: {
            name: {
              th: 'คลองเขม้า',
              en: 'Khlong Khamao',
            },
            coordinates: {
              lat: '8.036',
              lng: '98.976',
            },
            zipcode: null,
          },
          810805: {
            name: {
              th: 'โคกยาง',
              en: 'Khok Yang',
            },
            coordinates: {
              lat: '8.090',
              lng: '99.087',
            },
            zipcode: null,
          },
          810806: {
            name: {
              th: 'ตลิ่งชัน',
              en: 'Taling Chan',
            },
            coordinates: {
              lat: '7.963',
              lng: '98.969',
            },
            zipcode: null,
          },
          810807: {
            name: {
              th: 'ปกาสัย',
              en: 'Pakasai',
            },
            coordinates: {
              lat: '8.015',
              lng: '99.062',
            },
            zipcode: null,
          },
          810808: {
            name: {
              th: 'ห้วยยูง',
              en: 'Huai Yung',
            },
            coordinates: {
              lat: '8.120',
              lng: '99.026',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  82: {
    name: {
      th: 'พังงา',
      en: 'Phang-nga',
    },
    amphoes: {
      8201: {
        name: {
          th: 'เมืองพังงา',
          en: 'Mueang Phang-nga',
        },
        tambons: {
          820101: {
            name: {
              th: 'ท้ายช้าง',
              en: 'Thai Chang',
            },
            coordinates: {
              lat: '8.446',
              lng: '98.524',
            },
            zipcode: 82000,
          },
          820102: {
            name: {
              th: 'นบปริง',
              en: 'Nop Pring',
            },
            coordinates: {
              lat: '8.536',
              lng: '98.514',
            },
            zipcode: 82000,
          },
          820103: {
            name: {
              th: 'ถ้ำน้ำผุด',
              en: 'Tham Nam Phut',
            },
            coordinates: {
              lat: '8.427',
              lng: '98.527',
            },
            zipcode: 82000,
          },
          820104: {
            name: {
              th: 'บางเตย',
              en: 'Bang Toei',
            },
            coordinates: {
              lat: '8.407',
              lng: '98.575',
            },
            zipcode: 82000,
          },
          820105: {
            name: {
              th: 'ตากแดด',
              en: 'Tak Daet',
            },
            coordinates: {
              lat: '8.433',
              lng: '98.489',
            },
            zipcode: 82000,
          },
          820106: {
            name: {
              th: 'สองแพรก',
              en: 'Song Phraek',
            },
            coordinates: {
              lat: '8.603',
              lng: '98.554',
            },
            zipcode: 82000,
          },
          820107: {
            name: {
              th: 'ทุ่งคาโงก',
              en: 'Thung Kha Ngok',
            },
            coordinates: {
              lat: '8.554',
              lng: '98.438',
            },
            zipcode: 82000,
          },
          820108: {
            name: {
              th: 'เกาะปันหยี',
              en: 'Ko Panyi',
            },
            coordinates: {
              lat: '8.317',
              lng: '98.539',
            },
            zipcode: 82000,
          },
          820109: {
            name: {
              th: 'ป่ากอ',
              en: 'Pa Ko',
            },
            coordinates: {
              lat: '8.500',
              lng: '98.466',
            },
            zipcode: 82000,
          },
        },
      },
      8202: {
        name: {
          th: 'เกาะยาว',
          en: 'Ko Yao',
        },
        tambons: {
          820201: {
            name: {
              th: 'เกาะยาวน้อย',
              en: 'Ko Yao Noi',
            },
            coordinates: {
              lat: '8.190',
              lng: '98.547',
            },
            zipcode: null,
          },
          820202: {
            name: {
              th: 'เกาะยาวใหญ่',
              en: 'Ko Yao Yai',
            },
            coordinates: {
              lat: '8.040',
              lng: '98.638',
            },
            zipcode: null,
          },
          820203: {
            name: {
              th: 'พรุใน',
              en: 'Phru Nai',
            },
            coordinates: {
              lat: '7.830',
              lng: '98.600',
            },
            zipcode: 83000,
          },
        },
      },
      8203: {
        name: {
          th: 'กะปง',
          en: 'Kapong',
        },
        tambons: {
          820301: {
            name: {
              th: 'กะปง',
              en: 'Kapong',
            },
            coordinates: {
              lat: '8.648',
              lng: '98.468',
            },
            zipcode: 82170,
          },
          820302: {
            name: {
              th: 'ท่านา',
              en: 'Tha Na',
            },
            coordinates: {
              lat: '8.713',
              lng: '98.419',
            },
            zipcode: 82170,
          },
          820303: {
            name: {
              th: 'เหมาะ',
              en: 'Mo',
            },
            coordinates: {
              lat: '8.695',
              lng: '98.362',
            },
            zipcode: 82170,
          },
          820304: {
            name: {
              th: 'เหล',
              en: 'Le',
            },
            coordinates: {
              lat: '8.734',
              lng: '98.507',
            },
            zipcode: 82170,
          },
          820305: {
            name: {
              th: 'รมณีย์',
              en: 'Rommani',
            },
            coordinates: {
              lat: '8.829',
              lng: '98.501',
            },
            zipcode: 82170,
          },
        },
      },
      8204: {
        name: {
          th: 'ตะกั่วทุ่ง',
          en: 'Takua Thung',
        },
        tambons: {
          820401: {
            name: {
              th: 'ถ้ำ',
              en: 'Tham',
            },
            coordinates: {
              lat: '8.409',
              lng: '98.412',
            },
            zipcode: 82130,
          },
          820402: {
            name: {
              th: 'กระโสม',
              en: 'Krasom',
            },
            coordinates: {
              lat: '8.331',
              lng: '98.483',
            },
            zipcode: 82130,
          },
          820403: {
            name: {
              th: 'กะไหล',
              en: 'Kalai',
            },
            coordinates: {
              lat: '8.285',
              lng: '98.477',
            },
            zipcode: 82130,
          },
          820404: {
            name: {
              th: 'ท่าอยู่',
              en: 'Tha Yu',
            },
            coordinates: {
              lat: '8.261',
              lng: '98.398',
            },
            zipcode: 82130,
          },
          820405: {
            name: {
              th: 'หล่อยูง',
              en: 'Lo Yung',
            },
            coordinates: {
              lat: '8.198',
              lng: '98.358',
            },
            zipcode: 82140,
          },
          820406: {
            name: {
              th: 'โคกกลอย',
              en: 'Khok Kloi',
            },
            coordinates: {
              lat: '8.252',
              lng: '98.306',
            },
            zipcode: 82140,
          },
          820407: {
            name: {
              th: 'คลองเคียน',
              en: 'Khlong Khian',
            },
            coordinates: {
              lat: '8.179',
              lng: '98.455',
            },
            zipcode: 82130,
          },
        },
      },
      8205: {
        name: {
          th: 'ตะกั่วป่า',
          en: 'Takua Pa',
        },
        tambons: {
          820501: {
            name: {
              th: 'ตะกั่วป่า',
              en: 'Takua Pa',
            },
            coordinates: {
              lat: '8.829',
              lng: '98.362',
            },
            zipcode: 82110,
          },
          820502: {
            name: {
              th: 'บางนายสี',
              en: 'Bang Nai Si',
            },
            coordinates: {
              lat: '8.914',
              lng: '98.324',
            },
            zipcode: 82110,
          },
          820503: {
            name: {
              th: 'บางไทร',
              en: 'Bang Sai',
            },
            coordinates: {
              lat: '8.773',
              lng: '98.312',
            },
            zipcode: 82110,
          },
          820504: {
            name: {
              th: 'บางม่วง',
              en: 'Bang Muang',
            },
            coordinates: {
              lat: '8.819',
              lng: '98.289',
            },
            zipcode: 82190,
          },
          820505: {
            name: {
              th: 'ตำตัว',
              en: 'Tamtua',
            },
            coordinates: {
              lat: '8.784',
              lng: '98.377',
            },
            zipcode: 82110,
          },
          820506: {
            name: {
              th: 'โคกเคียน',
              en: 'Khok Khian',
            },
            coordinates: {
              lat: '8.851',
              lng: '98.388',
            },
            zipcode: 82110,
          },
          820507: {
            name: {
              th: 'คึกคัก',
              en: 'Khuek Khak',
            },
            coordinates: {
              lat: '8.698',
              lng: '98.280',
            },
            zipcode: 82220,
          },
          820508: {
            name: {
              th: 'เกาะคอเขา',
              en: 'Ko Kho Khao',
            },
            coordinates: {
              lat: '8.962',
              lng: '98.284',
            },
            zipcode: 82190,
          },
        },
      },
      8206: {
        name: {
          th: 'คุระบุรี',
          en: 'Khura Buri',
        },
        tambons: {
          820601: {
            name: {
              th: 'คุระ',
              en: 'Khu Ra',
            },
            coordinates: {
              lat: '9.262',
              lng: '98.414',
            },
            zipcode: 82150,
          },
          820602: {
            name: {
              th: 'บางวัน',
              en: 'Bang Wan',
            },
            coordinates: {
              lat: '9.046',
              lng: '98.405',
            },
            zipcode: 82150,
          },
          820603: {
            name: {
              th: 'เกาะพระทอง',
              en: 'Ko Phra Thong',
            },
            coordinates: {
              lat: '9.127',
              lng: '98.294',
            },
            zipcode: 82150,
          },
          820605: {
            name: {
              th: 'แม่นางขาว',
              en: 'Mae Nang Khao',
            },
            coordinates: {
              lat: '9.154',
              lng: '98.404',
            },
            zipcode: 82150,
          },
        },
      },
      8207: {
        name: {
          th: 'ทับปุด',
          en: 'Thap Put',
        },
        tambons: {
          820701: {
            name: {
              th: 'ทับปุด',
              en: 'Thap Put',
            },
            coordinates: {
              lat: '8.517',
              lng: '98.632',
            },
            zipcode: 82180,
          },
          820702: {
            name: {
              th: 'มะรุ่ย',
              en: 'Marui',
            },
            coordinates: {
              lat: '8.431',
              lng: '98.639',
            },
            zipcode: 82180,
          },
          820703: {
            name: {
              th: 'บ่อแสน',
              en: 'Bo Saen',
            },
            coordinates: {
              lat: '8.472',
              lng: '98.605',
            },
            zipcode: 82180,
          },
          820704: {
            name: {
              th: 'ถ้ำทองหลาง',
              en: 'Tham Thong Lang',
            },
            coordinates: {
              lat: '8.556',
              lng: '98.598',
            },
            zipcode: 82180,
          },
          820705: {
            name: {
              th: 'โคกเจริญ',
              en: 'Khok Charoen',
            },
            coordinates: {
              lat: '8.543',
              lng: '98.664',
            },
            zipcode: 82180,
          },
          820706: {
            name: {
              th: 'บางเหรียง',
              en: 'Bang Riang',
            },
            coordinates: {
              lat: '8.610',
              lng: '98.654',
            },
            zipcode: 82180,
          },
        },
      },
      8208: {
        name: {
          th: 'ท้ายเหมือง',
          en: 'Thai Mueang',
        },
        tambons: {
          820801: {
            name: {
              th: 'ท้ายเหมือง',
              en: 'Thai Mueang',
            },
            coordinates: {
              lat: '8.442',
              lng: '98.275',
            },
            zipcode: 82120,
          },
          820802: {
            name: {
              th: 'นาเตย',
              en: 'Na Toei',
            },
            coordinates: {
              lat: '8.344',
              lng: '98.303',
            },
            zipcode: 82120,
          },
          820803: {
            name: {
              th: 'บางทอง',
              en: 'Bang Thong',
            },
            coordinates: {
              lat: '8.418',
              lng: '98.354',
            },
            zipcode: 82120,
          },
          820804: {
            name: {
              th: 'ทุ่งมะพร้าว',
              en: 'Thung Maphrao',
            },
            coordinates: {
              lat: '8.536',
              lng: '98.277',
            },
            zipcode: 82120,
          },
          820805: {
            name: {
              th: 'ลำภี',
              en: 'Lam Phi',
            },
            coordinates: {
              lat: '8.590',
              lng: '98.360',
            },
            zipcode: 82120,
          },
          820806: {
            name: {
              th: 'ลำแก่น',
              en: 'Lam Kaen',
            },
            coordinates: {
              lat: '8.578',
              lng: '98.258',
            },
            zipcode: 82210,
          },
        },
      },
    },
  },
  83: {
    name: {
      th: 'ภูเก็ต',
      en: 'Phuket',
    },
    amphoes: {
      8301: {
        name: {
          th: 'เมืองภูเก็ต',
          en: 'Mueang Phuket',
        },
        tambons: {
          830101: {
            name: {
              th: 'ตลาดใหญ่',
              en: 'Talat Yai',
            },
            coordinates: {
              lat: '7.888',
              lng: '98.394',
            },
            zipcode: 83000,
          },
          830102: {
            name: {
              th: 'ตลาดเหนือ',
              en: 'Talat Nuea',
            },
            coordinates: {
              lat: '7.879',
              lng: '98.381',
            },
            zipcode: 83000,
          },
          830103: {
            name: {
              th: 'เกาะแก้ว',
              en: 'Ko Kaeo',
            },
            coordinates: {
              lat: '7.948',
              lng: '98.378',
            },
            zipcode: 83000,
          },
          830105: {
            name: {
              th: 'รัษฎา',
              en: null,
            },
            coordinates: {
              lat: '7.874',
              lng: '98.358',
            },
            zipcode: 83000,
          },
          830106: {
            name: {
              th: 'ฉลอง',
              en: 'Chalong',
            },
            coordinates: {
              lat: '7.843',
              lng: '98.341',
            },
            zipcode: 83130,
          },
          830107: {
            name: {
              th: 'ราไวย์',
              en: 'Rawai',
            },
            coordinates: {
              lat: '7.793',
              lng: '98.321',
            },
            zipcode: 83130,
          },
          830108: {
            name: {
              th: 'กะรน',
              en: 'Karon',
            },
            coordinates: {
              lat: '7.835',
              lng: '98.301',
            },
            zipcode: 83100,
          },
        },
      },
      8302: {
        name: {
          th: 'กะทู้',
          en: 'Kathu',
        },
        tambons: {
          830201: {
            name: {
              th: 'กะทู้',
              en: 'Kathu',
            },
            coordinates: {
              lat: '7.914',
              lng: '98.338',
            },
            zipcode: 83120,
          },
          830202: {
            name: {
              th: 'ป่าตอง',
              en: 'Patong',
            },
            coordinates: {
              lat: '7.904',
              lng: '98.308',
            },
            zipcode: 83150,
          },
          830203: {
            name: {
              th: 'กมลา',
              en: 'Kamala',
            },
            coordinates: {
              lat: '7.945',
              lng: '98.289',
            },
            zipcode: 83120,
          },
        },
      },
      8303: {
        name: {
          th: 'ถลาง',
          en: 'Thalang',
        },
        tambons: {
          830301: {
            name: {
              th: 'เทพกระษัตรี',
              en: 'Thep Krasattri',
            },
            coordinates: {
              lat: '8.047',
              lng: '98.323',
            },
            zipcode: 83110,
          },
          830302: {
            name: {
              th: 'ศรีสุนทร',
              en: 'Si Sunthon',
            },
            coordinates: {
              lat: '7.984',
              lng: '98.346',
            },
            zipcode: 83110,
          },
          830303: {
            name: {
              th: 'เชิงทะเล',
              en: 'Choeng Thale',
            },
            coordinates: {
              lat: '7.996',
              lng: '98.309',
            },
            zipcode: 83110,
          },
          830304: {
            name: {
              th: 'ป่าคลอก',
              en: 'Pa Khlok',
            },
            coordinates: {
              lat: '8.042',
              lng: '98.399',
            },
            zipcode: 83110,
          },
          830305: {
            name: {
              th: 'ไม้ขาว',
              en: 'Mai Khao',
            },
            coordinates: {
              lat: '8.147',
              lng: '98.318',
            },
            zipcode: 83110,
          },
          830306: {
            name: {
              th: 'สาคู',
              en: 'Sakhu',
            },
            coordinates: {
              lat: '8.093',
              lng: '98.315',
            },
            zipcode: 83110,
          },
        },
      },
    },
  },
  84: {
    name: {
      th: 'สุราษฎร์ธานี',
      en: 'Surat Thani',
    },
    amphoes: {
      8401: {
        name: {
          th: 'เมืองสุราษฎร์ธานี',
          en: 'Mueang Surat Thani',
        },
        tambons: {
          840101: {
            name: {
              th: 'ตลาด',
              en: 'Talat',
            },
            coordinates: {
              lat: '9.137',
              lng: '99.320',
            },
            zipcode: 84000,
          },
          840102: {
            name: {
              th: 'มะขามเตี้ย',
              en: 'Makham Tia',
            },
            coordinates: {
              lat: '9.112',
              lng: '99.347',
            },
            zipcode: 84000,
          },
          840103: {
            name: {
              th: 'วัดประดู่',
              en: 'Wat Pradu',
            },
            coordinates: {
              lat: '8.979',
              lng: '99.328',
            },
            zipcode: 84000,
          },
          840104: {
            name: {
              th: 'ขุนทะเล',
              en: 'Khun Thale',
            },
            coordinates: {
              lat: '9.023',
              lng: '99.353',
            },
            zipcode: 84100,
          },
          840105: {
            name: {
              th: 'บางใบไม้',
              en: 'Bang Bai Mai',
            },
            coordinates: {
              lat: '9.152',
              lng: '99.295',
            },
            zipcode: 84000,
          },
          840106: {
            name: {
              th: 'บางชนะ',
              en: 'Bang Chana',
            },
            coordinates: {
              lat: '9.155',
              lng: '99.311',
            },
            zipcode: 84000,
          },
          840107: {
            name: {
              th: 'คลองน้อย',
              en: 'Khlong Noi',
            },
            coordinates: {
              lat: '9.135',
              lng: '99.251',
            },
            zipcode: 84000,
          },
          840108: {
            name: {
              th: 'บางไทร',
              en: 'Bang Sai',
            },
            coordinates: {
              lat: '9.188',
              lng: '99.300',
            },
            zipcode: 84000,
          },
          840109: {
            name: {
              th: 'บางโพธิ์',
              en: 'Bang Pho',
            },
            coordinates: {
              lat: '9.185',
              lng: '99.278',
            },
            zipcode: 84000,
          },
          840110: {
            name: {
              th: 'บางกุ้ง',
              en: 'Bang Kung',
            },
            coordinates: {
              lat: '9.159',
              lng: '99.360',
            },
            zipcode: 84000,
          },
          840111: {
            name: {
              th: 'คลองฉนาก',
              en: 'Khlong Chanak',
            },
            coordinates: {
              lat: '9.191',
              lng: '99.351',
            },
            zipcode: 84000,
          },
        },
      },
      8402: {
        name: {
          th: 'กาญจนดิษฐ์',
          en: 'Kanchanadit',
        },
        tambons: {
          840201: {
            name: {
              th: 'ท่าทองใหม่',
              en: 'Tha Thong Mai',
            },
            coordinates: {
              lat: '9.174',
              lng: '99.396',
            },
            zipcode: 84290,
          },
          840202: {
            name: {
              th: 'ท่าทอง',
              en: 'Tha Thong',
            },
            coordinates: {
              lat: '9.255',
              lng: '99.539',
            },
            zipcode: 84160,
          },
          840203: {
            name: {
              th: 'กะแดะ',
              en: 'Kadae',
            },
            coordinates: {
              lat: '9.181',
              lng: '99.465',
            },
            zipcode: 84160,
          },
          840204: {
            name: {
              th: 'ทุ่งกง',
              en: 'Thung Kong',
            },
            coordinates: {
              lat: '9.122',
              lng: '99.405',
            },
            zipcode: 84290,
          },
          840205: {
            name: {
              th: 'กรูด',
              en: 'Krut',
            },
            coordinates: {
              lat: '9.103',
              lng: '99.549',
            },
            zipcode: 84130,
          },
          840206: {
            name: {
              th: 'ช้างซ้าย',
              en: 'Chang Sai',
            },
            coordinates: {
              lat: '9.020',
              lng: '99.478',
            },
            zipcode: 84160,
          },
          840207: {
            name: {
              th: 'พลายวาส',
              en: 'Phlai Wat',
            },
            coordinates: {
              lat: '9.184',
              lng: '99.518',
            },
            zipcode: 84160,
          },
          840208: {
            name: {
              th: 'ป่าร่อน',
              en: 'Pa Ron',
            },
            coordinates: {
              lat: '8.970',
              lng: '99.521',
            },
            zipcode: 84160,
          },
          840209: {
            name: {
              th: 'ตะเคียนทอง',
              en: 'Takhian Thong',
            },
            coordinates: {
              lat: '9.176',
              lng: '99.430',
            },
            zipcode: 84160,
          },
          840210: {
            name: {
              th: 'ช้างขวา',
              en: 'Chang Khwa',
            },
            coordinates: {
              lat: '9.103',
              lng: '99.442',
            },
            zipcode: 84160,
          },
          840211: {
            name: {
              th: 'ท่าอุแท',
              en: 'Tha Uthae',
            },
            coordinates: {
              lat: '9.113',
              lng: '99.639',
            },
            zipcode: 84160,
          },
          840212: {
            name: {
              th: 'ทุ่งรัง',
              en: 'Thung Rang',
            },
            coordinates: {
              lat: '9.042',
              lng: '99.403',
            },
            zipcode: 84290,
          },
          840213: {
            name: {
              th: 'คลองสระ',
              en: 'Khlong Sa',
            },
            coordinates: {
              lat: '8.976',
              lng: '99.626',
            },
            zipcode: 84160,
          },
        },
      },
      8403: {
        name: {
          th: 'ดอนสัก',
          en: 'Don Sak',
        },
        tambons: {
          840301: {
            name: {
              th: 'ดอนสัก',
              en: 'Don Sak',
            },
            coordinates: {
              lat: '9.275',
              lng: '99.707',
            },
            zipcode: 84220,
          },
          840302: {
            name: {
              th: 'ชลคราม',
              en: 'Chon Khram',
            },
            coordinates: {
              lat: '9.248',
              lng: '99.605',
            },
            zipcode: 84160,
          },
          840303: {
            name: {
              th: 'ไชยคราม',
              en: 'Chai Khram',
            },
            coordinates: {
              lat: '9.182',
              lng: '99.653',
            },
            zipcode: 84220,
          },
          840304: {
            name: {
              th: 'ปากแพรก',
              en: 'Pak Phraek',
            },
            coordinates: {
              lat: '9.134',
              lng: '99.701',
            },
            zipcode: 84340,
          },
        },
      },
      8404: {
        name: {
          th: 'เกาะสมุย',
          en: 'Ko Samui',
        },
        tambons: {
          840401: {
            name: {
              th: 'อ่างทอง',
              en: 'Ang Thong',
            },
            coordinates: {
              lat: '9.539',
              lng: '99.950',
            },
            zipcode: 84140,
          },
          840402: {
            name: {
              th: 'ลิปะน้อย',
              en: 'Lipa Noi',
            },
            coordinates: {
              lat: '9.502',
              lng: '99.959',
            },
            zipcode: 84140,
          },
          840403: {
            name: {
              th: 'ตลิ่งงาม',
              en: 'Taling Ngam',
            },
            coordinates: {
              lat: '9.453',
              lng: '99.955',
            },
            zipcode: 84140,
          },
          840404: {
            name: {
              th: 'หน้าเมือง',
              en: 'Na Mueang',
            },
            coordinates: {
              lat: '9.461',
              lng: '99.996',
            },
            zipcode: 84140,
          },
          840405: {
            name: {
              th: 'มะเร็ต',
              en: 'Maret',
            },
            coordinates: {
              lat: '9.468',
              lng: '100.032',
            },
            zipcode: 84310,
          },
          840406: {
            name: {
              th: 'บ่อผุด',
              en: 'Bo Phut',
            },
            coordinates: {
              lat: '9.527',
              lng: '100.039',
            },
            zipcode: 84320,
          },
          840407: {
            name: {
              th: 'แม่น้ำ',
              en: 'Maenam',
            },
            coordinates: {
              lat: '9.563',
              lng: '99.967',
            },
            zipcode: 84330,
          },
        },
      },
      8405: {
        name: {
          th: 'เกาะพะงัน',
          en: 'Ko Pha-ngan',
        },
        tambons: {
          840501: {
            name: {
              th: 'เกาะพะงัน',
              en: 'Ko Pha-Ngan',
            },
            coordinates: {
              lat: '9.759',
              lng: '99.999',
            },
            zipcode: 84280,
          },
          840502: {
            name: {
              th: 'บ้านใต้',
              en: 'Ban Tai',
            },
            coordinates: {
              lat: '9.723',
              lng: '100.062',
            },
            zipcode: 84280,
          },
        },
      },
      8406: {
        name: {
          th: 'ไชยา',
          en: 'Chaiya',
        },
        tambons: {
          840601: {
            name: {
              th: 'ตลาดไชยา',
              en: 'Talat Chaiya',
            },
            coordinates: {
              lat: '9.391',
              lng: '99.198',
            },
            zipcode: 84110,
          },
          840602: {
            name: {
              th: 'พุมเรียง',
              en: 'Phum Riang',
            },
            coordinates: {
              lat: '9.395',
              lng: '99.261',
            },
            zipcode: 84110,
          },
          840603: {
            name: {
              th: 'เลม็ด',
              en: 'Samet',
            },
            coordinates: {
              lat: '9.365',
              lng: '99.172',
            },
            zipcode: 84110,
          },
          840604: {
            name: {
              th: 'เวียง',
              en: 'Wiang',
            },
            coordinates: {
              lat: '9.388',
              lng: '99.148',
            },
            zipcode: 84110,
          },
          840605: {
            name: {
              th: 'ทุ่ง',
              en: 'Thung',
            },
            coordinates: {
              lat: '9.424',
              lng: '99.215',
            },
            zipcode: 84110,
          },
          840606: {
            name: {
              th: 'ป่าเว',
              en: 'Pa We',
            },
            coordinates: {
              lat: '9.447',
              lng: '99.144',
            },
            zipcode: 84110,
          },
          840607: {
            name: {
              th: 'ตะกรบ',
              en: 'Takrop',
            },
            coordinates: {
              lat: '9.464',
              lng: '99.229',
            },
            zipcode: 84110,
          },
          840608: {
            name: {
              th: 'โมถ่าย',
              en: 'Mo Thai',
            },
            coordinates: {
              lat: '9.407',
              lng: '99.084',
            },
            zipcode: 84110,
          },
          840609: {
            name: {
              th: 'ปากหมาก',
              en: 'Pak Mak',
            },
            coordinates: {
              lat: '9.477',
              lng: '98.948',
            },
            zipcode: 84110,
          },
        },
      },
      8407: {
        name: {
          th: 'ท่าชนะ',
          en: 'Tha Chana',
        },
        tambons: {
          840701: {
            name: {
              th: 'ท่าชนะ',
              en: 'Tha Chana',
            },
            coordinates: {
              lat: '9.593',
              lng: '99.183',
            },
            zipcode: 84170,
          },
          840702: {
            name: {
              th: 'สมอทอง',
              en: 'Samo Thong',
            },
            coordinates: {
              lat: '9.581',
              lng: '99.053',
            },
            zipcode: 84170,
          },
          840703: {
            name: {
              th: 'ประสงค์',
              en: 'Prasong',
            },
            coordinates: {
              lat: '9.586',
              lng: '98.851',
            },
            zipcode: 84170,
          },
          840704: {
            name: {
              th: 'คันธุลี',
              en: 'Khan Thuli',
            },
            coordinates: {
              lat: '9.661',
              lng: '99.088',
            },
            zipcode: 84170,
          },
          840705: {
            name: {
              th: 'วัง',
              en: 'Wang',
            },
            coordinates: {
              lat: '9.520',
              lng: '99.198',
            },
            zipcode: 84170,
          },
          840706: {
            name: {
              th: 'คลองพา',
              en: 'Khlong Pha',
            },
            coordinates: {
              lat: '9.685',
              lng: '98.865',
            },
            zipcode: 84170,
          },
        },
      },
      8408: {
        name: {
          th: 'คีรีรัฐนิคม',
          en: 'Khiri Rat Nikhom',
        },
        tambons: {
          840801: {
            name: {
              th: 'ท่าขนอน',
              en: 'Tha Khanon',
            },
            coordinates: {
              lat: '9.075',
              lng: '98.874',
            },
            zipcode: 84180,
          },
          840802: {
            name: {
              th: 'บ้านยาง',
              en: 'Ban Yang',
            },
            coordinates: {
              lat: '9.051',
              lng: '99.012',
            },
            zipcode: 84180,
          },
          840803: {
            name: {
              th: 'น้ำหัก',
              en: 'Nam Hak',
            },
            coordinates: {
              lat: '9.157',
              lng: '98.824',
            },
            zipcode: 84180,
          },
          840806: {
            name: {
              th: 'กะเปา',
              en: 'Kapao',
            },
            coordinates: {
              lat: '9.011',
              lng: '98.890',
            },
            zipcode: 84180,
          },
          840807: {
            name: {
              th: 'ท่ากระดาน',
              en: 'Tha Kradan',
            },
            coordinates: {
              lat: '9.063',
              lng: '99.025',
            },
            zipcode: 84180,
          },
          840808: {
            name: {
              th: 'ย่านยาว',
              en: 'Yan Yao',
            },
            coordinates: {
              lat: '8.992',
              lng: '98.977',
            },
            zipcode: 84180,
          },
          840809: {
            name: {
              th: 'ถ้ำสิงขร',
              en: 'Tham Singkhon',
            },
            coordinates: {
              lat: '8.993',
              lng: '99.038',
            },
            zipcode: 84180,
          },
          840810: {
            name: {
              th: 'บ้านทำเนียบ',
              en: 'Ban Thamniap',
            },
            coordinates: {
              lat: '8.868',
              lng: '98.968',
            },
            zipcode: 84180,
          },
        },
      },
      8409: {
        name: {
          th: 'บ้านตาขุน',
          en: 'Ban Ta Khun',
        },
        tambons: {
          840901: {
            name: {
              th: 'เขาวง',
              en: 'Khao Wong',
            },
            coordinates: {
              lat: '8.923',
              lng: '98.929',
            },
            zipcode: 84230,
          },
          840902: {
            name: {
              th: 'พะแสง',
              en: 'Phasaeng',
            },
            coordinates: {
              lat: '8.928',
              lng: '98.840',
            },
            zipcode: 84230,
          },
          840903: {
            name: {
              th: 'พรุไทย',
              en: 'Phru Thai',
            },
            coordinates: {
              lat: '8.949',
              lng: '98.896',
            },
            zipcode: 84230,
          },
          840904: {
            name: {
              th: 'เขาพัง',
              en: 'Khao Phang',
            },
            coordinates: {
              lat: '9.115',
              lng: '98.645',
            },
            zipcode: 84230,
          },
        },
      },
      8410: {
        name: {
          th: 'พนม',
          en: 'Phanom',
        },
        tambons: {
          841001: {
            name: {
              th: 'พนม',
              en: 'Phanom',
            },
            coordinates: {
              lat: '8.828',
              lng: '98.781',
            },
            zipcode: 84250,
          },
          841002: {
            name: {
              th: 'ต้นยวน',
              en: 'Ton Yuan',
            },
            coordinates: {
              lat: '8.776',
              lng: '98.900',
            },
            zipcode: 84250,
          },
          841003: {
            name: {
              th: 'คลองศก',
              en: 'Khlong Sok',
            },
            coordinates: {
              lat: '8.857',
              lng: '98.688',
            },
            zipcode: 84250,
          },
          841004: {
            name: {
              th: 'พลูเถื่อน',
              en: 'Phlu Thuean',
            },
            coordinates: {
              lat: '8.735',
              lng: '98.738',
            },
            zipcode: 84250,
          },
          841005: {
            name: {
              th: 'พังกาญจน์',
              en: 'Phang Kan',
            },
            coordinates: {
              lat: '8.878',
              lng: '98.806',
            },
            zipcode: 84250,
          },
          841006: {
            name: {
              th: 'คลองชะอุ่น',
              en: 'Khlong Cha-Un',
            },
            coordinates: {
              lat: '8.727',
              lng: '98.865',
            },
            zipcode: 84250,
          },
        },
      },
      8411: {
        name: {
          th: 'ท่าฉาง',
          en: 'Tha Chang',
        },
        tambons: {
          841101: {
            name: {
              th: 'ท่าฉาง',
              en: 'Tha Chang',
            },
            coordinates: {
              lat: '9.258',
              lng: '99.175',
            },
            zipcode: 84150,
          },
          841102: {
            name: {
              th: 'ท่าเคย',
              en: 'Tha Khoei',
            },
            coordinates: {
              lat: '9.228',
              lng: '99.181',
            },
            zipcode: 84150,
          },
          841103: {
            name: {
              th: 'คลองไทร',
              en: 'Khlong Sai',
            },
            coordinates: {
              lat: '9.200',
              lng: '99.165',
            },
            zipcode: 84150,
          },
          841104: {
            name: {
              th: 'เขาถ่าน',
              en: 'Khao Than',
            },
            coordinates: {
              lat: '9.310',
              lng: '99.188',
            },
            zipcode: 84150,
          },
          841105: {
            name: {
              th: 'เสวียด',
              en: 'Sawiat',
            },
            coordinates: {
              lat: '9.311',
              lng: '99.101',
            },
            zipcode: 84150,
          },
          841106: {
            name: {
              th: 'ปากฉลุย',
              en: 'Pak Chalui',
            },
            coordinates: {
              lat: '9.442',
              lng: '98.752',
            },
            zipcode: 84150,
          },
        },
      },
      8412: {
        name: {
          th: 'บ้านนาสาร',
          en: 'Ban Na San',
        },
        tambons: {
          841201: {
            name: {
              th: 'นาสาร',
              en: null,
            },
            coordinates: {
              lat: '8.812',
              lng: '99.359',
            },
            zipcode: 84120,
          },
          841202: {
            name: {
              th: 'พรุพี',
              en: 'Phru Phi',
            },
            coordinates: {
              lat: '8.707',
              lng: '99.391',
            },
            zipcode: 84270,
          },
          841203: {
            name: {
              th: 'ทุ่งเตา',
              en: 'Thung Tao',
            },
            coordinates: {
              lat: '8.917',
              lng: '99.357',
            },
            zipcode: 84120,
          },
          841204: {
            name: {
              th: 'ลำพูน',
              en: 'Lamphun',
            },
            coordinates: {
              lat: '8.845',
              lng: '99.461',
            },
            zipcode: 84120,
          },
          841205: {
            name: {
              th: 'ท่าชี',
              en: 'Tha Chi',
            },
            coordinates: {
              lat: '8.756',
              lng: '99.275',
            },
            zipcode: 84120,
          },
          841206: {
            name: {
              th: 'ควนศรี',
              en: 'Khuan Si',
            },
            coordinates: {
              lat: '8.713',
              lng: '99.303',
            },
            zipcode: 84270,
          },
          841207: {
            name: {
              th: 'ควนสุบรรณ',
              en: null,
            },
            coordinates: {
              lat: '8.876',
              lng: '99.367',
            },
            zipcode: 84120,
          },
          841208: {
            name: {
              th: 'คลองปราบ',
              en: 'Khlong Prap',
            },
            coordinates: {
              lat: '8.749',
              lng: '99.348',
            },
            zipcode: 84120,
          },
          841209: {
            name: {
              th: 'น้ำพุ',
              en: 'Namphu',
            },
            coordinates: {
              lat: '8.781',
              lng: '99.300',
            },
            zipcode: 84120,
          },
          841210: {
            name: {
              th: 'ทุ่งเตาใหม่',
              en: 'Thung Tao Mai',
            },
            coordinates: {
              lat: '8.952',
              lng: '99.408',
            },
            zipcode: 84120,
          },
          841211: {
            name: {
              th: 'เพิ่มพูนทรัพย์',
              en: 'Phoem Phun Sap',
            },
            coordinates: {
              lat: '8.746',
              lng: '99.462',
            },
            zipcode: 84120,
          },
        },
      },
      8413: {
        name: {
          th: 'บ้านนาเดิม',
          en: 'Ban Na Doem',
        },
        tambons: {
          841301: {
            name: {
              th: 'บ้านนา',
              en: 'Ban Na',
            },
            coordinates: {
              lat: '8.856',
              lng: '99.293',
            },
            zipcode: null,
          },
          841302: {
            name: {
              th: 'ท่าเรือ',
              en: 'Tha Ruea',
            },
            coordinates: {
              lat: '8.947',
              lng: '99.269',
            },
            zipcode: null,
          },
          841303: {
            name: {
              th: 'ทรัพย์ทวี',
              en: 'Sap Thawi',
            },
            coordinates: {
              lat: '8.914',
              lng: '99.226',
            },
            zipcode: 84240,
          },
          841304: {
            name: {
              th: 'นาใต้',
              en: 'Na Tai',
            },
            coordinates: {
              lat: '8.906',
              lng: '99.324',
            },
            zipcode: 84240,
          },
        },
      },
      8414: {
        name: {
          th: 'เคียนซา',
          en: 'Khian Sa',
        },
        tambons: {
          841401: {
            name: {
              th: 'เคียนซา',
              en: 'Khian Sa',
            },
            coordinates: {
              lat: '8.817',
              lng: '99.180',
            },
            zipcode: 84260,
          },
          841402: {
            name: {
              th: 'พ่วงพรมคร',
              en: 'Phuang Phromkhon',
            },
            coordinates: {
              lat: '8.665',
              lng: '99.227',
            },
            zipcode: 84210,
          },
          841403: {
            name: {
              th: 'เขาตอก',
              en: 'Khao Tok',
            },
            coordinates: {
              lat: '8.845',
              lng: '99.251',
            },
            zipcode: 84260,
          },
          841404: {
            name: {
              th: 'อรัญคามวารี',
              en: 'Aran Kham Wari',
            },
            coordinates: {
              lat: '8.745',
              lng: '99.190',
            },
            zipcode: 84260,
          },
          841405: {
            name: {
              th: 'บ้านเสด็จ',
              en: 'Ban Sadet',
            },
            coordinates: {
              lat: '8.736',
              lng: '99.024',
            },
            zipcode: 84260,
          },
        },
      },
      8415: {
        name: {
          th: 'เวียงสระ',
          en: 'Wiang Sa',
        },
        tambons: {
          841501: {
            name: {
              th: 'เวียงสระ',
              en: 'Wiang Sa',
            },
            coordinates: {
              lat: '8.649',
              lng: '99.342',
            },
            zipcode: 84190,
          },
          841502: {
            name: {
              th: 'บ้านส้อง',
              en: 'Ban Song',
            },
            coordinates: {
              lat: '8.645',
              lng: '99.420',
            },
            zipcode: 84190,
          },
          841503: {
            name: {
              th: 'คลองฉนวน',
              en: 'Khlong Chanuan',
            },
            coordinates: {
              lat: '8.517',
              lng: '99.321',
            },
            zipcode: 84190,
          },
          841504: {
            name: {
              th: 'ทุ่งหลวง',
              en: 'Thung Luang',
            },
            coordinates: {
              lat: '8.592',
              lng: '99.298',
            },
            zipcode: 84190,
          },
          841505: {
            name: {
              th: 'เขานิพันธ์',
              en: 'Khao Niphan',
            },
            coordinates: {
              lat: '8.584',
              lng: '99.348',
            },
            zipcode: 84190,
          },
        },
      },
      8416: {
        name: {
          th: 'พระแสง',
          en: 'Phrasaeng',
        },
        tambons: {
          841601: {
            name: {
              th: 'อิปัน',
              en: 'I Pan',
            },
            coordinates: {
              lat: '8.584',
              lng: '99.224',
            },
            zipcode: 84210,
          },
          841602: {
            name: {
              th: 'สินปุน',
              en: 'Sin Pun',
            },
            coordinates: {
              lat: '8.514',
              lng: '99.238',
            },
            zipcode: 84210,
          },
          841603: {
            name: {
              th: 'บางสวรรค์',
              en: 'Bang Sawan',
            },
            coordinates: {
              lat: '8.616',
              lng: '98.975',
            },
            zipcode: 84210,
          },
          841604: {
            name: {
              th: 'ไทรขึง',
              en: 'Sai Khueng',
            },
            coordinates: {
              lat: '8.538',
              lng: '99.063',
            },
            zipcode: 84210,
          },
          841605: {
            name: {
              th: 'สินเจริญ',
              en: 'Sin Charoen',
            },
            coordinates: {
              lat: '8.339',
              lng: '99.245',
            },
            zipcode: 84210,
          },
          841606: {
            name: {
              th: 'ไทรโสภา',
              en: 'Sai Sopha',
            },
            coordinates: {
              lat: '8.597',
              lng: '99.066',
            },
            zipcode: 84210,
          },
          841607: {
            name: {
              th: 'สาคู',
              en: 'Sakhu',
            },
            coordinates: {
              lat: '8.590',
              lng: '99.166',
            },
            zipcode: 84210,
          },
        },
      },
      8417: {
        name: {
          th: 'พุนพิน',
          en: 'Phunphin',
        },
        tambons: {
          841701: {
            name: {
              th: 'ท่าข้าม',
              en: 'Tha Kham',
            },
            coordinates: {
              lat: '9.062',
              lng: '99.218',
            },
            zipcode: 84130,
          },
          841702: {
            name: {
              th: 'ท่าสะท้อน',
              en: 'Tha Sathon',
            },
            coordinates: {
              lat: '8.994',
              lng: '99.227',
            },
            zipcode: 84130,
          },
          841703: {
            name: {
              th: 'ลีเล็ด',
              en: 'Li Let',
            },
            coordinates: {
              lat: '9.203',
              lng: '99.242',
            },
            zipcode: 84130,
          },
          841704: {
            name: {
              th: 'บางมะเดื่อ',
              en: 'Bang Maduea',
            },
            coordinates: {
              lat: '9.031',
              lng: '99.123',
            },
            zipcode: 84130,
          },
          841705: {
            name: {
              th: 'บางเดือน',
              en: 'Bang Duean',
            },
            coordinates: {
              lat: '9.026',
              lng: '99.087',
            },
            zipcode: 84130,
          },
          841706: {
            name: {
              th: 'ท่าโรงช้าง',
              en: 'Tha Rong Chang',
            },
            coordinates: {
              lat: '9.031',
              lng: '99.175',
            },
            zipcode: 84130,
          },
          841707: {
            name: {
              th: 'กรูด',
              en: 'Krut',
            },
            coordinates: {
              lat: '8.949',
              lng: '99.126',
            },
            zipcode: null,
          },
          841708: {
            name: {
              th: 'พุนพิน',
              en: 'Phunphin',
            },
            coordinates: {
              lat: '9.125',
              lng: '99.199',
            },
            zipcode: 84130,
          },
          841709: {
            name: {
              th: 'บางงอน',
              en: 'Bang Ngon',
            },
            coordinates: {
              lat: '9.116',
              lng: '99.060',
            },
            zipcode: 84130,
          },
          841710: {
            name: {
              th: 'ศรีวิชัย',
              en: 'Si Wichai',
            },
            coordinates: {
              lat: '9.162',
              lng: '99.213',
            },
            zipcode: 84130,
          },
          841711: {
            name: {
              th: 'น้ำรอบ',
              en: 'Nam Rop',
            },
            coordinates: {
              lat: '9.099',
              lng: '99.112',
            },
            zipcode: 84130,
          },
          841712: {
            name: {
              th: 'มะลวน',
              en: 'Maluan',
            },
            coordinates: {
              lat: '9.166',
              lng: '99.106',
            },
            zipcode: 84130,
          },
          841713: {
            name: {
              th: 'หัวเตย',
              en: 'Hua Toei',
            },
            coordinates: {
              lat: '9.136',
              lng: '99.163',
            },
            zipcode: 84130,
          },
          841714: {
            name: {
              th: 'หนองไทร',
              en: 'Nong Sai',
            },
            coordinates: {
              lat: '9.096',
              lng: '99.159',
            },
            zipcode: 84130,
          },
          841715: {
            name: {
              th: 'เขาหัวควาย',
              en: 'Khao Hua Khwai',
            },
            coordinates: {
              lat: '9.039',
              lng: '99.267',
            },
            zipcode: 84130,
          },
          841716: {
            name: {
              th: 'ตะปาน',
              en: 'Tapan',
            },
            coordinates: {
              lat: '8.866',
              lng: '99.087',
            },
            zipcode: 84130,
          },
        },
      },
      8418: {
        name: {
          th: 'ชัยบุรี',
          en: 'Chai Buri',
        },
        tambons: {
          841801: {
            name: {
              th: 'สองแพรก',
              en: 'Song Phraek',
            },
            coordinates: {
              lat: '8.483',
              lng: '99.125',
            },
            zipcode: null,
          },
          841802: {
            name: {
              th: 'ชัยบุรี',
              en: 'Chai Buri',
            },
            coordinates: {
              lat: '8.434',
              lng: '99.071',
            },
            zipcode: null,
          },
          841803: {
            name: {
              th: 'คลองน้อย',
              en: 'Khlong Noi',
            },
            coordinates: {
              lat: '8.503',
              lng: '98.997',
            },
            zipcode: null,
          },
          841804: {
            name: {
              th: 'ไทรทอง',
              en: 'Sai Thong',
            },
            coordinates: {
              lat: '8.389',
              lng: '99.073',
            },
            zipcode: 84350,
          },
        },
      },
      8419: {
        name: {
          th: 'วิภาวดี',
          en: 'Chai Buri',
        },
        tambons: {
          841901: {
            name: {
              th: 'ตะกุกใต้',
              en: 'Takuk Tai',
            },
            coordinates: {
              lat: '9.227',
              lng: '98.807',
            },
            zipcode: null,
          },
          841902: {
            name: {
              th: 'ตะกุกเหนือ',
              en: 'Takuk Nuea',
            },
            coordinates: {
              lat: '9.252',
              lng: '98.909',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  85: {
    name: {
      th: 'ระนอง',
      en: 'Ranong',
    },
    amphoes: {
      8501: {
        name: {
          th: 'เมืองระนอง',
          en: 'Mueang Ranong',
        },
        tambons: {
          850101: {
            name: {
              th: 'เขานิเวศน์',
              en: 'Khao Niwet',
            },
            coordinates: {
              lat: '9.960',
              lng: '98.621',
            },
            zipcode: 85000,
          },
          850102: {
            name: {
              th: 'ราชกรูด',
              en: 'Ratchakrut',
            },
            coordinates: {
              lat: '9.721',
              lng: '98.578',
            },
            zipcode: 85000,
          },
          850103: {
            name: {
              th: 'หงาว',
              en: 'Ngao',
            },
            coordinates: {
              lat: '9.857',
              lng: '98.589',
            },
            zipcode: 85000,
          },
          850104: {
            name: {
              th: 'บางริ้น',
              en: 'Bang Rin',
            },
            coordinates: {
              lat: '9.909',
              lng: '98.644',
            },
            zipcode: 85000,
          },
          850105: {
            name: {
              th: 'ปากน้ำ',
              en: 'Pak Nam',
            },
            coordinates: {
              lat: '10.060',
              lng: '98.647',
            },
            zipcode: 85000,
          },
          850106: {
            name: {
              th: 'บางนอน',
              en: 'Bang Non',
            },
            coordinates: {
              lat: '10.005',
              lng: '98.647',
            },
            zipcode: 85000,
          },
          850107: {
            name: {
              th: 'หาดส้มแป้น',
              en: 'Hat Som Paen',
            },
            coordinates: {
              lat: '9.916',
              lng: '98.699',
            },
            zipcode: 85000,
          },
          850108: {
            name: {
              th: 'ทรายแดง',
              en: 'Sai Daeng',
            },
            coordinates: {
              lat: '10.130',
              lng: '98.680',
            },
            zipcode: 85130,
          },
          850109: {
            name: {
              th: 'เกาะพยาม',
              en: 'Ko Phayam',
            },
            coordinates: {
              lat: '9.833',
              lng: '98.450',
            },
            zipcode: 85000,
          },
        },
      },
      8502: {
        name: {
          th: 'ละอุ่น',
          en: 'La-un',
        },
        tambons: {
          850201: {
            name: {
              th: 'ละอุ่นใต้',
              en: 'La-Un Tai',
            },
            coordinates: {
              lat: '10.124',
              lng: '98.769',
            },
            zipcode: 85130,
          },
          850202: {
            name: {
              th: 'ละอุ่นเหนือ',
              en: 'La-Un Nuea',
            },
            coordinates: {
              lat: '10.027',
              lng: '98.806',
            },
            zipcode: 85130,
          },
          850203: {
            name: {
              th: 'บางพระใต้',
              en: 'Bang Phra Tai',
            },
            coordinates: {
              lat: '10.126',
              lng: '98.730',
            },
            zipcode: 85130,
          },
          850204: {
            name: {
              th: 'บางพระเหนือ',
              en: 'Bang Phra Nuea',
            },
            coordinates: {
              lat: '10.022',
              lng: '98.731',
            },
            zipcode: 85130,
          },
          850205: {
            name: {
              th: 'บางแก้ว',
              en: 'Bang Kaeo',
            },
            coordinates: {
              lat: '10.179',
              lng: '98.780',
            },
            zipcode: 85130,
          },
          850206: {
            name: {
              th: 'ในวงเหนือ',
              en: 'Nai Wong Nuea',
            },
            coordinates: {
              lat: '10.020',
              lng: '98.842',
            },
            zipcode: 85130,
          },
          850207: {
            name: {
              th: 'ในวงใต้',
              en: 'Nai Wong Tai',
            },
            coordinates: {
              lat: '10.029',
              lng: '98.884',
            },
            zipcode: 85130,
          },
        },
      },
      8503: {
        name: {
          th: 'กะเปอร์',
          en: 'Papoe',
        },
        tambons: {
          850301: {
            name: {
              th: 'ม่วงกลวง',
              en: 'Muang Kluang',
            },
            coordinates: {
              lat: '9.606',
              lng: '98.515',
            },
            zipcode: 85120,
          },
          850302: {
            name: {
              th: 'กะเปอร์',
              en: 'Kapoe',
            },
            coordinates: {
              lat: '9.623',
              lng: '98.621',
            },
            zipcode: 85120,
          },
          850303: {
            name: {
              th: 'เชี่ยวเหลียง',
              en: 'Chiao Liang',
            },
            coordinates: {
              lat: '9.562',
              lng: '98.664',
            },
            zipcode: 85120,
          },
          850304: {
            name: {
              th: 'บ้านนา',
              en: 'Ban Na',
            },
            coordinates: {
              lat: '9.553',
              lng: '98.740',
            },
            zipcode: 85120,
          },
          850305: {
            name: {
              th: 'บางหิน',
              en: 'Bang Hin',
            },
            coordinates: {
              lat: '9.525',
              lng: '98.581',
            },
            zipcode: 85120,
          },
        },
      },
      8504: {
        name: {
          th: 'กระบุรี',
          en: 'Kra Buri',
        },
        tambons: {
          850401: {
            name: {
              th: 'น้ำจืด',
              en: 'Nam Chuet',
            },
            coordinates: {
              lat: '10.394',
              lng: '98.842',
            },
            zipcode: 85110,
          },
          850402: {
            name: {
              th: 'น้ำจืดน้อย',
              en: 'Nam Chuet Noi',
            },
            coordinates: {
              lat: '10.427',
              lng: '98.793',
            },
            zipcode: 85110,
          },
          850403: {
            name: {
              th: 'มะมุ',
              en: 'Mamu',
            },
            coordinates: {
              lat: '10.457',
              lng: '98.846',
            },
            zipcode: 85110,
          },
          850404: {
            name: {
              th: 'ปากจั่น',
              en: 'Pak Chan',
            },
            coordinates: {
              lat: '10.575',
              lng: '98.832',
            },
            zipcode: 85110,
          },
          850405: {
            name: {
              th: 'ลำเลียง',
              en: 'Lamliang',
            },
            coordinates: {
              lat: '10.287',
              lng: '98.842',
            },
            zipcode: 85110,
          },
          850406: {
            name: {
              th: 'จ.ป.ร.',
              en: 'Cho.Po.Ro.',
            },
            coordinates: {
              lat: '10.648',
              lng: '98.883',
            },
            zipcode: 85110,
          },
          850407: {
            name: {
              th: 'บางใหญ่',
              en: 'Bang Yai',
            },
            coordinates: {
              lat: '10.265',
              lng: '98.757',
            },
            zipcode: 85110,
          },
        },
      },
      8505: {
        name: {
          th: 'สุขสำราญ',
          en: 'Suk Samran',
        },
        tambons: {
          850501: {
            name: {
              th: 'นาคา',
              en: 'Nakha',
            },
            coordinates: {
              lat: '9.424',
              lng: '98.540',
            },
            zipcode: null,
          },
          850502: {
            name: {
              th: 'กำพวน',
              en: 'Kamphuan',
            },
            coordinates: {
              lat: '9.364',
              lng: '98.424',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  86: {
    name: {
      th: 'ชุมพร',
      en: 'Chumphon',
    },
    amphoes: {
      8601: {
        name: {
          th: 'เมืองชุมพร',
          en: 'Mueang Chumphon',
        },
        tambons: {
          860101: {
            name: {
              th: 'ท่าตะเภา',
              en: 'Tha Taphao',
            },
            coordinates: {
              lat: '10.498',
              lng: '99.179',
            },
            zipcode: 86000,
          },
          860102: {
            name: {
              th: 'ปากน้ำ',
              en: 'Pak Nam',
            },
            coordinates: {
              lat: '10.414',
              lng: '99.245',
            },
            zipcode: 86120,
          },
          860103: {
            name: {
              th: 'ท่ายาง',
              en: 'Tha Yang',
            },
            coordinates: {
              lat: '10.434',
              lng: '99.217',
            },
            zipcode: 86000,
          },
          860104: {
            name: {
              th: 'บางหมาก',
              en: 'Bang Mak',
            },
            coordinates: {
              lat: '10.449',
              lng: '99.185',
            },
            zipcode: 86000,
          },
          860105: {
            name: {
              th: 'นาทุ่ง',
              en: 'Na Thung',
            },
            coordinates: {
              lat: '10.500',
              lng: '99.196',
            },
            zipcode: 86000,
          },
          860106: {
            name: {
              th: 'นาชะอัง',
              en: 'Na Cha-Ang',
            },
            coordinates: {
              lat: '10.540',
              lng: '99.227',
            },
            zipcode: 86000,
          },
          860107: {
            name: {
              th: 'ตากแดด',
              en: 'Tak Daet',
            },
            coordinates: {
              lat: '10.467',
              lng: '99.153',
            },
            zipcode: 86000,
          },
          860108: {
            name: {
              th: 'บางลึก',
              en: 'Bang Luek',
            },
            coordinates: {
              lat: '10.551',
              lng: '99.185',
            },
            zipcode: 86000,
          },
          860109: {
            name: {
              th: 'หาดพันไกร',
              en: 'Hat Phan Krai',
            },
            coordinates: {
              lat: '10.561',
              lng: '99.143',
            },
            zipcode: 86000,
          },
          860110: {
            name: {
              th: 'วังไผ่',
              en: 'Wang Phai',
            },
            coordinates: {
              lat: '10.525',
              lng: '99.123',
            },
            zipcode: 86000,
          },
          860111: {
            name: {
              th: 'วังใหม่',
              en: 'Wang Mai',
            },
            coordinates: {
              lat: '10.523',
              lng: '99.054',
            },
            zipcode: 86190,
          },
          860112: {
            name: {
              th: 'บ้านนา',
              en: 'Ban Na',
            },
            coordinates: {
              lat: '10.437',
              lng: '98.952',
            },
            zipcode: 86190,
          },
          860113: {
            name: {
              th: 'ขุนกระทิง',
              en: 'Khun Krathing',
            },
            coordinates: {
              lat: '10.457',
              lng: '99.104',
            },
            zipcode: 86190,
          },
          860114: {
            name: {
              th: 'ทุ่งคา',
              en: 'Thung Kha',
            },
            coordinates: {
              lat: '10.398',
              lng: '99.140',
            },
            zipcode: 86100,
          },
          860115: {
            name: {
              th: 'วิสัยเหนือ',
              en: 'Wisai Nuea',
            },
            coordinates: {
              lat: '10.367',
              lng: '99.090',
            },
            zipcode: 86100,
          },
          860116: {
            name: {
              th: 'หาดทรายรี',
              en: 'Hat Sai Ri',
            },
            coordinates: {
              lat: '10.369',
              lng: '99.233',
            },
            zipcode: 86120,
          },
          860117: {
            name: {
              th: 'ถ้ำสิงห์',
              en: 'Tham Sing',
            },
            coordinates: {
              lat: '10.418',
              lng: '99.058',
            },
            zipcode: 86100,
          },
        },
      },
      8602: {
        name: {
          th: 'ท่าแซะ',
          en: 'Tha Sae',
        },
        tambons: {
          860201: {
            name: {
              th: 'ท่าแซะ',
              en: 'Tha Sae',
            },
            coordinates: {
              lat: '10.663',
              lng: '99.166',
            },
            zipcode: 86140,
          },
          860202: {
            name: {
              th: 'คุริง',
              en: 'Khu Ring',
            },
            coordinates: {
              lat: '10.744',
              lng: '99.118',
            },
            zipcode: 86140,
          },
          860203: {
            name: {
              th: 'สลุย',
              en: 'Salui',
            },
            coordinates: {
              lat: '10.835',
              lng: '99.233',
            },
            zipcode: 86140,
          },
          860204: {
            name: {
              th: 'นากระตาม',
              en: 'Na Kratam',
            },
            coordinates: {
              lat: '10.606',
              lng: '99.184',
            },
            zipcode: 86140,
          },
          860205: {
            name: {
              th: 'รับร่อ',
              en: 'Rap Ro',
            },
            coordinates: {
              lat: '10.768',
              lng: '99.011',
            },
            zipcode: 86190,
          },
          860206: {
            name: {
              th: 'ท่าข้าม',
              en: 'Tha Kham',
            },
            coordinates: {
              lat: '10.660',
              lng: '99.105',
            },
            zipcode: 86140,
          },
          860207: {
            name: {
              th: 'หงษ์เจริญ',
              en: 'Hong Charoen',
            },
            coordinates: {
              lat: '10.808',
              lng: '99.134',
            },
            zipcode: 86140,
          },
          860208: {
            name: {
              th: 'หินแก้ว',
              en: 'Hin Kaeo',
            },
            coordinates: {
              lat: '10.622',
              lng: '98.992',
            },
            zipcode: 86190,
          },
          860209: {
            name: {
              th: 'ทรัพย์อนันต์',
              en: 'Sap Anan',
            },
            coordinates: {
              lat: '10.713',
              lng: '99.202',
            },
            zipcode: 86140,
          },
          860210: {
            name: {
              th: 'สองพี่น้อง',
              en: 'Song Phi Nong',
            },
            coordinates: {
              lat: '10.949',
              lng: '99.189',
            },
            zipcode: 86140,
          },
        },
      },
      8603: {
        name: {
          th: 'ปะทิว',
          en: 'Pathio',
        },
        tambons: {
          860301: {
            name: {
              th: 'บางสน',
              en: 'Bang Son',
            },
            coordinates: {
              lat: '10.658',
              lng: '99.264',
            },
            zipcode: 86160,
          },
          860302: {
            name: {
              th: 'ทะเลทรัพย์',
              en: 'Thale Sap',
            },
            coordinates: {
              lat: '10.727',
              lng: '99.267',
            },
            zipcode: 86160,
          },
          860303: {
            name: {
              th: 'สะพลี',
              en: 'Saphli',
            },
            coordinates: {
              lat: '10.608',
              lng: '99.250',
            },
            zipcode: 86230,
          },
          860304: {
            name: {
              th: 'ชุมโค',
              en: 'Chum Kho',
            },
            coordinates: {
              lat: '10.767',
              lng: '99.336',
            },
            zipcode: 86160,
          },
          860305: {
            name: {
              th: 'ดอนยาง',
              en: 'Don Yang',
            },
            coordinates: {
              lat: '10.861',
              lng: '99.354',
            },
            zipcode: 86210,
          },
          860306: {
            name: {
              th: 'ปากคลอง',
              en: 'Pak Khlong',
            },
            coordinates: {
              lat: '10.910',
              lng: '99.463',
            },
            zipcode: 86210,
          },
          860307: {
            name: {
              th: 'เขาไชยราช',
              en: 'Khao Chaiyarat',
            },
            coordinates: {
              lat: '10.939',
              lng: '99.349',
            },
            zipcode: 86210,
          },
        },
      },
      8604: {
        name: {
          th: 'หลังสวน',
          en: 'Lang Suan',
        },
        tambons: {
          860401: {
            name: {
              th: 'หลังสวน',
              en: 'Lang Suan',
            },
            coordinates: {
              lat: '9.950',
              lng: '99.090',
            },
            zipcode: 86110,
          },
          860402: {
            name: {
              th: 'ขันเงิน',
              en: 'Khan Ngoen',
            },
            coordinates: {
              lat: '9.934',
              lng: '99.074',
            },
            zipcode: 86110,
          },
          860403: {
            name: {
              th: 'ท่ามะพลา',
              en: 'Tha Maphla',
            },
            coordinates: {
              lat: '9.929',
              lng: '99.045',
            },
            zipcode: 86110,
          },
          860404: {
            name: {
              th: 'นาขา',
              en: 'Nakha',
            },
            coordinates: {
              lat: '10.009',
              lng: '99.081',
            },
            zipcode: 86110,
          },
          860405: {
            name: {
              th: 'นาพญา',
              en: 'Na Phaya',
            },
            coordinates: {
              lat: '9.872',
              lng: '99.120',
            },
            zipcode: 86110,
          },
          860406: {
            name: {
              th: 'บ้านควน',
              en: 'Ban Khuan',
            },
            coordinates: {
              lat: '9.850',
              lng: '99.028',
            },
            zipcode: 86110,
          },
          860407: {
            name: {
              th: 'บางมะพร้าว',
              en: 'Bang Maphrao',
            },
            coordinates: {
              lat: '9.918',
              lng: '99.147',
            },
            zipcode: 86110,
          },
          860408: {
            name: {
              th: 'บางน้ำจืด',
              en: 'Bang Nam Chuet',
            },
            coordinates: {
              lat: '10.022',
              lng: '99.142',
            },
            zipcode: 86150,
          },
          860409: {
            name: {
              th: 'ปากน้ำ',
              en: 'Pak Nam',
            },
            coordinates: {
              lat: '9.955',
              lng: '99.151',
            },
            zipcode: null,
          },
          860410: {
            name: {
              th: 'พ้อแดง',
              en: 'Pho Daeng',
            },
            coordinates: {
              lat: '9.926',
              lng: '99.099',
            },
            zipcode: 86110,
          },
          860411: {
            name: {
              th: 'แหลมทราย',
              en: 'Laem Sai',
            },
            coordinates: {
              lat: '9.962',
              lng: '99.118',
            },
            zipcode: 86110,
          },
          860412: {
            name: {
              th: 'วังตะกอ',
              en: 'Wang Tako',
            },
            coordinates: {
              lat: '9.986',
              lng: '98.984',
            },
            zipcode: 86110,
          },
          860413: {
            name: {
              th: 'หาดยาย',
              en: 'Hat Yai',
            },
            coordinates: {
              lat: '9.960',
              lng: '98.956',
            },
            zipcode: 86110,
          },
        },
      },
      8605: {
        name: {
          th: 'ละแม',
          en: 'Lamae',
        },
        tambons: {
          860501: {
            name: {
              th: 'ละแม',
              en: 'Lamae',
            },
            coordinates: {
              lat: '9.752',
              lng: '98.945',
            },
            zipcode: 86170,
          },
          860502: {
            name: {
              th: 'ทุ่งหลวง',
              en: 'Thung Luang',
            },
            coordinates: {
              lat: '9.800',
              lng: '99.065',
            },
            zipcode: 86170,
          },
          860503: {
            name: {
              th: 'สวนแตง',
              en: 'Suan Taeng',
            },
            coordinates: {
              lat: '9.723',
              lng: '99.108',
            },
            zipcode: 86170,
          },
          860504: {
            name: {
              th: 'ทุ่งคาวัด',
              en: 'Thung Kha Wat',
            },
            coordinates: {
              lat: '9.748',
              lng: '99.000',
            },
            zipcode: 86170,
          },
        },
      },
      8606: {
        name: {
          th: 'พะโต๊ะ',
          en: 'Phato',
        },
        tambons: {
          860601: {
            name: {
              th: 'พะโต๊ะ',
              en: 'Phato',
            },
            coordinates: {
              lat: '9.816',
              lng: '98.800',
            },
            zipcode: 86180,
          },
          860602: {
            name: {
              th: 'ปากทรง',
              en: 'Pak Song',
            },
            coordinates: {
              lat: '9.741',
              lng: '98.703',
            },
            zipcode: 86180,
          },
          860603: {
            name: {
              th: 'ปังหวาน',
              en: 'Pang Wan',
            },
            coordinates: {
              lat: '9.951',
              lng: '98.866',
            },
            zipcode: 86180,
          },
          860604: {
            name: {
              th: 'พระรักษ์',
              en: 'Phrarak',
            },
            coordinates: {
              lat: '9.852',
              lng: '98.923',
            },
            zipcode: 86180,
          },
        },
      },
      8607: {
        name: {
          th: 'สวี',
          en: 'Sawi',
        },
        tambons: {
          860701: {
            name: {
              th: 'นาโพธิ์',
              en: 'Na Pho',
            },
            coordinates: {
              lat: '10.198',
              lng: '99.107',
            },
            zipcode: 86130,
          },
          860702: {
            name: {
              th: 'สวี',
              en: 'Sawi',
            },
            coordinates: {
              lat: '10.247',
              lng: '99.081',
            },
            zipcode: 86130,
          },
          860703: {
            name: {
              th: 'ทุ่งระยะ',
              en: 'Thung Raya',
            },
            coordinates: {
              lat: '10.277',
              lng: '98.945',
            },
            zipcode: 86130,
          },
          860704: {
            name: {
              th: 'ท่าหิน',
              en: 'Tha Hin',
            },
            coordinates: {
              lat: '10.213',
              lng: '99.151',
            },
            zipcode: 86130,
          },
          860705: {
            name: {
              th: 'ปากแพรก',
              en: 'Pak Phraek',
            },
            coordinates: {
              lat: '10.291',
              lng: '99.148',
            },
            zipcode: 86130,
          },
          860706: {
            name: {
              th: 'ด่านสวี',
              en: 'Dan Sawi',
            },
            coordinates: {
              lat: '10.243',
              lng: '99.191',
            },
            zipcode: 86130,
          },
          860707: {
            name: {
              th: 'ครน',
              en: 'Khron',
            },
            coordinates: {
              lat: '10.311',
              lng: '99.063',
            },
            zipcode: 86130,
          },
          860708: {
            name: {
              th: 'วิสัยใต้',
              en: 'Wisai Tai',
            },
            coordinates: {
              lat: '10.360',
              lng: '99.040',
            },
            zipcode: 86130,
          },
          860709: {
            name: {
              th: 'นาสัก',
              en: 'Na Sak',
            },
            coordinates: {
              lat: '10.177',
              lng: '99.000',
            },
            zipcode: 86130,
          },
          860710: {
            name: {
              th: 'เขาทะลุ',
              en: 'Khao Thalu',
            },
            coordinates: {
              lat: '10.197',
              lng: '98.915',
            },
            zipcode: 86130,
          },
          860711: {
            name: {
              th: 'เขาค่าย',
              en: 'Khao Khai',
            },
            coordinates: {
              lat: '10.104',
              lng: '98.891',
            },
            zipcode: 86130,
          },
        },
      },
      8608: {
        name: {
          th: 'ทุ่งตะโก',
          en: 'Thung Tako',
        },
        tambons: {
          860801: {
            name: {
              th: 'ปากตะโก',
              en: 'Pak Tako',
            },
            coordinates: {
              lat: '10.081',
              lng: '99.142',
            },
            zipcode: 86220,
          },
          860802: {
            name: {
              th: 'ทุ่งตะไคร',
              en: 'Thung Takhrai',
            },
            coordinates: {
              lat: '10.131',
              lng: '99.097',
            },
            zipcode: 86220,
          },
          860803: {
            name: {
              th: 'ตะโก',
              en: 'Tako',
            },
            coordinates: {
              lat: '10.056',
              lng: '98.974',
            },
            zipcode: 86220,
          },
          860804: {
            name: {
              th: 'ช่องไม้แก้ว',
              en: 'Chong Mai Kaeo',
            },
            coordinates: {
              lat: '10.117',
              lng: '99.024',
            },
            zipcode: 86220,
          },
        },
      },
    },
  },
  90: {
    name: {
      th: 'สงขลา',
      en: 'Songkhla',
    },
    amphoes: {
      9001: {
        name: {
          th: 'เมืองสงขลา',
          en: 'Muaeng Songkhla',
        },
        tambons: {
          900101: {
            name: {
              th: 'บ่อยาง',
              en: 'Bo Yang',
            },
            coordinates: {
              lat: '7.198',
              lng: '100.596',
            },
            zipcode: 90000,
          },
          900102: {
            name: {
              th: 'เขารูปช้าง',
              en: 'Khao Rupchang',
            },
            coordinates: {
              lat: '7.154',
              lng: '100.612',
            },
            zipcode: 90000,
          },
          900103: {
            name: {
              th: 'เกาะแต้ว',
              en: 'Ko Taeo',
            },
            coordinates: {
              lat: '7.112',
              lng: '100.638',
            },
            zipcode: 90000,
          },
          900104: {
            name: {
              th: 'พะวง',
              en: 'Pha Wong',
            },
            coordinates: {
              lat: '7.105',
              lng: '100.588',
            },
            zipcode: 90100,
          },
          900105: {
            name: {
              th: 'ทุ่งหวัง',
              en: 'Thung Wang',
            },
            coordinates: {
              lat: '7.069',
              lng: '100.657',
            },
            zipcode: 90000,
          },
          900106: {
            name: {
              th: 'เกาะยอ',
              en: 'Ko Yo',
            },
            coordinates: {
              lat: '7.171',
              lng: '100.517',
            },
            zipcode: 90100,
          },
        },
      },
      9002: {
        name: {
          th: 'สทิงพระ',
          en: 'Sathing Phra',
        },
        tambons: {
          900201: {
            name: {
              th: 'จะทิ้งพระ',
              en: 'Cha Thing Phra',
            },
            coordinates: {
              lat: '7.469',
              lng: '100.437',
            },
            zipcode: 90190,
          },
          900202: {
            name: {
              th: 'กระดังงา',
              en: 'Kradang Nga',
            },
            coordinates: {
              lat: '7.509',
              lng: '100.427',
            },
            zipcode: 90190,
          },
          900203: {
            name: {
              th: 'สนามชัย',
              en: 'Sanam Chai',
            },
            coordinates: {
              lat: '7.547',
              lng: '100.417',
            },
            zipcode: 90190,
          },
          900204: {
            name: {
              th: 'ดีหลวง',
              en: 'Di Luang',
            },
            coordinates: {
              lat: '7.580',
              lng: '100.406',
            },
            zipcode: 90190,
          },
          900205: {
            name: {
              th: 'ชุมพล',
              en: 'Chum Phon',
            },
            coordinates: {
              lat: '7.603',
              lng: '100.385',
            },
            zipcode: 90190,
          },
          900206: {
            name: {
              th: 'คลองรี',
              en: 'Khlong Re',
            },
            coordinates: {
              lat: '7.542',
              lng: '100.388',
            },
            zipcode: 90190,
          },
          900207: {
            name: {
              th: 'คูขุด',
              en: 'Khu Khut',
            },
            coordinates: {
              lat: '7.458',
              lng: '100.406',
            },
            zipcode: 90190,
          },
          900208: {
            name: {
              th: 'ท่าหิน',
              en: 'Tha Hin',
            },
            coordinates: {
              lat: '7.395',
              lng: '100.419',
            },
            zipcode: 90190,
          },
          900209: {
            name: {
              th: 'วัดจันทร์',
              en: 'Wat Chan',
            },
            coordinates: {
              lat: '7.382',
              lng: '100.461',
            },
            zipcode: 90190,
          },
          900210: {
            name: {
              th: 'บ่อแดง',
              en: 'Bodaeng',
            },
            coordinates: {
              lat: '7.406',
              lng: '100.454',
            },
            zipcode: 90190,
          },
          900211: {
            name: {
              th: 'บ่อดาน',
              en: 'Bodan',
            },
            coordinates: {
              lat: '7.435',
              lng: '100.447',
            },
            zipcode: 90190,
          },
        },
      },
      9003: {
        name: {
          th: 'จะนะ',
          en: 'Chana',
        },
        tambons: {
          900301: {
            name: {
              th: 'บ้านนา',
              en: 'Ban Na',
            },
            coordinates: {
              lat: '6.901',
              lng: '100.742',
            },
            zipcode: 90130,
          },
          900302: {
            name: {
              th: 'ป่าชิง',
              en: 'Pa Ching',
            },
            coordinates: {
              lat: '6.939',
              lng: '100.686',
            },
            zipcode: 90130,
          },
          900303: {
            name: {
              th: 'สะพานไม้แก่น',
              en: 'Saphan Mai Kaen',
            },
            coordinates: {
              lat: '6.826',
              lng: '100.783',
            },
            zipcode: 90130,
          },
          900304: {
            name: {
              th: 'สะกอม',
              en: 'Sa Kom',
            },
            coordinates: {
              lat: '6.935',
              lng: '100.794',
            },
            zipcode: 90150,
          },
          900305: {
            name: {
              th: 'นาหว้า',
              en: 'Na Wa',
            },
            coordinates: {
              lat: '6.896',
              lng: '100.655',
            },
            zipcode: 90130,
          },
          900306: {
            name: {
              th: 'นาทับ',
              en: 'Na Thap',
            },
            coordinates: {
              lat: '7.035',
              lng: '100.700',
            },
            zipcode: 90130,
          },
          900307: {
            name: {
              th: 'น้ำขาว',
              en: 'Nam Khao',
            },
            coordinates: {
              lat: '6.805',
              lng: '100.609',
            },
            zipcode: 90130,
          },
          900308: {
            name: {
              th: 'ขุนตัดหวาย',
              en: 'Khun Tat Wai',
            },
            coordinates: {
              lat: '6.794',
              lng: '100.700',
            },
            zipcode: 90130,
          },
          900309: {
            name: {
              th: 'ท่าหมอไทร',
              en: 'Tha Mo Sai',
            },
            coordinates: {
              lat: '6.794',
              lng: '100.751',
            },
            zipcode: 90130,
          },
          900310: {
            name: {
              th: 'จะโหนง',
              en: 'Cha Nong',
            },
            coordinates: {
              lat: '7.006',
              lng: '100.670',
            },
            zipcode: 90130,
          },
          900311: {
            name: {
              th: 'คู',
              en: 'Khu',
            },
            coordinates: {
              lat: '6.842',
              lng: '100.695',
            },
            zipcode: 90130,
          },
          900312: {
            name: {
              th: 'แค',
              en: 'Khae',
            },
            coordinates: {
              lat: '6.853',
              lng: '100.618',
            },
            zipcode: 90130,
          },
          900313: {
            name: {
              th: 'คลองเปียะ',
              en: 'Khlong Pia',
            },
            coordinates: {
              lat: '6.952',
              lng: '100.632',
            },
            zipcode: 90130,
          },
          900314: {
            name: {
              th: 'ตลิ่งชัน',
              en: 'Taling Chan',
            },
            coordinates: {
              lat: '6.969',
              lng: '100.742',
            },
            zipcode: 90130,
          },
        },
      },
      9004: {
        name: {
          th: 'นาทวี',
          en: 'Na Thawi',
        },
        tambons: {
          900401: {
            name: {
              th: 'นาทวี',
              en: 'Na Thawi',
            },
            coordinates: {
              lat: '6.693',
              lng: '100.777',
            },
            zipcode: 90160,
          },
          900402: {
            name: {
              th: 'ฉาง',
              en: 'Chang',
            },
            coordinates: {
              lat: '6.762',
              lng: '100.709',
            },
            zipcode: 90160,
          },
          900403: {
            name: {
              th: 'นาหมอศรี',
              en: 'Na Mosri',
            },
            coordinates: {
              lat: '6.781',
              lng: '100.670',
            },
            zipcode: 90160,
          },
          900404: {
            name: {
              th: 'คลองทราย',
              en: 'Khlong Sai',
            },
            coordinates: {
              lat: '6.741',
              lng: '100.603',
            },
            zipcode: 90160,
          },
          900405: {
            name: {
              th: 'ปลักหนู',
              en: 'Plak Nu',
            },
            coordinates: {
              lat: '6.676',
              lng: '100.663',
            },
            zipcode: 90160,
          },
          900406: {
            name: {
              th: 'ท่าประดู่',
              en: 'Tha Pradu',
            },
            coordinates: {
              lat: '6.681',
              lng: '100.730',
            },
            zipcode: 90160,
          },
          900407: {
            name: {
              th: 'สะท้อน',
              en: 'Sa Thon',
            },
            coordinates: {
              lat: '6.622',
              lng: '100.673',
            },
            zipcode: 90160,
          },
          900408: {
            name: {
              th: 'ทับช้าง',
              en: 'Thap Chang',
            },
            coordinates: {
              lat: '6.586',
              lng: '100.695',
            },
            zipcode: 90160,
          },
          900409: {
            name: {
              th: 'ประกอบ',
              en: 'Prakop',
            },
            coordinates: {
              lat: '6.483',
              lng: '100.643',
            },
            zipcode: 90160,
          },
          900410: {
            name: {
              th: 'คลองกวาง',
              en: 'Khlong Kwang',
            },
            coordinates: {
              lat: '6.636',
              lng: '100.610',
            },
            zipcode: 90160,
          },
        },
      },
      9005: {
        name: {
          th: 'เทพา',
          en: 'Thepha',
        },
        tambons: {
          900501: {
            name: {
              th: 'เทพา',
              en: 'Thepha',
            },
            coordinates: {
              lat: '6.820',
              lng: '100.940',
            },
            zipcode: 90150,
          },
          900502: {
            name: {
              th: 'ปากบาง',
              en: 'Pak Bang',
            },
            coordinates: {
              lat: '6.826',
              lng: '101.009',
            },
            zipcode: 90150,
          },
          900503: {
            name: {
              th: 'เกาะสะบ้า',
              en: 'Ko Saba',
            },
            coordinates: {
              lat: '6.863',
              lng: '100.891',
            },
            zipcode: 90150,
          },
          900504: {
            name: {
              th: 'ลำไพล',
              en: 'Lam Phli',
            },
            coordinates: {
              lat: '6.721',
              lng: '100.930',
            },
            zipcode: 90260,
          },
          900505: {
            name: {
              th: 'ท่าม่วง',
              en: 'Tha Muang',
            },
            coordinates: {
              lat: '6.742',
              lng: '101.001',
            },
            zipcode: 90150,
          },
          900506: {
            name: {
              th: 'วังใหญ่',
              en: 'Wang Yai',
            },
            coordinates: {
              lat: '6.763',
              lng: '100.853',
            },
            zipcode: 90260,
          },
          900507: {
            name: {
              th: 'สะกอม',
              en: 'Sa Kom',
            },
            coordinates: {
              lat: '6.880',
              lng: '100.818',
            },
            zipcode: null,
          },
        },
      },
      9006: {
        name: {
          th: 'สะบ้าย้อย',
          en: 'Saba Yoi',
        },
        tambons: {
          900601: {
            name: {
              th: 'สะบ้าย้อย',
              en: 'Sa Ba Yoi',
            },
            coordinates: {
              lat: '6.632',
              lng: '100.929',
            },
            zipcode: 90210,
          },
          900602: {
            name: {
              th: 'ทุ่งพอ',
              en: 'Thung Pho',
            },
            coordinates: {
              lat: '6.553',
              lng: '100.910',
            },
            zipcode: 90210,
          },
          900603: {
            name: {
              th: 'เปียน',
              en: 'Pian',
            },
            coordinates: {
              lat: '6.634',
              lng: '101.003',
            },
            zipcode: 90210,
          },
          900604: {
            name: {
              th: 'บ้านโหนด',
              en: 'Ban Not',
            },
            coordinates: {
              lat: '6.624',
              lng: '101.057',
            },
            zipcode: 90210,
          },
          900605: {
            name: {
              th: 'จะแหน',
              en: 'Cha Nae',
            },
            coordinates: {
              lat: '6.517',
              lng: '100.962',
            },
            zipcode: 90210,
          },
          900606: {
            name: {
              th: 'คูหา',
              en: 'Khu Ha',
            },
            coordinates: {
              lat: '6.610',
              lng: '100.833',
            },
            zipcode: 90210,
          },
          900607: {
            name: {
              th: 'เขาแดง',
              en: 'Khao Daeng',
            },
            coordinates: {
              lat: '6.505',
              lng: '100.802',
            },
            zipcode: 90210,
          },
          900608: {
            name: {
              th: 'บาโหย',
              en: 'Ba Hoi',
            },
            coordinates: {
              lat: '6.396',
              lng: '100.884',
            },
            zipcode: 90210,
          },
          900609: {
            name: {
              th: 'ธารคีรี',
              en: 'Than Khiri',
            },
            coordinates: {
              lat: '6.559',
              lng: '101.059',
            },
            zipcode: 90210,
          },
        },
      },
      9007: {
        name: {
          th: 'ระโนด',
          en: 'Ranot',
        },
        tambons: {
          900701: {
            name: {
              th: 'ระโนด',
              en: 'Ra Not',
            },
            coordinates: {
              lat: '7.728',
              lng: '100.267',
            },
            zipcode: 90140,
          },
          900702: {
            name: {
              th: 'คลองแดน',
              en: 'Khlong Daen',
            },
            coordinates: {
              lat: '7.912',
              lng: '100.319',
            },
            zipcode: 90140,
          },
          900703: {
            name: {
              th: 'ตาเครียะ',
              en: 'Takhria',
            },
            coordinates: {
              lat: '7.848',
              lng: '100.256',
            },
            zipcode: 90140,
          },
          900704: {
            name: {
              th: 'ท่าบอน',
              en: 'Tha Bon',
            },
            coordinates: {
              lat: '7.842',
              lng: '100.342',
            },
            zipcode: 90140,
          },
          900705: {
            name: {
              th: 'บ้านใหม่',
              en: 'Ban Mai',
            },
            coordinates: {
              lat: '7.821',
              lng: '100.300',
            },
            zipcode: 90140,
          },
          900706: {
            name: {
              th: 'บ่อตรุ',
              en: 'Botru',
            },
            coordinates: {
              lat: '7.631',
              lng: '100.374',
            },
            zipcode: 90140,
          },
          900707: {
            name: {
              th: 'ปากแตระ',
              en: 'Pak Trae',
            },
            coordinates: {
              lat: '7.766',
              lng: '100.358',
            },
            zipcode: 90140,
          },
          900708: {
            name: {
              th: 'พังยาง',
              en: 'Phang Yang',
            },
            coordinates: {
              lat: '7.721',
              lng: '100.354',
            },
            zipcode: 90140,
          },
          900709: {
            name: {
              th: 'ระวะ',
              en: 'Ra Wa',
            },
            coordinates: {
              lat: '7.713',
              lng: '100.378',
            },
            zipcode: 90140,
          },
          900710: {
            name: {
              th: 'วัดสน',
              en: 'Wat Son',
            },
            coordinates: {
              lat: '7.668',
              lng: '100.372',
            },
            zipcode: 90140,
          },
          900711: {
            name: {
              th: 'บ้านขาว',
              en: 'Ban Khao',
            },
            coordinates: {
              lat: '7.833',
              lng: '100.218',
            },
            zipcode: 90140,
          },
          900712: {
            name: {
              th: 'แดนสงวน',
              en: 'Daen Sa Nguan',
            },
            coordinates: {
              lat: '7.878',
              lng: '100.291',
            },
            zipcode: 90140,
          },
        },
      },
      9008: {
        name: {
          th: 'กระแสสินธุ์',
          en: 'Krasae Sin',
        },
        tambons: {
          900801: {
            name: {
              th: 'เกาะใหญ่',
              en: 'Koyai',
            },
            coordinates: {
              lat: '7.540',
              lng: '100.323',
            },
            zipcode: null,
          },
          900802: {
            name: {
              th: 'โรง',
              en: 'Rong',
            },
            coordinates: {
              lat: '7.677',
              lng: '100.271',
            },
            zipcode: null,
          },
          900803: {
            name: {
              th: 'เชิงแส',
              en: 'Choeng Sae',
            },
            coordinates: {
              lat: '7.634',
              lng: '100.332',
            },
            zipcode: null,
          },
          900804: {
            name: {
              th: 'กระแสสินธุ์',
              en: 'Krasae Sin',
            },
            coordinates: {
              lat: '7.594',
              lng: '100.326',
            },
            zipcode: 90270,
          },
        },
      },
      9009: {
        name: {
          th: 'รัตภูมิ',
          en: 'Rattaphum',
        },
        tambons: {
          900901: {
            name: {
              th: 'กำแพงเพชร',
              en: 'Kamphaeng Phet',
            },
            coordinates: {
              lat: '7.087',
              lng: '100.287',
            },
            zipcode: 90180,
          },
          900902: {
            name: {
              th: 'ท่าชะมวง',
              en: 'Tha Cha Muang',
            },
            coordinates: {
              lat: '7.132',
              lng: '100.106',
            },
            zipcode: 90180,
          },
          900903: {
            name: {
              th: 'คูหาใต้',
              en: 'Khu Ha Tai',
            },
            coordinates: {
              lat: '7.173',
              lng: '100.263',
            },
            zipcode: 90180,
          },
          900904: {
            name: {
              th: 'ควนรู',
              en: 'Khuan Ru',
            },
            coordinates: {
              lat: '7.183',
              lng: '100.313',
            },
            zipcode: 90220,
          },
          900909: {
            name: {
              th: 'เขาพระ',
              en: 'Khao Phra',
            },
            coordinates: {
              lat: '7.011',
              lng: '100.141',
            },
            zipcode: 90180,
          },
        },
      },
      9010: {
        name: {
          th: 'สะเดา',
          en: 'Sadao',
        },
        tambons: {
          901001: {
            name: {
              th: 'สะเดา',
              en: 'Sadao',
            },
            coordinates: {
              lat: '6.636',
              lng: '100.416',
            },
            zipcode: 90120,
          },
          901002: {
            name: {
              th: 'ปริก',
              en: 'Prik',
            },
            coordinates: {
              lat: '6.694',
              lng: '100.473',
            },
            zipcode: 90120,
          },
          901003: {
            name: {
              th: 'พังลา',
              en: 'Phang La',
            },
            coordinates: {
              lat: '6.773',
              lng: '100.464',
            },
            zipcode: 90170,
          },
          901004: {
            name: {
              th: 'สำนักแต้ว',
              en: 'Samnak Taeo',
            },
            coordinates: {
              lat: '6.567',
              lng: '100.516',
            },
            zipcode: 90120,
          },
          901005: {
            name: {
              th: 'ทุ่งหมอ',
              en: 'Thung Mo',
            },
            coordinates: {
              lat: '6.745',
              lng: '100.378',
            },
            zipcode: 90240,
          },
          901006: {
            name: {
              th: 'ท่าโพธิ์',
              en: 'Tha Pho',
            },
            coordinates: {
              lat: '6.796',
              lng: '100.416',
            },
            zipcode: 90170,
          },
          901007: {
            name: {
              th: 'ปาดังเบซาร์',
              en: 'Pa Dangbesa',
            },
            coordinates: {
              lat: '6.740',
              lng: '100.241',
            },
            zipcode: 90240,
          },
          901008: {
            name: {
              th: 'สำนักขาม',
              en: 'Samnak Kham',
            },
            coordinates: {
              lat: '6.584',
              lng: '100.382',
            },
            zipcode: 90320,
          },
          901009: {
            name: {
              th: 'เขามีเกียรติ',
              en: 'Khao Mikiat',
            },
            coordinates: {
              lat: '6.774',
              lng: '100.524',
            },
            zipcode: 90170,
          },
        },
      },
      9011: {
        name: {
          th: 'หาดใหญ่',
          en: 'Hat Yai',
        },
        tambons: {
          901101: {
            name: {
              th: 'หาดใหญ่',
              en: 'Hat Yai',
            },
            coordinates: {
              lat: '7.010',
              lng: '100.474',
            },
            zipcode: 90110,
          },
          901102: {
            name: {
              th: 'ควนลัง',
              en: 'Khuan Lang',
            },
            coordinates: {
              lat: '6.973',
              lng: '100.421',
            },
            zipcode: 90110,
          },
          901103: {
            name: {
              th: 'คูเต่า',
              en: 'Khu Tao',
            },
            coordinates: {
              lat: '7.133',
              lng: '100.481',
            },
            zipcode: 90110,
          },
          901104: {
            name: {
              th: 'คอหงส์',
              en: 'Kho Hong',
            },
            coordinates: {
              lat: '7.006',
              lng: '100.503',
            },
            zipcode: 90110,
          },
          901105: {
            name: {
              th: 'คลองแห',
              en: 'Khlong Hae',
            },
            coordinates: {
              lat: '7.055',
              lng: '100.480',
            },
            zipcode: 90110,
          },
          901107: {
            name: {
              th: 'คลองอู่ตะเภา',
              en: 'Khlong U Ta Phao',
            },
            coordinates: {
              lat: '7.045',
              lng: '100.446',
            },
            zipcode: 90110,
          },
          901108: {
            name: {
              th: 'ฉลุง',
              en: 'Chalung',
            },
            coordinates: {
              lat: '7.007',
              lng: '100.296',
            },
            zipcode: 90110,
          },
          901111: {
            name: {
              th: 'ทุ่งใหญ่',
              en: 'Thung Yai',
            },
            coordinates: {
              lat: '7.030',
              lng: '100.537',
            },
            zipcode: 90110,
          },
          901112: {
            name: {
              th: 'ทุ่งตำเสา',
              en: 'Thung Tam Sao',
            },
            coordinates: {
              lat: '6.900',
              lng: '100.244',
            },
            zipcode: 90110,
          },
          901113: {
            name: {
              th: 'ท่าข้าม',
              en: 'Tha Kham',
            },
            coordinates: {
              lat: '7.041',
              lng: '100.574',
            },
            zipcode: 90110,
          },
          901114: {
            name: {
              th: 'น้ำน้อย',
              en: 'Nam Noi',
            },
            coordinates: {
              lat: '7.108',
              lng: '100.530',
            },
            zipcode: 90110,
          },
          901116: {
            name: {
              th: 'บ้านพรุ',
              en: 'Ban Phru',
            },
            coordinates: {
              lat: '6.929',
              lng: '100.490',
            },
            zipcode: 90250,
          },
          901118: {
            name: {
              th: 'พะตง',
              en: 'Pha Tong',
            },
            coordinates: {
              lat: '6.837',
              lng: '100.558',
            },
            zipcode: 90230,
          },
        },
      },
      9012: {
        name: {
          th: 'นาหม่อม',
          en: 'Na Mom',
        },
        tambons: {
          901201: {
            name: {
              th: 'นาหม่อม',
              en: 'Na Mom',
            },
            coordinates: {
              lat: '6.965',
              lng: '100.529',
            },
            zipcode: null,
          },
          901202: {
            name: {
              th: 'พิจิตร',
              en: 'Phichit',
            },
            coordinates: {
              lat: '6.991',
              lng: '100.561',
            },
            zipcode: null,
          },
          901203: {
            name: {
              th: 'ทุ่งขมิ้น',
              en: 'Thung Khamin',
            },
            coordinates: {
              lat: '6.916',
              lng: '100.547',
            },
            zipcode: null,
          },
          901204: {
            name: {
              th: 'คลองหรัง',
              en: 'Khlong Rang',
            },
            coordinates: {
              lat: '6.924',
              lng: '100.591',
            },
            zipcode: null,
          },
        },
      },
      9013: {
        name: {
          th: 'ควนเนียง',
          en: 'Khuan Niang',
        },
        tambons: {
          901301: {
            name: {
              th: 'รัตภูมิ',
              en: 'Ratta Phum',
            },
            coordinates: {
              lat: '7.182',
              lng: '100.370',
            },
            zipcode: null,
          },
          901302: {
            name: {
              th: 'ควนโส',
              en: 'Khuan So',
            },
            coordinates: {
              lat: '7.229',
              lng: '100.402',
            },
            zipcode: null,
          },
          901303: {
            name: {
              th: 'ห้วยลึก',
              en: 'Huai Luek',
            },
            coordinates: {
              lat: '7.267',
              lng: '100.366',
            },
            zipcode: null,
          },
          901304: {
            name: {
              th: 'บางเหรียง',
              en: 'Bang Riang',
            },
            coordinates: {
              lat: '7.112',
              lng: '100.364',
            },
            zipcode: null,
          },
        },
      },
      9014: {
        name: {
          th: 'บางกล่ำ',
          en: 'Bang Klam',
        },
        tambons: {
          901401: {
            name: {
              th: 'บางกล่ำ',
              en: 'Bang Klam',
            },
            coordinates: {
              lat: '7.135',
              lng: '100.446',
            },
            zipcode: null,
          },
          901402: {
            name: {
              th: 'ท่าช้าง',
              en: 'Tha Chang',
            },
            coordinates: {
              lat: '7.048',
              lng: '100.378',
            },
            zipcode: null,
          },
          901403: {
            name: {
              th: 'แม่ทอม',
              en: 'Mae Thom',
            },
            coordinates: {
              lat: '7.100',
              lng: '100.456',
            },
            zipcode: null,
          },
          901404: {
            name: {
              th: 'บ้านหาร',
              en: 'Ban Han',
            },
            coordinates: {
              lat: '7.075',
              lng: '100.450',
            },
            zipcode: null,
          },
        },
      },
      9015: {
        name: {
          th: 'สิงหนคร',
          en: 'Singhanakhon',
        },
        tambons: {
          901501: {
            name: {
              th: 'ชิงโค',
              en: 'Ching Kho',
            },
            coordinates: {
              lat: '7.266',
              lng: '100.524',
            },
            zipcode: null,
          },
          901502: {
            name: {
              th: 'สทิงหม้อ',
              en: 'Sathing Mo',
            },
            coordinates: {
              lat: '7.210',
              lng: '100.524',
            },
            zipcode: null,
          },
          901503: {
            name: {
              th: 'ทำนบ',
              en: 'Tham Nop',
            },
            coordinates: {
              lat: '7.246',
              lng: '100.506',
            },
            zipcode: null,
          },
          901504: {
            name: {
              th: 'รำแดง',
              en: 'Ram Daeng',
            },
            coordinates: {
              lat: '7.290',
              lng: '100.486',
            },
            zipcode: null,
          },
          901505: {
            name: {
              th: 'วัดขนุน',
              en: 'Wat Khanun',
            },
            coordinates: {
              lat: '7.304',
              lng: '100.497',
            },
            zipcode: null,
          },
          901506: {
            name: {
              th: 'ชะแล้',
              en: 'Chalae',
            },
            coordinates: {
              lat: '7.308',
              lng: '100.441',
            },
            zipcode: null,
          },
          901507: {
            name: {
              th: 'ปากรอ',
              en: 'Pak Ro',
            },
            coordinates: {
              lat: '7.277',
              lng: '100.441',
            },
            zipcode: null,
          },
          901508: {
            name: {
              th: 'ป่าขาด',
              en: 'Pa Khat',
            },
            coordinates: {
              lat: '7.241',
              lng: '100.469',
            },
            zipcode: null,
          },
          901509: {
            name: {
              th: 'หัวเขา',
              en: 'Hua Khao',
            },
            coordinates: {
              lat: '7.181',
              lng: '100.570',
            },
            zipcode: null,
          },
          901510: {
            name: {
              th: 'บางเขียด',
              en: 'Bang Khiat',
            },
            coordinates: {
              lat: '7.346',
              lng: '100.437',
            },
            zipcode: null,
          },
          901511: {
            name: {
              th: 'ม่วงงาม',
              en: 'Muang Ngam',
            },
            coordinates: {
              lat: '7.347',
              lng: '100.476',
            },
            zipcode: null,
          },
        },
      },
      9016: {
        name: {
          th: 'คลองหอยโข่ง',
          en: 'Khlong Hoi Khong',
        },
        tambons: {
          901601: {
            name: {
              th: 'คลองหอยโข่ง',
              en: 'Khlong Hoi Khong',
            },
            coordinates: {
              lat: '6.830',
              lng: '100.313',
            },
            zipcode: null,
          },
          901602: {
            name: {
              th: 'ทุ่งลาน',
              en: 'Thung Lan',
            },
            coordinates: {
              lat: '6.886',
              lng: '100.442',
            },
            zipcode: null,
          },
          901603: {
            name: {
              th: 'โคกม่วง',
              en: 'Khok Muang',
            },
            coordinates: {
              lat: '6.875',
              lng: '100.406',
            },
            zipcode: null,
          },
          901604: {
            name: {
              th: 'คลองหลา',
              en: 'Khlong La',
            },
            coordinates: {
              lat: '6.882',
              lng: '100.310',
            },
            zipcode: 90115,
          },
        },
      },
    },
  },
  91: {
    name: {
      th: 'สตูล',
      en: 'Satun',
    },
    amphoes: {
      9101: {
        name: {
          th: 'เมืองสตูล',
          en: 'Mueang Satun',
        },
        tambons: {
          910101: {
            name: {
              th: 'พิมาน',
              en: 'Phiman',
            },
            coordinates: {
              lat: '6.615',
              lng: '100.071',
            },
            zipcode: 91000,
          },
          910102: {
            name: {
              th: 'คลองขุด',
              en: 'Khlong Khut',
            },
            coordinates: {
              lat: '6.625',
              lng: '100.120',
            },
            zipcode: 91000,
          },
          910103: {
            name: {
              th: 'ควนขัน',
              en: 'Khuan Khan',
            },
            coordinates: {
              lat: '6.633',
              lng: '100.036',
            },
            zipcode: 91000,
          },
          910104: {
            name: {
              th: 'บ้านควน',
              en: 'Ban Khuan',
            },
            coordinates: {
              lat: '6.691',
              lng: '100.049',
            },
            zipcode: 91140,
          },
          910105: {
            name: {
              th: 'ฉลุง',
              en: 'Chalung',
            },
            coordinates: {
              lat: '6.725',
              lng: '100.035',
            },
            zipcode: 91140,
          },
          910106: {
            name: {
              th: 'เกาะสาหร่าย',
              en: 'Ko Sarai',
            },
            coordinates: {
              lat: '6.628',
              lng: '99.647',
            },
            zipcode: 91000,
          },
          910107: {
            name: {
              th: 'ตันหยงโป',
              en: 'Tanyong Po',
            },
            coordinates: {
              lat: '6.589',
              lng: '99.936',
            },
            zipcode: 91000,
          },
          910108: {
            name: {
              th: 'เจ๊ะบิลัง',
              en: 'Che Bi Lang',
            },
            coordinates: {
              lat: '6.687',
              lng: '99.965',
            },
            zipcode: 91000,
          },
          910109: {
            name: {
              th: 'ตำมะลัง',
              en: 'Tam Malang',
            },
            coordinates: {
              lat: '6.556',
              lng: '100.044',
            },
            zipcode: 91000,
          },
          910110: {
            name: {
              th: 'ปูยู',
              en: 'Puyu',
            },
            coordinates: {
              lat: '6.517',
              lng: '100.124',
            },
            zipcode: 91000,
          },
          910111: {
            name: {
              th: 'ควนโพธิ์',
              en: 'Khuan Pho',
            },
            coordinates: {
              lat: '6.769',
              lng: '100.018',
            },
            zipcode: 91140,
          },
          910112: {
            name: {
              th: 'เกตรี',
              en: 'Ketari',
            },
            coordinates: {
              lat: '6.702',
              lng: '100.095',
            },
            zipcode: 91140,
          },
        },
      },
      9102: {
        name: {
          th: 'ควนโดน',
          en: 'Khuan Don',
        },
        tambons: {
          910201: {
            name: {
              th: 'ควนโดน',
              en: 'Khuan Don',
            },
            coordinates: {
              lat: '6.814',
              lng: '100.060',
            },
            zipcode: 91160,
          },
          910202: {
            name: {
              th: 'ควนสตอ',
              en: 'Khuan Sato',
            },
            coordinates: {
              lat: '6.764',
              lng: '100.101',
            },
            zipcode: 91160,
          },
          910203: {
            name: {
              th: 'ย่านซื่อ',
              en: 'Yan Sue',
            },
            coordinates: {
              lat: '6.767',
              lng: '100.065',
            },
            zipcode: 91160,
          },
          910204: {
            name: {
              th: 'วังประจัน',
              en: 'Wang Prachan',
            },
            coordinates: {
              lat: '6.753',
              lng: '100.160',
            },
            zipcode: 91160,
          },
        },
      },
      9103: {
        name: {
          th: 'ควนกาหลง',
          en: 'Khuan Kalong',
        },
        tambons: {
          910301: {
            name: {
              th: 'ทุ่งนุ้ย',
              en: 'Thung Nui',
            },
            coordinates: {
              lat: '6.871',
              lng: '100.144',
            },
            zipcode: 91130,
          },
          910302: {
            name: {
              th: 'ควนกาหลง',
              en: 'Khuan Kalong',
            },
            coordinates: {
              lat: '6.953',
              lng: '100.030',
            },
            zipcode: 91130,
          },
          910303: {
            name: {
              th: 'อุไดเจริญ',
              en: 'Udai Charoen',
            },
            coordinates: {
              lat: '6.903',
              lng: '99.932',
            },
            zipcode: null,
          },
        },
      },
      9104: {
        name: {
          th: 'ท่าแพ',
          en: 'Tha Phae',
        },
        tambons: {
          910401: {
            name: {
              th: 'ท่าแพ',
              en: 'Tha Phae',
            },
            coordinates: {
              lat: '6.791',
              lng: '99.964',
            },
            zipcode: null,
          },
          910402: {
            name: {
              th: 'แป-ระ',
              en: 'Pae-Ra',
            },
            coordinates: {
              lat: '6.855',
              lng: '99.927',
            },
            zipcode: 91150,
          },
          910403: {
            name: {
              th: 'สาคร',
              en: 'Sakhon',
            },
            coordinates: {
              lat: '6.787',
              lng: '99.865',
            },
            zipcode: 91150,
          },
          910404: {
            name: {
              th: 'ท่าเรือ',
              en: 'Tha Ruea',
            },
            coordinates: {
              lat: '6.803',
              lng: '99.917',
            },
            zipcode: 91150,
          },
        },
      },
      9105: {
        name: {
          th: 'ละงู',
          en: 'La-ngu',
        },
        tambons: {
          910501: {
            name: {
              th: 'กำแพง',
              en: 'Kamphaeng',
            },
            coordinates: {
              lat: '6.933',
              lng: '99.777',
            },
            zipcode: 91110,
          },
          910502: {
            name: {
              th: 'ละงู',
              en: 'La-Ngu',
            },
            coordinates: {
              lat: '6.842',
              lng: '99.826',
            },
            zipcode: 91110,
          },
          910503: {
            name: {
              th: 'เขาขาว',
              en: 'Khao Khao',
            },
            coordinates: {
              lat: '6.923',
              lng: '99.848',
            },
            zipcode: 91110,
          },
          910504: {
            name: {
              th: 'ปากน้ำ',
              en: 'Pak Nam',
            },
            coordinates: {
              lat: '6.872',
              lng: '99.731',
            },
            zipcode: 91110,
          },
          910505: {
            name: {
              th: 'น้ำผุด',
              en: 'Nam Phut',
            },
            coordinates: {
              lat: '6.973',
              lng: '99.850',
            },
            zipcode: 91110,
          },
          910506: {
            name: {
              th: 'แหลมสน',
              en: 'Laem Son',
            },
            coordinates: {
              lat: '6.946',
              lng: '99.713',
            },
            zipcode: 91110,
          },
        },
      },
      9106: {
        name: {
          th: 'ทุ่งหว้า',
          en: 'Thung  Wa',
        },
        tambons: {
          910601: {
            name: {
              th: 'ทุ่งหว้า',
              en: 'Thung Wa',
            },
            coordinates: {
              lat: '7.108',
              lng: '99.807',
            },
            zipcode: 91120,
          },
          910602: {
            name: {
              th: 'นาทอน',
              en: 'Na Thon',
            },
            coordinates: {
              lat: '7.032',
              lng: '99.743',
            },
            zipcode: 91120,
          },
          910603: {
            name: {
              th: 'ขอนคลาน',
              en: 'Khon Khlan',
            },
            coordinates: {
              lat: '6.998',
              lng: '99.691',
            },
            zipcode: 91120,
          },
          910604: {
            name: {
              th: 'ทุ่งบุหลัง',
              en: 'Thung Bu Lang',
            },
            coordinates: {
              lat: '7.047',
              lng: '99.692',
            },
            zipcode: 91120,
          },
          910605: {
            name: {
              th: 'ป่าแก่บ่อหิน',
              en: 'Pa Kae Bo Hin',
            },
            coordinates: {
              lat: '7.048',
              lng: '99.817',
            },
            zipcode: 91120,
          },
        },
      },
      9107: {
        name: {
          th: 'มะนัง',
          en: 'Manang',
        },
        tambons: {
          910701: {
            name: {
              th: 'ปาล์มพัฒนา',
              en: 'Pam Phatthana',
            },
            coordinates: {
              lat: '7.038',
              lng: '99.934',
            },
            zipcode: null,
          },
          910702: {
            name: {
              th: 'นิคมพัฒนา',
              en: 'Nikhom Phatthana',
            },
            coordinates: {
              lat: '6.950',
              lng: '99.931',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  92: {
    name: {
      th: 'ตรัง',
      en: 'Trang',
    },
    amphoes: {
      9201: {
        name: {
          th: 'เมืองตรัง',
          en: 'Mueang Trang',
        },
        tambons: {
          920101: {
            name: {
              th: 'ทับเที่ยง',
              en: 'Thap Thiang',
            },
            coordinates: {
              lat: '7.559',
              lng: '99.616',
            },
            zipcode: 92000,
          },
          920104: {
            name: {
              th: 'นาพละ',
              en: 'Na Phala',
            },
            coordinates: {
              lat: '7.599',
              lng: '99.664',
            },
            zipcode: 92000,
          },
          920105: {
            name: {
              th: 'บ้านควน',
              en: 'Ban Khuan',
            },
            coordinates: {
              lat: '7.529',
              lng: '99.636',
            },
            zipcode: 92000,
          },
          920106: {
            name: {
              th: 'นาบินหลา',
              en: 'Na Bin La',
            },
            coordinates: {
              lat: '7.541',
              lng: '99.662',
            },
            zipcode: 92170,
          },
          920107: {
            name: {
              th: 'ควนปริง',
              en: 'Khuan Pring',
            },
            coordinates: {
              lat: '7.521',
              lng: '99.596',
            },
            zipcode: 92000,
          },
          920108: {
            name: {
              th: 'นาโยงใต้',
              en: 'Na Yong Tai',
            },
            coordinates: {
              lat: '7.571',
              lng: '99.669',
            },
            zipcode: 92170,
          },
          920109: {
            name: {
              th: 'บางรัก',
              en: 'Bang Rak',
            },
            coordinates: {
              lat: '7.558',
              lng: '99.577',
            },
            zipcode: 92000,
          },
          920110: {
            name: {
              th: 'โคกหล่อ',
              en: 'Khok Lo',
            },
            coordinates: {
              lat: '7.524',
              lng: '99.615',
            },
            zipcode: 92000,
          },
          920113: {
            name: {
              th: 'นาโต๊ะหมิง',
              en: 'Na To Ming',
            },
            coordinates: {
              lat: '7.556',
              lng: '99.527',
            },
            zipcode: 92000,
          },
          920114: {
            name: {
              th: 'หนองตรุด',
              en: 'Nong Trut',
            },
            coordinates: {
              lat: '7.594',
              lng: '99.523',
            },
            zipcode: 92000,
          },
          920115: {
            name: {
              th: 'น้ำผุด',
              en: 'Nam Phut',
            },
            coordinates: {
              lat: '7.673',
              lng: '99.726',
            },
            zipcode: 92000,
          },
          920117: {
            name: {
              th: 'นาตาล่วง',
              en: 'Na Ta Luang',
            },
            coordinates: {
              lat: '7.584',
              lng: '99.596',
            },
            zipcode: 92000,
          },
          920118: {
            name: {
              th: 'บ้านโพธิ์',
              en: 'Ban Pho',
            },
            coordinates: {
              lat: '7.604',
              lng: '99.627',
            },
            zipcode: 92000,
          },
          920119: {
            name: {
              th: 'นาท่ามเหนือ',
              en: 'Na Tham Nuea',
            },
            coordinates: {
              lat: '7.652',
              lng: '99.613',
            },
            zipcode: 92190,
          },
          920120: {
            name: {
              th: 'นาท่ามใต้',
              en: 'Na Tham Tai',
            },
            coordinates: {
              lat: '7.635',
              lng: '99.551',
            },
            zipcode: 92190,
          },
        },
      },
      9202: {
        name: {
          th: 'กันตัง',
          en: 'Kantang',
        },
        tambons: {
          920201: {
            name: {
              th: 'กันตัง',
              en: 'Kantang',
            },
            coordinates: {
              lat: '7.410',
              lng: '99.519',
            },
            zipcode: 92110,
          },
          920202: {
            name: {
              th: 'ควนธานี',
              en: 'Khuan Thani',
            },
            coordinates: {
              lat: '7.512',
              lng: '99.563',
            },
            zipcode: 92110,
          },
          920203: {
            name: {
              th: 'บางหมาก',
              en: 'Bang Mak',
            },
            coordinates: {
              lat: '7.465',
              lng: '99.554',
            },
            zipcode: 92110,
          },
          920204: {
            name: {
              th: 'บางเป้า',
              en: 'Bang Pao',
            },
            coordinates: {
              lat: '7.402',
              lng: '99.549',
            },
            zipcode: 92110,
          },
          920205: {
            name: {
              th: 'วังวน',
              en: 'Wang Won',
            },
            coordinates: {
              lat: '7.358',
              lng: '99.558',
            },
            zipcode: 92110,
          },
          920206: {
            name: {
              th: 'กันตังใต้',
              en: 'Kantang Tai',
            },
            coordinates: {
              lat: '7.338',
              lng: '99.515',
            },
            zipcode: 92110,
          },
          920207: {
            name: {
              th: 'โคกยาง',
              en: 'Khok Yang',
            },
            coordinates: {
              lat: '7.509',
              lng: '99.480',
            },
            zipcode: 92110,
          },
          920208: {
            name: {
              th: 'คลองลุ',
              en: 'Khlong Lu',
            },
            coordinates: {
              lat: '7.482',
              lng: '99.495',
            },
            zipcode: 92110,
          },
          920209: {
            name: {
              th: 'ย่านซื่อ',
              en: 'Yan Sue',
            },
            coordinates: {
              lat: '7.473',
              lng: '99.541',
            },
            zipcode: 92110,
          },
          920210: {
            name: {
              th: 'บ่อน้ำร้อน',
              en: 'Bo Nam Ron',
            },
            coordinates: {
              lat: '7.425',
              lng: '99.474',
            },
            zipcode: 92110,
          },
          920211: {
            name: {
              th: 'บางสัก',
              en: 'Bang Sak',
            },
            coordinates: {
              lat: '7.397',
              lng: '99.383',
            },
            zipcode: 92110,
          },
          920212: {
            name: {
              th: 'นาเกลือ',
              en: 'Na Kluea',
            },
            coordinates: {
              lat: '7.326',
              lng: '99.466',
            },
            zipcode: 92110,
          },
          920213: {
            name: {
              th: 'เกาะลิบง',
              en: 'Ko Libong',
            },
            coordinates: {
              lat: '7.333',
              lng: '99.389',
            },
            zipcode: 92110,
          },
          920214: {
            name: {
              th: 'คลองชีล้อม',
              en: 'Khlong Chi Lom',
            },
            coordinates: {
              lat: '7.433',
              lng: '99.587',
            },
            zipcode: 92110,
          },
          920221: {
            name: {
              th: 'เกาะลูกไม้',
              en: null,
            },
            coordinates: {
              lat: '7.291',
              lng: '99.450',
            },
            zipcode: null,
          },
          920222: {
            name: {
              th: 'เกาะนก',
              en: null,
            },
            coordinates: {
              lat: '7.272',
              lng: '99.475',
            },
            zipcode: null,
          },
        },
      },
      9203: {
        name: {
          th: 'ย่านตาขาว',
          en: 'Yan Ta Khao',
        },
        tambons: {
          920301: {
            name: {
              th: 'ย่านตาขาว',
              en: 'Yan Ta Khao',
            },
            coordinates: {
              lat: '7.406',
              lng: '99.674',
            },
            zipcode: 92140,
          },
          920302: {
            name: {
              th: 'หนองบ่อ',
              en: 'Nong Bo',
            },
            coordinates: {
              lat: '7.416',
              lng: '99.718',
            },
            zipcode: 92140,
          },
          920303: {
            name: {
              th: 'นาชุมเห็ด',
              en: 'Na Chum Het',
            },
            coordinates: {
              lat: '7.477',
              lng: '99.822',
            },
            zipcode: 92140,
          },
          920304: {
            name: {
              th: 'ในควน',
              en: 'Nai Khuan',
            },
            coordinates: {
              lat: '7.376',
              lng: '99.768',
            },
            zipcode: 92140,
          },
          920305: {
            name: {
              th: 'โพรงจรเข้',
              en: 'Phrong Chorakhe',
            },
            coordinates: {
              lat: '7.407',
              lng: '99.830',
            },
            zipcode: 92140,
          },
          920306: {
            name: {
              th: 'ทุ่งกระบือ',
              en: 'Thung Krabue',
            },
            coordinates: {
              lat: '7.404',
              lng: '99.626',
            },
            zipcode: 92140,
          },
          920307: {
            name: {
              th: 'ทุ่งค่าย',
              en: 'Thung Khai',
            },
            coordinates: {
              lat: '7.474',
              lng: '99.648',
            },
            zipcode: 92140,
          },
          920308: {
            name: {
              th: 'เกาะเปียะ',
              en: 'Ko Pia',
            },
            coordinates: {
              lat: '7.453',
              lng: '99.701',
            },
            zipcode: 92140,
          },
        },
      },
      9204: {
        name: {
          th: 'ปะเหลียน',
          en: 'Palian',
        },
        tambons: {
          920401: {
            name: {
              th: 'ท่าข้าม',
              en: 'Tha Kham',
            },
            coordinates: {
              lat: '7.172',
              lng: '99.690',
            },
            zipcode: 92120,
          },
          920402: {
            name: {
              th: 'ทุ่งยาว',
              en: 'Thung Yao',
            },
            coordinates: {
              lat: '7.241',
              lng: '99.753',
            },
            zipcode: 92180,
          },
          920403: {
            name: {
              th: 'ปะเหลียน',
              en: 'Palian',
            },
            coordinates: {
              lat: '7.288',
              lng: '99.862',
            },
            zipcode: 92180,
          },
          920404: {
            name: {
              th: 'บางด้วน',
              en: 'Bang Duan',
            },
            coordinates: {
              lat: '7.350',
              lng: '99.699',
            },
            zipcode: 92140,
          },
          920407: {
            name: {
              th: 'บ้านนา',
              en: 'Ban Na',
            },
            coordinates: {
              lat: '7.302',
              lng: '99.699',
            },
            zipcode: 92140,
          },
          920409: {
            name: {
              th: 'สุโสะ',
              en: 'Suso',
            },
            coordinates: {
              lat: '7.236',
              lng: '99.673',
            },
            zipcode: 92120,
          },
          920410: {
            name: {
              th: 'ลิพัง',
              en: 'Li Phang',
            },
            coordinates: {
              lat: '7.184',
              lng: '99.810',
            },
            zipcode: 92180,
          },
          920411: {
            name: {
              th: 'เกาะสุกร',
              en: 'Ko Sukon',
            },
            coordinates: {
              lat: '7.101',
              lng: '99.581',
            },
            zipcode: 92120,
          },
          920412: {
            name: {
              th: 'ท่าพญา',
              en: 'Tha Phaya',
            },
            coordinates: {
              lat: '7.354',
              lng: '99.674',
            },
            zipcode: 92140,
          },
          920413: {
            name: {
              th: 'แหลมสอม',
              en: 'Laem Som',
            },
            coordinates: {
              lat: '7.287',
              lng: '99.763',
            },
            zipcode: 92180,
          },
          920414: {
            name: {
              th: 'เกาะค้างคาว',
              en: null,
            },
            coordinates: {
              lat: '7.152',
              lng: '99.651',
            },
            zipcode: null,
          },
        },
      },
      9205: {
        name: {
          th: 'สิเกา',
          en: 'Sikao',
        },
        tambons: {
          920501: {
            name: {
              th: 'บ่อหิน',
              en: 'Bo Hin',
            },
            coordinates: {
              lat: '7.593',
              lng: '99.345',
            },
            zipcode: 92150,
          },
          920502: {
            name: {
              th: 'เขาไม้แก้ว',
              en: 'Khao Mai Kaeo',
            },
            coordinates: {
              lat: '7.645',
              lng: '99.306',
            },
            zipcode: 92150,
          },
          920503: {
            name: {
              th: 'กะลาเส',
              en: 'Kalase',
            },
            coordinates: {
              lat: '7.744',
              lng: '99.326',
            },
            zipcode: 92150,
          },
          920504: {
            name: {
              th: 'ไม้ฝาด',
              en: 'Mai Fat',
            },
            coordinates: {
              lat: '7.505',
              lng: '99.369',
            },
            zipcode: 92150,
          },
          920505: {
            name: {
              th: 'นาเมืองเพชร',
              en: 'Na Mueang Phet',
            },
            coordinates: {
              lat: '7.559',
              lng: '99.465',
            },
            zipcode: 92000,
          },
        },
      },
      9206: {
        name: {
          th: 'ห้วยยอด',
          en: 'Huai Yot',
        },
        tambons: {
          920601: {
            name: {
              th: 'ห้วยยอด',
              en: 'Huai Yot',
            },
            coordinates: {
              lat: '7.776',
              lng: '99.632',
            },
            zipcode: 92130,
          },
          920602: {
            name: {
              th: 'หนองช้างแล่น',
              en: 'Nong Chang Laen',
            },
            coordinates: {
              lat: '7.840',
              lng: '99.585',
            },
            zipcode: 92130,
          },
          920605: {
            name: {
              th: 'บางดี',
              en: 'Bang Di',
            },
            coordinates: {
              lat: '7.841',
              lng: '99.494',
            },
            zipcode: 92210,
          },
          920606: {
            name: {
              th: 'บางกุ้ง',
              en: 'Bang Kung',
            },
            coordinates: {
              lat: '7.762',
              lng: '99.457',
            },
            zipcode: 92210,
          },
          920607: {
            name: {
              th: 'เขากอบ',
              en: 'Khao Kop',
            },
            coordinates: {
              lat: '7.798',
              lng: '99.563',
            },
            zipcode: 92130,
          },
          920608: {
            name: {
              th: 'เขาขาว',
              en: 'Khao Khao',
            },
            coordinates: {
              lat: '7.811',
              lng: '99.618',
            },
            zipcode: 92130,
          },
          920609: {
            name: {
              th: 'เขาปูน',
              en: 'Khao Pun',
            },
            coordinates: {
              lat: '7.803',
              lng: '99.668',
            },
            zipcode: 92130,
          },
          920610: {
            name: {
              th: 'ปากแจ่ม',
              en: 'Pak Chaem',
            },
            coordinates: {
              lat: '7.737',
              lng: '99.724',
            },
            zipcode: 92190,
          },
          920611: {
            name: {
              th: 'ปากคม',
              en: 'Pak Khom',
            },
            coordinates: {
              lat: '7.732',
              lng: '99.607',
            },
            zipcode: 92130,
          },
          920614: {
            name: {
              th: 'ท่างิ้ว',
              en: 'Tha Ngio',
            },
            coordinates: {
              lat: '7.845',
              lng: '99.651',
            },
            zipcode: 92130,
          },
          920615: {
            name: {
              th: 'ลำภูรา',
              en: 'Lamphura',
            },
            coordinates: {
              lat: '7.698',
              lng: '99.583',
            },
            zipcode: 92190,
          },
          920616: {
            name: {
              th: 'นาวง',
              en: 'Na Wong',
            },
            coordinates: {
              lat: '7.740',
              lng: '99.511',
            },
            zipcode: 92210,
          },
          920617: {
            name: {
              th: 'ห้วยนาง',
              en: 'Huai Nang',
            },
            coordinates: {
              lat: '7.893',
              lng: '99.576',
            },
            zipcode: 92130,
          },
          920619: {
            name: {
              th: 'ในเตา',
              en: 'Nai Tao',
            },
            coordinates: {
              lat: '7.860',
              lng: '99.727',
            },
            zipcode: 92130,
          },
          920620: {
            name: {
              th: 'ทุ่งต่อ',
              en: 'Thung To',
            },
            coordinates: {
              lat: '7.759',
              lng: '99.575',
            },
            zipcode: 92130,
          },
          920621: {
            name: {
              th: 'วังคีรี',
              en: 'Wang Khiri',
            },
            coordinates: {
              lat: '7.790',
              lng: '99.472',
            },
            zipcode: 92210,
          },
        },
      },
      9207: {
        name: {
          th: 'วังวิเศษ',
          en: 'Wang Wiset',
        },
        tambons: {
          920701: {
            name: {
              th: 'เขาวิเศษ',
              en: 'Khao Wiset',
            },
            coordinates: {
              lat: '7.651',
              lng: '99.459',
            },
            zipcode: null,
          },
          920702: {
            name: {
              th: 'วังมะปราง',
              en: 'Wang Maprang',
            },
            coordinates: {
              lat: '7.656',
              lng: '99.382',
            },
            zipcode: null,
          },
          920703: {
            name: {
              th: 'อ่าวตง',
              en: 'Ao Tong',
            },
            coordinates: {
              lat: '7.862',
              lng: '99.365',
            },
            zipcode: null,
          },
          920704: {
            name: {
              th: 'ท่าสะบ้า',
              en: 'Tha Saba',
            },
            coordinates: {
              lat: '7.690',
              lng: '99.523',
            },
            zipcode: null,
          },
          920705: {
            name: {
              th: 'วังมะปรางเหนือ',
              en: 'Wang Maprang Nuea',
            },
            coordinates: {
              lat: '7.746',
              lng: '99.398',
            },
            zipcode: 92220,
          },
        },
      },
      9208: {
        name: {
          th: 'นาโยง',
          en: 'Na Yong',
        },
        tambons: {
          920801: {
            name: {
              th: 'นาโยงเหนือ',
              en: 'Na Yong Nuea',
            },
            coordinates: {
              lat: '7.566',
              lng: '99.699',
            },
            zipcode: null,
          },
          920802: {
            name: {
              th: 'ช่อง',
              en: 'Chong',
            },
            coordinates: {
              lat: '7.546',
              lng: '99.793',
            },
            zipcode: null,
          },
          920803: {
            name: {
              th: 'ละมอ',
              en: 'Lamo',
            },
            coordinates: {
              lat: '7.597',
              lng: '99.753',
            },
            zipcode: null,
          },
          920804: {
            name: {
              th: 'โคกสะบ้า',
              en: 'Khok Saba',
            },
            coordinates: {
              lat: '7.490',
              lng: '99.714',
            },
            zipcode: null,
          },
          920805: {
            name: {
              th: 'นาหมื่นศรี',
              en: 'Na Muen Si',
            },
            coordinates: {
              lat: '7.609',
              lng: '99.699',
            },
            zipcode: null,
          },
          920806: {
            name: {
              th: 'นาข้าวเสีย',
              en: 'Na Khao Sia',
            },
            coordinates: {
              lat: '7.532',
              lng: '99.710',
            },
            zipcode: null,
          },
        },
      },
      9209: {
        name: {
          th: 'รัษฎา',
          en: 'Ratsada',
        },
        tambons: {
          920901: {
            name: {
              th: 'ควนเมา',
              en: 'Khuan Mao',
            },
            coordinates: {
              lat: '7.938',
              lng: '99.596',
            },
            zipcode: null,
          },
          920902: {
            name: {
              th: 'คลองปาง',
              en: 'Khlong Pang',
            },
            coordinates: {
              lat: '7.987',
              lng: '99.645',
            },
            zipcode: null,
          },
          920903: {
            name: {
              th: 'หนองบัว',
              en: 'Nong Bua',
            },
            coordinates: {
              lat: '7.978',
              lng: '99.731',
            },
            zipcode: null,
          },
          920904: {
            name: {
              th: 'หนองปรือ',
              en: 'Nong Prue',
            },
            coordinates: {
              lat: '7.900',
              lng: '99.669',
            },
            zipcode: null,
          },
          920905: {
            name: {
              th: 'เขาไพร',
              en: 'Khao Phrai',
            },
            coordinates: {
              lat: '7.960',
              lng: '99.661',
            },
            zipcode: null,
          },
        },
      },
      9210: {
        name: {
          th: 'หาดสำราญ',
          en: 'Hat Samran',
        },
        tambons: {
          921001: {
            name: {
              th: 'หาดสำราญ',
              en: 'Hat Samran',
            },
            coordinates: {
              lat: '7.256',
              lng: '99.552',
            },
            zipcode: null,
          },
          921002: {
            name: {
              th: 'บ้าหวี',
              en: 'Ba Wi',
            },
            coordinates: {
              lat: '7.283',
              lng: '99.597',
            },
            zipcode: null,
          },
          921003: {
            name: {
              th: 'ตะเสะ',
              en: 'Tase',
            },
            coordinates: {
              lat: '7.183',
              lng: '99.599',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  93: {
    name: {
      th: 'พัทลุง',
      en: 'Phatthalung',
    },
    amphoes: {
      9301: {
        name: {
          th: 'เมืองพัทลุง',
          en: 'Mueang Phatthalung',
        },
        tambons: {
          930101: {
            name: {
              th: 'คูหาสวรรค์',
              en: 'Khu Ha Sa Wan',
            },
            coordinates: {
              lat: '7.625',
              lng: '100.085',
            },
            zipcode: 93000,
          },
          930103: {
            name: {
              th: 'เขาเจียก',
              en: 'Khao Chiak',
            },
            coordinates: {
              lat: '7.624',
              lng: '100.039',
            },
            zipcode: 93000,
          },
          930104: {
            name: {
              th: 'ท่ามิหรำ',
              en: 'Tha Mi Ram',
            },
            coordinates: {
              lat: '7.589',
              lng: '100.050',
            },
            zipcode: 93000,
          },
          930105: {
            name: {
              th: 'โคกชะงาย',
              en: 'Khok Cha Ngai',
            },
            coordinates: {
              lat: '7.615',
              lng: '100.003',
            },
            zipcode: 93000,
          },
          930106: {
            name: {
              th: 'นาท่อม',
              en: 'Na Thom',
            },
            coordinates: {
              lat: '7.585',
              lng: '100.002',
            },
            zipcode: 93000,
          },
          930107: {
            name: {
              th: 'ปรางหมู่',
              en: 'Prang Mu',
            },
            coordinates: {
              lat: '7.647',
              lng: '100.068',
            },
            zipcode: 93000,
          },
          930108: {
            name: {
              th: 'ท่าแค',
              en: 'Tha Khae',
            },
            coordinates: {
              lat: '7.545',
              lng: '100.050',
            },
            zipcode: 93000,
          },
          930109: {
            name: {
              th: 'ลำปำ',
              en: 'Lam Pam',
            },
            coordinates: {
              lat: '7.648',
              lng: '100.188',
            },
            zipcode: 93000,
          },
          930110: {
            name: {
              th: 'ตำนาน',
              en: 'Tamnan',
            },
            coordinates: {
              lat: '7.572',
              lng: '100.083',
            },
            zipcode: 93000,
          },
          930111: {
            name: {
              th: 'ควนมะพร้าว',
              en: 'Khuan Ma Phrao',
            },
            coordinates: {
              lat: '7.588',
              lng: '100.124',
            },
            zipcode: 93000,
          },
          930112: {
            name: {
              th: 'ร่มเมือง',
              en: 'Rom Mueang',
            },
            coordinates: {
              lat: '7.556',
              lng: '100.008',
            },
            zipcode: 93000,
          },
          930113: {
            name: {
              th: 'ชัยบุรี',
              en: 'Chai Buri',
            },
            coordinates: {
              lat: '7.697',
              lng: '100.093',
            },
            zipcode: 93000,
          },
          930114: {
            name: {
              th: 'นาโหนด',
              en: 'Na Not',
            },
            coordinates: {
              lat: '7.507',
              lng: '100.061',
            },
            zipcode: 93000,
          },
          930115: {
            name: {
              th: 'พญาขัน',
              en: 'Phaya Khan',
            },
            coordinates: {
              lat: '7.652',
              lng: '100.107',
            },
            zipcode: 93000,
          },
        },
      },
      9302: {
        name: {
          th: 'กงหรา',
          en: 'Kong Ra',
        },
        tambons: {
          930201: {
            name: {
              th: 'กงหรา',
              en: 'Kong Ra',
            },
            coordinates: {
              lat: '7.463',
              lng: '99.877',
            },
            zipcode: 93180,
          },
          930202: {
            name: {
              th: 'ชะรัด',
              en: 'Charat',
            },
            coordinates: {
              lat: '7.473',
              lng: '99.989',
            },
            zipcode: 93000,
          },
          930203: {
            name: {
              th: 'คลองเฉลิม',
              en: 'Khlong Cha Loem',
            },
            coordinates: {
              lat: '7.349',
              lng: '99.958',
            },
            zipcode: 93180,
          },
          930204: {
            name: {
              th: 'คลองทรายขาว',
              en: 'Khlong Sai Khao',
            },
            coordinates: {
              lat: '7.423',
              lng: '99.921',
            },
            zipcode: 93180,
          },
          930205: {
            name: {
              th: 'สมหวัง',
              en: 'Som Wang',
            },
            coordinates: {
              lat: '7.509',
              lng: '99.990',
            },
            zipcode: 93000,
          },
        },
      },
      9303: {
        name: {
          th: 'เขาชัยสน',
          en: 'Khao Chaison',
        },
        tambons: {
          930301: {
            name: {
              th: 'เขาชัยสน',
              en: 'Khao Chaison',
            },
            coordinates: {
              lat: '7.433',
              lng: '100.111',
            },
            zipcode: 93130,
          },
          930302: {
            name: {
              th: 'ควนขนุน',
              en: 'Khuan Kha Nun',
            },
            coordinates: {
              lat: '7.491',
              lng: '100.095',
            },
            zipcode: 93130,
          },
          930305: {
            name: {
              th: 'จองถนน',
              en: 'Chong Tha Non',
            },
            coordinates: {
              lat: '7.489',
              lng: '100.194',
            },
            zipcode: 93130,
          },
          930306: {
            name: {
              th: 'หานโพธิ์',
              en: 'Han Pho',
            },
            coordinates: {
              lat: '7.541',
              lng: '100.195',
            },
            zipcode: 93130,
          },
          930307: {
            name: {
              th: 'โคกม่วง',
              en: 'Khok Muang',
            },
            coordinates: {
              lat: '7.389',
              lng: '100.067',
            },
            zipcode: 93130,
          },
        },
      },
      9304: {
        name: {
          th: 'ตะโหมด',
          en: 'Tamot',
        },
        tambons: {
          930401: {
            name: {
              th: 'แม่ขรี',
              en: 'Mae Khri',
            },
            coordinates: {
              lat: '7.338',
              lng: '100.097',
            },
            zipcode: null,
          },
          930402: {
            name: {
              th: 'ตะโหมด',
              en: 'Tanot',
            },
            coordinates: {
              lat: '7.278',
              lng: '100.007',
            },
            zipcode: null,
          },
          930403: {
            name: {
              th: 'คลองใหญ่',
              en: 'Khlong Yai',
            },
            coordinates: {
              lat: '7.260',
              lng: '100.074',
            },
            zipcode: null,
          },
        },
      },
      9305: {
        name: {
          th: 'ควนขนุน',
          en: 'Khuan Khanun',
        },
        tambons: {
          930501: {
            name: {
              th: 'ควนขนุน',
              en: 'Khuan Kha Nun',
            },
            coordinates: {
              lat: '7.756',
              lng: '100.003',
            },
            zipcode: null,
          },
          930502: {
            name: {
              th: 'ทะเลน้อย',
              en: 'Thale Noi',
            },
            coordinates: {
              lat: '7.824',
              lng: '100.145',
            },
            zipcode: 93150,
          },
          930504: {
            name: {
              th: 'นาขยาด',
              en: 'Nakhayat',
            },
            coordinates: {
              lat: '7.687',
              lng: '99.948',
            },
            zipcode: 93110,
          },
          930505: {
            name: {
              th: 'พนมวังก์',
              en: 'Phanom Wang',
            },
            coordinates: {
              lat: '7.684',
              lng: '100.023',
            },
            zipcode: 93110,
          },
          930506: {
            name: {
              th: 'แหลมโตนด',
              en: 'Laem Tanot',
            },
            coordinates: {
              lat: '7.826',
              lng: '100.030',
            },
            zipcode: 93110,
          },
          930508: {
            name: {
              th: 'ปันแต',
              en: 'Pantae',
            },
            coordinates: {
              lat: '7.783',
              lng: '100.051',
            },
            zipcode: 93110,
          },
          930509: {
            name: {
              th: 'โตนดด้วน',
              en: 'Tanot Duan',
            },
            coordinates: {
              lat: '7.718',
              lng: '100.036',
            },
            zipcode: 93110,
          },
          930510: {
            name: {
              th: 'ดอนทราย',
              en: 'Don Sai',
            },
            coordinates: {
              lat: '7.716',
              lng: '99.975',
            },
            zipcode: 93120,
          },
          930511: {
            name: {
              th: 'มะกอกเหนือ',
              en: 'Makok Nuea',
            },
            coordinates: {
              lat: '7.746',
              lng: '100.073',
            },
            zipcode: 93150,
          },
          930512: {
            name: {
              th: 'พนางตุง',
              en: 'Phanang Tung',
            },
            coordinates: {
              lat: '7.759',
              lng: '100.140',
            },
            zipcode: 93150,
          },
          930513: {
            name: {
              th: 'ชะมวง',
              en: 'Chamuang',
            },
            coordinates: {
              lat: '7.765',
              lng: '99.956',
            },
            zipcode: 93110,
          },
          930516: {
            name: {
              th: 'แพรกหา',
              en: 'Phraek Ha',
            },
            coordinates: {
              lat: '7.654',
              lng: '100.010',
            },
            zipcode: 93110,
          },
        },
      },
      9306: {
        name: {
          th: 'ปากพะยูน',
          en: 'Pak Phayun',
        },
        tambons: {
          930601: {
            name: {
              th: 'ปากพะยูน',
              en: 'Pak Pha Yun',
            },
            coordinates: {
              lat: '7.330',
              lng: '100.336',
            },
            zipcode: 93120,
          },
          930602: {
            name: {
              th: 'ดอนประดู่',
              en: 'Don Pradu',
            },
            coordinates: {
              lat: '7.279',
              lng: '100.318',
            },
            zipcode: 93120,
          },
          930603: {
            name: {
              th: 'เกาะนางคำ',
              en: 'Ko Nangkham',
            },
            coordinates: {
              lat: '7.343',
              lng: '100.389',
            },
            zipcode: 93120,
          },
          930604: {
            name: {
              th: 'เกาะหมาก',
              en: 'Ko Mak',
            },
            coordinates: {
              lat: '7.435',
              lng: '100.329',
            },
            zipcode: 93120,
          },
          930605: {
            name: {
              th: 'ฝาละมี',
              en: 'Fa La Mi',
            },
            coordinates: {
              lat: '7.371',
              lng: '100.261',
            },
            zipcode: 93120,
          },
          930606: {
            name: {
              th: 'หารเทา',
              en: 'Han Thao',
            },
            coordinates: {
              lat: '7.288',
              lng: '100.267',
            },
            zipcode: 93120,
          },
          930607: {
            name: {
              th: 'ดอนทราย',
              en: 'Don Sai',
            },
            coordinates: {
              lat: '7.244',
              lng: '100.321',
            },
            zipcode: null,
          },
        },
      },
      9307: {
        name: {
          th: 'ศรีบรรพต',
          en: 'Si Banphot',
        },
        tambons: {
          930701: {
            name: {
              th: 'เขาย่า',
              en: 'Khao Ya',
            },
            coordinates: {
              lat: '7.746',
              lng: '99.864',
            },
            zipcode: 93190,
          },
          930702: {
            name: {
              th: 'เขาปู่',
              en: 'Khao Pu',
            },
            coordinates: {
              lat: '7.701',
              lng: '99.833',
            },
            zipcode: 93190,
          },
          930703: {
            name: {
              th: 'ตะแพน',
              en: 'Tapaen',
            },
            coordinates: {
              lat: '7.645',
              lng: '99.878',
            },
            zipcode: 93190,
          },
        },
      },
      9308: {
        name: {
          th: 'ป่าบอน',
          en: 'Pa Bon',
        },
        tambons: {
          930801: {
            name: {
              th: 'ป่าบอน',
              en: 'Pa Bon',
            },
            coordinates: {
              lat: '7.337',
              lng: '100.171',
            },
            zipcode: null,
          },
          930802: {
            name: {
              th: 'โคกทราย',
              en: 'Khok Sai',
            },
            coordinates: {
              lat: '7.229',
              lng: '100.229',
            },
            zipcode: null,
          },
          930803: {
            name: {
              th: 'หนองธง',
              en: 'Nong Thong',
            },
            coordinates: {
              lat: '7.162',
              lng: '100.008',
            },
            zipcode: 93170,
          },
          930804: {
            name: {
              th: 'ทุ่งนารี',
              en: 'Thung Nari',
            },
            coordinates: {
              lat: '7.191',
              lng: '100.130',
            },
            zipcode: 93170,
          },
          930806: {
            name: {
              th: 'วังใหม่',
              en: 'Wang Mai',
            },
            coordinates: {
              lat: '7.318',
              lng: '100.230',
            },
            zipcode: 93170,
          },
        },
      },
      9309: {
        name: {
          th: 'บางแก้ว',
          en: 'Bang Kaeo',
        },
        tambons: {
          930901: {
            name: {
              th: 'ท่ามะเดื่อ',
              en: 'Tha Maduea',
            },
            coordinates: {
              lat: '7.451',
              lng: '100.157',
            },
            zipcode: null,
          },
          930902: {
            name: {
              th: 'นาปะขอ',
              en: 'Na Pakho',
            },
            coordinates: {
              lat: '7.435',
              lng: '100.197',
            },
            zipcode: null,
          },
          930903: {
            name: {
              th: 'โคกสัก',
              en: 'Khok Sak',
            },
            coordinates: {
              lat: '7.390',
              lng: '100.145',
            },
            zipcode: null,
          },
        },
      },
      9310: {
        name: {
          th: 'ป่าพยอม',
          en: 'Pa Phayom',
        },
        tambons: {
          931001: {
            name: {
              th: 'ป่าพะยอม',
              en: null,
            },
            coordinates: {
              lat: '7.860',
              lng: '99.947',
            },
            zipcode: null,
          },
          931002: {
            name: {
              th: 'ลานข่อย',
              en: null,
            },
            coordinates: {
              lat: '7.831',
              lng: '99.780',
            },
            zipcode: null,
          },
          931003: {
            name: {
              th: 'เกาะเต่า',
              en: 'Ko Tao',
            },
            coordinates: {
              lat: '7.783',
              lng: '99.799',
            },
            zipcode: null,
          },
          931004: {
            name: {
              th: 'บ้านพร้าว',
              en: null,
            },
            coordinates: {
              lat: '7.818',
              lng: '99.948',
            },
            zipcode: null,
          },
        },
      },
      9311: {
        name: {
          th: 'ศรีนครินทร์',
          en: 'Srinagarindra',
        },
        tambons: {
          931101: {
            name: {
              th: 'ชุมพล',
              en: 'Chum Phon',
            },
            coordinates: {
              lat: '7.608',
              lng: '99.948',
            },
            zipcode: null,
          },
          931102: {
            name: {
              th: 'บ้านนา',
              en: 'Ban Na',
            },
            coordinates: {
              lat: '7.559',
              lng: '99.868',
            },
            zipcode: null,
          },
          931103: {
            name: {
              th: 'อ่างทอง',
              en: 'Ang Thong',
            },
            coordinates: {
              lat: '7.554',
              lng: '99.971',
            },
            zipcode: null,
          },
          931104: {
            name: {
              th: 'ลำสินธุ์',
              en: 'Lam Sin',
            },
            coordinates: {
              lat: '7.495',
              lng: '99.875',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  94: {
    name: {
      th: 'ปัตตานี',
      en: 'Pattani',
    },
    amphoes: {
      9401: {
        name: {
          th: 'เมืองปัตตานี',
          en: 'Mueang Pattani',
        },
        tambons: {
          940101: {
            name: {
              th: 'สะบารัง',
              en: 'Sabarang',
            },
            coordinates: {
              lat: '6.876',
              lng: '101.251',
            },
            zipcode: 94000,
          },
          940102: {
            name: {
              th: 'อาเนาะรู',
              en: 'A Noru',
            },
            coordinates: {
              lat: '6.870',
              lng: '101.256',
            },
            zipcode: 94000,
          },
          940103: {
            name: {
              th: 'จะบังติกอ',
              en: 'Chabang Tiko',
            },
            coordinates: {
              lat: '6.857',
              lng: '101.251',
            },
            zipcode: 94000,
          },
          940104: {
            name: {
              th: 'บานา',
              en: 'Bana',
            },
            coordinates: {
              lat: '6.866',
              lng: '101.289',
            },
            zipcode: 94000,
          },
          940105: {
            name: {
              th: 'ตันหยงลุโละ',
              en: 'Tanyong Lulo',
            },
            coordinates: {
              lat: '6.877',
              lng: '101.305',
            },
            zipcode: 94000,
          },
          940106: {
            name: {
              th: 'คลองมานิง',
              en: 'Khlong Maning',
            },
            coordinates: {
              lat: '6.845',
              lng: '101.293',
            },
            zipcode: 94000,
          },
          940107: {
            name: {
              th: 'กะมิยอ',
              en: 'Kami Yo',
            },
            coordinates: {
              lat: '6.854',
              lng: '101.318',
            },
            zipcode: 94000,
          },
          940108: {
            name: {
              th: 'บาราโหม',
              en: 'Bara Hom',
            },
            coordinates: {
              lat: '6.873',
              lng: '101.317',
            },
            zipcode: 94000,
          },
          940109: {
            name: {
              th: 'ปะกาฮะรัง',
              en: 'Paka Harang',
            },
            coordinates: {
              lat: '6.833',
              lng: '101.232',
            },
            zipcode: 94000,
          },
          940110: {
            name: {
              th: 'รูสะมิแล',
              en: 'Ru Samilae',
            },
            coordinates: {
              lat: '6.864',
              lng: '101.207',
            },
            zipcode: 94000,
          },
          940111: {
            name: {
              th: 'ตะลุโบะ',
              en: 'Talu Bo',
            },
            coordinates: {
              lat: '6.853',
              lng: '101.267',
            },
            zipcode: 94000,
          },
          940112: {
            name: {
              th: 'บาราเฮาะ',
              en: 'Bara Ho',
            },
            coordinates: {
              lat: '6.829',
              lng: '101.267',
            },
            zipcode: 94000,
          },
          940113: {
            name: {
              th: 'ปุยุด',
              en: 'Pu Yut',
            },
            coordinates: {
              lat: '6.811',
              lng: '101.268',
            },
            zipcode: 94000,
          },
        },
      },
      9402: {
        name: {
          th: 'โคกโพธิ์',
          en: 'Khok Pho',
        },
        tambons: {
          940201: {
            name: {
              th: 'โคกโพธิ์',
              en: 'Khok Pho',
            },
            coordinates: {
              lat: '6.732',
              lng: '101.061',
            },
            zipcode: 94120,
          },
          940202: {
            name: {
              th: 'มะกรูด',
              en: 'Makrut',
            },
            coordinates: {
              lat: '6.738',
              lng: '101.119',
            },
            zipcode: 94120,
          },
          940203: {
            name: {
              th: 'บางโกระ',
              en: 'Bang Kro',
            },
            coordinates: {
              lat: '6.760',
              lng: '101.108',
            },
            zipcode: 94120,
          },
          940204: {
            name: {
              th: 'ป่าบอน',
              en: 'Pa Bon',
            },
            coordinates: {
              lat: '6.701',
              lng: '101.116',
            },
            zipcode: 94120,
          },
          940205: {
            name: {
              th: 'ทรายขาว',
              en: 'Sai Khao',
            },
            coordinates: {
              lat: '6.665',
              lng: '101.096',
            },
            zipcode: 94120,
          },
          940206: {
            name: {
              th: 'นาประดู่',
              en: 'Na Pradu',
            },
            coordinates: {
              lat: '6.689',
              lng: '101.142',
            },
            zipcode: 94180,
          },
          940207: {
            name: {
              th: 'ปากล่อ',
              en: 'Pak Lo',
            },
            coordinates: {
              lat: '6.620',
              lng: '101.187',
            },
            zipcode: 94180,
          },
          940208: {
            name: {
              th: 'ทุ่งพลา',
              en: 'Thung Phla',
            },
            coordinates: {
              lat: '6.641',
              lng: '101.152',
            },
            zipcode: 94180,
          },
          940211: {
            name: {
              th: 'ท่าเรือ',
              en: 'Tha Ruea',
            },
            coordinates: {
              lat: '6.780',
              lng: '101.053',
            },
            zipcode: 94120,
          },
          940213: {
            name: {
              th: 'นาเกตุ',
              en: 'Na Ket',
            },
            coordinates: {
              lat: '6.744',
              lng: '101.155',
            },
            zipcode: 94120,
          },
          940214: {
            name: {
              th: 'ควนโนรี',
              en: 'Khuan Nori',
            },
            coordinates: {
              lat: '6.719',
              lng: '101.199',
            },
            zipcode: 94180,
          },
          940215: {
            name: {
              th: 'ช้างให้ตก',
              en: 'Chang Hai Tok',
            },
            coordinates: {
              lat: '6.672',
              lng: '101.062',
            },
            zipcode: 94120,
          },
        },
      },
      9403: {
        name: {
          th: 'หนองจิก',
          en: 'Nong Chik',
        },
        tambons: {
          940301: {
            name: {
              th: 'เกาะเปาะ',
              en: 'Ko Po',
            },
            coordinates: {
              lat: '6.815',
              lng: '101.201',
            },
            zipcode: 94170,
          },
          940302: {
            name: {
              th: 'คอลอตันหยง',
              en: 'Kholo Tanyong',
            },
            coordinates: {
              lat: '6.744',
              lng: '101.222',
            },
            zipcode: 94170,
          },
          940303: {
            name: {
              th: 'ดอนรัก',
              en: 'Don Rak',
            },
            coordinates: {
              lat: '6.848',
              lng: '101.220',
            },
            zipcode: 94170,
          },
          940304: {
            name: {
              th: 'ดาโต๊ะ',
              en: 'Dato',
            },
            coordinates: {
              lat: '6.717',
              lng: '101.244',
            },
            zipcode: 94170,
          },
          940305: {
            name: {
              th: 'ตุยง',
              en: 'Tuyong',
            },
            coordinates: {
              lat: '6.832',
              lng: '101.178',
            },
            zipcode: 94170,
          },
          940306: {
            name: {
              th: 'ท่ากำชำ',
              en: 'Tha Kamcham',
            },
            coordinates: {
              lat: '6.826',
              lng: '101.072',
            },
            zipcode: 94170,
          },
          940307: {
            name: {
              th: 'บ่อทอง',
              en: 'Bo Thong',
            },
            coordinates: {
              lat: '6.789',
              lng: '101.135',
            },
            zipcode: 94170,
          },
          940308: {
            name: {
              th: 'บางเขา',
              en: 'Bang Khao',
            },
            coordinates: {
              lat: '6.834',
              lng: '101.130',
            },
            zipcode: 94170,
          },
          940309: {
            name: {
              th: 'บางตาวา',
              en: 'Bang Tawa',
            },
            coordinates: {
              lat: '6.858',
              lng: '101.158',
            },
            zipcode: 94170,
          },
          940310: {
            name: {
              th: 'ปุโละปุโย',
              en: 'Pulo Puyo',
            },
            coordinates: {
              lat: '6.774',
              lng: '101.194',
            },
            zipcode: 94170,
          },
          940311: {
            name: {
              th: 'ยาบี',
              en: 'Ya Bi',
            },
            coordinates: {
              lat: '6.782',
              lng: '101.246',
            },
            zipcode: 94170,
          },
          940312: {
            name: {
              th: 'ลิปะสะโง',
              en: 'Lipa Sa-Ngo',
            },
            coordinates: {
              lat: '6.805',
              lng: '101.231',
            },
            zipcode: 94170,
          },
        },
      },
      9404: {
        name: {
          th: 'ปะนาเระ',
          en: 'Panare',
        },
        tambons: {
          940401: {
            name: {
              th: 'ปะนาเระ',
              en: 'Panare',
            },
            coordinates: {
              lat: '6.860',
              lng: '101.484',
            },
            zipcode: 94130,
          },
          940402: {
            name: {
              th: 'ท่าข้าม',
              en: 'Tha Kham',
            },
            coordinates: {
              lat: '6.838',
              lng: '101.477',
            },
            zipcode: 94130,
          },
          940403: {
            name: {
              th: 'บ้านนอก',
              en: 'Ban Nok',
            },
            coordinates: {
              lat: '6.812',
              lng: '101.479',
            },
            zipcode: 94130,
          },
          940404: {
            name: {
              th: 'ดอน',
              en: 'Don',
            },
            coordinates: {
              lat: '6.786',
              lng: '101.482',
            },
            zipcode: 94190,
          },
          940405: {
            name: {
              th: 'ควน',
              en: 'Khuan',
            },
            coordinates: {
              lat: '6.762',
              lng: '101.493',
            },
            zipcode: 94190,
          },
          940406: {
            name: {
              th: 'ท่าน้ำ',
              en: 'Tha Nam',
            },
            coordinates: {
              lat: '6.771',
              lng: '101.515',
            },
            zipcode: 94130,
          },
          940407: {
            name: {
              th: 'คอกกระบือ',
              en: 'Khok Krabue',
            },
            coordinates: {
              lat: '6.800',
              lng: '101.520',
            },
            zipcode: 94130,
          },
          940408: {
            name: {
              th: 'พ่อมิ่ง',
              en: 'Pho Ming',
            },
            coordinates: {
              lat: '6.786',
              lng: '101.554',
            },
            zipcode: 94130,
          },
          940409: {
            name: {
              th: 'บ้านกลาง',
              en: 'Ban Klang',
            },
            coordinates: {
              lat: '6.839',
              lng: '101.521',
            },
            zipcode: 94130,
          },
          940410: {
            name: {
              th: 'บ้านน้ำบ่อ',
              en: 'Ban Nam Bo',
            },
            coordinates: {
              lat: '6.817',
              lng: '101.563',
            },
            zipcode: 94130,
          },
        },
      },
      9405: {
        name: {
          th: 'มายอ',
          en: 'Mayo',
        },
        tambons: {
          940501: {
            name: {
              th: 'มายอ',
              en: 'Mayo',
            },
            coordinates: {
              lat: '6.710',
              lng: '101.425',
            },
            zipcode: 94140,
          },
          940502: {
            name: {
              th: 'ถนน',
              en: 'Thanon',
            },
            coordinates: {
              lat: '6.750',
              lng: '101.419',
            },
            zipcode: 94140,
          },
          940503: {
            name: {
              th: 'ตรัง',
              en: 'Trang',
            },
            coordinates: {
              lat: '6.693',
              lng: '101.375',
            },
            zipcode: 94140,
          },
          940504: {
            name: {
              th: 'กระหวะ',
              en: 'Krawa',
            },
            coordinates: {
              lat: '6.759',
              lng: '101.441',
            },
            zipcode: 94190,
          },
          940505: {
            name: {
              th: 'ลุโบะยิไร',
              en: 'Lubo Yiri',
            },
            coordinates: {
              lat: '6.666',
              lng: '101.417',
            },
            zipcode: 94140,
          },
          940506: {
            name: {
              th: 'ลางา',
              en: 'La-Nga',
            },
            coordinates: {
              lat: '6.735',
              lng: '101.478',
            },
            zipcode: 94190,
          },
          940507: {
            name: {
              th: 'กระเสาะ',
              en: 'Kraso',
            },
            coordinates: {
              lat: '6.749',
              lng: '101.397',
            },
            zipcode: 94140,
          },
          940508: {
            name: {
              th: 'เกาะจัน',
              en: 'Ko Chan',
            },
            coordinates: {
              lat: '6.723',
              lng: '101.382',
            },
            zipcode: 94140,
          },
          940509: {
            name: {
              th: 'ปะโด',
              en: 'Podo',
            },
            coordinates: {
              lat: '6.666',
              lng: '101.359',
            },
            zipcode: 94140,
          },
          940510: {
            name: {
              th: 'สาคอบน',
              en: 'Sakho Bon',
            },
            coordinates: {
              lat: '6.730',
              lng: '101.352',
            },
            zipcode: 94140,
          },
          940511: {
            name: {
              th: 'สาคอใต้',
              en: 'Sakho Tai',
            },
            coordinates: {
              lat: '6.749',
              lng: '101.357',
            },
            zipcode: 94140,
          },
          940512: {
            name: {
              th: 'สะกำ',
              en: 'Sakam',
            },
            coordinates: {
              lat: '6.720',
              lng: '101.462',
            },
            zipcode: 94140,
          },
          940513: {
            name: {
              th: 'ปานัน',
              en: 'Pa Nan',
            },
            coordinates: {
              lat: '6.721',
              lng: '101.334',
            },
            zipcode: 94140,
          },
        },
      },
      9406: {
        name: {
          th: 'ทุ่งยางแดง',
          en: 'Thung Yang Daeng',
        },
        tambons: {
          940601: {
            name: {
              th: 'ตะโละแมะนา',
              en: 'Talo Maena',
            },
            coordinates: {
              lat: '6.604',
              lng: '101.401',
            },
            zipcode: 94140,
          },
          940602: {
            name: {
              th: 'พิเทน',
              en: 'Phithen',
            },
            coordinates: {
              lat: '6.679',
              lng: '101.467',
            },
            zipcode: 94140,
          },
          940603: {
            name: {
              th: 'น้ำดำ',
              en: 'Nam Dam',
            },
            coordinates: {
              lat: '6.605',
              lng: '101.448',
            },
            zipcode: 94140,
          },
          940604: {
            name: {
              th: 'ปากู',
              en: 'Pa Ku',
            },
            coordinates: {
              lat: '6.624',
              lng: '101.474',
            },
            zipcode: 94140,
          },
        },
      },
      9407: {
        name: {
          th: 'สายบุรี',
          en: 'Sai Buri',
        },
        tambons: {
          940701: {
            name: {
              th: 'ตะลุปัน',
              en: 'Talu Ban',
            },
            coordinates: {
              lat: '6.690',
              lng: '101.636',
            },
            zipcode: 94110,
          },
          940702: {
            name: {
              th: 'ตะบิ้ง',
              en: 'Tabing',
            },
            coordinates: {
              lat: '6.681',
              lng: '101.589',
            },
            zipcode: 94110,
          },
          940703: {
            name: {
              th: 'ปะเสยะวอ',
              en: 'Pase Yawo',
            },
            coordinates: {
              lat: '6.733',
              lng: '101.606',
            },
            zipcode: 94110,
          },
          940704: {
            name: {
              th: 'บางเก่า',
              en: 'Bang Kao',
            },
            coordinates: {
              lat: '6.764',
              lng: '101.591',
            },
            zipcode: 94110,
          },
          940705: {
            name: {
              th: 'บือเระ',
              en: 'Buere',
            },
            coordinates: {
              lat: '6.704',
              lng: '101.584',
            },
            zipcode: 94110,
          },
          940706: {
            name: {
              th: 'เตราะบอน',
              en: 'Tro Bon',
            },
            coordinates: {
              lat: '6.688',
              lng: '101.543',
            },
            zipcode: 94110,
          },
          940707: {
            name: {
              th: 'กะดุนง',
              en: 'Kadunong',
            },
            coordinates: {
              lat: '6.646',
              lng: '101.554',
            },
            zipcode: 94110,
          },
          940708: {
            name: {
              th: 'ละหาร',
              en: 'Lahan',
            },
            coordinates: {
              lat: '6.659',
              lng: '101.627',
            },
            zipcode: 94110,
          },
          940709: {
            name: {
              th: 'มะนังดาลำ',
              en: 'Manang Dalam',
            },
            coordinates: {
              lat: '6.649',
              lng: '101.599',
            },
            zipcode: 94110,
          },
          940710: {
            name: {
              th: 'แป้น',
              en: 'Paen',
            },
            coordinates: {
              lat: '6.747',
              lng: '101.553',
            },
            zipcode: 94110,
          },
          940711: {
            name: {
              th: 'ทุ่งคล้า',
              en: 'Thung Khla',
            },
            coordinates: {
              lat: '6.718',
              lng: '101.528',
            },
            zipcode: 94190,
          },
        },
      },
      9408: {
        name: {
          th: 'ไม้แก่น',
          en: 'Mai Kaen',
        },
        tambons: {
          940801: {
            name: {
              th: 'ไทรทอง',
              en: 'Sai Thong',
            },
            coordinates: {
              lat: '6.604',
              lng: '101.673',
            },
            zipcode: 94220,
          },
          940802: {
            name: {
              th: 'ไม้แก่น',
              en: 'Mai Kaen',
            },
            coordinates: {
              lat: '6.647',
              lng: '101.669',
            },
            zipcode: 94220,
          },
          940803: {
            name: {
              th: 'ตะโละไกรทอง',
              en: 'Talo Krai Thong',
            },
            coordinates: {
              lat: '6.627',
              lng: '101.640',
            },
            zipcode: 94220,
          },
          940804: {
            name: {
              th: 'คอนทราย',
              en: 'Don Sai',
            },
            coordinates: {
              lat: '6.597',
              lng: '101.701',
            },
            zipcode: 94220,
          },
        },
      },
      9409: {
        name: {
          th: 'ยะหริ่ง',
          en: 'Yaring',
        },
        tambons: {
          940901: {
            name: {
              th: 'ตะโละ',
              en: 'Talo',
            },
            coordinates: {
              lat: '6.782',
              lng: '101.353',
            },
            zipcode: 94150,
          },
          940902: {
            name: {
              th: 'ตะโละกาโปร์',
              en: 'Talo Kapo',
            },
            coordinates: {
              lat: '6.872',
              lng: '101.430',
            },
            zipcode: 94150,
          },
          940903: {
            name: {
              th: 'ตันหยงดาลอ',
              en: 'Tanyong Dalo',
            },
            coordinates: {
              lat: '6.808',
              lng: '101.423',
            },
            zipcode: 94150,
          },
          940904: {
            name: {
              th: 'ตันหยงจึงงา',
              en: 'Tanyong Chueng Nga',
            },
            coordinates: {
              lat: '6.787',
              lng: '101.458',
            },
            zipcode: 94190,
          },
          940905: {
            name: {
              th: 'ตอหลัง',
              en: 'To Lang',
            },
            coordinates: {
              lat: '6.802',
              lng: '101.448',
            },
            zipcode: 94150,
          },
          940906: {
            name: {
              th: 'ตาแกะ',
              en: 'Ta Kae',
            },
            coordinates: {
              lat: '6.844',
              lng: '101.336',
            },
            zipcode: 94150,
          },
          940907: {
            name: {
              th: 'ตาลีอายร์',
              en: 'Tali-Ai',
            },
            coordinates: {
              lat: '6.789',
              lng: '101.389',
            },
            zipcode: 94150,
          },
          940908: {
            name: {
              th: 'ยามู',
              en: 'Ya Mu',
            },
            coordinates: {
              lat: '6.855',
              lng: '101.372',
            },
            zipcode: 94150,
          },
          940909: {
            name: {
              th: 'บางปู',
              en: 'Bang Pu',
            },
            coordinates: {
              lat: '6.868',
              lng: '101.335',
            },
            zipcode: 94150,
          },
          940910: {
            name: {
              th: 'หนองแรต',
              en: 'Nong Raet',
            },
            coordinates: {
              lat: '6.849',
              lng: '101.403',
            },
            zipcode: 94150,
          },
          940911: {
            name: {
              th: 'ปิยามุมัง',
              en: 'Piya Mumang',
            },
            coordinates: {
              lat: '6.823',
              lng: '101.376',
            },
            zipcode: 94150,
          },
          940912: {
            name: {
              th: 'ปุลากง',
              en: 'Pulakong',
            },
            coordinates: {
              lat: '6.769',
              lng: '101.391',
            },
            zipcode: 94150,
          },
          940913: {
            name: {
              th: 'บาโลย',
              en: 'Baloi',
            },
            coordinates: {
              lat: '6.781',
              lng: '101.439',
            },
            zipcode: 94190,
          },
          940914: {
            name: {
              th: 'สาบัน',
              en: 'Saban',
            },
            coordinates: {
              lat: '6.820',
              lng: '101.439',
            },
            zipcode: 94150,
          },
          940915: {
            name: {
              th: 'มะนังยง',
              en: 'Manang Yong',
            },
            coordinates: {
              lat: '6.813',
              lng: '101.402',
            },
            zipcode: 94150,
          },
          940916: {
            name: {
              th: 'ราตาปันยัง',
              en: 'Rata Panyang',
            },
            coordinates: {
              lat: '6.822',
              lng: '101.353',
            },
            zipcode: 94150,
          },
          940917: {
            name: {
              th: 'จะรัง',
              en: 'Charang',
            },
            coordinates: {
              lat: '6.843',
              lng: '101.450',
            },
            zipcode: 94150,
          },
          940918: {
            name: {
              th: 'แหลมโพธิ์',
              en: 'Laem Pho',
            },
            coordinates: {
              lat: '6.942',
              lng: '101.296',
            },
            zipcode: 94150,
          },
        },
      },
      9410: {
        name: {
          th: 'ยะรัง',
          en: 'Yarang',
        },
        tambons: {
          941001: {
            name: {
              th: 'ยะรัง',
              en: 'Yarang',
            },
            coordinates: {
              lat: '6.769',
              lng: '101.299',
            },
            zipcode: 94160,
          },
          941002: {
            name: {
              th: 'สะดาวา',
              en: 'Sadawa',
            },
            coordinates: {
              lat: '6.821',
              lng: '101.311',
            },
            zipcode: 94160,
          },
          941003: {
            name: {
              th: 'ประจัน',
              en: 'Prachan',
            },
            coordinates: {
              lat: '6.797',
              lng: '101.289',
            },
            zipcode: 94160,
          },
          941004: {
            name: {
              th: 'สะนอ',
              en: 'Sano',
            },
            coordinates: {
              lat: '6.789',
              lng: '101.323',
            },
            zipcode: 94160,
          },
          941005: {
            name: {
              th: 'ระแว้ง',
              en: 'Rawaeng',
            },
            coordinates: {
              lat: '6.755',
              lng: '101.324',
            },
            zipcode: 94160,
          },
          941006: {
            name: {
              th: 'ปิตูมุดี',
              en: 'Pitu Mudi',
            },
            coordinates: {
              lat: '6.743',
              lng: '101.284',
            },
            zipcode: 94160,
          },
          941007: {
            name: {
              th: 'วัด',
              en: 'Wat',
            },
            coordinates: {
              lat: '6.735',
              lng: '101.308',
            },
            zipcode: 94160,
          },
          941008: {
            name: {
              th: 'กระโด',
              en: 'Krado',
            },
            coordinates: {
              lat: '6.717',
              lng: '101.299',
            },
            zipcode: 94160,
          },
          941009: {
            name: {
              th: 'คลองใหม่',
              en: 'Khlong Mai',
            },
            coordinates: {
              lat: '6.735',
              lng: '101.262',
            },
            zipcode: 94160,
          },
          941010: {
            name: {
              th: 'เมาะมาวี',
              en: 'Mo Mawi',
            },
            coordinates: {
              lat: '6.670',
              lng: '101.303',
            },
            zipcode: 94160,
          },
          941011: {
            name: {
              th: 'กอลำ',
              en: 'Ko Lam',
            },
            coordinates: {
              lat: '6.673',
              lng: '101.329',
            },
            zipcode: 94160,
          },
          941012: {
            name: {
              th: 'เขาตูม',
              en: 'Khao Tum',
            },
            coordinates: {
              lat: '6.603',
              lng: '101.314',
            },
            zipcode: 94160,
          },
        },
      },
      9411: {
        name: {
          th: 'กะพ้อ',
          en: 'Kapho',
        },
        tambons: {
          941101: {
            name: {
              th: 'กะรุบี',
              en: 'Karubi',
            },
            coordinates: {
              lat: '6.570',
              lng: '101.556',
            },
            zipcode: 94230,
          },
          941102: {
            name: {
              th: 'ตะโละดือรามัน',
              en: 'Talo Due Raman',
            },
            coordinates: {
              lat: '6.603',
              lng: '101.554',
            },
            zipcode: 94230,
          },
          941103: {
            name: {
              th: 'ปล่องหอย',
              en: 'Plong Hoi',
            },
            coordinates: {
              lat: '6.638',
              lng: '101.509',
            },
            zipcode: 94230,
          },
        },
      },
      9412: {
        name: {
          th: 'แม่ลาน',
          en: 'Mae Lan',
        },
        tambons: {
          941201: {
            name: {
              th: 'แม่ลาน',
              en: 'Mae Lan',
            },
            coordinates: {
              lat: '6.647',
              lng: '101.231',
            },
            zipcode: null,
          },
          941202: {
            name: {
              th: 'ม่วงเตี้ย',
              en: 'Muang Tia',
            },
            coordinates: {
              lat: '6.677',
              lng: '101.266',
            },
            zipcode: null,
          },
          941203: {
            name: {
              th: 'ป่าไร่',
              en: 'Pa Rai',
            },
            coordinates: {
              lat: '6.685',
              lng: '101.214',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  95: {
    name: {
      th: 'ยะลา',
      en: 'Yala',
    },
    amphoes: {
      9501: {
        name: {
          th: 'เมืองยะลา',
          en: 'Mueang Yala',
        },
        tambons: {
          950101: {
            name: {
              th: 'สะเตง',
              en: 'Sateng',
            },
            coordinates: {
              lat: '6.547',
              lng: '101.280',
            },
            zipcode: 95000,
          },
          950102: {
            name: {
              th: 'บุดี',
              en: 'Bu Di',
            },
            coordinates: {
              lat: '6.487',
              lng: '101.305',
            },
            zipcode: 95000,
          },
          950103: {
            name: {
              th: 'ยุโป',
              en: null,
            },
            coordinates: {
              lat: '6.597',
              lng: '101.283',
            },
            zipcode: 95000,
          },
          950104: {
            name: {
              th: 'ลิดล',
              en: 'Li Don',
            },
            coordinates: {
              lat: '6.551',
              lng: '101.166',
            },
            zipcode: 95160,
          },
          950106: {
            name: {
              th: 'ยะลา',
              en: 'Yala',
            },
            coordinates: {
              lat: '6.523',
              lng: '101.181',
            },
            zipcode: 95000,
          },
          950108: {
            name: {
              th: 'ท่าสาป',
              en: 'Tha Sap',
            },
            coordinates: {
              lat: '6.538',
              lng: '101.235',
            },
            zipcode: 95000,
          },
          950109: {
            name: {
              th: 'ลำใหม่',
              en: 'Lam Mai',
            },
            coordinates: {
              lat: '6.583',
              lng: '101.205',
            },
            zipcode: 95160,
          },
          950110: {
            name: {
              th: 'หน้าถ้ำ',
              en: 'Na Tham',
            },
            coordinates: {
              lat: '6.525',
              lng: '101.225',
            },
            zipcode: 95000,
          },
          950111: {
            name: {
              th: 'ลำพะยา',
              en: 'Lam Phaya',
            },
            coordinates: {
              lat: '6.584',
              lng: '101.162',
            },
            zipcode: 95160,
          },
          950112: {
            name: {
              th: 'เปาะเส้ง',
              en: 'Po Seng',
            },
            coordinates: {
              lat: '6.511',
              lng: '101.201',
            },
            zipcode: 95000,
          },
          950114: {
            name: {
              th: 'พร่อน',
              en: 'Phron',
            },
            coordinates: {
              lat: '6.563',
              lng: '101.229',
            },
            zipcode: 95160,
          },
          950115: {
            name: {
              th: 'บันนังสาเรง',
              en: 'Bannang Sareng',
            },
            coordinates: {
              lat: '6.480',
              lng: '101.258',
            },
            zipcode: 95000,
          },
          950116: {
            name: {
              th: 'สะเตงนอก',
              en: 'Sateng Nok',
            },
            coordinates: {
              lat: '6.552',
              lng: '101.324',
            },
            zipcode: 95000,
          },
          950118: {
            name: {
              th: 'ตาเซะ',
              en: 'Tase',
            },
            coordinates: {
              lat: '6.620',
              lng: '101.253',
            },
            zipcode: 95000,
          },
        },
      },
      9502: {
        name: {
          th: 'เบตง',
          en: 'Betong',
        },
        tambons: {
          950201: {
            name: {
              th: 'เบตง',
              en: 'Betong',
            },
            coordinates: {
              lat: '5.803',
              lng: '101.009',
            },
            zipcode: 95110,
          },
          950202: {
            name: {
              th: 'ยะรม',
              en: 'Yarom',
            },
            coordinates: {
              lat: '5.771',
              lng: '101.203',
            },
            zipcode: 95110,
          },
          950203: {
            name: {
              th: 'ตาเนาะแมเราะ',
              en: 'Tano Maero',
            },
            coordinates: {
              lat: '5.853',
              lng: '101.099',
            },
            zipcode: 95110,
          },
          950204: {
            name: {
              th: 'อัยเยอร์เวง',
              en: 'Aiyoe Weng',
            },
            coordinates: {
              lat: '5.963',
              lng: '101.398',
            },
            zipcode: 95110,
          },
          950205: {
            name: {
              th: 'ธารน้ำทิพย์',
              en: 'Than Nam Thip',
            },
            coordinates: {
              lat: '5.686',
              lng: '101.141',
            },
            zipcode: 95110,
          },
        },
      },
      9503: {
        name: {
          th: 'บันนังสตา',
          en: 'Bannang Sata',
        },
        tambons: {
          950301: {
            name: {
              th: 'บันนังสตา',
              en: 'Bannang Sata',
            },
            coordinates: {
              lat: '6.250',
              lng: '101.233',
            },
            zipcode: 95130,
          },
          950302: {
            name: {
              th: 'บาเจาะ',
              en: 'Bacho',
            },
            coordinates: {
              lat: '6.212',
              lng: '101.291',
            },
            zipcode: 95130,
          },
          950303: {
            name: {
              th: 'ตาเนาะปูเต๊ะ',
              en: 'Tano Pute',
            },
            coordinates: {
              lat: '6.355',
              lng: '101.327',
            },
            zipcode: 95130,
          },
          950304: {
            name: {
              th: 'ถ้ำทะลุ',
              en: 'Tham Thalu',
            },
            coordinates: {
              lat: '6.238',
              lng: '101.147',
            },
            zipcode: 95130,
          },
          950305: {
            name: {
              th: 'ตลิ่งชัน',
              en: 'Taling Chan',
            },
            coordinates: {
              lat: '6.271',
              lng: '101.352',
            },
            zipcode: 95130,
          },
          950306: {
            name: {
              th: 'เขื่อนบางลาง',
              en: 'Khuean Bang Lang',
            },
            coordinates: {
              lat: '6.142',
              lng: '101.309',
            },
            zipcode: 95130,
          },
        },
      },
      9504: {
        name: {
          th: 'ธารโต',
          en: 'Than To',
        },
        tambons: {
          950401: {
            name: {
              th: 'ธารโต',
              en: 'Than To',
            },
            coordinates: {
              lat: '6.167',
              lng: '101.187',
            },
            zipcode: null,
          },
          950402: {
            name: {
              th: 'บ้านแหร',
              en: 'Ban Rae',
            },
            coordinates: {
              lat: '6.105',
              lng: '101.204',
            },
            zipcode: null,
          },
          950403: {
            name: {
              th: 'แม่หวาด',
              en: 'Mae Wat',
            },
            coordinates: {
              lat: '6.102',
              lng: '101.384',
            },
            zipcode: null,
          },
          950404: {
            name: {
              th: 'คีรีเขต',
              en: 'Khiri Khet',
            },
            coordinates: {
              lat: '6.153',
              lng: '101.116',
            },
            zipcode: 95150,
          },
        },
      },
      9505: {
        name: {
          th: 'ยะหา',
          en: 'Yaha',
        },
        tambons: {
          950501: {
            name: {
              th: 'ยะหา',
              en: 'Yaha',
            },
            coordinates: {
              lat: '6.504',
              lng: '101.125',
            },
            zipcode: 95120,
          },
          950502: {
            name: {
              th: 'ละแอ',
              en: 'La-Ae',
            },
            coordinates: {
              lat: '6.466',
              lng: '101.186',
            },
            zipcode: 95120,
          },
          950503: {
            name: {
              th: 'ปะแต',
              en: 'Patae',
            },
            coordinates: {
              lat: '6.344',
              lng: '101.139',
            },
            zipcode: 95120,
          },
          950504: {
            name: {
              th: 'บาโร๊ะ',
              en: 'Baro',
            },
            coordinates: {
              lat: '6.457',
              lng: '101.133',
            },
            zipcode: 95120,
          },
          950506: {
            name: {
              th: 'ตาชี',
              en: 'Ta Chi',
            },
            coordinates: {
              lat: '6.563',
              lng: '101.115',
            },
            zipcode: 95120,
          },
          950507: {
            name: {
              th: 'บาโงยซิแน',
              en: 'Ba-Ngoi Sinae',
            },
            coordinates: {
              lat: '6.527',
              lng: '101.153',
            },
            zipcode: 95120,
          },
          950508: {
            name: {
              th: 'กาตอง',
              en: 'Katong',
            },
            coordinates: {
              lat: '6.502',
              lng: '101.063',
            },
            zipcode: 95120,
          },
        },
      },
      9506: {
        name: {
          th: 'รามัน',
          en: 'Raman',
        },
        tambons: {
          950601: {
            name: {
              th: 'กายูบอเกาะ',
              en: 'Kayu Boko',
            },
            coordinates: {
              lat: '6.476',
              lng: '101.413',
            },
            zipcode: 95140,
          },
          950602: {
            name: {
              th: 'กาลูปัง',
              en: 'Kalupang',
            },
            coordinates: {
              lat: '6.464',
              lng: '101.374',
            },
            zipcode: 95140,
          },
          950603: {
            name: {
              th: 'กาลอ',
              en: 'Kalo',
            },
            coordinates: {
              lat: '6.378',
              lng: '101.383',
            },
            zipcode: 95140,
          },
          950604: {
            name: {
              th: 'กอตอตือร๊ะ',
              en: 'Koto Tuera',
            },
            coordinates: {
              lat: '6.500',
              lng: '101.389',
            },
            zipcode: 95140,
          },
          950605: {
            name: {
              th: 'โกตาบารู',
              en: 'Kota Baru',
            },
            coordinates: {
              lat: '6.452',
              lng: '101.345',
            },
            zipcode: 95140,
          },
          950606: {
            name: {
              th: 'เกะรอ',
              en: 'Ke Ro',
            },
            coordinates: {
              lat: '6.535',
              lng: '101.576',
            },
            zipcode: 95140,
          },
          950607: {
            name: {
              th: 'จะกว๊ะ',
              en: 'Cha Kwua',
            },
            coordinates: {
              lat: '6.498',
              lng: '101.536',
            },
            zipcode: 95140,
          },
          950608: {
            name: {
              th: 'ท่าธง',
              en: 'Tha Thong',
            },
            coordinates: {
              lat: '6.565',
              lng: '101.458',
            },
            zipcode: 95140,
          },
          950609: {
            name: {
              th: 'เนินงาม',
              en: 'Noen Ngam',
            },
            coordinates: {
              lat: '6.508',
              lng: '101.347',
            },
            zipcode: 95140,
          },
          950610: {
            name: {
              th: 'บาลอ',
              en: 'Balo',
            },
            coordinates: {
              lat: '6.448',
              lng: '101.445',
            },
            zipcode: 95140,
          },
          950611: {
            name: {
              th: 'บาโงย',
              en: 'Ba-Ngoi',
            },
            coordinates: {
              lat: '6.473',
              lng: '101.349',
            },
            zipcode: 95140,
          },
          950612: {
            name: {
              th: 'บือมัง',
              en: 'Buemang',
            },
            coordinates: {
              lat: '6.411',
              lng: '101.336',
            },
            zipcode: 95140,
          },
          950613: {
            name: {
              th: 'ยะต๊ะ',
              en: 'Yata',
            },
            coordinates: {
              lat: '6.426',
              lng: '101.407',
            },
            zipcode: 95140,
          },
          950614: {
            name: {
              th: 'วังพญา',
              en: 'Wang Phaya',
            },
            coordinates: {
              lat: '6.544',
              lng: '101.375',
            },
            zipcode: 95140,
          },
          950615: {
            name: {
              th: 'อาซ่อง',
              en: 'A Song',
            },
            coordinates: {
              lat: '6.520',
              lng: '101.451',
            },
            zipcode: 95140,
          },
          950616: {
            name: {
              th: 'ตะโล๊ะหะลอ',
              en: 'Talo Halo',
            },
            coordinates: {
              lat: '6.479',
              lng: '101.485',
            },
            zipcode: 95140,
          },
        },
      },
      9507: {
        name: {
          th: 'กาบัง',
          en: 'Kabang',
        },
        tambons: {
          950701: {
            name: {
              th: 'กาบัง',
              en: 'Kabang',
            },
            coordinates: {
              lat: '6.449',
              lng: '101.000',
            },
            zipcode: null,
          },
          950702: {
            name: {
              th: 'บาละ',
              en: 'Bala',
            },
            coordinates: {
              lat: '6.330',
              lng: '101.023',
            },
            zipcode: null,
          },
        },
      },
      9508: {
        name: {
          th: 'กรงปีนัง',
          en: 'Krong Pinang',
        },
        tambons: {
          950801: {
            name: {
              th: 'กรงปินัง',
              en: 'Krong Pinang',
            },
            coordinates: {
              lat: '6.423',
              lng: '101.285',
            },
            zipcode: null,
          },
          950802: {
            name: {
              th: 'สะเอะ',
              en: 'Sa-Ae',
            },
            coordinates: {
              lat: '6.398',
              lng: '101.202',
            },
            zipcode: null,
          },
          950803: {
            name: {
              th: 'ห้วยกระทิง',
              en: 'Huai Krathing',
            },
            coordinates: {
              lat: '6.344',
              lng: '101.250',
            },
            zipcode: null,
          },
          950804: {
            name: {
              th: 'ปุโรง',
              en: 'Pu Rong',
            },
            coordinates: {
              lat: '6.456',
              lng: '101.230',
            },
            zipcode: null,
          },
        },
      },
    },
  },
  96: {
    name: {
      th: 'นราธิวาส',
      en: 'Narathiwat',
    },
    amphoes: {
      9601: {
        name: {
          th: 'เมืองนราธิวาส',
          en: 'Mueang Narathiwat',
        },
        tambons: {
          960101: {
            name: {
              th: 'บางนาค',
              en: 'Bang Nak',
            },
            coordinates: {
              lat: '6.424',
              lng: '101.820',
            },
            zipcode: 96000,
          },
          960102: {
            name: {
              th: 'ลำภู',
              en: 'Lamphu',
            },
            coordinates: {
              lat: '6.379',
              lng: '101.819',
            },
            zipcode: 96000,
          },
          960103: {
            name: {
              th: 'มะนังตายอ',
              en: 'Manang Tayo',
            },
            coordinates: {
              lat: '6.354',
              lng: '101.760',
            },
            zipcode: 96000,
          },
          960104: {
            name: {
              th: 'บางปอ',
              en: 'Bang Po',
            },
            coordinates: {
              lat: '6.330',
              lng: '101.804',
            },
            zipcode: 96000,
          },
          960105: {
            name: {
              th: 'กะลุวอ',
              en: 'Kaluwo',
            },
            coordinates: {
              lat: '6.342',
              lng: '101.874',
            },
            zipcode: 96000,
          },
          960106: {
            name: {
              th: 'กะลุวอเหนือ',
              en: 'Kaluwo Nuea',
            },
            coordinates: {
              lat: '6.404',
              lng: '101.862',
            },
            zipcode: 96000,
          },
          960107: {
            name: {
              th: 'โคกเคียน',
              en: 'Khok Khian',
            },
            coordinates: {
              lat: '6.482',
              lng: '101.763',
            },
            zipcode: 96000,
          },
        },
      },
      9602: {
        name: {
          th: 'ตากใบ',
          en: 'Tak Bai',
        },
        tambons: {
          960201: {
            name: {
              th: 'เจ๊ะเห',
              en: 'Che He',
            },
            coordinates: {
              lat: '6.257',
              lng: '102.051',
            },
            zipcode: 96110,
          },
          960202: {
            name: {
              th: 'ไพรวัน',
              en: 'Phrai Wan',
            },
            coordinates: {
              lat: '6.300',
              lng: '101.958',
            },
            zipcode: 96110,
          },
          960203: {
            name: {
              th: 'พร่อน',
              en: 'Phron',
            },
            coordinates: {
              lat: '6.202',
              lng: '102.014',
            },
            zipcode: 96110,
          },
          960204: {
            name: {
              th: 'ศาลาใหม่',
              en: 'Sala Mai',
            },
            coordinates: {
              lat: '6.282',
              lng: '102.008',
            },
            zipcode: 96110,
          },
          960205: {
            name: {
              th: 'บางขุนทอง',
              en: 'Bang Khun Thong',
            },
            coordinates: {
              lat: '6.234',
              lng: '101.967',
            },
            zipcode: 96110,
          },
          960206: {
            name: {
              th: 'เกาะสะท้อน',
              en: 'Ko Sathon',
            },
            coordinates: {
              lat: '6.220',
              lng: '102.059',
            },
            zipcode: 96110,
          },
          960207: {
            name: {
              th: 'นานาค',
              en: 'Na Nak',
            },
            coordinates: {
              lat: '6.142',
              lng: '102.070',
            },
            zipcode: 96110,
          },
          960208: {
            name: {
              th: 'โฆษิต',
              en: 'Khosit',
            },
            coordinates: {
              lat: '6.175',
              lng: '102.052',
            },
            zipcode: 96110,
          },
        },
      },
      9603: {
        name: {
          th: 'บาเจาะ',
          en: 'Tak Bai',
        },
        tambons: {
          960301: {
            name: {
              th: 'บาเจาะ',
              en: 'Bacho',
            },
            coordinates: {
              lat: '6.514',
              lng: '101.636',
            },
            zipcode: 96170,
          },
          960302: {
            name: {
              th: 'ลุโบะสาวอ',
              en: 'Lubo Sawo',
            },
            coordinates: {
              lat: '6.486',
              lng: '101.667',
            },
            zipcode: 96170,
          },
          960303: {
            name: {
              th: 'กาเยาะมาตี',
              en: 'Kayo Mati',
            },
            coordinates: {
              lat: '6.545',
              lng: '101.625',
            },
            zipcode: 96170,
          },
          960304: {
            name: {
              th: 'ปะลุกาสาเมาะ',
              en: 'Paluka Samo',
            },
            coordinates: {
              lat: '6.608',
              lng: '101.609',
            },
            zipcode: 96170,
          },
          960305: {
            name: {
              th: 'บาเระเหนือ',
              en: 'Bare Nuea',
            },
            coordinates: {
              lat: '6.571',
              lng: '101.613',
            },
            zipcode: 96170,
          },
          960306: {
            name: {
              th: 'บาเระใต้',
              en: 'Bare Tai',
            },
            coordinates: {
              lat: '6.539',
              lng: '101.693',
            },
            zipcode: 96170,
          },
        },
      },
      9604: {
        name: {
          th: 'ยี่งอ',
          en: 'Yi-ngo',
        },
        tambons: {
          960401: {
            name: {
              th: 'ยี่งอ',
              en: 'Yi-Ngo',
            },
            coordinates: {
              lat: '6.382',
              lng: '101.703',
            },
            zipcode: 96180,
          },
          960402: {
            name: {
              th: 'ละหาร',
              en: 'Lahan',
            },
            coordinates: {
              lat: '6.396',
              lng: '101.745',
            },
            zipcode: 96180,
          },
          960403: {
            name: {
              th: 'จอเบาะ',
              en: 'Cho Bo',
            },
            coordinates: {
              lat: '6.382',
              lng: '101.660',
            },
            zipcode: 96180,
          },
          960404: {
            name: {
              th: 'ลุโบะบายะ',
              en: 'Lubo Baya',
            },
            coordinates: {
              lat: '6.422',
              lng: '101.663',
            },
            zipcode: 96180,
          },
          960405: {
            name: {
              th: 'ลุโบะบือซา',
              en: 'Lubo Buesa',
            },
            coordinates: {
              lat: '6.430',
              lng: '101.725',
            },
            zipcode: 96180,
          },
          960406: {
            name: {
              th: 'ตะปอเยาะ',
              en: 'Tapo Yo',
            },
            coordinates: {
              lat: '6.457',
              lng: '101.663',
            },
            zipcode: 96180,
          },
        },
      },
      9605: {
        name: {
          th: 'ระแงะ',
          en: 'Ra-ngae',
        },
        tambons: {
          960501: {
            name: {
              th: 'ตันหยงมัส',
              en: 'Tanyong Mat',
            },
            coordinates: {
              lat: '6.311',
              lng: '101.724',
            },
            zipcode: 96130,
          },
          960502: {
            name: {
              th: 'ตันหยงลิมอ',
              en: 'Tanyong Limo',
            },
            coordinates: {
              lat: '6.283',
              lng: '101.775',
            },
            zipcode: 96130,
          },
          960506: {
            name: {
              th: 'บองอ',
              en: 'Bo-Ngo',
            },
            coordinates: {
              lat: '6.172',
              lng: '101.753',
            },
            zipcode: 96220,
          },
          960507: {
            name: {
              th: 'กาลิชา',
              en: 'Kalisa',
            },
            coordinates: {
              lat: '6.225',
              lng: '101.661',
            },
            zipcode: 96130,
          },
          960508: {
            name: {
              th: 'บาโงสะโต',
              en: 'Ba-Ngo Sato',
            },
            coordinates: {
              lat: '6.277',
              lng: '101.691',
            },
            zipcode: 96130,
          },
          960509: {
            name: {
              th: 'เฉลิม',
              en: 'Chaloem',
            },
            coordinates: {
              lat: '6.291',
              lng: '101.641',
            },
            zipcode: 96130,
          },
          960510: {
            name: {
              th: 'มะรือโบตก',
              en: 'Marue Bo Tok',
            },
            coordinates: {
              lat: '6.337',
              lng: '101.660',
            },
            zipcode: 96130,
          },
        },
      },
      9606: {
        name: {
          th: 'รือเสาะ',
          en: 'Rueso',
        },
        tambons: {
          960601: {
            name: {
              th: 'รือเสาะ',
              en: 'Rueso',
            },
            coordinates: {
              lat: '6.369',
              lng: '101.508',
            },
            zipcode: 96150,
          },
          960602: {
            name: {
              th: 'สาวอ',
              en: 'Sawo',
            },
            coordinates: {
              lat: '6.434',
              lng: '101.507',
            },
            zipcode: 96150,
          },
          960603: {
            name: {
              th: 'เรียง',
              en: 'Riang',
            },
            coordinates: {
              lat: '6.398',
              lng: '101.445',
            },
            zipcode: 96150,
          },
          960604: {
            name: {
              th: 'สามัคคี',
              en: 'Samakkhi',
            },
            coordinates: {
              lat: '6.423',
              lng: '101.587',
            },
            zipcode: 96150,
          },
          960605: {
            name: {
              th: 'บาตง',
              en: 'Batong',
            },
            coordinates: {
              lat: '6.336',
              lng: '101.445',
            },
            zipcode: 96150,
          },
          960606: {
            name: {
              th: 'ลาโละ',
              en: 'La Lo',
            },
            coordinates: {
              lat: '6.343',
              lng: '101.590',
            },
            zipcode: 96150,
          },
          960607: {
            name: {
              th: 'รือเสาะออก',
              en: 'Rueso Ok',
            },
            coordinates: {
              lat: '6.394',
              lng: '101.554',
            },
            zipcode: 96150,
          },
          960608: {
            name: {
              th: 'โคกสะตอ',
              en: 'Khok Sato',
            },
            coordinates: {
              lat: '6.282',
              lng: '101.449',
            },
            zipcode: 96150,
          },
          960609: {
            name: {
              th: 'สุวารี',
              en: 'Suwari',
            },
            coordinates: {
              lat: '6.458',
              lng: '101.611',
            },
            zipcode: 96150,
          },
        },
      },
      9607: {
        name: {
          th: 'ศรีสาคร',
          en: 'Si Sakhon',
        },
        tambons: {
          960701: {
            name: {
              th: 'ซากอ',
              en: 'Sa Ko',
            },
            coordinates: {
              lat: '6.230',
              lng: '101.522',
            },
            zipcode: 96210,
          },
          960702: {
            name: {
              th: 'ตะมะยูง',
              en: 'Tama Yung',
            },
            coordinates: {
              lat: '6.282',
              lng: '101.515',
            },
            zipcode: null,
          },
          960703: {
            name: {
              th: 'ศรีสาคร',
              en: 'Si Sakhon',
            },
            coordinates: {
              lat: '6.152',
              lng: '101.492',
            },
            zipcode: 96210,
          },
          960704: {
            name: {
              th: 'เชิงคีรี',
              en: 'Choeng Khiri',
            },
            coordinates: {
              lat: '6.270',
              lng: '101.570',
            },
            zipcode: 96210,
          },
          960705: {
            name: {
              th: 'กาหลง',
              en: 'Kalong',
            },
            coordinates: {
              lat: '6.213',
              lng: '101.414',
            },
            zipcode: 96210,
          },
          960706: {
            name: {
              th: 'ศรีบรรพต',
              en: 'Si Banphot',
            },
            coordinates: {
              lat: '6.152',
              lng: '101.574',
            },
            zipcode: 96210,
          },
        },
      },
      9608: {
        name: {
          th: 'แว้ง',
          en: 'Waeng',
        },
        tambons: {
          960801: {
            name: {
              th: 'แว้ง',
              en: 'Waeng',
            },
            coordinates: {
              lat: '5.936',
              lng: '101.835',
            },
            zipcode: 96160,
          },
          960802: {
            name: {
              th: 'กายูคละ',
              en: 'Kayu Khla',
            },
            coordinates: {
              lat: '5.974',
              lng: '101.911',
            },
            zipcode: 96160,
          },
          960803: {
            name: {
              th: 'ฆอเลาะ',
              en: 'Kho Lo',
            },
            coordinates: {
              lat: '5.906',
              lng: '101.912',
            },
            zipcode: 96160,
          },
          960804: {
            name: {
              th: 'โละจูด',
              en: 'Lo Chut',
            },
            coordinates: {
              lat: '5.831',
              lng: '101.834',
            },
            zipcode: 96160,
          },
          960805: {
            name: {
              th: 'แม่ดง',
              en: 'Mae Dong',
            },
            coordinates: {
              lat: '5.892',
              lng: '101.820',
            },
            zipcode: 96160,
          },
          960806: {
            name: {
              th: 'เอราวัณ',
              en: 'Erawan',
            },
            coordinates: {
              lat: '5.973',
              lng: '101.842',
            },
            zipcode: 96160,
          },
        },
      },
      9609: {
        name: {
          th: 'สุคิริน',
          en: 'Sukhirin',
        },
        tambons: {
          960901: {
            name: {
              th: 'มาโมง',
              en: 'Ma Mong',
            },
            coordinates: {
              lat: '5.876',
              lng: '101.764',
            },
            zipcode: 96190,
          },
          960902: {
            name: {
              th: 'สุคิริน',
              en: 'Sukhirin',
            },
            coordinates: {
              lat: '5.951',
              lng: '101.697',
            },
            zipcode: 96190,
          },
          960903: {
            name: {
              th: 'เกียร์',
              en: 'Kia',
            },
            coordinates: {
              lat: '5.984',
              lng: '101.775',
            },
            zipcode: 96190,
          },
          960904: {
            name: {
              th: 'ภูเขาทอง',
              en: 'Phu Khao Thong',
            },
            coordinates: {
              lat: '5.796',
              lng: '101.700',
            },
            zipcode: 96190,
          },
          960905: {
            name: {
              th: 'ร่มไทร',
              en: 'Rom Sai',
            },
            coordinates: {
              lat: '6.048',
              lng: '101.759',
            },
            zipcode: 96190,
          },
        },
      },
      9610: {
        name: {
          th: 'สุไหงโก-ลก',
          en: 'Su-ngai Kolok',
        },
        tambons: {
          961001: {
            name: {
              th: 'สุไหงโกลก',
              en: 'Su-Ngai Ko-Lok',
            },
            coordinates: {
              lat: '6.008',
              lng: '101.949',
            },
            zipcode: 96120,
          },
          961002: {
            name: {
              th: 'ป่าเสมัส',
              en: 'Pa Semat',
            },
            coordinates: {
              lat: '6.054',
              lng: '101.975',
            },
            zipcode: 96120,
          },
          961003: {
            name: {
              th: 'มูโนะ',
              en: 'Mu No',
            },
            coordinates: {
              lat: '6.108',
              lng: '102.052',
            },
            zipcode: 96120,
          },
          961004: {
            name: {
              th: 'ปูโยะ',
              en: 'Puyo',
            },
            coordinates: {
              lat: '6.109',
              lng: '101.993',
            },
            zipcode: 96120,
          },
        },
      },
      9611: {
        name: {
          th: 'สุไหงปาดี',
          en: 'Su-ngai Padi',
        },
        tambons: {
          961101: {
            name: {
              th: 'ปะลุรู',
              en: 'Paluru',
            },
            coordinates: {
              lat: '6.073',
              lng: '101.905',
            },
            zipcode: 96140,
          },
          961102: {
            name: {
              th: 'สุไหงปาดี',
              en: 'Su-Ngai Padi',
            },
            coordinates: {
              lat: '6.161',
              lng: '101.931',
            },
            zipcode: 96140,
          },
          961103: {
            name: {
              th: 'โต๊ะเด็ง',
              en: 'To Deng',
            },
            coordinates: {
              lat: '6.116',
              lng: '101.849',
            },
            zipcode: 96140,
          },
          961104: {
            name: {
              th: 'สากอ',
              en: 'Sako',
            },
            coordinates: {
              lat: '6.008',
              lng: '101.849',
            },
            zipcode: 96140,
          },
          961105: {
            name: {
              th: 'ริโก๋',
              en: 'Ri Ko',
            },
            coordinates: {
              lat: '6.057',
              lng: '101.845',
            },
            zipcode: 96140,
          },
          961106: {
            name: {
              th: 'กาวะ',
              en: 'Kawa',
            },
            coordinates: {
              lat: '6.020',
              lng: '101.889',
            },
            zipcode: 96140,
          },
        },
      },
      9612: {
        name: {
          th: 'จะแนะ',
          en: 'Chanae',
        },
        tambons: {
          961201: {
            name: {
              th: 'จะแนะ',
              en: 'Chanae',
            },
            coordinates: {
              lat: '6.077',
              lng: '101.693',
            },
            zipcode: null,
          },
          961202: {
            name: {
              th: 'ดุซงญอ',
              en: 'Du Song Yo',
            },
            coordinates: {
              lat: '6.123',
              lng: '101.643',
            },
            zipcode: null,
          },
          961203: {
            name: {
              th: 'ผดุงมาตร',
              en: 'Phadung Mat',
            },
            coordinates: {
              lat: '6.132',
              lng: '101.728',
            },
            zipcode: 96220,
          },
          961204: {
            name: {
              th: 'ช้างเผือก',
              en: 'Chang Phueak',
            },
            coordinates: {
              lat: '5.997',
              lng: '101.573',
            },
            zipcode: 96220,
          },
        },
      },
      9613: {
        name: {
          th: 'เจาะไอร้อง',
          en: 'Cho-airong',
        },
        tambons: {
          961301: {
            name: {
              th: 'จวบ',
              en: 'Chuap',
            },
            coordinates: {
              lat: '6.256',
              lng: '101.817',
            },
            zipcode: null,
          },
          961302: {
            name: {
              th: 'บูกิต',
              en: 'Bu Kit',
            },
            coordinates: {
              lat: '6.182',
              lng: '101.828',
            },
            zipcode: null,
          },
          961303: {
            name: {
              th: 'มะรือโบออก',
              en: 'Marue Bo Ok',
            },
            coordinates: {
              lat: '6.249',
              lng: '101.875',
            },
            zipcode: 96130,
          },
        },
      },
    },
  },
}
