<template>
  <div>
    <b-card-code>
      <b-row>
        <b-col lg="5">
          <h3 v-if="isCreateMode">
            สร้างรายการใหม่
          </h3>
          <div v-if="!isCreateMode">
            <h4 class="font-weight-bold">
              {{ uniqueId }}
            </h4>
            <span
              v-if="titleName"
              class="font-weight-bold"
            >
              ชื่อ : {{ titleName || "-" }}
            </span>
            <br v-if="titleName">
            <div style="margin-top: 4px; margin-bottom: 4px;">
              <b-badge
                v-if="isCovid"
                variant="info"
              >
                COVID
              </b-badge>
              <b-badge
                v-else
                variant="default"
                style="background-color: cornflowerblue"
              >
                NON-COVID
              </b-badge>
              -
              <b-badge
                v-if="form.covidCurrentLevel === 'green'"
                variant="success"
              >
                เขียว
              </b-badge>
              <b-badge
                v-else-if="form.covidCurrentLevel === 'yellow'"
                variant="warning"
              >
                เหลือง
              </b-badge>
              <b-badge
                v-else-if="form.covidCurrentLevel === 'red'"
                variant="danger"
              >
                แดง
              </b-badge>
              -
              <b-badge
                v-if="form.covidCurrentState === 'wait_bed'"
                variant="dark"
              >
                รอเตียง
              </b-badge>
              <b-badge
                v-else-if="form.covidCurrentState === 'hi'"
                variant="dark"
              >
                Home Isolation
              </b-badge>
              <b-badge
                v-else-if="form.covidCurrentState === 'hq'"
                variant="dark"
              >
                Home Quarantine
              </b-badge>
              <b-badge
                v-else-if="form.covidCurrentState === 'ci'"
                variant="dark"
              >
                Community Isolation
              </b-badge>
              <b-badge
                v-else-if="form.covidCurrentState === 'admit'"
                variant="dark"
              >
                Admit
              </b-badge>
              <b-badge
                v-else-if="form.covidCurrentState === 'end'"
                variant="dark"
              >
                จบเคส
              </b-badge>
              <b-badge
                v-else-if="form.covidCurrentState === 'death'"
                variant="dark"
              >
                ตาย
              </b-badge>
            </div>
            <small>
              บันทึกข้อมูลเมื่อ {{ createdAtFormatted }}
            </small>
          </div>
        </b-col>
        <b-col lg="7">
          <div
            v-if="saving"
            class="text-right"
          >
            <b-button
              type="submit"
              variant="primary"
              class="mr-1 btn-sm-block mt-1 mt-md-0"
              disabled
            >
              <b-spinner class="spinner-border-sm" />
            </b-button>
          </div>
          <div
            v-else
            class="text-right"
          >
            <b-button-group class="mr-1 btn-sm-block mt-1 mt-md-0">
              <b-dropdown
                v-if="!isCreateMode"
                right
                variant="outline-success"
                text="Actions"
              >
                <b-dropdown-item
                  variant="danger"
                  @click="confirmDeleteNovel"
                >
                  ลบ
                </b-dropdown-item>
              </b-dropdown>

              <b-dropdown
                v-if="!isCreateMode"
                right
                variant="outline-success"
                text="เอกสาร Covid"
              >
                <b-dropdown-item @click="submitFormAndDownloadLabATKFormDocx">
                  ใบรายงานแลป ATK
                </b-dropdown-item>
                <b-dropdown-item @click="submitFormAndDownloadRiskReportFormDocx">
                  ใบรายงานเสี่ยงภัย
                </b-dropdown-item>
              </b-dropdown>

              <b-dropdown
                v-if="!isCreateMode"
                right
                variant="outline-success"
                text="เอกสาร เสี่ยงสูง"
              >
                <b-dropdown-item @click="submitFormAndDownloadInvestigationFormDocx">
                  แบบสอบสวนผู้ป่วย
                </b-dropdown-item>
                <b-dropdown-item @click="submitFormAndDownloadSwabAgreementFormDocx">
                  ใบยินยอม รพ.
                </b-dropdown-item>
                <b-dropdown-item @click="submitFormAndDownloadRiskAgreementFormDocx">
                  ใบยินยอม สปคม.
                </b-dropdown-item>
              </b-dropdown>

              <b-button
                type="submit"
                variant="success"
                :disabled="saving"
                @click="submitForm"
              >
                บันทึกข้อมูล
              </b-button>

            </b-button-group>
          </div>
        </b-col>
      </b-row>

      <validation-observer ref="novelForm">
        <b-form @submit.prevent>
          <div>
            <hr>

            <b-row class="mt-2">
              <b-col lg="4">
                <b-form-group
                  label="เลขบัตรประจำตัวประชาชน"
                  label-for="v-id-card"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="เลขบัตรประจำตัวประชาชน"
                    rules="length:13|regex:^([0-9]+)$"
                  >
                    <b-form-input
                      id="v-id-card"
                      v-model="form.idCard"
                      placeholder="เลขบัตรประจำตัวประชาชน"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  label="เลข Passport"
                  label-for="v-passport-id"
                >
                  <b-form-input
                    id="v-passport-id"
                    v-model="form.passportId"
                    placeholder="เลข Passport"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  label="Patient Code"
                  label-for="v-code"
                >
                  <b-form-input
                    id="v-code"
                    v-model="form.patientCode"
                    placeholder="Patient Code"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  label="No. Covid Case"
                  label-for="v-covid-case-code"
                >
                  <b-form-input
                    id="v-covid-case-code"
                    v-model="form.covidCaseCode"
                    placeholder="Covid Case Code"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="สถานะ">
                  <v-select
                    v-model="form.covidCurrentState"
                    label="text"
                    :options="covidCurrentStateChoices"
                    :reduce="choice => choice.value"
                    :clearable="true"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="ระดับอาการ">
                  <v-select
                    v-model="form.covidCurrentLevel"
                    label="text"
                    :options="covidCurrentLevelChoices"
                    :reduce="choice => choice.value"
                    :clearable="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="4">
                <b-form-group
                  label="SAT Code"
                  label-for="v-covidSatCode"
                >
                  <b-form-input
                    id="v-covidSatCode"
                    v-model="form.covidSatCode"
                    placeholder="SAT Code"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <hr>

            <h4>ข้อมูลพื้นฐาน</h4>

            <b-row class="mt-2">
              <b-col lg="4">
                <div class="ml-2 ml-lg-2">
                  <b-form-group
                    label="คำนำหน้าชื่อ"
                    label-for="v-nameTitle"
                  >
                    <b-form-input
                      id="v-nameTitle"
                      v-model="form.nameTitle"
                      placeholder="คำนำหน้าชื่อ"
                      list="nameTitleList"
                    />
                    <datalist id="nameTitleList">
                      <option>นาย</option>
                      <option>นาง</option>
                      <option>นางสาว</option>
                      <option>เด็กชาย</option>
                      <option>เด็กหญิง</option>
                    </datalist>
                  </b-form-group>
                  <b-form-group
                    label="ชื่อ"
                    label-for="v-name"
                  >
                    <b-form-input
                      id="v-name"
                      v-model="form.name"
                      placeholder="ชื่อ"
                    />
                  </b-form-group>
                  <b-form-group
                    label="นามสกุล"
                    label-for="v-lastName"
                  >
                    <b-form-input
                      id="v-lastName"
                      v-model="form.lastName"
                      placeholder="นามสกุล"
                    />
                  </b-form-group>
                  <b-form-group
                    label="เพศ"
                    class="mt-2"
                  >
                    <b-form-radio-group
                      v-model="form.gender"
                      :options="genderChoices"
                    />
                  </b-form-group>
                  <b-row>
                    <b-col
                      cols="3"
                      class="pr-0 mr-0"
                    >
                      <b-form-group
                        label="วันเกิด"
                        label-for="v-birthDateDay"
                      >
                        <b-form-select
                          id="v-birthDateDay"
                          v-model="form.birthDateDay"
                          :options="birthDateDayChoices"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="4"
                      class="ml-0 mr-0 pl-0 pr-0"
                    >
                      <b-form-group
                        label="เดือนเกิด"
                        label-for="v-birthDateMonth"
                      >
                        <b-form-select
                          id="v-birthDateMonth"
                          v-model="form.birthDateMonth"
                          :options="birthDateMonthChoices"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="5"
                      class="pl-0 pl-0"
                    >
                      <b-form-group
                        label="ปีเกิด"
                        label-for="v-birthDateYear"
                      >
                        <b-form-select
                          id="v-birthDateYear"
                          v-model="form.birthDateYear"
                          :options="birthDateYearChoices"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col lg="4">
                <b-form-group
                  label="เบอร์โทรศัพท์ที่ติดต่อได้"
                  label-for="v-mobileNumber"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="เบอร์โทรศัพท์ที่ติดต่อได้"
                    rules="required|min:8"
                  >
                    <b-form-input
                      id="v-mobileNumber"
                      v-model="form.mobileNumber"
                      placeholder="เบอร์โทรศัพท์ที่ติดต่อได้"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="สัญชาติ"
                  label-for="v-nationality"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="สัญชาติ"
                  >
                    <b-form-input
                      id="v-nationality"
                      v-model="form.nationality"
                      placeholder="สัญชาติ"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="อาชีพ"
                  label-for="v-occupation"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="อาชีพ"
                  >
                    <b-form-input
                      id="v-occupation"
                      v-model="form.occupation"
                      placeholder="อาชีพ"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="สถานที่ทำงาน"
                  label-for="v-occupationLocation"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="สถานที่ทำงาน"
                  >
                    <b-form-input
                      id="v-occupationLocation"
                      v-model="form.occupationLocation"
                      placeholder="สถานที่ทำงาน"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="จังหวัดที่ทำงาน"
                  label-for="v-occupationProvince"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="จังหวัดที่ทำงาน"
                  >
                    <v-select
                      v-model="form.occupationProvince"
                      label="title"
                      :options="provinceChoices"
                      :reduce="choice => choice.value"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col lg="4">
                <b-form-group
                  label="ภาพ บัตรประจำตัวประชาชน / Passport"
                  label-for="v-id-card"
                >
                  <b-form-file
                    v-model="form.personalDocumentImage"
                    accept="image/*"
                  />

                  <div v-if="previewUploadImage">
                    <b-button
                      size="sm"
                      block
                      variant="outline-primary"
                      @click="clearDocumentImage"
                    >
                      ลบรูป
                    </b-button>
                  </div>
                </b-form-group>

                <div v-if="previewUploadImage">
                  <div class="text-center mt-2">
                    <b-img
                      :src="previewUploadImage"
                      fluid
                    />
                  </div>
                </div>
              </b-col>

            </b-row>

            <hr class="mb-1">

            <app-collapse>
              <app-collapse-item title="ข้อมูลความเสี่ยง">
                <b-card>
                  <div>
                    <b-row>
                      <b-col lg="4">
                        <h4>ที่อยู่ขณะป่วยในประเทศไทย</h4>

                        <div class="ml-2 ml-lg-2">
                          <b-form-group
                            label="ประเภทที่พักอาศัย"
                            class="mt-2"
                          >
                            <b-form-radio-group
                              v-model="form.sickLocationType"
                              :options="sickLocationTypeChoices"
                            />
                          </b-form-group>

                          <b-form-group
                            label="ประเภทที่พักอาศัยอื่นๆกรุณาระบุ"
                            class="mt-2"
                          >
                            <b-form-input
                              v-model="form.sickLocationOtherDetail"
                              :disabled="form.sickLocationType === 'home'"
                              placeholder="ประเภทที่พักอาศัยอื่นๆกรุณาระบุ"
                            />
                          </b-form-group>

                          <b-form-group
                            label="บ้านเลขที่"
                            class="mt-2"
                          >
                            <b-form-input
                              v-model="form.sickLocationAddress"
                              placeholder="บ้านเลขที่"
                            />
                          </b-form-group>

                          <b-form-group label="จังหวัด">
                            <v-select
                              v-model="form.sickLocationProvince"
                              label="title"
                              :options="provinceChoices"
                              :reduce="choice => choice.value"
                              :clearable="false"
                              @input="changeProvince"
                            />
                          </b-form-group>

                          <b-form-group label="อำเภอ / เขต">
                            <v-select
                              v-model="form.sickLocationDistrict"
                              label="title"
                              :options="districtChoices"
                              :reduce="choice => choice.value"
                              :clearable="false"
                              @input="changeDistrict"
                            />
                          </b-form-group>

                          <b-form-group label="ตำบล / แขวง">
                            <v-select
                              v-model="form.sickLocationSubDistrict"
                              label="title"
                              :options="subDistrictChoices"
                              :reduce="choice => choice.value"
                              :clearable="false"
                            />
                          </b-form-group>

                          <b-form-group
                            label="ชุมชน"
                            class="mt-2"
                          >
                            <b-form-input
                              v-model="form.sickLocationCommunityName"
                              placeholder="ชุมชน"
                            />
                          </b-form-group>
                        </div>
                      </b-col>
                      <b-col lg="8">
                        <hr class="d-md-none">
                        <h4>ประวัติการได้รับวัคซีนป้องกันโรคติดเชื้อไวรัสโคโรนา 2019</h4>

                        <div class="ml-2 ml-lg-2">
                          <b-form-group
                            label="เคยได้รับวัคซีนหรือไม่"
                            class="mt-2"
                          >
                            <b-form-radio-group
                              v-model="form.vaccineStatus"
                              :options="vaccineStatusChoices"
                              @input="changeVaccineStatus"
                            />
                          </b-form-group>
                          <b-form-group
                            label="สมุดบันทึกหรือหลักฐานการได้รับวัคซีน มีหรือไม่"
                            class="mt-2"
                          >
                            <b-form-radio-group
                              v-model="form.vaccinePassportStatus"
                              :options="vaccinePassportStatusChoices"
                            />
                          </b-form-group>
                        </div>
                      </b-col>
                    </b-row>

                    <hr class="mb-2">

                    <div>
                      <h4
                        class="d-inline-block"
                      >
                        ผลการตรวจ SARS-CoV-2  PCR
                      </h4>
                      <b-button
                        size="sm"
                        class="ml-1"
                        variant="outline-primary"
                        @click="addMoreCovidTest"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="12"
                        />
                      </b-button>
                    </div>
                    <div class="ml-2 ml-lg-2 mt-1">
                      <b-row
                        v-for="(covidTest, index) in form.covidTests"
                        :key="index"
                      >
                        <b-col
                          cols="11"
                          class="mr-0 pr-0"
                        >
                          <b-row>
                            <b-col
                              cols="2"
                              class="ml-0 pl-0 mr-0 pr-0"
                            >
                              <b-form-group label="ครั้งที่">
                                <b-form-input
                                  v-model="covidTest.order"
                                  placeholder="ครั้งที่"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="4"
                              md="2"
                              class="ml-0 pl-0 mr-0 pr-0"
                            >
                              <b-form-group label="วันที่เก็บ">
                                <b-form-datepicker
                                  v-model="covidTest.collectDate"
                                  placeholder="วันที่เก็บ"
                                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                  locale="th"
                                  reset-button
                                  close-button
                                  hide-header
                                />
                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="5"
                              md="2"
                              class="ml-0 pl-0 mr-0 pr-0"
                            >
                              <b-form-group label="ชนิดตัวอย่าง">
                                <b-form-input
                                  v-model="covidTest.sourceType"
                                  placeholder="ชนิดตัวอย่าง"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="5"
                              md="3"
                              class="ml-0 pl-0 mr-0 pr-0"
                            >
                              <b-form-group label="สถานที่ส่งตรวจ">
                                <b-form-input
                                  v-model="covidTest.labLocation"
                                  placeholder="สถานที่ส่งตรวจ"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="6"
                              md="2"
                              class="ml-0 pl-0 mr-0 pr-0"
                            >
                              <b-form-group label="ผลตรวจ">
                                <b-form-select v-model="covidTest.labResult">
                                  <b-form-select-option value="" />
                                  <b-form-select-option value="Detected">
                                    Detected
                                  </b-form-select-option>
                                  <b-form-select-option value="Not Detected">
                                    Not Detected
                                  </b-form-select-option>
                                </b-form-select>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col
                          cols="1"
                          class="ml-0 pl-0 mr-0 pr-0"
                        >
                          <b-button
                            size="md"
                            variant="outline-danger"
                            style="margin-top: 28px;"
                            @click="deleteCovidTest(index)"
                          >
                            <feather-icon
                              icon="XIcon"
                              size="12"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>

                    <div class="mt-1">
                      <h4
                        class="d-inline-block"
                      >
                        ผลการตรวจ SARS-CoV-2 Antibody / Antigen Test Kit
                      </h4>
                      <b-button
                        size="sm"
                        class="ml-1"
                        variant="outline-primary"
                        @click="addMoreAntibodyTest"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="12"
                        />
                      </b-button>
                    </div>
                    <div class="ml-2 ml-lg-2 mt-1">
                      <b-row
                        v-for="(antibodyTest, index) in form.antibodyTests"
                        :key="index"
                      >
                        <b-col
                          cols="11"
                          class="mr-0 pr-0"
                        >
                          <b-row>
                            <b-col
                              cols="2"
                              class="ml-0 pl-0 mr-0 pr-0"
                            >
                              <b-form-group label="ครั้งที่">
                                <b-form-input
                                  v-model="antibodyTest.order"
                                  placeholder="ครั้งที่"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="4"
                              md="2"
                              class="ml-0 pl-0 mr-0 pr-0"
                            >
                              <b-form-group label="วันที่เก็บ">
                                <b-form-datepicker
                                  v-model="antibodyTest.collectDate"
                                  placeholder="วันที่เก็บ"
                                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                  locale="th"
                                  reset-button
                                  close-button
                                  hide-header
                                />
                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="5"
                              md="2"
                              class="ml-0 pl-0 mr-0 pr-0"
                            >
                              <b-form-group label="ชนิดตัวอย่าง">
                                <b-form-select
                                  v-model="antibodyTest.sourceType"
                                  placeholder="ชนิดตัวอย่าง"
                                >
                                  <b-form-select-option value="Antibody">
                                    Antibody
                                  </b-form-select-option>
                                  <b-form-select-option value="Antigen Test Kit">
                                    Antigen Test Kit
                                  </b-form-select-option>
                                </b-form-select>
                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="5"
                              md="3"
                              class="ml-0 pl-0 mr-0 pr-0"
                            >
                              <b-form-group label="สถานที่ส่งตรวจ">
                                <b-form-input
                                  v-model="antibodyTest.labLocation"
                                  placeholder="สถานที่ส่งตรวจ"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col
                              cols="6"
                              md="2"
                              class="ml-0 pl-0 mr-0 pr-0"
                            >
                              <b-form-group label="ผลตรวจ">
                                <b-form-select v-model="antibodyTest.labResult">
                                  <b-form-select-option value="" />
                                  <b-form-select-option value="Positive">
                                    Positive
                                  </b-form-select-option>
                                  <b-form-select-option value="Negative">
                                    Negative
                                  </b-form-select-option>
                                </b-form-select>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col
                          cols="1"
                          class="ml-0 pl-0 mr-0 pr-0"
                        >
                          <b-button
                            size="md"
                            variant="outline-danger"
                            style="margin-top: 28px;"
                            @click="deleteAntibodyTest(index)"
                          >
                            <feather-icon
                              icon="XIcon"
                              size="12"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>

                    <hr class="mb-2">

                    <b-row>
                      <b-col lg="12">
                        <h3>ประวัติเสี่ยง</h3>
                        <b-row class="mt-1 mb-2 ml-3">
                          <b-col lg="4">
                            <div>
                              <label for="v-risk-info-r1-lastContactCovid19patient">วันที่สัมผัสผู้ป่วยโควิดครั้งสุดท้าย</label>
                              <b-form-datepicker
                                id="v-risk-info-r1-lastContactCovid19patient"
                                v-model="form.risks.lastContactCovid19patient"
                                placeholder="วันที่สัมผัสผู้ป่วยโควิดครั้งสุดท้าย"
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                locale="th"
                                reset-button
                                close-button
                                hide-header
                              />
                            </div>
                          </b-col>
                          <b-col lg="4">
                            <div>
                              <label for="v-risk-info-r1-firstHomeQuarantine">วันที่เริ่ม Home Quarantine</label>
                              <b-form-datepicker
                                id="v-risk-info-r1-firstHomeQuarantine"
                                v-model="form.risks.firstHomeQuarantine"
                                placeholder="วันที่เริ่ม Home Quarantine"
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                locale="th"
                                reset-button
                                close-button
                                hide-header
                              />
                            </div>
                          </b-col>
                          <b-col lg="4">
                            <div>
                              <label for="v-risk-info-r1-lastHomeQuarantine">Home Quarantine ถึงวันที่</label>
                              <b-form-datepicker
                                id="v-risk-info-r1-lastHomeQuarantine"
                                v-model="form.risks.lastHomeQuarantine"
                                placeholder="Home Quarantine ถึงวันที่"
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                locale="th"
                                reset-button
                                close-button
                                hide-header
                              />
                            </div>
                          </b-col>
                        </b-row>
                        <ol>
                          <li
                            v-for="riskItem in riskHistoryList"
                            :key="riskItem.field"
                            class="mb-1"
                          >
                            <div>
                              <b-form-checkbox
                                v-model="form.risks[riskItem.field]"
                                :value="riskItem.value"
                                inline
                                class="ml-1"
                              >
                                {{ riskItem.title }}
                              </b-form-checkbox>

                              <div
                                v-if="riskItem.field === 'r1'"
                                class="ml-3"
                                style="margin-top: 6px;"
                              >
                                <b-row>
                                  <b-col lg="3">
                                    <div class="">
                                      <label for="v-risk-info-r1-city">เมือง</label>
                                      <b-form-input
                                        id="v-risk-info-r1-city"
                                        v-model="form.risksInfo.r1.city"
                                        :disabled="!form.risks[riskItem.field]"
                                        placeholder="เมือง"
                                      />
                                    </div>
                                  </b-col>
                                  <b-col lg="3">
                                    <div class="">
                                      <label for="v-risk-info-r1-country">ประเทศ</label>
                                      <b-form-input
                                        id="v-risk-info-r1-country"
                                        v-model="form.risksInfo.r1.country"
                                        :disabled="!form.risks[riskItem.field]"
                                        placeholder="ประเทศ"
                                      />
                                    </div>
                                  </b-col>
                                  <b-col lg="3">
                                    <div>
                                      <label for="v-risk-info-r1-arriveThaiDate">เดินทางเข้าประเทศไทยวันที่</label>
                                      <b-form-datepicker
                                        id="v-risk-info-r1-arriveThaiDate"
                                        v-model="form.risksInfo.r1.arriveThaiDate"
                                        placeholder="เดินทางเข้าประเทศไทยวันที่"
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                        locale="th"
                                        reset-button
                                        close-button
                                        hide-header
                                        :disabled="!form.risks[riskItem.field]"
                                      />
                                    </div>
                                  </b-col>
                                </b-row>
                                <b-row class="mt-lg-1">
                                  <b-col lg="3">
                                    <div class="">
                                      <label for="v-risk-info-r1-airline">โดยสายการบิน</label>
                                      <b-form-input
                                        id="v-risk-info-r1-airline"
                                        v-model="form.risksInfo.r1.airline"
                                        placeholder="โดยสายการบิน"
                                        :disabled="!form.risks[riskItem.field]"
                                      />
                                    </div>
                                  </b-col>
                                  <b-col lg="3">
                                    <div class="">
                                      <label for="v-risk-info-r1-flightNumber">เที่ยวบินที่</label>
                                      <b-form-input
                                        id="v-risk-info-r1-flightNumber"
                                        v-model="form.risksInfo.r1.flightNumber"
                                        placeholder="เที่ยวบินที่"
                                        :disabled="!form.risks[riskItem.field]"
                                      />
                                    </div>
                                  </b-col>
                                  <b-col lg="3">
                                    <div class="">
                                      <label for="v-risk-info-r1-flightSeat">เลขที่นั่ง</label>
                                      <b-form-input
                                        id="v-risk-info-r1-flightSeat"
                                        v-model="form.risksInfo.r1.flightSeat"
                                        placeholder="เลขที่นั่ง"
                                        :disabled="!form.risks[riskItem.field]"
                                      />
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                              <div
                                v-if="riskItem.field === 'r4'"
                                class="ml-3"
                                style="margin-top: 6px;"
                              >
                                <b-row>
                                  <b-col lg="6">
                                    <div class="">
                                      <label for="v-risk-info-r4-detail">ระบุ</label>
                                      <b-form-input
                                        id="v-risk-info-r4-detail"
                                        v-model="form.risksInfo.r4.detail"
                                        :disabled="!form.risks[riskItem.field]"
                                        placeholder=""
                                      />
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                              <div
                                v-if="riskItem.field === 'r6'"
                                class="ml-3"
                                style="margin-top: 6px;"
                              >
                                <b-row>
                                  <b-col lg="6">
                                    <div class="">
                                      <label for="v-risk-info-r6-detail">ระบุ</label>
                                      <b-form-input
                                        id="v-risk-info-r6-detail"
                                        v-model="form.risksInfo.r6.detail"
                                        :disabled="!form.risks[riskItem.field]"
                                        placeholder=""
                                      />
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                              <div
                                v-if="riskItem.field === 'r0'"
                                class="ml-3"
                                style="margin-top: 6px;"
                              >
                                <b-row>
                                  <b-col lg="12">
                                    <div class="">
                                      <label for="v-risk-info-r0-detail">ระบุ</label>
                                      <b-form-input
                                        id="v-risk-info-r0-detail"
                                        v-model="form.risksInfo.r0.detail"
                                        :disabled="!form.risks[riskItem.field]"
                                        placeholder=""
                                      />
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </div>
                          </li>
                        </ol>
                      </b-col>
                    </b-row>

                    <hr>

                    <b-row>
                      <b-col lg="12">
                        <h3>รายละเอียดเหตุการณ์ ประวัติเสี่ยงต่อการติดเชื้อ ก่อนเริ่มป่วย</h3>
                        <div class="ml-2 mt-1">
                          <b-form-textarea
                            v-model="form.timelineDetail"
                            rows="4"
                            max-rows="12"
                          />
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </app-collapse-item>
              <app-collapse-item title="ข้อมูลสำหรับรายงานการสอบสวนโรครายวัน ศบส">
                <b-card>
                  <b-row>
                    <b-col lg="4">
                      <p>ที่อยู่ขณะป่วยในประเทศไทย</p>

                      <div class="ml-2 ml-lg-2">
                        <b-form-group
                          label="ประเภทที่พักอาศัย"
                          class="mt-2"
                        >
                          <b-form-radio-group
                            v-model="form.sickLocationType"
                            :options="sickLocationTypeChoices"
                          />
                        </b-form-group>

                        <b-form-group
                          label="ประเภทที่พักอาศัยอื่นๆกรุณาระบุ"
                          class="mt-2"
                        >
                          <b-form-input
                            v-model="form.sickLocationOtherDetail"
                            :disabled="form.sickLocationType === 'home'"
                            placeholder="ประเภทที่พักอาศัยอื่นๆกรุณาระบุ"
                          />
                        </b-form-group>

                        <b-form-group
                          label="บ้านเลขที่"
                          class="mt-2"
                        >
                          <b-form-input
                            v-model="form.sickLocationAddress"
                            placeholder="บ้านเลขที่"
                          />
                        </b-form-group>

                        <b-form-group label="จังหวัด">
                          <v-select
                            v-model="form.sickLocationProvince"
                            label="title"
                            :options="provinceChoices"
                            :reduce="choice => choice.value"
                            :clearable="false"
                            @input="changeProvince"
                          />
                        </b-form-group>

                        <b-form-group label="อำเภอ / เขต">
                          <v-select
                            v-model="form.sickLocationDistrict"
                            label="title"
                            :options="districtChoices"
                            :reduce="choice => choice.value"
                            :clearable="false"
                            @input="changeDistrict"
                          />
                        </b-form-group>

                        <b-form-group label="ตำบล / แขวง">
                          <v-select
                            v-model="form.sickLocationSubDistrict"
                            label="title"
                            :options="subDistrictChoices"
                            :reduce="choice => choice.value"
                            :clearable="false"
                          />
                        </b-form-group>

                        <b-form-group
                          label="ชุมชน"
                          class="mt-2"
                        >
                          <b-form-input
                            v-model="form.sickLocationCommunityName"
                            placeholder="ชุมชน"
                          />
                        </b-form-group>

                        <div>
                          <label for="v-startIsolationDate">วันที่เริ่ม Home Isolation</label>
                          <b-form-datepicker
                            id="v-startIsolationDate"
                            v-model="form.startIsolationDate"
                            placeholder="วันที่เริ่ม Isolation"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            locale="th"
                            reset-button
                            close-button
                            hide-header
                          />
                        </div>

                        <div class="mt-1">
                          <label for="v-lastIsolationDate">Home Isolation ถึงวันที่</label>
                          <b-form-datepicker
                            id="v-lastIsolationDate"
                            v-model="form.lastIsolationDate"
                            placeholder="Isolation ถึงวันที่"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            locale="th"
                            reset-button
                            close-button
                            hide-header
                          />
                        </div>
                      </div>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group label="วันที่รับแจ้งเคส">
                        <b-form-datepicker
                          v-model="form.dailyReportCaseIssueDate"
                          placeholder="วันที่รับแจ้งเคส"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          locale="th"
                          reset-button
                          close-button
                          hide-header
                        />
                      </b-form-group>
                      <b-form-group label="เวลารับแจ้งเคส">
                        <b-form-timepicker
                          v-model="form.dailyReportCaseIssueTime"
                          placeholder="เวลารับแจ้งเคส"
                          locale="th"
                          reset-button
                          close-button
                          hide-header
                        />
                      </b-form-group>

                      <b-form-group label="วันที่รายงาน">
                        <b-form-datepicker
                          v-model="form.dailyReportCaseReportDate"
                          placeholder="วันที่รายงาน"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          locale="th"
                          reset-button
                          close-button
                          hide-header
                        />
                      </b-form-group>
                      <b-form-group label="เวลาที่รายงาน">
                        <b-form-timepicker
                          v-model="form.dailyReportCaseReportTime"
                          placeholder="เวลาที่รายงาน"
                          locale="th"
                          reset-button
                          close-button
                          hide-header
                        />
                      </b-form-group>

                      <b-form-group
                        label="สถานะ"
                        label-for="v-dailyReportStatus"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="สถานะ"
                        >
                          <v-select
                            v-model="form.dailyReportStatus"
                            label="text"
                            :options="dailyReportStatusChoices"
                            :reduce="choice => choice.value"
                            :clearable="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="จำนวนผู้สัมผัสเสี่ยงสูง"
                        label-for="v-dailyReportNumberOfHighRiskContact"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="จำนวนผู้สัมผัสเสี่ยงสูง"
                        >
                          <b-form-input
                            id="v-dailyReportNumberOfHighRiskContact"
                            v-model="form.dailyReportNumberOfHighRiskContact"
                            placeholder="จำนวนผู้สัมผัสเสี่ยงสูง"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="ชนิดผู้สัมผัส"
                        label-for="v-dailyReportContactType"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="ชนิดผู้สัมผัส"
                        >
                          <v-select
                            v-model="form.dailyReportContactType"
                            label="text"
                            :options="dailyReportContactTypeChoices"
                            :reduce="choice => choice.value"
                            :clearable="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="ชนิดผู้สัมผัส อื่นๆกรุณาระบุ"
                        label-for="v-dailyReportContactTypeOther"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="ชนิดผู้สัมผัสอื่นๆ"
                        >
                          <b-form-input
                            id="v-dailyReportContactTypeOther"
                            v-model="form.dailyReportContactTypeOther"
                            placeholder="ชนิดผู้สัมผัส อื่นๆกรุณาระบุ"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group label="วันที่เริ่มป่วย">
                        <b-form-datepicker
                          v-model="form.dailyReportStartSickDate"
                          placeholder="วันที่เริ่มป่วย"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          locale="th"
                          reset-button
                          close-button
                          hide-header
                        />
                      </b-form-group>

                      <b-form-group
                        label="อาการ"
                        label-for="v-dailyReportSymptom"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="อาการ"
                        >
                          <b-form-input
                            id="v-dailyReportSymptom"
                            v-model="form.dailyReportSymptom"
                            placeholder="อาการ"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group label="วันที่รับการรักษา">
                        <b-form-datepicker
                          v-model="form.dailyReportStartTreatmentDate"
                          placeholder="วันที่รับการรักษา"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          locale="th"
                          reset-button
                          close-button
                          hide-header
                        />
                      </b-form-group>

                      <b-form-group label="สถานที่รักษา">
                        <b-form-input
                          id="v-dailyReportTreatmentLocation"
                          v-model="form.dailyReportTreatmentLocation"
                          placeholder="สถานที่รักษา"
                        />
                      </b-form-group>

                      <b-form-group label="รพ.ที่ Admit 1">
                        <b-form-input
                          id="v-dailyReportAdmitHospital1"
                          v-model="form.dailyReportAdmitHospital1"
                          placeholder="รพ.ที่ Admit 1"
                        />
                      </b-form-group>

                      <b-form-group label="รพ.ที่ Admit 2">
                        <b-form-input
                          id="v-dailyReportAdmitHospital2"
                          v-model="form.dailyReportAdmitHospital2"
                          placeholder="รพ.ที่ Admit 2"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group label="วันที่ส่ง LAB">
                        <b-form-datepicker
                          v-model="form.dailyReportLabDate"
                          placeholder="วันที่ส่ง LAB"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          locale="th"
                          reset-button
                          close-button
                          hide-header
                        />
                      </b-form-group>

                      <b-form-group label="ชนิดตัวอย่าง">
                        <b-form-select
                          v-model="form.dailyReportLabSourceType"
                          placeholder="ชนิดตัวอย่าง"
                          clearable
                        >
                          <b-form-select-option value="" />
                          <b-form-select-option value="RT-PCR">
                            RT-PCR
                          </b-form-select-option>
                          <b-form-select-option value="Antigen Test Kit">
                            Antigen Test Kit
                          </b-form-select-option>
                        </b-form-select>
                      </b-form-group>

                      <b-form-group
                        label="สถานที่ส่ง LAB"
                        label-for="v-dailyReportLabLocation"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="สถานที่ส่ง LAB"
                        >
                          <b-form-input
                            id="v-dailyReportLabLocation"
                            v-model="form.dailyReportLabLocation"
                            placeholder="สถานที่ส่ง LAB"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="ORF gene CT"
                        label-for="v-dailyReportLabResultORFGeneCT"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="ORF gene CT"
                        >
                          <b-form-input
                            id="v-dailyReportLabResultORFGeneCT"
                            v-model="form.dailyReportLabResultORFGeneCT"
                            placeholder="ORF gene CT"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="N Gene CT"
                        label-for="v-dailyReportLabResultNGeneCT"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="N Gene CT"
                        >
                          <b-form-input
                            id="v-dailyReportLabResultNGeneCT"
                            v-model="form.dailyReportLabResultNGeneCT"
                            placeholder="N Gene CT"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="RdRP Gene CT"
                        label-for="v-dailyReportLabResultRdRPGeneCT"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="RdRP Gene CT"
                        >
                          <b-form-input
                            id="v-dailyReportLabResultRdRPGeneCT"
                            v-model="form.dailyReportLabResultRdRPGeneCT"
                            placeholder="RdRP Gene CT"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="สัมผัสจาก(ชื่อสกุล)"
                        label-for="v-dailyReportLabContactFrom"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="สัมผัสจาก(ชื่อสกุล)"
                        >
                          <b-form-input
                            id="v-dailyReportLabContactFrom"
                            v-model="form.dailyReportLabContactFrom"
                            placeholder="สัมผัสจาก(ชื่อสกุล)"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="ภาพ Lab"
                        label-for="v-lab-image"
                      >
                        <b-form-file
                          v-model="form.labImage"
                          accept="image/*"
                        />

                        <div v-if="previewUploadLabImage">
                          <b-button
                            size="sm"
                            block
                            variant="outline-primary"
                            @click="clearLabImage"
                          >
                            ลบภาพ Lab
                          </b-button>
                        </div>
                      </b-form-group>

                      <div v-if="previewUploadLabImage">
                        <div class="text-center mt-2">
                          <b-img
                            :src="previewUploadLabImage"
                            fluid
                          />
                        </div>
                      </div>

                    </b-col>
                  </b-row>
                </b-card>
              </app-collapse-item>
            </app-collapse>

            <hr>

            <b-row>
              <!-- submit and reset -->
              <b-col cols="12">
                <div
                  v-if="saving"
                  class="text-right mt-2"
                >
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    disabled
                  >
                    <b-spinner class="spinner-border-sm" />
                  </b-button>
                </div>
                <div
                  v-else
                  class="text-right mt-2"
                >
                  <div
                    v-if="saving"
                    class="text-right"
                  >
                    <b-button
                      type="submit"
                      variant="primary"
                      class="mr-1 btn-sm-block mt-1 mt-md-0"
                      disabled
                    >
                      <b-spinner class="spinner-border-sm" />
                    </b-button>
                  </div>
                  <div
                    v-else
                    class="text-right"
                  >
                    <b-button-group class="mr-1 btn-sm-block mt-1 mt-md-0">
                      <b-dropdown
                        v-if="!isCreateMode"
                        right
                        variant="outline-success"
                        text="Actions"
                      >
                        <b-dropdown-item
                          variant="danger"
                          @click="confirmDeleteNovel(data.item)"
                        >
                          ลบ
                        </b-dropdown-item>
                      </b-dropdown>

                      <b-dropdown
                        v-if="!isCreateMode"
                        right
                        variant="outline-success"
                        text="เอกสาร Covid"
                      >
                        <b-dropdown-item @click="submitFormAndDownloadLabATKFormDocx">
                          ใบรายงานแลป ATK
                        </b-dropdown-item>
                        <b-dropdown-item @click="submitFormAndDownloadRiskReportFormDocx">
                          ใบรายงานเสี่ยงภัย
                        </b-dropdown-item>
                      </b-dropdown>
                      <b-dropdown
                        v-if="!isCreateMode"
                        right
                        variant="outline-success"
                        text="เอกสาร เสี่ยงสูง"
                      >
                        <b-dropdown-item @click="submitFormAndDownloadInvestigationFormDocx">
                          แบบสอบสวนผู้ป่วย
                        </b-dropdown-item>
                        <b-dropdown-item @click="submitFormAndDownloadSwabAgreementFormDocx">
                          ใบยินยอม รพ.
                        </b-dropdown-item>
                        <b-dropdown-item @click="submitFormAndDownloadRiskAgreementFormDocx">
                          ใบยินยอม สปคม.
                        </b-dropdown-item>
                      </b-dropdown>

                      <b-button
                        type="submit"
                        variant="success"
                        :disabled="saving"
                        @click="submitForm"
                      >
                        บันทึกข้อมูล
                      </b-button>

                    </b-button-group>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>

          <b-overlay
            :show="saving"
            no-wrap
          />
        </b-form>
      </validation-observer>
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow,
  BCol,
  BBadge,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormFile,
  BImg,
  BSpinner,
  BFormRadioGroup,
  BFormCheckbox,
  BFormDatepicker,
  BFormTimepicker,
  BOverlay, BButtonGroup, BDropdown, BDropdownItem, BFormTextarea, BFormSelect, BFormSelectOption, BCard,
} from 'bootstrap-vue'
import axios from '@/libs/axios'
import errorHandleMixins from '@/mixins/error'
import provinceList from '@/libs/tambon'
import vSelect from 'vue-select'
import FileSaver from 'file-saver'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, length, between, regex,
} from '@validations'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardCode,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormFile,
    BImg,
    BSpinner,
    BFormRadioGroup,
    BFormCheckbox,
    BFormDatepicker,
    BFormTimepicker,
    BOverlay,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [errorHandleMixins],
  data() {
    return {
      isCreateMode: false,
      saving: false,
      createdAt: '',
      uniqueId: '',
      titleName: '',
      provinceList,
      birthDateDayChoices: [
        { text: 'ไม่ทราบ', value: '' },
        ...[...Array(31).keys()].map(date => ({ text: `${date + 1}`, value: date + 1 })),
      ],
      birthDateMonthChoices: [
        { text: 'ไม่ทราบ', value: '' },
        { text: 'มกราคม', value: 1 },
        { text: 'กุมภาพันธ์', value: 2 },
        { text: 'มีนาคม', value: 3 },
        { text: 'เมษายน', value: 4 },
        { text: 'พฤษภาคม', value: 5 },
        { text: 'มิถุนายน', value: 6 },
        { text: 'กรกฎาคม', value: 7 },
        { text: 'สิงหาคม', value: 8 },
        { text: 'กันยายน', value: 9 },
        { text: 'ตุลาคม', value: 10 },
        { text: 'พฤศจิกายน', value: 11 },
        { text: 'ธันวาคม', value: 12 },
      ],
      birthDateYearChoices: [
        { text: 'ไม่ทราบ', value: '' },
        ...[...Array(120).keys()].map(year => {
          const nowYear = (new Date()).getFullYear()
          return { text: `${nowYear + 543 - year} / ${nowYear - year}`, value: nowYear - year }
        }),
      ],
      genderChoices: [
        { text: 'ไม่ระบุ', value: 'non-specific' },
        { text: 'ชาย', value: 'male' },
        { text: 'หญิง', value: 'female' },
      ],
      sickLocationTypeChoices: [
        { text: 'บ้าน', value: 'home' },
        { text: 'อื่นๆ (โปรดระบุ)', value: 'other' },
      ],
      vaccineStatusChoices: [
        { text: 'ไม่เคยได้รับ', value: 'no' },
        { text: 'เคยได้รับ', value: 'yes' },
      ],
      vaccinePassportStatusChoices: [
        { text: 'ไม่มี', value: 'no' },
        { text: 'มี', value: 'yes' },
      ],
      riskHistoryList: [
        { field: 'r1', value: 'yes', title: 'ช่วง 14 วันก่อนป่วยอาศัยอยู่หรือเดินทางมาจากพื้นที่ที่มีการระบาด' },
        { field: 'r2', value: 'yes', title: 'ช่วง 14 วันก่อนป่วยได้เข้ารับการรักษาหรือเยี่ยมผู้ป่วยในโรงพยาบาลของพื้นที่ที่มีการระบาด' },
        { field: 'r3', value: 'yes', title: 'ช่วง 14 วันก่อนป่วยได้ดูแลหรือสัมผัสใกล้ชิดกับผู้ป่วยอาการคล้ายไข้หวัดใหญ่หรือปอดอักเสบ' },
        { field: 'r4', value: 'yes', title: 'ช่วง 14 วันก่อนป่วยมีประวัติสัมผัสกับผู้ป่วยยืนยันโรคติดเชื้อไวรัสโคโรนา 2019' },
        { field: 'r5', value: 'yes', title: 'ช่วง 14 วันก่อนป่วยประกอบอาชีพที่สัมผัสใกล้ชิดกับนักท่องเที่ยวต่างชาติหรือแรงงานต่างชาติ' },
        { field: 'r6', value: 'yes', title: 'ช่วง 14 วันก่อนป่วยมีประวัติเดินทางไปในสถานที่ที่มีคนหนาแน่น เช่น ผับ สนามมวย' },
        { field: 'r7', value: 'yes', title: 'เป็นผู้ป่วยอาการทางเดินหายใจหรือปอดอักเสบเป็นกลุ่มก้อน' },
        { field: 'r8', value: 'yes', title: 'เป็นผู้ป่วยปอดอักเสบรุนแรงหรือเสียชีวิตที่หาสาเหตุไม่ได้' },
        { field: 'r9', value: 'yes', title: 'เป็นบุคลากรทางการแพทย์และสาธารณสุขหรือเจ้าหน้าที่ห้องปฏิบัติการ' },
        { field: 'r0', value: 'yes', title: 'อื่นๆ' },
      ],
      form: {
        nameTitle: '',
        name: '',
        lastName: '',
        gender: '',
        mobileNumber: '',
        nationality: '',
        occupation: '',
        occupationLocation: '',
        occupationProvince: '',
        dailyReportNumberOfHighRiskContact: '',
        dailyReportCaseIssueDate: '',
        dailyReportCaseIssueTime: '',
        dailyReportCaseReportDate: '',
        dailyReportCaseReportTime: '',
        dailyReportStatus: '',
        dailyReportContactType: '',
        dailyReportContactTypeOther: '',
        dailyReportStartSickDate: '',
        dailyReportSymptom: '',
        dailyReportStartTreatmentDate: '',
        dailyReportTreatmentLocation: '',
        dailyReportAdmitHospital1: '',
        dailyReportAdmitHospital2: '',
        dailyReportLabDate: '',
        dailyReportLabSourceType: '',
        dailyReportLabLocation: '',
        dailyReportLabResultORFGeneCT: '',
        dailyReportLabResultNGeneCT: '',
        dailyReportLabResultRdRPGeneCT: '',
        dailyReportLabContactFrom: '',
        birthDateDay: '',
        birthDateMonth: '',
        birthDateYear: '',
        idCard: '',
        passportId: '',
        patientCode: '',
        covidSatCode: '',
        covidCaseCode: '',
        covidCurrentState: '',
        covidCurrentLevel: '',
        markDeleteDocumentImage: false,
        markDeleteLabImage: false,
        personalDocumentImage: null,
        labImage: null,
        sickLocationType: 'home',
        sickLocationOtherDetail: '',
        sickLocationProvince: '',
        sickLocationDistrict: '',
        sickLocationSubDistrict: '',
        sickLocationRoad: '',
        sickLocationAlley: '',
        sickLocationVillage: '',
        sickLocationMoo: '',
        sickLocationAddress: '',
        sickLocationCommunityName: '',
        startIsolationDate: '',
        lastIsolationDate: '',
        vaccineStatus: '',
        vaccinePassportStatus: '',
        timelineDetail: '',
        covidTests: [{}],
        antibodyTests: [{}],
        risks: {
          r0: '',
          r1: '',
          r2: '',
          r3: '',
          r4: '',
          r5: '',
          r6: '',
          r7: '',
          r8: '',
          r9: '',
          lastContactCovid19patient: '',
          firstHomeQuarantine: '',
          lastHomeQuarantine: '',
        },
        risksInfo: {
          r0: {
            detail: '',
          },
          r1: {
            city: '', country: '', arriveThaiDate: '', airline: '', flightNumber: '', flightSeat: '',
          },
          r4: {
            detail: '',
          },
          r6: {
            detail: '',
          },
          ...({}),
        },
      },
      initialForm: {
        nameTitle: '',
        name: '',
        lastName: '',
        gender: '',
        mobileNumber: '',
        nationality: '',
        occupation: '',
        occupationLocation: '',
        occupationProvince: '',
        dailyReportNumberOfHighRiskContact: '',
        dailyReportCaseIssueDate: '',
        dailyReportCaseIssueTime: '',
        dailyReportCaseReportDate: '',
        dailyReportCaseReportTime: '',
        dailyReportStatus: '',
        dailyReportContactType: '',
        dailyReportContactTypeOther: '',
        dailyReportStartSickDate: '',
        dailyReportSymptom: '',
        dailyReportStartTreatmentDate: '',
        dailyReportTreatmentLocation: '',
        dailyReportAdmitHospital1: '',
        dailyReportAdmitHospital2: '',
        dailyReportLabDate: '',
        dailyReportLabSourceType: '',
        dailyReportLabLocation: '',
        dailyReportLabResultORFGeneCT: '',
        dailyReportLabResultNGeneCT: '',
        dailyReportLabResultRdRPGeneCT: '',
        dailyReportLabContactFrom: '',
        birthdate: '',
        idCard: '',
        passportId: '',
        patientCode: '',
        covidSatCode: '',
        covidCaseCode: '',
        covidCurrentState: '',
        covidCurrentLevel: '',
        markDeleteDocumentImage: false,
        personalDocumentImage: null,
        sickLocationType: 'home',
        sickLocationOtherDetail: '',
        sickLocationProvince: '',
        sickLocationDistrict: '',
        sickLocationSubDistrict: '',
        sickLocationRoad: '',
        sickLocationAlley: '',
        sickLocationVillage: '',
        sickLocationMoo: '',
        sickLocationAddress: '',
        sickLocationCommunityName: '',
        startIsolationDate: '',
        lastIsolationDate: '',
        vaccineStatus: '',
        vaccinePassportStatus: '',
        timelineDetail: '',
        covidTests: [{}],
        antibodyTests: [{}],
        risks: {
          r0: '',
          r1: '',
          r2: '',
          r3: '',
          r4: '',
          r5: '',
          r6: '',
          r7: '',
          r8: '',
          r9: '',
          lastContactCovid19patient: '',
          firstHomeQuarantine: '',
          lastHomeQuarantine: '',
        },
        risksInfo: {
          r0: {
            detail: '',
          },
          r1: {
            city: '', country: '', arriveThaiDate: '', airline: '', flightNumber: '', flightSeat: '',
          },
          r4: {
            detail: '',
          },
          r6: {
            detail: '',
          },
          ...({}),
        },
        required,
        length,
        between,
        regex,
      },

      covidCurrentStateChoices: [
        { text: 'รอเตียง', value: 'wait_bed' },
        { text: 'Home Isolation', value: 'hi' },
        { text: 'Community Isolation', value: 'ci' },
        { text: 'Home Quarantine', value: 'hq' },
        { text: 'Admit', value: 'admit' },
        { text: 'จบเคส', value: 'end' },
        { text: 'ตาย', value: 'death' },
      ],
      covidCurrentLevelChoices: [
        { text: 'เขียว', value: 'green' },
        { text: 'เหลือง', value: 'yellow' },
        { text: 'แดง', value: 'red' },
      ],

      dailyReportStatusChoices: [
        { text: 'ผู้ป่วย', value: 'general_patient' },
        { text: 'ผู้สัมผัสเสี่ยงสูง (HR)', value: 'hr' },
        { text: 'ผู้สัมผัสเสี่ยงต่ำ (LR)', value: 'lr' },
      ],
      dailyReportContactTypeChoices: [
        { text: 'ร่วมบ้าน', value: 'in_house' },
        { text: 'ที่ทำงาน', value: 'coworker' },
        { text: 'เพื่อน', value: 'friend' },
        { text: 'อื่นๆ', value: 'other' },
      ],
    }
  },
  computed: {
    previewUploadImage() {
      if (this.form.personalDocumentImage) {
        return URL.createObjectURL(this.form.personalDocumentImage)
      }
      if (!this.form.markDeleteDocumentImage && this.form.attachmentFiles && this.form.attachmentFiles.length > 0) {
        const file = this.form.attachmentFiles[0]
        return file.url
      }
      return null
    },
    previewUploadLabImage() {
      if (this.form.labImage && !this.form.labImage.url) {
        return URL.createObjectURL(this.form.labImage)
      }
      if (!this.form.markDeleteLabImage && this.form.labImage) {
        const file = this.form.labImage
        return file.url
      }
      return null
    },
    provinceChoices() {
      return Object.keys(provinceList).map(provinceKey => {
        const province = provinceList[provinceKey]
        return { title: province.name.th, value: provinceKey }
      })
    },
    districtChoices() {
      try {
        if (this.form.sickLocationProvince) {
          const province = provinceList[this.form.sickLocationProvince]
          return Object.keys(province.amphoes).map(districtKey => {
            const district = province.amphoes[districtKey]
            return { title: district.name.th, value: districtKey }
          })
        }
        return []
      } catch (e) {
        return []
      }
    },
    subDistrictChoices() {
      try {
        if (this.form.sickLocationProvince && this.form.sickLocationDistrict) {
          const province = provinceList[this.form.sickLocationProvince]
          const district = province.amphoes[this.form.sickLocationDistrict]
          return Object.keys(district.tambons).map(subDistrictKey => {
            const subDistrict = district.tambons[subDistrictKey]
            return { title: subDistrict.name.th, value: subDistrictKey }
          })
        }
        return []
      } catch (e) {
        return []
      }
    },
    createdAtFormatted() {
      if (this.createdAt) {
        return moment(this.createdAt).format('DD/MM/YYYY HH:mm')
      }
      return ''
    },
    isCovid() {
      if (this.form.covidCaseCode || (this.form.dailyReportLabDate && this.form.dailyReportLabSourceType)) {
        return true
      }
      return false
    },
  },
  watch: {
    '$route.path': function routePathChange() {
      this.resetForm()
    },
    'form.risks.lastContactCovid19patient': function lastContactCovid19patientChange(newVal) {
      if (newVal) {
        if (!this.form.risks.firstHomeQuarantine) this.form.risks.firstHomeQuarantine = moment(newVal).add(1, 'days').format('YYYY-MM-DD')
        if (!this.form.risks.lastHomeQuarantine) this.form.risks.lastHomeQuarantine = moment(newVal).add(14, 'days').format('YYYY-MM-DD')
      }
    },
    'form.dailyReportLabDate': function lastContactCovid19patientChange(newVal) {
      if (newVal) {
        if (!this.form.startIsolationDate) this.form.startIsolationDate = moment(newVal).add(1, 'days').format('YYYY-MM-DD')
        if (!this.form.lastIsolationDate) this.form.lastIsolationDate = moment(newVal).add(14, 'days').format('YYYY-MM-DD')
      }
    },
  },
  mounted() {
    this.resetForm()
  },
  methods: {
    async clearDocumentImage() {
      this.form.personalDocumentImage = null
      this.form.markDeleteDocumentImage = true
      this.form = { ...this.form }
    },
    async clearLabImage() {
      this.form.labImage = null
      this.form.markDeleteLabImage = true
      this.form = { ...this.form }
    },
    async changeProvince() {
      this.form.sickLocationDistrict = ''
      this.form.sickLocationSubDistrict = ''
    },
    async changeDistrict() {
      this.form.sickLocationSubDistrict = ''
    },
    async changeVaccineStatus() {
      if (this.form.vaccineStatus === 'no') {
        this.form.vaccinePassportStatus = 'no'
      }
    },
    resetForm() {
      this.form = { ...this.initialForm }
      // eslint-disable-next-line no-underscore-dangle
      this.form._id = this.$route.params.novelId || null
      // eslint-disable-next-line no-underscore-dangle
      if (this.form._id) {
        this.isCreateMode = false
        this.refetchNovel()
      } else {
        this.isCreateMode = true
        this.form = { ...this.initialForm }
      }
    },
    async refetchNovel() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        const response = await axios.get(`/novels/detail/${this.form._id}`)
        this.createdAt = response.data.createdAt
        this.uniqueId = response.data.uniqueId
        this.titleName = response.data.name

        const form = {
          // eslint-disable-next-line no-underscore-dangle
          _id: response.data._id,
          nameTitle: response.data.nameTitle || '',
          name: response.data.name,
          lastName: response.data.lastName,
          gender: response.data.gender || 'non-specific',
          mobileNumber: response.data.mobileNumber || '',
          nationality: response.data.nationality || '',
          occupation: response.data.occupation || '',
          occupationLocation: response.data.occupationLocation || '',
          occupationProvince: response.data.occupationProvince || '',
          dailyReportNumberOfHighRiskContact: response.data.dailyReportNumberOfHighRiskContact || '',
          dailyReportCaseIssueDate: response.data.dailyReportCaseIssueDate || '',
          dailyReportCaseIssueTime: response.data.dailyReportCaseIssueTime || '',
          dailyReportCaseReportDate: response.data.dailyReportCaseReportDate || '',
          dailyReportCaseReportTime: response.data.dailyReportCaseReportTime || '',
          dailyReportStatus: response.data.dailyReportStatus || '',
          dailyReportContactType: response.data.dailyReportContactType || '',
          dailyReportContactTypeOther: response.data.dailyReportContactTypeOther || '',
          dailyReportStartSickDate: response.data.dailyReportStartSickDate || '',
          dailyReportSymptom: response.data.dailyReportSymptom || '',
          dailyReportStartTreatmentDate: response.data.dailyReportStartTreatmentDate || '',
          dailyReportTreatmentLocation: response.data.dailyReportTreatmentLocation || '',
          dailyReportAdmitHospital1: response.data.dailyReportAdmitHospital1 || '',
          dailyReportAdmitHospital2: response.data.dailyReportAdmitHospital2 || '',
          dailyReportLabDate: response.data.dailyReportLabDate || '',
          dailyReportLabSourceType: response.data.dailyReportLabSourceType || '',
          dailyReportLabLocation: response.data.dailyReportLabLocation || '',
          dailyReportLabResultORFGeneCT: response.data.dailyReportLabResultORFGeneCT || '',
          dailyReportLabResultNGeneCT: response.data.dailyReportLabResultNGeneCT || '',
          dailyReportLabResultRdRPGeneCT: response.data.dailyReportLabResultRdRPGeneCT || '',
          dailyReportLabContactFrom: response.data.dailyReportLabContactFrom || '',
          birthDateDay: response.data.birthDateDay || '',
          birthDateMonth: response.data.birthDateMonth || '',
          birthDateYear: response.data.birthDateYear || '',
          idCard: response.data.idCard || '',
          passportId: response.data.passportId || '',
          patientCode: response.data.patientCode || '',
          attachmentFiles: response.data.attachmentFiles || [],
          labImage: response.data.labImage || null,
          sickLocationType: response.data.sickLocationType || 'home',
          sickLocationOtherDetail: response.data.sickLocationOtherDetail || '',
          sickLocationProvince: response.data.sickLocationProvince || '',
          sickLocationDistrict: response.data.sickLocationDistrict || '',
          sickLocationSubDistrict: response.data.sickLocationSubDistrict || '',
          sickLocationRoad: response.data.sickLocationRoad || '',
          sickLocationAlley: response.data.sickLocationAlley || '',
          sickLocationVillage: response.data.sickLocationVillage || '',
          sickLocationMoo: response.data.sickLocationMoo || '',
          sickLocationAddress: response.data.sickLocationAddress || '',
          sickLocationCommunityName: response.data.sickLocationCommunityName || '',
          startIsolationDate: response.data.startIsolationDate || '',
          lastIsolationDate: response.data.lastIsolationDate || '',
          vaccineStatus: response.data.vaccineStatus || '',
          vaccinePassportStatus: response.data.vaccinePassportStatus || '',
          risks: {
            r0: '',
            r1: '',
            r4: '',
            r6: '',
            ...(response.data.risks || {}),
          },
          risksInfo: {
            r0: {
              detail: '',
            },
            r1: {
              city: '', country: '', arriveThaiDate: '', airline: '', flightNumber: '', flightSeat: '',
            },
            r4: {
              detail: '',
            },
            r6: {
              detail: '',
            },
            ...(response.data.risksInfo || {}),
          },
          timelineDetail: response.data.timelineDetail || '',
          covidTests: response.data.covidTests && response.data.covidTests.length > 0 ? response.data.covidTests : [{}],
          antibodyTests: response.data.antibodyTests && response.data.antibodyTests.length > 0 ? response.data.antibodyTests : [{}],
          covidSatCode: response.data.covidSatCode || '',
          covidCaseCode: response.data.covidCaseCode || '',
          covidCurrentState: response.data.covidCurrentState || '',
          covidCurrentLevel: response.data.covidCurrentLevel || '',
        }
        this.form = { ...form }
      } catch (e) {
        this.$errorHandler(e)
      }
    },
    async submitForm(feedback = true, callback) {
      if (!this.form.idCard && !this.form.passportId && !this.form.patientCode) {
        this.$swal({
          icon: 'error',
          title: 'กรุณาระบุข้อมูล เลขบัตรประจำตัวประชาชน / Passport / Code อย่างน้อยอย่างใดอย่างหนึ่ง',
          confirmButtonText: 'ตกลง',
        })
        return
      }

      const validationResult = await this.$refs.novelForm.validate()
      if (!validationResult) { return }

      try {
        this.saving = true
        let documentImageId = null
        if (this.form.personalDocumentImage) {
          const formData = new FormData()
          formData.append('file', this.form.personalDocumentImage)

          const { data } = await axios.post('/upload/document-images', formData)
          // eslint-disable-next-line no-underscore-dangle
          documentImageId = data && data[0] && data[0]._id ? data[0]._id : null
        }

        let labImageId = null
        if (this.form.labImage && !this.form.labImage.url) {
          const formData = new FormData()
          formData.append('file', this.form.labImage)

          const { data } = await axios.post('/upload/lab-image', formData)
          // eslint-disable-next-line no-underscore-dangle
          labImageId = data && data[0] && data[0]._id ? data[0]._id : null
        }

        let path = '/novels/detail/create'
        let axiosMethod = axios.post
        // eslint-disable-next-line no-underscore-dangle
        if (this.form._id) {
          // eslint-disable-next-line no-underscore-dangle
          path = `/novels/detail/${this.form._id}`
          axiosMethod = axios.put
        }

        const result = await axiosMethod(path, {
          nameTitle: this.form.nameTitle,
          name: this.form.name,
          lastName: this.form.lastName,
          gender: this.form.gender || 'non-specific',
          birthDateDay: this.form.birthDateDay,
          birthDateMonth: this.form.birthDateMonth,
          birthDateYear: this.form.birthDateYear,
          mobileNumber: this.form.mobileNumber,
          nationality: this.form.nationality,
          occupation: this.form.occupation,
          occupationLocation: this.form.occupationLocation,
          occupationProvince: this.form.occupationProvince,
          dailyReportNumberOfHighRiskContact: this.form.dailyReportNumberOfHighRiskContact,
          dailyReportCaseIssueDate: this.form.dailyReportCaseIssueDate || '',
          dailyReportCaseIssueTime: this.form.dailyReportCaseIssueTime || '',
          dailyReportCaseReportDate: this.form.dailyReportCaseReportDate || '',
          dailyReportCaseReportTime: this.form.dailyReportCaseReportTime || '',
          dailyReportStatus: this.form.dailyReportStatus,
          dailyReportContactType: this.form.dailyReportContactType,
          dailyReportContactTypeOther: this.form.dailyReportContactTypeOther,
          dailyReportStartSickDate: this.form.dailyReportStartSickDate,
          dailyReportSymptom: this.form.dailyReportSymptom,
          dailyReportStartTreatmentDate: this.form.dailyReportStartTreatmentDate,
          dailyReportTreatmentLocation: this.form.dailyReportTreatmentLocation,
          dailyReportAdmitHospital1: this.form.dailyReportAdmitHospital1,
          dailyReportAdmitHospital2: this.form.dailyReportAdmitHospital2,
          dailyReportLabDate: this.form.dailyReportLabDate,
          dailyReportLabSourceType: this.form.dailyReportLabSourceType,
          dailyReportLabLocation: this.form.dailyReportLabLocation,
          dailyReportLabResultORFGeneCT: this.form.dailyReportLabResultORFGeneCT,
          dailyReportLabResultNGeneCT: this.form.dailyReportLabResultNGeneCT,
          dailyReportLabResultRdRPGeneCT: this.form.dailyReportLabResultRdRPGeneCT,
          dailyReportLabContactFrom: this.form.dailyReportLabContactFrom,
          idCard: this.form.idCard,
          passportId: this.form.passportId,
          patientCode: this.form.patientCode,
          markDeleteDocumentImage: this.form.markDeleteDocumentImage,
          documentImageId,
          markDeleteLabImage: this.form.markDeleteLabImage,
          labImageId,
          sickLocationType: this.form.sickLocationType || 'home',
          sickLocationOtherDetail: this.form.sickLocationType === 'other' ? this.form.sickLocationOtherDetail || '' : '',
          sickLocationProvince: this.form.sickLocationProvince || '',
          sickLocationDistrict: this.form.sickLocationDistrict || '',
          sickLocationSubDistrict: this.form.sickLocationSubDistrict || '',
          sickLocationRoad: this.form.sickLocationRoad || '',
          sickLocationAlley: this.form.sickLocationAlley || '',
          sickLocationVillage: this.form.sickLocationVillage || '',
          sickLocationMoo: this.form.sickLocationMoo || '',
          sickLocationAddress: this.form.sickLocationAddress || '',
          sickLocationCommunityName: this.form.sickLocationCommunityName || '',
          startIsolationDate: this.form.startIsolationDate || '',
          lastIsolationDate: this.form.lastIsolationDate || '',
          vaccineStatus: this.form.vaccineStatus || '',
          vaccinePassportStatus: this.form.vaccinePassportStatus || '',
          risks: this.form.risks || {},
          risksInfo: this.form.risksInfo || {},
          timelineDetail: this.form.timelineDetail || '',
          covidTests: this.form.covidTests || [{}],
          antibodyTests: this.form.antibodyTests || [{}],
          covidSatCode: this.form.covidSatCode,
          covidCaseCode: this.form.covidCaseCode,
          covidCurrentState: this.form.covidCurrentState,
          covidCurrentLevel: this.form.covidCurrentLevel,
        })
        if (feedback) {
          this.$swal({
            icon: 'success',
            title: 'สำเร็จ',
            text: 'บันทึกข้อมูลสำเร็จ',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
        this.saving = false
        this.resetForm()
        // eslint-disable-next-line consistent-return
        if (this.isCreateMode) {
          const { data } = result
          // eslint-disable-next-line no-underscore-dangle
          await this.$router.replace({ name: 'staff-novel-full-form', params: { novelId: data._id } })
        } else if (callback) callback()
      } catch (e) {
        this.saving = false
        this.$errorHandler(e)
        // eslint-disable-next-line consistent-return
      } finally {
        this.saving = false
      }
    },
    async submitFormAndDownloadInvestigationFormDocx() {
      try {
        this.saving = true
        this.submitForm(false, async () => {
          this.saving = true
          // eslint-disable-next-line no-underscore-dangle
          const { data } = await axios.get(`/novels/detail/${this.form._id}/docx/investigation-form`)
          if (data && data.url) {
            // eslint-disable-next-line no-underscore-dangle
            await FileSaver.saveAs(data.url, `${this.form.idCard || this.form.passportId || this.form.patientCode} - แบบสอบสวนผู้ป่วย.docx`)
          }
          this.saving = false
        })
      } catch (e) {
        this.saving = false
      } finally {
        this.saving = false
      }
    },
    async submitFormAndDownloadSwabAgreementFormDocx() {
      try {
        this.saving = true
        this.submitForm(false, async () => {
          this.saving = true
          // eslint-disable-next-line no-underscore-dangle
          const { data } = await axios.get(`/novels/detail/${this.form._id}/docx/swab-agreement-form`)
          if (data && data.url) {
            // eslint-disable-next-line no-underscore-dangle
            await FileSaver.saveAs(data.url, `${this.form.idCard || this.form.passportId || this.form.patientCode} - ใบยินยอม รพ.docx`)
          }
          this.saving = false
        })
      } catch (e) {
        this.saving = false
      } finally {
        this.saving = false
      }
    },
    async submitFormAndDownloadRiskAgreementFormDocx() {
      try {
        this.saving = true
        this.submitForm(false, async () => {
          this.saving = true
          // eslint-disable-next-line no-underscore-dangle
          const { data } = await axios.get(`/novels/detail/${this.form._id}/docx/risk-agreement-form`)
          if (data && data.url) {
            // eslint-disable-next-line no-underscore-dangle
            await FileSaver.saveAs(data.url, `${this.form.idCard || this.form.passportId || this.form.patientCode} - ใบยินยอม สปคม.docx`)
          }
          this.saving = false
        })
      } catch (e) {
        this.saving = false
      } finally {
        this.saving = false
      }
    },
    async submitFormAndDownloadRiskReportFormDocx() {
      try {
        this.saving = true
        this.submitForm(false, async () => {
          this.saving = true
          // eslint-disable-next-line no-underscore-dangle
          const { data } = await axios.get(`/novels/detail/${this.form._id}/docx/risk-report-form`)
          if (data && data.url) {
            // eslint-disable-next-line no-underscore-dangle
            await FileSaver.saveAs(data.url, `${this.form.idCard || this.form.passportId || this.form.patientCode} - รายงานเสี่ยงภัย.docx`)
          }
          this.saving = false
        })
      } catch (e) {
        this.saving = false
      } finally {
        this.saving = false
      }
    },
    async submitFormAndDownloadLabATKFormDocx() {
      try {
        this.saving = true
        this.submitForm(false, async () => {
          this.saving = true
          // eslint-disable-next-line no-underscore-dangle
          const { data } = await axios.get(`/novels/detail/${this.form._id}/docx/lab-atk-form`)
          if (data && data.url) {
            // eslint-disable-next-line no-underscore-dangle
            await FileSaver.saveAs(data.url, `${this.form.idCard || this.form.passportId || this.form.patientCode} - รายงานแลป ATK.docx`)
          }
          this.saving = false
        })
      } catch (e) {
        this.saving = false
      } finally {
        this.saving = false
      }
    },
    confirmDeleteNovel() {
      // eslint-disable-next-line no-underscore-dangle
      if (!this.isCreateMode && this.form._id) {
        this.$bvModal
          .msgBoxConfirm(`ยืนยันที่จะลบรายการของ ${[this.uniqueId, this.titleName].join(' ')} ใช่หรือไม่`, {
            title: 'ยืนยันการลบ',
            size: 'md',
            okVariant: 'danger',
            okTitle: 'ยืนยันลบ',
            cancelTitle: 'ไม่,ยกเลิก',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.deleteNovel()
            }
          })
      }
    },
    deleteNovel() {
      // eslint-disable-next-line no-underscore-dangle
      if (this.form._id) {
        axios
          // eslint-disable-next-line no-underscore-dangle
          .delete(`/novels/detail/${this.form._id}`)
          .then(() => {
            this.$router.replace({ name: 'staff-novel-list' })
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error delete novel',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },

    addMoreCovidTest() {
      this.form.covidTests.push({})
    },
    deleteCovidTest(index) {
      this.form.covidTests.splice(index, 1)
    },

    addMoreAntibodyTest() {
      this.form.antibodyTests.push({})
    },
    deleteAntibodyTest(index) {
      this.form.antibodyTests.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
